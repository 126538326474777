import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import { ReducerAction } from '../utils/reducer-utils'

export type GlobalMessageReducer = {
  message: string | undefined
}

const Params = Record<GlobalMessageReducer>({
  message: undefined,
})

export type GlobalMessageAction = ReducerAction & {
  message?: string
}

export default (
  state: Record<GlobalMessageReducer> = Params(),
  action: GlobalMessageAction = { type: '' }
): Record<GlobalMessageReducer> => {
  switch (action.type) {
    case ActionTypes.GLOBAL_MESSAGE_LOADED:
      return Params({
        message: action.message,
      })
    default:
      return state
  }
}
