import React from 'react'

import { fetchAgreements, postAgreement } from '../api/agreements'
import ActionTypes from '../constants/action-types'
import Agreement from '../model/agreement'
import { AgreementAction } from '../reducers/agreements'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingAgreements(): AgreementAction {
  return {
    type: ActionTypes.AGREEMENT_LOADING,
  }
}
export function loadedAgreements(companyID: string, agreements: Agreement[]): AgreementAction {
  return {
    type: ActionTypes.AGREEMENT_LOADED,
    agreements,
    companyID,
  }
}
function failedLoadingAgreements(companyID: string, error: Error): AgreementAction {
  return {
    type: ActionTypes.AGREEMENT_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingAgreement(companyID: string): AgreementAction {
  return {
    type: ActionTypes.AGREEMENT_ADDING,
    companyID,
  }
}
export function addedAgreement(companyID: string, agreement: Agreement): AgreementAction {
  return {
    type: ActionTypes.AGREEMENT_ADDED,
    companyID,
    agreement,
  }
}
function failedAddingAgreement(companyID: string, error: Error): AgreementAction {
  return {
    type: ActionTypes.AGREEMENT_ADD_FAILED,
    error,
    companyID,
  }
}

export function getAgreements() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Agreement[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingAgreements())
    return fetchAgreements(companyID)
      .then((res) => {
        dispatch(loadedAgreements(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingAgreements(companyID, e))
        }
      })
  }
}

export function acceptAgreement(type: 'GDPR_1.0') {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Agreement | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingAgreement(companyID))
    return postAgreement(companyID, type)
      .then((res) => {
        dispatch(addedAgreement(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingAgreement(companyID, e))
        }
      })
  }
}
