type GrossPayReductionType =
  | 'Gross Pay Reduction'
  | 'Gross Pay Reduction No Vacation'
  | 'Gross Pay Reduction No Pension'
  | 'Gross Pay Reduction No Vacation and Pension'

export function figureGrossPayReductionType(withVacation: boolean, withPension: boolean): GrossPayReductionType {
  if (withVacation) {
    if (withPension) {
      return 'Gross Pay Reduction'
    } else {
      return 'Gross Pay Reduction No Pension'
    }
  } else {
    if (withPension) {
      return 'Gross Pay Reduction No Vacation'
    } else {
      return 'Gross Pay Reduction No Vacation and Pension'
    }
  }
}
