import { addDays, isSameDay } from 'date-fns'
import React, { ReactElement, useEffect } from 'react'

import Contract from '../../../model/contract'
import SalaryPeriod from '../../../model/salaryPeriod'
import { EmployeeContractDeltaReducer } from '../../../reducers/employeeContractDeltas'
import { formatAPIDate, formatDate, getDate, isSameOrBefore, isTimeAfter, isValidDate } from '../../../utils/date-utils'
import { isContractDeltaActive } from '../../../utils/employee-contract-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Select from '../../antd/select'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'

type Props = {
  earliestMutableContract: Contract
  viewingContract: Contract
  employeeContractDeltas: EmployeeContractDeltaReducer

  getSalaryPeriods: () => SalaryPeriod[]
  contractSelectChange: (id: string) => void
}

export type FutureContractResult = {
  contractID: string
  validFrom: string
  viewingContractID: string
}

function CreateFutureContractForm(
  props: Props & FormComponentProps<FutureContractResult, FutureContractResult>
): ReactElement | null {
  const handleValidFrom = () => {
    const currentValidFrom = props.getFieldValue('validFrom')
    if (
      !props
        .getSalaryPeriods()
        .some((salaryPeriod) => isSameDay(getDate(salaryPeriod.start), getDate(currentValidFrom)))
    ) {
      props.setFieldValue(
        'validFrom',
        props
          .getSalaryPeriods()
          .find(
            (salaryPeriod) =>
              isTimeAfter(getDate(salaryPeriod.start), addDays(getDate(props.earliestMutableContract.validFrom), 1)) &&
              isTimeAfter(getDate(salaryPeriod.start), getDate())
          )?.start || currentValidFrom
      )
    }
  }

  useEffect(() => {
    if (props.viewingContract.id !== props.getFieldValue('viewingContractID')) {
      props.setFieldValue('contractID', props.viewingContract.id)
      props.setFieldValue('viewingContractID', props.viewingContract.id)
    }
  })

  const earliestMutableContract = props.earliestMutableContract
  const viewingContract = props.viewingContract

  const { decorateField } = props
  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('contractID', {
            title: t('create_future_contract.form.contract_id'),
            placeholder: t('create_future_contract.form.contract_id.placeholder'),
            validate: (val) => {
              if (!val) {
                return t('create_future_contract.form.contract_id.required')
              }
              if (!props.employeeContractDeltas.contracts.some((delta) => delta.contractID === val)) {
                return t('create_future_contract.form.contract_id.invalid')
              }
              return null
            },
            skipWrapper: true,
          })(
            <Select
              dropdownMatchSelectWidth={false}
              onChange={(e: string) => {
                props.contractSelectChange(e)
                handleValidFrom()
              }}
            >
              {props.employeeContractDeltas.contracts.reverse().map((delta) => {
                const isViewingContract = delta.contractID === viewingContract.id
                const isActiveContract = isContractDeltaActive(delta)
                return (
                  <Select.Option key={delta.contractID} value={delta.contractID}>
                    {formatDate(delta.validFrom)} - {delta.validTo ? formatDate(delta.validTo) : ''}
                    {isViewingContract || isActiveContract
                      ? ' ' +
                        (isActiveContract
                          ? isViewingContract
                            ? t('create_future_contract.form.contract_id.contract_state.active_viewing')
                            : t('create_future_contract.form.contract_id.contract_state.active')
                          : t('create_future_contract.form.contract_id.contract_state.viewing'))
                      : ''}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('validFrom', {
            title: t('create_future_contract.form.valid_from'),
            placeholder: t('create_future_contract.form.valid_from.placeholder'),
            validate: (val) => {
              if (!val) {
                return t('create_future_contract.form.valid_from.required')
              }
              if (!isValidDate(getDate(val))) {
                return t('create_future_contract.form.valid_from.invalid')
              }
              if (isSameOrBefore(getDate(val), getDate(earliestMutableContract.validFrom))) {
                return t('create_future_contract.form.valid_from.not_before_or_same_as_active')
              }
              return null
            },
          })(
            <Select dropdownMatchSelectWidth={false}>
              {props.getSalaryPeriods().map((salaryPeriod) => {
                return (
                  <Select.Option key={salaryPeriod.id} value={salaryPeriod.start}>
                    {formatDate(salaryPeriod.start)}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('create_future_contract.form.submit')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div className="ant-form-warning">
            {t('create_future_contract.form.warning.line_1')}
            <br />
            {t('create_future_contract.form.warning.line_2')}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, FutureContractResult, FutureContractResult>({
  mapPropsToFields: (props) => {
    return {
      validFrom: props.getSalaryPeriods()[0]?.start ?? formatAPIDate(getDate()),
      contractID: props.viewingContract.id,
      viewingContractID: props.viewingContract.id, // keeping track of it
    }
  },
  onSubmit: (values) => values,
})(CreateFutureContractForm)
