import RcTooltip from 'rc-tooltip'
import React, { CSSProperties, forwardRef, ReactElement, ReactNode, useEffect, useState } from 'react'
import { cloneElement } from 'react'

import classNames from '../../antd/_util/classNames'
import getPlacements, { PlacementAlign } from './placements'

import './style/css'

const splitObject = (obj: CSSProperties, keys: (keyof CSSProperties)[]) => {
  const picked: CSSProperties = {}
  const omited: CSSProperties = { ...obj }
  keys.forEach((key) => {
    if (obj && key in obj) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      picked[key] = obj[key]
      delete omited[key]
    }
  })
  return { picked, omited }
}

type Props = {
  title?: ReactNode
  prefixCls?: string
  placement?: 'top' | 'bottomRight'
  transitionName?: 'zoom-big-fast' | 'zoom-big'
  mouseEnterDelay?: number
  mouseLeaveDelay?: number
  arrowPointAtCenter?: boolean
  autoAdjustOverflow?: boolean
  visible?: boolean
  defaultVisible?: boolean
  onVisibleChange?: (visible: boolean) => void
  trigger?: 'hover' | 'click'
  openClassName?: string
  closeClassName?: string
  getPopupContainer?: () => void
  getTooltipContainer?: () => void
  overlayStyle?: CSSProperties
  overlayClassName?: string
  onClick?: (e: React.MouseEvent) => void
  children?: ReactNode
}

export default forwardRef(function Tooltip(props: Props, ref): ReactElement | null {
  const {
    prefixCls = 'ant-tooltip',
    placement = 'top',
    transitionName = 'zoom-big-fast',
    mouseEnterDelay = 0.1,
    mouseLeaveDelay = 0.1,
    arrowPointAtCenter = false,
    autoAdjustOverflow = true,
  } = props

  const [visible, setVisible] = useState(!!props.visible || !!props.defaultVisible)

  useEffect(() => {
    if ('visible' in props && props.visible !== undefined && props.visible !== visible) {
      setVisible(props.visible)
    }
  }, [props, visible, setVisible])

  const isNoTitle = () => {
    return !props.title
  }

  const onVisibleChange = (visible: boolean) => {
    const { onVisibleChange } = props
    if (!('visible' in props)) {
      setVisible(isNoTitle() ? false : visible)
    }
    if (onVisibleChange && !isNoTitle()) {
      onVisibleChange(visible)
    }
  }

  const getOurPlacements = () => {
    const { arrowPointAtCenter, autoAdjustOverflow } = props
    return getPlacements({
      arrowPointAtCenter,
      verticalArrowShift: 8,
      autoAdjustOverflow,
    })
  }

  const isHoverTrigger = (): boolean => {
    const { trigger } = props
    if (!trigger || trigger === 'hover') {
      return true
    }
    // if (Array.isArray(trigger)) {
    //   return trigger.indexOf('hover') >= 0
    // }
    return false
  }

  // Fix Tooltip won't hide at disabled button
  // mouse events don't trigger at disabled button in Chrome
  // https://github.com/react-component/tooltip/issues/18
  const getDisabledCompatibleChildren = (element: ReactElement) => {
    if (element.type === 'button' && element.props.disabled && isHoverTrigger()) {
      // Pick some layout related style properties up to span
      // Prevent layout bugs like https://github.com/ant-design/ant-design/issues/5254
      const { picked, omited } = splitObject(element.props.style, [
        'position',
        'left',
        'right',
        'top',
        'bottom',
        'float',
        'display',
        'zIndex',
      ])
      const spanStyle = {
        display: 'inline-block', // default inline-block is important
        ...picked,
        cursor: 'not-allowed',
      }
      const buttonStyle = {
        ...omited,
        pointerEvents: 'none',
      }
      const child = cloneElement(element, {
        style: buttonStyle,
        className: null,
      })
      return (
        <span style={spanStyle} className={element.props.className}>
          {child}
        </span>
      )
    }
    return element
  }

  const onPopupAlign = (domNode: HTMLDivElement, align: PlacementAlign) => {
    const placements = getOurPlacements()
    const placement = Object.keys(placements).filter(
      (key) => placements[key].points[0] === align.points[0] && placements[key].points[1] === align.points[1]
    )[0]
    if (!placement) {
      return
    }
    const rect = domNode.getBoundingClientRect()
    const transformOrigin = {
      top: '50%',
      left: '50%',
    }
    if (placement.indexOf('top') >= 0 || placement.indexOf('Bottom') >= 0) {
      transformOrigin.top = `${rect.height - align.offset[1]}px`
    } else if (placement.indexOf('Top') >= 0 || placement.indexOf('bottom') >= 0) {
      transformOrigin.top = `${-align.offset[1]}px`
    }
    if (placement.indexOf('left') >= 0 || placement.indexOf('Right') >= 0) {
      transformOrigin.left = `${rect.width - align.offset[0]}px`
    } else if (placement.indexOf('right') >= 0 || placement.indexOf('Left') >= 0) {
      transformOrigin.left = `${-align.offset[0]}px`
    }
    domNode.style.transformOrigin = `${transformOrigin.left} ${transformOrigin.top}`
  }

  const { title, openClassName, closeClassName, getPopupContainer, getTooltipContainer } = props
  const children = props.children
  let visibleOverride = visible
  // Hide tooltip when there is no title
  if (!('visible' in props) && isNoTitle()) {
    visibleOverride = false
  }

  const child = getDisabledCompatibleChildren(React.isValidElement(children) ? children : <span>{children}</span>)
  const childProps = child.props
  const openChildCls = classNames(childProps.className, {
    [openClassName || `${prefixCls}-open`]: true,
  })
  const closeChildCls = classNames(childProps.className, {
    [closeClassName || `${prefixCls}-close`]: true,
  })

  return (
    <RcTooltip
      {...props}
      prefixCls={prefixCls}
      placement={placement}
      transitionName={transitionName}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      arrowPointAtCenter={arrowPointAtCenter}
      autoAdjustOverflow={autoAdjustOverflow}
      getTooltipContainer={getPopupContainer || getTooltipContainer}
      ref={ref}
      builtinPlacements={getOurPlacements()}
      overlay={title || ''}
      visible={visibleOverride}
      onVisibleChange={onVisibleChange}
      onPopupAlign={onPopupAlign}
    >
      {visibleOverride
        ? cloneElement(child, { className: openChildCls })
        : cloneElement(child, { className: closeChildCls })}
    </RcTooltip>
  )
})
