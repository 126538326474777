import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import CompanyGroup, { CompanyGroupUser } from '../../../model/companyGroup'
import { CompanyGroupReducer } from '../../../reducers/companyGroups'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import UserEditModalForm, { UserEditResult } from './UserEditModalForm'

type Props = {
  visible: boolean
  userID?: string
  companyGroups: CompanyGroupReducer
  companyGroup: CompanyGroup

  addAlert: addAlertSignature
  updateCompanyGroupUser: (
    companyGroupID: string,
    userID: string,
    companyGroupUser: CompanyGroupUser
  ) => Promise<CompanyGroupUser | void>
  closeModal: () => void
}

export default function UserEditModal(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { companyGroups, companyGroup, addAlert, userID } = props
  const previousCompanyGroups = usePrevious(companyGroups)
  useEffect(() => {
    if (previousCompanyGroups && previousCompanyGroups.saving && !companyGroups.saving) {
      if (!companyGroups.error) {
        const originalUser = companyGroup.users.find((user) => user.userID === userID)
        addAlert(
          'success',
          t('companies.group.users.alert.success', { name: originalUser?.user.name ?? t('common.unknown') }),
          { timeout: 5 }
        )
      }
    }
  })

  const { visible } = props
  useEffect(() => {
    visibleComponentDidUpdate(visible, companyGroups.error, error, setError)
  }, [visible, companyGroups, error])

  const handleSubmit = (values: UserEditResult) => {
    const originalUser = props.companyGroup.users.find((user) => user.userID === props.userID)
    if (!originalUser) {
      props.closeModal()
      return
    }
    props
      .updateCompanyGroupUser(props.companyGroup.id, originalUser.userID, { ...originalUser, ...values })
      .then(() => props.closeModal())
  }

  if (!props.userID) {
    return null
  }

  const companyGroupUser = props.companyGroup.users.find((user) => user.userID === props.userID)
  if (!companyGroupUser) {
    return null
  }

  return (
    <Card>
      <Subtitle>
        {t('companies.group.users.edit.title', { name: companyGroupUser.user.name || companyGroupUser.user.email })}
      </Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <UserEditModalForm
        companyGroups={props.companyGroups}
        companyGroupUser={companyGroupUser}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
