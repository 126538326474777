import React, { ReactElement, useEffect } from 'react'
import { usePrevious } from 'react-use'

import { addAlert, addAlertSignature } from '../actions/alerts'
import { verifyEmail } from '../actions/user'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { UserReducer } from '../reducers/user'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'

type Reducers = {
  user: UserReducer
}

type Actions = {
  addAlert: addAlertSignature
  verifyEmail: (token: string) => void
}

function VerifyEmail(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { user, verifyEmail, params } = props
  useEffect(() => {
    if (!user.verifyingEmail && !user.verifiedEmail) {
      verifyEmail(splatString(params.splat))
    }
  })

  const { addAlert } = props
  const previousUser = usePrevious(user)

  useEffect(() => {
    // Check for save callback
    if (previousUser && previousUser.verifyingEmail && !user.verifyingEmail) {
      // Check for no error occurred
      if (!user.error) {
        addAlert('success', 'Tak, din email er nu verificeret.', {
          timeout: 5,
        })
        setTimeout(() => {
          // Redirect to account page
          jsBrowserHistory.push('/')
        }, 500)
      } else {
        addAlert('warning', 'Linket til verificering af mail er ikke gyldigt, kontakt support.')
        // Redirect to account page
        jsBrowserHistory.push('/')
      }
    }
  })

  return (
    <div style={{ position: 'relative', minHeight: '300px', marginTop: '96px' }}>
      <LoadingOverlay />
    </div>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    user: state.user,
  }),
  {
    addAlert: addAlert,
    verifyEmail: verifyEmail,
  }
)(VerifyEmail)
