import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Employee from '../../../model/employee'
import LeaveBalance from '../../../model/leaveBalance'
import LeaveType, { LeaveTypeName } from '../../../model/leaveType'
import Remuneration from '../../../model/remuneration'
import SalaryCycle from '../../../model/salaryCycle'
import SalaryType from '../../../model/salaryType'
import { AdjustmentOperation, DateFormat } from '../../../model/types'
import { LeaveAdjustmentReducer } from '../../../reducers/leaveAdjustments'
import RemunerationType from '../../../types/remuneration-type'
import { formatDate } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { formatLeaveTypeName, formatLeaveUnit } from '../../../utils/format-utils'
import {
  forceParseInputNumber,
  formatCurrency,
  formatDisplayNumber,
  formatInputNumber,
  formatNumber,
  parseInputNumber,
} from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { getDispositionDateList } from '../../../utils/one-time-pay-utils'
import { getCurrentPeriodFromDispositionDate } from '../../../utils/salary-period-utils'
import { t, tx } from '../../../utils/translation-utils'
import Radio, { Group as RadioGroup } from '../../antd/radio'
import Select from '../../antd/select'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import Switch from '../../elements/switch'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  employee: Employee
  leaveAdjustmentID?: string
  leaveAdjustments: LeaveAdjustmentReducer
  leaveBalances: List<LeaveBalance>
  leaveTypes: List<LeaveType>
  salaryTypes: List<SalaryType>
  remuneration: Remuneration
  remunerationType: RemunerationType
  salaryCycle: SalaryCycle
}

type Fields = {
  dispositionDate?: DateFormat
  leaveTypeName?: LeaveTypeName
  operation: AdjustmentOperation
  addValuation: boolean
  earned?: string
  used?: string
  writtenOff?: string
  excessCharged?: string
  valuation?: string
  liability?: string
}

export type LeaveAdjustmentResult = {
  dispositionDate: DateFormat
  leaveTypeName: LeaveTypeName
  operation: AdjustmentOperation
  earned?: number
  used?: number
  valuation?: number
  liability?: number
}

type valuationResult = {
  earned: number
  multiplier: number
  divider: number
  salary: number
  result: number
}

function calculationValuation(props: Props, earned: number): valuationResult | null {
  if (!earned || earned <= 0) {
    return null
  }
  const salary = props.employee.activeContract?.remuneration?.salary.find(
    (salary) => salary.salaryTypeID === props.salaryTypes.find((type) => type.name === 'Fixed')?.id
  )?.rate
  if (!salary) {
    return null
  }
  return {
    earned,
    multiplier: 0.125,
    divider: 2.08,
    salary,
    result: parseFloat((((salary * 0.125) / 2.08) * earned).toFixed(2)),
  }
}

function LeaveAdjustmentEditForm(
  props: Props & FormComponentProps<Fields, LeaveAdjustmentResult>
): ReactElement | null {
  const getLeaveTypes = () => {
    return props.leaveTypes.filter(
      (leaveType) =>
        props.remuneration.leave.some((leave) => leave.typeID === leaveType.id) ||
        props.leaveBalances.some(
          (balance) =>
            balance.leaveTypeID === leaveType.id &&
            (balance.left !== 0.0 || balance.used !== 0.0 || balance.registered !== 0.0)
        )
    )
  }

  const valueValidate = (val?: string) => {
    if (val) {
      return null
    }
    if (props.getFieldValue('liability') !== undefined) {
      return null
    }
    if (props.getFieldValue('earned') === undefined && props.getFieldValue('used') === undefined) {
      return t('balance_adjustment_tab.leave.edit.form.value.need_one')
    }
    return null
  }

  const { decorateField, getFieldValue } = props

  const leaveType = props.leaveTypes.find((leaveType) => leaveType.name === props.getFieldValue('leaveTypeName'))
  const leaveBalance = props.leaveBalances.find(
    (leaveBalance) => !!leaveType && leaveBalance.leaveTypeID === leaveType.id
  )
  let leaveUnit = leaveType ? leaveType.unit : 'Days'
  let div = 1
  if (leaveUnit === 'Minutes') {
    leaveUnit = 'Hours' // we force it to read hours instead
    div = 60
  }

  const canAddValuation =
    getFieldValue('leaveTypeName') === 'DenmarkVacationAccrual' &&
    getFieldValue('operation') === 'Increase' &&
    forceParseInputNumber(getFieldValue('earned')) > 0
  const valuation = calculationValuation(props, forceParseInputNumber(getFieldValue('earned')))
  const leaveTypes = getLeaveTypes()
  const adjustLiability = getFieldValue('leaveTypeName') === 'DenmarkOvertime'
  const toIncludePrecision = (leaveName: LeaveTypeName): boolean => {
    // helper function to determine whether to add the "include precision" field to formatLeaveTypeName()
    // basically: if the employee have both "versions" of some double leave types (e.g. DenmarkPersonalTimeAccrual/DenmarkPersonalDay),
    // we add the precision, so the user can tell them apart
    switch (leaveName) {
      case 'DenmarkPersonalDay':
        return leaveTypes.some((lt) => lt.name === 'DenmarkPersonalTimeAccrual')
      case 'DenmarkPersonalTimeAccrual':
        return leaveTypes.some((lt) => lt.name === 'DenmarkPersonalDay')
      case 'DenmarkExtraVacationAccrual':
        return leaveTypes.some((lt) => lt.name === 'DenmarkOptionalVacation')
      case 'DenmarkOptionalVacation':
        return leaveTypes.some((lt) => lt.name === 'DenmarkExtraVacationAccrual')
      default:
        return false
    }
  }

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('dispositionDate', {
            placeholder: t('balance_adjustment_tab.leave.edit.form.disposition_date'),
            validate: (val) => (!val ? t('balance_adjustment_tab.leave.edit.form.disposition_date.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false}>
              {getDispositionDateList(props.salaryCycle.salaryPeriods, []).map((date) => {
                return (
                  <Select.Option key={date} value={date}>
                    {formatDate(date)}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('leaveTypeName', {
            placeholder: t('balance_adjustment_tab.leave.edit.form.leave_type_name'),
            validate: (val) => (!val ? t('balance_adjustment_tab.leave.edit.form.leave_type_name.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false}>
              {leaveTypes.map((leaveType) => {
                return (
                  <Select.Option key={leaveType.id} value={leaveType.name}>
                    {formatLeaveTypeName(
                      leaveType.name,
                      false,
                      props.remunerationType,
                      toIncludePrecision(leaveType.name)
                    )}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('operation', {
            placeholder: t('balance_adjustment_tab.leave.edit.form.operation'),
            validate: (val) => (!val ? t('balance_adjustment_tab.leave.edit.form.operation.required') : null),
          })(
            <RadioGroup>
              <Radio value="Increase">{t('balance_adjustment_tab.leave.edit.form.operation.increase')}</Radio>
              <Radio value="Reduce">{t('balance_adjustment_tab.leave.edit.form.operation.reduce')}</Radio>
              <Radio value="Override">{t('balance_adjustment_tab.leave.edit.form.operation.override')}</Radio>
            </RadioGroup>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          {decorateField('earned', {
            placeholder: t('balance_adjustment_tab.leave.edit.form.earned'),
            suffix: formatLeaveUnit(leaveUnit),
            validate: valueValidate,
          })(<Input />)}
          {leaveBalance && (
            <p>
              {t('balance_adjustment_tab.leave.edit.form.value.balance')}: {formatNumber(leaveBalance.earned / div, 2)}{' '}
              {formatLeaveUnit(leaveUnit)}
            </p>
          )}
        </Col>
        <Col span={6}>
          {decorateField('used', {
            placeholder: t('balance_adjustment_tab.leave.edit.form.used'),
            suffix: formatLeaveUnit(leaveUnit),
            validate: valueValidate,
          })(<Input />)}
          {leaveBalance && (
            <p>
              {t('balance_adjustment_tab.leave.edit.form.value.balance')}: {formatNumber(leaveBalance.used / div, 2)}{' '}
              {formatLeaveUnit(leaveUnit)}
            </p>
          )}
        </Col>
        {adjustLiability && (
          <Col span={6}>
            {decorateField('liability', {
              placeholder: t('balance_adjustment_tab.leave.edit.form.liability'),
              suffix: t('balance_adjustment_tab.leave.edit.form.liability.suffix'),
            })(<Input />)}
            {leaveBalance && (
              <p>
                {t('balance_adjustment_tab.leave.edit.form.value.balance')}: {formatCurrency(leaveBalance.value, 2)}
              </p>
            )}
          </Col>
        )}
        {canAddValuation && (
          <Col span={12}>
            <div className="ant-switch-wrapper">
              {decorateField('addValuation', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">{t('balance_adjustment_tab.leave.edit.form.add_valuation')}</span>
            </div>
            {getFieldValue('addValuation') && (
              <>
                {decorateField('valuation', {
                  placeholder: t('balance_adjustment_tab.leave.edit.form.valuation.add'),
                  skipLabel: true,
                  suffix: t('balance_adjustment_tab.leave.edit.form.valuation.suffix'),
                  validate: (val) => {
                    if (val === undefined) {
                      return t('balance_adjustment_tab.leave.edit.form.valuation.required')
                    }
                    return null
                  },
                })(<Input />)}
                {valuation && (
                  <small>
                    {tx('balance_adjustment_tab.leave.edit.form.valuation.format', {
                      rate: formatCurrency(valuation.salary, 2),
                      multiplier: formatDisplayNumber(valuation.multiplier * 100, 2),
                      divider: formatDisplayNumber(valuation.divider),
                      earned: <strong>{formatDisplayNumber(valuation.earned, 2)}</strong>,
                      suffix: t('unit.day', { count: valuation.earned }),
                    })}
                  </small>
                )}
              </>
            )}
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.leaveAdjustments.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, LeaveAdjustmentResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      operation: 'Increase',
      dispositionDate: getCurrentPeriodFromDispositionDate(props.salaryCycle.salaryPeriods)?.dispositionDate,
      addValuation: false,
    }
    if (props.leaveAdjustmentID) {
      const leaveAdjustment = props.leaveAdjustments.leaveAdjustments.find(
        (leaveAdjustment) => leaveAdjustment.id === props.leaveAdjustmentID
      )
      if (leaveAdjustment) {
        const leaveType = props.leaveTypes.find((leaveType) => leaveType.name === leaveAdjustment.leaveTypeName)
        fields.leaveTypeName = leaveAdjustment.leaveTypeName
        fields.operation = leaveAdjustment.operation
        fields.dispositionDate = leaveAdjustment.dispositionDate
        // if the leaveType.unit is MINUTES, we display it as HOURS, so we divide these by 60
        let div = 1
        if (leaveType?.unit === 'Minutes') {
          div = 60
        }
        fields.earned =
          leaveAdjustment.earned !== undefined ? formatInputNumber(leaveAdjustment.earned / div) : undefined
        fields.used = leaveAdjustment.used !== undefined ? formatInputNumber(leaveAdjustment.used / div) : undefined
        fields.addValuation =
          fields.leaveTypeName === 'DenmarkVacationAccrual' && leaveAdjustment.valuation !== undefined
        fields.valuation =
          fields.addValuation && leaveAdjustment.valuation !== undefined
            ? formatInputNumber(leaveAdjustment.valuation)
            : undefined
        fields.liability =
          leaveAdjustment.liability !== undefined ? formatInputNumber(leaveAdjustment.liability) : undefined
      }
    }
    return fields
  },
  onChange: (key, val, allValues, options, props) => {
    const values: Partial<Fields> = {}
    switch (key) {
      case 'leaveTypeName':
        if (val !== 'DenmarkOvertime') {
          // make sure liability is undefined, if not set
          setByPath(values, 'liability', undefined)
        }
        setByPath(values, key, val)
        break
      case 'addValuation':
      case 'earned': {
        setByPath(values, key, val)
        const earned = forceParseInputNumber(key === 'earned' ? (val as string) : allValues['earned'])
        const valuation = calculationValuation(props, earned)
        if (valuation) {
          setByPath(values, 'valuation', formatInputNumber(valuation.result))
        }
        break
      }
      case 'valuation':
        if (options.trigger === 'onBlur' && forceParseInputNumber(val as string) <= 0) {
          const earned = forceParseInputNumber(allValues['earned'])
          const valuation = calculationValuation(props, earned)
          if (valuation) {
            setByPath(values, 'valuation', formatInputNumber(valuation.result))
            break
          }
        }
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values, props) => {
    let mul = 1
    if (props.leaveTypes.find((leaveType) => leaveType.name === values.leaveTypeName)?.unit === 'Minutes') {
      mul = 60
    }
    return {
      ...values,
      dispositionDate: values.dispositionDate!,
      leaveTypeName: values.leaveTypeName!,
      valuation:
        !values.addValuation && values.liability !== undefined
          ? forceParseInputNumber(values.liability) // use liability here for valuation, as we mean to adjust both
          : values.addValuation && values.valuation !== undefined
          ? forceParseInputNumber(values.valuation)
          : undefined,
      liability: values.liability !== undefined ? forceParseInputNumber(values.liability) : undefined,
      earned: values.earned !== undefined ? forceParseInputNumber(values.earned) * mul : undefined,
      used: values.used !== undefined ? forceParseInputNumber(values.used) * mul : undefined,
    }
  },
})(LeaveAdjustmentEditForm)
