import React, { ReactElement } from 'react'

import Document from '../../../model/document'
import { formatDate } from '../../../utils/date-utils'
import { formatLanguage } from '../../../utils/format-utils'
import { t } from '../../../utils/translation-utils'
import Table from '../../antd/table'
import Subtitle from '../../elements/Subtitle'

type Props = {
  document: Document
}

export default function DocumentSignerTable(props: Props): ReactElement | null {
  if (!props.document.signers || props.document.signers.length === 0) {
    return null
  }

  type SignerRow = {
    key: string
    name: string
    phoneNumber: string
    language: string
    signed: string
  }

  const columns = [
    { key: 'name', dataIndex: 'name', title: t('document_tab.signer.table.header.name') },
    { key: 'phoneNumber', dataIndex: 'phoneNumber', title: t('document_tab.signer.table.header.phone_number') },
    { key: 'language', dataIndex: 'language', title: t('document_tab.signer.table.header.language') },
    { key: 'signed', dataIndex: 'signed', title: t('document_tab.signer.table.header.signed') },
  ]

  const getSigners = (): SignerRow[] => {
    return props.document.signers.map((signer) => ({
      key: signer.id,
      name: signer.name,
      phoneNumber:
        signer.phoneNumber && signer.phoneNumberCountryCode
          ? '+' + signer.phoneNumberCountryCode + ' ' + signer.phoneNumber
          : '-',
      language: formatLanguage(signer.language),
      signed:
        signer.state === 'Signed'
          ? signer.signedAt
            ? t('document_tab.signer.table.signed.true_with_date', { date: formatDate(signer.signedAt) })
            : t('document_tab.signer.table.signed.true')
          : t('document_tab.signer.table.signed.false'),
    }))
  }

  return (
    <div>
      <Subtitle>{t('document_tab.signer.table.title')}</Subtitle>
      <p>&nbsp;</p>
      <Table size={'small'} columns={columns} dataSource={getSigners()} pagination={false} />
    </div>
  )
}
