import { DateFormat } from '../model/types'
import Voucher from '../model/voucher'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchVouchers(payRollID?: string, companyID?: string): Promise<RequestResponse<Voucher[]>> {
  if (payRollID) {
    companyID = undefined // if payRollID is set, ignore companyID
  }
  return secureRequest('GET', url('/v2/vouchers', { payRollID, companyID }))
}

export function postVoucherRebook(id: string): Promise<RequestResponse<Voucher>> {
  return secureRequest('POST', url('/v2/vouchers/' + id + '/rebook'))
}

export function patchVoucher(id: string, date: DateFormat): Promise<RequestResponse<Voucher>> {
  return secureRequest('PATCH', url('/v2/vouchers/' + id), {
    body: {
      date,
    },
  })
}
