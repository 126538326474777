import React from 'react'

import { putUserCompany } from '../api/user-companies'
import ActionTypes from '../constants/action-types'
import CompanyUser from '../model/companyUser'
import { UserCompanyAction } from '../reducers/userCompanies'
import { isRequestError } from '../utils/error-utils'

export function loadedUserCompanies(userCompanies: CompanyUser[]): UserCompanyAction {
  return {
    type: ActionTypes.USER_COMPANY_LOADED,
    userCompanies,
  }
}

export function addedUserCompany(userCompany: CompanyUser): UserCompanyAction {
  return {
    type: ActionTypes.USER_COMPANY_ADDED,
    companyID: userCompany.companyID,
    userCompany,
  }
}

function updatingUserCompany(userCompany: CompanyUser): UserCompanyAction {
  return {
    type: ActionTypes.USER_COMPANY_UPDATING,
    userCompany,
  }
}
export function updatedUserCompany(userCompany: CompanyUser): UserCompanyAction {
  return {
    type: ActionTypes.USER_COMPANY_UPDATED,
    companyID: userCompany.companyID,
    userCompany,
  }
}
function failedUpdatingUserCompany(error: Error): UserCompanyAction {
  return {
    type: ActionTypes.USER_COMPANY_UPDATE_FAILED,
    error,
  }
}

export function updateUserCompany(userCompany: CompanyUser) {
  return (dispatch: React.Dispatch<any>): Promise<CompanyUser | void> => {
    dispatch(updatingUserCompany(userCompany))
    return putUserCompany(userCompany)
      .then((res) => {
        dispatch(updatedUserCompany(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingUserCompany(e))
        }
      })
  }
}
