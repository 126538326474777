import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getContractBookContracts, getContractBookIntegrations } from '../actions/contract-book'
import { getContractTemplateContracts, getContractTemplates } from '../actions/contract-templates'
import { addContract } from '../actions/contracts'
import { updateEmployee } from '../actions/employees'
import { getEmploymentPositions } from '../actions/employment-positions'
import { getPensionCompanies } from '../actions/pension-companies'
import { getSalaryCycles } from '../actions/salary-cycles'
import ContractsAddComponent from '../components/contracts-add/ContractsAdd'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import Contract, { ContractMutableFields } from '../model/contract'
import Employee from '../model/employee'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { ContractBookContractReducer } from '../reducers/contractBookContracts'
import { ContractBookIntegrationReducer } from '../reducers/contractBookIntegrations'
import { ContractReducer } from '../reducers/contracts'
import { ContractTemplateContractReducer } from '../reducers/contractTemplateContracts'
import { ContractTemplateReducer } from '../reducers/contractTemplates'
import { EmployeeReducer } from '../reducers/employees'
import { EmploymentPositionReducer } from '../reducers/employmentPositions'
import { LeaveTypeReducer } from '../reducers/leaveTypes'
import { PensionCompanyReducer } from '../reducers/pensionCompanies'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { SupplementTypeReducer } from '../reducers/supplementTypes'
import { paths } from '../routes'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  contracts: ContractReducer
  employees: EmployeeReducer
  employmentPositions: EmploymentPositionReducer
  salaryCycles: SalaryCycleReducer
  pensionCompanies: PensionCompanyReducer
  leaveTypes: LeaveTypeReducer
  supplementTypes: SupplementTypeReducer
  salaryTypes: SalaryTypeReducer
  contractBookIntegrations: ContractBookIntegrationReducer
  contractBookContracts: ContractBookContractReducer
  contractTemplates: ContractTemplateReducer
  contractTemplateContracts: ContractTemplateContractReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateEmployee: (employee: Employee) => Promise<Employee | void>
  addContract: (contract: ContractMutableFields) => Promise<Contract | void>
  getSalaryCycles: () => void
  getEmploymentPositions: () => void
  getPensionCompanies: () => void
  getContractBookIntegrations: () => void
  getContractBookContracts: () => void
  getContractTemplates: () => void
  getContractTemplateContracts: (contractTemplateID: string) => void
}

function ContractsAdd(props: Reducers & Actions & RouteProps): ReactElement | null {
  const {
    employmentPositions,
    getEmploymentPositions,
    salaryCycles,
    getSalaryCycles,
    pensionCompanies,
    getPensionCompanies,
    contractBookIntegrations,
    getContractBookIntegrations,
    contractBookContracts,
    getContractBookContracts,
    contractTemplates,
    getContractTemplates,
    location,
    contractTemplateContracts,
    getContractTemplateContracts,
  } = props
  useEffect(() => {
    if (!employmentPositions.loaded && !employmentPositions.loading) {
      getEmploymentPositions()
    }
    if (!salaryCycles.loaded && !salaryCycles.loading) {
      getSalaryCycles()
    }
    if (!pensionCompanies.loaded && !pensionCompanies.loading) {
      getPensionCompanies()
    }
    if (!contractBookIntegrations.loading && !contractBookIntegrations.loaded) {
      getContractBookIntegrations()
    }
    if (
      contractBookIntegrations.loaded &&
      contractBookIntegrations.contractBookIntegrations &&
      contractBookIntegrations.contractBookIntegrations.size > 0
    ) {
      if (!contractBookContracts.loading && !contractBookContracts.loaded) {
        getContractBookContracts()
      }
    }
    if (!contractTemplates.loading && !contractTemplates.loaded) {
      getContractTemplates()
    }
    const contractTemplateID = location.query.contractTemplateID
    if (contractTemplateID) {
      if (
        contractTemplateContracts.contractTemplateID !== contractTemplateID ||
        (!contractTemplateContracts.loading && !contractTemplateContracts.loaded)
      ) {
        getContractTemplateContracts(contractTemplateID)
      }
    }
  })

  const contractTemplateID = props.location.query.contractTemplateID
  const loading =
    !props.employmentPositions.loaded ||
    !props.salaryCycles.loaded ||
    !props.pensionCompanies.loaded ||
    !props.contractBookIntegrations.loaded ||
    (props.contractBookIntegrations.contractBookIntegrations &&
      props.contractBookIntegrations.contractBookIntegrations.size > 0 &&
      !props.contractBookContracts.loaded) ||
    !props.contractTemplates.loaded ||
    (contractTemplateID &&
      (!props.contractTemplateContracts.loaded ||
        props.contractTemplateContracts.contractTemplateID !== contractTemplateID))
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  const company = props.companies.company
  const employee = props.employees.employees.find((employee) => employee.id === props.params.splat)

  if (!company || !employee) {
    jsBrowserHistory.push('/' + paths.EMPLOYEES)
    return null
  }

  return (
    <ContractsAddComponent
      contractBookContractID={props.location.query.contractBookContractID}
      contractTemplateID={contractTemplateID}
      alerts={props.alerts}
      employee={employee}
      company={company}
      contracts={props.contracts}
      contractTemplates={props.contractTemplates.contractTemplates}
      contractTemplateContracts={props.contractTemplateContracts.contractTemplateContracts}
      employees={props.employees}
      employmentPositions={props.employmentPositions.employmentPositions}
      salaryCycles={props.salaryCycles.salaryCycles}
      pensionCompanies={props.pensionCompanies.pensionCompanies}
      leaveTypes={props.leaveTypes.leaveTypes}
      supplementTypes={props.supplementTypes.supplementTypes}
      salaryTypes={props.salaryTypes.salaryTypes}
      contractBookContracts={props.contractBookContracts}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      updateEmployee={props.updateEmployee}
      addContract={props.addContract}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    contracts: state.contracts,
    employees: state.employees,
    employmentPositions: state.employmentPositions,
    salaryCycles: state.salaryCycles,
    pensionCompanies: state.pensionCompanies,
    leaveTypes: state.leaveTypes,
    supplementTypes: state.supplementTypes,
    salaryTypes: state.salaryTypes,
    contractBookIntegrations: state.contractBookIntegrations,
    contractBookContracts: state.contractBookContracts,
    contractTemplates: state.contractTemplates,
    contractTemplateContracts: state.contractTemplateContracts,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    updateEmployee: updateEmployee,
    addContract: addContract,
    getEmploymentPositions: getEmploymentPositions,
    getSalaryCycles: getSalaryCycles,
    getPensionCompanies: getPensionCompanies,
    getContractBookIntegrations: getContractBookIntegrations,
    getContractBookContracts: getContractBookContracts,
    getContractTemplates: getContractTemplates,
    getContractTemplateContracts: getContractTemplateContracts,
  }
)(ContractsAdd)
