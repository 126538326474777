import { CarAllowanceRate } from '../api/car-allowances'
import { DateFormat } from '../model/types'
import { getDate, isTimeBetween } from './date-utils'

export function findRelevantCarAllowance(rates?: CarAllowanceRate[], inputDate?: Date | DateFormat): number {
  const date = getDate(inputDate)
  return (
    rates?.find((rates) => isTimeBetween(date, rates.validFrom, rates.validTo))?.rates.find((rate) => rate.step === 0)
      ?.rate ?? 0
  )
}
