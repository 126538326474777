import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Employee from '../../../model/employee'
import { LeaveAdjustmentCreationFields, LeaveAdjustmentMutableFields } from '../../../model/leaveAdjustment'
import LeaveBalance from '../../../model/leaveBalance'
import LeaveType from '../../../model/leaveType'
import Remuneration from '../../../model/remuneration'
import SalaryCycle from '../../../model/salaryCycle'
import SalaryType from '../../../model/salaryType'
import { LeaveAdjustmentReducer } from '../../../reducers/leaveAdjustments'
import RemunerationType from '../../../types/remuneration-type'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import LeaveAdjustmentEditForm, { LeaveAdjustmentResult } from './LeaveAdjustmentEditForm'

type Props = {
  visible: boolean
  employee: Employee
  leaveAdjustmentID?: string
  leaveAdjustments: LeaveAdjustmentReducer
  leaveBalances: List<LeaveBalance>
  leaveTypes: List<LeaveType>
  salaryTypes: List<SalaryType>
  remuneration: Remuneration
  remunerationType: RemunerationType
  salaryCycle: SalaryCycle

  addLeaveAdjustment: (employeeID: string, leaveAdjustment: LeaveAdjustmentCreationFields) => void
  updateLeaveAdjustment: (employeeID: string, leaveAdjustment: LeaveAdjustmentMutableFields) => void
}

export default function LeaveAdjustmentEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, leaveAdjustments } = props
  useEffect(() => {
    visibleComponentDidUpdate(visible, leaveAdjustments.error, error, setError)
  }, [visible, leaveAdjustments, error])

  const handleSubmit = (values: LeaveAdjustmentResult) => {
    const isVacationFund =
      values.leaveTypeName === 'DenmarkVacationFund' || values.leaveTypeName === 'DenmarkVacationFundWithPension'
    const leaveAdjustment: LeaveAdjustmentCreationFields = {
      employeeID: props.employee.id,
      leaveTypeName: values.leaveTypeName,
      operation: values.operation,
      dispositionDate: values.dispositionDate,
      earned: values.earned,
      used: values.used,
      writtenOff: isVacationFund ? values.earned : undefined,
      excessCharged: isVacationFund ? values.used : undefined,
      valuation: values.valuation,
      liability: values.liability,
    }
    if (props.leaveAdjustmentID) {
      props.updateLeaveAdjustment(props.employee.id, { ...leaveAdjustment, id: props.leaveAdjustmentID })
    } else {
      props.addLeaveAdjustment(props.employee.id, leaveAdjustment)
    }
  }

  if (!props.employee || !props.visible) {
    return null
  }
  return (
    <Card className="balance-adjustment-modal">
      <Subtitle>
        {props.leaveAdjustmentID
          ? t('balance_adjustment_tab.leave.edit.title.edit')
          : t('balance_adjustment_tab.leave.edit.title.create')}
      </Subtitle>
      <p>{t('balance_adjustment_tab.leave.edit.intro')}</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <LeaveAdjustmentEditForm
        employee={props.employee}
        leaveAdjustmentID={props.leaveAdjustmentID}
        leaveAdjustments={props.leaveAdjustments}
        leaveBalances={props.leaveBalances}
        leaveTypes={props.leaveTypes}
        salaryTypes={props.salaryTypes}
        remuneration={props.remuneration}
        remunerationType={props.remunerationType}
        salaryCycle={props.salaryCycle}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
