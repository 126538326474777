import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature } from '../actions/alerts'
import { getDocumentCategories } from '../actions/document-categories'
import { addDocument, addSignerToDocument, deleteDocument, getDocuments, updateDocument } from '../actions/documents'
import DocumentComponent from '../components/documents/Documents'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import Document, { DocumentCreationFields, DocumentMutableFields } from '../model/document'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { CompanyPricingReducer } from '../reducers/companyPricings'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { DocumentCategoryReducer } from '../reducers/documentCategories'
import { DocumentReducer } from '../reducers/documents'
import { EmployeeReducer } from '../reducers/employees'
import Language from '../types/language'
import { formatLoadingText } from '../utils/loading-utils'
import { hasDepartmentPermission } from '../utils/permissions-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'
import AdministrationLayout from './layouts/AdministrationLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  companyFeatures: CompanyFeatureReducer
  companyUsers: CompanyUserReducer
  employees: EmployeeReducer
  documents: DocumentReducer
  documentCategories: DocumentCategoryReducer
  companyPricings: CompanyPricingReducer
}

type Actions = {
  addAlert: addAlertSignature
  getDocuments: () => void
  getDocumentCategories: () => void
  addDocument: (companyID: string, document: DocumentCreationFields) => Promise<Document | void>
  updateDocument: (companyID: string, document: DocumentMutableFields) => Promise<Document | void>
  addSignerToDocument: (document: Document, email: string, name: string, language: Language) => Promise<Document | void>
  deleteDocument: (documentID: string) => void
}

function Documents(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { documents, documentCategories, getDocuments, getDocumentCategories } = props

  useEffect(() => {
    if (!documents.loading && !documents.loaded) {
      getDocuments()
    }
    if (!documentCategories.loading && !documentCategories.loaded) {
      getDocumentCategories()
    }
  }, [documents, getDocuments, documentCategories, getDocumentCategories])

  const canSeeSalaryRates = hasDepartmentPermission(props.companyUsers.companyUser, undefined, 'SeeSalaryRates')

  const company = props.companies.company
  if (!company || !canSeeSalaryRates) {
    jsBrowserHistory.push('/')
    return null
  }

  if (!documents.loaded || !documentCategories.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            { loading: !documents.loaded, text: 'dokumenter' },
            { loading: !documentCategories.loaded, text: 'dokumentkategorier' },
          ])}
        />
      </div>
    )
  }

  return (
    <AdministrationLayout route={props.route}>
      <DocumentComponent
        subsection={splatString(props.params.splat)}
        alerts={props.alerts}
        company={company}
        companyFeatures={props.companyFeatures.companyFeatures}
        companyUser={props.companyUsers.companyUser}
        employees={props.employees.employees}
        documents={documents}
        documentCategories={documentCategories.documentCategories}
        companyPricings={props.companyPricings.companyPricings}
        addAlert={props.addAlert}
        addDocument={props.addDocument}
        updateDocument={props.updateDocument}
        addSignerToDocument={props.addSignerToDocument}
        deleteDocument={props.deleteDocument}
      />
    </AdministrationLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    companyFeatures: state.companyFeatures,
    companyUsers: state.companyUsers,
    companyPricings: state.companyPricings,
    employees: state.employees,
    documents: state.documents,
    documentCategories: state.documentCategories,
  }),
  {
    addAlert: addAlert,
    getDocuments: getDocuments,
    getDocumentCategories: getDocumentCategories,
    addDocument: addDocument,
    updateDocument: updateDocument,
    addSignerToDocument: addSignerToDocument,
    deleteDocument: deleteDocument,
  }
)(Documents)
