import { startOfMonth, subMonths } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Bank from '../../model/bank'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import CompanyUser from '../../model/companyUser'
import Department, { DepartmentCreationFields } from '../../model/department'
import { DocumentMutableFields } from '../../model/document'
import Employee, { AffiliationType, EmployeeCreation, NationalIDType, OnboardingState } from '../../model/employee'
import { TransferDestinationType } from '../../model/transfer'
import { DateFormat } from '../../model/types'
import { AlertReducer } from '../../reducers/alerts'
import { ContractBookContractReducer } from '../../reducers/contractBookContracts'
import { ContractTemplateReducer } from '../../reducers/contractTemplates'
import { DepartmentReducer } from '../../reducers/departments'
import { DocumentReducer } from '../../reducers/documents'
import { EmployeeReducer } from '../../reducers/employees'
import { UserReducer } from '../../reducers/user'
import { paths } from '../../routes'
import CountryCode from '../../types/country-code'
import Gender from '../../types/gender'
import IncomeType from '../../types/income-type'
import Language from '../../types/language'
import PreferredTaxCardType from '../../types/preferred-tax-card-type'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatAPIDate, getDate } from '../../utils/date-utils'
import { findDepartmentID } from '../../utils/department-utils'
import { formatError } from '../../utils/error-utils'
import { hasPaySlipTransportDefault } from '../../utils/pay-slip-utils'
import { t, tx } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Blocktitle from '../elements/Blocktitle'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Steps from '../elements/steps'
import Subtitle from '../elements/Subtitle'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import BaseDataForm, { BaseDataResults } from './BaseDataForm'
import CommunicationForm, { CommunicationFields } from './CommunicationForm'
import FinancialDataForm, { FinancialDataResult } from './FinancialDataForm'
import InviteForm, { InviteResult } from './InviteForm'

import './EmployeesAdd.css'

type Props = {
  contractBookContractID?: string
  contractTemplateID?: string
  documentID?: string // document to attach, once the employee has been created
  isInvite: boolean
  alerts: AlertReducer
  company: Company
  companyFeatures: List<CompanyFeature>
  contractTemplates: ContractTemplateReducer
  documents: DocumentReducer
  employees: EmployeeReducer
  departments: DepartmentReducer
  companyUser?: CompanyUser
  user: UserReducer
  banks: List<Bank>
  contractBookContracts: ContractBookContractReducer
  hasPremiumPackage: boolean

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
  addEmployee: (employee: EmployeeCreation) => Promise<Employee | void>
  updateDocument: (companyID: string, document: DocumentMutableFields) => void
}

type State = {
  employeeNumber: string
  nationalIDType: NationalIDType
  nationalID?: string
  name?: string
  address?: string
  postalCode?: string
  city?: string
  country?: CountryCode
  tin?: string
  gender?: Gender
  birthDate?: DateFormat
  email?: string
  phoneNumber?: string
  phoneNumberCountryCode: string
  contractTemplateID?: string
  employmentStartDate: DateFormat
  language: Language
  paySlipTransportEMail: boolean
  paySlipTransportMitDK: boolean
  paySlipTransportEBoks: boolean
  paySlipTransportSMS: boolean
  preferredTaxCardType: PreferredTaxCardType
  transferDestinationType: TransferDestinationType
  bankRegistrationNumber?: string
  bankAccountNumber?: string
  affiliationType: AffiliationType
  departmentID?: string
  sendLogin: boolean
  onboardingState: OnboardingState
  incomeType: IncomeType
  contractBookContractID?: string
}

export default function EmployeesAdd(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [current, setCurrent] = useState(0)
  const [newEmployeeID, setNewEmployeeID] = useState<string>()

  const getDepartmentIDs = () => {
    const companyUser = props.companyUser
    if (!companyUser) {
      return []
    }
    if (companyUser.departments.length === 0) {
      return []
    }
    return companyUser.departments.map((permission) => permission.departmentID)
  }

  const [state, setState] = useState<State>(() => {
    const state: State = {
      employeeNumber: props.company.nextAvailableEmployeeNumber.toString(),
      nationalIDType: 'DK CPR',
      nationalID: undefined,
      name: undefined,
      address: undefined,
      postalCode: undefined,
      city: undefined,
      country: undefined,
      gender: undefined,
      birthDate: undefined,
      tin: undefined,
      email: undefined,
      phoneNumber: undefined,
      phoneNumberCountryCode: '45',
      contractTemplateID: undefined,
      employmentStartDate: formatAPIDate(startOfMonth(subMonths(getDate(), 1))),
      language: Language.DANISH,
      paySlipTransportEMail: hasPaySlipTransportDefault(
        'EMail',
        props.company.settingsEnabled,
        props.companyFeatures.toArray()
      ),
      paySlipTransportMitDK: hasPaySlipTransportDefault(
        'MitDK',
        props.company.settingsEnabled,
        props.companyFeatures.toArray()
      ),
      paySlipTransportEBoks: hasPaySlipTransportDefault(
        'EBoks',
        props.company.settingsEnabled,
        props.companyFeatures.toArray()
      ),
      paySlipTransportSMS: hasPaySlipTransportDefault(
        'SMS',
        props.company.settingsEnabled,
        props.companyFeatures.toArray()
      ),
      preferredTaxCardType: PreferredTaxCardType.MAIN_CARD,
      transferDestinationType: 'DK Account',
      bankRegistrationNumber: undefined,
      bankAccountNumber: undefined,
      affiliationType: 'Standard',
      departmentID: undefined,
      sendLogin: false,
      onboardingState: 'Final',
      incomeType: IncomeType.DK_SALARY_INCOME,
      contractBookContractID: undefined,
    }
    if (props.isInvite) {
      state.sendLogin = true
      state.onboardingState = 'Draft'
    }
    if (props.contractBookContractID) {
      const contract = props.contractBookContracts.contracts.find((v) => v.contractID === props.contractBookContractID)
      if (contract) {
        if (contract.employeeNumber) {
          state.employeeNumber = contract.employeeNumber
        }
        state.nationalID = contract.nationalID
        state.name = contract.signeeName
        state.address = contract.address
        state.postalCode = contract.postalCode
        state.city = contract.city
        state.email = contract.email
        state.phoneNumber = contract.phoneNumber
        if (contract.phoneNumberCountryCode) {
          state.phoneNumberCountryCode = contract.phoneNumberCountryCode
        }
        if (contract.employmentStartDate) {
          state.employmentStartDate = contract.employmentStartDate
        }
        if (contract.language) {
          state.language = contract.language
        }
        state.bankRegistrationNumber = contract.bankRegistrationNumber
        state.bankAccountNumber = contract.bankAccountNumber
        if (contract.affiliationType) {
          state.affiliationType = contract.affiliationType
        }
        if (contract.incomeType) {
          state.incomeType = contract.incomeType
        }
        state.contractBookContractID = contract.contractID
      }
    }
    if (props.contractTemplateID) {
      state.contractTemplateID = props.contractTemplateID
    }
    const departmentIDs = getDepartmentIDs()
    if (departmentIDs.length > 0) {
      state.departmentID = departmentIDs[0] // just pick the first one
    }
    return state
  })

  const { employees, contractBookContractID, departments, documents } = props
  const previousEmployees = usePrevious(employees)

  useEffect(() => {
    // Check for save callback
    if (previousEmployees && previousEmployees.saving && !employees.saving) {
      // Check for no error occurred
      if (!employees.error) {
        // Find newly created employee
        const employeeList = employees.employees.filter((employee) => {
          return employee.nationalID === state.nationalID || employee.id === newEmployeeID
        })
        const employee = employeeList.first()
        if (employeeList.size === 1 && employee) {
          // Redirect to add contract flow
          let path = '/' + paths.EMPLOYEES + '/' + employee.id + '/' + paths.CONTRACTS + '/' + paths.ADD
          if (contractBookContractID) {
            path += '?contractBookContractID=' + contractBookContractID
          }
          if (state.contractTemplateID) {
            path += '?contractTemplateID=' + state.contractTemplateID
          }
          jsBrowserHistory.push(path)
        } else {
          // Something weird happened, redirect to employees page
          jsBrowserHistory.push('/' + paths.EMPLOYEES)
        }
      }
    }

    regularComponentDidUpdate(employees.error || departments.error || documents.error, error, setError)
  }, [employees, previousEmployees, contractBookContractID, departments, documents, error, newEmployeeID, state])

  const canUseNemKonto = () => {
    return props.company.enableNemKontoTransfers && state.nationalIDType !== 'DK No CPR'
  }

  const _handlePrevious = (values: CommunicationFields | FinancialDataResult, steps = 1) => {
    setState((prev) => ({ ...prev, ...values }))
    setCurrent((prev) => prev - steps)
  }
  const _handleNext = (values: BaseDataResults | CommunicationFields, steps = 1) => {
    // otherwise use our default progress
    setState((state) => {
      if (values.step === 'BaseData') {
        if (values.contractTemplateID) {
          // fill in the fields from the selected template
          const contractTemplate = props.contractTemplates.contractTemplates.find(
            (template) => template.id === values.contractTemplateID
          )
          if (contractTemplate) {
            state.affiliationType = contractTemplate.affiliationType ? contractTemplate.affiliationType : 'Standard'
            state.paySlipTransportSMS = values.phoneNumber ? contractTemplate.paySlipTransportSMS : false
            state.paySlipTransportMitDK = contractTemplate.paySlipTransportMitDK
            state.paySlipTransportEBoks = contractTemplate.paySlipTransportEBoks
            state.paySlipTransportEMail = values.email ? contractTemplate.paySlipTransportEMail : false
            state.language = contractTemplate.language

            state.preferredTaxCardType = contractTemplate.preferredTaxCardType
            state.incomeType = contractTemplate.incomeType
            state.transferDestinationType = contractTemplate.transferDestinationType
          }
        }
        if (!values.email) {
          state.paySlipTransportEMail = false
        }
        if (!values.phoneNumber) {
          state.paySlipTransportSMS = false
        }
      }
      return { ...state, ...values }
    })
    setCurrent((prev) => prev + steps)
  }
  const _handleSubmit = (values: InviteResult | FinancialDataResult) => {
    setState((prev) => ({ ...prev, ...values }))
    const combinedState = { ...state, ...values }
    const employee: EmployeeCreation = {
      ...{ paySlipTransports: [] },
      ...combinedState,
      nationalID: combinedState.nationalID || '',
      employeeNumber: combinedState.employeeNumber || '',
      departmentID: findDepartmentID(props.departments.departments.toArray(), combinedState.departmentID),
      name: combinedState.name || '',
      email: combinedState.email || '',
      address: combinedState.address || '',
      postalCode: combinedState.postalCode || '',
      city: combinedState.city || '',
    }
    const promise = new Promise<string | void>((resolve, reject) => {
      if (employee.departmentID && !props.departments.departments.some((item) => item.id === employee.departmentID)) {
        props
          .addDepartment({ name: employee.departmentID })
          .then((data) => {
            if (!data) {
              return
            }
            resolve(data.id)
          })
          .catch(reject)
      } else {
        resolve()
      }
    })
    promise.then((departmentID) => {
      if (departmentID) {
        employee.departmentID = departmentID
      }
      props.addEmployee(employee).then((res) => {
        if (res) {
          if (props.documentID) {
            const document = props.documents.documents.find((document) => document.id === props.documentID)
            if (document) {
              props.updateDocument(props.company.id, { ...document, employeeID: res.id })
            }
          }
          setNewEmployeeID(res.id)
        }
      })
    })
  }

  const saving = props.employees.saving || props.departments.saving
  let contractBookContract = null
  if (props.contractBookContractID) {
    contractBookContract = props.contractBookContracts.contracts.find(
      (v) => v.contractID === props.contractBookContractID
    )
    if (contractBookContract) {
      contractBookContract.fields.sort((a, b) => a.key.localeCompare(b.key, 'da-dk'))
    }
  }

  return (
    <div className="employees-add">
      <Row>
        {props.isInvite && <Col span={4}>&nbsp;</Col>}
        <Col span={16}>
          <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
          {saving && (
            <div style={{ position: 'relative', minHeight: '400px' }}>
              <LoadingOverlay />
            </div>
          )}
          {!saving && (
            <div className="employees-add-overview">
              {error && <Alert message={formatError(error)} type="error" showIcon />}
              {props.isInvite && (
                <div>
                  <Card>
                    <InviteForm
                      employees={props.employees.employees}
                      departments={props.departments.departments}
                      {...state}
                      onSubmit={_handleSubmit}
                    />
                  </Card>
                </div>
              )}
              {!props.isInvite && (
                <div>
                  {current === 0 && (
                    <Card>
                      <Blocktitle>{t('employee.add.step_0.title')}</Blocktitle>
                      <BaseDataForm
                        user={props.user}
                        company={props.company}
                        employees={props.employees}
                        departments={props.departments.departments}
                        departmentIDs={getDepartmentIDs()}
                        contractTemplates={props.contractTemplates.contractTemplates}
                        {...state}
                        onSubmit={_handleNext}
                      />
                    </Card>
                  )}
                  {current === 1 && (
                    <Card>
                      <Blocktitle>{t('employee.add.step_1.title')}</Blocktitle>
                      <CommunicationForm
                        {...state}
                        hasPremiumPackage={props.hasPremiumPackage}
                        onBack={_handlePrevious}
                        onSubmit={_handleNext}
                      />
                    </Card>
                  )}
                  {current === 2 && (
                    <Card>
                      <Blocktitle>{t('employee.add.step_2.title')}</Blocktitle>
                      <FinancialDataForm
                        company={props.company}
                        banks={props.banks}
                        canUseNemKonto={canUseNemKonto()}
                        {...state}
                        onBack={_handlePrevious}
                        onSubmit={_handleSubmit}
                      />
                    </Card>
                  )}
                </div>
              )}
            </div>
          )}
        </Col>
        {!props.isInvite && (
          <Col span={7}>
            <Card>
              <Subtitle>{t('employee.add.steps.title')}</Subtitle>
              <p>{t('employee.add.steps.intro')}</p>

              <Steps direction="vertical" current={current}>
                <Steps.Step title={t('employee.add.steps.0')} />
                <Steps.Step title={t('employee.add.steps.1')} />
                <Steps.Step title={t('employee.add.steps.2')} />
              </Steps>
            </Card>
            {contractBookContract && (
              <Card>
                <Subtitle>{t('employee.add.steps.contract_book.title')}</Subtitle>
                <p>{t('employee.add.steps.contract_book.intro')}</p>
                <ul>
                  {contractBookContract.fields.map((field) => (
                    <li key={field.key}>
                      {field.key}
                      <p>{field.value || t('employee.add.steps.contract_book.missing')}</p>
                    </li>
                  ))}
                </ul>
              </Card>
            )}
            <small style={{ textAlign: 'center' }}>
              {tx('employee.add.steps.support_note', {
                email: <a href="mailto:support@salary.dk">support@salary.dk</a>,
              })}
            </small>
          </Col>
        )}
      </Row>
    </div>
  )
}
