import { UserType } from '../model/user'

type withUserType = {
  userType?: UserType
}

/**
 * Determines whether the user is an admin or a support user
 * @param user Can be UserReducer or User
 */
export function isUserSupport(user?: withUserType): boolean {
  const userType = user?.userType
  return !!userType && (userType === 'Admin' || userType === 'Support')
}
