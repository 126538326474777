import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import CoarseCarAllowance, {
  CoarseCarAllowanceCreationFields,
  CoarseCarAllowanceMutableFields,
} from '../../model/coarseCarAllowance'
import CompanyUser from '../../model/companyUser'
import Department from '../../model/department'
import Employee from '../../model/employee'
import SalaryCycle from '../../model/salaryCycle'
import { AlertReducer } from '../../reducers/alerts'
import { CarAllowanceReducer } from '../../reducers/carAllowances'
import { CoarseCarAllowanceReducer } from '../../reducers/coarseCarAllowances'
import { paths } from '../../routes'
import { formatNumber } from '../../utils/number-utils'
import { hasDepartmentPermission } from '../../utils/permissions-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Table from '../antd/table'
import ContextMenu from '../elements/ContextMenu'
import EmployeeFilter, { FilterContainer, filterEmployee } from '../elements/EmployeeFilter'
import Headline from '../elements/Headline'
import Icon from '../elements/Icon'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import UserImage from '../elements/UserImage'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import CoarseCarAllowanceModal from './CoarseCarAllowanceModal'

import './CarAllowance.css'

type Props = {
  alerts: AlertReducer
  companyUser?: CompanyUser
  employees: List<Employee>
  departments: List<Department>
  coarseCarAllowances: CoarseCarAllowanceReducer
  carAllowances: CarAllowanceReducer
  salaryCycles: List<SalaryCycle>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  createCoarseCarAllowance: (carAllowance: CoarseCarAllowanceCreationFields) => void
  updateCoarseCarAllowance: (carAllowance: CoarseCarAllowanceMutableFields) => void
  deleteCoarseCarAllowance: (coarseCarAllowance: CoarseCarAllowance) => void
  approveCarAllowances: (ids: string[]) => void
}

export default function CarAllowance(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showCoarse, setShowCoarse] = useState<string | null>(null)
  const [filter, setFilter] = useState<FilterContainer>({ searchQuery: '' })

  const setCoarseVisibility = (showCoarse: string | null) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setShowCoarse(showCoarse)
  }

  const { coarseCarAllowances } = props
  const previousCoarseCarAllowances = usePrevious(coarseCarAllowances)

  useEffect(() => {
    // Check for save callback
    if (previousCoarseCarAllowances && previousCoarseCarAllowances.saving && !coarseCarAllowances.saving) {
      // Check for no error occurred
      if (!coarseCarAllowances.error) {
        // Close import modal
        setCoarseVisibility(null)
      }
    }
  })

  const approveAll = (employeeID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        const allowances = props.carAllowances.carAllowances
          .filter((reg) => reg.employeeID === employeeID && !reg.approved)
          .toArray()
        props.approveCarAllowances(allowances.map((allowance) => allowance.id))
      }
    }
  }

  type EmployeeRow = {
    key: string
    id: string
    type: string
    profileImageURL?: string
    name: string
    position?: string
    distance: number
    hasUnapproved: boolean
    canApproveObjects: boolean
    canEditObjects: boolean
  }

  const columns = [
    {
      title: t('car_allowances.table.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      render: (employee: EmployeeRow) => {
        return (
          <Headline>
            <UserImage src={employee.profileImageURL} name={employee.name} />
            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id}>{employee.name}</Link>
            <small>{employee.position ? employee.position : '-'}</small>
          </Headline>
        )
      },
    },
    {
      title: t('car_allowances.table.header.distance'),
      dataIndex: '',
      key: 'xDistance',
      width: 140,
      render: (employee: EmployeeRow) => t('car_allowance.trip_length', { amount: formatNumber(employee.distance, 2) }),
    },
    {
      title: '',
      dataIndex: '',
      key: 'xHasUnapproved',
      width: 210,
      render: (employee: EmployeeRow) => {
        if (employee.type === 'Coarse' || employee.distance === 0) {
          return ''
        }
        if (!employee.canApproveObjects) {
          return ''
        }
        if (props.carAllowances.saving) {
          return <LoadingOverlay />
        }
        if (employee.hasUnapproved) {
          return <DumbLink onClick={approveAll(employee.id)}>{t('car_allowances.table.approve_all')}</DumbLink>
        }
        return t('car_allowances.table.all_approved')
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (employee: EmployeeRow) => {
        if (!employee.canEditObjects) {
          return ''
        }
        if (employee.type === 'Coarse') {
          return (
            <ContextMenu>
              <DumbLink
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault()
                  setCoarseVisibility(employee.id)
                }}
              >
                <Icon type="user" color="grey" /> {t('car_allowances.table.actions.register')}
              </DumbLink>
              <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/car-allowance'}>
                <Icon type="edit" color="grey" /> {t('car_allowances.table.actions.edit')}
              </Link>
            </ContextMenu>
          )
        }
        return (
          <Tooltip placement="top" title={t('car_allowances.table.actions.edit')}>
            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/car-allowance'}>
              <Icon type="edit" color="lightgrey" />
            </Link>
          </Tooltip>
        )
      },
    },
  ]

  const getSimpleEmployees = (): EmployeeRow[] => {
    return props.employees
      .filter(
        (employee) =>
          filterEmployee(employee, filter) &&
          employee.affiliationType !== 'Freelancer' &&
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          employee.activeContract?.carAllowanceRegistrationMethodType === 'Coarse'
      )
      .map((employee) => {
        const carAllowances = props.coarseCarAllowances.coarseCarAllowances.filter(
          (reg) => reg.employeeID === employee.id
        )
        return {
          key: employee.id,
          id: employee.id,
          type: employee.activeContract?.carAllowanceRegistrationMethodType || 'Coarse',
          profileImageURL: employee.profileImageURL,
          name: employee.name || employee.email || '-',
          position: employee.activeContract ? employee.activeContract.position : undefined,
          distance: carAllowances.reduce((distance, allowance) => {
            return distance + allowance.kilometers
          }, 0),
          hasUnapproved: false,
          canApproveObjects: hasDepartmentPermission(props.companyUser, employee.departmentID, 'ApproveObjects'),
          canEditObjects: hasDepartmentPermission(props.companyUser, employee.departmentID, 'EditObjects'),
        }
      })
      .toArray()
  }

  const getDetailedEmployees = (): EmployeeRow[] => {
    return props.employees
      .filter(
        (employee) =>
          filterEmployee(employee, filter) &&
          employee.affiliationType !== 'Freelancer' &&
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          employee.activeContract?.carAllowanceRegistrationMethodType === 'Detailed'
      )
      .map((employee) => {
        const carAllowances = props.carAllowances.carAllowances.filter(
          (reg) => reg.employeeID === employee.id && !reg.settled
        )
        return {
          key: employee.id,
          id: employee.id,
          type: employee.activeContract?.carAllowanceRegistrationMethodType || 'Detailed',
          profileImageURL: employee.profileImageURL,
          name: employee.name || employee.email || '-',
          position: employee.activeContract ? employee.activeContract.position : undefined,
          distance: carAllowances.reduce((distance, allowance) => distance + allowance.kilometers, 0),
          hasUnapproved: carAllowances.some((allowance) => !allowance.approved),
          canApproveObjects: hasDepartmentPermission(props.companyUser, employee.departmentID, 'ApproveObjects'),
          canEditObjects: hasDepartmentPermission(props.companyUser, employee.departmentID, 'EditObjects'),
        }
      })
      .toArray()
  }

  const simpleEmployees = getSimpleEmployees()
  const detailedEmployees = getDetailedEmployees()

  return (
    <div className="car-allowance">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <TitleMenu>
        <EmployeeFilter
          departments={props.departments}
          companyUser={props.companyUser}
          onFilterChange={(filter) => setFilter(filter)}
        />
      </TitleMenu>
      <Title>{t('car_allowances.coarse.title')}</Title>
      <Table
        columns={columns}
        dataSource={simpleEmployees}
        pagination={
          simpleEmployees.length > 100
            ? {
                defaultPageSize: 100,
              }
            : false
        }
        onRowClick={(employee: EmployeeRow) => {
          setCoarseVisibility(employee.id)
        }}
      />

      <Title>{t('car_allowances.detailed.title')}</Title>
      <Table
        columns={columns}
        dataSource={detailedEmployees}
        pagination={
          detailedEmployees.length > 100
            ? {
                defaultPageSize: 100,
              }
            : false
        }
        onRowClick={(employee: EmployeeRow) => {
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id + '/car-allowance')
        }}
      />

      <Modal
        key={modalKey * -1}
        visible={!!showCoarse}
        onOk={() => setCoarseVisibility(null)}
        onCancel={() => setCoarseVisibility(null)}
        width={582}
        footer={null}
      >
        <CoarseCarAllowanceModal
          visible={!!showCoarse}
          employee={props.employees.find((employee) => employee.id === showCoarse)}
          coarseCarAllowances={props.coarseCarAllowances}
          salaryCycles={props.salaryCycles}
          addAlert={props.addAlert}
          createCoarseCarAllowance={props.createCoarseCarAllowance}
          updateCoarseCarAllowance={props.updateCoarseCarAllowance}
          deleteCoarseCarAllowance={props.deleteCoarseCarAllowance}
        />
      </Modal>
    </div>
  )
}
