import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import Company from '../../model/company'
import Department from '../../model/department'
import Employee from '../../model/employee'
import { ProjectCreationFields, ProjectMutableFields } from '../../model/project'
import { ProjectReducer } from '../../reducers/projects'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { formatDate } from '../../utils/date-utils'
import { formatCurrency } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Button from '../elements/button'
import CustomTable from '../elements/custom-table'
import Icon from '../elements/Icon'
import Subtitle from '../elements/Subtitle'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import DumbLink from '../widgets/DumbLink'
import ProjectEdit from './ProjectEdit'

import './Projects.css'

type Props = {
  company: Company
  employees: List<Employee>
  departments: List<Department>
  timeRegistrations: TimeRegistrationReducer
  projects: ProjectReducer

  createProject: (project: ProjectCreationFields) => void
  updateProject: (project: ProjectMutableFields) => void
  deleteProject: (id: string) => void
}

export default function Projects(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)
  const [parentID, setParentID] = useState<string>()

  const setEditProjectVisibility = (id: string | boolean, parentID?: string) => {
    setModalKey((prev) => prev + 1)
    setEditing(id)
    setParentID(parentID)
  }

  const handleDelete = (id: string) => {
    if (window.confirm(t('common.are_you_sure'))) {
      props.deleteProject(id)
    }
  }

  const { projects } = props
  const previousProjects = usePrevious(projects)
  useEffect(() => {
    if (previousProjects && previousProjects.saving && !projects.saving) {
      if (!projects.error) {
        setEditProjectVisibility(false)
      }
    }
  }, [previousProjects, projects])

  return (
    <div>
      <TitleMenu>
        <DumbLink onClick={() => setEditProjectVisibility(true)}>
          <Button type="secondary">{t('projects.header.new_project')}</Button>
        </DumbLink>
      </TitleMenu>
      <Title>{t('projects.header.title')}</Title>

      <CustomTable columns={3}>
        <CustomTable.Header>
          <CustomTable.TH>{t('projects.table.header.project')}</CustomTable.TH>
          <CustomTable.TH>&nbsp;</CustomTable.TH>
          <CustomTable.TH>{t('projects.table.header.employees')}</CustomTable.TH>
          <CustomTable.TH>&nbsp;</CustomTable.TH>
        </CustomTable.Header>
        <CustomTable.Body>
          {props.projects.projects.map((type) => {
            const hasChildren = props.projects.projects.some((t) => t.parentID === type.id)
            const registrations = props.timeRegistrations.timeRegistrations.filter((reg) => reg.projectID === type.id)
            return (
              <CustomTable.Row key={type.id} className={type.parentID ? 'sub-project' : 'main-project'}>
                <CustomTable.TD>
                  <Subtitle>{type.name}</Subtitle>
                  <small>{type.description}</small>
                </CustomTable.TD>
                <CustomTable.TD>
                  {type.externalID && (
                    <>
                      {t('projects.table.external_id')}: {type.externalID}
                    </>
                  )}
                  {type.externalID && type.hourlyRate && <br />}
                  {type.hourlyRate && (
                    <>
                      {t('projects.table.hourly_rate')}: {formatCurrency(type.hourlyRate)}
                    </>
                  )}
                  {type.hourlyRate && (type.validFrom || type.validTo) && <br />}
                  {(type.validFrom || type.validTo) && (
                    <>
                      {type.validFrom && formatDate(type.validFrom)} &mdash; {type.validTo && formatDate(type.validTo)}
                    </>
                  )}
                </CustomTable.TD>
                <CustomTable.TD>
                  {props.employees
                    .filter((employee) => type.employeeIDs.some((id) => id === employee.id))
                    .map((employee) => employee.name || employee.email || '-')
                    .sort((a, b) => a.localeCompare(b))
                    .join(', ')}
                </CustomTable.TD>
                <CustomTable.TD>
                  <Tooltip title={t('projects.table.actions.edit')}>
                    <span
                      onClick={() => setEditProjectVisibility(type.id, type.parentID)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon type="edit" color="grey" />
                    </span>
                  </Tooltip>
                  {!type.parentID && (
                    <Tooltip title={t('projects.table.actions.add_child')}>
                      <span onClick={() => setEditProjectVisibility(true, type.id)} style={{ cursor: 'pointer' }}>
                        <Icon type="add" color="grey" />
                      </span>
                    </Tooltip>
                  )}
                  {registrations.size === 0 && !hasChildren && (
                    <Tooltip title={t('projects.table.actions.delete')}>
                      <span onClick={() => handleDelete(type.id)} style={{ cursor: 'pointer' }}>
                        <Icon type="cross" color="grey" />
                      </span>
                    </Tooltip>
                  )}
                </CustomTable.TD>
              </CustomTable.Row>
            )
          })}
          {props.projects.projects.size === 0 && (
            <CustomTable.Row>
              <CustomTable.TD span={4} className="ant-table-placeholder">
                {t('projects.table.empty')}
              </CustomTable.TD>
            </CustomTable.Row>
          )}
        </CustomTable.Body>
      </CustomTable>

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditProjectVisibility(false)}
        onCancel={() => setEditProjectVisibility(false)}
        width={582}
        footer={null}
      >
        <ProjectEdit
          projectID={typeof editing === 'string' ? editing : undefined}
          projectParentID={parentID}
          visible={editing !== false}
          projects={props.projects}
          company={props.company}
          employees={props.employees}
          departments={props.departments}
          createProject={props.createProject}
          updateProject={props.updateProject}
        />
      </Modal>
    </div>
  )
}
