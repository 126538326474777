import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CostCenter from '../model/costCenter'
import { ReducerAction } from '../utils/reducer-utils'

export interface CostCenterReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  costCenters: List<CostCenter>
  error: Error | null
}

const Params = Record<CostCenterReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  costCenters: List<CostCenter>(),
  error: null,
})

export interface CostCenterAction extends ReducerAction {
  companyID?: string
  costCenters?: CostCenter[]
  costCenter?: CostCenter
  costCenterID?: string
}

const comparator = (a: CostCenter, b: CostCenter) => {
  return a.name.localeCompare(b.name, 'da-dk')
}

export default (
  state: Record<CostCenterReducer> = Params(),
  action: CostCenterAction = { type: '' }
): Record<CostCenterReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if ('companyID' in action && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.COSTCENTERS_LOADING:
      return state.set('loading', true).set('loaded', false).set('companyID', state.get('companyID'))
    case ActionTypes.COSTCENTERS_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('companyID', state.get('companyID'))
        .set('costCenters', List<CostCenter>(action.costCenters).sort(comparator))
        .set('error', null)
    case ActionTypes.COSTCENTERS_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', state.get('companyID'))
        .set('error', action.error || null)

    case ActionTypes.COSTCENTER_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COSTCENTER_ADDED:
      if (!action.costCenter) {
        return state
      }
      idx = state.get('costCenters').findIndex((item) => !!action.costCenter && item.id === action.costCenter.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('costCenters', state.get('costCenters').set(idx, action.costCenter).sort(comparator))
      }
      return state
        .set('saving', false)
        .set('costCenters', state.get('costCenters').push(action.costCenter).sort(comparator))
    case ActionTypes.COSTCENTER_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COSTCENTER_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COSTCENTER_UPDATED:
      if (!action.costCenter) {
        return state
      }
      idx = state.get('costCenters').findIndex((item) => item.id === action.costCenterID)
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('costCenters', state.get('costCenters').set(idx, action.costCenter).sort(comparator))
    case ActionTypes.COSTCENTER_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COSTCENTER_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COSTCENTER_DELETED:
      if ('costCenterID' in action) {
        idx = state.get('costCenters').findIndex((item) => {
          return item.id === action.costCenterID
        })
      }
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('costCenters', state.get('costCenters').delete(idx))
    case ActionTypes.COSTCENTER_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
