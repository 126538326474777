import React, { CSSProperties, ReactElement, ReactNode } from 'react'

import CompanySetting from '../../model/companySetting'
import { CompanyReducer } from '../../reducers/companies'
import { connectToReducer } from '../../utils/reducer-utils'
import Tooltip from '../elements/tooltip'

// NEED_NO_TRANSLATION

type Reducers = {
  companies: CompanyReducer
}

type Props = {
  setting: CompanySetting
  hasSetting?: boolean
  style?: CSSProperties
  description: string
  children: ReactNode
}

function SettingsLock(props: Reducers & Props): ReactElement | null {
  const { hasSetting = false } = props

  const checkHasSetting = () => {
    if (hasSetting) {
      return true
    }
    return props.companies.company?.settingsEnabled.some((setting) => setting === props.setting) || false
  }

  if (!checkHasSetting()) {
    return (
      <Tooltip title={props.description}>
        <div className={'lock setting-lock gtm-advance-settings'} style={props.style}>
          {props.children}
        </div>
      </Tooltip>
    )
  }
  return <>{props.children}</>
}

export default connectToReducer<Reducers, any, Props>(
  (state) => ({
    companies: state.companies,
  }),
  {}
)(SettingsLock)
