import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import { ReducerAction } from '../utils/reducer-utils'

export interface BootstrapReducer {
  loading: boolean
  loaded: boolean
  error: Error | null
}

const Params = Record<BootstrapReducer>({
  loading: false,
  loaded: false,
  error: null,
})

export default (
  state: Record<BootstrapReducer> = Params(),
  action: ReducerAction = { type: '' }
): Record<BootstrapReducer> => {
  switch (action.type) {
    case ActionTypes.BOOTSTRAP_LOADING:
      return Params({
        loading: true,
      })
    case ActionTypes.BOOTSTRAP_LOADED:
      return Params({
        loaded: true,
      })
    case ActionTypes.BOOTSTRAP_LOAD_FAILED:
      return Params({
        error: action.error,
      })
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
