import React, { ReactElement, useEffect, useState } from 'react'

import CompanyGroup, { CompanyGroupUser } from '../../../model/companyGroup'
import { UserPermission } from '../../../model/companyUser'
import UserInvite from '../../../model/userInvite'
import { UserReducer } from '../../../reducers/user'
import { UserInviteCompanyGroupReducer } from '../../../reducers/userInviteCompanyGroups'
import CompanyGroupUserType from '../../../types/company-group-user-type'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Icon from '../../elements/Icon'
import Subtitle from '../../elements/Subtitle'
import CompanyGroupUserAddForm, { CompanyGroupUserAddResult } from './CompanyGroupUserAddForm'

type Props = {
  companyGroup: CompanyGroup
  userInviteCompanyGroups: UserInviteCompanyGroupReducer
  user: UserReducer
  companyGroupUser?: CompanyGroupUser

  closeModal: () => void
  addCompanyGroupUserInvite: (
    companyGroupID: string,
    email: string,
    groupUserType: CompanyGroupUserType,
    permissions: UserPermission[]
  ) => Promise<UserInvite | void>
}

export default function CompanyGroupUserAdd(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { userInviteCompanyGroups } = props
  useEffect(() => {
    regularComponentDidUpdate(userInviteCompanyGroups.error, error, setError)
  }, [userInviteCompanyGroups, error])

  const handleSubmit = (values: CompanyGroupUserAddResult) => {
    const permissions: UserPermission[] = []
    if (values.permissionAdmin) {
      permissions.push('Admin')
    } else {
      permissions.push('Manager') // manager if not admin
    }
    if (values.permissionApprovePayRoll) {
      permissions.push('ApprovePayRoll')
    }
    if (values.permissionReviewPayRoll) {
      permissions.push('ReviewPayRoll')
    }
    props
      .addCompanyGroupUserInvite(props.companyGroup.id, values.email, values.groupUserType, permissions)
      .then(() => props.closeModal())
  }

  return (
    <Card className="company-group-users-add">
      <Subtitle>
        <Icon type="user-add" color="black" />
        {t('companies.group.users.add.title')}
      </Subtitle>
      <p>{t('companies.group.users.add.intro')}</p>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <CompanyGroupUserAddForm
        companyGroup={props.companyGroup}
        userInviteCompanyGroups={props.userInviteCompanyGroups}
        user={props.user}
        companyGroupUser={props.companyGroupUser}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
