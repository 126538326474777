import { logWarning } from './log-utils'
import { t } from './translation-utils'

export function formatStripeErrorCode(stripeCode: string) {
  switch (stripeCode) {
    case 'expired_card':
      return t('stripe.error_code.expired_card')
    case 'insufficient_funds':
      return t('stripe.error_code.insufficient_funds')
    case 'call_issuer':
      return t('stripe.error_code.call_issuer')
    case 'currency_not_supported':
      return t('stripe.error_code.currency_not_supported')
    case 'pickup_card':
      return t('stripe.error_code.pickup_card')
    case 'incorrect_number':
      return t('stripe.error_code.incorrect_number')
    default:
      logWarning('Encountered unknown Stripe code: `' + stripeCode + '`')
      return stripeCode
  }
}
