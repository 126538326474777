import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import CompanyUser from '../../model/companyUser'
import PayRoll from '../../model/payRoll'
import Shipment from '../../model/shipment'
import { formatDate, formatDateTime, trimCurrentYear } from '../../utils/date-utils'
import {
  formatPayRollStatus,
  formatSalaryFrequency,
  formatSalaryPrepaid,
  formatSourceType,
} from '../../utils/format-utils'
import { formatCurrency } from '../../utils/number-utils'
import { convertPayRollStatus } from '../../utils/pay-roll-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/Icon'
import Subtitle from '../elements/Subtitle'
import TaxReportReceiptModal from './TaxReportReceiptModal'

import './PayRollsSingle.css'

type Props = {
  payRoll: PayRoll
  shipments: List<Shipment>
  companyUsers: List<CompanyUser>

  setEditDispositionDateVisibility: (_s: boolean) => void
}

export default function DetailsCard(props: Props): ReactElement | null {
  const [showReceipt, setShowReceipt] = useState(false)

  const getStatusColor = () => {
    switch (convertPayRollStatus(props.payRoll)) {
      case 'Scheduled':
      case 'Awaiting':
      case 'Unknown':
        return 'var(--sally-grey)'
      case 'Approved':
      case 'Automatic Approval':
      case 'Success':
      case 'No Approval':
        return 'var(--sally-green)'
      case 'Cancelled':
      case 'Rejected':
      case 'Failed':
        return 'var(--sally-red)'
      default:
        return 'var(--sally-yellow)'
    }
  }

  const canChangeDispositionDate = (payRoll: PayRoll) => payRoll.canChangeDispositionDate

  const { payRoll } = props

  let payRollType: ReactElement | string = ''
  switch (payRoll.payRollType) {
    case 'TerminatedEmployments':
      payRollType = (
        <Row>
          <Col span={8}>{t('pay_roll.single.details.type')}:</Col>
          <Col span={16}>{t('pay_roll.single.details.type.terminated_employments')}</Col>
        </Row>
      )
      break
    case 'Negation':
      payRollType = (
        <Row>
          <Col span={8}>{t('pay_roll.single.details.type')}:</Col>
          <Col span={16}>{t('pay_roll.single.details.type.negation')}</Col>
        </Row>
      )
      break
    default:
      payRollType = (
        <Row>
          <Col span={8}>{t('pay_roll.single.details.type')}:</Col>
          <Col span={16}>
            {payRoll.isManual ? t('pay_roll.single.details.type.manual') : t('pay_roll.single.details.type.ordinary')}
          </Col>
        </Row>
      )
      break
  }

  const receipt = props.shipments
    .sort((a, b) => b.sendTime.localeCompare(a.sendTime))
    .find((shipment) => shipment.workflowID === payRoll.id && shipment.type === 'DK EINDKOMST')
  const hasReceipt =
    !!receipt &&
    receipt.replies.some((reply) => reply.type === 'DK EINDKOMST REPLY') &&
    convertPayRollStatus(props.payRoll) === 'Failed'
  const approvers = payRoll.approvers
    .filter((approver) => approver.type === 'Approve')
    .map((approver) => ({
      user: props.companyUsers.find((user) => user.userID === approver.userID),
      source: approver.source,
      createdAt: approver.createdAt,
    }))

  return (
    <div>
      <Card className="pay-roll-details">
        <Subtitle>{t('pay_roll.single.details.title')}</Subtitle>
        {payRollType}
        <Row>
          <Col span={8}>{t('pay_roll.single.details.status')}:</Col>
          <Col span={hasReceipt ? 8 : 16} style={{ color: getStatusColor() }}>
            {formatPayRollStatus(payRoll)}
          </Col>
          {hasReceipt && (
            <Col span={8}>
              <Button onClick={() => setShowReceipt(true)}>{t('pay_roll.single.details.status.see_receipt')}</Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={8}>{t('pay_roll.single.details.frequency')}:</Col>
          <Col span={16}>
            {formatSalaryFrequency(payRoll.salaryCycle.frequency)}{' '}
            <em>({formatSalaryPrepaid(payRoll.salaryCycle.prepaid).toLowerCase()})</em>
          </Col>
        </Row>
        <Row>
          <Col span={8}>{t('pay_roll.single.details.period')}:</Col>
          <Col span={16}>
            {trimCurrentYear(formatDate(payRoll.salaryPeriod.start))} <Icon type="arrow-long-right" color="blue" />{' '}
            {trimCurrentYear(formatDate(payRoll.salaryPeriod.end))}
          </Col>
        </Row>
        <Row>
          <Col span={8}>{t('pay_roll.single.details.disposition_date')}:</Col>
          <Col span={16} className={'disposition-date'}>
            <span>{trimCurrentYear(formatDate(payRoll.dispositionDate))}</span>
            {canChangeDispositionDate(payRoll) && (
              <Button className="ant-btn-edit" onClick={() => props.setEditDispositionDateVisibility(true)} />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={8}>{t('pay_roll.single.details.approved_by')}:</Col>
          <Col span={16}>
            {approvers.length > 0 && (
              <>
                {approvers.map((approver) => approver.user?.name ?? formatSourceType(approver.source)).join(', ')}
                <span className="approved-by-at">
                  {t('pay_roll.single.details.approved_by.at', {
                    time: formatDateTime(
                      approvers.reduce(
                        (time, approver) =>
                          time === '' || time.localeCompare(approver.createdAt) > 0 ? approver.createdAt : time,
                        ''
                      )
                    ),
                  })}
                </span>
              </>
            )}
            {approvers.length === 0 && (
              <i>
                {convertPayRollStatus(payRoll) === 'Approved' || convertPayRollStatus(payRoll) === 'Tentative'
                  ? t('common.unknown')
                  : t('pay_roll.single.details.approved_by.none')}
              </i>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={8}>{t('pay_roll.single.details.total_transfer')}:</Col>
          <Col span={16}>{formatCurrency(payRoll.totalTransfer, 2)}</Col>
        </Row>
      </Card>

      <Modal
        visible={showReceipt}
        onOk={() => setShowReceipt(false)}
        onCancel={() => setShowReceipt(false)}
        width={582}
        footer={null}
      >
        <TaxReportReceiptModal visible={showReceipt} receipt={receipt} />
      </Modal>
    </div>
  )
}
