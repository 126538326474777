import { List } from 'immutable'
import React, { ReactElement, ReactNode, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Employee from '../../model/employee'
import Warning from '../../model/warning'
import { AlertReducer } from '../../reducers/alerts'
import { paths } from '../../routes'
import { formatDate } from '../../utils/date-utils'
import { getPage, setPage } from '../../utils/route-utils'
import { formatStripeErrorCode } from '../../utils/stripe-utils'
import { t, tx } from '../../utils/translation-utils'
import Table from '../antd/table'
import Button from '../elements/button'
import ContextMenu from '../elements/ContextMenu'
import Headline from '../elements/Headline'
import Icon from '../elements/Icon'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'

import './Notifications.css'

type Props = {
  alerts: AlertReducer
  warnings: List<Warning>
  employees: List<Employee>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  deleteWarning: (id: string) => void
  deleteAllWarnings: () => void
}

export default function Notifications(props: Props): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])

  const getEmployee = (id: string) => {
    return props.employees.find((employee) => employee.id === id)
  }

  type WarningRow = {
    key: string
    id: string
    title: string
    description: ReactNode
    status: string
    statusText: string
    statusClass: string
    creationTime: string
    link?: string
    canAcknowledge: boolean
  }

  const remove = (warning: WarningRow) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setDeleting((prev) => [...prev, warning.id])
      props.deleteWarning(warning.id)
    }
  }

  const removeAll = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      props.deleteAllWarnings()
    }
  }

  const columns = [
    {
      title: t('notifications.table.header.status'),
      dataIndex: '',
      key: 'xStatus',
      width: '35%',
      render: (warning: WarningRow) => {
        return (
          <div>
            {warning.statusText}
            <small>{warning.creationTime}</small>
          </div>
        )
      },
    },
    {
      title: t('notifications.table.header.description'),
      dataIndex: '',
      key: 'xDescription',
      render: (warning: WarningRow) => {
        return (
          <Headline>
            {warning.title}
            {warning.description && <small>{warning.description}</small>}
          </Headline>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (warning: WarningRow) => {
        if (deleting.indexOf(warning.id) !== -1) {
          return null
        }
        if (!warning.link && !warning.canAcknowledge) {
          return null
        }
        return (
          <ContextMenu>
            {warning.link && (
              <Link to={warning.link}>
                <Icon type="info" color="grey" /> {t('notifications.table.actions.link')}
              </Link>
            )}
            {warning.canAcknowledge && (
              <Link to={'/' + paths.NOTIFICATIONS} onClick={remove(warning)}>
                <Icon type="edit" color="grey" /> {t('notifications.table.actions.remove')}
              </Link>
            )}
          </ContextMenu>
        )
      },
    },
  ]

  const getNotifications = (): WarningRow[] => {
    return props.warnings
      .filter((warning) => warning.companyLevel)
      .map((warning): WarningRow | null => {
        let employee: Employee | undefined
        switch (warning.warningType) {
          case 'EmployeeInvalidNationalID':
          case 'EmployeeMissingContract':
          case 'EmployeeEmptyTaxCard':
          case 'EmployeeMissingTaxCard':
          case 'InvalidProductionUnit':
          case 'EmployeeIsForeignResident':
          case 'EmployeeContractFamilyLeaveNotChanged':
            if (warning.subjectID) {
              employee = getEmployee(warning.subjectID)
            }
            break
          default:
            break
        }
        let title: string = warning.warningType
        let description: ReactNode | string = ''
        let link: string | undefined
        let statusText = 'Information'
        let statusClass = 'notifications-table-row-warning'
        switch (warning.warningType) {
          case 'CompanyNotVerified':
            title = t('notifications.warning.company_not_verified.title')
            description = tx('notifications.warning.company_not_verified.description', {
              email: (
                <a href="mailto:support@salary.dk">
                  {t('notifications.warning.company_not_verified.description.email')}
                </a>
              ),
            })
            statusText = t('notifications.warning.company_not_verified.status_text')
            statusClass = 'notifications-table-row-error'
            break
          case 'EmployeeInvalidNationalID':
            if (!employee) {
              return null
            }
            title = t('notifications.warning.employee_invalid_national_id.title', { name: employee.name })
            description = t('notifications.warning.employee_invalid_national_id.description')
            link = '/' + paths.EMPLOYEES + '/' + warning.subjectID
            statusText = t('notifications.warning.employee_invalid_national_id.status_text')
            statusClass = 'notifications-table-row-error'
            break
          case 'EmployeeMissingContract':
            if (!employee) {
              return null
            }
            title = t('notifications.warning.employee_missing_contract.title', { name: employee.name })
            description = t('notifications.warning.employee_missing_contract.description')
            link = '/' + paths.EMPLOYEES + '/' + warning.subjectID + '/' + paths.CONTRACTS + '/' + paths.ADD
            statusText = t('notifications.warning.employee_missing_contract.status_text')
            statusClass = 'notifications-table-row-error'
            break
          case 'EmployeeEmptyTaxCard':
            if (!employee) {
              return null
            }
            title = t('notifications.warning.employee_empty_tax_card.title', { name: employee.name })
            description = t('notifications.warning.employee_empty_tax_card.description')
            link = '/' + paths.EMPLOYEES + '/' + warning.subjectID
            statusText = t('notifications.warning.employee_empty_tax_card.status_text')
            statusClass = 'notifications-table-row-warning'
            break
          case 'EmployeeMissingTaxCard':
            if (!employee) {
              return null
            }
            title = t('notifications.warning.employee_missing_tax_card.title', { name: employee.name })
            description = t('notifications.warning.employee_missing_tax_card.description', { name: employee.name })
            link = '/' + paths.EMPLOYEES + '/' + warning.subjectID
            statusText = t('notifications.warning.employee_missing_tax_card.status_text')
            statusClass = 'notifications-table-row-warning'
            break
          case 'InvalidProductionUnit':
            if (!employee) {
              return null
            }
            title = t('notifications.warning.invalid_production_unit.title')
            description = t('notifications.warning.invalid_production_unit.description', { name: employee.name })
            link = '/' + paths.EMPLOYEES + '/' + warning.subjectID
            statusText = t('notifications.warning.invalid_production_unit.status_text')
            statusClass = 'notifications-table-row-warning'
            break
          case 'EmployeeIsForeignResident':
            if (!employee) {
              return null
            }
            title = t('notifications.warning.employee_is_foreign_resident.title')
            description = t('notifications.warning.employee_is_foreign_resident.description', { name: employee.name })
            link = '/' + paths.EMPLOYEES + '/' + warning.subjectID
            statusText = t('notifications.warning.employee_is_foreign_resident.status_text')
            statusClass = 'notifications-table-row-warning'
            break
          case 'NETSOnboardingFailed':
            title = t('notifications.warning.nets_onboarding_failed.title')
            description = t('notifications.warning.nets_onboarding_failed.description')
            link = '/' + paths.COMPANIES + '/' + warning.companyID + '/transfers'
            statusText = t('notifications.warning.nets_onboarding_failed.status_text')
            statusClass = 'notifications-table-row-error'
            break
          case 'NotRegisteredAsEmployer':
            title = t('notifications.warning.not_registered_as_employer.title')
            description = t('notifications.warning.not_registered_as_employer.description')
            statusText = t('notifications.warning.not_registered_as_employer.status_text')
            statusClass = 'notifications-table-row-error'
            break
          case 'VoucherBookingFailed':
            title = t('notifications.warning.voucher_booking_failed.title')
            description = t('notifications.warning.voucher_booking_failed.description')
            link = '/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING
            statusText = t('notifications.warning.voucher_booking_failed.status_text')
            statusClass = 'notifications-table-row-error'
            break
          case 'VoucherMissingAccount':
            title = t('notifications.warning.voucher_missing_account.title')
            description = t('notifications.warning.voucher_missing_account.description', {
              accounts: JSON.parse(warning.parameter || '').join(', '),
            })
            link = '/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING
            statusText = t('notifications.warning.voucher_missing_account.status_text')
            statusClass = 'notifications-table-row-warning'
            break
          case 'FixCreditCardIntegration': {
            const result = JSON.parse(warning.parameter || '')
            title = t('notifications.warning.fix_credit_card_integration.title')
            link = '/' + paths.COMPANIES + '/' + warning.companyID + '/invoices'
            switch (result.Action) {
              case 'Contact Issuer':
                description = t('notifications.warning.fix_credit_card_integration.description.contact_issuer')
                break
              case 'Try Again':
                description = t('notifications.warning.fix_credit_card_integration.description.try_again')
                link = '/' + paths.COMPANIES + '/' + warning.companyID + '/transfers'
                break
              case 'New Card':
                description = t('notifications.warning.fix_credit_card_integration.description.new_card')
                break
              default:
                description = t('notifications.warning.fix_credit_card_integration.description.default')
                break
            }
            if (result.Code !== '') {
              description = t('notifications.warning.fix_credit_card_integration.description.with_note_from_issuer', {
                description: description as string,
                error: formatStripeErrorCode(result.Code),
              })
            }
            statusText = t('notifications.warning.fix_credit_card_integration.status_text')
            statusClass = 'notifications-table-row-error'
            break
          }
          case 'DineroOldToken':
            title = t('notifications.warning.dinero_old_token.title')
            link = '/' + paths.COMPANIES + '/' + warning.companyID + '/' + paths.ACCOUNTING + '/' + paths.ADD
            description = t('notifications.warning.dinero_old_token.description')
            statusText = t('notifications.warning.dinero_old_token.status_text')
            statusClass = 'notifications-table-row-warning'
            break
          case 'EmployeeContractFamilyLeaveNotChanged':
            if (!employee) {
              return null
            }
            title = t('notifications.warning.employee_contract_family_leave_not_changed.title', { name: employee.name })
            description = t('notifications.warning.employee_contract_family_leave_not_changed.description', {
              name: employee.name,
            })
            link = '/' + paths.EMPLOYEES + '/' + warning.subjectID
            statusText = t('notifications.warning.employee_contract_family_leave_not_changed.status_text')
            statusClass = 'notifications-table-row-warning'
            break
          default:
            break
        }
        return {
          key: warning.id,
          id: warning.id,
          title,
          description,
          status: warning.severityType,
          statusText,
          statusClass,
          creationTime: formatDate(warning.createdAt),
          link,
          canAcknowledge: warning.canAcknowledge,
        }
      })
      .toArray()
      .reduce((list: WarningRow[], warning: WarningRow | null): WarningRow[] => {
        if (warning === null) {
          return list
        }
        return [...list, warning]
      }, [])
  }

  const getRowClassName = (warning: WarningRow): string => {
    const classNames = []
    if (warning.link) {
      classNames.push('notifications-table-row-clickable')
    }
    classNames.push(warning.statusClass)
    return classNames.join(' ')
  }

  const notifications = getNotifications()
  return (
    <div className="notifications">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <TitleMenu>
        {notifications.some((warning) => warning.canAcknowledge) && (
          <Button onClick={removeAll} type="danger">
            {t('notifications.header.delete_all')}
          </Button>
        )}
      </TitleMenu>

      <Title>{t('notifications.header.title')}</Title>
      <Table
        columns={columns}
        dataSource={notifications}
        showHeader={false}
        pagination={notifications.length > 10 ? { defaultCurrent: getPage(), onChange: setPage } : false}
        className="notifications-table"
        onRowClick={(warning: WarningRow) => {
          if (warning.link) {
            jsBrowserHistory.push(warning.link)
          }
        }}
        rowClassName={getRowClassName}
      />
    </div>
  )
}
