import React from 'react'

import {
  fetchEmployments,
  patchTerminateEmployment,
  postEmployment,
  postRehireEmployee,
  putEmployment,
} from '../api/employments'
import ActionTypes from '../constants/action-types'
import Employment from '../model/employment'
import { DateFormat } from '../model/types'
import { EmploymentAction } from '../reducers/employments'
import { formatAPIDate, getDate } from '../utils/date-utils'
import { isRequestError } from '../utils/error-utils'

function loadingEmployments(employeeID: string): EmploymentAction {
  return {
    type: ActionTypes.EMPLOYMENT_LOADING,
    employeeID,
  }
}
function loadedEmployments(employeeID: string, employments: Employment[]): EmploymentAction {
  return {
    type: ActionTypes.EMPLOYMENT_LOADED,
    employments,
    employeeID,
  }
}
function failedLoadingEmployments(employeeID: string, error: Error): EmploymentAction {
  return {
    type: ActionTypes.EMPLOYMENT_LOAD_FAILED,
    error,
    employeeID,
  }
}

function addingEmployment(employeeID: string): EmploymentAction {
  return {
    type: ActionTypes.EMPLOYMENT_ADDING,
    employeeID,
  }
}
export function addedEmployment(employeeID: string, employment: Employment): EmploymentAction {
  return {
    type: ActionTypes.EMPLOYMENT_ADDED,
    employeeID,
    employment,
  }
}
function failedAddingEmployment(employeeID: string, error: Error): EmploymentAction {
  return {
    type: ActionTypes.EMPLOYMENT_ADD_FAILED,
    error,
    employeeID,
  }
}

function updatingEmployment(employeeID: string, employmentID?: string): EmploymentAction {
  return {
    type: ActionTypes.EMPLOYMENT_UPDATING,
    employeeID,
    employmentID,
  }
}
export function updatedEmployment(employeeID: string, employment: Employment): EmploymentAction {
  return {
    type: ActionTypes.EMPLOYMENT_UPDATED,
    employeeID,
    employmentID: employment.id,
    employment,
  }
}
function failedUpdatingEmployment(
  employeeID: string,
  employmentID: string | undefined,
  error: Error
): EmploymentAction {
  return {
    type: ActionTypes.EMPLOYMENT_UPDATE_FAILED,
    error,
    employeeID,
    employmentID,
  }
}

export function getEmployments(employeeID: string) {
  return (dispatch: React.Dispatch<any>): Promise<Employment[] | void> => {
    dispatch(loadingEmployments(employeeID))
    return fetchEmployments(employeeID)
      .then((res) => {
        dispatch(loadedEmployments(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingEmployments(employeeID, e))
        }
      })
  }
}

export function addEmployment(employeeID: string, employment: Employment) {
  return (dispatch: React.Dispatch<any>): Promise<Employment | void> => {
    dispatch(addingEmployment(employeeID))
    return postEmployment(employeeID, employment)
      .then((res) => {
        dispatch(addedEmployment(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingEmployment(employeeID, e))
        }
      })
  }
}

export function updateEmployment(employeeID: string, employment: Employment) {
  return (dispatch: React.Dispatch<any>): Promise<Employment | void> => {
    dispatch(updatingEmployment(employeeID, employment.id))
    return putEmployment(employment)
      .then((res) => {
        dispatch(updatedEmployment(employeeID, res.data))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingEmployment(employeeID, employment.id, e))
        }
      })
  }
}

export function terminateEmployment(employeeID: string, employment: Employment, removeOrphans = false) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(updatingEmployment(employeeID, employment.id))
    return patchTerminateEmployment(employment.id, employment.endDate || formatAPIDate(getDate()), removeOrphans)
      .then((res) => {
        dispatch(updatedEmployment(employeeID, res.data))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingEmployment(employeeID, employment.id, e))
        }
        return false
      })
  }
}

export function rehireEmployee(employeeID: string, startDate: DateFormat) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(updatingEmployment(employeeID))
    return postRehireEmployee(employeeID, startDate)
      .then((res) => {
        dispatch(updatedEmployment(employeeID, res.data))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingEmployment(employeeID, undefined, e))
        }
        return false
      })
  }
}
