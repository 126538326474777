import React from 'react'

import classNames from '../_util/classNames'
import BaseIcon from '../../elements/base-icon'
import Checkbox from '../../elements/checkbox'
import Dropdown from '../dropdown'
import Menu from '../menu'

function checkSelection({ store, getCheckboxPropsByItem, getRecordKey, data, type, byDefaultChecked }) {
  return byDefaultChecked
    ? data[type]((item, i) => getCheckboxPropsByItem(item, i).defaultChecked)
    : data[type]((item, i) => store.getState().selectedRowKeys.indexOf(getRecordKey(item, i)) >= 0)
}

function getIndeterminateState(props) {
  const { store, data } = props
  if (!data.length) {
    return false
  }

  const someCheckedNotByDefaultChecked =
    checkSelection({
      ...props,
      data,
      type: 'some',
      byDefaultChecked: false,
    }) &&
    !checkSelection({
      ...props,
      data,
      type: 'every',
      byDefaultChecked: false,
    })
  const someCheckedByDefaultChecked =
    checkSelection({
      ...props,
      data,
      type: 'some',
      byDefaultChecked: true,
    }) &&
    !checkSelection({
      ...props,
      data,
      type: 'every',
      byDefaultChecked: true,
    })

  if (store.getState().selectionDirty) {
    return someCheckedNotByDefaultChecked
  }
  return someCheckedNotByDefaultChecked || someCheckedByDefaultChecked
}

function getCheckState(props) {
  const { store, data } = props
  if (!data.length) {
    return false
  }
  if (store.getState().selectionDirty) {
    return checkSelection({
      ...props,
      data,
      type: 'every',
      byDefaultChecked: false,
    })
  }
  return (
    checkSelection({
      ...props,
      data,
      type: 'every',
      byDefaultChecked: false,
    }) ||
    checkSelection({
      ...props,
      data,
      type: 'every',
      byDefaultChecked: true,
    })
  )
}

class SelectionCheckboxAll extends React.Component {
  constructor(props) {
    super(props)

    this.defaultSelections = props.hideDefaultSelections
      ? []
      : [
          {
            key: 'all',
            text: props.locale.selectAll,
            onSelect: () => {},
          },
          {
            key: 'invert',
            text: props.locale.selectInvert,
            onSelect: () => {},
          },
        ]

    this.state = {
      checked: false,
      indeterminate: false,
    }
  }

  componentDidMount() {
    this.subscribe()
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  subscribe() {
    const { store } = this.props
    this.unsubscribe = store.subscribe(() => {
      this.setCheckState(this.props)
    })
  }

  setCheckState(props) {
    const checked = getCheckState(props)
    const indeterminate = getIndeterminateState(props)
    if (checked !== this.state.checked) {
      this.setState({ checked })
    }
    if (indeterminate !== this.state.indeterminate) {
      this.setState({ indeterminate })
    }
  }

  handleSelectAllChagne = (e) => {
    let checked = e.target.checked
    this.props.onSelect(checked ? 'all' : 'removeAll', 0, null)
  }

  renderMenus(selections) {
    return selections.map((selection, index) => {
      return (
        <Menu.Item key={selection.key || index}>
          <div
            onClick={() => {
              this.props.onSelect(selection.key, index, selection.onSelect)
            }}
          >
            {selection.text}
          </div>
        </Menu.Item>
      )
    })
  }

  render() {
    const { disabled, prefixCls, selections, getPopupContainer } = this.props
    const { checked, indeterminate } = this.state

    let selectionPrefixCls = `${prefixCls}-selection`

    let customSelections = null

    if (selections) {
      let newSelections = Array.isArray(selections) ? this.defaultSelections.concat(selections) : this.defaultSelections

      const menu = (
        <Menu className={`${selectionPrefixCls}-menu`} selectedKeys={[]}>
          {this.renderMenus(newSelections)}
        </Menu>
      )

      customSelections =
        newSelections.length > 0 ? (
          <Dropdown overlay={menu} getPopupContainer={getPopupContainer}>
            <div className={`${selectionPrefixCls}-down`}>
              <BaseIcon type="down" />
            </div>
          </Dropdown>
        ) : null
    }

    return (
      <div className={selectionPrefixCls}>
        <Checkbox
          className={classNames({ [`${selectionPrefixCls}-select-all-custom`]: customSelections })}
          checked={checked}
          indeterminate={indeterminate}
          disabled={disabled}
          onChange={this.handleSelectAllChagne}
        />
        {customSelections}
      </div>
    )
  }
}

SelectionCheckboxAll.getDerivedStateFromProps = function (props, state) {
  const checked = getCheckState(props)
  const indeterminate = getIndeterminateState(props)
  const newState = {}
  if (indeterminate !== state.indeterminate) {
    newState.indeterminate = indeterminate
  }
  if (checked !== state.checked) {
    newState.checked = checked
  }
  return newState
}

export default SelectionCheckboxAll
