import { addYears, subYears } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { useEffectOnce, usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import { getDataIntegrationLeaveRegistrationsExport } from '../../api/data-integration'
import { AsynchronousScheduleCreationFields } from '../../model/asynchronousSchedule'
import AsynchronousTask from '../../model/asynchronousTask'
import Company from '../../model/company'
import Employee from '../../model/employee'
import LeaveType from '../../model/leaveType'
import { DateFormat } from '../../model/types'
import { AsynchronousScheduleReducer } from '../../reducers/asynchronousSchedules'
import { AsynchronousTaskReducer } from '../../reducers/asynchronousTasks'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatAPIDate, formatDate, formatDateTime, getDate } from '../../utils/date-utils'
import { compareError, convertStoredErrorToError, formatError } from '../../utils/error-utils'
import { formatLeaveTypeName } from '../../utils/format-utils'
import { formatLoadingText } from '../../utils/loading-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Table from '../antd/table'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import LoadingOverlay from '../widgets/LoadingOverlay'
import AsynchronousScheduleModal from './AsynchronousScheduleModal'
import AsynchronousTaskSelector from './AsynchronousTaskSelector'
import AsynchronousTaskStatusDisplay from './AsynchronousTaskStatusDisplay'

type Props = {
  asynchronousTasks: AsynchronousTaskReducer
  asynchronousSchedules: AsynchronousScheduleReducer
  company: Company
  timeRegistrations: TimeRegistrationReducer
  employees: List<Employee>
  leaveTypes: List<LeaveType>
  displayName: string

  addAlert: addAlertSignature
  startLeaveRegistrationExport: (companyID: string) => Promise<AsynchronousTask | void>
  saveLeaveRegistrationExport: (
    companyID: string,
    asynchronousTaskID: string,
    timeRegistrationIDs: string[]
  ) => Promise<AsynchronousTask | void>
  getAsynchronousTask: (id: string) => void
  getTimeRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    from: DateFormat,
    to: DateFormat
  ) => void
  addAsynchronousSchedule: (schedule: AsynchronousScheduleCreationFields) => void
  updateAsynchronousSchedule: (companyID: string, scheduleID: string, cron: string) => void
  deleteAsynchronousSchedule: (companyID: string, scheduleID: string) => void
}

type State = {
  timeRegistrationIDs?: string[]
  step: number
  asynchronousTask?: AsynchronousTask
  exporting: boolean
  saving: boolean
}

export default function LeaveRegistrationsExport(props: Props): ReactElement | null {
  const [state, setState] = useState<State>({ step: 0, exporting: false, saving: false })
  const [loading, setLoading] = useState(false)
  const [showSchedule, setShowVisible] = useState(false)
  const [modalKey, setModalKey] = useState(1)
  const [error, setError] = useState<Error | null>(null)
  const [taskError, setTaskError] = useState<Error | null>(null)

  const { asynchronousTasks } = props
  useEffectOnce(() => {
    const task = asynchronousTasks.asynchronousTasks
      .filter((task) => task.type === 'LeaveRegistrationExportGather' && task.status === 'Success')
      .sort((a, b) => (a.startedAt || '').localeCompare(b.startedAt || ''))
      .last()
    if (task) {
      getDataIntegrationLeaveRegistrationsExport(task.id).then((res) => {
        setState((prev) => ({
          ...prev,
          step: 1,
          asynchronousTask: task,
          timeRegistrationIDs: res.data,
          importing: false,
        }))
      })
    }
  })

  useEffectOnce(() => {
    if (!props.timeRegistrations.loaded && !props.timeRegistrations.loading) {
      props.getTimeRegistrations(
        props.company.id,
        undefined,
        undefined,
        formatAPIDate(subYears(getDate(), 2)),
        formatAPIDate(addYears(getDate(), 10))
      )
    }
  })

  const loadTimeRegistrations = (task: AsynchronousTask) => {
    if (task.status !== 'Success') {
      return
    }
    setLoading(true)
    getDataIntegrationLeaveRegistrationsExport(task.id).then((res) => {
      setState((prev) => ({ ...prev, timeRegistrationIDs: res.data }))
      setLoading(false)
    })
  }

  const setScheduleVisibility = (visible: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowVisible(visible)
  }

  const { asynchronousSchedules } = props
  const previousAsynchronousSchedules = usePrevious(asynchronousSchedules)
  useEffect(() => {
    if (previousAsynchronousSchedules && previousAsynchronousSchedules.saving && !asynchronousSchedules.saving) {
      if (!asynchronousSchedules.error) {
        setScheduleVisibility(false)
      }
    }
  })

  const { addAlert } = props

  useEffect(() => {
    const existingTask = state.asynchronousTask
    if (!existingTask) {
      return
    }
    const updatedTask = asynchronousTasks.asynchronousTasks.find((task) => task.id === existingTask.id)
    if (!updatedTask) {
      return
    }
    switch (updatedTask.type) {
      case 'LeaveRegistrationExportGather': {
        let nextExporting = state.exporting
        if (nextExporting && (updatedTask.status === 'Failed' || updatedTask.status === 'Success')) {
          nextExporting = false
        }
        const newError = convertStoredErrorToError(updatedTask.error)
        if (!compareError(taskError, newError)) {
          setTaskError(newError)
        }
        if (state.exporting !== nextExporting || existingTask.status !== updatedTask.status) {
          setState((prev) => {
            if (prev.exporting !== nextExporting) {
              prev = { ...prev, exporting: nextExporting }
            }
            if (prev.asynchronousTask && prev.asynchronousTask.status !== updatedTask.status) {
              prev = {
                ...prev,
                asynchronousTask: updatedTask,
              }
            }
            return prev
          })
        }
        if (updatedTask.status === 'Success' && state.timeRegistrationIDs === undefined && !loading) {
          loadTimeRegistrations(updatedTask)
        }
        break
      }
      case 'LeaveRegistrationExportSave': {
        const needsAlert = state.saving
        let nextSaving = state.saving
        if (nextSaving && (updatedTask.status === 'Failed' || updatedTask.status === 'Success')) {
          nextSaving = false
        }

        const newError = convertStoredErrorToError(updatedTask.error)
        if (!compareError(taskError, newError)) {
          setTaskError(newError)
        }

        if (state.saving !== nextSaving || existingTask.status !== updatedTask.status) {
          setState((prev) => {
            if (prev.saving !== nextSaving) {
              prev = { ...prev, saving: nextSaving }
            }
            if (prev.asynchronousTask && prev.asynchronousTask.status !== updatedTask.status) {
              prev = {
                ...prev,
                asynchronousTask: updatedTask,
              }
            }
            return prev
          })
        }
        if (needsAlert && updatedTask.status === 'Success') {
          addAlert('success', t('data_integration.leave_export.alert.success'), { timeout: 5 })
        }
        break
      }
      default:
        break
    }
  }, [asynchronousTasks, state, loading, taskError, addAlert])

  useEffect(() => {
    regularComponentDidUpdate(asynchronousTasks.error, error, setError)
  }, [asynchronousTasks, error])

  const startExport = (e: React.MouseEvent) => {
    e.preventDefault()

    setState((prev) => ({ ...prev, timeRegistrationIDs: undefined }))

    props.startLeaveRegistrationExport(props.company.id).then((res) => {
      if (!res) {
        return
      }
      setState((prev) => ({
        ...prev,
        step: 1,
        asynchronousTask: res,
        importing: true,
        employees: undefined,
      }))
      loadTimeRegistrations(res)
    })
  }

  const refreshTask = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!state.asynchronousTask) {
      return
    }

    props.getAsynchronousTask(state.asynchronousTask.id)
  }

  const saveExport = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!state.asynchronousTask) {
      return
    }

    props
      .saveLeaveRegistrationExport(props.company.id, state.asynchronousTask.id, state.timeRegistrationIDs ?? [])
      .then((res) => {
        if (!res) {
          return
        }
        setState((prev) => ({
          ...prev,
          step: 2,
          asynchronousTask: res,
          importing: false,
          saving: true,
        }))
      })
  }

  const selectAsynchronousTask = (taskID: string) => {
    const task = props.asynchronousTasks.asynchronousTasks.find((task) => task.id === taskID)
    if (task) {
      setState((prev) => ({
        ...prev,
        step: 1,
        asynchronousTask: task,
        importing: true,
        saving: false,
        employees: undefined,
      }))
      loadTimeRegistrations(task)
      setTaskError(convertStoredErrorToError(task.error))
    }
  }

  type LeaveRegistrationRow = {
    name: string
    leaveType: string
    date: string
    rate: string
  }

  const columns = [
    {
      key: 'name',
      title: t('data_integration.leave_export.header.name'),
      dataIndex: 'name',
    },
    { key: 'leaveType', title: t('data_integration.leave_export.header.leave_type'), dataIndex: 'leaveType' },
    { key: 'date', title: t('data_integration.leave_export.header.date'), dataIndex: 'date' },
    { key: 'rate', title: t('data_integration.leave_export.header.rate'), dataIndex: 'rate' },
  ]

  const getLeaveRegistrationRows = (): LeaveRegistrationRow[] => {
    return (state.timeRegistrationIDs ?? [])
      .map((id) => {
        const leaveRegistration = props.timeRegistrations.timeRegistrations.find((timeReg) => timeReg.id === id)
        if (!leaveRegistration) {
          return null
        }
        if (!leaveRegistration.leaveTypeID) {
          return null
        }
        const leaveType = props.leaveTypes.find((leaveType) => leaveType.id === leaveRegistration.leaveTypeID)
        if (!leaveType) {
          return null
        }
        const employee = props.employees.find((employee) => employee.id === leaveRegistration.employeeID)
        if (!employee) {
          return null
        }
        return {
          name: employee.name || employee.email || '-',
          leaveType: formatLeaveTypeName(leaveType.name),
          date: formatDate(leaveRegistration.date),
          rate: t('unit.day_count', { count: leaveRegistration.days ?? 0 }),
        }
      })
      .reduce((list: LeaveRegistrationRow[], row) => {
        if (!row) {
          return list
        }
        list.push(row)
        return list
      }, [])
  }

  const gatherButton = (text: string) => {
    return (
      <Row style={{ float: 'right', width: '200px' }}>
        <Col span={24}>
          <Button
            size="large"
            onClick={startExport}
            type="secondary"
            noArrow
            className="gtm-leave-registrations-export"
            style={{ float: 'right' }}
          >
            {text}
          </Button>
        </Col>
      </Row>
    )
  }

  if (!props.asynchronousTasks.loaded) {
    return (
      <LoadingOverlay
        text={formatLoadingText([
          { loading: !props.asynchronousTasks.loaded, text: t('loading.reducer.asynchronous_tasks') },
        ])}
      />
    )
  }

  return (
    <div>
      {taskError && (
        <Alert
          message={
            <>
              {formatError(taskError)}
              {gatherButton(t('data_integration.leave_export.actions.collect_again'))}
            </>
          }
          type="error"
          showIcon
        />
      )}
      {!taskError && error && <Alert message={formatError(error)} type="error" showIcon />}
      <TitleMenu>
        <Button onClick={() => setScheduleVisibility(true)}>
          {t('data_integration.leave_export.actions.schedule')}
        </Button>
        <AsynchronousTaskSelector
          asynchronousTasks={props.asynchronousTasks.asynchronousTasks}
          asynchronousTaskType={'LeaveRegistrationExportGather'}
          asynchronousTask={state.asynchronousTask}
          onChange={selectAsynchronousTask}
        />
      </TitleMenu>
      <Title> {t('data_integration.leave_export.title', { name: props.displayName })}</Title>
      {state.step === 0 && (
        <Row>
          <Col span={24}>{gatherButton(t('data_integration.leave_export.actions.collect'))}</Col>
        </Row>
      )}
      {state.step === 1 && state.exporting && (
        <AsynchronousTaskStatusDisplay
          title={t('data_integration.leave_export.status.gathering.title')}
          description={t('data_integration.leave_export.status.gathering.description')}
          task={state.asynchronousTask}
          refreshTask={refreshTask}
        />
      )}
      {state.step === 2 && state.saving && (
        <AsynchronousTaskStatusDisplay
          title={t('data_integration.leave_export.status.saving.title')}
          description={t('data_integration.leave_export.status.saving.description')}
          task={state.asynchronousTask}
          refreshTask={refreshTask}
        />
      )}
      {(state.step === 1 || state.step === 2) &&
        !state.exporting &&
        !state.saving &&
        state.timeRegistrationIDs !== undefined && (
          <div
            className="leave-registration-integration-export-list"
            style={{ textAlign: 'left', fontSize: '14px', marginTop: '30px' }}
          >
            <Row>
              <Col span={12}>
                <Button
                  size="large"
                  onClick={saveExport}
                  type="secondary"
                  noArrow
                  className="gtm-leave-registrations-export-save"
                >
                  {t('data_integration.leave_export.actions.save_export')}
                </Button>
                {state.asynchronousTask?.finishedAt && (
                  <span style={{ paddingLeft: '15px' }}>
                    {t('data_integration.leave_export.data_collected', {
                      date: formatDateTime(state.asynchronousTask?.finishedAt),
                    })}
                  </span>
                )}
              </Col>
              <Col span={12}>{gatherButton(t('data_integration.leave_export.actions.collect_again'))}</Col>
            </Row>
            <Table columns={columns} dataSource={getLeaveRegistrationRows()} pagination={false} />
            <Row style={{ marginTop: '35px' }}>
              <Col span={24}>
                <Button
                  size="large"
                  onClick={saveExport}
                  type="secondary"
                  noArrow
                  className="gtm-leave-registrations-export-save"
                >
                  {t('data_integration.leave_export.actions.save_export')}
                </Button>
              </Col>
            </Row>
          </div>
        )}

      {!taskError && !error && state.step === 1 && !state.exporting && state.timeRegistrationIDs === undefined && (
        <LoadingOverlay text={t('data_integration.leave_export.collecting')} />
      )}

      <Modal
        key={modalKey}
        visible={showSchedule}
        onOk={() => setScheduleVisibility(false)}
        onCancel={() => setScheduleVisibility(false)}
        width={582}
        footer={null}
      >
        <AsynchronousScheduleModal
          companyID={props.company.id}
          asynchronousSchedules={props.asynchronousSchedules}
          scheduleType={'LeaveRegistrationExport'}
          addAsynchronousSchedule={props.addAsynchronousSchedule}
          updateAsynchronousSchedule={props.updateAsynchronousSchedule}
          deleteAsynchronousSchedule={props.deleteAsynchronousSchedule}
        />
      </Modal>
    </div>
  )
}
