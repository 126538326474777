import CountryCode from '../types/country-code'
import countryCode from '../types/country-code'
import { formatCountryCode } from './format-utils'

/**
 * sortCountryCode ensures that X1 and X2 are always sorted last, regardless of their locale translation
 * @param a
 * @param b
 */
export function sortCountryCode(a: CountryCode, b: CountryCode): number {
  const aText = a === countryCode.X1 ? 'ÅÅ1' : a === countryCode.X2 ? 'ÅÅ2' : formatCountryCode(a)
  const bText = b === countryCode.X1 ? 'ÅÅ1' : b === countryCode.X2 ? 'ÅÅ2' : formatCountryCode(b)
  return aText.localeCompare(bText, 'da')
}
