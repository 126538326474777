import React, { ReactElement } from 'react'

import { FormField } from '../../../utils/form-utils'
import {
  formatInputDecimalSeparator,
  formatInputNumber,
  isNumeric,
  parseInputNumber,
  prepareCalculatorInput,
} from '../../../utils/number-utils'
import HelpModal from '../HelpModal'
import Icon from '../Icon'
import Input from './Input'

type Props = {
  title?: string
  placeholder?: string
  className?: string
  tabIndex?: number
  required?: boolean
  formMode?: boolean
  disabled?: boolean
  suffix?: React.ReactNode

  setFormValue?: (value: string) => void
}

export default React.forwardRef<FormField, Props>(function Calculator(props, ref): ReactElement | null {
  const input = React.useRef<HTMLInputElement>(null)

  const calculateValue = (value: string): number | null => {
    // following characters are permitted:
    // 0-9 , . + - * / ( )
    let v = value.replace(/[^0-9,.+\-*/()]+/g, '')
    v = prepareCalculatorInput(v) // this will ensure we can parse the expression
    // we use a bit of a hack to avoid using eval()
    const parse = (s: string): any => {
      return Function(`'use strict'; return (${s})`)()
    }
    try {
      const n = parse(v)
      if (isNumeric(n)) {
        // could turn into a value, format it
        return n
      }
    } catch (e) {
      // ignore
    }
    // not sure what it is, return null to inform the caller it was invalid
    return null
  }

  const keepInputClean = (v: string): string => {
    // only permit allowed characters
    v = v.replace(/[^0-9.,+\-*/()]/g, '')
    // clean up for language (so both . and , are decimal separators)
    return formatInputDecimalSeparator(v)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.formMode || !input.current) {
      return
    }
    input.current.value = keepInputClean(e.currentTarget.value)
  }

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (props.formMode || !input.current) {
      return
    }
    input.current.value = formatInputNumber(parseInputNumber(e.currentTarget.value), 6, false)
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (props.formMode || !input.current) {
      return
    }
    const value = calculateValue(e.currentTarget.value)
    if (value !== null) {
      input.current.value = formatInputNumber(value)
    }
  }

  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!props.setFormValue) {
      return
    }
    const currentValue = e.currentTarget.value
    if (!currentValue.match(/[+\-*/()]/g) || currentValue.match(/^-[0-9,.]+$/)) {
      // no arithmetic symbols OR negative number, so nothing to do
      return
    }
    const value = calculateValue(e.currentTarget.value)
    if (value !== null) {
      // only preventDefault if we actually have something to change
      e.preventDefault()
      props.setFormValue(formatInputNumber(value, 6, false))
    }
  }

  const validate = (v: string): boolean => !!v.match(/^[0-9.,]+$/) && calculateValue(v) !== null

  React.useImperativeHandle(ref, () => ({
    onChange: (e) => {
      return keepInputClean(e.currentTarget.value)
    },
    onFocus: (e) => {
      if (!validate(e.currentTarget.value)) {
        return e.currentTarget.value
      }
      return formatInputNumber(parseInputNumber(e.currentTarget.value), 6, false)
    },
    onBlur: (e) => {
      const value = calculateValue(e.currentTarget.value)
      if (value !== null) {
        return formatInputNumber(value)
      }
      return e.currentTarget.value
    },
    validate: (v, trigger) => {
      if (props.disabled) {
        return null
      }
      const title = props.title ?? props.placeholder ?? 'Felt'
      if (!v) {
        if (props.required) {
          return `${title} er påkrævet`
        }
        return null
      }
      if (trigger === 'onChange') {
        return null
      }
      if (!validate(v)) {
        return `${title} er ugyldig`
      }
      return null
    },
  }))

  const suffixIcon = (
    <HelpModal key="calculatorHelp" className="calculator-icon" toggle={<Icon type="calculator" />}>
      <p>
        Når du ser et felt med lommeregnerikonet (
        <Icon type="calculator" style={{ position: 'relative', top: '5px' }} />
        ), kan du bruge feltet som en lommeregner.
      </p>
      <p>
        Feltet tillader addition (<code>+</code>), subtraktion (<code>-</code>), multiplikation (<code>*</code>) og
        division (<code>/</code>). Det er også muligt at inddele beregningen i parenteser.
      </p>
      <p>Feltet beregnes når du trykker enter eller fjerner fokus fra feltet.</p>
    </HelpModal>
  )
  const suffix = props.suffix ? [props.suffix, suffixIcon] : suffixIcon

  return (
    <Input
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onPressEnter={onPressEnter}
      {...props}
      suffix={suffix}
      forwardRef={input}
    />
  )
})
