import React, { ReactElement } from 'react'

import { removeAlertSignature } from '../../actions/alerts'
import { AlertReducer } from '../../reducers/alerts'
import Alert from '../elements/alert'

// NEED_NO_TRANSLATION

type Props = {
  alerts: AlertReducer
  removeAlert: removeAlertSignature
}

export default function Alerts(props: Props): ReactElement | null {
  return (
    <div className="alerts">
      {props.alerts.alerts.map((alert) => {
        const timeout = alert.options.timeout || 0
        if (timeout > 0) {
          setTimeout(() => {
            props.removeAlert(alert.id)
          }, timeout * 1000)
        }
        return (
          <Alert
            key={alert.id}
            message={alert.message}
            type={alert.type}
            showIcon
            closable={alert.options.closable}
            onClose={() => {
              props.removeAlert(alert.id)
            }}
          />
        )
      })}
    </div>
  )
}
