import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import Company from '../../../model/company'
import Employee from '../../../model/employee'
import { OneTimePayCreationFields, OneTimePayMutableFields, OneTimePayType } from '../../../model/oneTimePay'
import SalaryCycle from '../../../model/salaryCycle'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { paths } from '../../../routes'
import { formatDate } from '../../../utils/date-utils'
import { t } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Table from '../../antd/table'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Icon from '../../elements/Icon'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import Tooltip from '../../elements/tooltip'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import FreeTextEdit from './FreeTextEdit'

type Props = {
  subsection?: string
  company: Company
  canEditObjects: boolean
  canApproveObjects: boolean
  employee: Employee
  isFreelancer: boolean
  oneTimePays: OneTimePayReducer
  salaryCycle?: SalaryCycle
  eIncomeText: boolean

  addAlert: addAlertSignature
  approveOneTimePays: (ids: string[]) => void
  unapproveOneTimePays: (ids: string[]) => void
  addOneTimePay: (employeeID: string, oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, oneTimePay: OneTimePayMutableFields) => void
  deleteOneTimePay: (id: string) => void
}

export default function FreeTextTab(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)
  const [mutable, setMutable] = useState(false)

  const setEditVisibility = (id: string | boolean, mutable = true) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditing(id)
    setMutable(mutable)
  }

  const { oneTimePays } = props
  const previousOneTimePays = usePrevious(oneTimePays)

  useEffect(() => {
    // Check for save callback
    if (previousOneTimePays && previousOneTimePays.saving && !oneTimePays.saving) {
      // Check for no error occurred
      if (!oneTimePays.error) {
        // Close edit modal
        setEditVisibility(false)
      }
    }
  })

  const showHistory = () => {
    return props.subsection === 'history'
  }

  const approve = (oneTimePayID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => [...prev, oneTimePayID])
      props.approveOneTimePays([oneTimePayID])
    }
  }
  const unapprove = (oneTimePayID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => prev.filter((id) => id !== oneTimePayID))
      props.unapproveOneTimePays([oneTimePayID])
    }
  }
  const remove = (oneTimePayID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, oneTimePayID])
        props.deleteOneTimePay(oneTimePayID)
      }
    }
  }

  let otpType: OneTimePayType = 'Free Text'
  if (props.eIncomeText) {
    otpType = 'E-Income Text'
  }

  type OneTimePayRow = {
    key: string
    id: string
    date: string
    title: string
    approved: boolean
    immutable: boolean
  }

  const columns = [
    {
      title: t('free_text.table.header.approved'),
      key: 'x1',
      className: 'one-time-pay-table-approved',
      render: (oneTimePay: OneTimePayRow) => {
        if (oneTimePay.approved) {
          return (
            <span>
              {t('free_text.table.approved.true')}
              {!oneTimePay.immutable && props.canApproveObjects && (
                <Tooltip title={t('free_text.table.approved.remove')}>
                  <span onClick={unapprove(oneTimePay.id)} style={{ cursor: 'pointer' }}>
                    <Icon type="cross" color="grey" />
                  </span>
                </Tooltip>
              )}
            </span>
          )
        }
        if (approving.indexOf(oneTimePay.id) !== -1) {
          return t('free_text.table.saving')
        }
        if (!props.canApproveObjects) {
          return t('free_text.table.approved.false')
        }
        return (
          <DumbLink onClick={approve(oneTimePay.id)} type="standard">
            {t('free_text.table.approved.approve')}
          </DumbLink>
        )
      },
    },
    { title: t('free_text.table.header.date'), dataIndex: 'date', key: 'date' },
    { title: t('free_text.table.header.title'), dataIndex: 'title', key: 'title' },
    {
      title: '',
      key: 'x2',
      className: 'employee-table-actions',
      render: (oneTimePay: OneTimePayRow) => {
        if (deleting.indexOf(oneTimePay.id) !== -1) {
          return null
        }
        if (!props.canEditObjects) {
          return null
        }
        return (
          <div>
            <Tooltip title={oneTimePay.immutable ? t('free_text.actions.view') : t('free_text.actions.edit')}>
              <span
                onClick={() => setEditVisibility(oneTimePay.id, !oneTimePay.immutable)}
                style={{ cursor: 'pointer' }}
              >
                <Icon type={oneTimePay.immutable ? 'search' : 'edit'} color="grey" />
              </span>
            </Tooltip>
            {!oneTimePay.immutable && (
              <Tooltip title={t('free_text.actions.delete')}>
                <span onClick={remove(oneTimePay.id)} style={{ cursor: 'pointer' }}>
                  <Icon type="cross" color="grey" />
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const getFreeTexts = (): OneTimePayRow[] => {
    return props.oneTimePays.oneTimePays
      .filter((oneTimePay) => oneTimePay.type === otpType)
      .filter((oneTimePay) => (showHistory() ? oneTimePay.settled : !oneTimePay.settled))
      .map((oneTimePay) => {
        let title = oneTimePay.title
        if (title.length > 22) {
          title = title.substring(0, 20) + '...'
        }
        return {
          key: oneTimePay.id,
          id: oneTimePay.id,
          date: formatDate(oneTimePay.dispositionDate),
          title,
          immutable: oneTimePay.immutable,
          approved: oneTimePay.approved,
          original: oneTimePay,
        }
      })
      .toArray()
  }

  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      const oneTimePays = getFreeTexts().filter((reg) => !reg.approved)
      setApproving(oneTimePays.map((reg) => reg.id))
      props.approveOneTimePays(oneTimePays.map((otp) => otp.id))
    }
  }

  const hasUnapprovedFreeTexts = () => {
    return getFreeTexts().some((otp) => !otp.approved)
  }

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (
    !props.employee.activeContract ||
    props.employee.activeEmployment.id !== props.employee.activeContract.employmentID
  ) {
    return <NoContractCard employee={props.employee} />
  }
  if (!props.salaryCycle) {
    return null
  }

  let createText = t('free_text.header.create.standard')
  let path = 'free-text'
  if (props.eIncomeText) {
    createText = t('free_text.header.create.e_income')
    path = 'e-income-text'
  }

  return (
    <Card className="employees-single-form">
      <TitleMenu>
        {!showHistory() && props.canEditObjects && (
          <Button onClick={() => setEditVisibility(true)} style={{ marginRight: 20 }}>
            <Icon type="user" color="grey" />
            {createText}
          </Button>
        )}
        {showHistory() ? (
          <Link
            to={'/' + (props.isFreelancer ? paths.FREELANCERS : paths.EMPLOYEES) + '/' + props.employee.id + '/' + path}
          >
            <Button className="gtm-hide-free-text-history" style={{ paddingRight: 14 }}>
              {t('free_text.header.view_history')}
            </Button>
          </Link>
        ) : (
          <Link
            to={
              '/' +
              (props.isFreelancer ? paths.FREELANCERS : paths.EMPLOYEES) +
              '/' +
              props.employee.id +
              '/' +
              path +
              '/history'
            }
          >
            <Button className="gtm-show-free-text-history" style={{ paddingRight: 14 }}>
              {t('free_text.header.show_history')}
            </Button>
          </Link>
        )}

        {hasUnapprovedFreeTexts() && props.canApproveObjects && (
          <Button onClick={approveAll} style={{ marginLeft: 20 }}>
            {t('free_text.header.approve_all')}
          </Button>
        )}
      </TitleMenu>
      <Title>
        {showHistory()
          ? props.eIncomeText
            ? t('free_text.header.title.history.e_income')
            : t('free_text.header.title.history.standard')
          : props.eIncomeText
          ? t('free_text.header.title.standard.e_income')
          : t('free_text.header.title.standard.standard')}
      </Title>
      <p>&nbsp;</p>

      <Table columns={columns} dataSource={getFreeTexts()} size="small" pagination={false} />
      {props.oneTimePays.saving && <LoadingOverlay />}

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <FreeTextEdit
          visible={editing !== false}
          editing={mutable}
          canApproveObjects={props.canApproveObjects}
          company={props.company}
          employee={props.employee}
          forEIncome={props.eIncomeText}
          oneTimePayID={typeof editing === 'string' ? editing : undefined}
          oneTimePays={props.oneTimePays}
          salaryCycle={props.salaryCycle}
          addOneTimePay={props.addOneTimePay}
          updateOneTimePay={props.updateOneTimePay}
        />
      </Modal>
    </Card>
  )
}
