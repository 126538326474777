import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import SalaryType from '../model/salaryType'
import { ReducerAction } from '../utils/reducer-utils'

export interface SalaryTypeReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  salaryTypes: List<SalaryType>
  error: Error | null
}

const Params = Record<SalaryTypeReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  salaryTypes: List<SalaryType>(),
  error: null,
})

export interface SalaryTypeAction extends ReducerAction {
  companyID?: string
  salaryTypes?: SalaryType[]
  salaryType?: SalaryType
  salaryTypeID?: string
  active?: boolean
}

const comparator = (a: SalaryType, b: SalaryType) => {
  if (a.active !== b.active) {
    return a.active ? 1 : -1
  }
  return (a.title || (a.titleTranslations && a.titleTranslations.da) || '').localeCompare(
    b.title || (b.titleTranslations && b.titleTranslations.da) || '',
    'da-dk'
  )
}

export default (
  state: Record<SalaryTypeReducer> = Params(),
  action: SalaryTypeAction = { type: '' }
): Record<SalaryTypeReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.SALARY_TYPES_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.SALARY_TYPES_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        salaryTypes: List<SalaryType>(action.salaryTypes).sort(comparator),
      })
    case ActionTypes.SALARY_TYPES_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.SALARY_TYPE_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SALARY_TYPE_ADDED:
      if (!action.salaryType) {
        return state
      }
      idx = state.get('salaryTypes').findIndex((item) => !!action.salaryType && item.id === action.salaryType.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('salaryTypes', state.get('salaryTypes').set(idx, action.salaryType).sort(comparator))
      }
      return state
        .set('saving', false)
        .set('salaryTypes', state.get('salaryTypes').push(action.salaryType).sort(comparator))
    case ActionTypes.SALARY_TYPE_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.SALARY_TYPE_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SALARY_TYPE_UPDATED:
      if (!action.salaryType) {
        return state
      }
      idx = state.get('salaryTypes').findIndex((item) => item.id === action.salaryTypeID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state
        .set('saving', false)
        .set('salaryTypes', state.get('salaryTypes').set(idx, action.salaryType).sort(comparator))
    case ActionTypes.SALARY_TYPE_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.SALARY_TYPE_ACTIVE_TOGGLING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SALARY_TYPE_ACTIVE_TOGGLED: {
      if (!action.salaryTypeID || typeof action.active !== 'boolean') {
        return state
      }
      idx = state.get('salaryTypes').findIndex((item) => item.id === action.salaryTypeID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      const salaryType = state.get('salaryTypes').get(idx)
      if (!salaryType) {
        return state.set('saving', false)
      }
      salaryType.active = action.active
      return state
        .set('saving', false)
        .set('salaryTypes', state.get('salaryTypes').set(idx, salaryType).sort(comparator))
    }
    case ActionTypes.SALARY_TYPE_ACTIVE_TOGGLE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
