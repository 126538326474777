import { ReportType } from '../model/report'
import { DateFormat } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export interface ReportCreation {
  done: boolean
  fileID?: string
  taskID?: string
}

export function getExcelReport(
  companyID: string,
  report: ReportType,
  from: DateFormat,
  to: DateFormat,
  payRollID?: string,
  voucherID?: string,
  reimbursementVoucherID?: string
): Promise<RequestResponse<ReportCreation>> {
  return secureRequest(
    'GET',
    url('/v2/reportExcel', { companyID, type: report, from, to, payRollID, voucherID, reimbursementVoucherID })
  )
}

export function getPDFReport(
  companyID: string,
  report: ReportType,
  from: DateFormat,
  to: DateFormat,
  payRollID?: string
): Promise<RequestResponse<ReportCreation>> {
  return secureRequest('GET', url('/v2/reportPDF', { companyID, type: report, from, to, payRollID }))
}

export function getGroupExcelReport(
  companyGroupID: string,
  companies: string[],
  report: ReportType,
  from: DateFormat,
  to: DateFormat
): Promise<RequestResponse<ReportCreation>> {
  return secureRequest(
    'GET',
    url('/v2/reportExcel', { companyGroupID, companyID: companies.join(','), type: report, from, to })
  )
}

export function getCSVReport(
  companyID: string,
  report: ReportType,
  from: DateFormat,
  to: DateFormat,
  payRollID?: string,
  voucherID?: string,
  reimbursementVoucherID?: string
): Promise<RequestResponse<ReportCreation>> {
  return secureRequest(
    'GET',
    url('/v2/reportCSV', { companyID, type: report, from, to, payRollID, voucherID, reimbursementVoucherID })
  )
}

export function getStatisticsReport(
  companyID: string,
  period: string,
  year: number
): Promise<RequestResponse<ReportCreation>> {
  return secureRequest('GET', url('/v2/companies/' + companyID + '/statistics', { period, year, format: 'excel' }))
}
