import React, { ReactElement, useEffect } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import { CompanyBankAccountReducer } from '../../reducers/companyBankAccounts'
import { validateEmail } from '../../utils/email-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'

type Props = {
  company: Company
  companyBankAccounts: CompanyBankAccountReducer

  addAlert: addAlertSignature
}

type Fields = {
  contactName?: string
  contactPhone?: string
  contactEmail?: string
  bankRegistrationNumber?: string
  bankAccountNumber?: string
}

export type BankAccountResult = {
  contactName?: string
  contactEmail?: string
  contactPhone: string
  bankRegistrationNumber: string
  bankAccountNumber: string
}

function CompanyBankAccountForm(props: Props & FormComponentProps<Fields, BankAccountResult>): ReactElement | null {
  const { companyBankAccounts, company, addAlert } = props
  const previousCompanyBankAccounts = usePrevious(companyBankAccounts)

  useEffect(() => {
    if (previousCompanyBankAccounts && previousCompanyBankAccounts.saving && !companyBankAccounts.saving) {
      if (!companyBankAccounts.error) {
        addAlert('success', t('company_bank_account.alert.success', { name: company.name }), { timeout: 5 })
      }
    }
  }, [previousCompanyBankAccounts, companyBankAccounts, addAlert, company])

  const { decorateField } = props
  return (
    <div>
      <Row>
        <Col span={8}>
          {decorateField('contactName', {
            placeholder: t('company_bank_account.request.form.contact_name'),
            validate: (val) => (!val ? t('company_bank_account.request.form.validation.contact_name.required') : null),
          })(<Input />)}
        </Col>
        <Col span={8}>
          {decorateField('contactEmail', {
            placeholder: t('company_bank_account.request.form.contact_email'),
            validate: (val) => {
              if (!val) {
                return t('company_bank_account.request.form.validation.contact_email.required')
              }
              if (!validateEmail(val)) {
                return t('company_bank_account.request.form.validation.contact_email.invalid')
              }
              return null
            },
          })(<Input />)}
        </Col>
        <Col span={8}>
          {decorateField('contactPhone', {
            placeholder: t('company_bank_account.request.form.contact_phone'),
            validate: (val) => {
              if (!val) {
                return t('company_bank_account.request.form.validation.contact_phone.required')
              }
              if (!val.match(/^[0-9]{8}$/)) {
                return t('company_bank_account.request.form.validation.contact_phone.invalid')
              }
              return null
            },
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('bankRegistrationNumber', {
            placeholder: t('company_bank_account.request.form.bank_registration_number'),
            validate: (val) => {
              if (!val) {
                return t('company_bank_account.request.form.validation.bank_registration_number.required')
              }
              if (!val.match(/^[0-9]{4}$/)) {
                return t('company_bank_account.request.form.validation.bank_registration_number.invalid')
              }
              return null
            },
          })(<Input />)}
        </Col>
        <Col span={12}>
          {decorateField('bankAccountNumber', {
            placeholder: t('company_bank_account.request.form.bank_account_number'),
            validate: (val) => {
              if (!val) {
                return t('company_bank_account.request.form.validation.bank_account_number.required')
              }
              if (!val.match(/^[0-9]{6,10}$/)) {
                return t('company_bank_account.request.form.validation.bank_account_number.invalid')
              }
              return null
            },
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary" className="gtm-company-bank-account-create">
            {t('company_bank_account.request.form.submit')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, BankAccountResult>({
  onSubmit: (values) => ({
    contactName: values.contactName,
    contactEmail: values.contactEmail,
    contactPhone: values.contactPhone!,
    bankRegistrationNumber: values.bankRegistrationNumber!,
    bankAccountNumber: values.bankAccountNumber!,
  }),
})(CompanyBankAccountForm)
