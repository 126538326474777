import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../../model/company'
import { DocumentCreationFields, DocumentMutableFields } from '../../../model/document'
import DocumentCategory from '../../../model/documentCategory'
import Employee from '../../../model/employee'
import { DocumentReducer } from '../../../reducers/documents'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import DocumentEditForm, { DocumentResult } from './DocumentEditForm'

type Props = {
  visible: boolean
  company: Company
  employee?: Employee
  employees?: List<Employee>
  documentID?: string
  fileID?: string
  documents: DocumentReducer
  documentCategories: List<DocumentCategory>

  addDocument: (companyID: string, document: DocumentCreationFields) => void
  updateDocument: (companyID: string, document: DocumentMutableFields) => void
}

export default function DocumentEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, documents } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, documents.error, error, setError)
  }, [visible, documents, error])

  const handleSubmit = (values: DocumentResult) => {
    if (props.documentID) {
      const document = props.documents.documents.find((document) => document.id === props.documentID)
      if (document) {
        props.updateDocument(props.company.id, {
          ...document,
          documentCategoryID: values.documentCategoryID,
          name: values.name,
          visibleForEmployee: values.visibleForEmployee,
        })
      }
    } else if (props.fileID) {
      props.addDocument(props.company.id, {
        companyID: props.company.id,
        employeeID: props.employee?.id ?? values.employeeID,
        documentCategoryID: values.documentCategoryID,
        name: values.name,
        visibleForEmployee: values.visibleForEmployee,
        fileID: props.fileID,
      })
    }
  }

  return (
    <Card className="document-modal">
      <Subtitle>{props.documentID ? t('document_tab.edit.title.edit') : t('document_tab.edit.title.create')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <DocumentEditForm
        documentID={props.documentID}
        documents={props.documents}
        documentCategories={props.documentCategories}
        employees={props.employees}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
