import React from 'react'

import {
  delDocument,
  delDocumentSigner,
  fetchDocuments,
  postDocument,
  postSignerToDocument,
  putDocument,
} from '../api/documents'
import ActionTypes from '../constants/action-types'
import Document, { DocumentCreationFields, DocumentMutableFields } from '../model/document'
import { DocumentAction } from '../reducers/documents'
import Language from '../types/language'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingDocuments(companyID: string): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_LOADING,
    companyID,
  }
}
function loadedDocuments(companyID: string, documents: Document[]): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_LOADED,
    companyID,
    documents,
  }
}
function failedLoadingDocuments(companyID: string, error: Error): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_LOAD_FAILED,
    companyID,
    error,
  }
}

function addingDocument(companyID: string): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_ADDING,
    companyID,
  }
}
export function addedDocument(companyID: string, document: Document): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_ADDED,
    companyID,
    document,
  }
}
function failedAddingDocument(companyID: string, error: Error): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_ADD_FAILED,
    companyID,
    error,
  }
}

function updatingDocument(companyID: string): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_UPDATING,
    companyID,
  }
}
export function updatedDocument(companyID: string, document: Document): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_UPDATED,
    companyID,
    documentID: document.id,
    document,
  }
}
function failedUpdatingDocument(companyID: string, error: Error): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_UPDATE_FAILED,
    error,
    companyID,
  }
}

function deletingDocument(): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_DELETING,
  }
}
export function deletedDocument(documentID: string): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_DELETED,
    documentID,
  }
}
function failedDeletingDocument(error: Error): DocumentAction {
  return {
    type: ActionTypes.DOCUMENT_DELETE_FAILED,
    error,
  }
}

export function getDocuments() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Document[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingDocuments(companyID))
    return fetchDocuments(companyID)
      .then((res) => {
        dispatch(loadedDocuments(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingDocuments(companyID, e))
        }
      })
  }
}

export function addDocument(companyID: string, document: DocumentCreationFields) {
  return (dispatch: React.Dispatch<any>): Promise<Document | void> => {
    dispatch(addingDocument(companyID))
    return postDocument(document)
      .then((res) => {
        dispatch(addedDocument(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingDocument(companyID, e))
        }
      })
  }
}

export function updateDocument(companyID: string, document: DocumentMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<Document | void> => {
    dispatch(updatingDocument(companyID))
    return putDocument(document)
      .then((res) => {
        dispatch(updatedDocument(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingDocument(companyID, e))
        }
      })
  }
}

export function deleteDocument(id: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(deletingDocument())
    return delDocument(id)
      .then(() => {
        dispatch(deletedDocument(id))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingDocument(e))
        }
      })
  }
}

export function addSignerToDocument(
  document: Document,
  signerEmail: string,
  signerName: string,
  signerLanguage: Language
) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(updatingDocument(document.companyID))
    return postSignerToDocument(document.id, signerEmail, signerName, signerLanguage)
      .then((res) => {
        dispatch(updatedDocument(document.companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingDocument(document.companyID, e))
        }
      })
  }
}

export function deleteDocumentSigner(document: Document, documentSignerID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(updatingDocument(document.companyID))
    return delDocumentSigner(documentSignerID)
      .then((res) => {
        dispatch(updatedDocument(document.companyID, res.data))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingDocument(document.companyID, e))
        }
      })
  }
}
