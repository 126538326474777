import { startOfMonth } from 'date-fns'
import React, { ReactElement, useEffect, useState } from 'react'

import { addAlertSignature } from '../../../actions/alerts'
import Employee from '../../../model/employee'
import { DateFormat } from '../../../model/types'
import { EmploymentReducer } from '../../../reducers/employments'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate, getDate } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import RehireEmployeeForm, { Fields } from './RehireEmployeeForm'

type Props = {
  visible: boolean
  employee: Employee
  employments: EmploymentReducer

  addAlert: addAlertSignature
  rehireEmployee: (employeeID: string, startDate: DateFormat) => Promise<boolean | void>
  closeModal: () => void
}

export default function RehireEmployee(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, employments } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, employments.error, error, setError)
  }, [visible, employments, error])

  const handleSubmit = (values: Fields) => {
    props.rehireEmployee(props.employee.id, formatAPIDate(values.startDate)).then((success) => {
      if (success) {
        const isFreelancer = props.employee.affiliationType === 'Freelancer'
        props.addAlert(
          'success',
          t(
            isFreelancer
              ? 'employee_single.rehire.alert.success.freelancer'
              : 'employee_single.rehire.alert.success.employee',
            { name: props.employee.name }
          ),
          { timeout: 5 }
        )
        props.closeModal()
      }
    })
  }

  const isFreelancer = props.employee.affiliationType === 'Freelancer'

  return (
    <Card className="employees-terminate">
      <Subtitle>
        {isFreelancer ? t('employee_single.rehire.title.freelancer') : t('employee_single.rehire.title.employee')}
      </Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <RehireEmployeeForm
        startDate={isFreelancer ? getDate() : startOfMonth(getDate())}
        employee={props.employee}
        employments={props.employments}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
