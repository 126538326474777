import User from '../model/user'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

type Criipto = {
  url: string
}

export function getUserVerificationUrl(): Promise<RequestResponse<Criipto>> {
  return secureRequest('GET', url('/v2/users/verify'))
}

export function postVerifyUserCallback(signatureToken: string): Promise<RequestResponse<User>> {
  return secureRequest('POST', url('/v2/users/verify'), {
    body: {
      signatureToken,
    },
  })
}
