import OneTimePay, { OneTimePayCreationFields, OneTimePayMutableFields } from '../model/oneTimePay'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchOneTimePays(
  companyID: string | undefined,
  employeeID: string | undefined,
  limit: number,
  offset: number,
  order?: 'dispositionDateDescending' | 'dispositionDateAscending'
): Promise<RequestResponse<OneTimePay[]>> {
  return secureRequest('GET', url('/v2/oneTimePays', { companyID, employeeID, limit, offset, order }))
}

export function postOneTimePay(
  employeeID: string,
  oneTimePay: OneTimePayCreationFields
): Promise<RequestResponse<OneTimePay>> {
  let approved = true
  if (typeof oneTimePay.approved !== 'undefined' && oneTimePay.approved !== null) {
    approved = oneTimePay.approved
  }
  return secureRequest('POST', url('/v2/oneTimePays'), {
    body: {
      employeeID: employeeID,
      type: oneTimePay.type,
      category: oneTimePay.category,
      title: oneTimePay.title,
      payOutType: oneTimePay.payOutType,
      amount: oneTimePay.amount,
      rate: oneTimePay.rate,
      units: oneTimePay.units,
      dispositionDate: oneTimePay.dispositionDate,
      costCenterID: oneTimePay.costCenterID,
      accountingText: oneTimePay.accountingText,
      debitCompanyAccountingAccountID: oneTimePay.debitCompanyAccountingAccountID,
      approved: approved,
    },
  })
}

export function putOneTimePay(oneTimePay: OneTimePayMutableFields): Promise<RequestResponse<OneTimePay>> {
  let approved = true
  if (typeof oneTimePay.approved !== 'undefined' && oneTimePay.approved !== null) {
    approved = oneTimePay.approved
  }
  return secureRequest('PUT', url('/v2/oneTimePays/' + oneTimePay.id), {
    body: {
      type: oneTimePay.type,
      category: oneTimePay.category,
      title: oneTimePay.title,
      payOutType: oneTimePay.payOutType,
      amount: oneTimePay.amount,
      rate: oneTimePay.rate,
      units: oneTimePay.units,
      dispositionDate: oneTimePay.dispositionDate,
      costCenterID: oneTimePay.costCenterID,
      accountingText: oneTimePay.accountingText,
      debitCompanyAccountingAccountID: oneTimePay.debitCompanyAccountingAccountID,
      approved: approved,
    },
  })
}

export function patchOneTimePays(
  action: 'Approve' | 'Unapprove',
  oneTimePayIDs: string[]
): Promise<RequestResponse<OneTimePay[]>> {
  return secureRequest('PATCH', url('/v2/oneTimePays'), {
    body: {
      action,
      oneTimePayIDs,
    },
  })
}

export function delOneTimePay(id: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/oneTimePays/' + id))
}
