import React from 'react'

import {
  fetchPaymentConfiguration,
  fetchPaymentConfigurationStripe,
  patchPaymentConfiguration,
  patchPaymentConfigurationStripe,
  postCompanyBankAccount,
  postPaymentConfigurationStripe,
} from '../api/payment-configurations'
import ActionTypes from '../constants/action-types'
import BankAccount from '../model/bankAccount'
import PaymentConfiguration, { TransferSetting } from '../model/paymentConfiguration'
import StripeConfiguration, { StripeConfigurationSetup } from '../model/stripeConfiguration'
import { CompanyBankAccountAction } from '../reducers/companyBankAccounts'
import { PaymentConfigurationAction } from '../reducers/paymentConfigurations'
import { StripeConfigurationAction } from '../reducers/stripeConfiguration'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingPaymentConfigurations(): PaymentConfigurationAction {
  return {
    type: ActionTypes.PAYMENT_CONFIGURATIONS_LOADING,
  }
}
function loadedPaymentConfigurations(
  companyID: string,
  paymentConfigurations: PaymentConfiguration[]
): PaymentConfigurationAction {
  return {
    type: ActionTypes.PAYMENT_CONFIGURATIONS_LOADED,
    companyID,
    paymentConfigurations,
  }
}
function failedLoadingPaymentConfigurations(companyID: string, error: Error): PaymentConfigurationAction {
  return {
    type: ActionTypes.PAYMENT_CONFIGURATIONS_LOAD_FAILED,
    error,
    companyID,
  }
}

export function addedPaymentConfiguration(
  companyID: string,
  paymentConfiguration: PaymentConfiguration
): PaymentConfigurationAction {
  return {
    type: ActionTypes.PAYMENT_CONFIGURATION_ADDED,
    companyID,
    paymentConfiguration,
  }
}

function updatingPaymentConfiguration(companyID: string): PaymentConfigurationAction {
  return {
    type: ActionTypes.PAYMENT_CONFIGURATION_UPDATING,
    companyID,
  }
}
export function updatedPaymentConfiguration(
  companyID: string,
  paymentConfiguration: PaymentConfiguration
): PaymentConfigurationAction {
  return {
    type: ActionTypes.PAYMENT_CONFIGURATION_UPDATED,
    companyID,
    paymentConfiguration,
  }
}
function failedUpdatingPaymentConfiguration(companyID: string, error: Error): PaymentConfigurationAction {
  return {
    type: ActionTypes.PAYMENT_CONFIGURATION_UPDATE_FAILED,
    error,
    companyID,
  }
}

export function deletedPaymentConfiguration(
  companyID: string,
  paymentConfiguration: PaymentConfiguration
): PaymentConfigurationAction {
  return {
    type: ActionTypes.PAYMENT_CONFIGURATION_DELETED,
    companyID,
    paymentConfiguration,
  }
}

function loadingStripeConfiguration(): StripeConfigurationAction {
  return {
    type: ActionTypes.STRIPE_CONFIGURATION_LOADING,
  }
}
function loadedStripeConfiguration(companyID: string, configuration?: StripeConfiguration): StripeConfigurationAction {
  return {
    type: ActionTypes.STRIPE_CONFIGURATION_LOADED,
    companyID,
    configuration,
  }
}
function failedLoadingStripeConfiguration(companyID: string, error: Error): StripeConfigurationAction {
  return {
    type: ActionTypes.STRIPE_CONFIGURATION_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingStripeConfiguration(companyID: string): StripeConfigurationAction {
  return {
    type: ActionTypes.STRIPE_CONFIGURATION_ADDING,
    companyID,
  }
}
export function addedStripeConfiguration(companyID: string): StripeConfigurationAction {
  return {
    type: ActionTypes.STRIPE_CONFIGURATION_ADDED,
    companyID,
  }
}
function failedAddingStripeConfiguration(companyID: string, error: Error): StripeConfigurationAction {
  return {
    type: ActionTypes.STRIPE_CONFIGURATION_ADD_FAILED,
    error,
    companyID,
  }
}

function updatingStripeConfiguration(companyID: string): StripeConfigurationAction {
  return {
    type: ActionTypes.STRIPE_CONFIGURATION_UPDATING,
    companyID,
  }
}
export function updatedStripeConfiguration(
  companyID: string,
  configuration: StripeConfiguration
): StripeConfigurationAction {
  return {
    type: ActionTypes.STRIPE_CONFIGURATION_UPDATED,
    companyID,
    configuration,
  }
}
function failedUpdatingStripeConfiguration(companyID: string, error: Error): StripeConfigurationAction {
  return {
    type: ActionTypes.STRIPE_CONFIGURATION_UPDATE_FAILED,
    error,
    companyID,
  }
}

function addingCompanyBankAccount(companyID: string): CompanyBankAccountAction {
  return {
    type: ActionTypes.COMPANY_BANK_ACCOUNT_ADDING,
    companyID,
  }
}
function addedCompanyBankAccount(companyID: string): CompanyBankAccountAction {
  return {
    type: ActionTypes.COMPANY_BANK_ACCOUNT_ADDED,
    companyID,
  }
}
function failedAddingCompanyBankAccount(companyID: string, error: Error): CompanyBankAccountAction {
  return {
    type: ActionTypes.COMPANY_BANK_ACCOUNT_ADD_FAILED,
    error,
    companyID,
  }
}

export function getPaymentConfigurations() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<PaymentConfiguration[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingPaymentConfigurations())
    return fetchPaymentConfiguration(companyID)
      .then((res) => {
        dispatch(loadedPaymentConfigurations(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingPaymentConfigurations(companyID, e))
        }
      })
  }
}

export function updatePaymentConfiguration(transferSettings: TransferSetting[]) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<PaymentConfiguration | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingPaymentConfiguration(companyID))
    return patchPaymentConfiguration(companyID, transferSettings)
      .then((res) => {
        dispatch(updatedPaymentConfiguration(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingPaymentConfiguration(companyID, e))
        }
      })
  }
}

export function getStripeConfiguration() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<StripeConfiguration | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingStripeConfiguration())
    return fetchPaymentConfigurationStripe(companyID)
      .then((res) => {
        dispatch(loadedStripeConfiguration(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (e.type === 'NotFound') {
          dispatch(loadedStripeConfiguration(companyID, undefined))
        } else {
          dispatch(failedLoadingStripeConfiguration(companyID, e))
        }
      })
  }
}

export function addStripeConfiguration() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<StripeConfigurationSetup | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingStripeConfiguration(companyID))
    return postPaymentConfigurationStripe(companyID)
      .then((res) => {
        dispatch(addedStripeConfiguration(companyID))
        return res.data
      })
      .catch((e) => {
        dispatch(failedAddingStripeConfiguration(companyID, e))
      })
  }
}

export function updateStripeConfiguration(setupIntentID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<StripeConfiguration | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(updatingStripeConfiguration(companyID))
    return patchPaymentConfigurationStripe(companyID, setupIntentID)
      .then((res) => {
        dispatch(updatedStripeConfiguration(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        dispatch(failedUpdatingStripeConfiguration(companyID, e))
      })
  }
}

export function addCompanyBankAccount(bankAccount: BankAccount) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(addingCompanyBankAccount(companyID))
    return postCompanyBankAccount(companyID, bankAccount)
      .then(() => {
        dispatch(addedCompanyBankAccount(companyID))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingCompanyBankAccount(companyID, e))
        }
      })
  }
}
