import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { CompanyReducer } from '../../reducers/companies'
import { CompanyFeatureReducer } from '../../reducers/companyFeatures'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { paths } from '../../routes'
import { hasDepartmentPermission } from '../../utils/permissions-utils'
import { connectToReducer } from '../../utils/reducer-utils'
import { RoutePropsRoute } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'

import './SubmenuLayout.css'

type Reducers = {
  companyFeatures: CompanyFeatureReducer
  companyUsers: CompanyUserReducer
  companies: CompanyReducer
  children?: ReactElement
}

type Props = {
  route: RoutePropsRoute
  children: React.ReactNode
}

function AdministrationLayout(props: Reducers & Props): ReactElement | null {
  const path = props.route.path.split('/')[0]

  const canSeeSalaryRates = hasDepartmentPermission(props.companyUsers.companyUser, undefined, 'SeeSalaryRates')

  const hasDocumentFeature =
    canSeeSalaryRates &&
    props.companyFeatures.loaded &&
    props.companyFeatures.companyFeatures.some((feature) => feature.featureType === 'Document Store')
  const hasAssetsFeature =
    props.companyFeatures.loaded &&
    props.companyFeatures.companyFeatures.some((feature) => feature.featureType === 'Asset Management')
  const hasProjectFeature =
    (props.companyFeatures.loaded &&
      props.companyFeatures.companyFeatures.some((feature) => feature.featureType === 'Projects') &&
      props.companies.company?.settingsEnabled.some((setting) => setting === 'EnableProjects')) ??
    false

  return (
    <div className="submenu">
      <div className="submenu-topbar">
        {hasDocumentFeature && (
          <Link key={paths.DOCUMENTS} to={'/' + paths.DOCUMENTS} className={path === paths.DOCUMENTS ? 'active' : ''}>
            {t('administration_layout.menu.documents')}
          </Link>
        )}
        {hasAssetsFeature && (
          <Link key={paths.ASSETS} to={'/' + paths.ASSETS} className={path === paths.ASSETS ? 'active' : ''}>
            {t('administration_layout.menu.assets')}
          </Link>
        )}
        {hasProjectFeature && (
          <Link key={paths.PROJECTS} to={'/' + paths.PROJECTS} className={path === paths.PROJECTS ? 'active' : ''}>
            {t('administration_layout.menu.projects')}
          </Link>
        )}
      </div>
      <div className="submenu-main">{props.children}</div>
    </div>
  )
}

export default connectToReducer<Reducers, any, Props>(
  (state) => ({
    companyFeatures: state.companyFeatures,
    companyUsers: state.companyUsers,
    companies: state.companies,
  }),
  {}
)(AdministrationLayout)
