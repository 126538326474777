import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function harvest(
  userID: string | undefined,
  companyID: string | undefined,
  data: Record<string, string | undefined>
): Promise<RequestResponse> {
  userID = userID || undefined
  companyID = companyID || undefined
  const harvests = []
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      const value = data[key]
      if (!value) {
        continue
      }
      harvests.push({
        key,
        value,
        userID,
        companyID,
      })
    }
  }
  return secureRequest('POST', url('/v2/harvest'), { body: harvests })
}
