import { isSameDay, subDays } from 'date-fns'
import React, { ReactElement, useEffect, useState } from 'react'

import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import { ContractReducer } from '../../../reducers/contracts'
import { EmployeeContractDeltaReducer } from '../../../reducers/employeeContractDeltas'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatDate, getDate, isTimeBefore } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Subtitle from '../../elements/Subtitle'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  visible: boolean
  viewingContract: Contract
  employee: Employee
  employeeContractDeltas: EmployeeContractDeltaReducer
  contracts: ContractReducer

  deleteContract: (contractID: string, employeeID: string, removeOrphans: boolean) => void
  setEmployeeContract: (employeeID: string, contractID: string) => void
  closeModal: () => void
}

export default function DeleteContract(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [saving, setSaving] = useState(false)

  const { visible, contracts } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, contracts.error, error, setError)
  }, [visible, contracts, error])

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    setSaving(true)
    const previousContract = props.employeeContractDeltas.contracts
      .map((delta) => delta.contract)
      .filter((contract) => contract.validTo && contract.validTo.localeCompare(props.viewingContract.validFrom))
      .sort((a, b) => a.validFrom.localeCompare(b.validFrom))
      .last()
    props.deleteContract(props.viewingContract.id, props.employee.id, false)
    if (previousContract) {
      props.setEmployeeContract(props.employee.id, previousContract.id)
    }
    props.closeModal()
    setSaving(false)
  }

  if (saving) {
    return (
      <Card>
        <LoadingOverlay />
      </Card>
    )
  }

  const viewingContract = props.viewingContract
  const previousContract = props.employeeContractDeltas.contracts
    .map((delta) => delta.contract)
    .find(
      (contract) =>
        !!contract.validTo && isSameDay(getDate(contract.validTo), subDays(getDate(viewingContract.validFrom), 1))
    )
  const isFutureContract = isTimeBefore(getDate(), getDate(props.viewingContract.validFrom))

  return (
    <Card>
      <Subtitle>{isFutureContract ? t('contract_delete.title.future') : t('contract_delete.title.ordinary')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Row>
        <Col span={24}>
          <p>
            {t(['contract_delete', 'introduction', viewingContract.validTo ? 'end_date' : 'no_end_date'], {
              from: formatDate(viewingContract.validFrom),
              to: viewingContract.validTo ? formatDate(viewingContract.validTo) : '',
            })}
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="danger" onClick={handleSubmit}>
            {t('contract_delete.submit')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div className="ant-form-warning">
            <p>{t('contract_delete.warning')}</p>
            {previousContract && previousContract.validTo && (
              <p>
                {t(
                  [
                    'contract_delete',
                    'warning',
                    'previous_contract',
                    viewingContract.validTo ? 'end_date_changed' : 'end_date_removed',
                  ],
                  {
                    from: formatDate(previousContract.validFrom),
                    to: formatDate(previousContract.validTo),
                    to_new: viewingContract.validTo ? formatDate(viewingContract.validTo) : '',
                  }
                )}
              </p>
            )}
            {!previousContract && <p>{t('contract_delete.warning.no_previous_contract')}</p>}
          </div>
        </Col>
      </Row>
    </Card>
  )
}
