import React, { ReactElement } from 'react'
import { useEffectOnce } from 'react-use'

import Company from '../../model/company'
import { NetsMessageReducer } from '../../reducers/netsMessages'
import { formatDate } from '../../utils/date-utils'
import { formatNetsMessageType } from '../../utils/format-utils'
import { formatCurrency, formatNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Table from '../antd/table'
import Card from '../elements/card'
import Title from '../elements/Title'
import Tooltip from '../elements/tooltip'
import LoadingOverlay from '../widgets/LoadingOverlay'

const columns = [
  { title: t('nets_tab.table.header.date'), dataIndex: 'date', key: 'date', width: '15%' },
  { title: t('nets_tab.table.header.title'), dataIndex: 'title', key: 'title', width: '25%' },
  { title: t('nets_tab.table.header.info'), dataIndex: 'info', key: 'info' },
]

function formatDispositionDateChangeReason(type: string) {
  switch (type) {
    case '01':
      return t('nets_tab.disposition_date_change_reason.01')
    case '02':
      return t('nets_tab.disposition_date_change_reason.02')
    case '03':
      return t('nets_tab.disposition_date_change_reason.03')
    case '04':
      return t('nets_tab.disposition_date_change_reason.04')
    default:
      return type
  }
}

function formatTransferType(type: number) {
  let text = t('common.unknown')
  if (type >= 10 && type <= 29) {
    text = t('nets_tab.transfer_type.10')
  } else if (type >= 30 && type <= 39) {
    text = t('nets_tab.transfer_type.30')
  } else if (40 <= type && type <= 49) {
    text = t('nets_tab.transfer_type.40')
  } else if (50 <= type && type <= 59) {
    text = t('nets_tab.transfer_type.50')
  } else if (60 <= type && type <= 69) {
    text = t('nets_tab.transfer_type.60')
  } else if (70 <= type && type <= 79) {
    text = t('nets_tab.transfer_type.70')
  } else if (80 <= type && type <= 89) {
    text = t('nets_tab.transfer_type.80')
  } else if (type === 90) {
    text = t('nets_tab.transfer_type.90')
  } else if (type >= 91 && type <= 99) {
    text = t('nets_tab.transfer_type.91')
  }
  return (
    <span>
      {text} <em>({type})</em>
    </span>
  )
}

function formatTransactionType(type: string) {
  switch (type) {
    case 'H':
      return t('nets_tab.transaction_type.h')
    case 'A':
      return t('nets_tab.transaction_type.a')
    default:
      return type
  }
}

function formatStopCode(type: number) {
  switch (type) {
    case 1:
      return t('nets_tab.stop_code.1')
    case 3:
      return t('nets_tab.stop_code.3')
    default:
      return type
  }
}

function formatRecordType(type: number) {
  switch (type) {
    case 7:
      return t('nets_tab.record_type.7')
    case 8:
      return t('nets_tab.record_type.8')
    default:
      return type
  }
}

function formatInfoType(type: number) {
  switch (type) {
    case 0:
      return t('nets_tab.info_type.0')
    case 100:
      return t('nets_tab.info_type.100')
    case 150:
      return t('nets_tab.info_type.150')
    case 200:
      return t('nets_tab.info_type.200')
    case 300:
      return t('nets_tab.info_type.300')
    case 400:
      return t('nets_tab.info_type.400')
    case 800:
      return t('nets_tab.info_type.800')
    case 900:
      return t('nets_tab.info_type.900')
    default:
      return type
  }
}

function formatLimitType(type: number) {
  switch (type) {
    case 2:
      return <Tooltip title={t('nets_tab.limit_type.2.tool_tip')}>{t('nets_tab.limit_type.2.text')}</Tooltip>
    case 3:
      return <Tooltip title={t('nets_tab.limit_type.3.tool_tip')}>{t('nets_tab.limit_type.3.text')}</Tooltip>
    case 4:
      return <Tooltip title={t('nets_tab.limit_type.4.tool_tip')}>{t('nets_tab.limit_type.4.text')}</Tooltip>
    default:
      return type
  }
}

function formatErrorCode(type: string) {
  switch (type) {
    case 'D':
      return t('nets_tab.error_code.d')
    case 'K':
      return t('nets_tab.error_code.k')
    case 'P':
      return t('nets_tab.error_code.p')
    case 'R':
      return t('nets_tab.error_code.r')
    case 'S':
      return t('nets_tab.error_code.s')
    case 'T':
      return t('nets_tab.error_code.t')
    case '1':
      return t('nets_tab.error_code.1')
    case '2':
      return t('nets_tab.error_code.2')
    case '3':
      return t('nets_tab.error_code.3')
    case '4':
      return t('nets_tab.error_code.4')
    case '5':
      return t('nets_tab.error_code.5')
    case '6':
      return t('nets_tab.error_code.6')
    case '7':
      return t('nets_tab.error_code.7')
    case '8':
      return t('nets_tab.error_code.8')
    case '9':
      return t('nets_tab.error_code.9')
    default:
      return type
  }
}

type Props = {
  company: Company
  netsMessages: NetsMessageReducer

  getNetsMessages: () => void
}

export default function NetsTab(props: Props): ReactElement | null {
  useEffectOnce(() => {
    if (!props.netsMessages.loading && !props.netsMessages.loaded) {
      props.getNetsMessages()
    }
  })

  const getMessages = () => {
    return props.netsMessages.netsMessages
      .map((message) => {
        const info = []
        const data = JSON.parse(message.messageData)
        switch (message.messageType) {
          case 'DispositionChanged':
            info.push(
              <div key={1}>
                <em>{t('nets_tab.message.disposition_changed.reason')}:</em>{' '}
                {formatDispositionDateChangeReason(data.DispositionDateChangeReason)}
              </div>
            )
            info.push(
              <div key={2}>
                <em>{t('nets_tab.message.disposition_changed.cvr')}:</em> {data.PayerCVR}
              </div>
            )
            info.push(
              <div key={3}>
                <em>{t('nets_tab.message.disposition_changed.registration_number')}:</em> {data.FromRegistrationNumber}
              </div>
            )
            info.push(
              <div key={4}>
                <em>{t('nets_tab.message.disposition_changed.account_number')}:</em> {data.FromAccountNumber}
              </div>
            )
            info.push(
              <div key={5}>
                <em>{t('nets_tab.message.disposition_changed.transfer_type')}:</em>{' '}
                {formatTransferType(data.TransferType)}
              </div>
            )
            info.push(
              <div key={6}>
                <em>{t('nets_tab.message.disposition_changed.original_disposition_date')}:</em>{' '}
                {formatDate(data.OriginalDispositionDate)}
              </div>
            )
            info.push(
              <div key={7}>
                <em>{t('nets_tab.message.disposition_changed.record_count')}:</em> {formatNumber(data.RecordCount)}
              </div>
            )
            info.push(
              <div key={8}>
                <em>{t('nets_tab.message.disposition_changed.amount')}:</em> {formatCurrency(data.Amount, 2)}
              </div>
            )
            info.push(
              <div key={9}>
                <em>{t('nets_tab.message.disposition_changed.new_disposition_date')}:</em>{' '}
                {formatDate(data.NewDispositionDate)}
              </div>
            )
            info.push(
              <div key={10}>
                <em>{t('nets_tab.message.disposition_changed.transaction_type')}:</em>{' '}
                {formatTransactionType(data.TransactionType)}
              </div>
            )
            if (data.DispositionDateChangeReason === '02') {
              info.push(
                <div key={11}>
                  <em>{t('nets_tab.message.disposition_changed.next_bank_day')}:</em> {formatDate(data.NextBankingDate)}
                </div>
              )
            }
            break
          case 'Stop':
            info.push(
              <div key={1}>
                <em>{t('nets_tab.message.stop.code')}:</em> {formatStopCode(data.StopCode)}
              </div>
            )
            info.push(
              <div key={2}>
                <em>{t('nets_tab.message.stop.cvr')}:</em> {data.PayerCVR}
              </div>
            )
            info.push(
              <div key={3}>
                <em>{t('nets_tab.message.stop.name')}:</em> {data.PayerName}
              </div>
            )
            info.push(
              <div key={4}>
                <em>{t('nets_tab.message.stop.registration_number')}:</em> {data.FromRegistrationNumber}
              </div>
            )
            info.push(
              <div key={5}>
                <em>{t('nets_tab.message.stop.account_number')}:</em> {data.FromAccountNumber}
              </div>
            )
            info.push(
              <div key={6}>
                <em>{t('nets_tab.message.stop.transfer_type')}:</em> {formatTransferType(data.TransferType)}
              </div>
            )
            info.push(
              <div key={7}>
                <em>{t('nets_tab.message.stop.disposition_date')}:</em> {formatDate(data.DispositionDate)}
              </div>
            )
            info.push(
              <div key={8}>
                <em>{t('nets_tab.message.stop.record_count')}:</em> {formatNumber(data.RecordCount)}
              </div>
            )
            info.push(
              <div key={9}>
                <em>{t('nets_tab.message.stop.amount')}:</em> {formatCurrency(data.Amount, 2)}
              </div>
            )
            break
          case 'TransactionReceipt':
            info.push(
              <div key={1}>
                <em>{t('nets_tab.message.transaction_receipt.transfer_type')}:</em>{' '}
                {formatTransferType(data.TransferType)}
              </div>
            )
            info.push(
              <div key={2}>
                <em>{t('nets_tab.message.transaction_receipt.transaction_type')}:</em>{' '}
                {formatTransactionType(data.TransactionType)}
              </div>
            )
            info.push(
              <div key={3}>
                <em>{t('nets_tab.message.transaction_receipt.bookkeeping_date')}:</em>{' '}
                {formatDate(data.BookkeepingDate)}
              </div>
            )
            info.push(
              <div key={4}>
                <em>{t('nets_tab.message.transaction_receipt.record_count')}:</em> {formatNumber(data.RecordCount)}
              </div>
            )
            info.push(
              <div key={5}>
                <em>{t('nets_tab.message.transaction_receipt.amount')}:</em> {formatCurrency(data.Amount, 2)}
              </div>
            )
            info.push(
              <div key={6}>
                <em>{t('nets_tab.message.transaction_receipt.rejected_record_count')}:</em>{' '}
                {formatNumber(data.RejectedRecordCount)}
              </div>
            )
            info.push(
              <div key={7}>
                <em>{t('nets_tab.message.transaction_receipt.rejected_amount')}:</em>{' '}
                {formatCurrency(data.RejectedAmount, 2)}
              </div>
            )
            info.push(
              <div key={8}>
                <em>{t('nets_tab.message.transaction_receipt.record_type')}:</em> {formatRecordType(data.RecordType)}
              </div>
            )
            info.push(
              <div key={9}>
                <em>{t('nets_tab.message.transaction_receipt.registration_number')}:</em> {data.FromRegistrationNumber}
              </div>
            )
            info.push(
              <div key={10}>
                <em>{t('nets_tab.message.transaction_receipt.account_number')}:</em> {data.FromAccountNumber}
              </div>
            )
            info.push(
              <div key={11}>
                <em>{t('nets_tab.message.transaction_receipt.info_type')}:</em> {formatInfoType(data.InfoType)}
              </div>
            )
            break
          case 'MaximumAmount':
            info.push(
              <div key={1}>
                <em>{t('nets_tab.message.maximum_amount.maximum_amount')}:</em> {formatCurrency(data.MaximumAmount, 0)}
              </div>
            )
            info.push(
              <div key={2}>
                <em>{t('nets_tab.message.maximum_amount.limit_type')}:</em> {formatLimitType(data.LimitType)}
              </div>
            )
            break
          case 'Comment':
            info.push(
              <div key={1}>
                <em>{t('nets_tab.message.comment.error_text_1')}:</em> {data.ErrorText1}
              </div>
            )
            info.push(
              <div key={2}>
                <em>{t('nets_tab.message.comment.error_text_2')}:</em> {data.ErrorText2}
              </div>
            )
            info.push(
              <div key={3}>
                <em>{t('nets_tab.message.comment.transfer_type')}:</em> {formatTransferType(data.TransferType)}
              </div>
            )
            info.push(
              <div key={4}>
                <em>{t('nets_tab.message.comment.record_count')}:</em> {formatNumber(data.RecordCount)}
              </div>
            )
            info.push(
              <div key={5}>
                <em>{t('nets_tab.message.comment.amount')}:</em> {formatCurrency(data.Amount, 2)}
              </div>
            )
            info.push(
              <div key={6}>
                <em>{t('nets_tab.message.comment.reference')}:</em> {data.Reference}
              </div>
            )
            info.push(
              <div key={7}>
                <em>{t('nets_tab.message.comment.retracted_amount')}:</em> {formatCurrency(data.Amount, 2)}
              </div>
            )
            info.push(
              <div key={8}>
                <em>{t('nets_tab.message.comment.error_code')}:</em> {formatErrorCode(data.ErrorCode)}
              </div>
            )
            break
          case 'Info':
            info.push(
              <div key={1}>
                <em>{t('nets_tab.message.info.transfer_type')}:</em> {formatTransferType(data.TransferType)}
              </div>
            )
            info.push(
              <div key={2}>
                <em>{t('nets_tab.message.info.info_type')}:</em> {formatInfoType(data.InfoType)}
              </div>
            )
            info.push(
              <div key={3}>
                <em>{t('nets_tab.message.info.pbs')}:</em> {data.PBSNumber}
              </div>
            )
            info.push(
              <div key={4}>
                <em>{t('nets_tab.message.info.cpr')}:</em> {data.CPRNumber}
              </div>
            )
            info.push(
              <div key={5}>
                <em>{t('nets_tab.message.info.customer_number')}:</em> {data.CustomerNumber}
              </div>
            )
            info.push(
              <div key={6}>
                <em>{t('nets_tab.message.info.amount')}:</em> {formatCurrency(data.Amount, 2)}
              </div>
            )
            info.push(
              <div key={7}>
                <em>{t('nets_tab.message.info.error_code')}:</em> {formatErrorCode(data.ErrorCode)}
              </div>
            )
            break
          case 'DoubleDelivery':
            info.push(
              <div key={1}>
                <em>{t('nets_tab.message.double_delivery.cvr')}:</em> {data.PayerCVR}
              </div>
            )
            info.push(
              <div key={2}>
                <em>{t('nets_tab.message.double_delivery.registration_number')}:</em> {data.FromRegistrationNumber}
              </div>
            )
            info.push(
              <div key={3}>
                <em>{t('nets_tab.message.double_delivery.account_number')}:</em> {data.FromAccountNumber}
              </div>
            )
            info.push(
              <div key={4}>
                <em>{t('nets_tab.message.double_delivery.cvr_supplier')}:</em> {data.DataSupplierCVR}
              </div>
            )
            info.push(
              <div key={5}>
                <em>{t('nets_tab.message.double_delivery.delivery_number')}:</em> {data.DeliveryNumber}
              </div>
            )
            info.push(
              <div key={6}>
                <em>{t('nets_tab.message.double_delivery.section_number')}:</em> {data.SectionNumber}
              </div>
            )
            info.push(
              <div key={7}>
                <em>{t('nets_tab.message.double_delivery.disposition_date')}:</em> {formatDate(data.DispositionDate)}
              </div>
            )
            info.push(
              <div key={8}>
                <em>{t('nets_tab.message.double_delivery.transfer_type')}:</em> {formatTransferType(data.TransferType)}
              </div>
            )
            info.push(
              <div key={9}>
                <em>{t('nets_tab.message.double_delivery.record_count')}:</em> {formatNumber(data.RecordCount)}
              </div>
            )
            info.push(
              <div key={10}>
                <em>{t('nets_tab.message.double_delivery.amount')}:</em> {formatCurrency(data.Amount, 2)}
              </div>
            )
            break
          default:
            for (const key in data) {
              info.push(
                <div key={key}>
                  <em>{key}</em> {data[key]}
                </div>
              )
            }
            break
        }
        return {
          key: message.id,
          id: message.id,
          date: formatDate(message.receivedAt),
          title: formatNetsMessageType(message.messageType),
          info: info,
        }
      })
      .toArray()
  }

  if (!props.netsMessages.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <Card>
      <Title>{t('nets_tab.title')}</Title>

      <Table columns={columns} dataSource={getMessages()} size="small" pagination={false} />
    </Card>
  )
}
