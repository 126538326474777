// functions to handle js to ts issues for antd components

import { RequestError } from './request-utils'

interface FileChangeEventFileResponseData {
  id: string
  mimeType?: string
}

interface FileChangeEventFileResponseError {
  details?: string[]
  message: string
  type: string
}

export function HandleFileEventError(response: FileChangeEventFileResponse): RequestError | undefined {
  const error = response?.error
  if (error) {
    return new RequestError(error.message, error.type, error.details || [])
  }
  return undefined
}

interface FileChangeEventFileResponse {
  data?: FileChangeEventFileResponseData
  error?: FileChangeEventFileResponseError
}

interface FileChangeEventFile {
  status: 'uploading' | 'done' | 'error'
  response: FileChangeEventFileResponse
}

export interface FileChangeEvent {
  file: FileChangeEventFile
}
