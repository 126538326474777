import { List } from 'immutable'
import React, { ReactElement, useEffect } from 'react'
import { Link } from 'react-router'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import CompanySetting from '../../model/companySetting'
import Employee from '../../model/employee'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyPaymentIntegrationReducer } from '../../reducers/companyPaymentIntegrations'
import { paths } from '../../routes'
import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import LoadingOverlay from '../widgets/LoadingOverlay'
import TransferImmediatePayForm, { TransferImmediatePayResult } from './TransferImmediatePayForm'

type Props = {
  companies: CompanyReducer
  company: Company
  employees: List<Employee>
  companyFeatures: List<CompanyFeature>
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer

  addAlert: addAlertSignature
  updateCompany: (company: Company) => Promise<Company | void>
  getCompanyPaymentIntegrations: () => void
  enableCompanySettings: (companyID: string, enable: CompanySetting[]) => void
  disableCompanySettings: (companyID: string, disable: CompanySetting[]) => void
}

export default function TransferImmediatePay(props: Props): ReactElement | null {
  const { companyPaymentIntegrations, getCompanyPaymentIntegrations } = props
  useEffect(() => {
    if (!companyPaymentIntegrations.loaded && !companyPaymentIntegrations.loading) {
      getCompanyPaymentIntegrations()
    }
  })

  const hasSwipeFeature = () => {
    return props.companyFeatures.some((feature) => feature.featureType === 'Swipe')
  }
  const hasImmediatePayFeature = () => {
    return props.companyFeatures.some((feature) => feature.featureType === 'Immediate Pay')
  }
  const hasReimbursementVoucherFeature = () => {
    return props.companyFeatures.some((feature) => feature.featureType === 'Reimbursement Vouchers')
  }
  const hasZimpler = companyPaymentIntegrations.companyPaymentIntegrations.some(
    (integration) => integration.paymentIntegrationType === 'Zimpler'
  )

  const handleSubmit = (values: TransferImmediatePayResult) => {
    props
      .updateCompany({
        ...props.company,
        enableImmediatePay: values.enableImmediatePay,
        enableSwipe: values.enableSwipe,
        periodicLimitForImmediatePayOut: values.periodicLimitForImmediatePayOut,
        swipeEmployeeTypes: values.swipeEmployeeTypes,
        swipeNotificationAt: values.swipeNotificationAt,
        enableAlwaysCurrentPayRollRun: values.enableAlwaysCurrentPayRollRun,
        swipeInstantCostPayer: values.swipeInstantCostPayer,
        swipeFeeCost: values.swipeFeeCost,
        swipeFeeCostType: values.swipeFeeCostType,
        swipeFeeCostCap: values.swipeFeeCostCap,
        swipeFeeCostFrequency: values.swipeFeeCostFrequency,
      })
      .then((res) => {
        if (!res) {
          return
        }
        if (values.autoApproveTimeRegistrationHours) {
          props.enableCompanySettings(props.company.id, ['AutoApproveTimeRegistrationHours'])
        } else {
          props.disableCompanySettings(props.company.id, ['AutoApproveTimeRegistrationHours'])
        }
      })
  }

  if (!companyPaymentIntegrations.loaded) {
    return <LoadingOverlay />
  }

  return (
    <Card>
      <TitleMenu>
        {hasReimbursementVoucherFeature() && (
          <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/reimbursement-settings'}>
            <Button>{t('transfer_immediate_pay.header.reimbursement_settings')}</Button>
          </Link>
        )}
        <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/transfers'}>
          <Button>{t('transfer_immediate_pay.header.transfers')}</Button>
        </Link>
      </TitleMenu>
      {hasSwipeFeature() && hasImmediatePayFeature() && (
        <Title>{t('transfer_immediate_pay.title.immediate_and_swipe')}</Title>
      )}
      {hasSwipeFeature() && !hasImmediatePayFeature() && <Title>{t('transfer_immediate_pay.title.swipe')}</Title>}
      {!hasSwipeFeature() && hasImmediatePayFeature() && <Title>{t('transfer_immediate_pay.title.immediate')}</Title>}

      {hasImmediatePayFeature() && (
        <p>
          {tx('transfer_immediate_pay.description.immediate_pay', {
            immediate: <strong>{t('transfer_immediate_pay.description.immediate_pay.title')}</strong>,
          })}
        </p>
      )}
      {hasSwipeFeature() && (
        <p>
          {tx(
            hasZimpler
              ? 'transfer_immediate_pay.description.swipe.with_zimpler'
              : 'transfer_immediate_pay.description.swipe.without_zimpler',
            { title: <strong>{t('transfer_immediate_pay.description.swipe.title')}</strong> }
          )}
        </p>
      )}

      {hasZimpler && <p>{t('transfer_immediate_pay.description.zimpler')}</p>}

      <p>
        {hasZimpler
          ? t('transfer_immediate_pay.description.payment_service.with_zimpler')
          : t('transfer_immediate_pay.description.payment_service.without_zimpler')}
      </p>

      {hasSwipeFeature() && (
        <p>
          {tx('transfer_immediate_pay.description.swipe_cost', {
            cost: <strong>{t('transfer_immediate_pay.description.swipe_cost.cost')}</strong>,
          })}
        </p>
      )}

      <TransferImmediatePayForm
        companies={props.companies}
        company={props.company}
        employees={props.employees}
        addAlert={props.addAlert}
        hasSwipeFeature={hasSwipeFeature()}
        hasImmediatePayFeature={hasImmediatePayFeature()}
        hasZimpler={hasZimpler}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
