import React from 'react'

import {
  delCompanyDeviationConfiguration,
  fetchCompanyDeviationConfigurations,
  postCompanyDeviationConfiguration,
  putCompanyDeviationConfiguration,
} from '../api/company-deviation-configurations'
import ActionTypes from '../constants/action-types'
import CompanyDeviationConfiguration, {
  CompanyDeviationConfigurationMutableFields,
} from '../model/companyDeviationConfiguration'
import { CompanyDeviationConfigurationAction } from '../reducers/companyDeviationConfigurations'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingCompanyDeviationConfigurations(): CompanyDeviationConfigurationAction {
  return {
    type: ActionTypes.COMPANY_DEVIATION_CONFIGURATION_LOADING,
  }
}
export function loadedCompanyDeviationConfigurations(
  companyID: string,
  companyDeviationConfigurations: CompanyDeviationConfiguration[]
): CompanyDeviationConfigurationAction {
  return {
    type: ActionTypes.COMPANY_DEVIATION_CONFIGURATION_LOADED,
    companyDeviationConfigurations,
    companyID,
  }
}
function failedLoadingCompanyDeviationConfigurations(
  companyID: string,
  error: Error
): CompanyDeviationConfigurationAction {
  return {
    type: ActionTypes.COMPANY_DEVIATION_CONFIGURATION_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingCompanyDeviationConfiguration(): CompanyDeviationConfigurationAction {
  return {
    type: ActionTypes.COMPANY_DEVIATION_CONFIGURATION_ADDING,
  }
}
export function addedCompanyDeviationConfiguration(
  companyID: string,
  companyDeviationConfiguration: CompanyDeviationConfiguration
): CompanyDeviationConfigurationAction {
  return {
    type: ActionTypes.COMPANY_DEVIATION_CONFIGURATION_ADDED,
    companyID,
    companyDeviationConfiguration,
  }
}
function failedAddingCompanyDeviationConfiguration(
  companyID: string,
  error: Error
): CompanyDeviationConfigurationAction {
  return {
    type: ActionTypes.COMPANY_DEVIATION_CONFIGURATION_ADD_FAILED,
    error,
    companyID,
  }
}

function updatingCompanyDeviationConfiguration(
  companyID: string,
  companyDeviationConfigurationID: string
): CompanyDeviationConfigurationAction {
  return {
    type: ActionTypes.COMPANY_DEVIATION_CONFIGURATION_UPDATING,
    companyID,
    companyDeviationConfigurationID,
  }
}
export function updatedCompanyDeviationConfiguration(
  companyID: string,
  companyDeviationConfigurationID: string,
  companyDeviationConfiguration: CompanyDeviationConfiguration
): CompanyDeviationConfigurationAction {
  return {
    type: ActionTypes.COMPANY_DEVIATION_CONFIGURATION_UPDATED,
    companyID,
    companyDeviationConfigurationID,
    companyDeviationConfiguration,
  }
}
function failedUpdatingCompanyDeviationConfiguration(
  companyID: string,
  companyDeviationConfigurationID: string,
  error: Error
): CompanyDeviationConfigurationAction {
  return {
    type: ActionTypes.COMPANY_DEVIATION_CONFIGURATION_UPDATE_FAILED,
    error,
    companyID,
    companyDeviationConfigurationID,
  }
}

function deletingCompanyDeviationConfiguration(
  companyID: string,
  companyDeviationConfigurationID: string
): CompanyDeviationConfigurationAction {
  return {
    type: ActionTypes.COMPANY_DEVIATION_CONFIGURATION_DELETING,
    companyID,
    companyDeviationConfigurationID,
  }
}
export function deletedCompanyDeviationConfiguration(
  companyID: string,
  companyDeviationConfigurationID: string
): CompanyDeviationConfigurationAction {
  return {
    type: ActionTypes.COMPANY_DEVIATION_CONFIGURATION_DELETED,
    companyID,
    companyDeviationConfigurationID,
  }
}
function failedDeletingCompanyDeviationConfiguration(
  companyID: string,
  companyDeviationConfigurationID: string,
  error: Error
): CompanyDeviationConfigurationAction {
  return {
    type: ActionTypes.COMPANY_DEVIATION_CONFIGURATION_DELETE_FAILED,
    error,
    companyID,
    companyDeviationConfigurationID,
  }
}

export function addCompanyDeviationConfiguration(
  companyDeviationConfiguration: CompanyDeviationConfigurationMutableFields
) {
  return (
    dispatch: React.Dispatch<any>,
    getState?: getStateSignature
  ): Promise<CompanyDeviationConfiguration | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingCompanyDeviationConfiguration())
    return postCompanyDeviationConfiguration(companyID, companyDeviationConfiguration)
      .then((res) => {
        dispatch(addedCompanyDeviationConfiguration(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingCompanyDeviationConfiguration(companyID, e))
        }
      })
  }
}

export function getCompanyDeviationConfigurations() {
  return (
    dispatch: React.Dispatch<any>,
    getState?: getStateSignature
  ): Promise<CompanyDeviationConfiguration[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingCompanyDeviationConfigurations())
    return fetchCompanyDeviationConfigurations(companyID)
      .then((res) => {
        dispatch(loadedCompanyDeviationConfigurations(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompanyDeviationConfigurations(companyID, e))
        }
      })
  }
}

export function updateCompanyDeviationConfiguration(companyDeviationConfiguration: CompanyDeviationConfiguration) {
  return (
    dispatch: React.Dispatch<any>,
    getState?: getStateSignature
  ): Promise<CompanyDeviationConfiguration | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingCompanyDeviationConfiguration(companyID, companyDeviationConfiguration.id))
    return putCompanyDeviationConfiguration(companyID, companyDeviationConfiguration)
      .then((res) => {
        dispatch(updatedCompanyDeviationConfiguration(companyID, res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompanyDeviationConfiguration(companyID, companyDeviationConfiguration.id, e))
        }
      })
  }
}

export function deleteCompanyDeviationConfiguration(companyDeviationConfigurationID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingCompanyDeviationConfiguration(companyID, companyDeviationConfigurationID))
    return delCompanyDeviationConfiguration(companyDeviationConfigurationID)
      .then(() => {
        dispatch(deletedCompanyDeviationConfiguration(companyID, companyDeviationConfigurationID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingCompanyDeviationConfiguration(companyID, companyDeviationConfigurationID, e))
        }
      })
  }
}
