import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import TaxCard from '../model/taxCard'
import { ReducerAction } from '../utils/reducer-utils'

export interface TaxCardReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  taxCards: List<TaxCard>
  error: Error | null
}

const Params = Record<TaxCardReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  taxCards: List<TaxCard>(),
  error: null,
})

export interface TaxCardAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  taxCards?: TaxCard[]
  taxCard?: TaxCard
}

const comparator = (a: TaxCard, b: TaxCard) => {
  if (a.activeForCurrentPeriod !== b.activeForCurrentPeriod) {
    return a.activeForCurrentPeriod ? -1 : 1
  }
  return b.validFromDate.localeCompare(a.validFromDate, 'da-dk')
}

export default (
  state: Record<TaxCardReducer> = Params(),
  action: TaxCardAction = { type: '' }
): Record<TaxCardReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.TAX_CARD_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.TAX_CARD_LOADED:
    case ActionTypes.TAX_CARD_LOADED_PARTIAL:
      return Params({
        loaded: true,
        loading: action.type === ActionTypes.TAX_CARD_LOADED_PARTIAL,
        companyID: state.get('companyID'),
        employeeID: state.get('employeeID'),
        taxCards: state.get('taxCards').concat(List(action.taxCards)).sort(comparator),
      })
    case ActionTypes.TAX_CARD_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        employeeID: state.get('employeeID'),
        error: action.error || null,
      })

    case ActionTypes.TAX_CARD_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.TAX_CARD_UPDATED:
      if (!action.taxCard) {
        return state
      }
      idx = state.get('taxCards').findIndex((item) => (item && action.taxCard ? item.id === action.taxCard.id : false))
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state
        .set('saving', false)
        .set('taxCards', state.get('taxCards').set(idx, action.taxCard).sort(comparator).toList())
    case ActionTypes.TAX_CARD_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.TAX_CARD_ADDED:
      if (!action.taxCard) {
        return state
      }
      idx = state.get('taxCards').findIndex((item) => (item && action.taxCard ? item.id === action.taxCard.id : false))
      if (idx !== -1) {
        return state
      }
      return state.set('taxCards', state.get('taxCards').push(action.taxCard).sort(comparator).toList())

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
