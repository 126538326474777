import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import NetsMessage from '../model/netsMessage'
import { ReducerAction } from '../utils/reducer-utils'

export interface NetsMessageReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  netsMessages: List<NetsMessage>
  error: Error | null
}

const Params = Record<NetsMessageReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  netsMessages: List<NetsMessage>(),
  error: null,
})

export interface NetsMessageAction extends ReducerAction {
  companyID?: string
  netsMessages?: NetsMessage[]
}

const comparator = (a: NetsMessage, b: NetsMessage) => {
  return b.receivedAt.localeCompare(a.receivedAt, 'da-dk')
}

export default (
  state: Record<NetsMessageReducer> = Params(),
  action: NetsMessageAction = { type: '' }
): Record<NetsMessageReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.NETS_MESSAGES_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.NETS_MESSAGES_LOADED:
    case ActionTypes.NETS_MESSAGES_LOADED_PARTIAL:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        loading: action.type === ActionTypes.NETS_MESSAGES_LOADED_PARTIAL,
        netsMessages: state.get('netsMessages').concat(List(action.netsMessages)).sort(comparator),
      })
    case ActionTypes.NETS_MESSAGES_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
