import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import { SalaryClass, SalaryTypeMutableFields } from '../../model/salaryType'
import { SalaryTypeReducer } from '../../reducers/salaryTypes'
import { listSalaryTypesWithNumberDiscriminator } from '../../utils/salary-type-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Table from '../antd/table'
import Button from '../elements/button'
import Card from '../elements/card'
import Icon from '../elements/Icon'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import SalaryTypeEdit from './SalaryTypeEdit'

type Props = {
  salaryTypes: SalaryTypeReducer
  company: Company

  addAlert: addAlertSignature
  addSalaryType: (salaryType: SalaryTypeMutableFields) => void
  updateSalaryType: (salaryType: SalaryTypeMutableFields) => void
  deleteSalaryType: (salaryTypeID: string) => void
  reactivateSalaryType: (salaryTypeID: string) => void
}

export default function SalaryTypesTab(props: Props): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [salaryClass, setSalaryClass] = useState<SalaryClass>()
  const [editing, setEditing] = useState<string | boolean>(false)
  const [showDeactivated, setShowDeactivated] = useState(false)

  const { salaryTypes } = props
  const previousSalaryTypes = usePrevious(salaryTypes)

  const setEditVisibility = useCallback((id: string | boolean, salaryClass?: SalaryClass) => {
    setModalKey((prev) => prev + 1)
    setEditing(id)
    setSalaryClass(salaryClass)
  }, [])

  useEffect(() => {
    if (previousSalaryTypes && previousSalaryTypes.saving && !salaryTypes.saving) {
      if (!salaryTypes.error) {
        setEditVisibility(false)
      }
    }
  }, [previousSalaryTypes, salaryTypes, setEditVisibility])

  const remove = (salaryTypeID: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, salaryTypeID])
        props.deleteSalaryType(salaryTypeID)
      }
    }
  }

  const reactivate = (salaryTypeID: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      props.reactivateSalaryType(salaryTypeID)
    }
  }

  type SalaryTypeRow = {
    key: string
    id: string
    title: string
    class: string
    supplements?: number
    active: boolean
    isHourly: boolean
    includeInVacationBasis: string
    includeInPensionBasis: string
    includeInSHFritvalgBasis: string
  }

  const columns = [
    { title: t('salary_types.tab.table.header.title'), dataIndex: 'title', key: 'title' },
    {
      title: t('salary_types.tab.table.header.class'),
      dataIndex: '',
      key: 'xClass',
      render: (salaryType: SalaryTypeRow) => {
        if (!salaryType.supplements) {
          return salaryType.class
        }
        return (
          <>
            {salaryType.class}
            <br />
            <small>{t('salary_types.tab.table.with_supplements', { count: salaryType.supplements })}</small>
          </>
        )
      },
    },
    {
      title: t('salary_types.tab.table.header.status'),
      dataIndex: '',
      key: 'xStatus',
      render: (salaryType: SalaryTypeRow) =>
        salaryType.active ? t('salary_types.tab.table.active.true') : t('salary_types.tab.table.active.false'),
    },
    {
      title: t('salary_types.tab.table.header.include_in_vacation_basis'),
      dataIndex: 'includeInVacationBasis',
      key: 'includeInVacationBasis',
    },
    {
      title: t('salary_types.tab.table.header.include_in_pension_basis'),
      dataIndex: 'includeInPensionBasis',
      key: 'includeInPensionBasis',
    },
    {
      title: t('salary_types.tab.table.header.include_in_sh_fritvalg_basis'),
      dataIndex: 'includeInSHFritvalgBasis',
      key: 'includeInSHFritvalgBasis',
    },
    {
      title: '',
      dataIndex: '',
      key: 'x1',
      className: 'company-table-actions',
      render: (salaryType: SalaryTypeRow) => {
        return (
          <div>
            <Tooltip
              title={
                salaryType.isHourly
                  ? t('salary_types.tab.table.actions.hourly.edit')
                  : t('salary_types.tab.table.actions.supplement_varied.edit')
              }
            >
              <span onClick={() => setEditVisibility(salaryType.id)} style={{ cursor: 'pointer' }}>
                <Icon type="edit" color="grey" />
              </span>
            </Tooltip>
            {salaryType.active && deleting.indexOf(salaryType.id) === -1 && (
              <Tooltip
                title={
                  salaryType.isHourly
                    ? t('salary_types.tab.table.actions.hourly.remove')
                    : t('salary_types.tab.table.actions.supplement_varied.remove')
                }
              >
                <span onClick={remove(salaryType.id)} style={{ cursor: 'pointer' }}>
                  <Icon type="cross" color="grey" />
                </span>
              </Tooltip>
            )}
            {!salaryType.active && (
              <Tooltip
                title={
                  salaryType.isHourly
                    ? t('salary_types.tab.table.actions.hourly.reactivate')
                    : t('salary_types.tab.table.actions.supplement_varied.reactivate')
                }
              >
                <span onClick={reactivate(salaryType.id)} style={{ cursor: 'pointer' }}>
                  <Icon type="refresh" color="grey" />
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const getSalaryTypes = (): SalaryTypeRow[] => {
    return listSalaryTypesWithNumberDiscriminator(props.salaryTypes.salaryTypes.toArray())
      .filter(
        (salaryType) =>
          salaryType.active !== showDeactivated &&
          (salaryType.class === 'Hourly' || salaryType.class === 'SupplementVaried')
      )
      .map(
        (salaryType): SalaryTypeRow => ({
          key: salaryType.id,
          id: salaryType.id,
          title: salaryType.displayTitle,
          active: salaryType.active,
          class:
            salaryType.class === 'Hourly'
              ? t('salary_types.tab.table.class.hourly')
              : t('salary_types.tab.table.class.supplement_varied'),
          supplements: salaryType.supplements ? salaryType.supplements.length : undefined,
          isHourly: salaryType.class === 'Hourly',
          includeInVacationBasis: salaryType.includeInVacationBasis
            ? t('salary_types.tab.table.included.true')
            : t('salary_types.tab.table.included.false'),
          includeInPensionBasis: salaryType.includeInPensionBasis
            ? t('salary_types.tab.table.included.true')
            : t('salary_types.tab.table.included.false'),
          includeInSHFritvalgBasis: salaryType.includeInShFritvalgBasis
            ? t('salary_types.tab.table.included.true')
            : t('salary_types.tab.table.included.false'),
        })
      )
      .sort((a, b) => {
        if (a.active === b.active) {
          if (a.isHourly === b.isHourly) {
            return a.title.localeCompare(b.title)
          }
          return a.isHourly ? -1 : 1
        }
        return a.active ? -1 : 1
      })
  }

  return (
    <Card>
      <TitleMenu>
        <Button type="primary" onClick={() => setEditVisibility(true, 'SupplementVaried')}>
          <Icon type="company-add" />
          {t('salary_types.tab.header.add_supplement_varied')}
        </Button>
        <Button type="primary" onClick={() => setEditVisibility(true, 'Hourly')}>
          <Icon type="company-add" />
          {t('salary_types.tab.header.add_hourly')}
        </Button>
        <Button onClick={() => setShowDeactivated((prev) => !prev)} style={{ minWidth: '100px' }}>
          {showDeactivated ? t('salary_types.tab.header.show_active') : t('salary_types.tab.header.show_inactive')}
        </Button>
      </TitleMenu>
      <Title>{t('salary_types.tab.title')}</Title>

      <Table columns={columns} dataSource={getSalaryTypes()} size="small" pagination={false} />

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={980}
        footer={null}
      >
        <SalaryTypeEdit
          visible={editing !== false}
          salaryTypeID={typeof editing === 'boolean' ? undefined : editing}
          salaryTypes={props.salaryTypes}
          salaryClass={salaryClass}
          allowSalaryTypeSupplement={props.company.settingsEnabled.some(
            (setting) => setting === 'AllowSalaryTypeSupplements'
          )}
          addSalaryType={props.addSalaryType}
          updateSalaryType={props.updateSalaryType}
        />
      </Modal>
    </Card>
  )
}
