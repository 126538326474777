import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import { resendDocumentSigner } from '../../api/documents'
import CompanyPricing from '../../model/companyPricing'
import DocumentCategory from '../../model/documentCategory'
import { DocumentReducer } from '../../reducers/documents'
import Language from '../../types/language'
import { validateEmail } from '../../utils/email-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatLanguage } from '../../utils/format-utils'
import { formatCurrency, formatOrdinalNumber } from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'
import Radio, { Group as RadioGroup } from '../antd/radio'
import Select from '../antd/select'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/Icon'
import Input from '../elements/input'
import Subtitle from '../elements/Subtitle'
import FeatureLock from '../widgets/FeatureLock'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  documentID?: string
  documents: DocumentReducer
  documentCategories: List<DocumentCategory>
  companyPricings: List<CompanyPricing>
}

type Fields = {
  documentCategoryID?: string
  name?: string
  visibleForEmployee: boolean
  signWith: 'SMS' | 'Criipto'
  signers: {
    id?: string
    email?: string
    name?: string
    language: Language
  }[]
}

export type SigningDocumentResult = {
  documentCategoryID: string
  name: string
  visibleForEmployee: boolean
  signWith: 'SMS' | 'Criipto'
  newSigners: {
    email: string
    name: string
    language: Language
  }[]
}

function SigningDocumentEditForm(
  props: Props & FormComponentProps<Fields, SigningDocumentResult>
): ReactElement | null {
  const [resent, setResent] = useState<string[]>([])
  const add = () => {
    props.setFieldValue('signers', [...props.getFieldValue('signers'), { language: Language.DANISH }])
  }
  const remove = (i: number) => {
    props.setFieldValue(
      'signers',
      props.getFieldValue('signers').filter((_, j) => j !== i)
    )
  }
  const resend = (signerID: string) => {
    resendDocumentSigner(signerID).finally(() => setResent((prev) => [...prev, signerID]))
  }

  const criiptoRate =
    props.companyPricings.find((pricing) => pricing.pricingType === 'Document Signature Validation')?.rate ?? 5 // default to 5

  const { decorateField, decorateAnyField, getFieldValue } = props

  const numberOfSigners = getFieldValue('signers').length

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('documentCategoryID', {
            placeholder: t('documents.signing.edit.form.document_category_id'),
            validate: (val) => (!val ? t('documents.signing.edit.form.document_category_id.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false}>
              {props.documentCategories
                .filter(
                  (documentCategory) =>
                    documentCategory.active || documentCategory.id === getFieldValue('documentCategoryID')
                )
                .map((documentCategory) => {
                  return (
                    <Select.Option key={documentCategory.id} value={documentCategory.id}>
                      {documentCategory.name}
                    </Select.Option>
                  )
                })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('name', {
            placeholder: t('documents.signing.edit.form.name'),
            validate: (val) => (!val ? t('documents.signing.edit.form.name.required') : null),
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('signWith', {
            title: t('documents.signing.edit.form.signed_with'),
          })(
            <RadioGroup disabled={!!props.documentID}>
              <Radio value={'SMS'}>{t('documents.signing.edit.form.signed_with.sms')}</Radio>
              <FeatureLock
                featureType={'Document Signature Validation'}
                description={t('documents.signing.edit.form.signed_with.criipto.lock')}
              >
                <Radio value={'Criipto'}>
                  {tx('documents.signing.edit.form.signed_with.criipto', {
                    price: <strong>{formatCurrency(criiptoRate)}</strong>,
                  })}
                </Radio>
              </FeatureLock>
            </RadioGroup>
          )}
        </Col>
      </Row>
      {getFieldValue('signers').map((signer, i) => {
        return (
          <React.Fragment key={`signer-${i}`}>
            {numberOfSigners > 1 && (
              <Row className="document-signer-title">
                <Col span={24}>
                  <Subtitle>
                    {t('documents.signing.edit.form.signers.title', {
                      number: formatOrdinalNumber(i + 1),
                    })}
                  </Subtitle>
                </Col>
              </Row>
            )}
            <Row key={`signer-${i}-elements`}>
              <Col span={10}>
                {decorateAnyField(`signers.${i}.email`, {
                  placeholder: t('documents.signing.edit.form.signers.email'),
                  validate: (val) => {
                    if (!val) {
                      return t('documents.signing.edit.form.signers.email.required')
                    }
                    if (!validateEmail(val)) {
                      return t('documents.signing.edit.form.signers.email.invalid')
                    }
                    return null
                  },
                })(<Input disabled={!!signer.id} />)}
              </Col>
              <Col span={8}>
                {decorateAnyField(`signers.${i}.name`, {
                  placeholder: t('documents.signing.edit.form.signers.name'),
                  validate: (val) => (!val ? t('documents.signing.edit.form.signers.name.required') : null),
                })(<Input disabled={!!signer.id} />)}
              </Col>
              <Col span={4}>
                {decorateAnyField(`signers.${i}.language`, {
                  title: t('documents.signing.edit.form.signers.language'),
                })(
                  <RadioGroup disabled={!!signer.id}>
                    <Radio value={Language.DANISH}>{formatLanguage(Language.DANISH)}</Radio>
                    <Radio value={Language.ENGLISH}>{formatLanguage(Language.ENGLISH)}</Radio>
                  </RadioGroup>
                )}
              </Col>
              <Col span={2}>
                {!!signer.id && !resent.some((id) => id === signer.id) && (
                  <span
                    className="document-resend-signer"
                    onClick={() => resend(signer.id || '')}
                    title={t('documents.signing.edit.form.signers.refresh')}
                  >
                    <Icon type={'refresh'} />
                  </span>
                )}
                {!signer.id && numberOfSigners > 1 && (
                  <span
                    className="document-remove-signer"
                    onClick={() => remove(i)}
                    title={t('documents.signing.edit.form.signers.remove')}
                  >
                    <Icon type={'cross'} />
                  </span>
                )}
              </Col>
            </Row>
          </React.Fragment>
        )
      })}
      <Row>
        <Col span={24}>
          <span className="document-add-signer" onClick={add}>
            <Icon type="add" color="orange" /> {t('documents.signing.edit.form.signers.add')}
          </span>
        </Col>
      </Row>
      {numberOfSigners > 1 && (
        <Row className="document-signer-order-note">
          <Col span={24}>
            <p>
              {numberOfSigners > 2
                ? t('documents.signing.edit.form.signers.multiple_signers_note.2_plus')
                : t('documents.signing.edit.form.signers.multiple_signers_note')}
            </p>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {props.documentID ? t('form.button.save_changes') : t('documents.signing.edit.form.submit.create')}
          </Button>
        </Col>
      </Row>
      {props.documents.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, SigningDocumentResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      visibleForEmployee: true,
      signWith: 'SMS',
      signers: [],
    }
    const document = props.documents.documents.find((document) => document.id === props.documentID)
    if (document) {
      fields.documentCategoryID = document.documentCategoryID
      fields.name = document.name
      fields.visibleForEmployee = document.visibleForEmployee
      if (document.signatureType !== 'None') {
        fields.signWith = document.signatureType
      }
      fields.signers = document.signers.map((signer) => ({
        id: signer.id,
        email: signer.email,
        name: signer.name,
        language: signer.language,
      }))
    } else {
      fields.signers = [
        {
          language: Language.DANISH,
        },
      ]
    }
    return fields
  },
  onSubmit: (values) => {
    return {
      documentCategoryID: values.documentCategoryID!,
      name: values.name!,
      visibleForEmployee: values.visibleForEmployee,
      signWith: values.signWith,
      newSigners: values.signers
        .filter((signer) => !signer.id)
        .map((signer) => ({
          email: signer.email!,
          name: signer.name!,
          language: signer.language,
        })),
    }
  },
})(SigningDocumentEditForm)
