import React, { ReactElement, useEffect } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import Company from '../../../model/company'
import Employee from '../../../model/employee'
import { EmployeeReducer } from '../../../reducers/employees'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Card from '../../elements/card'
import Title from '../../elements/Title'
import EmployeeSettingsForm, { EmployeeSettingsResult } from './EmployeeSettingsForm'

type Props = {
  employee: Employee
  company: Company
  employees: EmployeeReducer

  addAlert: addAlertSignature
  updateEmployee: (employee: Employee) => void
}

export default function EmployeeSettings(props: Props): ReactElement | null {
  const { employees } = props
  const previousEmployees = usePrevious(employees)
  useEffect(() => {
    if (previousEmployees && previousEmployees.saving && !employees.saving) {
      if (employees.error) {
        props.addAlert('error', formatError(employees.error), { closable: true })
      } else {
        props.addAlert('success', t('employee_settings.alert.success', { name: props.employee.name }), { timeout: 5 })
      }
    }
  })
  const handleSubmit = (values: EmployeeSettingsResult) => {
    props.updateEmployee({ ...props.employee, ...values })
  }

  return (
    <Card className="employees-single-form">
      <Title>{t('employee_settings.title')}</Title>
      <p>{t('employee_settings.intro.line_1')}</p>
      <p>{t('employee_settings.intro.line_2')}</p>
      <EmployeeSettingsForm company={props.company} employee={props.employee} onSubmit={handleSubmit} />
    </Card>
  )
}
