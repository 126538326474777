import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import SupplementType from '../model/supplementType'
import { ReducerAction } from '../utils/reducer-utils'

export interface SupplementTypeReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  supplementTypes: List<SupplementType>
  error: Error | null
}

const Params = Record<SupplementTypeReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  supplementTypes: List<SupplementType>(),
  error: null,
})

export interface SupplementTypeAction extends ReducerAction {
  companyID?: string
  supplementTypes?: SupplementType[]
}

export default (
  state: Record<SupplementTypeReducer> = Params(),
  action: SupplementTypeAction = { type: '' }
): Record<SupplementTypeReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.SUPPLEMENT_TYPES_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.SUPPLEMENT_TYPES_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        supplementTypes: List<SupplementType>(action.supplementTypes),
      })
    case ActionTypes.SUPPLEMENT_TYPES_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
