import { List } from 'immutable'
import React, { ReactElement } from 'react'

import CompanyGroup from '../../../model/companyGroup'
import PricingPackage from '../../../model/pricingPackage'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Select from '../../antd/select'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'

type Props = {
  companyGroup: CompanyGroup
  pricingPackages: List<PricingPackage>
}

type Fields = {
  name?: string
  defaultPricingPackageID?: string
}

export type SettingResult = {
  name: string
  defaultPricingPackageID: string
}

function SettingsForm(props: Props & FormComponentProps<Fields, SettingResult>): ReactElement | null {
  const { decorateField } = props

  const pricingPackages = props.pricingPackages.filter((pricingPackage) => pricingPackage.type !== 'Addon')

  return (
    <div>
      <Row>
        <Col>
          {decorateField('name', {
            title: t('companies.settings.form.name'),
            validate: (val) => {
              if (!val) {
                return t('companies.settings.form.name.required')
              }
              return null
            },
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('defaultPricingPackageID', {
            title: t('companies.settings.form.default_pricing_package_id'),
          })(
            <Select>
              {pricingPackages.map((pricingPackage) => {
                return (
                  <Select.Option key={pricingPackage.id} value={pricingPackage.id}>
                    {pricingPackage.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
        <Col span={12}>
          <p style={{ marginTop: '20px' }}>{t('companies.settings.form.default_pricing_package_id.note')}</p>
        </Col>
      </Row>
      <Button htmlType="submit" size="large" type="secondary">
        {t('form.button.save_changes')}
      </Button>
    </div>
  )
}

export default withValidations<Props, Fields, SettingResult>({
  mapPropsToFields: (props) => {
    return {
      name: props.companyGroup.name,
      defaultPricingPackageID: props.companyGroup.defaultPricingPackageID,
    }
  },
  onSubmit: (values) => ({
    name: values.name!,
    defaultPricingPackageID: values.defaultPricingPackageID!,
  }),
})(SettingsForm)
