import React, { ReactElement } from 'react'

import { BenefitType } from '../../model/remuneration'
import {
  decorateAnyFieldSignature,
  getAnyFieldErrorSignature,
  getFieldValueSignature,
  setAnyFieldValueSignature,
  setFieldValueSignature,
} from '../../utils/form-utils'
import { t, tx } from '../../utils/translation-utils'
import Form from '../antd/form'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import Icon from '../elements/Icon'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'

type BenefitTaxReportingNoThresholdReportingFields = {
  taxReportingsNoThreshold: {
    title: string
    type: BenefitType
    reportingAmount: string
  }[]
}

type Props<Fields extends BenefitTaxReportingNoThresholdReportingFields> = {
  decorateAnyField: decorateAnyFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  setFieldValue: setFieldValueSignature<Fields>
  setAnyFieldValue: setAnyFieldValueSignature
  getAnyFieldError: getAnyFieldErrorSignature
}

export default function BenefitTaxReportingNoThresholdReportingForm<
  Fields extends BenefitTaxReportingNoThresholdReportingFields
>(props: Props<Fields>): ReactElement | null {
  const add = () => {
    const { getFieldValue, setAnyFieldValue } = props
    const taxReportingsNoThreshold = getFieldValue('taxReportingsNoThreshold')
    setAnyFieldValue(`taxReportingsNoThreshold.${taxReportingsNoThreshold.length}`, {
      type: 'Tax Reporting No Threshold',
    })
  }
  const remove = (i: number) => {
    const { getFieldValue, setFieldValue } = props
    const otherReportings = getFieldValue('taxReportingsNoThreshold')
    otherReportings.splice(i, 1)
    setFieldValue('taxReportingsNoThreshold', otherReportings)
  }

  const { decorateAnyField, getFieldValue, getAnyFieldError } = props

  return (
    <div>
      <Row style={{ marginTop: '15px', marginBottom: '20px' }}>
        <Col span={24}>
          <Headline>{t('benefit.form.tax_reporting_no_threshold.intro.title')}</Headline>
        </Col>
        <Col span={24}>
          <p>{t('benefit.form.tax_reporting_no_threshold.intro.line_1')}</p>
          <p>
            {tx('benefit.form.tax_reporting_no_threshold.intro.line_2', {
              link: (
                <a href="https://skat.dk/skat.aspx?oid=2045908" target="_blank" rel="noopener noreferrer">
                  {t('benefit.form.tax_reporting_no_threshold.intro.line_2.link')}
                </a>
              ),
            })}
          </p>
        </Col>
      </Row>
      <Subcard>
        <Row style={{ marginBottom: '-10px' }}>
          <Col span={16}>
            <label>{t('benefit.form.tax_reporting_no_threshold.header.title')}</label>
          </Col>
          <Col span={8}>
            <label>{t('benefit.form.tax_reporting_no_threshold.header.reporting_amount')}</label>
          </Col>
        </Row>
        {getFieldValue('taxReportingsNoThreshold').map((benefit, i) => {
          return (
            <Form.Item
              key={i}
              validateStatus={
                getAnyFieldError(`taxReportingsNoThreshold.${i}.title`) ||
                getAnyFieldError(`taxReportingsNoThreshold.${i}.reportingAmount`)
                  ? 'error'
                  : 'success'
              }
            >
              <Row>
                <Col span={14}>
                  {decorateAnyField(`taxReportingsNoThreshold.${i}.title`, {
                    placeholder: t('benefit.form.tax_reporting_no_threshold.title'),
                    validate: (val: string | undefined) =>
                      !val ? t('benefit.form.tax_reporting_no_threshold.title.required') : null,
                    skipWrapper: true,
                    skipLabel: true,
                  })(<Input />)}
                </Col>
                <Col span={9}>
                  {decorateAnyField(`taxReportingsNoThreshold.${i}.reportingAmount`, {
                    placeholder: t('benefit.form.tax_reporting_no_threshold.reporting_amount'),
                    suffix: t('benefit.form.tax_reporting_no_threshold.reporting_amount.suffix'),
                    validate: (val: string | undefined) =>
                      !val ? t('benefit.form.tax_reporting_no_threshold.reporting_amount.required') : null,
                    skipWrapper: true,
                    skipLabel: true,
                  })(<Input />)}
                </Col>
                <Col span={1} className="contracts-remove-row">
                  <span onClick={() => remove(i)}>
                    <Icon type="cross" color="grey" />
                  </span>
                </Col>
              </Row>
            </Form.Item>
          )
        })}
        <span className="contracts-add-row" onClick={add}>
          <Icon type="add" color="orange" />{' '}
          {getFieldValue('taxReportingsNoThreshold').length > 0
            ? t('benefit.form.tax_reporting_no_threshold.add_row.not_empty')
            : t('benefit.form.tax_reporting_no_threshold.add_row.empty')}
        </span>
      </Subcard>
    </div>
  )
}
