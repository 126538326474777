import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { postEmployeesBatch } from '../../api/employees'
import Asset from '../../model/asset'
import AssetCategory from '../../model/assetCategory'
import AsynchronousTask, { AsynchronousTaskStatus } from '../../model/asynchronousTask'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import Department from '../../model/department'
import Employee from '../../model/employee'
import EmployeeBatchResult from '../../model/employeeBatchResult'
import EmploymentPosition from '../../model/employmentPosition'
import PensionCompany from '../../model/pensionCompany'
import SalaryCycle from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import SupplementType from '../../model/supplementType'
import { UserReducer } from '../../reducers/user'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'
import BatchUpdateForm, { BatchResult } from './BatchUpdateForm'

type Props = {
  assets: List<Asset>
  assetCategories: List<AssetCategory>
  asynchronousTasks: List<AsynchronousTask>
  company: Company
  companyUser?: CompanyUser
  user: UserReducer
  departments: List<Department>
  employees: List<Employee>
  supplementTypes: List<SupplementType>
  salaryCycles: List<SalaryCycle>
  pensionCompanies: List<PensionCompany>
  employmentPositions: List<EmploymentPosition>
  salaryTypes: List<SalaryType>
  employeeIDs: string[]
  hasFutureContractsFeature: boolean
  hasAssetsFeature: boolean

  goBack: () => void
}

export default function BatchUpdate(props: Props): ReactElement | null {
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [batchMessages, setBatchMessages] = useState<EmployeeBatchResult[]>([])
  const [taskID, setTaskID] = useState<string>()
  const [taskStatus, setTaskStatus] = useState<AsynchronousTaskStatus>()
  const [error, setError] = useState<Error | null>(null)

  const { asynchronousTasks } = props

  useEffect(() => {
    if (saving && taskID) {
      const asynchronousTask = asynchronousTasks.find((task) => task.id === taskID)
      if (!asynchronousTask) {
        return
      }
      if (asynchronousTask.status !== taskStatus) {
        setTaskStatus(asynchronousTask.status)
        if (asynchronousTask.status === 'Success') {
          setSaving(false)
          setSaved(true)
          if (asynchronousTask.employeeBatchResult) {
            setBatchMessages(asynchronousTask.employeeBatchResult.employees)
          }
        }
      }
    }
  }, [asynchronousTasks, saving, taskID, taskStatus])

  const handleSubmit = (values: BatchResult) => {
    const { action, operation, ...fields } = values
    if (!action) {
      return
    }
    setTaskStatus(undefined)
    setTaskID(undefined)
    setSaving(true)
    postEmployeesBatch(props.company.id, props.employeeIDs, action, operation, fields)
      .then((res) => {
        if (res.data.done) {
          setSaving(false)
          setSaved(true)
          setBatchMessages(res.data.employees)
        } else {
          setTaskID(res.data.taskID)
        }
      })
      .catch((e) => setError(e))
  }

  const displayLoadingText = () => {
    if (!taskID) {
      return t('employee_batch.update.loading.no_task')
    }
    return t('employee_batch.update.loading.task')
  }

  return (
    <div>
      <Subtitle>{t('employee_batch.update.title')}</Subtitle>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      {saving && <LoadingOverlay text={displayLoadingText()} />}
      <BatchUpdateForm
        assets={props.assets}
        assetCategories={props.assetCategories}
        company={props.company}
        companyUser={props.companyUser}
        user={props.user}
        departments={props.departments}
        employees={props.employees}
        supplementTypes={props.supplementTypes}
        salaryCycles={props.salaryCycles}
        pensionCompanies={props.pensionCompanies}
        employmentPositions={props.employmentPositions}
        employeeIDs={props.employeeIDs}
        batchMessages={batchMessages}
        hasFutureContractsFeature={props.hasFutureContractsFeature}
        hasAssetsFeature={props.hasAssetsFeature}
        saved={saved}
        reset={() => {
          setSaved(false)
          setTaskID(undefined)
          setTaskStatus(undefined)
        }}
        salaryTypes={props.salaryTypes}
        onBack={() => props.goBack()}
        onSubmit={handleSubmit}
      />
    </div>
  )
}
