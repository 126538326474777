import PricingPackage from '../model/pricingPackage'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchPricingPackages(includeIDs?: string[]): Promise<RequestResponse<PricingPackage[]>> {
  let query = {}
  if (includeIDs !== undefined && includeIDs.length > 0) {
    query = { includePricingPackageID: includeIDs.join(',') }
  }
  return secureRequest('GET', url('/v2/pricingPackages', query))
}

export function changeCompanyPricingPackage(companyID: string, pricingPackageID: string): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/companyPricingPackages/' + companyID), {
    body: {
      pricingPackageID,
    },
  })
}
