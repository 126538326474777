import { addYears, subYears } from 'date-fns'
import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getRemuneration } from '../actions/contracts'
import { getLeaveBalances } from '../actions/leave-balances'
import {
  approveTimeRegistrations,
  createTimeRegistration,
  createTimeRegistrationBulk,
  deleteTimeRegistration,
  getTimeRegistrations,
  updateTimeRegistration,
} from '../actions/time-registrations'
import { getVacationCalendarYear } from '../actions/vacation-calendars'
import { TimeRegistrationBulk } from '../api/time-registrations'
import LeaveRegistrationComponent from '../components/leave-registration/LeaveRegistration'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { TimeRegistrationMutableFields } from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employees'
import { LeaveBalanceReducer } from '../reducers/leaveBalances'
import { LeaveTypeReducer } from '../reducers/leaveTypes'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { VacationCalendarReducer } from '../reducers/vacationCalendars'
import { formatAPIDate, getDate } from '../utils/date-utils'
import { formatLoadingText } from '../utils/loading-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import RegistrationLayout from './layouts/RegistrationLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  employees: EmployeeReducer
  departments: DepartmentReducer
  timeRegistrations: TimeRegistrationReducer
  leaveBalances: LeaveBalanceReducer
  leaveTypes: LeaveTypeReducer
  salaryTypes: SalaryTypeReducer
  vacationCalendars: VacationCalendarReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getTimeRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    from: DateFormat,
    to: DateFormat
  ) => void
  getRemuneration: (id: string) => void
  createTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  createTimeRegistrationBulk: (bulk: TimeRegistrationBulk) => void
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  approveTimeRegistrations: (ids: string[]) => void
  deleteTimeRegistration: (id: string) => void
  getLeaveBalances: (companyID: string) => void
  getVacationCalendarYear: (companyID: string, year: number) => void
}

function LeaveRegistration(props: RouteProps & Reducers & Actions): ReactElement | null {
  const { companies, timeRegistrations, getTimeRegistrations, leaveBalances, getLeaveBalances } = props
  useEffect(() => {
    if (!companies.company) {
      return
    }
    const companyID = companies.company.id

    if (
      timeRegistrations.companyID !== companyID ||
      !!timeRegistrations.employeeID ||
      (!timeRegistrations.loading && !timeRegistrations.loaded)
    ) {
      getTimeRegistrations(
        companyID,
        undefined,
        undefined,
        formatAPIDate(subYears(getDate(), 2)),
        formatAPIDate(addYears(getDate(), 10))
      )
    }

    if (
      leaveBalances.companyID !== companyID ||
      !!leaveBalances.employeeID ||
      (!leaveBalances.loading && !leaveBalances.loaded)
    ) {
      getLeaveBalances(companyID)
    }
  })

  const company = companies.company

  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  const loading = timeRegistrations.loading || !leaveBalances.loaded || !company
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            { loading: timeRegistrations.loading, text: 'registreringer' },
            { loading: !leaveBalances.loaded, text: 'feriesaldi' },
          ])}
        />
      </div>
    )
  }

  const getShowCalendar = (): boolean => {
    const s = props.location.pathname.split('/')
    return s[s.length - 1] === 'calendar'
  }

  return (
    <RegistrationLayout route={props.route}>
      <LeaveRegistrationComponent
        alerts={props.alerts}
        showCalendar={getShowCalendar()}
        company={company}
        companyUser={props.companyUsers.companyUser}
        employees={props.employees}
        departments={props.departments.departments}
        timeRegistrations={props.timeRegistrations}
        leaveBalances={props.leaveBalances.leaveBalances}
        leaveTypes={props.leaveTypes.leaveTypes}
        salaryTypes={props.salaryTypes.salaryTypes}
        vacationCalendars={props.vacationCalendars}
        getRemuneration={props.getRemuneration}
        createTimeRegistration={props.createTimeRegistration}
        createTimeRegistrationBulk={props.createTimeRegistrationBulk}
        approveTimeRegistrations={props.approveTimeRegistrations}
        updateTimeRegistration={props.updateTimeRegistration}
        deleteTimeRegistration={props.deleteTimeRegistration}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        getVacationCalendarYear={props.getVacationCalendarYear}
      />
    </RegistrationLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    companyUsers: state.companyUsers,
    employees: state.employees,
    departments: state.departments,
    timeRegistrations: state.timeRegistrations,
    leaveBalances: state.leaveBalances,
    leaveTypes: state.leaveTypes,
    salaryTypes: state.salaryTypes,
    vacationCalendars: state.vacationCalendars,
  }),
  {
    getTimeRegistrations: getTimeRegistrations,
    getRemuneration: getRemuneration,
    createTimeRegistration: createTimeRegistration,
    createTimeRegistrationBulk: createTimeRegistrationBulk,
    approveTimeRegistrations: approveTimeRegistrations,
    updateTimeRegistration: updateTimeRegistration,
    deleteTimeRegistration: deleteTimeRegistration,
    getLeaveBalances: getLeaveBalances,
    addAlert: addAlert,
    removeAlert: removeAlert,
    getVacationCalendarYear: getVacationCalendarYear,
  }
)(LeaveRegistration)
