import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import SupportAccess from '../model/supportAccess'
import { DateTimeFormat } from '../model/types'
import { ReducerAction } from '../utils/reducer-utils'

export interface SupportAccessReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  error: Error | null
  expiresAt: DateTimeFormat | null
}

const Params = Record<SupportAccessReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  error: null,
  expiresAt: null,
})

export interface SupportAccessAction extends ReducerAction {
  companyID?: string
  supportAccess?: SupportAccess
}

export default (
  state: Record<SupportAccessReducer> = Params(),
  action: SupportAccessAction = { type: '' }
): Record<SupportAccessReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.SUPPORT_ACCESS_GRANTING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        saving: true,
      })
    case ActionTypes.SUPPORT_ACCESS_GRANTED:
      if (!action.supportAccess) {
        return state
      }
      return Params({
        companyID: action.companyID || state.get('companyID'),
        expiresAt: action.supportAccess.expiresAt,
        loaded: true,
      })
    case ActionTypes.SUPPORT_ACCESS_GRANT_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error,
      })
    case ActionTypes.SUPPORT_ACCESS_REVOKING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        saving: true,
      })
    case ActionTypes.SUPPORT_ACCESS_REVOKED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
      })
    case ActionTypes.SUPPORT_ACCESS_REVOKE_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error,
      })
    case ActionTypes.SUPPORT_ACCESS_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.SUPPORT_ACCESS_LOADED:
      if (!action.supportAccess) {
        return state
      }
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loaded: true,
        expiresAt: action.supportAccess.expiresAt,
      })
    case ActionTypes.SUPPORT_ACCESS_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
