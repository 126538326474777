import CoarseTimeRegistration, { CoarseTimeRegistrationMutableFields } from '../model/coarseTimeRegistration'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCoarseTimeRegistrations(
  companyID?: string,
  salaryPeriodID?: string,
  employeeID?: string,
  payRollID?: string
): Promise<RequestResponse<CoarseTimeRegistration[]>> {
  return secureRequest('GET', url('/v2/coarseTimeRegistrations', { companyID, salaryPeriodID, employeeID, payRollID }))
}

export function putCoarseTimeRegistrationBulk(
  coarseTimeRegistrations: CoarseTimeRegistrationMutableFields[]
): Promise<RequestResponse<CoarseTimeRegistration[]>> {
  return secureRequest('PUT', url('/v2/coarseTimeRegistrationsBulk'), {
    body: coarseTimeRegistrations.map((timeReg) => {
      return {
        employeeID: timeReg.employeeID,
        salaryPeriodID: timeReg.salaryPeriodID,
        salaryType: '',
        salaryTypeID: timeReg.salaryTypeID,
        hours: timeReg.hours,
        days: timeReg.days,
      }
    }),
  })
}
