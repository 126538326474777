import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import SupplementBalance from '../model/supplementBalance'
import { ReducerAction } from '../utils/reducer-utils'

export interface SupplementBalanceReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  supplementBalances: List<SupplementBalance>
  error: Error | null
}

const Params = Record<SupplementBalanceReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  supplementBalances: List<SupplementBalance>(),
  error: null,
})

export interface SupplementBalanceAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  supplementBalances?: SupplementBalance[]
  supplementBalance?: SupplementBalance
  supplementTypeName?: string
}

export default (
  state: Record<SupplementBalanceReducer> = Params(),
  action: SupplementBalanceAction = { type: '' }
): Record<SupplementBalanceReducer> => {
  let idx = -1
  switch (action.type) {
    case ActionTypes.SUPPLEMENT_BALANCES_LOADING:
      return Params({
        companyID: action.companyID,
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.SUPPLEMENT_BALANCES_LOADED:
    case ActionTypes.SUPPLEMENT_BALANCES_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID,
        employeeID: action.employeeID,
        loaded: true,
        loading: action.type === ActionTypes.SUPPLEMENT_BALANCES_LOADED_PARTIAL,
        supplementBalances: state.get('supplementBalances').concat(List(action.supplementBalances)),
      })
    case ActionTypes.SUPPLEMENT_BALANCES_LOAD_FAILED:
      return Params({
        companyID: action.companyID,
        employeeID: action.employeeID,
        error: action.error,
      })
    case ActionTypes.SUPPLEMENT_BALANCE_UPDATED:
      if (!action.supplementBalance) {
        return state
      }
      idx = state
        .get('supplementBalances')
        .findIndex(
          (item) => item.supplementTypeName === action.supplementTypeName && item.employeeID === action.employeeID
        )
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state
        .set('loaded', true)
        .set('supplementBalances', state.get('supplementBalances').set(idx, action.supplementBalance))

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
