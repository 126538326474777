import React from 'react'

import { fetchExternalSalaryFileSettings, putExternalSalaryFileSettings } from '../api/external-salary-file-settings'
import ActionTypes from '../constants/action-types'
import ExternalSalaryFileSetting, { ExternalSalaryFileSettingMutableFields } from '../model/externalSalaryFileSetting'
import { ExternalSalaryFileSettingAction } from '../reducers/externalSalaryFileSettings'
import { isRequestError } from '../utils/error-utils'

function loadingExternalSalaryFileSettings(): ExternalSalaryFileSettingAction {
  return {
    type: ActionTypes.EXTERNAL_SALARY_FILE_SETTINGS_LOADING,
  }
}
function loadedExternalSalaryFileSettings(settings: ExternalSalaryFileSetting[]): ExternalSalaryFileSettingAction {
  return {
    type: ActionTypes.EXTERNAL_SALARY_FILE_SETTINGS_LOADED,
    externalSalaryFileSettings: settings,
  }
}
function failedLoadingExternalSalaryFileSettings(error: Error): ExternalSalaryFileSettingAction {
  return {
    type: ActionTypes.EXTERNAL_SALARY_FILE_SETTINGS_LOAD_FAILED,
    error,
  }
}

function savingExternalSalaryFileSettings(): ExternalSalaryFileSettingAction {
  return {
    type: ActionTypes.EXTERNAL_SALARY_FILE_SETTINGS_SAVING,
  }
}
function savedExternalSalaryFileSettings(settings: ExternalSalaryFileSetting[]): ExternalSalaryFileSettingAction {
  return {
    type: ActionTypes.EXTERNAL_SALARY_FILE_SETTINGS_SAVED,
    externalSalaryFileSettings: settings,
  }
}
function failedSavingExternalSalaryFileSettings(error: Error): ExternalSalaryFileSettingAction {
  return {
    type: ActionTypes.EXTERNAL_SALARY_FILE_SETTINGS_SAVE_FAILED,
    error,
  }
}

export function getExternalSalaryFileSettings(companyID: string) {
  return (dispatch: React.Dispatch<any>): Promise<ExternalSalaryFileSetting[] | void> => {
    dispatch(loadingExternalSalaryFileSettings())
    return fetchExternalSalaryFileSettings(companyID)
      .then((res) => {
        dispatch(loadedExternalSalaryFileSettings(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingExternalSalaryFileSettings(e))
        }
      })
  }
}

export function saveExternalSalaryFileSettings(companyID: string, settings: ExternalSalaryFileSettingMutableFields[]) {
  return (dispatch: React.Dispatch<any>): Promise<ExternalSalaryFileSetting[] | void> => {
    dispatch(savingExternalSalaryFileSettings())
    return putExternalSalaryFileSettings(companyID, settings)
      .then((res) => {
        dispatch(savedExternalSalaryFileSettings(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedSavingExternalSalaryFileSettings(e))
        }
      })
  }
}
