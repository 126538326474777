export function hasLoanService(): boolean {
  if (process.env.REACT_APP_FEATURE_LOAN_SERVICE) {
    return true
  }
  return false
}

export function hasDocumentSigning(): boolean {
  if (process.env.REACT_APP_FEATURE_DOCUMENT_SIGNING) {
    return true
  }
  return false
}

// it's a bit of a hack to allow overriding these flags, but certain pages permit it (specifically "outside" pages)
let allowEnglishOverride = false
export function setAllowEnglishOverride(v: boolean) {
  allowEnglishOverride = v
}

export function hasAllowEnglish(): boolean {
  if (allowEnglishOverride) {
    return true
  }
  if (process.env.REACT_APP_FEATURE_ALLOW_ENGLISH) {
    return true
  }
  return false
}

export function hasQuickLanguageSwitch(): boolean {
  if (process.env.REACT_APP_FEATURE_QUICK_LANGUAGE_SWITCH) {
    return true
  }
  return false
}

export function hasSwipeFee(): boolean {
  if (process.env.REACT_APP_FEATURE_SWIPE_FEE) {
    return true
  }
  return false
}
