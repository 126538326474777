import { addYears, startOfYear, subYears } from 'date-fns'
import React, { ReactElement, useCallback, useEffect } from 'react'

import { getAccountingDimensions } from '../actions/accounting-dimensions'
import { getCompanyAccountingIntegration } from '../actions/accounting-integration'
import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getAssetCategories } from '../actions/asset-categories'
import {
  createAsset,
  deleteAsset,
  getAssets,
  hideAsset,
  receiveAsset,
  returnAsset,
  updateAsset,
} from '../actions/assets'
import { getBanks } from '../actions/banks'
import {
  approveCarAllowances,
  createCarAllowances,
  deleteCarAllowance,
  getCarAllowances,
  unapproveCarAllowances,
  updateCarAllowance,
} from '../actions/car-allowances'
import {
  createCoarseCarAllowance,
  deleteCoarseCarAllowance,
  getCoarseCarAllowances,
  updateCoarseCarAllowance,
} from '../actions/coarse-car-allowances'
import {
  createCoarseSalaryRegistrationBulk,
  deleteCoarseSalaryRegistration,
  getCoarseSalaryRegistrations,
  updateCoarseSalaryRegistrationBulk,
} from '../actions/coarse-salary-registrations'
import { getCoarseTimeRegistrations, updateCoarseTimeRegistrationBulk } from '../actions/coarse-time-registrations'
import { getCompanyPricingPackages } from '../actions/company-pricing-packages'
import {
  addContract,
  createLeave,
  deleteContract,
  getContractDeltas,
  getContracts,
  getRemuneration,
  removeLeave,
  setEmployeeContract,
  updateContract,
} from '../actions/contracts'
import { getCostCenters } from '../actions/cost-centers'
import { addDepartment, getDepartments } from '../actions/departments'
import { getDocumentCategories } from '../actions/document-categories'
import { addDocument, deleteDocument, getDocuments, updateDocument } from '../actions/documents'
import { getEmployeeDimensions, updateEmployeeDimensions } from '../actions/employee-dimensions'
import {
  deleteEmployeeEmergencyContact,
  getEmployeeEmergencyContact,
  updateEmployeeEmergencyContact,
} from '../actions/employee-emergency-contacts'
import {
  deleteEmployee,
  deleteEmployeeUser,
  markEmployeeReady,
  sendInvite,
  unmarkEmployeeReady,
  updateEmployee,
  updateProfileImage,
} from '../actions/employees'
import { getEmploymentPositions } from '../actions/employment-positions'
import {
  addEmployment,
  getEmployments,
  rehireEmployee,
  terminateEmployment,
  updateEmployment,
} from '../actions/employments'
import { getExpenseCategories } from '../actions/expense-categories'
import {
  addLeaveAdjustment,
  deleteLeaveAdjustment,
  getLeaveAdjustments,
  updateLeaveAdjustment,
} from '../actions/leave-adjustments'
import { getLeaveBalances } from '../actions/leave-balances'
import { getLeaveTypes } from '../actions/leave-types'
import { addOneTimePayPension, getOneTimePayPensions, updateOneTimePayPension } from '../actions/one-time-pay-pensions'
import {
  addOneTimePay,
  approveOneTimePays,
  deleteOneTimePay,
  getOneTimePays,
  unapproveOneTimePays,
  updateOneTimePay,
} from '../actions/one-time-pays'
import { getPaySlips } from '../actions/pay-slips'
import { getPensionCompanies } from '../actions/pension-companies'
import { getPricingPackages } from '../actions/pricing-packages'
import { getProjects } from '../actions/projects'
import {
  addReimbursementVoucher,
  approveReimbursementVouchers,
  draftReimbursementVouchers,
  getReimbursementVouchers,
  unapproveReimbursementVouchers,
  updateReimbursementVoucher,
  updateReimbursementVoucherFields,
} from '../actions/reimbursement-vouchers'
import { getSalaryCycles } from '../actions/salary-cycles'
import {
  approveSalaryRegistrations,
  createSalaryRegistration,
  deleteSalaryRegistration,
  deleteSalaryRegistrationBulk,
  getSalaryRegistrations,
  unapproveSalaryRegistrations,
  updateSalaryRegistration,
} from '../actions/salary-registrations'
import { getStartBalances, updateStartBalances } from '../actions/start-balances'
import {
  addSupplementAdjustment,
  deleteSupplementAdjustment,
  getSupplementAdjustments,
  updateSupplementAdjustment,
} from '../actions/supplement-adjustments'
import { getSupplementBalances } from '../actions/supplement-balances'
import { addTaxCardRequest, getTaxCardRequests, getTaxCards, TaxCardUpdate, updateTaxCard } from '../actions/tax-cards'
import {
  deleteTimeRegistrationTemplate,
  getTimeRegistrationTemplates,
  saveTimeRegistrationTemplates,
} from '../actions/time-registration-templates'
import {
  approveTimeRegistrations,
  createTimeRegistration,
  createTimeRegistrationBulk,
  deleteTimeRegistration,
  deleteTimeRegistrationBulk,
  getTimeRegistrations,
  unapproveTimeRegistrations,
  updateTimeRegistration,
} from '../actions/time-registrations'
import { deleteWarning } from '../actions/warnings'
import {
  ReimbursementVoucherCreate,
  ReimbursementVoucherFieldsUpdate,
  ReimbursementVoucherUpdate,
} from '../api/reimbursement-vouchers'
import { TimeRegistrationBulk } from '../api/time-registrations'
import EmployeesSingleComponent from '../components/employees-single/EmployeesSingle'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import Asset, { AssetMutableFields } from '../model/asset'
import AssetCategory from '../model/assetCategory'
import CarAllowance, { CarAllowanceMutableFields } from '../model/carAllowance'
import CoarseCarAllowance, {
  CoarseCarAllowanceCreationFields,
  CoarseCarAllowanceMutableFields,
} from '../model/coarseCarAllowance'
import CoarseSalaryRegistration, { CoarseSalaryRegistrationMutableFields } from '../model/coarseSalaryRegistration'
import { CoarseTimeRegistrationMutableFields } from '../model/coarseTimeRegistration'
import Contract, { ContractCreationFields, ContractMutableFields } from '../model/contract'
import Department, { DepartmentCreationFields } from '../model/department'
import { DocumentCreationFields, DocumentMutableFields } from '../model/document'
import Employee from '../model/employee'
import { EmployeeDimensionMutableFields } from '../model/employeeDimension'
import { EmployeeEmergencyContactMutableFields } from '../model/employeeEmergencyContact'
import Employment from '../model/employment'
import { LeaveAdjustmentCreationFields, LeaveAdjustmentMutableFields } from '../model/leaveAdjustment'
import { OneTimePayCreationFields, OneTimePayMutableFields } from '../model/oneTimePay'
import { OneTimePayPensionCreationFields, OneTimePayPensionMutableFields } from '../model/oneTimePayPension'
import ProfileImage from '../model/profileImage'
import ReimbursementVoucher from '../model/reimbursementVoucher'
import { SalaryRegistrationMutableFields } from '../model/salaryRegistration'
import { StartBalances } from '../model/startBalances'
import { SupplementAdjustmentCreationFields, SupplementAdjustmentMutableFields } from '../model/supplementAdjustment'
import TaxCardRequest from '../model/taxCardRequest'
import { TimeRegistrationClass, TimeRegistrationMutableFields } from '../model/timeRegistration'
import TimeRegistrationTemplate, { TimeRegistrationTemplateCreationFields } from '../model/timeRegistrationTemplate'
import { DateFormat, SettledState } from '../model/types'
import { AccountingDimensionReducer } from '../reducers/accountingDimensions'
import { AlertReducer } from '../reducers/alerts'
import { AssetCategoryReducer } from '../reducers/assetCategories'
import { AssetReducer } from '../reducers/assets'
import { BankReducer } from '../reducers/banks'
import { CarAllowanceReducer } from '../reducers/carAllowances'
import { CoarseCarAllowanceReducer } from '../reducers/coarseCarAllowances'
import { CoarseSalaryRegistrationReducer } from '../reducers/coarseSalaryRegistrations'
import { CoarseTimeRegistrationReducer } from '../reducers/coarseTimeRegistrations'
import { CompanyReducer } from '../reducers/companies'
import { CompanyAccountingIntegrationReducer } from '../reducers/companyAccountingIntegration'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { CompanyPricingPackageReducer } from '../reducers/companyPricingPackages'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { ContractReducer } from '../reducers/contracts'
import { CostCenterReducer } from '../reducers/costCenters'
import { DepartmentReducer } from '../reducers/departments'
import { DocumentCategoryReducer } from '../reducers/documentCategories'
import { DocumentReducer } from '../reducers/documents'
import { EmployeeContractDeltaReducer } from '../reducers/employeeContractDeltas'
import { EmployeeDimensionReducer } from '../reducers/employeeDimensions'
import { EmployeeEmergencyContactReducer } from '../reducers/employeeEmergencyContacts'
import { EmployeeInviteReducer } from '../reducers/employeeInvites'
import { EmployeeProfileImageReducer } from '../reducers/employeeProfileImages'
import { EmployeeReducer } from '../reducers/employees'
import { EmploymentPositionReducer } from '../reducers/employmentPositions'
import { EmploymentReducer } from '../reducers/employments'
import { ExpenseCategoryReducer } from '../reducers/expenseCategories'
import { LeaveAdjustmentReducer } from '../reducers/leaveAdjustments'
import { LeaveBalanceReducer } from '../reducers/leaveBalances'
import { LeaveTypeReducer } from '../reducers/leaveTypes'
import { OneTimePayPensionReducer } from '../reducers/oneTimePayPensions'
import { OneTimePayReducer } from '../reducers/oneTimePays'
import { PaySlipReducer } from '../reducers/paySlips'
import { PensionCompanyReducer } from '../reducers/pensionCompanies'
import { PricingPackageReducer } from '../reducers/pricingPackages'
import { ProjectReducer } from '../reducers/projects'
import { ReimbursementVoucherReducer } from '../reducers/reimbursementVouchers'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { SalaryRegistrationReducer } from '../reducers/salaryRegistrations'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { StartBalanceReducer } from '../reducers/startBalances'
import { SupplementAdjustmentReducer } from '../reducers/supplementAdjustments'
import { SupplementBalanceReducer } from '../reducers/supplementBalances'
import { SupplementTypeReducer } from '../reducers/supplementTypes'
import { TaxCardRequestReducer } from '../reducers/taxCardRequests'
import { TaxCardReducer } from '../reducers/taxCards'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { TimeRegistrationTemplateReducer } from '../reducers/timeRegistrationTemplates'
import { UserReducer } from '../reducers/user'
import { WarningReducer } from '../reducers/warnings'
import { paths } from '../routes'
import { formatAPIDate, getDate } from '../utils/date-utils'
import { formatLoadingText } from '../utils/loading-utils'
import { hasDepartmentPermission } from '../utils/permissions-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'
import { t } from '../utils/translation-utils'

type Reducers = {
  alerts: AlertReducer
  employees: EmployeeReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  user: UserReducer
  companyFeatures: CompanyFeatureReducer
  timeRegistrations: TimeRegistrationReducer
  timeRegistrationTemplates: TimeRegistrationTemplateReducer
  coarseTimeRegistrations: CoarseTimeRegistrationReducer
  coarseSalaryRegistrations: CoarseSalaryRegistrationReducer
  leaveBalances: LeaveBalanceReducer
  leaveAdjustments: LeaveAdjustmentReducer
  paySlips: PaySlipReducer
  employmentPositions: EmploymentPositionReducer
  salaryCycles: SalaryCycleReducer
  pensionCompanies: PensionCompanyReducer
  leaveTypes: LeaveTypeReducer
  supplementAdjustments: SupplementAdjustmentReducer
  supplementBalances: SupplementBalanceReducer
  supplementTypes: SupplementTypeReducer
  salaryTypes: SalaryTypeReducer
  startBalances: StartBalanceReducer
  banks: BankReducer
  employeeEmergencyContacts: EmployeeEmergencyContactReducer
  employeeContractDeltas: EmployeeContractDeltaReducer
  employments: EmploymentReducer
  contracts: ContractReducer
  costCenters: CostCenterReducer
  departments: DepartmentReducer
  documents: DocumentReducer
  documentCategories: DocumentCategoryReducer
  taxCards: TaxCardReducer
  taxCardRequests: TaxCardRequestReducer
  oneTimePays: OneTimePayReducer
  oneTimePayPensions: OneTimePayPensionReducer
  carAllowances: CarAllowanceReducer
  coarseCarAllowances: CoarseCarAllowanceReducer
  employeeInvites: EmployeeInviteReducer
  employeeProfileImages: EmployeeProfileImageReducer
  employeeDimensions: EmployeeDimensionReducer
  accountingDimensions: AccountingDimensionReducer
  companyAccountingIntegration: CompanyAccountingIntegrationReducer
  expenseCategories: ExpenseCategoryReducer
  reimbursementVouchers: ReimbursementVoucherReducer
  salaryRegistrations: SalaryRegistrationReducer
  warnings: WarningReducer
  pricingPackages: PricingPackageReducer
  companyPricingPackages: CompanyPricingPackageReducer
  assets: AssetReducer
  assetCategories: AssetCategoryReducer
  projects: ProjectReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateEmployee: (employee: Employee) => Promise<Employee | void>
  deleteEmployee: (employeeID: string, removeOrphans: boolean) => Promise<boolean | void>
  getTimeRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => void
  createTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  createTimeRegistrationBulk: (registration: TimeRegistrationBulk) => void
  approveTimeRegistrations: (registrationIDs: string[]) => void
  unapproveTimeRegistrations: (registrationIDs: string[]) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (registrationID: string) => void
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    timeRegistrationClass: TimeRegistrationClass
  ) => void
  updateCoarseTimeRegistrationBulk: (employeeID: string, registrations: CoarseTimeRegistrationMutableFields[]) => void
  getCoarseTimeRegistrations: (
    companyID: string | undefined,
    salaryPeriodID: string | undefined,
    employeeID: string | undefined
  ) => void
  createCoarseSalaryRegistrationBulk: (
    employeeID: string,
    coarseSalaryRegistrations: CoarseSalaryRegistration[]
  ) => void
  updateCoarseSalaryRegistrationBulk: (
    employeeID: string,
    coarseSalaryRegistrations: CoarseSalaryRegistrationMutableFields[]
  ) => void
  deleteCoarseSalaryRegistration: (registration: CoarseSalaryRegistration) => void
  getCoarseSalaryRegistrations: (
    companyID: string | undefined,
    salaryPeriodID: string | undefined,
    employeeID: string | undefined
  ) => void
  getLeaveBalances: () => void
  getPaySlips: (payRollID: string | undefined, employeeID: string | undefined) => void
  getSalaryCycles: () => void
  getEmploymentPositions: () => void
  getPensionCompanies: () => void
  getBanks: () => void
  getOneTimePays: (companyID: string | undefined, employeeID: string | undefined) => void
  getCarAllowances: (
    companyID: string | undefined,
    employeeID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => void
  getEmployeeEmergencyContact: (employeeID: string) => void
  updateEmployeeEmergencyContact: (
    employeeID: string,
    employeeEmergencyContact: EmployeeEmergencyContactMutableFields
  ) => void
  deleteEmployeeEmergencyContact: (employeeID: string) => void
  getEmployments: (employeeID: string) => void
  addEmployment: (employeeID: string, employment: Employment) => void
  updateEmployment: (employeeID: string, employment: Employment) => void
  terminateEmployment: (employeeID: string, employment: Employment) => Promise<boolean | void>
  rehireEmployee: (employeeID: string, startDate: DateFormat) => Promise<boolean | void>
  getStartBalances: (employmentID: string) => void
  updateStartBalances: (employmentID: string, startBalances: StartBalances<number>) => void
  addContract: (contract: ContractCreationFields) => Promise<Contract | void>
  getContracts: () => void
  updateContract: (
    contract: ContractMutableFields,
    setViewing?: boolean,
    removeOrphans?: boolean
  ) => Promise<Contract | void>
  deleteContract: (contractID: string, employeeID: string, removeOrphans: boolean) => void
  createLeave: (employeeID: string, leaveFrom: DateFormat, leaveTo: DateFormat) => Promise<Contract[] | void>
  removeLeave: (contractID: string) => void
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
  getCostCenters: () => void
  getDepartments: () => void
  getDocuments: () => void
  addDocument: (employeeID: string, document: DocumentCreationFields) => void
  updateDocument: (companyID: string, document: DocumentMutableFields) => void
  deleteDocument: (documentID: string) => void
  getDocumentCategories: () => void
  getTaxCards: (companyID?: string, employeeID?: string) => void
  updateTaxCard: (taxCard: TaxCardUpdate) => void
  getTaxCardRequests: (employeeID: string) => void
  addTaxCardRequest: (employeeID: string) => Promise<TaxCardRequest | null>
  addOneTimePay: (employeeID: string, oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, otp: OneTimePayMutableFields) => void
  approveOneTimePays: (oneTimePayIDs: string[]) => void
  unapproveOneTimePays: (oneTimePayIDs: string[]) => void
  deleteOneTimePay: (id: string) => void
  createCarAllowances: (carAllowances: CarAllowanceMutableFields[]) => void
  approveCarAllowances: (carAllowanceIDs: string[]) => void
  unapproveCarAllowances: (carAllowanceIDs: string[]) => void
  updateCarAllowance: (carAllowance: CarAllowanceMutableFields) => void
  deleteCarAllowance: (carAllowance: CarAllowance) => void
  getCoarseCarAllowances: (companyID?: string, salaryPeriodIDs?: string[], employeeID?: string) => void
  createCoarseCarAllowance: (carAllowance: CoarseCarAllowanceCreationFields) => void
  updateCoarseCarAllowance: (carAllowance: CoarseCarAllowanceMutableFields) => void
  deleteCoarseCarAllowance: (coarseCarAllowance: CoarseCarAllowance) => void
  sendInvite: (employeeID: string) => void
  deleteEmployeeUser: (employeeID: string) => void
  updateProfileImage: (employeeID: string, image: ProfileImage) => void
  getContractDeltas: (employeeID: string) => void
  setEmployeeContract: (employeeID: string, contractID: string) => void
  getLeaveAdjustments: (companyID?: string, employeeID?: string) => void
  addLeaveAdjustment: (employeeID: string, leaveAdjustment: LeaveAdjustmentCreationFields) => void
  updateLeaveAdjustment: (employeeID: string, leaveAdjustment: LeaveAdjustmentMutableFields) => void
  deleteLeaveAdjustment: (leaveAdjustmentID: string) => void
  getLeaveTypes: () => void
  getSupplementAdjustments: (companyID?: string, employeeID?: string) => void
  addSupplementAdjustment: (employeeID: string, supplementAdjustment: SupplementAdjustmentCreationFields) => void
  updateSupplementAdjustment: (employeeID: string, supplementAdjustment: SupplementAdjustmentMutableFields) => void
  deleteSupplementAdjustment: (supplementAdjustmentID: string) => void
  getRemuneration: (contractID: string) => void
  getOneTimePayPensions: (companyID?: string, employeeID?: string) => void
  addOneTimePayPension: (employeeID: string, otp: OneTimePayPensionCreationFields) => void
  updateOneTimePayPension: (employeeID: string, otp: OneTimePayPensionMutableFields) => void
  getAccountingDimensions: () => void
  getEmployeeDimensions: (companyID: string) => void
  updateEmployeeDimensions: (employeeID: string, employeeDimensions: EmployeeDimensionMutableFields[]) => void
  getCompanyAccountingIntegration: () => void
  getReimbursementVouchers: () => void
  getExpenseCategories: () => void
  addReimbursementVoucher: (voucher: ReimbursementVoucherCreate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucher: (voucher: ReimbursementVoucherUpdate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (voucher: ReimbursementVoucherFieldsUpdate) => Promise<ReimbursementVoucher | void>
  approveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  unapproveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  draftReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  getSalaryRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat,
    stateFilter?: SettledState,
    offset?: number
  ) => void
  createSalaryRegistration: (registration: SalaryRegistrationMutableFields) => void
  updateSalaryRegistration: (registration: SalaryRegistrationMutableFields) => void
  approveSalaryRegistrations: (ids: string[]) => void
  unapproveSalaryRegistrations: (ids: string[]) => void
  deleteSalaryRegistrationBulk: () => void
  deleteSalaryRegistration: (id: string) => void
  getSupplementBalances: () => void
  getPricingPackages: (includePricingPackageID: string[]) => void
  getCompanyPricingPackages: () => void
  createAsset: (companyID: string, asset: AssetMutableFields) => void
  updateAsset: (companyID: string, asset: AssetMutableFields) => void
  deleteAsset: (assetID: string) => void
  returnAsset: (companyID: string, asset: Asset) => void
  receiveAsset: (companyID: string, asset: Asset) => void
  hideAsset: (companyID: string, asset: Asset) => void
  getAssetCategories: () => Promise<AssetCategory[] | void>
  getAssets: () => Promise<Asset[] | void>
  getProjects: () => void
  deleteWarning: (id: string) => void
  markEmployeeReady: (employeeID: string) => Promise<Employee | void>
  unmarkEmployeeReady: (employeeID: string) => Promise<Employee | void>
  getTimeRegistrationTemplates: (employeeID: string) => void
  saveTimeRegistrationTemplates: (
    template: TimeRegistrationTemplateCreationFields
  ) => Promise<TimeRegistrationTemplate | void>
  deleteTimeRegistrationTemplate: (
    companyID: string | undefined,
    employeeID: string | undefined
  ) => Promise<boolean | void>
}

function EmployeesSingle(props: Reducers & Actions & RouteProps): ReactElement | null {
  interface Splat {
    employeeID: string
    section: string
    subsection?: string
  }
  const { params } = props
  const resolveSplat = useCallback((): Splat => {
    const parts = splatString(params.splat).split('/')
    const employeeID = parts[0]
    let section = ''
    let subsection = undefined
    if (parts.length > 1) {
      section = parts[1]
      if (parts.length > 2) {
        subsection = parts[2]
      }
    }
    section = section || 'employment'
    return { employeeID, section, subsection }
  }, [params])

  const hasEmployeeEmergencyContactsFeature =
    props.companyFeatures.loaded &&
    props.companyFeatures.companyFeatures.some((feature) => feature.featureType === 'Employee Emergency Contacts')

  const hasFutureContractsFeature =
    props.companyFeatures.loaded &&
    props.companyFeatures.companyFeatures.some((feature) => feature.featureType === 'Future Contracts')

  const {
    employees,
    companyUsers,
    timeRegistrations,
    getTimeRegistrations,
    coarseTimeRegistrations,
    getCoarseTimeRegistrations,
    coarseSalaryRegistrations,
    getCoarseSalaryRegistrations,
    salaryRegistrations,
    getSalaryRegistrations,
    employeeDimensions,
    getEmployeeDimensions,
    companies,
    paySlips,
    getPaySlips,
    employments,
    getEmployments,
    employeeEmergencyContacts,
    getEmployeeEmergencyContact,
    taxCards,
    getTaxCards,
    expenseCategories,
    getExpenseCategories,
    reimbursementVouchers,
    getReimbursementVouchers,
    salaryCycles,
    getSalaryCycles,
    carAllowances,
    getCarAllowances,
    coarseCarAllowances,
    getCoarseCarAllowances,
    oneTimePays,
    getOneTimePays,
    costCenters,
    getCostCenters,
    departments,
    getDepartments,
    leaveTypes,
    getLeaveTypes,
    getRemuneration,
    pricingPackages,
    getPricingPackages,
    employmentPositions,
    getEmploymentPositions,
    pensionCompanies,
    getPensionCompanies,
    leaveAdjustments,
    getLeaveAdjustments,
    supplementAdjustments,
    getSupplementAdjustments,
    projects,
    getProjects,
  } = props
  useEffect(() => {
    const { employeeID } = resolveSplat()
    const employee = employees.employees.find((employee) => employee.id === employeeID)
    if (!employee) {
      jsBrowserHistory.push('/' + paths.EMPLOYEES)
      return
    }
    const company = companies.company
    if (!company) {
      jsBrowserHistory.push('/')
      return
    }
    const canSeeSalaryRates = hasDepartmentPermission(companyUsers.companyUser, employee.departmentID, 'SeeSalaryRates')
    const canEditObjects = hasDepartmentPermission(companyUsers.companyUser, employee.departmentID, 'EditObjects')
    if (timeRegistrations.employeeID !== employeeID || (!timeRegistrations.loading && !timeRegistrations.loaded)) {
      getTimeRegistrations(
        undefined,
        employeeID,
        undefined,
        formatAPIDate(startOfYear(subYears(getDate(), 2))),
        formatAPIDate(addYears(getDate(), 2))
      )
    }
    if (
      coarseTimeRegistrations.employeeID !== employeeID ||
      (!coarseTimeRegistrations.loading && !coarseTimeRegistrations.loaded)
    ) {
      getCoarseTimeRegistrations(undefined, undefined, employeeID)
    }
    if (
      coarseSalaryRegistrations.employeeID !== employeeID ||
      (!coarseSalaryRegistrations.loading && !coarseSalaryRegistrations.loaded)
    ) {
      getCoarseSalaryRegistrations(undefined, undefined, employeeID)
    }
    if (
      salaryRegistrations.employeeID !== employeeID ||
      (!salaryRegistrations.loading && !salaryRegistrations.loaded)
    ) {
      getSalaryRegistrations(
        undefined,
        employeeID,
        undefined,
        formatAPIDate(startOfYear(subYears(getDate(), 2))),
        formatAPIDate(addYears(getDate(), 2))
      )
    }
    if (!employeeDimensions.loaded && !employeeDimensions.loading) {
      getEmployeeDimensions(company.id)
    }
    if (canSeeSalaryRates) {
      // things a department approver cannot load
      if (paySlips.employeeID !== employeeID || (!paySlips.loading && !paySlips.loaded)) {
        getPaySlips(undefined, employeeID)
      }
    }
    if (canEditObjects) {
      if (employments.employeeID !== employeeID || (!employments.loading && !employments.loaded)) {
        getEmployments(employeeID)
      }
      if (
        hasEmployeeEmergencyContactsFeature &&
        ((employeeEmergencyContacts.loaded && employeeEmergencyContacts.employeeID !== employeeID) ||
          (!employeeEmergencyContacts.loaded && !employeeEmergencyContacts.loading))
      ) {
        getEmployeeEmergencyContact(employeeID)
      }
      if (taxCards.employeeID !== employeeID || (!taxCards.loading && !taxCards.loaded)) {
        getTaxCards(undefined, employeeID)
      }
      if (!expenseCategories.loaded && !expenseCategories.loading) {
        getExpenseCategories()
      }
      if (!reimbursementVouchers.loaded && !reimbursementVouchers.loading) {
        getReimbursementVouchers()
      }
    }
    if (!salaryCycles.loaded && !salaryCycles.loading) {
      getSalaryCycles()
    }
    if (carAllowances.employeeID !== employeeID || (!carAllowances.loading && !carAllowances.loaded)) {
      getCarAllowances(
        undefined,
        employeeID,
        formatAPIDate(startOfYear(subYears(getDate(), 2))),
        formatAPIDate(addYears(getDate(), 2))
      )
    }
    if (
      coarseCarAllowances.employeeID !== employeeID ||
      (!coarseCarAllowances.loading && !coarseCarAllowances.loaded)
    ) {
      getCoarseCarAllowances(undefined, undefined, employeeID)
    }
    if (oneTimePays.employeeID !== employeeID || (!oneTimePays.loading && !oneTimePays.loaded)) {
      getOneTimePays(undefined, employeeID)
    }
    if (!costCenters.loaded && !costCenters.loading) {
      getCostCenters()
    }
    if (!departments.loaded && !departments.loading) {
      getDepartments()
    }
    if (!leaveTypes.loaded && !leaveTypes.loading) {
      getLeaveTypes()
    }
    let loadingID = null
    if (employee.earliestMutableContract && !employee.earliestMutableContract.remuneration && !employees.loading) {
      getRemuneration(employee.earliestMutableContract.id)
      loadingID = employee.earliestMutableContract.id
    }
    if (
      employee.activeContract &&
      !employee.activeContract.remuneration &&
      !employees.loading &&
      employee.activeContract.id !== loadingID
    ) {
      getRemuneration(employee.activeContract.id)
    }
    if (!pricingPackages.loaded && !pricingPackages.loading) {
      getPricingPackages([])
    }
    if (!employmentPositions.loaded && !employmentPositions.loading) {
      getEmploymentPositions()
    }
    if (!pensionCompanies.loaded && !pensionCompanies.loading) {
      getPensionCompanies()
    }
    if (leaveAdjustments.employeeID !== employeeID || (!leaveAdjustments.loaded && !leaveAdjustments.loading)) {
      getLeaveAdjustments(undefined, employeeID)
    }
    if (
      supplementAdjustments.employeeID !== employeeID ||
      (!supplementAdjustments.loaded && !supplementAdjustments.loading)
    ) {
      getSupplementAdjustments(undefined, employeeID)
    }
    if (!projects.loading && !projects.loaded) {
      getProjects()
    }
  }, [
    carAllowances,
    getCarAllowances,
    coarseCarAllowances,
    getCoarseCarAllowances,
    coarseSalaryRegistrations,
    getCoarseSalaryRegistrations,
    coarseTimeRegistrations,
    getCoarseTimeRegistrations,
    companies,
    companyUsers,
    costCenters,
    getCostCenters,
    departments,
    getDepartments,
    employeeDimensions,
    getEmployeeDimensions,
    employeeEmergencyContacts,
    getEmployeeEmergencyContact,
    employees,
    employments,
    getEmployments,
    expenseCategories,
    getExpenseCategories,
    leaveTypes,
    getLeaveTypes,
    oneTimePays,
    getOneTimePays,
    paySlips,
    getPaySlips,
    reimbursementVouchers,
    getReimbursementVouchers,
    salaryCycles,
    getSalaryCycles,
    taxCards,
    getTaxCards,
    timeRegistrations,
    getTimeRegistrations,
    salaryRegistrations,
    getSalaryRegistrations,
    hasEmployeeEmergencyContactsFeature,
    getRemuneration,
    pricingPackages,
    getPricingPackages,
    employmentPositions,
    getEmploymentPositions,
    pensionCompanies,
    getPensionCompanies,
    leaveAdjustments,
    getLeaveAdjustments,
    supplementAdjustments,
    getSupplementAdjustments,
    projects,
    getProjects,
    resolveSplat,
  ])

  const company = companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }
  const { employeeID, section, subsection } = resolveSplat()
  const employee = props.employees.employees.find((employee) => employee.id === employeeID)
  if (!employee) {
    // if we could not find the employee?  Send them to the employee overview
    jsBrowserHistory.push('/' + paths.EMPLOYEES)
    return null
  }
  const canSeeSalaryRates = hasDepartmentPermission(
    props.companyUsers.companyUser,
    employee.departmentID,
    'SeeSalaryRates'
  )
  const canEditObjects = hasDepartmentPermission(props.companyUsers.companyUser, employee.departmentID, 'EditObjects')
  const canApproveObjects = hasDepartmentPermission(
    props.companyUsers.companyUser,
    employee.departmentID,
    'ApproveObjects'
  )
  const canHireFire = hasDepartmentPermission(props.companyUsers.companyUser, employee.departmentID, 'HireFire')
  const loading =
    props.timeRegistrations.loading ||
    !props.coarseTimeRegistrations.loaded ||
    props.salaryRegistrations.loading ||
    !props.coarseSalaryRegistrations.loaded ||
    (canSeeSalaryRates && !props.paySlips.loaded) ||
    !props.salaryCycles.loaded ||
    (canEditObjects && !props.employments.loaded) ||
    (canEditObjects && hasEmployeeEmergencyContactsFeature && !props.employeeEmergencyContacts.loaded) ||
    (canEditObjects && !props.taxCards.loaded) ||
    !props.oneTimePays.loaded ||
    !props.carAllowances.loaded ||
    !props.coarseCarAllowances.loaded ||
    !props.employmentPositions.loaded ||
    !props.pensionCompanies.loaded ||
    !props.projects.loaded ||
    (employee.earliestMutableContract && !employee.earliestMutableContract.remuneration)

  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            { loading: props.timeRegistrations.loading, text: t('loading.reducer.time_registrations') },
            { loading: !props.coarseTimeRegistrations.loaded, text: t('loading.reducer.coarse_time_registrations') },
            { loading: props.salaryRegistrations.loading, text: t('loading.reducer.salary_registrations') },
            { loading: !props.coarseSalaryRegistrations, text: t('loading.reducer.coarse_salary_registrations') },
            { loading: canSeeSalaryRates && !props.paySlips.loaded, text: t('loading.reducer.pay_slips') },
            { loading: !props.salaryCycles.loaded, text: t('loading.reducer.salary_cycles') },
            { loading: canEditObjects && !props.employments.loaded, text: t('loading.reducer.employments') },
            {
              loading: canEditObjects && hasEmployeeEmergencyContactsFeature && !props.employeeEmergencyContacts.loaded,
              text: t('loading.reducer.employee_emergency_contacts'),
            },
            { loading: canEditObjects && !props.taxCards.loaded, text: t('loading.reducer.tax_cards') },
            { loading: !props.oneTimePays.loaded, text: t('loading.reducer.one_time_pays') },
            { loading: !props.carAllowances.loaded, text: t('loading.reducer.car_allowances') },
            { loading: !props.coarseCarAllowances.loaded, text: t('loading.reducer.coarse_car_allowances') },
            {
              loading: !!employee.earliestMutableContract && !employee.earliestMutableContract.remuneration,
              text: t('loading.reducer.remuneration'),
            },
            { loading: !props.employmentPositions.loaded, text: t('loading.reducer.employment_positions') },
            { loading: !props.pensionCompanies.loaded, text: t('loading.reducer.pension_companies') },
            { loading: !props.projects.loaded, text: t('loading.reducer.projects') },
          ])}
        />
      </div>
    )
  }

  return (
    <EmployeesSingleComponent
      section={section}
      subsection={subsection}
      alerts={props.alerts}
      employee={employee}
      employees={props.employees}
      company={company}
      companyUsers={props.companyUsers}
      user={props.user}
      companyFeatures={props.companyFeatures.companyFeatures}
      settingsEnabled={company.settingsEnabled}
      timeRegistrations={props.timeRegistrations}
      timeRegistrationTemplates={props.timeRegistrationTemplates}
      coarseTimeRegistrations={props.coarseTimeRegistrations}
      coarseSalaryRegistrations={props.coarseSalaryRegistrations}
      leaveBalances={props.leaveBalances}
      leaveAdjustments={props.leaveAdjustments}
      paySlips={props.paySlips}
      employmentPositions={props.employmentPositions.employmentPositions}
      salaryCycles={props.salaryCycles.salaryCycles}
      pensionCompanies={props.pensionCompanies.pensionCompanies}
      leaveTypes={props.leaveTypes.leaveTypes}
      supplementAdjustments={props.supplementAdjustments}
      supplementBalances={props.supplementBalances}
      supplementTypes={props.supplementTypes.supplementTypes}
      salaryTypes={props.salaryTypes.salaryTypes}
      startBalances={props.startBalances}
      banks={props.banks}
      employeeEmergencyContacts={props.employeeEmergencyContacts}
      employeeContractDeltas={props.employeeContractDeltas}
      hasFutureContractsFeature={hasFutureContractsFeature}
      employments={props.employments}
      contracts={props.contracts}
      costCenters={props.costCenters}
      departments={props.departments}
      documents={props.documents}
      documentCategories={props.documentCategories}
      taxCards={props.taxCards}
      taxCardRequests={props.taxCardRequests}
      oneTimePays={props.oneTimePays}
      oneTimePayPensions={props.oneTimePayPensions}
      carAllowances={props.carAllowances}
      coarseCarAllowances={props.coarseCarAllowances}
      employeeInvites={props.employeeInvites}
      employeeProfileImages={props.employeeProfileImages}
      accountingDimensions={props.accountingDimensions}
      employeeDimensions={props.employeeDimensions.employeeDimensions}
      companyAccountingIntegration={props.companyAccountingIntegration}
      expenseCategories={props.expenseCategories.expenseCategories}
      reimbursementVouchers={props.reimbursementVouchers}
      salaryRegistrations={props.salaryRegistrations}
      warnings={props.warnings.warnings}
      pricingPackages={props.pricingPackages.pricingPackages}
      companyPricingPackages={props.companyPricingPackages}
      projects={props.projects.projects}
      assets={props.assets}
      assetCategories={props.assetCategories}
      hasEmployeeEmergencyContactsFeature={hasEmployeeEmergencyContactsFeature}
      canSeeSalaryRates={canSeeSalaryRates}
      canEditObjects={canEditObjects}
      canApproveObjects={canApproveObjects}
      canHireFire={canHireFire}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      updateEmployee={props.updateEmployee}
      deleteEmployee={props.deleteEmployee}
      createTimeRegistration={props.createTimeRegistration}
      createTimeRegistrationBulk={props.createTimeRegistrationBulk}
      approveTimeRegistrations={props.approveTimeRegistrations}
      unapproveTimeRegistrations={props.unapproveTimeRegistrations}
      updateTimeRegistration={props.updateTimeRegistration}
      deleteTimeRegistration={props.deleteTimeRegistration}
      deleteTimeRegistrationBulk={props.deleteTimeRegistrationBulk}
      updateCoarseTimeRegistrationBulk={props.updateCoarseTimeRegistrationBulk}
      createCoarseSalaryRegistrationBulk={props.createCoarseSalaryRegistrationBulk}
      updateCoarseSalaryRegistrationBulk={props.updateCoarseSalaryRegistrationBulk}
      deleteCoarseSalaryRegistration={props.deleteCoarseSalaryRegistration}
      getLeaveBalances={props.getLeaveBalances}
      getSupplementBalances={props.getSupplementBalances}
      getBanks={props.getBanks}
      updateEmployeeEmergencyContact={props.updateEmployeeEmergencyContact}
      deleteEmployeeEmergencyContact={props.deleteEmployeeEmergencyContact}
      addEmployment={props.addEmployment}
      updateEmployment={props.updateEmployment}
      terminateEmployment={props.terminateEmployment}
      rehireEmployee={props.rehireEmployee}
      getStartBalances={props.getStartBalances}
      updateStartBalances={props.updateStartBalances}
      addContract={props.addContract}
      updateContract={props.updateContract}
      deleteContract={props.deleteContract}
      createLeave={props.createLeave}
      removeLeave={props.removeLeave}
      addDepartment={props.addDepartment}
      getDocuments={props.getDocuments}
      addDocument={props.addDocument}
      updateDocument={props.updateDocument}
      deleteDocument={props.deleteDocument}
      getDocumentCategories={props.getDocumentCategories}
      updateTaxCard={props.updateTaxCard}
      getTaxCardRequests={props.getTaxCardRequests}
      addTaxCardRequest={props.addTaxCardRequest}
      addOneTimePay={props.addOneTimePay}
      updateOneTimePay={props.updateOneTimePay}
      approveOneTimePays={props.approveOneTimePays}
      unapproveOneTimePays={props.unapproveOneTimePays}
      deleteOneTimePay={props.deleteOneTimePay}
      createCarAllowances={props.createCarAllowances}
      approveCarAllowances={props.approveCarAllowances}
      unapproveCarAllowances={props.unapproveCarAllowances}
      updateCarAllowance={props.updateCarAllowance}
      deleteCarAllowance={props.deleteCarAllowance}
      createCoarseCarAllowance={props.createCoarseCarAllowance}
      updateCoarseCarAllowance={props.updateCoarseCarAllowance}
      deleteCoarseCarAllowance={props.deleteCoarseCarAllowance}
      sendInvite={props.sendInvite}
      deleteEmployeeUser={props.deleteEmployeeUser}
      updateProfileImage={props.updateProfileImage}
      getContractDeltas={props.getContractDeltas}
      setEmployeeContract={props.setEmployeeContract}
      addLeaveAdjustment={props.addLeaveAdjustment}
      updateLeaveAdjustment={props.updateLeaveAdjustment}
      deleteLeaveAdjustment={props.deleteLeaveAdjustment}
      addSupplementAdjustment={props.addSupplementAdjustment}
      updateSupplementAdjustment={props.updateSupplementAdjustment}
      deleteSupplementAdjustment={props.deleteSupplementAdjustment}
      getOneTimePayPensions={props.getOneTimePayPensions}
      addOneTimePayPension={props.addOneTimePayPension}
      updateOneTimePayPension={props.updateOneTimePayPension}
      updateEmployeeDimensions={props.updateEmployeeDimensions}
      addReimbursementVoucher={props.addReimbursementVoucher}
      updateReimbursementVoucher={props.updateReimbursementVoucher}
      updateReimbursementVoucherFields={props.updateReimbursementVoucherFields}
      approveReimbursementVouchers={props.approveReimbursementVouchers}
      unapproveReimbursementVouchers={props.unapproveReimbursementVouchers}
      draftReimbursementVouchers={props.draftReimbursementVouchers}
      createSalaryRegistration={props.createSalaryRegistration}
      updateSalaryRegistration={props.updateSalaryRegistration}
      approveSalaryRegistrations={props.approveSalaryRegistrations}
      unapproveSalaryRegistrations={props.unapproveSalaryRegistrations}
      deleteSalaryRegistrationBulk={props.deleteSalaryRegistrationBulk}
      deleteSalaryRegistration={props.deleteSalaryRegistration}
      createAsset={props.createAsset}
      updateAsset={props.updateAsset}
      deleteAsset={props.deleteAsset}
      returnAsset={props.returnAsset}
      receiveAsset={props.receiveAsset}
      hideAsset={props.hideAsset}
      getAssetCategories={props.getAssetCategories}
      getAssets={props.getAssets}
      getCompanyAccountingIntegration={props.getCompanyAccountingIntegration}
      getAccountingDimensions={props.getAccountingDimensions}
      getCompanyPricingPackages={props.getCompanyPricingPackages}
      deleteWarning={props.deleteWarning}
      markEmployeeReady={props.markEmployeeReady}
      unmarkEmployeeReady={props.unmarkEmployeeReady}
      getTimeRegistrationTemplates={props.getTimeRegistrationTemplates}
      saveTimeRegistrationTemplates={props.saveTimeRegistrationTemplates}
      deleteTimeRegistrationTemplate={props.deleteTimeRegistrationTemplate}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    employees: state.employees,
    companies: state.companies,
    companyUsers: state.companyUsers,
    user: state.user,
    companyFeatures: state.companyFeatures,
    timeRegistrations: state.timeRegistrations,
    timeRegistrationTemplates: state.timeRegistrationTemplates,
    coarseTimeRegistrations: state.coarseTimeRegistrations,
    coarseSalaryRegistrations: state.coarseSalaryRegistrations,
    leaveBalances: state.leaveBalances,
    leaveAdjustments: state.leaveAdjustments,
    paySlips: state.paySlips,
    employmentPositions: state.employmentPositions,
    salaryCycles: state.salaryCycles,
    pensionCompanies: state.pensionCompanies,
    leaveTypes: state.leaveTypes,
    supplementAdjustments: state.supplementAdjustments,
    supplementBalances: state.supplementBalances,
    supplementTypes: state.supplementTypes,
    salaryTypes: state.salaryTypes,
    startBalances: state.startBalances,
    banks: state.banks,
    employeeEmergencyContacts: state.employeeEmergencyContacts,
    employeeContractDeltas: state.employeeContractDeltas,
    employments: state.employments,
    contracts: state.contracts,
    costCenters: state.costCenters,
    departments: state.departments,
    documents: state.documents,
    documentCategories: state.documentCategories,
    taxCards: state.taxCards,
    taxCardRequests: state.taxCardRequests,
    oneTimePays: state.oneTimePays,
    oneTimePayPensions: state.oneTimePayPensions,
    carAllowances: state.carAllowances,
    coarseCarAllowances: state.coarseCarAllowances,
    employeeInvites: state.employeeInvites,
    employeeProfileImages: state.employeeProfileImages,
    employeeDimensions: state.employeeDimensions,
    accountingDimensions: state.accountingDimensions,
    companyAccountingIntegration: state.companyAccountingIntegration,
    expenseCategories: state.expenseCategories,
    reimbursementVouchers: state.reimbursementVouchers,
    salaryRegistrations: state.salaryRegistrations,
    warnings: state.warnings,
    pricingPackages: state.pricingPackages,
    companyPricingPackages: state.companyPricingPackages,
    assets: state.assets,
    assetCategories: state.assetCategories,
    projects: state.projects,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    updateEmployee: updateEmployee,
    deleteEmployee: deleteEmployee,
    getTimeRegistrations: getTimeRegistrations,
    createTimeRegistration: createTimeRegistration,
    createTimeRegistrationBulk: createTimeRegistrationBulk,
    approveTimeRegistrations: approveTimeRegistrations,
    unapproveTimeRegistrations: unapproveTimeRegistrations,
    updateTimeRegistration: updateTimeRegistration,
    deleteTimeRegistration: deleteTimeRegistration,
    deleteTimeRegistrationBulk: deleteTimeRegistrationBulk,
    getCoarseTimeRegistrations: getCoarseTimeRegistrations,
    updateCoarseTimeRegistrationBulk: updateCoarseTimeRegistrationBulk,
    getCoarseSalaryRegistrations: getCoarseSalaryRegistrations,
    createCoarseSalaryRegistrationBulk: createCoarseSalaryRegistrationBulk,
    updateCoarseSalaryRegistrationBulk: updateCoarseSalaryRegistrationBulk,
    deleteCoarseSalaryRegistration: deleteCoarseSalaryRegistration,
    getLeaveBalances: getLeaveBalances,
    getSupplementBalances: getSupplementBalances,
    getPaySlips: getPaySlips,
    getSalaryCycles: getSalaryCycles,
    getEmploymentPositions: getEmploymentPositions,
    getPensionCompanies: getPensionCompanies,
    getBanks: getBanks,
    getEmployeeEmergencyContact: getEmployeeEmergencyContact,
    updateEmployeeEmergencyContact: updateEmployeeEmergencyContact,
    deleteEmployeeEmergencyContact: deleteEmployeeEmergencyContact,
    getEmployments: getEmployments,
    addEmployment: addEmployment,
    updateEmployment: updateEmployment,
    terminateEmployment: terminateEmployment,
    rehireEmployee: rehireEmployee,
    getStartBalances: getStartBalances,
    updateStartBalances: updateStartBalances,
    addContract: addContract,
    getContracts: getContracts,
    updateContract: updateContract,
    deleteContract: deleteContract,
    createLeave: createLeave,
    removeLeave: removeLeave,
    addDepartment: addDepartment,
    getCostCenters: getCostCenters,
    getDepartments: getDepartments,
    getDocuments: getDocuments,
    addDocument: addDocument,
    updateDocument: updateDocument,
    deleteDocument: deleteDocument,
    getDocumentCategories: getDocumentCategories,
    getTaxCards: getTaxCards,
    updateTaxCard: updateTaxCard,
    getTaxCardRequests: getTaxCardRequests,
    addTaxCardRequest: addTaxCardRequest,
    getOneTimePays: getOneTimePays,
    addOneTimePay: addOneTimePay,
    updateOneTimePay: updateOneTimePay,
    approveOneTimePays: approveOneTimePays,
    unapproveOneTimePays: unapproveOneTimePays,
    deleteOneTimePay: deleteOneTimePay,
    getCarAllowances: getCarAllowances,
    createCarAllowances: createCarAllowances,
    approveCarAllowances: approveCarAllowances,
    unapproveCarAllowances: unapproveCarAllowances,
    updateCarAllowance: updateCarAllowance,
    deleteCarAllowance: deleteCarAllowance,
    getCoarseCarAllowances: getCoarseCarAllowances,
    createCoarseCarAllowance: createCoarseCarAllowance,
    updateCoarseCarAllowance: updateCoarseCarAllowance,
    deleteCoarseCarAllowance: deleteCoarseCarAllowance,
    sendInvite: sendInvite,
    deleteEmployeeUser: deleteEmployeeUser,
    updateProfileImage: updateProfileImage,
    getContractDeltas: getContractDeltas,
    setEmployeeContract: setEmployeeContract,
    getLeaveAdjustments: getLeaveAdjustments,
    addLeaveAdjustment: addLeaveAdjustment,
    updateLeaveAdjustment: updateLeaveAdjustment,
    deleteLeaveAdjustment: deleteLeaveAdjustment,
    getLeaveTypes: getLeaveTypes,
    getSupplementAdjustments: getSupplementAdjustments,
    addSupplementAdjustment: addSupplementAdjustment,
    updateSupplementAdjustment: updateSupplementAdjustment,
    deleteSupplementAdjustment: deleteSupplementAdjustment,
    getRemuneration: getRemuneration,
    getOneTimePayPensions: getOneTimePayPensions,
    addOneTimePayPension: addOneTimePayPension,
    updateOneTimePayPension: updateOneTimePayPension,
    getEmployeeDimensions: getEmployeeDimensions,
    updateEmployeeDimensions: updateEmployeeDimensions,
    getCompanyAccountingIntegration: getCompanyAccountingIntegration,
    getExpenseCategories: getExpenseCategories,
    getReimbursementVouchers: getReimbursementVouchers,
    getAccountingDimensions: getAccountingDimensions,
    addReimbursementVoucher: addReimbursementVoucher,
    updateReimbursementVoucher: updateReimbursementVoucher,
    updateReimbursementVoucherFields: updateReimbursementVoucherFields,
    approveReimbursementVouchers: approveReimbursementVouchers,
    unapproveReimbursementVouchers: unapproveReimbursementVouchers,
    draftReimbursementVouchers: draftReimbursementVouchers,
    getSalaryRegistrations: getSalaryRegistrations,
    createSalaryRegistration: createSalaryRegistration,
    updateSalaryRegistration: updateSalaryRegistration,
    approveSalaryRegistrations: approveSalaryRegistrations,
    unapproveSalaryRegistrations: unapproveSalaryRegistrations,
    deleteSalaryRegistrationBulk: deleteSalaryRegistrationBulk,
    deleteSalaryRegistration: deleteSalaryRegistration,
    getPricingPackages: getPricingPackages,
    getCompanyPricingPackages: getCompanyPricingPackages,
    createAsset: createAsset,
    updateAsset: updateAsset,
    deleteAsset: deleteAsset,
    returnAsset: returnAsset,
    receiveAsset: receiveAsset,
    hideAsset: hideAsset,
    getAssetCategories: getAssetCategories,
    getAssets: getAssets,
    getProjects: getProjects,
    deleteWarning: deleteWarning,
    markEmployeeReady: markEmployeeReady,
    unmarkEmployeeReady: unmarkEmployeeReady,
    getTimeRegistrationTemplates: getTimeRegistrationTemplates,
    saveTimeRegistrationTemplates: saveTimeRegistrationTemplates,
    deleteTimeRegistrationTemplate: deleteTimeRegistrationTemplate,
  }
)(EmployeesSingle)
