import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { CostCenterAccounting } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import { OneTimePayCreationFields, OneTimePayMutableFields } from '../../../model/oneTimePay'
import SalaryCycle from '../../../model/salaryCycle'
import SupplementType from '../../../model/supplementType'
import { LeaveBalanceReducer } from '../../../reducers/leaveBalances'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { SupplementBalanceReducer } from '../../../reducers/supplementBalances'
import { UserReducer } from '../../../reducers/user'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import BonusEditForm, { BonusResult } from './BonusEditForm'

type Props = {
  visible: boolean
  editing: boolean
  canApproveObjects: boolean
  employee?: Employee
  company: Company
  oneTimePayID?: string
  oneTimePays: OneTimePayReducer
  salaryCycle?: SalaryCycle
  supplementBalances: SupplementBalanceReducer
  supplementTypes: List<SupplementType>
  leaveBalances: LeaveBalanceReducer
  leaveTypes: List<LeaveType>
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>
  user: UserReducer

  addOneTimePay: (employeeID: string, otp: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, otp: OneTimePayMutableFields) => void
  getRemuneration?: (contractID: string) => void
  getSupplementBalances: (companyID?: string, employeeID?: string) => void
  getLeaveBalances: (companyID?: string, employeeID?: string) => void
}

export default function BonusEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const oneTimePayError = props.oneTimePays.error
  const { visible, employee, getRemuneration } = props

  useEffect(() => {
    if (getRemuneration && employee && employee.activeContract) {
      if (!employee.activeContract.remuneration) {
        getRemuneration(employee.activeContract.id)
      }
    }
  }, [employee, getRemuneration])

  const { leaveBalances, getLeaveBalances, supplementBalances, getSupplementBalances } = props

  useEffect(() => {
    if (!employee) {
      return
    }
    if (leaveBalances.employeeID !== employee.id || (!leaveBalances.loading && !leaveBalances.loaded)) {
      getLeaveBalances(undefined, employee.id)
    }
    if (supplementBalances.employeeID !== employee.id || (!supplementBalances.loading && !supplementBalances.loaded)) {
      getSupplementBalances(undefined, employee.id)
    }
  }, [employee, leaveBalances, getLeaveBalances, supplementBalances, getSupplementBalances])

  useEffect(() => {
    visibleComponentDidUpdate(visible, oneTimePayError, error, setError)
  }, [visible, oneTimePayError, error])

  const handleSubmit = (values: BonusResult) => {
    if (!props.editing || !props.employee) {
      return
    }
    const oneTimePay: OneTimePayCreationFields = {
      employeeID: props.employee.id,
      type: values.type,
      category: values.category,
      dispositionDate: values.dispositionDate,
      amount: values.amount,
      rate: values.rate,
      units: values.units,
      title: values.title,
      costCenterID: values.costCenterID,
      accountingText: values.accountingText,
      approved: values.approved,
    }
    if (props.oneTimePayID) {
      props.updateOneTimePay(props.employee.id, { ...oneTimePay, id: props.oneTimePayID })
    } else {
      props.addOneTimePay(props.employee.id, oneTimePay)
    }
  }

  if (!props.employee || !props.employee.activeContract || !props.salaryCycle) {
    return null
  }
  if (
    (props.supplementBalances && !props.supplementBalances.loaded) ||
    (props.leaveBalances && !props.leaveBalances.loaded) ||
    !props.employee.activeContract.remuneration
  ) {
    return (
      <Card className="bonus-modal">
        <LoadingOverlay />
      </Card>
    )
  }
  return (
    <Card className="bonus-modal">
      <Subtitle>
        {props.oneTimePayID
          ? props.editing
            ? t('bonus.edit.title.edit')
            : t('bonus.edit.title.view')
          : t('bonus.edit.title.create')}
      </Subtitle>
      <p>{t('bonus.edit.intro')}</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <BonusEditForm
        editing={props.editing}
        canApproveObjects={props.canApproveObjects}
        employee={props.employee}
        company={props.company}
        oneTimePayID={props.oneTimePayID}
        oneTimePays={props.oneTimePays}
        salaryCycle={props.salaryCycle}
        supplementBalances={props.supplementBalances.supplementBalances}
        supplementTypes={props.supplementTypes}
        leaveBalances={props.leaveBalances.leaveBalances}
        leaveTypes={props.leaveTypes}
        costCenterAccounting={props.costCenterAccounting}
        costCenters={props.costCenters}
        user={props.user}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
