import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company, { SwipeCostPayer } from '../../model/company'
import Employee from '../../model/employee'
import { EmploymentType } from '../../model/types'
import { CompanyReducer } from '../../reducers/companies'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { employmentTypes, isEmployeeType } from '../../utils/employee-utils'
import { formatError } from '../../utils/error-utils'
import { hasSwipeFee } from '../../utils/feature-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatEmploymentType } from '../../utils/format-utils'
import { forceParseInputNumber, formatCurrency, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { t, tx } from '../../utils/translation-utils'
import Form from '../antd/form'
import Radio, { Group as RadioGroup } from '../antd/radio'
import Select from '../antd/select'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Switch from '../elements/switch'
import SwitchWrapper from '../form-elements/SwitchWrapper'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  companies: CompanyReducer
  company: Company
  employees: List<Employee>
  hasSwipeFeature: boolean
  hasImmediatePayFeature: boolean
  hasZimpler: boolean

  addAlert: addAlertSignature
}

type Fields = {
  enableSwipe: boolean
  enableImmediatePay: boolean
  enableLimit: boolean
  autoApproveTimeRegistrationHours: boolean
  enableSwipeFee: boolean
  swipeFeeCost?: string
  swipeFeeCostType: 'Fixed' | 'Percentage'
  swipeFeeCostCap?: string
  swipeFeeCostFrequency: 'Each' | 'Period'
  enableAlwaysCurrentPayRollRun: boolean
  periodicLimitForImmediatePayOut?: string
  swipeNotificationAt?: string
  swipeInstantCostPayer: SwipeCostPayer
} & Partial<Record<`allow${EmploymentType}`, boolean>>

export type TransferImmediatePayResult = {
  enableSwipe: boolean
  enableImmediatePay: boolean
  periodicLimitForImmediatePayOut?: number
  swipeNotificationAt?: number
  autoApproveTimeRegistrationHours: boolean
  swipeFeeCost?: number
  swipeFeeCostType: 'Fixed' | 'Percentage'
  swipeFeeCostCap?: number
  swipeFeeCostFrequency: 'Each' | 'Period'
  swipeEmployeeTypes: EmploymentType[]
  enableAlwaysCurrentPayRollRun: boolean
  swipeInstantCostPayer: SwipeCostPayer
}

function TransferImmediatePayForm(
  props: Props & FormComponentProps<Fields, TransferImmediatePayResult>
): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { companies, addAlert, company } = props
  const previousCompanies = usePrevious(companies)

  useEffect(() => {
    if (previousCompanies && previousCompanies.saving && !companies.saving) {
      if (!companies.error) {
        addAlert('success', t('transfer_immediate_pay.alert.success', { name: company.name }), { timeout: 5 })
      }
    }
  })
  useEffect(() => {
    regularComponentDidUpdate(companies.error, error, setError)
  }, [companies, error])

  const getNumberOfValidEmployees = (): number => {
    const swipeEmployeeTypes: EmploymentType[] = []
    employmentTypes.forEach((employmentType) => {
      if (props.getFieldValue(`allow${employmentType}`)) {
        swipeEmployeeTypes.push(employmentType)
      }
    })
    return props.employees.filter(
      (employee) =>
        employee.hasSwipe && swipeEmployeeTypes.some((employeeType) => isEmployeeType(employee, employeeType))
    ).size
  }

  const getMaximalAutoSwipe = () => {
    const number = forceParseInputNumber(props.getFieldValue('periodicLimitForImmediatePayOut'))
    return number * getNumberOfValidEmployees()
  }

  const { decorateField, getFieldValue } = props

  const validEmployees = getNumberOfValidEmployees()

  return (
    <div>
      {props.getFormError()}
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Row>
        {props.hasImmediatePayFeature && (
          <Col span={8}>
            <div className="ant-switch-wrapper">
              {decorateField('enableImmediatePay', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">{t('transfer_immediate_pay.form.enable_immediate_pay')}</span>
            </div>
          </Col>
        )}
        {props.hasSwipeFeature && (
          <Col span={8}>
            <div className="ant-switch-wrapper">
              {decorateField('enableSwipe', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">{t('transfer_immediate_pay.form.enable_swipe')}</span>
            </div>
          </Col>
        )}
      </Row>
      {(getFieldValue('enableImmediatePay') || getFieldValue('enableSwipe')) && (
        <Row>
          <Col span={16}>
            <p style={{ padding: '0', margin: '0' }}>{t('transfer_immediate_pay.form.enable_limit.note')}</p>
          </Col>
        </Row>
      )}
      {(getFieldValue('enableImmediatePay') || getFieldValue('enableSwipe')) && (
        <Row style={getFieldValue('enableSwipe') ? { marginTop: '0px' } : {}}>
          <Col span={8}>
            <div className="ant-switch-wrapper">
              {decorateField('enableLimit', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">{t('transfer_immediate_pay.form.enable_limit')}</span>
            </div>
          </Col>
          {getFieldValue('enableLimit') && (
            <Col span={8}>
              {decorateField('periodicLimitForImmediatePayOut', {
                placeholder: t('transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out'),
                suffix: t('transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.suffix'),
                skipLabel: true,
                validate: (val) => {
                  if (!val) {
                    return t('transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.required')
                  }
                  if (parseInputNumber(val) < 1) {
                    return t('transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.invalid')
                  }
                  return null
                },
              })(<Input />)}
            </Col>
          )}
          {getFieldValue('enableLimit') &&
            parseInputNumber(getFieldValue('periodicLimitForImmediatePayOut')) > 0 &&
            validEmployees > 1 && (
              <Col span={8}>
                <p>
                  {tx('transfer_immediate_pay.form.periodic_limit_for_immediate_pay_out.note', {
                    count: validEmployees,
                    amount: <strong>{formatCurrency(getMaximalAutoSwipe(), 2)}</strong>,
                  })}
                </p>
              </Col>
            )}
        </Row>
      )}
      {getFieldValue('enableSwipe') && (
        <div className="swipe-employee-types">
          <Row style={{ marginTop: '15px' }}>
            <Col span={16}>
              <p style={{ padding: '0', margin: '0' }}>
                {tx('transfer_immediate_pay.form.swipe_employment_type.note', {
                  employees: (
                    <strong>
                      {t('transfer_immediate_pay.form.swipe_employment_type.note.employees', { count: validEmployees })}
                    </strong>
                  ),
                  link: (
                    <Link to={'/swipe-overview/employees'}>
                      {t('transfer_immediate_pay.form.swipe_employment_type.note.link')}
                    </Link>
                  ),
                })}
              </p>
            </Col>
          </Row>
          <Row style={{ marginTop: '0px' }}>
            {employmentTypes.map((employmentType, i) => {
              return (
                <Col span={4} key={i}>
                  <div className="ant-switch-wrapper">
                    {decorateField(`allow${employmentType}`, {
                      skipWrapper: true,
                      skipLabel: true,
                      valueOnChecked: true,
                      noBlur: true,
                    })(<Switch />)}
                    <span className="ant-switch-text">{formatEmploymentType(employmentType)}</span>
                  </div>
                </Col>
              )
            })}
          </Row>
        </div>
      )}
      {getFieldValue('enableSwipe') && (
        <Row style={{ marginTop: '25px' }}>
          <Col span={8}>
            <div className="ant-switch-wrapper">
              {decorateField('autoApproveTimeRegistrationHours', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">
                {t('transfer_immediate_pay.form.auto_approve_time_registration_hours')}
              </span>
            </div>
          </Col>
          <Col span={12}>
            <p>{t('transfer_immediate_pay.form.auto_approve_time_registration_hours.note')}</p>
          </Col>
        </Row>
      )}
      {getFieldValue('enableSwipe') && props.hasZimpler && (
        <Row>
          <Col span={8}>
            {decorateField('swipeInstantCostPayer', {
              skipWrapper: true,
              skipLabel: true,
            })(
              <RadioGroup>
                <Radio value={'Employer'}>{t('transfer_immediate_pay.form.swipe_instant_cost_payer.employer')}</Radio>
                <Radio value={'Shared'}>{t('transfer_immediate_pay.form.swipe_instant_cost_payer.shared')}</Radio>
                <Radio value={'Employee'}>{t('transfer_immediate_pay.form.swipe_instant_cost_payer.employee')}</Radio>
              </RadioGroup>
            )}
          </Col>
          <Col span={12}>
            <p style={{ paddingTop: '10px' }}>
              {t('transfer_immediate_pay.form.swipe_instant_cost_payer.note.line_1')}
              <br />
              {t('transfer_immediate_pay.form.swipe_instant_cost_payer.note.line_2')}
            </p>
          </Col>
        </Row>
      )}
      {hasSwipeFee() && getFieldValue('enableSwipe') && (
        <>
          <Row>
            <Col span={8}>
              <SwitchWrapper id={'enableSwipeFee'} decorateField={decorateField}>
                {t('transfer_immediate_pay.form.enable_swipe_fee')}
              </SwitchWrapper>
            </Col>
            <Col span={12}>
              <p>
                {props.hasZimpler && getFieldValue('swipeInstantCostPayer') !== 'Employer'
                  ? t('transfer_immediate_pay.form.enable_swipe_fee.note.with_zimpler_fee')
                  : t('transfer_immediate_pay.form.enable_swipe_fee.note.without_zimpler')}
              </p>
            </Col>
          </Row>
          {getFieldValue('enableSwipeFee') && (
            <>
              <Row>
                <Col span={8}>
                  <Form.Item validateStatus={props.getFieldError('swipeFeeCost') ? 'error' : 'success'}>
                    <label>
                      {props.getFieldError('swipeFeeCost') || t('transfer_immediate_pay.form.swipe_fee_cost')}
                    </label>
                    <Input.Group compact>
                      {decorateField('swipeFeeCost', {
                        placeholder: t('transfer_immediate_pay.form.swipe_fee_cost'),
                        validate: (val) => {
                          if (!val) {
                            return t('transfer_immediate_pay.form.swipe_fee_cost.required')
                          }
                          const n = parseInputNumber(val)
                          if (typeof n !== 'number') {
                            return t('transfer_immediate_pay.form.swipe_fee_cost.invalid')
                          }
                          return null
                        },
                        skipWrapper: true,
                        skipLabel: true,
                      })(<Input style={{ width: '80%' }} />)}
                      {decorateField('swipeFeeCostType', {
                        skipWrapper: true,
                        skipLabel: true,
                      })(
                        <Select dropdownMatchSelectWidth={false} style={{ width: '20%' }}>
                          <Select.Option value="Percentage">
                            {t('transfer_immediate_pay.form.swipe_fee_cost.percentage')}
                          </Select.Option>
                          <Select.Option value="Fixed">
                            {t('transfer_immediate_pay.form.swipe_fee_cost.fixed')}
                          </Select.Option>
                        </Select>
                      )}
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  {decorateField('swipeFeeCostFrequency', {
                    title: t('transfer_immediate_pay.form.swipe_fee_cost_frequency'),
                  })(
                    <RadioGroup>
                      <Radio value={'Period'}>{t('transfer_immediate_pay.form.swipe_fee_cost_frequency.period')}</Radio>
                      <Radio value={'Each'}>{t('transfer_immediate_pay.form.swipe_fee_cost_frequency.each')}</Radio>
                    </RadioGroup>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  {getFieldValue('swipeFeeCostType') === 'Percentage' ? (
                    decorateField('swipeFeeCostCap', {
                      placeholder: t('transfer_immediate_pay.form.swipe_fee_cost_cap'),
                      suffix: t('transfer_immediate_pay.form.swipe_fee_cost_cap.suffix'),
                      validate: (val) => {
                        if (!val) {
                          return null
                        }
                        if (forceParseInputNumber(val) < 0) {
                          return t('transfer_immediate_pay.form.swipe_fee_cost_cap.invalid')
                        }
                        return null
                      },
                    })(<Input />)
                  ) : (
                    <>&nbsp;</>
                  )}
                </Col>
                <Col span={12}>
                  <p>{t('transfer_immediate_pay.form.swipe_fee_cost.note.type')}</p>
                  <p>{t('transfer_immediate_pay.form.swipe_fee_cost.note.frequency')}</p>
                  {getFieldValue('swipeFeeCostType') === 'Percentage' && (
                    <p>{t('transfer_immediate_pay.form.swipe_fee_cost.note.cap')}</p>
                  )}
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      {getFieldValue('enableSwipe') && (
        <Row>
          <Col span={8}>
            {decorateField('swipeNotificationAt', {
              placeholder: t('transfer_immediate_pay.form.swipe_notification_at'),
              validate: (val) => {
                if (!val) {
                  return t('transfer_immediate_pay.form.swipe_notification_at.required')
                }
                if (parseInputNumber(val) < 0 || parseInputNumber(val) > 23) {
                  return t('transfer_immediate_pay.form.swipe_notification_at.between_0_and_23')
                }
                return null
              },
            })(<Input />)}
          </Col>
          <Col span={12}>
            <p style={{ paddingTop: '10px' }}>
              {t('transfer_immediate_pay.form.swipe_notification_at.note.line_1')}
              <br />
              {t('transfer_immediate_pay.form.swipe_notification_at.note.line_2')}
            </p>
          </Col>
        </Row>
      )}
      {getFieldValue('enableSwipe') && (
        <Row>
          <Col span={8}>
            <div className="ant-switch-wrapper">
              {decorateField('enableAlwaysCurrentPayRollRun', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">
                {t('transfer_immediate_pay.form.enable_always_current_pay_roll_run')}
              </span>
            </div>
          </Col>
          <Col span={12}>
            <p style={{ paddingTop: '10px' }}>
              {t('transfer_immediate_pay.form.enable_always_current_pay_roll_run.note.line_1')}
              <br />
              {t('transfer_immediate_pay.form.enable_always_current_pay_roll_run.note.line_2')}
            </p>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary" className="gtm-transfer-settings-save">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.companies.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, TransferImmediatePayResult>({
  mapPropsToFields: (props) => {
    let limit = props.company.periodicLimitForImmediatePayOut || 0
    const enableLimit = limit > 0.0
    if (!enableLimit) {
      limit = 5000.0 // to suggest 5000
    }
    const fields: Fields = {
      enableImmediatePay: props.company.enableImmediatePay,
      enableSwipe: props.company.enableSwipe,
      enableSwipeFee: hasSwipeFee() ? (props.company.swipeFeeCost ?? 0) > 0 : false,
      swipeFeeCost: props.company.swipeFeeCost ? formatInputNumber(props.company.swipeFeeCost) : undefined,
      swipeFeeCostType: props.company.swipeFeeCostType ?? 'Fixed',
      swipeFeeCostCap: props.company.swipeFeeCostCap ? formatInputNumber(props.company.swipeFeeCostCap) : undefined,
      swipeFeeCostFrequency: props.company.swipeFeeCostFrequency ?? 'Period',
      periodicLimitForImmediatePayOut: formatInputNumber(limit),
      enableLimit: enableLimit,
      autoApproveTimeRegistrationHours: props.company.settingsEnabled.some(
        (setting) => setting === 'AutoApproveTimeRegistrationHours'
      ),
      swipeNotificationAt: props.company.swipeNotificationAt ? props.company.swipeNotificationAt.toString() : '16',
      enableAlwaysCurrentPayRollRun: props.company.enableAlwaysCurrentPayRollRun,
      swipeInstantCostPayer: props.company.swipeInstantCostPayer || 'Employer',
    }
    if (props.company.swipeEmployeeTypes.length > 0) {
      props.company.swipeEmployeeTypes.forEach((employeeType) => {
        fields[`allow${employeeType}`] = true
      })
    } else {
      fields.allowHourly = true // suggest hourly per default
      fields.allowCommissioned = true // suggest commission as well
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values: Partial<Fields> = {}
    switch (key) {
      case 'periodicLimitForImmediatePayOut':
      case 'swipeFeeCost':
      case 'swipeFeeCostCap':
        values[key] = formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    const result = {
      ...values,
      periodicLimitForImmediatePayOut: values.enableLimit
        ? forceParseInputNumber(values.periodicLimitForImmediatePayOut)
        : 0.0,
      swipeFeeCost: values.enableSwipeFee ? forceParseInputNumber(values.swipeFeeCost) || undefined : 0,
      swipeFeeCostCap:
        values.enableSwipeFee && values.swipeFeeCostType === 'Percentage'
          ? forceParseInputNumber(values.swipeFeeCostCap) || undefined
          : 0,
      swipeNotificationAt: forceParseInputNumber(values.swipeNotificationAt),
      swipeEmployeeTypes: employmentTypes.reduce((list: EmploymentType[], employmentType) => {
        if (values[`allow${employmentType}`]) {
          list.push(employmentType)
        }
        return list
      }, []),
    }
    if (result.swipeEmployeeTypes.length === 0) {
      result.enableSwipe = false // an empty list is the same as disabling Swipe
    }
    return result
  },
})(TransferImmediatePayForm)
