import {
  addHours,
  addMinutes,
  addMonths,
  compareAsc,
  differenceInHours,
  differenceInWeeks,
  endOfMonth,
  startOfMonth,
  startOfWeek,
  subHours,
} from 'date-fns'

import { TimeRegistrationClass } from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { getDate } from './date-utils'

export type WorkHourResult = {
  employeeID: string
  // Employees must have less or equal to 48 hours average per week over 4 calendar months.
  // Dates are listed are the start of week of the last week for each violation.
  // For the purposes of calculating this average, we include the full weeks on both ends
  // of the four months.
  averageRuleViolations: Date[]
  // Employees must have at least 11 hours of rest for every 24 hour period.
  // Dates listed are the start of week for each violation.
  restHoursViolations: Date[]
  // Employees must have at least 1 day off during every week.
  // Dates listed are the start of week for each violation.
  dayOffViolations: Date[]
}

type RegDistance = {
  // startPeriod is the beginning of the break and 24 hours ahead
  startPeriod: [Date, Date]
  // endPeriod is the reverse, based on the end of the break
  endPeriod: [Date, Date]
  breakLength: number
}

export type BasicTimeRegistration = {
  employeeID: string
  class: TimeRegistrationClass
  date: DateFormat
  hours?: number
  start?: number
  end?: number
}

function calcRegDistance(regA: BasicTimeRegistration, regB: BasicTimeRegistration): RegDistance {
  const aTimeEnd = regA.end ?? (regA.start ? regA.start + (regA.hours ?? 0) * 60 : (regA.hours ?? 0) * 60)
  const bTimeStart = regB.start ?? 0
  const aEnd = addMinutes(getDate(regA.date, true), aTimeEnd)
  const bStart = addMinutes(getDate(regB.date, true), bTimeStart)
  return {
    startPeriod: [aEnd, addHours(aEnd, 24)],
    endPeriod: [subHours(bStart, 24), bStart],
    breakLength: Math.abs(differenceInHours(aEnd, bStart, { roundingMethod: 'ceil' })),
  }
}

export function validateWorkHours(
  registrations: readonly BasicTimeRegistration[],
  employeeID?: string
): WorkHourResult[] {
  registrations = registrations.filter(
    (reg) => reg.class === 'Work Hours' && (!employeeID || reg.employeeID === employeeID)
  )
  type WeekContainerBase = {
    employeeID: string
    start: Date
    registrations: BasicTimeRegistration[]
  }
  type WeekContainer = WeekContainerBase & {
    totalHours: number
    daysWithRegistrations: number
    distanceBetweenRegistrations: RegDistance[]
  }
  const employeeIDs: string[] = []
  const weeks = registrations
    .reduce((weeks: WeekContainerBase[], reg) => {
      if (!employeeIDs.includes(reg.employeeID)) {
        employeeIDs.push(reg.employeeID)
      }
      const start = startOfWeek(getDate(reg.date), { weekStartsOn: 1 })
      const weekIdx = weeks.findIndex(
        (week) => week.employeeID === reg.employeeID && compareAsc(week.start, start) === 0
      )
      if (weekIdx === -1) {
        weeks.push({
          employeeID: reg.employeeID,
          start: start,
          registrations: [reg],
        })
      } else {
        weeks[weekIdx].registrations = [...weeks[weekIdx].registrations, reg]
      }
      return weeks
    }, [])
    .sort((a, b) => {
      if (a.employeeID === b.employeeID) {
        compareAsc(a.start, b.start)
      }
      return a.employeeID.localeCompare(b.employeeID)
    })
    .reduce((weeks: WeekContainer[], week) => {
      week.registrations = week.registrations.sort((a, b) => {
        if (a.date === b.date) {
          return (a.start ?? 0) < (b.start ?? 0) ? -1 : 1
        }
        return a.date.localeCompare(b.date)
      })
      const totalHours = week.registrations.reduce((total, reg) => total + (reg.hours ?? 0), 0)
      const daysWithRegistrations = week.registrations.reduce((days: string[], reg) => {
        if (!days.includes(reg.date)) {
          days.push(reg.date)
        }
        return days
      }, []).length
      let distanceBetweenRegistrations: RegDistance[] = []
      if (weeks.length > 0) {
        const lastWeek = weeks[weeks.length - 1]
        if (lastWeek.employeeID === week.employeeID) {
          distanceBetweenRegistrations.push(
            calcRegDistance(lastWeek.registrations[lastWeek.registrations.length - 1], week.registrations[0])
          )
        }
      }
      distanceBetweenRegistrations = week.registrations.reduce((list, reg, idx, regs) => {
        if (idx === 0) {
          return list
        }
        list.push(calcRegDistance(regs[idx - 1], reg))
        return list
      }, distanceBetweenRegistrations)
      weeks.push({ ...week, totalHours, daysWithRegistrations, distanceBetweenRegistrations })
      return weeks
    }, [])

  return employeeIDs
    .reduce((results: WorkHourResult[], employeeID) => {
      const theseWeeks = weeks.filter((week) => week.employeeID === employeeID)
      // our four months rolling average
      const averageRuleViolations = []
      let periodStart = startOfMonth(weeks[0].start)
      const checkEnd = endOfMonth(weeks[weeks.length - 1].start)
      while (compareAsc(periodStart, checkEnd) < 0) {
        const periodEnd = endOfMonth(addMonths(periodStart, 4))
        const periodWeekStart = startOfWeek(periodStart, { weekStartsOn: 1 })
        const periodWeekEnd = startOfWeek(periodEnd, { weekStartsOn: 1 })
        const numberOfWeeks = differenceInWeeks(periodWeekStart, periodWeekEnd)
        const total = theseWeeks.reduce((total, week) => {
          if (compareAsc(periodWeekStart, week.start) < 0) {
            return total
          }
          if (compareAsc(periodWeekEnd, week.start) > 0) {
            return total
          }
          return total + week.totalHours
        }, 0)
        const average = total / numberOfWeeks
        if (average > 48) {
          averageRuleViolations.push(periodWeekEnd)
        }
        periodStart = addMonths(periodStart, 1)
      }

      // rest hours (11 per 24 hours)
      // TODO: Actually check whether it's legal, and not just if the break is less than 11 hours
      const restHoursViolations = theseWeeks.reduce((dates: Date[], week) => {
        return [
          ...dates,
          ...week.distanceBetweenRegistrations.filter((dist) => dist.breakLength < 11).map(() => week.start),
        ]
      }, [])

      // at least one day off per week
      const dayOffViolations = theseWeeks.reduce((dates: Date[], week) => {
        if (week.daysWithRegistrations < 7) {
          return dates
        }
        return [...dates, week.start]
      }, [])

      results.push({
        employeeID,
        averageRuleViolations,
        restHoursViolations,
        dayOffViolations,
      })
      return results
    }, [])
    .filter(
      (result) =>
        result.averageRuleViolations.length > 0 ||
        result.restHoursViolations.length > 0 ||
        result.dayOffViolations.length > 0
    )
}
