import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import Employee from '../../model/employee'
import { FileChangeEvent } from '../../utils/antd-utils'
import { getAccessToken } from '../../utils/cookie-utils'
import { compareEmployees } from '../../utils/employee-utils'
import { combineSearchOption } from '../../utils/form-utils'
import { url } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import UploadDragger from '../antd/upload/Dragger'
import Button from '../elements/button'
import { Col, Row } from '../elements/grid'
import Icon from '../elements/Icon'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  employees: List<Employee>

  onSubmit: (_fileID: string, _employeeID?: string) => void
}

export default function ReimbursementVoucherNewForm(props: Props): ReactElement | null {
  const [employeeID, setEmployeeID] = useState<string | undefined>(undefined)
  const [uploading, setUploading] = useState(false)

  const handleFileUpload = (e: FileChangeEvent) => {
    switch (e.file.status) {
      case 'uploading':
        setUploading(true)
        break
      case 'done':
        setUploading(false)
        if (e.file.response.data) {
          props.onSubmit(e.file.response.data.id, employeeID)
        }
        break
      default:
        break
    }
  }

  return (
    <div>
      {uploading && <LoadingOverlay />}
      <Row>
        <Col span={24}>
          <Select
            tabIndex={1}
            showSearch={true}
            filterOption={(inputValue: string, option: ReactElement) =>
              combineSearchOption(option).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }
            placeholder={t('reimbursement_vouchers.new.form.employee.placeholder')}
            onChange={(value: string) => setEmployeeID(value)}
          >
            {props.employees
              .sort((a, b) => {
                const { orderA, orderB } = {
                  orderA: a.employmentStatus === 'Terminated' ? 1 : 0,
                  orderB: b.employmentStatus === 'Terminated' ? 1 : 0,
                }
                if (orderA === orderB) {
                  return compareEmployees(a, b)
                }
                return orderA - orderB
              })
              .map((employee) => {
                const name = employee.name || employee.email
                return (
                  <Select.Option key={employee.id} value={employee.id} title={name}>
                    {employee.employmentStatus === 'Terminated'
                      ? t('reimbursement_vouchers.new.form.employee.terminated', { name })
                      : employee.disableReimbursementVouchers
                      ? t('reimbursement_vouchers.new.form.employee.disabled', { name })
                      : name}
                  </Select.Option>
                )
              })}
          </Select>
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={24}>
          <UploadDragger
            tabIndex={2}
            name={'fileData'}
            action={url('v2/stagedFiles')}
            headers={{ Authorization: getAccessToken() }}
            accept={'*'}
            showUploadList={false}
            onChange={handleFileUpload}
            disabled={!employeeID}
          >
            <Button type="secondary" className="gtm-reimbursement-upload" disabled={!employeeID}>
              <Icon type="upload" />
              {t('reimbursement_vouchers.new.form.upload')}
            </Button>
          </UploadDragger>
        </Col>
      </Row>
    </div>
  )
}
