import { List } from 'immutable'
import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import {
  applyStagedImport,
  getStagedImport,
  getStagedImportResult,
  postStagedImports,
  StagedImportType,
} from '../../api/staged-import'
import ApplyResult from '../../model/applyResult'
import AsynchronousTask from '../../model/asynchronousTask'
import Company from '../../model/company'
import CostCenter from '../../model/costCenter'
import LeaveType from '../../model/leaveType'
import { OneTimePayType } from '../../model/oneTimePay'
import SalaryCycle, { SalaryFrequency } from '../../model/salaryCycle'
import SalaryPeriod from '../../model/salaryPeriod'
import SalaryType from '../../model/salaryType'
import StagedImportResult from '../../model/stagedImportResult'
import { AlertReducer } from '../../reducers/alerts'
import { ContractReducer } from '../../reducers/contracts'
import { EmployeeReducer } from '../../reducers/employees'
import { PayRollReducer } from '../../reducers/payRolls'
import { SalaryCycleReducer } from '../../reducers/salaryCycles'
import { paths } from '../../routes'
import RemunerationType from '../../types/remuneration-type'
import StagedImportFileType from '../../types/staged-import-file-type'
import { FileChangeEvent } from '../../utils/antd-utils'
import { getAccessToken } from '../../utils/cookie-utils'
import { formatDate } from '../../utils/date-utils'
import { convertStoredErrorToError, formatError, isRequestError } from '../../utils/error-utils'
import { formatSalaryFrequency } from '../../utils/format-utils'
import { logWarning } from '../../utils/log-utils'
import { secureUrl, url } from '../../utils/request-utils'
import { getCurrentPeriodFromDispositionDate } from '../../utils/salary-period-utils'
import { t, tx } from '../../utils/translation-utils'
import Radio, { Group as RadioGroup } from '../antd/radio'
import Select from '../antd/select'
import UploadDragger from '../antd/upload/Dragger'
import Alert from '../elements/alert'
import Blocktitle from '../elements/Blocktitle'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/Icon'
import Steps from '../elements/steps'
import Subtitle from '../elements/Subtitle'
import Switch from '../elements/switch'
import Alerts from '../widgets/Alerts'
import LoadingOverlay from '../widgets/LoadingOverlay'
import StagedImportResultComponent from './StagedImportResultDisplay'

import './StagedImport.css'

type Props = {
  type?: StagedImportFileType
  alerts: AlertReducer
  company: Company
  contracts: ContractReducer
  costCenters: List<CostCenter>
  employees: EmployeeReducer
  leaveTypes: List<LeaveType>
  salaryCycles: SalaryCycleReducer
  salaryTypes: List<SalaryType>
  payRolls: PayRollReducer
  asynchronousTasks: List<AsynchronousTask>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getRemuneration: (contractID: string) => void
  getContracts: () => void
  getPayRolls: () => void
}

type ImportType =
  | 'ExcelHours'
  | 'FreelancersBonus'
  | 'DanloenSalary'
  | 'DataloenSalary'
  | 'ProloenSalary'
  | 'WorkfeedSalary'

export default function StagedImport(props: Props): ReactElement | null {
  type State = {
    stagedImportID?: string
    stagedImportResult?: StagedImportResult
    applyResultID?: string
    applyResult?: ApplyResult[]
  }
  const [state, setState] = useState<State>({})
  const [deleteUnsettled, setDeleteUnsettled] = useState(true)

  const getSalaryFrequencies = (): SalaryFrequency[] => {
    const order: Record<SalaryFrequency, number> = {
      Monthly: 0,
      BiWeekly: 10,
      Weekly: 20,
    }
    return props.employees.employees
      .filter(
        (employee) =>
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          employee.activeContract &&
          employee.activeContract.remunerationType !== RemunerationType.SALARIED
      )
      .reduce((list: SalaryFrequency[], employee) => {
        const contract = employee.activeContract
        if (contract) {
          const salaryCycle = props.salaryCycles.salaryCycles.find((cycle) => cycle.id === contract.salaryCycleID)
          if (salaryCycle) {
            if (list.indexOf(salaryCycle.frequency) === -1) {
              return [...list, salaryCycle.frequency]
            }
          }
        }
        return list
      }, [])
      .sort((a, b) => {
        return order[a] - order[b]
      })
  }

  const [frequency, setFrequency] = useState<SalaryFrequency>(() => {
    const frequencies = getSalaryFrequencies()
    return frequencies.length > 0 ? frequencies[0] : 'Monthly'
  })

  const getSalaryCycleID = useCallback(
    (company: Company, salaryCycles: List<SalaryCycle>, thisFrequency = frequency) => {
      let salaryCycleID = undefined
      if (thisFrequency === 'Monthly') {
        salaryCycleID = company.defaultHourlyMonthlyCycleID
      }
      if (thisFrequency === 'BiWeekly') {
        salaryCycleID = company.defaultBiweeklyCycleID
      }
      if (!salaryCycleID) {
        if (salaryCycles) {
          const salaryCycle = salaryCycles.find(
            (salaryCycle) => salaryCycle.frequency === thisFrequency && !salaryCycle.prepaid && !salaryCycle.offset
          )
          if (salaryCycle) {
            salaryCycleID = salaryCycle.id
          }
        }
      }
      return salaryCycleID
    },
    [frequency]
  )

  const company = props.company
  const { payRolls, salaryCycles } = props

  const getSalaryPeriods = useCallback((): SalaryPeriod[] => {
    if (!company) {
      return []
    }
    const salaryCycleID = getSalaryCycleID(company, salaryCycles.salaryCycles)
    const salaryCycle = salaryCycles.salaryCycles.find((salaryCycle) => salaryCycle.id === salaryCycleID)
    if (salaryCycle) {
      if (!salaryCycle.salaryPeriods) {
        return []
      }
      // only return salary periods that are attached to "open" pay rolls
      return salaryCycle.salaryPeriods.filter((period) =>
        payRolls.payRolls.some((payRoll) => payRoll.status === 'InProgress' && payRoll.salaryPeriod.id === period.id)
      )
    }
    return []
  }, [getSalaryCycleID, company, payRolls, salaryCycles])

  const [salaryPeriodID, setSalaryPeriodID] = useState<string | undefined>(() => {
    const salaryPeriod = getCurrentPeriodFromDispositionDate(getSalaryPeriods())
    if (salaryPeriod) {
      return salaryPeriod.id
    }
    return undefined
  })
  const [importType, setImportType] = useState<ImportType>(() => {
    let importType: ImportType = 'ExcelHours'
    switch (props.type) {
      case StagedImportFileType.TIME_REGISTRATIONS:
        importType = 'ExcelHours'
        break
      case StagedImportFileType.FREELANCERS_FEE:
        importType = 'FreelancersBonus'
        break
      case StagedImportFileType.DANLOEN:
        importType = 'DanloenSalary'
        break
      case StagedImportFileType.PROLOEN:
        importType = 'ProloenSalary'
        break
      default:
        importType = 'ExcelHours'
        break
    }
    return importType
  })
  const [step, setStep] = useState(0)
  const [error, setError] = useState<Error | null>(null)
  const [uploading, setUploading] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (step !== 3 && !!state.stagedImportID && !state.stagedImportResult) {
      const task = props.asynchronousTasks.find((task) => task.id === state.stagedImportID)
      if (task) {
        if (task.status === 'Success') {
          getStagedImport(state.stagedImportID)
            .then((res) => {
              setState((prev) => ({ ...prev, stagedImportResult: res.data }))
              setStep(3)
            })
            .catch((e) => {
              if (isRequestError(e)) {
                setError(e)
              }
            })
            .finally(() => setLoading(false))
        } else if (task.status === 'Failed') {
          setError(convertStoredErrorToError(task.error))
          setLoading(false)
        }
      }
    }
    if (step !== 5 && !!state.applyResultID && !state.applyResult) {
      const task = props.asynchronousTasks.find((task) => task.id === state.applyResultID)
      if (task) {
        if (task.status === 'Success') {
          getStagedImportResult(state.applyResultID)
            .then((res) => {
              setState((prev) => ({ ...prev, applyResult: res.data }))
              setStep(5)
            })
            .catch((e) => {
              if (isRequestError(e)) {
                setError(e)
              }
            })
            .finally(() => setLoading(false))
        } else if (task.status === 'Failed') {
          setError(convertStoredErrorToError(task.error))
          setLoading(false)
        }
      }
    }
  }, [props.asynchronousTasks, state, step])

  const { getPayRolls } = props

  useEffect(() => {
    if (!payRolls.loaded && !payRolls.loading) {
      getPayRolls()
    }
    if (payRolls.loaded && !salaryPeriodID) {
      const salaryPeriod = getCurrentPeriodFromDispositionDate(getSalaryPeriods())
      if (salaryPeriod) {
        setSalaryPeriodID(salaryPeriod.id)
      }
    }
  }, [payRolls, getPayRolls, salaryPeriodID, setSalaryPeriodID, frequency, getSalaryPeriods])

  const handleApplyStagedImport = () => {
    if (state.stagedImportID) {
      setLoading(true)
      applyStagedImport(state.stagedImportID)
        .then((res) => {
          if (!res.error) {
            if (res.data.done) {
              setState((prev) => ({ ...prev, applyResult: res.data.result }))
              setStep(4)
              setLoading(false)
            } else {
              setState((prev) => ({ ...prev, applyResultID: res.data.applyResultID }))
            }
          }
        })
        .catch((e) => {
          if (isRequestError(e)) {
            setError(e)
          }
          setLoading(false)
        })
    }
  }

  const handleChangeType = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const importType = e.target.value
    if (
      ['ExcelHours', 'FreelancersBonus', 'DanloenSalary', 'DataloenSalary', 'ProloenSalary', 'WorkfeedSalary'].some(
        (i) => i === importType
      )
    ) {
      setImportType(importType as ImportType)
    }
  }

  const usingOwnFormat = (): boolean => {
    return importType === 'ExcelHours' || importType === 'FreelancersBonus'
  }

  const usingExternalFormat = (): boolean => {
    return (
      importType === 'DanloenSalary' ||
      importType === 'DataloenSalary' ||
      importType === 'ProloenSalary' ||
      importType === 'WorkfeedSalary'
    )
  }

  const handleSelectType = (e: React.MouseEvent) => {
    e.preventDefault()
    if (usingOwnFormat()) {
      setStep(1)
    } else {
      setStep(2)
    }
  }

  const handleFileUpload = (e: FileChangeEvent) => {
    switch (e.file.status) {
      case 'uploading':
        setUploading(true)
        break
      case 'done': {
        setUploading(false)
        let oneTimePayType: OneTimePayType | undefined = undefined
        let useImportType: StagedImportType = 'ExcelHours'
        switch (importType) {
          case 'ExcelHours':
            break
          case 'DanloenSalary':
            useImportType = 'DanloenSalary'
            break
          case 'DataloenSalary':
            useImportType = 'DataloenSalary'
            break
          case 'ProloenSalary':
            useImportType = 'ProloenSalary'
            break
          case 'WorkfeedSalary':
            useImportType = 'WorkfeedSalary'
            break
          case 'FreelancersBonus':
            useImportType = 'OneTimePays'
            oneTimePayType = 'Compensation'
            break
          default:
            logWarning('Unknown importType: ' + importType)
            return // do nothing
        }
        if (e.file.response.data) {
          setLoading(true)
          postStagedImports(
            useImportType,
            props.company.id,
            e.file.response.data.id,
            salaryPeriodID,
            oneTimePayType,
            deleteUnsettled
          )
            .then((res) => {
              if (!res.error) {
                if (res.data.done) {
                  setState({ stagedImportID: res.data.stagedImportID, stagedImportResult: res.data.stagedImport })
                  setStep(3)
                  setLoading(false)
                } else {
                  setState({ stagedImportID: res.data.stagedImportID })
                }
              }
            })
            .catch((e) => {
              if (isRequestError(e)) {
                setError(e)
              }
              setLoading(false)
            })
        }
        break
      }
      default:
        break
    }
  }

  const changeFrequency = (frequency: SalaryFrequency) => {
    setFrequency(frequency)
  }

  const changePeriod = (id: string) => {
    setSalaryPeriodID(id)
  }

  const getTemplateURL = () => {
    let url
    switch (importType) {
      case 'ExcelHours':
        url = '/v2/timeRegistrations/Excel'
        url += '?salaryPeriodID=' + salaryPeriodID
        break
      case 'FreelancersBonus': {
        const otpType: OneTimePayType = 'Compensation'
        url = '/v2/oneTimePays/Excel'
        url += '?oneTimePayType=' + otpType
        break
      }
      default:
        return undefined
    }
    if (!url) {
      return undefined
    }
    url += '&companyID=' + props.company.id
    url += '&disposition=inline'
    return secureUrl(url)
  }

  const formatImportTypeFile = () => {
    switch (importType) {
      case 'DanloenSalary':
        return t('staged_import.import_type_file.danloen_salary')
      case 'DataloenSalary':
        return t('staged_import.import_type_file.dataloen_salary')
      case 'ProloenSalary':
        return t('staged_import.import_type_file.proloen_salary')
      case 'WorkfeedSalary':
        return t('staged_import.import_type_file.workfeed_salary')
      default:
        return t('staged_import.import_type_file.excel_file')
    }
  }

  if (!company) {
    return null
  }

  if (!props.employees.loaded || !props.salaryCycles.loaded) {
    return <LoadingOverlay />
  }

  const frequencies = getSalaryFrequencies()
  const salaryPeriods = getSalaryPeriods()

  const periodForm = () => {
    return (
      <div className="staged-import-period-form">
        {frequencies.length > 1 && (
          <div className="staged-import-frequency-select">
            <p>{t('staged_import.period_form.select_cycle')}:</p>
            <Select
              placeholder={t('staged_import.period_form.select_cycle')}
              value={frequency}
              onChange={changeFrequency}
            >
              {frequencies.map((frequency) => (
                <Select.Option key={frequency} value={frequency}>
                  {formatSalaryFrequency(frequency)}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        {salaryPeriods.length > 1 && (
          <div className="staged-import-period-select">
            <p>{t('staged_import.period_form.select_period')}:</p>
            <Select
              placeholder={t('staged_import.period_form.select_period')}
              value={salaryPeriodID}
              onChange={changePeriod}
            >
              {salaryPeriods.map((salaryPeriod) => (
                <Select.Option key={salaryPeriod.id} value={salaryPeriod.id}>
                  {formatDate(salaryPeriod.start)} - {formatDate(salaryPeriod.end)}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
      </div>
    )
  }

  const templateURL = getTemplateURL()

  return (
    <div className="staged-import">
      <Row>
        <Col span={16}>
          <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
          <div className="staged-import-container">
            {error && <Alert message={formatError(error)} type="error" showIcon />}
            <div>
              {step === 0 && (
                <Card>
                  <Blocktitle>{t('staged_import.step.0.title')}</Blocktitle>
                  <p>{t('staged_import.step.0.intro.line_1')}</p>
                  <p>
                    {tx('staged_import.step.0.intro.line_2', {
                      title: <strong>{t('staged_import.step.0.intro.line_2.title')}</strong>,
                    })}
                  </p>
                  <p>
                    {tx('staged_import.step.0.intro.line_3', {
                      title: <strong>{t('staged_import.step.0.intro.line_3.title')}</strong>,
                    })}
                  </p>
                  <p>{t('staged_import.step.0.intro.line_4')}</p>
                  <p>
                    {tx('staged_import.step.0.intro.line_5', {
                      danloen: <strong>{t('staged_import.step.0.intro.line_5.danloen')}</strong>,
                      dataloen: <strong>{t('staged_import.step.0.intro.line_5.dataloen')}</strong>,
                      proloen: <strong>{t('staged_import.step.0.intro.line_5.proloen')}</strong>,
                    })}
                  </p>
                  <p>{t('staged_import.step.0.intro.line_6')}</p>
                  <RadioGroup
                    placeholder={t('staged_import.step.0.import_type_select.placeholder')}
                    value={importType}
                    onChange={handleChangeType}
                  >
                    <Radio value="ExcelHours">{t('staged_import.step.0.import_type_select.type.excel_hours')}</Radio>
                    <Radio value="FreelancersBonus">
                      {t('staged_import.step.0.import_type_select.type.freelancer_bonus')}
                    </Radio>
                    <Radio value="DanloenSalary">
                      {t('staged_import.step.0.import_type_select.type.danloen_salary')}
                    </Radio>
                    <Radio value="DataloenSalary">
                      {t('staged_import.step.0.import_type_select.type.dataloen_salary')}
                    </Radio>
                    <Radio value="ProloenSalary">
                      {t('staged_import.step.0.import_type_select.type.proloen_salary')}
                    </Radio>
                    <Radio value="WorkfeedSalary">
                      {t('staged_import.step.0.import_type_select.type.workfeed_salary')}
                    </Radio>
                  </RadioGroup>
                  {importType === 'ExcelHours' && frequencies.length > 1 && (
                    <div className="staged-import-period-select">
                      <p>{t('staged_import.step.0.select_period')}:</p>
                      <Select
                        placeholder={t('staged_import.step.0.select_period')}
                        value={frequency}
                        onChange={changeFrequency}
                      >
                        {frequencies.map((frequency) => (
                          <Select.Option key={frequency} value={frequency}>
                            {formatSalaryFrequency(frequency)}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  )}
                  {usingExternalFormat() && (
                    <p>
                      {tx('staged_import.step.0.external_format_note', {
                        nb: <strong>{t('staged_import.step.0.external_format_note.nb')}</strong>,
                      })}
                    </p>
                  )}
                  {importType === 'WorkfeedSalary' && <p>{t('staged_import.step.0.workfeed_format_note')}</p>}
                  {(importType === 'DanloenSalary' || importType === 'ProloenSalary') && (
                    <p>
                      {tx(
                        [
                          'staged_import',
                          'step',
                          '0',
                          'external_salary_file_setting',
                          'note',
                          importType === 'ProloenSalary' ? 'proloen' : 'danloen',
                        ],
                        {
                          link: (
                            <Link
                              to={
                                '/' +
                                paths.COMPANIES +
                                '/' +
                                company.id +
                                '/external-salary-file-settings/' +
                                (importType === 'ProloenSalary' ? 'proloen' : 'danloen')
                              }
                            >
                              {t('staged_import.step.0.external_salary_file_setting.note.link')}
                            </Link>
                          ),
                        }
                      )}
                    </p>
                  )}
                  <div className="button-row">
                    <Button type="secondary" onClick={handleSelectType}>
                      {usingOwnFormat()
                        ? t('staged_import.step.0.continue.template')
                        : t('staged_import.step.0.continue.upload')}
                    </Button>
                  </div>
                </Card>
              )}
              {step === 1 && (
                <Card>
                  <Blocktitle>{t('staged_import.step.1.title')}</Blocktitle>
                  <p>
                    {importType === 'FreelancersBonus'
                      ? t('staged_import.step.1.intro.freelancers')
                      : t('staged_import.step.1.intro.employees')}
                  </p>
                  {importType === 'ExcelHours' && periodForm()}
                  <div className="button-row">
                    <Button size="large" onClick={() => setStep(0)} className="gtm-stagedfile-back-1 back-button">
                      {t('staged_import.step.1.button.back')}
                    </Button>
                    {templateURL && (
                      <Link to={templateURL} target="_blank" rel="noopener noreferrer" onClick={() => setStep(2)}>
                        <Button
                          type="secondary"
                          className="gtm-stagedfile-download-template"
                          disabled={!salaryPeriodID}
                        >
                          <Icon type="download" />
                          {t('staged_import.step.1.button.download_template')}
                        </Button>
                      </Link>
                    )}
                    <Button size="large" onClick={() => setStep(2)}>
                      {t('staged_import.step.1.button.continue')}
                    </Button>
                  </div>
                </Card>
              )}
              {step === 2 && (
                <Card>
                  <Blocktitle>{t('staged_import.step.2.title')}</Blocktitle>
                  {importType === 'ExcelHours' && <p>{t('staged_import.step.2.intro.excel_hours')}</p>}
                  {importType === 'FreelancersBonus' && <p>{t('staged_import.step.2.intro.freelancers_bonus')}</p>}
                  {importType === 'DanloenSalary' && <p>{t('staged_import.step.2.intro.danloen_salary')}</p>}
                  {importType === 'DataloenSalary' && <p>{t('staged_import.step.2.intro.dataloen_salary')}</p>}
                  {importType === 'ProloenSalary' && <p>{t('staged_import.step.2.intro.proloen_salary')}</p>}
                  {importType === 'WorkfeedSalary' && <p>{t('staged_import.step.2.intro.workfeed_salary')}</p>}
                  {importType === 'ExcelHours' && periodForm()}
                  <div className="button-row">
                    <Button
                      size="large"
                      className="gtm-stagedfile-back-2 back-button"
                      onClick={() => {
                        if (usingOwnFormat()) {
                          setStep(1)
                        } else {
                          setStep(0)
                        }
                      }}
                    >
                      {t('staged_import.step.2.button.back')}
                    </Button>
                    <UploadDragger
                      name={'fileData'}
                      action={url('v2/stagedFiles')}
                      headers={{ authorization: getAccessToken() }}
                      accept={usingOwnFormat() ? '.xlsx' : '.csv'}
                      showUploadList={false}
                      onChange={handleFileUpload}
                    >
                      <Button type="secondary" className="gtm-stagedfile-upload" disabled={salaryPeriods.length === 0}>
                        <Icon type="upload" />
                        {t('staged_import.step.2.button.upload', {
                          type: formatImportTypeFile(),
                        })}
                      </Button>
                    </UploadDragger>
                  </div>
                  {importType === 'WorkfeedSalary' && (
                    <div className="button-row">
                      <div className="ant-switch-wrapper">
                        <Switch checked={deleteUnsettled} onChange={(value: boolean) => setDeleteUnsettled(value)} />
                        <span className="ant-switch-text">{t('staged_import.step.2.check_box.delete_unsettled')}</span>
                      </div>
                    </div>
                  )}
                  {(uploading || loading) && (
                    <LoadingOverlay
                      text={
                        state.stagedImportID
                          ? t('staged_import.step.2.loading.async')
                          : t('staged_import.step.2.loading.not_async')
                      }
                    />
                  )}
                </Card>
              )}
              {(step === 3 || step === 4) && (
                <Card>
                  <Blocktitle>
                    {step === 3 ? t('staged_import.step.3.title') : t('staged_import.step.4.title')}
                  </Blocktitle>
                  {step === 3 && (
                    <div>
                      <p>{t('staged_import.step.3.intro.line_1')}</p>
                      <p>{t('staged_import.step.3.intro.line_2')}</p>
                    </div>
                  )}
                  <div className="button-row" style={{ marginBottom: '15px' }}>
                    {step === 3 && (
                      <Button size="large" onClick={() => setStep(2)} className="gtm-stagedfile-back-3 back-button">
                        {t('staged_import.step.3.button.back')}
                      </Button>
                    )}
                    {step === 4 && (
                      <Link
                        to={
                          '/' +
                          (importType === 'FreelancersBonus' ? paths.FREELANCERS_OVERVIEW : paths.TIME_REGISTRATION)
                        }
                      >
                        <Button type="secondary" className="gtm-stagedfile-done">
                          {t('staged_import.step.4.button.done')}
                        </Button>
                      </Link>
                    )}
                    {step === 3 && (
                      <Button onClick={handleApplyStagedImport} type="secondary" className="gtm-stagedfile-apply">
                        {t('staged_import.step.3.button.approve')}
                      </Button>
                    )}
                  </div>
                  {loading && (
                    <LoadingOverlay
                      text={
                        state.applyResultID
                          ? t('staged_import.step.3.loading.async')
                          : t('staged_import.step.3.loading.not_async')
                      }
                    />
                  )}
                  <StagedImportResultComponent
                    stagedImport={state.stagedImportResult}
                    applyResult={state.applyResult}
                    employees={props.employees.employees}
                    contracts={props.contracts}
                    costCenters={props.costCenters}
                    leaveTypes={props.leaveTypes}
                    salaryTypes={props.salaryTypes}
                    salaryCycles={props.salaryCycles.salaryCycles}
                    getContracts={props.getContracts}
                  />
                </Card>
              )}
            </div>
          </div>
        </Col>
        <Col span={7}>
          <Card>
            <Subtitle>{t('staged_import.steps.title')}</Subtitle>
            <p>{t('staged_import.steps.intro')}</p>

            <Steps direction="vertical" current={step}>
              <Steps.Step title={t('staged_import.steps.0.summary')} />
              <Steps.Step title={t('staged_import.steps.1.summary')} />
              <Steps.Step title={t('staged_import.steps.2.summary')} />
              <Steps.Step title={t('staged_import.steps.3.summary')} />
              <Steps.Step title={t('staged_import.steps.4.summary')} />
            </Steps>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
