import PaySlip from '../model/paySlip'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchPaySlips(
  payRollID?: string,
  employeeID?: string,
  limit = 1000,
  offset = 0
): Promise<RequestResponse<PaySlip[]>> {
  if (payRollID) {
    employeeID = undefined
  }
  return secureRequest('GET', url('/v2/paySlips', { payRollID, employeeID, limit, offset }))
}

export function fetchPaySlip(paySlipID: string): Promise<RequestResponse<PaySlip>> {
  return secureRequest('GET', url('/v2/paySlips/' + paySlipID))
}

export function resendPaySlip(paySlipID: string): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/paySlips/' + paySlipID + '/resend'))
}
