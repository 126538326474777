import CoarseSalaryRegistration, { CoarseSalaryRegistrationMutableFields } from '../model/coarseSalaryRegistration'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCoarseSalaryRegistrations(
  companyID?: string,
  salaryPeriodID?: string,
  employeeID?: string,
  payRollID?: string
): Promise<RequestResponse<CoarseSalaryRegistration[]>> {
  return secureRequest(
    'GET',
    url('/v2/coarseSalaryRegistrations', { companyID, salaryPeriodID, employeeID, payRollID })
  )
}

export function postCoarseSalaryRegistration(
  coarseSalaryRegistration: CoarseSalaryRegistration
): Promise<RequestResponse<CoarseSalaryRegistration>> {
  return secureRequest('POST', url('/v2/coarseSalaryRegistrations'), {
    body: {
      employeeID: coarseSalaryRegistration.employeeID,
      salaryPeriodID: coarseSalaryRegistration.salaryPeriodID,
      salaryTypeID: coarseSalaryRegistration.salaryTypeID,
      quantity: coarseSalaryRegistration.quantity,
    },
  })
}

export function postCoarseSalaryRegistrationBulk(
  coarseSalaryRegistrations: CoarseSalaryRegistration[]
): Promise<RequestResponse<CoarseSalaryRegistration[]>> {
  return secureRequest('POST', url('/v2/coarseSalaryRegistrationsBulk'), {
    body: coarseSalaryRegistrations.map((reg) => ({
      employeeID: reg.employeeID,
      salaryPeriodID: reg.salaryPeriodID,
      salaryTypeID: reg.salaryTypeID,
      quantity: reg.quantity,
    })),
  })
}

export function putCoarseSalaryRegistration(
  salaryRegistrationID: string,
  coarseSalaryRegistration: CoarseSalaryRegistration
): Promise<RequestResponse<CoarseSalaryRegistration>> {
  return secureRequest('PUT', url('/v2/coarseSalaryRegistrations/' + salaryRegistrationID), {
    body: {
      employeeID: coarseSalaryRegistration.employeeID,
      salaryPeriodID: coarseSalaryRegistration.salaryPeriodID,
      salaryTypeID: coarseSalaryRegistration.salaryTypeID,
      quantity: coarseSalaryRegistration.quantity,
    },
  })
}

export function putCoarseSalaryRegistrationBulk(
  coarseSalaryRegistrations: CoarseSalaryRegistrationMutableFields[]
): Promise<RequestResponse<CoarseSalaryRegistration[]>> {
  return secureRequest('PUT', url('/v2/coarseSalaryRegistrationsBulk'), {
    body: coarseSalaryRegistrations.map((reg) => ({
      employeeID: reg.employeeID,
      salaryPeriodID: reg.salaryPeriodID,
      salaryTypeID: reg.salaryTypeID,
      quantity: reg.quantity,
    })),
  })
}

export function delCoarseSalaryRegistration(salaryRegistrationID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/coarseSalaryRegistrations/' + salaryRegistrationID))
}
