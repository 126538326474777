import { combineReducers } from 'redux'

import accountingDimensions, { AccountingDimensionReducer } from './accountingDimensions'
import agreements, { AgreementReducer } from './agreements'
import alerts, { AlertReducer } from './alerts'
import apiKeys, { ApiKeyReducer } from './apiKeys'
import assetCategories, { AssetCategoryReducer } from './assetCategories'
import assets, { AssetReducer } from './assets'
import asynchronousSchedules, { AsynchronousScheduleReducer } from './asynchronousSchedules'
import asynchronousTasks, { AsynchronousTaskReducer } from './asynchronousTasks'
import availableAccountingIntegrations, {
  AvailableAccountingIntegrationReducer,
} from './availableAccountingIntegrations'
import availableDataIntegrations, { AvailableDataIntegrationReducer } from './availableDataIntegrations'
import banks, { BankReducer } from './banks'
import bootstrap, { BootstrapReducer } from './bootstrap'
import carAllowances, { CarAllowanceReducer } from './carAllowances'
import coarseCarAllowances, { CoarseCarAllowanceReducer } from './coarseCarAllowances'
import coarseSalaryRegistrations, { CoarseSalaryRegistrationReducer } from './coarseSalaryRegistrations'
import coarseTimeRegistrations, { CoarseTimeRegistrationReducer } from './coarseTimeRegistrations'
import companies, { CompanyReducer } from './companies'
import companyAccountingIntegration, { CompanyAccountingIntegrationReducer } from './companyAccountingIntegration'
import companyAccountPlans, { CompanyAccountPlanReducer } from './companyAccountPlans'
import companyBankAccounts, { CompanyBankAccountReducer } from './companyBankAccounts'
import companyDataIntegration, { CompanyDataIntegrationReducer } from './companyDataIntegration'
import companyDeviationConfigurations, { CompanyDeviationConfigurationReducer } from './companyDeviationConfigurations'
import companyFeatures, { CompanyFeatureReducer } from './companyFeatures'
import companyGroups, { CompanyGroupReducer } from './companyGroups'
import companyIntegrations, { CompanyIntegrationReducer } from './companyIntegrations'
import companyLogo, { CompanyLogoReducer } from './companyLogo'
import companyPaymentIntegrations, { CompanyPaymentIntegrationReducer } from './companyPaymentIntegrations'
import companyPricingPackages, { CompanyPricingPackageReducer } from './companyPricingPackages'
import companyPricings, { CompanyPricingReducer } from './companyPricings'
import companyUsers, { CompanyUserReducer } from './companyUsers'
import contractBookContracts, { ContractBookContractReducer } from './contractBookContracts'
import contractBookDrafts, { ContractBookDraftReducer } from './contractBookDrafts'
import contractBookIntegrations, { ContractBookIntegrationReducer } from './contractBookIntegrations'
import contractBookTemplates, { ContractBookTemplateReducer } from './contractBookTemplates'
import contracts, { ContractReducer } from './contracts'
import contractTemplateContracts, { ContractTemplateContractReducer } from './contractTemplateContracts'
import contractTemplates, { ContractTemplateReducer } from './contractTemplates'
import costCenters, { CostCenterReducer } from './costCenters'
import dashboards, { DashboardReducer } from './dashboards'
import departments, { DepartmentReducer } from './departments'
import departmentUsers, { DepartmentUserReducer } from './departmentUsers'
import documentCategories, { DocumentCategoryReducer } from './documentCategories'
import documents, { DocumentReducer } from './documents'
import employeeContractDeltas, { EmployeeContractDeltaReducer } from './employeeContractDeltas'
import employeeDimensions, { EmployeeDimensionReducer } from './employeeDimensions'
import employeeEmergencyContacts, { EmployeeEmergencyContactReducer } from './employeeEmergencyContacts'
import employeeInvites, { EmployeeInviteReducer } from './employeeInvites'
import employeeProfileImages, { EmployeeProfileImageReducer } from './employeeProfileImages'
import employees, { EmployeeReducer } from './employees'
import employmentPositions, { EmploymentPositionReducer } from './employmentPositions'
import employments, { EmploymentReducer } from './employments'
import expenseCategories, { ExpenseCategoryReducer } from './expenseCategories'
import externalSalaryFileSettings, { ExternalSalaryFileSettingReducer } from './externalSalaryFileSettings'
import globalMessage, { GlobalMessageReducer } from './globalMessage'
import incomeTaxReports, { IncomeTaxReportReducer } from './incomeTaxReports'
import invoices, { InvoiceReducer } from './invoices'
import leaveAdjustments, { LeaveAdjustmentReducer } from './leaveAdjustments'
import leaveBalances, { LeaveBalanceReducer } from './leaveBalances'
import leaveTypes, { LeaveTypeReducer } from './leaveTypes'
import modals, { ModalReducer } from './modals'
import netsMessages, { NetsMessageReducer } from './netsMessages'
import oneTimePayPensions, { OneTimePayPensionReducer } from './oneTimePayPensions'
import oneTimePays, { OneTimePayReducer } from './oneTimePays'
import outgoingLeads, { OutgoingLeadReducer } from './outgoingLeads'
import paymentConfigurations, { PaymentConfigurationReducer } from './paymentConfigurations'
import payRollDeviations, { PayRollDeviationReducer } from './payRollDeviations'
import payRolls, { PayRollReducer } from './payRolls'
import paySlips, { PaySlipReducer } from './paySlips'
import pensionCompanies, { PensionCompanyReducer } from './pensionCompanies'
import pricingPackages, { PricingPackageReducer } from './pricingPackages'
import projects, { ProjectReducer } from './projects'
import recoveryCodes, { RecoveryCodeReducer } from './recoveryCodes'
import reimbursementVouchers, { ReimbursementVoucherReducer } from './reimbursementVouchers'
import salaryCycles, { SalaryCycleReducer } from './salaryCycles'
import salaryRegistrations, { SalaryRegistrationReducer } from './salaryRegistrations'
import salaryTypes, { SalaryTypeReducer } from './salaryTypes'
import shipments, { ShipmentReducer } from './shipments'
import startBalances, { StartBalanceReducer } from './startBalances'
import stripeConfiguration, { StripeConfigurationReducer } from './stripeConfiguration'
import supplementAdjustments, { SupplementAdjustmentReducer } from './supplementAdjustments'
import supplementBalances, { SupplementBalanceReducer } from './supplementBalances'
import supplementTypes, { SupplementTypeReducer } from './supplementTypes'
import supportAccess, { SupportAccessReducer } from './supportAccess'
import swipes, { SwipeReducer } from './swipes'
import taxCardRequests, { TaxCardRequestReducer } from './taxCardRequests'
import taxCards, { TaxCardReducer } from './taxCards'
import timeRegistrations, { TimeRegistrationReducer } from './timeRegistrations'
import timeRegistrationTemplates, { TimeRegistrationTemplateReducer } from './timeRegistrationTemplates'
import transfers, { TransferReducer } from './transfers'
import user, { UserReducer } from './user'
import userCompanies, { UserCompanyReducer } from './userCompanies'
import userInviteCompanyGroups, { UserInviteCompanyGroupReducer } from './userInviteCompanyGroups'
import userInvites, { UserInviteReducer } from './userInvites'
import vacationCalendars, { VacationCalendarReducer } from './vacationCalendars'
import vouchers, { VoucherReducer } from './vouchers'
import warnings, { WarningReducer } from './warnings'

export interface AllReducers {
  accountingDimensions: AccountingDimensionReducer
  agreements: AgreementReducer
  alerts: AlertReducer
  apiKeys: ApiKeyReducer
  assets: AssetReducer
  assetCategories: AssetCategoryReducer
  asynchronousSchedules: AsynchronousScheduleReducer
  asynchronousTasks: AsynchronousTaskReducer
  availableAccountingIntegrations: AvailableAccountingIntegrationReducer
  availableDataIntegrations: AvailableDataIntegrationReducer
  banks: BankReducer
  bootstrap: BootstrapReducer
  carAllowances: CarAllowanceReducer
  coarseCarAllowances: CoarseCarAllowanceReducer
  coarseSalaryRegistrations: CoarseSalaryRegistrationReducer
  coarseTimeRegistrations: CoarseTimeRegistrationReducer
  companies: CompanyReducer
  companyAccountingIntegration: CompanyAccountingIntegrationReducer
  companyAccountPlans: CompanyAccountPlanReducer
  companyDataIntegration: CompanyDataIntegrationReducer
  companyBankAccounts: CompanyBankAccountReducer
  companyDeviationConfigurations: CompanyDeviationConfigurationReducer
  companyFeatures: CompanyFeatureReducer
  companyIntegrations: CompanyIntegrationReducer
  companyLogo: CompanyLogoReducer
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer
  companyPricingPackages: CompanyPricingPackageReducer
  companyPricings: CompanyPricingReducer
  companyUsers: CompanyUserReducer
  companyGroups: CompanyGroupReducer
  contractBookContracts: ContractBookContractReducer
  contractBookDrafts: ContractBookDraftReducer
  contractBookIntegrations: ContractBookIntegrationReducer
  contractBookTemplates: ContractBookTemplateReducer
  contracts: ContractReducer
  contractTemplates: ContractTemplateReducer
  contractTemplateContracts: ContractTemplateContractReducer
  costCenters: CostCenterReducer
  dashboards: DashboardReducer
  departments: DepartmentReducer
  departmentUsers: DepartmentUserReducer
  documents: DocumentReducer
  documentCategories: DocumentCategoryReducer
  employees: EmployeeReducer
  employeeContractDeltas: EmployeeContractDeltaReducer
  employeeDimensions: EmployeeDimensionReducer
  employeeEmergencyContacts: EmployeeEmergencyContactReducer
  employeeInvites: EmployeeInviteReducer
  employeeProfileImages: EmployeeProfileImageReducer
  employmentPositions: EmploymentPositionReducer
  employments: EmploymentReducer
  expenseCategories: ExpenseCategoryReducer
  externalSalaryFileSettings: ExternalSalaryFileSettingReducer
  globalMessage: GlobalMessageReducer
  incomeTaxReports: IncomeTaxReportReducer
  invoices: InvoiceReducer
  leaveAdjustments: LeaveAdjustmentReducer
  leaveBalances: LeaveBalanceReducer
  leaveTypes: LeaveTypeReducer
  modals: ModalReducer
  netsMessages: NetsMessageReducer
  oneTimePays: OneTimePayReducer
  oneTimePayPensions: OneTimePayPensionReducer
  outgoingLeads: OutgoingLeadReducer
  paymentConfigurations: PaymentConfigurationReducer
  payRollDeviations: PayRollDeviationReducer
  payRolls: PayRollReducer
  paySlips: PaySlipReducer
  pensionCompanies: PensionCompanyReducer
  pricingPackages: PricingPackageReducer
  recoveryCodes: RecoveryCodeReducer
  reimbursementVouchers: ReimbursementVoucherReducer
  salaryCycles: SalaryCycleReducer
  salaryRegistrations: SalaryRegistrationReducer
  salaryTypes: SalaryTypeReducer
  shipments: ShipmentReducer
  startBalances: StartBalanceReducer
  stripeConfiguration: StripeConfigurationReducer
  supplementAdjustments: SupplementAdjustmentReducer
  supplementBalances: SupplementBalanceReducer
  supplementTypes: SupplementTypeReducer
  supportAccess: SupportAccessReducer
  swipes: SwipeReducer
  taxCards: TaxCardReducer
  taxCardRequests: TaxCardRequestReducer
  timeRegistrations: TimeRegistrationReducer
  timeRegistrationTemplates: TimeRegistrationTemplateReducer
  projects: ProjectReducer
  transfers: TransferReducer
  user: UserReducer
  userInvites: UserInviteReducer
  userInviteCompanyGroups: UserInviteCompanyGroupReducer
  userCompanies: UserCompanyReducer
  vacationCalendars: VacationCalendarReducer
  vouchers: VoucherReducer
  warnings: WarningReducer
}

export default combineReducers({
  alerts,
  accountingDimensions,
  agreements,
  apiKeys,
  assets,
  assetCategories,
  asynchronousSchedules,
  asynchronousTasks,
  availableAccountingIntegrations,
  availableDataIntegrations,
  banks,
  bootstrap,
  carAllowances,
  coarseCarAllowances,
  coarseTimeRegistrations,
  coarseSalaryRegistrations,
  companies,
  companyAccountingIntegration,
  companyAccountPlans,
  companyDataIntegration,
  companyBankAccounts,
  companyDeviationConfigurations,
  companyFeatures,
  companyIntegrations,
  companyLogo,
  companyPaymentIntegrations,
  companyPricingPackages,
  companyPricings,
  companyUsers,
  companyGroups,
  contractBookContracts,
  contractBookDrafts,
  contractBookIntegrations,
  contractBookTemplates,
  contracts,
  contractTemplates,
  contractTemplateContracts,
  costCenters,
  dashboards,
  departments,
  departmentUsers,
  documents,
  documentCategories,
  employees,
  employeeContractDeltas,
  employeeDimensions,
  employeeEmergencyContacts,
  employeeInvites,
  employeeProfileImages,
  employmentPositions,
  employments,
  expenseCategories,
  externalSalaryFileSettings,
  globalMessage,
  incomeTaxReports,
  invoices,
  leaveAdjustments,
  leaveBalances,
  leaveTypes,
  modals,
  netsMessages,
  oneTimePays,
  oneTimePayPensions,
  outgoingLeads,
  paymentConfigurations,
  payRollDeviations,
  payRolls,
  paySlips,
  pensionCompanies,
  pricingPackages,
  recoveryCodes,
  reimbursementVouchers,
  salaryCycles,
  salaryRegistrations,
  salaryTypes,
  shipments,
  startBalances,
  stripeConfiguration,
  supplementAdjustments,
  supplementBalances,
  supplementTypes,
  supportAccess,
  swipes,
  taxCards,
  taxCardRequests,
  timeRegistrations,
  timeRegistrationTemplates,
  projects,
  transfers,
  user,
  userInvites,
  userInviteCompanyGroups,
  userCompanies,
  vacationCalendars,
  vouchers,
  warnings,
})
