import React, { ReactElement, useEffect, useState } from 'react'

import Contract from '../../../model/contract'
import Department, { DepartmentCreationFields } from '../../../model/department'
import Employee from '../../../model/employee'
import { DepartmentReducer } from '../../../reducers/departments'
import { EmployeeReducer } from '../../../reducers/employees'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { findDepartmentID } from '../../../utils/department-utils'
import { getValidFrom } from '../../../utils/employment-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import EmploymentDetailsEditForm, { EmploymentDetailFields } from './EmploymentDetailsEditForm'

type Props = {
  visible: boolean
  departments: DepartmentReducer
  employee: Employee
  employees: EmployeeReducer
  mutableContract: Contract
  isDepartmentManager: boolean

  updateEmployee: (employee: Employee) => void
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
}

export default function EmploymentDetailsEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, employees } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, employees.error, error, setError)
  }, [visible, employees, error])

  const handleSubmit = (values: EmploymentDetailFields) => {
    const employee = {
      ...props.employee,
      departmentID: findDepartmentID(props.departments.departments.toArray(), values.departmentID || undefined),
    }
    if (employee.departmentID) {
      const promise = new Promise((resolve: (id: string | null) => void, reject) => {
        if (props.departments.departments.findIndex((item) => item.id === employee.departmentID) === -1) {
          props
            .addDepartment({ name: employee.departmentID || '' })
            .then((data) => {
              if (!data) {
                return
              }
              resolve(data.id)
            })
            .catch(reject)
        } else {
          resolve(null)
        }
      })
      promise.then((departmentID: string | null) => {
        if (departmentID) {
          employee.departmentID = departmentID
        }
        props.updateEmployee(employee)
      })
    } else {
      employee.departmentID = undefined
      props.updateEmployee(employee)
    }
  }

  return (
    <Card>
      <Subtitle>{t('freelancer.details.edit.title')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <EmploymentDetailsEditForm
        validFrom={getValidFrom(props.employee, props.mutableContract).validFrom}
        employee={props.employee}
        employees={props.employees}
        departments={props.departments}
        isDepartmentManager={props.isDepartmentManager}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
