import React from 'react'

import {
  delCoarseSalaryRegistration,
  fetchCoarseSalaryRegistrations,
  postCoarseSalaryRegistration,
  postCoarseSalaryRegistrationBulk,
  putCoarseSalaryRegistration,
  putCoarseSalaryRegistrationBulk,
} from '../api/coarse-salary-registrations'
import ActionTypes from '../constants/action-types'
import CoarseSalaryRegistration, { CoarseSalaryRegistrationMutableFields } from '../model/coarseSalaryRegistration'
import { CoarseSalaryRegistrationAction } from '../reducers/coarseSalaryRegistrations'
import { isRequestError } from '../utils/error-utils'

function loadingCoarseSalaryRegistrations(
  companyID: string | undefined,
  salaryPeriodID: string | undefined,
  employeeID: string | undefined
): CoarseSalaryRegistrationAction {
  return {
    type: ActionTypes.COARSE_SALARY_REGISTRATION_LOADING,
    companyID,
    salaryPeriodID,
    employeeID,
  }
}
function loadedCoarseSalaryRegistrations(
  companyID: string | undefined,
  salaryPeriodID: string | undefined,
  employeeID: string | undefined,
  coarseSalaryRegistrations: CoarseSalaryRegistration[]
): CoarseSalaryRegistrationAction {
  return {
    type: ActionTypes.COARSE_SALARY_REGISTRATION_LOADED,
    companyID,
    salaryPeriodID,
    employeeID,
    coarseSalaryRegistrations,
  }
}
function failedLoadingCoarseSalaryRegistrations(
  companyID: string | undefined,
  salaryPeriodID: string | undefined,
  employeeID: string | undefined,
  error: Error
): CoarseSalaryRegistrationAction {
  return {
    type: ActionTypes.COARSE_SALARY_REGISTRATION_LOAD_FAILED,
    companyID,
    salaryPeriodID,
    employeeID,
    error,
  }
}

function creatingCoarseSalaryRegistration(employeeID: string): CoarseSalaryRegistrationAction {
  return {
    type: ActionTypes.COARSE_SALARY_REGISTRATION_CREATING,
    employeeID,
  }
}
export function createdCoarseSalaryRegistration(
  employeeID: string,
  coarseSalaryRegistration: CoarseSalaryRegistration
): CoarseSalaryRegistrationAction {
  return {
    type: ActionTypes.COARSE_SALARY_REGISTRATION_CREATED,
    employeeID,
    coarseSalaryRegistration,
  }
}
function failedCreatingCoarseSalaryRegistration(employeeID: string, error: Error): CoarseSalaryRegistrationAction {
  return {
    type: ActionTypes.COARSE_SALARY_REGISTRATION_CREATE_FAILED,
    error,
    employeeID,
  }
}

function updatingCoarseSalaryRegistration(
  employeeID: string,
  coarseSalaryRegistrationID?: string
): CoarseSalaryRegistrationAction {
  return {
    type: ActionTypes.COARSE_SALARY_REGISTRATION_UPDATING,
    employeeID,
    coarseSalaryRegistrationID,
  }
}
export function updatedCoarseSalaryRegistration(
  employeeID: string,
  coarseSalaryRegistrationID: string,
  coarseSalaryRegistration: CoarseSalaryRegistration
): CoarseSalaryRegistrationAction {
  return {
    type: ActionTypes.COARSE_SALARY_REGISTRATION_UPDATED,
    employeeID,
    coarseSalaryRegistrationID,
    coarseSalaryRegistration,
  }
}
function failedUpdatingCoarseSalaryRegistration(
  employeeID: string,
  coarseSalaryRegistrationID: string | undefined,
  error: Error
): CoarseSalaryRegistrationAction {
  return {
    type: ActionTypes.COARSE_SALARY_REGISTRATION_UPDATE_FAILED,
    error,
    employeeID,
    coarseSalaryRegistrationID,
  }
}

function deletingCoarseSalaryRegistration(coarseSalaryRegistrationID: string): CoarseSalaryRegistrationAction {
  return {
    type: ActionTypes.COARSE_SALARY_REGISTRATION_DELETING,
    coarseSalaryRegistrationID,
  }
}
export function deletedCoarseSalaryRegistration(coarseSalaryRegistrationID: string): CoarseSalaryRegistrationAction {
  return {
    type: ActionTypes.COARSE_SALARY_REGISTRATION_DELETED,
    coarseSalaryRegistrationID,
  }
}
function failedDeletingCoarseSalaryRegistration(
  coarseSalaryRegistrationID: string,
  error: Error
): CoarseSalaryRegistrationAction {
  return {
    type: ActionTypes.COARSE_SALARY_REGISTRATION_DELETE_FAILED,
    error,
    coarseSalaryRegistrationID,
  }
}

export function getCoarseSalaryRegistrations(companyID?: string, salaryPeriodID?: string, employeeID?: string) {
  return (dispatch: React.Dispatch<any>): Promise<CoarseSalaryRegistration[] | void> => {
    dispatch(loadingCoarseSalaryRegistrations(companyID, salaryPeriodID, employeeID))
    return fetchCoarseSalaryRegistrations(companyID, salaryPeriodID, employeeID)
      .then((res) => {
        dispatch(loadedCoarseSalaryRegistrations(companyID, salaryPeriodID, employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCoarseSalaryRegistrations(companyID, salaryPeriodID, employeeID, e))
        }
      })
  }
}

export function createCoarseSalaryRegistration(registration: CoarseSalaryRegistration) {
  return (dispatch: React.Dispatch<any>): Promise<CoarseSalaryRegistration | void> => {
    dispatch(creatingCoarseSalaryRegistration(registration.employeeID))
    return postCoarseSalaryRegistration(registration)
      .then((res) => {
        dispatch(createdCoarseSalaryRegistration(registration.employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingCoarseSalaryRegistration(registration.employeeID, e))
        }
      })
  }
}

export function createCoarseSalaryRegistrationBulk(
  employeeID: string,
  coarseSalaryRegistrations: CoarseSalaryRegistration[]
) {
  return (dispatch: React.Dispatch<any>): Promise<CoarseSalaryRegistration[] | void> => {
    dispatch(creatingCoarseSalaryRegistration(employeeID))
    return postCoarseSalaryRegistrationBulk(coarseSalaryRegistrations)
      .then((res) => {
        res.data.forEach((salaryRegistration) => {
          dispatch(createdCoarseSalaryRegistration(employeeID, salaryRegistration))
        })
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingCoarseSalaryRegistration(employeeID, e))
        }
      })
  }
}

export function updateCoarseSalaryRegistration(registration: CoarseSalaryRegistration) {
  return (dispatch: React.Dispatch<any>): Promise<CoarseSalaryRegistration | void> => {
    dispatch(updatingCoarseSalaryRegistration(registration.employeeID, registration.id))
    return putCoarseSalaryRegistration(registration.id, registration)
      .then((res) => {
        dispatch(updatedCoarseSalaryRegistration(registration.employeeID, registration.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCoarseSalaryRegistration(registration.employeeID, registration.id, e))
        }
      })
  }
}

export function updateCoarseSalaryRegistrationBulk(
  employeeID: string,
  coarseSalaryRegistrations: CoarseSalaryRegistrationMutableFields[]
) {
  return (dispatch: React.Dispatch<any>): Promise<CoarseSalaryRegistration[] | void> => {
    dispatch(updatingCoarseSalaryRegistration(employeeID, undefined))
    return putCoarseSalaryRegistrationBulk(coarseSalaryRegistrations)
      .then((res) => {
        res.data.forEach((salaryRegistration) => {
          dispatch(updatedCoarseSalaryRegistration(employeeID, salaryRegistration.id, salaryRegistration))
        })
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCoarseSalaryRegistration(employeeID, undefined, e))
        }
      })
  }
}

export function deleteCoarseSalaryRegistration(registration: CoarseSalaryRegistration) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(deletingCoarseSalaryRegistration(registration.id))
    return delCoarseSalaryRegistration(registration.id)
      .then(() => {
        dispatch(deletedCoarseSalaryRegistration(registration.id))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingCoarseSalaryRegistration(registration.id, e))
        }
      })
  }
}
