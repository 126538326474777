import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import ContractBookContract from '../model/contractBookContract'
import { ReducerAction } from '../utils/reducer-utils'

export interface ContractBookContractReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  contracts: List<ContractBookContract>
  error: Error | null
}

const Params = Record<ContractBookContractReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  contracts: List<ContractBookContract>(),
  error: null,
})

export interface ContractBookContractAction extends ReducerAction {
  companyID?: string
  contracts?: ContractBookContract[]
  contractID?: string
  contract?: ContractBookContract
}

const comparator = (a: ContractBookContract, b: ContractBookContract) => {
  return b.updatedAt.localeCompare(a.updatedAt, 'da-dk')
}

export default (
  state: Record<ContractBookContractReducer> = Params(),
  action: ContractBookContractAction = { type: '' }
): Record<ContractBookContractReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.CONTRACT_BOOK_CONTRACT_LOADING:
      return state.set('loading', true).set('loaded', false).set('companyID', state.get('companyID'))
    case ActionTypes.CONTRACT_BOOK_CONTRACT_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('companyID', state.get('companyID'))
        .set('contracts', List<ContractBookContract>(action.contracts).sort(comparator))
        .set('error', null)
    case ActionTypes.CONTRACT_BOOK_CONTRACT_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', state.get('companyID'))
        .set('error', action.error || null)

    case ActionTypes.CONTRACT_BOOK_CONTRACT_UPDATING:
      return state.set('saving', true)
    case ActionTypes.CONTRACT_BOOK_CONTRACT_UPDATED:
      return state.set('saving', false).set(
        'contracts',
        state.get('contracts').map((contract) => {
          if (contract.id !== action.contractID || !action.contract) {
            return contract
          }
          return action.contract
        })
      )
    case ActionTypes.CONTRACT_BOOK_CONTRACT_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
