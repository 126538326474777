import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyLogoReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  companyLogo: string | undefined
  error: Error | null
}

const Params = Record<CompanyLogoReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  companyLogo: undefined,
  error: null,
})

export interface CompanyLogoAction extends ReducerAction {
  companyID?: string
  companyLogo?: string | null
}

export default (
  state: Record<CompanyLogoReducer> = Params(),
  action: CompanyLogoAction = { type: '' }
): Record<CompanyLogoReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.COMPANY_LOGO_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.COMPANY_LOGO_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        companyLogo: action.companyLogo || undefined,
      })
    case ActionTypes.COMPANY_LOGO_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.COMPANY_LOGO_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_LOGO_UPDATED:
      if (!action.companyLogo) {
        return state
      }
      return state.set('saving', false).set('companyLogo', action.companyLogo)
    case ActionTypes.COMPANY_LOGO_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COMPANY_LOGO_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_LOGO_DELETED:
      return state.set('saving', false).set('companyLogo', undefined)
    case ActionTypes.COMPANY_LOGO_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
