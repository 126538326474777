import { addMonths, max, startOfMonth, subYears } from 'date-fns'
import { List } from 'immutable'
import React, { useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import CoarseCarAllowance, {
  CoarseCarAllowanceCreationFields,
  CoarseCarAllowanceMutableFields,
} from '../../../model/coarseCarAllowance'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import { CoarseCarAllowanceReducer } from '../../../reducers/coarseCarAllowances'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate, formatDate, getDate, isSameOrAfter, isSameOrBefore } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { formatSavingText } from '../../../utils/loading-utils'
import { getCurrentPeriodFromDispositionDate } from '../../../utils/salary-period-utils'
import { t, tx } from '../../../utils/translation-utils'
import Form from '../../antd/form'
import Select from '../../antd/select'
import Alert from '../../elements/alert'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import CoarseCarAllowanceForm, { CoarseCarAllowanceResult } from './CoarseCarAllowanceForm'

type Props = {
  preventPeriodChange: boolean
  employee: Employee
  coarseCarAllowances: CoarseCarAllowanceReducer
  salaryCycles: List<SalaryCycle>
  salaryCycle: SalaryCycle

  addAlert: addAlertSignature
  createCoarseCarAllowance: (carAllowance: CoarseCarAllowanceCreationFields) => void
  updateCoarseCarAllowance: (carAllowance: CoarseCarAllowanceMutableFields) => void
  deleteCoarseCarAllowance: (carAllowance: CoarseCarAllowance) => void
}

export default function CoarseCarAllowanceTab(props: Props) {
  const [salaryPeriodID, setSalaryPeriodID] = useState(
    () => getCurrentPeriodFromDispositionDate(props.salaryCycle.salaryPeriods)?.id
  )
  const [error, setError] = useState<Error | null>(null)

  const { coarseCarAllowances, addAlert, employee } = props
  const previousCoarseCarAllowances = usePrevious(coarseCarAllowances)

  useEffect(() => {
    // Check for save callback
    if (previousCoarseCarAllowances && previousCoarseCarAllowances.saving && !coarseCarAllowances.saving) {
      // Check for no error occurred
      if (!coarseCarAllowances.error) {
        addAlert('success', t('car_allowances_tab.coarse.alert.success', { name: employee.name }), { timeout: 5 })
      }
    }
  })

  useEffect(() => {
    regularComponentDidUpdate(coarseCarAllowances.error, error, setError)
  }, [coarseCarAllowances, error])

  const getSalaryPeriods = () => {
    const contract = props.employee.activeContract
    if (!contract) {
      return []
    }
    let useFirst = false
    const salaryCycles = props.salaryCycles.filter(
      (item) => item.id === contract.salaryCycleID && item.frequency === 'Monthly'
    )
    if (salaryCycles.size > 0) {
      useFirst = true
    }
    return props.salaryCycle.salaryPeriods
      .map((salaryPeriod) => ({
        ...salaryPeriod,
        first: formatAPIDate(useFirst ? startOfMonth(getDate(salaryPeriod.end)) : salaryPeriod.start),
      }))
      .filter((salaryPeriod) => {
        const date = max([getDate(salaryPeriod.dispositionDate), getDate(salaryPeriod.end)])
        return isSameOrAfter(date, subYears(getDate(), 1)) && isSameOrBefore(date, addMonths(getDate(), 1))
      })
      .sort((a, b) => b.start.localeCompare(a.start))
  }

  const getPeriod = () => props.salaryCycle.salaryPeriods.find((period) => period.id === salaryPeriodID)

  const getCoarseCarAllowance = () => {
    const period = getPeriod()
    if (!period) {
      return null
    }
    return props.coarseCarAllowances.coarseCarAllowances.find(
      (carAllowance) => carAllowance.employeeID === props.employee.id && carAllowance.salaryPeriodID === period.id
    )
  }

  const handleSubmit = (values: CoarseCarAllowanceResult) => {
    const period = getPeriod()
    if (!period) {
      return
    }
    const coarseCarAllowance = getCoarseCarAllowance()
    if (values.kilometers === 0) {
      if (coarseCarAllowance) {
        props.deleteCoarseCarAllowance(coarseCarAllowance)
      }
    } else {
      if (coarseCarAllowance) {
        props.updateCoarseCarAllowance({
          id: coarseCarAllowance.id,
          employeeID: props.employee.id,
          salaryPeriodID: period.id,
          kilometers: values.kilometers,
        })
      } else {
        props.createCoarseCarAllowance({
          employeeID: props.employee.id,
          salaryPeriodID: period.id,
          kilometers: values.kilometers,
        })
      }
    }
  }
  const getKilometers = () => {
    const coarseCarAllowance = getCoarseCarAllowance()
    if (coarseCarAllowance) {
      return coarseCarAllowance.kilometers
    }
    return 0
  }

  const period = getPeriod()
  if (!period) {
    return null // should not happen
  }
  return (
    <div>
      <p>
        {tx('car_allowances_tab.coarse.warning', {
          link: (
            <a href="http://skat.dk/skat.aspx?oid=2234872" target="_blank" rel="noopener noreferrer">
              {t('car_allowances_tab.coarse.warning.link')}
            </a>
          ),
        })}
      </p>

      {props.preventPeriodChange ? (
        <p>
          {t('car_allowances_tab.coarse.period.no_change', {
            from: formatDate(period.start),
            to: formatDate(period.end),
          })}
        </p>
      ) : (
        <Row>
          <Col span={12}>
            <Form.Item style={{ marginBottom: 10 }}>
              <label style={{ marginBottom: 0 }}>{t('car_allowances_tab.coarse.period.select_period')}</label>
              <Select
                value={salaryPeriodID}
                dropdownMatchSelectWidth={false}
                onChange={(e: string) => setSalaryPeriodID(e)}
              >
                {getSalaryPeriods().map((salaryPeriod) => {
                  return (
                    <Select.Option key={salaryPeriod.id} value={salaryPeriod.id}>
                      {t('car_allowances_tab.coarse.period.period_range', {
                        from: formatDate(salaryPeriod.start),
                        to: formatDate(salaryPeriod.end),
                      })}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <CoarseCarAllowanceForm key={salaryPeriodID} kilometers={getKilometers()} onSubmit={handleSubmit} />
      {props.coarseCarAllowances.saving && (
        <LoadingOverlay
          text={formatSavingText([
            { loading: props.coarseCarAllowances.saving, text: t('loading.reducer.coarse_car_allowances') },
          ])}
        />
      )}
    </div>
  )
}
