// finds the departmentID based on whether it is actually an ID or name, returns it as is if it cannot find it
import Department from '../model/department'

export function findDepartmentID(departments: Department[], departmentIDOrName?: string) {
  if (!departmentIDOrName) {
    return undefined
  }
  let department = departments.find((department) => department.id === departmentIDOrName)
  if (department) {
    return department.id
  }
  department = departments.find((department) => department.name.toLowerCase() === departmentIDOrName.toLowerCase())
  if (department) {
    return department.id
  }
  return departmentIDOrName
}
