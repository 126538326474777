import { subMonths } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import Employee from '../../model/employee'
import SalaryCycle from '../../model/salaryCycle'
import SalaryPeriod from '../../model/salaryPeriod'
import { formatDate, getDate, isSameOrAfter, isSameOrBefore } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatBiweeklySalaryCycle, formatSalaryCycle } from '../../utils/format-utils'
import { setByPath } from '../../utils/object-utils'
import { getCurrentPeriodFromDispositionDate } from '../../utils/salary-period-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import SearchEmployeeForm from '../search-employees/SearchEmployeeForm'

function getSalaryCyclesHelper(salaryCycles: List<SalaryCycle>, employees: List<Employee>) {
  return salaryCycles.filter((salaryCycle) =>
    employees.some((employee) => employee.activeContract?.salaryCycleID === salaryCycle.id)
  )
}

function getSalaryPeriodsHelper(salaryPeriods: SalaryPeriod[]) {
  return salaryPeriods
    .filter(
      (salaryPeriod) =>
        isSameOrAfter(getDate(salaryPeriod.start), subMonths(getDate(), 12)) &&
        isSameOrBefore(getDate(salaryPeriod.start), getDate())
    )
    .sort((a, b) => b.start.localeCompare(a.start))
}

type Props = {
  company: Company
  salaryCycles: List<SalaryCycle>
  employees: List<Employee>
}

type Fields = {
  type: 'Extra' | 'ZeroTaxReport'
  employeeIDs: string[]
  salaryCycleID?: string
  salaryPeriodID?: string
  onlyMutable: false
  createdBefore?: Date
  onlyDraft: boolean
}

export type PayRollsAddResult = {
  type: 'Extra' | 'ZeroTaxReport'
  employeeIDs: string[]
  salaryCycleID: string
  salaryPeriodID: string
}

function PayRollsAddForm(props: Props & FormComponentProps<Fields, PayRollsAddResult>): ReactElement | null {
  const handleSalaryCycleSelect = (id: string) => {
    const salaryCycle = props.salaryCycles.find((salaryCycle) => salaryCycle.id === id)
    if (salaryCycle) {
      if (salaryCycle.salaryPeriods) {
        const salaryPeriod = getCurrentPeriodFromDispositionDate(getSalaryPeriodsHelper(salaryCycle.salaryPeriods))
        if (salaryPeriod) {
          props.setFieldValue('salaryPeriodID', salaryPeriod.id)
        }
      }
      props.setFieldValue('salaryCycleID', id)
    }
  }

  const getSalaryCycles = () => {
    return getSalaryCyclesHelper(props.salaryCycles, props.employees)
  }

  const getSalaryPeriods = () => {
    const cycleID = props.getFieldValue('salaryCycleID')
    if (!cycleID || cycleID === '') {
      return null
    }
    const salaryCycle = props.salaryCycles.find((salaryCycle) => salaryCycle.id === cycleID)
    if (!salaryCycle || !salaryCycle.salaryPeriods) {
      return null
    }
    return getSalaryPeriodsHelper(salaryCycle.salaryPeriods)
  }

  const getEmployees = () => {
    if (!props.getFieldValue('salaryCycleID') || !props.getFieldValue('salaryPeriodID')) {
      return []
    }
    const cycleID = props.getFieldValue('salaryCycleID')
    return props.employees
      .filter((employee) => employee.activeContract && employee.activeContract.salaryCycleID === cycleID)
      .toArray()
  }

  const { decorateField, getFieldValue } = props

  const mode = getFieldValue('type')

  const salaryPeriods = getSalaryPeriods()

  return (
    <div>
      <Row style={{ float: 'right', width: '50%', marginTop: '-75px' }}>
        <Col span={12} style={{ float: 'right' }}>
          {decorateField('type', {
            placeholder: t('pay_rolls.add.form.type'),
          })(
            <Select dropdownMatchSelectWidth={false}>
              <Select.Option value={'Extra'}>{t('pay_rolls.add.form.type.extra')}</Select.Option>
              <Select.Option value={'ZeroTaxReport'}>{t('pay_rolls.add.form.type.zero_tax_report')}</Select.Option>
            </Select>
          )}
        </Col>
      </Row>
      <Row style={{ clear: 'both' }}>
        {mode === 'Extra' && (
          <Col span={12}>
            {decorateField('salaryCycleID', {
              placeholder: t('pay_rolls.add.form.salary_cycle_id'),
            })(
              <Select dropdownMatchSelectWidth={false} onChange={handleSalaryCycleSelect}>
                {getSalaryCycles().map((salaryCycle) => {
                  return (
                    <Select.Option key={salaryCycle.id} value={salaryCycle.id}>
                      {formatSalaryCycle(salaryCycle.frequency, salaryCycle.offset)}
                      {salaryCycle.frequency === 'BiWeekly' && ` (${formatBiweeklySalaryCycle(salaryCycle)})`}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Col>
        )}
        {getFieldValue('salaryCycleID') && salaryPeriods && (
          <Col span={12}>
            {decorateField('salaryPeriodID', {
              placeholder: t('pay_rolls.add.form.salary_period_id'),
            })(
              <Select dropdownMatchSelectWidth={false}>
                {salaryPeriods.map((salaryPeriod) => {
                  return (
                    <Select.Option key={salaryPeriod.id} value={salaryPeriod.id}>
                      {formatDate(salaryPeriod.start)} - {formatDate(salaryPeriod.end)}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Col>
        )}
      </Row>
      {mode === 'Extra' && getFieldValue('salaryCycleID') && getFieldValue('salaryPeriodID') && (
        <SearchEmployeeForm<Fields>
          company={props.company}
          employees={getEmployees()}
          getFieldValue={props.getFieldValue}
          setFieldValue={props.setFieldValue}
          decorateField={decorateField}
        />
      )}
      <Row>
        <Col span={24}>
          <Button
            style={{ marginTop: '10px' }}
            htmlType="submit"
            size="large"
            type="secondary"
            disabled={mode === 'Extra' && getFieldValue('employeeIDs').length === 0}
          >
            {t('pay_rolls.add.form.submit')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, PayRollsAddResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      type: 'Extra',
      employeeIDs: [],
      onlyMutable: false,
      onlyDraft: false,
    }

    const salaryCycle = getSalaryCyclesHelper(props.salaryCycles, props.employees).first()
    if (salaryCycle) {
      fields.salaryCycleID = salaryCycle.id
      if (salaryCycle.salaryPeriods) {
        const salaryPeriod = getCurrentPeriodFromDispositionDate(getSalaryPeriodsHelper(salaryCycle.salaryPeriods))
        if (salaryPeriod) {
          fields.salaryPeriodID = salaryPeriod.id
        }
      }
    }

    return fields
  },
  onChange: (key, val) => {
    const values = {}
    if (key === 'type' && val === 'ZeroTaxReport') {
      setByPath(values, 'salaryCycleID', '5f88455c-83f4-55df-46b2-c4c14ec06c91')
    }
    setByPath(values, key, val)
    return values
  },
  onSubmit: (values) => ({
    type: values.type,
    salaryCycleID: values.salaryCycleID!,
    salaryPeriodID: values.salaryPeriodID!,
    employeeIDs: values.employeeIDs,
  }),
})(PayRollsAddForm)
