import React, { ReactElement } from 'react'

import Company from '../../../model/company'
import { decorateFieldSignature } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'

type OneTimePayAccountingTextFields = {
  accountingText?: string
}

type Props<Fields extends OneTimePayAccountingTextFields> = {
  editing: boolean
  company: Company
  additionalRequirement?: boolean

  decorateField: decorateFieldSignature<Fields>
}

export default function OneTimePayAccountingText<Fields extends OneTimePayAccountingTextFields>(
  props: Props<Fields>
): ReactElement | null {
  if (!props.company.settingsEnabled.some((setting) => setting === 'OneTimePayAccountingTextEnabled')) {
    return null
  }
  // here we want undefined and true to evaluate to false, so that the component will be rendered
  // only if it is explicitly false, should it return null
  if (!(props.additionalRequirement ?? true)) {
    return null
  }
  return (
    <Row>
      <Col span={24}>
        {props.decorateField('accountingText', {
          placeholder: t('otp_form.accounting_text'),
        })(<Input disabled={!props.editing} />)}
      </Col>
    </Row>
  )
}
