import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../../model/company'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import { ContractReducer } from '../../../reducers/contracts'
import { formatDate, getDate, isTimeAfter } from '../../../utils/date-utils'
import { EmployeePayType } from '../../../utils/employee-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import ContractPayment from '../../form-elements/ContractPayment'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  mutableContract: Contract
  validFrom: Date
  employee: Employee
  contracts: ContractReducer
  company: Company
  salaryCycles: List<SalaryCycle>
}

export type EmploymentPayFields = {
  payType: EmployeePayType
  salaryCycleID: string
}

function EmploymentPayEditForm(
  props: Props & FormComponentProps<EmploymentPayFields, EmploymentPayFields>
): ReactElement | null {
  return (
    <div>
      {props.getFormError()}
      <ContractPayment<EmploymentPayFields>
        decorateField={props.decorateField}
        getFieldValue={props.getFieldValue}
        getAnyFieldValue={props.getAnyFieldValue}
        setFieldValue={props.setFieldValue}
        company={props.company}
        employee={props.employee}
        salaryCycles={props.salaryCycles}
      />
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div className="ant-form-warning">
            {isTimeAfter(props.validFrom, getDate())
              ? t('freelancer.pay.edit.valid_from.at_date', { date: formatDate(props.validFrom) })
              : t('freelancer.pay.edit.valid_from.now')}
          </div>
        </Col>
      </Row>
      {props.contracts.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, EmploymentPayFields, EmploymentPayFields>({
  mapPropsToFields: (props) => ({
    payType: 'Commissioned', // ignored, since it is a freelancer
    salaryCycleID: props.mutableContract.salaryCycleID,
  }),
  onSubmit: (values) => values,
})(EmploymentPayEditForm)
