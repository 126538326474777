import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Invoice from '../model/invoice'
import { ReducerAction } from '../utils/reducer-utils'

export interface InvoiceReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  payRollID: string | undefined
  loading: boolean
  loaded: boolean
  invoices: List<Invoice>
  error: Error | null
}

const Params = Record<InvoiceReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  payRollID: undefined,
  loading: false,
  loaded: false,
  invoices: List<Invoice>(),
  error: null,
})

export interface InvoiceAction extends ReducerAction {
  companyID?: string
  payRollID?: string
  invoices?: Invoice[]
  invoice?: Invoice
  invoiceID?: string
}

const comparator = (a: Invoice, b: Invoice) => {
  return b.invoiceDate.localeCompare(a.invoiceDate, 'da-dk')
}

export default (
  state: Record<InvoiceReducer> = Params(),
  action: InvoiceAction = { type: '' }
): Record<InvoiceReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  if (
    ![
      ActionTypes.INVOICES_LOADING,
      ActionTypes.INVOICES_LOADED,
      ActionTypes.INVOICES_LOADED_PARTIAL,
      ActionTypes.INVOICES_LOAD_FAILED,
    ].some((type) => type === action.type)
  ) {
    // only process actions for the active company or pay roll, when not loading
    if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
      return state
    }
    if (action.payRollID && state.get('payRollID') && action.payRollID !== state.get('payRollID')) {
      return state
    }
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.INVOICES_LOADING:
      return Params({
        companyID: action.companyID,
        payRollID: action.payRollID,
        loading: true,
      })
    case ActionTypes.INVOICES_LOADED:
    case ActionTypes.INVOICES_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID,
        payRollID: action.payRollID,
        loaded: true,
        loading: action.type === ActionTypes.INVOICES_LOADED_PARTIAL,
        invoices: state.get('invoices').concat(List(action.invoices)).sort(comparator),
      })
    case ActionTypes.INVOICES_LOAD_FAILED:
      return Params({
        companyID: action.companyID,
        payRollID: action.payRollID,
        error: action.error,
      })

    case ActionTypes.INVOICE_ADDED:
      if (!action.invoice) {
        return state
      }
      idx = state.get('invoices').findIndex((item) => !!action.invoice && item.id === action.invoice.id)
      if (idx !== -1) {
        return state.set('invoices', state.get('invoices').set(idx, action.invoice).sort(comparator))
      }
      return state.set('invoices', state.get('invoices').push(action.invoice).sort(comparator))
    case ActionTypes.INVOICE_UPDATED:
      if (!action.invoice) {
        return state
      }
      idx = state.get('invoices').findIndex((item) => item.id === action.invoiceID)
      if (idx === -1) {
        return state
      }
      return state.set('invoices', state.get('invoices').set(idx, action.invoice).sort(comparator))
    case ActionTypes.INVOICE_DELETED:
      idx = state.get('invoices').findIndex((item) => item.id === action.invoiceID)
      if (idx === -1) {
        return state
      }
      return state.set('invoices', state.get('invoices').delete(idx))

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
