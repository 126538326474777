import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CompanyDeviationConfiguration from '../model/companyDeviationConfiguration'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyDeviationConfigurationReducer {
  companyID: string | undefined
  loaded: boolean
  loading: boolean
  saving: boolean
  companyDeviationConfigurations: List<CompanyDeviationConfiguration>
  error: Error | null
}

const Params = Record<CompanyDeviationConfigurationReducer>({
  companyID: undefined,
  loaded: false,
  loading: false,
  saving: false,
  companyDeviationConfigurations: List<CompanyDeviationConfiguration>(),
  error: null,
})

export interface CompanyDeviationConfigurationAction extends ReducerAction {
  companyID?: string
  companyDeviationConfigurations?: CompanyDeviationConfiguration[]
  companyDeviationConfiguration?: CompanyDeviationConfiguration
  companyDeviationConfigurationID?: string
}

export default (
  state: Record<CompanyDeviationConfigurationReducer> = Params(),
  action: CompanyDeviationConfigurationAction = { type: '' }
): Record<CompanyDeviationConfigurationReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = action.companyDeviationConfigurationID
    ? state
        .get('companyDeviationConfigurations')
        .findIndex((item) => item.id === action.companyDeviationConfigurationID)
    : -1
  idx = action.companyDeviationConfiguration
    ? state
        .get('companyDeviationConfigurations')
        .findIndex(
          (item) => !!action.companyDeviationConfiguration && item.id === action.companyDeviationConfiguration.id
        )
    : idx

  switch (action.type) {
    case ActionTypes.COMPANY_DEVIATION_CONFIGURATION_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.COMPANY_DEVIATION_CONFIGURATION_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        companyDeviationConfigurations: List<CompanyDeviationConfiguration>(action.companyDeviationConfigurations),
      })
    case ActionTypes.COMPANY_DEVIATION_CONFIGURATION_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.COMPANY_DEVIATION_CONFIGURATION_ADDING:
    case ActionTypes.COMPANY_DEVIATION_CONFIGURATION_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_DEVIATION_CONFIGURATION_ADDED:
    case ActionTypes.COMPANY_DEVIATION_CONFIGURATION_UPDATED:
      if (!action.companyDeviationConfiguration) {
        return state
      }
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set(
            'companyDeviationConfigurations',
            state.get('companyDeviationConfigurations').set(idx, action.companyDeviationConfiguration)
          )
      }
      return state
        .set('saving', false)
        .set(
          'companyDeviationConfigurations',
          state.get('companyDeviationConfigurations').push(action.companyDeviationConfiguration)
        )

    case ActionTypes.COMPANY_DEVIATION_CONFIGURATION_ADD_FAILED:
    case ActionTypes.COMPANY_DEVIATION_CONFIGURATION_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COMPANY_DEVIATION_CONFIGURATION_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_DEVIATION_CONFIGURATION_DELETED:
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state
        .set('saving', false)
        .set('companyDeviationConfigurations', state.get('companyDeviationConfigurations').delete(idx))
    case ActionTypes.COMPANY_DEVIATION_CONFIGURATION_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
