import React from 'react'

import { delApiKey, fetchApiKeys, postApiKey } from '../api/api-keys'
import ActionTypes from '../constants/action-types'
import ApiKey, { ApiKeyCreationFields } from '../model/apiKey'
import { ApiKeyAction } from '../reducers/apiKeys'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingApiKeys(): ApiKeyAction {
  return {
    type: ActionTypes.API_KEYS_LOADING,
  }
}
export function loadedApiKeys(companyID: string, apiKeys: ApiKey[]): ApiKeyAction {
  return {
    type: ActionTypes.API_KEYS_LOADED,
    apiKeys,
    companyID,
  }
}
function failedLoadingApiKeys(companyID: string, error: Error): ApiKeyAction {
  return {
    type: ActionTypes.API_KEYS_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingApiKey(): ApiKeyAction {
  return {
    type: ActionTypes.API_KEY_ADDING,
  }
}
export function addedApiKey(companyID: string, apiKey: ApiKey): ApiKeyAction {
  return {
    type: ActionTypes.API_KEY_ADDED,
    companyID,
    apiKey,
  }
}
function failedAddingApiKey(companyID: string, error: Error): ApiKeyAction {
  return {
    type: ActionTypes.API_KEY_ADD_FAILED,
    error,
    companyID,
  }
}

function deletingApiKey(companyID: string, apiKeyID: string): ApiKeyAction {
  return {
    type: ActionTypes.API_KEY_DELETING,
    companyID,
    apiKeyID,
  }
}
export function deletedApiKey(companyID: string, apiKeyID: string): ApiKeyAction {
  return {
    type: ActionTypes.API_KEY_DELETED,
    companyID,
    apiKeyID,
  }
}
function failedDeletingApiKey(companyID: string, apiKeyID: string, error: Error): ApiKeyAction {
  return {
    type: ActionTypes.API_KEY_DELETE_FAILED,
    error,
    companyID,
    apiKeyID,
  }
}

export function addApiKey(apiKey: ApiKeyCreationFields) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ApiKey | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingApiKey())
    return postApiKey(companyID, apiKey)
      .then((res) => {
        dispatch(addedApiKey(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingApiKey(companyID, e))
        }
      })
  }
}

export function getApiKeys() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ApiKey[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingApiKeys())
    return fetchApiKeys(companyID)
      .then((res) => {
        dispatch(loadedApiKeys(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingApiKeys(companyID, e))
        }
      })
  }
}

export function deleteApiKey(apiKeyID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingApiKey(companyID, apiKeyID))
    return delApiKey(apiKeyID)
      .then(() => {
        dispatch(deletedApiKey(companyID, apiKeyID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingApiKey(companyID, apiKeyID, e))
        }
      })
  }
}
