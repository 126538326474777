import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Bank from '../model/bank'
import { ReducerAction } from '../utils/reducer-utils'

export interface BankReducer {
  loading: boolean
  loaded: boolean
  banks: List<Bank>
  error: Error | null
}
const Params = Record<BankReducer>({
  loading: false,
  loaded: false,
  banks: List<Bank>(),
  error: null,
})

export interface BankAction extends ReducerAction {
  banks?: Bank[]
}

export default (state: Record<BankReducer> = Params(), action: BankAction = { type: '' }): Record<BankReducer> => {
  switch (action.type) {
    case ActionTypes.BANKS_LOADING:
      return Params({
        loading: true,
      })
    case ActionTypes.BANKS_LOADED:
      return Params({
        loaded: true,
        banks: List<Bank>(action.banks),
      })
    case ActionTypes.BANKS_LOAD_FAILED:
      return Params({
        error: action.error,
      })
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
