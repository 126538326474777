import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import StripeConfiguration from '../model/stripeConfiguration'
import { ReducerAction } from '../utils/reducer-utils'

export interface StripeConfigurationReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  cardBrand: string | undefined
  country: string | undefined
  last4: string | undefined
  expireMonth: number | undefined
  expireYear: number | undefined
  error: Error | null
}

const Params = Record<StripeConfigurationReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  cardBrand: undefined,
  country: undefined,
  last4: undefined,
  expireMonth: undefined,
  expireYear: undefined,
  error: null,
})

export interface StripeConfigurationAction extends ReducerAction {
  companyID?: string
  configuration?: StripeConfiguration
}

export default (
  state: Record<StripeConfigurationReducer> = Params(),
  action: StripeConfigurationAction = { type: '' }
): Record<StripeConfigurationReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.STRIPE_CONFIGURATION_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.STRIPE_CONFIGURATION_LOADED:
      if (!action.configuration) {
        // if undefined, assume it simply does not exist
        return Params({
          companyID: state.get('companyID'),
          loaded: true,
        })
      }
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        cardBrand: action.configuration.cardBrand,
        country: action.configuration.country,
        last4: action.configuration.last4,
        expireMonth: action.configuration.expireMonth,
        expireYear: action.configuration.expireYear,
      })
    case ActionTypes.STRIPE_CONFIGURATION_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.STRIPE_CONFIGURATION_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.STRIPE_CONFIGURATION_ADDED:
      return state.set('saving', false)
    case ActionTypes.STRIPE_CONFIGURATION_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.STRIPE_CONFIGURATION_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.STRIPE_CONFIGURATION_UPDATED:
      if (!action.configuration) {
        return state
      }
      return state
        .set('saving', false)
        .set('cardBrand', action.configuration.cardBrand)
        .set('country', action.configuration.country)
        .set('last4', action.configuration.last4)
        .set('expireMonth', action.configuration.expireMonth)
        .set('expireYear', action.configuration.expireYear)
    case ActionTypes.STRIPE_CONFIGURATION_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
