import CarAllowance, { CarAllowanceMutableFields } from '../model/carAllowance'
import { DateFormat } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCarAllowances(
  companyID: string | undefined,
  employeeID: string | undefined,
  fromDate: DateFormat,
  toDate: DateFormat
): Promise<RequestResponse<CarAllowance[]>> {
  return secureRequest('GET', url('/v2/carAllowances', { companyID, employeeID, from: fromDate, to: toDate }))
}

export function postCarAllowances(
  employeeID: string,
  carAllowances: CarAllowanceMutableFields[]
): Promise<RequestResponse<CarAllowance[]>> {
  return secureRequest('POST', url('/v2.2/carAllowances'), {
    body: carAllowances,
  })
}

export function putCarAllowance(carAllowance: CarAllowanceMutableFields): Promise<RequestResponse<CarAllowance>> {
  return secureRequest('PUT', url('/v2/carAllowances/' + carAllowance.id), {
    body: {
      date: carAllowance.date,
      kilometers: carAllowance.kilometers,
      licensePlate: carAllowance.licensePlate,
      reason: carAllowance.reason,
      locationFrom: carAllowance.locationFrom,
      locationTo: carAllowance.locationTo,
      avoidFerries: carAllowance.avoidFerries,
      approved: carAllowance.approved,
      employeeID: carAllowance.employeeID,
      costCenterID: carAllowance.costCenterID,
      rate: carAllowance.rate,
    },
  })
}

export function patchCarAllowances(
  action: 'Approve' | 'Unapprove',
  carAllowanceIDs: string[]
): Promise<RequestResponse<CarAllowance[]>> {
  return secureRequest('PATCH', url('/v2/carAllowances'), {
    body: {
      action,
      carAllowanceIDs,
    },
  })
}

export function delCarAllowance(carAllowanceID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/carAllowances/' + carAllowanceID))
}

export type CarAllowanceRate = {
  validFrom: DateFormat
  validTo: DateFormat
  rates: {
    step: number
    rate: number
  }[]
}

export function fetchCarAllowanceRates(): Promise<RequestResponse<CarAllowanceRate[]>> {
  return secureRequest('GET', url('/v2/carAllowances/rates'))
}
