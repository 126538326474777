import ContractBookContract from '../model/contractBookContract'
import ContractBookIntegration, {
  ContractBookIntegrationCreationFields,
  ContractBookIntegrationMutableFields,
} from '../model/contractBookIntegration'
import ContractBookTemplate from '../model/contractBookTemplate'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchContractBookIntegrations(companyID: string): Promise<RequestResponse<ContractBookIntegration[]>> {
  return secureRequest('GET', url('/v2/companies/' + companyID + '/contractBook'))
}

export function postContractBookIntegration(
  companyID: string,
  integration: ContractBookIntegrationCreationFields
): Promise<RequestResponse<ContractBookIntegration>> {
  return secureRequest('POST', url('/v2/companies/' + companyID + '/contractBook'), {
    body: {
      contractBookAPIKey: integration.contractBookAPIKey,
      contractBookCallbackToken: integration.contractBookCallbackToken,
    },
  })
}

export function putContractBookIntegration(
  companyID: string,
  contractBookID: string,
  integration: ContractBookIntegrationMutableFields
): Promise<RequestResponse<ContractBookIntegration>> {
  return secureRequest('PUT', url('/v2/companies/' + companyID + '/contractBook/' + contractBookID), {
    body: { contractBookAPIKey: integration.contractBookAPIKey },
  })
}

export function delContractBookIntegration(companyID: string, contractBookID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/companies/' + companyID + '/contractBook/' + contractBookID))
}

export function fetchContractBookTemplates(companyID: string): Promise<RequestResponse<ContractBookTemplate[]>> {
  return secureRequest('GET', url('/v2/companies/' + companyID + '/contractBook/templates'))
}

export type ContractBookDraftCreated = {
  id: string
  editUrl: string
}

export function postContractBookDraft(
  companyID: string,
  contractBookID: string,
  templateID: string
): Promise<RequestResponse<ContractBookDraftCreated>> {
  return secureRequest('POST', url('/v2/companies/' + companyID + '/contractBook/' + contractBookID + '/drafts'), {
    body: { templateID },
  })
}

export function fetchContractBookContracts(companyID: string): Promise<RequestResponse<ContractBookContract[]>> {
  return secureRequest('GET', url('/v2/companies/' + companyID + '/contractBookContracts'))
}

export function patchContractBookContract(
  companyID: string,
  contractID: string,
  active: boolean
): Promise<RequestResponse<ContractBookContract>> {
  return secureRequest('PATCH', url('/v2/companies/' + companyID + '/contractBookContracts/' + contractID), {
    body: {
      active,
    },
  })
}
