import { VacationDay, VacationNamedDay } from '../model/vacationCalendar'
import { buildDate, formatDate, getDate } from './date-utils'
import { formatNameVacationDay } from './format-utils'
import { t } from './translation-utils'

export const VacationNamedDays: VacationNamedDay[] = [
  'Palm Sunday',
  'Maundy Thursday',
  'Good Friday',
  'Easter Sunday',
  'Easter Monday',
  'Great Prayer Day',
  'Christ Ascension',
  'The Day After Christ Ascension',
  'First Day Of Pentecost',
  'Whit Monday',
]

export function formatVacationDay(date: VacationDay): string {
  if (date.namedDay) {
    return formatNameVacationDay(date.namedDay)
  }
  if (!date.day) {
    return 'Ukendt'
  }
  switch (date.day) {
    case '12-24':
      return 'Juleaftensdag'
    case '12-25':
      return '1. første juledag'
    case '12-26':
      return '2. anden juledag'
    case '12-31':
      return 'Nytårsaftensdag'
    case '01-01':
      return 'Nytårsdag'
    default: {
      const d = date.day.split('-')
      return formatDate(buildDate(getDate().getFullYear(), parseInt(d[0]) - 1, parseInt(d[1])), t('date.day_month'))
    }
  }
}
