import React, { ReactElement, useEffect, useState } from 'react'
import { useEffectOnce, usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import { OutgoingLeadType } from '../../model/outgoingLead'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyIntegrationReducer } from '../../reducers/companyIntegrations'
import { OutgoingLeadReducer } from '../../reducers/outgoingLeads'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'
import Alerts from '../widgets/Alerts'
import LoadingOverlay from '../widgets/LoadingOverlay'

import './BasicIntegrations.css'

type Props = {
  alerts: AlertReducer
  company: Company
  companyIntegrations: CompanyIntegrationReducer
  outgoingLeads: OutgoingLeadReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getCompanyIntegrations: () => void
  deleteCompanyIntegration: (id: string) => void
  getOutgoingLeads: () => void
  createOutgoingLead: (leadType: OutgoingLeadType) => void
}

export default function Codan(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { companyIntegrations, getCompanyIntegrations, outgoingLeads, getOutgoingLeads } = props

  useEffectOnce(() => {
    if (!companyIntegrations.loading && !companyIntegrations.loaded) {
      getCompanyIntegrations()
    }
    if (!outgoingLeads.loading && !outgoingLeads.loaded) {
      getOutgoingLeads()
    }
  })

  const { addAlert, company } = props
  const previousCompanyIntegrations = usePrevious(companyIntegrations)

  useEffect(() => {
    if (previousCompanyIntegrations && previousCompanyIntegrations.saving && !companyIntegrations.saving) {
      // Check for no error occurred
      if (!companyIntegrations.error) {
        addAlert('success', t('basic_integrations.codan.alert.success', { name: company.name }), { timeout: 5 })
      }
    }

    regularComponentDidUpdate(companyIntegrations.error, error, setError)
  }, [previousCompanyIntegrations, companyIntegrations, addAlert, company, error])

  const hasIntegration = () => {
    return companyIntegrations.companyIntegrations.some((integration) => integration.type === 'Codan')
  }
  const hasPendingIntegration = () => {
    return outgoingLeads.outgoingLeads.some(
      (outgoingLead) =>
        outgoingLead.type === 'DK Codan New Customer' &&
        (outgoingLead.status === 'Pending' || outgoingLead.status === 'WaitingReply')
    )
  }

  const startIntegration = (e: React.MouseEvent) => {
    e.preventDefault()

    props.createOutgoingLead('DK Codan New Customer')
  }
  const stopIntegration = (e: React.MouseEvent) => {
    e.preventDefault()

    const integration = companyIntegrations.companyIntegrations.find((integration) => integration.type === 'Codan')
    if (integration) {
      props.deleteCompanyIntegration(integration.id)
    }
  }

  if (!props.companyIntegrations.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <div>
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <Card>
        {error && <Alert message={formatError(error)} type="error" showIcon />}
        <img
          src="/images/codan-logo-614x120.png"
          alt="Codan"
          style={{ float: 'right', width: 250, marginTop: 50, marginRight: 30, marginBottom: 50 }}
        />
        <Row>
          <Col span={16}>
            {hasIntegration() && (
              <>
                <p>{t('basic_integrations.codan.has_integration.line_1')}</p>
                <p>{t('basic_integrations.codan.has_integration.line_2')}</p>
              </>
            )}
            {!hasIntegration() && hasPendingIntegration() && (
              <p>{t('basic_integrations.codan.pending_integration.line_1')}</p>
            )}
            {!hasIntegration() && !hasPendingIntegration() && (
              <>
                <p>{t('basic_integrations.codan.no_integration.line_1')}</p>
                <p>{t('basic_integrations.codan.no_integration.line_2')}</p>
                <p>{t('basic_integrations.codan.no_integration.line_3')}</p>
              </>
            )}
            <p>
              <a href="https://salary.dk/forebyg-indbrud-og-skader/" target="_blank" rel="noopener noreferrer">
                {t('basic_integrations.codan.advise_link')}
              </a>
            </p>
            <p>
              <a
                href="https://www.codan.dk/om-codan/persondatapolitik/Codan.tsx#:~:text=Codan%20respekterer%20retten%20til%20dit%20privatliv%20og%20din,overensstemmelse%20med%20den%20til%20enhver%20tid%20g%C3%A6ldende%20lovgivning."
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('basic_integrations.codan.privacy_policy_link')}
              </a>
            </p>
          </Col>
        </Row>
        {hasIntegration() && (
          <Row>
            <Col span={24}>
              <Subtitle>{t('basic_integrations.codan.stop_integration.title')}</Subtitle>
              <br />
              <Button onClick={stopIntegration} size="large" type="secondary">
                {t('basic_integrations.codan.stop_integration.button')}
              </Button>
            </Col>
          </Row>
        )}
        {!hasIntegration() && !hasPendingIntegration() && (
          <Row>
            <Col span={24}>
              <Button onClick={startIntegration} size="large" type="secondary">
                {t('basic_integrations.codan.start_integration')}
              </Button>
            </Col>
          </Row>
        )}
        {(props.companyIntegrations.saving || props.outgoingLeads.saving) && <LoadingOverlay />}
      </Card>
    </div>
  )
}
