import React from 'react'

import { fetchCoarseTimeRegistrations, putCoarseTimeRegistrationBulk } from '../api/coarse-time-registrations'
import ActionTypes from '../constants/action-types'
import CoarseTimeRegistration, { CoarseTimeRegistrationMutableFields } from '../model/coarseTimeRegistration'
import { CoarseTimeRegistrationAction } from '../reducers/coarseTimeRegistrations'
import { isRequestError } from '../utils/error-utils'

function loadingCoarseTimeRegistrations(
  companyID?: string,
  salaryPeriodID?: string,
  employeeID?: string
): CoarseTimeRegistrationAction {
  return {
    type: ActionTypes.COARSE_TIME_REGISTRATION_LOADING,
    companyID,
    salaryPeriodID,
    employeeID,
  }
}
function loadedCoarseTimeRegistrations(
  companyID: string | undefined,
  salaryPeriodID: string | undefined,
  employeeID: string | undefined,
  coarseTimeRegistrations: CoarseTimeRegistration[]
): CoarseTimeRegistrationAction {
  return {
    type: ActionTypes.COARSE_TIME_REGISTRATION_LOADED,
    companyID,
    salaryPeriodID,
    employeeID,
    coarseTimeRegistrations,
  }
}
function failedLoadingCoarseTimeRegistrations(
  companyID: string | undefined,
  salaryPeriodID: string | undefined,
  employeeID: string | undefined,
  error: Error
): CoarseTimeRegistrationAction {
  return {
    type: ActionTypes.COARSE_TIME_REGISTRATION_LOAD_FAILED,
    companyID,
    salaryPeriodID,
    employeeID,
    error,
  }
}

export function createdCoarseTimeRegistration(
  employeeID: string,
  coarseTimeRegistration: CoarseTimeRegistration
): CoarseTimeRegistrationAction {
  return {
    type: ActionTypes.COARSE_TIME_REGISTRATION_CREATED,
    employeeID,
    coarseTimeRegistration,
  }
}

function updatingCoarseTimeRegistration(
  employeeID: string,
  coarseTimeRegistrationID?: string
): CoarseTimeRegistrationAction {
  return {
    type: ActionTypes.COARSE_TIME_REGISTRATION_UPDATING,
    employeeID,
    coarseTimeRegistrationID,
  }
}
export function updatedCoarseTimeRegistration(
  employeeID: string,
  coarseTimeRegistrationID: string,
  coarseTimeRegistration: CoarseTimeRegistration
): CoarseTimeRegistrationAction {
  return {
    type: ActionTypes.COARSE_TIME_REGISTRATION_UPDATED,
    employeeID,
    coarseTimeRegistrationID,
    coarseTimeRegistration,
  }
}
function failedUpdatingCoarseTimeRegistration(
  employeeID: string,
  coarseTimeRegistrationID: string | undefined,
  error: Error
): CoarseTimeRegistrationAction {
  return {
    type: ActionTypes.COARSE_TIME_REGISTRATION_UPDATE_FAILED,
    error,
    employeeID,
    coarseTimeRegistrationID,
  }
}

export function deletedCoarseTimeRegistration(coarseTimeRegistrationID: string): CoarseTimeRegistrationAction {
  return {
    type: ActionTypes.COARSE_TIME_REGISTRATION_DELETED,
    coarseTimeRegistrationID,
  }
}

export function getCoarseTimeRegistrations(companyID?: string, salaryPeriodID?: string, employeeID?: string) {
  return (dispatch: React.Dispatch<any>): Promise<CoarseTimeRegistration[] | void> => {
    dispatch(loadingCoarseTimeRegistrations(companyID, salaryPeriodID, employeeID))
    return fetchCoarseTimeRegistrations(companyID, salaryPeriodID, employeeID)
      .then((res) => {
        dispatch(loadedCoarseTimeRegistrations(companyID, salaryPeriodID, employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCoarseTimeRegistrations(companyID, salaryPeriodID, employeeID, e))
        }
      })
  }
}

export function updateCoarseTimeRegistrationBulk(
  employeeID: string,
  registrations: CoarseTimeRegistrationMutableFields[]
) {
  return (dispatch: React.Dispatch<any>): Promise<CoarseTimeRegistration[] | void> => {
    dispatch(updatingCoarseTimeRegistration(employeeID, undefined))
    return putCoarseTimeRegistrationBulk(registrations)
      .then((res) => {
        res.data.forEach((timeRegistration) => {
          dispatch(updatedCoarseTimeRegistration(employeeID, timeRegistration.id, timeRegistration))
        })
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCoarseTimeRegistration(employeeID, undefined, e))
        }
      })
  }
}
