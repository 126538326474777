import React, { CSSProperties, ReactElement } from 'react'

import './Elements.css'

// NEED_NO_TRANSLATION

export default function Title(props: {
  className?: string
  style?: CSSProperties
  children?: React.ReactNode
}): ReactElement {
  return (
    <h1 className={'h1' + (props.className ? ' ' + props.className : '')} style={props.style}>
      {props.children}
    </h1>
  )
}
