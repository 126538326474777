import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getAssetCategories } from '../actions/asset-categories'
import { getAssets } from '../actions/assets'
import { getAsynchronousTasks } from '../actions/asynchronous-tasks'
import { getSupplementTypes } from '../actions/companies'
import { getCompanyFeatures } from '../actions/company-features'
import { getDepartments } from '../actions/departments'
import { getEmploymentPositions } from '../actions/employment-positions'
import { getPensionCompanies } from '../actions/pension-companies'
import { getSalaryCycles } from '../actions/salary-cycles'
import EmployeesBatchComponent from '../components/employees-batch/EmployeesBatch'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { AlertReducer } from '../reducers/alerts'
import { AssetCategoryReducer } from '../reducers/assetCategories'
import { AssetReducer } from '../reducers/assets'
import { AsynchronousTaskReducer } from '../reducers/asynchronousTasks'
import { CompanyReducer } from '../reducers/companies'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employees'
import { EmploymentPositionReducer } from '../reducers/employmentPositions'
import { PensionCompanyReducer } from '../reducers/pensionCompanies'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { SupplementTypeReducer } from '../reducers/supplementTypes'
import { UserReducer } from '../reducers/user'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  alerts: AlertReducer
  assets: AssetReducer
  assetCategories: AssetCategoryReducer
  asynchronousTasks: AsynchronousTaskReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  user: UserReducer
  companyFeatures: CompanyFeatureReducer
  employees: EmployeeReducer
  supplementTypes: SupplementTypeReducer
  pensionCompanies: PensionCompanyReducer
  employmentPositions: EmploymentPositionReducer
  departments: DepartmentReducer
  salaryCycles: SalaryCycleReducer
  salaryTypes: SalaryTypeReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getDepartments: () => void
  getSalaryCycles: () => void
  getSupplementTypes: () => void
  getPensionCompanies: () => void
  getEmploymentPositions: () => void
  getCompanyFeatures: () => void
  getAssets: () => void
  getAssetCategories: () => void
  getAsynchronousTasks: (companyID: string) => void
}

function EmployeesBatch(props: Reducers & Actions): ReactElement | null {
  const {
    companies,
    departments,
    getDepartments,
    salaryCycles,
    getSalaryCycles,
    supplementTypes,
    getSupplementTypes,
    pensionCompanies,
    getPensionCompanies,
    employmentPositions,
    getEmploymentPositions,
    companyFeatures,
    getCompanyFeatures,
    assets,
    getAssets,
    assetCategories,
    getAssetCategories,
    asynchronousTasks,
    getAsynchronousTasks,
  } = props
  useEffect(() => {
    if (!departments.loaded && !departments.loading) {
      getDepartments()
    }
    if (!salaryCycles.loaded && !salaryCycles.loading) {
      getSalaryCycles()
    }
    if (!supplementTypes.loaded && !supplementTypes.loading) {
      getSupplementTypes()
    }
    if (!pensionCompanies.loaded && !pensionCompanies.loading) {
      getPensionCompanies()
    }
    if (!employmentPositions.loaded && !employmentPositions.loading) {
      getEmploymentPositions()
    }
    if (!companyFeatures.loaded && !companyFeatures.loading) {
      getCompanyFeatures()
    }
    if (!assets.loaded && !assets.loading) {
      getAssets()
    }
    if (!assetCategories.loaded && !assetCategories.loading) {
      getAssetCategories()
    }
    if (!asynchronousTasks.loaded && !asynchronousTasks.loading && companies.company) {
      getAsynchronousTasks(companies.company.id)
    }
  })

  const loading = !props.departments.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  if (!props.companies.company) {
    return null
  }

  return (
    <EmployeesBatchComponent
      alerts={props.alerts}
      assets={props.assets.assets}
      assetCategories={props.assetCategories.assetCategories}
      asynchronousTasks={props.asynchronousTasks.asynchronousTasks}
      company={props.companies.company}
      companyUsers={props.companyUsers}
      user={props.user}
      companyFeatures={props.companyFeatures}
      employees={props.employees}
      pensionCompanies={props.pensionCompanies}
      employmentPositions={props.employmentPositions}
      supplementTypes={props.supplementTypes}
      departments={props.departments}
      salaryCycles={props.salaryCycles}
      salaryTypes={props.salaryTypes}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    alerts: state.alerts,
    assets: state.assets,
    assetCategories: state.assetCategories,
    asynchronousTasks: state.asynchronousTasks,
    companies: state.companies,
    companyUsers: state.companyUsers,
    user: state.user,
    companyFeatures: state.companyFeatures,
    employees: state.employees,
    supplementTypes: state.supplementTypes,
    pensionCompanies: state.pensionCompanies,
    employmentPositions: state.employmentPositions,
    departments: state.departments,
    salaryCycles: state.salaryCycles,
    salaryTypes: state.salaryTypes,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getDepartments: getDepartments,
    getSalaryCycles: getSalaryCycles,
    getSupplementTypes: getSupplementTypes,
    getPensionCompanies: getPensionCompanies,
    getEmploymentPositions: getEmploymentPositions,
    getCompanyFeatures: getCompanyFeatures,
    getAssets: getAssets,
    getAssetCategories: getAssetCategories,
    getAsynchronousTasks: getAsynchronousTasks,
  }
)(EmployeesBatch)
