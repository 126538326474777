import React, { CSSProperties, ReactElement, ReactNode } from 'react'

import './DumbLink.css'

// NEED_NO_TRANSLATION

type Props = {
  onClick: (e: React.MouseEvent) => void
  className?: string
  style?: CSSProperties
  title?: string
  type?: 'standard'
  children: ReactNode
}

/**
 * A widget for when you need something to appear like a link, but it does not behave like one;
 * specifically that it does not have the to-prop.
 * @param props
 * @constructor
 */
export default function DumbLink(props: Props): ReactElement {
  let className = 'dumb-link'
  if (props.className) {
    className = className + ' ' + props.className
  }
  switch (props.type) {
    case 'standard':
      className = className + ' standard-link'
  }
  return (
    <a href={'#!'} style={props.style} onClick={props.onClick} className={className} title={props.title}>
      {props.children}
    </a>
  )
}
