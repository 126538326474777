import React, { ReactElement } from 'react'

import ActionTypes from '../constants/action-types'
import Alert, { AlertOptions, AlertType } from '../model/alert'
import { AlertAction } from '../reducers/alerts'
import { getStateSignature } from '../utils/reducer-utils'

export function addedAlert(alert: Alert): AlertAction {
  return {
    type: ActionTypes.ALERT_ADDED,
    alert,
  }
}
export function removedAlert(alertID: string): AlertAction {
  return {
    type: ActionTypes.ALERT_REMOVED,
    alertID,
  }
}

export type addAlertSignature = (type: AlertType, message: string | ReactElement, options?: AlertOptions) => void

export function addAlert(type: AlertType, message: string | ReactElement, options?: AlertOptions) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): void => {
    type = type.toLowerCase() as AlertType
    if (['success', 'warning', 'error'].indexOf(type) === -1) {
      type = 'success'
    }
    if (getState) {
      // prevent an alert from appearing more than once
      if (getState().alerts.alerts.some((alert: Alert) => alert.type === type && alert.message === message)) {
        return
      }
    }
    options = options || {}
    options.timeout = options.timeout && !isNaN(options.timeout) ? options.timeout : 0
    options.closable = !('closable' in options && options.closable === false)
    dispatch(
      addedAlert({
        id: '', // it will be set by the reducer
        type,
        message,
        options,
      })
    )
  }
}

export type removeAlertSignature = (id: string) => void

export function removeAlert(id: string) {
  return (dispatch: React.Dispatch<any>): void => {
    dispatch(removedAlert(id))
  }
}
