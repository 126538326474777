import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../model/company'
import { UserPermission } from '../../model/companyUser'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import EditCompanyUserForm, { EditCompanyUserResult } from './EditCompanyUserForm'

type Props = {
  visible: boolean
  companyUserID?: string
  company: Company
  companyUsers: CompanyUserReducer

  closeModal: () => void
  getCompanyUsers: () => void
  grantCompanyUserPermission: (companyGroupID: string, permission: UserPermission) => void
  revokeCompanyUserPermission: (companyGroupID: string, permissionID: string) => void
}

export default function EditCompanyUser(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, companyUsers } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, companyUsers.error, error, setError)
  }, [visible, companyUsers, error])

  const getCompanyUser = () => props.companyUsers.companyUsers.find((v) => v.id === props.companyUserID)

  const handleSubmit = (values: EditCompanyUserResult) => {
    const companyUserID = props.companyUserID
    if (!companyUserID) {
      return
    }
    const perms: UserPermission[] = []
    if (values.permissionAdmin) {
      perms.push('Admin')
    } else if (values.permissionApprovePayRoll) {
      perms.push('ApprovePayRoll')
      perms.push('Manager')
    } else {
      perms.push('Manager')
    }
    if (values.permissionReviewPayRoll) {
      perms.push('ReviewPayRoll')
    }
    const companyUser = getCompanyUser()
    if (companyUser && companyUser.permissions) {
      companyUser.permissions.forEach((permission) => {
        let shouldHavePermission = false
        perms.forEach((perm) => {
          if (perm && permission.permission === perm) {
            shouldHavePermission = true
          }
        })
        if (!shouldHavePermission) {
          if (permission.permission === 'Admin') {
            // if we remove admin permission, we should not create the manager permission
            const idx = perms.indexOf('Manager')
            if (idx) {
              perms.splice(idx, 1)
            }
          }
          props.revokeCompanyUserPermission(companyUserID, permission.id)
        }
      })
    }
    perms.forEach((perm) => {
      let hasPermission = false
      if (companyUser && companyUser.permissions) {
        companyUser.permissions.forEach((permission) => {
          if (perm && permission.permission === perm) {
            hasPermission = true
          }
        })
      }
      if (perm && !hasPermission) {
        props.grantCompanyUserPermission(companyUserID, perm)
      }
    })
    props.getCompanyUsers() // reload
    props.closeModal()
  }

  const companyUser = getCompanyUser()
  return (
    <Card className="company-user-permissions">
      <Subtitle>{t('company_users.edit.title', { name: companyUser?.name })}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <EditCompanyUserForm
        company={props.company}
        companyUser={companyUser}
        companyUsers={props.companyUsers}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
