import { List } from 'immutable'
import React, { ReactElement } from 'react'

import AsynchronousTask, { AsynchronousTaskType } from '../../model/asynchronousTask'
import { formatDate, formatDateTime } from '../../utils/date-utils'
import { formatAsynchronousTaskStatus } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'

type Props = {
  asynchronousTasks: List<AsynchronousTask>
  asynchronousTaskType: AsynchronousTaskType
  asynchronousTask?: AsynchronousTask
  onChange: (asynchronousTaskID: string) => void
  filter?: (task: AsynchronousTask) => boolean
}

export default function AsynchronousTaskSelector(props: Props): ReactElement | null {
  const onChange = (value: string) => {
    props.onChange(value)
  }

  const list = props.asynchronousTasks.filter(
    (task) => task.type === props.asynchronousTaskType && (!props.filter || props.filter(task))
  )
  const asynchronousTask =
    props.asynchronousTask?.type === props.asynchronousTaskType
      ? !props.filter || props.filter(props.asynchronousTask)
        ? props.asynchronousTask
        : undefined
      : undefined

  if (list.size === 0) {
    return null
  }

  return (
    <div className="asynchronous-task-selector">
      <label>{t('data_integration.async_task_select.title')}</label>
      <Select
        onChange={onChange}
        dropdownMatchSelectWidth={false}
        value={asynchronousTask?.id}
        placeholder={t('data_integration.async_task_select.title')}
      >
        {list
          .sort((a, b) => {
            if (!a.startedAt) {
              return 1
            }
            if (!b.startedAt) {
              return -1
            }
            return b.startedAt.localeCompare(a.startedAt)
          })
          .reduce((list: AsynchronousTask[], task) => {
            // ensure that we display five most recent non-failed ones and the five most recent failed ones
            if (
              (task.status !== 'Failed' && list.filter((item) => item.status !== 'Failed').length >= 5) ||
              (task.status === 'Failed' && list.filter((item) => item.status === 'Failed').length >= 5)
            ) {
              return list
            }
            list.push(task)
            return list
          }, [])
          .map((task) => {
            return (
              <Select.Option key={task.id} value={task.id}>
                {t(
                  task.finishedAt
                    ? 'data_integration.async_task_select.format.with_finished_at'
                    : 'data_integration.async_task_select.format.without_finished_at',
                  {
                    started_at: task.startedAt
                      ? t('data_integration.async_task_select.format.started_at', {
                          date: formatDateTime(task.startedAt),
                        })
                      : t('data_integration.async_task_select.format.not_started_at'),
                    time: task.finishedAt ? formatDate(task.finishedAt, 'HH:mm') : undefined,
                  }
                )}{' '}
                ({formatAsynchronousTaskStatus(task.status)})
              </Select.Option>
            )
          })}
      </Select>
    </div>
  )
}
