import React, { ReactElement } from 'react'

import Limits from '../../../constants/limits'
import { decorateFieldSignature, getFieldValueSignature } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Input from '../../elements/input'

type OneTimePayTitleFields = {
  title?: string
}

type Props<Fields extends OneTimePayTitleFields> = {
  editing: boolean

  getFieldValue: getFieldValueSignature<Fields>
  decorateField: decorateFieldSignature<Fields>
}

export default function OneTimePayTitle<Fields extends OneTimePayTitleFields>(
  props: Props<Fields>
): ReactElement | null {
  const charLength = (props.getFieldValue('title') ?? '').length
  const maxLength = Limits.MAX_PAY_SLIP_TEXT_LENGTH

  return (
    <>
      {props.decorateField('title', {
        placeholder: t('otp_form.title'),
        validate: (val) => (!val ? t('otp_form.title.required') : null),
      })(<Input disabled={!props.editing} />)}
      <span className="ant-form-char-limit">
        <span className="ant-form-char-limit-count">
          {t('otp_form.title.count', { length: charLength, max: maxLength })}
        </span>
        {charLength > maxLength && <span className="ant-form-char-limit-warning">{t('otp_form.title.warning')}</span>}
      </span>
    </>
  )
}
