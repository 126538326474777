import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Employee from '../../model/employee'
import PensionCompany from '../../model/pensionCompany'
import SalaryCycle from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import { DateFormat } from '../../model/types'
import { displayContractDelta } from '../../utils/contract-delta-utils'
import { formatDate, getDate, isTimeBefore } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { calculateFutureValidFrom } from '../../utils/future-contracts-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import Switch from '../elements/switch'
import { FutureContract } from './types'

type Props = {
  futureContracts: FutureContract[]
  validFrom: DateFormat
  employee: Employee
  salaryCycles: List<SalaryCycle>
  salaryTypes: List<SalaryType>
  pensionCompanies: List<PensionCompany>
}

type Fields = {
  future: {
    include: boolean
  }[]
}

export type FutureContractStepResult = {
  includedFutureContracts: number[] // index of the future contracts selected
  step: 'FutureContractStep'
}

function FutureContractStep(props: Props & FormComponentProps<Fields, FutureContractStepResult>): ReactElement | null {
  const { decorateAnyField } = props
  let previousValidFrom = props.validFrom

  if (
    props.employee.activeEmployment &&
    isTimeBefore(getDate(previousValidFrom), getDate(props.employee.activeEmployment.startDate))
  ) {
    previousValidFrom = props.employee.activeEmployment.startDate
  }

  return (
    <div>
      <Subtitle>{t('contracts_add.future_contract_step.title')}</Subtitle>
      <p>{t('contracts_add.future_contract_step.intro')}</p>
      <div>
        {props.futureContracts.map((futureContract, i) => {
          const validFrom = calculateFutureValidFrom(
            props.salaryCycles.toArray(),
            futureContract.salaryCycleID,
            previousValidFrom,
            futureContract.validFrom
          )
          if (!validFrom) {
            return null
          }
          previousValidFrom = validFrom
          const changes = displayContractDelta(
            futureContract.delta,
            props.salaryCycles.toArray(),
            props.salaryTypes.toArray(),
            props.pensionCompanies.toArray()
          )
          return (
            <Subcard key={`future-${i}`} className="future-contract-row">
              <Subtitle>Gyldig fra {formatDate(validFrom)}</Subtitle>
              <div className="ant-switch-wrapper">
                {decorateAnyField(`future.${i}.include`, {
                  skipWrapper: true,
                  skipLabel: true,
                  valueOnChecked: true,
                  noBlur: true,
                })(<Switch />)}
                <span className="ant-switch-text">{t('contracts_add.future_contract_step.form.include')}</span>
              </div>
              {changes.map((change, j) => {
                switch (change.type) {
                  case 'change':
                    return (
                      <span
                        key={`${i}-${j}`}
                        className={
                          'future-contract-row-change' +
                          (change.increase ? ' increase' : '') +
                          (change.decrease ? ' decrease' : '')
                        }
                      >
                        {!change.value ? (change.increase ? '+' : change.decrease ? '-' : '') : ''}
                        {change.message}
                        {change.value ? ': ' + (change.increase ? '+' : change.decrease ? '-' : '') + change.value : ''}
                      </span>
                    )
                  case 'remove':
                    return (
                      <span key={`${i}-${j}`} className={'future-contract-row-change remove'}>
                        -{change.message}
                      </span>
                    )
                  case 'initial':
                    return (
                      <span key={`${i}-${j}`} className={'future-contract-row-change initial'}>
                        {change.message}
                        {change.value ? ': ' + change.value : ''}
                      </span>
                    )
                  default:
                    break
                }
                return ''
              })}
            </Subcard>
          )
        })}
      </div>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('contracts_add.future_contract_step.submit')}
          </Button>
          <Button size="large" onClick={props.goBack}>
            {t('contracts_add.future_contract_step.back')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, FutureContractStepResult>({
  mapPropsToFields: (props) => ({
    future: props.futureContracts.map((_) => ({
      include: true,
    })),
  }),
  onSubmit: (values, props) => ({
    includedFutureContracts: props.futureContracts.filter((_, i) => values.future[i].include).map((_, i) => i),
    step: 'FutureContractStep',
  }),
})(FutureContractStep)
