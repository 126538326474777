import { addYears, max } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import LeaveType from '../../../model/leaveType'
import Remuneration from '../../../model/remuneration'
import SalaryPeriod from '../../../model/salaryPeriod'
import { StartBalances } from '../../../model/startBalances'
import { DateFormat } from '../../../model/types'
import { formatAPIDate, formatDate, getDate, isSameOrAfter } from '../../../utils/date-utils'
import { decorateAnyFieldSignature, getAnyFieldValueSignature } from '../../../utils/form-utils'
import { parseInputNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Select from '../../antd/select'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Headline from '../../elements/Headline'
import Input from '../../elements/input'

export type StartBalanceFields = {
  startBalances: Omit<StartBalances<string>, 'dispositionDate'> & {
    dispositionDate?: DateFormat
  }
}

export type ResultFields = {
  startBalances: StartBalances<number>
}

type Props<Fields extends StartBalanceFields> = {
  decorateAnyField: decorateAnyFieldSignature<Fields>
  getAnyFieldValue: getAnyFieldValueSignature
  contractValidFrom: DateFormat
  remuneration: Remuneration
  leaveTypes: List<LeaveType>
  salaryPeriods: SalaryPeriod[]
  disabled: boolean
}

type ExtraPersonalCheckResult = {
  extraVacation: boolean
  extraVacationAccrual: boolean
  personalDays: boolean
  personalDaysAccrual: boolean
  overtime: boolean
  any: boolean
}

export default function StartBalanceEditForm<Fields extends StartBalanceFields>(
  props: Props<Fields>
): ReactElement | null {
  const [extraPersonalCheckResult, setExtraPersonalCheckResult] = useState<ExtraPersonalCheckResult | undefined>(
    undefined
  )

  const extraPersonalCheck = (): ExtraPersonalCheckResult => {
    if (extraPersonalCheckResult) {
      return extraPersonalCheckResult
    }
    const result: ExtraPersonalCheckResult = {
      extraVacation: false,
      extraVacationAccrual: true,
      personalDays: false,
      personalDaysAccrual: true,
      overtime: false,
      any: false,
    }
    props.remuneration.leave.forEach((row) => {
      const name = row.type ? row.type.name : props.leaveTypes.find((type) => type.id === row.typeID)?.name
      switch (name) {
        case 'DenmarkPersonalDay':
          result.personalDays = true
          result.personalDaysAccrual = false
          result.any = true
          break
        case 'DenmarkPersonalTimeAccrual':
          result.personalDays = true
          result.personalDaysAccrual = true
          result.any = true
          break
        case 'DenmarkOptionalVacation':
          result.extraVacation = true
          result.extraVacationAccrual = false
          result.any = true
          break
        case 'DenmarkExtraVacationAccrual':
          result.extraVacation = true
          result.extraVacationAccrual = true
          result.any = true
          break
        case 'DenmarkOvertime':
          result.overtime = true
          result.any = true
          break
        default:
          break
      }
    })
    setExtraPersonalCheckResult(result)
    return result
  }

  const hasGreatPrayerDay = (): boolean => {
    return props.remuneration.supplements.some((value) => value.type?.name === 'DenmarkGreatPrayerDaySupplement')
  }

  const hasVacation = props.remuneration.leave.length > 0

  const hasPaidVacation = () => {
    let hasPaidVacation = true
    props.remuneration.leave.forEach((row) => {
      const name = row.type ? row.type.name : props.leaveTypes.find((type) => type.id === row.typeID)?.name
      switch (name) {
        case 'DenmarkVacationPaid':
        case 'DenmarkVacationAccrual':
          hasPaidVacation = true
          break
        case 'DenmarkVacationNoPay':
        case 'DenmarkVacationFund':
        case 'DenmarkVacationFundWithPension':
          hasPaidVacation = false
          break
        default:
          break
      }
    })
    return hasPaidVacation
  }

  const hasExtraPersonal = () => {
    const extraPersonal = extraPersonalCheck()
    return extraPersonal.any
  }
  const extraVacationCheck = (accrual: boolean) => {
    const extraPersonal = extraPersonalCheck()
    return extraPersonal.extraVacation && extraPersonal.extraVacationAccrual === accrual
  }
  const personalDaysCheck = (accrual: boolean) => {
    const extraPersonal = extraPersonalCheck()
    return extraPersonal.personalDays && extraPersonal.personalDaysAccrual === accrual
  }
  const overtimeCheck = () => {
    const extraPersonal = extraPersonalCheck()
    return extraPersonal.overtime
  }

  const { decorateAnyField } = props

  const today = getDate(props.getAnyFieldValue('startBalances.dispositionDate') as DateFormat | undefined)

  const getDispositionDates = (): DateFormat[] => {
    const also = formatAPIDate(today)
    // make sure that we do not allow days before 2021
    const validFrom = max([getDate(props.contractValidFrom), getDate('2021-01-01')])
    let foundAlso = false
    const list = props.salaryPeriods
      .filter((period) => isSameOrAfter(getDate(period.end), validFrom))
      .map((period) => {
        if (period.dispositionDate === also) {
          foundAlso = true
        }
        return period.dispositionDate
      })
    if (!foundAlso) {
      list.push(also)
    }
    return list.sort((a: DateFormat, b: DateFormat) => a.localeCompare(b, 'da-dk'))
  }

  const month = today.getMonth()
  let startYear = today.getFullYear()
  let endYear = addYears(today, 1).getFullYear()
  if (month < 8) {
    startYear -= 1
    endYear -= 1
  }
  const vacationBasisTitle = t('start_balances.form.vacation_basis_title', { start_year: startYear, end_year: endYear })
  const vacationBasisCarryOverTitle = t('start_balances.form.vacation_basis_carry_over_title', {
    start_year: startYear - 1,
    end_year: endYear - 1,
  })
  const accrualDaysEarnedTitle = t('start_balances.form.accrual_days_earned_title', {
    start_year: startYear,
    end_year: endYear,
  })
  const accrualDaysCarryOverTitle = t('start_balances.form.accrual_days_carry_over_title', {
    start_year: startYear - 1,
    end_year: endYear - 1,
  })
  let accrualDaysTakenTitle = t('start_balances.form.accrual_days_taken_title', {
    start_year: startYear,
    end_year: endYear,
  })
  const fundVacationNetTitle = t('start_balances.form.fund_vacation_net_title', {
    start_year: startYear,
    end_year: endYear,
  })
  const fundDaysEarnedTitle = t('start_balances.form.fund_days_earned_title', {
    start_year: startYear,
    end_year: endYear,
  })
  const fundDaysTakenTitle = t('start_balances.form.fund_days_taken_title', {
    start_year: startYear,
    end_year: endYear,
  })
  let supplementEarnedTitle = t('start_balances.form.supplement_earned_title.not_summer', {
    start_year: startYear,
    end_year: endYear,
  })
  if (month >= 5 && month <= 7) {
    supplementEarnedTitle = t('start_balances.form.supplement_earned_title.summer', {
      start_year: endYear,
      end_year: endYear,
    })
  }
  if (month >= 8) {
    accrualDaysTakenTitle = t('start_balances.form.accrual_days_taken_title.year_end', {
      start_year: startYear - 1,
      end_year: startYear,
    })
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          {decorateAnyField('startBalances.dispositionDate', {
            placeholder: t('start_balances.form.disposition_date'),
            validate: (val) => {
              if (!val) {
                return t('start_balances.form.disposition_date.required')
              }
              return null
            },
          })(
            <Select dropdownMatchSelectWidth={false}>
              {getDispositionDates().map((date) => {
                return (
                  <Select.Option key={date} value={date}>
                    {formatDate(date)}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Headline>{t('start_balances.form.salary_title')}</Headline>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          {decorateAnyField('startBalances.salary', {
            placeholder: t('start_balances.form.salary'),
            suffix: t('start_balances.form.salary.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.salary.required')
              }
              if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('start_balances.form.salary.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
        <Col span={6}>
          {decorateAnyField('startBalances.dkSpecific.amIncome', {
            placeholder: t('start_balances.form.am_income'),
            suffix: t('start_balances.form.am_income.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.am_income.required')
              }
              if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('start_balances.form.am_income.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
        <Col span={6}>
          {decorateAnyField('startBalances.dkSpecific.aIncome', {
            placeholder: t('start_balances.form.a_income'),
            suffix: t('start_balances.form.a_income.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.a_income.required')
              }
              if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('start_balances.form.a_income.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
        <Col span={6}>
          {decorateAnyField('startBalances.workHours', {
            placeholder: t('start_balances.form.work_hours'),
            suffix: t('start_balances.form.work_hours.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.work_hours.required')
              }
              if (parseInputNumber(val) < 0 || parseInputNumber(val) > 8784 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('start_balances.form.work_hours.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
        <Col span={6}>
          {decorateAnyField('startBalances.workDays', {
            placeholder: t('start_balances.form.work_days'),
            suffix: t('start_balances.form.work_days.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.work_days.required')
              }
              if (parseInputNumber(val) < 0 || parseInputNumber(val) > 8784 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('start_balances.form.work_days.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Headline>{t('start_balances.form.tax_title')}</Headline>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          {decorateAnyField('startBalances.dkSpecific.amTax', {
            placeholder: t('start_balances.form.am_tax'),
            suffix: t('start_balances.form.am_tax.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.am_tax.required')
              }
              if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('start_balances.form.am_tax.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
        <Col span={6}>
          {decorateAnyField('startBalances.dkSpecific.aTax', {
            placeholder: t('start_balances.form.a_tax'),
            suffix: t('start_balances.form.a_tax.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.a_tax.required')
              }
              if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('start_balances.form.a_tax.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
        <Col span={6}>
          {decorateAnyField('startBalances.dkSpecific.employeeATP', {
            placeholder: t('start_balances.form.employee_atp'),
            suffix: t('start_balances.form.employee_atp.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.employee_atp.required')
              }
              if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('start_balances.form.employee_atp.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
        <Col span={6}>
          {decorateAnyField('startBalances.dkSpecific.employerATP', {
            placeholder: t('start_balances.form.employer_atp'),
            suffix: t('start_balances.form.employer_atp.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.employer_atp.required')
              }
              if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('start_balances.form.employer_atp.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
        <Col span={12}>
          {decorateAnyField('startBalances.dkSpecific.shDage', {
            placeholder: t('start_balances.form.sh_dage'),
            suffix: t('start_balances.form.sh_dage.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.sh_dage.required')
              }
              if (!val.toString().match(/^-?[0-9,.]+$/)) {
                return t('start_balances.form.sh_dage.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
        <Col span={12}>
          {decorateAnyField('startBalances.dkSpecific.fritvalg', {
            placeholder: t('start_balances.form.fritvalg'),
            suffix: t('start_balances.form.fritvalg.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.fritvalg.required')
              }
              if (!val.toString().match(/^-?[0-9,.]+$/)) {
                return t('start_balances.form.fritvalg.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
        <Col span={12}>
          {decorateAnyField('startBalances.dkSpecific.carAllowance.kilometers', {
            placeholder: t('start_balances.form.car_allowance'),
            suffix: t('start_balances.form.car_allowance.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.car_allowance.required')
              }
              if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('start_balances.form.car_allowance.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Headline>{t('start_balances.form.pension_title')}</Headline>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateAnyField('startBalances.employeePaidPension', {
            placeholder: t('start_balances.form.employee_paid_pension'),
            suffix: t('start_balances.form.employee_paid_pension.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.employee_paid_pension.required')
              }
              if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('start_balances.form.employee_paid_pension.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
        <Col span={12}>
          {decorateAnyField('startBalances.employerPaidPension', {
            placeholder: t('start_balances.form.employer_paid_pension'),
            suffix: t('start_balances.form.employer_paid_pension.suffix'),
            validate: (val) => {
              if (!val && val !== 0) {
                return t('start_balances.form.employer_paid_pension.required')
              }
              if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('start_balances.form.employer_paid_pension.invalid')
              }
              return null
            },
          })(<Input disabled={props.disabled} />)}
        </Col>
      </Row>
      {hasVacation && (
        <div>
          <Row>
            <Col span={24}>
              <Headline>{t('start_balances.form.vacation_title')}</Headline>
            </Col>
          </Row>
          {hasPaidVacation() && (
            <Row>
              {(month === 0 || month >= 8) && (
                <Col span={12}>
                  {decorateAnyField('startBalances.dkSpecific.vacationAccrual.vacationBasisCarryOver', {
                    placeholder: vacationBasisCarryOverTitle,
                    suffix: t('start_balances.form.generic_vacation.suffix.currency'),
                    validate: (val) => {
                      if (!val && val !== 0) {
                        return t('start_balances.form.generic_vacation.required', {
                          title: vacationBasisCarryOverTitle,
                        })
                      }
                      if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                        return t('start_balances.form.generic_vacation.invalid', { title: vacationBasisCarryOverTitle })
                      }
                      return null
                    },
                  })(<Input disabled={props.disabled} />)}
                </Col>
              )}
              {(month === 0 || month >= 8) && (
                <Col span={12}>
                  {decorateAnyField('startBalances.dkSpecific.vacationAccrual.daysCarryOver', {
                    placeholder: accrualDaysCarryOverTitle,
                    suffix: t('start_balances.form.generic_vacation.suffix.days'),
                    validate: (val) => {
                      if (!val && val !== 0) {
                        return t('start_balances.form.generic_vacation.required', { title: accrualDaysCarryOverTitle })
                      }
                      if (
                        parseInputNumber(val) < 0 ||
                        parseInputNumber(val) > 365 ||
                        !val.toString().match(/^[0-9,.]+$/)
                      ) {
                        return t('start_balances.form.generic_vacation.invalid', { title: accrualDaysCarryOverTitle })
                      }
                      return null
                    },
                  })(<Input disabled={props.disabled} />)}
                </Col>
              )}
              <Col span={12}>
                {decorateAnyField('startBalances.dkSpecific.vacationAccrual.vacationBasis', {
                  placeholder: vacationBasisTitle,
                  suffix: t('start_balances.form.generic_vacation.suffix.currency'),
                  validate: (val) => {
                    if (!val && val !== 0) {
                      return t('start_balances.form.generic_vacation.required', { title: vacationBasisTitle })
                    }
                    if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                      return t('start_balances.form.generic_vacation.invalid', { title: vacationBasisTitle })
                    }
                    return null
                  },
                })(<Input disabled={props.disabled} />)}
              </Col>
              <Col span={12}>
                {decorateAnyField('startBalances.dkSpecific.vacationAccrual.daysEarned', {
                  placeholder: accrualDaysEarnedTitle,
                  suffix: t('start_balances.form.generic_vacation.suffix.days'),
                  validate: (val) => {
                    if (!val && val !== 0) {
                      return t('start_balances.form.generic_vacation.required', { title: accrualDaysEarnedTitle })
                    }
                    if (
                      parseInputNumber(val) < 0 ||
                      parseInputNumber(val) > 365 ||
                      !val.toString().match(/^[0-9,.]+$/)
                    ) {
                      return t('start_balances.form.generic_vacation.invalid', { title: accrualDaysEarnedTitle })
                    }
                    return null
                  },
                })(<Input disabled={props.disabled} />)}
              </Col>
              <Col span={12}>
                {decorateAnyField('startBalances.dkSpecific.vacationAccrual.daysTaken', {
                  placeholder: accrualDaysTakenTitle,
                  suffix: t('start_balances.form.generic_vacation.suffix.days'),
                  validate: (val) => {
                    if (!val && val !== 0) {
                      return t('start_balances.form.generic_vacation.required', { title: accrualDaysTakenTitle })
                    }
                    if (
                      parseInputNumber(val) < 0 ||
                      parseInputNumber(val) > 365 ||
                      !val.toString().match(/^[0-9,.]+$/)
                    ) {
                      return t('start_balances.form.generic_vacation.invalid', { title: accrualDaysTakenTitle })
                    }
                    return null
                  },
                })(<Input disabled={props.disabled} />)}
              </Col>
              <Col span={12}>
                {decorateAnyField('startBalances.dkSpecific.vacationAccrual.supplementEarned', {
                  placeholder: supplementEarnedTitle,
                  suffix: t('start_balances.form.generic_vacation.suffix.currency'),
                  validate: (val) => {
                    if (!val && val !== 0) {
                      return t('start_balances.form.generic_vacation.required', { title: supplementEarnedTitle })
                    }
                    if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                      return t('start_balances.form.generic_vacation.invalid', { title: supplementEarnedTitle })
                    }
                    return null
                  },
                })(<Input disabled={props.disabled} />)}
              </Col>
            </Row>
          )}
          {!hasPaidVacation() && (
            <Row>
              <Col span={12}>
                {decorateAnyField('startBalances.dkSpecific.vacationFund.vacationNet', {
                  placeholder: fundVacationNetTitle,
                  suffix: t('start_balances.form.generic_vacation.suffix.currency'),
                  validate: (val) => {
                    if (!val && val !== 0) {
                      return t('start_balances.form.generic_vacation.required', { title: fundVacationNetTitle })
                    }
                    if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                      return t('start_balances.form.generic_vacation.invalid', { title: fundVacationNetTitle })
                    }
                    return null
                  },
                })(<Input disabled={props.disabled} />)}
              </Col>
              <Col span={12}>
                {decorateAnyField('startBalances.dkSpecific.vacationFund.vacationBasis', {
                  placeholder: vacationBasisTitle,
                  suffix: t('start_balances.form.generic_vacation.suffix.currency'),
                  validate: (val) => {
                    if (!val && val !== 0) {
                      return t('start_balances.form.generic_vacation.required', { title: vacationBasisTitle })
                    }
                    if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                      return t('start_balances.form.generic_vacation.invalid', { title: vacationBasisTitle })
                    }
                    return null
                  },
                })(<Input disabled={props.disabled} />)}
              </Col>
              <Col span={12}>
                {decorateAnyField('startBalances.dkSpecific.vacationFund.daysEarned', {
                  placeholder: fundDaysEarnedTitle,
                  suffix: t('start_balances.form.generic_vacation.suffix.days'),
                  validate: (val) => {
                    if (!val && val !== 0) {
                      return t('start_balances.form.generic_vacation.required', { title: fundDaysEarnedTitle })
                    }
                    if (
                      parseInputNumber(val) < 0 ||
                      parseInputNumber(val) > 365 ||
                      !val.toString().match(/^[0-9,.]+$/)
                    ) {
                      return t('start_balances.form.generic_vacation.invalid', { title: fundDaysEarnedTitle })
                    }
                    return null
                  },
                })(<Input disabled={props.disabled} />)}
              </Col>
              <Col span={12}>
                {decorateAnyField('startBalances.dkSpecific.vacationFund.daysTaken', {
                  placeholder: fundDaysTakenTitle,
                  suffix: t('start_balances.form.generic_vacation.suffix.days'),
                  validate: (val) => {
                    if (!val && val !== 0) {
                      return t('start_balances.form.generic_vacation.required', { title: fundDaysTakenTitle })
                    }
                    if (
                      parseInputNumber(val) < 0 ||
                      parseInputNumber(val) > 365 ||
                      !val.toString().match(/^[0-9,.]+$/)
                    ) {
                      return t('start_balances.form.generic_vacation.invalid', { title: fundDaysTakenTitle })
                    }
                    return null
                  },
                })(<Input disabled={props.disabled} />)}
              </Col>
            </Row>
          )}
          {hasExtraPersonal() && (
            <Row>
              {extraVacationCheck(false) && (
                <Col span={12}>
                  {decorateAnyField('startBalances.dkSpecific.vacationOptional.daysAvailableThisYear', {
                    placeholder: t('start_balances.form.vacation_optional_days_available_this_year'),
                    suffix: t('start_balances.form.vacation_optional_days_available_this_year.suffix'),
                    validate: (val) => {
                      if (!val && val !== 0) {
                        return t('start_balances.form.vacation_optional_days_available_this_year.required')
                      }
                      if (
                        parseInputNumber(val) < 0 ||
                        parseInputNumber(val) > 365 ||
                        !val.toString().match(/^[0-9,.]+$/)
                      ) {
                        return t('start_balances.form.vacation_optional_days_available_this_year.invalid')
                      }
                      return null
                    },
                  })(<Input disabled={props.disabled} />)}
                </Col>
              )}
              {extraVacationCheck(false) && (
                <Col span={12}>
                  {decorateAnyField('startBalances.dkSpecific.vacationOptional.daysTakenThisYear', {
                    placeholder: t('start_balances.form.vacation_optional_days_taken_this_year'),
                    suffix: t('start_balances.form.vacation_optional_days_taken_this_year.suffix'),
                    validate: (val) => {
                      if (!val && val !== 0) {
                        return t('start_balances.form.vacation_optional_days_taken_this_year.required')
                      }
                      if (
                        parseInputNumber(val) < 0 ||
                        parseInputNumber(val) > 365 ||
                        !val.toString().match(/^[0-9,.]+$/)
                      ) {
                        return t('start_balances.form.vacation_optional_days_taken_this_year.invalid')
                      }
                      return null
                    },
                  })(<Input disabled={props.disabled} />)}
                </Col>
              )}
              {extraVacationCheck(true) && (
                <Col span={12}>
                  {decorateAnyField('startBalances.dkSpecific.extraVacationAccrual.daysEarned', {
                    placeholder: t('start_balances.form.extra_vacation_accrual_days_earned'),
                    suffix: t('start_balances.form.extra_vacation_accrual_days_earned.suffix'),
                    validate: (val) => {
                      if (!val && val !== 0) {
                        return t('start_balances.form.extra_vacation_accrual_days_earned.required')
                      }
                      if (
                        parseInputNumber(val) < 0 ||
                        parseInputNumber(val) > 365 ||
                        !val.toString().match(/^[0-9,.]+$/)
                      ) {
                        return t('start_balances.form.extra_vacation_accrual_days_earned.invalid')
                      }
                      return null
                    },
                  })(<Input disabled={props.disabled} />)}
                </Col>
              )}
              {extraVacationCheck(true) && (
                <Col span={12}>
                  {decorateAnyField('startBalances.dkSpecific.extraVacationAccrual.daysTaken', {
                    placeholder: t('start_balances.form.extra_vacation_accrual_days_taken'),
                    suffix: t('start_balances.form.extra_vacation_accrual_days_taken.suffix'),
                    validate: (val) => {
                      if (!val && val !== 0) {
                        return t('start_balances.form.extra_vacation_accrual_days_taken.required')
                      }
                      if (
                        parseInputNumber(val) < 0 ||
                        parseInputNumber(val) > 365 ||
                        !val.toString().match(/^[0-9,.]+$/)
                      ) {
                        return t('start_balances.form.extra_vacation_accrual_days_taken.invalid')
                      }
                      return null
                    },
                  })(<Input disabled={props.disabled} />)}
                </Col>
              )}
              {personalDaysCheck(false) && (
                <Col span={12}>
                  {decorateAnyField('startBalances.dkSpecific.vacationPersonalDays.daysAvailableThisYear', {
                    placeholder: t('start_balances.form.vacation_personal_days_days_available_this_year'),
                    suffix: t('start_balances.form.vacation_personal_days_days_available_this_year.suffix'),
                    validate: (val) => {
                      if (!val && val !== 0) {
                        return t('start_balances.form.vacation_personal_days_days_available_this_year.required')
                      }
                      if (
                        parseInputNumber(val) < 0 ||
                        parseInputNumber(val) > 365 ||
                        !val.toString().match(/^[0-9,.]+$/)
                      ) {
                        return t('start_balances.form.vacation_personal_days_days_available_this_year.invalid')
                      }
                      return null
                    },
                  })(<Input disabled={props.disabled} />)}
                </Col>
              )}
              {personalDaysCheck(false) && (
                <Col span={12}>
                  {decorateAnyField('startBalances.dkSpecific.vacationPersonalDays.daysTakenThisYear', {
                    placeholder: t('start_balances.form.vacation_personal_days_days_taken_this_year'),
                    suffix: t('start_balances.form.vacation_personal_days_days_taken_this_year.suffix'),
                    validate: (val) => {
                      if (!val && val !== 0) {
                        return t('start_balances.form.vacation_personal_days_days_taken_this_year.required')
                      }
                      if (
                        parseInputNumber(val) < 0 ||
                        parseInputNumber(val) > 365 ||
                        !val.toString().match(/^[0-9,.]+$/)
                      ) {
                        return t('start_balances.form.vacation_personal_days_days_taken_this_year.invalid')
                      }
                      return null
                    },
                  })(<Input disabled={props.disabled} />)}
                </Col>
              )}
              {personalDaysCheck(true) && (
                <Col span={12}>
                  {decorateAnyField('startBalances.dkSpecific.personalTimeAccrual.daysEarned', {
                    placeholder: t('start_balances.form.personal_time_accrual_days_earned'),
                    suffix: t('start_balances.form.personal_time_accrual_days_earned.suffix'),
                    validate: (val) => {
                      if (!val && val !== 0) {
                        return t('start_balances.form.personal_time_accrual_days_earned.required')
                      }
                      if (
                        parseInputNumber(val) < 0 ||
                        parseInputNumber(val) > 365 ||
                        !val.toString().match(/^[0-9,.]+$/)
                      ) {
                        return t('start_balances.form.personal_time_accrual_days_earned.invalid')
                      }
                      return null
                    },
                  })(<Input disabled={props.disabled} />)}
                </Col>
              )}
              {personalDaysCheck(true) && (
                <Col span={12}>
                  {decorateAnyField('startBalances.dkSpecific.personalTimeAccrual.daysTaken', {
                    placeholder: t('start_balances.form.personal_time_accrual_days_taken'),
                    suffix: t('start_balances.form.personal_time_accrual_days_taken.suffix'),
                    validate: (val) => {
                      if (!val && val !== 0) {
                        return t('start_balances.form.personal_time_accrual_days_taken.required')
                      }
                      if (
                        parseInputNumber(val) < 0 ||
                        parseInputNumber(val) > 365 ||
                        !val.toString().match(/^[0-9,.]+$/)
                      ) {
                        return t('start_balances.form.personal_time_accrual_days_taken.invalid')
                      }
                      return null
                    },
                  })(<Input disabled={props.disabled} />)}
                </Col>
              )}
              {overtimeCheck() && (
                <Col span={12}>
                  {decorateAnyField('startBalances.dkSpecific.overtime.balance', {
                    placeholder: t('start_balances.form.overtime_balance'),
                    suffix: t('start_balances.form.overtime_balance.suffix'),
                    validate: (val) => {
                      if (!val && val !== 0) {
                        return t('start_balances.form.overtime_balance.required')
                      }
                      if (!val.toString().match(/^-?[0-9,.]+$/)) {
                        return t('start_balances.form.overtime_balance.invalid')
                      }
                      return null
                    },
                  })(<Input disabled={props.disabled} />)}
                </Col>
              )}
            </Row>
          )}
          {hasGreatPrayerDay() && (
            <Row>
              <Col span={12}>
                {decorateAnyField('startBalances.dkSpecific.greatPrayerDay.earned', {
                  placeholder: t('start_balances.form.great_prayer_day_earned'),
                  suffix: t('start_balances.form.great_prayer_day_earned.suffix'),
                  validate: (val) => {
                    if (!val && val !== 0) {
                      return t('start_balances.form.great_prayer_day_earned.required')
                    }
                    if (parseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                      return t('start_balances.form.great_prayer_day_earned.invalid')
                    }
                    return null
                  },
                })(<Input disabled={props.disabled} />)}
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  )
}
