import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Agreement from '../model/agreement'
import { ReducerAction } from '../utils/reducer-utils'

export interface AgreementReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  agreements: List<Agreement>
  error: Error | null
}

const Params = Record<AgreementReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  agreements: List<Agreement>(),
  error: null,
})

export interface AgreementAction extends ReducerAction {
  companyID?: string
  agreements?: Agreement[]
  agreement?: Agreement
}

export default (
  state: Record<AgreementReducer> = Params(),
  action: AgreementAction = { type: '' }
): Record<AgreementReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.AGREEMENT_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.AGREEMENT_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('agreements', List<Agreement>(action.agreements))
        .set('error', null)
    case ActionTypes.AGREEMENT_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('error', action.error || null)

    case ActionTypes.AGREEMENT_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.AGREEMENT_ADDED:
      if (!action.agreement) {
        return state
      }
      idx = state.get('agreements').findIndex((item) => !!action.agreement && item.type === action.agreement.type)
      if (idx !== -1) {
        return state.set('saving', false).set('agreements', state.get('agreements').set(idx, action.agreement))
      }
      return state.set('saving', false).set('agreements', state.get('agreements').push(action.agreement))
    case ActionTypes.AGREEMENT_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
