import React from 'react'
import { IndexRoute, Route, RoutePropLocation, RouteProps } from 'react-router'

import App from './App'
import AcceptUserInvite from './controllers/AcceptUserInvite'
import Account from './controllers/Account'
import Accounting from './controllers/Accounting'
import AccountingActiveInformation from './controllers/AccountingActiveInformation'
import AccountingDaybooks from './controllers/AccountingDaybooks'
import AccountingDimensions from './controllers/AccountingDimensions'
import AccountingOrganizations from './controllers/AccountingOrganizations'
import AccountingSettings from './controllers/AccountingSettings'
import AccountingVouchers from './controllers/AccountingVouchers'
import ApproveTab from './controllers/ApproveTab'
import Assets from './controllers/Assets'
import BasicIntegration from './controllers/BasicIntegration'
import DocumentSigned from './controllers/callbacks/CriiptoSigned'
import CarAllowance from './controllers/CarAllowance'
import Companies from './controllers/Companies'
import CompaniesAdd from './controllers/CompaniesAdd'
import CompaniesSingle from './controllers/CompaniesSingle'
import CompanyAccountingIntegration from './controllers/CompanyAccountingIntegration'
import CompanyDashboards from './controllers/CompanyDashboards'
import CompanyDataIntegration from './controllers/CompanyDataIntegration'
import CompanyUsers from './controllers/CompanyUsers'
import ConfirmEmailChange from './controllers/ConfirmEmailChange'
import ContractBook from './controllers/ContractBook'
import ContractsAdd from './controllers/ContractsAdd'
import ContractTemplates from './controllers/ContractTemplates'
import Dashboard from './controllers/Dashboard'
import DataIntegration from './controllers/DataIntegration'
import Documents from './controllers/Documents'
import Employees from './controllers/Employees'
import EmployeesAdd from './controllers/EmployeesAdd'
import EmployeesBatch from './controllers/EmployeesBatch'
import EmployeesSingle from './controllers/EmployeesSingle'
import Freelancers from './controllers/Freelancers'
import FreelancersAdd from './controllers/FreelancersAdd'
import FreelancersOverview from './controllers/FreelancersOverview'
import FreelancersSingle from './controllers/FreelancersSingle'
import InAccounting from './controllers/InAccounting'
import InApiKeys from './controllers/InApiKeys'
import InLogin from './controllers/InLogin'
import InTimeRegistration from './controllers/InTimeRegistration'
import Jumper from './controllers/jumper/Jumper'
import LeaveRegistration from './controllers/LeaveRegistration'
import LoanService from './controllers/LoanService'
import Login from './controllers/Login'
import Notifications from './controllers/Notifications'
import OneTimePays from './controllers/OneTimePays'
import PayRolls from './controllers/PayRolls'
import PayRollsAdd from './controllers/PayRollsAdd'
import PayRollsSingle from './controllers/PayRollsSingle'
import PDFPreview from './controllers/PDFPreview'
import DocumentSigningPhonePage from './controllers/phone-page/DocumentSigningPhonePage'
import PayrollApprovePhonePage from './controllers/phone-page/PayrollApprovePhonePage'
import PersonVerificationPhonePage from './controllers/phone-page/PersonVerificationPhonePage'
import Projects from './controllers/Projects'
import Register from './controllers/Register'
import ReimbursementVouchers from './controllers/ReimbursementVouchers'
import ResetPassword from './controllers/ResetPassword'
import SalaryRegistration from './controllers/SalaryRegistration'
import SSO from './controllers/SSO'
import StagedImport from './controllers/StagedImport'
import SwipeOverview from './controllers/SwipeOverview'
import TermsContainer from './controllers/TermContainer'
import TimeRegistration from './controllers/TimeRegistration'
import VerifyEmail from './controllers/VerifyEmail'
import WebTokenAuth from './controllers/WebTokenAuth'
import WebTokenConnect from './controllers/WebTokenConnect'
import WebTokenUserConnect from './controllers/WebTokenUserConnect'
import WorkHours from './controllers/WorkHours'
import { getAccessToken } from './utils/cookie-utils'

export const paths = {
  JUMP: 'jump',

  PAYROLL_APPROVE_PHONE_SITE: 'a',
  DOCUMENT_SIGNING_PHONE_SITE: 'signing',
  ACCOUNT: 'account',
  CHANGE_EMAIL: 'change-email',
  VERIFY_EMAIL: 'verify-email',
  PERSON_VERIFICATION: 'verify-person',
  USER_VERIFICATION: 'verify-user',

  EMPLOYEES: 'employees',
  CONTRACTS: 'contracts',
  COMPANIES: 'companies',
  COMPANY_GROUPS: 'company-groups',
  ACCOUNTING: 'accounting',
  CAR_ALLOWANCE: 'car-allowance',
  DASHBOARD: 'dashboard',
  FREELANCERS: 'freelancers',
  FREELANCERS_OVERVIEW: 'freelancers-overview',
  INTEGRATIONS: 'integrations',
  APPROVE_TAB: 'approve',
  LEAVE_REGISTRATION: 'leave-registration',
  NOTIFICATIONS: 'notifications',
  ONE_TIME_PAYS: 'one-time-pays',
  PAY_ROLLS: 'pay',
  PDF_PREVIEW: 'pdf',
  REIMBURSEMENT_VOUCHERS: 'reimbursement-vouchers',
  STAGED_IMPORT: 'staged-import',
  TIME_REGISTRATION: 'time-registration',
  DATA_PROVIDER: 'data-provider',
  SALARY_REGISTRATION: 'salary-registration',
  SWIPE_OVERVIEW: 'swipe-overview',
  USERS: 'users',
  EMPLOYEE_TEMPLATES: 'employee-templates',
  ASSETS: 'assets',
  ASSET_CATEGORIES: 'asset-categories',
  DOCUMENTS: 'documents',
  PROJECTS: 'projects',
  WORK_HOURS: 'work-hours',

  // Integrations
  ACCOUNTING_VOUCHERS: 'accounting-vouchers',
  ACCOUNTING_DIMENSIONS: 'accounting-dimensions',
  ACCOUNTING_DAYBOOKS: 'accounting-daybooks',
  ACCOUNTING_ORGANIZATIONS: 'accounting-organizations',
  SETTINGS: 'settings',
  ACTIVE_INFORMATION: 'active-information',
  CONTRACT_BOOK: 'contract-book',
  LOAN: 'loan',
  WEB_AUTH: 'webauth',
  WEB_CONNECT: 'webconnect',
  USER_CONNECT: 'userconnect',
  CRIIPTO: 'criipto',

  LOGIN: 'login',
  REGISTER: 'register',
  REQUEST_PASSWORD: 'request-password',
  RESET_PASSWORD: 'reset-password',
  ACCEPT_USER_INVITE: 'user-invite',
  SSO: 'sso',

  IN: 'in',

  // Redirects
  API_KEYS: 'api-keys',

  ADD: 'add',
  INVITE: 'invite',
  BATCH: 'batch',
  AWAITING_CONTRACT: 'awaiting-contract',

  TERMS: 'terms',
}

function requireUnauthorized(nextState: RouteProps, replace: (state: Partial<RoutePropLocation>) => void) {
  if (getAccessToken()) {
    replace({
      pathname: nextState.location.query.ref || '/',
    })
  }
}
function requireAuthorized(nextState: RouteProps, replace: (state: Partial<RoutePropLocation>) => void) {
  if (!getAccessToken()) {
    replace({
      pathname: '/' + paths.LOGIN,
      query: { ref: nextState.location.pathname + nextState.location.search + nextState.location.hash },
    })
  }
}

export default (
  <Route path="/" component={App}>
    <IndexRoute component={Dashboard} onEnter={requireAuthorized} />

    <Route path={paths.JUMP} component={Jumper} onEnter={requireAuthorized} />
    <Route path={paths.JUMP + '/*'} component={Jumper} onEnter={requireAuthorized} />
    <Route path={paths.JUMP + '/*/*'} component={Jumper} onEnter={requireAuthorized} />
    <Route path={paths.JUMP + '/*/*/*'} component={Jumper} onEnter={requireAuthorized} />
    <Route path={paths.JUMP + '/*/*/*/*'} component={Jumper} onEnter={requireAuthorized} />

    <Route path={paths.ACCOUNT} component={Account} onEnter={requireAuthorized} />
    <Route path={paths.ACCOUNT + '/*'} component={Account} onEnter={requireAuthorized} />
    <Route path={paths.CHANGE_EMAIL + '/*'} component={ConfirmEmailChange} />
    <Route path={paths.VERIFY_EMAIL + '/*'} component={VerifyEmail} />
    <Route path={paths.ACCEPT_USER_INVITE + '/*'} component={AcceptUserInvite} onEnter={requireAuthorized} />

    <Route path={paths.COMPANIES} component={Companies} onEnter={requireAuthorized} />
    <Route path={paths.COMPANY_GROUPS} component={Companies} onEnter={requireAuthorized} />
    <Route path={paths.COMPANY_GROUPS + '/*'} component={Companies} onEnter={requireAuthorized} />
    <Route path={paths.COMPANY_GROUPS + '/*/*'} component={Companies} onEnter={requireAuthorized} />
    <Route
      path={paths.COMPANIES + '/*/' + paths.ACCOUNTING + '/' + paths.ADD}
      component={CompanyAccountingIntegration}
      onEnter={requireAuthorized}
    />
    <Route
      path={paths.COMPANIES + '/*/' + paths.DATA_PROVIDER + '/' + paths.ADD}
      component={CompanyDataIntegration}
      onEnter={requireAuthorized}
    />
    <Route path={paths.COMPANIES + '/' + paths.ADD} component={CompaniesAdd} onEnter={requireAuthorized} />
    <Route path={paths.COMPANIES + '/*/users'} component={CompanyUsers} onEnter={requireAuthorized} />
    <Route path={paths.COMPANIES + '/*'} component={CompaniesSingle} onEnter={requireAuthorized} />
    <Route path={paths.COMPANIES + '/*/*'} component={CompaniesSingle} onEnter={requireAuthorized} />
    <Route path={paths.COMPANIES + '/*/*/*'} component={CompaniesSingle} onEnter={requireAuthorized} />
    <Route path={paths.DASHBOARD} component={CompanyDashboards} onEnter={requireAuthorized} />

    <Route
      path={paths.EMPLOYEES + '/*/' + paths.CONTRACTS + '/' + paths.ADD}
      component={ContractsAdd}
      onEnter={requireAuthorized}
    />

    <Route path={paths.EMPLOYEES} component={Employees} onEnter={requireAuthorized} />
    <Route path={paths.EMPLOYEES + '/' + paths.AWAITING_CONTRACT} component={Employees} onEnter={requireAuthorized} />
    <Route path={paths.EMPLOYEES + '/' + paths.ADD} component={EmployeesAdd} onEnter={requireAuthorized} />
    <Route path={paths.EMPLOYEES + '/' + paths.INVITE} component={EmployeesAdd} onEnter={requireAuthorized} />
    <Route path={paths.EMPLOYEES + '/' + paths.BATCH} component={EmployeesBatch} onEnter={requireAuthorized} />
    <Route path={paths.EMPLOYEES + '/*'} component={EmployeesSingle} onEnter={requireAuthorized} />
    <Route path={paths.EMPLOYEES + '/*/*'} component={EmployeesSingle} onEnter={requireAuthorized} />
    <Route path={paths.EMPLOYEES + '/*/*/*'} component={EmployeesSingle} onEnter={requireAuthorized} />

    <Route path={paths.EMPLOYEE_TEMPLATES} component={ContractTemplates} onEnter={requireAuthorized} />
    <Route path={paths.EMPLOYEE_TEMPLATES + '/*'} component={ContractTemplates} onEnter={requireAuthorized} />

    <Route path={paths.FREELANCERS} component={Freelancers} onEnter={requireAuthorized} />
    <Route path={paths.FREELANCERS + '/' + paths.ADD} component={FreelancersAdd} onEnter={requireAuthorized} />
    <Route path={paths.FREELANCERS + '/' + paths.INVITE} component={FreelancersAdd} onEnter={requireAuthorized} />
    <Route path={paths.FREELANCERS + '/*'} component={FreelancersSingle} onEnter={requireAuthorized} />
    <Route path={paths.FREELANCERS + '/*/*'} component={FreelancersSingle} onEnter={requireAuthorized} />

    <Route path={paths.INTEGRATIONS + '/' + paths.ACCOUNTING} component={Accounting} onEnter={requireAuthorized} />
    <Route
      path={paths.INTEGRATIONS + '/' + paths.ACCOUNTING_VOUCHERS}
      component={AccountingVouchers}
      onEnter={requireAuthorized}
    />
    <Route
      path={paths.INTEGRATIONS + '/' + paths.ACCOUNTING_DAYBOOKS}
      component={AccountingDaybooks}
      onEnter={requireAuthorized}
    />
    <Route
      path={paths.INTEGRATIONS + '/' + paths.ACCOUNTING_ORGANIZATIONS}
      component={AccountingOrganizations}
      onEnter={requireAuthorized}
    />
    <Route
      path={paths.INTEGRATIONS + '/' + paths.ACCOUNTING_DIMENSIONS}
      component={AccountingDimensions}
      onEnter={requireAuthorized}
    />
    <Route
      path={paths.INTEGRATIONS + '/' + paths.ACCOUNTING + '/' + paths.SETTINGS}
      component={AccountingSettings}
      onEnter={requireAuthorized}
    />
    <Route
      path={paths.INTEGRATIONS + '/' + paths.ACCOUNTING + '/' + paths.ACTIVE_INFORMATION}
      component={AccountingActiveInformation}
      onEnter={requireAuthorized}
    />
    <Route path={paths.INTEGRATIONS + '/' + paths.CONTRACT_BOOK} component={ContractBook} onEnter={requireAuthorized} />
    <Route
      path={paths.INTEGRATIONS + '/' + paths.DATA_PROVIDER}
      component={DataIntegration}
      onEnter={requireAuthorized}
    />
    <Route path={paths.INTEGRATIONS + '/' + paths.LOAN} component={LoanService} onEnter={requireAuthorized} />
    <Route path={paths.INTEGRATIONS + '/*'} component={BasicIntegration} onEnter={requireAuthorized} />

    <Route path={paths.IN + '/' + paths.ACCOUNTING} component={InAccounting} onEnter={requireAuthorized} />
    <Route path={paths.IN + '/' + paths.ACCOUNTING + '/*'} component={InAccounting} onEnter={requireAuthorized} />
    <Route path={paths.IN + '/' + paths.TIME_REGISTRATION} component={InTimeRegistration} onEnter={requireAuthorized} />
    <Route
      path={paths.IN + '/' + paths.TIME_REGISTRATION + '/*'}
      component={InTimeRegistration}
      onEnter={requireAuthorized}
    />
    <Route path={paths.IN + '/' + paths.API_KEYS} component={InApiKeys} onEnter={requireAuthorized} />
    <Route path={paths.IN + '/' + paths.WEB_AUTH} component={WebTokenAuth} />
    <Route path={paths.IN + '/' + paths.WEB_CONNECT} component={WebTokenConnect} onEnter={requireAuthorized} />
    <Route path={paths.IN + '/' + paths.USER_CONNECT} component={WebTokenUserConnect} onEnter={requireAuthorized} />
    <Route path={paths.IN + '/' + paths.LOGIN} component={InLogin} />

    <Route path={paths.NOTIFICATIONS} component={Notifications} onEnter={requireAuthorized} />

    <Route path={paths.PAY_ROLLS} component={PayRolls} onEnter={requireAuthorized} />
    <Route path={paths.PAY_ROLLS + '/' + paths.ADD} component={PayRollsAdd} onEnter={requireAuthorized} />
    <Route path={paths.PAY_ROLLS + '/*'} component={PayRollsSingle} onEnter={requireAuthorized} />

    <Route path={paths.APPROVE_TAB} component={ApproveTab} onEnter={requireAuthorized} />
    <Route path={paths.CAR_ALLOWANCE} component={CarAllowance} onEnter={requireAuthorized} />
    <Route path={paths.LEAVE_REGISTRATION} component={LeaveRegistration} onEnter={requireAuthorized} />
    <Route path={paths.LEAVE_REGISTRATION + '/calendar'} component={LeaveRegistration} onEnter={requireAuthorized} />
    <Route path={paths.ONE_TIME_PAYS} component={OneTimePays} onEnter={requireAuthorized} />
    <Route path={paths.TIME_REGISTRATION} component={TimeRegistration} onEnter={requireAuthorized} />
    <Route path={paths.TIME_REGISTRATION + '/calendar'} component={TimeRegistration} onEnter={requireAuthorized} />
    <Route path={paths.SALARY_REGISTRATION} component={SalaryRegistration} onEnter={requireAuthorized} />
    <Route path={paths.WORK_HOURS} component={WorkHours} onEnter={requireAuthorized} />

    <Route path={paths.REIMBURSEMENT_VOUCHERS} component={ReimbursementVouchers} onEnter={requireAuthorized} />
    <Route path={paths.REIMBURSEMENT_VOUCHERS + '/*'} component={ReimbursementVouchers} onEnter={requireAuthorized} />
    <Route path={paths.FREELANCERS_OVERVIEW} component={FreelancersOverview} onEnter={requireAuthorized} />
    <Route path={paths.SWIPE_OVERVIEW} component={SwipeOverview} onEnter={requireAuthorized} />
    <Route path={paths.SWIPE_OVERVIEW + '/*'} component={SwipeOverview} onEnter={requireAuthorized} />

    <Route path={paths.DOCUMENTS} component={Documents} onEnter={requireAuthorized} />
    <Route path={paths.DOCUMENTS + '/*'} component={Documents} onEnter={requireAuthorized} />
    <Route path={paths.ASSETS} component={Assets} onEnter={requireAuthorized} />
    <Route path={paths.PROJECTS} component={Projects} onEnter={requireAuthorized} />

    <Route path={paths.PDF_PREVIEW + '/*/*'} component={PDFPreview} onEnter={requireAuthorized} />

    <Route path={paths.STAGED_IMPORT} component={StagedImport} onEnter={requireAuthorized} />

    <Route path={paths.SSO + '/*'} component={SSO} onEnter={requireAuthorized} />

    <Route path={paths.LOGIN} component={Login} onEnter={requireUnauthorized} />
    <Route path={paths.REGISTER} component={Register} onEnter={requireUnauthorized} />
    <Route path={paths.REQUEST_PASSWORD} component={Login} onEnter={requireUnauthorized} />
    <Route path={paths.RESET_PASSWORD + '/*'} component={ResetPassword} onEnter={requireUnauthorized} />
    <Route path={paths.TERMS} component={TermsContainer} />

    <Route path={paths.PAYROLL_APPROVE_PHONE_SITE} component={PayrollApprovePhonePage} />
    <Route path={paths.DOCUMENT_SIGNING_PHONE_SITE + '/*'} component={DocumentSigningPhonePage} />

    <Route path={paths.PERSON_VERIFICATION} component={PersonVerificationPhonePage} />

    <Route path={paths.IN + '/' + paths.CRIIPTO} component={DocumentSigned} />

    {/*<Route path="*" component={PageNotFound} />*/}
  </Route>
)
