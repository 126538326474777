import React from 'react'

import { fetchEmploymentPositions } from '../api/employment-positions'
import ActionTypes from '../constants/action-types'
import EmploymentPosition from '../model/employmentPosition'
import { EmploymentPositionAction } from '../reducers/employmentPositions'
import { isRequestError } from '../utils/error-utils'

function loadingEmploymentPositions(): EmploymentPositionAction {
  return {
    type: ActionTypes.EMPLOYMENT_POSITIONS_LOADING,
  }
}
function loadedEmploymentPositions(employmentPositions: EmploymentPosition[]): EmploymentPositionAction {
  return {
    type: ActionTypes.EMPLOYMENT_POSITIONS_LOADED,
    employmentPositions,
  }
}
function failedLoadingEmploymentPositions(error: Error): EmploymentPositionAction {
  return {
    type: ActionTypes.EMPLOYMENT_POSITIONS_LOAD_FAILED,
    error,
  }
}

export function getEmploymentPositions() {
  return (dispatch: React.Dispatch<any>): Promise<EmploymentPosition[] | void> => {
    dispatch(loadingEmploymentPositions())
    return fetchEmploymentPositions()
      .then((res) => {
        dispatch(loadedEmploymentPositions(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingEmploymentPositions(e))
        }
      })
  }
}
