import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { fetchCompanyAccountingIntegrationOrganizations } from '../../api/accounting-integration'
import { ExternalOrganization } from '../../api/company-accounting-integration-setup'
import Company from '../../model/company'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyAccountingIntegrationReducer } from '../../reducers/companyAccountingIntegration'
import { paths } from '../../routes'
import { formatError, isRequestError } from '../../utils/error-utils'
import { t, tx } from '../../utils/translation-utils'
import OrganizationForm, { OrganizationFields } from '../company-accounting-integration/OrganizationForm'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  alerts: AlertReducer
  company: Company
  companyAccountingIntegration: CompanyAccountingIntegrationReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  selectCompanyAccountingOrganization: (organizationID: string) => void
}

export default function AccountingOrganizations(props: Props): ReactElement | null {
  const [organizations, setOrganizations] = useState<ExternalOrganization[]>([])
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(true)

  const { company } = props

  useEffect(() => {
    if (organizations.length === 0) {
      fetchCompanyAccountingIntegrationOrganizations(company.id)
        .then((res) => {
          setOrganizations(res.data)
          setLoading(false)
        })
        .catch((e) => {
          if (isRequestError(e)) {
            setError(e)
          }
          setLoading(false)
        })
    }
  }, [organizations, company])

  const { companyAccountingIntegration, addAlert } = props
  const previousCompanyAccountingIntegration = usePrevious(companyAccountingIntegration)

  useEffect(() => {
    if (
      previousCompanyAccountingIntegration &&
      previousCompanyAccountingIntegration.saving &&
      !companyAccountingIntegration.saving
    ) {
      if (!companyAccountingIntegration.error) {
        addAlert('success', t('accounting_tab.organizations.alert.success'), { timeout: 5 })
        jsBrowserHistory.push('/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING)
      }
    }
  }, [previousCompanyAccountingIntegration, companyAccountingIntegration, addAlert])

  const handleSubmit = (values: OrganizationFields) => {
    props.selectCompanyAccountingOrganization(values.organizationID)
  }

  if (loading || companyAccountingIntegration.saving) {
    return <LoadingOverlay />
  }

  const accountingIntegration = companyAccountingIntegration.accountingIntegration

  return (
    <Card>
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      <TitleMenu>
        <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING}>
          <Button>{t('accounting_tab.organizations.back')}</Button>
        </Link>
      </TitleMenu>
      <Title>{t('accounting_tab.organizations.title')}</Title>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      {!accountingIntegration && <p>{t('accounting_tab.organizations.no_integration')}</p>}
      {accountingIntegration && !accountingIntegration.hasOrganizations && (
        <p>{t('accounting_tab.organizations.no_organization')}</p>
      )}
      {accountingIntegration && accountingIntegration.hasOrganizations && organizations.length > 0 && (
        <div>
          <p>{tx('accounting_tab.organizations.has_organization', { name: <strong>{company.name}</strong> })}</p>
          <OrganizationForm
            editing={true}
            organizations={organizations}
            organizationID={accountingIntegration.organizationID}
            onSubmit={handleSubmit}
          />
        </div>
      )}
    </Card>
  )
}
