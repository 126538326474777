import { DataIntegrationParameter } from '../model/availableDataIntegration'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export type DataIntegrationStep = 'NeedKey' | 'NeedAuth' | 'Done'

type DataIntegrationSetupStatus = {
  next: DataIntegrationStep
  state: string
  authURL?: string
}

type InitDataIntegrationSetup = {
  type: string
}

export type DataIntegrationSetup = Partial<Record<DataIntegrationParameter, string>> & {
  next?: DataIntegrationStep
  authParameters?: {
    key: string
    value: string
  }[]
}

export function postCompanyDataIntegrationSetup(
  companyID: string,
  values: InitDataIntegrationSetup
): Promise<RequestResponse<DataIntegrationSetupStatus>> {
  return secureRequest('POST', url('/v2/dataIntegrations/setup', { companyID }), {
    body: values,
  })
}

export function patchCompanyDataIntegrationSetup(
  state: string,
  values: DataIntegrationSetup
): Promise<RequestResponse<DataIntegrationSetupStatus>> {
  return secureRequest('PATCH', url('/v2/dataIntegrations/setup', { state }), {
    body: values,
  })
}
