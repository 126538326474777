import { differenceInMonths, differenceInWeeks } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement } from 'react'

import ContractDelta from '../../model/contractDelta'
import ContractTemplate from '../../model/contractTemplate'
import { ContractTemplateContractContainer } from '../../model/contractTemplateContract'
import Department from '../../model/department'
import EmploymentPosition from '../../model/employmentPosition'
import PensionCompany from '../../model/pensionCompany'
import SalaryCycle from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import { displayContractDelta } from '../../utils/contract-delta-utils'
import { getDate } from '../../utils/date-utils'
import { formatWorkCycle } from '../../utils/day-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import {
  formatEmploymentType,
  formatIncomeType,
  formatLanguage,
  formatLeaveTypeName,
  formatPreferredTaxCardType,
  formatSalaryCycle,
  formatSupplementTypeFullName,
  formatTransferDestinationType,
} from '../../utils/format-utils'
import {
  forceParseInputNumber,
  formatCurrency,
  formatDisplayNumber,
  formatInputNumber,
  formatNumber,
  formatOrdinalNumber,
} from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import Switch from '../elements/switch'

type PartialContractTemplate = Omit<
  ContractTemplate,
  'id' | 'companyID' | 'version' | 'public' | 'active' | 'title' | 'description'
> & {
  title?: string
  description?: string
}

type Props = {
  creating: boolean
  contractTemplate: PartialContractTemplate
  contractTemplateContracts: List<ContractDelta> | List<ContractTemplateContractContainer>
  employmentPositions: List<EmploymentPosition>
  departments: List<Department>
  salaryCycles: List<SalaryCycle>
  salaryTypes: List<SalaryType>
  pensionCompanies: List<PensionCompany>
  handlePrevious?: () => void
}

type FieldFutureContract = {
  included: boolean
  validFrom?: string
}

type Fields = {
  title?: string
  description?: string
  futureContract: FieldFutureContract[]
  salary: { active: boolean }[]
}

export type ContractTemplateResult = {
  title: string
  description: string
  futureContract: {
    included: boolean
    validFrom: number
  }[]
  salary: boolean[]
}

function getContract(delta?: ContractDelta | ContractTemplateContractContainer) {
  if (!delta) {
    return undefined
  }
  if ('templateContract' in delta) {
    return delta.templateContract
  }
  return delta.contract
}

function getFirstContract(deltas: List<ContractDelta> | List<ContractTemplateContractContainer>) {
  const firstDelta = deltas.first()
  if (!firstDelta) {
    return undefined
  }
  return getContract(firstDelta)
}

function ContractTemplateForm(props: Props & FormComponentProps<Fields, ContractTemplateResult>): ReactElement | null {
  const template = props.contractTemplate
  const department =
    template.departmentID && props.departments.find((department) => department.id === template.departmentID)
  const deltas = props.contractTemplateContracts
  const baseContract = getFirstContract(deltas)
  if (!baseContract) {
    return null
  }
  const remuneration = baseContract.remuneration
  let position = baseContract.position
  if (!position && baseContract.employmentPositionID) {
    const p = props.employmentPositions.find((position) => position.id === baseContract.employmentPositionID)
    if (p) {
      position = p.title
    }
  }
  const salaryCycle = baseContract && props.salaryCycles.find((cycle) => cycle.id === baseContract.salaryCycleID)
  if (!salaryCycle) {
    return null
  }
  const periodsType =
    salaryCycle.frequency === 'Monthly'
      ? t('contract_template.form.frequency.monthly')
      : salaryCycle.frequency === 'Weekly'
      ? t('contract_template.form.frequency.weekly')
      : salaryCycle.frequency === 'BiWeekly'
      ? t('contract_template.form.frequency.bi_weekly')
      : t('contract_template.form.frequency.default')

  const { decorateField, decorateAnyField, getAnyFieldValue } = props

  return (
    <div className="contract-template-add-review">
      {decorateField('title', {
        placeholder: t('contract_template.form.title'),
        validate: (val) => {
          if (!val) {
            return t('contract_template.form.title.required')
          }
          return null
        },
      })(<Input />)}
      {decorateField('description', {
        placeholder: t('contract_template.form.description'),
        validate: (val) => {
          if (!val) {
            return t('contract_template.form.description.required')
          }
          return null
        },
      })(<Input />)}
      <Subcard>
        <Subtitle>{t('contract_template.form.fields.employee.title')}</Subtitle>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.employee.employee_type')}</Col>
          <Col span={4}>{formatEmploymentType(template.employeeType)}</Col>
        </Row>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.employee.director')}</Col>
          <Col span={4}>
            {template.affiliationType === 'Director'
              ? t('contract_template.form.true')
              : t('contract_template.form.false')}
          </Col>
        </Row>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.employee.pay_slip_transport')}</Col>
          <Col span={4}>
            {t('contract_template.form.fields.employee.pay_slip_transport.mit_dk')}:{' '}
            {template.paySlipTransportMitDK ? t('contract_template.form.true') : t('contract_template.form.false')}
          </Col>
          <Col span={4}>
            {t('contract_template.form.fields.employee.pay_slip_transport.e_boks')}:{' '}
            {template.paySlipTransportEBoks ? t('contract_template.form.true') : t('contract_template.form.false')}
          </Col>
          <Col span={4}>
            {t('contract_template.form.fields.employee.pay_slip_transport.email')}:{' '}
            {template.paySlipTransportEMail ? t('contract_template.form.true') : t('contract_template.form.false')}
          </Col>
          <Col span={4}>
            {t('contract_template.form.fields.employee.pay_slip_transport.sms')}:{' '}
            {template.paySlipTransportSMS ? t('contract_template.form.true') : t('contract_template.form.false')}
          </Col>
        </Row>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.employee.transport_destination_type')}</Col>
          <Col span={8}>{formatTransferDestinationType(template.transferDestinationType)}</Col>
        </Row>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.employee.language')}</Col>
          <Col span={8}>{formatLanguage(template.language)}</Col>
        </Row>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.employee.preferred_tax_card_type')}</Col>
          <Col span={8}>{formatPreferredTaxCardType(template.preferredTaxCardType)}</Col>
        </Row>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.employee.income_type')}</Col>
          <Col span={8}>{formatIncomeType(template.incomeType)}</Col>
        </Row>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.employee.department')}</Col>
          <Col span={8}>
            {department ? department.name : t('contract_template.form.fields.employee.department.none')}
          </Col>
        </Row>
      </Subcard>
      <Subcard>
        <Subtitle>{t('contract_template.form.fields.contract.title')}</Subtitle>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.contract.position')}</Col>
          <Col span={8}>{position}</Col>
        </Row>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.contract.salary_cycle')}</Col>
          <Col span={8}>{formatSalaryCycle(salaryCycle.frequency, salaryCycle.offset)}</Col>
        </Row>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.contract.weekly_hours')}</Col>
          <Col span={8}>
            {t('contract_template.form.fields.contract.weekly_hours.suffix', { count: baseContract.weeklyHours })}
          </Col>
        </Row>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.contract.work_week')}</Col>
          <Col span={12}>{formatWorkCycle(baseContract.workCycle ?? [], baseContract.workCycleAnchorDate)}</Col>
        </Row>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.contract.car_allowance_registration_method_type')}</Col>
          <Col span={8}>
            {baseContract.carAllowanceRegistrationMethodType === 'Detailed'
              ? t('contract_template.form.fields.contract.car_allowance_registration_method_type.detailed')
              : t('contract_template.form.fields.contract.car_allowance_registration_method_type.coarse')}
          </Col>
        </Row>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.contract.time_registration_method_type')}</Col>
          <Col span={8}>
            {baseContract.timeRegistrationMethodType === 'Detailed'
              ? t('contract_template.form.fields.contract.time_registration_method_type.detailed')
              : t('contract_template.form.fields.contract.time_registration_method_type.coarse')}
          </Col>
        </Row>
        <Row>
          <Col span={6}>{t('contract_template.form.fields.contract.day_laborer')}</Col>
          <Col span={8}>
            {baseContract.dayLaborer === 'None'
              ? t('contract_template.form.false')
              : baseContract.dayLaborer === 'Hours'
              ? t('contract_template.form.fields.contract.day_laborer.hours')
              : t('contract_template.form.fields.contract.day_laborer.days')}
          </Col>
        </Row>
      </Subcard>
      <Subcard>
        <Subtitle>{t('contract_template.form.fields.remuneration.title')}</Subtitle>
        {props.creating && (
          <p>
            {tx('contract_template.form.fields.remuneration.intro', {
              not: <em>{t('contract_template.form.fields.remuneration.intro.not')}</em>,
            })}
          </p>
        )}
        <Row>
          <Col span={6}>{t('contract_template.form.fields.remuneration.header.type')}</Col>
          <Col span={4}>{t('contract_template.form.fields.remuneration.header.rate')}</Col>
          {props.creating && <Col span={4}>{t('contract_template.form.fields.remuneration.header.include')}</Col>}
        </Row>
        {remuneration &&
          remuneration.salary.map((salary, i) => {
            return (
              <Row key={salary.salaryTypeID}>
                <Col span={6}>{salary.title}</Col>
                <Col span={4}>{formatCurrency(salary.rate, 2)}</Col>
                {props.creating && (
                  <Col span={4}>
                    {decorateAnyField(`salary.${i}.active`, {
                      skipWrapper: true,
                      skipLabel: true,
                      valueOnChecked: true,
                      noBlur: true,
                    })(<Switch />)}
                  </Col>
                )}
              </Row>
            )
          })}
      </Subcard>
      <Subcard>
        <Subtitle>{t('contract_template.form.fields.benefits.title')}</Subtitle>
        {(!remuneration || remuneration.benefits.length === 0) && (
          <p className="contract-template-none">{t('contract_template.form.fields.benefits.none')}</p>
        )}
        {remuneration &&
          remuneration.benefits.map((benefit) => {
            return (
              <Row key={benefit.id}>
                <Col span={6}>{benefit.title}</Col>
                <Col span={12}>
                  {t('contract_template.form.fields.benefits.value')}: {formatCurrency(benefit.amount)}
                </Col>
              </Row>
            )
          })}
      </Subcard>
      <Subcard>
        <Subtitle>{t('contract_template.form.fields.leave.title')}</Subtitle>
        {(!remuneration || remuneration.leave.length === 0) && (
          <p className="contract-template-none">{t('contract_template.form.fields.leave.none')}</p>
        )}
        {remuneration &&
          remuneration.leave.map((leave) => {
            if (!leave.type) {
              return null
            }
            return (
              <Row key={leave.id}>
                <Col span={6}>{formatLeaveTypeName(leave.type.name)}</Col>
                <Col span={8}>{t('unit.day_count', { count: leave.days })}</Col>
              </Row>
            )
          })}
      </Subcard>
      <Subcard>
        <Subtitle>{t('contract_template.form.fields.pension.title')}</Subtitle>
        {(!remuneration || remuneration.pension.length === 0) && (
          <p className="contract-template-none">{t('contract_template.form.fields.pension.none')}</p>
        )}
        {remuneration &&
          remuneration.pension.map((pension) => {
            return (
              <Row key={pension.id}>
                <Col span={6}>{pension.title}</Col>
                <Col span={8}>
                  {pension.percentage
                    ? formatNumber(pension.percentage, 2) + ' %'
                    : pension.fixedAmount
                    ? formatCurrency(pension.fixedAmount, 2)
                    : ''}
                </Col>
              </Row>
            )
          })}
      </Subcard>
      <Subcard>
        <Subtitle>{t('contract_template.form.fields.supplements.title')}</Subtitle>
        {(!remuneration || remuneration.supplements.length === 0) && (
          <p className="contract-template-none">{t('contract_template.form.fields.supplements.none')}</p>
        )}
        {remuneration &&
          remuneration.supplements.map((supplement) => {
            if (!supplement.type) {
              return null
            }
            return (
              <Row key={supplement.id}>
                <Col span={6}>{formatSupplementTypeFullName(supplement.type.name)}</Col>
                <Col span={8}>
                  {supplement.compensationRate < 1.0
                    ? formatNumber(supplement.compensationRate * 100, 2) + ' %'
                    : formatCurrency(supplement.compensationRate, 2)}
                </Col>
              </Row>
            )
          })}
      </Subcard>

      {deltas.size > 1 && (
        <Subcard>
          <Subtitle>{t('contract_template.form.future.title')}</Subtitle>
          <p>{t('contract_template.form.future.intro')}</p>
          <Row>
            <Col span={16}>{t('contract_template.form.future.header.changes')}</Col>
            <Col span={8}>{t('contract_template.form.future.header.due_date')}</Col>
          </Row>
          {deltas.map((delta, i) => {
            if (i === 0) {
              return null
            }
            const changes = displayContractDelta(
              delta,
              props.salaryCycles.toArray(),
              props.salaryTypes.toArray(),
              props.pensionCompanies.toArray()
            ).filter((change) => {
              return !(
                change.field === 'salary' &&
                remuneration &&
                !remuneration.salary.some(
                  (salary, k) => salary.salaryTypeID === change.salaryTypeID && getAnyFieldValue(`salary.${k}.active`)
                )
              )
            })
            const contract = getContract(delta)
            if (!contract) {
              return null
            }
            return (
              <Row key={`futureContract-${i}`}>
                <Col span={16}>
                  <strong>
                    {t('contract_template.form.future.change.title', {
                      number: formatOrdinalNumber(i),
                    })}
                  </strong>
                  <ul>
                    {changes.map((change, j) => {
                      switch (change.type) {
                        case 'change': {
                          let text = t('contract_template.form.future.change.type.base')
                          let value = change.value
                          if (change.increase) {
                            text = t('contract_template.form.future.change.type.increase')
                            value = '+' + value
                          }
                          if (change.decrease) {
                            text = t('contract_template.form.future.change.type.decrease')
                          }
                          return (
                            <li key={`change-${i}-${j}`}>
                              {text}: {change.message} {value}
                            </li>
                          )
                        }
                        case 'remove':
                          return (
                            <li key={`change-${i}-${j}`}>
                              {t('contract_template.form.future.change.type.remove')}: {change.message}
                            </li>
                          )
                        default:
                          return null
                      }
                    })}
                  </ul>
                </Col>
                <Col span={8}>
                  {props.creating && (
                    <div className="ant-switch-wrapper">
                      {decorateAnyField(`futureContract.${i}.included`, {
                        skipWrapper: true,
                        skipLabel: true,
                        valueOnChecked: true,
                        noBlur: true,
                      })(<Switch disabled={changes.length === 0} />)}
                      <span className="ant-switch-text">{t('contract_template.form.future.change.include')}</span>
                    </div>
                  )}
                  {props.creating &&
                    getAnyFieldValue(`futureContract.${i}.included`) &&
                    decorateAnyField(`futureContract.${i}.validFrom`, {
                      placeholder: t('contract_template.form.future.change.valid_from'),
                      suffix: periodsType,
                      validate: (val) => {
                        if (!val) {
                          return t('contract_template.form.future.change.valid_from.required')
                        }
                        if (!val.match(/^[0-9]+$/)) {
                          return t('contract_template.form.future.change.valid_from.invalid')
                        }
                        return null
                      },
                    })(<Input />)}
                  {!props.creating && (
                    <>
                      {t(
                        i === 1
                          ? 'contract_template.form.future.change.valid_from.note.from_employment'
                          : 'contract_template.form.future.change.valid_from.note.from_last_change',
                        { number: formatDisplayNumber(forceParseInputNumber(contract.validFrom)), period: periodsType }
                      )}
                    </>
                  )}
                </Col>
              </Row>
            )
          })}{' '}
        </Subcard>
      )}

      <div style={{ marginTop: '15px' }}>
        <Button htmlType="submit" type="secondary">
          {props.creating ? t('contract_template.form.submit.create') : t('contract_template.form.submit.update')}
        </Button>
        {props.creating && <Button onClick={props.handlePrevious}>{t('contract_template.form.back')}</Button>}
      </div>
    </div>
  )
}

export default withValidations<Props, Fields, ContractTemplateResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      title: props.contractTemplate?.title,
      description: props.contractTemplate?.description,
      futureContract: [],
      salary: [],
    }
    if (props.creating) {
      const firstContract = getFirstContract(props.contractTemplateContracts)
      if (firstContract && firstContract.remuneration) {
        fields.salary = firstContract.remuneration.salary.map(() => ({
          active: true,
        }))
      }
      const salaryCycle = props.salaryCycles.find((cycle) => cycle.id === firstContract?.salaryCycleID)
      if (salaryCycle) {
        // they are only ContractDelta in this context
        props.contractTemplateContracts.forEach((delta, i) => {
          if (!('validFrom' in delta)) {
            return
          }
          if (i === 0) {
            fields.futureContract.push({ included: true }) // ignored anyway
            return
          }
          const item: FieldFutureContract = { included: true, validFrom: undefined }
          let diff
          const previousDelta = props.contractTemplateContracts.get(i - 1)! as ContractDelta
          switch (salaryCycle.frequency) {
            case 'Monthly':
              diff = differenceInMonths(getDate(previousDelta.validFrom), getDate(delta.validFrom))
              break
            case 'BiWeekly':
              diff = differenceInWeeks(getDate(previousDelta.validFrom), getDate(delta.validFrom)) / 2
              break
            case 'Weekly':
              diff = differenceInWeeks(getDate(previousDelta.validFrom), getDate(delta.validFrom))
              break
            default:
              break
          }
          if (diff) {
            item.validFrom = formatInputNumber(Math.abs(diff))
          }
          fields.futureContract.push(item)
        })
      }
    }
    return fields
  },
  onChange: (key, val, allValues, _, props) => {
    const values = {}
    setByPath(values, key, val)
    if (key.match(/salary-[0-9]+/)) {
      if (props.contractTemplateContracts.size > 1) {
        setByPath(allValues, key, val) // make sure it is set on allValues when we check
        const remuneration = getFirstContract(props.contractTemplateContracts)?.remuneration
        props.contractTemplateContracts.forEach((delta, i) => {
          if (i === 0) {
            return
          }
          const changes = displayContractDelta(
            delta,
            props.salaryCycles.toArray(),
            props.salaryTypes.toArray(),
            props.pensionCompanies.toArray()
          ).filter((change) => {
            return !(
              change.field === 'salary' &&
              remuneration &&
              !remuneration.salary.some(
                (salary, j) => salary.salaryTypeID === change.salaryTypeID && allValues.salary[j].active
              )
            )
          })
          if (changes.length === 0) {
            setByPath(values, `futureContract.${i}.included`, false)
          }
        })
      }
    }
    return values
  },
  onSubmit: (values) => ({
    title: values.title!,
    description: values.description!,
    salary: values.salary.map((item) => item.active),
    futureContract: values.futureContract.map((futureContract) => ({
      ...futureContract,
      validFrom: forceParseInputNumber(futureContract.validFrom),
    })),
  }),
})(ContractTemplateForm)
