import { List } from 'immutable'
import React, { ReactElement, useEffect } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../../actions/alerts'
import { EmployeeConversionType } from '../../../api/employees'
import Company from '../../../model/company'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Department, { DepartmentCreationFields } from '../../../model/department'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import { ContractReducer } from '../../../reducers/contracts'
import { DepartmentReducer } from '../../../reducers/departments'
import { EmployeeReducer } from '../../../reducers/employees'
import { getDate, isTimeAfter } from '../../../utils/date-utils'
import { t } from '../../../utils/translation-utils'
import NoEmploymentCard from '../../employees-single/NoEmploymentCard'
import NoContractCard from '../NoContractCard'
import EmploymentDetailsCard from './EmploymentDetailsCard'
import EmploymentPayCard from './EmploymentPayCard'

type Props = {
  employee: Employee
  company: Company
  contracts: ContractReducer
  departments: DepartmentReducer
  employees: EmployeeReducer
  viewingContract?: Contract
  isDepartmentManager: boolean
  salaryCycles: List<SalaryCycle>
  canEditObjects: boolean
  canHireFire: boolean

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
  getDepartments: () => void
  updateEmployee: (employee: Employee) => void
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
  convertEmployee: (employeeID: string, toType: EmployeeConversionType) => Promise<Employee | void>
}

export default function EmploymentTab(props: Props): ReactElement | null {
  const { departments, getDepartments } = props
  useEffect(() => {
    if (!departments.loaded && !departments.loading) {
      getDepartments()
    }
  })

  const { contracts, addAlert, employee } = props
  const previousContracts = usePrevious(contracts)

  useEffect(() => {
    if (previousContracts && previousContracts.saving && !contracts.saving) {
      if (!contracts.error) {
        addAlert('success', t('freelancer.employment.alert.success', { name: employee.name }), { timeout: 5 })
      }
    }
  })

  const viewingContract = props.viewingContract || props.employee.activeContract
  const isMutableContract = () => {
    if (!props.employee.earliestMutableContract || !props.canEditObjects || !viewingContract) {
      return false
    }
    return (
      viewingContract.id === props.employee.earliestMutableContract.id ||
      isTimeAfter(getDate(viewingContract.validFrom), getDate(props.employee.earliestMutableContract.validTo))
    )
  }

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (!viewingContract) {
    return (
      <NoContractCard
        addAlert={props.addAlert}
        employee={props.employee}
        employment={props.employee.activeEmployment}
        salaryCycles={props.salaryCycles}
        company={props.company}
        addContract={props.addContract}
      />
    )
  }
  // mutableContract down the line refers to the viewingContract if isMutableContract is true
  return (
    <div className="employees-single-employment-tab">
      <EmploymentDetailsCard
        mutableContract={viewingContract}
        employee={props.employee}
        departments={props.departments}
        employees={props.employees}
        isDepartmentManager={props.isDepartmentManager}
        canEditObjects={props.canEditObjects}
        canHireFire={props.canHireFire}
        isMutableContract={isMutableContract()}
        updateEmployee={props.updateEmployee}
        addDepartment={props.addDepartment}
        convertEmployee={props.convertEmployee}
      />
      <EmploymentPayCard
        mutableContract={viewingContract}
        employee={props.employee}
        contracts={props.contracts}
        company={props.company}
        salaryCycles={props.salaryCycles}
        isMutableContract={isMutableContract()}
        addContract={props.addContract}
        updateContract={props.updateContract}
      />
    </div>
  )
}
