import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getAsynchronousTasks, startOneTimePaysImport, storeStagedImportData } from '../actions/asynchronous-tasks'
import { getSupplementTypes } from '../actions/companies'
import { getRemuneration } from '../actions/contracts'
import { getLeaveBalances } from '../actions/leave-balances'
import { getLeaveTypes } from '../actions/leave-types'
import { addOneTimePay, approveOneTimePays, getOneTimePays, updateOneTimePay } from '../actions/one-time-pays'
import { getSalaryCycles } from '../actions/salary-cycles'
import { getSupplementBalances } from '../actions/supplement-balances'
import OneTimePaysComponent from '../components/one-time-pays/OneTimePays'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import AsynchronousTask from '../model/asynchronousTask'
import { OneTimePayCreationFields, OneTimePayMutableFields, OneTimePayType } from '../model/oneTimePay'
import { AlertReducer } from '../reducers/alerts'
import { AsynchronousTaskReducer } from '../reducers/asynchronousTasks'
import { CompanyReducer } from '../reducers/companies'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { CostCenterReducer } from '../reducers/costCenters'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employees'
import { LeaveBalanceReducer } from '../reducers/leaveBalances'
import { LeaveTypeReducer } from '../reducers/leaveTypes'
import { OneTimePayReducer } from '../reducers/oneTimePays'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { SupplementBalanceReducer } from '../reducers/supplementBalances'
import { SupplementTypeReducer } from '../reducers/supplementTypes'
import { UserReducer } from '../reducers/user'
import { formatLoadingText } from '../utils/loading-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import { t } from '../utils/translation-utils'
import RegistrationLayout from './layouts/RegistrationLayout'

type Reducers = {
  alerts: AlertReducer
  asynchronousTasks: AsynchronousTaskReducer
  companies: CompanyReducer
  employees: EmployeeReducer
  oneTimePays: OneTimePayReducer
  salaryCycles: SalaryCycleReducer
  supplementBalances: SupplementBalanceReducer
  supplementTypes: SupplementTypeReducer
  leaveBalances: LeaveBalanceReducer
  leaveTypes: LeaveTypeReducer
  costCenters: CostCenterReducer
  departments: DepartmentReducer
  companyUsers: CompanyUserReducer
  user: UserReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getRemuneration: (contractID: string) => void
  getOneTimePays: (companyID?: string, employeeID?: string) => void
  approveOneTimePays: (oneTimePayIDs: string[]) => void
  addOneTimePay: (employeeID: string, oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, oneTimePay: OneTimePayMutableFields) => void
  getSalaryCycles: () => void
  getAsynchronousTasks: (companyID: string) => void
  getSupplementTypes: () => void
  getSupplementBalances: () => void
  getLeaveTypes: () => void
  getLeaveBalances: () => void
  startOneTimePaysImport: (fileID: string, oneTimePayType?: OneTimePayType) => Promise<AsynchronousTask | void>
  storeStagedImportData: (asynchronousTaskID: string) => Promise<AsynchronousTask | void>
}

function OneTimePays(props: Reducers & Actions & RouteProps): ReactElement | null {
  const {
    companies,
    oneTimePays,
    getOneTimePays,
    salaryCycles,
    getSalaryCycles,
    supplementTypes,
    getSupplementTypes,
    leaveTypes,
    getLeaveTypes,
    asynchronousTasks,
    getAsynchronousTasks,
  } = props

  useEffect(() => {
    const companyID = companies.company?.id
    if (!companyID) {
      return
    }
    if (oneTimePays.companyID !== companyID || (!oneTimePays.loading && !oneTimePays.loaded)) {
      getOneTimePays(companyID)
    }
    if (!salaryCycles.loading && !salaryCycles.loaded) {
      getSalaryCycles()
    }
    if (!supplementTypes.loading && !supplementTypes.loaded) {
      getSupplementTypes()
    }
    if (!leaveTypes.loading && !leaveTypes.loaded) {
      getLeaveTypes()
    }
    if (!asynchronousTasks.loading && !asynchronousTasks.loaded) {
      getAsynchronousTasks(companyID)
    }
  })

  const company = companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  if (!props.oneTimePays.loaded || !props.salaryCycles.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            { loading: !props.oneTimePays.loaded, text: t('loading.reducer.one_time_pays') },
            { loading: !props.salaryCycles.loaded, text: t('loading.reducer.salary_cycles') },
          ])}
        />
      </div>
    )
  }

  return (
    <RegistrationLayout route={props.route}>
      <OneTimePaysComponent
        alerts={props.alerts}
        asynchronousTasks={props.asynchronousTasks}
        company={company}
        employees={props.employees}
        oneTimePays={props.oneTimePays}
        salaryCycles={props.salaryCycles.salaryCycles}
        supplementBalances={props.supplementBalances}
        supplementTypes={props.supplementTypes.supplementTypes}
        leaveBalances={props.leaveBalances}
        leaveTypes={props.leaveTypes.leaveTypes}
        costCenterAccounting={company.costCenterAccounting}
        costCenters={props.costCenters.costCenters}
        departments={props.departments.departments}
        companyUser={props.companyUsers.companyUser}
        user={props.user}
        getRemuneration={props.getRemuneration}
        addOneTimePay={props.addOneTimePay}
        approveOneTimePays={props.approveOneTimePays}
        updateOneTimePay={props.updateOneTimePay}
        startOneTimePaysImport={props.startOneTimePaysImport}
        storeStagedImportData={props.storeStagedImportData}
        getSupplementBalances={props.getSupplementBalances}
        getLeaveBalances={props.getLeaveBalances}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
      />
    </RegistrationLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    asynchronousTasks: state.asynchronousTasks,
    companies: state.companies,
    employees: state.employees,
    oneTimePays: state.oneTimePays,
    salaryCycles: state.salaryCycles,
    supplementBalances: state.supplementBalances,
    supplementTypes: state.supplementTypes,
    leaveBalances: state.leaveBalances,
    leaveTypes: state.leaveTypes,
    costCenters: state.costCenters,
    departments: state.departments,
    companyUsers: state.companyUsers,
    user: state.user,
  }),
  {
    getRemuneration: getRemuneration,
    getOneTimePays: getOneTimePays,
    addOneTimePay: addOneTimePay,
    approveOneTimePays: approveOneTimePays,
    updateOneTimePay: updateOneTimePay,
    getSalaryCycles: getSalaryCycles,
    getSupplementBalances: getSupplementBalances,
    getLeaveBalances: getLeaveBalances,
    getSupplementTypes: getSupplementTypes,
    getLeaveTypes: getLeaveTypes,
    getAsynchronousTasks: getAsynchronousTasks,
    addAlert: addAlert,
    removeAlert: removeAlert,
    startOneTimePaysImport: startOneTimePaysImport,
    storeStagedImportData: storeStagedImportData,
  }
)(OneTimePays)
