import RcSwitch from 'rc-switch'
import React, { CSSProperties, ReactElement, ReactNode } from 'react'

import classNames from '../../antd/_util/classNames'

import './style/css'

type Props = {
  prefixCls?: string
  size?: 'small' | 'default'
  className?: string
  style?: CSSProperties
  checked?: boolean
  onChange?: (checked: boolean) => void
  checkedChildren?: ReactNode
  unCheckedChildren?: ReactNode
  disabled?: boolean
  id?: string
}

export default function Switch(props: Props): ReactElement | null {
  const { prefixCls = 'ant-switch', size, className = '' } = props
  const classes = classNames(className, {
    [`${prefixCls}-small`]: size === 'small',
  })
  return <RcSwitch {...props} prefixCls={prefixCls} className={classes} />
}
