import React, { ReactElement } from 'react'

import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'

import './ResponsiveNoteModal.css'

type Props = {
  closeModal: () => void
}

export default function ResponsiveNoteModal(props: Props): ReactElement | null {
  return (
    <Card className="responsive-note">
      <Title>{t('responsive_note_modal.title')}</Title>
      <p>{t('responsive_note_modal.text.line_1')}</p>
      <p>{t('responsive_note_modal.text.line_2')}</p>
      <Button onClick={() => props.closeModal()}>{t('responsive_note_modal.ok')}</Button>
    </Card>
  )
}
