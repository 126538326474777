import VacationCalendar from '../model/vacationCalendar'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchVacationCalendar(companyID: string, year?: number): Promise<RequestResponse<VacationCalendar>> {
  return secureRequest('GET', url('/v2/companies/' + companyID + '/vacation', { year }))
}

export function postVacationCalendar(
  companyID: string,
  vacationCalendar: VacationCalendar
): Promise<RequestResponse<VacationCalendar>> {
  return secureRequest('POST', url('/v2/companies/' + companyID + '/vacation'), {
    body: vacationCalendar,
  })
}
