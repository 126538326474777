import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCompanyLogo(companyID: string): Promise<RequestResponse<string>> {
  return secureRequest('GET', url('/v2/companyLogos/' + companyID))
}

export type CompanyLogoCreate = {
  fileID: string
}

export function putCompanyLogo(companyID: string, image: CompanyLogoCreate): Promise<RequestResponse<string>> {
  return secureRequest('PUT', url('/v2/companyLogos/' + companyID), {
    body: { fileID: image.fileID },
  })
}

export function delCompanyLogo(companyID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/companyLogos/' + companyID))
}
