import TimeRegistrationTemplate, { TimeRegistrationTemplateCreationFields } from '../model/timeRegistrationTemplate'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchTimeRegistrationTemplates(
  companyID: string
): Promise<RequestResponse<TimeRegistrationTemplate[]>> {
  return secureRequest('GET', url('/v2/timeRegistrationTemplates', { companyID }))
}

export function postTimeRegistrationTemplates(
  template: TimeRegistrationTemplateCreationFields
): Promise<RequestResponse<TimeRegistrationTemplate>> {
  return secureRequest('POST', url('/v2/timeRegistrationTemplates'), {
    body: {
      companyID: template.companyID,
      employeeID: template.employeeID,
      class: template.class,
      lines: template.lines,
    },
  })
}

export function delTimeRegistrationTemplates(
  companyID: string | undefined,
  employeeID: string | undefined
): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/timeRegistrationTemplates', { companyID, employeeID }))
}
