import React from 'react'

import {
  delCompanyGroupUserInvite,
  fetchCompanyGroupUserInvites,
  postUserInviteCompanyGroup,
} from '../api/company-users'
import ActionTypes from '../constants/action-types'
import { GroupUserType } from '../model/companyGroup'
import { UserPermission } from '../model/companyUser'
import UserInvite from '../model/userInvite'
import { UserInviteCompanyGroupAction } from '../reducers/userInviteCompanyGroups'
import { isRequestError } from '../utils/error-utils'

function loadingCompanyGroupUserInvites(): UserInviteCompanyGroupAction {
  return {
    type: ActionTypes.USER_COMPANY_GROUP_INVITES_LOADING,
  }
}
function loadedCompanyGroupUserInvites(
  companyGroupID: string,
  userInvites: UserInvite[]
): UserInviteCompanyGroupAction {
  return {
    type: ActionTypes.USER_COMPANY_GROUP_INVITES_LOADED,
    companyGroupID,
    userInvites,
  }
}
function failedLoadingCompanyGroupUserInvites(companyGroupID: string, error: Error): UserInviteCompanyGroupAction {
  return {
    type: ActionTypes.USER_COMPANY_GROUP_INVITES_LOAD_FAILED,
    error,
    companyGroupID,
  }
}

function addingCompanyGroupUserInvite(): UserInviteCompanyGroupAction {
  return {
    type: ActionTypes.USER_COMPANY_GROUP_INVITE_ADDING,
  }
}
export function addedCompanyGroupUserInvite(
  companyGroupID: string,
  userInvite: UserInvite
): UserInviteCompanyGroupAction {
  return {
    type: ActionTypes.USER_COMPANY_GROUP_INVITE_ADDED,
    companyGroupID,
    userInvite,
  }
}
function failedAddingCompanyGroupUserInvite(companyGroupID: string, error: Error): UserInviteCompanyGroupAction {
  return {
    type: ActionTypes.USER_COMPANY_GROUP_INVITE_ADD_FAILED,
    error,
    companyGroupID,
  }
}

function deletingCompanyGroupUserInvite(companyGroupID: string, email: string): UserInviteCompanyGroupAction {
  return {
    type: ActionTypes.USER_COMPANY_GROUP_INVITE_DELETING,
    companyGroupID,
    email,
  }
}
export function deletedCompanyGroupUserInvite(companyGroupID: string, email: string): UserInviteCompanyGroupAction {
  return {
    type: ActionTypes.USER_COMPANY_GROUP_INVITE_DELETED,
    companyGroupID,
    email,
  }
}
function failedDeletingCompanyGroupUserInvite(
  companyGroupID: string,
  email: string,
  error: Error
): UserInviteCompanyGroupAction {
  return {
    type: ActionTypes.USER_COMPANY_GROUP_INVITE_DELETE_FAILED,
    error,
    companyGroupID,
    email,
  }
}

export function getCompanyGroupUserInvites(companyGroupID: string) {
  return (dispatch: React.Dispatch<any>): Promise<UserInvite[] | void> => {
    dispatch(loadingCompanyGroupUserInvites())
    return fetchCompanyGroupUserInvites(companyGroupID)
      .then((res) => {
        dispatch(loadedCompanyGroupUserInvites(companyGroupID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompanyGroupUserInvites(companyGroupID, e))
        }
      })
  }
}

export function addCompanyGroupUserInvite(
  companyGroupID: string,
  email: string,
  userGroupType: GroupUserType,
  permissions: UserPermission[] = []
) {
  return (dispatch: React.Dispatch<any>): Promise<UserInvite | void> => {
    dispatch(addingCompanyGroupUserInvite())
    return postUserInviteCompanyGroup(companyGroupID, email, userGroupType, permissions)
      .then((res) => {
        dispatch(addedCompanyGroupUserInvite(companyGroupID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingCompanyGroupUserInvite(companyGroupID, e))
        }
      })
  }
}

export function deleteCompanyGroupUserInvite(companyGroupID: string, email: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(deletingCompanyGroupUserInvite(companyGroupID, email))
    return delCompanyGroupUserInvite(companyGroupID, email)
      .then(() => {
        dispatch(deletedCompanyGroupUserInvite(companyGroupID, email))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingCompanyGroupUserInvite(companyGroupID, email, e))
        }
      })
  }
}
