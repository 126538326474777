import { addYears, startOfYear, subYears } from 'date-fns'
import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { disableCompanySettings, enableCompanySettings } from '../actions/companies'
import {
  deleteTimeRegistrationTemplate,
  getTimeRegistrationTemplates,
  saveTimeRegistrationTemplates,
} from '../actions/time-registration-templates'
import { deleteTimeRegistrationBulk, getTimeRegistrations } from '../actions/time-registrations'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import WorkHoursComponent from '../components/work-hours/WorkHours'
import Company from '../model/company'
import CompanySetting from '../model/companySetting'
import { TimeRegistrationClass } from '../model/timeRegistration'
import TimeRegistrationTemplate, { TimeRegistrationTemplateCreationFields } from '../model/timeRegistrationTemplate'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employees'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { TimeRegistrationTemplateReducer } from '../reducers/timeRegistrationTemplates'
import { UserReducer } from '../reducers/user'
import { formatAPIDate, getDate } from '../utils/date-utils'
import { formatLoadingText } from '../utils/loading-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import { t } from '../utils/translation-utils'
import RegistrationLayout from './layouts/RegistrationLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  companyFeatures: CompanyFeatureReducer
  departments: DepartmentReducer
  employees: EmployeeReducer
  timeRegistrations: TimeRegistrationReducer
  timeRegistrationTemplates: TimeRegistrationTemplateReducer
  user: UserReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getTimeRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    from: DateFormat,
    to: DateFormat
  ) => void
  enableCompanySettings: (companyID: string, enable: CompanySetting[]) => Promise<Company | void>
  disableCompanySettings: (companyID: string, disable: CompanySetting[]) => Promise<Company | void>
  getTimeRegistrationTemplates: (companyID: string) => void
  saveTimeRegistrationTemplates: (
    template: TimeRegistrationTemplateCreationFields
  ) => Promise<TimeRegistrationTemplate | void>
  deleteTimeRegistrationTemplate: (
    companyID: string | undefined,
    employeeID: string | undefined
  ) => Promise<boolean | void>
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    timeRegistrationClass: TimeRegistrationClass,
    deleteAll: boolean
  ) => Promise<boolean | void>
}

function WorkHours(props: Reducers & Actions & RouteProps): ReactElement | null {
  const company = props.companies.company
  const { timeRegistrations, getTimeRegistrations } = props
  useEffect(() => {
    const companyID = company?.id
    if (
      companyID &&
      (timeRegistrations.companyID !== companyID ||
        !!timeRegistrations.employeeID ||
        (!timeRegistrations.loading && !timeRegistrations.loaded))
    ) {
      getTimeRegistrations(
        companyID,
        undefined,
        undefined,
        formatAPIDate(startOfYear(subYears(getDate(), 2))),
        formatAPIDate(addYears(getDate(), 10))
      )
    }
  })

  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  const loading = props.timeRegistrations.loading && !props.timeRegistrations.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            {
              loading: props.timeRegistrations.loading && !props.timeRegistrations.loaded,
              text: t('loading.reducer.time_registrations'),
            },
          ])}
        />
      </div>
    )
  }

  return (
    <RegistrationLayout route={props.route}>
      <WorkHoursComponent
        alerts={props.alerts}
        company={company}
        companyUsers={props.companyUsers}
        departments={props.departments.departments}
        employees={props.employees.employees}
        timeRegistrations={props.timeRegistrations}
        timeRegistrationTemplates={props.timeRegistrationTemplates}
        companyFeatures={props.companyFeatures.companyFeatures}
        user={props.user}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        enableCompanySettings={props.enableCompanySettings}
        disableCompanySettings={props.disableCompanySettings}
        getTimeRegistrationTemplates={props.getTimeRegistrationTemplates}
        saveTimeRegistrationTemplates={props.saveTimeRegistrationTemplates}
        deleteTimeRegistrationTemplate={props.deleteTimeRegistrationTemplate}
        deleteTimeRegistrationBulk={props.deleteTimeRegistrationBulk}
      />
    </RegistrationLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    companyUsers: state.companyUsers,
    companyFeatures: state.companyFeatures,
    departments: state.departments,
    employees: state.employees,
    timeRegistrations: state.timeRegistrations,
    timeRegistrationTemplates: state.timeRegistrationTemplates,
    user: state.user,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getTimeRegistrations: getTimeRegistrations,
    enableCompanySettings: enableCompanySettings,
    disableCompanySettings: disableCompanySettings,
    getTimeRegistrationTemplates: getTimeRegistrationTemplates,
    saveTimeRegistrationTemplates: saveTimeRegistrationTemplates,
    deleteTimeRegistrationTemplate: deleteTimeRegistrationTemplate,
    deleteTimeRegistrationBulk: deleteTimeRegistrationBulk,
  }
)(WorkHours)
