import SupplementBalance from '../model/supplementBalance'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchSupplementBalances(
  companyID: string | undefined,
  employeeID: string | undefined,
  limit: number,
  offset: number
): Promise<RequestResponse<SupplementBalance[]>> {
  return secureRequest('GET', url('/v2/supplementBalances', { companyID, employeeID, limit, offset }))
}
