import React, { ReactElement } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { deleteAllWarnings, deleteWarning } from '../actions/warnings'
import NotificationsComponent from '../components/notifications/Notifications'
import { AlertReducer } from '../reducers/alerts'
import { EmployeeReducer } from '../reducers/employees'
import { WarningReducer } from '../reducers/warnings'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  alerts: AlertReducer
  warnings: WarningReducer
  employees: EmployeeReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  deleteWarning: (id: string) => void
  deleteAllWarnings: () => void
}

function Notifications(props: Reducers & Actions): ReactElement | null {
  return (
    <NotificationsComponent
      alerts={props.alerts}
      warnings={props.warnings.warnings}
      employees={props.employees.employees}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      deleteWarning={props.deleteWarning}
      deleteAllWarnings={props.deleteAllWarnings}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    alerts: state.alerts,
    warnings: state.warnings,
    employees: state.employees,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    deleteWarning: deleteWarning,
    deleteAllWarnings: deleteAllWarnings,
  }
)(Notifications)
