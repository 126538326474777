import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Warning from '../model/warning'
import { ReducerAction } from '../utils/reducer-utils'

export interface WarningReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  warnings: List<Warning>
  error: Error | null
}

const Params = Record<WarningReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  warnings: List<Warning>(),
  error: null,
})

export interface WarningAction extends ReducerAction {
  companyID?: string
  warnings?: Warning[]
  warning?: Warning
  warningID?: string
}

const comparator = (a: Warning, b: Warning) => {
  return b.createdAt.localeCompare(a.createdAt, 'da-dk')
}

export default (
  state: Record<WarningReducer> = Params(),
  action: WarningAction = { type: '' }
): Record<WarningReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.WARNINGS_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.WARNINGS_LOADED:
    case ActionTypes.WARNINGS_LOADED_PARTIAL:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        loading: action.type === ActionTypes.WARNINGS_LOADED_PARTIAL,
        warnings: state.get('warnings').concat(List(action.warnings)).sort(comparator),
      })
    case ActionTypes.WARNINGS_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.WARNING_ADDED:
      if (!action.warning) {
        return state
      }
      idx = state.get('warnings').findIndex((item) => !!action.warning && item.id === action.warning.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('warnings', state.get('warnings').set(idx, action.warning).sort(comparator))
      }
      return state.set('saving', false).set('warnings', state.get('warnings').push(action.warning))
    case ActionTypes.WARNING_UPDATED:
      if (!action.warning) {
        return state
      }
      idx = state.get('warnings').findIndex((item) => item.id === action.warningID)
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('warnings', state.get('warnings').set(idx, action.warning))

    case ActionTypes.WARNING_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.WARNING_DELETED:
      idx = state.get('warnings').findIndex((item) => item.id === action.warningID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('warnings', state.get('warnings').delete(idx))
    case ActionTypes.WARNING_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
