export default {
  Calendar: {
    lang: {
      placeholder: 'Select date',
      timePlaceholder: 'Select time',
      locale: 'en_GB',
      today: 'Today',
      now: 'Now',
      backToToday: 'Back to today',
      ok: 'Ok',
      clear: 'Clear',
      month: 'Month',
      year: 'Year',
      timeSelect: 'select time',
      dateSelect: 'select date',
      weekSelect: 'Choose a week',
      monthSelect: 'Choose a month',
      yearSelect: 'Choose a year',
      decadeSelect: 'Choose a decade',
      yearFormat: 'YYYY',
      dateFormat: 'M/D/YYYY',
      dayFormat: 'D',
      dateTimeFormat: 'M/D/YYYY HH:mm:ss',
      monthBeforeYear: true,
      previousMonth: 'Previous month (PageUp)',
      nextMonth: 'Next month (PageDown)',
      previousYear: 'Last year (Control + left)',
      nextYear: 'Next year (Control + right)',
      previousDecade: 'Last decade',
      nextDecade: 'Next decade',
      previousCentury: 'Last century',
      nextCentury: 'Next century',
    },
    timePickerLocale: {
      placeholder: 'Select time',
    },
  },
  DatePicker: {
    timezoneOffset: 1 * 60,
    firstDayOfWeek: 0,
    minimalDaysInFirstWeek: 1,
    lang: {
      placeholder: 'Select date',
      timePlaceholder: 'Select time',
      rangePlaceholder: ['Start date', 'End date'],
      locale: 'en_GB',
      today: 'Today',
      now: 'Now',
      backToToday: 'Back to today',
      ok: 'Ok',
      clear: 'Clear',
      month: 'Month',
      year: 'Year',
      timeSelect: 'select time',
      dateSelect: 'select date',
      weekSelect: 'Choose a week',
      monthSelect: 'Choose a month',
      yearSelect: 'Choose a year',
      decadeSelect: 'Choose a decade',
      yearFormat: 'YYYY',
      dateFormat: 'M/D/YYYY',
      dayFormat: 'D',
      dateTimeFormat: 'M/D/YYYY HH:mm:ss',
      monthBeforeYear: true,
      previousMonth: 'Previous month (PageUp)',
      nextMonth: 'Next month (PageDown)',
      previousYear: 'Last year (Control + left)',
      nextYear: 'Next year (Control + right)',
      previousDecade: 'Last decade',
      nextDecade: 'Next decade',
      previousCentury: 'Last century',
      nextCentury: 'Next century',
    },
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Cancel',
    justOkText: 'OK',
  },
  Pagination: {
    items_per_page: '/ page',
    jump_to: 'Go to',
    page: '',
    prev_page: 'Previous Page',
    next_page: 'Next Page',
    prev_5: 'Previous 5 Pages',
    next_5: 'Next 5 Pages',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Cancel',
  },
  Select: {
    notFoundContent: 'Not found',
  },
  Table: {
    filterTitle: 'Filter menu',
    filterConfirm: 'OK',
    filterReset: 'Reset',
    emptyText: 'No data',
  },
  TimePicker: {
    placeholder: 'Select time',
    clear: 'Clear',
    format: {
      eras: ['BCE', 'BC'],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortWeekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      veryShortWeekdays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      ampms: ['AM', 'PM'],
      datePatterns: [
        // mandag d. 23. oktober 2015
        'EEEE d MMMM yyyy',
        // 23 oktober 2015
        'd MMMM yyyy',
        // 23. okt. 2015
        'd MMM. yyyy',
        // 23/10/15
        'dd/MM/yy',
        // 23/10/2015
        'dd/MM/yyyy',
        // 2015-10-15
        'yyyy-MM-dd',
      ],
      timePatterns: ["HH:mm:ss 'GMT'Z", 'HH:mm:ss', 'HH:mm'],
      dateTimePattern: '{date} {time}',
    },
    calendar: {
      timezoneOffset: 1 * 60,
      firstDayOfWeek: 0,
      minimalDaysInFirstWeek: 1,
    },
  },
  Transfer: {
    notFoundContent: 'Not found',
    searchPlaceholder: 'Search here',
    itemUnit: 'item',
    itemsUnit: 'items',
  },
  locale: 'en',
}
