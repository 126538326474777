import ContractDelta from '../model/contractDelta'
import { SupplementTypeName } from '../model/supplementType'
import { getDate, isSameOrBefore, isTimeAfter } from './date-utils'

const FreeChoicePercentage: SupplementTypeName[] = [
  'DenmarkFritvalg',
  'DenmarkFritvalgWithVacation',
  'DenmarkFritvalgWithVacationNoPension',
  'DenmarkFritvalgNoPension',
  'DenmarkFritvalgDirectPay',
  'DenmarkFritvalgWithVacationDirectPay',
  'DenmarkFritvalgWithVacationNoPensionDirectPay',
  'DenmarkFritvalgNoPensionDirectPay',
]
const FreeChoiceAmount: SupplementTypeName[] = [
  'DenmarkFritvalgAmount',
  'DenmarkFritvalgWithVacationAmount',
  'DenmarkFritvalgWithVacationNoPensionAmount',
  'DenmarkFritvalgNoPensionAmount',
  'DenmarkFritvalgAmountDirectPay',
  'DenmarkFritvalgWithVacationAmountDirectPay',
  'DenmarkFritvalgWithVacationNoPensionAmountDirectPay',
  'DenmarkFritvalgNoPensionAmountDirectPay',
]
const SHPercentage: SupplementTypeName[] = [
  'DenmarkSHDage',
  'DenmarkSHDageWithVacation',
  'DenmarkSHDageWithVacationNoPension',
  'DenmarkSHDageNoPension',
  'DenmarkSHDageDirectPay',
  'DenmarkSHDageWithVacationDirectPay',
  'DenmarkSHDageWithVacationNoPensionDirectPay',
  'DenmarkSHDageNoPensionDirectPay',
]
const SHAmount: SupplementTypeName[] = [
  'DenmarkSHDageAmount',
  'DenmarkSHDageWithVacationAmount',
  'DenmarkSHDageWithVacationNoPensionAmount',
  'DenmarkSHDageNoPensionAmount',
  'DenmarkSHDageAmountDirectPay',
  'DenmarkSHDageWithVacationAmountDirectPay',
  'DenmarkSHDageWithVacationNoPensionAmountDirectPay',
  'DenmarkSHDageNoPensionAmountDirectPay',
]

export function isChoiceSupplement(name?: SupplementTypeName): boolean {
  return !!name && [...FreeChoicePercentage, ...FreeChoiceAmount, ...SHPercentage, ...SHAmount].indexOf(name) !== -1
}

export function isPercentageChoiceSupplement(name?: SupplementTypeName): boolean {
  return !!name && [...FreeChoicePercentage, ...SHPercentage].indexOf(name) !== -1
}

export function isAmountChoiceSupplement(name?: SupplementTypeName): boolean {
  return !!name && [...FreeChoiceAmount, ...SHAmount].indexOf(name) !== -1
}

export function isFreeChoiceSupplement(name?: SupplementTypeName): boolean {
  return !!name && [...FreeChoicePercentage, ...FreeChoiceAmount].indexOf(name) !== -1
}

export function isSHSupplement(name?: SupplementTypeName): boolean {
  return !!name && [...SHPercentage, ...SHAmount].indexOf(name) !== -1
}

/**
 * Checks if `name` is the percentage variant of `otherName`, basically removing "Amount" from `otherName`
 * and see if it matches `name`.
 * @param name
 * @param otherName
 */
export function isPercentageChoiceVariant(name?: SupplementTypeName, otherName?: SupplementTypeName): boolean {
  if (!name || !otherName) {
    return false
  }
  return name === otherName.replace(/Amount/, '')
}

export function isVacationChoiceSupplement(name?: SupplementTypeName): boolean {
  return !!name && !!name.match(/.+WithVacation.*/)
}

export function isPensionChoiceSupplement(name?: SupplementTypeName): boolean {
  return !!name && !name.match(/.+NoPension.*/)
}

export function isDirectPayChoiceSupplement(name?: SupplementTypeName): boolean {
  return !!name && !!name.match(/.+DirectPay$/)
}

/*
How to interpret `activeTo`, `futureTo` and `validTo` values on the contract delta.
| `activeTo` | `futureTo` | `validTo` | Meaning                                                                                         |
| ---------- | ---------- | --------- | ----------------------------------------------------------------------------------------------- |
| `null`     | `null`     | set       | Expired                                                                                         |
| set        | `null`     | set       | Active until and including `activeTo`, then expired                                             |
| `null`     | `null`     | `null`    | Active                                                                                          |
| set        | set        | set       | Future until and including `futureTo`, then active until and including `activeTo`, then expired |
| `null`     | set        | `null`    | Future until and including `futureTo`, then active                                              |
 */

export function isContractDeltaExpired(delta?: ContractDelta): boolean {
  if (!delta) {
    return false
  }
  if (!delta.validTo) {
    return false
  }
  if (!delta.activeTo && !delta.futureTo) {
    return true
  }
  return !!(delta.activeTo && isTimeAfter(getDate(), getDate(delta.activeTo)))
}

export function isContractDeltaActive(delta?: ContractDelta): boolean {
  if (!delta) {
    return true
  }
  if (!delta.validTo && !delta.activeTo && !delta.futureTo) {
    return true
  }
  if (delta.validTo && delta.activeTo && !delta.futureTo && isSameOrBefore(getDate(), getDate(delta.activeTo))) {
    return true
  }
  if (
    delta.validTo &&
    delta.activeTo &&
    delta.futureTo &&
    isTimeAfter(getDate(), getDate(delta.futureTo)) &&
    isSameOrBefore(getDate(), getDate(delta.activeTo))
  ) {
    return true
  }
  return !!(!delta.validTo && !delta.activeTo && delta.futureTo && isTimeAfter(getDate(), getDate(delta.futureTo)))
}

export function isContractDeltaFuture(delta?: ContractDelta): boolean {
  if (!delta) {
    return false
  }
  if (!delta.futureTo) {
    return false
  }
  return isSameOrBefore(getDate(), getDate(delta.futureTo))
}
