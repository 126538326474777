import React from 'react'

import { fetchEmployeeDimensions, putEmployeeDimensions } from '../api/employee-dimensions'
import ActionTypes from '../constants/action-types'
import EmployeeDimension, { EmployeeDimensionMutableFields } from '../model/employeeDimension'
import { EmployeeDimensionAction } from '../reducers/employeeDimensions'
import { isRequestError } from '../utils/error-utils'

function loadingEmployeeDimensions(companyID: string): EmployeeDimensionAction {
  return {
    type: ActionTypes.EMPLOYEE_DIMENSIONS_LOADING,
    companyID,
  }
}
function loadedEmployeeDimensions(companyID: string, employeeDimensions: EmployeeDimension[]): EmployeeDimensionAction {
  return {
    type: ActionTypes.EMPLOYEE_DIMENSIONS_LOADED,
    employeeDimensions,
    companyID,
  }
}
function failedLoadingEmployeeDimensions(companyID: string, error: Error): EmployeeDimensionAction {
  return {
    type: ActionTypes.EMPLOYEE_DIMENSIONS_LOAD_FAILED,
    error,
    companyID,
  }
}

function updatingEmployeeDimensions(employeeID: string): EmployeeDimensionAction {
  return {
    type: ActionTypes.EMPLOYEE_DIMENSIONS_UPDATING,
    employeeID,
  }
}
function updatedEmployeeDimensions(
  employeeID: string,
  employeeDimensions: EmployeeDimension[]
): EmployeeDimensionAction {
  return {
    type: ActionTypes.EMPLOYEE_DIMENSIONS_UPDATED,
    employeeID,
    employeeDimensions,
  }
}
function failedUpdatingEmployeeDimensions(employeeID: string, error: Error): EmployeeDimensionAction {
  return {
    type: ActionTypes.EMPLOYEE_DIMENSIONS_UPDATE_FAILED,
    employeeID,
    error,
  }
}

export function updatedEmployeeDimension(
  employeeID: string,
  employeeDimension: EmployeeDimension
): EmployeeDimensionAction {
  return {
    type: ActionTypes.EMPLOYEE_DIMENSION_UPDATED,
    employeeID,
    employeeDimension,
  }
}

export function getEmployeeDimensions(companyID: string) {
  return (dispatch: React.Dispatch<any>): Promise<EmployeeDimension[] | void> => {
    dispatch(loadingEmployeeDimensions(companyID))
    return fetchEmployeeDimensions(companyID)
      .then((res) => {
        dispatch(loadedEmployeeDimensions(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingEmployeeDimensions(companyID, e))
        }
      })
  }
}

export function updateEmployeeDimensions(employeeID: string, employeeDimensions: EmployeeDimensionMutableFields[]) {
  return (dispatch: React.Dispatch<any>): Promise<EmployeeDimension[] | void> => {
    dispatch(updatingEmployeeDimensions(employeeID))
    return putEmployeeDimensions(employeeID, employeeDimensions)
      .then((res) => {
        dispatch(updatedEmployeeDimensions(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingEmployeeDimensions(employeeID, e))
        }
      })
  }
}
