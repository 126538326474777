import React, { ReactElement } from 'react'

import { AsynchronousScheduleCreationFields, AsynchronousScheduleType } from '../../model/asynchronousSchedule'
import { AsynchronousScheduleReducer } from '../../reducers/asynchronousSchedules'
import { formatDateTime } from '../../utils/date-utils'
import { formatAsynchronousScheduleType } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import { Col, Row } from '../elements/grid'
import Subtitle from '../elements/Subtitle'
import AsynchronousScheduleModalEdit, { ScheduleResult } from './AsynchronousScheduleModalEdit'

type Props = {
  companyID: string
  asynchronousSchedules: AsynchronousScheduleReducer
  scheduleType: AsynchronousScheduleType

  addAsynchronousSchedule: (schedule: AsynchronousScheduleCreationFields) => void
  updateAsynchronousSchedule: (companyID: string, scheduleID: string, cron: string) => void
  deleteAsynchronousSchedule: (companyID: string, scheduleID: string) => void
}

export default function AsynchronousScheduleModal(props: Props): ReactElement | null {
  const schedule = props.asynchronousSchedules.asynchronousSchedules.find(
    (schedule) => schedule.type === props.scheduleType
  )

  const handleSubmit = (values: ScheduleResult) => {
    if (schedule) {
      props.updateAsynchronousSchedule(props.companyID, schedule.id, values.cron)
    } else {
      props.addAsynchronousSchedule({
        companyID: props.companyID,
        cron: values.cron,
        type: props.scheduleType,
      })
    }
  }

  const title = formatAsynchronousScheduleType(props.scheduleType)

  return (
    <Card>
      <Subtitle>{t('data_integration.async_schedule.edit.title', { title })}</Subtitle>
      <AsynchronousScheduleModalEdit
        asynchronousSchedules={props.asynchronousSchedules}
        title={title}
        asynchronousScheduleID={schedule?.id}
        onSubmit={handleSubmit}
      />
      {schedule && (
        <>
          <Row style={{ marginTop: '15px' }}>
            <Col span={24}>
              <p>
                {t('data_integration.async_schedule.edit.intro', { title })}
                <br />
                <strong>{formatDateTime(schedule.nextRunAt)}</strong>.
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button
                onClick={() => props.deleteAsynchronousSchedule(props.companyID, schedule.id)}
                type="danger"
                style={{ width: '100%' }}
                size="large"
              >
                {t('data_integration.async_schedule.edit.delete', { title })}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Card>
  )
}
