import React, { ReactElement, useEffect } from 'react'
import { usePrevious } from 'react-use'

import BasicUpgradeComponent from '../../components/widgets/BasicUpgrade'
import LoadingOverlay from '../../components/widgets/LoadingOverlay'
import PremiumUpgradeComponent from '../../components/widgets/PremiumUpgrade'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyPricingPackageReducer } from '../../reducers/companyPricingPackages'
import { PricingPackageReducer } from '../../reducers/pricingPackages'

type Props = {
  id: number
  companies: CompanyReducer
  pricingPackages: PricingPackageReducer
  companyPricingPackages: CompanyPricingPackageReducer
  featurePackage: 'Basic' | 'Premium'

  removeModal: (id: number) => void
  getPricingPackages: (includeIDs: string[]) => void
  getCompanyPricingPackages: () => void
  addCompanyPricingPackage: (pricingPackageID: string) => void
}

export default function PackageUpgrade(props: Props): ReactElement | null {
  const getMissingPackages = () => {
    const pricingPackages = props.pricingPackages.pricingPackages.map((v) => v.id)
    const companyPricingPackages = props.companyPricingPackages.companyPricingPackages
      .map((v) => v.pricingPackageID)
      .toArray()
    return companyPricingPackages.filter((v) => !pricingPackages.includes(v))
  }

  const { companyPricingPackages, pricingPackages, getPricingPackages, getCompanyPricingPackages } = props

  useEffect(() => {
    if (!companyPricingPackages.loading && !companyPricingPackages.loaded) {
      getCompanyPricingPackages()
    }
  })

  useEffect(() => {
    if (companyPricingPackages.loaded) {
      const missingPackages = getMissingPackages()
      if (!pricingPackages.loading && (!pricingPackages.loaded || missingPackages.length > 0)) {
        getPricingPackages(missingPackages)
      }
    }
  })

  const previousCompanyPricingPackages = usePrevious(companyPricingPackages)

  const { removeModal, id } = props

  useEffect(() => {
    if (previousCompanyPricingPackages && previousCompanyPricingPackages.saving && !companyPricingPackages.saving) {
      if (!companyPricingPackages.error) {
        removeModal(id)
      }
    }
  })

  const company = props.companies.company
  if (!company) {
    return null // should not happen
  }

  if (!props.pricingPackages.loaded || !props.companyPricingPackages.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  if (props.featurePackage === 'Premium') {
    return (
      <PremiumUpgradeComponent
        company={company}
        pricingPackages={props.pricingPackages.pricingPackages}
        companyPricingPackages={props.companyPricingPackages}
        addCompanyPricingPackage={props.addCompanyPricingPackage}
      />
    )
  }

  return (
    <BasicUpgradeComponent
      company={company}
      pricingPackages={props.pricingPackages.pricingPackages}
      companyPricingPackages={props.companyPricingPackages}
      addCompanyPricingPackage={props.addCompanyPricingPackage}
    />
  )
}
