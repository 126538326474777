import { endOfMonth, startOfDay, startOfMonth, subMonths } from 'date-fns'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature } from '../../actions/alerts'
import Company, { CompanyMutableFields } from '../../model/company'
import { ReportType } from '../../model/report'
import { AsynchronousTaskReducer } from '../../reducers/asynchronousTasks'
import { PayRollReducer } from '../../reducers/payRolls'
import { paths } from '../../routes'
import { formatAPIDate, formatDate, getDate } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { setByPath } from '../../utils/object-utils'
import { formatReportName, getReports } from '../../utils/report-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Select from '../antd/select'
import Button from '../elements/button'
import Card from '../elements/card'
import DatePicker from '../elements/date-picker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import DumbLink from '../widgets/DumbLink'
import ReportButton, { RegularReportFields } from '../widgets/ReportButton'
import ReportDSTAutomaticModal, { AutomaticFields } from './ReportDSTAutomaticModal'
import ReportDSTModal from './ReportDSTModal'

type Props = {
  company: Company
  payRolls: PayRollReducer
  asynchronousTasks: AsynchronousTaskReducer

  addAlert: addAlertSignature
  getPayRolls: () => void
  getAsynchronousTasks: (companyID: string) => void
  updateCompany: (company: CompanyMutableFields) => Promise<Company | void>
}

type Fields = {
  report?: ReportType
  from: Date
  to: Date
  payRollID?: string
}

function ReportTab(props: Props & FormComponentProps<Fields, Fields>): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showDSTReport, setShowDSTReport] = useState(false)
  const [showDSTAutoReport, setShowDSTAutoReport] = useState(false)
  const [buttonState, setButtonState] = useState(1)
  const [disabled, setDisabled] = useState(false)

  const { payRolls, getPayRolls } = props

  useEffect(() => {
    if (!payRolls.loaded && !payRolls.loading) {
      getPayRolls()
    }
  }, [payRolls, getPayRolls])

  const { asynchronousTasks, getAsynchronousTasks, company } = props

  useEffect(() => {
    if (!asynchronousTasks.loaded && !asynchronousTasks.loading) {
      getAsynchronousTasks(company.id)
    }
  }, [asynchronousTasks, getAsynchronousTasks, company])

  const setDSTReportVisibility = (visible: boolean) => {
    setModalKey(modalKey + 1)
    setShowDSTReport(visible)
  }

  const setDSTAutomaticReportVisibility = (visible: boolean) => {
    setModalKey(modalKey + 1)
    setShowDSTAutoReport(visible)
  }

  const needsPayRoll = () => {
    const report = props.getFieldValue('report')
    return report === 'PayRollEmployees' || report === 'SalaryReceipt'
  }

  const needsTime = () => {
    return (
      !needsPayRoll() &&
      props.getFieldValue('report') !== 'Employees' &&
      props.getFieldValue('report') !== 'ReimbursementVouchersAccounting' &&
      props.getFieldValue('report') !== 'StartBalances'
    )
  }

  const needsTo = () => {
    return needsTime() || props.getFieldValue('report') === 'LeaveBalances'
  }

  const hasExcelVersion = (): boolean => {
    return props.getFieldValue('report') !== 'SalaryReceipt'
  }

  const hasPDFVersion = (): boolean => {
    return (
      props.getFieldValue('report') === 'PeriodIncomeTaxReport' ||
      props.getFieldValue('report') === 'SalaryReceipt' ||
      props.getFieldValue('report') === 'Assets'
    )
  }

  const isValid = (): boolean => {
    const { getFieldValue } = props
    if (!getFieldValue('report')) {
      return false
    }
    if (needsTime() && !getFieldValue('from')) {
      return false
    }
    if (needsTo() && !getFieldValue('to')) {
      return false
    }
    if (needsPayRoll() && !getFieldValue('payRollID')) {
      return false
    }

    return true
  }

  const getFields = (): RegularReportFields | undefined => {
    const { getFieldValue } = props

    const report = getFieldValue('report')
    if (!report) {
      return undefined
    }
    let from = formatAPIDate(getFieldValue('from'))
    let to = formatAPIDate(getFieldValue('to'))
    const payRollID: string | undefined = needsPayRoll() ? getFieldValue('payRollID') : undefined

    if (!needsTo()) {
      to = '0001-01-01'
    }
    if (!needsTime()) {
      from = '0001-01-01'
    }

    return {
      type: 'Regular',
      companyID: company.id,
      report,
      from: from,
      to: to,
      payRollID: payRollID,
    }
  }

  const handleAutomaticSubmit = (values: AutomaticFields) => {
    // Update company
    const company = props.company
    company.DAUnionCode = values.DAUnionCode
    company.enableYearlySalaryStatistics = values.enableYearlySalaryStatistics
    company.enableQuarterlySalaryStatistics = values.enableQuarterlySalaryStatistics
    company.enableAbsenceStatistics = values.enableAbsenceStatistics
    props.updateCompany(company).then(() => setDSTAutomaticReportVisibility(false))
  }

  const { decorateField } = props

  return (
    <Card>
      <TitleMenu>
        <DumbLink onClick={() => setDSTAutomaticReportVisibility(true)}>
          <Button>{t('report_tab.header.dst_automatic')}</Button>
        </DumbLink>
        <DumbLink onClick={() => setDSTReportVisibility(true)}>
          <Button>{t('report_tab.header.dst_manual')}</Button>
        </DumbLink>
        <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/tax-cards'}>
          <Button>{t('report_tab.header.tax_cards')}</Button>
        </Link>
      </TitleMenu>
      <Title>{t('report_tab.title')}</Title>

      <Row>
        <Col span={24}>
          {decorateField('report', {
            skipWrapper: true,
            skipLabel: true,
            placeholder: t('report_tab.form.report'),
          })(
            <Select
              dropdownMatchSelectWidth={false}
              style={{ width: '38%' }}
              dropdownClassName={'ant-select-dropdown-long'}
              onChange={() => setButtonState((prev) => prev + 1)}
              disabled={disabled}
            >
              {getReports().map((report) => {
                return (
                  <Select.Option key={report} value={report}>
                    {formatReportName(report)}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      {(needsTime() || needsTo()) && (
        <Row>
          {needsTime() && (
            <Col span={12}>
              {decorateField('from', {
                placeholder: t('report_tab.form.from'),
                validate: (val) => {
                  if (!val) {
                    return t('report_tab.form.from.required')
                  }
                  return null
                },
              })(
                <DatePicker
                  allowClear={false}
                  style={{ width: '100%' }}
                  disabled={disabled}
                  onChange={() => setButtonState((prev) => prev + 1)}
                />
              )}
            </Col>
          )}
          <Col span={12}>
            {decorateField('to', {
              placeholder: t('report_tab.form.to'),
              validate: (val) => {
                if (!val) {
                  return t('report_tab.form.to.required')
                }
                return null
              },
            })(
              <DatePicker
                allowClear={false}
                style={{ width: '100%' }}
                disabled={disabled}
                onChange={() => setButtonState((prev) => prev + 1)}
              />
            )}
          </Col>
        </Row>
      )}
      {needsPayRoll() && (
        <Row>
          <Col span={24}>
            {decorateField('payRollID', {
              skipWrapper: true,
              placeholder: t('report_tab.form.pay_roll_id'),
            })(
              <Select
                dropdownMatchSelectWidth={false}
                style={{ width: '38%' }}
                disabled={disabled}
                onChange={() => setButtonState((prev) => prev + 1)}
              >
                {props.payRolls.payRolls
                  .filter((payRoll) => payRoll.status === 'Success')
                  .sort((a, b) => b.salaryPeriod.start.localeCompare(a.salaryPeriod.start))
                  .map((payRoll) => {
                    return (
                      <Select.Option key={payRoll.id} value={payRoll.id}>
                        Fra {formatDate(payRoll.salaryPeriod.start)} til {formatDate(payRoll.salaryPeriod.end)}
                      </Select.Option>
                    )
                  })}
              </Select>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          {hasExcelVersion() && (
            <ReportButton
              onStateChange={(ready) => setDisabled(!ready)}
              state={buttonState}
              asynchronousTasks={props.asynchronousTasks.asynchronousTasks}
              type={'Excel'}
              addAlert={props.addAlert}
              getFields={getFields}
              isValid={isValid}
              buttonType="secondary"
              text={t('report_tab.form.download_excel')}
            />
          )}
          {hasPDFVersion() && (
            <ReportButton
              onStateChange={(ready) => setDisabled(!ready)}
              state={buttonState}
              asynchronousTasks={props.asynchronousTasks.asynchronousTasks}
              type={'PDF'}
              addAlert={props.addAlert}
              getFields={getFields}
              isValid={isValid}
              buttonType="secondary"
              text={t('report_tab.form.download_pdf')}
            />
          )}
        </Col>
      </Row>

      <Modal
        key={modalKey}
        visible={showDSTReport}
        onOk={() => setDSTReportVisibility(false)}
        onCancel={() => setDSTReportVisibility(false)}
        width={582}
        footer={null}
      >
        <ReportDSTModal
          visible={showDSTReport}
          asynchronousTasks={props.asynchronousTasks.asynchronousTasks}
          company={props.company}
          addAlert={props.addAlert}
        />
      </Modal>

      <Modal
        key={`auto-${modalKey}`}
        visible={showDSTAutoReport}
        onOk={() => setDSTAutomaticReportVisibility(false)}
        onCancel={() => setDSTAutomaticReportVisibility(false)}
        width={582}
        footer={null}
      >
        <ReportDSTAutomaticModal visible={showDSTAutoReport} company={props.company} onSubmit={handleAutomaticSubmit} />
      </Modal>
    </Card>
  )
}

export default withValidations<Props, Fields, Fields>({
  mapPropsToFields: () => ({
    from: startOfMonth(subMonths(getDate(), 1)),
    to: startOfDay(endOfMonth(subMonths(getDate(), 1))),
  }),
  onChange: (key, val) => {
    const values = {}
    switch (key) {
      case 'from':
        setByPath(values, key, val)
        if (val && val instanceof Date) {
          setByPath(values, 'to', startOfDay(endOfMonth(val)))
        }
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => values,
})(ReportTab)
