import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { lookupCVR } from '../../api/companies'
import Company, { ProductionUnitCreationFields } from '../../model/company'
import { isRequestError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import AutoComplete from '../antd/auto-complete'
import Spin from '../elements/spin'
import Subcard from '../elements/Subcard'

export interface SearchResult {
  nationalID: string
  name: string
  address: string
  postalCode: string
  city: string
  productionUnits: ProductionUnitCreationFields[]
}

interface Props {
  onSelect: (result: SearchResult) => void
}

interface State {
  searchStart: number
  searchResults: Company[]
  searching: boolean
}

function parseProductionUnits(res: Company): ProductionUnitCreationFields[] {
  const productionUnits: ProductionUnitCreationFields[] = []
  if (res.productionUnits && res.productionUnits instanceof Array) {
    res.productionUnits.forEach((unit) => {
      productionUnits.push({
        productionUnitNumber: unit.productionUnitNumber,
        name: unit.name,
      })
    })
  }
  return productionUnits
}
function parseAddress(res: Company) {
  if (!res.address) {
    return ''
  }
  const address = res.address.split('\n')
  return address.join(', ')
}

export default function CompanySearch(props: Props): ReactElement | null {
  const [inputSearch, setInputSearch] = useState('')
  const [search, setSearch] = useState('')
  const [state, setState] = useState<State>({
    searchStart: Date.now(),
    searchResults: [],
    searching: false,
  })

  const handleSelect = (value: string) => {
    const company = state.searchResults.find((res) => res.name + ' (' + res.nationalID + ')' === value)
    if (company) {
      props.onSelect({
        nationalID: company.nationalID,
        name: company.name,
        address: parseAddress(company),
        postalCode: company.postalCode,
        city: company.city,
        productionUnits: parseProductionUnits(company),
      })
    }
  }

  const { onSelect } = props

  const doLookup = useCallback(
    (value: string) => {
      const start = Date.now()
      lookupCVR(value)
        .then((res) => {
          // Ignore old requests
          if (state.searchStart > start) {
            return
          }
          if (res.error) {
            setState({
              searchResults: [],
              searchStart: start,
              searching: false,
            })
          } else {
            setState({
              searchResults: res.data,
              searchStart: start,
              searching: false,
            })
          }
        })
        .catch((e) => {
          // Ignore old requests
          if (state.searchStart > start) {
            return
          }
          if (isRequestError(e)) {
            setState({
              searchResults: [],
              searchStart: start,
              searching: false,
            })
          }
          onSelect({
            nationalID: value,
            name: '',
            address: '',
            postalCode: '',
            city: '',
            productionUnits: [],
          })
        })
    },
    [onSelect, state]
  )

  const previousSearch = usePrevious(search)

  useEffect(() => {
    if (!search || !state.searching || (previousSearch && previousSearch === search)) {
      return
    }
    doLookup(search)
  }, [previousSearch, search, doLookup, state])

  useEffect(() => {
    const searchTimeout = setTimeout(() => setSearch(inputSearch.trim()), 100)
    return () => clearTimeout(searchTimeout)
  }, [inputSearch])

  const handleChange = (value: string) => {
    if (value.match(/(.+) \([0-9]{8}\)/i)) {
      setInputSearch(value)
      setState({
        searchResults: [],
        searchStart: Date.now(),
        searching: false,
      })
      return
    }
    if (search === value) {
      return
    }
    setState((prev) => ({
      searchResults: prev.searchResults,
      searchStart: prev.searchStart,
      searching: true,
    }))
    setInputSearch(value)
  }

  const dataSource = state.searchResults.map((res) => {
    return res.name + ' (' + res.nationalID + ')'
  })
  return (
    <Subcard className="company-search">
      <label htmlFor="search">{t('companies_add.search.title')}</label>
      <div className="autocomplete-wrapper">
        <AutoComplete
          value={inputSearch}
          dataSource={dataSource}
          filterOption={(inputValue: string, option: ReactElement) => {
            return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }}
          className="autocomplete-field"
          dropdownClassName="company-search-dropdown"
          onSelect={handleSelect}
          onChange={handleChange}
          placeholder={t('companies_add.search.placeholder')}
        />
        {state.searching && <Spin />}
      </div>
    </Subcard>
  )
}
