import React, { ReactElement, useEffect, useState } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import { ContractBookDraftCreated } from '../../api/contract-book'
import { ContractBookDraftReducer } from '../../reducers/contractBookDrafts'
import { ContractBookTemplateReducer } from '../../reducers/contractBookTemplates'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import ContractBookForm, { ContractBookResult } from './ContractBookForm'

type Props = {
  visible: boolean
  contractBookTemplates: ContractBookTemplateReducer
  contractBookDrafts: ContractBookDraftReducer

  close: () => void
  addAlert: addAlertSignature
  addContractBookDraft: (contractBookID: string, templateID: string) => Promise<ContractBookDraftCreated | void>
}

export default function ContractBookModal(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, contractBookDrafts } = props
  useEffect(() => {
    visibleComponentDidUpdate(visible, contractBookDrafts.error, error, setError)
  }, [visible, contractBookDrafts, error])

  const handleSubmit = (values: ContractBookResult) => {
    if (!values.templateID) {
      return
    }
    const contractBookID = props.contractBookTemplates.templates.find(
      (template) => template.id === values.templateID
    )?.contractBookID
    if (!contractBookID) {
      props.addAlert('error', t('employees.contract_book.error.no_template'), { timeout: 10 })
      return
    }
    props.addContractBookDraft(contractBookID, values.templateID).then((res) => {
      if (res) {
        window.open(res.editUrl, 'contract-book-tab')
        props.close()
      } else {
        props.addAlert('error', t('employees.contract_book.error.creation_error'), { timeout: 10 })
      }
    })
  }

  return (
    <Card>
      <Subtitle>{t('employees.contract_book.title')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <ContractBookForm
        contractBookTemplates={props.contractBookTemplates}
        contractBookDrafts={props.contractBookDrafts}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
