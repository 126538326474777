import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getContracts, getRemuneration } from '../actions/contracts'
import { getCostCenters } from '../actions/cost-centers'
import { getLeaveTypes } from '../actions/leave-types'
import { getPayRolls } from '../actions/pay-rolls'
import { getSalaryCycles } from '../actions/salary-cycles'
import { getSalaryTypes } from '../actions/salary-types'
import StagedImportComponent from '../components/staged-import/StagedImport'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { AlertReducer } from '../reducers/alerts'
import { AsynchronousTaskReducer } from '../reducers/asynchronousTasks'
import { CompanyReducer } from '../reducers/companies'
import { ContractReducer } from '../reducers/contracts'
import { CostCenterReducer } from '../reducers/costCenters'
import { EmployeeReducer } from '../reducers/employees'
import { LeaveTypeReducer } from '../reducers/leaveTypes'
import { PayRollReducer } from '../reducers/payRolls'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import StagedImportFileType from '../types/staged-import-file-type'
import { formatLoadingText } from '../utils/loading-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import { t } from '../utils/translation-utils'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  contracts: ContractReducer
  costCenters: CostCenterReducer
  employees: EmployeeReducer
  leaveTypes: LeaveTypeReducer
  salaryCycles: SalaryCycleReducer
  salaryTypes: SalaryTypeReducer
  payRolls: PayRollReducer
  asynchronousTasks: AsynchronousTaskReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getSalaryCycles: () => void
  getSalaryTypes: () => void
  getCostCenters: () => void
  getLeaveTypes: () => void
  getRemuneration: (contractID: string) => void
  getContracts: () => void
  getPayRolls: () => void
}

function StagedImport(props: RouteProps & Reducers & Actions): ReactElement | null {
  const {
    salaryCycles,
    getSalaryCycles,
    salaryTypes,
    getSalaryTypes,
    costCenters,
    getCostCenters,
    leaveTypes,
    getLeaveTypes,
  } = props

  useEffect(() => {
    if (!salaryCycles.loaded && !salaryCycles.loading) {
      getSalaryCycles()
    }
  }, [salaryCycles, getSalaryCycles])
  useEffect(() => {
    if (!salaryTypes.loaded && !salaryTypes.loading) {
      getSalaryTypes()
    }
  }, [salaryTypes, getSalaryTypes])
  useEffect(() => {
    if (!costCenters.loaded && !costCenters.loading) {
      getCostCenters()
    }
  }, [costCenters, getCostCenters])
  useEffect(() => {
    if (!leaveTypes.loaded && !leaveTypes.loading) {
      getLeaveTypes()
    }
  }, [leaveTypes, getLeaveTypes])

  let type: StagedImportFileType | undefined = undefined
  if (props.location.query && props.location.query.type) {
    for (const item in StagedImportFileType) {
      const val = (StagedImportFileType as Record<string, string>)[item]
      if (val === props.location.query.type) {
        type = props.location.query.type as StagedImportFileType
      }
    }
  }

  const company = props.companies.company
  if (!company) {
    return null
  }

  if (!salaryCycles.loaded || !salaryTypes.loaded || !costCenters.loaded || !leaveTypes.loaded) {
    return (
      <LoadingOverlay
        text={formatLoadingText([
          { loading: !salaryCycles.loaded, text: t('loading.reducer.salary_cycles') },
          { loading: !salaryTypes.loaded, text: t('loading.reducer.salary_types') },
          { loading: !costCenters.loaded, text: t('loading.reducer.cost_centers') },
          { loading: !leaveTypes.loaded, text: t('loading.reducer.leave_types') },
        ])}
      />
    )
  }

  return (
    <StagedImportComponent
      type={type}
      alerts={props.alerts}
      company={company}
      contracts={props.contracts}
      costCenters={props.costCenters.costCenters}
      employees={props.employees}
      leaveTypes={props.leaveTypes.leaveTypes}
      salaryCycles={props.salaryCycles}
      salaryTypes={props.salaryTypes.salaryTypes}
      asynchronousTasks={props.asynchronousTasks.asynchronousTasks}
      payRolls={props.payRolls}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      getRemuneration={props.getRemuneration}
      getContracts={props.getContracts}
      getPayRolls={props.getPayRolls}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    contracts: state.contracts,
    costCenters: state.costCenters,
    employees: state.employees,
    leaveTypes: state.leaveTypes,
    salaryCycles: state.salaryCycles,
    salaryTypes: state.salaryTypes,
    payRolls: state.payRolls,
    asynchronousTasks: state.asynchronousTasks,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getCostCenters: getCostCenters,
    getSalaryCycles: getSalaryCycles,
    getSalaryTypes: getSalaryTypes,
    getLeaveTypes: getLeaveTypes,
    getRemuneration: getRemuneration,
    getContracts: getContracts,
    getPayRolls: getPayRolls,
  }
)(StagedImport)
