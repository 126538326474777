import React from 'react'

import { deleteSupportAccess, fetchSupportCompanies, getSupportAccess, postSupportAccess } from '../api/support-access'
import ActionTypes from '../constants/action-types'
import Company from '../model/company'
import SupportAccess from '../model/supportAccess'
import { CompanyAction } from '../reducers/companies'
import { SupportAccessAction } from '../reducers/supportAccess'
import { getActiveCompany } from '../utils/cookie-utils'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'
import { loadedCompanies } from './companies'

function loadingSupportAccess(): SupportAccessAction {
  return {
    type: ActionTypes.SUPPORT_ACCESS_LOADING,
  }
}

function loadedSupportAccess(companyID: string, supportAccess: SupportAccess): SupportAccessAction {
  return {
    type: ActionTypes.SUPPORT_ACCESS_LOADED,
    companyID,
    supportAccess,
  }
}

function failedLoadingSupportAccess(companyID: string, error: Error): SupportAccessAction {
  return {
    type: ActionTypes.SUPPORT_ACCESS_LOAD_FAILED,
    companyID,
    error,
  }
}

function grantingSupportAccess(): SupportAccessAction {
  return {
    type: ActionTypes.SUPPORT_ACCESS_GRANTING,
  }
}

function grantedSupportAccess(companyID: string, supportAccess: SupportAccess): SupportAccessAction {
  return {
    type: ActionTypes.SUPPORT_ACCESS_GRANTED,
    companyID,
    supportAccess,
  }
}

function failedGrantingSupportAccess(companyID: string, error: Error): SupportAccessAction {
  return {
    type: ActionTypes.SUPPORT_ACCESS_GRANT_FAILED,
    error,
    companyID,
  }
}

function revokingSupportAccess(): SupportAccessAction {
  return {
    type: ActionTypes.SUPPORT_ACCESS_REVOKING,
  }
}

function revokedSupportAccess(companyID: string): SupportAccessAction {
  return {
    type: ActionTypes.SUPPORT_ACCESS_REVOKED,
    companyID,
  }
}

function failedRevokingSupportAccess(companyID: string, error: Error): SupportAccessAction {
  return {
    type: ActionTypes.SUPPORT_ACCESS_REVOKE_FAILED,
    error,
    companyID,
  }
}

function loadingSupportCompanies(): CompanyAction {
  return {
    type: ActionTypes.SUPPORT_COMPANIES_LOADING,
  }
}

function loadedSupportCompanies(companies: Company[], doNotSetActiveCompany = false): CompanyAction {
  return {
    type: ActionTypes.SUPPORT_COMPANIES_LOADED,
    companies,
    companyID: doNotSetActiveCompany ? undefined : getActiveCompany(),
  }
}

function failedLoadingSupportCompanies(error: Error): CompanyAction {
  return {
    type: ActionTypes.SUPPORT_COMPANIES_LOAD_FAILED,
    error,
  }
}

export function grantSupportAccess() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<SupportAccess | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(grantingSupportAccess())
    return postSupportAccess(companyID)
      .then((res) => {
        dispatch(grantedSupportAccess(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedGrantingSupportAccess(companyID, e))
        }
      })
  }
}

export function revokeSupportAccess() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(revokingSupportAccess())
    return deleteSupportAccess(companyID)
      .then(() => {
        dispatch(revokedSupportAccess(companyID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedRevokingSupportAccess(companyID, e))
        }
      })
  }
}

export function loadSupportAccess() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<SupportAccess | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingSupportAccess())
    return getSupportAccess(companyID)
      .then((res) => {
        dispatch(loadedSupportAccess(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          if (e.type === 'NotFound') {
            dispatch(loadedSupportAccess(companyID, {}))
          } else {
            dispatch(failedLoadingSupportAccess(companyID, e))
          }
        }
      })
  }
}

export function getSupportCompanies() {
  return (dispatch: React.Dispatch<any>): Promise<Company[] | void> => {
    dispatch(loadingSupportCompanies())
    return fetchSupportCompanies()
      .then((res) => {
        dispatch(loadedSupportCompanies(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSupportCompanies(e))
        }
      })
  }
}

export function getSupportCompaniesThenLoadCompanies(regularCompanies: Company[], doNotSetActiveCompany = false) {
  return (dispatch: React.Dispatch<any>): Promise<Company[] | void> => {
    dispatch(loadingSupportCompanies())
    return fetchSupportCompanies()
      .then((res) => {
        dispatch(loadedSupportCompanies(res.data, doNotSetActiveCompany))
        dispatch(loadedCompanies(regularCompanies, doNotSetActiveCompany))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSupportCompanies(e))
        }
      })
  }
}
