import React, { ReactElement } from 'react'

import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import Subtitle from '../../elements/Subtitle'

type Fields = {
  code?: string
}

export type CampaignResult = {
  code: string
}

function PricingPackageCampaignForm(props: FormComponentProps<Fields, CampaignResult>): ReactElement | null {
  const { decorateField } = props
  return (
    <Row className="campaign-code-activation">
      <Col span={12}>
        <Subtitle>{t('pricing_campaign.form_title')}</Subtitle>
        <p>{t('pricing_campaign.form_description')}</p>
      </Col>
      <Col span={10}>
        {decorateField('code', {
          placeholder: t('pricing_campaign.form.code'),
          skipLabel: true,
          validate: (val) => (!val ? t('pricing_campaign.form.validation.code_required') : null),
        })(<Input />)}
      </Col>
      <Col span={2}>
        <Button htmlType="submit" size="large">
          {t('pricing_campaign.form.submit')}
        </Button>
      </Col>
    </Row>
  )
}

export default withValidations<any, Fields, CampaignResult>({
  onSubmit: (values) => ({ code: values.code!.trim() }),
})(PricingPackageCampaignForm)
