import React from 'react'

import { fetchAvailableDataIntegrations } from '../api/available-data-integrations'
import ActionTypes from '../constants/action-types'
import AvailableDataIntegration from '../model/availableDataIntegration'
import { AvailableDataIntegrationAction } from '../reducers/availableDataIntegrations'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingAvailableDataIntegrations(): AvailableDataIntegrationAction {
  return {
    type: ActionTypes.AVAILABLE_DATA_INTEGRATIONS_LOADING,
  }
}
function loadedAvailableDataIntegrations(
  companyID: string,
  availableDataIntegrations: AvailableDataIntegration[]
): AvailableDataIntegrationAction {
  return {
    type: ActionTypes.AVAILABLE_DATA_INTEGRATIONS_LOADED,
    companyID,
    availableDataIntegrations,
  }
}
function failedLoadingAvailableDataIntegrations(companyID: string, error: Error): AvailableDataIntegrationAction {
  return {
    type: ActionTypes.AVAILABLE_DATA_INTEGRATIONS_LOAD_FAILED,
    companyID,
    error,
  }
}

export function getAvailableDataIntegrations() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AvailableDataIntegration[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingAvailableDataIntegrations())
    return fetchAvailableDataIntegrations(companyID)
      .then((res) => {
        dispatch(loadedAvailableDataIntegrations(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingAvailableDataIntegrations(companyID, e))
        }
      })
  }
}
