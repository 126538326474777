import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import SupplementAdjustment from '../model/supplementAdjustment'
import { ReducerAction } from '../utils/reducer-utils'

export interface SupplementAdjustmentReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  supplementAdjustments: List<SupplementAdjustment>
  error: Error | null
}

const Params = Record<SupplementAdjustmentReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  supplementAdjustments: List<SupplementAdjustment>(),
  error: null,
})

export interface SupplementAdjustmentAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  supplementAdjustments?: SupplementAdjustment[]
  supplementAdjustment?: SupplementAdjustment
  supplementAdjustmentID?: string
}

export default (
  state: Record<SupplementAdjustmentReducer> = Params(),
  action: SupplementAdjustmentAction = { type: '' }
): Record<SupplementAdjustmentReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.SUPPLEMENT_ADJUSTMENTS_LOADING:
      return Params({
        companyID: action.companyID,
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.SUPPLEMENT_ADJUSTMENTS_LOADED:
      return Params({
        companyID: action.companyID,
        employeeID: action.employeeID,
        loaded: true,
        supplementAdjustments: List<SupplementAdjustment>(action.supplementAdjustments),
      })
    case ActionTypes.SUPPLEMENT_ADJUSTMENTS_LOAD_FAILED:
      return Params({
        companyID: action.companyID,
        employeeID: action.employeeID,
        error: action.error,
      })
    case ActionTypes.SUPPLEMENT_ADJUSTMENTS_ADDING:
      return state.set('saving', true).set('loaded', false) // first be loaded when socket returns them
    case ActionTypes.SUPPLEMENT_ADJUSTMENTS_ADDED:
      if (!action.supplementAdjustment) {
        return state
      }
      idx = state
        .get('supplementAdjustments')
        .findIndex((item) => !!action.supplementAdjustment && item.id === action.supplementAdjustment.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('supplementAdjustments', state.get('supplementAdjustments').set(idx, action.supplementAdjustment))
      }
      return state
        .set('saving', false)
        .set('supplementAdjustments', state.get('supplementAdjustments').push(action.supplementAdjustment))
    case ActionTypes.SUPPLEMENT_ADJUSTMENTS_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)
    case ActionTypes.SUPPLEMENT_ADJUSTMENTS_UPDATING:
      return state.set('saving', true).set('loaded', false) // first be loaded when socket returns them
    case ActionTypes.SUPPLEMENT_ADJUSTMENTS_UPDATED:
      if (!action.supplementAdjustment) {
        return state
      }
      // only update for this employee
      if (!state.get('employeeID') || action.supplementAdjustment.employeeID !== state.get('employeeID')) {
        return state
      }
      idx = state
        .get('supplementAdjustments')
        .findIndex((item) => item.id === action.supplementAdjustmentID && item.employeeID === action.employeeID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state
        .set('saving', false)
        .set('supplementAdjustments', state.get('supplementAdjustments').set(idx, action.supplementAdjustment))
    case ActionTypes.SUPPLEMENT_ADJUSTMENTS_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)
    case ActionTypes.SUPPLEMENT_ADJUSTMENTS_DELETING:
      return state.set('saving', true)
    case ActionTypes.SUPPLEMENT_ADJUSTMENTS_DELETED:
      idx = state.get('supplementAdjustments').findIndex((item) => item.id === action.supplementAdjustmentID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('supplementAdjustments', state.get('supplementAdjustments').delete(idx))
    case ActionTypes.SUPPLEMENT_ADJUSTMENTS_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
