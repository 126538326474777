import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import { ReducerAction } from '../utils/reducer-utils'

export interface EmployeeInviteReducer {
  employeeID: string | undefined
  sending: boolean
  deleting: boolean
  error: Error | null
}

const Params = Record<EmployeeInviteReducer>({
  employeeID: undefined,
  sending: false,
  deleting: false,
  error: null,
})

export interface EmployeeInviteAction extends ReducerAction {
  employeeID?: string
}

export default (
  state: Record<EmployeeInviteReducer> = Params(),
  action: EmployeeInviteAction = { type: '' }
): Record<EmployeeInviteReducer> => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_INVITE_SENDING:
      return state.set('sending', true).set('error', null).set('employeeID', undefined)
    case ActionTypes.EMPLOYEE_INVITE_SENT:
      return state.set('sending', false).set('error', null).set('employeeID', action.employeeID)
    case ActionTypes.EMPLOYEE_INVITE_SEND_FAILED:
      return state.set('sending', false).set('error', action.error || null)

    case ActionTypes.EMPLOYEE_USER_DELETING:
      return state.set('deleting', true).set('error', null)
    case ActionTypes.EMPLOYEE_USER_DELETE:
      return state.set('deleting', false).set('error', null)
    case ActionTypes.EMPLOYEE_USER_DELETE_FAILED:
      return state.set('deleting', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
