import React from 'react'

import { patchAccountingDimensions } from '../api/accounting-dimensions'
import {
  AccountPlanUpdate,
  fetchCompanyAccountingIntegration,
  fetchCompanyAccountPlans,
  postCompanyAccountingIntegrationSync,
  putCompanyAccountingIntegration,
  putCompanyAccountingIntegrationDaybooks,
  putCompanyAccountingIntegrationOrganizations,
  putCompanyAccountPlan,
} from '../api/accounting-integration'
import ActionTypes from '../constants/action-types'
import AccountingIntegration, { AccountPlan, CostCenterAccounting } from '../model/accountingIntegration'
import { CompanyAccountingIntegrationAction } from '../reducers/companyAccountingIntegration'
import { CompanyAccountPlanAction } from '../reducers/companyAccountPlans'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingCompanyAccountingIntegration(): CompanyAccountingIntegrationAction {
  return {
    type: ActionTypes.ACCOUNTING_INTEGRATION_LOADING,
  }
}
function loadedCompanyAccountingIntegration(
  companyID: string,
  accountingIntegration: AccountingIntegration
): CompanyAccountingIntegrationAction {
  return {
    type: ActionTypes.ACCOUNTING_INTEGRATION_LOADED,
    companyID,
    accountingIntegration,
  }
}
function failedLoadingCompanyAccountingIntegration(
  companyID: string,
  error: Error
): CompanyAccountingIntegrationAction {
  return {
    type: ActionTypes.ACCOUNTING_INTEGRATION_LOAD_FAILED,
    error,
    companyID,
  }
}

function updatingCompanyAccountingIntegration(companyID: string): CompanyAccountingIntegrationAction {
  return {
    type: ActionTypes.ACCOUNTING_INTEGRATION_UPDATING,
    companyID,
  }
}
export function updatedCompanyAccountingIntegration(
  companyID: string,
  accountingIntegration: AccountingIntegration
): CompanyAccountingIntegrationAction {
  return {
    type: ActionTypes.ACCOUNTING_INTEGRATION_UPDATED,
    companyID,
    accountingIntegration,
  }
}
function failedUpdatingCompanyAccountingIntegration(
  companyID: string,
  error: Error
): CompanyAccountingIntegrationAction {
  return {
    type: ActionTypes.ACCOUNTING_INTEGRATION_UPDATE_FAILED,
    error,
    companyID,
  }
}

function loadingCompanyAccountPlans(): CompanyAccountingIntegrationAction {
  return {
    type: ActionTypes.ACCOUNT_PLANS_LOADING,
  }
}
function loadedCompanyAccountPlans(companyID: string, data: AccountPlan): CompanyAccountPlanAction {
  return {
    type: ActionTypes.ACCOUNT_PLANS_LOADED,
    companyID,
    accounts: data.accounts,
    mapping: data.mapping,
  }
}
function failedLoadingCompanyAccountPlans(companyID: string, error: Error): CompanyAccountPlanAction {
  return {
    type: ActionTypes.ACCOUNT_PLANS_LOAD_FAILED,
    error,
    companyID,
  }
}

function updatingCompanyAccountPlans(companyID: string): CompanyAccountPlanAction {
  return {
    type: ActionTypes.ACCOUNT_PLANS_UPDATING,
    companyID,
  }
}
function updatedCompanyAccountPlans(companyID: string, data: AccountPlan): CompanyAccountPlanAction {
  return {
    type: ActionTypes.ACCOUNT_PLANS_UPDATED,
    companyID,
    accounts: data.accounts,
    mapping: data.mapping,
  }
}
function failedUpdatingCompanyAccountPlans(companyID: string, error: Error): CompanyAccountPlanAction {
  return {
    type: ActionTypes.ACCOUNT_PLANS_UPDATE_FAILED,
    error,
    companyID,
  }
}

function syncingCompanyAccountingIntegration(companyID: string): CompanyAccountingIntegrationAction {
  return {
    type: ActionTypes.ACCOUNTING_INTEGRATION_SYNCING,
    companyID,
  }
}
export function syncedCompanyAccountingIntegration(companyID: string): CompanyAccountingIntegrationAction {
  return {
    type: ActionTypes.ACCOUNTING_INTEGRATION_SYNCED,
    companyID,
  }
}
function failedSyncingCompanyAccountingIntegration(
  companyID: string,
  error: Error
): CompanyAccountingIntegrationAction {
  return {
    type: ActionTypes.ACCOUNTING_INTEGRATION_SYNC_FAILED,
    error,
    companyID,
  }
}

export function getCompanyAccountingIntegration() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AccountingIntegration | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingCompanyAccountingIntegration())
    return fetchCompanyAccountingIntegration(companyID)
      .then((res) => {
        dispatch(loadedCompanyAccountingIntegration(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompanyAccountingIntegration(companyID, e))
        }
      })
  }
}

export function updateCompanyAccountingIntegration(state: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AccountingIntegration | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingCompanyAccountingIntegration(companyID))
    return putCompanyAccountingIntegration(state)
      .then((res) => {
        dispatch(updatedCompanyAccountingIntegration(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompanyAccountingIntegration(companyID, e))
        }
      })
  }
}

export function setCompanyAccountingIntegrationAccounting(
  companyID: string,
  costCenterAccounting: CostCenterAccounting
) {
  return (dispatch: React.Dispatch<any>): Promise<AccountingIntegration | void> => {
    dispatch(updatingCompanyAccountingIntegration(companyID))
    return patchAccountingDimensions(companyID, costCenterAccounting)
      .then((res) => {
        dispatch(updatedCompanyAccountingIntegration(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompanyAccountingIntegration(companyID, e))
        }
      })
  }
}

export function getCompanyAccountPlans() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AccountPlan | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingCompanyAccountPlans())
    return fetchCompanyAccountPlans(companyID)
      .then((res) => {
        dispatch(loadedCompanyAccountPlans(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompanyAccountPlans(companyID, e))
        }
      })
  }
}

export function updateCompanyAccountPlan(accountPlan: AccountPlanUpdate) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AccountPlan | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingCompanyAccountPlans(companyID))
    return putCompanyAccountPlan(companyID, accountPlan)
      .then((res) => {
        dispatch(updatedCompanyAccountPlans(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompanyAccountPlans(companyID, e))
        }
      })
  }
}

export function syncCompanyAccountingIntegration() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(syncingCompanyAccountingIntegration(companyID))
    return postCompanyAccountingIntegrationSync(companyID)
      .then(() => {
        dispatch(syncedCompanyAccountingIntegration(companyID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedSyncingCompanyAccountingIntegration(companyID, e))
        }
      })
  }
}

export function selectCompanyAccountingDaybook(daybookID?: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AccountingIntegration | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingCompanyAccountingIntegration(companyID))
    return putCompanyAccountingIntegrationDaybooks(companyID, daybookID)
      .then((res) => {
        dispatch(updatedCompanyAccountingIntegration(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompanyAccountingIntegration(companyID, e))
        }
      })
  }
}

export function selectCompanyAccountingOrganization(organizationID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AccountingIntegration | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingCompanyAccountingIntegration(companyID))
    return putCompanyAccountingIntegrationOrganizations(companyID, organizationID)
      .then((res) => {
        dispatch(updatedCompanyAccountingIntegration(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompanyAccountingIntegration(companyID, e))
        }
      })
  }
}
