import Department, { DepartmentCreationFields, DepartmentMutableFields } from '../model/department'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchDepartments(companyID: string): Promise<RequestResponse<Department[]>> {
  return secureRequest('GET', url('/v2/departments', { companyID }))
}

export function postDepartment(
  companyID: string,
  department: DepartmentCreationFields
): Promise<RequestResponse<Department>> {
  return secureRequest('POST', url('/v2/departments'), {
    body: {
      companyID: companyID,
      name: department.name,
    },
  })
}

export function putDepartment(
  companyID: string,
  department: DepartmentMutableFields
): Promise<RequestResponse<Department>> {
  return secureRequest('PUT', url('/v2/departments/' + department.id), {
    body: {
      companyID: companyID,
      name: department.name,
    },
  })
}

export function delDepartment(departmentID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/departments/' + departmentID))
}
