import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import Employee from '../../model/employee'
import Employment from '../../model/employment'
import SalaryCycle from '../../model/salaryCycle'
import SalaryPeriod from '../../model/salaryPeriod'
import SalaryType from '../../model/salaryType'
import { DateFormat } from '../../model/types'
import { Day } from '../../model/types'
import { formatAPIDate, getDate, isSameOrAfter } from '../../utils/date-utils'
import { week } from '../../utils/day-utils'
import { EmployeePayType } from '../../utils/employee-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { getSalaryCyclePeriods } from '../../utils/salary-period-utils'
import { isSalarySupplement } from '../../utils/salary-type-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'
import ContractFirstSalaryPeriod from '../form-elements/ContractFirstSalaryPeriod'
import ContractWorktime, { formWorkCycleToContractWorkCycle } from '../form-elements/ContractWorktime'
import PayForm from './PayForm'
import { RemunerationFields, SalaryDefinitionFields } from './types'

type Props = {
  company: Company
  employee: Employee
  employment: Employment
  salaryCycles: List<SalaryCycle>
  salaryTypes: List<SalaryType>

  payType: EmployeePayType
  validFrom: DateFormat
  salaryCycleID: string
  weeklyHours: number
  weeklyWorkDays: number
  periodWorkHours?: number
  workCycleAnchorDate?: DateFormat
  remuneration: RemunerationFields
}

type Fields = {
  payType: EmployeePayType
  salaryCycleID: string
  validFrom: DateFormat
  salaryPeriod?: SalaryPeriod
  weeklyHours: string
  keepWeekOpen: boolean
  weeklyWorkDays: string
  periodWorkHours?: string
  remuneration: {
    salary: {
      salaryTypeID: string
      title?: string
      rate?: string
      quantity?: string
    }[]
  }
  workCycle: Record<`workDay${Day}`, boolean>[]
  workCycleAnchorDate?: Date
}

export type PayStepResult = {
  payType: EmployeePayType
  salaryCycleID: string
  validFrom: DateFormat
  salaryPeriod?: SalaryPeriod
  weeklyHours: number
  keepWeekOpen: boolean
  workCycle: Day[][]
  workCycleAnchorDate?: DateFormat
  periodWorkHours?: number
  remuneration: {
    salary: SalaryDefinitionFields[]
  }
  step: 'PayStep'
}

function PayStep(props: Props & FormComponentProps<Fields, PayStepResult>): ReactElement | null {
  const { getFieldValue } = props

  return (
    <div>
      <Subtitle>{t('contracts_add.pay_step.title', { name: props.employee.name })}</Subtitle>
      <p>&nbsp;</p>
      {props.getFormError()}
      <ContractFirstSalaryPeriod<Fields>
        decorateField={props.decorateField}
        getFieldValue={props.getFieldValue}
        setFieldValue={props.setFieldValue}
        employment={props.employment}
        salaryCycles={props.salaryCycles}
        salaryCycleID={props.salaryCycleID}
      />
      {getFieldValue('payType') === 'Salaried' && (
        <ContractWorktime<Fields>
          company={props.company}
          decorateField={props.decorateField}
          decorateAnyField={props.decorateAnyField}
          getFieldValue={props.getFieldValue}
          setFieldValue={props.setFieldValue}
          getFieldError={props.getFieldError}
        />
      )}
      <PayForm<Fields>
        decorateAnyField={props.decorateAnyField}
        getFieldValue={props.getFieldValue}
        getAnyFieldValue={props.getAnyFieldValue}
        setFieldValue={props.setFieldValue}
        setAnyFieldValue={props.setAnyFieldValue}
        getAnyFieldError={props.getAnyFieldError}
        disableNewRows={getFieldValue('payType') === 'Commissioned'}
        salaryCycles={props.salaryCycles}
        salaryTypes={props.salaryTypes}
      />
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('contracts_add.pay_step.submit')}
          </Button>
          <Button size="large" onClick={props.goBack}>
            {t('contracts_add.pay_step.back')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, PayStepResult>({
  mapPropsToFields: (props) => {
    let validFrom = props.validFrom
    const salaryPeriod = getSalaryCyclePeriods(props.salaryCycles, props.salaryCycleID).find(
      (salaryPeriod) =>
        isSameOrAfter(getDate(salaryPeriod.dispositionDate), getDate(props.employment.startDate)) &&
        isSameOrAfter(getDate(salaryPeriod.dispositionDate), getDate())
    )
    if (salaryPeriod) {
      validFrom = salaryPeriod.start
    }
    return {
      payType: props.payType,
      salaryCycleID: props.salaryCycleID,
      validFrom,
      salaryPeriod,
      weeklyHours: formatInputNumber(props.weeklyHours),
      keepWeekOpen: false,
      weeklyWorkDays: formatInputNumber(props.weeklyWorkDays),
      workCycle: [
        {
          workDayMonday: true,
          workDayTuesday: true,
          workDayWednesday: true,
          workDayThursday: true,
          workDayFriday: true,
          workDaySaturday: false,
          workDaySunday: false,
        },
      ],
      workCycleAnchorDate: props.workCycleAnchorDate ? getDate(props.workCycleAnchorDate) : undefined,
      periodWorkHours: props.periodWorkHours ? formatInputNumber(props.periodWorkHours) : undefined,
      remuneration: {
        salary: props.remuneration.salary.map((row) => ({
          salaryTypeID: row.salaryTypeID,
          title: row.title,
          rate: row.rate ? formatInputNumber(row.rate) : undefined,
          quantity: row.quantity ? formatInputNumber(row.quantity) : undefined,
        })),
      },
    }
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    if (['weeklyHours', 'weeklyWorkDays'].indexOf(key) !== -1 || key.match(/^remuneration\.salary\.\d+\.rate$/)) {
      setByPath(values, key, formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })))
    } else if (key.match(/^remuneration\.salary\.\d+\.quantity$/)) {
      setByPath(
        values,
        key,
        val ? formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })) : ''
      )
    } else {
      setByPath(values, key, val)
    }
    if (key === 'weeklyWorkDays' && parseInputNumber(val as string) !== 5 && !allValues.keepWeekOpen) {
      const num = parseInputNumber(val as string)
      if (num >= 1 && num <= 7) {
        // only handle valid ranges
        week.forEach((day, i) => {
          setByPath(values, 'workDay' + day, i < num)
        })
      }
    }
    return values
  },
  onSubmit: (values, props) => ({
    ...values,
    weeklyHours: forceParseInputNumber(values.weeklyHours),
    weeklyWorkDays: forceParseInputNumber(values.weeklyWorkDays),
    periodWorkHours: values.periodWorkHours ? forceParseInputNumber(values.periodWorkHours) : undefined,
    workCycle: formWorkCycleToContractWorkCycle(values.workCycle),
    workCycleAnchorDate: values.workCycleAnchorDate ? formatAPIDate(values.workCycleAnchorDate) : undefined,
    remuneration: {
      salary: values.remuneration.salary.map((row) => ({
        ...row,
        title: row.title || undefined,
        rate: forceParseInputNumber(row.rate),
        quantity:
          isSalarySupplement(props.salaryTypes.find((salaryType) => salaryType.id === row.salaryTypeID)) && row.quantity
            ? forceParseInputNumber(row.quantity)
            : undefined,
      })),
    },
    step: 'PayStep',
  }),
})(PayStep)
