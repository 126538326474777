import { GroupUserType } from '../model/companyGroup'
import CompanyUser, { UserPermission } from '../model/companyUser'
import { DepartmentUserMutableFields } from '../model/departmentUser'
import UserInvite from '../model/userInvite'
import UserCompanyType from '../types/user-company-type'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCompanyUsers(
  companyID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<CompanyUser[]>> {
  return secureRequest('GET', url('/v2/companyUsers', { companyID, limit, offset }))
}

export function delCompanyUser(companyID: string, userID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/companyUsers', { companyID, userID }))
}

export function fetchUserInvites(companyID: string): Promise<RequestResponse<UserInvite[]>> {
  return secureRequest('GET', url('/v2/userInvites', { companyID }))
}

export function fetchCompanyGroupUserInvites(companyGroupID: string): Promise<RequestResponse<UserInvite[]>> {
  return secureRequest('GET', url('/v2/userInvites', { companyGroupID }))
}

export function postUserInvite(
  companyID: string,
  email: string,
  userType: UserCompanyType,
  permissions: UserPermission[],
  departments: DepartmentUserMutableFields[] | undefined = undefined,
  name?: string,
  phoneNumberCountryCode?: string,
  phoneNumber?: string
): Promise<RequestResponse<UserInvite | undefined>> {
  const body = {
    companyID,
    email,
    userType,
    departments: departments || [],
    permissions,
    name,
    phoneNumberCountryCode,
    phoneNumber,
  }
  if (userType === UserCompanyType.DEPARTMENT_MANAGER) {
    body.userType = UserCompanyType.INTERNAL_SALARY_ADMINISTRATOR
  }
  if (userType === UserCompanyType.APPROVE_ONLY) {
    body.userType = UserCompanyType.INTERNAL_SALARY_ADMINISTRATOR
  }
  if (userType === UserCompanyType.READ_ONLY) {
    body.userType = UserCompanyType.INTERNAL_SALARY_ADMINISTRATOR
  }
  return secureRequest('POST', url('/v2/userInvites'), { body })
}

export function postUserInviteCompanyGroup(
  companyGroupID: string,
  email: string,
  groupUserType: GroupUserType,
  permissions: UserPermission[]
): Promise<RequestResponse<UserInvite>> {
  const body = {
    companyGroupID,
    email,
    groupUserType,
    permissions,
  }
  return secureRequest('POST', url('/v2/userInvites'), { body })
}

export function delUserInvite(companyID: string, email: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/userInvites', { companyID, email }))
}

export function delCompanyGroupUserInvite(companyGroupID: string, email: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/userInvites', { companyGroupID, email }))
}

export function postCompanyUserPermission(
  userCompanyID: string,
  permission: UserPermission
): Promise<RequestResponse<CompanyUser>> {
  return secureRequest('POST', url('/v2/userCompanyPermissions', { userCompanyID }), {
    body: { permission },
  })
}

export function delCompanyUserPermission(permissionID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/userCompanyPermissions/' + permissionID))
}
