import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Contract from '../model/contract'
import { ReducerAction } from '../utils/reducer-utils'

interface HashMap {
  [key: string]: boolean
}

export interface ContractReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  contracts: List<Contract>
  hashMap: HashMap
  error: Error | null
}

const Params = Record<ContractReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  contracts: List<Contract>(),
  hashMap: {},
  error: null,
})

export interface ContractAction extends ReducerAction {
  companyID?: string
  contracts?: Contract[]
  contract?: Contract
  contractID?: string
}

export default (
  state: Record<ContractReducer> = Params(),
  action: ContractAction = { type: '' }
): Record<ContractReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  const contracts = state.get('contracts').toArray()
  let contract = null
  const hashMap = state.get('hashMap')
  switch (action.type) {
    case ActionTypes.EMPLOYEE_CONTRACT_LOADING:
      return Params({
        companyID: state.get('companyID'),
        hashMap: {},
        loading: true,
      })
    case ActionTypes.EMPLOYEE_CONTRACT_LOADED:
    case ActionTypes.EMPLOYEE_CONTRACT_LOADED_PARTIAL:
      if (!action.contracts) {
        return state
      }
      action.contracts.forEach((contract) => {
        if (hashMap[contract.id]) {
          return
        }
        contracts.push(contract)
        hashMap[contract.id] = true
      })
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        loading: action.type === ActionTypes.EMPLOYEE_CONTRACT_LOADED_PARTIAL,
        contracts: List<Contract>(action.contracts),
        hashMap,
      })
    case ActionTypes.EMPLOYEE_CONTRACT_LOAD_FAILED:
      return state
        .set('loaded', false)
        .set('loading', false)
        .set('error', action.error || null)

    case ActionTypes.EMPLOYEE_CONTRACT_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EMPLOYEE_CONTRACT_ADDED:
      if (!action.contract) {
        return state
      }
      idx = state.get('contracts').findIndex((item) => !!action.contract && item.id === action.contract.id)
      if (idx !== -1) {
        return state.set('saving', false).set('contracts', state.get('contracts').set(idx, action.contract))
      }
      hashMap[action.contract.id] = true
      return state
        .set('saving', false)
        .set('contracts', state.get('contracts').push(action.contract))
        .set('hashMap', hashMap)
    case ActionTypes.EMPLOYEE_CONTRACT_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.EMPLOYEE_CONTRACT_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EMPLOYEE_CONTRACT_UPDATED:
      if (!action.contract) {
        return state
      }
      idx = state.get('contracts').findIndex((item) => item.id === action.contractID)
      if (idx !== -1) {
        return state.set('saving', false).set('contracts', state.get('contracts').set(idx, action.contract))
      }
      return state.set('saving', false).set('contracts', state.get('contracts').push(action.contract))
    case ActionTypes.EMPLOYEE_CONTRACT_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.EMPLOYEE_CONTRACT_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EMPLOYEE_CONTRACT_DELETED:
      idx = state.get('contracts').findIndex((item) => item.id === action.contractID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      contract = contracts[idx]
      if (contract && contract.id) {
        hashMap[contract.id] = false
      }
      return state.set('saving', false).set('contracts', state.get('contracts').delete(idx)).set('hashMap', hashMap)
    case ActionTypes.EMPLOYEE_CONTRACT_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
