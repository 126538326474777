import { addDays, addMonths, getWeek, isSameDay, startOfDay } from 'date-fns'
import { List } from 'immutable'
import React, { CSSProperties, ReactElement, ReactNode, useEffect, useState } from 'react'
import { Link } from 'react-router'

import Company from '../../../model/company'
import CompanyUser from '../../../model/companyUser'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import TimeRegistration from '../../../model/timeRegistration'
import { DateFormat } from '../../../model/types'
import { VacationCalendarReducer } from '../../../reducers/vacationCalendars'
import { buildDate, formatAPIDate, formatDate, getDate, isTimeBefore } from '../../../utils/date-utils'
import { formatDisplayNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import { formatVacationDay } from '../../../utils/vacation-utils'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import Button from '../button'
import Card from '../card'
import EmployeeFilter, { FilterContainer, filterEmployee } from '../EmployeeFilter'
import Col from '../grid/col'
import Row from '../grid/row'
import Popover from '../popover'

type CalendarLegend = {
  className: string
  backgroundColor?: string
  title: string
}

type TimeRegistrationFormat = {
  title: string
  additionalClass?: string
  backgroundColor?: string
  allowGradient: boolean
  percentage: number
  amount: number
  alwaysDisplayAmount?: boolean
}

type Props = {
  timeRegistrationFilter: (reg: TimeRegistration) => boolean
  employeeFilter?: (employee: Employee) => boolean
  employeeLink?: (employeeID: string) => string
  periodDragMode?: boolean
  approveAllButtonText?: string
  approveAllConfirmText?: (needApproval: number, currentYear: number, currentMonth: number) => string
  legends?: CalendarLegend[]
  baseCellClassName?: string
  instructionText: ReactNode
  formatRegistrationCell: (timeRegs: TimeRegistration[], employee: Employee, date: Date) => TimeRegistrationFormat
  formatRegistrationContextMenuItem: (timeReg: TimeRegistration) => string
  newTypeText?: string
  allowMoreRegistrationsPerDay: boolean
  allowRegistrationsOnHoliday?: boolean

  onNewPeriod?: (employeeID: string, fromDate: DateFormat, toDate: DateFormat) => void
  onEditRegistration?: (employeeID: string, timeRegistrationID: string) => void
  onApproveTimeRegistrations?: (timeRegistrationIDs: string[]) => void
  onCellClick?: (employeeID: string, date: Date) => void

  company: Company
  companyUser?: CompanyUser
  employees: List<Employee>
  departments: List<Department>
  timeRegistrations: List<TimeRegistration>
  vacationCalendars: VacationCalendarReducer

  getVacationCalendarYear: (companyID: string, year: number) => void
}

type MouseDrag = {
  active: boolean
  employeeID?: string
  startDay?: number
  fromDay?: number
  toDay?: number
}

export function Calendar(props: Props): ReactElement | null {
  const [currentYear, setCurrentYear] = useState(getDate().getFullYear())
  const [currentMonth, setCurrentMonth] = useState(getDate().getMonth())
  const [filter, setFilter] = useState<FilterContainer>({ searchQuery: '' })
  const [mouseDragging, setMouseDragging] = useState<MouseDrag>({ active: false })

  const { vacationCalendars, company, getVacationCalendarYear } = props

  useEffect(() => {
    if (
      (!vacationCalendars.years[currentYear] && !vacationCalendars.loading) ||
      (!vacationCalendars.loading && !vacationCalendars.loaded)
    ) {
      getVacationCalendarYear(company.id, currentYear)
    }
  }, [vacationCalendars, company, currentYear, getVacationCalendarYear])

  const setMonth = (dir: number) => {
    const result = currentMonth + dir
    if (result < 0) {
      setCurrentMonth(11)
      setCurrentYear((prev) => prev - 1)
    } else if (result > 11) {
      setCurrentMonth(0)
      setCurrentYear((prev) => prev + 1)
    } else {
      setCurrentMonth((prev) => prev + dir)
    }
  }

  const today = () => {
    setCurrentMonth(getDate().getMonth())
    setCurrentYear(getDate().getFullYear())
  }

  const getEmployees = (): Employee[] => {
    return props.employees
      .filter((employee) => {
        if (props.employeeFilter && !props.employeeFilter(employee)) {
          return false
        }
        if (
          employee.affiliationType === 'Freelancer' ||
          employee.employmentStatus === 'Terminated' ||
          employee.onboardingState !== 'Final'
        ) {
          return false
        }
        if (!employee.activeContract && !employee.earliestMutableContract) {
          return false
        }
        return filterEmployee(employee, filter)
      })
      .toArray()
  }

  const getDates = (): number[] => {
    let tmpDate = buildDate(currentYear, currentMonth, 1)
    const dates: number[] = []
    while (tmpDate.getMonth() === currentMonth) {
      dates.push(tmpDate.getDate())
      tmpDate = addDays(tmpDate, 1)
    }
    return dates
  }

  const currentVacationCalendar = vacationCalendars.years[currentYear]

  const holidays: Record<string, boolean> = {}
  const holidayNames: Record<string, string> = {}

  const dayToKey = (day: number): string => {
    return `${currentYear}${currentMonth}${day}`
  }

  const isHoliday = (day: number): boolean => {
    if (!currentVacationCalendar || !currentVacationCalendar.days) {
      return false
    }
    const key = dayToKey(day)
    let isHoliday = holidays[key]
    if (isHoliday !== undefined) {
      return isHoliday
    }
    const checkDate = buildDate(currentYear, currentMonth, day)
    const dayOfWeek = checkDate.getDay()
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      holidays[key] = true
      return true
    }
    isHoliday = currentVacationCalendar.days.some((date) => date.date && isSameDay(getDate(date.date), checkDate))
    holidays[key] = isHoliday
    return isHoliday
  }

  const holidayName = (day: number): string => {
    if (!currentVacationCalendar || !currentVacationCalendar.days) {
      return ''
    }
    const key = dayToKey(day)
    let holidayName = holidayNames[key]
    if (holidayName !== undefined) {
      return holidayName
    }
    holidayName = ''
    const checkDate = buildDate(currentYear, currentMonth, day)
    const holiday = currentVacationCalendar.days.find((date) => date.date && isSameDay(getDate(date.date), checkDate))
    if (holiday) {
      holidayName = formatVacationDay(holiday)
    }
    holidayNames[key] = holidayName
    return holidayName
  }

  const dayToWeekDayShortHand = (day: number): string => {
    const weekDay = buildDate(currentYear, currentMonth, day).getDay()
    switch (weekDay) {
      case 1:
        return t('calendar.week.short.monday')
      case 2:
        return t('calendar.week.short.tuesday')
      case 3:
        return t('calendar.week.short.wednesday')
      case 4:
        return t('calendar.week.short.thursday')
      case 5:
        return t('calendar.week.short.friday')
      case 6:
        return t('calendar.week.short.saturday')
      case 0:
        return t('calendar.week.short.sunday')
      default:
        return ''
    }
  }

  const isOddWeek = (): boolean => {
    const day = buildDate(currentYear, currentMonth, 1).getDay()
    return day === 2 || day === 4 || day === 6
  }

  if (props.periodDragMode) {
    document.onmouseup = () => {
      if (!mouseDragging.active) {
        return
      }
      if (mouseDragging.employeeID && mouseDragging.fromDay && mouseDragging.toDay) {
        const fromDate = formatAPIDate(buildDate(currentYear, currentMonth, mouseDragging.fromDay))
        const toDate = formatAPIDate(buildDate(currentYear, currentMonth, mouseDragging.toDay))
        if (props.onNewPeriod) {
          props.onNewPeriod(mouseDragging.employeeID, fromDate, toDate)
        }
      }
      setMouseDragging({ active: false })
    }
  }

  const mouseDown = (e: React.MouseEvent<HTMLTableDataCellElement>) => {
    if (!props.periodDragMode) {
      return
    }
    const s = e.currentTarget?.id.split('_')
    const employeeID = s[0]
    const day = s.length === 2 ? parseInt(s[1]) : 0
    if (!employeeID || !day) {
      return // do nothing
    }
    setMouseDragging({ active: true, employeeID, startDay: day, fromDay: day, toDay: day })
  }

  const mouseOver = (e: React.MouseEvent<HTMLTableDataCellElement>) => {
    if (!props.periodDragMode || !mouseDragging.active) {
      return // nothing to do
    }
    const s = e.currentTarget?.id.split('_')
    const day = s.length === 2 ? parseInt(s[1]) : 0
    if (!day) {
      return // nothing to do
    }
    setMouseDragging((prev) => {
      const next = {
        active: true,
        employeeID: prev.employeeID,
        startDay: prev.startDay,
        fromDay: prev.fromDay,
        toDay: prev.toDay,
      }
      if (day === next.startDay) {
        next.fromDay = day
        next.toDay = day
      } else if (next.startDay && day < next.startDay) {
        next.fromDay = day
      } else {
        next.toDay = day
      }
      return next
    })
  }

  const cellClick = (employeeID: string, day?: number) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (!props.onCellClick) {
        return
      }
      if (!day) {
        const s = e.currentTarget?.id.split('_')
        day = s.length === 2 ? parseInt(s[1]) : 0
        if (!day) {
          return // nothing to do
        }
      }
      props.onCellClick(employeeID, buildDate(currentYear, currentMonth, day))
    }
  }

  const selectedCell = (employeeID: string, day: number): string => {
    if (!props.periodDragMode) {
      return ''
    }
    if (mouseDragging.active) {
      if (mouseDragging.employeeID === employeeID && mouseDragging.fromDay !== undefined) {
        let toDay = mouseDragging.toDay
        const fromDay = mouseDragging.fromDay
        if (!toDay) {
          toDay = mouseDragging.fromDay
        }
        if (fromDay <= day && day <= toDay) {
          return ' selected'
        }
      }
    }
    return ''
  }

  const editRegistration = (employeeID: string, timeRegID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (props.onEditRegistration) {
        props.onEditRegistration(employeeID, timeRegID)
      }
    }
  }

  const getLegends = (): CalendarLegend[] => {
    let legends = []
    if (props.onApproveTimeRegistrations) {
      legends.push({ className: 'need-approval', title: t('calendar.legend.need_approval') })
    }
    legends = [...legends, ...(props.legends ?? [])]
    legends.push({ className: 'employee-holiday', title: t('calendar.legend.employee_holiday') })
    return legends
  }

  if (!currentVacationCalendar || !currentVacationCalendar.days || !vacationCalendars.loaded) {
    return <LoadingOverlay />
  }

  const dates = getDates()

  type Week = {
    span: number
    no: number
  }

  const startMonth = startOfDay(buildDate(currentYear, currentMonth))
  const endMonth = startOfDay(addMonths(startMonth, 1))

  const timeRegistrations: Record<string, Record<number, TimeRegistration[]>> = {}
  const needApproval: string[] = []
  props.timeRegistrations
    .filter((reg) => {
      if (!props.timeRegistrationFilter(reg)) {
        return false
      }
      const date = startOfDay(getDate(reg.date))
      return !isTimeBefore(date, startMonth) && isTimeBefore(date, endMonth)
    })
    .forEach((reg) => {
      const d = getDate(reg.date).getDate()

      if (!timeRegistrations[reg.employeeID]) {
        timeRegistrations[reg.employeeID] = { [d]: [reg] }
      } else {
        if (!timeRegistrations[reg.employeeID][d]) {
          timeRegistrations[reg.employeeID][d] = [reg]
        } else {
          timeRegistrations[reg.employeeID][d].push(reg)
        }
      }
      if (!reg.approved) {
        needApproval.push(reg.id)
      }
    })

  return (
    <Card className="sally-calendar">
      <Row>
        <Col span={24} className="sally-calendar-nav">
          <Button className="btn-previous" onClick={() => setMonth(-1)}>
            {t('calendar.navigation.previous_month')}
          </Button>
          <Button className="btn-today" onClick={() => today()}>
            {t('calendar.navigation.today')}
          </Button>
          <Button className="btn-next" onClick={() => setMonth(1)}>
            {t('calendar.navigation.next_month')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="sally-calendar-filters">
          {props.onApproveTimeRegistrations && needApproval.length > 0 && (
            <Button
              type="primary"
              className="gtm-sally-calendar-approve-all"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                if (!props.onApproveTimeRegistrations) {
                  return
                }
                if (props.approveAllConfirmText) {
                  if (window.confirm(props.approveAllConfirmText(needApproval.length, currentYear, currentMonth))) {
                    props.onApproveTimeRegistrations(needApproval)
                  }
                } else {
                  props.onApproveTimeRegistrations(needApproval)
                }
              }}
            >
              {props.approveAllButtonText ? (
                props.approveAllButtonText
              ) : (
                <>
                  {t('calendar.approval_all_button_text', {
                    month: formatDate(buildDate(currentYear, currentMonth), t('date.month_of_year')),
                  })}
                </>
              )}
            </Button>
          )}
          <EmployeeFilter
            departments={props.departments}
            companyUser={props.companyUser}
            onFilterChange={(filter) => setFilter(filter)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="sally-calendar-legend">
          {getLegends().map((legend, i) => {
            return (
              <div className="legend" key={`legend-${i}`}>
                <div
                  className={`colour ${legend.className}`}
                  style={legend.backgroundColor ? { backgroundColor: legend.backgroundColor } : {}}
                  title={legend.title}
                >
                  {' '}
                </div>
                <label>{legend.title}</label>
              </div>
            )
          })}
        </Col>
      </Row>
      <Row>
        <Col span={24} className="sally-calendar-help">
          {props.instructionText}
        </Col>
      </Row>
      <table className="table-sally-calendar">
        <thead>
          <tr className="header-month-row">
            <th className="header-label"> </th>
            <th colSpan={31}>{formatDate(buildDate(currentYear, currentMonth), t('date.month_of_year'))}</th>
          </tr>
          <tr className={'header-week-row' + (isOddWeek() ? ' header-week-odd' : '')}>
            <th className="header-label">{t('calendar.table.header.week')}</th>
            {dates
              .reduce((weeks: Week[], date) => {
                const weekNo = getWeek(buildDate(currentYear, currentMonth, date), {
                  weekStartsOn: 1,
                  firstWeekContainsDate: 4,
                })
                if (weeks.length === 0) {
                  return [{ span: 1, no: weekNo }]
                }
                if (weeks[weeks.length - 1].no === weekNo) {
                  weeks[weeks.length - 1].span = weeks[weeks.length - 1].span + 1
                  return weeks
                }
                weeks.push({ span: 1, no: weekNo })
                return weeks
              }, [])
              .map((week) => {
                return (
                  <th colSpan={week.span} key={week.no}>
                    {week.no}
                  </th>
                )
              })}
          </tr>
          <tr className="header-date-row">
            <th className="header-label">{t('calendar.table.header.date')}</th>
            {dates.map((day) => {
              return (
                <th className="header-date" key={day}>
                  {day}
                </th>
              )
            })}
            {dates.length < 31 &&
              Array(31 - dates.length)
                .fill(0)
                .map((_, i) => (
                  <th className="header-date-filler" key={dates.length + i}>
                    {' '}
                  </th>
                ))}
          </tr>
          <tr className="header-day-row">
            <th className="header-label">{t('calendar.table.header.day')}</th>
            {dates.map((day) => {
              return (
                <th className="header-day" key={day}>
                  {dayToWeekDayShortHand(day)}
                </th>
              )
            })}
            {dates.length < 31 &&
              Array(31 - dates.length)
                .fill(0)
                .map((_, i) => (
                  <th className="header-date-filler" key={dates.length + i}>
                    {' '}
                  </th>
                ))}
          </tr>
        </thead>
        <tbody className={mouseDragging.active ? 'selecting' : ''}>
          {getEmployees().map((employee) => {
            const employeeRegistrations = timeRegistrations[employee.id]
            const needApproval = employeeRegistrations
              ? Object.values(employeeRegistrations)
                  .reduce((list, sublist) => [...list, ...sublist], [])
                  .filter((reg) => !reg.approved)
                  .map((reg) => reg.id)
              : []
            return (
              <tr
                className={
                  'employee-row' + (mouseDragging.active && mouseDragging.employeeID === employee.id ? ' active' : '')
                }
                key={employee.id}
              >
                <th>
                  {props.employeeLink ? (
                    <Link className="employee-link" to={props.employeeLink(employee.id)}>
                      {employee.name}
                    </Link>
                  ) : (
                    employee.name
                  )}
                  {props.onApproveTimeRegistrations && needApproval.length > 0 && (
                    <DumbLink
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault()
                        if (props.onApproveTimeRegistrations) {
                          props.onApproveTimeRegistrations(needApproval)
                        }
                      }}
                      style={{ float: 'right' }}
                    >
                      {t('calendar.table.approve')}
                    </DumbLink>
                  )}
                </th>
                {dates.map((day) => {
                  const timeRegs = employeeRegistrations && employeeRegistrations[day]
                  if (timeRegs) {
                    const timeReg = timeRegs[0]
                    const {
                      title,
                      additionalClass,
                      backgroundColor,
                      allowGradient,
                      percentage,
                      amount,
                      alwaysDisplayAmount,
                    } = props.formatRegistrationCell(timeRegs, employee, buildDate(currentYear, currentMonth, day))
                    let className = ''
                    if (props.baseCellClassName) {
                      className = props.baseCellClassName
                    }
                    if (additionalClass) {
                      className += ' ' + additionalClass
                    }
                    let workTitle = title
                    if ((props.allowMoreRegistrationsPerDay && timeRegs.length >= 1) || timeRegs.length === 1) {
                      if (!timeReg.approved) {
                        className += ' need-approval interactive'
                        workTitle = t('calendar.table.click_to_approve', { title: workTitle })
                      } else {
                        className += ' interactive'
                        workTitle = t('calendar.table.click_to_edit', { title: workTitle })
                      }
                    }
                    const style: CSSProperties = {}
                    if (allowGradient && percentage < 100) {
                      style.background =
                        'linear-gradient(to bottom right, var(--sally-blue-faded) ' +
                        (percentage - 2) +
                        '%,  #fff ' +
                        (percentage + 2) +
                        '%)'
                    } else if (backgroundColor) {
                      style.backgroundColor = backgroundColor
                    }
                    const content = alwaysDisplayAmount || amount !== 1 ? formatDisplayNumber(amount) : ' '
                    return (
                      <td
                        id={employee.id + '_' + day}
                        className={className}
                        key={day}
                        title={workTitle}
                        onClick={timeRegs.length === 1 ? editRegistration(employee.id, timeReg.id) : undefined}
                        style={style}
                      >
                        {props.allowMoreRegistrationsPerDay && timeRegs.length > 1 && (
                          <Popover
                            placement="top"
                            content={
                              <>
                                {timeRegs
                                  .sort((a, b) => (a.start ?? 0) - (b.start ?? 0))
                                  .map((timeReg) => (
                                    <DumbLink key={timeReg.id} onClick={editRegistration(employee.id, timeReg.id)}>
                                      {props.formatRegistrationContextMenuItem(timeReg)}
                                    </DumbLink>
                                  ))}
                                {props.onCellClick && (
                                  <DumbLink onClick={cellClick(employee.id, day)}>
                                    {t('calendar.table.cell.context_menu.new', {
                                      new_type: props.newTypeText ?? t('common.unknown'),
                                      name: employee.name,
                                    })}
                                  </DumbLink>
                                )}
                              </>
                            }
                            trigger="click"
                            overlayClassName="context-menu-popover"
                            onClick={(e: React.MouseEvent) => e.stopPropagation()}
                            overlayStyle={{ zIndex: '100' }}
                          >
                            <div>{content}</div>
                          </Popover>
                        )}
                        {(!props.allowMoreRegistrationsPerDay || timeRegs.length < 2) && <>{content}</>}
                      </td>
                    )
                  }
                  let holiday = false
                  let holidayN
                  if (isHoliday(day)) {
                    holiday = true
                    holidayN = holidayName(day)
                    if (!props.allowRegistrationsOnHoliday) {
                      return (
                        <td id={employee.id + '_' + day} className="employee-holiday" key={day} title={holidayN}>
                          {' '}
                        </td>
                      )
                    }
                  }
                  return (
                    <td
                      id={employee.id + '_' + day}
                      className={
                        (holiday ? 'employee-holiday' : 'employee-other') +
                        (props.periodDragMode || !!props.onCellClick ? ' interactive' : '') +
                        selectedCell(employee.id, day)
                      }
                      key={day}
                      onMouseDown={props.periodDragMode ? mouseDown : undefined}
                      onMouseOver={props.periodDragMode ? mouseOver : undefined}
                      onClick={props.onCellClick ? cellClick(employee.id) : undefined}
                      title={
                        props.periodDragMode
                          ? t('calendar.table.cell.drag_mode', {
                              new_type: props.newTypeText ?? t('common.unknown'),
                              name: employee.name,
                            })
                          : props.onCellClick
                          ? t('calendar.table.cell.click_mode', {
                              new_type: props.newTypeText ?? t('common.unknown'),
                              name: employee.name,
                            })
                          : holidayN
                      }
                    >
                      {' '}
                    </td>
                  )
                })}
                {dates.length < 31 && <td colSpan={31 - dates.length}> </td>}
              </tr>
            )
          })}
        </tbody>
      </table>
    </Card>
  )
}
