import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import PaymentConfiguration from '../model/paymentConfiguration'
import { ReducerAction } from '../utils/reducer-utils'

export interface PaymentConfigurationReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  paymentConfigurations: List<PaymentConfiguration>
  error: Error | null
}

const Params = Record<PaymentConfigurationReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  paymentConfigurations: List<PaymentConfiguration>(),
  error: null,
})

export interface PaymentConfigurationAction extends ReducerAction {
  companyID?: string
  paymentConfigurations?: PaymentConfiguration[]
  paymentConfiguration?: PaymentConfiguration
}

export default (
  state: Record<PaymentConfigurationReducer> = Params(),
  action: PaymentConfigurationAction = { type: '' }
): Record<PaymentConfigurationReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.PAYMENT_CONFIGURATIONS_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.PAYMENT_CONFIGURATIONS_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        paymentConfigurations: List<PaymentConfiguration>(action.paymentConfigurations),
      })
    case ActionTypes.PAYMENT_CONFIGURATIONS_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.PAYMENT_CONFIGURATION_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.PAYMENT_CONFIGURATION_ADDED:
      if (!action.paymentConfiguration) {
        return state
      }
      idx = state
        .get('paymentConfigurations')
        .findIndex(
          (item) =>
            !!action.paymentConfiguration && item.integrationType === action.paymentConfiguration.integrationType
        )
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('paymentConfigurations', state.get('paymentConfigurations').set(idx, action.paymentConfiguration))
      }
      return state
        .set('saving', false)
        .set('paymentConfigurations', state.get('paymentConfigurations').push(action.paymentConfiguration))
    case ActionTypes.PAYMENT_CONFIGURATION_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.PAYMENT_CONFIGURATION_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.PAYMENT_CONFIGURATION_UPDATED:
      if (!action.paymentConfiguration) {
        return state
      }
      idx = state
        .get('paymentConfigurations')
        .findIndex(
          (item) =>
            !!action.paymentConfiguration && item.integrationType === action.paymentConfiguration.integrationType
        )
      if (idx === -1) {
        return state
          .set('saving', false)
          .set('paymentConfigurations', state.get('paymentConfigurations').push(action.paymentConfiguration))
      }
      return state
        .set('saving', false)
        .set('paymentConfigurations', state.get('paymentConfigurations').set(idx, action.paymentConfiguration))
    case ActionTypes.PAYMENT_CONFIGURATION_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.PAYMENT_CONFIGURATION_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.PAYMENT_CONFIGURATION_DELETED:
      idx = state
        .get('paymentConfigurations')
        .findIndex(
          (item) =>
            !!action.paymentConfiguration && item.integrationType === action.paymentConfiguration.integrationType
        )
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('paymentConfigurations', state.get('paymentConfigurations').delete(idx))
    case ActionTypes.PAYMENT_CONFIGURATION_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
