import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { removeAlert, removeAlertSignature } from '../actions/alerts'
import { login, requestPassword } from '../actions/user'
import Modal from '../components/antd/modal'
import LoginComponent from '../components/login/Login'
import RequestPasswordComponent from '../components/password-request/RequestPassword'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import { AccessToken } from '../model/user'
import { AlertReducer } from '../reducers/alerts'
import { UserReducer } from '../reducers/user'
import { paths } from '../routes'
import { connectToReducer } from '../utils/reducer-utils'
import { ChannelMFAError } from '../utils/request-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  user: UserReducer
  alerts: AlertReducer
}

type Actions = {
  removeAlert: removeAlertSignature
  login: (
    email: string,
    password: string,
    challengeID?: string,
    response?: string,
    recoveryCode?: string,
    trustDevice?: boolean
  ) => Promise<AccessToken | ChannelMFAError | void>
  requestPassword: (email: string) => Promise<boolean | void>
}

function Login(props: Reducers & Actions & RouteProps): ReactElement | null {
  const [showRequestPassword, setShowRequestPassword] = useState(props.route.path === paths.REQUEST_PASSWORD)

  const previousShowRequestPassword = usePrevious(showRequestPassword)

  const { route } = props

  useEffect(() => {
    if (previousShowRequestPassword && route.path === paths.LOGIN) {
      setShowRequestPassword(false)
    }
    if (!previousShowRequestPassword && route.path === paths.REQUEST_PASSWORD) {
      setShowRequestPassword(true)
    }
  }, [previousShowRequestPassword, route])

  const setRequestPasswordVisibility = (visible: boolean) => {
    setShowRequestPassword(visible)
    jsBrowserHistory.push('/' + (visible ? paths.REQUEST_PASSWORD : paths.LOGIN) + document.location.search)
  }

  return (
    <>
      <LoginComponent user={props.user} alerts={props.alerts} removeAlert={props.removeAlert} login={props.login} />
      <Modal
        visible={showRequestPassword}
        onOk={() => setRequestPasswordVisibility(false)}
        onCancel={() => setRequestPasswordVisibility(false)}
        width={478}
        footer={null}
      >
        <RequestPasswordComponent
          visible={showRequestPassword}
          user={props.user}
          requestPassword={props.requestPassword}
        />
      </Modal>
    </>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    user: state.user,
    alerts: state.alerts,
  }),
  {
    login: login,
    requestPassword: requestPassword,
    removeAlert: removeAlert,
  }
)(Login)
