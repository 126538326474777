import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import { getStagedImportData, getStagedImportResult } from '../../api/data'
import ApplyResult from '../../model/applyResult'
import AsynchronousTask from '../../model/asynchronousTask'
import Company from '../../model/company'
import { ExternalPayRollImportMode } from '../../model/dataIntegration'
import Employee from '../../model/employee'
import EmployeeBatchResult from '../../model/employeeBatchResult'
import PayRoll from '../../model/payRoll'
import { BasicSalaryPeriod } from '../../model/salaryPeriod'
import SalaryType from '../../model/salaryType'
import { StagedImportDataCompany, StagedImportDataEmployee } from '../../model/stagedImport'
import { AsynchronousTaskReducer } from '../../reducers/asynchronousTasks'
import { ContractReducer } from '../../reducers/contracts'
import { paths } from '../../routes'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { getEmployeeSalaryDefinition, SalaryInformation } from '../../utils/data-integration-utils'
import { formatDateTime, getDate, isTimeAfter } from '../../utils/date-utils'
import { compareError, convertStoredErrorToError, formatError } from '../../utils/error-utils'
import { formatLoadingText } from '../../utils/loading-utils'
import { formatCurrency } from '../../utils/number-utils'
import { formatEmployeeBatchMessage } from '../../utils/staged-import-utils'
import { t, tx } from '../../utils/translation-utils'
import Radio from '../antd/radio'
import RadioGroup from '../antd/radio/group'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Checkbox from '../elements/checkbox'
import CustomTable from '../elements/custom-table'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import AsynchronousTaskSelector from './AsynchronousTaskSelector'
import AsynchronousTaskStatusDisplay from './AsynchronousTaskStatusDisplay'

type Props = {
  asynchronousTasks: AsynchronousTaskReducer
  company: Company
  contracts: ContractReducer
  payRolls: List<PayRoll>
  employees: List<Employee>
  salaryTypes: List<SalaryType>
  displayName: string

  addAlert: addAlertSignature
  startPayRollImport: (
    companyID: string,
    salaryPeriodID: string,
    mode: ExternalPayRollImportMode
  ) => Promise<AsynchronousTask | void>
  storeStagedImportData: (asynchronousTaskID: string, employeesToImport?: string[]) => Promise<AsynchronousTask | void>
  getAsynchronousTask: (id: string) => void
  getContracts: () => void
}

type State = {
  companies?: StagedImportDataCompany[]
  applyResult?: ApplyResult[]
  employeesToImport: string[]
  step: number
  asynchronousTask?: AsynchronousTask
  importing: boolean
  saving: boolean
}

export default function PayRollImport(props: Props): ReactElement | null {
  const [mode, setMode] = useState<ExternalPayRollImportMode>('Hourly')
  const [state, setState] = useState<State>({ step: 0, importing: false, saving: false, employeesToImport: [] })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [taskError, setTaskError] = useState<Error | null>(null)
  const [contractsLoaded, setContractsLoaded] = useState(false)

  const loadEmployees = (task: AsynchronousTask) => {
    if (task.status !== 'Success') {
      return
    }
    setLoading(true)
    getStagedImportData(task.id).then((res) => {
      const employees = res.data.companies.reduce((l: StagedImportDataEmployee[], c) => [...l, ...c.employees], [])
      setState((prev) => ({
        ...prev,
        companies: res.data.companies,
        employeesToImport: employees
          .filter((employee) => employee.errors.length === 0)
          .map((employee) => employee.importID),
        applyResult: undefined,
      }))
      setLoading(false)
    })
  }

  const loadApplyResult = useCallback(
    (task: AsynchronousTask) => {
      if (task.status !== 'Success') {
        return
      }
      if (loading) {
        return
      }
      if (state.applyResult) {
        return
      }
      setLoading(true)
      getStagedImportResult(task.id).then((res) => {
        if (!res) {
          return
        }
        setState((prev) => ({ ...prev, applyResult: res.data }))
        setLoading(false)
      })
    },
    [loading, state]
  )

  const { asynchronousTasks } = props
  useEffectOnce(() => {
    const task = asynchronousTasks.asynchronousTasks
      .filter((task) => task.type === 'ExternalPayRollImportGather' && task.status === 'Success')
      .sort((a, b) => (a.startedAt || '').localeCompare(b.startedAt || ''))
      .last()
    if (task) {
      loadEmployees(task)
    }
  })

  const toggleMode = (mode: ExternalPayRollImportMode) => {
    setMode(mode)
    setState({ step: 0, importing: false, saving: false, employeesToImport: [] })
  }

  const { contracts, employees, getContracts } = props

  useEffect(() => {
    if (contractsLoaded) {
      return
    }

    if (state.companies && state.companies.length > 0) {
      if (
        !contracts.loading &&
        state.companies.some((company) =>
          company.employees.some((modEmp) => {
            const employee = employees.find((employee) => employee.id === modEmp.employeeID)
            return employee && employee.earliestMutableContract && !employee.earliestMutableContract.remuneration
          })
        )
      ) {
        getContracts()
        setContractsLoaded(true)
      }
    }
  }, [state, contracts, getContracts, employees, contractsLoaded])

  const { addAlert } = props

  useEffect(() => {
    const existingTask = state.asynchronousTask
    if (!existingTask) {
      return
    }
    const updatedTask = asynchronousTasks.asynchronousTasks.find((task) => task.id === existingTask.id)
    if (!updatedTask) {
      return
    }
    switch (updatedTask.type) {
      case 'ExternalPayRollImportGather': {
        let nextImporting = state.importing
        if (nextImporting && (updatedTask.status === 'Failed' || updatedTask.status === 'Success')) {
          nextImporting = false
        }

        const newError = convertStoredErrorToError(updatedTask.error)
        if (!compareError(taskError, newError)) {
          setTaskError(newError)
        }

        if (state.importing !== nextImporting || existingTask.status !== updatedTask.status) {
          setState((prev) => {
            if (prev.importing !== nextImporting) {
              prev = { ...prev, importing: nextImporting }
            }
            if (prev.asynchronousTask && prev.asynchronousTask.status !== updatedTask.status) {
              prev = {
                ...prev,
                asynchronousTask: updatedTask,
              }
            }
            return prev
          })
        }
        if (updatedTask.status === 'Success' && !state.companies && !loading) {
          loadEmployees(updatedTask)
        }
        break
      }
      case 'StagedDataSave': {
        let nextSaving = state.saving
        if (nextSaving && (updatedTask.status === 'Failed' || updatedTask.status === 'Success')) {
          nextSaving = false
        }

        const newError = convertStoredErrorToError(updatedTask.error)
        if (!compareError(taskError, newError)) {
          setTaskError(newError)
        }

        if (state.saving !== nextSaving || existingTask.status !== updatedTask.status) {
          setState((prev) => {
            if (prev.saving !== nextSaving) {
              prev = { ...prev, saving: nextSaving }
            }
            if (prev.asynchronousTask && prev.asynchronousTask.status !== updatedTask.status) {
              prev = {
                ...prev,
                asynchronousTask: updatedTask,
              }
            }
            return prev
          })
        }
        if (updatedTask.status === 'Success') {
          addAlert(
            'success',
            mode === 'Hourly'
              ? t('data_integration.pay_roll.alert.success.hourly')
              : t('data_integration.pay_roll.alert.success.salaried'),
            {
              timeout: 5,
            }
          )
          if ((updatedTask.applySummary?.failures ?? 0) > 0) {
            loadApplyResult(updatedTask)
          } else {
            if (mode === 'Hourly') {
              jsBrowserHistory.push('/' + paths.TIME_REGISTRATION)
            } else {
              jsBrowserHistory.push('/' + paths.SALARY_REGISTRATION)
            }
          }
        }
        break
      }
      default:
        break
    }
  }, [asynchronousTasks, state, loading, taskError, addAlert, loadApplyResult, mode])

  useEffect(() => {
    regularComponentDidUpdate(asynchronousTasks.error, error, setError)
  }, [asynchronousTasks, error])

  const getPeriod = (): BasicSalaryPeriod | undefined => {
    const activePayRolls = props.payRolls
      .filter((v) => {
        if (v.payRollType !== 'Ordinary') {
          return false
        }
        switch (v.status) {
          case 'Scheduled':
          case 'Upcoming':
          case 'InProgress':
            return true
          default:
            return false
        }
      })
      .sort((a, b) => a.dispositionDate.localeCompare(b.dispositionDate)) // always newest first
    let payRoll: PayRoll | undefined = undefined
    if (activePayRolls.size === 1) {
      payRoll = activePayRolls.first()
    } else if (activePayRolls.size > 1) {
      // pick newest with offset
      payRoll = activePayRolls.find((v) => !!v.salaryCycle.offset)
      if (!payRoll) {
        // pick newest
        payRoll = activePayRolls.first()
      }
    }
    if (!payRoll) {
      payRoll = props.payRolls.reduce((payRoll: PayRoll | undefined, v) => {
        if (!payRoll || isTimeAfter(getDate(v.salaryPeriod.end), getDate(payRoll.salaryPeriod.end))) {
          return v
        }
        return payRoll
      }, payRoll)
    }
    if (payRoll) {
      return payRoll.salaryPeriod
    }
    return undefined
  }

  const startImport = (e: React.MouseEvent) => {
    e.preventDefault()

    const period = getPeriod()
    if (!period) {
      setState((prev) => ({ ...prev, importing: false }))
      setTaskError(new Error(t('data_integration.pay_roll.error.no_pay_roll_period')))
      return
    }
    props.startPayRollImport(props.company.id, period.id, mode).then((res) => {
      if (!res) {
        return
      }
      setState((prev) => ({
        ...prev,
        step: 1,
        asynchronousTask: res,
        importing: true,
        companies: undefined,
        employeesToImport: [],
        applyResult: undefined,
      }))
      loadEmployees(res)
    })
  }

  const refreshTask = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!state.asynchronousTask) {
      return
    }

    props.getAsynchronousTask(state.asynchronousTask.id)
  }

  const saveImport = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!state.asynchronousTask) {
      return
    }

    props.storeStagedImportData(state.asynchronousTask.id, state.employeesToImport).then((res) => {
      if (!res) {
        return
      }
      setState((prev) => ({
        ...prev,
        step: 2,
        asynchronousTask: res,
        importing: false,
        saving: true,
        applyResult: undefined,
      }))
    })
  }

  const selectAsynchronousTask = (taskID: string) => {
    const task = props.asynchronousTasks.asynchronousTasks.find((task) => task.id === taskID)
    if (task) {
      setState((prev) => ({
        ...prev,
        step: 1,
        asynchronousTask: task,
        importing: true,
        saving: false,
        companies: undefined,
        employeesToImport: [],
        applyResult: undefined,
      }))
      loadEmployees(task)
      setTaskError(convertStoredErrorToError(task.error))
      if (task.gatherInformation?.payRollImportMode) {
        setMode(task.gatherInformation.payRollImportMode)
      }
    }
  }

  type EmployeeRow = {
    key: string
    importID: string
    name: string
    externalID: string
    employeeID?: string
    nameSalary?: string
    errors: string[]
    canImport: boolean
    willImport: boolean
    salaryTypes: {
      hours: number
      quantity: number
      salaryType: {
        salaryType?: string
        toAdd: boolean
        toModify: boolean
        toCreate: boolean
        rate?: number
      }
    }[]
    importResult?: EmployeeBatchResult
  }

  const getRowClassName = (result?: EmployeeBatchResult): string | undefined => {
    if (!result) {
      return undefined
    }

    switch (result.state) {
      case 'Success':
        return 'employee-import-result success'
      case 'Failure':
        return 'employee-import-result failure'
      default:
        return undefined
    }
  }

  const getEmployeeRows = (): EmployeeRow[] => {
    if (!state.companies) {
      return []
    }
    const salaryTypes = {
      ...props.salaryTypes.reduce((o: Record<string, SalaryType>, salaryType) => {
        o[salaryType.id] = salaryType
        return o
      }, {}),
      ...state.companies.reduce((o: Record<string, SalaryType>, company) => {
        return {
          ...o,
          ...company.salaryTypes.reduce((oo: Record<string, SalaryType>, salaryType) => {
            oo[salaryType.id] = salaryType
            return oo
          }, {}),
        }
      }, {}),
    }
    return state.companies
      .reduce((l: StagedImportDataEmployee[], company) => [...l, ...company.employees], [])
      .reduce((list: EmployeeRow[], employee) => {
        const errors = employee.errors

        type timeRegSum = {
          salaryTypeID: string
          salaryInfo: SalaryInformation
          hours: number
        }
        const timeRegistrationSum: timeRegSum[] = employee.timeRegistrations.reduce(
          (sums: timeRegSum[], registration) => {
            if (!registration.salaryTypeID) {
              return sums
            }
            const existingSumIdx = sums.findIndex((sum) => sum.salaryTypeID === registration.salaryTypeID)
            if (existingSumIdx === -1) {
              return [
                ...sums,
                {
                  salaryTypeID: registration.salaryTypeID,
                  salaryInfo: getEmployeeSalaryDefinition(
                    props.employees,
                    employee,
                    salaryTypes,
                    registration.salaryTypeID
                  ),
                  hours: registration.hours ?? 0,
                },
              ]
            }
            sums[existingSumIdx].hours += registration.hours ?? 0
            return sums
          },
          []
        )
        type suppSum = {
          salaryTypeID: string
          salaryInfo: SalaryInformation
          quantity: number
        }
        const supplementSum: suppSum[] = employee.salaryRegistrations.reduce((sums: suppSum[], registration) => {
          const existingSumIdx = sums.findIndex((sum) => sum.salaryTypeID === registration.salaryTypeID)
          if (existingSumIdx === -1) {
            return [
              ...sums,
              {
                salaryTypeID: registration.salaryTypeID,
                salaryInfo: getEmployeeSalaryDefinition(
                  props.employees,
                  employee,
                  salaryTypes,
                  registration.salaryTypeID
                ),
                quantity: registration.quantity,
              },
            ]
          }
          sums[existingSumIdx].quantity += registration.quantity
          return sums
        }, [])

        list.push({
          key: employee.importID,
          importID: employee.importID,
          name: employee.name ?? employee.additionalData?.currentSalaryName ?? employee.email ?? t('common.unknown'),
          externalID: employee.additionalData?.externalID ?? t('common.unknown'),
          employeeID: employee.employeeID,
          nameSalary: employee.additionalData?.currentSalaryName,
          errors: errors.map((err) => err.message),
          canImport: errors.length === 0,
          willImport: state.employeesToImport.some((id) => id === employee.importID),
          salaryTypes: [
            ...timeRegistrationSum.map((sum) => ({
              hours: sum.hours,
              quantity: 0,
              salaryType: {
                salaryType: sum.salaryInfo.title,
                toAdd: sum.salaryInfo.toAdd,
                toModify: sum.salaryInfo.toModify,
                toCreate: sum.salaryInfo.toCreate,
                rate: sum.salaryInfo.rate,
              },
            })),
            ...supplementSum.map((sum) => ({
              salaryType: {
                salaryType: sum.salaryInfo.title,
                toAdd: sum.salaryInfo.toAdd,
                toModify: sum.salaryInfo.toModify,
                toCreate: sum.salaryInfo.toCreate,
                rate: sum.salaryInfo.rate,
              },
              hours: 0,
              quantity: sum.quantity,
            })),
          ],
          importResult: state.applyResult?.reduce((msg: EmployeeBatchResult | undefined, applyResult) => {
            return applyResult.messages.find((result) => result.importID === employee.importID)
          }, undefined),
        })
        return list
      }, [])
      .sort((a, b) => {
        const importResultA = a.importResult
        const importResultB = b.importResult
        if (!importResultA || !importResultB || importResultA.state === importResultB.state) {
          const canImportA = a.errors.length === 0
          const canImportB = b.errors.length === 0
          if (canImportA === canImportB) {
            return a.name.localeCompare(b.name)
          }
          if (canImportA) {
            return 1
          }
          return canImportB ? -1 : 1
        }
        if (importResultA.state !== 'Failure') {
          return 1
        }
        return importResultB.state === 'Failure' ? 1 : -1
      })
  }

  const toggleEmployeeImport = (importID: string) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setState((prev) => {
        if (e.target.checked) {
          return { ...prev, employeesToImport: [...prev.employeesToImport, importID] }
        } else {
          return { ...prev, employeesToImport: [...prev.employeesToImport.filter((id) => id !== importID)] }
        }
      })
    }
  }

  if (!props.asynchronousTasks.loaded) {
    return (
      <LoadingOverlay text={formatLoadingText([{ loading: !props.asynchronousTasks.loaded, text: 'indlæsninger' }])} />
    )
  }

  let errors = 0
  if (state.step === 1 && !state.importing && state.companies) {
    errors = state.companies.reduce(
      (errors, company) => errors + company.employees.reduce((errors, employee) => errors + employee.errors.length, 0),
      0
    )
  }

  const employeeRows = getEmployeeRows()

  type Totals = {
    totalHours: number
    importableHours: number
    totalGross: number
    importableGross: number
  }
  const totals = employeeRows.reduce(
    (totals: Totals, row) => {
      const hours = row.salaryTypes.reduce((h, t) => h + t.hours, 0)
      const gross = row.salaryTypes.reduce(
        (g, t) => g + t.hours * (t.salaryType.rate ?? 0) + t.quantity * (t.salaryType.rate ?? 0),
        0
      )
      return {
        totalHours: totals.totalHours + hours,
        importableHours: totals.importableHours + (row.canImport ? hours : 0),
        totalGross: totals.totalGross + gross,
        importableGross: totals.importableGross + (row.canImport ? gross : 0),
      }
    },
    { totalHours: 0, importableHours: 0, totalGross: 0, importableGross: 0 }
  )

  const isUsed = state.asynchronousTask?.gatherInformation?.used ?? false

  return (
    <div>
      {taskError && (
        <Alert
          message={
            <>
              {formatError(taskError)}
              <Button
                onClick={startImport}
                noArrow
                regular
                className="gtm-time-registrations-employees-import"
                style={{ float: 'right' }}
              >
                {t('data_integration.pay_roll.actions.start_import_again')}
              </Button>
            </>
          }
          type="error"
          showIcon
        />
      )}
      {!taskError && error && <Alert message={formatError(error)} type="error" showIcon />}
      {isUsed && (
        <Alert
          type={'warning'}
          message={
            <>
              {t('data_integration.pay_roll.warning.already_used')}
              <Button
                onClick={startImport}
                noArrow
                regular
                className="gtm-time-registrations-employees-import"
                style={{ float: 'right' }}
              >
                {t('data_integration.pay_roll.actions.start_new_import')}
              </Button>
            </>
          }
          showIcon
        />
      )}
      <TitleMenu style={{ width: '400px' }}>
        <AsynchronousTaskSelector
          asynchronousTasks={props.asynchronousTasks.asynchronousTasks}
          asynchronousTaskType={'ExternalPayRollImportGather'}
          asynchronousTask={state.asynchronousTask}
          onChange={selectAsynchronousTask}
          filter={(task) => task.gatherInformation?.payRollImportMode === mode}
        />
      </TitleMenu>
      <Title>Importer timer fra {props.displayName}</Title>
      <Row>
        <Col span={12} style={{ marginTop: '-25px' }}>
          <RadioGroup
            value={mode}
            onChange={(v: React.ChangeEvent<HTMLInputElement>) =>
              toggleMode(v.target.value as ExternalPayRollImportMode)
            }
          >
            <Radio value={'Hourly'}>{t('data_integration.pay_roll.mode.hourly')}</Radio>
            <Radio value={'Salaried'}>{t('data_integration.pay_roll.mode.salaried')}</Radio>
          </RadioGroup>
        </Col>
      </Row>
      {state.step === 0 && (
        <Row>
          <Col span={24}>
            <Button
              size="large"
              onClick={startImport}
              type="secondary"
              noArrow
              className="gtm-time-registrations-import"
            >
              Hent data
            </Button>
          </Col>
        </Row>
      )}
      {state.step === 1 && state.importing && (
        <AsynchronousTaskStatusDisplay
          title={t('data_integration.pay_roll.status.importing.title')}
          description={t('data_integration.pay_roll.status.importing.description')}
          task={state.asynchronousTask}
          refreshTask={refreshTask}
        />
      )}
      {state.step === 2 && state.saving && (
        <AsynchronousTaskStatusDisplay
          title={t('data_integration.pay_roll.status.saving.title')}
          description={t(
            mode === 'Hourly'
              ? 'data_integration.pay_roll.status.saving.description.hourly'
              : 'data_integration.pay_roll.status.saving.description.salaried'
          )}
          task={state.asynchronousTask}
          refreshTask={refreshTask}
        />
      )}
      {(state.step === 1 || (state.step === 2 && !!state.applyResult)) && !state.importing && state.companies && (
        <div className="time-registration-integration-import-list" style={{ textAlign: 'left', fontSize: '14px' }}>
          {errors > 0 && (
            <Alert
              key={'employee-map-error'}
              message={t('data_integration.pay_roll.error.employees_cannot_import', { count: errors })}
              type="error"
              showIcon
            />
          )}
          <Row>
            <Col span={12}>
              {!isUsed && (
                <Button
                  size="large"
                  onClick={saveImport}
                  type="secondary"
                  noArrow
                  className="gtm-time-registrations-import-save"
                >
                  {t('data_integration.pay_roll.actions.save_import')}
                </Button>
              )}
              {state.asynchronousTask?.finishedAt && (
                <span style={{ paddingLeft: '15px' }}>
                  {t('data_integration.pay_roll.data_finished_at', {
                    date: formatDateTime(state.asynchronousTask?.finishedAt),
                  })}
                </span>
              )}
            </Col>
            <Col span={12}>
              <Button
                size="large"
                onClick={startImport}
                className="gtm-time-registrations-new-import"
                style={{ float: 'right' }}
              >
                {t('data_integration.pay_roll.actions.start_import_again')}
              </Button>
            </Col>
          </Row>
          <Row style={{ textAlign: 'right' }}>
            <Col span={12}>&nbsp;</Col>
            <Col span={6}>
              {t('data_integration.pay_roll.summary.total_gross')}:{' '}
              <strong>{formatCurrency(totals.totalGross, 2)}</strong>
              {totals.totalGross !== totals.importableGross &&
                tx('data_integration.pay_roll.summary.total_gross.can_be_imported', {
                  amount: <strong>{formatCurrency(totals.importableGross, 2)}</strong>,
                })}
            </Col>
            <Col span={6}>
              {t('data_integration.pay_roll.summary.total_hours')}:{' '}
              <strong>{t('unit.day_count', { count: totals.totalHours })}</strong>
              {totals.totalHours !== totals.importableHours &&
                tx('data_integration.pay_roll.summary.total_hours.can_be_imported', {
                  amount: (
                    <strong>
                      {t('data_integration.pay_roll.summary.total_hours.format', { count: totals.importableHours })}
                    </strong>
                  ),
                })}
            </Col>
          </Row>
          {!!state.applyResult && (state.asynchronousTask?.applySummary?.failures ?? 0) > 0 && (
            <Alert
              type={'warning'}
              message={t('data_integration.pay_roll.warning.error_on_employees', {
                count: state.asynchronousTask?.applySummary?.failures ?? 0,
              })}
              showIcon
            />
          )}
          <CustomTable columns={5}>
            <CustomTable.Header>
              <CustomTable.TH>&nbsp;</CustomTable.TH>
              <CustomTable.TH>{t('data_integration.pay_roll.table.header.name')}</CustomTable.TH>
              <CustomTable.TH>{t('data_integration.pay_roll.table.header.remuneration')}</CustomTable.TH>
              <CustomTable.TH>{t('data_integration.pay_roll.table.header.rate')}</CustomTable.TH>
              <CustomTable.TH>{t('data_integration.pay_roll.table.header.hours')}</CustomTable.TH>
            </CustomTable.Header>
            <CustomTable.Body>
              {getEmployeeRows().map((employee) => {
                let spanRow = employee.salaryTypes.length
                if (employee.importResult && employee.importResult.state !== 'Success') {
                  spanRow++
                }
                if (employee.errors.length > 0) {
                  spanRow++
                }
                return (
                  <React.Fragment key={employee.key}>
                    {employee.salaryTypes.map((salaryRow, i) => {
                      const salaryType = salaryRow.salaryType
                      return (
                        <CustomTable.Row
                          key={`${employee.key}-${i}`}
                          className={
                            getRowClassName(employee.importResult) +
                            (i > 0 ? ' spanned-row' : spanRow > 1 ? ' spanned-row-first' : '')
                          }
                        >
                          {i === 0 && (
                            <>
                              <CustomTable.TD spanRow={spanRow}>
                                <Checkbox
                                  checked={employee.willImport}
                                  disabled={!employee.canImport}
                                  onChange={toggleEmployeeImport(employee.importID)}
                                />
                              </CustomTable.TD>
                              <CustomTable.TD spanRow={spanRow}>
                                {employee.name || (
                                  <i>
                                    {t('data_integration.pay_roll.table.external_id')}: {employee.externalID || '-'}
                                  </i>
                                )}
                                {employee.name &&
                                employee.employeeID &&
                                employee.nameSalary &&
                                employee.name !== employee.nameSalary ? (
                                  <>
                                    <br />({employee.nameSalary})
                                  </>
                                ) : (
                                  ''
                                )}
                              </CustomTable.TD>
                            </>
                          )}
                          <CustomTable.TD>
                            {salaryType.salaryType}
                            {employee.canImport && salaryType.toCreate && (
                              <div>
                                <strong className="time-registration-integration-note">
                                  {t('data_integration.pay_roll.table.note.remuneration_created')}
                                </strong>
                              </div>
                            )}
                            {employee.canImport && salaryType.toAdd && !salaryType.toCreate && (
                              <div>
                                <strong className="time-registration-integration-note">
                                  {t('data_integration.pay_roll.table.note.remuneration_added')}
                                </strong>
                              </div>
                            )}
                            {employee.canImport && salaryType.toModify && !salaryType.toAdd && !salaryType.toCreate && (
                              <div>
                                <strong className="time-registration-integration-note">
                                  {t('data_integration.pay_roll.table.note.remuneration_changed')}
                                </strong>
                              </div>
                            )}
                          </CustomTable.TD>
                          <CustomTable.TD>{salaryType?.rate ? formatCurrency(salaryType.rate, 2) : '-'}</CustomTable.TD>
                          <CustomTable.TD>
                            {salaryRow.hours > 0 && t('unit.hour_count', { count: salaryRow.hours })}
                            {salaryRow.quantity > 0 &&
                              t('data_integration.pay_roll.table.quantity_format', { count: salaryRow.quantity })}
                          </CustomTable.TD>
                        </CustomTable.Row>
                      )
                    })}
                    {employee.errors.length > 0 && (
                      <CustomTable.Row className={getRowClassName(employee.importResult) + ' spanned-row'}>
                        <CustomTable.TD span={3}>
                          {employee.errors.map((err, i) => (
                            <div key={`err-${i}`} className="time-registration-integration-error">
                              {err}
                            </div>
                          ))}
                        </CustomTable.TD>
                      </CustomTable.Row>
                    )}
                    {employee.importResult && employee.importResult.state !== 'Success' && (
                      <CustomTable.Row className={'employee-import-result failure spanned-row'}>
                        <CustomTable.TD span={3}>
                          {formatEmployeeBatchMessage(
                            employee.importResult.state,
                            employee.importResult.message,
                            employee.importResult.details
                          )}
                        </CustomTable.TD>
                      </CustomTable.Row>
                    )}
                  </React.Fragment>
                )
              })}
            </CustomTable.Body>
          </CustomTable>
          <Row style={{ marginTop: '35px' }}>
            <Col span={24}>
              {!isUsed && (
                <Button
                  size="large"
                  onClick={saveImport}
                  type="secondary"
                  noArrow
                  className="gtm-time-registrations-import-save"
                >
                  {t('data_integration.pay_roll.actions.save_import')}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      )}

      {!taskError && !error && state.step === 1 && !state.importing && !state.companies && (
        <LoadingOverlay text={t('data_integration.pay_roll.importing')} />
      )}
      {contracts.loading && <LoadingOverlay />}
    </div>
  )
}
