import React, { ReactElement } from 'react'

import Company from '../../model/company'
import DayLaborer from '../../types/day-laborer'
import { getDate, isSameOrAfter } from '../../utils/date-utils'
import { EmployeePayType } from '../../utils/employee-utils'
import { FormComponentProps } from '../../utils/form-utils'
import { forceParseInputNumber, parseInputNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Radio, { Group as RadioGroup } from '../antd/radio'
import Select from '../antd/select'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/Icon'
import Input from '../elements/input'
import Switch from '../elements/switch'
import Tooltip from '../elements/tooltip'
import SettingsLock from '../widgets/SettingsLock'

type FormFields = {
  vacationType: 'paid' | 'unpaid' | 'none'
  vacationDays: string
  vacationSupplement?: string
  vacationMoney?: string
  extraVacationDays: string
  personalDays: string
  extraVacationAccrual: 'Monthly' | 'Yearly'
  hasExtraVacation: boolean
  originalExtraVacationAccrual: 'Monthly' | 'Yearly'
  personalDaysAccrual: 'Monthly' | 'Yearly'
  hasPersonalDays: boolean
  originalPersonalDaysAccrual: 'Monthly' | 'Yearly'
  horestaVacationSupplement: boolean
  horestaVacationSupplementAmount?: string
  timeBank: 'None' | 'Flex' | 'Overtime'
  overtimeRate?: string
  hasVacationFund: boolean
  manualVacationFund: boolean
  vacationFundCVR?: string
  useDayLaborer: boolean
  useATP: boolean
  useATPInitial: boolean
  dayLaborer: DayLaborer
  minDays: number
  maxDays: number
  greatPrayerDaySupplement: boolean
  useGPDInitial: boolean
  includeVacationSupplementsInPensionBasis: boolean
}

type Props = {
  payType: EmployeePayType
  company: Company
}

export default function VacationForm<Fields extends FormFields>({
  payType,
  company,
  decorateField,
  getFieldValue,
}: Props & FormComponentProps<Fields, any>): ReactElement | null {
  const hasVacationAsHourlyPaid = payType !== 'Salaried'
  const canHavePaidVacation =
    !hasVacationAsHourlyPaid || company.settingsEnabled.some((setting) => setting === 'AllowNonSalariedPaidVacation')

  const vacationFunds = [
    { cvr: company.nationalID, name: t('vacation_form.funds.own') },
    { cvr: '31378028', name: t('vacation_form.funds.31378028') },
    { cvr: '81831718', name: t('vacation_form.funds.81831718') },
    { cvr: '19003981', name: t('vacation_form.funds.19003981') },
    { cvr: '34498458', name: t('vacation_form.funds.34498458') },
    { cvr: '10250218', name: t('vacation_form.funds.10250218') },
  ]

  const allowVacationRate =
    payType !== 'Commissioned' &&
    getFieldValue('vacationType') === 'unpaid' &&
    company.settingsEnabled.some((setting) => setting === 'AllowVacationRate')

  const greatPrayerDayAvailable = isSameOrAfter(getDate(), getDate('2024-01-01'))
  // show great prayer day if:
  // salaried,
  // none (Director pay),
  // the setting was on (so it can be turned off)
  // OR the company setting allows it for all
  const showGreatPrayerDay =
    greatPrayerDayAvailable &&
    (payType === 'Salaried' ||
      getFieldValue('vacationType') === 'none' ||
      getFieldValue('useGPDInitial') ||
      company.settingsEnabled.some((setting) => setting === 'GreatPrayerDayForAll'))

  const showVacationSupplementInPensionBasis = company.settingsEnabled.some(
    (setting) => setting === 'IncludeVacationSupplementsInPensionBasis'
  )

  return (
    <div>
      <Row>
        <Col span={24}>
          {decorateField('vacationType', {
            skipLabel: true,
          })(
            <RadioGroup>
              <Radio value={'paid'} disabled={!canHavePaidVacation}>
                {t('vacation_form.vacation_type.paid')}&nbsp;&nbsp;
                <Tooltip placement="top" title={t('vacation_form.vacation_type.paid.tooltip')}>
                  <span>
                    <Icon type="info" color="grey" />
                  </span>
                </Tooltip>
              </Radio>
              <Radio value={'unpaid'}>
                {t('vacation_form.vacation_type.unpaid')}&nbsp;&nbsp;
                <Tooltip placement="top" title={t('vacation_form.vacation_type.unpaid.tooltip')}>
                  <span>
                    <Icon type="info" color="grey" />
                  </span>
                </Tooltip>
              </Radio>
              <Radio value={'none'}>
                {t('vacation_form.vacation_type.none')}&nbsp;&nbsp;
                <Tooltip placement="top" title={t('vacation_form.vacation_type.none.tooltip')}>
                  <span>
                    <Icon type="info" color="grey" />
                  </span>
                </Tooltip>
              </Radio>
            </RadioGroup>
          )}
        </Col>
      </Row>
      {getFieldValue('vacationType') !== 'none' && (
        <Row>
          <Col span={6}>
            {decorateField('vacationDays', {
              placeholder: t('vacation_form.vacation_days'),
              suffix: t('vacation_form.vacation_days.suffix'),
              validate: (val) => {
                if (!val) {
                  return t('vacation_form.vacation_days.required')
                }
                if (!val.toString().match(/^[0-9,.]+$/)) {
                  return t('vacation_form.vacation_days.invalid')
                }
                if (
                  forceParseInputNumber(val) < getFieldValue('minDays') ||
                  forceParseInputNumber(val) > getFieldValue('maxDays')
                ) {
                  return t('vacation_form.vacation_days.must_be_between', {
                    min: getFieldValue('minDays'),
                    max: getFieldValue('maxDays'),
                  })
                }
                return null
              },
            })(<Input disabled={getFieldValue('minDays') === getFieldValue('maxDays')} />)}
          </Col>
          <Col span={6}>
            {getFieldValue('vacationType') === 'paid' &&
              decorateField('vacationSupplement', {
                placeholder: t('vacation_form.vacation_supplement'),
                suffix: t('vacation_form.vacation_supplement.suffix'),
                validate: (val, allValues) => {
                  if (allValues.vacationType !== 'paid') {
                    return null
                  }
                  if (!val) {
                    return t('vacation_form.vacation_supplement.required')
                  }
                  if (parseInputNumber(val) < 1 || parseInputNumber(val) > 100 || !val.toString().match(/^[0-9,.]+$/)) {
                    return t('vacation_form.vacation_supplement.invalid')
                  }
                  return null
                },
              })(<Input />)}
            {getFieldValue('vacationType') === 'unpaid' &&
              decorateField('vacationMoney', {
                placeholder: t('vacation_form.vacation_money'),
                suffix: t('vacation_form.vacation_money.suffix'),
                validate: (val, allValues) => {
                  if (allValues.vacationType !== 'unpaid') {
                    return null
                  }
                  if (!val) {
                    return t('vacation_form.vacation_money.required')
                  }
                  if (!val.toString().match(/^[0-9,.]+$/)) {
                    return t('vacation_form.vacation_money.invalid')
                  }
                  const n = forceParseInputNumber(val)
                  if (allowVacationRate) {
                    if (!(n === 12.5 || n === 12 || n > 12.5)) {
                      return t('vacation_form.vacation_money.restricted_rate')
                    }
                  } else if (n !== 12.5) {
                    return t('vacation_form.vacation_money.must_be_12_5')
                  }
                  return null
                },
              })(<Input disabled={!allowVacationRate} />)}
          </Col>
          <Col span={6}>
            {decorateField('extraVacationDays', {
              placeholder: t('vacation_form.extra_vacation_days'),
              suffix: t('vacation_form.extra_vacation_days.suffix'),
              validate: (val) => {
                if (!val) {
                  return t('vacation_form.extra_vacation_days.required')
                }
                if (parseInputNumber(val) < 0 || parseInputNumber(val) > 365 || !val.toString().match(/^[0-9,.]+$/)) {
                  return t('vacation_form.extra_vacation_days.invalid')
                }
                return null
              },
            })(<Input />)}
            {forceParseInputNumber(getFieldValue('extraVacationDays')) > 0 && (
              <div>
                {decorateField('extraVacationAccrual', {
                  title: t('vacation_form.extra_vacation_accrual'),
                  skipWrapper: true,
                  noBlur: true,
                })(
                  <RadioGroup>
                    <Radio value={'Monthly'}>{t('vacation_form.extra_vacation_accrual.monthly')}</Radio>
                    <Radio value={'Yearly'}>{t('vacation_form.extra_vacation_accrual.yearly')}</Radio>
                  </RadioGroup>
                )}
              </div>
            )}
          </Col>
          <Col span={6}>
            {decorateField('personalDays', {
              placeholder: t('vacation_form.personal_days'),
              suffix: t('vacation_form.personal_days.suffix'),
              validate: (val) => {
                if (!val) {
                  return t('vacation_form.personal_days.required')
                }
                if (parseInputNumber(val) < 0 || parseInputNumber(val) > 365 || !val.toString().match(/^[0-9,.]+$/)) {
                  return t('vacation_form.personal_days.invalid')
                }
                return null
              },
            })(<Input />)}
            {forceParseInputNumber(getFieldValue('personalDays')) > 0 && (
              <div>
                {decorateField('personalDaysAccrual', {
                  title: t('vacation_form.personal_days_accrual'),
                  skipWrapper: true,
                  noBlur: true,
                })(
                  <RadioGroup>
                    <Radio value={'Monthly'}>{t('vacation_form.personal_days_accrual.monthly')}</Radio>
                    <Radio value={'Yearly'}>{t('vacation_form.personal_days_accrual.yearly')}</Radio>
                  </RadioGroup>
                )}
              </div>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12}>
          <SettingsLock setting={'AllowVacationFund'} description={t('vacation_form.has_vacation_fund.lock')}>
            <div className="ant-switch-wrapper">
              {decorateField('hasVacationFund', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">{t('vacation_form.has_vacation_fund')}</span>
            </div>
            {getFieldValue('hasVacationFund') &&
              decorateField('vacationFundCVR', {
                placeholder: t('vacation_form.vacation_fund_cvr'),
                skipLabel: true,
                validate: (val) => {
                  if (!val) {
                    return t('vacation_form.vacation_fund_cvr.required')
                  }
                  return null
                },
              })(
                <Select dropdownMatchSelectWidth={false}>
                  {vacationFunds.map((fund) => {
                    return (
                      <Select.Option key={fund.cvr} value={fund.cvr}>
                        {fund.name} ({fund.cvr})
                      </Select.Option>
                    )
                  })}
                </Select>
              )}
            {getFieldValue('hasVacationFund') && getFieldValue('vacationFundCVR') === company.nationalID && (
              <div className="ant-switch-wrapper">
                {decorateField('manualVacationFund', {
                  skipWrapper: true,
                  skipLabel: true,
                  valueOnChecked: true,
                  noBlur: true,
                })(<Switch />)}
                <span className="ant-switch-text">{t('vacation_form.manual_vacation_fund')}</span>
              </div>
            )}
          </SettingsLock>
        </Col>
        {getFieldValue('vacationType') === 'unpaid' && (
          <Col span={12}>
            <SettingsLock setting={'AllowDayLaborer'} description={t('vacation_form.use_day_laborer.lock')}>
              <div className="ant-switch-wrapper">
                {decorateField('useDayLaborer', {
                  skipWrapper: true,
                  skipLabel: true,
                  valueOnChecked: true,
                  noBlur: true,
                })(<Switch />)}
                <span className="ant-switch-text">{t('vacation_form.use_day_laborer')}</span>
              </div>
              {getFieldValue('useDayLaborer') &&
                decorateField('dayLaborer', { title: 'Ferieberegning', skipWrapper: true })(
                  <RadioGroup>
                    <Radio value={DayLaborer.NONE}>{t('vacation_form.use_day_laborer.none')}</Radio>
                    <Radio value={DayLaborer.DAYS}>{t('vacation_form.use_day_laborer.days')}</Radio>
                    <Radio value={DayLaborer.HOURS}>{t('vacation_form.use_day_laborer.hours')}</Radio>
                  </RadioGroup>
                )}
            </SettingsLock>
          </Col>
        )}
        {getFieldValue('vacationType') === 'none' &&
          (!getFieldValue('useATPInitial') || company.settingsEnabled.some((setting) => setting === 'AllowNoATP')) && (
            <Col span={12}>
              <div className="ant-switch-wrapper">
                {decorateField('useATP', {
                  skipWrapper: true,
                  skipLabel: true,
                  valueOnChecked: true,
                  noBlur: true,
                })(<Switch />)}
                <span className="ant-switch-text">{t('vacation_form.use_atp')}</span>
              </div>
            </Col>
          )}
        {company.settingsEnabled.some((setting) => setting === 'EnableFlexAndOvertime') && (
          <Col span={12}>
            {decorateField('timeBank', {
              title: t('vacation_form.time_bank'),
              skipWrapper: true,
            })(
              <RadioGroup>
                <Radio value={'None'}>{t('vacation_form.time_bank.none')}</Radio>
                <Radio value={'Flex'}>{t('vacation_form.time_bank.flex')}</Radio>
                <Radio value={'Overtime'}>{t('vacation_form.time_bank.overtime')}</Radio>
              </RadioGroup>
            )}
            {getFieldValue('timeBank') === 'Overtime' &&
              decorateField('overtimeRate', {
                title: t('vacation_form.overtime_rate'),
                suffix: t('vacation_form.overtime_rate.suffix'),
                validate: (val, allValues) => {
                  if (allValues.timeBank !== 'Overtime') {
                    return null
                  }
                  if (!val) {
                    return t('vacation_form.overtime_rate.required')
                  }
                  if (parseInputNumber(val) < 1 || !val.toString().match(/^[0-9,.]+$/)) {
                    return t('vacation_form.overtime_rate.invalid')
                  }
                  return null
                },
              })(<Input />)}
          </Col>
        )}
      </Row>
      {getFieldValue('hasVacationFund') && getFieldValue('vacationFundCVR') === '34498458' && (
        <Row>
          <Col span={12}>
            <div className="ant-switch-wrapper">
              {decorateField('horestaVacationSupplement', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">{t('vacation_form.horesta_vacation_supplement')}</span>
            </div>
          </Col>
          {/*getFieldValue('horestaVacationSupplement') && ( TODO Support percentage
                <div className="ant-switch-wrapper">
                  {decorateField('horestaVacationSupplementPercentage', {
                    skipLabel: true
                  })(<Switch checkedChildren="Procent" unCheckedChildren="Beløb" />)}
                </div>
              )*/}
          {getFieldValue('horestaVacationSupplement') && (
            <Col span={12}>
              {decorateField('horestaVacationSupplementAmount', {
                placeholder: t('vacation_form.horesta_vacation_supplement_amount'),
                suffix: t('vacation_form.horesta_vacation_supplement_amount.suffix'), //getFieldValue('horestaVacationSupplementPercentage') ? '%' : 'kr.',
                validate: (val, allValues) => {
                  if (!allValues.horestaVacationSupplement) {
                    return null
                  }
                  if (!val) {
                    return t('vacation_form.horesta_vacation_supplement_amount.required')
                  }
                  if (parseInputNumber(val) < 1 || parseInputNumber(val) > 100 || !val.toString().match(/^[0-9,.]+$/)) {
                    return t('vacation_form.horesta_vacation_supplement_amount.invalid')
                  }
                  return null
                },
              })(<Input />)}
            </Col>
          )}
        </Row>
      )}
      {showGreatPrayerDay && (
        <Row>
          <Col span={12}>
            <div className="ant-switch-wrapper">
              {decorateField('greatPrayerDaySupplement', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">{t('vacation_form.great_prayer_supplement')}</span>
            </div>
          </Col>
        </Row>
      )}
      {showVacationSupplementInPensionBasis && (
        <Row>
          <Col span={12}>
            <div className="ant-switch-wrapper">
              {decorateField('includeVacationSupplementsInPensionBasis', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">{t('vacation_form.vacation_supplement_in_pension_basis')}</span>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
