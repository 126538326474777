import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { paths } from '../../routes'
import MfaChannel from '../../types/mfa-channel'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatMfaChannel } from '../../utils/format-utils'
import { t, tx } from '../../utils/translation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import Checkbox from '../elements/checkbox'
import Input from '../elements/input'
import DumbLink from '../widgets/DumbLink'

type Props = {
  email?: string
  channel?: MfaChannel
  remember?: boolean
}

export type Fields = {
  email?: string
  password?: string
  response?: string
  recoveryCode?: string
  trustDevice?: boolean
  useRecoveryCode: boolean
  remember: boolean
}

function LoginForm(props: Props & FormComponentProps<Fields, Fields>): ReactElement | null {
  const toggleRecoveryCode = (e: React.MouseEvent) => {
    e.preventDefault()

    props.setFieldValue('useRecoveryCode', true)
  }

  const { decorateField, getFieldValue } = props

  if (props.channel) {
    const useRecoveryCode = getFieldValue('useRecoveryCode')
    let desc
    if (useRecoveryCode) {
      desc = t('login.channel.recovery_code')
    } else {
      desc = t('login.channel.mfa_channel', { channel: formatMfaChannel(props.channel) })
    }
    return (
      <div>
        <p style={{ textAlign: 'left' }}>{t('login.channel.title', { desc })}</p>
        <p>&nbsp;</p>
        {!useRecoveryCode &&
          decorateField('response', {
            placeholder: t('login.channel.form.response'),
            validate: (val) => {
              if (!val) {
                return t('login.channel.form.response.required')
              }
              return null
            },
          })(<Input inputMode="numeric" autoComplete="one-time-code" />)}
        {useRecoveryCode &&
          decorateField('recoveryCode', {
            placeholder: t('login.channel.form.recovery_code'),
            validate: (val) => {
              if (!val) {
                return t('login.channel.form.recovery_code.required')
              }
              return null
            },
          })(<Input inputMode="numeric" />)}
        <Form.Item>
          <DumbLink onClick={toggleRecoveryCode} className="login-form-forgot">
            {t('login.channel.form.toggle_recovery_code')}
          </DumbLink>
          {decorateField('trustDevice', {
            skipWrapper: true,
            skipLabel: true,
            valueOnChecked: true,
          })(<Checkbox>{t('login.channel.form.trust_device')}</Checkbox>)}

          <Button htmlType="submit" type="secondary" id="button-log-in">
            Log ind
          </Button>
        </Form.Item>
      </div>
    )
  }
  return (
    <div>
      {decorateField('email', {
        placeholder: t('login.form.email'),
        validate: (val) => {
          if (!val) {
            return t('login.form.email.required')
          }
          return null
        },
      })(<Input />)}
      {decorateField('password', {
        placeholder: t('login.form.password'),
        validate: (val) => (!val ? t('login.form.password.required') : null),
      })(<Input type="password" />)}
      <Form.Item>
        <Link to={'/' + paths.REQUEST_PASSWORD} className="login-form-forgot">
          {t('login.form.forgot_password')}
        </Link>
        {decorateField('remember', {
          skipWrapper: true,
          skipLabel: true,
          valueOnChecked: true,
        })(<Checkbox>{t('login.form.remember')}</Checkbox>)}

        <Button htmlType="submit" type="secondary" id="button-log-in">
          {t('login.form.submit')}
        </Button>

        <Link to={'/' + paths.REGISTER + document.location.search}>
          <Button className="login-form-button">{t('login.form.create_new_user')}</Button>
        </Link>

        <p>
          {tx('login.form.employee_user_note', {
            link: (
              <a href="https://employee.salary.dk" target="_blank" rel="noopener noreferrer">
                {t('login.form.employee_user_note.link')}
              </a>
            ),
          })}
        </p>
      </Form.Item>
    </div>
  )
}

export default withValidations<Props, Fields, Fields>({
  mapPropsToFields: (props) => {
    const fields: Fields = { useRecoveryCode: false, remember: false }
    if (props.email) {
      fields.email = props.email
    }
    if (props.remember) {
      fields.remember = props.remember
    }
    return fields
  },
  onSubmit: (values) => values,
})(LoginForm)
