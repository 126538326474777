import React from 'react'

import {
  deleteAssetCategoryRequest,
  fetchAssetCategories,
  postAssetCategory,
  putAssetCategory,
} from '../api/asset-categories'
import ActionTypes from '../constants/action-types'
import AssetCategory, { AssetCategoryMutableFields } from '../model/assetCategory'
import { AssetCategoryAction } from '../reducers/assetCategories'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'

function creatingAssetCategory(companyID: string): AssetCategoryAction {
  return {
    type: ActionTypes.ASSET_CATEGORY_CREATING,
    companyID,
  }
}
export function createdAssetCategory(companyID: string, assetCategory: AssetCategory): AssetCategoryAction {
  return {
    type: ActionTypes.ASSET_CATEGORY_CREATED,
    companyID,
    assetCategory: assetCategory,
  }
}
function failedCreatingAssetCategory(companyID: string, error: Error): AssetCategoryAction {
  return {
    type: ActionTypes.ASSET_CATEGORY_CREATE_FAILED,
    error,
    companyID,
  }
}

function updatingAssetCategory(companyID: string): AssetCategoryAction {
  return {
    type: ActionTypes.ASSET_CATEGORY_UPDATING,
    companyID,
  }
}
export function updatedAssetCategory(companyID: string, assetCategory: AssetCategory): AssetCategoryAction {
  return {
    type: ActionTypes.ASSET_CATEGORY_UPDATED,
    companyID,
    assetCategory: assetCategory,
  }
}
function failedUpdatingAssetCategory(companyID: string, error: Error): AssetCategoryAction {
  return {
    type: ActionTypes.ASSET_CATEGORY_UPDATE_FAILED,
    error,
    companyID,
  }
}

function deletingAssetCategory(assetCategoryID: string): AssetCategoryAction {
  return {
    type: ActionTypes.ASSET_CATEGORY_DELETING,
    assetCategoryID: assetCategoryID,
  }
}
export function deletedAssetCategory(assetCategoryID: string): AssetCategoryAction {
  return {
    type: ActionTypes.ASSET_CATEGORY_DELETED,
    assetCategoryID: assetCategoryID,
  }
}
function failedDeletingAssetCategory(error: Error): AssetCategoryAction {
  return {
    type: ActionTypes.ASSET_CATEGORY_DELETE_FAILED,
    error,
  }
}

function loadingAssetCategories(companyID: string): AssetCategoryAction {
  return {
    type: ActionTypes.ASSET_CATEGORY_LOADING,
    companyID,
  }
}
function loadedAssetCategories(
  companyID: string,
  assetCategories: AssetCategory[],
  partial = false
): AssetCategoryAction {
  return {
    type: partial ? ActionTypes.ASSET_CATEGORY_LOADED_PARTIAL : ActionTypes.ASSET_CATEGORY_LOADED,
    assetCategories,
    companyID,
  }
}
function failedLoadingAssetCategories(companyID: string, error: Error): AssetCategoryAction {
  return {
    type: ActionTypes.ASSET_CATEGORY_LOAD_FAILED,
    error,
    companyID,
  }
}

export function createAssetCategory(assetCategory: AssetCategoryMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<AssetCategory | void> => {
    dispatch(creatingAssetCategory(assetCategory.companyID))
    return postAssetCategory(assetCategory)
      .then((response) => {
        dispatch(createdAssetCategory(assetCategory.companyID, response.data))
        return response.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingAssetCategory(assetCategory.companyID, e))
        }
      })
  }
}

export function updateAssetCategory(assetCategory: AssetCategoryMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<AssetCategory | void> => {
    dispatch(updatingAssetCategory(assetCategory.companyID))
    return putAssetCategory(assetCategory)
      .then((response) => {
        dispatch(updatedAssetCategory(assetCategory.companyID, response.data))
        return response.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingAssetCategory(assetCategory.companyID, e))
        }
      })
  }
}

export function deleteAssetCategory(assetCategoryID: string) {
  return (dispatch: React.Dispatch<any>): Promise<AssetCategory | void> => {
    dispatch(deletingAssetCategory(assetCategoryID))
    return deleteAssetCategoryRequest(assetCategoryID)
      .then(() => {
        dispatch(deletedAssetCategory(assetCategoryID))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingAssetCategory(e))
        }
      })
  }
}

export function getAssetCategories(offset?: number) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AssetCategory[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    if (!offset) {
      dispatch(loadingAssetCategories(companyID))
      offset = 0
    }
    const limit = 1000
    return fetchAssetCategories(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedAssetCategories(companyID, data)),
          (data) => dispatch(loadedAssetCategories(companyID, data, true)),
          (offset) => dispatch(getAssetCategories(offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingAssetCategories(companyID, e))
        }
      })
  }
}
