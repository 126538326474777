import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CompanyPricingPackage from '../model/companyPricingPackage'
import { getDate, isSameOrAfter } from '../utils/date-utils'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyPricingPackageReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  companyPricingPackages: List<CompanyPricingPackage>
  error: Error | null
}

const Params = Record<CompanyPricingPackageReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  companyPricingPackages: List<CompanyPricingPackage>(),
  error: null,
})

export interface CompanyPricingPackageAction extends ReducerAction {
  companyID?: string
  companyPricingPackages?: CompanyPricingPackage[]
  companyPricingPackage?: CompanyPricingPackage
  companyPricingPackageID?: string
  pricingPackageID?: string // Not actually used
}

export default (
  state: Record<CompanyPricingPackageReducer> = Params(),
  action: CompanyPricingPackageAction = { type: '' }
): Record<CompanyPricingPackageReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.COMPANY_PRICING_PACKAGES_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.COMPANY_PRICING_PACKAGES_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        companyPricingPackages: List<CompanyPricingPackage>(action.companyPricingPackages),
      })
    case ActionTypes.COMPANY_PRICING_PACKAGES_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.COMPANY_PRICING_PACKAGE_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_PRICING_PACKAGE_ADDED:
      if (!action.companyPricingPackage) {
        return state
      }
      idx = state
        .get('companyPricingPackages')
        .findIndex((item) => !!action.companyPricingPackage && item.id === action.companyPricingPackage.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('companyPricingPackages', state.get('companyPricingPackages').set(idx, action.companyPricingPackage))
      }
      return state
        .set('saving', false)
        .set('companyPricingPackages', state.get('companyPricingPackages').push(action.companyPricingPackage))
    case ActionTypes.COMPANY_PRICING_PACKAGE_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COMPANY_PRICING_PACKAGE_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_PRICING_PACKAGE_UPDATED:
      if (!action.companyPricingPackage) {
        return state
      }
      idx = state
        .get('companyPricingPackages')
        .findIndex((item) => !!action.companyPricingPackage && item.id === action.companyPricingPackage.id)
      if (idx === -1) {
        return state
      }
      // if the pricing package is no longer valid, remove it
      if (
        action.companyPricingPackage.validTo &&
        isSameOrAfter(getDate(), getDate(action.companyPricingPackage.validTo))
      ) {
        return state.set('saving', false).set('companyPricingPackages', state.get('companyPricingPackages').remove(idx))
      }
      return state
        .set('saving', false)
        .set('companyPricingPackages', state.get('companyPricingPackages').set(idx, action.companyPricingPackage))
    case ActionTypes.COMPANY_PRICING_PACKAGE_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COMPANY_PRICING_PACKAGE_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_PRICING_PACKAGE_DELETED:
      idx = state.get('companyPricingPackages').findIndex((item) => item.id === action.companyPricingPackageID)
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('companyPricingPackages', state.get('companyPricingPackages').delete(idx))
    case ActionTypes.COMPANY_PRICING_PACKAGE_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
