import Employment from '../model/employment'
import { DateFormat } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchEmployments(employeeID: string): Promise<RequestResponse<Employment[]>> {
  return secureRequest('GET', url('/v2/employments', { employeeID }))
}

export function postEmployment(employeeID: string, employment: Employment): Promise<RequestResponse<Employment>> {
  return secureRequest('POST', url('/v2/employments'), {
    body: {
      employeeID: employeeID,
      startDate: employment.startDate,
      endDate: employment.endDate,
      employeeNumber: employment.employeeNumber,
      preferredTaxCardType: employment.preferredTaxCardType,
      incomeType: employment.incomeType,
    },
  })
}

export function putEmployment(employment: Employment): Promise<RequestResponse<Employment>> {
  return secureRequest('PUT', url('/v2/employments/' + employment.id), {
    body: {
      startDate: employment.startDate,
      endDate: employment.endDate,
      employeeNumber: employment.employeeNumber,
      preferredTaxCardType: employment.preferredTaxCardType,
      incomeType: employment.incomeType,
    },
  })
}

export function patchTerminateEmployment(
  employmentID: string,
  endDate: DateFormat,
  removeOrphans: boolean
): Promise<RequestResponse<Employment>> {
  return secureRequest('PATCH', url('/v2/employments/' + employmentID + '/terminate'), {
    body: {
      endDate,
      removeOrphans,
    },
  })
}

export function postRehireEmployee(employeeID: string, startDate: DateFormat): Promise<RequestResponse<Employment>> {
  return secureRequest('POST', url('/v2/employees/' + employeeID + '/rehire'), {
    body: {
      startDate,
    },
  })
}
