import { startOfMonth, subMonths, subYears } from 'date-fns'
import React from 'react'

import { delOneTimePay, fetchOneTimePays, patchOneTimePays, postOneTimePay, putOneTimePay } from '../api/one-time-pays'
import ActionTypes from '../constants/action-types'
import OneTimePay, { OneTimePayCreationFields, OneTimePayMutableFields } from '../model/oneTimePay'
import { OneTimePayAction } from '../reducers/oneTimePays'
import { getDate, isTimeBefore } from '../utils/date-utils'
import { isRequestError } from '../utils/error-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'

function loadingOneTimePays(companyID?: string, employeeID?: string): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_LOADING,
    companyID,
    employeeID,
  }
}
function loadedOneTimePays(
  companyID: string | undefined,
  employeeID: string | undefined,
  oneTimePays: OneTimePay[],
  partial = false
): OneTimePayAction {
  return {
    type: partial ? ActionTypes.ONE_TIME_PAY_LOADED_PARTIAL : ActionTypes.ONE_TIME_PAY_LOADED,
    oneTimePays,
    companyID,
    employeeID,
  }
}
function failedLoadingOneTimePays(
  companyID: string | undefined,
  employeeID: string | undefined,
  error: Error
): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_LOAD_FAILED,
    error,
    companyID,
    employeeID,
  }
}

function addingOneTimePay(employeeID: string): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_ADDING,
    employeeID,
  }
}
export function addedOneTimePay(employeeID: string, oneTimePay: OneTimePay): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_ADDED,
    employeeID,
    oneTimePay,
  }
}
function failedAddingOneTimePay(employeeID: string, error: Error): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_ADD_FAILED,
    error,
    employeeID,
  }
}

function updatingOneTimePay(employeeID: string): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_UPDATING,
    employeeID,
  }
}
export function updatedOneTimePay(employeeID: string, oneTimePay: OneTimePay): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_UPDATED,
    employeeID,
    oneTimePayID: oneTimePay.id,
    oneTimePay,
  }
}
function failedUpdatingOneTimePay(employeeID: string, error: Error): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_UPDATE_FAILED,
    error,
    employeeID,
  }
}

function approvingOneTimePays(oneTimePayIDs: string[]): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_APPROVING,
    oneTimePayIDs,
  }
}
export function approvedOneTimePays(oneTimePayIDs: string[], approved: boolean): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_APPROVED,
    oneTimePayIDs,
    approved,
  }
}
function failedApprovingOneTimePays(oneTimePayIDs: string[], error: Error): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_APPROVE_FAILED,
    error,
    oneTimePayIDs,
  }
}

function deletingOneTimePay(): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_DELETING,
  }
}
export function deletedOneTimePay(oneTimePayID: string): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_DELETED,
    oneTimePayID,
  }
}
function failedDeletingOneTimePay(error: Error): OneTimePayAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_DELETE_FAILED,
    error,
  }
}

export function getOneTimePays(companyID?: string, employeeID?: string, offset?: number, dateCutOff?: Date | null) {
  return (dispatch: React.Dispatch<any>): Promise<OneTimePay[] | void> => {
    if (!offset) {
      dispatch(loadingOneTimePays(companyID, employeeID))
      offset = 0
    }
    const limit = 1000
    // we wish to limit the number of OTPs we fetch, if there are simply too many
    // we don't care under 1000, up till 10k, we only want 2 years back, and beyond that only 6 months
    // we then check the data we are returned, and see if it's too far back, and then we stop fetching more
    // yes, that can technically mean, we'll get a little beyond the cut off date, but that's acceptable
    return fetchOneTimePays(companyID, employeeID, limit, offset, 'dispositionDateDescending')
      .then((res) => {
        if (dateCutOff === undefined) {
          // not initialised
          const count = res.pagination?.count ?? 0
          if (count < 1000) {
            dateCutOff = null // no cut off
          } else if (count < 10000) {
            // go at most 2 years back
            dateCutOff = startOfMonth(subYears(getDate(), 2))
          } else {
            // go at most 6 months back
            dateCutOff = startOfMonth(subMonths(getDate(), 6))
          }
        }
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedOneTimePays(companyID, employeeID, data)),
          (data) => dispatch(loadedOneTimePays(companyID, employeeID, data, true)),
          (offset) => dispatch(getOneTimePays(companyID, employeeID, offset, dateCutOff)),
          (data) => {
            if (!dateCutOff) {
              return true
            }
            // check that the last one is the same or after our cut off date
            return !isTimeBefore(getDate(data[data.length - 1].dispositionDate), dateCutOff)
          }
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingOneTimePays(companyID, employeeID, e))
        }
      })
  }
}

export function addOneTimePay(employeeID: string, oneTimePay: OneTimePayCreationFields) {
  return (dispatch: React.Dispatch<any>): Promise<OneTimePay | void> => {
    dispatch(addingOneTimePay(employeeID))
    return postOneTimePay(employeeID, oneTimePay)
      .then((res) => {
        dispatch(addedOneTimePay(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingOneTimePay(employeeID, e))
        }
      })
  }
}

export function updateOneTimePay(employeeID: string, oneTimePay: OneTimePayMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<OneTimePay | void> => {
    if (!oneTimePay.id) {
      return PromiseVoid
    }
    dispatch(updatingOneTimePay(oneTimePay.id))
    return putOneTimePay(oneTimePay)
      .then((res) => {
        dispatch(updatedOneTimePay(oneTimePay.id!, res.data))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingOneTimePay(oneTimePay.id!, e))
        }
      })
  }
}

export function approveOneTimePays(oneTimePayIDs: string[]) {
  return (dispatch: React.Dispatch<any>): Promise<OneTimePay[] | void> => {
    dispatch(approvingOneTimePays(oneTimePayIDs))
    return patchOneTimePays('Approve', oneTimePayIDs)
      .then(() => {
        dispatch(approvedOneTimePays(oneTimePayIDs, true))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedApprovingOneTimePays(oneTimePayIDs, e))
        }
      })
  }
}

export function unapproveOneTimePays(oneTimePayIDs: string[]) {
  return (dispatch: React.Dispatch<any>): Promise<OneTimePay[] | void> => {
    dispatch(approvingOneTimePays(oneTimePayIDs))
    return patchOneTimePays('Unapprove', oneTimePayIDs)
      .then(() => {
        dispatch(approvedOneTimePays(oneTimePayIDs, false))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedApprovingOneTimePays(oneTimePayIDs, e))
        }
      })
  }
}

export function deleteOneTimePay(id: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(deletingOneTimePay())
    return delOneTimePay(id)
      .then(() => {
        dispatch(deletedOneTimePay(id))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingOneTimePay(e))
        }
      })
  }
}
