import { isSameDay, startOfYear, subYears } from 'date-fns'
import { List } from 'immutable'

import { CostCenterAccounting } from '../model/accountingIntegration'
import CostCenter from '../model/costCenter'
import OneTimePay from '../model/oneTimePay'
import SalaryPeriod from '../model/salaryPeriod'
import { DateFormat } from '../model/types'
import { getDate, isTimeBefore } from './date-utils'

export function hasCostCenters(
  costCenterAccounting: CostCenterAccounting,
  costCenters: List<CostCenter>,
  accountingType: CostCenterAccounting = 'CostCenters'
): boolean {
  return costCenterAccounting === accountingType && costCenters.size > 0
}

// return a list of disposition dates based on periods, and potential additional dates
export function getDispositionDateList(salaryPeriods: SalaryPeriod[], additionalDates: DateFormat[]): DateFormat[] {
  const startOfLastYear = startOfYear(subYears(getDate(), 1))

  const dates = salaryPeriods
    .filter(
      (salaryPeriod) =>
        isTimeBefore(startOfLastYear, getDate(salaryPeriod.latestStartTime)) &&
        !additionalDates.some((date) => isSameDay(getDate(salaryPeriod.dispositionDate), getDate(date)))
    )
    .map((period) => period.dispositionDate)

  additionalDates?.forEach((date) => dates.push(date))

  return dates.sort((a, b) => b.localeCompare(a))
}

export function getOneTimePaySelectedDate(oneTimePays: OneTimePay[], oneTimePayID?: string): DateFormat | undefined {
  if (!oneTimePayID) {
    return undefined
  }

  const oneTimePay = oneTimePays.find((oneTimePay) => oneTimePay.id === oneTimePayID)
  if (!oneTimePay) {
    return undefined
  }
  return oneTimePay.dispositionDate
}
