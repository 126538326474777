import RcSteps from 'rc-steps'
import React, { ReactElement } from 'react'

import './style/css'

type StepsProps = {
  prefixCls?: string
  iconPrefix?: string
  current?: number
  direction?: 'vertical'
  children?: React.ReactNode
}

function Steps(props: StepsProps): ReactElement | null {
  const { prefixCls = 'ant-steps', iconPrefix = 'ant', current = 0 } = props
  return <RcSteps prefixCls={prefixCls} iconPrefix={iconPrefix} current={current} {...props} />
}

type StepProps = {
  title?: string
}

Steps.Step = (props: StepProps): ReactElement | null => {
  return <RcSteps.Step {...props} />
}

export default Steps
