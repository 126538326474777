import { ReactElement, useEffect } from 'react'

import { setAccessToken } from '../utils/cookie-utils'
import { companySelected } from '../utils/hooks-utils'
import { logWarning } from '../utils/log-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

function InLogin(props: RouteProps): ReactElement | null {
  const accessToken = props.location.query.accessToken
  const companyID = props.location.query.companyID

  useEffect(() => {
    if (!accessToken) {
      logWarning('Attempted to in/login without an access token')
      document.location = '/'
      return
    }

    setAccessToken(accessToken)

    if (!companyID) {
      // not required
      document.location = '/'
      return
    }

    companySelected(companyID)
    document.location = '/'
  }, [accessToken, companyID])

  return null
}

export default connectToReducer<any, any, RouteProps>(() => ({}), {})(InLogin)
