import React, { CSSProperties, ReactElement } from 'react'

import './Elements.css'

// NEED_NO_TRANSLATION

export default function TitleMenu(props: { style?: CSSProperties; children?: React.ReactNode }): ReactElement {
  return (
    <div className="title-menu" style={props.style}>
      {props.children}
    </div>
  )
}
