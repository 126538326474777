import { request, RequestResponse, url } from '../utils/request-utils'

type Criipto = {
  url: string
}

export function getPersonVerificationUrl(): Promise<RequestResponse<Criipto>> {
  return request('GET', url('/v2/support/verifyPerson'))
}

export function postVerifyPersonCallback(signatureToken: string): Promise<RequestResponse> {
  return request('POST', url('/v2/support/verifyPerson'), {
    body: {
      signatureToken,
    },
  })
}
