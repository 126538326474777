import React, { ReactElement } from 'react'

import { DateFormat } from '../../model/types'
import Voucher from '../../model/voucher'
import { formatAPIDate } from '../../utils/date-utils'
import { t } from '../../utils/translation-utils'
import Card from '../elements/card'
import Title from '../elements/Title'
import VoucherDateModalForm, { VoucherDateResult } from './VoucherDateModalForm'

type Props = {
  visible: boolean
  voucher?: Voucher

  changeVoucherDate: (id: string, date: DateFormat) => Promise<Voucher | void>
  closeModal: () => void
}

export default function VoucherDateModal(props: Props): ReactElement | null {
  if (!props.voucher) {
    return null
  }

  const handleSubmit = (values: VoucherDateResult) => {
    if (!props.voucher) {
      return
    }
    props.changeVoucherDate(props.voucher.id, formatAPIDate(values.date)).finally(() => {
      props.closeModal()
    })
  }

  return (
    <Card>
      <Title>{t('voucher_date_modal.title')}</Title>
      <p>{t('voucher_date_modal.intro')}</p>
      <VoucherDateModalForm voucher={props.voucher} onSubmit={handleSubmit} />
    </Card>
  )
}
