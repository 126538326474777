import { AccountPlan, AccountPlanUpdateConfiguration } from '../model/accountingIntegration'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export type CompanyAccountingIntegrationStep =
  | 'NeedKey'
  | 'NeedAuth'
  | 'ChooseOrganization'
  | 'ChooseDaybook'
  | 'MapAccounts'
  | 'Done'

export type ExternalOrganization = {
  organizationID: string
  name: string
}
export type ExternalDaybook = {
  daybookID: string
  name: string
}
export type ExternalDimension = {
  dimensionID: string
  dimensionName: string
  costCenterID?: string
  costCenterName?: string
}
export type ExternalDimensionValue = {
  dimensionValueID: string
  dimensionValueName: string
  costCenterID?: string
  costCenterName?: string
}

export type CompanyAccountingIntegrationSetupStatus = {
  next: CompanyAccountingIntegrationStep
  steps: CompanyAccountingIntegrationStep[]
  state: string
  type: CompanyAccountingIntegrationType
  authURL?: string
  organizations?: ExternalOrganization[]
  allowNoDaybook?: boolean
  daybooks?: ExternalDaybook[]
  accountPlan?: AccountPlan
}

export type CompanyAccountingIntegrationType =
  | 'BillysBilling'
  | 'BusinessCentral'
  | 'Dinero'
  | 'Dynaccount'
  | 'Economics'
  | 'Inventio'
  | 'Meneto'
  | 'NetsuiteOmnit'
  | 'Uniconta'
  | 'Xena'
  | 'Xero'
  | 'Odoo'
  | 'None'

type InitCompanyAccountingIntegrationSetup = {
  type: CompanyAccountingIntegrationType
}

export function postCompanyAccountingIntegrationSetup(
  companyID: string,
  values: InitCompanyAccountingIntegrationSetup
): Promise<RequestResponse<CompanyAccountingIntegrationSetupStatus>> {
  return secureRequest('POST', url('/v2/accountingIntegrations/setup', { companyID }), { body: values })
}

export function postCompanyAccountingIntegrationReAuth(
  companyID: string
): Promise<RequestResponse<CompanyAccountingIntegrationSetupStatus>> {
  return secureRequest('POST', url('/v2/accountingIntegrations/reAuth', { companyID }))
}

export type AuthParameter =
  | 'apiKey'
  | 'username'
  | 'password'
  | 'apiID'
  | 'apiSecret'
  | 'realm'
  | 'consumerKey'
  | 'consumerSecret'
  | 'tokenKey'
  | 'tokenSecret'
  | 'externalURL'
  | 'domain'
  | 'database'

export type AuthParameters = Partial<Record<string, string>>

export type CompanyAccountingIntegrationSetup = {
  next?: CompanyAccountingIntegrationStep
  authParameters?: AuthParameters
  organizationID?: string
  daybookID?: string
  accountMapping?: AccountPlanUpdateConfiguration[]
}

export function patchCompanyAccountingIntegrationSetup(
  state: string,
  values: CompanyAccountingIntegrationSetup
): Promise<RequestResponse<CompanyAccountingIntegrationSetupStatus>> {
  return secureRequest('PATCH', url('/v2/accountingIntegrations/setup', { state }), { body: values })
}
