import React, { ReactElement } from 'react'

import Employee from '../../../model/employee'
import { EmployeeReducer } from '../../../reducers/employees'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  employee: Employee
  employees: EmployeeReducer
}

function DeleteEmployeeForm(props: Props & FormComponentProps<any, any>): ReactElement | null {
  const { employee } = props
  const isFreelancer = employee.affiliationType === 'Freelancer'

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" type="danger" size="large" block>
            {t(isFreelancer ? 'employee_delete.button.freelancer' : 'employee_delete.button.employee', {
              name: employee.name,
            })}
          </Button>
        </Col>
      </Row>
      {props.employees.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, any, any>({
  mapPropsToFields: () => ({}),
  onSubmit: () => ({}),
})(DeleteEmployeeForm)
