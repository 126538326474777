import React, { ReactElement } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { disableCompanySettings, enableCompanySettings, updateCompany } from '../actions/companies'
import AccountingSettingsComponent from '../components/accounting/AccountingSettings'
import Company from '../model/company'
import CompanySetting from '../model/companySetting'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import IntegrationsLayout from './layouts/IntegrationsLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateCompany: (company: Company) => Promise<Company | void>
  enableCompanySettings: (companyID: string, settings: CompanySetting[]) => Promise<Company | void>
  disableCompanySettings: (companyID: string, settings: CompanySetting[]) => Promise<Company | void>
}

function AccountingSettings(props: Reducers & Actions & RouteProps): ReactElement | null {
  const company = props.companies.company
  if (!company) {
    return null
  }

  return (
    <IntegrationsLayout location={props.location}>
      <AccountingSettingsComponent
        alerts={props.alerts}
        company={company}
        companies={props.companies}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        updateCompany={props.updateCompany}
        enableCompanySettings={props.enableCompanySettings}
        disableCompanySettings={props.disableCompanySettings}
      />
    </IntegrationsLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    updateCompany: updateCompany,
    enableCompanySettings: enableCompanySettings,
    disableCompanySettings: disableCompanySettings,
  }
)(AccountingSettings)
