import React from 'react'

import {
  delEmployeeEmergencyContact,
  fetchEmployeeEmergencyContact,
  putEmployeeEmergencyContact,
} from '../api/employee-emergency-contacts'
import ActionTypes from '../constants/action-types'
import EmployeeEmergencyContact, { EmployeeEmergencyContactMutableFields } from '../model/employeeEmergencyContact'
import { EmployeeEmergencyContactAction } from '../reducers/employeeEmergencyContacts'
import { isRequestError } from '../utils/error-utils'

function loadingEmployeeEmergencyContact(): EmployeeEmergencyContactAction {
  return {
    type: ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_LOADING,
  }
}
function loadedEmployeeEmergencyContact(
  employeeID: string,
  employeeEmergencyContact: EmployeeEmergencyContact | null
): EmployeeEmergencyContactAction {
  return {
    type: ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_LOADED,
    employeeID,
    employeeEmergencyContact,
  }
}
function failedLoadingEmployeeEmergencyContact(employeeID: string, error: Error): EmployeeEmergencyContactAction {
  return {
    type: ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_LOAD_FAILED,
    error,
    employeeID,
  }
}

function updatingEmployeeEmergencyContact(employeeID: string): EmployeeEmergencyContactAction {
  return {
    type: ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_UPDATING,
    employeeID,
  }
}
export function updatedEmployeeEmergencyContact(
  employeeID: string,
  employeeEmergencyContact: EmployeeEmergencyContact
): EmployeeEmergencyContactAction {
  return {
    type: ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_UPDATED,
    employeeID,
    employeeEmergencyContact,
  }
}
function failedUpdatingEmployeeEmergencyContact(employeeID: string, error: Error): EmployeeEmergencyContactAction {
  return {
    type: ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_UPDATE_FAILED,
    error,
    employeeID,
  }
}

function deletingEmployeeEmergencyContact(employeeID: string): EmployeeEmergencyContactAction {
  return {
    type: ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_DELETING,
    employeeID,
  }
}
export function deletedEmployeeEmergencyContact(employeeID: string): EmployeeEmergencyContactAction {
  return {
    type: ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_DELETED,
    employeeID,
  }
}
function failedDeletingEmployeeEmergencyContact(employeeID: string, error: Error): EmployeeEmergencyContactAction {
  return {
    type: ActionTypes.EMPLOYEE_EMERGENCY_CONTACT_DELETE_FAILED,
    error,
    employeeID,
  }
}

export function getEmployeeEmergencyContact(employeeID: string) {
  return (dispatch: React.Dispatch<any>): Promise<EmployeeEmergencyContact | undefined | void> => {
    dispatch(loadingEmployeeEmergencyContact())
    return fetchEmployeeEmergencyContact(employeeID)
      .then((res) => {
        dispatch(loadedEmployeeEmergencyContact(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          if (e.type === 'NotFound') {
            dispatch(loadedEmployeeEmergencyContact(employeeID, null))
          } else {
            dispatch(failedLoadingEmployeeEmergencyContact(employeeID, e))
          }
        }
      })
  }
}

export function updateEmployeeEmergencyContact(
  employeeID: string,
  employeeEmergencyContact: EmployeeEmergencyContactMutableFields
) {
  return (dispatch: React.Dispatch<any>): Promise<EmployeeEmergencyContact | void> => {
    dispatch(updatingEmployeeEmergencyContact(employeeID))
    return putEmployeeEmergencyContact(employeeID, employeeEmergencyContact)
      .then((res) => {
        dispatch(updatedEmployeeEmergencyContact(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingEmployeeEmergencyContact(employeeID, e))
        }
      })
  }
}

export function deleteEmployeeEmergencyContact(employeeID: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(deletingEmployeeEmergencyContact(employeeID))
    return delEmployeeEmergencyContact(employeeID)
      .then(() => {
        dispatch(deletedEmployeeEmergencyContact(employeeID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          if (e.type === 'NotFound') {
            dispatch(deletedEmployeeEmergencyContact(employeeID))
          } else {
            dispatch(failedDeletingEmployeeEmergencyContact(employeeID, e))
          }
        }
      })
  }
}
