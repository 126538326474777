import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import Project from '../../../model/project'
import SalaryType from '../../../model/salaryType'
import { TimeRegistrationMutableFields } from '../../../model/timeRegistration'
import { TimeRegistrationReducer } from '../../../reducers/timeRegistrations'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import { TimeRegistrationEditMode } from '../../types'
import { ResultFields as FormFields } from './DetailedTimeRegistrationEditForm'
import DetailedTimeRegistrationEditForm from './DetailedTimeRegistrationEditForm'

type BaseProps = {
  visible: boolean
  company: Company
  employee?: Employee
  suggestedDate?: Date
  mode: TimeRegistrationEditMode
  timeRegistrationID?: string
  timeRegistrations: TimeRegistrationReducer
  costCenters: List<CostCenter>
  departments: List<Department>
  includeDateRange?: [Date, Date] // from -> to

  createTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  deleteTimeRegistration?: (id: string) => void
  approveTimeRegistrations?: (ids: string[]) => void
}

type HoursProps = BaseProps & {
  mode: 'Hours'
  salaryTypes: List<SalaryType>
  projects: List<Project>
}

type TimeBoxProps = BaseProps & {
  mode: 'FlexTime' | 'Overtime'
  leaveTypes: List<LeaveType>
}

type ProjectProps = BaseProps & {
  mode: 'Project' | 'Work Hours'
  projects: List<Project>
}

export default function DetailedTimeRegistrationEdit(
  props: HoursProps | TimeBoxProps | ProjectProps
): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    visibleComponentDidUpdate(props.visible, props.timeRegistrations.error, error, setError)
  }, [props.visible, props.timeRegistrations.error, error])

  const handleSubmit = (values: FormFields) => {
    if (!props.employee) {
      return
    }
    const timeRegistration: TimeRegistrationMutableFields = {
      employeeID: props.employee.id,
      class: values.class,
      salaryTypeID: values.salaryTypeID,
      projectID: values.projectID,
      date: values.date,
      start: values.start,
      end: values.end,
      hours: values.hours,
      minutes: values.minutes,
      note: values.note || '',
      costCenterID: values.costCenterID,
      approved: true,
    }
    if (props.mode === 'FlexTime') {
      const leaveType = props.leaveTypes.find((leaveType) => leaveType.name === 'DenmarkFlexTime')
      if (leaveType) {
        timeRegistration.leaveTypeID = leaveType.id
      }
    }
    if (props.mode === 'Overtime') {
      const leaveType = props.leaveTypes.find((leaveType) => leaveType.name === 'DenmarkOvertime')
      if (leaveType) {
        timeRegistration.leaveTypeID = leaveType.id
      }
    }
    if (props.timeRegistrationID) {
      timeRegistration.id = props.timeRegistrationID
      props.updateTimeRegistration(timeRegistration)
    } else {
      props.createTimeRegistration(timeRegistration)
    }
  }

  const needsApproval = () => {
    let needsApproval = false
    if (props.timeRegistrationID) {
      const timeReg = props.timeRegistrations.timeRegistrations.find((reg) => reg.id === props.timeRegistrationID)
      if (timeReg) {
        needsApproval = !timeReg.approved
      }
    }
    return needsApproval
  }

  const approveTimeReg = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!props.approveTimeRegistrations || !props.timeRegistrationID || !needsApproval()) {
      return
    }
    props.approveTimeRegistrations([props.timeRegistrationID])
  }

  const deleteTimeReg = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!props.timeRegistrationID || !props.deleteTimeRegistration) {
      return
    }
    if (window.confirm(t('common.are_you_sure'))) {
      props.deleteTimeRegistration(props.timeRegistrationID)
    }
  }

  if (!props.employee) {
    return null
  }

  let title = t('time_registration_tab.detailed.edit.title.hours.create')
  if (props.timeRegistrationID) {
    title = t('time_registration_tab.detailed.edit.title.hours.edit')
    switch (props.mode) {
      case 'FlexTime':
        title = t('time_registration_tab.detailed.edit.title.flex_time.edit')
        break
      case 'Overtime':
        title = t('time_registration_tab.detailed.edit.title.overtime.edit')
        break
      case 'Project':
        title = t('time_registration_tab.detailed.edit.title.project.edit')
        break
      case 'Work Hours':
        title = t('time_registration_tab.detailed.edit.title.work_hours.edit')
        break
    }
  } else {
    switch (props.mode) {
      case 'FlexTime':
        title = t('time_registration_tab.detailed.edit.title.flex_time.create')
        break
      case 'Overtime':
        title = t('time_registration_tab.detailed.edit.title.overtime.create')
        break
      case 'Project':
        title = t('time_registration_tab.detailed.edit.title.project.create')
        break
      case 'Work Hours':
        title = t('time_registration_tab.detailed.edit.title.work_hours.create')
        break
    }
  }
  return (
    <Card>
      <Subtitle>{title}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <DetailedTimeRegistrationEditForm
        company={props.company}
        employee={props.employee}
        mode={props.mode}
        timeRegistrationID={props.timeRegistrationID}
        suggestedDate={props.suggestedDate}
        timeRegistrations={props.timeRegistrations}
        salaryTypes={props.mode === 'Hours' ? props.salaryTypes : List()}
        projects={
          props.mode === 'Hours' || props.mode === 'Project' || props.mode === 'Work Hours' ? props.projects : List()
        }
        costCenters={props.costCenters}
        departments={props.departments}
        includeDateRange={props.includeDateRange}
        onSubmit={handleSubmit}
      />
      {props.approveTimeRegistrations && needsApproval() && (
        <Button size="large" type="secondary" style={{ width: '100%', marginTop: '10px' }} onClick={approveTimeReg}>
          {t('time_registration_tab.detailed.edit.button.approve')}
        </Button>
      )}
      {props.deleteTimeRegistration && props.timeRegistrationID && (
        <Button type="danger" size="large" style={{ width: '100%', marginTop: '10px' }} onClick={deleteTimeReg}>
          {t('time_registration_tab.detailed.edit.button.delete')}
        </Button>
      )}
    </Card>
  )
}
