import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../model/company'
import CompanySetting from '../../model/companySetting'
import { CompanyReducer } from '../../reducers/companies'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import EarlyPaymentsModalForm, { EarlyPaymentsFields } from './EarlyPaymentsModalForm'

type Props = {
  visible: boolean
  companies: CompanyReducer
  company: Company
  updateCompany: (company: Company) => Promise<Company | void>
  enableCompanySettings: (companyID: string, enable: CompanySetting[]) => void
  disableCompanySettings: (companyID: string, disable: CompanySetting[]) => void
}

export default function EarlyPaymentsModal(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, companies } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, companies.error, error, setError)
  }, [visible, companies, error])

  const handleSubmit = (values: EarlyPaymentsFields) => {
    props.updateCompany({ ...props.company, ...{ enableEarlyPayments: values.enableEarlyPayments } })
  }

  return (
    <Card className="early-payments">
      <Subtitle>{t('early_payments.edit.title')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <p>{t('early_payments.edit.description')}</p>
      <p>
        <strong>{t('early_payments.edit.note')}</strong>
      </p>
      <EarlyPaymentsModalForm company={props.company} companies={props.companies} onSubmit={handleSubmit} />
    </Card>
  )
}
