import React, { ReactNode } from 'react'
import reactStringReplace from 'react-string-replace'

import { TitleTranslations } from '../model/salaryType'
import { getCurrentLanguage, getTranslationStrings } from './language-utils'
import { logWarning } from './log-utils'
import { formatDisplayNumber } from './number-utils'

type CountData = {
  count?: number
}

type Data<T> = {
  [key: string]: T | number | undefined
} & CountData

function getTerm(path: string, data: CountData): string {
  data = data || {}

  // Normalize translation path
  if (path.match(/[^a-z0-9_.]+/g)) {
    // even though we normalise it, it's still _bad_ that the input contained invalid characters
    logWarning(`Translation path (${path}) contained invalid character: ${path.match(/[^a-z0-9_.]+/g)}`)
    path = path.toLowerCase()
    path = path.replace(/[^a-z0-9.]+/g, '_')
  }

  // Check for pluralization
  if (data.count !== undefined) {
    path += data.count === 1 ? '.one' : '.many'
  }

  // Find translation for path
  const val = getTranslationStrings()[path]
  if (val === undefined) {
    if (process.env.NODE_ENV !== 'test') {
      logWarning('Missing translation: `' + path + '`')
    }
    return path
  }

  return val
}

function translateReactNode(path: string, data: Data<ReactNode>): ReactNode {
  const val = getTerm(path, data)
  let term

  // Replace variables in translation
  for (const key in data) {
    // special handling for converting count to a presentable number
    const item = key === 'count' ? formatDisplayNumber(data[key]) : data[key]
    if (item !== undefined && item !== null) {
      term = reactStringReplace(term ?? val, new RegExp(`{(${key})}`, 'ig'), (match, i) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        typeof item === 'object' && 'props' in item ? React.cloneElement(item, { key: `${key}-${i}` }) : item
      )
    }
  }

  return term
}

function translate(path: string, data: Data<string>): string {
  let val = getTerm(path, data)

  // Replace variables in translation
  for (const key in data) {
    // special handling for converting count to a presentable number
    const item = key === 'count' ? formatDisplayNumber(data[key]) : data[key]
    if (item !== undefined && item !== null) {
      val = val.replace(new RegExp('{' + key + '}', 'ig'), item.toString())
    }
  }

  return val
}

/**
 * `t()` takes a `path` and returns its message based on the current language (set by cookie).
 * If there is no translation for `path`, the `path` itself is returned.
 *
 * `data` allows the caller to insert things into message from `path`.
 * Those placeholders are indicated as `{key}`, where `key` in `data` will be replaced.
 *
 * `data` does have one special field: `count`; this determines whether the path will be suffixed
 * with either `.one` (where `count` = 1) or `.many` (where `count` != 1), allowing for plurisation handling.
 * @param {string | string[]} path If array, the path is joined together with `.`
 * @param data
 */
export function t(path: string | string[], data?: Data<string>): string {
  if (Array.isArray(path)) {
    return t(path.join('.'), data)
  }
  return translate.apply(null, [path, data || {}])
}

/**
 * `tx()` works the same as `t()`, but instead of returning a `string`, it returns a `ReactNode`,
 * and it allows the values on `data` to be `ReactNode`, meaning components can be inserted this way.
 * @param {string | string[]} path
 * @param data
 */
export function tx(path: string | string[], data?: Data<ReactNode>): ReactNode {
  if (Array.isArray(path)) {
    return tx(path.join('.'), data)
  }
  return translateReactNode.apply(null, [path, data || {}])
}

export type TitleTranslationElement = {
  title?: string | null
  titleTranslations?: TitleTranslations
}

export function translateGroupTitle(element?: TitleTranslationElement, preferTitle = true): string {
  if (!element) {
    return t('common.unknown')
  }
  if (preferTitle && !!element.title && element.title !== '') {
    return element.title
  }
  const titleTranslation = element.titleTranslations && element.titleTranslations[getCurrentLanguage()]
  if (titleTranslation) {
    return titleTranslation
  }
  // attempt Danish, as it might be there
  if (element.titleTranslations && element.titleTranslations['da']) {
    return element.titleTranslations['da']
  }
  if (element.title) {
    return element.title
  }
  if (process.env.NODE_ENV !== 'test') {
    logWarning('Missing title', { element })
  }
  return t('common.unknown')
}
