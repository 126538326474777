import CompanyUser from '../model/companyUser'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function putUserCompany(userCompany: CompanyUser): Promise<RequestResponse<CompanyUser>> {
  return secureRequest('PUT', url('/v2/userCompanies/' + userCompany.companyID), {
    body: {
      notificationPayRollAutomaticallyStarted: userCompany.notificationPayRollAutomaticallyStarted || false,
      notificationPayRollDone: userCompany.notificationPayRollDone || false,
      notificationPayRollDeadlineUpcoming: userCompany.notificationPayRollDeadlineUpcoming || false,
      notificationPayRollApprovalUpcoming: userCompany.notificationPayRollApprovalUpcoming || false,
      notificationSwipeAwaitingApproval: userCompany.notificationSwipeAwaitingApproval || false,
      notificationPayRollDepartmentApproval1: userCompany.notificationPayRollDepartmentApproval1 || false,
      notificationPayRollDepartmentApproval2: userCompany.notificationPayRollDepartmentApproval2 || false,
      notificationAutomaticZeroTaxReportDone: userCompany.notificationAutomaticZeroTaxReportDone || false,
      notificationTransferInfo: userCompany.notificationTransferInfo || false,
      notificationEmployeeUpdatedBanking: userCompany.notificationEmployeeUpdatedBanking || false,
    },
  })
}
