import React from 'react'

import {
  delCompanyUser,
  delCompanyUserPermission,
  delUserInvite,
  fetchCompanyUsers,
  fetchUserInvites,
  postCompanyUserPermission,
  postUserInvite,
} from '../api/company-users'
import ActionTypes from '../constants/action-types'
import CompanyUser, { UserPermission } from '../model/companyUser'
import { DepartmentUserMutableFields } from '../model/departmentUser'
import UserInvite from '../model/userInvite'
import { CompanyUserAction } from '../reducers/companyUsers'
import { UserInviteAction } from '../reducers/userInvites'
import UserCompanyType from '../types/user-company-type'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature, getUserID } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'

function loadingCompanyUsers(companyID: string): CompanyUserAction {
  return {
    type: ActionTypes.COMPANY_USERS_LOADING,
    companyID,
  }
}
export function loadedCompanyUsers(
  companyID: string,
  userID: string,
  companyUsers: CompanyUser[],
  partial = false
): CompanyUserAction {
  return {
    type: partial ? ActionTypes.COMPANY_USERS_LOADED_PARTIAL : ActionTypes.COMPANY_USERS_LOADED,
    companyUsers,
    companyID,
    userID,
  }
}
function failedLoadingCompanyUsers(companyID: string, error: Error): CompanyUserAction {
  return {
    type: ActionTypes.COMPANY_USERS_LOAD_FAILED,
    error,
    companyID,
  }
}

function deletingCompanyUser(companyID: string, userID: string): CompanyUserAction {
  return {
    type: ActionTypes.COMPANY_USER_DELETING,
    companyID,
    userID,
  }
}
function deletedCompanyUser(companyID: string, userID: string): CompanyUserAction {
  return {
    type: ActionTypes.COMPANY_USER_DELETED,
    companyID,
    userID,
  }
}
export function deletedCompanyUserByID(companyUserID: string): CompanyUserAction {
  return {
    type: ActionTypes.COMPANY_USER_DELETED,
    companyUserID,
  }
}
function failedDeletingCompanyUser(companyID: string, userID: string, error: Error): CompanyUserAction {
  return {
    type: ActionTypes.COMPANY_USER_DELETE_FAILED,
    error,
    companyID,
    userID,
  }
}

function loadingUserInvites(): UserInviteAction {
  return {
    type: ActionTypes.USER_INVITES_LOADING,
  }
}
function loadedUserInvites(companyID: string, userInvites: UserInvite[]): UserInviteAction {
  return {
    type: ActionTypes.USER_INVITES_LOADED,
    companyID,
    userInvites,
  }
}
function failedLoadingUserInvites(companyID: string, error: Error): UserInviteAction {
  return {
    type: ActionTypes.USER_INVITES_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingUserInvite(): UserInviteAction {
  return {
    type: ActionTypes.USER_INVITE_ADDING,
  }
}
export function addedUserInvite(companyID: string, userInvite?: UserInvite): UserInviteAction {
  return {
    type: ActionTypes.USER_INVITE_ADDED,
    companyID,
    userInvite,
  }
}
function failedAddingUserInvite(companyID: string, error: Error): UserInviteAction {
  return {
    type: ActionTypes.USER_INVITE_ADD_FAILED,
    error,
    companyID,
  }
}

function deletingUserInvite(companyID: string, email: string): UserInviteAction {
  return {
    type: ActionTypes.USER_INVITE_DELETING,
    companyID,
    email,
  }
}
export function deletedUserInvite(companyID: string, email: string): UserInviteAction {
  return {
    type: ActionTypes.USER_INVITE_DELETED,
    companyID,
    email,
  }
}
function failedDeletingUserInvite(companyID: string, email: string, error: Error): UserInviteAction {
  return {
    type: ActionTypes.USER_INVITE_DELETE_FAILED,
    error,
    companyID,
    email,
  }
}

function grantingCompanyUserPermission(): CompanyUserAction {
  return {
    type: ActionTypes.COMPANY_USER_PERMISSION_GRANTING,
  }
}
function grantedCompanyUserPermission(userCompanyID: string, companyUser: CompanyUser): CompanyUserAction {
  return {
    type: ActionTypes.COMPANY_USER_PERMISSION_GRANTED,
    userCompanyID,
    companyUser,
  }
}
function failedGrantingCompanyUserPermission(userCompanyID: string, error: Error): CompanyUserAction {
  return {
    type: ActionTypes.COMPANY_USER_PERMISSION_GRANT_FAILED,
    error,
    userCompanyID,
  }
}

function revokingCompanyUserPermission(): CompanyUserAction {
  return {
    type: ActionTypes.COMPANY_USER_PERMISSION_REVOKING,
  }
}
function revokedCompanyUserPermission(userCompanyID: string, permissionID: string): CompanyUserAction {
  return {
    type: ActionTypes.COMPANY_USER_PERMISSION_REVOKED,
    userCompanyID,
    permissionID,
  }
}
function failedRevokingCompanyUserPermission(permissionID: string, error: Error): CompanyUserAction {
  return {
    type: ActionTypes.COMPANY_USER_PERMISSION_REVOKE_FAILED,
    error,
    permissionID,
  }
}

export function getCompanyUsers(companyID?: string, offset?: number) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<CompanyUser[] | void> => {
    if (!companyID) {
      companyID = getCompanyID(getState)
      if (!companyID) {
        return PromiseVoid
      }
    }
    const userID = getUserID(getState)
    if (!userID) {
      return PromiseVoid
    }

    if (!offset) {
      dispatch(loadingCompanyUsers(companyID))
      offset = 0
    }
    const limit = 1000
    return fetchCompanyUsers(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedCompanyUsers(companyID!, userID, data)),
          (data) => dispatch(loadedCompanyUsers(companyID!, userID, data, true)),
          (offset) => dispatch(getCompanyUsers(companyID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompanyUsers(companyID!, e))
        }
      })
  }
}

export function deleteCompanyUser(userID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingCompanyUser(companyID, userID))
    return delCompanyUser(companyID, userID)
      .then(() => {
        dispatch(deletedCompanyUser(companyID, userID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingCompanyUser(companyID, userID, e))
        }
      })
  }
}

export function getUserInvites() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<UserInvite[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingUserInvites())
    return fetchUserInvites(companyID)
      .then((res) => {
        dispatch(loadedUserInvites(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingUserInvites(companyID, e))
        }
      })
  }
}

export function addUserInvite(
  email: string,
  userType: UserCompanyType,
  permissions: UserPermission[] = [],
  departments: DepartmentUserMutableFields[] | undefined = undefined,
  name?: string,
  phoneNumberCountryCode?: string,
  phoneNumber?: string
) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<UserInvite | undefined | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingUserInvite())
    return postUserInvite(
      companyID,
      email,
      userType,
      permissions,
      departments,
      name,
      phoneNumberCountryCode,
      phoneNumber
    )
      .then((res) => {
        dispatch(addedUserInvite(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingUserInvite(companyID, e))
        }
      })
  }
}

export function deleteUserInvite(email: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingUserInvite(companyID, email))
    return delUserInvite(companyID, email)
      .then(() => {
        dispatch(deletedUserInvite(companyID, email))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingUserInvite(companyID, email, e))
        }
      })
  }
}

export function grantCompanyUserPermission(userCompanyID: string, permission: UserPermission) {
  return (dispatch: React.Dispatch<any>): Promise<CompanyUser | void> => {
    dispatch(grantingCompanyUserPermission())
    return postCompanyUserPermission(userCompanyID, permission)
      .then((res) => {
        dispatch(grantedCompanyUserPermission(userCompanyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedGrantingCompanyUserPermission(userCompanyID, e))
        }
      })
  }
}

export function revokeCompanyUserPermission(userCompanyID: string, permissionID: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(revokingCompanyUserPermission())
    return delCompanyUserPermission(permissionID)
      .then(() => {
        dispatch(revokedCompanyUserPermission(userCompanyID, permissionID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedRevokingCompanyUserPermission(permissionID, e))
        }
      })
  }
}
