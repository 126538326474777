import React from 'react'

import { fetchSupplementBalances } from '../api/supplement-balances'
import ActionTypes from '../constants/action-types'
import SupplementBalance from '../model/supplementBalance'
import { SupplementBalanceAction } from '../reducers/supplementBalances'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingSupplementBalances(
  companyID: string | undefined,
  employeeID: string | undefined
): SupplementBalanceAction {
  return {
    type: ActionTypes.SUPPLEMENT_BALANCES_LOADING,
    companyID,
    employeeID,
  }
}
function loadedSupplementBalances(
  companyID: string | undefined,
  employeeID: string | undefined,
  supplementBalances: SupplementBalance[],
  partial = false
): SupplementBalanceAction {
  return {
    type: partial ? ActionTypes.SUPPLEMENT_BALANCES_LOADED_PARTIAL : ActionTypes.SUPPLEMENT_BALANCES_LOADED,
    companyID,
    employeeID,
    supplementBalances,
  }
}
function failedLoadingSupplementBalances(
  companyID: string | undefined,
  employeeID: string | undefined,
  error: Error
): SupplementBalanceAction {
  return {
    type: ActionTypes.SUPPLEMENT_BALANCES_LOAD_FAILED,
    error,
    companyID,
    employeeID,
  }
}

export function updatedSupplementBalance(
  employeeID: string,
  supplementBalance: SupplementBalance
): SupplementBalanceAction {
  return {
    type: ActionTypes.SUPPLEMENT_BALANCE_UPDATED,
    employeeID,
    supplementTypeName: supplementBalance.supplementTypeName,
    supplementBalance,
  }
}

export function getSupplementBalances(companyID?: string, employeeID?: string, offset?: number) {
  return (dispatch: React.Dispatch<any>): Promise<SupplementBalance[] | void> => {
    if (!offset) {
      dispatch(loadingSupplementBalances(companyID, employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchSupplementBalances(companyID, employeeID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedSupplementBalances(companyID, employeeID, data)),
          (data) => dispatch(loadedSupplementBalances(companyID, employeeID, data, true)),
          (offset) => dispatch(getSupplementBalances(companyID, employeeID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSupplementBalances(companyID, employeeID, e))
        }
      })
  }
}
