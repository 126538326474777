import PropTypes from 'prop-types'

export default (componentName, defaultLocale) =>
  function (Component) {
    const ComponentWithStatics = Component
    class NewComponent extends Component {
      getLocale() {
        const { antLocale } = this.context
        const localeFromContext = antLocale && antLocale[componentName]
        const localeFromProps = this.props.locale || {}
        return {
          ...defaultLocale,
          ...(localeFromContext || {}),
          ...localeFromProps,
        }
      }
    }

    NewComponent.propTypes = ComponentWithStatics.propTypes
    NewComponent.defaultProps = ComponentWithStatics.defaultProps
    NewComponent.contextTypes = {
      ...(ComponentWithStatics.context || {}),
      antLocale: PropTypes.object,
    }

    return NewComponent
  }
