import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import Project from '../../../model/project'
import SalaryType from '../../../model/salaryType'
import { TimeRegistrationReducer } from '../../../reducers/timeRegistrations'
import { formatDate } from '../../../utils/date-utils'
import { formatLeaveTypeName } from '../../../utils/format-utils'
import { formatDisplayNumber } from '../../../utils/number-utils'
import { getSalaryType } from '../../../utils/salary-type-utils'
import { t } from '../../../utils/translation-utils'
import Table from '../../antd/table'
import Icon from '../../elements/Icon'
import Tooltip from '../../elements/tooltip'
import { TimeRegistrationMode } from '../../types'

type Props = {
  employee: Employee
  mode: TimeRegistrationMode
  timeRegistrations: TimeRegistrationReducer
  salaryTypes: List<SalaryType>
  leaveTypes: List<LeaveType>
  projects: List<Project>
}

export default function DetailedTimeRegistrationHistoryTab(props: Props): ReactElement | null {
  type TimeRegistrationRow = {
    key: string
    id: string
    date: string
    description: string
    hours: string
    note?: string
  }
  const columns = [
    { title: t('time_registration_tab.detailed.history.table.header.date'), dataIndex: 'date', key: 'date' },
    {
      title: t('time_registration_tab.detailed.history.table.header.description'),
      dataIndex: 'description',
      key: 'description',
    },
    { title: t('time_registration_tab.detailed.history.table.header.hours'), dataIndex: 'hours', key: 'hours' },
    {
      title: t('time_registration_tab.detailed.history.table.header.note'),
      key: 'x2',
      className: 'employee-table-actions',
      render: (timeRegistration: TimeRegistrationRow) => {
        if (!timeRegistration.note) {
          return null
        }
        return (
          <div>
            <Tooltip title={timeRegistration.note}>
              <span>
                <Icon type="file" color="grey" />
              </span>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const getTimeRegistrations = (): TimeRegistrationRow[] => {
    const isHourRegistration = props.mode === 'Hours'
    return props.timeRegistrations.timeRegistrations
      .filter(
        (timeRegistration) =>
          (isHourRegistration && timeRegistration.class === 'Hours') ||
          (props.mode === 'FlexTime' && timeRegistration.class === 'Flex') ||
          (props.mode === 'Overtime' && timeRegistration.class === 'Overtime') ||
          (props.mode === 'Project' && timeRegistration.class === 'Project Hours')
      )
      .filter((timeRegistration) => timeRegistration.settled)
      .map((timeRegistration) => {
        let title: undefined | string
        let hours = formatDisplayNumber(timeRegistration.hours)
        if (timeRegistration.class === 'Hours') {
          const salary = props.employee.activeContract?.remuneration?.salary.find(
            (row) => row.salaryTypeID === timeRegistration.salaryTypeID
          )
          title = salary?.title
          if (!title) {
            title = getSalaryType(props.salaryTypes.toArray(), timeRegistration.salaryTypeID)?.title || ''
          }
        } else if (timeRegistration.class === 'Flex' || timeRegistration.class === 'Overtime') {
          const num = (timeRegistration.minutes || 0) / 60
          if (props.mode === 'Overtime') {
            title = formatLeaveTypeName('DenmarkOvertime')
          } else {
            title = formatLeaveTypeName('DenmarkFlexTime')
          }
          if ((timeRegistration.minutes || 0) < 0) {
            hours = t('time_registration_tab.detailed.history.table.title.earned', {
              hours: formatDisplayNumber(num * -1),
            })
          } else {
            hours = t('time_registration_tab.detailed.history.table.title.used', { hours: formatDisplayNumber(num) })
          }
        } else {
          title = props.projects.find((p) => p.id === timeRegistration.projectID)?.name || ''
        }
        return {
          key: timeRegistration.id,
          id: timeRegistration.id,
          date: formatDate(timeRegistration.date),
          description: title,
          hours,
          note: timeRegistration.note,
        }
      })
      .toArray()
  }

  return (
    <div>
      <Table columns={columns} dataSource={getTimeRegistrations()} size="small" pagination={false} />
    </div>
  )
}
