import React, { ReactElement } from 'react'

import { CompanyGroupUser } from '../../../model/companyGroup'
import { UserPermission } from '../../../model/companyUser'
import { CompanyGroupReducer } from '../../../reducers/companyGroups'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { formatUserPermission } from '../../../utils/format-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Checkbox from '../../elements/checkbox'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Switch from '../../elements/switch'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  companyGroups: CompanyGroupReducer
  companyGroupUser: CompanyGroupUser
}

type Fields = Partial<Record<`permission-${UserPermission}`, boolean>>

export type UserEditResult = {
  permissions: UserPermission[]
}

const permissions: UserPermission[] = ['ApprovePayRoll', 'ReviewPayRoll']

function UserEditModalForm(props: Props & FormComponentProps<Fields, UserEditResult>): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('permission-Admin', {
            title: formatUserPermission('Admin'),
            valueOnChecked: true,
            noBlur: true,
          })(<Switch />)}
        </Col>
      </Row>
      <Row>
        {permissions.map((permission) => {
          return (
            <Col span={12}>
              {decorateField(`permission-${permission}`, {
                title: formatUserPermission(permission),
                valueOnChecked: true,
                noBlur: true,
              })(<Checkbox />)}
            </Col>
          )
        })}
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.companyGroups.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, UserEditResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {}
    permissions.forEach((permission) => {
      fields[`permission-${permission}`] = props.companyGroupUser.permissions.some((row) => row === permission)
    })
    if (props.companyGroupUser.permissions.some((row) => row === 'Admin')) {
      fields[`permission-Admin`] = true
    }
    return fields
  },
  onSubmit: (values) => {
    const newPermissions: UserPermission[] = []
    permissions.forEach((permission) => {
      if (values[`permission-${permission}`]) {
        newPermissions.push(permission)
      }
    })
    if (values['permission-Admin']) {
      newPermissions.push('Admin')
    } else {
      newPermissions.push('Manager')
    }
    return {
      permissions: newPermissions,
    }
  },
})(UserEditModalForm)
