import React, { ReactElement } from 'react'

import { formatDisplayNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Alert from './alert'
import Button from './button'

type Summary = {
  count: number
  value: number
}

type Props = {
  isDetailed: boolean
  detailedSummary: Summary
  coarseSummary: Summary
  itemMessageID: 'time_registration' | 'car_allowance' | 'salary_registration'

  deleteDetailed: () => void
  deleteCoarse: () => void
  switchToDetailed: () => void
  switchToCoarse: () => void
  formatValue: (value: number) => string
}

export function RegistrationMethodAlert(props: Props): ReactElement | null {
  if (
    (props.isDetailed && props.coarseSummary.count === 0) ||
    (!props.isDetailed && props.detailedSummary.count === 0)
  ) {
    return null
  }
  let summary: Summary
  if (props.isDetailed) {
    summary = props.coarseSummary
  } else {
    summary = props.detailedSummary
  }

  const deleteRegistrations = () => {
    if (window.confirm(t('common.are_you_sure'))) {
      if (props.isDetailed) {
        props.deleteCoarse()
      } else {
        props.deleteDetailed()
      }
    }
  }

  const switchMethod = () => {
    if (props.isDetailed) {
      props.switchToCoarse()
    } else {
      props.switchToDetailed()
    }
  }

  return (
    <Alert
      type="warning"
      message={
        <div className="registration-method-alert-message">
          <div>
            {props.isDetailed
              ? t('registrations.general.hidden_coarse_registrations', {
                  amount: formatDisplayNumber(summary.count),
                  descriptor: t([props.itemMessageID, `coarse_${props.itemMessageID}`], { count: summary.count }),
                  value: props.formatValue(summary.value),
                })
              : t('registrations.general.hidden_detailed_registrations', {
                  amount: formatDisplayNumber(summary.count),
                  descriptor: t([props.itemMessageID, `detailed_${props.itemMessageID}`], { count: summary.count }),
                  value: props.formatValue(summary.value),
                })}
          </div>
          <div className="registration-method-alert-buttons">
            <Button type="danger" regular onClick={() => deleteRegistrations()}>
              {t('registrations.general.delete_hidden_registrations')}
            </Button>
            {((props.isDetailed && props.detailedSummary.count === 0) ||
              (!props.isDetailed && props.coarseSummary.count === 0)) && (
              <Button type="secondary" regular onClick={() => switchMethod()}>
                {props.isDetailed
                  ? t('registrations.general.switch_to_coarse')
                  : t('registrations.general.switch_to_detailed')}
              </Button>
            )}
          </div>
        </div>
      }
      showIcon
    />
  )
}
