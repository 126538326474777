import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { CompanyAccountingIntegrationType } from '../../api/company-accounting-integration-setup'
import {
  AccountingMapping,
  AccountPlanUpdateConfiguration,
  BookingRule,
  CostCenterAccounting,
} from '../../model/accountingIntegration'
import AvailableAccountingIntegration from '../../model/availableAccountingIntegration'
import CostCenter from '../../model/costCenter'
import { CompanyAccountPlanReducer } from '../../reducers/companyAccountPlans'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatAccountingIntegration, formatBookingRule } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import AccountMappingForm, { NO_ACCOUNT } from '../company-accounting-integration/AccountMappingForm'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'

type Props = {
  integrationType: CompanyAccountingIntegrationType
  costCenterAccounting: CostCenterAccounting
  costCenterID: string
  displayName?: string
  bookingRule?: BookingRule
  availableAccountingIntegrations: List<AvailableAccountingIntegration>
  accountMapping: AccountingMapping[]
  companyAccountPlans: CompanyAccountPlanReducer
  costCenters: List<CostCenter>

  onCostCenterChange: (value: string) => void
  getCompanyAccountPlans: () => void
}

export type AccountingFormFields = {
  accountMapping: AccountPlanUpdateConfiguration[]
  bookingRule?: BookingRule
}

function AccountingForm(
  props: Props & FormComponentProps<AccountingFormFields, AccountingFormFields>
): ReactElement | null {
  const getAllowedBookingRules = (type: CompanyAccountingIntegrationType): BookingRule[] => {
    let allowedBookingRules: BookingRule[] = []
    if (type === 'None') {
      return allowedBookingRules
    }
    const integration = props.availableAccountingIntegrations.find((integration) => integration.type === type)
    if (integration && integration.allowedBookingRules) {
      allowedBookingRules = integration.allowedBookingRules
    }
    return allowedBookingRules
  }

  const handleRefresh = (e: React.MouseEvent) => {
    e.preventDefault()

    props.getCompanyAccountPlans()
  }

  const { decorateField } = props

  const integrationType = props.integrationType
  const allowedBookingRules = getAllowedBookingRules(integrationType)
  return (
    <div>
      <Row>&nbsp;</Row>
      {props.getFormError()}
      {allowedBookingRules.length > 1 && (
        <Row>
          <Col span={12}>
            {decorateField('bookingRule', {
              title: t('accounting_tab.form.booking_rule', {
                system: props.displayName || formatAccountingIntegration(integrationType),
              }),
              placeholder: t('accounting_tab.form.booking_rule.placeholder'),
              validate: (val) => (!val ? t('accounting_tab.form.booking_rule.required') : null),
            })(
              <Select dropdownMatchSelectWidth={false}>
                {allowedBookingRules.map((allowedBookingRule) => {
                  return (
                    <Select.Option key={allowedBookingRule} value={allowedBookingRule}>
                      {formatBookingRule(allowedBookingRule)}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: 5 }}>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary" className="gtm-accounting-integration-save">
            {t('form.button.save_changes')}
          </Button>
          {integrationType !== 'None' && (
            <Button onClick={handleRefresh} className="gtm-refresh-account-plan">
              {t('accounting_tab.form.refresh_account_plan')}
            </Button>
          )}
        </Col>
      </Row>
      <Row>&nbsp;</Row>
      {props.costCenterAccounting !== 'Off' && (
        <div className="companies-accounting-cost-centers">
          <Row>
            <Col span={8}>
              <label>
                {props.costCenterAccounting === 'CostCenters'
                  ? t('accounting_tab.form.select_cost_center.cost_centers')
                  : t('accounting_tab.form.select_cost_center.departments')}
              </label>
              <Select
                value={props.costCenterID}
                showSearch={true}
                filterOption={(inputValue: string, option: ReactElement) => {
                  const item = option.props.children
                  return item.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }}
                onChange={props.onCostCenterChange}
              >
                <Select.Option value={''}>{t('accounting_tab.form.select_cost_center.company')}</Select.Option>
                {props.costCenters
                  .filter((costCenter) => costCenter.active)
                  .map((costCenter) => {
                    return (
                      <Select.Option key={costCenter.id} value={costCenter.id}>
                        {costCenter.name}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Col>
          </Row>
          <Row>&nbsp;</Row>
        </div>
      )}
      <AccountMappingForm<AccountingFormFields>
        decorateAnyField={props.decorateAnyField}
        getFieldValue={props.getFieldValue}
        setAnyFieldValue={props.setAnyFieldValue}
        getAnyFieldValue={props.getAnyFieldValue}
        getAnyFieldError={props.getAnyFieldError}
        companyAccountPlans={props.companyAccountPlans}
        integrationType={props.integrationType}
      />
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary" className="gtm-accounting-integration-save">
            {t('form.button.save_changes')}
          </Button>
          {integrationType !== 'None' && (
            <Button onClick={handleRefresh} className="gtm-refresh-account-plan">
              {t('accounting_tab.form.refresh_account_plan')}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, AccountingFormFields, AccountingFormFields>({
  mapPropsToFields: (props) => {
    let costCenterID = props.costCenterID
    let accountMapping = props.accountMapping.find((v) => v.costCenterID === costCenterID)
    if (!accountMapping) {
      costCenterID = ''
      accountMapping = props.accountMapping.find((v) => v.costCenterID === costCenterID)
    }
    let configurations: AccountPlanUpdateConfiguration[] = []
    if (accountMapping) {
      configurations = accountMapping.configurations.map((account): AccountPlanUpdateConfiguration => {
        const externalID =
          accountMapping && accountMapping.costCenterID === props.costCenterID ? account.externalID : undefined
        return {
          accountNumber: props.integrationType === 'None' ? externalID : account.accountNumber,
          accountType: account.accountType,
          externalID: externalID && externalID !== '' ? externalID : props.integrationType === 'None' ? '' : NO_ACCOUNT,
          text: account.text,
        }
      })
    }
    const fields: AccountingFormFields = {
      accountMapping: configurations,
    }
    if (props.integrationType !== 'None') {
      fields.bookingRule = props.bookingRule
    }
    return fields
  },
  onSubmit: (values) => {
    values.accountMapping = values.accountMapping.map((mapping) => {
      if (mapping.externalID === NO_ACCOUNT) {
        mapping.externalID = undefined
        mapping.accountNumber = undefined
      }
      return mapping
    })
    return values
  },
})(AccountingForm)
