import React from 'react'

import { fetchStartBalances, putStartBalances } from '../api/start-balances'
import ActionTypes from '../constants/action-types'
import { StartBalances } from '../model/startBalances'
import { StartBalanceAction } from '../reducers/startBalances'
import { isRequestError } from '../utils/error-utils'

function loadingStartBalances(employmentID: string): StartBalanceAction {
  return {
    type: ActionTypes.START_BALANCES_LOADING,
    employmentID,
  }
}
function loadedStartBalances(employmentID: string, startBalances: StartBalances<number> | null): StartBalanceAction {
  return {
    type: ActionTypes.START_BALANCES_LOADED,
    startBalances,
    employmentID,
  }
}
function failedLoadingStartBalances(employmentID: string, error: Error): StartBalanceAction {
  return {
    type: ActionTypes.START_BALANCES_LOAD_FAILED,
    error,
    employmentID,
  }
}

function updatingStartBalances(employmentID: string): StartBalanceAction {
  return {
    type: ActionTypes.START_BALANCES_UPDATING,
    employmentID,
  }
}
export function updatedStartBalances(employmentID: string, startBalances: StartBalances<number>): StartBalanceAction {
  return {
    type: ActionTypes.START_BALANCES_UPDATED,
    employmentID,
    startBalances,
  }
}
function failedUpdatingStartBalances(employmentID: string, error: Error): StartBalanceAction {
  return {
    type: ActionTypes.START_BALANCES_UPDATE_FAILED,
    error,
    employmentID,
  }
}

export function getStartBalances(employmentID: string) {
  return (dispatch: React.Dispatch<any>): Promise<StartBalances<number> | void> => {
    dispatch(loadingStartBalances(employmentID))
    return fetchStartBalances(employmentID)
      .then((res) => {
        dispatch(loadedStartBalances(employmentID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          if (e.message === 'NotFound') {
            dispatch(loadedStartBalances(employmentID, null))
          } else {
            dispatch(failedLoadingStartBalances(employmentID, e))
          }
        }
      })
  }
}

export function updateStartBalances(employmentID: string, startBalances: StartBalances<number>) {
  return (dispatch: React.Dispatch<any>): Promise<StartBalances<number> | void> => {
    dispatch(updatingStartBalances(employmentID))
    return putStartBalances(employmentID, startBalances)
      .then((res) => {
        dispatch(updatedStartBalances(employmentID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingStartBalances(employmentID, e))
        }
      })
  }
}
