import React from 'react'

import {
  delContract,
  deleteRemoveLeaveForContract,
  fetchContracts,
  fetchEmployeeContractDeltas,
  getContract,
  postContract,
  postCreateLeaveForEmployee,
  putContract,
} from '../api/contracts'
import ActionTypes from '../constants/action-types'
import Contract, { ContractCreationFields, ContractMutableFields } from '../model/contract'
import ContractDelta from '../model/contractDelta'
import Remuneration from '../model/remuneration'
import { DateFormat } from '../model/types'
import { ContractAction } from '../reducers/contracts'
import { EmployeeContractDeltaAction } from '../reducers/employeeContractDeltas'
import { EmployeeAction } from '../reducers/employees'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'

function loadingContracts(): ContractAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_LOADING,
  }
}
function loadedContracts(companyID: string, contracts: Contract[], partial = false): ContractAction {
  return {
    type: partial ? ActionTypes.EMPLOYEE_CONTRACT_LOADED_PARTIAL : ActionTypes.EMPLOYEE_CONTRACT_LOADED,
    contracts,
    companyID,
  }
}
function failedLoadingContracts(companyID: string, error: Error): ContractAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingContract(): ContractAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_ADDING,
  }
}
export function addedContract(contract: Contract, setViewing: boolean): ContractAction & EmployeeContractDeltaAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_ADDED,
    contract,
    setViewing,
  }
}
function failedAddingContract(error: Error): ContractAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_ADD_FAILED,
    error,
  }
}

function updatingContract(contractID: string): ContractAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_UPDATING,
    contractID,
  }
}
export function updatedContract(
  contractID: string,
  contract: Contract,
  setViewing: boolean
): ContractAction & EmployeeContractDeltaAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_UPDATED,
    contractID,
    contract,
    setViewing,
  }
}
function failedUpdatingContract(contractID: string, error: Error): ContractAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_UPDATE_FAILED,
    error,
    contractID,
  }
}

function deletingContract(contractID: string): ContractAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_DELETING,
    contractID,
  }
}
function deletedContract(contractID: string): ContractAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_DELETED,
    contractID,
  }
}
function failedDeletingContract(contractID: string, error: Error): ContractAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_DELETE_FAILED,
    error,
    contractID,
  }
}

function loadingContractDeltas(employeeID: string): EmployeeContractDeltaAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_DELTA_LOADING,
    employeeID,
  }
}
function loadedContractDeltas(employeeID: string, contractDeltas: ContractDelta[]): EmployeeContractDeltaAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_DELTA_LOADED,
    employeeID,
    contractDeltas,
  }
}
function failedLoadingContractDeltas(employeeID: string, error: Error): EmployeeContractDeltaAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_DELTA_LOAD_FAILED,
    error,
    employeeID,
  }
}
function setViewingContract(employeeID: string, contractID: string): EmployeeContractDeltaAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_DELTA_SET_VIEWING_CONTRACT,
    employeeID,
    contractID,
  }
}
function setContractDeltasDirty(employeeID: string): EmployeeContractDeltaAction {
  return {
    type: ActionTypes.EMPLOYEE_CONTRACT_DELTA_SET_DIRTY,
    employeeID,
  }
}

function loadingRemuneration(contractID: string): ContractAction {
  return {
    type: ActionTypes.REMUNERATION_LOADING,
    contractID,
  }
}
function loadedRemuneration(
  contractID: string,
  employeeID: string,
  remuneration: Remuneration
): ContractAction & EmployeeAction {
  return {
    type: ActionTypes.REMUNERATION_LOADED,
    contractID,
    employeeID,
    remuneration,
  }
}
function failedLoadingRemuneration(contractID: string, error: Error): ContractAction {
  return {
    type: ActionTypes.REMUNERATION_LOAD_FAILED,
    error,
    contractID,
  }
}

export function getContracts(offset?: number) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Contract[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    if (!offset) {
      dispatch(loadingContracts())
      offset = 0
    }

    const limit = 500
    return fetchContracts(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedContracts(companyID, data)),
          (data) => dispatch(loadedContracts(companyID, data, true)),
          (offset) => dispatch(getContracts(offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingContracts(companyID, e))
        }
      })
  }
}

export function addContract(contract: ContractCreationFields, setViewing = true) {
  return (dispatch: React.Dispatch<any>): Promise<Contract | void> => {
    dispatch(addingContract())
    return postContract(contract.employmentID, contract)
      .then((res) => {
        dispatch(addedContract(res.data, setViewing))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingContract(e))
        }
      })
  }
}

export function updateContract(contract: ContractMutableFields, setViewing = true, removeOrphans = false) {
  return (dispatch: React.Dispatch<any>): Promise<Contract | void> => {
    if (!contract.id) {
      return PromiseVoid
    }
    dispatch(updatingContract(contract.id))
    return putContract(contract, removeOrphans)
      .then((res) => {
        dispatch(updatedContract(res.data.id, res.data, setViewing))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingContract(contract.id!, e))
        }
      })
  }
}

export function createLeave(employeeID: string, leaveFrom: DateFormat, leaveTo: DateFormat) {
  return (dispatch: React.Dispatch<any>): Promise<Contract[] | void> => {
    dispatch(updatingContract(employeeID))
    return postCreateLeaveForEmployee(employeeID, leaveFrom, leaveTo)
      .then((res) => {
        const list = []
        if (res.data.contractBefore) {
          dispatch(updatedContract(res.data.contractBefore.id, res.data.contractBefore, false))
          dispatch(setContractDeltasDirty(res.data.contractBefore.employeeID))
          list.push(res.data.contractBefore)
        }
        if (res.data.contractAfter) {
          dispatch(updatedContract(res.data.contractAfter.id, res.data.contractAfter, false))
          dispatch(setContractDeltasDirty(res.data.contractAfter.employeeID))
          list.push(res.data.contractAfter)
        }
        return list
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingContract(employeeID, e))
        }
      })
  }
}

export function removeLeave(contractID: string) {
  return (dispatch: React.Dispatch<any>): Promise<Contract | void> => {
    dispatch(updatingContract(contractID))
    return deleteRemoveLeaveForContract(contractID)
      .then((res) => {
        dispatch(updatedContract(res.data.id, res.data, false))
        dispatch(setContractDeltasDirty(res.data.employeeID))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingContract(contractID, e))
        }
      })
  }
}

export function deleteContract(contractID: string, employeeID: string, removeOrphans: boolean) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(deletingContract(contractID))
    return delContract(contractID, removeOrphans)
      .then(() => {
        dispatch(deletedContract(contractID))
        dispatch(setContractDeltasDirty(employeeID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingContract(contractID, e))
        }
      })
  }
}

export function getContractDeltas(employeeID: string) {
  return (dispatch: React.Dispatch<any>): Promise<ContractDelta[] | void> => {
    dispatch(loadingContractDeltas(employeeID))
    return fetchEmployeeContractDeltas(employeeID)
      .then((res) => {
        dispatch(loadedContractDeltas(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingContractDeltas(employeeID, e))
        }
      })
  }
}

export function setEmployeeContract(employeeID: string, contractID: string) {
  return (dispatch: React.Dispatch<any>): void => {
    dispatch(setViewingContract(employeeID, contractID))
  }
}

export function getRemuneration(contractID: string) {
  return (dispatch: React.Dispatch<any>): Promise<Remuneration | null | void> => {
    dispatch(loadingRemuneration(contractID))
    return getContract(contractID)
      .then((res) => {
        if (!res.data || !res.data.remuneration) {
          dispatch(failedLoadingRemuneration(contractID, new Error('Empty response')))
          return null
        }
        dispatch(loadedRemuneration(contractID, res.data.employeeID, res.data.remuneration))
        return res.data.remuneration
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingRemuneration(contractID, e))
        }
      })
  }
}
