import { List, Record as ImmutableRecord } from 'immutable'

import ActionTypes from '../constants/action-types'
import PricingPackage, { PricingPackageGroup, PricingPackageType } from '../model/pricingPackage'
import { ReducerAction } from '../utils/reducer-utils'

export interface PricingPackageReducer {
  loading: boolean
  loaded: boolean
  pricingPackages: List<PricingPackage>
  error: Error | null
}

const Params = ImmutableRecord<PricingPackageReducer>({
  loading: false,
  loaded: false,
  pricingPackages: List<PricingPackage>(),
  error: null,
})

export interface PricingPackageAction extends ReducerAction {
  pricingPackages?: PricingPackage[]
}

const groupOrder: Record<PricingPackageGroup, number> = {
  Gratis: 0,
  Automatic: 1,
  Premium: 2,
  Addon: 3,
}
const typeOrder: Record<PricingPackageType, number> = {
  Base: 0,
  Addon: 1,
}
const comparator = (a: PricingPackage, b: PricingPackage) => {
  const groupSort = groupOrder[a.group] - groupOrder[b.group]
  if (groupSort !== 0) {
    return groupSort
  }
  const typeSort = typeOrder[a.type] - typeOrder[b.type]
  if (typeSort !== 0) {
    return typeSort
  }
  return a.name.localeCompare(b.name, 'da-dk')
}

export default (
  state: ImmutableRecord<PricingPackageReducer> = Params(),
  action: PricingPackageAction = { type: '' }
): ImmutableRecord<PricingPackageReducer> => {
  switch (action.type) {
    case ActionTypes.PRICING_PACKAGES_LOADING:
      return Params({
        loading: true,
      })
    case ActionTypes.PRICING_PACKAGES_LOADED:
      return Params({
        loaded: true,
        pricingPackages: List(action.pricingPackages).sort(comparator),
      })
    case ActionTypes.PRICING_PACKAGES_LOAD_FAILED:
      return Params({
        error: action.error,
      })
    case ActionTypes.COMPANIES_SETTING_ACTIVE:
      // When we change the active company, be sure to remove all pricing packages, since we might
      // have some saved that others should not see.
      return Params()
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
