import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { CostCenterAccounting } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Employee from '../../../model/employee'
import { OneTimePayCreationFields, OneTimePayMutableFields } from '../../../model/oneTimePay'
import SalaryCycle from '../../../model/salaryCycle'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import ReimbursementEditForm, { ReimbursementResult } from './ReimbursementEditForm'

type Props = {
  visible: boolean
  editing: boolean
  canApproveObjects: boolean
  employee?: Employee
  company: Company
  oneTimePayID?: string
  oneTimePays: OneTimePayReducer
  salaryCycle?: SalaryCycle
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>

  addOneTimePay: (id: string, oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (id: string, oneTimePay: OneTimePayMutableFields) => void
}

export default function ReimbursementEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const visible = props.visible
  const oneTimePayError = props.oneTimePays.error

  useEffect(() => {
    visibleComponentDidUpdate(visible, oneTimePayError, error, setError)
  }, [visible, oneTimePayError, error])

  const handleSubmit = (values: ReimbursementResult) => {
    if (!props.editing || !props.employee) {
      return
    }
    const oneTimePay: OneTimePayCreationFields = {
      employeeID: props.employee.id,
      type: 'Reimbursement',
      dispositionDate: values.dispositionDate!,
      amount: values.amount,
      rate: values.rate,
      units: values.units,
      title: values.title!,
      costCenterID: values.costCenterID,
      accountingText: values.accountingText,
      approved: values.approved,
    }
    if (props.oneTimePayID) {
      props.updateOneTimePay(props.employee.id, { ...oneTimePay, id: props.oneTimePayID })
    } else {
      props.addOneTimePay(props.employee.id, oneTimePay)
    }
  }

  if (!props.employee || !props.salaryCycle) {
    return null
  }
  return (
    <Card className="reimbursement-modal">
      <Subtitle>
        {props.oneTimePayID
          ? props.editing
            ? t('reimbursements.edit.title.edit')
            : t('reimbursements.edit.title.view')
          : t('reimbursements.edit.title.create')}
      </Subtitle>
      <p>{t('reimbursements.edit.intro')}</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <ReimbursementEditForm
        editing={props.editing}
        canApproveObjects={props.canApproveObjects}
        employee={props.employee}
        company={props.company}
        oneTimePayID={props.oneTimePayID}
        oneTimePays={props.oneTimePays}
        salaryCycle={props.salaryCycle}
        costCenterAccounting={props.costCenterAccounting}
        costCenters={props.costCenters}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
