import { RequestResponse } from '../utils/request-utils'

export function handlePagination<T>(
  res: RequestResponse<T[]>,
  limit: number,
  offset: number | undefined,
  callLoaded: (data: T[]) => void,
  callPartialLoaded: (data: T[]) => void,
  fetchCall: (offset: number) => void,
  toContinue?: (data: T[]) => boolean
): T[] {
  offset = offset ?? 0
  if (res.pagination && res.pagination.count > offset + limit) {
    let shouldContinue = true
    if (toContinue) {
      shouldContinue = toContinue(res.data)
    }
    if (shouldContinue) {
      callPartialLoaded(res.data)
      fetchCall(offset + limit)
    } else {
      // pretend we are done
      callLoaded(res.data)
    }
    return res.data
  } else {
    callLoaded(res.data)
    return res.data
  }
}
