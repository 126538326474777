import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Modal from '../model/modal'
import { ReducerAction } from '../utils/reducer-utils'

export interface ModalReducer {
  nextID: number
  modals: List<Modal>
}

const Params = Record<ModalReducer>({
  nextID: 1,
  modals: List<Modal>(),
})

export interface ModalAction extends ReducerAction {
  modal?: Modal
  modalID?: number
}

export default (state: Record<ModalReducer> = Params(), action: ModalAction = { type: '' }): Record<ModalReducer> => {
  switch (action.type) {
    case ActionTypes.MODAL_ADDED:
      if (!action.modal) {
        return state
      }
      return state
        .set('nextID', state.get('nextID') + 1)
        .set('modals', state.get('modals').unshift({ ...{ id: state.get('nextID') }, ...action.modal }))
    case ActionTypes.MODAL_REMOVED:
      if (action.modalID) {
        const idx = state.get('modals').findIndex((item) => item.id === action.modalID)
        if (idx !== -1) {
          return state.set('modals', state.get('modals').delete(idx))
        }
        return state
      }
      return state.set('modals', state.get('modals').pop())
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
