import React, { ReactElement, useEffect } from 'react'

import { getAccountingDimensions, selectAccountingDimensions } from '../actions/accounting-dimensions'
import {
  getCompanyAccountingIntegration,
  setCompanyAccountingIntegrationAccounting,
} from '../actions/accounting-integration'
import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { disableCompanySettings, enableCompanySettings } from '../actions/companies'
import AccountingDimensionComponent from '../components/accounting/AccountingDimensions'
import Alert from '../components/elements/alert'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { AccountingDimensionCreation, AccountingDimensionMutable } from '../model/accountingDimension'
import AccountingIntegration, { CostCenterAccounting } from '../model/accountingIntegration'
import CompanySetting from '../model/companySetting'
import { AccountingDimensionReducer } from '../reducers/accountingDimensions'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { CompanyAccountingIntegrationReducer } from '../reducers/companyAccountingIntegration'
import { CostCenterReducer } from '../reducers/costCenters'
import { formatError } from '../utils/error-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import IntegrationsLayout from './layouts/IntegrationsLayout'

type Reducers = {
  alerts: AlertReducer
  accountingDimensions: AccountingDimensionReducer
  companies: CompanyReducer
  companyAccountingIntegration: CompanyAccountingIntegrationReducer
  costCenters: CostCenterReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getCompanyAccountingIntegration: () => void
  getAccountingDimensions: () => void
  selectAccountingDimensions: (dimensions: (AccountingDimensionMutable | AccountingDimensionCreation)[]) => void
  setCompanyAccountingIntegrationAccounting: (
    companyID: string,
    costCenterAccounting: CostCenterAccounting
  ) => Promise<AccountingIntegration | void>
  enableCompanySettings: (companyID: string, settings: CompanySetting[]) => void
  disableCompanySettings: (companyID: string, settings: CompanySetting[]) => void
}

function AccountingDimensions(props: Reducers & Actions & RouteProps): ReactElement | null {
  const {
    accountingDimensions,
    getAccountingDimensions,
    companyAccountingIntegration,
    getCompanyAccountingIntegration,
  } = props
  useEffect(() => {
    if (!accountingDimensions.loaded && !accountingDimensions.loading && !accountingDimensions.error) {
      getAccountingDimensions()
    }
    if (!companyAccountingIntegration.loaded && !companyAccountingIntegration.loading) {
      getCompanyAccountingIntegration()
    }
  })

  const company = props.companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  const loading = !companyAccountingIntegration.loaded || (!accountingDimensions.error && !accountingDimensions.loaded)

  if (loading) {
    return <LoadingOverlay />
  }

  return (
    <IntegrationsLayout location={props.location}>
      {accountingDimensions.error && <Alert message={formatError(accountingDimensions.error)} type="error" showIcon />}
      <AccountingDimensionComponent
        alerts={props.alerts}
        accountingDimensions={props.accountingDimensions}
        company={company}
        companies={props.companies}
        companyAccountingIntegration={props.companyAccountingIntegration}
        costCenters={props.costCenters.costCenters}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        selectAccountingDimensions={props.selectAccountingDimensions}
        setCompanyAccountingIntegrationAccounting={props.setCompanyAccountingIntegrationAccounting}
        enableCompanySettings={props.enableCompanySettings}
        disableCompanySettings={props.disableCompanySettings}
      />
    </IntegrationsLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    accountingDimensions: state.accountingDimensions,
    companies: state.companies,
    companyAccountingIntegration: state.companyAccountingIntegration,
    costCenters: state.costCenters,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    selectAccountingDimensions: selectAccountingDimensions,
    setCompanyAccountingIntegrationAccounting: setCompanyAccountingIntegrationAccounting,
    enableCompanySettings: enableCompanySettings,
    disableCompanySettings: disableCompanySettings,
    getCompanyAccountingIntegration: getCompanyAccountingIntegration,
    getAccountingDimensions: getAccountingDimensions,
  }
)(AccountingDimensions)
