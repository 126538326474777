import ExpenseCategory, { ExpenseCategoryBaseFields, ExpenseCategoryUpdateFields } from '../model/expenseCategory'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchExpenseCategories(companyID: string): Promise<RequestResponse<ExpenseCategory[]>> {
  return secureRequest('GET', url('/v2/expenseCategories', { companyID }))
}

export function postExpenseCategory(
  companyID: string,
  expenseCategory: ExpenseCategoryBaseFields
): Promise<RequestResponse<ExpenseCategory>> {
  return secureRequest('POST', url('/v2/expenseCategories', { companyID }), {
    body: {
      name: expenseCategory.name,
      accountingAccountID: expenseCategory.accountingAccountID,
      order: expenseCategory.order,
    },
  })
}

export function putExpenseCategory(
  expenseCategory: ExpenseCategoryUpdateFields
): Promise<RequestResponse<ExpenseCategory>> {
  return secureRequest('PUT', url('/v2/expenseCategories/' + expenseCategory.id), {
    body: {
      name: expenseCategory.name,
      accountingAccountID: expenseCategory.accountingAccountID,
      order: expenseCategory.order,
    },
  })
}

export function delExpenseCategory(expenseCategoryID: string): Promise<RequestResponse<ExpenseCategory | void>> {
  return secureRequest('DELETE', url('/v2/expenseCategories/' + expenseCategoryID))
}
