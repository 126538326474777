import React, { ReactElement } from 'react'

import Company from '../../../model/company'
import { decorateFieldSignature } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Switch from '../../elements/switch'

type OneTimePayApprovedFields = {
  approved: boolean
}

type Props<Fields extends OneTimePayApprovedFields> = {
  editing: boolean
  company: Company
  canApproveObjects: boolean

  decorateField: decorateFieldSignature<Fields>
}

export default function OneTimePayApproved<Fields extends OneTimePayApprovedFields>(
  props: Props<Fields>
): ReactElement | null {
  const askForDraft =
    props.company.settingsEnabled.some((setting) => setting === 'AskForOTPDraftState') && props.canApproveObjects

  if (!askForDraft) {
    return null
  }

  return (
    <Row>
      <Col span={12}>
        {props.decorateField('approved', {
          placeholder: t('otp_form.approved.approved'),
          valueOnChecked: true,
          noBlur: true,
        })(
          <Switch
            disabled={!props.editing}
            checkedChildren={t('otp_form.approved.checked')}
            unCheckedChildren={t('otp_form.approved.unchecked')}
          />
        )}
      </Col>
    </Row>
  )
}
