import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import UserInvite from '../model/userInvite'
import { ReducerAction } from '../utils/reducer-utils'

export interface UserInviteCompanyGroupReducer {
  companyGroupID: string | null
  loading: boolean
  loaded: boolean
  saving: boolean
  userInvites: List<UserInvite>
  error: Error | null
}

const Params = Record<UserInviteCompanyGroupReducer>({
  companyGroupID: null,
  loading: false,
  loaded: false,
  saving: false,
  userInvites: List<UserInvite>(),
  error: null,
})

export interface UserInviteCompanyGroupAction extends ReducerAction {
  companyGroupID?: string
  userInvites?: UserInvite[]
  userInvite?: UserInvite
  email?: string
}

export default (
  state: Record<UserInviteCompanyGroupReducer> = Params(),
  action: UserInviteCompanyGroupAction = { type: '' }
): Record<UserInviteCompanyGroupReducer> => {
  // keep track of active group
  if (action.type === ActionTypes.COMPANY_GROUPS_SET_ACTIVE || action.type === ActionTypes.COMPANY_GROUPS_LOADED) {
    return Params({
      companyGroupID: action.companyGroupID,
    })
  }
  // only process actions for the active company group
  if (action.companyGroupID && state.get('companyGroupID') && action.companyGroupID !== state.get('companyGroupID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.USER_COMPANY_GROUP_INVITES_LOADING:
      return Params({
        companyGroupID: state.get('companyGroupID'),
        loading: true,
      })
    case ActionTypes.USER_COMPANY_GROUP_INVITES_LOADED:
      return Params({
        companyGroupID: state.get('companyGroupID'),
        loaded: true,
        userInvites: List<UserInvite>(action.userInvites),
      })
    case ActionTypes.USER_COMPANY_GROUP_INVITES_LOAD_FAILED:
      return Params({
        companyGroupID: state.get('companyGroupID'),
        error: action.error,
      })

    case ActionTypes.USER_COMPANY_GROUP_INVITE_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.USER_COMPANY_GROUP_INVITE_ADDED:
      if (!action.userInvite) {
        return state
      }
      idx = state.get('userInvites').findIndex((item) => !!action.userInvite && item.email === action.userInvite.email)
      if (idx !== -1) {
        return state.set('saving', false).set('userInvites', state.get('userInvites').set(idx, action.userInvite))
      }
      return state.set('saving', false).set('userInvites', state.get('userInvites').push(action.userInvite))
    case ActionTypes.USER_COMPANY_GROUP_INVITE_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_COMPANY_GROUP_INVITE_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.USER_COMPANY_GROUP_INVITE_DELETED:
      idx = state.get('userInvites').findIndex((item) => item.email === action.email)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('userInvites', state.get('userInvites').delete(idx))
    case ActionTypes.USER_COMPANY_GROUP_INVITE_DELETE_FAILED:
      idx = state.get('userInvites').findIndex((item) => item.email === action.email)
      if (idx === -1) {
        return state.set('saving', false).set('error', action.error || null)
      }
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
