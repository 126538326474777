import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { AccountingDimensionMutable } from '../../../model/accountingDimension'
import AccountingIntegration from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import { AccountingDimensionReducer } from '../../../reducers/accountingDimensions'
import { t } from '../../../utils/translation-utils'
import Subcard from '../../elements/Subcard'
import Subtitle from '../../elements/Subtitle'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import DimensionsForm, { AccountingDimensionResult } from './DimensionsForm'

type Props = {
  accountingIntegration: AccountingIntegration
  accountingDimensions: AccountingDimensionReducer
  company: Company
  costCenters: List<CostCenter>

  selectAccountingDimensions: (dimensions: AccountingDimensionMutable[]) => void
}

export default function DimensionsCard(props: Props): ReactElement | null {
  const handleDimensionSubmit = (values: AccountingDimensionResult) => {
    props.selectAccountingDimensions(values.dimensions)
  }

  return (
    <Subcard>
      <Subtitle>{t('dimensions_tab.card.title')}</Subtitle>
      {!props.accountingDimensions.saving && (
        <DimensionsForm
          accountingIntegration={props.accountingIntegration}
          accountingDimensions={props.accountingDimensions}
          costCenterAccounting={props.company.costCenterAccounting}
          costCenters={props.costCenters}
          onSubmit={handleDimensionSubmit}
        />
      )}
      {props.accountingDimensions.saving && <LoadingOverlay />}
    </Subcard>
  )
}
