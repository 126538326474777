import React, { ReactElement, ReactNode } from 'react'

import Employee from '../../model/employee'
import { IncomeTaxReportEmployee } from '../../model/incomeTaxReport'
import { formatDate } from '../../utils/date-utils'
import { formatNationalIDLabel } from '../../utils/format-utils'
import { addLeadingZeros, formatCurrency, formatNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import { normalizeNationalID } from '../../utils/validation-utils'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import Title from '../elements/Title'
import Tooltip from '../elements/tooltip'

type Props = {
  visible: boolean
  employeeNumber?: string
  taxReport?: IncomeTaxReportEmployee
  employee?: Employee
}

export default function TaxReportModal(props: Props): ReactElement | null {
  if (!props.employeeNumber || !props.taxReport || !props.employee) {
    return null
  }

  const showFieldNumberToolTip = (fieldNumber: string): ReactNode => {
    return (
      <Tooltip title={t(['tax_report_field_number', addLeadingZeros(fieldNumber, 4)])}>
        <span className="field-number-tool-tip">{fieldNumber}</span>
      </Tooltip>
    )
  }

  const { taxReport, employee } = props
  return (
    <Card className="pay-roll-tax-report">
      <Title>{t('pay_roll.single.tax_report_modal.title')}</Title>
      <Row>
        <Col span={8}>
          {t('pay_roll.single.tax_report_modal.name')}:
          <br />
          <strong>{employee ? employee.name : props.employeeNumber}</strong>
        </Col>
        <Col span={8}>
          {formatNationalIDLabel(employee ? employee.nationalIDType : 'DK CPR')}:
          <br />
          <strong>
            {normalizeNationalID(
              employee?.nationalID ? employee.nationalID : taxReport.cpr || taxReport.cvr || '',
              employee?.nationalIDType || 'DK CPR'
            ) || '-'}
          </strong>
        </Col>
        {(!employee || employee.nationalIDType === 'DK CPR') && (
          <Col span={8}>
            {t('pay_roll.single.tax_report_modal.employee_number')}:
            <br />
            <strong>{taxReport.employeeNumber}</strong>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={16}>
          {t('pay_roll.single.tax_report_modal.period')}:
          <br />
          <strong>
            {formatDate(taxReport.periodStart)} - {formatDate(taxReport.periodEnd)} (
            {taxReport.paidAhead
              ? t('pay_roll.single.tax_report_modal.period.pre_paid')
              : t('pay_roll.single.tax_report_modal.period.post_paid')}
            )
          </strong>
        </Col>
        <Col span={8}>
          {t('pay_roll.single.tax_report_modal.disposition_date')}:
          <br />
          <strong>{formatDate(taxReport.dispositionDate)}</strong>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          {t('pay_roll.single.tax_report_modal.income_type')}:
          <br />
          <strong>{taxReport.incomeType}</strong>
        </Col>
        <Col span={16}>
          {t('pay_roll.single.tax_report_modal.production_unit')}:
          <br />
          <strong>{taxReport.productionNumber}</strong>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Headline>{t('pay_roll.single.tax_report_modal.income_details')}</Headline>
        </Col>
      </Row>
      {taxReport.salaryLines.map((_, i) => {
        if (i % 3 !== 0) {
          return null
        }
        return (
          <Row key={'row-' + i}>
            {taxReport.salaryLines.map((line, j) => {
              if (j < i || j > i + 2) {
                return null
              }
              let val = line.fieldValue
              switch (line.type) {
                case 'Amount':
                  val = formatCurrency(val as number, 2)
                  break
                case 'Count':
                  val = formatNumber(val as number, 2)
                  break
                case 'Boolean':
                  val = val
                    ? t('pay_roll.single.tax_report_modal.boolean.true')
                    : t('pay_roll.single.tax_report_modal.boolean.false')
                  break
                default:
                  break
              }
              return (
                <Col key={'col-' + j} span={8}>
                  {showFieldNumberToolTip(line.fieldNumber)}
                  {line.fieldCategory !== '0000' && <span> ({line.fieldCategory})</span>}:<br />
                  <strong>{val}</strong>
                </Col>
              )
            })}
          </Row>
        )
      })}
      {taxReport.terminations.length > 0 && (
        <Row>
          <Col span={24}>
            <Headline>{t('pay_roll.single.tax_report_modal.termination_title')}</Headline>
          </Col>
        </Row>
      )}
      {taxReport.terminations.length > 0 && (
        <Row>
          <Col span={24}>
            {t('pay_roll.single.tax_report_modal.termination_date')}:
            <br />
            <strong>{formatDate(taxReport.terminations[0].terminationDate)}</strong>
          </Col>
        </Row>
      )}
      {taxReport.terminations.map((termination) => {
        return (
          <Row key={termination.year}>
            <Col span={8}>
              {t('pay_roll.single.tax_report_modal.termination_year')}:
              <br />
              <strong>{termination.year}</strong>
            </Col>
            <Col span={8}>
              {t('pay_roll.single.tax_report_modal.termination_vacation_net')}:
              <br />
              <strong>{formatCurrency(termination.vacationNet, 2)}</strong>
            </Col>
            <Col span={8}>
              {t('pay_roll.single.tax_report_modal.termination_vacation_days')}:
              <br />
              <strong>{formatNumber(termination.vacationDays, 2)}</strong>
            </Col>
          </Row>
        )
      })}
    </Card>
  )
}
