import React, { ReactElement } from 'react'

import { CostCenterAccounting } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import { CompanyReducer } from '../../../reducers/companies'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Radio, { Group as RadioGroup } from '../../antd/radio'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Switch from '../../elements/switch'
import FeatureLock from '../../widgets/FeatureLock'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  company: Company
  companies: CompanyReducer
  hasDimensions: boolean
}

export type AccountingDepartmentFields = {
  costCenterAccounting: CostCenterAccounting
  timeRegistrationDepartment: boolean
}

function DepartmentsForm(
  props: Props & FormComponentProps<AccountingDepartmentFields, AccountingDepartmentFields>
): ReactElement | null {
  const { decorateField, getFieldValue } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          <FeatureLock
            featureType={'Accounting Integration'}
            description={t('dimensions_tab.departments.form.cost_center_accounting.lock')}
          >
            {decorateField('costCenterAccounting', {
              title: t('dimensions_tab.departments.form.cost_center_accounting'),
              skipWrapper: true,
              noBlur: true,
            })(
              <RadioGroup>
                <Radio value={'Off'}>{t('dimensions_tab.departments.form.cost_center_accounting.off')}</Radio>
                <Radio value={'Departments'}>
                  {props.hasDimensions
                    ? t('dimensions_tab.departments.form.cost_center_accounting.departments.has_dimensions')
                    : t('dimensions_tab.departments.form.cost_center_accounting.departments')}
                </Radio>
                {props.hasDimensions && (
                  <Radio value={'CostCenters'}>
                    {t('dimensions_tab.departments.form.cost_center_accounting.cost_centers')}
                  </Radio>
                )}
              </RadioGroup>
            )}
          </FeatureLock>
        </Col>
      </Row>
      {getFieldValue('costCenterAccounting') !== 'Off' && (
        <Row>
          <Col span={12}>
            {decorateField('timeRegistrationDepartment', {
              placeholder:
                getFieldValue('costCenterAccounting') === 'Departments'
                  ? t('dimensions_tab.departments.form.time_registration_department.departments')
                  : t('dimensions_tab.departments.form.time_registration_department.cost_centers'),
              valueOnChecked: true,
            })(<Switch />)}
            {getFieldValue('costCenterAccounting') === 'Departments' && (
              <p>{t('dimensions_tab.departments.form.time_registration_department.note.departments')}</p>
            )}
            {getFieldValue('costCenterAccounting') === 'CostCenters' && (
              <p>{t('dimensions_tab.departments.form.time_registration_department.note.cost_centers')}</p>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.companies.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, AccountingDepartmentFields, AccountingDepartmentFields>({
  mapPropsToFields: (props) => ({
    costCenterAccounting: props.company.costCenterAccounting,
    timeRegistrationDepartment: props.company.settingsEnabled.some(
      (setting) => setting === 'DepartmentSelectTimeRegistration'
    ),
  }),
  onSubmit: (values) => values,
})(DepartmentsForm)
