import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import { ReducerAction } from '../utils/reducer-utils'

export interface EmployeeProfileImageReducer {
  employeeID: string | null
  saving: boolean
  error: Error | null
}

const Params = Record<EmployeeProfileImageReducer>({
  employeeID: null,
  saving: false,
  error: null,
})

export interface EmployeeProfileImageAction extends ReducerAction {
  employeeID?: string
}

export default (
  state: Record<EmployeeProfileImageReducer> = Params(),
  action: ReducerAction = { type: '' }
): Record<EmployeeProfileImageReducer> => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_PROFILE_IMAGE_UPDATING:
    case ActionTypes.EMPLOYEE_PROFILE_IMAGE_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EMPLOYEE_PROFILE_IMAGE_UPDATED:
    case ActionTypes.EMPLOYEE_PROFILE_IMAGE_DELETED:
      return state.set('saving', false).set('error', null)
    case ActionTypes.EMPLOYEE_PROFILE_IMAGE_UPDATE_FAILED:
    case ActionTypes.EMPLOYEE_PROFILE_IMAGE_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
