import React, { ReactElement, useState } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import { patchMfa, putMfa } from '../../api/mfa'
import MfaChannel from '../../types/mfa-channel'
import { formatError, isRequestError } from '../../utils/error-utils'
import { formatMfaChannel } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Steps from '../elements/steps'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  visible: boolean

  closeModal: () => void
  addAlert: addAlertSignature
}

export default function MfaEditModal(props: Props): ReactElement | null {
  type State = {
    step: number
    channel?: MfaChannel
    challengeID?: string
    key?: string
    qrImage?: string
    response?: string
  }
  const [state, setState] = useState<State>({
    step: 0,
  })
  type WrapperState = {
    saving: boolean
    error: Error | null
  }
  const [wrapperState, setWrapperState] = useState<WrapperState>({
    saving: false,
    error: null,
  })

  const handleNext = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!state.channel) {
      setWrapperState((prev) => ({ ...prev, error: new Error(t('account.error.mfa_select_method')) }))
      return
    }

    setWrapperState({ error: null, saving: true })
    putMfa(state.channel)
      .then((res) => {
        setState((prev) => ({
          ...prev,
          step: 1,
          challengeID: res.data.challengeID,
          key: res.data.key,
          qrImage: res.data.qrImage,
        }))
        setWrapperState({ error: null, saving: false })
      })
      .catch((e) => {
        if (isRequestError(e)) {
          setWrapperState({ error: e, saving: false })
        }
      })
  }

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!state.challengeID || !state.response) {
      setWrapperState((prev) => ({ ...prev, error: new Error(t('account.error.mfa_input_code')) }))
      return
    }

    setWrapperState({ error: null, saving: true })
    patchMfa(state.challengeID, state.response)
      .then(() => {
        props.addAlert('success', t('account.message.mfa_was_saved'), { timeout: 5 })
        props.closeModal()
      })
      .catch((e) => {
        if (isRequestError(e)) {
          setWrapperState({ error: e, saving: false })
        }
      })
  }

  return (
    <Card>
      <Title>{t('account.mfa.setup')}</Title>
      <Steps current={state.step}>
        <Steps.Step title={t('account.mfa.select_method')} />
        <Steps.Step title={t('account.mfa.confirm')} />
      </Steps>
      <p>&nbsp;</p>
      {wrapperState.error && <Alert message={formatError(wrapperState.error)} type="error" showIcon />}
      {state.step === 0 && (
        <div>
          <Row>
            <Col span={24}>
              <p>
                {t('account.mfa.message.description', {
                  sms: formatMfaChannel(MfaChannel.SMS),
                  totp: formatMfaChannel(MfaChannel.TOTP),
                })}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label>{t('account.mfa.method')}</label>
              <Select
                placeholder={t('account.mfa.select_method')}
                onChange={(val: MfaChannel) => setState((prev) => ({ ...prev, channel: val }))}
              >
                <Select.Option value={MfaChannel.TOTP}>{formatMfaChannel(MfaChannel.TOTP)}</Select.Option>
                <Select.Option value={MfaChannel.SMS}>{formatMfaChannel(MfaChannel.SMS)}</Select.Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col span={24}>&nbsp;</Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button size="large" type="secondary" block onClick={handleNext}>
                {t('account.mfa.continue_to_confirmation')}
              </Button>
            </Col>
          </Row>
        </div>
      )}
      {state.step === 1 && (
        <div>
          {state.channel === MfaChannel.SMS && (
            <Row>
              <Col span={24}>
                <p>{t('account.mfa.message.sms_confirmation')}</p>
              </Col>
            </Row>
          )}
          {state.channel === MfaChannel.TOTP && (
            <div>
              <Row>
                <Col span={24}>
                  <p>{t('account.mfa.message.totp_confirmation')}</p>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <img src={`data:image/png;base64, ${state.qrImage}`} alt="" />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                  {state.key}
                </Col>
              </Row>
              <Row>
                <Col span={24}>&nbsp;</Col>
              </Row>
            </div>
          )}
          <Row>
            <Col span={24}>
              <label>{t('account.mfa.code')}:</label>
              <Input
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  // take the value, so it won't disappear when we call the setState function
                  const code = e.currentTarget.value
                  setState((prev) => ({ ...prev, response: code }))
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>&nbsp;</Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button size="large" type="secondary" block onClick={handleSubmit}>
                {t('form.button.save_setup')}
              </Button>
            </Col>
          </Row>
        </div>
      )}
      {wrapperState.saving && <LoadingOverlay />}
    </Card>
  )
}
