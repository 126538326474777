import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import { AccessToken } from '../../model/user'
import { UserReducer } from '../../reducers/user'
import { formatError, isMFAChannelError } from '../../utils/error-utils'
import { ChannelMFAError, RequestError } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import { Col, Row } from '../elements/grid'
import Input from '../elements/input'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  userID: string
  justLoggedOut: boolean
  user: UserReducer

  loginUserID: (
    userID: string,
    mfaChallengeID?: string,
    mfaResponse?: string
  ) => Promise<AccessToken | RequestError | ChannelMFAError>
}

export default function PayrollApproveLogin(props: Props): ReactElement | null {
  const [mfaChallengeID, setMFAChallengeID] = useState<string | undefined>(undefined)
  const [mfaResponse, setMFAResponse] = useState<string | undefined>(undefined)
  const [step, setStep] = useState(0)
  const [error, setError] = useState<RequestError | null>(null)

  const { loginUserID, userID, justLoggedOut } = props

  const initiateLogin = useCallback(() => {
    loginUserID(userID)
      .then((res) => {
        if (res && isMFAChannelError(res)) {
          const error = res as ChannelMFAError
          setStep(2)
          setMFAChallengeID(error.mfaChallengeID)
          return
        }
        if (res && res instanceof RequestError) {
          setError(res as RequestError)
        }
      })
      .catch((e) => {
        if (isMFAChannelError(e)) {
          const error = e as ChannelMFAError
          setStep(2)
          setMFAChallengeID(error.mfaChallengeID)
        } else {
          setError(e)
        }
      })
  }, [loginUserID, userID])

  useEffect(() => {
    if (step === 0) {
      setStep(1)
      if (!mfaChallengeID && !justLoggedOut) {
        initiateLogin()
      }
    }
  }, [step, setStep, mfaChallengeID, loginUserID, userID, setMFAChallengeID, justLoggedOut, initiateLogin])

  const loginAgain = () => {
    initiateLogin()
  }

  const login = () => {
    props
      .loginUserID(props.userID, mfaChallengeID, mfaResponse)
      .then((res) => {
        if (res && isMFAChannelError(res)) {
          setError(res as RequestError)
          return
        }
        if (res && res instanceof RequestError) {
          setError(res as RequestError)
          return
        }
        setError(null)
      })
      .catch((e) => {
        setError(e)
      })
  }

  if (!mfaChallengeID) {
    if (error) {
      return <Alert message={formatError(error)} type="error" showIcon />
    }
    if (justLoggedOut) {
      return (
        <Card className="login-form logout-form">
          <Button onClick={loginAgain}>{t('payroll_approve.login.login_again')}</Button>
        </Card>
      )
    }
    return <LoadingOverlay />
  }

  if (props.user.loggingIn) {
    return <LoadingOverlay />
  }

  return (
    <Card className="login-form">
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Row>
        <Col span={24}>
          <label>{t('payroll_approve.login.login_with_sms_code')}:</label>
          <Input
            placeholder="SMS-kode"
            inputMode="numeric"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMFAResponse(e.currentTarget.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button onClick={login}>{t('payroll_approve.login.submit')}</Button>
        </Col>
      </Row>
    </Card>
  )
}
