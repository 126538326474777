import React, { ReactElement } from 'react'

import AccountingActiveInformationComponent from '../components/accounting/AccountingActiveInformation'
import { CompanyReducer } from '../reducers/companies'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import IntegrationsLayout from './layouts/IntegrationsLayout'

type Reducers = {
  companies: CompanyReducer
}

function AccountingActiveInformation(props: Reducers & RouteProps): ReactElement | null {
  const company = props.companies.company
  if (!company) {
    return null
  }

  return (
    <IntegrationsLayout location={props.location}>
      <AccountingActiveInformationComponent company={company} />
    </IntegrationsLayout>
  )
}

export default connectToReducer<Reducers, any, RouteProps>(
  (state) => ({
    companies: state.companies,
  }),
  {}
)(AccountingActiveInformation)
