import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export type LoanEstimate = {
  id: string
  currency: string
  interestRate: number
  loanDurations: number[]
  maximumAmount: number
  minimumAmount: number
  amountIncrements: number
}

export function fetchLoanEstimate(companyID: string): Promise<RequestResponse<LoanEstimate>> {
  return secureRequest('GET', url('/v2/loan', { companyID }))
}

type LoanLeadFields = {
  loanEstimateID: string
  companyID: string
  loanAmount: number
  loanDuration: number
}

type LoanLead = {
  redirectURL: string
}

export function postLoanLead(fields: LoanLeadFields): Promise<RequestResponse<LoanLead>> {
  return secureRequest('POST', url('/v2/loan'), { body: fields })
}
