import Document, {
  DocumentCreationFields,
  DocumentMutableFields,
  DocumentSignatureType,
  DocumentSignState,
} from '../model/document'
import Language from '../types/language'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchDocuments(companyID: string): Promise<RequestResponse<Document[]>> {
  return secureRequest('GET', url('/v2/documents', { companyID }))
}

export function postDocument(document: DocumentCreationFields): Promise<RequestResponse<Document>> {
  return secureRequest('POST', url('/v2/documents'), {
    body: {
      companyID: document.companyID,
      employeeID: document.employeeID,
      fileID: document.fileID,
      documentCategoryID: document.documentCategoryID,
      name: document.name,
      visibleForEmployee: document.visibleForEmployee,
      signatureType: document.signatureType,
    },
  })
}

export function putDocument(document: DocumentMutableFields): Promise<RequestResponse<Document>> {
  return secureRequest('PUT', url('/v2/documents/' + document.id), {
    body: {
      employeeID: document.employeeID,
      documentCategoryID: document.documentCategoryID,
      name: document.name,
      visibleForEmployee: document.visibleForEmployee,
      archived: document.archived,
    },
  })
}

export function delDocument(id: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/documents/' + id))
}

export type SigningDocument = {
  companyNationalID: string
  companyName: string
  signerName: string
  signerEmail: string
  signerLanguage: Language
  signState: DocumentSignState
  signatureType: DocumentSignatureType
  signUrl?: string
}

export function getSigningDocument(id: string, signID: string, key: string): Promise<RequestResponse<SigningDocument>> {
  return secureRequest('GET', url('/v2/documents/' + id + '/sign', { signID, key }))
}

export function postSigningDocumentStart(
  id: string,
  signID: string,
  signKey: string,
  signerPhoneNumberCountryCode: string,
  signerPhoneNumber: string
): Promise<RequestResponse<Document>> {
  return secureRequest('POST', url('/v2/documents/' + id + '/sign'), {
    body: {
      action: 'StartSigning',
      signID,
      signKey,
      signerPhoneNumberCountryCode,
      signerPhoneNumber,
    },
  })
}

export function postSigningDocumentComplete(
  id: string,
  signID: string,
  signKey: string,
  signerVerificationCode: string
): Promise<RequestResponse<Document>> {
  return secureRequest('POST', url('/v2/documents/' + id + '/sign'), {
    body: {
      action: 'CompleteSigning',
      signID,
      signKey,
      signerVerificationCode,
    },
  })
}

export function postSigningDocumentCallback(
  id: string,
  signID: string,
  signKey: string,
  signatureToken: string
): Promise<RequestResponse<Document>> {
  return secureRequest('POST', url('/v2/documents/' + id + '/sign'), {
    body: {
      action: 'SignatureCallback',
      signID,
      signKey,
      signatureToken,
    },
  })
}

export function postSignerToDocument(
  documentID: string,
  email: string,
  name: string,
  language: Language
): Promise<RequestResponse<Document>> {
  return secureRequest('POST', url('/v2/documentSigners'), {
    body: {
      documentID,
      email,
      name,
      language,
    },
  })
}

export function delDocumentSigner(documentSignerID: string): Promise<RequestResponse<Document>> {
  return secureRequest('DELETE', url('/v2/documentSigners/' + documentSignerID))
}

export function resendDocumentSigner(documentSignerID: string): Promise<RequestResponse> {
  return secureRequest('PATCH', url('/v2/documentSigners/' + documentSignerID), {
    body: {
      action: 'ResendForSigning',
    },
  })
}
