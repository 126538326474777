import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Contract from '../model/contract'
import ContractDelta from '../model/contractDelta'
import { getDate, isSameOrBefore } from '../utils/date-utils'
import { ReducerAction } from '../utils/reducer-utils'

export interface EmployeeContractDeltaReducer {
  employeeID: string | null
  loading: boolean
  loaded: boolean
  contracts: List<ContractDelta>
  dirty: boolean // if dirty, it should be reloaded
  viewingContract: Contract | null
  viewingContractID: string | null // preferred viewing contract
  error: Error | null
}

const Params = Record<EmployeeContractDeltaReducer>({
  employeeID: null,
  loading: false,
  loaded: false,
  contracts: List<ContractDelta>(),
  dirty: false, // if dirty, it should be reloaded
  viewingContract: null,
  viewingContractID: null, // preferred viewing contract
  error: null,
})

export interface EmployeeContractDeltaAction extends ReducerAction {
  employeeID?: string
  contractID?: string
  contractDeltas?: ContractDelta[]
  setViewing?: boolean
  contract?: Contract
}

const comparator = (a: ContractDelta, b: ContractDelta) => a.validFrom.localeCompare(b.validFrom)

export default (
  state: Record<EmployeeContractDeltaReducer> = Params(),
  action: EmployeeContractDeltaAction = { type: '' }
): Record<EmployeeContractDeltaReducer> => {
  let viewingContract = state.get('viewingContract')
  const viewingContractID = state.get('viewingContractID')
  let contractDeltas = null
  switch (action.type) {
    case ActionTypes.EMPLOYEE_CONTRACT_DELTA_LOADING:
      if (!action.employeeID) {
        return state
      }
      return state
        .set('employeeID', action.employeeID)
        .set('loading', true)
        .set('loaded', false)
        .set('error', null)
        .set('dirty', false)
        .set('viewingContract', null)
    case ActionTypes.EMPLOYEE_CONTRACT_DELTA_LOADED:
      contractDeltas = List(action.contractDeltas)
      if (viewingContractID) {
        contractDeltas.forEach((contract) => {
          if (contract.contractID === viewingContractID) {
            viewingContract = contract.contract
          }
        })
      }
      if (!viewingContract && state.get('viewingContractID')) {
        // pick the one preferred
        const delta = contractDeltas.find((delta) => delta.contractID === state.get('viewingContractID'))
        if (delta) {
          viewingContract = delta.contract
        }
      }
      if (!viewingContract && contractDeltas.size > 0) {
        // try to find the first one that is valid
        const delta = contractDeltas
          .sort(comparator)
          .filter((delta) => !delta.futureTo && (!delta.validTo || isSameOrBefore(getDate(), getDate(delta.validTo))))
          .first()
        if (delta) {
          viewingContract = delta.contract
        } else {
          // just pick the newest contract
          const last = contractDeltas.sort(comparator).last()
          if (last) {
            viewingContract = last.contract
          }
        }
      }
      if (viewingContract) {
        state = state.set('viewingContract', viewingContract).set('viewingContractID', viewingContract.id)
      }
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('error', null)
        .set('contracts', contractDeltas.sort(comparator))
        .set('dirty', false)

    case ActionTypes.EMPLOYEE_CONTRACT_DELTA_SET_VIEWING_CONTRACT:
      if (action.employeeID === state.get('employeeID') && action.contractID) {
        state.get('contracts').forEach((contract) => {
          if (contract.contractID === action.contractID) {
            viewingContract = contract.contract
          }
        })
      }
      if (!viewingContract) {
        return state
      }
      return state.set('viewingContract', viewingContract).set('viewingContractID', viewingContract.id)
    case ActionTypes.EMPLOYEE_CONTRACT_DELTA_LOAD_FAILED:
      if (!action.employeeID) {
        return state
      }
      return state
        .set('employeeID', action.employeeID)
        .set('loading', false)
        .set('loaded', false)
        .set('error', action.error || null)
        .set('contracts', List<ContractDelta>())
        .set('dirty', true)

    case ActionTypes.EMPLOYEE_CONTRACT_ADDED:
    case ActionTypes.EMPLOYEE_CONTRACT_UPDATED:
      if (action.setViewing) {
        const changingContractID = action.contract && action.contract ? action.contract.id : action.contractID
        return state.set('dirty', true).set('viewingContractID', changingContractID || null) // remind us that we prefer this one
      }
      return state.set('dirty', true)

    case ActionTypes.EMPLOYEE_CONTRACT_DELTA_SET_DIRTY:
      return state.set('dirty', true)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
