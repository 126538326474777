import { List } from 'immutable'
import React, { ReactElement } from 'react'

import {
  AuthParameter,
  AuthParameters,
  CompanyAccountingIntegrationType,
} from '../../api/company-accounting-integration-setup'
import AvailableAccountingIntegration from '../../model/availableAccountingIntegration'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  availableAccountingIntegrations: List<AvailableAccountingIntegration>

  integrationType: CompanyAccountingIntegrationType
  authParameters?: AuthParameters
}

type ConnectionFields = Partial<Record<`parameter-${AuthParameter}`, string>>

export type ConnectionResult = {
  readonly step: 'NeedKey'
  authParameters: AuthParameters
}

function ConnectionForm(props: Props & FormComponentProps<ConnectionFields, ConnectionResult>): ReactElement | null {
  const getIntegration = () => {
    return props.availableAccountingIntegrations.find((integration) => integration.type === props.integrationType)
  }
  const getIntegrationGuide = (): string => {
    return getIntegration()?.description || ''
  }

  const { decorateField } = props
  const integration = getIntegration()

  if (!integration || integration.hasAuthURL || !integration.parameters) {
    return (
      <div style={{ position: 'relative', minHeight: '400px' }}>
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <div>
      <Subtitle>{t('accounting_integration.connection.title')}</Subtitle>
      <p dangerouslySetInnerHTML={{ __html: getIntegrationGuide() }} />
      {props.getFormError()}
      <Subcard className="connection-search">
        <form autoComplete={'false'}>
          <input type="text" name="username" style={{ position: 'absolute', opacity: 0, zIndex: -9000 }} />
          <input type="password" name="password" style={{ position: 'absolute', opacity: 0, zIndex: -9000 }} />
          {integration.parameters.map((parameter) =>
            decorateField(`parameter-${parameter.name}`, {
              title: parameter.displayName,
              placeholder: t('accounting_integration.connection.form.parameter.placeholder', {
                parameter: parameter.displayName,
                integration: integration?.displayName,
              }),
              validate: (val) =>
                !val
                  ? t('accounting_integration.connection.form.parameter.required', { parameter: parameter.displayName })
                  : null,
            })(
              <Input
                type={parameter.type === 'password' ? 'password' : 'text'}
                autoComplete={'false'}
                data-lpignore={true}
              />
            )
          )}
        </form>
      </Subcard>
      <Row>
        <Col span={24}>
          <Button
            htmlType="submit"
            size="large"
            type="secondary"
            className="gtm-accounting-integration-continue-from-connection"
          >
            {t('accounting_integration.connection.next_step')}
          </Button>
          <Button size="large" onClick={props.goBack} className="gtm-accounting-integration-back">
            {t('accounting_integration.connection.back')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, ConnectionFields, ConnectionResult>({
  mapPropsToFields: (props) => {
    const fields: ConnectionFields = {}
    for (const key in props.authParameters) {
      fields[`parameter-${key as AuthParameter}`] = props.authParameters[key as AuthParameter]
    }
    return fields
  },
  onSubmit: (values) => {
    const authParameters: AuthParameters = {}
    for (const key in values) {
      if (!key.match(/^parameter-/)) {
        continue
      }
      authParameters[key.replace(/^parameter-/, '') as AuthParameter] =
        values[key as `parameter-${AuthParameter}`]?.trim()
    }
    return {
      step: 'NeedKey',
      authParameters,
    }
  },
})(ConnectionForm)
