import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import LeaveBalance from '../model/leaveBalance'
import { ReducerAction } from '../utils/reducer-utils'

export interface LeaveBalanceReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  leaveBalances: List<LeaveBalance>
  error: Error | null
}

const Params = Record<LeaveBalanceReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  leaveBalances: List<LeaveBalance>(),
  error: null,
})

export interface LeaveBalanceAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  leaveTypeID?: string
  leaveBalances?: LeaveBalance[]
  leaveBalance?: LeaveBalance
}

export default (
  state: Record<LeaveBalanceReducer> = Params(),
  action: LeaveBalanceAction = { type: '' }
): Record<LeaveBalanceReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.LEAVE_BALANCES_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.LEAVE_BALANCES_LOADED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loaded: true,
        leaveBalances: List<LeaveBalance>(action.leaveBalances),
      })
    case ActionTypes.LEAVE_BALANCES_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        error: action.error,
      })
    case ActionTypes.LEAVE_BALANCE_UPDATED:
      if (!action.leaveBalance) {
        return state
      }
      idx = state
        .get('leaveBalances')
        .findIndex((item) => item.leaveTypeID === action.leaveTypeID && item.employeeID === action.employeeID)
      if (idx === -1) {
        return state
      }
      return state.set('leaveBalances', state.get('leaveBalances').set(idx, action.leaveBalance))

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
