import React, { ReactElement, useEffect } from 'react'

import { getCompanyAccountPlans, updateCompanyAccountingIntegration } from '../actions/accounting-integration'
import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getAvailableAccountingIntegrations } from '../actions/available-accounting-integrations'
import CompanyAccountingIntegrationComponent from '../components/company-accounting-integration/CompanyAccountingIntegration'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import AccountingIntegration from '../model/accountingIntegration'
import { AlertReducer } from '../reducers/alerts'
import { AvailableAccountingIntegrationReducer } from '../reducers/availableAccountingIntegrations'
import { CompanyReducer } from '../reducers/companies'
import { CompanyAccountingIntegrationReducer } from '../reducers/companyAccountingIntegration'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  availableAccountingIntegrations: AvailableAccountingIntegrationReducer
  companyAccountingIntegration: CompanyAccountingIntegrationReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getAvailableAccountingIntegrations: () => void
  updateCompanyAccountingIntegration: (state: string) => Promise<AccountingIntegration | void>
  getCompanyAccountPlans: (companyID: string) => void
}

function CompanyAccountingIntegration(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { availableAccountingIntegrations, getAvailableAccountingIntegrations } = props

  useEffect(() => {
    if (!availableAccountingIntegrations.loaded && !availableAccountingIntegrations.loading) {
      getAvailableAccountingIntegrations()
    }
  }, [availableAccountingIntegrations, getAvailableAccountingIntegrations])

  const company = props.companies.company
  if (!company) {
    return null
  }

  if (!props.availableAccountingIntegrations.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <CompanyAccountingIntegrationComponent
      query={props.location.query}
      alerts={props.alerts}
      company={company}
      availableAccountingIntegrations={props.availableAccountingIntegrations.availableAccountingIntegrations}
      companyAccountingIntegration={props.companyAccountingIntegration}
      updateCompanyAccountingIntegration={props.updateCompanyAccountingIntegration}
      getCompanyAccountPlans={props.getCompanyAccountPlans}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    availableAccountingIntegrations: state.availableAccountingIntegrations,
    companyAccountingIntegration: state.companyAccountingIntegration,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getAvailableAccountingIntegrations: getAvailableAccountingIntegrations,
    updateCompanyAccountingIntegration: updateCompanyAccountingIntegration,
    getCompanyAccountPlans: getCompanyAccountPlans,
  }
)(CompanyAccountingIntegration)
