import LeaveAdjustment, { LeaveAdjustmentCreationFields, LeaveAdjustmentMutableFields } from '../model/leaveAdjustment'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchLeaveAdjustments(
  companyID?: string,
  employeeID?: string
): Promise<RequestResponse<LeaveAdjustment[]>> {
  return secureRequest('GET', url('/v2/balanceAdjustments/leave', { companyID, employeeID }))
}

export function postLeaveAdjustment(
  leaveAdjustment: LeaveAdjustmentCreationFields
): Promise<RequestResponse<LeaveAdjustment>> {
  return secureRequest('POST', url('/v2/balanceAdjustments/leave'), {
    body: {
      employeeID: leaveAdjustment.employeeID,
      leaveTypeName: leaveAdjustment.leaveTypeName,
      operation: leaveAdjustment.operation,
      dispositionDate: leaveAdjustment.dispositionDate,
      earned: leaveAdjustment.earned,
      used: leaveAdjustment.used,
      writtenOff: leaveAdjustment.writtenOff,
      excessCharged: leaveAdjustment.excessCharged,
      complimentary: leaveAdjustment.complimentary,
      valuation: leaveAdjustment.valuation,
      liability: leaveAdjustment.liability,
    },
  })
}

export function putLeaveAdjustment(
  leaveAdjustmentID: string,
  leaveAdjustment: LeaveAdjustmentMutableFields
): Promise<RequestResponse<LeaveAdjustment>> {
  return secureRequest('PUT', url('/v2/balanceAdjustments/leave/' + leaveAdjustmentID), {
    body: {
      employeeID: leaveAdjustment.employeeID,
      leaveTypeName: leaveAdjustment.leaveTypeName,
      operation: leaveAdjustment.operation,
      dispositionDate: leaveAdjustment.dispositionDate,
      earned: leaveAdjustment.earned,
      used: leaveAdjustment.used,
      writtenOff: leaveAdjustment.writtenOff,
      excessCharged: leaveAdjustment.excessCharged,
      complimentary: leaveAdjustment.complimentary,
      valuation: leaveAdjustment.valuation,
      liability: leaveAdjustment.liability,
    },
  })
}

export function removeLeaveAdjustment(leaveAdjustmentID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/balanceAdjustments/leave/' + leaveAdjustmentID))
}

export type TransferLeaveBalance = {
  from: string
  to: string
}

export function postTransferLeaveBalances(
  employeeID: string,
  transfers: TransferLeaveBalance[]
): Promise<RequestResponse<LeaveAdjustment>> {
  return secureRequest('POST', url('/v2/balanceTransfer/leave'), {
    body: {
      employeeID,
      transfers,
    },
  })
}
