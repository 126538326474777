import { startOfWeek } from 'date-fns'
import React, { ReactElement, useState } from 'react'

import Company from '../../model/company'
import { TimeRegistrationClass } from '../../model/timeRegistration'
import { formatDate, getDate } from '../../utils/date-utils'
import { formatError } from '../../utils/error-utils'
import { t, tx } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Checkbox from '../elements/checkbox'
import Title from '../elements/Title'

type Props = {
  company: Company

  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    timeRegistrationClass: TimeRegistrationClass,
    deleteAll: boolean
  ) => Promise<boolean | void>
  closeModal: () => void
}

export default function WorkHoursDeleteAll(props: Props): ReactElement | null {
  const [deleteAll, setDeleteAll] = useState(false)
  const [error, setError] = useState<Error>()

  return (
    <Card>
      <Title>{t('work_hours.delete_all.title')}</Title>
      {error && <Alert type="error" message={formatError(error)} showIcon />}
      <p>
        {tx('work_hours.delete_all.intro.line_1', {
          from: <strong>{formatDate(startOfWeek(getDate(), { weekStartsOn: 1 }))}</strong>,
        })}
      </p>
      <p>
        <strong>{t('work_hours.delete_all.intro.line_2')}</strong>
      </p>
      <Checkbox style={{ display: 'block' }} checked={deleteAll} onChange={() => setDeleteAll((prev) => !prev)}>
        {t('work_hours.delete_all.delete_all')}
      </Checkbox>
      <Button
        type="danger"
        style={{ marginTop: '20px' }}
        onClick={() => {
          props
            .deleteTimeRegistrationBulk(props.company.id, undefined, 'Work Hours', deleteAll)
            .then((res) => {
              if (!res) {
                return
              }
              props.closeModal()
            })
            .catch((e) => {
              setError(e)
            })
        }}
      >
        {deleteAll ? t('work_hours.delete_all.submit.include_past') : t('work_hours.delete_all.submit.normal')}
      </Button>
    </Card>
  )
}
