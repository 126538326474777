import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { useEffectOnce } from 'react-use'

import Company from '../../model/company'
import Employee from '../../model/employee'
import { TaxCardReducer } from '../../reducers/taxCards'
import { paths } from '../../routes'
import { formatShortDate } from '../../utils/date-utils'
import { formatTaxCardType } from '../../utils/format-utils'
import { formatCurrency, formatNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Table from '../antd/table'
import Card from '../elements/card'
import Title from '../elements/Title'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'

const columns = [
  {
    title: t('company_tax_cards.table.header.employee_number'),
    dataIndex: 'employeeNumber',
    key: 'employeeNumber',
  },
  { title: t('company_tax_cards.table.header.national_id'), dataIndex: 'nationalID', key: 'nationalID' },
  { title: t('company_tax_cards.table.header.name'), dataIndex: 'name', key: 'name' },
  { title: t('company_tax_cards.table.header.tax_card_type'), dataIndex: 'taxCardType', key: 'taxCardType' },
  { title: t('company_tax_cards.table.header.tax_percentage'), dataIndex: 'taxPercentage', key: 'taxPercentage' },
  {
    title: t('company_tax_cards.table.header.deduction_monthly'),
    dataIndex: 'deductionMonthly',
    key: 'deductionMonthly',
  },
  {
    title: t('company_tax_cards.table.header.max_aincome_free_card'),
    dataIndex: 'maxAIncomeFreeCard',
    key: 'maxAIncomeFreeCard',
  },
]

type Props = {
  company: Company
  employees: List<Employee>
  taxCards: TaxCardReducer

  getTaxCards: (companyID: string) => void
}

export default function TaxCardsTab(props: Props): ReactElement | null {
  useEffectOnce(() => {
    const companyID = props.company.id
    if (
      props.taxCards.companyID !== companyID ||
      props.taxCards.employeeID ||
      (!props.taxCards.loading && !props.taxCards.loaded)
    ) {
      props.getTaxCards(companyID)
    }
  })

  type TaxCardRow = {
    key: string
    id: string
    employeeID?: string
    employeeNumber: string
    nationalID: string
    name: string
    validFrom: string
    taxCardType: string
    taxPercentage: string
    deductionMonthly: string
    maxAIncomeFreeCard: string
  }

  const getTaxCardRows = (): TaxCardRow[] => {
    return props.taxCards.taxCards
      .filter((taxCard) => taxCard.activeForCurrentPeriod)
      .map((taxCard) => {
        let employeeID = undefined
        let employeeNumber = ''
        let nationalID = ''
        let name = ''
        const employee = props.employees.find((employee) => employee.id === taxCard.employeeID)
        if (employee) {
          employeeID = employee.id
          employeeNumber = employee.activeEmployment?.employeeNumber || ''
          nationalID = employee.nationalID || ''
          name = employee.name || employee.email || '-'
        }
        let deductionMonthly = 0
        let maxAIncomeFreeCard = 0
        if (taxCard.taxCardType === 'Free') {
          maxAIncomeFreeCard = taxCard.maxAIncomeFreeCard
        } else {
          deductionMonthly = taxCard.deductionMonthly
        }
        return {
          key: taxCard.id,
          id: taxCard.id,
          employeeID,
          employeeNumber,
          nationalID: nationalID.replace(/^(\d{6})(\d{4})$/, '$1-$2'),
          name,
          validFrom: formatShortDate(taxCard.validFromDate),
          taxCardType: formatTaxCardType(taxCard.taxCardType),
          taxPercentage: formatNumber(taxCard.taxPercentage) + '%',
          deductionMonthly: formatCurrency(deductionMonthly),
          maxAIncomeFreeCard: formatCurrency(maxAIncomeFreeCard),
        }
      })
      .toArray()
  }

  if (!props.taxCards.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <Card>
      <Title>{t('company_tax_cards.title')}</Title>

      <Table
        columns={columns}
        dataSource={getTaxCardRows()}
        size="small"
        pagination={false}
        className={'tax-cards-table'}
        onRowClick={(taxCard: TaxCardRow) => {
          if (taxCard.employeeID) {
            jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + taxCard.employeeID + '/tax-cards')
          }
        }}
      />
    </Card>
  )
}
