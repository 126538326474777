import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { AccountingAccount } from '../../model/accountingIntegration'
import Employee from '../../model/employee'
import { EmployeeReducer } from '../../reducers/employees'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import EmployeeNondisburseableAccountEditForm, {
  EmployeeNondisburseableAccountResult,
} from './EmployeeNondisburseableAccountEditForm'

type Props = {
  visible: boolean
  employeeID: string
  employees: EmployeeReducer
  accounts: List<AccountingAccount>

  updateEmployee: (employee: Employee) => void
}

export default function EmployeeNondisburseableAccountEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, employees } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, employees.error, error, setError)
  }, [visible, employees, error])

  const handleSubmit = (values: EmployeeNondisburseableAccountResult) => {
    let employee = props.employees.employees.find((employee) => employee.id === props.employeeID)
    if (employee) {
      employee = { ...employee, nondisburseableAccountingAccountID: values.nondisburseableAccountingAccountID }
      props.updateEmployee(employee)
    }
  }

  const employee = props.employees.employees.find((employee) => employee.id === props.employeeID)

  if (!employee) {
    return null
  }

  return (
    <Card>
      <Subtitle>{t('employee_nondisburseable_account.edit.title', { name: employee.name })}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <EmployeeNondisburseableAccountEditForm
        employee={employee}
        employees={props.employees}
        accounts={props.accounts}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
