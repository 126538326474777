import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import PackageLock from '../../components/widgets/PackageLock'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyFeatureReducer } from '../../reducers/companyFeatures'
import { CompanyIntegrationReducer } from '../../reducers/companyIntegrations'
import { CompanyPricingPackageReducer } from '../../reducers/companyPricingPackages'
import { PricingPackageReducer } from '../../reducers/pricingPackages'
import { UserReducer } from '../../reducers/user'
import { paths } from '../../routes'
import { hasLoanService } from '../../utils/feature-utils'
import { getCompanyPricingPackage } from '../../utils/pricing-package-utils'
import { connectToReducer } from '../../utils/reducer-utils'
import { RoutePropsLocation } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'

import './SubmenuLayout.css'

type Reducers = {
  user: UserReducer
  companies: CompanyReducer
  companyFeatures: CompanyFeatureReducer
  companyIntegrations: CompanyIntegrationReducer
  companyPricingPackages: CompanyPricingPackageReducer
  pricingPackages: PricingPackageReducer
}

type Props = {
  location: RoutePropsLocation
  children: React.ReactNode
  className?: string
  departmentManager?: boolean
}

function IntegrationsLayout(props: Reducers & Props): ReactElement | null {
  const hasContractBookFeature = () => {
    return props.companyFeatures.companyFeatures.some((feature) => feature.featureType === 'ContractBook')
  }
  const isDefinitelyNotAnAccountant = () => {
    // if they do not have this signup role, they MIGHT be an accountant
    if (!props.user.signupCompanyRole || props.user.signupCompanyRole !== 'Company Owner, Runs Pay Roll') {
      return false
    }
    // more than one company?  Probably an accountant
    if (props.companies.companies.size !== 1) {
      return false
    }
    // no company?!  Weird accountant...
    const company = props.companies.company
    if (!company) {
      return false
    }
    // Now find the company's pricing package, and make sure it's not the Accountant one
    return (
      getCompanyPricingPackage(
        props.pricingPackages.pricingPackages.toArray(),
        props.companyPricingPackages.companyPricingPackages.toArray(),
        company.id
      )?.name !== 'Accountant'
    ) // then we might be able to presume they are probably _not_ an accountant
  }

  const path = props.location.pathname.replace('/' + paths.INTEGRATIONS + '/', '')
  const departmentManager = !!props.departmentManager

  return (
    <div className={'submenu integrations-layout' + (props.className ? ' ' + props.className : '')}>
      <div className="submenu-topbar">
        {!departmentManager && (
          <Link
            key={paths.ACCOUNTING}
            to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING}
            className={
              path === paths.ACCOUNTING ||
              path === paths.ACCOUNTING_DIMENSIONS ||
              path === paths.ACCOUNTING_VOUCHERS ||
              path === paths.ACCOUNTING_DAYBOOKS ||
              path === paths.ACCOUNTING_ORGANIZATIONS
                ? 'active'
                : ''
            }
          >
            {t('integration_layout.menu.accounting')}
          </Link>
        )}
        {hasContractBookFeature() && (
          <Link
            key={paths.CONTRACT_BOOK}
            to={'/' + paths.INTEGRATIONS + '/' + paths.CONTRACT_BOOK}
            className={path === paths.CONTRACT_BOOK ? 'active' : ''}
          >
            {t('integration_layout.menu.contract_book')}
          </Link>
        )}
        {!departmentManager && (
          <Link
            key={paths.DATA_PROVIDER}
            to={'/' + paths.INTEGRATIONS + '/' + paths.DATA_PROVIDER}
            className={path === paths.DATA_PROVIDER ? 'active' : ''}
          >
            {t('integration_layout.menu.data_provider')}
          </Link>
        )}
        {!departmentManager && (
          <Link key={'codan'} to={'/' + paths.INTEGRATIONS + '/codan'} className={path === 'codan' ? 'active' : ''}>
            {t('integration_layout.menu.codan')}
          </Link>
        )}
        {!departmentManager && (
          <Link
            key={'pension'}
            to={'/' + paths.INTEGRATIONS + '/pension'}
            className={path === 'pension' ? 'active' : ''}
          >
            {t('integration_layout.menu.pension')}
          </Link>
        )}
        {!departmentManager && (
          <Link
            key={'minrefusion'}
            to={'/' + paths.INTEGRATIONS + '/minrefusion'}
            className={path === 'minrefusion' ? 'active' : ''}
          >
            {t('integration_layout.menu.min_refusion')}
          </Link>
        )}
        {!departmentManager && (
          <Link key={'rmindr'} to={'/' + paths.INTEGRATIONS + '/rmindr'} className={path === 'rmindr' ? 'active' : ''}>
            {t('integration_layout.menu.rmindr')}
          </Link>
        )}
        {!departmentManager && (
          <PackageLock packageGroups={['Premium']}>
            <Link
              key={'personalezonen'}
              to={'/' + paths.INTEGRATIONS + '/personalezonen'}
              className={path === 'personalezonen' ? 'active' : ''}
            >
              {t('integration_layout.menu.personalezonen')}
            </Link>
          </PackageLock>
        )}
        {hasLoanService() &&
          !departmentManager &&
          props.companies.company &&
          props.companies.company.verified &&
          props.companies.companies.size === 1 && (
            <Link
              key={paths.LOAN}
              to={'/' + paths.INTEGRATIONS + '/' + paths.LOAN}
              className={path === paths.LOAN ? 'active' : ''}
            >
              {t('integration_layout.menu.loan')}
            </Link>
          )}
        {isDefinitelyNotAnAccountant() && !departmentManager && (
          <Link
            key={'advisor'}
            to={'/' + paths.INTEGRATIONS + '/advisor'}
            className={path === 'advisor' ? 'active' : ''}
          >
            {t('integration_layout.menu.advisor')}
          </Link>
        )}
      </div>
      <div className="submenu-main">{props.children}</div>
    </div>
  )
}

export default connectToReducer<Reducers, any, Props>(
  (state) => ({
    user: state.user,
    companies: state.companies,
    companyFeatures: state.companyFeatures,
    companyIntegrations: state.companyIntegrations,
    companyPricingPackages: state.companyPricingPackages,
    pricingPackages: state.pricingPackages,
  }),
  {}
)(IntegrationsLayout)
