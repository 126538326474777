import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import { TaxCardUpdate } from '../../../actions/tax-cards'
import { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import Employment from '../../../model/employment'
import TaxCard from '../../../model/taxCard'
import TaxCardRequest from '../../../model/taxCardRequest'
import { ContractReducer } from '../../../reducers/contracts'
import { EmploymentReducer } from '../../../reducers/employments'
import { TaxCardRequestReducer } from '../../../reducers/taxCardRequests'
import { TaxCardReducer } from '../../../reducers/taxCards'
import PreferredTaxCardType from '../../../types/preferred-tax-card-type'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatDate } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { formatPreferredTaxCardType, formatTaxCardRequestType, formatTaxCardType } from '../../../utils/format-utils'
import { formatCurrency, formatNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Select from '../../antd/select'
import Table from '../../antd/table'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Icon from '../../elements/Icon'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoEmploymentCard from '../NoEmploymentCard'
import EditModal from './EditModal'
import ExtraTaxPercentageModal from './ExtraTaxPercentageModal'

type Props = {
  employee: Employee
  taxCards: TaxCardReducer
  taxCardRequests: TaxCardRequestReducer
  canEditObjects: boolean
  canHireFire: boolean
  contracts: ContractReducer
  employments: EmploymentReducer

  addAlert: addAlertSignature
  getTaxCardRequests: (_: string) => void
  updateEmployment: (_id: string, _e: Employment) => void
  addTaxCardRequest: (employeeID: string) => Promise<TaxCardRequest | null>
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
  updateTaxCard: (_: TaxCardUpdate) => void
}

export default function TaxCardsTab(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState<number>(1)
  const [showEdit, setShowEdit] = useState<string | null>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [preferredTaxCardType, setPreferredTaxCardType] = useState<PreferredTaxCardType>(
    props.employee.activeEmployment
      ? props.employee.activeEmployment.preferredTaxCardType
      : PreferredTaxCardType.MAIN_CARD
  )
  const [error, setError] = useState<Error | null>(null)

  const setEditVisibility = useCallback(
    (v: string | null): void => {
      setShowEdit(v)
      setModalKey(modalKey + 1)
    },
    [modalKey]
  )
  const setModalVisibility = useCallback(
    (v: boolean): void => {
      setShowModal(v)
      setModalKey(modalKey + 1)
    },
    [modalKey]
  )

  const addAlert = props.addAlert

  const taxCardRequests = props.taxCardRequests
  const getTaxCardRequests = props.getTaxCardRequests

  const employee = props.employee

  useEffect(() => {
    if (taxCardRequests.employeeID !== employee.id || (!taxCardRequests.loading && !taxCardRequests.loaded)) {
      getTaxCardRequests(employee.id)
    }
  }, [taxCardRequests, employee, getTaxCardRequests])

  const taxCardSaving = props.taxCards.saving
  const wasTaxCardSaving = usePrevious(taxCardSaving)
  const taxCardError = props.taxCards.error

  useEffect(() => {
    if (wasTaxCardSaving && !taxCardSaving && !taxCardError) {
      addAlert('success', t('tax_card.tab.alert.success.tax_card', { name: employee.name }), { timeout: 5 })
      setEditVisibility(null)
    }
  }, [wasTaxCardSaving, taxCardSaving, taxCardError, employee, addAlert, setEditVisibility])

  const contractSaving = props.contracts.saving
  const wasContractSaving = usePrevious(contractSaving)
  const contractError = props.contracts.error

  useEffect(() => {
    if (wasContractSaving && !contractSaving && !contractError) {
      addAlert('success', t('tax_card.tab.alert.success.tax_percentage', { name: employee.name }), { timeout: 5 })
      setModalVisibility(false)
    }
  }, [wasContractSaving, contractSaving, contractError, employee, addAlert, setModalVisibility])

  const employmentSaving = props.employments.saving
  const wasEmploymentSaving = usePrevious(employmentSaving)
  const employmentError = props.employments.error

  useEffect(() => {
    if (wasEmploymentSaving && !employmentSaving && !contractError) {
      addAlert('success', t('tax_card.tab.alert.success.request'), { timeout: 10 })
    }
  }, [wasEmploymentSaving, employmentSaving, employmentError, addAlert, contractError])

  const taxCardRequestError = props.taxCardRequests.error

  useEffect(() => {
    regularComponentDidUpdate(employmentError || taxCardRequestError, error, setError)
  }, [employmentError, taxCardRequestError, error])

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      if (
        props.employee.activeEmployment &&
        props.employee.activeEmployment.preferredTaxCardType !== preferredTaxCardType
      ) {
        const employment = { ...props.employee.activeEmployment, preferredTaxCardType }
        props.updateEmployment(props.employee.id, employment)
      } else {
        props.addTaxCardRequest(props.employee.id).then((data) => {
          if (data) {
            props.addAlert('success', t('tax_card.tab.alert.success.request'), { timeout: 10 })
          }
        })
      }
    }
  }

  type TaxCardRow = {
    key: string
    id: string
    validFrom: string
    taxCardType: string
    taxPercentage: string
    deductionMonthly: string
    maxAIncomeFreeCard: string
    incomeYear: number
    isActive: boolean
    active: string
    extraTaxPercentage?: number
  }

  const taxCardsColumns = [
    {
      title: t('tax_card.tab.table.header.valid_from'),
      key: 'xValidFrom',
      render: (taxCard: TaxCardRow) => {
        return (
          <span>
            {taxCard.validFrom}
            <span onClick={() => setEditVisibility(taxCard.id)} style={{ cursor: 'pointer' }}>
              <Icon type="edit" color="grey" style={{ margin: '0 0 -4px 5px' }} />
            </span>
          </span>
        )
      },
    },
    { title: t('tax_card.tab.table.header.tax_card_type'), dataIndex: 'taxCardType', key: 'taxCardType' },
    {
      title: t('tax_card.tab.table.header.percentage'),
      key: 'xPercentage',
      render: (taxCard: TaxCardRow) => {
        if (taxCard.isActive && taxCard.extraTaxPercentage) {
          return (
            <span>
              <span>{taxCard.taxPercentage}</span>&nbsp;
              <span style={{ color: 'var(--sally-grey)' }}>(+{taxCard.extraTaxPercentage}%)</span>
            </span>
          )
        } else {
          return <span>{taxCard.taxPercentage}</span>
        }
      },
    },
    {
      title: t('tax_card.tab.table.header.deduction_monthly'),
      dataIndex: 'deductionMonthly',
      key: 'deductionMonthly',
    },
    {
      title: t('tax_card.tab.table.header.max_a_income_free_card'),
      dataIndex: 'maxAIncomeFreeCard',
      key: 'maxAIncomeFreeCard',
    },
    { title: t('tax_card.tab.table.header.income_year'), dataIndex: 'incomeYear', key: 'incomeYear' },
    {
      title: t('tax_card.tab.table.header.active'),
      dataIndex: 'active',
      key: 'active',
    },
  ]

  const getTaxCards = (): TaxCardRow[] => {
    return props.taxCards.taxCards.toArray().map<TaxCardRow>((taxCard: TaxCard): TaxCardRow => {
      const { taxCardType } = taxCard
      let deductionMonthly = 0
      let maxAIncomeFreeCard = 0
      if (taxCardType === 'Free') {
        maxAIncomeFreeCard = taxCard.maxAIncomeFreeCard
      } else {
        deductionMonthly = taxCard.deductionMonthly
      }
      return {
        key: taxCard.id,
        id: taxCard.id,
        validFrom: formatDate(taxCard.validFromDate),
        taxCardType: formatTaxCardType(taxCardType),
        taxPercentage: formatNumber(taxCard.taxPercentage) + '%',
        deductionMonthly: formatCurrency(deductionMonthly),
        maxAIncomeFreeCard: formatCurrency(maxAIncomeFreeCard),
        incomeYear: taxCard.incomeYear,
        isActive: taxCard.activeForCurrentPeriod,
        active: taxCard.activeForCurrentPeriod
          ? t('tax_card.tab.table.active.true')
          : t('tax_card.tab.table.active.false'),
        extraTaxPercentage: props.employee.earliestMutableContract?.extraTaxPercentage,
      }
    })
  }

  type TaxCardRequestRow = {
    key: string
    id: string
    requestType: string
    sentTime: string
  }

  const taxCardRequestsColumns = [
    { title: t('tax_card.tab.request_table.header.request_type'), dataIndex: 'requestType', key: 'requestType' },
    { title: t('tax_card.tab.request_table.header.sent_time'), dataIndex: 'sentTime', key: 'sentTime' },
  ]

  const fetchTaxCardRequests = (): TaxCardRequestRow[] => {
    return props.taxCardRequests.taxCardRequests.toArray().map((taxCardRequest: TaxCardRequest): TaxCardRequestRow => {
      return {
        key: taxCardRequest.id,
        id: taxCardRequest.id,
        requestType: formatTaxCardRequestType(taxCardRequest.requestType),
        sentTime: formatDate(taxCardRequest.sentTime),
      }
    })
  }

  if (!props.taxCardRequests.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }

  return (
    <div>
      <Card>
        <TitleMenu>
          {props.employee.earliestMutableContract && props.canEditObjects && (
            <Button onClick={() => setModalVisibility(true)} style={{ float: 'left', marginRight: '20px' }}>
              <Icon type="edit" color="grey" />
              {t('tax_card.tab.header.increase_percentage')}
            </Button>
          )}

          {props.canHireFire && (
            <span>
              <Select
                dropdownMatchSelectWidth={false}
                placeholder={t('tax_card.tab.header.select_tax_card')}
                onChange={(value: PreferredTaxCardType) => setPreferredTaxCardType(value)}
                value={preferredTaxCardType}
              >
                <Select.Option value={PreferredTaxCardType.MAIN_CARD}>
                  {formatPreferredTaxCardType(PreferredTaxCardType.MAIN_CARD)}
                </Select.Option>
                <Select.Option value={PreferredTaxCardType.SECONDARY_CARD}>
                  {formatPreferredTaxCardType(PreferredTaxCardType.SECONDARY_CARD)}
                </Select.Option>
              </Select>
              <Button onClick={handleSubmit}>
                <Icon type="file" color="grey" />
                {t('tax_card.tab.header.request_new_tax_card')}
              </Button>
            </span>
          )}
          {(props.employments.saving || props.taxCardRequests.saving) && <LoadingOverlay />}
        </TitleMenu>
        <Title>{t('tax_card.tab.title.standard')}</Title>
        {error && <Alert message={formatError(error)} type="error" showIcon />}

        <Table columns={taxCardsColumns} dataSource={getTaxCards()} size="small" pagination={false} />
      </Card>
      <Card>
        <Title>{t('tax_card.tab.title.requests')}</Title>

        <Table columns={taxCardRequestsColumns} dataSource={fetchTaxCardRequests()} size="small" pagination={false} />
      </Card>

      <Modal
        key={'edit' + modalKey}
        visible={!!showEdit}
        onOk={() => setEditVisibility(null)}
        onCancel={() => setEditVisibility(null)}
        width={376}
        footer={null}
      >
        <EditModal
          visible={!!showEdit}
          taxCardID={showEdit || undefined}
          taxCards={props.taxCards}
          updateTaxCard={props.updateTaxCard}
        />
      </Modal>

      {props.employee.earliestMutableContract && (
        <Modal
          key={'modal' + modalKey}
          visible={showModal}
          onOk={() => setModalVisibility(false)}
          onCancel={() => setModalVisibility(false)}
          width={376}
          footer={null}
        >
          <ExtraTaxPercentageModal
            visible={showModal}
            employee={props.employee}
            contract={props.employee.earliestMutableContract}
            contracts={props.contracts}
            addContract={props.addContract}
            updateContract={props.updateContract}
          />
        </Modal>
      )}
    </div>
  )
}
