import React, { ReactElement } from 'react'

import Asset from '../../../model/asset'
import { formatDate, getDate, isTimeBefore } from '../../../utils/date-utils'
import { t } from '../../../utils/translation-utils'
import Card from '../../elements/card'

type Props = {
  asset: Asset | undefined
  category: string | undefined
}

export default function AssetDisplay(props: Props): ReactElement | null {
  if (props.asset == undefined) {
    return null
  }

  const today = getDate()

  let returnString = ''

  let receiveDate = undefined
  if (props.asset.receivedDate) {
    receiveDate = getDate(props.asset.receivedDate)
    if (isTimeBefore(today, receiveDate)) {
      returnString = t('asset.display.received.future', { date: formatDate(props.asset.receivedDate) })
    } else {
      returnString = t('asset.display.received.past', { date: formatDate(props.asset.receivedDate) })
    }
  }

  let returnDate = undefined
  if (props.asset.returnDate) {
    returnDate = getDate(props.asset.returnDate)
    if (isTimeBefore(today, returnDate)) {
      returnString = t('asset.display.returned.future', { date: formatDate(props.asset.returnDate) })
    } else {
      returnString = t('asset.display.returned.past', { date: formatDate(props.asset.returnDate) })
    }
  }

  return (
    <Card>
      <div>
        <div style={{ fontWeight: 'bold', fontSize: 22 }}>
          {props.asset.title}, {props.category}
        </div>
        {returnString && (
          <div>
            <span>{returnString}</span>
          </div>
        )}
        <div style={{ fontSize: 18 }}>{props.asset.description}</div>
        <div style={{ fontSize: 18 }}>{props.asset.note}</div>
      </div>
    </Card>
  )
}
