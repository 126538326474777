import React from 'react'

import { fetchShipments } from '../api/shipments'
import ActionTypes from '../constants/action-types'
import Shipment from '../model/shipment'
import { ShipmentAction } from '../reducers/shipments'
import { isRequestError } from '../utils/error-utils'

function loadingShipments(payRollID: string): ShipmentAction {
  return {
    type: ActionTypes.SHIPMENT_LOADING,
    payRollID,
  }
}
function loadedShipments(payRollID: string, shipments: Shipment[]): ShipmentAction {
  return {
    type: ActionTypes.SHIPMENT_LOADED,
    payRollID,
    shipments,
  }
}
function failedLoadingShipments(payRollID: string, error: Error): ShipmentAction {
  return {
    type: ActionTypes.SHIPMENT_LOAD_FAILED,
    payRollID,
    error,
  }
}

export function getShipments(payRollID: string) {
  return (dispatch: React.Dispatch<any>): Promise<Shipment[] | void> => {
    dispatch(loadingShipments(payRollID))
    return fetchShipments(payRollID)
      .then((res) => {
        dispatch(loadedShipments(payRollID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingShipments(payRollID, e))
        }
      })
  }
}
