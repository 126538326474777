import React, { CSSProperties, ReactElement } from 'react'

import './Elements.css'

// NEED_NO_TRANSLATION

export default function Headline(props: {
  className?: string
  style?: CSSProperties
  children?: React.ReactNode
}): ReactElement {
  return (
    <h3 className={'h3' + (props.className ? ' ' + props.className : '')} style={props.style}>
      {props.children}
    </h3>
  )
}
