import React, { ReactElement } from 'react'

import { CompanyAccountingIntegrationType } from '../../api/company-accounting-integration-setup'
import { AccountingAccount, AccountPlan, AccountPlanUpdateConfiguration } from '../../model/accountingIntegration'
import { CompanyAccountPlanReducer } from '../../reducers/companyAccountPlans'
import {
  combineSearchOption,
  decorateAnyFieldSignature,
  getAnyFieldErrorSignature,
  getAnyFieldValueSignature,
  getFieldValueSignature,
  setAnyFieldValueSignature,
} from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Select from '../antd/select'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'
import LoadingOverlay from '../widgets/LoadingOverlay'

type AccountMappingFormFields = {
  accountMapping: AccountPlanUpdateConfiguration[]
}

type Props<Fields extends AccountMappingFormFields> = {
  decorateAnyField: decorateAnyFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  setAnyFieldValue: setAnyFieldValueSignature
  getAnyFieldValue: getAnyFieldValueSignature
  getAnyFieldError: getAnyFieldErrorSignature

  integrationType: CompanyAccountingIntegrationType
  accountPlan?: AccountPlan
  companyAccountPlans?: CompanyAccountPlanReducer
}

export const NO_ACCOUNT = 'NO_ACCOUNT'

export default function AccountMappingForm<Fields extends AccountMappingFormFields>(
  props: Props<Fields>
): ReactElement | null {
  const getAccounts = (selectedExternalID?: string): AccountingAccount[] => {
    let list: AccountingAccount[] = []
    if (props.accountPlan) {
      if (props.accountPlan.accounts) {
        list = props.accountPlan.accounts
      }
    } else if (props.companyAccountPlans) {
      if (props.companyAccountPlans.accounts) {
        list = props.companyAccountPlans.accounts.toArray()
      }
    }
    return list.filter((account) => account.active || account.externalID === selectedExternalID)
  }
  const hasIntegration = () => {
    return getAccounts().length > 0 && props.integrationType !== 'None'
  }
  const handleAccountChanged = (i: number) => {
    return (value: string) => {
      const { setAnyFieldValue } = props
      let accountNumber
      if (hasIntegration()) {
        const account = getAccounts().find((account) => account.externalID === value)
        if (account) {
          accountNumber = account.accountNumber
        }
      } else {
        accountNumber = value
      }
      if (accountNumber) {
        setAnyFieldValue(`accountMapping.${i}.accountNumber`, accountNumber)
      }
    }
  }

  if (props.companyAccountPlans && !props.companyAccountPlans.loaded) {
    return (
      <Subcard>
        <div
          style={{
            position: 'relative',
            minHeight: '150px',
            marginTop: '16px',
          }}
        >
          <LoadingOverlay />
        </div>
      </Subcard>
    )
  }
  const { decorateAnyField, getFieldValue, getAnyFieldError, getAnyFieldValue } = props

  return (
    <Subcard>
      <Row style={{ marginBottom: '-10px' }}>
        <Col span={6}>
          <label>{t('accounting_integration.mapping.form.header.type')}</label>
        </Col>
        <Col span={9}>
          <label>{t('accounting_integration.mapping.form.header.account')}</label>
        </Col>
        <Col span={9}>
          <label>{t('accounting_integration.mapping.form.header.text')}</label>
        </Col>
      </Row>
      {getFieldValue('accountMapping').map((mapping, i) => {
        const error = getAnyFieldError(`accountMapping.${i}.externalID`)
        return (
          <Form.Item
            key={i}
            validateStatus={
              getAnyFieldError(`accountMapping.${i}.externalID`) || getAnyFieldError(`accountMapping.${i}.text`)
                ? 'error'
                : 'success'
            }
          >
            <Col span={24}>
              <Input.Group compact>
                <div style={{ width: '24%' }}>
                  {mapping.accountType}
                  {error && <div className="ant-form-error no-icon">{error}</div>}
                </div>
                {hasIntegration() &&
                  decorateAnyField(`accountMapping.${i}.externalID`, {
                    skipWrapper: true,
                    skipLabel: true,
                    validate: (externalID?: string) => {
                      if (!externalID || externalID === NO_ACCOUNT) {
                        return null
                      }
                      const account = getAccounts(externalID).find((account) => account.externalID === externalID)
                      if (!account) {
                        return t('accounting_integration.mapping.form.external_id.not_active')
                      }
                      if (!account.active) {
                        return t('accounting_integration.mapping.form.external_id.not_active.number', {
                          number: account.accountNumber,
                        })
                      }
                      return null
                    },
                  })(
                    <Select
                      dropdownMatchSelectWidth={false}
                      showSearch={true}
                      filterOption={(inputValue: string, option: ReactElement) => {
                        return combineSearchOption(option).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                      }}
                      onChange={handleAccountChanged(i)}
                      style={{ width: '38%' }}
                    >
                      <Select.Option key={NO_ACCOUNT} value={NO_ACCOUNT}>
                        <i>{t('accounting_integration.mapping.form.external_id.no_account')}</i>
                      </Select.Option>
                      {getAccounts(getAnyFieldValue(`accountMapping.${i}.externalID`) as string).map((account) => {
                        return (
                          <Select.Option
                            key={account.externalID}
                            value={account.externalID}
                            title={account.accountNumber + ' ' + account.name}
                          >
                            {account.accountNumber ? account.accountNumber + ' ' : ''}
                            {account.name}
                            {!account.active && (
                              <>
                                {' '}
                                <i>{t('accounting_integration.mapping.form.external_id.option_not_active')}</i>
                              </>
                            )}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  )}
                {!hasIntegration() &&
                  decorateAnyField(`accountMapping.${i}.externalID`, {
                    skipWrapper: true,
                    skipLabel: true,
                  })(
                    <Input
                      style={{ width: '38%' }}
                      placeholder={t('accounting_integration.mapping.form.external_id.no_integration')}
                    />
                  )}
                {decorateAnyField(`accountMapping.${i}.text`, {
                  skipWrapper: true,
                  skipLabel: true,
                })(<Input style={{ width: '38%' }} placeholder={t('accounting_integration.mapping.form.text')} />)}
              </Input.Group>
            </Col>
          </Form.Item>
        )
      })}
    </Subcard>
  )
}
