import React from 'react'

import { fetchPensionCompanies } from '../api/pension-companies'
import ActionTypes from '../constants/action-types'
import PensionCompany from '../model/pensionCompany'
import { PensionCompanyAction } from '../reducers/pensionCompanies'
import { isRequestError } from '../utils/error-utils'

function loadingPensionCompanies(): PensionCompanyAction {
  return {
    type: ActionTypes.PENSION_COMPANY_LOADING,
  }
}
function loadedPensionCompanies(pensionCompanies: PensionCompany[]): PensionCompanyAction {
  return {
    type: ActionTypes.PENSION_COMPANY_LOADED,
    pensionCompanies,
  }
}
function failedLoadingPensionCompanies(error: Error): PensionCompanyAction {
  return {
    type: ActionTypes.PENSION_COMPANY_LOAD_FAILED,
    error,
  }
}

export function getPensionCompanies() {
  return (dispatch: React.Dispatch<any>): Promise<PensionCompany[] | void> => {
    dispatch(loadingPensionCompanies())
    return fetchPensionCompanies()
      .then((res) => {
        dispatch(loadedPensionCompanies(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingPensionCompanies(e))
        }
      })
  }
}
