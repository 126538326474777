import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Asset from '../../model/asset'
import AssetCategory from '../../model/assetCategory'
import AsynchronousTask from '../../model/asynchronousTask'
import Company from '../../model/company'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyFeatureReducer } from '../../reducers/companyFeatures'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { DepartmentReducer } from '../../reducers/departments'
import { EmployeeReducer } from '../../reducers/employees'
import { EmploymentPositionReducer } from '../../reducers/employmentPositions'
import { PensionCompanyReducer } from '../../reducers/pensionCompanies'
import { SalaryCycleReducer } from '../../reducers/salaryCycles'
import { SalaryTypeReducer } from '../../reducers/salaryTypes'
import { SupplementTypeReducer } from '../../reducers/supplementTypes'
import { UserReducer } from '../../reducers/user'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Blocktitle from '../elements/Blocktitle'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Steps from '../elements/steps'
import Subtitle from '../elements/Subtitle'
import Alerts from '../widgets/Alerts'
import BatchUpdate from './BatchUpdate'
import EmployeeSearchForm, { BatchEmployeeSearchResult } from './EmployeeSearchForm'

import './EmployeesBatch.css'

type Props = {
  alerts: AlertReducer
  assets: List<Asset>
  assetCategories: List<AssetCategory>
  asynchronousTasks: List<AsynchronousTask>
  company: Company
  companyUsers: CompanyUserReducer
  user: UserReducer
  companyFeatures: CompanyFeatureReducer
  employees: EmployeeReducer
  supplementTypes: SupplementTypeReducer
  pensionCompanies: PensionCompanyReducer
  employmentPositions: EmploymentPositionReducer
  departments: DepartmentReducer
  salaryCycles: SalaryCycleReducer
  salaryTypes: SalaryTypeReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
}

export default function EmployeesBatch(props: Props): ReactElement | null {
  const [employeeIDs, setEmployeeIDs] = useState<string[]>([])
  const [step, setStep] = useState<'Search' | 'Batch'>('Search')
  const [error, setError] = useState<Error | null>(null)

  const { employees, departments } = props

  useEffect(() => {
    regularComponentDidUpdate(employees.error || departments.error, error, setError)
  }, [employees, departments, error])

  const handlePrevious = () => {
    setStep('Search')
  }
  const handleNext = (values: BatchEmployeeSearchResult) => {
    setEmployeeIDs(values.employeeIDs)
    setStep('Batch')
  }

  const hasFutureContractsFeature = () => {
    return (
      props.companyFeatures.loaded &&
      props.companyFeatures.companyFeatures.some((feature) => feature.featureType === 'Future Contracts')
    )
  }

  const hasAssetsFeature = () => {
    return (
      props.companyFeatures.loaded &&
      props.companyFeatures.companyFeatures.some((feature) => feature.featureType === 'Asset Management')
    )
  }

  return (
    <div className="employees-batch">
      <Row>
        <Col span={16}>
          <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
          <div className="employees-batch-overview">
            {error && <Alert message={formatError(error)} type="error" showIcon />}
            <div>
              {step === 'Search' && (
                <Card>
                  <Blocktitle>{t('employee_batch.wizard.step.0.title')}</Blocktitle>
                  <EmployeeSearchForm
                    company={props.company}
                    employees={props.employees.employees}
                    employeeIDs={employeeIDs}
                    departments={props.departments.departments}
                    salaryCycles={props.salaryCycles.salaryCycles}
                    onSubmit={handleNext}
                  />
                </Card>
              )}
              {step === 'Batch' && (
                <Card>
                  <Blocktitle>{t('employee_batch.wizard.step.1.title')}</Blocktitle>
                  <BatchUpdate
                    assets={props.assets}
                    assetCategories={props.assetCategories}
                    asynchronousTasks={props.asynchronousTasks}
                    company={props.company}
                    companyUser={props.companyUsers.companyUser}
                    user={props.user}
                    departments={props.departments.departments}
                    employees={props.employees.employees}
                    supplementTypes={props.supplementTypes.supplementTypes}
                    salaryCycles={props.salaryCycles.salaryCycles}
                    salaryTypes={props.salaryTypes.salaryTypes}
                    pensionCompanies={props.pensionCompanies.pensionCompanies}
                    employmentPositions={props.employmentPositions.employmentPositions}
                    employeeIDs={employeeIDs}
                    hasFutureContractsFeature={hasFutureContractsFeature()}
                    hasAssetsFeature={hasAssetsFeature()}
                    goBack={handlePrevious}
                  />
                </Card>
              )}
            </div>
          </div>
        </Col>
        <Col span={7}>
          <Card>
            <Subtitle>{t('employee_batch.wizard.steps.title')}</Subtitle>
            <p>{t('employee_batch.wizard.steps.intro')}</p>

            <Steps direction="vertical" current={step === 'Batch' ? 1 : 0}>
              <Steps.Step title={t('employee_batch.wizard.steps.0')} />
              <Steps.Step title={t('employee_batch.wizard.steps.1')} />
            </Steps>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
