import React, { ReactElement } from 'react'

import Company from '../../model/company'
import { CompanyReducer } from '../../reducers/companies'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import SwitchWrapper from '../form-elements/SwitchWrapper'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  companies: CompanyReducer
  company: Company
}

export type EarlyPaymentsFields = {
  enableEarlyPayments: boolean
}

function EarlyPaymentsModalForm(
  props: Props & FormComponentProps<EarlyPaymentsFields, EarlyPaymentsFields>
): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          <SwitchWrapper<EarlyPaymentsFields> id={'enableEarlyPayments'} decorateField={decorateField}>
            {t('early_payments.edit.form.enable_early_payments')}
          </SwitchWrapper>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary" block>
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.companies.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, EarlyPaymentsFields, EarlyPaymentsFields>({
  mapPropsToFields: (props) => {
    return {
      enableEarlyPayments: props.company.enableEarlyPayments,
    }
  },
  onSubmit: (values) => values,
})(EarlyPaymentsModalForm)
