import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import ContractTemplateContract, { ContractTemplateContractContainer } from '../model/contractTemplateContract'
import { ReducerAction } from '../utils/reducer-utils'

export interface ContractTemplateContractReducer {
  companyID: string | undefined
  contractTemplateID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  contractTemplateContracts: List<ContractTemplateContractContainer>
  error: Error | null
}

const Params = Record<ContractTemplateContractReducer>({
  companyID: undefined,
  contractTemplateID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  contractTemplateContracts: List<ContractTemplateContractContainer>(),
  error: null,
})

export interface ContractTemplateContractAction extends ReducerAction {
  companyID?: string
  contractTemplateID?: string
  contractTemplateContractID?: string
  contractTemplateContracts?: ContractTemplateContractContainer[]
  contractTemplateContract?: ContractTemplateContract
}

const comparator = (a: ContractTemplateContractContainer, b: ContractTemplateContractContainer) => {
  return a.templateContract.order - b.templateContract.order
}

function newContainer(
  container: ContractTemplateContractContainer | undefined,
  templateContract: ContractTemplateContract
): ContractTemplateContractContainer {
  if (container) {
    return { ...container, templateContractID: templateContract.id, templateContract: templateContract }
  }
  return {
    futureTo: null,
    activeTo: null,
    addRemuneration: { salary: [], benefits: [], pension: [], leave: [], supplements: [] },
    delRemuneration: { salary: [], benefits: [], pension: [], leave: [], supplements: [] },
    templateContractID: templateContract.id,
    templateContract: templateContract,
  }
}

export default (
  state: Record<ContractTemplateContractReducer> = Params(),
  action: ContractTemplateContractAction = { type: '' }
): Record<ContractTemplateContractReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  // only process actions for the active contract template, when not loading
  if (
    ![
      ActionTypes.CONTRACT_TEMPLATE_CONTRACT_LOADING,
      ActionTypes.CONTRACT_TEMPLATE_CONTRACT_LOADED,
      ActionTypes.CONTRACT_TEMPLATE_CONTRACT_LOAD_FAILED,
    ].some((type) => action.type === type) &&
    state.get('contractTemplateID') &&
    action.contractTemplateID !== state.get('contractTemplateID')
  ) {
    return state
  }

  const idx = state
    .get('contractTemplateContracts')
    .findIndex((item) => item.templateContract.id === action.contractTemplateContractID)

  switch (action.type) {
    case ActionTypes.CONTRACT_TEMPLATE_CONTRACT_LOADING:
      if (!action.contractTemplateID) {
        return state
      }
      return state.set('loading', true).set('loaded', false).set('contractTemplateID', action.contractTemplateID)
    case ActionTypes.CONTRACT_TEMPLATE_CONTRACT_LOADED:
      if (!action.contractTemplateID) {
        return state
      }
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('contractTemplateID', action.contractTemplateID)
        .set(
          'contractTemplateContracts',
          List<ContractTemplateContractContainer>(action.contractTemplateContracts).sort(comparator)
        )
        .set('error', null)
    case ActionTypes.CONTRACT_TEMPLATE_CONTRACT_LOAD_FAILED:
      if (!action.contractTemplateID) {
        return state
      }
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('contractTemplateID', action.contractTemplateID)
        .set('error', action.error || null)

    case ActionTypes.CONTRACT_TEMPLATE_CONTRACT_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CONTRACT_TEMPLATE_CONTRACT_ADDED:
      if (!action.contractTemplateContract) {
        return state
      }
      if (idx !== -1) {
        return state.set('saving', false).set(
          'contractTemplateContracts',
          state
            .get('contractTemplateContracts')
            .set(idx, newContainer(state.get('contractTemplateContracts').get(idx), action.contractTemplateContract))
            .sort(comparator)
        )
      }
      return state
        .set('saving', false)
        .set(
          'contractTemplateContracts',
          state
            .get('contractTemplateContracts')
            .push(newContainer(undefined, action.contractTemplateContract))
            .sort(comparator)
        )
    case ActionTypes.CONTRACT_TEMPLATE_CONTRACT_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.CONTRACT_TEMPLATE_CONTRACT_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CONTRACT_TEMPLATE_CONTRACT_UPDATED:
      if (!action.contractTemplateContract) {
        return state
      }
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set(
        'contractTemplateContracts',
        state
          .get('contractTemplateContracts')
          .set(idx, newContainer(state.get('contractTemplateContracts').get(idx), action.contractTemplateContract))
          .sort(comparator)
      )
    case ActionTypes.CONTRACT_TEMPLATE_CONTRACT_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.CONTRACT_TEMPLATE_CONTRACT_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CONTRACT_TEMPLATE_CONTRACT_DELETED:
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state
        .set('saving', false)
        .set('contractTemplateContracts', state.get('contractTemplateContracts').delete(idx))
    case ActionTypes.CONTRACT_TEMPLATE_CONTRACT_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
