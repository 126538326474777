import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import OutgoingLead from '../model/outgoingLead'
import { ReducerAction } from '../utils/reducer-utils'

export interface OutgoingLeadReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  outgoingLeads: List<OutgoingLead>
  error: Error | null
}

const Params = Record<OutgoingLeadReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  outgoingLeads: List<OutgoingLead>(),
  error: null,
})

export interface OutgoingLeadAction extends ReducerAction {
  companyID?: string
  outgoingLeads?: OutgoingLead[]
  outgoingLead?: OutgoingLead
}

export default (
  state: Record<OutgoingLeadReducer> = Params(),
  action: OutgoingLeadAction = { type: '' }
): Record<OutgoingLeadReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.OUTGOING_LEAD_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.OUTGOING_LEAD_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        outgoingLeads: List<OutgoingLead>(action.outgoingLeads),
      })
    case ActionTypes.OUTGOING_LEAD_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.OUTGOING_LEAD_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.OUTGOING_LEAD_CREATED:
      if (!action.outgoingLead) {
        return state
      }
      idx = state.get('outgoingLeads').findIndex((item) => !!action.outgoingLead && item.id === action.outgoingLead.id)
      if (idx !== -1) {
        return state.set('saving', false).set('outgoingLeads', state.get('outgoingLeads').set(idx, action.outgoingLead))
      }
      return state.set('saving', false).set('outgoingLeads', state.get('outgoingLeads').push(action.outgoingLead))
    case ActionTypes.OUTGOING_LEAD_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
