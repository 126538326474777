import SalaryType from '../model/salaryType'

export function getSalaryType(salaryTypes?: SalaryType[], salaryTypeID?: string): SalaryType | undefined {
  return salaryTypes?.find((type) => type.id === salaryTypeID)
}

export function isSalarySupplement(salaryType?: SalaryType): boolean {
  if (!salaryType) {
    return false
  }
  return (
    salaryType.name === 'Supplement' ||
    salaryType.name === 'Supplement No Pension' ||
    salaryType.name === 'Supplement No Vacation' ||
    salaryType.name === 'Supplement No Vacation and Pension'
  )
}

type UseSalaryType = SalaryType & {
  title: string
  displayTitle: string
}

export function listSalaryTypesWithNumberDiscriminator(salaryTypes: SalaryType[]): UseSalaryType[] {
  return salaryTypes.reduce((types: UseSalaryType[], salaryType, i, salaryTypes) => {
    const newType = { ...salaryType, title: salaryType.title ?? '', displayTitle: salaryType.title ?? '' }
    const others = salaryTypes.filter((other) => other.title === newType.title).sort((a, b) => a.id.localeCompare(b.id))
    // if there are multiple salary types with exactly the same name, we use the ID of the types to differ between them
    // adding a number, so the user knows to pick the same one again
    if (others.length > 1) {
      const idx = others.findIndex((other) => other.id === salaryType.id)
      newType.displayTitle = `${newType.displayTitle} (${idx + 1})`
    }
    types.push(newType)
    return types
  }, [])
}
