import { t } from './translation-utils'

interface Element {
  loading: boolean
  text: string
}

function formatElements(elements: Element[]) {
  let text = ''
  let i = 0
  elements.reverse().forEach((element) => {
    if (element.loading) {
      switch (i) {
        case 0:
          text = element.text
          break
        case 1:
          text = t('loading.and', { left: element.text, right: text })
          break
        default:
          text = t('loading.comma', { left: element.text, right: text })
          break
      }
      i++
    }
  })
  return text
}

export function formatLoadingText(elements: Element[]): string {
  const text = formatElements(elements)
  if (text !== '') {
    return t('loading.loading', { text })
  }
  return ''
}

export function formatSavingText(elements: Element[]): string {
  const text = formatElements(elements)
  if (text !== '') {
    return t('loading.saving', { text })
  }
  return ''
}
