import { postLookupNationalID } from '../api/employees'
import { NationalIDType } from '../model/employee'
import { DateTimeFormat } from '../model/types'
import { getDate, isValidDate } from './date-utils'
import { hasBirthDate } from './employee-utils'
import { getFieldValueSignature, setFieldErrorSignature, setFieldValueSignature } from './form-utils'
import { t } from './translation-utils'
import { getCityFromPostalCode } from './validation-utils'

export function parseNationalID(nationalIDType: NationalIDType, nationalID?: string): Date | undefined {
  if (nationalIDType !== 'DK CPR' || !nationalID) {
    return undefined
  }
  /*
  Rules for determining year:
  Løbenr.   Årstal (5. og 6. ciffer)
  Ciffer 7  00-36       37-57       58-99
  0-3       1900-1999   1900-1999   1900-1999
  4         2000-2036   1937-1999   1937-1999
  5-8       2000-2057   2000-2057   1858-1899
  9         2000-2036   1937-1999   1937-1999
   */
  const day = parseInt(nationalID.substring(0, 2))
  const month = parseInt(nationalID.substring(2, 4))
  let year = parseInt(nationalID.substring(4, 6))
  if (!day || !month || day > 31 || month > 12) {
    // invalid CPR number
    return undefined
  }
  const digit7 = parseInt(nationalID.substring(6, 7))
  if (digit7 >= 0 && digit7 <= 3) {
    year = 1900 + year
  } else if (digit7 === 4) {
    if (year <= 36) {
      year = 2000 + year
    } else {
      year = 1900 + year
    }
  } else if (digit7 >= 5 && digit7 <= 8) {
    if (year <= 57) {
      year = 2000 + year
    } else {
      year = 1800 + year
    }
  } else {
    // 9
    if (year <= 36) {
      year = 2000 + year
    } else {
      year = 1900 + year
    }
  }
  const date = new Date(year, month - 1, day) // month is 0-indexed
  if (!isValidDate(date)) {
    return undefined
  }
  return date
}

type hasLastIDVerification = {
  lastIDVerification?: DateTimeFormat
}

export function canLookupNationalID(user: hasLastIDVerification | undefined, nationalIDType: NationalIDType): boolean {
  return !!user?.lastIDVerification && (nationalIDType === 'DK CPR' || nationalIDType === 'DK Foreign')
}

type NationalIDLookupFields = {
  nationalID?: string
  nationalIDType: NationalIDType
  address?: string
  postalCode?: string
  city?: string
  name?: string
  birthDate?: Date
}

export function lookupNationalID<Fields extends NationalIDLookupFields>(
  getFieldValue: getFieldValueSignature<Fields>,
  setFieldValue: setFieldValueSignature<Fields>,
  setFieldError: setFieldErrorSignature<Fields>
) {
  const nationalID = getFieldValue('nationalID')?.replace(/[^0-9]/g, '') ?? ''
  if (nationalID.length !== 10) {
    setFieldError('nationalID', t('national_id.invalid'))
    return
  }
  postLookupNationalID(nationalID)
    .then((res) => {
      if (!res) {
        setFieldError('nationalID', t('national_id.look_up.not_found'))
        return
      }
      if (res.data.hiddenAddress) {
        setFieldError('nationalID', t('national_id.look_up.hidden_address'))
        return
      }
      setFieldValue('address', res.data.address)
      setFieldValue('postalCode', res.data.postalCode)
      if (res.data.city) {
        setFieldValue('city', res.data.city)
      } else {
        setFieldValue('city', getCityFromPostalCode(res.data.postalCode))
      }
      setFieldValue('name', res.data.name)
      if (hasBirthDate(getFieldValue('nationalIDType')) && res.data.birthDate) {
        setFieldValue('birthDate', getDate(res.data.birthDate))
      }
    })
    .catch(() => {
      setFieldError('nationalID', t('national_id.look_up.error'))
    })
}
