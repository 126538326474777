import { PricingPackageGroup } from '../model/pricingPackage'
import { getDate, isTimeBefore } from './date-utils'

export function beforeNewAutomaticPrice(): boolean {
  return isTimeBefore(getDate(), getDate('2024-03-01'))
}

export function getPrickingPackagePrice(group: PricingPackageGroup): number {
  switch (group) {
    case 'Automatic':
      if (beforeNewAutomaticPrice()) {
        return 19
      }
      return 21
    case 'Premium':
      return 39
    default:
      return 0
  }
}
