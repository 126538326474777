import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../../model/company'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import { OneTimePayPensionScheme } from '../../../model/oneTimePayPension'
import PensionCompany, { PensionCustomerIDType } from '../../../model/pensionCompany'
import SalaryCycle from '../../../model/salaryCycle'
import { DateFormat } from '../../../model/types'
import { OneTimePayPensionReducer } from '../../../reducers/oneTimePayPensions'
import PensionScheme from '../../../types/pension-scheme'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { formatPensionCustomerIDType, formatPensionDefinition, formatPensionScheme } from '../../../utils/format-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { t } from '../../../utils/translation-utils'
import Select from '../../antd/select'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import Tooltip from '../../elements/tooltip'
import OneTimePayApproved from '../../form-elements/one-time-pay/OneTimePayApproved'
import OneTimePayDispositionDate from '../../form-elements/one-time-pay/OneTimePayDispositionDate'
import OneTimePayTitle from '../../form-elements/one-time-pay/OneTimePayTitle'
import LoadingOverlay from '../../widgets/LoadingOverlay'

const NO_PENSION_COMPANY = 'NoPensionCompany'

type Props = {
  editing: boolean
  canApproveObjects: boolean
  company: Company
  employee: Employee
  oneTimePayID?: string
  oneTimePayPensions: OneTimePayPensionReducer
  salaryCycle: SalaryCycle
  pensionCompanies: List<PensionCompany>
  viewingContract: Contract
}

type Fields = {
  pensionListID?: string
  dispositionDate?: DateFormat
  title?: string
  approved: boolean
  amount?: string
  scheme?: OneTimePayPensionScheme
  pensionCompanyID?: string
  pensionCustomerID?: string
  account?: string
  sortCode?: string
  reportingBaseAmount?: string
  reportingPercentage?: string
  unionAgreementNumber?: string
}

export type OneTimePayPensionResultFields = {
  dispositionDate: DateFormat
  title: string
  approved: boolean
  amount: number
  destinationType: 'DK Account' | 'DK PBS'
  scheme: OneTimePayPensionScheme
  pensionCompanyID?: string
  pensionCustomerID?: string
  account?: string
  sortCode?: string
  reportingBaseAmount?: number
  reportingPercentage?: number
  unionAgreementNumber?: string
}

function ExtraPensionEditForm(
  props: Props & FormComponentProps<Fields, OneTimePayPensionResultFields>
): ReactElement | null {
  type State = {
    customerIDType?: PensionCustomerIDType
    hasUnionAgreementNumber: boolean
  }
  const [state, setState] = useState<State>({ hasUnionAgreementNumber: true })

  const { getFieldValue, pensionCompanies } = props

  useEffect(() => {
    if (getFieldValue('pensionCompanyID') !== NO_PENSION_COMPANY) {
      const pensionCompany = pensionCompanies.find((company) => company.id === getFieldValue('pensionCompanyID'))
      if (pensionCompany) {
        setState({
          customerIDType: pensionCompany.customerIDType,
          hasUnionAgreementNumber:
            pensionCompany.fields && pensionCompany.fields.indexOf('UnionAgreementNumber') !== -1,
        })
      }
    } else {
      setState({
        hasUnionAgreementNumber: true,
      })
    }
  }, [pensionCompanies, getFieldValue])

  const getCustomerIDType = (pensionCompanyID: string): PensionCustomerIDType | undefined => {
    let customerIDType = undefined
    if (pensionCompanyID !== NO_PENSION_COMPANY) {
      const pensionCompany = pensionCompanies.find((company) => company.id === pensionCompanyID)
      if (pensionCompany) {
        customerIDType = pensionCompany.customerIDType
      }
    }
    return customerIDType
  }

  const setPensionCompany = (pensionCompanyID: string) => {
    const { setFieldValue, getFieldValue } = props
    let customerIDType = undefined
    let hasUnionAgreementNumber = true
    if (pensionCompanyID !== NO_PENSION_COMPANY) {
      const pensionCompany = pensionCompanies.find((company) => company.id === pensionCompanyID)
      if (pensionCompany) {
        customerIDType = pensionCompany.customerIDType
        hasUnionAgreementNumber = pensionCompany.fields && pensionCompany.fields.indexOf('UnionAgreementNumber') !== -1
      }
    }
    setState({ customerIDType, hasUnionAgreementNumber })
    if (!getFieldValue('pensionCustomerID')) {
      setFieldValue('pensionCustomerID', customerIDType === 'NationalID' ? props.employee.nationalID : '')
    }
  }

  const fillInForm = (pensionListID?: string) => {
    if (!pensionListID) {
      return
    }
    const pension = props.viewingContract.remuneration?.pension.find((pension) => pension.id === pensionListID)
    if (!pension || !pension.pensionCompanyID) {
      return
    }

    const { setFieldValue } = props
    setFieldValue('pensionCompanyID', pension.pensionCompanyID)
    setPensionCompany(pension.pensionCompanyID)
    setFieldValue('pensionCustomerID', pension.pensionCustomerID)
    setFieldValue('sortCode', pension.sortCode)
    setFieldValue('account', pension.account)
    let scheme =
      pension.scheme === PensionScheme.EMPLOYEE_PAID || pension.scheme == PensionScheme.EMPLOYER_PAID
        ? pension.scheme
        : undefined
    switch (pension.scheme) {
      case PensionScheme.EMPLOYEE_PAID_NET:
        scheme = PensionScheme.EMPLOYEE_PAID
        break
      case PensionScheme.EMPLOYER_PAID_NET:
        scheme = PensionScheme.EMPLOYER_PAID
        break
      default:
        break
    }
    setFieldValue('scheme', scheme)
    setFieldValue('unionAgreementNumber', pension.unionAgreementNumber)
    if (pension.percentage && pension.percentage > 0.0) {
      setFieldValue('reportingPercentage', formatInputNumber(pension.percentage))
    }
    if (pension.fixedAmount && pension.fixedAmount > 0.0) {
      setFieldValue('reportingBaseAmount', formatInputNumber(pension.fixedAmount))
    }
  }

  const { decorateField } = props

  const customerIDType =
    state.customerIDType || getCustomerIDType(getFieldValue('pensionCompanyID') || NO_PENSION_COMPANY)
  const pensionList = props.viewingContract.remuneration?.pension.filter((pension) => pension.scheme !== 'ATP') ?? []

  return (
    <div>
      {props.getFormError()}
      {pensionList.length > 0 && !props.oneTimePayID && (
        <Row>
          <Col span={24}>
            <p>{t('extra_pension.edit.form.pension_list_id.intro', { count: pensionList.length })}</p>
            {decorateField('pensionListID', {
              placeholder: t('extra_pension.edit.form.pension_list_id'),
              skipLabel: true,
            })(
              <Select dropdownMatchSelectWidth={false} disabled={!props.editing} onChange={fillInForm}>
                {pensionList.map((row) => {
                  return (
                    <Select.Option key={row.id} value={row.id}>
                      {formatPensionDefinition(row, props.pensionCompanies.toArray())}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12}>
          <OneTimePayDispositionDate
            {...props}
            oneTimePays={props.oneTimePayPensions.oneTimePayPensions
              .map((otpPension) => otpPension.oneTimePay)
              .toArray()}
            oneTimePayID={props.oneTimePayID}
            title={t('extra_pension.edit.form.disposition_date')}
          />
        </Col>
        <Col span={12}>
          {decorateField('pensionCompanyID', {
            placeholder: t('extra_pension.edit.form.pension_company_id'),
            validate: (val) => (!val ? t('extra_pension.edit.form.pension_company_id.required') : null),
          })(
            <Select
              dropdownMatchSelectWidth={false}
              disabled={!props.editing}
              showSearch={true}
              filterOption={(inputValue: string, option: ReactElement) => {
                const children = option.props.children
                const item = typeof children === 'string' ? children : children.join('')
                return item.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
              }}
              onChange={setPensionCompany}
            >
              <Select.Option key={NO_PENSION_COMPANY} value={NO_PENSION_COMPANY}>
                {t('extra_pension.edit.form.pension_company_id.none')}
              </Select.Option>
              {props.pensionCompanies
                .filter((company) => company.active || company.id === getFieldValue('pensionCompanyID'))
                .map((company) => {
                  return (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name} ({company.paymentID})
                    </Select.Option>
                  )
                })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField(`amount`, {
            placeholder: t('extra_pension.edit.form.amount'),
            suffix: t('extra_pension.edit.form.amount.suffix'),
            validate: (val) => {
              if (!val) {
                return t('extra_pension.edit.form.amount.required')
              }
              if (!parseInputNumber(val)) {
                return t('extra_pension.edit.form.amount.invalid')
              }
              if (forceParseInputNumber(val) < 0) {
                return t('extra_pension.edit.form.amount.cannot_be_negative')
              }
              return null
            },
          })(<Input disabled={!props.editing} />)}
        </Col>
      </Row>
      <OneTimePayApproved {...props} />
      {getFieldValue('pensionCompanyID') !== NO_PENSION_COMPANY && customerIDType && (
        <Row>
          <Col span={12}>
            {decorateField(`pensionCustomerID`, {
              placeholder: formatPensionCustomerIDType(customerIDType),
              validate: (val) => {
                if (getFieldValue(`pensionCompanyID`) === NO_PENSION_COMPANY) {
                  return null
                }
                if (!val) {
                  return t('extra_pension.edit.form.pension_customer_id.required', {
                    type: formatPensionCustomerIDType(customerIDType),
                  })
                }
                return null
              },
            })(<Input disabled={!props.editing} />)}
          </Col>
          {state.hasUnionAgreementNumber && (
            <Col span={12}>
              <Tooltip title={t('extra_pension.edit.form.union_agreement_number.tooltip')}>
                {decorateField(`unionAgreementNumber`, {
                  placeholder: t('extra_pension.edit.form.union_agreement_number'),
                  validate: (val) => {
                    if (!val) {
                      return null
                    }
                    if (val.length !== 5) {
                      return t('extra_pension.edit.form.union_agreement_number.not_five_characters')
                    }
                    return null
                  },
                })(<Input disabled={!props.editing} />)}
              </Tooltip>
            </Col>
          )}
        </Row>
      )}
      {getFieldValue('pensionCompanyID') === NO_PENSION_COMPANY && (
        <Row>
          <Col span={6}>
            {decorateField(`sortCode`, {
              placeholder: t('extra_pension.edit.form.sort_code'),
              validate: (val) => {
                if (getFieldValue(`pensionCompanyID`) !== NO_PENSION_COMPANY) {
                  return null
                }
                if (!val) {
                  return t('extra_pension.edit.form.sort_code.required')
                }
                if (!val.match(/^[0-9]{4}$/)) {
                  return t('extra_pension.edit.form.sort_code.invalid')
                }
                return null
              },
            })(<Input disabled={!props.editing} />)}
          </Col>
          <Col span={6}>
            {decorateField(`account`, {
              placeholder: t('extra_pension.edit.form.account'),
              validate: (val) => {
                if (getFieldValue(`pensionCompanyID`) !== NO_PENSION_COMPANY) {
                  return null
                }
                if (!val) {
                  return t('extra_pension.edit.form.account.required')
                }
                if (!val.match(/^[0-9]{6,10}$/)) {
                  return t('extra_pension.edit.form.account.invalid')
                }
                return null
              },
            })(<Input disabled={!props.editing} />)}
          </Col>
        </Row>
      )}
      {getFieldValue('pensionCompanyID') !== NO_PENSION_COMPANY && (
        <Row>
          <Col>
            <p>{t('extra_pension.edit.form.reporting_base_amount.note')}</p>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12}>
          {decorateField(`scheme`, {
            placeholder: t('extra_pension.edit.form.scheme'),
            validate: (val) => (!val ? t('extra_pension.edit.form.scheme.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false} disabled={!props.editing}>
              <Select.Option value={PensionScheme.EMPLOYEE_PAID}>
                {formatPensionScheme(PensionScheme.EMPLOYEE_PAID)}
              </Select.Option>
              <Select.Option value={PensionScheme.EMPLOYER_PAID}>
                {formatPensionScheme(PensionScheme.EMPLOYER_PAID)}
              </Select.Option>
            </Select>
          )}
        </Col>
        {getFieldValue('pensionCompanyID') !== NO_PENSION_COMPANY && (
          <Col span={7}>
            {decorateField(`reportingBaseAmount`, {
              placeholder: t('extra_pension.edit.form.reporting_base_amount'),
              suffix: t('extra_pension.edit.form.reporting_base_amount.suffix'),
              validate: (val) => {
                if (getFieldValue('pensionCompanyID') === NO_PENSION_COMPANY) {
                  return null
                }
                if (!val) {
                  return t('extra_pension.edit.form.reporting_base_amount.required')
                }
                const num = parseInputNumber(val, { trim: true })
                if (typeof num !== 'number') {
                  return t('extra_pension.edit.form.reporting_base_amount.invalid')
                }
                if (num > 9_999_999_999.99) {
                  return t('extra_pension.edit.form.reporting_base_amount.max_amount')
                }
                return null
              },
            })(<Input disabled={!props.editing} />)}
          </Col>
        )}
        {getFieldValue('pensionCompanyID') !== NO_PENSION_COMPANY && (
          <Col span={5}>
            {decorateField(`reportingPercentage`, {
              placeholder: t('extra_pension.edit.form.reporting_percentage'),
              suffix: t('extra_pension.edit.form.reporting_percentage.suffix'),
              validate: (val) => {
                if (getFieldValue('pensionCompanyID') === NO_PENSION_COMPANY) {
                  return null
                }
                if (!val) {
                  return t('extra_pension.edit.form.reporting_percentage.required')
                }
                const num = parseInputNumber(val, { trim: true })
                if (typeof num !== 'number') {
                  return t('extra_pension.edit.form.reporting_percentage.invalid')
                }
                if (num > 99.99) {
                  return t('extra_pension.edit.form.reporting_percentage.max_amount')
                }
                return null
              },
            })(<Input disabled={!props.editing} />)}
          </Col>
        )}
      </Row>
      {getFieldValue('scheme') !== PensionScheme.EMPLOYER_PAID && (
        <Row>
          <Col span={24}>
            <OneTimePayTitle {...props} />
          </Col>
        </Row>
      )}
      {props.editing && (
        <Row>
          <Col span={24}>
            <Button htmlType="submit" size="large" type="secondary">
              {t('form.button.save_changes')}
            </Button>
          </Col>
        </Row>
      )}
      {props.oneTimePayPensions.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, OneTimePayPensionResultFields>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      pensionCompanyID: NO_PENSION_COMPANY,
      approved:
        !props.company.settingsEnabled.some((setting) => setting === 'AskForOTPDraftState') && props.canApproveObjects,
    }
    const oneTimePayPension = props.oneTimePayPensions.oneTimePayPensions.find(
      (oneTimePayPension) => oneTimePayPension.oneTimePay.id === props.oneTimePayID
    )
    if (oneTimePayPension) {
      const oneTimePay = oneTimePayPension.oneTimePay
      fields.dispositionDate = oneTimePay.dispositionDate
      fields.title = oneTimePay.title
      fields.approved = oneTimePay.approved
      fields.amount = formatInputNumber(oneTimePay.amount)
      fields.pensionCompanyID = oneTimePayPension.pensionCompanyID || NO_PENSION_COMPANY
      fields.pensionCustomerID = oneTimePayPension.pensionCustomerID
      fields.account = oneTimePayPension.account
      fields.sortCode = oneTimePayPension.sortCode
      fields.scheme = oneTimePayPension.scheme
      fields.reportingBaseAmount = formatInputNumber(oneTimePayPension.reportingBaseAmount)
      fields.reportingPercentage = formatInputNumber(oneTimePayPension.reportingPercentage)
      fields.unionAgreementNumber = oneTimePayPension.unionAgreementNumber
    }
    return fields
  },
  onChange: (key, val, allValues) => {
    const values = {}
    switch (key) {
      case 'reportingBaseAmount': {
        setByPath(values, key, formatInputNumber(parseInputNumber(val as string)))
        const num = forceParseInputNumber(val as string)
        const amount = forceParseInputNumber(allValues.amount)
        if (num > 0 && amount > 0) {
          setByPath(values, 'reportingPercentage', formatInputNumber(((amount / num) * 100).toFixed(2)))
        }
        break
      }
      case 'reportingPercentage': {
        setByPath(values, key, formatInputNumber(parseInputNumber(val as string)))
        const num = forceParseInputNumber(val as string)
        const amount = forceParseInputNumber(allValues.amount)
        if (num > 0 && amount > 0) {
          setByPath(values, 'reportingBaseAmount', formatInputNumber(((amount / num) * 100).toFixed(2)))
        }
        break
      }
      case 'amount': {
        setByPath(values, key, formatInputNumber(parseInputNumber(val as string)))
        const num = forceParseInputNumber(val as string)
        const percentage = forceParseInputNumber(allValues.reportingPercentage)
        if (percentage > 0 && num > 0) {
          setByPath(values, 'reportingBaseAmount', formatInputNumber(((num / percentage) * 100).toFixed(2)))
        } else {
          const baseAmount = forceParseInputNumber(allValues.reportingBaseAmount)
          if (baseAmount > 0 && num > 0) {
            setByPath(values, 'reportingPercentage', formatInputNumber(((num / baseAmount) * 100).toFixed(2)))
          }
        }
        break
      }
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    const result: OneTimePayPensionResultFields = {
      dispositionDate: values.dispositionDate!,
      approved: values.approved,
      amount: forceParseInputNumber(values.amount),
      destinationType: 'DK Account',
      title: values.title ? values.title : ' ',
      scheme: values.scheme!,
      unionAgreementNumber:
        values.unionAgreementNumber && values.unionAgreementNumber.trim() !== ''
          ? values.unionAgreementNumber
          : undefined,
    }
    if (!values.pensionCompanyID || values.pensionCompanyID === NO_PENSION_COMPANY) {
      result.destinationType = 'DK Account'
      result.account = values.account
      result.sortCode = values.sortCode
    } else {
      result.destinationType = 'DK PBS'
      result.pensionCompanyID = values.pensionCompanyID
      result.pensionCustomerID = values.pensionCustomerID
      result.reportingBaseAmount = forceParseInputNumber(values.reportingBaseAmount)
      result.reportingPercentage = forceParseInputNumber(values.reportingPercentage)
    }
    return result
  },
})(ExtraPensionEditForm)
