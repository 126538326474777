import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CompanyIntegration from '../model/companyIntegration'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyIntegrationReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  companyIntegrations: List<CompanyIntegration>
  error: Error | null
}

const Params = Record<CompanyIntegrationReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  companyIntegrations: List<CompanyIntegration>(),
  error: null,
})

export interface CompanyIntegrationAction extends ReducerAction {
  companyID?: string
  companyIntegrations?: CompanyIntegration[]
  companyIntegration?: CompanyIntegration
  companyIntegrationID?: string
}

export default (
  state: Record<CompanyIntegrationReducer> = Params(),
  action: CompanyIntegrationAction = { type: '' }
): Record<CompanyIntegrationReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.COMPANY_INTEGRATION_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.COMPANY_INTEGRATION_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        companyIntegrations: List<CompanyIntegration>(action.companyIntegrations),
      })
    case ActionTypes.COMPANY_INTEGRATION_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.COMPANY_INTEGRATION_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_INTEGRATION_CREATED:
      if (!action.companyIntegration) {
        return state
      }
      idx = state
        .get('companyIntegrations')
        .findIndex((item) => !!action.companyIntegration && item.id === action.companyIntegration.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('companyIntegrations', state.get('companyIntegrations').set(idx, action.companyIntegration))
      }
      return state
        .set('saving', false)
        .set('companyIntegrations', state.get('companyIntegrations').push(action.companyIntegration))
    case ActionTypes.COMPANY_INTEGRATION_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COMPANY_INTEGRATION_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_INTEGRATION_DELETED:
      idx = state.get('companyIntegrations').findIndex((item) => item.id === action.companyIntegrationID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('companyIntegrations', state.get('companyIntegrations').delete(idx))
    case ActionTypes.COMPANY_INTEGRATION_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
