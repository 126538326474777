import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import Employee from '../../model/employee'
import IncomeTaxReport, { IncomeTaxReportEmployee } from '../../model/incomeTaxReport'
import PayRoll from '../../model/payRoll'
import { t, tx } from '../../utils/translation-utils'
import { normalizeNationalID } from '../../utils/validation-utils'
import Modal from '../antd/modal'
import Table from '../antd/table'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'
import Tooltip from '../elements/tooltip'
import DumbLink from '../widgets/DumbLink'
import TaxReportModal from './TaxReportModal'

type Props = {
  payRoll: PayRoll
  employeesNumberMap: Record<string, Employee>
  incomeTaxReports: List<IncomeTaxReport>
}

export default function TaxReportsCard(props: Props): ReactElement | null {
  const [expanded, setExpanded] = useState(false)
  const [showDetails, setShowDetails] = useState<string | boolean>(false)
  const [taxReport, setTaxReport] = useState<IncomeTaxReportEmployee | undefined>(undefined)
  const [employee, setEmployee] = useState<Employee | undefined>(undefined)

  const toggleExpanded = () => setExpanded((prev) => !prev)
  const setDetailsVisibility = (
    employeeNumber: boolean | string,
    taxReport?: IncomeTaxReportEmployee,
    employee?: Employee
  ) => {
    setShowDetails(employeeNumber)
    setTaxReport(taxReport)
    setEmployee(employee)
  }

  interface TaxReportRow {
    key: string
    id: string
    name: string
    cprNumber: string
    taxReport: IncomeTaxReportEmployee
    employee?: Employee
  }

  const columns = [
    { title: t('pay_roll.single.tax_reports.header.name'), dataIndex: 'name', key: 'name' },
    { title: t('pay_roll.single.tax_reports.header.national_id'), dataIndex: 'cprNumber', key: 'cprNumber' },
    {
      title: '',
      dataIndex: '',
      key: 'x4',
      className: 'pay-roll-table-actions',
      render: (taxReport: TaxReportRow) => (
        <DumbLink
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            setDetailsVisibility(taxReport.id, taxReport.taxReport, taxReport.employee)
          }}
        >
          <Tooltip placement="top" title={t('pay_roll.single.tax_reports.actions.show_details')}>
            <span className="pay-roll-icon-file" />
          </Tooltip>
        </DumbLink>
      ),
    },
  ]

  const getTaxReports = (): TaxReportRow[] => {
    const taxReport = props.incomeTaxReports.first()
    if (!taxReport) {
      return []
    }
    return taxReport.employees.map((taxReport) => {
      const cprNumber = taxReport.cpr || taxReport.cvr || '-'
      const combinedTaxReport: TaxReportRow = {
        key: `${taxReport.employeeNumber || cprNumber}-${taxReport.periodStart}-${taxReport.periodEnd}`,
        id: taxReport.employeeNumber,
        cprNumber,
        taxReport,
        name: '-',
      }
      const employee = props.employeesNumberMap[taxReport.employeeNumber]
      if (employee) {
        combinedTaxReport.name = employee.name || employee.email || '-'
        combinedTaxReport.cprNumber = employee.nationalID
          ? normalizeNationalID(employee.nationalID, employee.nationalIDType)
          : '-'
        combinedTaxReport.employee = employee
      }
      return combinedTaxReport
    })
  }

  const taxReports = getTaxReports()
  return (
    <Card>
      <Row>
        <Col span={6}>
          <Subtitle>{t('pay_roll.single.tax_reports.title')}</Subtitle>
        </Col>
        <Col span={12}>
          {tx('pay_roll.single.tax_reports.automatic_via', {
            entity: <strong>{t('pay_roll.single.tax_reports.automatic_via.entity')}</strong>,
          })}
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <span onClick={toggleExpanded} className={'pay-roll-toggle' + (expanded ? ' pay-roll-toggle-visible' : '')}>
            {expanded ? t('pay_roll.single.tax_reports.toggle.hide') : t('pay_roll.single.tax_reports.toggle.show')}
          </span>
        </Col>
      </Row>

      <div className={'pay-roll-card-container' + (expanded ? ' pay-roll-card-container-visible' : '')}>
        <Table
          columns={columns}
          dataSource={taxReports}
          size="small"
          pagination={taxReports.length > 100 ? { defaultPageSize: 100 } : false}
          className={'pay-roll-table pay-roll-table-small'}
        />
      </div>

      <Modal
        visible={!!showDetails}
        onOk={() => setDetailsVisibility(false)}
        onCancel={() => setDetailsVisibility(false)}
        width={582}
        footer={null}
      >
        <TaxReportModal
          visible={!!showDetails}
          employeeNumber={typeof showDetails !== 'boolean' ? showDetails : undefined}
          taxReport={taxReport}
          employee={employee}
        />
      </Modal>
    </Card>
  )
}
