import React, { CSSProperties, ReactElement } from 'react'

import './Elements.css'

// NEED_NO_TRANSLATION

type Props = {
  src?: string
  company?: string
  name?: string
  size?: string
  style?: CSSProperties
}

export default function UserImage(props: Props): ReactElement {
  const style = { ...props.style }
  const { size = 'medium' } = props
  let name = ''
  if (props.src) {
    style.backgroundImage = 'url(' + props.src + ')'
  } else if (props.company) {
    const names = props.company.split(' ')
    name += names[0].substring(0, 1)
    if (names.length > 1) {
      name += names[1].substring(0, 1)
    }
    name = name.toUpperCase()
  } else if (props.name) {
    const names = props.name.split(' ')
    name += names[0].substring(0, 1)
    if (names.length > 1) {
      name += (names.pop() || ' ').substring(0, 1)
    }
    name = name.toUpperCase()
  }
  return (
    <span className={'user-image user-image-' + size} style={style}>
      {name}
    </span>
  )
}
