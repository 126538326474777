import React from 'react'

import {
  delCompanyDataIntegration,
  fetchCompanyDataIntegration,
  putCompanyDataIntegration,
} from '../api/data-integration'
import ActionTypes from '../constants/action-types'
import DataIntegration from '../model/dataIntegration'
import { DataIntegrationAction } from '../reducers/companyDataIntegration'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingCompanyDataIntegration(companyID: string): DataIntegrationAction {
  return {
    type: ActionTypes.DATA_INTEGRATION_LOADING,
    companyID,
  }
}
function loadedCompanyDataIntegration(
  companyID: string,
  integration: DataIntegration | undefined
): DataIntegrationAction {
  return {
    type: ActionTypes.DATA_INTEGRATION_LOADED,
    companyID,
    integration,
  }
}
function failedLoadingCompanyDataIntegration(companyID: string, error: Error): DataIntegrationAction {
  return {
    type: ActionTypes.DATA_INTEGRATION_LOAD_FAILED,
    error,
    companyID,
  }
}

function updatingCompanyDataIntegration(companyID: string): DataIntegrationAction {
  return {
    type: ActionTypes.DATA_INTEGRATION_UPDATING,
    companyID,
  }
}
export function updatedCompanyDataIntegration(companyID: string, integration: DataIntegration): DataIntegrationAction {
  return {
    type: ActionTypes.DATA_INTEGRATION_UPDATED,
    companyID,
    integration,
  }
}
function failedUpdatingCompanyDataIntegration(companyID: string, error: Error): DataIntegrationAction {
  return {
    type: ActionTypes.DATA_INTEGRATION_UPDATE_FAILED,
    error,
    companyID,
  }
}

function deletingCompanyDataIntegration(companyID: string): DataIntegrationAction {
  return {
    type: ActionTypes.DATA_INTEGRATION_DELETING,
    companyID,
  }
}
export function deletedCompanyDataIntegration(companyID: string): DataIntegrationAction {
  return {
    type: ActionTypes.DATA_INTEGRATION_DELETED,
    companyID,
  }
}
function failedDeletingCompanyDataIntegration(companyID: string, error: Error): DataIntegrationAction {
  return {
    type: ActionTypes.DATA_INTEGRATION_DELETE_FAILED,
    error,
    companyID,
  }
}

export function getCompanyDataIntegration() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<DataIntegration | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingCompanyDataIntegration(companyID))
    return fetchCompanyDataIntegration(companyID)
      .then((res) => {
        dispatch(loadedCompanyDataIntegration(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          if (e.type === 'NotFound') {
            dispatch(loadedCompanyDataIntegration(companyID, undefined))
          } else {
            dispatch(failedLoadingCompanyDataIntegration(companyID, e))
          }
        }
      })
  }
}

export function updateCompanyDataIntegration(state: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<DataIntegration | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingCompanyDataIntegration(companyID))
    return putCompanyDataIntegration(state)
      .then((res) => {
        setTimeout(() => {
          dispatch(updatedCompanyDataIntegration(companyID, res.data))
        }, 500)
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompanyDataIntegration(companyID, e))
        }
      })
  }
}

export function deleteCompanyDataIntegration() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingCompanyDataIntegration(companyID))
    return delCompanyDataIntegration(companyID)
      .then(() => {
        dispatch(deletedCompanyDataIntegration(companyID))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingCompanyDataIntegration(companyID, e))
        }
      })
  }
}
