import React, { ReactElement, useEffect } from 'react'

import { getCompanyAccountingIntegration, selectCompanyAccountingDaybook } from '../actions/accounting-integration'
import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import AccountingDaybookComponent from '../components/accounting/AccountingDaybooks'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { CompanyAccountingIntegrationReducer } from '../reducers/companyAccountingIntegration'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import IntegrationsLayout from './layouts/IntegrationsLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  companyAccountingIntegration: CompanyAccountingIntegrationReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  selectCompanyAccountingDaybook: () => void
  getCompanyAccountingIntegration: () => void
}

function AccountingDaybooks(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { companyAccountingIntegration, getCompanyAccountingIntegration } = props

  useEffect(() => {
    if (!companyAccountingIntegration.loaded && !companyAccountingIntegration.loading) {
      getCompanyAccountingIntegration()
    }
  }, [companyAccountingIntegration, getCompanyAccountingIntegration])

  const company = props.companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  if (!companyAccountingIntegration.loaded) {
    return <LoadingOverlay />
  }

  return (
    <IntegrationsLayout location={props.location}>
      <AccountingDaybookComponent
        alerts={props.alerts}
        company={company}
        companyAccountingIntegration={props.companyAccountingIntegration}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        selectCompanyAccountingDaybook={props.selectCompanyAccountingDaybook}
      />
    </IntegrationsLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    companyAccountingIntegration: state.companyAccountingIntegration,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    selectCompanyAccountingDaybook: selectCompanyAccountingDaybook,
    getCompanyAccountingIntegration: getCompanyAccountingIntegration,
  }
)(AccountingDaybooks)
