import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { AccountingAccount, CostCenterAccounting } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Employee from '../../../model/employee'
import { OneTimePayCategory, OneTimePayType } from '../../../model/oneTimePay'
import SalaryCycle from '../../../model/salaryCycle'
import { DateFormat } from '../../../model/types'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { oneTimePayCategoriesByType } from '../../../utils/employee-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { formatOneTimePayCategory, formatOneTimePayType } from '../../../utils/format-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { t } from '../../../utils/translation-utils'
import Select from '../../antd/select'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import Switch from '../../elements/switch'
import OneTimePayAccountingText from '../../form-elements/one-time-pay/OneTimePayAccountingText'
import OneTimePayApproved from '../../form-elements/one-time-pay/OneTimePayApproved'
import OneTimePayCostCenter from '../../form-elements/one-time-pay/OneTimePayCostCenter'
import OneTimePayDebitAccount from '../../form-elements/one-time-pay/OneTimePayDebitAccount'
import OneTimePayDispositionDate from '../../form-elements/one-time-pay/OneTimePayDispositionDate'
import OneTimePayTitle from '../../form-elements/one-time-pay/OneTimePayTitle'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  editing: boolean
  canApproveObjects: boolean
  employee: Employee
  company: Company
  oneTimePayID?: string
  oneTimePays: OneTimePayReducer
  salaryCycle: SalaryCycle
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>
  accounts: List<AccountingAccount>
}

type Fields = {
  dispositionDate?: DateFormat
  type: OneTimePayType
  amount?: string
  title?: string
  approved: boolean
  category?: OneTimePayCategory
  costCenterID?: string
  accountingText?: string
  withoutPayment: boolean
  debitCompanyAccountingAccountID?: string
}

export type FeeResult = {
  dispositionDate: DateFormat
  type: OneTimePayType
  amount: number
  title: string
  approved: boolean
  category?: OneTimePayCategory
  costCenterID?: string
  accountingText?: string
  withPayment: boolean
  debitCompanyAccountingAccountID?: string
}

function FeeEditForm(props: Props & FormComponentProps<Fields, FeeResult>): ReactElement | null {
  const { decorateField, getFieldValue } = props

  const types: OneTimePayType[] = ['Compensation', 'Honorary Gift', 'Compensation No AM']
  const categories: OneTimePayCategory[] = ['DKArt-0000']
  const extraCategories = oneTimePayCategoriesByType(props.getFieldValue('type'))
  const hasCategories = extraCategories.length > 0
  categories.push(...extraCategories)

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          <OneTimePayDispositionDate
            {...props}
            oneTimePayID={props.oneTimePayID}
            oneTimePays={props.oneTimePays.oneTimePays.toArray()}
          />
        </Col>
        <Col span={12}>
          {decorateField('amount', {
            title: t('freelancer.fee.edit.amount'),
            placeholder: t('freelancer.fee.edit.amount.placeholder'),
            validate: (val) => (!val ? t('freelancer.fee.edit.amount.required') : null),
            suffix: t('freelancer.fee.edit.amount.suffix'),
          })(<Input disabled={!props.editing} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('type', {
            placeholder: t('freelancer.fee.edit.type'),
            validate: (val) => (!val ? t('freelancer.fee.edit.type.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false} disabled={!props.editing}>
              {types.map((type) => {
                return (
                  <Select.Option key={type} value={type}>
                    {formatOneTimePayType(type)}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <OneTimePayApproved {...props} />
      {hasCategories && (
        <Row>
          <Col span={24}>
            {decorateField('category', {
              placeholder: t('freelancer.fee.edit.category'),
            })(
              <Select dropdownMatchSelectWidth={false} disabled={!props.editing}>
                {categories.map((category) => {
                  return (
                    <Select.Option key={category} value={category}>
                      {formatOneTimePayCategory(category)}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Col>
        </Row>
      )}
      <OneTimePayCostCenter {...props} />
      <Row>
        <Col span={24}>
          <OneTimePayTitle {...props} />
        </Col>
      </Row>
      <OneTimePayAccountingText {...props} />
      {getFieldValue('type') === 'Compensation' && <OneTimePayDebitAccount {...props} />}
      {getFieldValue('type') === 'Compensation' && getFieldValue('category') === 'DKArt-0000' && (
        <Row>
          <Col span={24}>
            {decorateField('withoutPayment', {
              title: t('freelancer.fee.edit.without_payment'),
              valueOnChecked: true,
              noBlur: true,
            })(
              <Switch
                disabled={!props.editing}
                checkedChildren={t('freelancer.fee.edit.without_payment.checked')}
                unCheckedChildren={t('freelancer.fee.edit.without_payment.unchecked')}
              />
            )}
          </Col>
        </Row>
      )}
      {props.editing && (
        <Row>
          <Col span={24}>
            <Button htmlType="submit" size="large" type="secondary">
              {t('form.button.save_changes')}
            </Button>
          </Col>
        </Row>
      )}
      {props.oneTimePays.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, FeeResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      withoutPayment: false,
      type: 'Compensation',
      category: 'DKArt-0000',
      approved:
        !props.company.settingsEnabled.some((setting) => setting === 'AskForOTPDraftState') && props.canApproveObjects,
    }
    if (props.oneTimePayID) {
      const oneTimePay = props.oneTimePays.oneTimePays.find((oneTimePay) => oneTimePay.id === props.oneTimePayID)
      if (oneTimePay) {
        fields.dispositionDate = oneTimePay.dispositionDate
        fields.amount = formatInputNumber(oneTimePay.amount)
        fields.type = oneTimePay.type
        fields.category = oneTimePay.category
        fields.approved = oneTimePay.approved
        if (oneTimePay.payOutType === 'Manual') {
          fields.withoutPayment = true
        }
        fields.title = oneTimePay.title
        fields.costCenterID = oneTimePay.costCenterID
        fields.accountingText = oneTimePay.accountingText
        fields.debitCompanyAccountingAccountID = oneTimePay.debitCompanyAccountingAccountID
      }
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values: Partial<Fields> = {}
    switch (key) {
      case 'amount':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        )
        break
      case 'type':
        values.category = 'DKArt-0000'
        setByPath(values, key, val)
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => ({
    ...values,
    dispositionDate: values.dispositionDate!,
    amount: forceParseInputNumber(values.amount),
    title: values.title!,
    withPayment: !values.withoutPayment,
  }),
})(FeeEditForm)
