import React from 'react'

import { delCostCenter, fetchCostCenters, postCostCenter, putCostCenter } from '../api/cost-centers'
import ActionTypes from '../constants/action-types'
import CostCenter from '../model/costCenter'
import { CostCenterAction } from '../reducers/costCenters'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingCostCenters(): CostCenterAction {
  return {
    type: ActionTypes.COSTCENTERS_LOADING,
  }
}
export function loadedCostCenters(companyID: string, costCenters: CostCenter[]): CostCenterAction {
  return {
    type: ActionTypes.COSTCENTERS_LOADED,
    costCenters,
    companyID,
  }
}
function failedLoadingCostCenters(companyID: string, error: Error): CostCenterAction {
  return {
    type: ActionTypes.COSTCENTERS_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingCostCenter(): CostCenterAction {
  return {
    type: ActionTypes.COSTCENTER_ADDING,
  }
}
export function addedCostCenter(companyID: string, costCenter: CostCenter): CostCenterAction {
  return {
    type: ActionTypes.COSTCENTER_ADDED,
    companyID,
    costCenter,
  }
}
function failedAddingCostCenter(companyID: string, error: Error): CostCenterAction {
  return {
    type: ActionTypes.COSTCENTER_ADD_FAILED,
    error,
    companyID,
  }
}

function updatingCostCenter(companyID: string, costCenterID: string): CostCenterAction {
  return {
    type: ActionTypes.COSTCENTER_UPDATING,
    companyID,
    costCenterID,
  }
}
export function updatedCostCenter(companyID: string, costCenterID: string, costCenter: CostCenter): CostCenterAction {
  return {
    type: ActionTypes.COSTCENTER_UPDATED,
    companyID,
    costCenterID,
    costCenter,
  }
}
function failedUpdatingCostCenter(companyID: string, costCenterID: string, error: Error): CostCenterAction {
  return {
    type: ActionTypes.COSTCENTER_UPDATE_FAILED,
    error,
    companyID,
    costCenterID,
  }
}

function deletingCostCenter(companyID: string, costCenterID: string): CostCenterAction {
  return {
    type: ActionTypes.COSTCENTER_DELETING,
    companyID,
    costCenterID,
  }
}
export function deletedCostCenter(companyID: string, costCenterID: string): CostCenterAction {
  return {
    type: ActionTypes.COSTCENTER_DELETED,
    companyID,
    costCenterID,
  }
}
function failedDeletingCostCenter(companyID: string, costCenterID: string, error: Error): CostCenterAction {
  return {
    type: ActionTypes.COSTCENTER_DELETE_FAILED,
    error,
    companyID,
    costCenterID,
  }
}

export function addCostCenter(costCenter: CostCenter) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<CostCenter | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingCostCenter())
    return postCostCenter(companyID, costCenter)
      .then((res) => {
        dispatch(addedCostCenter(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingCostCenter(companyID, e))
        }
      })
  }
}

export function getCostCenters() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<CostCenter[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingCostCenters())
    return fetchCostCenters(companyID)
      .then((res) => {
        dispatch(loadedCostCenters(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCostCenters(companyID, e))
        }
      })
  }
}

export function updateCostCenter(costCenter: CostCenter) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<CostCenter | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingCostCenter(companyID, costCenter.id))
    return putCostCenter(companyID, costCenter)
      .then((res) => {
        dispatch(updatedCostCenter(companyID, res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCostCenter(companyID, costCenter.id, e))
        }
      })
  }
}

export function deleteCostCenter(costCenterID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingCostCenter(companyID, costCenterID))
    return delCostCenter(costCenterID)
      .then(() => {
        dispatch(deletedCostCenter(companyID, costCenterID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingCostCenter(companyID, costCenterID, e))
        }
      })
  }
}
