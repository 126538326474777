import BankAccount from '../model/bankAccount'
import PaymentConfiguration, { TransferSetting } from '../model/paymentConfiguration'
import StripeConfiguration, { StripeConfigurationSetup } from '../model/stripeConfiguration'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchPaymentConfiguration(companyID: string): Promise<RequestResponse<PaymentConfiguration[]>> {
  return secureRequest('GET', url('/v2/paymentConfigurations', { companyID }))
}

export function patchPaymentConfiguration(
  companyID: string,
  transferSettings: TransferSetting[]
): Promise<RequestResponse<PaymentConfiguration>> {
  return secureRequest('PATCH', url('/v2/paymentConfigurations/' + companyID), {
    body: transferSettings.map((setting) => ({
      integration: setting.integration,
      type: setting.type,
    })),
  })
}

export function fetchPaymentConfigurationStripe(companyID: string): Promise<RequestResponse<StripeConfiguration>> {
  return secureRequest('GET', url('/v2/paymentConfigurations/stripe', { companyID }))
}

export function postPaymentConfigurationStripe(companyID: string): Promise<RequestResponse<StripeConfigurationSetup>> {
  return secureRequest('POST', url('/v2/paymentConfigurations/stripe', { companyID }))
}

export function patchPaymentConfigurationStripe(
  companyID: string,
  setupIntentID: string
): Promise<RequestResponse<StripeConfiguration>> {
  return secureRequest('PATCH', url('/v2/paymentConfigurations/stripe', { companyID }), {
    body: { setupIntentID },
  })
}

export function delPaymentConfigurationStripe(companyID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/paymentConfigurations/stripe', { companyID }))
}

export function postCompanyBankAccount(companyID: string, bankAccount: BankAccount): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/companyBankAccounts/' + companyID), {
    body: {
      contactName: bankAccount.contactName,
      contactPhone: bankAccount.contactPhone,
      contactEmail: bankAccount.contactEmail,
      bankRegistrationNumber: bankAccount.bankRegistrationNumber,
      bankAccountNumber: bankAccount.bankAccountNumber,
    },
  })
}
