import omit from 'omit.js'
import React, { CSSProperties, ReactElement, useState } from 'react'

import classNames from '../../antd/_util/classNames'
import calculateNodeHeight from './calculateNodeHeight'

// NEED_NO_TRANSLATION

type Props = {
  rows?: number
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  disabled?: boolean
  className?: string
  style?: CSSProperties
  value?: string
  defaultValue?: string
  autosize?: { minRows: number; maxRows: number }
  onPressEnter?: (e: React.KeyboardEvent) => void
  onKeyDown?: (e: React.KeyboardEvent) => void
}

export default function TextArea(props: Props): ReactElement | null {
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)
  const [textareaStyles, setTextareaStyles] = useState<CSSProperties>({})
  const prefixCls = 'ant-input'

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { onPressEnter, onKeyDown } = props
    if (e.key === 'Enter' && onPressEnter) {
      onPressEnter(e)
    }
    if (onKeyDown) {
      onKeyDown(e)
    }
  }

  const resizeTextarea = () => {
    const { autosize } = props
    if (!autosize || !textAreaRef.current) {
      return
    }
    const minRows = autosize ? autosize.minRows : undefined
    const maxRows = autosize ? autosize.maxRows : undefined
    const textareaStyles = calculateNodeHeight(textAreaRef.current, false, minRows, maxRows)
    setTextareaStyles(textareaStyles)
  }

  const getTextAreaClassName = () => {
    const { className, disabled } = props
    return classNames(prefixCls, className, {
      [`${prefixCls}-disabled`]: disabled,
    })
  }

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!('value' in props)) {
      resizeTextarea()
    }
    const { onChange } = props
    if (onChange) {
      onChange(e)
    }
  }

  const otherProps = omit(props, ['value', 'onChange', 'autosize', 'onPressEnter', 'onKeyDown'])
  return (
    <textarea
      {...otherProps}
      onKeyDown={handleKeyDown}
      value={props.value ? props.value : undefined}
      className={getTextAreaClassName()}
      style={{ ...props.style, ...textareaStyles }}
      onChange={handleTextareaChange}
      ref={textAreaRef}
    />
  )
}
