import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { UserReducer } from '../../reducers/user'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'
import RequestPasswordForm, { RequestPasswordResult } from './RequestPasswordForm'

import './RequestPassword.css'

type Props = {
  visible: boolean
  user: UserReducer

  requestPassword: (email: string) => Promise<boolean | void>
}

export default function RequestPassword(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [completed, setCompleted] = useState(false)

  const { visible } = props
  const previousVisible = usePrevious(visible)
  useEffect(() => {
    if (previousVisible !== undefined && !previousVisible && visible) {
      setCompleted(false)
    }
  }, [previousVisible, visible])

  const { user } = props
  useEffect(() => {
    regularComponentDidUpdate(user.error, error, setError)
  }, [user, error])

  const handleSubmit = (values: RequestPasswordResult) => {
    props.requestPassword(values.email).then(() => {
      setCompleted(true)
    })
  }

  if (completed) {
    return <div className="request-password request-password-completed">{t('password_request.completed')}</div>
  }
  return (
    <div className="request-password">
      <Subtitle>{t('password_request.title')}</Subtitle>
      <p>{t('password_request.intro')}</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <RequestPasswordForm onSubmit={handleSubmit} />
      {props.user.requestingPassword && <LoadingOverlay />}
    </div>
  )
}
