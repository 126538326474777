import SalaryRegistration, { SalaryRegistrationMutableFields } from '../model/salaryRegistration'
import { DateFormat, SettledState } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchSalaryRegistrations(
  companyID?: string,
  employeeID?: string,
  payRollID?: string,
  fromDate?: DateFormat,
  toDate?: DateFormat,
  stateFilter?: SettledState,
  limit = 1000,
  offset = 0
): Promise<RequestResponse<SalaryRegistration[]>> {
  return secureRequest(
    'GET',
    url('/v2/salaryRegistrations', {
      limit,
      offset,
      companyID,
      employeeID,
      payRollID,
      from: fromDate,
      to: toDate,
      stateFilter,
    })
  )
}

export function postSalaryRegistration(
  salaryRegistration: SalaryRegistrationMutableFields
): Promise<RequestResponse<SalaryRegistration>> {
  return secureRequest('POST', url('/v2/salaryRegistrations'), {
    body: {
      employeeID: salaryRegistration.employeeID,
      date: salaryRegistration.date,
      salaryTypeID: salaryRegistration.salaryTypeID,
      quantity: salaryRegistration.quantity,
      costCenterID: salaryRegistration.costCenterID,
      note: salaryRegistration.note,
      approved: salaryRegistration.approved,
    },
  })
}

export function postSalaryRegistrationBulk(
  salaryRegistrations: SalaryRegistration[]
): Promise<RequestResponse<SalaryRegistration[]>> {
  return secureRequest('POST', url('/v2/salaryRegistrations/bulk'), {
    body: salaryRegistrations.map((reg) => ({
      employeeID: reg.employeeID,
      date: reg.date,
      salaryTypeID: reg.salaryTypeID,
      quantity: reg.quantity,
      costCenterID: reg.costCenterID,
      note: reg.note,
    })),
  })
}

export function putSalaryRegistration(
  salaryRegistrationID: string,
  salaryRegistration: SalaryRegistrationMutableFields
): Promise<RequestResponse<SalaryRegistration>> {
  return secureRequest('PUT', url('/v2/salaryRegistrations/' + salaryRegistrationID), {
    body: {
      employeeID: salaryRegistration.employeeID,
      date: salaryRegistration.date,
      salaryTypeID: salaryRegistration.salaryTypeID,
      quantity: salaryRegistration.quantity,
      costCenterID: salaryRegistration.costCenterID,
      note: salaryRegistration.note,
    },
  })
}

export function patchSalaryRegistrationApprove(
  action: 'Approve' | 'Unapprove',
  ids: string[]
): Promise<RequestResponse<SalaryRegistration[]>> {
  return secureRequest('PATCH', url('/v2/salaryRegistrations'), {
    body: {
      action,
      salaryRegistrationIDs: ids,
    },
  })
}

export function delSalaryRegistration(salaryRegistrationID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/salaryRegistrations/' + salaryRegistrationID))
}

export function delSalaryRegistrationBulk(companyID?: string, employeeID?: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/salaryRegistrations/bulk'), { body: { companyID, employeeID } })
}
