import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import LeaveType from '../../model/leaveType'
import { CompanyReducer } from '../../reducers/companies'
import { formatMonth } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatLeaveTypeName } from '../../utils/format-utils'
import { forceParseInputNumber, formatInputNumber } from '../../utils/number-utils'
import { capitalise } from '../../utils/string-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  company: Company
  companies: CompanyReducer
  companyFeatures: List<CompanyFeature>
  leaveTypes: List<LeaveType>
}

type Fields = {
  limitICalendarToDepartments: boolean
  vacationExcessLimit: string
  automaticVacationTransferLimit: string
  automaticOptionalVacationTransferLimit: string
}

export type VacationSettingResult = {
  limitICalendarToDepartments: boolean
  vacationExcessLimit: number
  automaticVacationTransferLimit: number
  automaticOptionalVacationTransferLimit: number
}

function VacationSettingsForm(props: Props & FormComponentProps<Fields, VacationSettingResult>): ReactElement | null {
  const hasEmployeeCalendarFeature = props.companyFeatures.some(
    (feature) => feature.featureType === 'Employee Calendar'
  )

  const { decorateField } = props
  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('vacationExcessLimit', {
            placeholder: t('vacation_settings.form.vacation_excess_limit'),
            validate: (val) => {
              if (!val) {
                return null
              }
              if (!val.match(/^[0-9]+$/)) {
                return t('vacation_settings.form.vacation_excess_limit.invalid')
              }
              return null
            },
          })(<Input tabIndex={1} />)}
        </Col>
        <Col span={12}>
          {decorateField('automaticVacationTransferLimit', {
            placeholder: t('vacation_settings.form.automatic_vacation_transfer_limit'),
            validate: (val) => {
              if (!val) {
                return null
              }
              if (!val.match(/^[0-9]+$/)) {
                return t('vacation_settings.form.automatic_vacation_transfer_limit.invalid')
              }
              return null
            },
          })(<Input tabIndex={2} />)}
        </Col>
        <Col span={12}>
          {decorateField('automaticOptionalVacationTransferLimit', {
            placeholder: t('vacation_settings.form.automatic_optional_vacation_transfer_limit'),
            validate: (val) => {
              if (!val) {
                return null
              }
              if (!val.match(/^[0-9]+$/)) {
                return t('vacation_settings.form.automatic_optional_vacation_transfer_limit.invalid')
              }
              return null
            },
          })(<Input tabIndex={3} />)}
        </Col>
      </Row>
      {hasEmployeeCalendarFeature && (
        <Row>
          <Col span={12}>
            {decorateField('limitICalendarToDepartments', {
              placeholder: t('vacation_settings.form.limit_i_calendar_to_departments'),
              validate: (val) => (!val ? t('vacation_settings.form.limit_i_calendar_to_departments.required') : null),
            })(
              <Select dropdownMatchSelectWidth={false}>
                <Select.Option value="Employee">
                  {t('vacation_settings.form.limit_i_calendar_to_departments.employee')}
                </Select.Option>
                <Select.Option value="Department">
                  {t('vacation_settings.form.limit_i_calendar_to_departments.department')}
                </Select.Option>
                <Select.Option value="All">
                  {t('vacation_settings.form.limit_i_calendar_to_departments.all')}
                </Select.Option>
              </Select>
            )}
          </Col>
        </Row>
      )}
      <Subtitle style={{ marginTop: '15px' }}>{t('vacation_settings.form.leave_types_cycles_title')}</Subtitle>
      <Row>
        {props.leaveTypes
          .filter(
            (leaveType) => leaveType.cycle === 'Yearly' && leaveType.assignable && leaveType.vesting === 'Prepaid'
          )
          .map((leaveType) => {
            return (
              <Col span={12}>
                <strong>{formatLeaveTypeName(leaveType.name)}</strong>:{' '}
                {capitalise(formatMonth(leaveType.cycleStart - 1))} &mdash;{' '}
                {formatMonth((leaveType.cycleStart + 10) % 12)}
              </Col>
            )
          })}
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary" tabIndex={7}>
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.companies.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, VacationSettingResult>({
  mapPropsToFields: (props) => ({
    limitICalendarToDepartments: props.company.limitICalendarToDepartments,
    vacationExcessLimit: props.company.vacationExcessLimit ? formatInputNumber(props.company.vacationExcessLimit) : '0',
    automaticVacationTransferLimit: props.company.automaticVacationTransferLimit
      ? formatInputNumber(props.company.automaticVacationTransferLimit)
      : '0',
    automaticOptionalVacationTransferLimit: props.company.automaticOptionalVacationTransferLimit
      ? formatInputNumber(props.company.automaticOptionalVacationTransferLimit)
      : '0',
  }),
  onSubmit: (values) => ({
    ...values,
    vacationExcessLimit: forceParseInputNumber(values.vacationExcessLimit),
    automaticVacationTransferLimit: forceParseInputNumber(values.automaticVacationTransferLimit),
    automaticOptionalVacationTransferLimit: forceParseInputNumber(values.automaticOptionalVacationTransferLimit),
  }),
})(VacationSettingsForm)
