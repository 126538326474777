import CoarseCarAllowance, {
  CoarseCarAllowanceCreationFields,
  CoarseCarAllowanceMutableFields,
} from '../model/coarseCarAllowance'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCoarseCarAllowances(
  companyID?: string,
  salaryPeriodIDs?: string[],
  employeeID?: string
): Promise<RequestResponse<CoarseCarAllowance[]>> {
  return secureRequest(
    'GET',
    url('/v2/coarseCarAllowances', {
      companyID,
      salaryPeriodID: salaryPeriodIDs ? salaryPeriodIDs.join(',') : undefined,
      employeeID,
    })
  )
}

export function postCoarseCarAllowance(
  employeeID: string,
  coarseCarAllowance: CoarseCarAllowanceCreationFields
): Promise<RequestResponse<CoarseCarAllowance>> {
  return secureRequest('POST', url('/v2/coarseCarAllowances'), {
    body: {
      employeeID: coarseCarAllowance.employeeID,
      salaryPeriodID: coarseCarAllowance.salaryPeriodID,
      kilometers: coarseCarAllowance.kilometers,
    },
  })
}

export function putCoarseCarAllowance(
  coarseCarAllowanceID: string,
  coarseCarAllowance: CoarseCarAllowanceMutableFields
): Promise<RequestResponse<CoarseCarAllowance>> {
  return secureRequest('PUT', url('/v2/coarseCarAllowances/' + coarseCarAllowanceID), {
    body: {
      employeeID: coarseCarAllowance.employeeID,
      salaryPeriodID: coarseCarAllowance.salaryPeriodID,
      kilometers: coarseCarAllowance.kilometers,
    },
  })
}

export function delCoarseCarAllowance(coarseCarAllowanceID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/coarseCarAllowances/' + coarseCarAllowanceID))
}
