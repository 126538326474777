import { List } from 'immutable'
import React, { ReactElement } from 'react'

import CostCenter from '../../../model/costCenter'
import {
  combineSearchOption,
  decorateAnyFieldSignature,
  getAnyFieldErrorSignature,
  getAnyFieldValueSignature,
} from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Select from '../../antd/select'
import Checkbox from '../../elements/checkbox'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'

export type DimensionLine = {
  no: number
  internalID: string
  externalID: string
  code: string
  dimensionName?: string
  dimensionInternalID?: string
  dimensionCode?: string
  dimensionValueName?: string
  costCenterID?: string | 'New'
  hadCostCenter: boolean
  active: boolean
}

type DimensionRowFields = {
  lines: DimensionLine[]
}

type Props<Fields extends DimensionRowFields> = {
  decorateAnyField: decorateAnyFieldSignature<Fields>
  getAnyFieldValue: getAnyFieldValueSignature
  getAnyFieldError: getAnyFieldErrorSignature

  line: DimensionLine
  hasDimensionValues: boolean
  useDepartments: boolean

  costCenters: List<CostCenter>
}

export default function DimensionsFormRow<Fields extends DimensionRowFields>(
  props: Props<Fields>
): ReactElement | null {
  const { decorateAnyField, getAnyFieldError, getAnyFieldValue } = props

  const i = props.line.no
  const error = getAnyFieldError(`lines.${i}.costCenterID`)

  return (
    <Row key={`line-${i}`}>
      {props.hasDimensionValues && <Col span={6}>{props.line.dimensionCode}</Col>}
      <Col span={props.hasDimensionValues ? 8 : 10}>
        {props.line.code}
        {props.line.dimensionValueName ? ' (' + props.line.dimensionValueName + ')' : ''}
        {props.line.dimensionName ? ' (' + props.line.dimensionName + ')' : ''}
      </Col>
      <Col span={props.hasDimensionValues ? 8 : 12}>
        {decorateAnyField(`lines.${i}.costCenterID`, {
          skipLabel: true,
          placeholder: props.useDepartments
            ? t('dimensions_tab.card.form.row.cost_center_id.department')
            : t('dimensions_tab.card.form.row.cost_center_id.cost_center'),
          validate: (val) => {
            if (!getAnyFieldValue(`lines.${i}.active`)) {
              return null
            }
            if (val === 'New') {
              return null
            }
            if (!val) {
              return props.useDepartments
                ? t('dimensions_tab.card.form.row.cost_center_id.required.department')
                : t('dimensions_tab.card.form.row.cost_center_id.required.cost_center')
            }
            if (!props.costCenters.find((costCenter) => costCenter.id === val)?.active) {
              return props.useDepartments
                ? t('dimensions_tab.card.form.row.cost_center_id.deactivated.department')
                : t('dimensions_tab.card.form.row.cost_center_id.deactivated.cost_center')
            }
            return null
          },
        })(
          <Select
            dropdownMatchSelectWidth={false}
            disabled={!getAnyFieldValue(`lines.${i}.active`)}
            showSearch={true}
            filterOption={(inputValue: string, option: ReactElement) => {
              return combineSearchOption(option).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }}
          >
            <Select.Option
              key={'New'}
              value={'New'}
              title={
                props.useDepartments
                  ? t('dimensions_tab.card.form.row.cost_center_id.new.department')
                  : t('dimensions_tab.card.form.row.cost_center_id.new.cost_center')
              }
            >
              <i>
                {props.useDepartments
                  ? t('dimensions_tab.card.form.row.cost_center_id.new.department')
                  : t('dimensions_tab.card.form.row.cost_center_id.new.cost_center')}
              </i>
            </Select.Option>
            {props.costCenters
              .filter(
                (costCenter) => costCenter.active || getAnyFieldValue(`lines.${i}.costCenterID`) === costCenter.id
              )
              .map((costCenter) => {
                return (
                  <Select.Option key={costCenter.id} value={costCenter.id} title={costCenter.name}>
                    {costCenter.active
                      ? costCenter.name
                      : t('dimensions_tab.card.form.row.cost_center_id.deactivated_note', { name: costCenter.name })}
                  </Select.Option>
                )
              })}
          </Select>
        )}
        {error && (
          <span className="has-error">
            <label>{error}</label>
          </span>
        )}
      </Col>
      <Col span={2}>
        {decorateAnyField(`lines.${i}.active`, {
          skipLabel: true,
          skipWrapper: true,
          valueOnChecked: true,
          noBlur: true,
        })(<Checkbox />)}
      </Col>
    </Row>
  )
}
