import { List } from 'immutable'

import Employee from '../model/employee'
import SalaryType from '../model/salaryType'
import { StagedImportDataEmployee } from '../model/stagedImport'
import { translateGroupTitle } from './translation-utils'

export type SalaryInformation = {
  title: string
  toAdd: boolean
  toModify: boolean
  toCreate: boolean
  rate?: number
}

export function getEmployeeSalaryDefinition(
  employees: List<Employee>,
  employee: StagedImportDataEmployee,
  salaryTypes: Record<string, SalaryType>,
  salaryTypeID: string
): SalaryInformation {
  if (salaryTypeID === 'not-to-be-created') {
    return {
      title: 'Oprettes ikke',
      toAdd: false,
      toModify: false,
      toCreate: false,
    }
  }
  let salaryChange = employee.salaryChanges.find((c) => c.salaryTypeID === salaryTypeID)
  if (!salaryChange) {
    // try finding it on the remuneration object
    salaryChange = employee.remuneration?.salary.find((s) => s.salaryTypeID === salaryTypeID)
  }
  if (salaryChange) {
    const toCreate = !!salaryChange.salaryTypeID.match(/^new-salary-type-[0-9]+$/)
    return {
      title: salaryChange.title ?? translateGroupTitle(salaryTypes[salaryChange.salaryTypeID]),
      toAdd: toCreate
        ? false
        : !employees
            .find((e) => e.id === employee.employeeID)
            ?.activeContract?.remuneration?.salary.some((s) => s.salaryTypeID === salaryTypeID),
      toModify: true,
      toCreate,
      rate: salaryChange.rate,
    }
  }

  const salaryDefinition = employees
    .find((e) => e.id === employee.employeeID)
    ?.activeContract?.remuneration?.salary.find((s) => s.salaryTypeID === salaryTypeID)
  if (salaryDefinition) {
    return {
      title: salaryDefinition.title ?? translateGroupTitle(salaryTypes[salaryDefinition.salaryTypeID]),
      toAdd: false,
      toModify: false,
      toCreate: false,
      rate: salaryDefinition.rate,
    }
  }

  return {
    title: translateGroupTitle(salaryTypes[salaryTypeID]),
    toAdd: false,
    toModify: false,
    toCreate: false,
  }
}
