import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../../model/company'
import Employee from '../../../model/employee'
import { OneTimePayCreationFields, OneTimePayMutableFields, OneTimePayType } from '../../../model/oneTimePay'
import SalaryCycle from '../../../model/salaryCycle'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import FreeTextEditForm, { FreeTextResult } from './FreeTextEditForm'

type Props = {
  visible: boolean
  editing: boolean
  canApproveObjects: boolean
  company: Company
  employee?: Employee
  oneTimePayID?: string
  oneTimePays: OneTimePayReducer
  salaryCycle: SalaryCycle
  forEIncome: boolean

  addOneTimePay: (id: string, oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (id: string, oneTimePay: OneTimePayMutableFields) => void
}

export default function FreeText(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, oneTimePays } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, oneTimePays.error, error, setError)
  }, [visible, oneTimePays, error])

  let otpType: OneTimePayType = 'Free Text'
  if (props.forEIncome) {
    otpType = 'E-Income Text'
  }

  const handleSubmit = (values: FreeTextResult) => {
    if (!props.editing || !props.employee) {
      return
    }
    const oneTimePay: OneTimePayCreationFields = {
      employeeID: props.employee.id,
      type: otpType,
      dispositionDate: values.dispositionDate,
      amount: 0.0,
      title: values.title,
      approved: values.approved,
    }
    if (props.oneTimePayID) {
      props.updateOneTimePay(props.employee.id, { ...oneTimePay, id: props.oneTimePayID })
    } else {
      props.addOneTimePay(props.employee.id, oneTimePay)
    }
  }

  let text = t('free_text.intro.standard')
  if (props.forEIncome) {
    text = t('free_text.intro.e_income')
  }

  if (!props.employee) {
    return null
  }
  return (
    <Card className="bonus-modal">
      <Subtitle>
        {props.forEIncome
          ? props.oneTimePayID
            ? props.editing
              ? t('free_text.title.standard.edit')
              : t('free_text.title.standard.view')
            : t('free_text.title.standard.create')
          : props.oneTimePayID
          ? props.editing
            ? t('free_text.title.e_income.edit')
            : t('free_text.title.e_income.view')
          : t('free_text.title.e_income.create')}
      </Subtitle>
      <p>{text}</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <FreeTextEditForm
        editing={props.editing}
        canApproveObjects={props.canApproveObjects}
        company={props.company}
        employee={props.employee}
        oneTimePayID={props.oneTimePayID}
        oneTimePays={props.oneTimePays}
        salaryCycle={props.salaryCycle}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
