import React, { ReactElement, useEffect, useState } from 'react'

import { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import { ContractReducer } from '../../../reducers/contracts'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate } from '../../../utils/date-utils'
import { getValidFrom } from '../../../utils/employment-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import RateEditForm, { RateResult } from './RateEditForm'

type Props = {
  visible: boolean
  employee: Employee
  contracts: ContractReducer

  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
}

export default function RateEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, contracts } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, contracts.error, error, setError)
  }, [visible, contracts, error])

  const handleSubmit = (values: RateResult) => {
    if (!props.employee.earliestMutableContract) {
      return
    }
    const contract = { ...props.employee.earliestMutableContract, carAllowanceRate: values.carAllowanceRate }
    const { validFrom, canUpdate } = getValidFrom(props.employee, contract)
    if (canUpdate) {
      props.updateContract(contract)
    } else {
      contract.validFrom = formatAPIDate(validFrom)
      props.addContract(contract)
    }
  }

  const contract = props.employee.earliestMutableContract
  if (!contract) {
    return null
  }

  return (
    <Card className="rate-modal">
      <Subtitle>{t('car_allowance_rate.edit.title')}</Subtitle>
      <p>
        {t('car_allowance_rate.edit.intro.line_1')}
        <br />
        {t('car_allowance_rate.edit.intro.line_2')}
      </p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <RateEditForm contract={contract} contracts={props.contracts} onSubmit={handleSubmit} />
    </Card>
  )
}
