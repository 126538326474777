export default {
  Calendar: {
    lang: {
      placeholder: 'Vælg dato',
      timePlaceholder: 'Vælg tidspunkt',
      today: 'I dag',
      now: 'Nu',
      backToToday: 'Gå til i dag',
      ok: 'Ok',
      clear: 'Annuller',
      month: 'Måned',
      year: 'År',
      timeSelect: 'Vælg tidspunkt',
      dateSelect: 'Vælg dato',
      monthSelect: 'Vælg måned',
      yearSelect: 'Vælg år',
      decadeSelect: 'Vælg årti',
      yearFormat: 'YYYY',
      dateFormat: 'D/M/YYYY',
      dayFormat: 'D',
      dateTimeFormat: 'D/M/YYYY HH:mm:ss',
      monthFormat: 'MMMM',
      monthBeforeYear: true,
      previousMonth: 'Forrige måned (PageUp)',
      nextMonth: 'Næste måned (PageDown)',
      previousYear: 'Forrige år (Control + left)',
      nextYear: 'Næste år (Control + right)',
      previousDecade: 'Forrige årti',
      nextDecade: 'Næste årti',
      previousCentury: 'Forrige århundrede',
      nextCentury: 'Næste århundrede',
    },
    timePickerLocale: {
      placeholder: 'Vælg tidspunkt',
    },
  },
  DatePicker: {
    timezoneOffset: 1 * 60,
    firstDayOfWeek: 0,
    minimalDaysInFirstWeek: 1,
    lang: {
      placeholder: 'Vælg dato',
      timePlaceholder: 'Vælg tidspunkt',
      rangePlaceholder: ['Startdato', 'Slutdato'],
      today: 'I dag',
      now: 'Nu',
      backToToday: 'Gå til i dag',
      ok: 'Ok',
      clear: 'Annuller',
      month: 'Måned',
      year: 'År',
      timeSelect: 'Vælg tidspunkt',
      dateSelect: 'Vælg dato',
      monthSelect: 'Vælg måned',
      yearSelect: 'Vælg år',
      decadeSelect: 'Vælg årti',
      yearFormat: 'YYYY',
      dateFormat: 'D/M/YYYY',
      dayFormat: 'D',
      dateTimeFormat: 'D/M/YYYY HH:mm:ss',
      monthFormat: 'MMMM',
      monthBeforeYear: true,
      previousMonth: 'Forrige måned(PageUp)',
      nextMonth: 'Næste måned (PageDown)',
      previousYear: 'Forrige år (Control + left)',
      nextYear: 'Næste r (Control + right)',
      previousDecade: 'Forrige årti',
      nextDecade: 'Næste årti',
      previousCentury: 'Forrige århundrede',
      nextCentury: 'Næste århundrede',
    },
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Annuller',
    justOkText: 'OK',
  },
  Pagination: {
    items_per_page: '/ side',
    jump_to: 'Gå til',
    page: '',
    prev_page: 'Forrige side',
    next_page: 'Næste side',
    prev_5: '5 sider tilbage',
    next_5: '5 sider frem',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Annuller',
  },
  Select: {
    notFoundContent: 'Ikke fundet',
  },
  Table: {
    filterTitle: 'Filtrér',
    filterConfirm: 'OK',
    filterReset: 'Nulstil',
    emptyText: 'Ingen data',
  },
  TimePicker: {
    placeholder: 'Vælg tidspunkt',
    clear: 'Ryd',
    format: {
      eras: ['f.Kr.', 'e.Kr.'],
      months: [
        'januar',
        'februar',
        'marts',
        'april',
        'maj',
        'juni',
        'juli',
        'august',
        'september',
        'oktober',
        'november',
        'december',
      ],
      shortMonths: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
      weekdays: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
      shortWeekdays: ['søn', 'man', 'tir', 'ons', 'tor', 'fre', 'lør'],
      veryShortWeekdays: ['sø', 'ma', 'ti', 'on', 'to', 'fr', 'lø'],
      ampms: ['AM', 'PM'],
      datePatterns: [
        // mandag d. 23. oktober 2015
        'EEEE d. d. MMMM yyyy', // eslint-disable-line no-useless-escape
        // d. 23. oktober 2015
        'd. d. MMMM yyyy', // eslint-disable-line no-useless-escape
        // 23. oktober 2015
        'd. MMMM yyyy',
        // 23. okt. 2015
        'd. MMM. yyyy',
        // 23/10/15
        'dd/MM/yy',
        // 23/10/2015
        'dd/MM/yyyy',
        // 2015-10-15
        'yyyy-MM-dd',
      ],
      timePatterns: ["HH:mm:ss 'GMT'Z", 'HH:mm:ss', 'HH:mm'],
      dateTimePattern: '{date} {time}',
    },
    calendar: {
      timezoneOffset: 1 * 60,
      firstDayOfWeek: 0,
      minimalDaysInFirstWeek: 1,
    },
  },
  Transfer: {
    notFoundContent: 'Ikke fundet',
    searchPlaceholder: 'Søg her',
    itemUnit: 'element',
    itemsUnit: 'elementer',
  },
  locale: 'da',
}
