import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import EmployeeDimension from '../model/employeeDimension'
import { ReducerAction } from '../utils/reducer-utils'

export interface EmployeeDimensionReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  employeeDimensions: List<EmployeeDimension>
  error: Error | null
}

const Params = Record<EmployeeDimensionReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  employeeDimensions: List<EmployeeDimension>(),
  error: null,
})

export interface EmployeeDimensionAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  employeeDimensions?: EmployeeDimension[]
  employeeDimension?: EmployeeDimension
}

export default (
  state: Record<EmployeeDimensionReducer> = Params(),
  action: EmployeeDimensionAction = { type: '' }
): Record<EmployeeDimensionReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  let employeeDimensions = state.get('employeeDimensions')
  switch (action.type) {
    case ActionTypes.EMPLOYEE_DIMENSIONS_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.EMPLOYEE_DIMENSIONS_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        employeeDimensions: List<EmployeeDimension>(action.employeeDimensions),
      })
    case ActionTypes.EMPLOYEE_DIMENSIONS_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.EMPLOYEE_DIMENSIONS_UPDATED:
      if (!action.employeeDimensions) {
        return state
      }
      // first remove all existing dimensions for this employee
      employeeDimensions = employeeDimensions.filter((oldDimension) => oldDimension.employeeID !== action.employeeID)
      // then all the ones with this employee ID
      employeeDimensions = employeeDimensions.concat(
        action.employeeDimensions.filter((newDimension) => newDimension.employeeID === action.employeeID)
      )
      return state.set('loaded', true).set('employeeDimensions', employeeDimensions)

    case ActionTypes.EMPLOYEE_DIMENSIONS_UPDATING:
      return state.set('saving', true)
    case ActionTypes.EMPLOYEE_DIMENSION_UPDATED:
      if (!action.employeeDimension) {
        return state
      }
      idx = state
        .get('employeeDimensions')
        .findIndex(
          (dimension) =>
            !!action.employeeDimension &&
            dimension.dimensionID === action.employeeDimension.dimensionID &&
            dimension.employeeID === action.employeeDimension.employeeID
        )
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('employeeDimensions', state.get('employeeDimensions').set(idx, action.employeeDimension))
    case ActionTypes.EMPLOYEE_DIMENSIONS_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
