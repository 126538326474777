import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import AvailableAccountingIntegration from '../model/availableAccountingIntegration'
import { ReducerAction } from '../utils/reducer-utils'

export interface AvailableAccountingIntegrationReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  availableAccountingIntegrations: List<AvailableAccountingIntegration>
  error: Error | null
}

const Params = Record<AvailableAccountingIntegrationReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  availableAccountingIntegrations: List<AvailableAccountingIntegration>(),
  error: null,
})

export interface AvailableAccountingIntegrationAction extends ReducerAction {
  companyID?: string
  availableAccountingIntegrations?: AvailableAccountingIntegration[]
}

export default (
  state: Record<AvailableAccountingIntegrationReducer> = Params(),
  action: AvailableAccountingIntegrationAction = { type: '' }
): Record<AvailableAccountingIntegrationReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.AVAILABLE_ACCOUNTING_INTEGRATIONS_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.AVAILABLE_ACCOUNTING_INTEGRATIONS_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        availableAccountingIntegrations: List<AvailableAccountingIntegration>(action.availableAccountingIntegrations),
      })
    case ActionTypes.AVAILABLE_ACCOUNTING_INTEGRATIONS_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error || null,
      })
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
