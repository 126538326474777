import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { ReimbursementVoucherFieldsUpdate, ReimbursementVoucherUpdate } from '../../api/reimbursement-vouchers'
import { CostCenterAccounting } from '../../model/accountingIntegration'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import CostCenter from '../../model/costCenter'
import Employee from '../../model/employee'
import ExpenseCategory from '../../model/expenseCategory'
import ReimbursementVoucher from '../../model/reimbursementVoucher'
import { ReimbursementVoucherReducer } from '../../reducers/reimbursementVouchers'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { hasDepartmentPermission } from '../../utils/permissions-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import ReimbursementVoucherEditForm, { ResultFields } from './ReimbursementVoucherEditForm'

type Props = {
  visible: boolean
  reimbursementVoucherID?: string
  company: Company
  companyUser?: CompanyUser
  companyUsers: List<CompanyUser>
  employees: List<Employee>
  expenseCategories: List<ExpenseCategory>
  reimbursementVouchers: ReimbursementVoucherReducer
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>

  updateReimbursementVoucher: (o: ReimbursementVoucherUpdate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (o: ReimbursementVoucherFieldsUpdate) => Promise<ReimbursementVoucher | void>
  approveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  unapproveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  draftReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  closeModal: () => void
}

export default function ReimbursementVoucherEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const visible = props.visible
  const reimbursementVoucherError = props.reimbursementVouchers.error
  let canEditObjects = true
  if (props.reimbursementVoucherID) {
    const voucher = props.reimbursementVouchers.reimbursementVouchers.find(
      (voucher) => voucher.id === props.reimbursementVoucherID
    )
    if (voucher) {
      const employee = props.employees.find((employee) => employee.id === voucher.employeeID)
      if (employee) {
        canEditObjects = hasDepartmentPermission(props.companyUser, employee.departmentID, 'EditObjects')
      } else {
        canEditObjects = false
      }
    } else {
      canEditObjects = false
    }
  }

  useEffect(() => {
    visibleComponentDidUpdate(visible, reimbursementVoucherError, error, setError)
  }, [visible, reimbursementVoucherError, error])

  const handleSubmit = async (values: ResultFields) => {
    if (!canEditObjects) {
      return
    }
    const id = props.reimbursementVoucherID
    if (!id) {
      return
    }
    props
      .updateReimbursementVoucherFields({
        id: id,
        receiptDate: values.receiptDate,
        amount: values.amount,
        currency: values.currency,
      })
      .then((res) => {
        if (!res) {
          return
        }
        props
          .updateReimbursementVoucher({
            id: res.id,
            disburseAmount: values.disburseAmount,
            disburseable: values.disburseable,
            immediatePay: values.immediatePay,
            expenseCategoryID: values.expenseCategoryID,
            costCenterID: values.costCenterID,
            note: values.note,
            cardNote: values.cardNote,
          })
          .then((res) => {
            if (!res) {
              return
            }
            let closed = false
            if (values.ready) {
              if (values.approved) {
                if (res.approvalState !== 'Approved') {
                  closed = true
                  props.approveReimbursementVouchers([res.id]).then(() => props.closeModal())
                }
              } else {
                if (res.approvalState === 'Approved') {
                  closed = true
                  props.unapproveReimbursementVouchers([res.id]).then(() => props.closeModal())
                }
              }
            } else {
              if (res.approvalState !== 'Draft') {
                closed = true
                props.draftReimbursementVouchers([res.id]).then(() => props.closeModal())
              }
            }
            if (!closed) {
              props.closeModal()
            }
          })
      })
  }

  return (
    <Card className="reimbursement-voucher-modal">
      <Subtitle>
        {props.reimbursementVoucherID
          ? t('reimbursement_vouchers.edit.title.edit')
          : t('reimbursement_vouchers.edit.title.create')}
      </Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <ReimbursementVoucherEditForm
        reimbursementVoucherID={props.reimbursementVoucherID}
        disabled={!canEditObjects}
        company={props.company}
        companyUsers={props.companyUsers}
        employees={props.employees}
        expenseCategories={props.expenseCategories}
        reimbursementVouchers={props.reimbursementVouchers}
        costCenterAccounting={props.costCenterAccounting}
        costCenters={props.costCenters}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
