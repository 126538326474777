import CostCenter from '../model/costCenter'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCostCenters(companyID: string): Promise<RequestResponse<CostCenter[]>> {
  return secureRequest('GET', url('/v2/costCenters', { companyID }))
}

export function postCostCenter(companyID: string, costCenter: CostCenter): Promise<RequestResponse<CostCenter>> {
  return secureRequest('POST', url('/v2/costCenters'), {
    body: {
      companyID: companyID,
      name: costCenter.name,
    },
  })
}

export function putCostCenter(companyID: string, costCenter: CostCenter): Promise<RequestResponse<CostCenter>> {
  return secureRequest('PUT', url('/v2/costCenters/' + costCenter.id), {
    body: {
      companyID: companyID,
      name: costCenter.name,
    },
  })
}

export function delCostCenter(costCenterID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/costCenters/' + costCenterID))
}
