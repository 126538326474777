import React from 'react'

import Spin from '../elements/spin'

import './Landing.css'

// NEED_NO_TRANSLATION

export default function Landing() {
  return (
    <div className="landing">
      <Spin size="large" />
    </div>
  )
}
