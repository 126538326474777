import React, { ReactElement } from 'react'
import { useEffectOnce } from 'react-use'

import { getCompanyAccountPlans } from '../actions/accounting-integration'
import { removeAlert, removeAlertSignature } from '../actions/alerts'
import { getCostCenters } from '../actions/cost-centers'
import { addOneTimePay, getOneTimePays, updateOneTimePay } from '../actions/one-time-pays'
import { getSalaryCycles } from '../actions/salary-cycles'
import FreelancersOverviewComponent from '../components/freelancers-overview/FreelancersOverview'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { OneTimePayCreationFields, OneTimePayMutableFields } from '../model/oneTimePay'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { CompanyAccountPlanReducer } from '../reducers/companyAccountPlans'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { CostCenterReducer } from '../reducers/costCenters'
import { EmployeeReducer } from '../reducers/employees'
import { OneTimePayReducer } from '../reducers/oneTimePays'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import RegistrationLayout from './layouts/RegistrationLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  employees: EmployeeReducer
  oneTimePays: OneTimePayReducer
  costCenters: CostCenterReducer
  salaryCycles: SalaryCycleReducer
  companyAccountPlans: CompanyAccountPlanReducer
}

type Actions = {
  removeAlert: removeAlertSignature
  getCostCenters: () => void
  getOneTimePays: (companyID?: string, employeeID?: string) => void
  addOneTimePay: (employeeID: string, oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, oneTimePay: OneTimePayMutableFields) => void
  getSalaryCycles: () => void
  getCompanyAccountPlans: () => void
}

function FreelancersOverview({
  route,
  alerts,
  companies,
  companyUsers,
  employees,
  oneTimePays,
  costCenters,
  salaryCycles,
  companyAccountPlans,
  removeAlert,
  getCostCenters,
  getOneTimePays,
  addOneTimePay,
  updateOneTimePay,
  getSalaryCycles,
  getCompanyAccountPlans,
}: Reducers & Actions & RouteProps): ReactElement | null {
  const company = companies.company

  useEffectOnce(() => {
    if (!company) {
      return
    }
    const companyID = company.id
    if (oneTimePays.companyID !== companyID || (!oneTimePays.loaded && !oneTimePays.loading)) {
      getOneTimePays(companyID)
    }
    if (!costCenters.loaded && !costCenters.loading) {
      getCostCenters()
    }
    if (!salaryCycles.loaded && !salaryCycles.loading) {
      getSalaryCycles()
    }
  })

  const getSalaryCycle = () =>
    salaryCycles.salaryCycles.find(
      (salaryCycle) => salaryCycle.frequency === 'Monthly' && !salaryCycle.prepaid && !salaryCycle.offset
    )

  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  const loading = (!employees.loading && !employees.loaded) || employees.loading
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <RegistrationLayout route={route}>
      <FreelancersOverviewComponent
        alerts={alerts}
        company={company}
        companyUser={companyUsers.companyUser}
        employees={employees.employees}
        oneTimePays={oneTimePays}
        salaryCycle={getSalaryCycle()}
        costCenterAccounting={company.costCenterAccounting}
        costCenters={costCenters.costCenters}
        companyAccountPlans={companyAccountPlans}
        addOneTimePay={addOneTimePay}
        updateOneTimePay={updateOneTimePay}
        removeAlert={removeAlert}
        getCompanyAccountPlans={getCompanyAccountPlans}
      />
    </RegistrationLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    companyUsers: state.companyUsers,
    employees: state.employees,
    oneTimePays: state.oneTimePays,
    costCenters: state.costCenters,
    salaryCycles: state.salaryCycles,
    companyAccountPlans: state.companyAccountPlans,
  }),
  {
    removeAlert: removeAlert,
    getCostCenters: getCostCenters,
    getOneTimePays: getOneTimePays,
    updateOneTimePay: updateOneTimePay,
    addOneTimePay: addOneTimePay,
    getSalaryCycles: getSalaryCycles,
    getCompanyAccountPlans: getCompanyAccountPlans,
  }
)(FreelancersOverview)
