import AccountingDimension, {
  AccountingDimensionCreation,
  AccountingDimensionMutable,
} from '../model/accountingDimension'
import AccountingIntegration, { CostCenterAccounting } from '../model/accountingIntegration'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchAccountingDimensions(companyID: string): Promise<RequestResponse<AccountingDimension[]>> {
  return secureRequest('GET', url('/v2/accountingIntegrations/dimensions', { companyID }))
}

export function patchAccountingDimensions(
  companyID: string,
  useDimensions: CostCenterAccounting
): Promise<RequestResponse<AccountingIntegration>> {
  return secureRequest('PATCH', url('/v2/accountingIntegrations/dimensions', { companyID }), {
    body: {
      useDimensions,
    },
  })
}

export function putAccountingDimensions(
  companyID: string,
  dimensionMapping: (AccountingDimensionMutable | AccountingDimensionCreation)[]
): Promise<RequestResponse<AccountingDimension[]>> {
  return secureRequest('PUT', url('/v2/accountingIntegrations/dimensions', { companyID }), {
    body: {
      dimensionMapping,
    },
  })
}
