export default {
  USER_REGISTERING: 'USER_REGISTERING',
  USER_REGISTERED: 'USER_REGISTERED',
  USER_REGISTERING_FAILED: 'USER_REGISTERING_FAILED',
  USER_LOGGING_IN: 'USER_LOGGING_IN',
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  USER_LOGIN_FAILED: 'USER_LOGIN_FAILED',
  USER_LOGGING_OUT: 'USER_LOGGING_OUT',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  USER_LOGOUT_FAILED: 'USER_LOGOUT_FAILED',
  USER_LOADING: 'USER_LOADING',
  USER_LOADED: 'USER_LOADED',
  USER_LOAD_FAILED: 'USER_LOAD_FAILED',
  USER_REQUESTING_PASSWORD: 'USER_REQUESTING_PASSWORD',
  USER_REQUESTED_PASSWORD: 'USER_REQUESTED_PASSWORD',
  USER_REQUEST_PASSWORD_FAILED: 'USER_REQUEST_PASSWORD_FAILED',
  USER_RESETTING_PASSWORD: 'USER_RESETTING_PASSWORD',
  USER_RESET_PASSWORD: 'USER_RESET_PASSWORD',
  USER_RESET_PASSWORD_FAILED: 'USER_RESET_PASSWORD_FAILED',
  COMPANIES_LOADING: 'COMPANIES_LOADING',
  COMPANIES_LOADED: 'COMPANIES_LOADED',
  COMPANIES_LOAD_FAILED: 'COMPANIES_LOAD_FAILED',
  COMPANIES_SETTING_ACTIVE: 'COMPANIES_SETTING_ACTIVE',
  COMPANY_ADDING: 'COMPANY_ADDING',
  COMPANY_ADDED: 'COMPANY_ADDED',
  COMPANY_ADD_FAILED: 'COMPANY_ADD_FAILED',
  COMPANY_UPDATING: 'COMPANY_UPDATING',
  COMPANY_UPDATED: 'COMPANY_UPDATED',
  COMPANY_UPDATE_FAILED: 'COMPANY_UPDATE_FAILED',
  COMPANY_CONNECTING: 'COMPANY_CONNECTING',
  COMPANY_CONNECTED: 'COMPANY_CONNECTED',
  COMPANY_CONNECT_FAILED: 'COMPANY_CONNECT_FAILED',
  USER_CONNECTING: 'USER_CONNECTING',
  USER_CONNECTED: 'USER_CONNECTED',
  USER_CONNECT_FAILED: 'USER_CONNECT_FAILED',
  COMPANY_DELETED: 'COMPANY_DELETED',
  COMPANY_GROUPS_LOADING: 'COMPANY_GROUPS_LOADING',
  COMPANY_GROUPS_LOADED: 'COMPANY_GROUPS_LOADED',
  COMPANY_GROUPS_LOAD_FAILED: 'COMPANY_GROUPS_LOAD_FAILED',
  COMPANY_GROUPS_SET_ACTIVE: 'COMPANY_GROUPS_SET_ACTIVE',
  COMPANY_GROUPS_UPDATING: 'COMPANY_GROUPS_UPDATING',
  COMPANY_GROUPS_UPDATED: 'COMPANY_GROUPS_UPDATED',
  COMPANY_GROUPS_UPDATE_FAILED: 'COMPANY_GROUPS_UPDATE_FAILED',
  COMPANY_GROUPS_ADDING_COMPANY: 'COMPANY_GROUPS_ADDING_COMPANY',
  COMPANY_GROUPS_ADDED_COMPANY: 'COMPANY_GROUPS_ADDED_COMPANY',
  COMPANY_GROUPS_ADD_COMPANY_FAILED: 'COMPANY_GROUPS_ADD_COMPANY_FAILED',
  COMPANY_GROUPS_REMOVING_COMPANY: 'COMPANY_GROUPS_REMOVING_COMPANY',
  COMPANY_GROUPS_REMOVED_COMPANY: 'COMPANY_GROUPS_REMOVED_COMPANY',
  COMPANY_GROUPS_REMOVE_COMPANY_FAILED: 'COMPANY_GROUPS_REMOVE_COMPANY_FAILED',
  COMPANY_GROUPS_USER_ADDED: 'COMPANY_GROUPS_USER_ADDED',
  COMPANY_GROUPS_USER_UPDATED: 'COMPANY_GROUPS_USER_UPDATED',
  COMPANY_GROUPS_USER_DELETED: 'COMPANY_GROUPS_USER_DELETED',
  EMPLOYEES_LOADING: 'EMPLOYEES_LOADING',
  EMPLOYEES_LOADED: 'EMPLOYEES_LOADED',
  EMPLOYEES_LOADED_PARTIAL: 'EMPLOYEES_LOADED_PARTIAL',
  EMPLOYEES_LOAD_FAILED: 'EMPLOYEES_LOAD_FAILED',
  EMPLOYEE_ADDING: 'EMPLOYEE_ADDING',
  EMPLOYEE_ADDED: 'EMPLOYEE_ADDED',
  EMPLOYEE_ADD_FAILED: 'EMPLOYEE_ADD_FAILED',
  EMPLOYEE_UPDATING: 'EMPLOYEE_UPDATING',
  EMPLOYEE_UPDATED: 'EMPLOYEE_UPDATED',
  EMPLOYEE_UPDATE_FAILED: 'EMPLOYEE_UPDATE_FAILED',
  EMPLOYEE_DELETING: 'EMPLOYEE_DELETING',
  EMPLOYEE_DELETED: 'EMPLOYEE_DELETED',
  EMPLOYEE_DELETE_FAILED: 'EMPLOYEE_DELETE_FAILED',
  EMPLOYEES_BATCH_UPDATING: 'EMPLOYEES_BATCH_UPDATING',
  EMPLOYEES_BATCH_UPDATED: 'EMPLOYEES_BATCH_UPDATED',
  EMPLOYEES_BATCH_UPDATE_FAILED: 'EMPLOYEES_BATCH_UPDATE_FAILED',
  EMPLOYEES_BATCH_RESET: 'EMPLOYEES_BATCH_RESET',
  TAX_CARD_LOADING: 'TAX_CARD_LOADING',
  TAX_CARD_LOADED: 'TAX_CARD_LOADED',
  TAX_CARD_LOADED_PARTIAL: 'TAX_CARD_LOADED_PARTIAL',
  TAX_CARD_LOAD_FAILED: 'TAX_CARD_LOAD_FAILED',
  TAX_CARD_UPDATING: 'TAX_CARD_UPDATING',
  TAX_CARD_UPDATED: 'TAX_CARD_UPDATED',
  TAX_CARD_UPDATE_FAILED: 'TAX_CARD_UPDATE_FAILED',
  TAX_CARD_ADDED: 'TAX_CARD_ADDED',
  TAX_CARD_REQUEST_LOADING: 'TAX_CARD_REQUEST_LOADING',
  TAX_CARD_REQUEST_LOADED: 'TAX_CARD_REQUEST_LOADED',
  TAX_CARD_REQUEST_LOADED_PARTIAL: 'TAX_CARD_REQUEST_LOADED_PARTIAL',
  TAX_CARD_REQUEST_LOAD_FAILED: 'TAX_CARD_REQUEST_LOAD_FAILED',
  TAX_CARD_REQUEST_ADDING: 'TAX_CARD_REQUEST_ADDING',
  TAX_CARD_REQUEST_ADDED: 'TAX_CARD_REQUEST_ADDED',
  TAX_CARD_REQUEST_ADD_FAILED: 'TAX_CARD_REQUEST_ADD_FAILED',
  EMPLOYEE_CONTRACT_LOADING: 'EMPLOYEE_CONTRACT_LOADING',
  EMPLOYEE_CONTRACT_LOADED: 'EMPLOYEE_CONTRACT_LOADED',
  EMPLOYEE_CONTRACT_LOADED_PARTIAL: 'EMPLOYEE_CONTRACT_LOADED_PARTIAL',
  EMPLOYEE_CONTRACT_LOAD_FAILED: 'EMPLOYEE_CONTRACT_LOAD_FAILED',
  EMPLOYEE_CONTRACT_ADDING: 'EMPLOYEE_CONTRACT_ADDING',
  EMPLOYEE_CONTRACT_ADDED: 'EMPLOYEE_CONTRACT_ADDED',
  EMPLOYEE_CONTRACT_ADD_FAILED: 'EMPLOYEE_CONTRACT_ADD_FAILED',
  EMPLOYEE_CONTRACT_UPDATING: 'EMPLOYEE_CONTRACT_UPDATING',
  EMPLOYEE_CONTRACT_UPDATED: 'EMPLOYEE_CONTRACT_UPDATED',
  EMPLOYEE_CONTRACT_UPDATE_FAILED: 'EMPLOYEE_CONTRACT_UPDATE_FAILED',
  EMPLOYEE_CONTRACT_DELETING: 'EMPLOYEE_CONTRACT_DELETING',
  EMPLOYEE_CONTRACT_DELETED: 'EMPLOYEE_CONTRACT_DELETED',
  EMPLOYEE_CONTRACT_DELETE_FAILED: 'EMPLOYEE_CONTRACT_DELETE_FAILED',
  EMPLOYEE_CONTRACT_DELTA_LOADING: 'EMPLOYEE_CONTRACT_DELTA_LOADING',
  EMPLOYEE_CONTRACT_DELTA_LOADED: 'EMPLOYEE_CONTRACT_DELTA_LOADED',
  EMPLOYEE_CONTRACT_DELTA_LOAD_FAILED: 'EMPLOYEE_CONTRACT_DELTA_LOAD_FAILED',
  EMPLOYEE_CONTRACT_DELTA_SET_VIEWING_CONTRACT: 'EMPLOYEE_CONTRACT_DELTA_SET_VIEWING_CONTRACT',
  EMPLOYEE_CONTRACT_DELTA_SET_DIRTY: 'EMPLOYEE_CONTRACT_DELTA_SET_DIRTY',
  REMUNERATION_LOADING: 'REMUNERATION_LOADING',
  REMUNERATION_LOADED: 'REMUNERATION_LOADED',
  REMUNERATION_LOAD_FAILED: 'REMUNERATION_LOAD_FAILED',
  TIME_REGISTRATION_LOADING: 'TIME_REGISTRATION_LOADING',
  TIME_REGISTRATION_LOADED: 'TIME_REGISTRATION_LOADED',
  TIME_REGISTRATION_LOADED_PARTIAL: 'TIME_REGISTRATION_LOADED_PARTIAL',
  TIME_REGISTRATION_LOAD_FAILED: 'TIME_REGISTRATION_LOAD_FAILED',
  TIME_REGISTRATION_CREATING: 'TIME_REGISTRATION_CREATING',
  TIME_REGISTRATION_CREATED: 'TIME_REGISTRATION_CREATED',
  TIME_REGISTRATION_CREATE_FAILED: 'TIME_REGISTRATION_CREATE_FAILED',
  TIME_REGISTRATION_APPROVING: 'TIME_REGISTRATION_APPROVING',
  TIME_REGISTRATION_APPROVED: 'TIME_REGISTRATION_APPROVED',
  TIME_REGISTRATION_APPROVE_FAILED: 'TIME_REGISTRATION_APPROVE_FAILED',
  TIME_REGISTRATION_UPDATING: 'TIME_REGISTRATION_UPDATING',
  TIME_REGISTRATION_UPDATED: 'TIME_REGISTRATION_UPDATED',
  TIME_REGISTRATION_UPDATE_FAILED: 'TIME_REGISTRATION_UPDATE_FAILED',
  TIME_REGISTRATION_DELETING: 'TIME_REGISTRATION_DELETING',
  TIME_REGISTRATION_DELETED: 'TIME_REGISTRATION_DELETED',
  TIME_REGISTRATION_BULK_DELETED: 'TIME_REGISTRATION_BULK_DELETED',
  TIME_REGISTRATION_DELETE_FAILED: 'TIME_REGISTRATION_DELETE_FAILED',
  TIME_REGISTRATION_TYPE_LOADING: 'TIME_REGISTRATION_TYPE_LOADING',
  TIME_REGISTRATION_TYPE_LOADED: 'TIME_REGISTRATION_TYPE_LOADED',
  TIME_REGISTRATION_TYPE_LOAD_FAILED: 'TIME_REGISTRATION_TYPE_LOAD_FAILED',
  TIME_REGISTRATION_TYPE_CREATING: 'TIME_REGISTRATION_TYPE_CREATING',
  TIME_REGISTRATION_TYPE_CREATED: 'TIME_REGISTRATION_TYPE_CREATED',
  TIME_REGISTRATION_TYPE_CREATE_FAILED: 'TIME_REGISTRATION_TYPE_CREATE_FAILED',
  TIME_REGISTRATION_TYPE_UPDATING: 'TIME_REGISTRATION_TYPE_UPDATING',
  TIME_REGISTRATION_TYPE_UPDATED: 'TIME_REGISTRATION_TYPE_UPDATED',
  TIME_REGISTRATION_TYPE_UPDATE_FAILED: 'TIME_REGISTRATION_TYPE_UPDATE_FAILED',
  TIME_REGISTRATION_TYPE_DELETING: 'TIME_REGISTRATION_TYPE_DELETING',
  TIME_REGISTRATION_TYPE_DELETED: 'TIME_REGISTRATION_TYPE_DELETED',
  TIME_REGISTRATION_TYPE_DELETE_FAILED: 'TIME_REGISTRATION_TYPE_DELETE_FAILED',
  SALARY_CYCLE_LOADING: 'SALARY_CYCLE_LOADING',
  SALARY_CYCLE_LOADED: 'SALARY_CYCLE_LOADED',
  SALARY_CYCLE_LOAD_FAILED: 'SALARY_CYCLE_LOAD_FAILED',
  ACCOUNTING_INTEGRATION_LOADING: 'ACCOUNTING_INTEGRATION_LOADING',
  ACCOUNTING_INTEGRATION_LOADED: 'ACCOUNTING_INTEGRATION_LOADED',
  ACCOUNTING_INTEGRATION_LOAD_FAILED: 'ACCOUNTING_INTEGRATION_LOAD_FAILED',
  ACCOUNTING_INTEGRATION_UPDATING: 'ACCOUNTING_INTEGRATION_UPDATING',
  ACCOUNTING_INTEGRATION_UPDATED: 'ACCOUNTING_INTEGRATION_UPDATED',
  ACCOUNTING_INTEGRATION_UPDATE_FAILED: 'ACCOUNTING_INTEGRATION_UPDATE_FAILED',
  ACCOUNTING_INTEGRATION_SYNCING: 'ACCOUNTING_INTEGRATION_SYNCING',
  ACCOUNTING_INTEGRATION_SYNCED: 'ACCOUNTING_INTEGRATION_SYNCED',
  ACCOUNTING_INTEGRATION_SYNC_FAILED: 'ACCOUNTING_INTEGRATION_SYNC_FAILED',
  ACCOUNT_PLANS_LOADING: 'ACCOUNT_PLANS_LOADING',
  ACCOUNT_PLANS_LOADED: 'ACCOUNT_PLANS_LOADED',
  ACCOUNT_PLANS_LOAD_FAILED: 'ACCOUNT_PLANS_LOAD_FAILED',
  ACCOUNT_PLANS_UPDATING: 'ACCOUNT_PLANS_UPDATING',
  ACCOUNT_PLANS_UPDATED: 'ACCOUNT_PLANS_UPDATED',
  ACCOUNT_PLANS_UPDATE_FAILED: 'ACCOUNT_PLANS_UPDATE_FAILED',
  PAYMENT_CONFIGURATIONS_LOADING: 'PAYMENT_CONFIGURATIONS_LOADING',
  PAYMENT_CONFIGURATIONS_LOADED: 'PAYMENT_CONFIGURATIONS_LOADED',
  PAYMENT_CONFIGURATIONS_LOAD_FAILED: 'PAYMENT_CONFIGURATIONS_LOAD_FAILED',
  COMPANY_PAYMENT_INTEGRATIONS_LOADING: 'COMPANY_PAYMENT_INTEGRATIONS_LOADING',
  COMPANY_PAYMENT_INTEGRATIONS_LOADED: 'COMPANY_PAYMENT_INTEGRATIONS_LOADED',
  COMPANY_PAYMENT_INTEGRATIONS_LOAD_FAILED: 'COMPANY_PAYMENT_INTEGRATIONS_LOAD_FAILED',
  COMPANY_PAYMENT_INTEGRATION_ADDED: 'COMPANY_PAYMENT_INTEGRATION_ADDED',
  COMPANY_PAYMENT_INTEGRATION_UPDATED: 'COMPANY_PAYMENT_INTEGRATION_UPDATED',
  COMPANY_PAYMENT_INTEGRATION_DELETED: 'COMPANY_PAYMENT_INTEGRATION_DELETED',
  PAYMENT_CONFIGURATION_ADDING: 'PAYMENT_CONFIGURATION_ADDING',
  PAYMENT_CONFIGURATION_ADDED: 'PAYMENT_CONFIGURATION_ADDED',
  PAYMENT_CONFIGURATION_ADD_FAILED: 'PAYMENT_CONFIGURATION_ADD_FAILED',
  PAYMENT_CONFIGURATION_UPDATING: 'PAYMENT_CONFIGURATION_UPDATING',
  PAYMENT_CONFIGURATION_UPDATED: 'PAYMENT_CONFIGURATION_UPDATED',
  PAYMENT_CONFIGURATION_UPDATE_FAILED: 'PAYMENT_CONFIGURATION_UPDATE_FAILED',
  PAYMENT_CONFIGURATION_DELETING: 'PAYMENT_CONFIGURATION_DELETING',
  PAYMENT_CONFIGURATION_DELETED: 'PAYMENT_CONFIGURATION_DELETED',
  PAYMENT_CONFIGURATION_DELETE_FAILED: 'PAYMENT_CONFIGURATION_DELETE_FAILED',
  PAY_SLIP_LOADING: 'PAY_SLIP_LOADING',
  PAY_SLIP_LOADED: 'PAY_SLIP_LOADED',
  PAY_SLIP_LOADED_PARTIAL: 'PAY_SLIP_LOADED_PARTIAL',
  PAY_SLIP_LOAD_FAILED: 'PAY_SLIP_LOAD_FAILED',
  PAY_SLIP_ADDED: 'PAY_SLIP_ADDED',
  PAY_SLIP_UPDATED: 'PAY_SLIP_UPDATED',
  PAY_SLIP_DELETED: 'PAY_SLIP_DELETED',
  TRANSFERS_LOADING: 'TRANSFERS_LOADING',
  TRANSFERS_LOADED: 'TRANSFERS_LOADED',
  TRANSFERS_LOADED_PARTIAL: 'TRANSFERS_LOADED_PARTIAL',
  TRANSFERS_LOAD_FAILED: 'TRANSFERS_LOAD_FAILED',
  TRANSFER_ADDED: 'TRANSFER_ADDED',
  TRANSFER_UPDATING: 'TRANSFER_UPDATING',
  TRANSFER_UPDATED: 'TRANSFER_UPDATED',
  TRANSFER_UPDATE_FAILED: 'TRANSFER_UPDATE_FAILED',
  TRANSFER_DELETED: 'TRANSFER_DELETED',
  INVOICES_LOADING: 'INVOICES_LOADING',
  INVOICES_LOADED: 'INVOICES_LOADED',
  INVOICES_LOADED_PARTIAL: 'INVOICES_LOADED_PARTIAL',
  INVOICES_LOAD_FAILED: 'INVOICES_LOAD_FAILED',
  INVOICE_ADDED: 'INVOICE_ADDED',
  INVOICE_UPDATED: 'INVOICE_UPDATED',
  INVOICE_DELETED: 'INVOICE_DELETED',
  START_BALANCE_LOADING: 'START_BALANCE_LOADING',
  START_BALANCE_LOADED: 'START_BALANCE_LOADED',
  START_BALANCE_LOAD_FAILED: 'START_BALANCE_LOAD_FAILED',
  START_BALANCE_UPDATING: 'START_BALANCE_UPDATING',
  START_BALANCE_UPDATED: 'START_BALANCE_UPDATED',
  START_BALANCE_UPDATE_FAILED: 'START_BALANCE_UPDATE_FAILED',
  PAY_ROLL_LOADING: 'PAY_ROLL_LOADING',
  PAY_ROLL_LOADED: 'PAY_ROLL_LOADED',
  PAY_ROLL_LOADED_PARTIAL: 'PAY_ROLL_LOADED_PARTIAL',
  PAY_ROLL_LOAD_FAILED: 'PAY_ROLL_LOAD_FAILED',
  PAY_ROLL_SINGLE_LOADING: 'PAY_ROLL_SINGLE_LOADING',
  PAY_ROLL_SINGLE_LOADED: 'PAY_ROLL_SINGLE_LOADED',
  PAY_ROLL_SINGLE_LOAD_FAILED: 'PAY_ROLL_SINGLE_LOAD_FAILED',
  PAY_ROLL_ADDING: 'PAY_ROLL_ADDING',
  PAY_ROLL_ADDED: 'PAY_ROLL_ADDED',
  PAY_ROLL_ADD_FAILED: 'PAY_ROLL_ADD_FAILED',
  PAY_ROLL_UPDATING: 'PAY_ROLL_UPDATING',
  PAY_ROLL_UPDATED: 'PAY_ROLL_UPDATED',
  PAY_ROLL_UPDATE_FAILED: 'PAY_ROLL_UPDATE_FAILED',
  PAY_ROLL_APPROVING: 'PAY_ROLL_APPROVING',
  PAY_ROLL_APPROVED: 'PAY_ROLL_APPROVED',
  PAY_ROLL_APPROVE_FAILED: 'PAY_ROLL_APPROVE_FAILED',
  PAY_ROLL_REJECTING: 'PAY_ROLL_REJECTING',
  PAY_ROLL_REJECTED: 'PAY_ROLL_REJECTED',
  PAY_ROLL_REJECT_FAILED: 'PAY_ROLL_REJECT_FAILED',
  PAY_ROLL_RESTARTING: 'PAY_ROLL_RESTARTING',
  PAY_ROLL_RESTARTED: 'PAY_ROLL_RESTARTED',
  PAY_ROLL_RESTART_FAILED: 'PAY_ROLL_RESTART_FAILED',
  PAY_ROLL_DELETING: 'PAY_ROLL_DELETING',
  PAY_ROLL_DELETED: 'PAY_ROLL_DELETED',
  PAY_ROLL_DELETE_FAILED: 'PAY_ROLL_DELETE_FAILED',
  PAY_ROLL_PAYMENT_METHOD_CHANGING: 'PAY_ROLL_PAYMENT_METHOD_CHANGING',
  PAY_ROLL_PAYMENT_METHOD_CHANGED: 'PAY_ROLL_PAYMENT_METHOD_CHANGED',
  PAY_ROLL_PAYMENT_METHOD_CHANGE_FAILED: 'PAY_ROLL_PAYMENT_METHOD_CHANGE_FAILED',
  PAY_ROLL_RESENDING_SKAT: 'PAY_ROLL_RESENDING_SKAT',
  PAY_ROLL_RESENT_SKAT: 'PAY_ROLL_RESENT_SKAT',
  PAY_ROLL_RESEND_SKAT_FAILED: 'PAY_ROLL_RESEND_SKAT_FAILED',
  VOUCHERS_LOADING: 'VOUCHERS_LOADING',
  VOUCHERS_LOADED: 'VOUCHERS_LOADED',
  VOUCHERS_LOAD_FAILED: 'VOUCHERS_LOAD_FAILED',
  VOUCHER_ADDED: 'VOUCHER_ADDED',
  VOUCHER_UPDATING: 'VOUCHER_UPDATING',
  VOUCHER_UPDATED: 'VOUCHER_UPDATED',
  VOUCHER_UPDATE_FAILED: 'VOUCHER_UPDATE_FAILED',
  VOUCHER_DELETED: 'VOUCHER_DELETED',
  PENSION_COMPANY_LOADING: 'PENSION_COMPANY_LOADING',
  PENSION_COMPANY_LOADED: 'PENSION_COMPANY_LOADED',
  PENSION_COMPANY_LOAD_FAILED: 'PENSION_COMPANY_LOAD_FAILED',
  BOOTSTRAP_LOADING: 'BOOTSTRAP_LOADING',
  BOOTSTRAP_LOADED: 'BOOTSTRAP_LOADED',
  BOOTSTRAP_LOAD_FAILED: 'BOOTSTRAP_LOAD_FAILED',
  EMPLOYMENT_LOADING: 'EMPLOYMENT_LOADING',
  EMPLOYMENT_LOADED: 'EMPLOYMENT_LOADED',
  EMPLOYMENT_LOAD_FAILED: 'EMPLOYMENT_LOAD_FAILED',
  EMPLOYMENT_ADDING: 'EMPLOYMENT_ADDING',
  EMPLOYMENT_ADDED: 'EMPLOYMENT_ADDED',
  EMPLOYMENT_ADD_FAILED: 'EMPLOYMENT_ADD_FAILED',
  EMPLOYMENT_UPDATING: 'EMPLOYMENT_UPDATING',
  EMPLOYMENT_UPDATED: 'EMPLOYMENT_UPDATED',
  EMPLOYMENT_UPDATE_FAILED: 'EMPLOYMENT_UPDATE_FAILED',
  EMPLOYEE_INVITE_SENDING: 'EMPLOYEE_INVITE_SENDING',
  EMPLOYEE_INVITE_SENT: 'EMPLOYEE_INVITE_SENT',
  EMPLOYEE_INVITE_SEND_FAILED: 'EMPLOYEE_INVITE_SEND_FAILED',
  EMPLOYEE_USER_DELETING: 'EMPLOYEE_USER_DELETING',
  EMPLOYEE_USER_DELETE: 'EMPLOYEE_USER_DELETE',
  EMPLOYEE_USER_DELETE_FAILED: 'EMPLOYEE_USER_DELETE_FAILED',
  USER_COMPANY_LOADING: 'USER_COMPANY_LOADING',
  USER_COMPANY_LOADED: 'USER_COMPANY_LOADED',
  USER_COMPANY_LOAD_FAILED: 'USER_COMPANY_LOAD_FAILED',
  USER_COMPANY_ADDING: 'USER_COMPANY_ADDING',
  USER_COMPANY_ADDED: 'USER_COMPANY_ADDED',
  USER_COMPANY_ADD_FAILED: 'USER_COMPANY_ADD_FAILED',
  USER_COMPANY_UPDATING: 'USER_COMPANY_UPDATING',
  USER_COMPANY_UPDATED: 'USER_COMPANY_UPDATED',
  USER_COMPANY_UPDATE_FAILED: 'USER_COMPANY_UPDATE_FAILED',
  SUPPORT_ACCESS_GRANTING: 'SUPPORT_ACCESS_GRANTING',
  SUPPORT_ACCESS_GRANTED: 'SUPPORT_ACCESS_GRANTED',
  SUPPORT_ACCESS_GRANT_FAILED: 'SUPPORT_ACCESS_GRANT_FAILED',
  SUPPORT_ACCESS_REVOKING: 'SUPPORT_ACCESS_REVOKING',
  SUPPORT_ACCESS_REVOKED: 'SUPPORT_ACCESS_REVOKED',
  SUPPORT_ACCESS_REVOKE_FAILED: 'SUPPORT_ACCESS_REVOKE_FAILED',
  SUPPORT_ACCESS_LOADING: 'SUPPORT_ACCESS_LOADING',
  SUPPORT_ACCESS_LOADED: 'SUPPORT_ACCESS_LOADED',
  SUPPORT_ACCESS_LOAD_FAILED: 'SUPPORT_ACCESS_LOAD_FAILED',
  SUPPORT_COMPANIES_LOADING: 'SUPPORT_COMPANIES_LOADING',
  SUPPORT_COMPANIES_LOADED: 'SUPPORT_COMPANIES_LOADED',
  SUPPORT_COMPANIES_LOAD_FAILED: 'SUPPORT_COMPANIES_LOAD_FAILED',
  PRICING_PACKAGES_LOADING: 'PRICING_PACKAGES_LOADING',
  PRICING_PACKAGES_LOADED: 'PRICING_PACKAGES_LOADED',
  PRICING_PACKAGES_LOAD_FAILED: 'PRICING_PACKAGES_LOAD_FAILED',
  COMPANY_PRICING_PACKAGES_LOADING: 'COMPANY_PRICING_PACKAGES_LOADING',
  COMPANY_PRICING_PACKAGES_LOADED: 'COMPANY_PRICING_PACKAGES_LOADED',
  COMPANY_PRICING_PACKAGES_LOAD_FAILED: 'COMPANY_PRICING_PACKAGES_LOAD_FAILED',
  COMPANY_PRICING_PACKAGE_ADDING: 'COMPANY_PRICING_PACKAGE_ADDING',
  COMPANY_PRICING_PACKAGE_ADDED: 'COMPANY_PRICING_PACKAGE_ADDED',
  COMPANY_PRICING_PACKAGE_ADD_FAILED: 'COMPANY_PRICING_PACKAGE_ADD_FAILED',
  COMPANY_PRICING_PACKAGE_UPDATING: 'COMPANY_PRICING_PACKAGE_UPDATING',
  COMPANY_PRICING_PACKAGE_UPDATED: 'COMPANY_PRICING_PACKAGE_UPDATED',
  COMPANY_PRICING_PACKAGE_UPDATE_FAILED: 'COMPANY_PRICING_PACKAGE_UPDATE_FAILED',
  COMPANY_PRICING_PACKAGE_DELETING: 'COMPANY_PRICING_PACKAGE_DELETING',
  COMPANY_PRICING_PACKAGE_DELETED: 'COMPANY_PRICING_PACKAGE_DELETED',
  COMPANY_PRICING_PACKAGE_DELETE_FAILED: 'COMPANY_PRICING_PACKAGE_DELETE_FAILED',
  COMPANY_USERS_LOADING: 'COMPANY_USERS_LOADING',
  COMPANY_USERS_LOADED: 'COMPANY_USERS_LOADED',
  COMPANY_USERS_LOADED_PARTIAL: 'COMPANY_USERS_LOADED_PARTIAL',
  COMPANY_USERS_LOAD_FAILED: 'COMPANY_USERS_LOAD_FAILED',
  COMPANY_USER_DELETING: 'COMPANY_USER_DELETING',
  COMPANY_USER_DELETED: 'COMPANY_USER_DELETED',
  COMPANY_USER_DELETE_FAILED: 'COMPANY_USER_DELETE_FAILED',
  COMPANY_LOGO_LOADING: 'COMPANY_LOGO_LOADING',
  COMPANY_LOGO_LOADED: 'COMPANY_LOGO_LOADED',
  COMPANY_LOGO_LOAD_FAILED: 'COMPANY_LOGO_LOAD_FAILED',
  COMPANY_LOGO_UPDATING: 'COMPANY_LOGO_UPDATING',
  COMPANY_LOGO_UPDATED: 'COMPANY_LOGO_UPDATED',
  COMPANY_LOGO_UPDATE_FAILED: 'COMPANY_LOGO_UPDATE_FAILED',
  COMPANY_LOGO_DELETING: 'COMPANY_LOGO_DELETING',
  COMPANY_LOGO_DELETED: 'COMPANY_LOGO_DELETED',
  COMPANY_LOGO_DELETE_FAILED: 'COMPANY_LOGO_DELETE_FAILED',
  USER_INVITES_LOADING: 'USER_INVITES_LOADING',
  USER_INVITES_LOADED: 'USER_INVITES_LOADED',
  USER_INVITES_LOAD_FAILED: 'USER_INVITES_LOAD_FAILED',
  USER_INVITE_ADDING: 'USER_INVITE_ADDING',
  USER_INVITE_ADDED: 'USER_INVITE_ADDED',
  USER_INVITE_ADD_FAILED: 'USER_INVITE_ADD_FAILED',
  USER_INVITE_DELETING: 'USER_INVITE_DELETING',
  USER_INVITE_DELETED: 'USER_INVITE_DELETED',
  USER_INVITE_DELETE_FAILED: 'USER_INVITE_DELETE_FAILED',
  USER_COMPANY_GROUP_INVITES_LOADING: 'USER_COMPANY_GROUP_INVITES_LOADING',
  USER_COMPANY_GROUP_INVITES_LOADED: 'USER_COMPANY_GROUP_INVITES_LOADED',
  USER_COMPANY_GROUP_INVITES_LOAD_FAILED: 'USER_COMPANY_GROUP_INVITES_LOAD_FAILED',
  USER_COMPANY_GROUP_INVITE_ADDING: 'USER_COMPANY_GROUP_INVITE_ADDING',
  USER_COMPANY_GROUP_INVITE_ADDED: 'USER_COMPANY_GROUP_INVITE_ADDED',
  USER_COMPANY_GROUP_INVITE_ADD_FAILED: 'USER_COMPANY_GROUP_INVITE_ADD_FAILED',
  USER_COMPANY_GROUP_INVITE_DELETING: 'USER_COMPANY_GROUP_INVITE_DELETING',
  USER_COMPANY_GROUP_INVITE_DELETED: 'USER_COMPANY_GROUP_INVITE_DELETED',
  USER_COMPANY_GROUP_INVITE_DELETE_FAILED: 'USER_COMPANY_GROUP_INVITE_DELETE_FAILED',
  COMPANY_USER_PERMISSION_GRANTING: 'COMPANY_USER_PERMISSION_GRANTING',
  COMPANY_USER_PERMISSION_GRANTED: 'COMPANY_USER_PERMISSION_GRANTED',
  COMPANY_USER_PERMISSION_GRANT_FAILED: 'COMPANY_USER_PERMISSION_GRANT_FAILED',
  COMPANY_USER_PERMISSION_REVOKING: 'COMPANY_USER_PERMISSION_REVOKING',
  COMPANY_USER_PERMISSION_REVOKED: 'COMPANY_USER_PERMISSION_REVOKED',
  COMPANY_USER_PERMISSION_REVOKE_FAILED: 'COMPANY_USER_PERMISSION_REVOKE_FAILED',
  EMPLOYMENT_POSITIONS_LOADING: 'EMPLOYMENT_POSITIONS_LOADING',
  EMPLOYMENT_POSITIONS_LOADED: 'EMPLOYMENT_POSITIONS_LOADED',
  EMPLOYMENT_POSITIONS_LOAD_FAILED: 'EMPLOYMENT_POSITIONS_LOAD_FAILED',
  BANKS_LOADING: 'BANKS_LOADING',
  BANKS_LOADED: 'BANKS_LOADED',
  BANKS_LOAD_FAILED: 'BANKS_LOAD_FAILED',
  LEAVE_TYPES_LOADING: 'LEAVE_TYPES_LOADING',
  LEAVE_TYPES_LOADED: 'LEAVE_TYPES_LOADED',
  LEAVE_TYPES_LOAD_FAILED: 'LEAVE_TYPES_LOAD_FAILED',
  LEAVE_TYPES_UPDATING: 'LEAVE_TYPES_UPDATING',
  LEAVE_TYPES_UPDATED: 'LEAVE_TYPES_UPDATED',
  LEAVE_TYPES_UPDATE_FAILED: 'LEAVE_TYPES_UPDATE_FAILED',
  SUPPLEMENT_TYPES_LOADING: 'SUPPLEMENT_TYPES_LOADING',
  SUPPLEMENT_TYPES_LOADED: 'SUPPLEMENT_TYPES_LOADED',
  SUPPLEMENT_TYPES_LOAD_FAILED: 'SUPPLEMENT_TYPES_LOAD_FAILED',
  SALARY_TYPES_LOADING: 'SALARY_TYPES_LOADING',
  SALARY_TYPES_LOADED: 'SALARY_TYPES_LOADED',
  SALARY_TYPES_LOAD_FAILED: 'SALARY_TYPES_LOAD_FAILED',
  SALARY_TYPE_ADDING: 'SALARY_TYPE_ADDING',
  SALARY_TYPE_ADDED: 'SALARY_TYPE_ADDED',
  SALARY_TYPE_ADD_FAILED: 'SALARY_TYPE_ADD_FAILED',
  SALARY_TYPE_UPDATING: 'SALARY_TYPE_UPDATING',
  SALARY_TYPE_UPDATED: 'SALARY_TYPE_UPDATED',
  SALARY_TYPE_UPDATE_FAILED: 'SALARY_TYPE_UPDATE_FAILED',
  SALARY_TYPE_ACTIVE_TOGGLING: 'SALARY_TYPE_ACTIVE_TOGGLING',
  SALARY_TYPE_ACTIVE_TOGGLED: 'SALARY_TYPE_ACTIVE_TOGGLED',
  SALARY_TYPE_ACTIVE_TOGGLE_FAILED: 'SALARY_TYPE_ACTIVE_TOGGLE_FAILED',
  ALERT_ADDED: 'ALERT_ADDED',
  ALERT_REMOVED: 'ALERT_REMOVED',
  MODAL_ADDED: 'MODAL_ADDED',
  MODAL_REMOVED: 'MODAL_REMOVED',
  COMPANY_FEATURES_LOADING: 'COMPANY_FEATURES_LOADING',
  COMPANY_FEATURES_LOADED: 'COMPANY_FEATURES_LOADED',
  COMPANY_FEATURES_LOAD_FAILED: 'COMPANY_FEATURES_LOAD_FAILED',
  COMPANY_FEATURE_ADDED: 'COMPANY_FEATURE_ADDED',
  COMPANY_FEATURE_DELETED: 'COMPANY_FEATURE_DELETED',
  COMPANY_PRICINGS_LOADING: 'COMPANY_PRICINGS_LOADING',
  COMPANY_PRICINGS_LOADED: 'COMPANY_PRICINGS_LOADED',
  COMPANY_PRICINGS_LOAD_FAILED: 'COMPANY_PRICINGS_LOAD_FAILED',
  START_BALANCES_LOADING: 'START_BALANCES_LOADING',
  START_BALANCES_LOADED: 'START_BALANCES_LOADED',
  START_BALANCES_LOAD_FAILED: 'START_BALANCES_LOAD_FAILED',
  START_BALANCES_UPDATING: 'START_BALANCES_UPDATING',
  START_BALANCES_UPDATED: 'START_BALANCES_UPDATED',
  START_BALANCES_UPDATE_FAILED: 'START_BALANCES_UPDATE_FAILED',
  WARNINGS_LOADING: 'WARNINGS_LOADING',
  WARNINGS_LOADED: 'WARNINGS_LOADED',
  WARNINGS_LOADED_PARTIAL: 'WARNINGS_LOADED_PARTIAL',
  WARNINGS_LOAD_FAILED: 'WARNINGS_LOAD_FAILED',
  WARNING_ADDED: 'WARNING_ADDED',
  WARNING_UPDATED: 'WARNING_UPDATED',
  WARNING_DELETING: 'WARNING_DELETING',
  WARNING_DELETED: 'WARNING_DELETED',
  WARNING_DELETE_FAILED: 'WARNING_DELETE_FAILED',
  COMPANY_BANK_ACCOUNT_ADDING: 'COMPANY_BANK_ACCOUNT_ADDING',
  COMPANY_BANK_ACCOUNT_ADDED: 'COMPANY_BANK_ACCOUNT_ADDED',
  COMPANY_BANK_ACCOUNT_ADD_FAILED: 'COMPANY_BANK_ACCOUNT_ADD_FAILED',
  ONE_TIME_PAY_LOADING: 'ONE_TIME_PAY_LOADING',
  ONE_TIME_PAY_LOADED: 'ONE_TIME_PAY_LOADED',
  ONE_TIME_PAY_LOADED_PARTIAL: 'ONE_TIME_PAY_LOADED_PARTIAL',
  ONE_TIME_PAY_LOAD_FAILED: 'ONE_TIME_PAY_LOAD_FAILED',
  ONE_TIME_PAY_ADDING: 'ONE_TIME_PAY_ADDING',
  ONE_TIME_PAY_ADDED: 'ONE_TIME_PAY_ADDED',
  ONE_TIME_PAY_ADD_FAILED: 'ONE_TIME_PAY_ADD_FAILED',
  ONE_TIME_PAY_UPDATING: 'ONE_TIME_PAY_UPDATING',
  ONE_TIME_PAY_UPDATED: 'ONE_TIME_PAY_UPDATED',
  ONE_TIME_PAY_UPDATE_FAILED: 'ONE_TIME_PAY_UPDATE_FAILED',
  ONE_TIME_PAY_APPROVING: 'ONE_TIME_PAY_APPROVING',
  ONE_TIME_PAY_APPROVED: 'ONE_TIME_PAY_APPROVED',
  ONE_TIME_PAY_APPROVE_FAILED: 'ONE_TIME_PAY_APPROVE_FAILED',
  ONE_TIME_PAY_DELETING: 'ONE_TIME_PAY_DELETING',
  ONE_TIME_PAY_DELETED: 'ONE_TIME_PAY_DELETED',
  ONE_TIME_PAY_DELETE_FAILED: 'ONE_TIME_PAY_DELETE_FAILED',
  ONE_TIME_PAY_PENSION_LOADING: 'ONE_TIME_PAY_PENSION_LOADING',
  ONE_TIME_PAY_PENSION_LOADED: 'ONE_TIME_PAY_PENSION_LOADED',
  ONE_TIME_PAY_PENSION_LOADED_PARTIAL: 'ONE_TIME_PAY_PENSION_LOADED_PARTIAL',
  ONE_TIME_PAY_PENSION_LOAD_FAILED: 'ONE_TIME_PAY_PENSION_LOAD_FAILED',
  ONE_TIME_PAY_PENSION_ADDING: 'ONE_TIME_PAY_PENSION_ADDING',
  ONE_TIME_PAY_PENSION_ADDED: 'ONE_TIME_PAY_PENSION_ADDED',
  ONE_TIME_PAY_PENSION_ADD_FAILED: 'ONE_TIME_PAY_PENSION_ADD_FAILED',
  ONE_TIME_PAY_PENSION_UPDATING: 'ONE_TIME_PAY_PENSION_UPDATING',
  ONE_TIME_PAY_PENSION_UPDATED: 'ONE_TIME_PAY_PENSION_UPDATED',
  ONE_TIME_PAY_PENSION_UPDATE_FAILED: 'ONE_TIME_PAY_PENSION_UPDATE_FAILED',
  USER_UPDATING: 'USER_UPDATING',
  USER_UPDATED: 'USER_UPDATED',
  USER_UPDATE_FAILED: 'USER_UPDATE_FAILED',
  USER_REQUESTING_EMAIL_CHANGE: 'USER_REQUESTING_EMAIL_CHANGE',
  USER_REQUESTED_EMAIL_CHANGE: 'USER_REQUESTED_EMAIL_CHANGE',
  USER_REQUEST_EMAIL_CHANGE_FAILED: 'USER_REQUEST_EMAIL_CHANGE_FAILED',
  USER_CONFIRMING_EMAIL_CHANGE: 'USER_CONFIRMING_EMAIL_CHANGE',
  USER_CONFIRMED_EMAIL_CHANGE: 'USER_CONFIRMED_EMAIL_CHANGE',
  USER_CONFIRM_EMAIL_CHANGE_FAILED: 'USER_CONFIRM_EMAIL_CHANGE_FAILED',
  USER_VERIFYING_EMAIL: 'USER_VERIFYING_EMAIL',
  USER_VERIFIED_EMAIL: 'USER_VERIFIED_EMAIL',
  USER_VERIFY_EMAIL_FAILED: 'USER_VERIFY_EMAIL_FAILED',
  USER_CHANGING_PASSWORD: 'USER_CHANGING_PASSWORD',
  USER_CHANGED_PASSWORD: 'USER_CHANGED_PASSWORD',
  USER_CHANGE_PASSWORD_FAILED: 'USER_CHANGE_PASSWORD_FAILED',
  USER_ACCEPTING_INVITE: 'USER_ACCEPTING_INVITE',
  USER_ACCEPTED_INVITE: 'USER_ACCEPTED_INVITE',
  USER_ACCEPT_INVITE_FAILED: 'USER_ACCEPT_INVITE_FAILED',
  EMPLOYEE_PROFILE_IMAGE_UPDATING: 'EMPLOYEE_PROFILE_IMAGE_UPDATING',
  EMPLOYEE_PROFILE_IMAGE_UPDATED: 'EMPLOYEE_PROFILE_IMAGE_UPDATED',
  EMPLOYEE_PROFILE_IMAGE_UPDATE_FAILED: 'EMPLOYEE_PROFILE_IMAGE_UPDATE_FAILED',
  EMPLOYEE_PROFILE_IMAGE_DELETING: 'EMPLOYEE_PROFILE_IMAGE_DELETING',
  EMPLOYEE_PROFILE_IMAGE_DELETED: 'EMPLOYEE_PROFILE_IMAGE_DELETED',
  EMPLOYEE_PROFILE_IMAGE_DELETE_FAILED: 'EMPLOYEE_PROFILE_IMAGE_DELETE_FAILED',
  NETS_MESSAGES_LOADING: 'NETS_MESSAGES_LOADING',
  NETS_MESSAGES_LOADED: 'NETS_MESSAGES_LOADED',
  NETS_MESSAGES_LOADED_PARTIAL: 'NETS_MESSAGES_LOADED_PARTIAL',
  NETS_MESSAGES_LOAD_FAILED: 'NETS_MESSAGES_LOAD_FAILED',
  INCOME_TAX_REPORTS_LOADING: 'INCOME_TAX_REPORTS_LOADING',
  INCOME_TAX_REPORTS_LOADED: 'INCOME_TAX_REPORTS_LOADED',
  INCOME_TAX_REPORTS_LOADED_PARTIAL: 'INCOME_TAX_REPORTS_LOADED_PARTIAL',
  INCOME_TAX_REPORTS_LOAD_FAILED: 'INCOME_TAX_REPORTS_LOAD_FAILED',
  INCOME_TAX_REPORT_ADDED: 'INCOME_TAX_REPORT_ADDED',
  INCOME_TAX_REPORT_UPDATED: 'INCOME_TAX_REPORT_UPDATED',
  INCOME_TAX_REPORT_DELETED: 'INCOME_TAX_REPORT_DELETED',
  DEPARTMENTS_LOADING: 'DEPARTMENTS_LOADING',
  DEPARTMENTS_LOADED: 'DEPARTMENTS_LOADED',
  DEPARTMENTS_LOAD_FAILED: 'DEPARTMENTS_LOAD_FAILED',
  DEPARTMENT_ADDING: 'DEPARTMENT_ADDING',
  DEPARTMENT_ADDED: 'DEPARTMENT_ADDED',
  DEPARTMENT_ADD_FAILED: 'DEPARTMENT_ADD_FAILED',
  DEPARTMENT_UPDATING: 'DEPARTMENT_UPDATING',
  DEPARTMENT_UPDATED: 'DEPARTMENT_UPDATED',
  DEPARTMENT_UPDATE_FAILED: 'DEPARTMENT_UPDATE_FAILED',
  DEPARTMENT_DELETING: 'DEPARTMENT_DELETING',
  DEPARTMENT_DELETED: 'DEPARTMENT_DELETED',
  DEPARTMENT_DELETE_FAILED: 'DEPARTMENT_DELETE_FAILED',
  COARSE_TIME_REGISTRATION_LOADING: 'COARSE_TIME_REGISTRATION_LOADING',
  COARSE_TIME_REGISTRATION_LOADED: 'COARSE_TIME_REGISTRATION_LOADED',
  COARSE_TIME_REGISTRATION_LOAD_FAILED: 'COARSE_TIME_REGISTRATION_LOAD_FAILED',
  COARSE_TIME_REGISTRATION_CREATING: 'COARSE_TIME_REGISTRATION_CREATING',
  COARSE_TIME_REGISTRATION_CREATED: 'COARSE_TIME_REGISTRATION_CREATED',
  COARSE_TIME_REGISTRATION_CREATE_FAILED: 'COARSE_TIME_REGISTRATION_CREATE_FAILED',
  COARSE_TIME_REGISTRATION_UPDATING: 'COARSE_TIME_REGISTRATION_UPDATING',
  COARSE_TIME_REGISTRATION_UPDATED: 'COARSE_TIME_REGISTRATION_UPDATED',
  COARSE_TIME_REGISTRATION_UPDATE_FAILED: 'COARSE_TIME_REGISTRATION_UPDATE_FAILED',
  COARSE_TIME_REGISTRATION_DELETING: 'COARSE_TIME_REGISTRATION_DELETING',
  COARSE_TIME_REGISTRATION_DELETED: 'COARSE_TIME_REGISTRATION_DELETED',
  COARSE_TIME_REGISTRATION_DELETE_FAILED: 'COARSE_TIME_REGISTRATION_DELETE_FAILED',
  COARSE_SALARY_REGISTRATION_LOADING: 'COARSE_SALARY_REGISTRATION_LOADING',
  COARSE_SALARY_REGISTRATION_LOADED: 'COARSE_SALARY_REGISTRATION_LOADED',
  COARSE_SALARY_REGISTRATION_LOAD_FAILED: 'COARSE_SALARY_REGISTRATION_LOAD_FAILED',
  COARSE_SALARY_REGISTRATION_CREATING: 'COARSE_SALARY_REGISTRATION_CREATING',
  COARSE_SALARY_REGISTRATION_CREATED: 'COARSE_SALARY_REGISTRATION_CREATED',
  COARSE_SALARY_REGISTRATION_CREATE_FAILED: 'COARSE_SALARY_REGISTRATION_CREATE_FAILED',
  COARSE_SALARY_REGISTRATION_UPDATING: 'COARSE_SALARY_REGISTRATION_UPDATING',
  COARSE_SALARY_REGISTRATION_UPDATED: 'COARSE_SALARY_REGISTRATION_UPDATED',
  COARSE_SALARY_REGISTRATION_UPDATE_FAILED: 'COARSE_SALARY_REGISTRATION_UPDATE_FAILED',
  COARSE_SALARY_REGISTRATION_DELETING: 'COARSE_SALARY_REGISTRATION_DELETING',
  COARSE_SALARY_REGISTRATION_DELETED: 'COARSE_SALARY_REGISTRATION_DELETED',
  COARSE_SALARY_REGISTRATION_DELETE_FAILED: 'COARSE_SALARY_REGISTRATION_DELETE_FAILED',
  SALARY_REGISTRATION_LOADING: 'SALARY_REGISTRATION_LOADING',
  SALARY_REGISTRATION_LOADED: 'SALARY_REGISTRATION_LOADED',
  SALARY_REGISTRATION_LOADED_PARTIAL: 'SALARY_REGISTRATION_LOADED_PARTIAL',
  SALARY_REGISTRATION_LOAD_FAILED: 'SALARY_REGISTRATION_LOAD_FAILED',
  SALARY_REGISTRATION_CREATING: 'SALARY_REGISTRATION_CREATING',
  SALARY_REGISTRATION_CREATED: 'SALARY_REGISTRATION_CREATED',
  SALARY_REGISTRATION_CREATE_FAILED: 'SALARY_REGISTRATION_CREATE_FAILED',
  SALARY_REGISTRATION_APPROVING: 'SALARY_REGISTRATION_APPROVING',
  SALARY_REGISTRATION_APPROVED: 'SALARY_REGISTRATION_APPROVED',
  SALARY_REGISTRATION_APPROVE_FAILED: 'SALARY_REGISTRATION_APPROVE_FAILED',
  SALARY_REGISTRATION_UPDATING: 'SALARY_REGISTRATION_UPDATING',
  SALARY_REGISTRATION_UPDATED: 'SALARY_REGISTRATION_UPDATED',
  SALARY_REGISTRATION_UPDATE_FAILED: 'SALARY_REGISTRATION_UPDATE_FAILED',
  SALARY_REGISTRATION_DELETING: 'SALARY_REGISTRATION_DELETING',
  SALARY_REGISTRATION_DELETED: 'SALARY_REGISTRATION_DELETED',
  SALARY_REGISTRATION_DELETE_FAILED: 'SALARY_REGISTRATION_DELETE_FAILED',
  LEAVE_BALANCES_LOADING: 'LEAVE_BALANCES_LOADING',
  LEAVE_BALANCES_LOADED: 'LEAVE_BALANCES_LOADED',
  LEAVE_BALANCES_LOAD_FAILED: 'LEAVE_BALANCES_LOAD_FAILED',
  LEAVE_BALANCE_UPDATED: 'LEAVE_BALANCE_UPDATED',
  SUPPLEMENT_BALANCES_LOADING: 'SUPPLEMENT_BALANCES_LOADING',
  SUPPLEMENT_BALANCES_LOADED: 'SUPPLEMENT_BALANCES_LOADED',
  SUPPLEMENT_BALANCES_LOADED_PARTIAL: 'SUPPLEMENT_BALANCES_LOADED_PARTIAL',
  SUPPLEMENT_BALANCES_LOAD_FAILED: 'SUPPLEMENT_BALANCES_LOAD_FAILED',
  SUPPLEMENT_BALANCE_UPDATED: 'SUPPLEMENT_BALANCE_UPDATED',
  LEAVE_ADJUSTMENTS_LOADING: 'LEAVE_ADJUSTMENTS_LOADING',
  LEAVE_ADJUSTMENTS_LOADED: 'LEAVE_ADJUSTMENTS_LOADED',
  LEAVE_ADJUSTMENTS_LOAD_FAILED: 'LEAVE_ADJUSTMENTS_LOAD_FAILED',
  LEAVE_ADJUSTMENTS_ADDING: 'LEAVE_ADJUSTMENTS_ADDING',
  LEAVE_ADJUSTMENTS_ADDED: 'LEAVE_ADJUSTMENTS_ADDED',
  LEAVE_ADJUSTMENTS_ADD_FAILED: 'LEAVE_ADJUSTMENTS_ADD_FAILED',
  LEAVE_ADJUSTMENTS_UPDATING: 'LEAVE_ADJUSTMENTS_UPDATING',
  LEAVE_ADJUSTMENTS_UPDATED: 'LEAVE_ADJUSTMENTS_UPDATED',
  LEAVE_ADJUSTMENTS_UPDATE_FAILED: 'LEAVE_ADJUSTMENTS_UPDATE_FAILED',
  LEAVE_ADJUSTMENTS_DELETING: 'LEAVE_ADJUSTMENTS_DELETING',
  LEAVE_ADJUSTMENTS_DELETED: 'LEAVE_ADJUSTMENTS_DELETED',
  LEAVE_ADJUSTMENTS_DELETE_FAILED: 'LEAVE_ADJUSTMENTS_DELETE_FAILED',
  SUPPLEMENT_ADJUSTMENTS_LOADING: 'SUPPLEMENT_ADJUSTMENTS_LOADING',
  SUPPLEMENT_ADJUSTMENTS_LOADED: 'SUPPLEMENT_ADJUSTMENTS_LOADED',
  SUPPLEMENT_ADJUSTMENTS_LOAD_FAILED: 'SUPPLEMENT_ADJUSTMENTS_LOAD_FAILED',
  SUPPLEMENT_ADJUSTMENTS_ADDING: 'SUPPLEMENT_ADJUSTMENTS_ADDING',
  SUPPLEMENT_ADJUSTMENTS_ADDED: 'SUPPLEMENT_ADJUSTMENTS_ADDED',
  SUPPLEMENT_ADJUSTMENTS_ADD_FAILED: 'SUPPLEMENT_ADJUSTMENTS_ADD_FAILED',
  SUPPLEMENT_ADJUSTMENTS_UPDATING: 'SUPPLEMENT_ADJUSTMENTS_UPDATING',
  SUPPLEMENT_ADJUSTMENTS_UPDATED: 'SUPPLEMENT_ADJUSTMENTS_UPDATED',
  SUPPLEMENT_ADJUSTMENTS_UPDATE_FAILED: 'SUPPLEMENT_ADJUSTMENTS_UPDATE_FAILED',
  SUPPLEMENT_ADJUSTMENTS_DELETING: 'SUPPLEMENT_ADJUSTMENTS_DELETING',
  SUPPLEMENT_ADJUSTMENTS_DELETED: 'SUPPLEMENT_ADJUSTMENTS_DELETED',
  SUPPLEMENT_ADJUSTMENTS_DELETE_FAILED: 'SUPPLEMENT_ADJUSTMENTS_DELETE_FAILED',
  CAR_ALLOWANCES_LOADING: 'CAR_ALLOWANCES_LOADING',
  CAR_ALLOWANCES_LOADED: 'CAR_ALLOWANCES_LOADED',
  CAR_ALLOWANCES_LOAD_FAILED: 'CAR_ALLOWANCES_LOAD_FAILED',
  CAR_ALLOWANCE_CREATED: 'CAR_ALLOWANCE_CREATED',
  CAR_ALLOWANCES_CREATING: 'CAR_ALLOWANCES_CREATING',
  CAR_ALLOWANCES_CREATED: 'CAR_ALLOWANCES_CREATED',
  CAR_ALLOWANCES_CREATE_FAILED: 'CAR_ALLOWANCES_CREATE_FAILED',
  CAR_ALLOWANCE_APPROVING: 'CAR_ALLOWANCE_APPROVING',
  CAR_ALLOWANCE_APPROVED: 'CAR_ALLOWANCE_APPROVED',
  CAR_ALLOWANCE_APPROVE_FAILED: 'CAR_ALLOWANCE_APPROVE_FAILED',
  CAR_ALLOWANCE_UPDATING: 'CAR_ALLOWANCE_UPDATING',
  CAR_ALLOWANCE_UPDATED: 'CAR_ALLOWANCE_UPDATED',
  CAR_ALLOWANCE_UPDATE_FAILED: 'CAR_ALLOWANCE_UPDATE_FAILED',
  CAR_ALLOWANCE_DELETING: 'CAR_ALLOWANCE_DELETING',
  CAR_ALLOWANCE_DELETED: 'CAR_ALLOWANCE_DELETED',
  CAR_ALLOWANCE_DELETE_FAILED: 'CAR_ALLOWANCE_DELETE_FAILED',
  COARSE_CAR_ALLOWANCE_LOADING: 'COARSE_CAR_ALLOWANCE_LOADING',
  COARSE_CAR_ALLOWANCE_LOADED: 'COARSE_CAR_ALLOWANCE_LOADED',
  COARSE_CAR_ALLOWANCE_LOAD_FAILED: 'COARSE_CAR_ALLOWANCE_LOAD_FAILED',
  COARSE_CAR_ALLOWANCE_CREATING: 'COARSE_CAR_ALLOWANCE_CREATING',
  COARSE_CAR_ALLOWANCE_CREATED: 'COARSE_CAR_ALLOWANCE_CREATED',
  COARSE_CAR_ALLOWANCE_CREATE_FAILED: 'COARSE_CAR_ALLOWANCE_CREATE_FAILED',
  COARSE_CAR_ALLOWANCE_UPDATING: 'COARSE_CAR_ALLOWANCE_UPDATING',
  COARSE_CAR_ALLOWANCE_UPDATED: 'COARSE_CAR_ALLOWANCE_UPDATED',
  COARSE_CAR_ALLOWANCE_UPDATE_FAILED: 'COARSE_CAR_ALLOWANCE_UPDATE_FAILED',
  COARSE_CAR_ALLOWANCE_DELETING: 'COARSE_CAR_ALLOWANCE_DELETING',
  COARSE_CAR_ALLOWANCE_DELETED: 'COARSE_CAR_ALLOWANCE_DELETED',
  COARSE_CAR_ALLOWANCE_DELETE_FAILED: 'COARSE_CAR_ALLOWANCE_DELETE_FAILED',
  FREELANCE_CONTRACT_LOADING: 'FREELANCE_CONTRACT_LOADING',
  FREELANCE_CONTRACT_LOADED: 'FREELANCE_CONTRACT_LOADED',
  FREELANCE_CONTRACT_LOAD_FAILED: 'FREELANCE_CONTRACT_LOAD_FAILED',
  FREELANCE_CONTRACT_ADDING: 'FREELANCE_CONTRACT_ADDING',
  FREELANCE_CONTRACT_ADDED: 'FREELANCE_CONTRACT_ADDED',
  FREELANCE_CONTRACT_ADD_FAILED: 'FREELANCE_CONTRACT_ADD_FAILED',
  FREELANCE_CONTRACT_UPDATING: 'FREELANCE_CONTRACT_UPDATING',
  FREELANCE_CONTRACT_UPDATED: 'FREELANCE_CONTRACT_UPDATED',
  FREELANCE_CONTRACT_UPDATE_FAILED: 'FREELANCE_CONTRACT_UPDATE_FAILED',
  INVOICED_SALARY_LOADING: 'INVOICED_SALARY_LOADING',
  INVOICED_SALARY_LOADED: 'INVOICED_SALARY_LOADED',
  INVOICED_SALARY_LOAD_FAILED: 'INVOICED_SALARY_LOAD_FAILED',
  INVOICED_SALARY_ADDING: 'INVOICED_SALARY_ADDING',
  INVOICED_SALARY_ADDED: 'INVOICED_SALARY_ADDED',
  INVOICED_SALARY_ADD_FAILED: 'INVOICED_SALARY_ADD_FAILED',
  INVOICED_SALARY_UPDATING: 'INVOICED_SALARY_UPDATING',
  INVOICED_SALARY_UPDATED: 'INVOICED_SALARY_UPDATED',
  INVOICED_SALARY_UPDATE_FAILED: 'INVOICED_SALARY_UPDATE_FAILED',
  INVOICED_SALARY_DELETING: 'INVOICED_SALARY_DELETING',
  INVOICED_SALARY_DELETED: 'INVOICED_SALARY_DELETED',
  INVOICED_SALARY_DELETE_FAILED: 'INVOICED_SALARY_DELETE_FAILED',
  PAY_INVOICE_LOADING: 'PAY_INVOICE_LOADING',
  PAY_INVOICE_LOADED: 'PAY_INVOICE_LOADED',
  PAY_INVOICE_LOAD_FAILED: 'PAY_INVOICE_LOAD_FAILED',
  PAY_INVOICE_ADDED: 'PAY_INVOICE_ADDED',
  PAY_INVOICE_UPDATED: 'PAY_INVOICE_UPDATED',
  PAY_INVOICE_DELETED: 'PAY_INVOICE_DELETED',
  AGREEMENT_LOADING: 'AGREEMENT_LOADING',
  AGREEMENT_LOADED: 'AGREEMENT_LOADED',
  AGREEMENT_LOAD_FAILED: 'AGREEMENT_LOAD_FAILED',
  AGREEMENT_ADDING: 'AGREEMENT_ADDING',
  AGREEMENT_ADDED: 'AGREEMENT_ADDED',
  AGREEMENT_ADD_FAILED: 'AGREEMENT_ADD_FAILED',
  API_KEYS_LOADING: 'API_KEYS_LOADING',
  API_KEYS_LOADED: 'API_KEYS_LOADED',
  API_KEYS_LOAD_FAILED: 'API_KEYS_LOAD_FAILED',
  API_KEY_ADDING: 'API_KEY_ADDING',
  API_KEY_ADDED: 'API_KEY_ADDED',
  API_KEY_ADD_FAILED: 'API_KEY_ADD_FAILED',
  API_KEY_DELETING: 'API_KEY_DELETING',
  API_KEY_DELETED: 'API_KEY_DELETED',
  API_KEY_DELETE_FAILED: 'API_KEY_DELETE_FAILED',
  CONTRACT_BOOK_INTEGRATION_LOADING: 'CONTRACT_BOOK_INTEGRATION_LOADING',
  CONTRACT_BOOK_INTEGRATION_LOADED: 'CONTRACT_BOOK_INTEGRATION_LOADED',
  CONTRACT_BOOK_INTEGRATION_LOAD_FAILED: 'CONTRACT_BOOK_INTEGRATION_LOAD_FAILED',
  CONTRACT_BOOK_INTEGRATION_UPDATING: 'CONTRACT_BOOK_INTEGRATION_UPDATING',
  CONTRACT_BOOK_INTEGRATION_UPDATED: 'CONTRACT_BOOK_INTEGRATION_UPDATED',
  CONTRACT_BOOK_INTEGRATION_UPDATE_FAILED: 'CONTRACT_BOOK_INTEGRATION_UPDATE_FAILED',
  CONTRACT_BOOK_INTEGRATION_DELETING: 'CONTRACT_BOOK_INTEGRATION_DELETING',
  CONTRACT_BOOK_INTEGRATION_DELETED: 'CONTRACT_BOOK_INTEGRATION_DELETED',
  CONTRACT_BOOK_INTEGRATION_DELETE_FAILED: 'CONTRACT_BOOK_INTEGRATION_DELETE_FAILED',
  CONTRACT_BOOK_TEMPLATE_LOADING: 'CONTRACT_BOOK_TEMPLATE_LOADING',
  CONTRACT_BOOK_TEMPLATE_LOADED: 'CONTRACT_BOOK_TEMPLATE_LOADED',
  CONTRACT_BOOK_TEMPLATE_LOAD_FAILED: 'CONTRACT_BOOK_TEMPLATE_LOAD_FAILED',
  CONTRACT_BOOK_DRAFT_ADDING: 'CONTRACT_BOOK_DRAFT_ADDING',
  CONTRACT_BOOK_DRAFT_ADDED: 'CONTRACT_BOOK_DRAFT_ADDED',
  CONTRACT_BOOK_DRAFT_ADD_FAILED: 'CONTRACT_BOOK_DRAFT_ADD_FAILED',
  CONTRACT_BOOK_CONTRACT_LOADING: 'CONTRACT_BOOK_CONTRACT_LOADING',
  CONTRACT_BOOK_CONTRACT_LOADED: 'CONTRACT_BOOK_CONTRACT_LOADED',
  CONTRACT_BOOK_CONTRACT_LOAD_FAILED: 'CONTRACT_BOOK_CONTRACT_LOAD_FAILED',
  CONTRACT_BOOK_CONTRACT_UPDATING: 'CONTRACT_BOOK_CONTRACT_UPDATING',
  CONTRACT_BOOK_CONTRACT_UPDATED: 'CONTRACT_BOOK_CONTRACT_UPDATED',
  CONTRACT_BOOK_CONTRACT_UPDATE_FAILED: 'CONTRACT_BOOK_CONTRACT_UPDATE_FAILED',
  COMPANY_INTEGRATION_LOADING: 'COMPANY_INTEGRATION_LOADING',
  COMPANY_INTEGRATION_LOADED: 'COMPANY_INTEGRATION_LOADED',
  COMPANY_INTEGRATION_LOAD_FAILED: 'COMPANY_INTEGRATION_LOAD_FAILED',
  COMPANY_INTEGRATION_CREATING: 'COMPANY_INTEGRATION_CREATING',
  COMPANY_INTEGRATION_CREATED: 'COMPANY_INTEGRATION_CREATED',
  COMPANY_INTEGRATION_CREATE_FAILED: 'COMPANY_INTEGRATION_CREATE_FAILED',
  COMPANY_INTEGRATION_DELETING: 'COMPANY_INTEGRATION_DELETING',
  COMPANY_INTEGRATION_DELETED: 'COMPANY_INTEGRATION_DELETED',
  COMPANY_INTEGRATION_DELETE_FAILED: 'COMPANY_INTEGRATION_DELETE_FAILED',
  AVAILABLE_ACCOUNTING_INTEGRATIONS_LOADING: 'AVAILABLE_ACCOUNTING_INTEGRATIONS_LOADING',
  AVAILABLE_ACCOUNTING_INTEGRATIONS_LOADED: 'AVAILABLE_ACCOUNTING_INTEGRATIONS_LOADED',
  AVAILABLE_ACCOUNTING_INTEGRATIONS_LOAD_FAILED: 'AVAILABLE_ACCOUNTING_INTEGRATIONS_LOAD_FAILED',
  REIMBURSEMENT_VOUCHER_LOADING: 'REIMBURSEMENT_VOUCHER_LOADING',
  REIMBURSEMENT_VOUCHER_LOADED: 'REIMBURSEMENT_VOUCHER_LOADED',
  REIMBURSEMENT_VOUCHER_LOADED_PARTIAL: 'REIMBURSEMENT_VOUCHER_LOADED_PARTIAL',
  REIMBURSEMENT_VOUCHER_LOAD_FAILED: 'REIMBURSEMENT_VOUCHER_LOAD_FAILED',
  REIMBURSEMENT_VOUCHER_ADDING: 'REIMBURSEMENT_VOUCHER_ADDING',
  REIMBURSEMENT_VOUCHER_ADDED: 'REIMBURSEMENT_VOUCHER_ADDED',
  REIMBURSEMENT_VOUCHER_ADD_FAILED: 'REIMBURSEMENT_VOUCHER_ADD_FAILED',
  REIMBURSEMENT_VOUCHER_UPDATING: 'REIMBURSEMENT_VOUCHER_UPDATING',
  REIMBURSEMENT_VOUCHER_UPDATED: 'REIMBURSEMENT_VOUCHER_UPDATED',
  REIMBURSEMENT_VOUCHER_UPDATE_FAILED: 'REIMBURSEMENT_VOUCHER_UPDATE_FAILED',
  REIMBURSEMENT_VOUCHER_DELETING: 'REIMBURSEMENT_VOUCHER_DELETING',
  REIMBURSEMENT_VOUCHER_DELETED: 'REIMBURSEMENT_VOUCHER_DELETED',
  REIMBURSEMENT_VOUCHER_DELETE_FAILED: 'REIMBURSEMENT_VOUCHER_DELETE_FAILED',
  RECOVERY_CODES_LOADING: 'RECOVERY_CODES_LOADING',
  RECOVERY_CODES_LOADED: 'RECOVERY_CODES_LOADED',
  RECOVERY_CODES_LOAD_FAILED: 'RECOVERY_CODES_LOAD_FAILED',
  STRIPE_CONFIGURATION_LOADING: 'STRIPE_CONFIGURATION_LOADING',
  STRIPE_CONFIGURATION_LOADED: 'STRIPE_CONFIGURATION_LOADED',
  STRIPE_CONFIGURATION_LOAD_FAILED: 'STRIPE_CONFIGURATION_LOAD_FAILED',
  STRIPE_CONFIGURATION_ADDING: 'STRIPE_CONFIGURATION_ADDING',
  STRIPE_CONFIGURATION_ADDED: 'STRIPE_CONFIGURATION_ADDED',
  STRIPE_CONFIGURATION_ADD_FAILED: 'STRIPE_CONFIGURATION_ADD_FAILED',
  STRIPE_CONFIGURATION_UPDATING: 'STRIPE_CONFIGURATION_UPDATING',
  STRIPE_CONFIGURATION_UPDATED: 'STRIPE_CONFIGURATION_UPDATED',
  STRIPE_CONFIGURATION_UPDATE_FAILED: 'STRIPE_CONFIGURATION_UPDATE_FAILED',
  DOCUMENT_LOADING: 'DOCUMENT_LOADING',
  DOCUMENT_LOADED: 'DOCUMENT_LOADED',
  DOCUMENT_LOAD_FAILED: 'DOCUMENT_LOAD_FAILED',
  DOCUMENT_ADDING: 'DOCUMENT_ADDING',
  DOCUMENT_ADDED: 'DOCUMENT_ADDED',
  DOCUMENT_ADD_FAILED: 'DOCUMENT_ADD_FAILED',
  DOCUMENT_UPDATING: 'DOCUMENT_UPDATING',
  DOCUMENT_UPDATED: 'DOCUMENT_UPDATED',
  DOCUMENT_UPDATE_FAILED: 'DOCUMENT_UPDATE_FAILED',
  DOCUMENT_DELETING: 'DOCUMENT_DELETING',
  DOCUMENT_DELETED: 'DOCUMENT_DELETED',
  DOCUMENT_DELETE_FAILED: 'DOCUMENT_DELETE_FAILED',
  DOCUMENT_CATEGORY_LOADING: 'DOCUMENT_CATEGORY_LOADING',
  DOCUMENT_CATEGORY_LOADED: 'DOCUMENT_CATEGORY_LOADED',
  DOCUMENT_CATEGORY_LOAD_FAILED: 'DOCUMENT_CATEGORY_LOAD_FAILED',
  DOCUMENT_CATEGORY_ADDING: 'DOCUMENT_CATEGORY_ADDING',
  DOCUMENT_CATEGORY_ADDED: 'DOCUMENT_CATEGORY_ADDED',
  DOCUMENT_CATEGORY_ADD_FAILED: 'DOCUMENT_CATEGORY_ADD_FAILED',
  DOCUMENT_CATEGORY_UPDATING: 'DOCUMENT_CATEGORY_UPDATING',
  DOCUMENT_CATEGORY_UPDATED: 'DOCUMENT_CATEGORY_UPDATED',
  DOCUMENT_CATEGORY_UPDATE_FAILED: 'DOCUMENT_CATEGORY_UPDATE_FAILED',
  OUTGOING_LEAD_LOADING: 'OUTGOING_LEAD_LOADING',
  OUTGOING_LEAD_LOADED: 'OUTGOING_LEAD_LOADED',
  OUTGOING_LEAD_LOAD_FAILED: 'OUTGOING_LEAD_LOAD_FAILED',
  OUTGOING_LEAD_CREATING: 'OUTGOING_LEAD_CREATING',
  OUTGOING_LEAD_CREATED: 'OUTGOING_LEAD_CREATED',
  OUTGOING_LEAD_CREATE_FAILED: 'OUTGOING_LEAD_CREATE_FAILED',
  EXPENSE_CATEGORY_LOADING: 'EXPENSE_CATEGORY_LOADING',
  EXPENSE_CATEGORY_LOADED: 'EXPENSE_CATEGORY_LOADED',
  EXPENSE_CATEGORY_LOAD_FAILED: 'EXPENSE_CATEGORY_LOAD_FAILED',
  EXPENSE_CATEGORY_ADDING: 'EXPENSE_CATEGORY_ADDING',
  EXPENSE_CATEGORY_ADDED: 'EXPENSE_CATEGORY_ADDED',
  EXPENSE_CATEGORY_ADD_FAILED: 'EXPENSE_CATEGORY_ADD_FAILED',
  EXPENSE_CATEGORY_UPDATING: 'EXPENSE_CATEGORY_UPDATING',
  EXPENSE_CATEGORY_UPDATED: 'EXPENSE_CATEGORY_UPDATED',
  EXPENSE_CATEGORY_UPDATE_FAILED: 'EXPENSE_CATEGORY_UPDATE_FAILED',
  EXPENSE_CATEGORY_DELETING: 'EXPENSE_CATEGORY_DELETING',
  EXPENSE_CATEGORY_DELETED: 'EXPENSE_CATEGORY_DELETED',
  EXPENSE_CATEGORY_DELETE_FAILED: 'EXPENSE_CATEGORY_DELETE_FAILED',
  EMPLOYEE_EMERGENCY_CONTACT_LOADING: 'EMPLOYEE_EMERGENCY_CONTACT_LOADING',
  EMPLOYEE_EMERGENCY_CONTACT_LOADED: 'EMPLOYEE_EMERGENCY_CONTACT_LOADED',
  EMPLOYEE_EMERGENCY_CONTACT_LOAD_FAILED: 'EMPLOYEE_EMERGENCY_CONTACT_LOAD_FAILED',
  EMPLOYEE_EMERGENCY_CONTACT_UPDATING: 'EMPLOYEE_EMERGENCY_CONTACT_UPDATING',
  EMPLOYEE_EMERGENCY_CONTACT_UPDATED: 'EMPLOYEE_EMERGENCY_CONTACT_UPDATED',
  EMPLOYEE_EMERGENCY_CONTACT_UPDATE_FAILED: 'EMPLOYEE_EMERGENCY_CONTACT_UPDATE_FAILED',
  EMPLOYEE_EMERGENCY_CONTACT_DELETING: 'EMPLOYEE_EMERGENCY_CONTACT_DELETING',
  EMPLOYEE_EMERGENCY_CONTACT_DELETED: 'EMPLOYEE_EMERGENCY_CONTACT_DELETED',
  EMPLOYEE_EMERGENCY_CONTACT_DELETE_FAILED: 'EMPLOYEE_EMERGENCY_CONTACT_DELETE_FAILED',
  AVAILABLE_DATA_INTEGRATIONS_LOADING: 'AVAILABLE_DATA_INTEGRATIONS_LOADING',
  AVAILABLE_DATA_INTEGRATIONS_LOADED: 'AVAILABLE_DATA_INTEGRATIONS_LOADED',
  AVAILABLE_DATA_INTEGRATIONS_LOAD_FAILED: 'AVAILABLE_DATA_INTEGRATIONS_LOAD_FAILED',
  DATA_INTEGRATION_LOADING: 'DATA_INTEGRATION_LOADING',
  DATA_INTEGRATION_LOADED: 'DATA_INTEGRATION_LOADED',
  DATA_INTEGRATION_LOAD_FAILED: 'DATA_INTEGRATION_LOAD_FAILED',
  DATA_INTEGRATION_UPDATING: 'DATA_INTEGRATION_UPDATING',
  DATA_INTEGRATION_UPDATED: 'DATA_INTEGRATION_UPDATED',
  DATA_INTEGRATION_UPDATE_FAILED: 'DATA_INTEGRATION_UPDATE_FAILED',
  DATA_INTEGRATION_DELETING: 'DATA_INTEGRATION_DELETING',
  DATA_INTEGRATION_DELETED: 'DATA_INTEGRATION_DELETED',
  DATA_INTEGRATION_DELETE_FAILED: 'DATA_INTEGRATION_DELETE_FAILED',
  DASHBOARD_LOADING: 'DASHBOARD_LOADING',
  DASHBOARD_LOADED: 'DASHBOARD_LOADED',
  DASHBOARD_LOAD_FAILED: 'DASHBOARD_LOAD_FAILED',
  COSTCENTERS_LOADING: 'COSTCENTERS_LOADING',
  COSTCENTERS_LOADED: 'COSTCENTERS_LOADED',
  COSTCENTERS_LOAD_FAILED: 'COSTCENTERS_LOAD_FAILED',
  COSTCENTER_ADDING: 'COSTCENTER_ADDING',
  COSTCENTER_ADDED: 'COSTCENTER_ADDED',
  COSTCENTER_ADD_FAILED: 'COSTCENTER_ADD_FAILED',
  COSTCENTER_UPDATING: 'COSTCENTER_UPDATING',
  COSTCENTER_UPDATED: 'COSTCENTER_UPDATED',
  COSTCENTER_UPDATE_FAILED: 'COSTCENTER_UPDATE_FAILED',
  COSTCENTER_DELETING: 'COSTCENTER_DELETING',
  COSTCENTER_DELETED: 'COSTCENTER_DELETED',
  COSTCENTER_DELETE_FAILED: 'COSTCENTER_DELETE_FAILED',
  DEPARTMENT_USERS_LOADING: 'DEPARTMENT_USERS_LOADING',
  DEPARTMENT_USERS_LOADED: 'DEPARTMENT_USERS_LOADED',
  DEPARTMENT_USERS_LOAD_FAILED: 'DEPARTMENT_USERS_LOAD_FAILED',
  DEPARTMENT_USER_PERMISSION_GRANTING: 'DEPARTMENT_USER_PERMISSION_GRANTING',
  DEPARTMENT_USER_PERMISSION_GRANTED: 'DEPARTMENT_USER_PERMISSION_GRANTED',
  DEPARTMENT_USER_PERMISSION_GRANT_FAILED: 'DEPARTMENT_USER_PERMISSION_GRANT_FAILED',
  DEPARTMENT_USER_PERMISSION_REVOKING: 'DEPARTMENT_USER_PERMISSION_REVOKING',
  DEPARTMENT_USER_PERMISSION_REVOKED: 'DEPARTMENT_USER_PERMISSION_REVOKED',
  DEPARTMENT_USER_PERMISSION_REVOKE_FAILED: 'DEPARTMENT_USER_PERMISSION_REVOKE_FAILED',
  COMPANY_DEVIATION_CONFIGURATION_LOADING: 'COMPANY_DEVIATION_CONFIGURATION_LOADING',
  COMPANY_DEVIATION_CONFIGURATION_LOADED: 'COMPANY_DEVIATION_CONFIGURATION_LOADED',
  COMPANY_DEVIATION_CONFIGURATION_LOAD_FAILED: 'COMPANY_DEVIATION_CONFIGURATION_LOAD_FAILED',
  COMPANY_DEVIATION_CONFIGURATION_ADDING: 'COMPANY_DEVIATION_CONFIGURATION_ADDING',
  COMPANY_DEVIATION_CONFIGURATION_ADDED: 'COMPANY_DEVIATION_CONFIGURATION_ADDED',
  COMPANY_DEVIATION_CONFIGURATION_ADD_FAILED: 'COMPANY_DEVIATION_CONFIGURATION_ADD_FAILED',
  COMPANY_DEVIATION_CONFIGURATION_UPDATING: 'COMPANY_DEVIATION_CONFIGURATION_UPDATING',
  COMPANY_DEVIATION_CONFIGURATION_UPDATED: 'COMPANY_DEVIATION_CONFIGURATION_UPDATED',
  COMPANY_DEVIATION_CONFIGURATION_UPDATE_FAILED: 'COMPANY_DEVIATION_CONFIGURATION_UPDATE_FAILED',
  COMPANY_DEVIATION_CONFIGURATION_DELETING: 'COMPANY_DEVIATION_CONFIGURATION_DELETING',
  COMPANY_DEVIATION_CONFIGURATION_DELETED: 'COMPANY_DEVIATION_CONFIGURATION_DELETED',
  COMPANY_DEVIATION_CONFIGURATION_DELETE_FAILED: 'COMPANY_DEVIATION_CONFIGURATION_DELETE_FAILED',
  PAY_ROLL_DEVIATION_LOADING: 'PAY_ROLL_DEVIATION_LOADING',
  PAY_ROLL_DEVIATION_LOADED: 'PAY_ROLL_DEVIATION_LOADED',
  PAY_ROLL_DEVIATION_LOAD_FAILED: 'PAY_ROLL_DEVIATION_LOAD_FAILED',
  PAY_ROLL_DEVIATION_ADDED: 'PAY_ROLL_DEVIATION_ADDED',
  PAY_ROLL_DEVIATION_UPDATED: 'PAY_ROLL_DEVIATION_UPDATED',
  PAY_ROLL_DEVIATION_DELETED: 'PAY_ROLL_DEVIATION_DELETED',
  SWIPE_LOADING: 'SWIPE_LOADING',
  SWIPE_LOADED: 'SWIPE_LOADED',
  SWIPE_LOADED_PARTIAL: 'SWIPE_LOADED_PARTIAL',
  SWIPE_LOAD_FAILED: 'SWIPE_LOAD_FAILED',
  SWIPE_ADDED: 'SWIPE_ADDED',
  SWIPE_UPDATED: 'SWIPE_UPDATED',
  SWIPE_APPROVING: 'SWIPE_APPROVING',
  SWIPE_APPROVED: 'SWIPE_APPROVED',
  SWIPE_APPROVE_FAILED: 'SWIPE_APPROVE_FAILED',
  SWIPE_DELETING: 'SWIPE_DELETING',
  SWIPE_DELETED: 'SWIPE_DELETED',
  SWIPE_DELETE_FAILED: 'SWIPE_DELETE_FAILED',
  CONTRACT_TEMPLATE_LOADING: 'CONTRACT_TEMPLATE_LOADING',
  CONTRACT_TEMPLATE_LOADED: 'CONTRACT_TEMPLATE_LOADED',
  CONTRACT_TEMPLATE_LOAD_FAILED: 'CONTRACT_TEMPLATE_LOAD_FAILED',
  CONTRACT_TEMPLATE_ADDING: 'CONTRACT_TEMPLATE_ADDING',
  CONTRACT_TEMPLATE_ADDED: 'CONTRACT_TEMPLATE_ADDED',
  CONTRACT_TEMPLATE_ADD_FAILED: 'CONTRACT_TEMPLATE_ADD_FAILED',
  CONTRACT_TEMPLATE_UPDATING: 'CONTRACT_TEMPLATE_UPDATING',
  CONTRACT_TEMPLATE_UPDATED: 'CONTRACT_TEMPLATE_UPDATED',
  CONTRACT_TEMPLATE_UPDATE_FAILED: 'CONTRACT_TEMPLATE_UPDATE_FAILED',
  CONTRACT_TEMPLATE_DELETING: 'CONTRACT_TEMPLATE_DELETING',
  CONTRACT_TEMPLATE_DELETED: 'CONTRACT_TEMPLATE_DELETED',
  CONTRACT_TEMPLATE_DELETE_FAILED: 'CONTRACT_TEMPLATE_DELETE_FAILED',
  CONTRACT_TEMPLATE_CONTRACT_LOADING: 'CONTRACT_TEMPLATE_CONTRACT_LOADING',
  CONTRACT_TEMPLATE_CONTRACT_LOADED: 'CONTRACT_TEMPLATE_CONTRACT_LOADED',
  CONTRACT_TEMPLATE_CONTRACT_LOAD_FAILED: 'CONTRACT_TEMPLATE_CONTRACT_LOAD_FAILED',
  CONTRACT_TEMPLATE_CONTRACT_ADDING: 'CONTRACT_TEMPLATE_CONTRACT_ADDING',
  CONTRACT_TEMPLATE_CONTRACT_ADDED: 'CONTRACT_TEMPLATE_CONTRACT_ADDED',
  CONTRACT_TEMPLATE_CONTRACT_ADD_FAILED: 'CONTRACT_TEMPLATE_CONTRACT_ADD_FAILED',
  CONTRACT_TEMPLATE_CONTRACT_UPDATING: 'CONTRACT_TEMPLATE_CONTRACT_UPDATING',
  CONTRACT_TEMPLATE_CONTRACT_UPDATED: 'CONTRACT_TEMPLATE_CONTRACT_UPDATED',
  CONTRACT_TEMPLATE_CONTRACT_UPDATE_FAILED: 'CONTRACT_TEMPLATE_CONTRACT_UPDATE_FAILED',
  CONTRACT_TEMPLATE_CONTRACT_DELETING: 'CONTRACT_TEMPLATE_CONTRACT_DELETING',
  CONTRACT_TEMPLATE_CONTRACT_DELETED: 'CONTRACT_TEMPLATE_CONTRACT_DELETED',
  CONTRACT_TEMPLATE_CONTRACT_DELETE_FAILED: 'CONTRACT_TEMPLATE_CONTRACT_DELETE_FAILED',
  ACCOUNTING_DIMENSIONS_LOADING: 'ACCOUNTING_DIMENSIONS_LOADING',
  ACCOUNTING_DIMENSIONS_LOADED: 'ACCOUNTING_DIMENSIONS_LOADED',
  ACCOUNTING_DIMENSIONS_LOAD_FAILED: 'ACCOUNTING_DIMENSIONS_LOAD_FAILED',
  ACCOUNTING_DIMENSIONS_UPDATING: 'ACCOUNTING_DIMENSIONS_UPDATING',
  ACCOUNTING_DIMENSIONS_UPDATED: 'ACCOUNTING_DIMENSIONS_UPDATED',
  ACCOUNTING_DIMENSIONS_UPDATE_FAILED: 'ACCOUNTING_DIMENSIONS_UPDATE_FAILED',
  ACCOUNTING_DIMENSION_ADDED: 'ACCOUNTING_DIMENSION_ADDED',
  ACCOUNTING_DIMENSION_UPDATED: 'ACCOUNTING_DIMENSION_UPDATED',
  EMPLOYEE_DIMENSIONS_LOADING: 'EMPLOYEE_DIMENSIONS_LOADING',
  EMPLOYEE_DIMENSIONS_LOADED: 'EMPLOYEE_DIMENSIONS_LOADED',
  EMPLOYEE_DIMENSIONS_LOAD_FAILED: 'EMPLOYEE_DIMENSIONS_LOAD_FAILED',
  EMPLOYEE_DIMENSIONS_UPDATING: 'EMPLOYEE_DIMENSIONS_UPDATING',
  EMPLOYEE_DIMENSIONS_UPDATED: 'EMPLOYEE_DIMENSIONS_UPDATED',
  EMPLOYEE_DIMENSIONS_UPDATE_FAILED: 'EMPLOYEE_DIMENSIONS_UPDATE_FAILED',
  EMPLOYEE_DIMENSION_UPDATED: 'EMPLOYEE_DIMENSION_UPDATED',
  ASYNCHRONOUS_TASK_LOADING: 'ASYNCHRONOUS_TASK_LOADING',
  ASYNCHRONOUS_TASK_LOADED: 'ASYNCHRONOUS_TASK_LOADED',
  ASYNCHRONOUS_TASK_LOAD_FAILED: 'ASYNCHRONOUS_TASK_LOAD_FAILED',
  ASYNCHRONOUS_TASK_START: 'ASYNCHRONOUS_TASK_START',
  ASYNCHRONOUS_TASK_SAVED: 'ASYNCHRONOUS_TASK_SAVED',
  ASYNCHRONOUS_TASK_START_FAILED: 'ASYNCHRONOUS_TASK_START_FAILED',
  ASYNCHRONOUS_SCHEDULE_LOADING: 'ASYNCHRONOUS_SCHEDULE_LOADING',
  ASYNCHRONOUS_SCHEDULE_LOADED: 'ASYNCHRONOUS_SCHEDULE_LOADED',
  ASYNCHRONOUS_SCHEDULE_LOAD_FAILED: 'ASYNCHRONOUS_SCHEDULE_LOAD_FAILED',
  ASYNCHRONOUS_SCHEDULE_ADDING: 'ASYNCHRONOUS_SCHEDULE_ADDING',
  ASYNCHRONOUS_SCHEDULE_ADDED: 'ASYNCHRONOUS_SCHEDULE_ADDED',
  ASYNCHRONOUS_SCHEDULE_ADD_FAILED: 'ASYNCHRONOUS_SCHEDULE_ADD_FAILED',
  ASYNCHRONOUS_SCHEDULE_UPDATING: 'ASYNCHRONOUS_SCHEDULE_UPDATING',
  ASYNCHRONOUS_SCHEDULE_UPDATED: 'ASYNCHRONOUS_SCHEDULE_UPDATED',
  ASYNCHRONOUS_SCHEDULE_UPDATE_FAILED: 'ASYNCHRONOUS_SCHEDULE_UPDATE_FAILED',
  ASYNCHRONOUS_SCHEDULE_DELETING: 'ASYNCHRONOUS_SCHEDULE_DELETING',
  ASYNCHRONOUS_SCHEDULE_DELETED: 'ASYNCHRONOUS_SCHEDULE_DELETED',
  ASYNCHRONOUS_SCHEDULE_DELETE_FAILED: 'ASYNCHRONOUS_SCHEDULE_DELETE_FAILED',
  VACATION_CALENDAR_LOADING: 'VACATION_CALENDAR_LOADING',
  VACATION_CALENDAR_LOADED: 'VACATION_CALENDAR_LOADED',
  VACATION_CALENDAR_LOAD_FAILED: 'VACATION_CALENDAR_LOAD_FAILED',
  VACATION_CALENDAR_YEAR_LOADING: 'VACATION_CALENDAR_YEAR_LOADING',
  VACATION_CALENDAR_YEAR_LOADED: 'VACATION_CALENDAR_YEAR_LOADED',
  VACATION_CALENDAR_YEAR_LOAD_FAILED: 'VACATION_CALENDAR_YEAR_LOAD_FAILED',
  VACATION_CALENDAR_UPDATING: 'VACATION_CALENDAR_UPDATING',
  VACATION_CALENDAR_UPDATED: 'VACATION_CALENDAR_UPDATED',
  VACATION_CALENDAR_UPDATE_FAILED: 'VACATION_CALENDAR_UPDATE_FAILED',
  SHIPMENT_LOADING: 'SHIPMENT_LOADING',
  SHIPMENT_LOADED: 'SHIPMENT_LOADED',
  SHIPMENT_LOAD_FAILED: 'SHIPMENT_LOAD_FAILED',
  EXTERNAL_SALARY_FILE_SETTINGS_LOADING: 'EXTERNAL_SALARY_FILE_SETTINGS_LOADING',
  EXTERNAL_SALARY_FILE_SETTINGS_LOADED: 'EXTERNAL_SALARY_FILE_SETTINGS_LOADED',
  EXTERNAL_SALARY_FILE_SETTINGS_LOAD_FAILED: 'EXTERNAL_SALARY_FILE_SETTINGS_LOAD_FAILED',
  EXTERNAL_SALARY_FILE_SETTINGS_SAVING: 'EXTERNAL_SALARY_FILE_SETTINGS_SAVING',
  EXTERNAL_SALARY_FILE_SETTINGS_SAVED: 'EXTERNAL_SALARY_FILE_SETTINGS_SAVED',
  EXTERNAL_SALARY_FILE_SETTINGS_SAVE_FAILED: 'EXTERNAL_SALARY_FILE_SETTINGS_SAVE_FAILED',
  ASSET_CREATING: 'ASSET_CREATING',
  ASSET_CREATED: 'ASSET_CREATED',
  ASSET_CREATE_FAILED: 'ASSET_CREATE_FAILED',
  ASSET_LOADING: 'ASSET_LOADING',
  ASSET_LOADED: 'ASSET_LOADED',
  ASSET_LOADED_PARTIAL: 'ASSET_LOADED_PARTIAL',
  ASSET_LOAD_FAILED: 'ASSET_LOAD_FAILED',
  ASSET_UPDATING: 'ASSET_UPDATING',
  ASSET_UPDATED: 'ASSET_UPDATED',
  ASSET_UPDATE_FAILED: 'ASSET_UPDATE_FAILED',
  ASSET_DELETING: 'ASSET_DELETING',
  ASSET_DELETED: 'ASSET_DELETED',
  ASSET_DELETE_FAILED: 'ASSET_DELETE_FAILED',
  ASSET_CATEGORY_CREATING: 'ASSET_CATEGORY_CREATING',
  ASSET_CATEGORY_CREATED: 'ASSET_CATEGORY_CREATED',
  ASSET_CATEGORY_CREATE_FAILED: 'ASSET_CATEGORY_CREATE_FAILED',
  ASSET_CATEGORY_LOADING: 'ASSET_CATEGORY_LOADING',
  ASSET_CATEGORY_LOADED: 'ASSET_CATEGORY_LOADED',
  ASSET_CATEGORY_LOADED_PARTIAL: 'ASSET_CATEGORY_LOADED_PARTIAL',
  ASSET_CATEGORY_LOAD_FAILED: 'ASSET_CATEGORY_LOAD_FAILED',
  ASSET_CATEGORY_UPDATING: 'ASSET_CATEGORY_UPDATING',
  ASSET_CATEGORY_UPDATED: 'ASSET_CATEGORY_UPDATED',
  ASSET_CATEGORY_UPDATE_FAILED: 'ASSET_CATEGORY_UPDATE_FAILED',
  ASSET_CATEGORY_DELETING: 'ASSET_CATEGORY_DELETING',
  ASSET_CATEGORY_DELETED: 'ASSET_CATEGORY_DELETED',
  ASSET_CATEGORY_DELETE_FAILED: 'ASSET_CATEGORY_DELETE_FAILED',
  GLOBAL_MESSAGE_LOADED: 'GLOBAL_MESSAGE_LOADED',
  TIME_REGISTRATION_TEMPLATE_LOADING: 'TIME_REGISTRATION_TEMPLATE_LOADING',
  TIME_REGISTRATION_TEMPLATE_LOADED: 'TIME_REGISTRATION_TEMPLATE_LOADED',
  TIME_REGISTRATION_TEMPLATE_LOAD_FAILED: 'TIME_REGISTRATION_TEMPLATE_LOAD_FAILED',
  TIME_REGISTRATION_TEMPLATE_SAVING: 'TIME_REGISTRATION_TEMPLATE_SAVING',
  TIME_REGISTRATION_TEMPLATE_SAVED: 'TIME_REGISTRATION_TEMPLATE_SAVED',
  TIME_REGISTRATION_TEMPLATE_SAVE_FAILED: 'TIME_REGISTRATION_TEMPLATE_SAVE_FAILED',
}
