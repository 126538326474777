import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../model/company'
import CompanyPricing from '../../model/companyPricing'
import Document, { DocumentCreationFields, DocumentMutableFields } from '../../model/document'
import DocumentCategory from '../../model/documentCategory'
import { DocumentReducer } from '../../reducers/documents'
import Language from '../../types/language'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { createSynchroniser, executeSynchroniser } from '../../utils/sync-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import SigningDocumentEditForm, { SigningDocumentResult } from './SigningDocumentEditForm'

type Props = {
  visible: boolean
  company: Company
  documentID?: string
  fileID?: string
  documents: DocumentReducer
  documentCategories: List<DocumentCategory>
  companyPricings: List<CompanyPricing>

  addDocument: (companyID: string, document: DocumentCreationFields) => Promise<Document | void>
  updateDocument: (companyID: string, document: DocumentMutableFields) => Promise<Document | void>
  addSignerToDocument: (document: Document, email: string, name: string, language: Language) => Promise<Document | void>
}

export default function SigningDocumentEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, documents } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, documents.error, error, setError)
  }, [visible, documents, error])

  const handleSubmit = (values: SigningDocumentResult) => {
    const handleDocumentCreation = (document: Document | void) => {
      if (!document) {
        return
      }
      const actions = createSynchroniser<Document>()
      values.newSigners.forEach((signer) => {
        actions.push(() => props.addSignerToDocument(document, signer.email, signer.name, signer.language))
      })
      executeSynchroniser(actions)
    }

    if (props.documentID) {
      const document = props.documents.documents.find((document) => document.id === props.documentID)
      if (document) {
        props
          .updateDocument(props.company.id, {
            ...document,
            documentCategoryID: values.documentCategoryID,
            name: values.name,
            visibleForEmployee: values.visibleForEmployee,
          })
          .then(handleDocumentCreation)
      }
    } else if (props.fileID) {
      props
        .addDocument(props.company.id, {
          companyID: props.company.id,
          documentCategoryID: values.documentCategoryID,
          name: values.name,
          visibleForEmployee: true,
          fileID: props.fileID,
          signatureType: values.signWith,
        })
        .then(handleDocumentCreation)
    }
  }

  return (
    <Card className="document-modal">
      <Subtitle>
        {props.documentID ? t('documents.signing.edit.title.edit') : t('documents.signing.edit.title.create')}
      </Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <SigningDocumentEditForm
        documentID={props.documentID}
        documents={props.documents}
        documentCategories={props.documentCategories}
        companyPricings={props.companyPricings}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
