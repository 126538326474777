import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../model/company'
import Department from '../../model/department'
import Employee from '../../model/employee'
import { ProjectCreationFields, ProjectMutableFields } from '../../model/project'
import { ProjectReducer } from '../../reducers/projects'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import ProjectEditForm, { ProjectResult } from './ProjectEditForm'
import ProjectEmployeeSelect from './ProjectEmployeeSelect'

type Props = {
  projectID?: string
  projectParentID?: string
  visible: boolean
  company: Company
  employees: List<Employee>
  departments: List<Department>
  projects: ProjectReducer

  createProject: (project: ProjectCreationFields) => void
  updateProject: (project: ProjectMutableFields) => void
}

export default function ProjectEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [showSelectEmployees, setShowSelectEmployees] = useState(false)
  // first select the list of employees from the item selected, if that does not exist, try its parent (if provided), otherwise just an empty list
  const [employeeIDs, setEmployeeIDs] = useState<string[]>(
    props.projects.projects.find((t) => t.id === props.projectID)?.employeeIDs ||
      props.projects.projects.find((t) => t.id === props.projectParentID)?.employeeIDs ||
      []
  )
  const { visible, projects } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, projects.error, error, setError)
  }, [visible, projects, error])

  const handleSubmit = (values: ProjectResult) => {
    const timeType: ProjectCreationFields = {
      companyID: props.company.id,
      parentID: props.projectParentID,
      name: values.name,
      description: values.description,
      externalID: values.externalID,
      hourlyRate: values.hourlyRate,
      order: 1, // TODO
      validFrom: values.validFrom,
      validTo: values.validTo,
      employeeIDs,
    }
    if (props.projectID) {
      props.updateProject({ ...timeType, id: props.projectID })
    } else {
      props.createProject(timeType)
    }
  }

  let title = props.projectID ? t('projects.edit.title.standard.edit') : t('projects.edit.title.standard.create')
  if (props.projectParentID) {
    const parent = props.projects.projects.find((t) => t.id === props.projectParentID)
    if (parent) {
      title = t(props.projectID ? 'projects.edit.title.child.edit' : 'projects.edit.title.child.create', {
        name: parent.name,
      })
    }
  }

  return (
    <Card>
      <Subtitle>{title}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <ProjectEditForm
        projectID={props.projectID}
        projects={props.projects}
        employees={props.employees.filter((employee) => employeeIDs.some((id) => id === employee.id)).toArray()}
        showEmployeeSelect={() => setShowSelectEmployees(true)}
        onSubmit={handleSubmit}
      />

      <Modal
        key={1}
        visible={showSelectEmployees}
        onOk={() => setShowSelectEmployees(false)}
        onCancel={() => setShowSelectEmployees(false)}
        width={800}
        footer={null}
      >
        <ProjectEmployeeSelect
          company={props.company}
          employees={props.employees}
          employeeIDs={employeeIDs}
          departments={props.departments}
          updateEmployeeList={setEmployeeIDs}
          closeModal={() => setShowSelectEmployees(false)}
        />
      </Modal>
    </Card>
  )
}
