import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import Department from '../../model/department'
import Employee from '../../model/employee'
import SalaryCycle from '../../model/salaryCycle'
import { EmploymentType } from '../../model/types'
import { isSameOrAfter } from '../../utils/date-utils'
import { compareEmployees, isEmployeeType } from '../../utils/employee-utils'
import { decorateFieldSignature, getFieldValueSignature, setFieldValueSignature } from '../../utils/form-utils'
import { formatBiweeklySalaryCycle, formatSalaryCycle } from '../../utils/format-utils'
import { escapeRegExp } from '../../utils/string-utils'
import { t, tx } from '../../utils/translation-utils'
import Select from '../antd/select'
import Button from '../elements/button'
import DatePicker from '../elements/date-picker/DatePicker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import Icon from '../elements/Icon'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import UserImage from '../elements/UserImage'
import SwitchWrapper from '../form-elements/SwitchWrapper'

import './SearchEmployeeForm.css'

const NO_DEPARTMENT = 'NO DEPARTMENT'

export type SearchEmployeeFields = {
  employeeIDs: string[]
  searchQuery?: string
  employmentType?: EmploymentType
  departmentID?: string
  salaryCycleID?: string
  onlyMutable: boolean
  createdBefore?: Date
  onlyDraft: boolean
}

type Props<Fields extends SearchEmployeeFields> = {
  company: Company
  employees: Employee[]
  departments?: List<Department>
  salaryCycles?: List<SalaryCycle>
  getFieldValue: getFieldValueSignature<Fields>
  setFieldValue: setFieldValueSignature<Fields>
  decorateField: decorateFieldSignature<Fields>
  selectHeight?: number
  allowOnlyDraft?: boolean
}

export default function SearchEmployeeForm<Fields extends SearchEmployeeFields>(
  props: Props<Fields>
): ReactElement | null {
  const resetFields = () => {
    props.setFieldValue('searchQuery', undefined)
    props.setFieldValue('employmentType', undefined)
    props.setFieldValue('departmentID', undefined)
    props.setFieldValue('salaryCycleID', undefined)
    props.setFieldValue('onlyMutable', false)
    props.setFieldValue('createdBefore', undefined)
    props.setFieldValue('onlyDraft', false)
  }

  const getDepartments = () => {
    return (
      props.departments
        ?.filter((department) => {
          if (!department.active) {
            return false
          }
          return props.employees.some((employee) => employee.departmentID === department.id)
        })
        .toArray() || []
    )
  }

  const getEmploymentTypes = () => {
    const types: { key: EmploymentType; title: string }[] = []
    let hasFullTime,
      hasPartTime,
      hasHourly,
      hasFreelancer,
      hasCommissioned = false

    // figure out what kind of types we have
    props.employees.forEach((employee) => {
      if (isEmployeeType(employee, 'FullTime')) {
        hasFullTime = true
      }
      if (isEmployeeType(employee, 'PartTime')) {
        hasPartTime = true
      }
      if (isEmployeeType(employee, 'Hourly')) {
        hasHourly = true
      }
      if (isEmployeeType(employee, 'Freelancer')) {
        hasFreelancer = true
      }
      if (isEmployeeType(employee, 'Commissioned')) {
        hasCommissioned = true
      }
    })

    if (hasFullTime) {
      types.push({ key: 'FullTime', title: t('employee_search.form.employment_types.full_time') })
    }
    if (hasPartTime) {
      types.push({ key: 'PartTime', title: t('employee_search.form.employment_types.part_time') })
    }
    if (hasHourly) {
      types.push({ key: 'Hourly', title: t('employee_search.form.employment_types.hourly') })
    }
    if (hasFreelancer) {
      types.push({ key: 'Freelancer', title: t('employee_search.form.employment_types.freelancer') })
    }
    if (hasCommissioned) {
      types.push({ key: 'Commissioned', title: t('employee_search.form.employment_types.commissioned') })
    }
    return types
  }

  const hasDraftEmployee = props.employees.some((e) => e.onboardingState === 'Draft')

  const getSalaryCycles = () => {
    return (
      props.salaryCycles
        ?.filter((salaryCycle) =>
          props.employees.some(
            (employee) =>
              employee.earliestMutableContract && employee.earliestMutableContract.salaryCycleID === salaryCycle.id
          )
        )
        .toArray() || []
    )
  }

  const setEmployeeIDs = (employeeIDs: string[]) => {
    props.setFieldValue('employeeIDs', employeeIDs)
  }

  type EmployeeRow = Employee & {
    position?: string
  }

  const getFilteredEmployees = (): EmployeeRow[] => {
    const searchQuery = props.getFieldValue('searchQuery')
    const departmentID = props.getFieldValue('departmentID')
    const employmentType = props.getFieldValue('employmentType')
    const salaryCycleID = props.getFieldValue('salaryCycleID')
    const employeeIDs = props.getFieldValue('employeeIDs')
    const onlyMutable = props.getFieldValue('onlyMutable')
    const createdBefore = props.getFieldValue('createdBefore')
    const onlyDraft = props.getFieldValue('onlyDraft')
    return props.employees
      .filter((employee) => {
        if (employeeIDs.some((employeeID) => employeeID === employee.id)) {
          return false
        }
        if (createdBefore && isSameOrAfter(employee.createdAt, createdBefore)) {
          return false
        }
        if (onlyMutable && !!employee.immutableEndDate) {
          return false
        }
        if (searchQuery) {
          const pattern = new RegExp(escapeRegExp(searchQuery), 'i')
          if (!pattern.test(employee.name ?? '')) {
            return false
          }
        }
        if (employmentType) {
          if (!isEmployeeType(employee, employmentType)) {
            return false
          }
        }
        if (departmentID) {
          if (departmentID === NO_DEPARTMENT && !!employee.departmentID) {
            return false
          }
          if (departmentID !== NO_DEPARTMENT && (!employee.departmentID || employee.departmentID !== departmentID)) {
            return false
          }
        }
        if (salaryCycleID) {
          const contract = employee.activeContract
          if (!contract) {
            return false
          }
          if (contract.salaryCycleID !== salaryCycleID) {
            return false
          }
        }
        if (onlyDraft && employee.onboardingState !== 'Draft') {
          return false
        }
        return true
      })
      .map((employee) => ({
        ...employee,
        position: employee.activeContract?.position,
      }))
      .sort((a, b) => compareEmployees(a, b))
  }

  const getSelectedEmployees = (): EmployeeRow[] => {
    const employeeIDs = props.getFieldValue('employeeIDs')
    return props.employees
      .filter((employee) => employeeIDs.some((employeeID) => employeeID === employee.id))
      .map((employee) => ({
        ...employee,
        position: employee.activeContract?.position,
      }))
      .sort((a, b) => compareEmployees(a, b))
  }

  const selectEmployee = (employeeID: string) => {
    const employeeIDs = props.getFieldValue('employeeIDs')
    employeeIDs.push(employeeID)
    setEmployeeIDs(employeeIDs)
  }

  const deselectEmployee = (employeeID: string) => {
    let employeeIDs = props.getFieldValue('employeeIDs')
    employeeIDs = employeeIDs.filter((thisID) => thisID !== employeeID)
    setEmployeeIDs(employeeIDs)
  }

  const selectAllEmployees = () => {
    getFilteredEmployees().forEach((employee) => selectEmployee(employee.id))
  }

  const deselectAllEmployees = () => {
    setEmployeeIDs([])
  }

  const { decorateField } = props

  const availableEmployees = getFilteredEmployees()
  const selectedEmployees = getSelectedEmployees()

  const { selectHeight = 400 } = props

  const departments = getDepartments()
  const employmentTypes = getEmploymentTypes()
  const salaryCycles = getSalaryCycles()

  return (
    <div className="employee-search-search">
      <Row>
        <Col span={12}>
          {decorateField('searchQuery', {
            title: t('employee_search.form.search_query'),
            placeholder: t('employee_search.form.search_query.placeholder'),
          })(<Input.Search tabIndex={1} />)}
        </Col>
        <Col span={12}>
          <Button style={{ marginTop: '26px', float: 'right' }} onClick={resetFields}>
            {t('employee_search.form.reset_fields')}
          </Button>
        </Col>
      </Row>
      {((props.departments && departments.length > 0) ||
        employmentTypes.length > 1 ||
        (props.salaryCycles && salaryCycles.length > 1)) && (
        <Row>
          {props.departments && departments.length > 0 && (
            <Col span={12}>
              {decorateField('departmentID', {
                title: t('employee_search.form.department_id'),
                placeholder: t('employee_search.form.department_id.placeholder'),
              })(
                <Select tabIndex={2} dropdownMatchSelectWidth={false}>
                  <Select.Option key="" value="" title={t('employee_search.form.department_id.all')}>
                    {t('employee_search.form.department_id.all')}
                  </Select.Option>
                  <Select.Option
                    key={NO_DEPARTMENT}
                    value={NO_DEPARTMENT}
                    title={t('employee_search.form.department_id.none')}
                  >
                    {t('employee_search.form.department_id.none')}
                  </Select.Option>
                  {getDepartments().map((department) => {
                    return (
                      <Select.Option key={department.id} value={department.id} title={department.name}>
                        {department.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              )}
            </Col>
          )}
          {employmentTypes.length > 1 && (
            <Col span={12}>
              {decorateField('employmentType', {
                title: t('employee_search.form.employment_type'),
                placeholder: t('employee_search.form.employment_type.placeholder'),
              })(
                <Select tabIndex={3} dropdownMatchSelectWidth={false}>
                  <Select.Option key="" value="" title={t('employee_search.form.employment_type.all')}>
                    {t('employee_search.form.employment_type.all')}
                  </Select.Option>
                  {getEmploymentTypes().map((employmentType) => {
                    return (
                      <Select.Option key={employmentType.key} value={employmentType.key} title={employmentType.title}>
                        {employmentType.title}
                      </Select.Option>
                    )
                  })}
                </Select>
              )}
            </Col>
          )}
          {props.salaryCycles && salaryCycles.length > 1 && (
            <Col span={12}>
              {decorateField('salaryCycleID', {
                title: t('employee_search.form.salary_cycle_id'),
                placeholder: t('employee_search.form.salary_cycle_id.placeholder'),
              })(
                <Select tabIndex={4} dropdownMatchSelectWidth={false}>
                  <Select.Option key="" value="" title={t('employee_search.form.salary_cycle_id.all')}>
                    {t('employee_search.form.salary_cycle_id.all')}
                  </Select.Option>
                  {getSalaryCycles().map((salaryCycle) => {
                    return (
                      <Select.Option
                        key={salaryCycle.id}
                        value={salaryCycle.id}
                        title={formatSalaryCycle(salaryCycle.frequency, salaryCycle.offset)}
                      >
                        {formatSalaryCycle(salaryCycle.frequency, salaryCycle.offset)}
                        {salaryCycle.frequency === 'BiWeekly' && ` (${formatBiweeklySalaryCycle(salaryCycle, false)})`}
                      </Select.Option>
                    )
                  })}
                </Select>
              )}
            </Col>
          )}
        </Row>
      )}
      <Row>
        <Col span={12}>
          {decorateField('createdBefore', {
            placeholder: t('employee_search.form.created_before'),
          })(<DatePicker allowClear />)}
        </Col>
        {props.allowOnlyDraft && hasDraftEmployee && (
          <Col span={12}>
            <SwitchWrapper id={'onlyDraft'} decorateField={decorateField} style={{ marginTop: '22px' }}>
              {t('employee_search.form.only_draft')}
            </SwitchWrapper>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={18}>
          <SwitchWrapper id={'onlyMutable'} decorateField={decorateField}>
            {tx('employee_search.form.only_mutable', { not: <em>{t('employee_search.form.only_mutable.not')}</em> })}
          </SwitchWrapper>
        </Col>
      </Row>
      <Subcard className="employee-search-search-results">
        <Row>
          <Col span={12}>
            <Subtitle>{t('employee_search.form.not_selected')}</Subtitle>
          </Col>
          <Col span={12}>
            <Subtitle>{t('employee_search.form.selected')}</Subtitle>
          </Col>
        </Row>
        <Row className="employee-search-search-result-container">
          <Col span={12} style={{ maxHeight: `${selectHeight}px`, overflowY: 'scroll' }}>
            {availableEmployees.map((employee) => {
              return (
                <Row className={'employee-search-employee employees'} key={employee.id}>
                  <Col span={21}>
                    <Headline>
                      <UserImage src={employee.profileImageURL} name={employee.name || employee.email} />
                      <span>{employee.name || employee.email}</span>
                      <small>{employee.position ? employee.position : '-'}</small>
                    </Headline>
                  </Col>
                  <Col span={3}>
                    <Button
                      className={'ant-btn-secondary employee-search-select'}
                      onClick={() => selectEmployee(employee.id)}
                      title={t('employee_search.form.not_selected.select')}
                    >
                      <Icon type="arrow-right" />
                    </Button>
                  </Col>
                </Row>
              )
            })}
          </Col>
          <Col span={12} style={{ maxHeight: `${selectHeight}px`, overflowY: 'scroll' }}>
            {selectedEmployees.map((employee) => {
              return (
                <Row className={'employee-search-employee employees'} key={employee.id}>
                  <Col span={3}>
                    <Button
                      className={'ant-btn-danger employee-search-deselect'}
                      onClick={() => deselectEmployee(employee.id)}
                      title={t('employee_search.form.selected.select')}
                    >
                      <Icon type="arrow-left" />
                    </Button>
                  </Col>
                  <Col span={21}>
                    <Headline>
                      <UserImage src={employee.profileImageURL} name={employee.name || employee.email} />
                      <span>{employee.name || employee.email}</span>
                      <small>{employee.position ? employee.position : '-'}</small>
                    </Headline>
                  </Col>
                </Row>
              )
            })}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <footer>
              {t('employee_search.form.not_selected.footer', { count: availableEmployees.length })}
              <Button
                className={'ant-btn-secondary employee-search-select'}
                style={{ float: 'right' }}
                onClick={() => selectAllEmployees()}
              >
                {t('employee_search.form.not_selected.select_all')}
              </Button>
            </footer>
          </Col>
          <Col span={12}>
            <footer>
              {t('employee_search.form.selected.footer', { count: selectedEmployees.length })}
              <Button
                className={'ant-btn-danger employee-search-deselect'}
                style={{ float: 'right' }}
                onClick={() => deselectAllEmployees()}
              >
                {t('employee_search.form.selected.select_all')}
              </Button>
            </footer>
          </Col>
        </Row>
      </Subcard>
    </div>
  )
}
