import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import UserInvite from '../model/userInvite'
import { ReducerAction } from '../utils/reducer-utils'

export interface UserInviteReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  userInvites: List<UserInvite>
  error: Error | null
}

const Params = Record<UserInviteReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  userInvites: List<UserInvite>(),
  error: null,
})

export interface UserInviteAction extends ReducerAction {
  companyID?: string
  userInvites?: UserInvite[]
  userInvite?: UserInvite
  email?: string
}

export default (
  state: Record<UserInviteReducer> = Params(),
  action: UserInviteAction = { type: '' }
): Record<UserInviteReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.USER_INVITES_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.USER_INVITES_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        userInvites: List(action.userInvites),
      })
    case ActionTypes.USER_INVITES_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.USER_INVITE_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.USER_INVITE_ADDED:
      if (!action.userInvite) {
        return state.set('saving', false)
      }
      idx = state.get('userInvites').findIndex((item) => !!action.userInvite && item.email === action.userInvite.email)
      if (idx !== -1) {
        return state.set('saving', false).set('userInvites', state.get('userInvites').set(idx, action.userInvite))
      }
      return state.set('saving', false).set('userInvites', state.get('userInvites').push(action.userInvite))
    case ActionTypes.USER_INVITE_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_INVITE_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.USER_INVITE_DELETED:
      idx = state.get('userInvites').findIndex((item) => item.email === action.email)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('userInvites', state.get('userInvites').delete(idx))
    case ActionTypes.USER_INVITE_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
