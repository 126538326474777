import Cookies from 'js-cookie'

import { DateTimeFormat } from '../model/types'
import Language from '../types/language'

const ACCESS_TOKEN = 'sally'
export function getAccessToken(): string | undefined {
  return Cookies.get(ACCESS_TOKEN)
}
export function setAccessToken(accessToken: string | null, expiration?: number): void {
  if (!expiration) {
    expiration = Infinity
  }
  if (!accessToken) {
    Cookies.remove(ACCESS_TOKEN)
  } else {
    Cookies.set(ACCESS_TOKEN, accessToken, { expires: expiration })
  }
}

const ACTIVE_COMPANY = 'sallyCompany'
export function getActiveCompany(): string | undefined {
  return Cookies.get(ACTIVE_COMPANY)
}
export function setActiveCompany(activeCompany: string | null, expiration?: number): void {
  if (!expiration) {
    expiration = Infinity
  }
  if (!activeCompany) {
    Cookies.remove(ACTIVE_COMPANY)
  } else {
    Cookies.set(ACTIVE_COMPANY, activeCompany, { expires: expiration })
  }
}

const CLOCK = 'sallyClock'
export function getClock(): DateTimeFormat | undefined {
  return Cookies.get(CLOCK)
}
export function setClock(clock: DateTimeFormat | null, expiration?: number): void {
  if (!expiration) {
    expiration = Infinity
  }
  if (!clock) {
    Cookies.remove(CLOCK)
  } else {
    Cookies.set(CLOCK, clock, { expires: expiration })
  }
}

const REMEMBER_EMAIL = 'sallyEmail'
export function getEmail(): string | undefined {
  return Cookies.get(REMEMBER_EMAIL)
}
export function setEmail(email: string | null, expiration?: number): void {
  if (!expiration) {
    expiration = Infinity
  }
  if (!email) {
    Cookies.remove(REMEMBER_EMAIL)
  } else {
    Cookies.set(REMEMBER_EMAIL, email, { expires: expiration })
  }
}

const DATA_INTEGRATION = 'dataIntegration'
export function getDataIntegration(): string | undefined {
  return Cookies.get(DATA_INTEGRATION)
}
export function setDataIntegration(dataIntegration: string): void {
  if (!dataIntegration) {
    Cookies.remove(DATA_INTEGRATION)
  } else {
    Cookies.set(DATA_INTEGRATION, dataIntegration)
  }
}

const LANGUAGE = 'language'
// generally, you want to be calling translation-utils' getCurrentLanguage()
export function getLanguage(): Language | undefined {
  const language = Cookies.get(LANGUAGE)
  switch (language) {
    case Language.DANISH:
      return Language.DANISH
    case Language.ENGLISH:
      return Language.ENGLISH
    default:
      return undefined
  }
}
export function setLanguage(language: Language | null): void {
  if (!language) {
    Cookies.remove(LANGUAGE)
  } else {
    Cookies.set(LANGUAGE, language)
  }
}

function randomString(length: number, chars: string): string {
  let result = ''
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
  return result
}

const DEVICE_TOKEN = 'deviceToken'
export function getDeviceToken(): string | undefined {
  return Cookies.get(DEVICE_TOKEN)
}
export function createDeviceToken(): string {
  const expiration = 30 * 24 * 60 * 60 // always set to 30 days
  const deviceToken = randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')
  Cookies.set(DEVICE_TOKEN, deviceToken, { expires: expiration })
  return deviceToken
}
