import React, { ReactElement } from 'react'

import Shipment from '../../model/shipment'
import { t } from '../../utils/translation-utils'
import Card from '../elements/card'
import { Col, Row } from '../elements/grid'
import Title from '../elements/Title'

type Props = {
  visible: boolean
  receipt?: Shipment
}

export default function TaxReportReceiptModal(props: Props): ReactElement | null {
  if (!props.receipt) {
    return null
  }

  type Line = {
    code: string
    text: string
    information: string
  }

  const getLines = (): Line[] => {
    if (!props.receipt) {
      return []
    }
    return props.receipt.replies
      .filter((reply) => reply.type === 'DK EINDKOMST REPLY')
      .reduce((lines: Line[], reply) => {
        return reply.notifications.reduce((lines: Line[], notification) => {
          return [...lines, { code: notification.code, text: notification.text, information: notification.information }]
        }, lines)
      }, [])
  }

  return (
    <Card className="pay-roll-tax-report-receipt">
      <Title>{t('pay_roll.single.tax_report_receipt.title')}</Title>
      {getLines().map((line, i) => {
        return (
          <Row key={i}>
            <Col span={24}>
              <Row>
                <Col span={8}>{t('pay_roll.single.tax_report_receipt.code')}</Col>
                <Col span={16}>{line.code}</Col>
              </Row>
              <Row>
                <Col span={8}>{t('pay_roll.single.tax_report_receipt.text')}</Col>
                <Col span={16}>{line.text}</Col>
              </Row>
              <Row>
                <Col span={8}>{t('pay_roll.single.tax_report_receipt.information')}</Col>
                <Col span={16}>{line.information}</Col>
              </Row>
            </Col>
          </Row>
        )
      })}
    </Card>
  )
}
