import { Store } from 'redux'

import AccountingDimension from '../model/accountingDimension'
import AccountingIntegration from '../model/accountingIntegration'
import ApiKey from '../model/apiKey'
import Asset from '../model/asset'
import AssetCategory from '../model/assetCategory'
import AsynchronousTask from '../model/asynchronousTask'
import CarAllowance from '../model/carAllowance'
import CoarseCarAllowance from '../model/coarseCarAllowance'
import CoarseSalaryRegistration from '../model/coarseSalaryRegistration'
import CoarseTimeRegistration from '../model/coarseTimeRegistration'
import Company from '../model/company'
import CompanyDeviationConfiguration from '../model/companyDeviationConfiguration'
import CompanyFeature from '../model/companyFeature'
import CompanyGroup from '../model/companyGroup'
import CompanyPricingPackage from '../model/companyPricingPackage'
import CompanyUser from '../model/companyUser'
import Contract from '../model/contract'
import CostCenter from '../model/costCenter'
import Department from '../model/department'
import Document from '../model/document'
import DocumentCategory from '../model/documentCategory'
import Employee from '../model/employee'
import EmployeeDimension from '../model/employeeDimension'
import EmployeeEmergencyContact from '../model/employeeEmergencyContact'
import Employment from '../model/employment'
import ExpenseCategory from '../model/expenseCategory'
import IncomeTaxReport from '../model/incomeTaxReport'
import Invoice from '../model/invoice'
import LeaveAdjustment from '../model/leaveAdjustment'
import LeaveBalance from '../model/leaveBalance'
import OneTimePay from '../model/oneTimePay'
import OneTimePayPension from '../model/oneTimePayPension'
import PaymentConfiguration from '../model/paymentConfiguration'
import PaymentIntegration from '../model/paymentIntegration'
import PayRoll from '../model/payRoll'
import PayRollDeviation from '../model/payRollDeviation'
import PaySlip from '../model/paySlip'
import Project from '../model/project'
import ReimbursementVoucher from '../model/reimbursementVoucher'
import SalaryRegistration from '../model/salaryRegistration'
import SalaryType from '../model/salaryType'
import SupplementAdjustment from '../model/supplementAdjustment'
import SupplementBalance from '../model/supplementBalance'
import Swipe from '../model/swipe'
import TaxCard from '../model/taxCard'
import TaxCardRequest from '../model/taxCardRequest'
import TimeRegistration from '../model/timeRegistration'
import Transfer from '../model/transfer'
import User from '../model/user'
import Voucher from '../model/voucher'
import Warning from '../model/warning'
import { logDebug } from '../utils/log-utils'
import { addedAccountingDimension, updatedAccountingDimension } from './accounting-dimensions'
import { updatedCompanyAccountingIntegration } from './accounting-integration'
import { addedApiKey, deletedApiKey } from './api-keys'
import { createdAssetCategory, deletedAssetCategory, updatedAssetCategory } from './asset-categories'
import { createdAsset, deletedAsset, updatedAsset } from './assets'
import { savedAsynchronousTask } from './asynchronous-tasks'
import { createdCarAllowance, deletedCarAllowance, updatedCarAllowance } from './car-allowances'
import {
  createdCoarseCarAllowance,
  deletedCoarseCarAllowance,
  updatedCoarseCarAllowance,
} from './coarse-car-allowances'
import {
  createdCoarseSalaryRegistration,
  deletedCoarseSalaryRegistration,
  updatedCoarseSalaryRegistration,
} from './coarse-salary-registrations'
import {
  createdCoarseTimeRegistration,
  deletedCoarseTimeRegistration,
  updatedCoarseTimeRegistration,
} from './coarse-time-registrations'
import { addedCompany, deletedCompany, updatedCompany } from './companies'
import {
  addedCompanyDeviationConfiguration,
  deletedCompanyDeviationConfiguration,
  updatedCompanyDeviationConfiguration,
} from './company-deviation-configurations'
import { addedCompanyFeature, deletedCompanyFeature } from './company-features'
import { updatedCompanyGroup } from './company-groups'
import {
  addedCompanyPaymentIntegration,
  deletedCompanyPaymentIntegration,
  updatedCompanyPaymentIntegration,
} from './company-payment-integrations'
import {
  addedCompanyPricingPackage,
  deletedCompanyPricingPackage,
  updatedCompanyPricingPackage,
} from './company-pricing-packages'
import { deletedCompanyUserByID } from './company-users'
import { addedContract, updatedContract } from './contracts'
import { addedCostCenter, deletedCostCenter, updatedCostCenter } from './cost-centers'
import { addedDepartment, deletedDepartment, updatedDepartment } from './departments'
import { addedDocumentCategory, updatedDocumentCategory } from './document-categories'
import { addedDocument, deletedDocument, updatedDocument } from './documents'
import { updatedEmployeeDimension } from './employee-dimensions'
import { deletedEmployeeEmergencyContact, updatedEmployeeEmergencyContact } from './employee-emergency-contacts'
import { addedEmployee, deletedEmployee, updatedEmployee } from './employees'
import { addedEmployment, updatedEmployment } from './employments'
import { addedExpenseCategory, deletedExpenseCategory, updatedExpenseCategory } from './expense-categories'
import { addedIncomeTaxReport, deletedIncomeTaxReport, updatedIncomeTaxReport } from './income-tax-reports'
import { addedInvoice, deletedInvoice, updatedInvoice } from './invoices'
import { addedLeaveAdjustment, deletedLeaveAdjustment, updatedLeaveAdjustment } from './leave-adjustments'
import { updatedLeaveBalance } from './leave-balances'
import { addedOneTimePayPension, updatedOneTimePayPension } from './one-time-pay-pensions'
import { addedOneTimePay, deletedOneTimePay, updatedOneTimePay } from './one-time-pays'
import { addedPayRollDeviation, deletedPayRollDeviation, updatedPayRollDeviation } from './pay-roll-deviations'
import { addedPayRoll, deletedPayRoll, updatedPayRoll } from './pay-rolls'
import { addedPaySlip, deletedPaySlip, updatedPaySlip } from './pay-slips'
import {
  addedPaymentConfiguration,
  deletedPaymentConfiguration,
  updatedPaymentConfiguration,
} from './payment-configurations'
import { createdProject, deletedProject, updatedProject } from './projects'
import {
  addedReimbursementVoucher,
  deletedReimbursementVoucher,
  updatedReimbursementVoucher,
} from './reimbursement-vouchers'
import { createdSalaryRegistration, deletedSalaryRegistration, updatedSalaryRegistration } from './salary-registrations'
import { addedSalaryType, updatedSalaryType } from './salary-types'
import {
  addedSupplementAdjustment,
  deletedSupplementAdjustment,
  updatedSupplementAdjustment,
} from './supplement-adjustments'
import { updatedSupplementBalance } from './supplement-balances'
import { addedSwipe, deletedSwipe, updatedSwipe } from './swipes'
import { addedTaxCard, addedTaxCardRequest, updatedTaxCard } from './tax-cards'
import { createdTimeRegistration, deletedTimeRegistration, updatedTimeRegistration } from './time-registrations'
import { addedTransfer, deletedTransfer, updatedTransfer } from './transfers'
import { updatedUser } from './user'
import { addedUserCompany, updatedUserCompany } from './user-companies'
import { addedVoucher, deletedVoucher, updatedVoucher } from './vouchers'
import { addedWarning, deletedWarning, updatedWarning } from './warnings'

type operation = string

const CREATE: operation = 'create'
const UPDATE: operation = 'update'
const DELETE: operation = 'delete'

function handleApiKey(store: Store, companyID: string, operation: operation, data: ApiKey) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedApiKey(companyID, data))
      break
    case DELETE:
      store.dispatch(deletedApiKey(companyID, data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleAsynchronousTask(store: Store, companyID: string, operation: operation, data: AsynchronousTask) {
  switch (operation) {
    case CREATE:
    case UPDATE:
      store.dispatch(savedAsynchronousTask(companyID, data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleAsset(store: Store, companyID: string, operation: operation, data: Asset) {
  switch (operation) {
    case CREATE:
      store.dispatch(createdAsset(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedAsset(companyID, data))
      break
    case DELETE:
      store.dispatch(deletedAsset(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleAssetCategory(store: Store, operation: operation, data: AssetCategory) {
  switch (operation) {
    case CREATE:
      store.dispatch(createdAssetCategory(data.companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedAssetCategory(data.companyID, data))
      break
    case DELETE:
      store.dispatch(deletedAssetCategory(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleCarAllowance(store: Store, operation: operation, data: CarAllowance) {
  switch (operation) {
    case CREATE:
      store.dispatch(createdCarAllowance(data.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedCarAllowance(data.employeeID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedCarAllowance(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleCoarseCarAllowance(store: Store, operation: operation, data: CoarseCarAllowance) {
  switch (operation) {
    case CREATE:
      store.dispatch(createdCoarseCarAllowance(data.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedCoarseCarAllowance(data.employeeID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedCoarseCarAllowance(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleCoarseTimeRegistration(store: Store, operation: operation, data: CoarseTimeRegistration) {
  switch (operation) {
    case CREATE:
      store.dispatch(createdCoarseTimeRegistration(data.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedCoarseTimeRegistration(data.employeeID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedCoarseTimeRegistration(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleCoarseSalaryRegistration(store: Store, operation: operation, data: CoarseSalaryRegistration) {
  switch (operation) {
    case CREATE:
      store.dispatch(createdCoarseSalaryRegistration(data.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedCoarseSalaryRegistration(data.employeeID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedCoarseSalaryRegistration(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleCompany(store: Store, operation: operation, data: Company) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedCompany(data))
      break
    case UPDATE:
      store.dispatch(updatedCompany(data))
      break
    case DELETE:
      store.dispatch(deletedCompany(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleCompanyGroup(store: Store, operation: operation, data: CompanyGroup) {
  switch (operation) {
    case UPDATE:
      store.dispatch(updatedCompanyGroup(data.id, data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleCompanyFeature(store: Store, operation: operation, data: CompanyFeature) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedCompanyFeature(data.companyID, data))
      break
    case DELETE:
      store.dispatch(deletedCompanyFeature(data.companyID, data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleCompanyAccountingIntegration(
  store: Store,
  companyID: string,
  operation: operation,
  data: AccountingIntegration
) {
  switch (operation) {
    case UPDATE:
      store.dispatch(updatedCompanyAccountingIntegration(companyID, data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleAccountingDimension(store: Store, companyID: string, operation: operation, data: AccountingDimension) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedAccountingDimension(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedAccountingDimension(companyID, data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleCompanyDeviationConfiguration(
  store: Store,
  companyID: string,
  operation: operation,
  data: CompanyDeviationConfiguration
) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedCompanyDeviationConfiguration(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedCompanyDeviationConfiguration(companyID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedCompanyDeviationConfiguration(companyID, data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handlePaymentConfiguration(store: Store, companyID: string, operation: operation, data: PaymentConfiguration) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedPaymentConfiguration(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedPaymentConfiguration(companyID, data))
      break
    case DELETE:
      store.dispatch(deletedPaymentConfiguration(companyID, data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handlePaymentIntegration(store: Store, companyID: string, operation: operation, data: PaymentIntegration) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedCompanyPaymentIntegration(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedCompanyPaymentIntegration(companyID, data))
      break
    case DELETE:
      store.dispatch(deletedCompanyPaymentIntegration(companyID, data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleCompanyPricingPackage(
  store: Store,
  companyID: string,
  operation: operation,
  data: CompanyPricingPackage
) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedCompanyPricingPackage(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedCompanyPricingPackage(companyID, data))
      break
    case DELETE:
      store.dispatch(deletedCompanyPricingPackage(companyID, data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleCostCenter(store: Store, companyID: string, operation: operation, data: CostCenter) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedCostCenter(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedCostCenter(companyID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedCostCenter(companyID, data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleDepartment(store: Store, companyID: string, operation: operation, data: Department) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedDepartment(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedDepartment(companyID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedDepartment(companyID, data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleDocument(store: Store, companyID: string, operation: operation, data: Document) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedDocument(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedDocument(companyID, data))
      break
    case DELETE:
      store.dispatch(deletedDocument(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleDocumentCategory(store: Store, companyID: string, operation: operation, data: DocumentCategory) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedDocumentCategory(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedDocumentCategory(companyID, data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleEmployee(store: Store, companyID: string, operation: operation, data: Employee) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedEmployee(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedEmployee(companyID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedEmployee(companyID, data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleEmployeeDimension(store: Store, operation: operation, data: EmployeeDimension) {
  switch (operation) {
    case UPDATE:
      store.dispatch(updatedEmployeeDimension(data.employeeID, data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleContract(store: Store, operation: operation, data: Contract) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedContract(data, false))
      break
    case UPDATE:
      store.dispatch(updatedContract(data.id, data, false))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleEmployeeEmergencyContact(store: Store, operation: operation, data: EmployeeEmergencyContact) {
  switch (operation) {
    case CREATE:
    case UPDATE:
      store.dispatch(updatedEmployeeEmergencyContact(data.employeeID, data))
      break
    case DELETE:
      store.dispatch(deletedEmployeeEmergencyContact(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleEmployment(store: Store, operation: operation, data: Employment) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedEmployment(data.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedEmployment(data.employeeID, data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleExpenseCategory(store: Store, companyID: string, operation: operation, data: ExpenseCategory) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedExpenseCategory(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedExpenseCategory(companyID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedExpenseCategory(companyID, data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleIncomeTaxReport(store: Store, operation: operation, data: IncomeTaxReport) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedIncomeTaxReport(data.payRollID, data))
      break
    case UPDATE:
      store.dispatch(updatedIncomeTaxReport(data.id, data))
      break
    case DELETE:
      store.dispatch(deletedIncomeTaxReport(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleInvoice(store: Store, operation: operation, data: Invoice) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedInvoice(data.payRollID, data.companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedInvoice(data.id, data))
      break
    case DELETE:
      store.dispatch(deletedInvoice(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleLeaveAdjustment(store: Store, operation: operation, data: LeaveAdjustment) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedLeaveAdjustment(data.employeeID, data.id, data))
      break
    case UPDATE:
      store.dispatch(updatedLeaveAdjustment(data.employeeID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedLeaveAdjustment(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleLeaveBalance(store: Store, operation: operation, data: LeaveBalance) {
  switch (operation) {
    case UPDATE:
      store.dispatch(updatedLeaveBalance(data.employeeID, data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleSupplementAdjustment(store: Store, operation: operation, data: SupplementAdjustment) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedSupplementAdjustment(data.employeeID, data.id, data))
      break
    case UPDATE:
      store.dispatch(updatedSupplementAdjustment(data.employeeID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedSupplementAdjustment(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleSupplementBalance(store: Store, operation: operation, data: SupplementBalance) {
  switch (operation) {
    case UPDATE:
      store.dispatch(updatedSupplementBalance(data.employeeID, data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleOneTimePay(store: Store, operation: operation, data: OneTimePay) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedOneTimePay(data.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedOneTimePay(data.employeeID, data))
      break
    case DELETE:
      store.dispatch(deletedOneTimePay(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleOneTimePayPension(store: Store, operation: operation, data: OneTimePayPension) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedOneTimePayPension(data.oneTimePay.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedOneTimePayPension(data.oneTimePay.employeeID, data))
      break
    case DELETE:
      store.dispatch(deletedOneTimePay(data.oneTimePay.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handlePayRoll(store: Store, companyID: string, operation: operation, data: PayRoll) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedPayRoll(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedPayRoll(companyID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedPayRoll(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handlePayRollDeviation(store: Store, operation: operation, data: PayRollDeviation) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedPayRollDeviation(data))
      break
    case UPDATE:
      store.dispatch(updatedPayRollDeviation(data.id, data))
      break
    case DELETE:
      store.dispatch(deletedPayRollDeviation(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handlePaySlip(store: Store, operation: operation, data: PaySlip) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedPaySlip(data.payRollID, data.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedPaySlip(data.id, data))
      break
    case DELETE:
      store.dispatch(deletedPaySlip(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleProject(store: Store, operation: operation, data: Project) {
  switch (operation) {
    case CREATE:
      store.dispatch(createdProject(data.companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedProject(data.companyID, data))
      break
    case DELETE:
      store.dispatch(deletedProject(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleReimbursementVoucher(store: Store, operation: operation, data: ReimbursementVoucher) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedReimbursementVoucher(data))
      break
    case UPDATE:
      store.dispatch(updatedReimbursementVoucher(data))
      break
    case DELETE:
      store.dispatch(deletedReimbursementVoucher(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleSalaryRegistration(store: Store, operation: operation, data: SalaryRegistration) {
  switch (operation) {
    case CREATE:
      store.dispatch(createdSalaryRegistration(data.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedSalaryRegistration(data.employeeID, data))
      break
    case DELETE:
      store.dispatch(deletedSalaryRegistration(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleSalaryType(store: Store, companyID: string, operation: operation, data: SalaryType) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedSalaryType(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedSalaryType(companyID, data.id, data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleSwipe(store: Store, companyID: string, operation: operation, data: Swipe) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedSwipe(companyID, data.id, data))
      break
    case UPDATE:
      store.dispatch(updatedSwipe(companyID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedSwipe(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleTaxCard(store: Store, operation: operation, data: TaxCard) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedTaxCard(data))
      break
    case UPDATE:
      store.dispatch(updatedTaxCard(data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleTaxCardRequest(store: Store, operation: operation, data: TaxCardRequest) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedTaxCardRequest(data.employeeID, data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleTimeRegistration(store: Store, operation: operation, data: TimeRegistration) {
  switch (operation) {
    case CREATE:
      store.dispatch(createdTimeRegistration(data.employeeID, data))
      break
    case UPDATE:
      store.dispatch(updatedTimeRegistration(data.employeeID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedTimeRegistration(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleTransfer(store: Store, companyID: string, operation: operation, data: Transfer) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedTransfer(data.payRollID, companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedTransfer(data.id, data))
      break
    case DELETE:
      store.dispatch(deletedTransfer(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleUser(store: Store, operation: operation, data: User) {
  switch (operation) {
    case UPDATE:
      store.dispatch(updatedUser(data))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleUserCompany(store: Store, operation: operation, data: CompanyUser) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedUserCompany(data))
      break
    case UPDATE:
      store.dispatch(updatedUserCompany(data))
      break
    case DELETE:
      if (data.id) {
        store.dispatch(deletedCompanyUserByID(data.id))
      }
      break
    default:
      // Unhandled operation
      break
  }
}

function handleVoucher(store: Store, companyID: string, operation: operation, data: Voucher) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedVoucher(data.companyID, data.payRollID, data))
      break
    case UPDATE:
      store.dispatch(updatedVoucher(data.id, data))
      break
    case DELETE:
      store.dispatch(deletedVoucher(data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

function handleWarning(store: Store, companyID: string, operation: operation, data: Warning) {
  switch (operation) {
    case CREATE:
      store.dispatch(addedWarning(companyID, data))
      break
    case UPDATE:
      store.dispatch(updatedWarning(companyID, data.id, data))
      break
    case DELETE:
      store.dispatch(deletedWarning(companyID, data.id))
      break
    default:
      // Unhandled operation
      break
  }
}

interface SocketItem {
  type: string
  operation: operation
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: Object
}

export interface SocketData {
  channel: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: Object | SocketItem[]
  type?: string
  operation?: operation
}

function handleMessageData(store: Store, channel: string, data: SocketItem): void {
  logDebug('socket', { ...data, channel })
  const [channelType, id] = channel.split(':')
  let companyID: string | null = id
  if (channelType === 'department') {
    const state = store.getState()
    companyID = null
    if (state.companies.company) {
      companyID = state.companies.company.id
    }
  }
  switch (data.type) {
    case 'accountingIntegration':
      if (companyID) {
        handleCompanyAccountingIntegration(store, companyID, data.operation, data.data as AccountingIntegration)
      }
      break
    case 'accountingDimension':
      if (companyID) {
        handleAccountingDimension(store, companyID, data.operation, data.data as AccountingDimension)
      }
      break
    case 'apiKey':
      if (companyID) {
        handleApiKey(store, companyID, data.operation, data.data as ApiKey)
      }
      break
    case 'asynchronousTask':
      if (companyID) {
        handleAsynchronousTask(store, companyID, data.operation, data.data as AsynchronousTask)
      }
      break
    case 'asset':
      if (companyID) {
        handleAsset(store, companyID, data.operation, data.data as Asset)
      }
      break
    case 'assetCategory':
      handleAssetCategory(store, data.operation, data.data as AssetCategory)
      break
    case 'carAllowance':
      handleCarAllowance(store, data.operation, data.data as CarAllowance)
      break
    case 'coarseCarAllowance':
      handleCoarseCarAllowance(store, data.operation, data.data as CoarseCarAllowance)
      break
    case 'coarseTimeRegistration':
      handleCoarseTimeRegistration(store, data.operation, data.data as CoarseTimeRegistration)
      break
    case 'coarseSalaryRegistration':
      handleCoarseSalaryRegistration(store, data.operation, data.data as CoarseSalaryRegistration)
      break
    case 'company':
      handleCompany(store, data.operation, data.data as Company)
      break
    case 'companyGroup':
      handleCompanyGroup(store, data.operation, data.data as CompanyGroup)
      break
    case 'companyFeature':
      handleCompanyFeature(store, data.operation, data.data as CompanyFeature)
      break
    case 'companyPricingPackage':
      if (companyID) {
        handleCompanyPricingPackage(store, companyID, data.operation, data.data as CompanyPricingPackage)
      }
      break
    case 'companyDeviationConfiguration':
      if (companyID) {
        handleCompanyDeviationConfiguration(
          store,
          companyID,
          data.operation,
          data.data as CompanyDeviationConfiguration
        )
      }
      break
    case 'costCenter':
      if (companyID) {
        handleCostCenter(store, companyID, data.operation, data.data as CostCenter)
      }
      break
    case 'department':
      if (companyID) {
        handleDepartment(store, companyID, data.operation, data.data as Department)
      }
      break
    case 'document':
      if (companyID) {
        handleDocument(store, companyID, data.operation, data.data as Document)
      }
      break
    case 'documentCategory':
      if (companyID) {
        handleDocumentCategory(store, companyID, data.operation, data.data as DocumentCategory)
      }
      break
    case 'employee':
      if (companyID) {
        handleEmployee(store, companyID, data.operation, data.data as Employee)
      }
      break
    case 'employeeDimension':
      handleEmployeeDimension(store, data.operation, data.data as EmployeeDimension)
      break
    case 'employeeContract':
      handleContract(store, data.operation, data.data as Contract)
      break
    case 'employeeEmergencyContact':
      handleEmployeeEmergencyContact(store, data.operation, data.data as EmployeeEmergencyContact)
      break
    case 'employment':
      handleEmployment(store, data.operation, data.data as Employment)
      break
    case 'employeeTimeRegistration':
      handleTimeRegistration(store, data.operation, data.data as TimeRegistration)
      break
    case 'expenseCategory':
      if (companyID) {
        handleExpenseCategory(store, companyID, data.operation, data.data as ExpenseCategory)
      }
      break
    case 'incomeTaxReport':
      handleIncomeTaxReport(store, data.operation, data.data as IncomeTaxReport)
      break
    case 'invoice':
      handleInvoice(store, data.operation, data.data as Invoice)
      break
    case 'leaveAdjustment':
      handleLeaveAdjustment(store, data.operation, data.data as LeaveAdjustment)
      break
    case 'leaveBalance':
      handleLeaveBalance(store, data.operation, data.data as LeaveBalance)
      break
    case 'supplementAdjustment':
      handleSupplementAdjustment(store, data.operation, data.data as SupplementAdjustment)
      break
    case 'supplementBalance':
      handleSupplementBalance(store, data.operation, data.data as SupplementBalance)
      break
    case 'oneTimePay':
      handleOneTimePay(store, data.operation, data.data as OneTimePay)
      break
    case 'oneTimePayPension':
      handleOneTimePayPension(store, data.operation, data.data as OneTimePayPension)
      break
    case 'paymentConfiguration':
      if (companyID) {
        handlePaymentConfiguration(store, companyID, data.operation, data.data as PaymentConfiguration)
      }
      break
    case 'paymentIntegration':
      if (companyID) {
        handlePaymentIntegration(store, companyID, data.operation, data.data as PaymentIntegration)
      }
      break
    case 'payRoll':
      if (companyID) {
        handlePayRoll(store, companyID, data.operation, data.data as PayRoll)
      }
      break
    case 'payRollDeviation':
      handlePayRollDeviation(store, data.operation, data.data as PayRollDeviation)
      break
    case 'paySlip':
      handlePaySlip(store, data.operation, data.data as PaySlip)
      break
    case 'project':
      handleProject(store, data.operation, data.data as Project)
      break
    case 'reimbursementVoucher':
      handleReimbursementVoucher(store, data.operation, data.data as ReimbursementVoucher)
      break
    case 'salaryRegistration':
      handleSalaryRegistration(store, data.operation, data.data as SalaryRegistration)
      break
    case 'salaryType':
      if (companyID) {
        handleSalaryType(store, companyID, data.operation, data.data as SalaryType)
      }
      break
    case 'swipe':
      if (companyID) {
        handleSwipe(store, companyID, data.operation, data.data as Swipe)
      }
      break
    case 'taxCard':
      handleTaxCard(store, data.operation, data.data as TaxCard)
      break
    case 'taxCardRequest':
      handleTaxCardRequest(store, data.operation, data.data as TaxCardRequest)
      break
    case 'transfer':
      if (companyID) {
        handleTransfer(store, companyID, data.operation, data.data as Transfer)
      }
      break
    case 'user':
      handleUser(store, data.operation, data.data as User)
      break
    case 'userCompany':
      handleUserCompany(store, data.operation, data.data as CompanyUser)
      break
    case 'voucher':
      if (companyID) {
        handleVoucher(store, companyID, data.operation, data.data as Voucher)
      }
      break
    case 'warning':
      if (companyID) {
        handleWarning(store, companyID, data.operation, data.data as Warning)
      }
      break
    default:
      // Unhandled type
      break
  }
}

export function handleMessage(store: Store, data: SocketData): void {
  if (data.channel == null) {
    return
  }
  if (Array.isArray(data.data)) {
    logDebug('socket array', { length: data.data.length, channel: data.channel })
    for (const item in data.data) {
      handleMessageData(store, data.channel, data.data[item])
    }
  } else {
    handleMessageData(store, data.channel, data as SocketItem)
  }
}
