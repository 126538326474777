import { EmployeeBatchStatus } from '../model/employeeBatchResult'
import { formatErrorMessageDetails } from './error-utils'
import { logWarning } from './log-utils'
import { t } from './translation-utils'

export function formatExcelColumn(col: number): string {
  return String.fromCharCode('A'.charCodeAt(0) + (col - 1))
}

export function formatStagedImportMessage(type: 'CSV Error' | 'Excel Error', message: string): string {
  switch (type) {
    case 'CSV Error':
    case 'Excel Error':
      switch (message) {
        case 'CompanyNotFound':
          return t('staged_import.error.company_not_found')
        case 'EmployeeNotFound':
          return t('staged_import.error.employee_not_found')
        default:
          if (message) {
            return message
          }
          if (process.env.NODE_ENV !== 'test') {
            logWarning('Missing staged import message CSV/Excel error message')
          }
          return t('staged_import.error.unknown')
      }
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing staged import message type handling: ' + type)
      }
      if (message) {
        return message
      }
      return t('staged_import.error.unknown')
  }
}

export function formatEmployeeBatchStatus(state: EmployeeBatchStatus): string {
  switch (state) {
    case 'Success':
      return t('staged_import.status.success')
    case 'NoChange':
      return t('staged_import.status.no_change')
    case 'Failure':
      return t('staged_import.status.failure')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Unknown employee bath message state: ' + state)
      }
      return t('common.unknown')
  }
}

export function formatEmployeeBatchMessage(state: EmployeeBatchStatus, message: string, details: string[]): string {
  switch (state) {
    case 'Success':
      switch (message) {
        case 'Employee Invited':
          return t('employee_batch.message.success.employee_invited')
        case 'Employee Updated':
          return t('employee_batch.message.success.employee_updated')
        case 'Employee Contract Updated':
          return t('employee_batch.message.success.employee_contract_updated')
        case 'Employee Terminated':
          return t('employee_batch.message.success.employee_terminated')
        case 'Employee Termination Scheduled':
          return t('employee_batch.message.success.employee_termination_scheduled')
        default:
          if (process.env.NODE_ENV !== 'test') {
            logWarning('Missing employee bath message success handling: ' + message)
          }
          return t('employee_batch.message.success.generic')
      }
    case 'NoChange':
      switch (message) {
        // no changes
        case 'Already Had Pay Slip Transport Type':
          return t('employee_batch.message.no_change.already_had_pay_slip_transport_type')
        case 'Already Did Not Have Pay Slip Transport Type':
          return t('employee_batch.message.no_change.already_did_not_have_pay_slip_transport_type')
        case 'Already Did Not Have Benefit':
        case 'Already Had Benefit':
        case 'Already Had Benefit With Same Amount':
        case 'Already Did Not Have Supplement':
        case 'Already Had Supplement':
        case 'Already Had Supplement With Same Rate':
          return t('employee_batch.message.no_change.employee_contract_needed_no_update')
        case 'Employee Already In Department':
          return t('employee_batch.message.no_change.employee_already_in_department')
        case 'Employee Already Not In Department':
          return t('employee_batch.message.no_change.employee_already_not_in_department')
        case 'Employee Already Set To language':
          return t('employee_batch.message.no_change.employee_already_set_to_language')
        case 'Employee Already Invited':
          return t('employee_batch.message.no_change.employee_already_invited')
        case 'Employee Already Had Preferred Tax Card':
          return t('employee_batch.message.no_change.employee_already_had_preferred_tax_card')
        case 'Great Prayer Day Not Enabled For All Employees':
          return t('employee_batch.message.great_prayer_day_not_enabled_for_all_employees')
        case 'Great Prayer Day Not Available Yet':
          return t('employee_batch.message.great_prayer_day_not_available_yet')
        default:
          if (process.env.NODE_ENV !== 'test') {
            logWarning('Missing employee bath message no change handling: ' + message)
          }
          return t('employee_batch.message.no_change.generic')
      }
    case 'Failure':
      switch (message) {
        case 'Company Not Found':
          return t('employee_batch.message.failure.company_not_found')
        case 'Leave Type Not Found':
          return t('employee_batch.message.failure.leave_type_not_found')
        case 'Salary Definition Not Found':
          return t('employee_batch.message.failure.salary_definition_not_found')
        case 'Email Not Found':
          return t('employee_batch.message.failure.email_not_found')
        case 'Phone Number Not Set':
          return t('employee_batch.message.failure.phone_number_not_set')
        case 'Invalid National ID':
          if (details) {
            if (details.some((d) => d === 'DK No CPR')) {
              return t('employee_batch.message.failure.invalid_national_id.dk_no_cpr')
            }
            if (details.some((d) => d === 'Must Have CPR')) {
              return t('employee_batch.message.failure.invalid_national_id.must_have_cpr')
            }
          }
          return t('employee_batch.message.failure.invalid_national_id')
        case 'Employee Contract Not Found':
          return t('employee_batch.message.failure.employee_contract_not_found')
        case 'Employee Contract Invalid':
          if (details) {
            if (details.some((d) => d === 'Validate Create Contract')) {
              return t('employee_batch.message.failure.employee_contract_invalid.validate_create_contract')
            }
            if (details.some((d) => d === 'Update Employee Contract')) {
              return t('employee_batch.message.failure.employee_contract_invalid.update_employee_contract')
            }
            if (details.some((d) => d === 'Create Employee Contract')) {
              return t('employee_batch.message.failure.employee_contract_invalid.create_employee_contract')
            }
          }
          return t('employee_batch.message.failure.employee_contract_invalid')
        case 'Employee Is Freelancer':
          return t('employee_batch.message.failure.employee_is_freelancer')
        case 'Company Feature Missing':
          return t('employee_batch.message.failure.company_feature_missing')
        case 'Pension Definition Invalid':
          return t('employee_batch.message.failure.pension_definition_invalid')
        default: {
          // OK, we do not handle this error message here, try using formatErrorMessage for that
          const returnMessage = formatErrorMessageDetails(message, details)
          if (!returnMessage) {
            if (process.env.NODE_ENV !== 'test') {
              logWarning('Missing employee bath message failure handling: ' + message)
            }
            return t('employee_batch.message.failure.generic')
          }
          return returnMessage as string
        }
      }
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Unknown employee bath message state: ' + state)
      }
      return t('common.unknown')
  }
}
