import omit from 'omit.js'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CompanySetting from '../../model/companySetting'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyReducer } from '../../reducers/companies'
import { paths } from '../../routes'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { createSynchroniser, executeSynchroniser } from '../../utils/sync-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import AccountingSettingsForm, { AccountingSettingsResult } from './AccountingSettingsForm'

type Props = {
  alerts: AlertReducer
  company: Company
  companies: CompanyReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateCompany: (company: Company) => Promise<Company | void>
  enableCompanySettings: (companyID: string, settings: CompanySetting[]) => Promise<Company | void>
  disableCompanySettings: (companyID: string, settings: CompanySetting[]) => Promise<Company | void>
}

export default function AccountingSettings(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const { company, companies, addAlert } = props
  const previousCompanies = usePrevious(companies)

  useEffect(() => {
    if (previousCompanies && previousCompanies.saving && !companies.saving) {
      if (!companies.error) {
        addAlert('success', t('accounting_tab.settings.alert.success', { name: company.name }), { timeout: 5 })
      }
    }
  })

  useEffect(() => {
    regularComponentDidUpdate(companies.error, error, setError)
  }, [companies, error])

  const handleSubmit = (values: AccountingSettingsResult) => {
    const actions = createSynchroniser<Company>()
    if (values.disableSettings.length > 0) {
      actions.push(() => props.disableCompanySettings(props.company.id, values.disableSettings))
    }
    if (values.enableSettings.length > 0) {
      actions.push(() => props.enableCompanySettings(props.company.id, values.enableSettings))
    }
    actions.push(() => {
      return props.updateCompany({ ...props.company, ...omit(values, ['enableSettings', 'disableSettings']) })
    })
    executeSynchroniser(actions)
  }

  return (
    <Card>
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <TitleMenu>
        <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING}>
          <Button>{t('accounting_tab.settings.back')}</Button>
        </Link>
      </TitleMenu>
      <Title>{t('accounting_tab.settings.title')}</Title>
      <AccountingSettingsForm company={props.company} onSubmit={handleSubmit} />
    </Card>
  )
}
