// stolen from https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
export function escapeRegExp(s: string) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function capitalise(s: string) {
  return s[0].toUpperCase() + s.substring(1)
}

export function decapitalise(s: string) {
  return s[0].toLowerCase() + s.substring(1)
}

export function camelCaseToSnakeCase(s?: string) {
  if (!s) {
    return undefined
  }
  s = s.replace(/([A-Z])/g, '_$1').toLowerCase()
  if (s[0] === '_') {
    return s.substring(1)
  }
  return s
}
