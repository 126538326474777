import React from 'react'

import { fetchVouchers, patchVoucher, postVoucherRebook } from '../api/vouchers'
import ActionTypes from '../constants/action-types'
import { DateFormat } from '../model/types'
import Voucher from '../model/voucher'
import { VoucherAction } from '../reducers/vouchers'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingVouchers(companyID: string, payRollID?: string): VoucherAction {
  return {
    type: ActionTypes.VOUCHERS_LOADING,
    companyID,
    payRollID,
  }
}
function loadedVouchers(companyID: string, payRollID: string | undefined, vouchers: Voucher[]): VoucherAction {
  return {
    type: ActionTypes.VOUCHERS_LOADED,
    vouchers,
    companyID,
    payRollID,
  }
}
function failedLoadingVouchers(companyID: string, payRollID: string | undefined, error: Error): VoucherAction {
  return {
    type: ActionTypes.VOUCHERS_LOAD_FAILED,
    error,
    companyID,
    payRollID,
  }
}

export function addedVoucher(companyID: string, payRollID: string | undefined, voucher: Voucher): VoucherAction {
  return {
    type: ActionTypes.VOUCHER_ADDED,
    companyID,
    payRollID,
    voucher,
  }
}

function updatingVoucher(voucherID: string): VoucherAction {
  return {
    type: ActionTypes.VOUCHER_UPDATING,
    voucherID,
  }
}
export function updatedVoucher(voucherID: string, voucher: Voucher): VoucherAction {
  return {
    type: ActionTypes.VOUCHER_UPDATED,
    voucherID,
    voucher,
  }
}
function failedUpdatingVoucher(voucherID: string, error: Error): VoucherAction {
  return {
    type: ActionTypes.VOUCHER_UPDATE_FAILED,
    voucherID,
    error,
  }
}

export function deletedVoucher(voucherID: string): VoucherAction {
  return {
    type: ActionTypes.VOUCHER_DELETED,
    voucherID,
  }
}

export function getVouchers(payRollID?: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Voucher[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(loadingVouchers(companyID, payRollID))
    return fetchVouchers(payRollID, companyID)
      .then((res) => {
        dispatch(loadedVouchers(companyID, payRollID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingVouchers(companyID, payRollID, e))
        }
      })
  }
}

export function rebookVoucher(id: string) {
  return (dispatch: React.Dispatch<any>): Promise<Voucher | void> => {
    dispatch(updatingVoucher(id))
    return postVoucherRebook(id)
      .then((res) => {
        dispatch(updatedVoucher(id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingVoucher(id, e))
        }
      })
  }
}

export function changeVoucherDate(id: string, date: DateFormat) {
  return (dispatch: React.Dispatch<any>): Promise<Voucher | void> => {
    dispatch(updatingVoucher(id))
    return patchVoucher(id, date)
      .then((res) => {
        dispatch(updatedVoucher(id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingVoucher(id, e))
        }
      })
  }
}
