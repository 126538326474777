type cronDetails = {
  dayFrequency: number
  hourStamp: number
}

/**
 * parseCron assumes the following:
 * minute is 0
 * hour is between 0 and 23 (inclusive)
 * day is either * or followed by /N (where N is the day frequency)
 * month and week day are *
 *
 * @param cron
 */
export function parseCron(cron: string): cronDetails {
  const s = cron.split(' ')
  if (s.length !== 5) {
    return {
      dayFrequency: -1,
      hourStamp: -1,
    }
  }
  const hourStamp = parseInt(s[1])
  const dayS = s[2].split('/')
  let dayFrequency = 1
  if (dayS.length === 2) {
    dayFrequency = parseInt(dayS[1])
  }
  return {
    dayFrequency,
    hourStamp,
  }
}

export function buildCron(details: cronDetails): string {
  let day = '*'
  if (details.dayFrequency > 1) {
    day = `*/${details.dayFrequency}`
  }
  return `0 ${details.hourStamp} ${day} * *`
}
