import * as Sentry from '@sentry/browser'

export function logDebug(this: any, message: string, extra?: Record<string, unknown>): void {
  if (process.env.NODE_ENV !== 'production') {
    console.log.apply(this, [message, extra])
  }
}

export function log(this: any, message: string, extra?: Record<string, unknown>) {
  if (extra) {
    Sentry.withScope((scope) => {
      for (const key in extra) {
        scope.setExtra(key, extra[key])
      }
      Sentry.captureMessage(message, Sentry.Severity.Info)
    })
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log.apply(this, [message])
  }
}

export function logWarning(this: any, message: string, extra?: Record<string, unknown>) {
  if (extra) {
    Sentry.withScope((scope) => {
      for (const key in extra) {
        scope.setExtra(key, extra[key])
      }
      Sentry.captureMessage(message, Sentry.Severity.Warning)
    })
  }
  if (process.env.NODE_ENV !== 'production') {
    console.warn.apply(this, [message])
  }
}

export function logError(this: any, message: string, extra?: Record<string, unknown>) {
  if (extra) {
    Sentry.withScope((scope) => {
      for (const key in extra) {
        scope.setExtra(key, extra[key])
      }
      Sentry.captureMessage(message, Sentry.Severity.Error)
    })
  }
  if (process.env.NODE_ENV !== 'production') {
    console.error.apply(this, [message])
  }
}
