import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import ApiKey from '../model/apiKey'
import { ReducerAction } from '../utils/reducer-utils'

export interface ApiKeyReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  apiKeys: List<ApiKey>
  error: Error | null
}

const Params = Record<ApiKeyReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  apiKeys: List<ApiKey>(),
  error: null,
})

export interface ApiKeyAction extends ReducerAction {
  companyID?: string
  apiKeys?: ApiKey[]
  apiKey?: ApiKey
  apiKeyID?: string
}

export default (
  state: Record<ApiKeyReducer> = Params(),
  action: ApiKeyAction = { type: '' }
): Record<ApiKeyReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.API_KEYS_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.API_KEYS_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('apiKeys', List<ApiKey>(action.apiKeys))
        .set('error', null)
    case ActionTypes.API_KEYS_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('error', action.error || null)

    case ActionTypes.API_KEY_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.API_KEY_ADDED:
      if (!action.apiKey) {
        return state
      }
      idx = state.get('apiKeys').findIndex((item) => !!action.apiKey && item.id === action.apiKey.id)

      if (idx !== -1) {
        return state.set('saving', false).set('apiKeys', state.get('apiKeys').set(idx, action.apiKey))
      }
      return state.set('saving', false).set('apiKeys', state.get('apiKeys').push(action.apiKey))
    case ActionTypes.API_KEY_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.API_KEY_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.API_KEY_DELETED:
      idx = state.get('apiKeys').findIndex((item) => item.id === action.apiKeyID)
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('apiKeys', state.get('apiKeys').delete(idx))
    case ActionTypes.API_KEY_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
