import React, { ReactElement, useEffect, useState } from 'react'

import { AssetCategoryMutableFields } from '../../../model/assetCategory'
import Company from '../../../model/company'
import { AssetCategoryReducer } from '../../../reducers/assetCategories'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import AssetCategoryEditForm, { AssetCategoryResult } from './AssetCategoryEditForm'

type Props = {
  visible: boolean
  company: Company
  assetCategoryID?: string
  createAssetCategory: (assetCategory: AssetCategoryMutableFields) => void
  updateAssetCategory: (assetCategory: AssetCategoryMutableFields) => void
  assetCategories: AssetCategoryReducer
}

export default function AssetCategoryEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, assetCategories } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, assetCategories.error, error, setError)
  }, [visible, assetCategories, error])

  const handleSubmit = (values: AssetCategoryResult) => {
    if (!props.company) {
      return
    }
    const assetCategory: AssetCategoryMutableFields = {
      companyID: props.company.id,
      title: values.title,
    }
    if (props.assetCategoryID) {
      assetCategory.id = props.assetCategoryID
      props.updateAssetCategory(assetCategory)
    } else {
      props.createAssetCategory(assetCategory)
    }
  }

  return (
    <Card>
      <Subtitle>
        {props.assetCategoryID ? t('asset_category.edit.title.edit') : t('asset_category.edit.title.create')}
      </Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <AssetCategoryEditForm
        company={props.company}
        assetCategoryID={props.assetCategoryID}
        assetCategories={props.assetCategories}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
