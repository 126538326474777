import CompanyFeature from '../model/companyFeature'
import CompanySetting from '../model/companySetting'

export function hasPaySlipTransportDefault(
  method: 'EMail' | 'MitDK' | 'EBoks' | 'SMS',
  settings: CompanySetting[],
  companyFeatures: CompanyFeature[]
): boolean {
  switch (method) {
    case 'EMail':
      return settings.some((setting) => setting === 'PaySlipTransportDefaultEMail')
    case 'MitDK':
      return (
        companyFeatures.some((feature) => feature.featureType === 'DK EBoks') &&
        settings.some((setting) => setting === 'PaySlipTransportDefaultMitDK')
      )
    case 'EBoks':
      return (
        companyFeatures.some((feature) => feature.featureType === 'DK EBoks') &&
        settings.some((setting) => setting === 'PaySlipTransportDefaultEBoks')
      )
    case 'SMS':
      return (
        companyFeatures.some((feature) => feature.featureType === 'SMS') &&
        settings.some((setting) => setting === 'PaySlipTransportDefaultSMS')
      )
    default:
      return false
  }
}
