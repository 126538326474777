import React from 'react'

import {
  delAsynchronousSchedule,
  fetchAsynchronousSchedules,
  patchAsynchronousSchedule,
  postAsynchronousSchedule,
} from '../api/asynchronous-schedules'
import ActionTypes from '../constants/action-types'
import AsynchronousSchedule, { AsynchronousScheduleCreationFields } from '../model/asynchronousSchedule'
import { AsynchronousScheduleAction } from '../reducers/asynchronousSchedules'
import { isRequestError } from '../utils/error-utils'

function loadingAsynchronousSchedules(companyID: string): AsynchronousScheduleAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_SCHEDULE_LOADING,
    companyID,
  }
}
function loadedAsynchronousSchedules(
  companyID: string,
  asynchronousSchedules: AsynchronousSchedule[]
): AsynchronousScheduleAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_SCHEDULE_LOADED,
    companyID,
    asynchronousSchedules,
  }
}
function failedLoadingAsynchronousSchedules(companyID: string, error: Error): AsynchronousScheduleAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_SCHEDULE_LOAD_FAILED,
    companyID,
    error,
  }
}

function addingAsynchronousSchedule(companyID: string): AsynchronousScheduleAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_SCHEDULE_ADDING,
    companyID,
  }
}
function addedAsynchronousSchedule(
  companyID: string,
  asynchronousSchedule: AsynchronousSchedule
): AsynchronousScheduleAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_SCHEDULE_ADDED,
    companyID,
    asynchronousSchedule,
  }
}
function failedAddingAsynchronousSchedule(companyID: string, error: Error): AsynchronousScheduleAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_SCHEDULE_ADD_FAILED,
    companyID,
    error,
  }
}

function updatingAsynchronousSchedule(companyID: string): AsynchronousScheduleAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_SCHEDULE_UPDATING,
    companyID,
  }
}
function updatedAsynchronousSchedule(
  companyID: string,
  asynchronousSchedule: AsynchronousSchedule
): AsynchronousScheduleAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_SCHEDULE_UPDATED,
    companyID,
    asynchronousSchedule,
  }
}
function failedUpdatingAsynchronousSchedule(companyID: string, error: Error): AsynchronousScheduleAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_SCHEDULE_UPDATE_FAILED,
    companyID,
    error,
  }
}

function deletingAsynchronousSchedule(companyID: string): AsynchronousScheduleAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_SCHEDULE_DELETING,
    companyID,
  }
}
function deletedAsynchronousSchedule(companyID: string, asynchronousScheduleID: string): AsynchronousScheduleAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_SCHEDULE_DELETED,
    companyID,
    asynchronousScheduleID,
  }
}
function failedDeletingAsynchronousSchedule(companyID: string, error: Error): AsynchronousScheduleAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_SCHEDULE_DELETE_FAILED,
    companyID,
    error,
  }
}

export function getAsynchronousSchedules(companyID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(loadingAsynchronousSchedules(companyID))
    return fetchAsynchronousSchedules(companyID)
      .then((res) => {
        dispatch(loadedAsynchronousSchedules(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingAsynchronousSchedules(companyID, e))
        }
      })
  }
}

export function addAsynchronousSchedule(schedule: AsynchronousScheduleCreationFields) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(addingAsynchronousSchedule(schedule.companyID))
    return postAsynchronousSchedule(schedule)
      .then((res) => {
        dispatch(addedAsynchronousSchedule(schedule.companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingAsynchronousSchedule(schedule.companyID, e))
        }
      })
  }
}

export function updateAsynchronousSchedule(companyID: string, scheduleID: string, cron: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(updatingAsynchronousSchedule(companyID))
    return patchAsynchronousSchedule(scheduleID, cron)
      .then((res) => {
        dispatch(updatedAsynchronousSchedule(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingAsynchronousSchedule(companyID, e))
        }
      })
  }
}

export function deleteAsynchronousSchedule(companyID: string, scheduleID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(deletingAsynchronousSchedule(companyID))
    return delAsynchronousSchedule(scheduleID)
      .then((res) => {
        dispatch(deletedAsynchronousSchedule(companyID, scheduleID))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingAsynchronousSchedule(companyID, e))
        }
      })
  }
}
