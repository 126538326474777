import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../model/company'
import { DocumentMutableFields } from '../../model/document'
import Employee from '../../model/employee'
import { DocumentReducer } from '../../reducers/documents'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import AttachDocumentForm, { AttachDocumentResult } from './AttachDocumentForm'

type Props = {
  visible: boolean
  company: Company
  documentID: string
  documents: DocumentReducer
  employees: List<Employee>

  updateDocument: (companyID: string, document: DocumentMutableFields) => void
}

export default function AttachDocument(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, documents } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, documents.error, error, setError)
  }, [visible, documents, error])

  const handleSubmit = (values: AttachDocumentResult) => {
    const existingDocument = documents.documents.find((document) => document.id === props.documentID)
    if (existingDocument) {
      props.updateDocument(props.company.id, { ...existingDocument, employeeID: values.employeeID })
    }
  }

  return (
    <Card className="document-modal">
      <Subtitle>{t('documents.attach.title')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <AttachDocumentForm employees={props.employees} onSubmit={handleSubmit} />
    </Card>
  )
}
