import React from 'react'

import {
  fetchLeaveAdjustments,
  postLeaveAdjustment,
  postTransferLeaveBalances,
  putLeaveAdjustment,
  removeLeaveAdjustment,
  TransferLeaveBalance,
} from '../api/leave-adjustments'
import ActionTypes from '../constants/action-types'
import LeaveAdjustment, { LeaveAdjustmentCreationFields, LeaveAdjustmentMutableFields } from '../model/leaveAdjustment'
import { LeaveAdjustmentAction } from '../reducers/leaveAdjustments'
import { isRequestError } from '../utils/error-utils'

function loadingLeaveAdjustments(companyID: string | undefined, employeeID: string | undefined): LeaveAdjustmentAction {
  return {
    type: ActionTypes.LEAVE_ADJUSTMENTS_LOADING,
    companyID,
    employeeID,
  }
}
function loadedLeaveAdjustments(
  companyID: string | undefined,
  employeeID: string | undefined,
  leaveAdjustments: LeaveAdjustment[]
): LeaveAdjustmentAction {
  return {
    type: ActionTypes.LEAVE_ADJUSTMENTS_LOADED,
    companyID,
    employeeID,
    leaveAdjustments,
  }
}
function failedLoadingLeaveAdjustments(
  companyID: string | undefined,
  employeeID: string | undefined,
  error: Error
): LeaveAdjustmentAction {
  return { type: ActionTypes.LEAVE_ADJUSTMENTS_LOAD_FAILED, companyID, employeeID, error }
}

function addingLeaveAdjustment(employeeID: string): LeaveAdjustmentAction {
  return {
    type: ActionTypes.LEAVE_ADJUSTMENTS_ADDING,
    employeeID,
  }
}
export function addedLeaveAdjustment(
  employeeID: string,
  leaveAdjustmentID: string,
  leaveAdjustment: LeaveAdjustment
): LeaveAdjustmentAction {
  return {
    type: ActionTypes.LEAVE_ADJUSTMENTS_ADDED,
    employeeID,
    leaveAdjustmentID,
    leaveAdjustment,
  }
}
function failedAddedLeaveAdjustment(employeeID: string, error: Error): LeaveAdjustmentAction {
  return { type: ActionTypes.LEAVE_ADJUSTMENTS_ADD_FAILED, employeeID, error }
}

function updatingLeaveAdjustment(employeeID: string, leaveAdjustmentID?: string): LeaveAdjustmentAction {
  return {
    type: ActionTypes.LEAVE_ADJUSTMENTS_UPDATING,
    employeeID,
    leaveAdjustmentID,
  }
}
export function updatedLeaveAdjustment(
  employeeID: string,
  leaveAdjustmentID?: string,
  leaveAdjustment?: LeaveAdjustment
): LeaveAdjustmentAction {
  return {
    type: ActionTypes.LEAVE_ADJUSTMENTS_UPDATED,
    employeeID,
    leaveAdjustmentID,
    leaveAdjustment,
  }
}
function failedUpdatingLeaveAdjustment(employeeID: string, error: Error): LeaveAdjustmentAction {
  return { type: ActionTypes.LEAVE_ADJUSTMENTS_UPDATE_FAILED, employeeID, error }
}

function deletingLeaveAdjustment(leaveAdjustmentID: string): LeaveAdjustmentAction {
  return {
    type: ActionTypes.LEAVE_ADJUSTMENTS_DELETING,
    leaveAdjustmentID,
  }
}
export function deletedLeaveAdjustment(leaveAdjustmentID: string): LeaveAdjustmentAction {
  return {
    type: ActionTypes.LEAVE_ADJUSTMENTS_DELETED,
    leaveAdjustmentID,
  }
}
function failedDeletingLeaveAdjustment(leaveAdjustmentID: string, error: Error): LeaveAdjustmentAction {
  return { type: ActionTypes.LEAVE_ADJUSTMENTS_DELETE_FAILED, leaveAdjustmentID, error }
}

export function getLeaveAdjustments(companyID?: string, employeeID?: string) {
  return (dispatch: React.Dispatch<any>): Promise<LeaveAdjustment[] | void> => {
    dispatch(loadingLeaveAdjustments(companyID, employeeID))
    return fetchLeaveAdjustments(companyID, employeeID)
      .then((res) => {
        dispatch(loadedLeaveAdjustments(companyID, employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingLeaveAdjustments(companyID, employeeID, e))
        }
      })
  }
}

export function addLeaveAdjustment(employeeID: string, leaveAdjustment: LeaveAdjustmentCreationFields) {
  return (dispatch: React.Dispatch<any>): Promise<LeaveAdjustment | void> => {
    dispatch(addingLeaveAdjustment(employeeID))
    return postLeaveAdjustment(leaveAdjustment)
      .then((res) => {
        dispatch(addedLeaveAdjustment(employeeID, res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddedLeaveAdjustment(employeeID, e))
        }
      })
  }
}

export function updateLeaveAdjustment(employeeID: string, leaveAdjustment: LeaveAdjustmentMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<LeaveAdjustment | void> => {
    dispatch(updatingLeaveAdjustment(employeeID, leaveAdjustment.id))
    return putLeaveAdjustment(leaveAdjustment.id, leaveAdjustment)
      .then((res) => {
        dispatch(updatedLeaveAdjustment(employeeID, leaveAdjustment.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingLeaveAdjustment(employeeID, e))
        }
      })
  }
}

export function deleteLeaveAdjustment(leaveAdjustmentID: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(deletingLeaveAdjustment(leaveAdjustmentID))
    return removeLeaveAdjustment(leaveAdjustmentID)
      .then(() => {
        dispatch(deletedLeaveAdjustment(leaveAdjustmentID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingLeaveAdjustment(leaveAdjustmentID, e))
        }
      })
  }
}

export function transferLeaveBalances(employeeID: string, transfers: TransferLeaveBalance[]) {
  return (dispatch: React.Dispatch<any>): Promise<LeaveAdjustment | void> => {
    dispatch(updatingLeaveAdjustment(employeeID))
    return postTransferLeaveBalances(employeeID, transfers)
      .then((res) => {
        dispatch(updatedLeaveAdjustment(employeeID))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingLeaveAdjustment(employeeID, e))
        }
      })
  }
}
