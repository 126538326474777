import React, { ReactElement, useEffect, useState } from 'react'

import { addAlertSignature } from '../../../actions/alerts'
import CompanyGroup, { CompanyGroupUser, GroupUserType } from '../../../model/companyGroup'
import { UserPermission } from '../../../model/companyUser'
import UserInvite from '../../../model/userInvite'
import { CompanyGroupReducer } from '../../../reducers/companyGroups'
import { UserReducer } from '../../../reducers/user'
import { UserInviteCompanyGroupReducer } from '../../../reducers/userInviteCompanyGroups'
import CompanyGroupUserType from '../../../types/company-group-user-type'
import { formatDate } from '../../../utils/date-utils'
import { formatCompanyGroupUserType, formatUserPermission } from '../../../utils/format-utils'
import { getPage, setPage } from '../../../utils/route-utils'
import { t } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Table from '../../antd/table'
import Button from '../../elements/button'
import ContextMenu from '../../elements/ContextMenu'
import Icon from '../../elements/Icon'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import CompanyGroupUserAdd from './CompanyGroupUserAdd'
import UserEditModal from './UserEditModal'

type Props = {
  companyGroup: CompanyGroup
  companyGroups: CompanyGroupReducer
  userInviteCompanyGroups: UserInviteCompanyGroupReducer
  user: UserReducer
  companyGroupUser?: CompanyGroupUser

  addAlert: addAlertSignature
  removeUserFromCompanyGroup: (groupID: string, userID: string) => void
  getCompanyGroupUserInvites: (companyGroupID: string) => void
  addCompanyGroupUserInvite: (
    companyGroupID: string,
    email: string,
    groupUserType: CompanyGroupUserType,
    permissions: UserPermission[]
  ) => Promise<UserInvite | void>
  deleteCompanyGroupUserInvite: (groupID: string, email: string) => void
  updateCompanyGroupUser: (
    companyGroupID: string,
    userID: string,
    companyGroupUser: CompanyGroupUser
  ) => Promise<CompanyGroupUser | void>
}

export default function UsersOverview(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showEditingUser, setShowEditingUser] = useState<string | boolean>(false)
  const [showAddUserInvite, setShowAddUserInvite] = useState(false)
  const [deleting, setDeleting] = useState<string[]>([])

  const { userInviteCompanyGroups, getCompanyGroupUserInvites, companyGroup } = props

  useEffect(() => {
    if (!userInviteCompanyGroups.loading && !userInviteCompanyGroups.loaded) {
      getCompanyGroupUserInvites(companyGroup.id)
    }
  })

  const setEditVisibility = (userID: string | boolean) => {
    setModalKey((prev) => prev + 1)
    setShowEditingUser(userID)
  }

  const setShowInviteUser = (visible: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowAddUserInvite(visible)
  }

  const deleteUser = (userID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        props.removeUserFromCompanyGroup(props.companyGroup.id, userID)
      }
    }
  }

  const deleteInvitation = (email: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, email])
        props.deleteCompanyGroupUserInvite(props.companyGroup.id, email)
      }
    }
  }

  const isGroupAdmin = () => {
    return !!props.companyGroupUser && props.companyGroupUser.groupUserType === CompanyGroupUserType.ADMIN
  }

  const permissionList = (permissions: UserPermission[]) => {
    return permissions
      .sort((a, b) => formatUserPermission(a).localeCompare(formatUserPermission(b)))
      .reduce((permissions, permission, i) => {
        if (i > 0) {
          permissions += ', '
        }
        return permissions + formatUserPermission(permission)
      }, '')
  }

  type UserInviteRow = {
    key: string
    id: string
    email: string
    type: string
    createdAt?: string
  }

  const getUserInvites = (): UserInviteRow[] => {
    return props.userInviteCompanyGroups.userInvites
      .filter((userInvite) => userInvite.companyGroupID && !deleting.some((email) => email === userInvite.email))
      .map((userInvite) => {
        let type = formatCompanyGroupUserType(userInvite.groupUserType || 'Regular')
        if (userInvite.permissions) {
          type += ' (' + permissionList(userInvite.permissions) + ')'
        }
        return {
          key: userInvite.email,
          id: userInvite.email,
          email: userInvite.email,
          type,
          createdAt: userInvite.createdAt ? formatDate(userInvite.createdAt) : undefined,
        }
      })
      .toArray()
  }

  type UserRow = {
    key: string
    id: string
    userName: string
    type: string
    userType: GroupUserType
    permissions: string
  }

  const getUsers = (): UserRow[] => {
    const order: Record<GroupUserType, number> = {
      Admin: 0,
      Regular: 1,
    }

    return props.companyGroup.users
      .map((user) => {
        const permissions = permissionList(user.permissions)
        return {
          key: user.userID,
          id: user.userID,
          userName: user.user.name || user.user.email,
          type: formatCompanyGroupUserType(user.groupUserType),
          userType: user.groupUserType,
          permissions: permissions,
        }
      })
      .sort((a, b) => {
        const sort = order[a.userType] - order[b.userType]
        if (sort !== 0) {
          return sort
        }

        return a.userName.localeCompare(b.userName)
      })
  }

  const userColumns = [
    { title: t('companies.group.users.table.header.user_name'), dataIndex: 'userName', key: 'userName' },
    { title: t('companies.group.users.table.header.type'), dataIndex: 'type', key: 'type' },
    { title: t('companies.group.users.table.header.permissions'), dataIndex: 'permissions', key: 'permissions' },
    {
      title: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (user: UserRow) => {
        if (!isGroupAdmin() && user.userType === 'Admin') {
          return null
        }
        return (
          <ContextMenu>
            <DumbLink
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                setEditVisibility(user.id)
              }}
            >
              <Icon type="edit" color="grey" /> {t('companies.group.users.action.edit_permissions')}
            </DumbLink>
            {isGroupAdmin() && (
              <DumbLink onClick={deleteUser(user.id)}>
                <Icon type="cross" color="grey" /> {t('companies.group.users.action.delete')}
              </DumbLink>
            )}
          </ContextMenu>
        )
      },
    },
  ]

  const userInviteColumns = [
    {
      title: t('companies.group.users.invites.table.header.email'),
      dataIndex: '',
      key: 'xEmail',
      render: (userInvite: UserInviteRow) => {
        return (
          <div>
            {userInvite.email}
            <small>{userInvite.type}</small>
          </div>
        )
      },
    },
    { title: t('companies.group.users.invites.table.header.created_at'), dataIndex: 'createdAt', key: 'createdAt' },
    {
      title: '',
      dataIndex: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (userInvite: UserInviteRow) => {
        return (
          <ContextMenu>
            <DumbLink onClick={deleteInvitation(userInvite.id)}>
              <Icon type="user-delete" color="grey" /> {t('companies.group.users.invites.table.action.delete')}
            </DumbLink>
          </ContextMenu>
        )
      },
    },
  ]

  if (!props.userInviteCompanyGroups.loaded) {
    return <LoadingOverlay />
  }

  const count = props.companyGroup.users.length
  const inviteCount = getUserInvites().length

  return (
    <div className="company-group-users">
      <TitleMenu>
        <Button type="primary" onClick={() => setShowInviteUser(true)}>
          <Icon type="user-add" />
          {t('companies.group.users.header.add')}
        </Button>
      </TitleMenu>
      {isGroupAdmin() && (
        <>
          <Title>{t('companies.group.users.title')}</Title>

          <Table
            columns={userColumns}
            dataSource={getUsers()}
            pagination={count > 10 ? { defaultCurrent: getPage(), onChange: setPage } : false}
          />

          <Modal
            key={modalKey}
            visible={showEditingUser !== false}
            onOk={() => setEditVisibility(false)}
            onCancel={() => setEditVisibility(false)}
            width={376}
            footer={null}
          >
            <UserEditModal
              visible={showEditingUser !== false}
              companyGroups={props.companyGroups}
              companyGroup={props.companyGroup}
              userID={typeof showEditingUser === 'string' ? showEditingUser : undefined}
              closeModal={() => setEditVisibility(false)}
              addAlert={props.addAlert}
              updateCompanyGroupUser={props.updateCompanyGroupUser}
            />
          </Modal>
        </>
      )}

      <Title>{t('companies.group.users.invitations.title', { count: inviteCount })}</Title>
      <Table
        columns={userInviteColumns}
        dataSource={getUserInvites()}
        pagination={inviteCount > 10 ? undefined : false}
      />

      <Modal
        key={`invite-${modalKey}`}
        visible={showAddUserInvite}
        onOk={() => setShowInviteUser(false)}
        onCancel={() => setShowInviteUser(false)}
        width={582}
        footer={null}
      >
        <CompanyGroupUserAdd
          companyGroup={props.companyGroup}
          userInviteCompanyGroups={props.userInviteCompanyGroups}
          user={props.user}
          closeModal={() => setShowInviteUser(false)}
          addCompanyGroupUserInvite={props.addCompanyGroupUserInvite}
          companyGroupUser={props.companyGroupUser}
        />
      </Modal>
    </div>
  )
}
