import React from 'react'

import { fetchSalaryCycles } from '../api/salary-cycles'
import ActionTypes from '../constants/action-types'
import SalaryCycle from '../model/salaryCycle'
import { SalaryCycleAction } from '../reducers/salaryCycles'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingSalaryCycles(companyID: string): SalaryCycleAction {
  return {
    type: ActionTypes.SALARY_CYCLE_LOADING,
    companyID,
  }
}
function loadedSalaryCycles(companyID: string, salaryCycles: SalaryCycle[]): SalaryCycleAction {
  return {
    type: ActionTypes.SALARY_CYCLE_LOADED,
    companyID,
    salaryCycles,
  }
}
function failedLoadingSalaryCycles(companyID: string, error: Error): SalaryCycleAction {
  return {
    type: ActionTypes.SALARY_CYCLE_LOAD_FAILED,
    companyID,
    error,
  }
}

export function getSalaryCycles() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<SalaryCycle[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(loadingSalaryCycles(companyID))
    return fetchSalaryCycles(companyID)
      .then((res) => {
        dispatch(loadedSalaryCycles(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSalaryCycles(companyID, e))
        }
      })
  }
}
