import React, { ReactElement } from 'react'

import { addModal } from '../../actions/modals'
import { FeatureType } from '../../model/companyFeature'
import { ModalOption, ModalType } from '../../model/modal'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyFeatureReducer } from '../../reducers/companyFeatures'
import { connectToReducer } from '../../utils/reducer-utils'
import Tooltip from '../elements/tooltip'

import './Lock.css'

// NEED_NO_TRANSLATION

type Reducers = {
  companies: CompanyReducer
  companyFeatures: CompanyFeatureReducer
}

type Actions = {
  addModal: (type: ModalType, options: ModalOption) => void
}

type Props = {
  featurePackage?: 'Basic' | 'Premium'
  hasFeature?: boolean
  featureType?: FeatureType
  description: string
  children: React.ReactNode
}

function FeatureLock(props: Props & Reducers & Actions): ReactElement | null {
  const { featurePackage = 'Basic', hasFeature = false } = props

  const navigate = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    let modalType: ModalType = 'basic-upgrade'
    if (featurePackage === 'Premium') {
      modalType = 'premium-upgrade'
    }
    props.addModal(modalType, { width: 984 })
  }

  const checkFeature = (): boolean => {
    if (hasFeature) {
      return true
    }
    if (!props.featureType) {
      return false
    }
    return props.companyFeatures.companyFeatures.some((feature) => feature.featureType === props.featureType)
  }

  if (!checkFeature()) {
    return (
      <Tooltip title={props.description} overlayStyle={{ maxWidth: '170px' }}>
        <div onClick={navigate} className={'lock gtm-upgrade-to-' + featurePackage.toLowerCase()}>
          {props.children}
        </div>
      </Tooltip>
    )
  }
  return <>{props.children}</>
}

export default connectToReducer<Reducers, Actions, Props>(
  (state) => ({
    companies: state.companies,
    companyFeatures: state.companyFeatures,
  }),
  {
    addModal: addModal,
  }
)(FeatureLock)
