import IncomeTaxReport from '../model/incomeTaxReport'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchIncomeTaxReports(
  payRollID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<IncomeTaxReport[]>> {
  return secureRequest('GET', url('/v2/incomeTaxReports', { payRollID, limit, offset }))
}
