import React, { ReactElement, useEffect, useState } from 'react'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { fetchLoanEstimate, LoanEstimate, postLoanLead } from '../../api/loan'
import Company from '../../model/company'
import { AlertReducer } from '../../reducers/alerts'
import { formatError, isRequestError } from '../../utils/error-utils'
import Card from '../elements/card'
import Alerts from '../widgets/Alerts'
import LoadingOverlay from '../widgets/LoadingOverlay'
import LoanServiceForm, { Fields } from './LoanServiceForm'

import './Loan.css'

interface Props {
  alerts: AlertReducer
  company: Company

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
}

export default function LoanService(props: Props): ReactElement | null {
  const [loanEstimate, setLoanEstimate] = useState<LoanEstimate | undefined>(undefined)
  const [iframeURL, setIframeURL] = useState<string | undefined>(undefined)
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)

  const { addAlert } = props
  const companyID = props.company.id

  useEffect(() => {
    if (!loanEstimate) {
      fetchLoanEstimate(companyID)
        .then((res) => {
          setLoanEstimate(res.data)
        })
        .catch((e) => {
          if (isRequestError(e)) {
            setHasError(true)
            addAlert('error', 'Kunne ikke skaffe låneeksempel, prøv igen senere eller kontakt supporten.')
          }
        })
    }
  }, [loanEstimate, setLoanEstimate, companyID, addAlert])

  const handleSubmit = (values: Fields) => {
    if (!loanEstimate) {
      return
    }
    setLoading(true)
    postLoanLead({
      loanEstimateID: loanEstimate.id,
      companyID: props.company.id,
      loanAmount: values.loanAmount,
      loanDuration: values.loanDuration,
    })
      .then((res) => {
        setIframeURL(res.data.redirectURL)
      })
      .catch((e) => {
        props.addAlert('error', formatError(e))
        setHasError(true)
      })
      .finally(() => setLoading(false))
  }

  if ((!loanEstimate && !hasError) || loading) {
    return <LoadingOverlay />
  }

  return (
    <div className="loan-service">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      {iframeURL && <iframe src={iframeURL} title={'Låneformular'} />}
      {!iframeURL && loanEstimate && (
        <Card>
          <LoanServiceForm company={props.company} lendingInfo={loanEstimate} onSubmit={handleSubmit} />
        </Card>
      )}
    </div>
  )
}
