import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CoarseSalaryRegistration from '../model/coarseSalaryRegistration'
import { ReducerAction } from '../utils/reducer-utils'

export interface CoarseSalaryRegistrationReducer {
  companyID: string | undefined
  employeeID: string | undefined
  salaryPeriodID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  coarseSalaryRegistrations: List<CoarseSalaryRegistration>
  error: Error | null
}

const Params = Record<CoarseSalaryRegistrationReducer>({
  companyID: undefined,
  employeeID: undefined,
  salaryPeriodID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  coarseSalaryRegistrations: List<CoarseSalaryRegistration>(),
  error: null,
})

export interface CoarseSalaryRegistrationAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  salaryPeriodID?: string
  coarseSalaryRegistrations?: CoarseSalaryRegistration[]
  coarseSalaryRegistration?: CoarseSalaryRegistration
  coarseSalaryRegistrationID?: string
}

export default (
  state: Record<CoarseSalaryRegistrationReducer> = Params(),
  action: CoarseSalaryRegistrationAction = { type: '' }
): Record<CoarseSalaryRegistrationReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.COARSE_SALARY_REGISTRATION_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        salaryPeriodID: action.salaryPeriodID,
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.COARSE_SALARY_REGISTRATION_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('coarseSalaryRegistrations', List(action.coarseSalaryRegistrations))
        .set('error', null)
    case ActionTypes.COARSE_SALARY_REGISTRATION_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('error', action.error || null)

    case ActionTypes.COARSE_SALARY_REGISTRATION_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COARSE_SALARY_REGISTRATION_CREATED:
      if (!action.coarseSalaryRegistration) {
        return state
      }
      if (!action.companyID || !action.salaryPeriodID) {
        // only process actions for the active employee
        if (action.employeeID !== state.get('employeeID')) {
          return state
        }
      }
      idx = state
        .get('coarseSalaryRegistrations')
        .findIndex((item) => !!action.coarseSalaryRegistration && item.id === action.coarseSalaryRegistration.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set(
            'coarseSalaryRegistrations',
            state.get('coarseSalaryRegistrations').set(idx, action.coarseSalaryRegistration)
          )
      }
      return state
        .set('saving', false)
        .set('coarseSalaryRegistrations', state.get('coarseSalaryRegistrations').push(action.coarseSalaryRegistration))
    case ActionTypes.COARSE_SALARY_REGISTRATION_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COARSE_SALARY_REGISTRATION_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COARSE_SALARY_REGISTRATION_UPDATED:
      if (!action.coarseSalaryRegistration) {
        return state
      }
      if (action.employeeID && action.coarseSalaryRegistrationID) {
        idx = state
          .get('coarseSalaryRegistrations')
          .findIndex((item) => item.employeeID === action.employeeID && item.id === action.coarseSalaryRegistrationID)
      }
      if (idx === -1) {
        return state
          .set('saving', false)
          .set(
            'coarseSalaryRegistrations',
            state.get('coarseSalaryRegistrations').push(action.coarseSalaryRegistration)
          )
      }
      return state
        .set('saving', false)
        .set(
          'coarseSalaryRegistrations',
          state.get('coarseSalaryRegistrations').set(idx, action.coarseSalaryRegistration)
        )
    case ActionTypes.COARSE_SALARY_REGISTRATION_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COARSE_SALARY_REGISTRATION_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COARSE_SALARY_REGISTRATION_DELETED:
      if (action.coarseSalaryRegistrationID) {
        idx = state.get('coarseSalaryRegistrations').findIndex((item) => item.id === action.coarseSalaryRegistrationID)
      }
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('coarseSalaryRegistrations', state.get('coarseSalaryRegistrations').delete(idx))
    case ActionTypes.COARSE_SALARY_REGISTRATION_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
