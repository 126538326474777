import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CompanyUser from '../model/companyUser'
import DepartmentUser from '../model/departmentUser'
import { ReducerAction } from '../utils/reducer-utils'

// CompanyUsers are users attached to the company

export interface CompanyUserReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  companyUsers: List<CompanyUser>
  companyUser?: CompanyUser
  error: Error | null
}

const Params = Record<CompanyUserReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  companyUsers: List<CompanyUser>(),
  companyUser: undefined,
  error: null,
})

export interface CompanyUserAction extends ReducerAction {
  companyID?: string
  userCompanyID?: string
  companyUserID?: string
  userID?: string
  companyUsers?: CompanyUser[]
  companyUser?: CompanyUser
  userCompany?: CompanyUser
  permissionID?: string
  departmentUser?: DepartmentUser
}

export default (
  state: Record<CompanyUserReducer> = Params(),
  action: CompanyUserAction = { type: '' }
): Record<CompanyUserReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let indicies = [-1, -1]
  let idx = -1
  if (action.userCompanyID) {
    idx = state.get('companyUsers').findIndex((item) => item.id === action.userCompanyID)
  }
  if (action.companyUserID) {
    idx = state.get('companyUsers').findIndex((item) => item.id === action.companyUserID)
  }
  if (action.userID) {
    idx = state.get('companyUsers').findIndex((item) => item.userID === action.userID)
  }
  const currentCompanyUser = state.get('companyUser')
  const companyUser = state.get('companyUsers').get(idx)
  let changeCompanyUser: CompanyUser | undefined = undefined
  switch (action.type) {
    case ActionTypes.COMPANY_USERS_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.COMPANY_USERS_LOADED:
    case ActionTypes.COMPANY_USERS_LOADED_PARTIAL:
      if (!action.companyUsers) {
        action.companyUsers = []
      }
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: action.type === ActionTypes.COMPANY_USERS_LOADED_PARTIAL,
        loaded: true,
        companyUsers: state.get('companyUsers').concat(List(action.companyUsers)),
        companyUser:
          action.companyUsers.find((item) => item.companyID === action.companyID && item.userID === action.userID) ||
          state.get('companyUser'),
      })
    case ActionTypes.COMPANY_USERS_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.USER_COMPANY_ADDED:
      if (!action.userCompany) {
        return state
      }
      if (currentCompanyUser && action.userCompany.id === currentCompanyUser.id) {
        state = state.set('companyUser', action.userCompany)
      }
      if (idx !== -1) {
        return state.set('saving', false).set('companyUsers', state.get('companyUsers').set(idx, action.userCompany))
      }
      return state.set('saving', false).set('companyUsers', state.get('companyUsers').push(action.userCompany))
    case ActionTypes.USER_COMPANY_UPDATED:
      if (!action.userCompany) {
        return state
      }
      if (idx === -1) {
        return state
      }
      if (currentCompanyUser && action.userCompany.id === currentCompanyUser.id) {
        state = state.set('companyUser', action.userCompany)
      }
      return state.set('saving', false).set('companyUsers', state.get('companyUsers').set(idx, action.userCompany))

    case ActionTypes.COMPANY_USER_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_USER_DELETED:
      if (currentCompanyUser && action.userCompanyID === currentCompanyUser.id) {
        state = state.set('companyUser', undefined)
      }
      if (currentCompanyUser && action.userID === currentCompanyUser.userID) {
        state = state.set('companyUser', undefined)
      }
      if (idx !== -1) {
        return state.set('saving', false).set('companyUsers', state.get('companyUsers').delete(idx))
      }
      return state.set('saving', false)
    case ActionTypes.COMPANY_USER_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COMPANY_USER_PERMISSION_GRANTING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_USER_PERMISSION_GRANTED:
      if (!action.companyUser) {
        return state
      }
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('companyUsers', state.get('companyUsers').set(idx, action.companyUser))
    case ActionTypes.COMPANY_USER_PERMISSION_GRANT_FAILED:
      return state.set('saving', false).set('error', action.error || null)
    case ActionTypes.COMPANY_USER_PERMISSION_REVOKING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_USER_PERMISSION_REVOKED:
      if (!companyUser) {
        return state
      }
      if (idx === -1) {
        return state
      }
      companyUser.permissions = companyUser.permissions.filter((v) => v.id !== action.permissionID)
      if (currentCompanyUser && companyUser.id === currentCompanyUser.id) {
        state = state.set('companyUser', companyUser)
      }
      return state.set('saving', false).set('companyUsers', state.get('companyUsers').set(idx, companyUser))
    case ActionTypes.COMPANY_USER_PERMISSION_REVOKE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COMPANIES_SETTING_ACTIVE:
      if (!currentCompanyUser) {
        return state
      }
      changeCompanyUser = state
        .get('companyUsers')
        .find((item) => item.companyID === action.companyID && item.userID === currentCompanyUser.userID)
      if (!changeCompanyUser) {
        return state
      }
      return state.set('companyUser', changeCompanyUser)

    case ActionTypes.DEPARTMENT_USER_PERMISSION_GRANTING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DEPARTMENT_USER_PERMISSION_GRANTED:
      if (!action.departmentUser) {
        return state
      }
      indicies = state.get('companyUsers').reduce(
        (indices, item, idx) => {
          if (!action.departmentUser) {
            return indices
          }
          if (indices[0] !== -1 && indices[1] !== -1) {
            return indices
          }
          if (item.id !== action.departmentUser.userCompanyID) {
            return indices
          }
          const didx = item.departments.findIndex(
            (subitem) => !!action.departmentUser && subitem.departmentID === action.departmentUser.departmentID
          )
          return [idx, didx]
        },
        [-1, -1]
      )
      if (indicies[0] === -1) {
        return state.set('saving', false)
      }
      if (indicies[1] === -1) {
        changeCompanyUser = state.get('companyUsers').get(indicies[0])
        if (!changeCompanyUser) {
          return state.set('saving', false)
        }
        changeCompanyUser.departments.push(action.departmentUser)
        return state
          .set('saving', false)
          .set('companyUsers', state.get('companyUsers').set(indicies[0], changeCompanyUser))
      }
      changeCompanyUser = state.get('companyUsers').get(indicies[0])
      if (!changeCompanyUser) {
        return state.set('saving', false)
      }
      changeCompanyUser.departments[indicies[1]] = action.departmentUser
      if (currentCompanyUser && changeCompanyUser.id === currentCompanyUser.id) {
        state = state.set('companyUser', changeCompanyUser)
      }
      return state
        .set('saving', false)
        .set('companyUsers', state.get('companyUsers').set(indicies[0], changeCompanyUser))
    case ActionTypes.DEPARTMENT_USER_PERMISSION_GRANT_FAILED:
      return state.set('saving', false).set('error', action.error || null)
    case ActionTypes.DEPARTMENT_USER_PERMISSION_REVOKING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DEPARTMENT_USER_PERMISSION_REVOKED:
      indicies = state.get('companyUsers').reduce(
        (indices, item, idx) => {
          if (!action.departmentUser) {
            return indices
          }
          if (indices[0] !== -1 && indices[1] !== -1) {
            return indices
          }
          if (item.id !== action.departmentUser.userCompanyID) {
            return indices
          }
          const didx = item.departments.findIndex(
            (subitem) => !!action.departmentUser && subitem.departmentID === action.departmentUser.departmentID
          )
          return [idx, didx]
        },
        [-1, -1]
      )
      if (indicies[0] === -1 || indicies[0] === -1) {
        return state.set('saving', false)
      }
      changeCompanyUser = state.get('companyUsers').get(indicies[0])
      if (!changeCompanyUser) {
        return state.set('saving', false)
      }
      changeCompanyUser.departments = changeCompanyUser.departments.filter((_, i) => i !== indicies[1])
      if (currentCompanyUser && changeCompanyUser.id === currentCompanyUser.id) {
        state = state.set('companyUser', changeCompanyUser)
      }
      return state
        .set('saving', false)
        .set('companyUsers', state.get('companyUsers').set(indicies[0], changeCompanyUser))
    case ActionTypes.DEPARTMENT_USER_PERMISSION_REVOKE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
