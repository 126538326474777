import { addDays, addYears, isSameDay } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Contract, { ContractCreationFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import LeaveType, { LeaveTypeName } from '../../../model/leaveType'
import SalaryCycle from '../../../model/salaryCycle'
import SupplementType, { SupplementTypeName } from '../../../model/supplementType'
import { ContractReducer } from '../../../reducers/contracts'
import { EmployeeContractDeltaReducer } from '../../../reducers/employeeContractDeltas'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { getDate, isTimeAfter, isTimeBefore } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import CreateFutureContractForm, { FutureContractResult } from './CreateFutureContractForm'

type Props = {
  visible: boolean
  employee: Employee
  earliestMutableContract?: Contract
  viewingContract: Contract
  employeeContractDeltas: EmployeeContractDeltaReducer
  salaryCycles: List<SalaryCycle>
  salaryCycle: SalaryCycle
  contracts: ContractReducer
  leaveTypes: List<LeaveType>
  supplementTypes: List<SupplementType>

  addContract: (contract: ContractCreationFields, setViewing: boolean) => void
  closeModal: () => void
}

export default function CreateFutureContract(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [saving, setSaving] = useState(false)
  const [currentSalaryPeriods, setCurrentSalaryPeriods] = useState(props.salaryCycle.salaryPeriods)

  const contractSelectChange = (id: string) => {
    const contract = props.employeeContractDeltas.contracts
      .map((delta) => delta.contract)
      .find((contract) => contract.id === id)

    // see if we cannot find it already loaded
    const salaryPeriods = props.salaryCycles.find((cycle) => cycle.id === contract?.salaryCycleID)?.salaryPeriods
    if (salaryPeriods) {
      setCurrentSalaryPeriods(salaryPeriods)
    }
  }

  const getSalaryPeriods = () => {
    return currentSalaryPeriods.filter(
      (salaryPeriod) =>
        (!props.earliestMutableContract ||
          isTimeAfter(getDate(salaryPeriod.start), addDays(getDate(props.earliestMutableContract.validFrom), 1))) &&
        isTimeAfter(getDate(salaryPeriod.start), getDate()) &&
        isTimeBefore(getDate(salaryPeriod.start), addYears(getDate(), 2)) &&
        // do not match an existing contract's start date
        !props.employeeContractDeltas.contracts.some((delta) =>
          isSameDay(getDate(delta.validFrom), getDate(salaryPeriod.start))
        )
    )
  }

  const { visible, contracts } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, contracts.error, error, setError)
  }, [visible, contracts, error])

  const getLeaveType = (name: LeaveTypeName) => props.leaveTypes.find((type) => type.name === name)!
  const getSupplementType = (name: SupplementTypeName) => props.supplementTypes.find((type) => type.name === name)!

  const handleSubmit = (values: FutureContractResult) => {
    setSaving(true)
    const baseContract = props.employeeContractDeltas.contracts.find(
      (delta) => delta.contractID === values.contractID
    )?.contract
    if (!baseContract) {
      return
    }
    const contract = {
      ...baseContract,
      validFrom: values.validFrom,
    }
    if (!contract.remuneration) {
      return
    }
    // fix contract, in case it is using old vacation types
    contract.remuneration.leave.map((leave) => {
      if (leave.type?.name === 'DenmarkVacationPaid') {
        leave.typeID = getLeaveType('DenmarkVacationAccrual').id
      }
      if (leave.type?.name === 'DenmarkVacationNoPay') {
        leave.typeID = getLeaveType('DenmarkVacationFund').id
      }
      return leave
    })
    contract.remuneration.supplements.map((supplement) => {
      if (supplement.type?.name === 'DenmarkVacationPaidSupplement') {
        supplement.typeID = getSupplementType('DenmarkVacationSupplement').id
      }
      return supplement
    })
    props.addContract(contract, true)
    props.closeModal()
    setSaving(false)
  }

  if (!props.earliestMutableContract || !props.viewingContract) {
    return null // do nothing if both contracts are missing
  }

  if (saving) {
    return (
      <Card>
        <LoadingOverlay />
      </Card>
    )
  }

  return (
    <Card>
      <Subtitle>{t('create_future_contract.title')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <CreateFutureContractForm
        earliestMutableContract={props.earliestMutableContract}
        viewingContract={props.viewingContract}
        employeeContractDeltas={props.employeeContractDeltas}
        getSalaryPeriods={getSalaryPeriods}
        contractSelectChange={contractSelectChange}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
