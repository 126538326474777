import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import ContractBookIntegration from '../model/contractBookIntegration'
import { ReducerAction } from '../utils/reducer-utils'

export interface ContractBookIntegrationReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  contractBookIntegrations: List<ContractBookIntegration>
  error: Error | null
}

const Params = Record<ContractBookIntegrationReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  contractBookIntegrations: List<ContractBookIntegration>(),
  error: null,
})

export interface ContractBookIntegrationAction extends ReducerAction {
  companyID?: string
  integrations?: ContractBookIntegration[]
  integration?: ContractBookIntegration
  contractBookID?: string
}

export default (
  state: Record<ContractBookIntegrationReducer> = Params(),
  action: ContractBookIntegrationAction = { type: '' }
): Record<ContractBookIntegrationReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.CONTRACT_BOOK_INTEGRATION_LOADING:
      return state.set('loading', true)
    case ActionTypes.CONTRACT_BOOK_INTEGRATION_LOADED:
      return state.set('loading', false).set('loaded', true).set('contractBookIntegrations', List(action.integrations))
    case ActionTypes.CONTRACT_BOOK_INTEGRATION_LOAD_FAILED:
      return state.set('loading', false).set('error', action.error || null)

    case ActionTypes.CONTRACT_BOOK_INTEGRATION_UPDATING:
      return state.set('saving', true).set('loaded', false).set('error', null)
    case ActionTypes.CONTRACT_BOOK_INTEGRATION_UPDATED:
      if (!action.integration) {
        return state
      }
      idx = state.get('contractBookIntegrations').findIndex((item) => item.id === action.contractBookID)
      state = state.set('saving', false).set('loaded', true).set('error', null)
      if (idx === -1) {
        return state.set('contractBookIntegrations', state.get('contractBookIntegrations').push(action.integration))
      }
      return state.set('contractBookIntegrations', state.get('contractBookIntegrations').set(idx, action.integration))
    case ActionTypes.CONTRACT_BOOK_INTEGRATION_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.CONTRACT_BOOK_INTEGRATION_DELETING:
      return state.set('saving', true).set('loaded', false).set('error', null)
    case ActionTypes.CONTRACT_BOOK_INTEGRATION_DELETED:
      if ('contractBookID' in action) {
        idx = state.get('contractBookIntegrations').findIndex((item) => {
          return item.id === action.contractBookID
        })
      }
      state = state.set('saving', false).set('loaded', true).set('error', null)
      if (idx === -1) {
        return state
      }
      return state.set('contractBookIntegrations', state.get('contractBookIntegrations').delete(idx))
    case ActionTypes.CONTRACT_BOOK_INTEGRATION_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
