import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CompanyGroup, { CompanyGroupUser } from '../model/companyGroup'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyGroupReducer {
  loading: boolean
  loaded: boolean
  saving: boolean
  companyGroups: List<CompanyGroup>
  companyGroup: CompanyGroup | null // the currently active company group
  error: Error | null
}

const Params = Record<CompanyGroupReducer>({
  loading: false,
  loaded: false,
  saving: false,
  companyGroups: List<CompanyGroup>(),
  companyGroup: null, // the currently active company group
  error: null,
})

export interface CompanyGroupAction extends ReducerAction {
  companyGroups?: CompanyGroup[]
  companyGroup?: CompanyGroup
  companyGroupID?: string
  companyGroupUser?: CompanyGroupUser
  userID?: string
}

const comparator = (a: CompanyGroup, b: CompanyGroup) => {
  return a.name.localeCompare(b.name, 'da-dk')
}

export default (
  state: Record<CompanyGroupReducer> = Params(),
  action: CompanyGroupAction = { type: '' }
): Record<CompanyGroupReducer> => {
  let idx = -1
  const activeCompanyGroup = state.get('companyGroup')
  let otherCompanyGroup: CompanyGroup | undefined = undefined
  let newList: List<CompanyGroup> | null = null
  switch (action.type) {
    case ActionTypes.COMPANY_GROUPS_LOADING:
      return Params({
        loading: true,
      })
    case ActionTypes.COMPANY_GROUPS_LOADED:
      newList = List<CompanyGroup>(action.companyGroups).sort(comparator)
      return Params({
        loaded: true,
        companyGroups: newList,
        companyGroup: newList.find((group) => group.id === action.companyGroupID),
      })
    case ActionTypes.COMPANY_GROUPS_LOAD_FAILED:
      return Params({
        loaded: true,
        error: action.error,
      })

    case ActionTypes.COMPANY_GROUPS_SET_ACTIVE:
      otherCompanyGroup = state.get('companyGroups').find((group) => group.id === action.companyGroupID)
      if (!otherCompanyGroup) {
        return state
      }
      return state.set('companyGroup', otherCompanyGroup)

    case ActionTypes.COMPANY_GROUPS_UPDATING:
      return state.set('saving', true)
    case ActionTypes.COMPANY_GROUPS_UPDATED:
      if (!action.companyGroup) {
        return state
      }
      idx = state.get('companyGroups').findIndex((group) => group.id === action.companyGroupID)
      if (idx === -1) {
        return state.set('companyGroups', state.get('companyGroups').push(action.companyGroup)).set('saving', false)
      }
      if (activeCompanyGroup && activeCompanyGroup.id === action.companyGroupID) {
        return state
          .set('companyGroups', state.get('companyGroups').set(idx, action.companyGroup))
          .set('companyGroup', action.companyGroup)
          .set('saving', false)
      }
      return state.set('companyGroups', state.get('companyGroups').set(idx, action.companyGroup)).set('saving', false)
    case ActionTypes.COMPANY_GROUPS_UPDATE_FAILED:
      return state.set('error', action.error || null).set('saving', false)

    case ActionTypes.COMPANY_GROUPS_ADDING_COMPANY:
    case ActionTypes.COMPANY_GROUPS_REMOVING_COMPANY:
      return state.set('saving', true)
    case ActionTypes.COMPANY_GROUPS_ADDED_COMPANY:
    case ActionTypes.COMPANY_GROUPS_REMOVED_COMPANY:
      if (!action.companyGroup) {
        return state
      }
      idx = state.get('companyGroups').findIndex((group) => group.id === action.companyGroupID)
      if (idx === -1) {
        return state.set('companyGroups', state.get('companyGroups').push(action.companyGroup)).set('saving', false)
      }
      if (activeCompanyGroup && activeCompanyGroup.id === action.companyGroupID) {
        return state
          .set('companyGroups', state.get('companyGroups').set(idx, action.companyGroup))
          .set('companyGroup', action.companyGroup)
          .set('saving', false)
      }
      return state.set('companyGroups', state.get('companyGroups').set(idx, action.companyGroup)).set('saving', false)
    case ActionTypes.COMPANY_GROUPS_ADD_COMPANY_FAILED:
    case ActionTypes.COMPANY_GROUPS_REMOVE_COMPANY_FAILED:
      return state.set('error', action.error || null).set('saving', false)

    case ActionTypes.COMPANY_GROUPS_USER_ADDED:
      if (!action.companyGroupUser) {
        return state
      }
      idx = state.get('companyGroups').findIndex((group) => group.id === action.companyGroupID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      otherCompanyGroup = state.get('companyGroups').get(idx)
      if (!otherCompanyGroup) {
        return state.set('saving', false)
      }
      otherCompanyGroup.users.push(action.companyGroupUser)

      if (activeCompanyGroup && activeCompanyGroup.id === action.companyGroupID) {
        return state
          .set('companyGroups', state.get('companyGroups').set(idx, otherCompanyGroup))
          .set('companyGroup', otherCompanyGroup)
          .set('saving', false)
      }
      return state.set('companyGroups', state.get('companyGroups').set(idx, otherCompanyGroup)).set('saving', false)

    case ActionTypes.COMPANY_GROUPS_USER_UPDATED:
      idx = state.get('companyGroups').findIndex((group) => group.id === action.companyGroupID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      otherCompanyGroup = state.get('companyGroups').get(idx)
      if (!otherCompanyGroup) {
        return state.set('saving', false)
      }
      otherCompanyGroup.users = otherCompanyGroup.users.map((user) => {
        if (user.userID !== action.userID) {
          return user
        }
        return action.companyGroupUser || user
      })

      if (activeCompanyGroup && activeCompanyGroup.id === action.companyGroupID) {
        return state
          .set('companyGroups', state.get('companyGroups').set(idx, otherCompanyGroup))
          .set('companyGroup', otherCompanyGroup)
          .set('saving', false)
      }
      return state.set('companyGroups', state.get('companyGroups').set(idx, otherCompanyGroup)).set('saving', false)

    case ActionTypes.COMPANY_GROUPS_USER_DELETED:
      idx = state.get('companyGroups').findIndex((group) => group.id === action.companyGroupID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      otherCompanyGroup = state.get('companyGroups').get(idx)
      if (!otherCompanyGroup) {
        return state.set('saving', false)
      }
      otherCompanyGroup.users = otherCompanyGroup.users.filter((user) => user.userID !== action.userID)

      if (activeCompanyGroup && activeCompanyGroup.id === action.companyGroupID) {
        return state
          .set('companyGroups', state.get('companyGroups').set(idx, otherCompanyGroup))
          .set('companyGroup', otherCompanyGroup)
          .set('saving', false)
      }
      return state.set('companyGroups', state.get('companyGroups').set(idx, otherCompanyGroup)).set('saving', false)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
