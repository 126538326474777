import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import PDFPreviewTypes from '../../constants/pdf-preview-types'
import Invoice from '../../model/invoice'
import PayRoll from '../../model/payRoll'
import { paths } from '../../routes'
import { formatCurrency, formatNumber } from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'
import Table from '../antd/table'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/Icon'
import Subtitle from '../elements/Subtitle'

type Props = {
  invoices: List<Invoice>
  payRoll: PayRoll
}

export default function InvoiceCard(props: Props): ReactElement | null {
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => setExpanded((prev) => !prev)

  type InvoiceLineRow = {
    key: string
    description: string
    unitCount?: string
    unitType?: string
    unitPrice?: string
    total: string
  }

  const columns = [
    { title: t('pay_roll.single.invoice.header.description'), dataIndex: 'description', key: 'description' },
    {
      title: t('pay_roll.single.invoice.header.unit_count'),
      dataIndex: '',
      key: 'unitCount',
      render: (line: InvoiceLineRow) => (
        <span>
          {line.unitCount} <span style={{ color: 'var(--sally-grey-shaded)' }}>{line.unitType}</span>
        </span>
      ),
    },
    { title: t('pay_roll.single.invoice.header.unit_price'), dataIndex: 'unitPrice', key: 'unitPrice' },
    { title: t('pay_roll.single.invoice.header.total'), dataIndex: 'total', key: 'total' },
  ]

  const getInvoiceLines = (): InvoiceLineRow[] => {
    const invoice = props.invoices.first()
    if (!invoice) {
      return []
    }
    const lines: InvoiceLineRow[] = invoice.lines.map((line) => {
      return {
        key: line.id,
        description: line.text,
        unitCount: formatNumber(line.unitCount),
        unitType: line.unitType,
        unitPrice: formatCurrency(line.unitPrice, 2),
        total: formatCurrency(line.subTotal, 2),
      }
    })
    lines.push({
      key: '1',
      description: 'Subtotal',
      total: formatCurrency(invoice.subTotal, 2),
    })
    lines.push({
      key: '2',
      description: 'Moms af ' + formatCurrency(invoice.subTotal, 2),
      total: formatCurrency(invoice.totalVAT, 2),
    })
    lines.push({
      key: '3',
      description: 'Total',
      total: formatCurrency(invoice.total, 2),
    })
    return lines
  }

  if (props.invoices.size === 0) {
    return null
  }
  return (
    <Card>
      <Row>
        <Col span={6}>
          <Subtitle>{t('pay_roll.single.invoice.title')}</Subtitle>
        </Col>
        <Col span={12}>
          {tx('pay_roll.single.invoice.total', {
            total: <strong>{formatCurrency(props.payRoll.totalInvoice, 2)}</strong>,
          })}
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <span onClick={toggleExpanded} className={'pay-roll-toggle' + (expanded ? ' pay-roll-toggle-visible' : '')}>
            {expanded ? t('pay_roll.single.invoice.toggle.hide') : t('pay_roll.single.invoice.toggle.show')}
          </span>
        </Col>
      </Row>

      <div className={'pay-roll-card-container' + (expanded ? ' pay-roll-card-container-visible' : '')}>
        {props.payRoll.isApproved && (
          <Row className="pay-roll-card-buttons">
            <Col span={24} style={{ textAlign: 'right' }}>
              <Link
                to={'/' + paths.PDF_PREVIEW + '/' + PDFPreviewTypes.INVOICE + '/' + props.invoices.first()?.id}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>
                  <Icon type="file" color="lightgrey" />
                  {t('pay_roll.single.invoice.download_pdf')}
                </Button>
              </Link>
            </Col>
          </Row>
        )}

        <Table
          columns={columns}
          dataSource={getInvoiceLines()}
          size="small"
          pagination={false}
          className={'pay-roll-table pay-roll-table-small'}
        />
      </div>
    </Card>
  )
}
