import React, { ReactElement, useEffect } from 'react'
import { useEffectOnce } from 'react-use'

import { removeAlert, removeAlertSignature } from '../actions/alerts'
import { getAvailableAccountingIntegrations } from '../actions/available-accounting-integrations'
import { getDashboards } from '../actions/dashboards'
import CompanyDashboardsComponent from '../components/company-dashboards/CompanyDashboards'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { AlertReducer } from '../reducers/alerts'
import { AvailableAccountingIntegrationReducer } from '../reducers/availableAccountingIntegrations'
import { DashboardReducer } from '../reducers/dashboards'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  alerts: AlertReducer
  availableAccountingIntegrations: AvailableAccountingIntegrationReducer
  dashboards: DashboardReducer
}

type Actions = {
  removeAlert: removeAlertSignature
  getAvailableAccountingIntegrations: () => void
  getDashboards: () => void
}

function CompanyDashboards(props: Reducers & Actions): ReactElement | null {
  const { availableAccountingIntegrations, getAvailableAccountingIntegrations } = props

  useEffect(() => {
    if (!availableAccountingIntegrations.loaded && !availableAccountingIntegrations.loading) {
      getAvailableAccountingIntegrations()
    }
  })

  const { getDashboards } = props

  useEffectOnce(() => {
    // Always load this
    getDashboards()
  })

  const loading = !props.dashboards.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <CompanyDashboardsComponent
      alerts={props.alerts}
      availableAccountingIntegrations={props.availableAccountingIntegrations.availableAccountingIntegrations}
      dashboards={props.dashboards.dashboards}
      removeAlert={props.removeAlert}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    alerts: state.alerts,
    availableAccountingIntegrations: state.availableAccountingIntegrations,
    dashboards: state.dashboards,
  }),
  {
    removeAlert: removeAlert,
    getAvailableAccountingIntegrations: getAvailableAccountingIntegrations,
    getDashboards: getDashboards,
  }
)(CompanyDashboards)
