import React, { ReactElement } from 'react'

import { register } from '../actions/user'
import RegisterComponent from '../components/register/Register'
import { AccessToken } from '../model/user'
import { UserReducer } from '../reducers/user'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  user: UserReducer
}

type Actions = {
  register: (
    email: string,
    password: string,
    name: string,
    phoneNumber: string,
    phoneNumberCountryCode: string
  ) => Promise<AccessToken | void>
}

function Register(props: Reducers & Actions & RouteProps): ReactElement | null {
  return (
    <div>
      <RegisterComponent user={props.user} register={props.register} />
    </div>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    user: state.user,
  }),
  {
    register: register,
  }
)(Register)
