import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { CostCenterAccounting } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import OneTimePay, { OneTimePayCreationFields, OneTimePayMutableFields } from '../../../model/oneTimePay'
import SalaryCycle from '../../../model/salaryCycle'
import SupplementType from '../../../model/supplementType'
import { LeaveBalanceReducer } from '../../../reducers/leaveBalances'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { SupplementBalanceReducer } from '../../../reducers/supplementBalances'
import { UserReducer } from '../../../reducers/user'
import { paths } from '../../../routes'
import { formatDate } from '../../../utils/date-utils'
import { isBonus } from '../../../utils/employee-utils'
import { formatOneTimePayType } from '../../../utils/format-utils'
import { formatCurrency, formatNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Table from '../../antd/table'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Icon from '../../elements/Icon'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import Tooltip from '../../elements/tooltip'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import BonusEdit from './BonusEdit'

type Props = {
  subsection?: string
  employee: Employee
  company: Company
  canEditObjects: boolean
  canApproveObjects: boolean
  oneTimePays: OneTimePayReducer
  salaryCycle?: SalaryCycle
  supplementBalances: SupplementBalanceReducer
  supplementTypes: List<SupplementType>
  leaveBalances: LeaveBalanceReducer
  leaveTypes: List<LeaveType>
  costCenters: List<CostCenter>
  costCenterAccounting: CostCenterAccounting
  user: UserReducer

  approveOneTimePays: (ids: string[]) => void
  unapproveOneTimePays: (ids: string[]) => void
  addOneTimePay: (employeeID: string, otp: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, otp: OneTimePayMutableFields) => void
  deleteOneTimePay: (id: string) => void
  getSupplementBalances: (companyID?: string, employeeID?: string) => void
  getLeaveBalances: (companyID?: string, employeeID?: string) => void
}

export default function BonusTab(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)
  const [mutable, setMutable] = useState(false)

  const employeeID = props.employee.id
  const { supplementBalances, getSupplementBalances, leaveBalances, getLeaveBalances } = props

  useEffect(() => {
    if (supplementBalances.employeeID !== employeeID || (!supplementBalances.loading && !supplementBalances.loaded)) {
      getSupplementBalances(undefined, employeeID)
    }
  }, [supplementBalances, employeeID, getSupplementBalances])

  useEffect(() => {
    if (leaveBalances.employeeID !== employeeID || (!leaveBalances.loading && !leaveBalances.loaded)) {
      getLeaveBalances(undefined, employeeID)
    }
  }, [leaveBalances, employeeID, getLeaveBalances])

  const setEditVisibility = useCallback(
    (id: boolean | string, mutable = true) => {
      // Increment modalKey to create a new component
      setModalKey(modalKey + 1)
      setEditing(id)
      setMutable(mutable)
    },
    [setModalKey, setEditing, setMutable, modalKey]
  )

  const oneTimePaySaving = props.oneTimePays.saving
  const wasOneTimePaySaving = usePrevious(oneTimePaySaving)
  const oneTimePayError = props.oneTimePays.error

  useEffect(() => {
    if (wasOneTimePaySaving && !oneTimePaySaving) {
      if (!oneTimePayError) {
        setEditVisibility(false)
      }
    }
  }, [wasOneTimePaySaving, oneTimePaySaving, oneTimePayError, setEditVisibility])

  const showHistory = props.subsection === 'history'

  type OneTimePayRow = {
    key: string
    id: string
    date: string
    type: string
    approved: boolean
    notSeparate: boolean
    immutable: boolean
    title?: string
    amount: string
    rate?: string
    units?: string
    original: OneTimePay
  }

  const approve = (oneTimePay: OneTimePay) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => [...prev, oneTimePay.id])
      props.approveOneTimePays([oneTimePay.id])
    }
  }
  const unapprove = (oneTimePay: OneTimePay) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => prev.filter((id) => id !== oneTimePay.id))
      props.unapproveOneTimePays([oneTimePay.id])
    }
  }
  const remove = (oneTimePay: OneTimePay) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, oneTimePay.id])
        props.deleteOneTimePay(oneTimePay.id)
      }
    }
  }

  const columns = [
    {
      title: t('bonus.tab.table.header.approved'),
      key: 'x1',
      className: 'one-time-pay-table-approved no-break-column',
      render: (oneTimePay: OneTimePayRow) => {
        if (oneTimePay.approved) {
          return (
            <span>
              {t('bonus.tab.table.approved')}
              {!oneTimePay.immutable && props.canApproveObjects && (
                <Tooltip title={t('bonus.tab.table.action.remove_approve')}>
                  <DumbLink onClick={unapprove(oneTimePay.original)} style={{ cursor: 'pointer' }}>
                    <Icon type="cross" color="grey" />
                  </DumbLink>
                </Tooltip>
              )}
            </span>
          )
        }
        if (approving.indexOf(oneTimePay.id) !== -1) {
          return t('bonus.tab.table.saving')
        }
        if (!props.canApproveObjects) {
          return t('bonus.tab.table.not_approved')
        }
        return (
          <DumbLink onClick={approve(oneTimePay.original)} type="standard">
            {t('bonus.tab.table.action.approve')}
          </DumbLink>
        )
      },
    },
    { title: t('bonus.tab.table.header.date'), dataIndex: 'date', key: 'date', className: 'no-break-column' },
    { title: t('bonus.tab.table.header.type'), dataIndex: 'type', key: 'type' },
    { title: t('bonus.tab.table.header.title'), dataIndex: 'title', key: 'title' },
    {
      title: t('bonus.tab.table.header.amount'),
      key: 'xAmount',
      className: 'no-break-column',
      render: (oneTimePay: OneTimePayRow) => {
        if (oneTimePay.notSeparate) {
          return null
        }
        if (!oneTimePay.rate) {
          return oneTimePay.amount
        }
        return (
          <span>
            {oneTimePay.amount}
            <br />
            <small>
              ({oneTimePay.units} à {oneTimePay.rate})
            </small>
          </span>
        )
      },
    },
    {
      title: '',
      key: 'x2',
      className: 'employee-table-actions',
      render: (oneTimePay: OneTimePayRow) => {
        if (deleting.indexOf(oneTimePay.id) !== -1) {
          return null
        }
        if (!props.canEditObjects) {
          return null
        }
        return (
          <div>
            <Tooltip title={oneTimePay.immutable ? t('bonus.tab.table.action.view') : t('bonus.tab.table.action.edit')}>
              <span
                onClick={() => setEditVisibility(oneTimePay.id, !oneTimePay.immutable)}
                style={{ cursor: 'pointer' }}
              >
                <Icon type={oneTimePay.immutable ? 'search' : 'edit'} color="grey" />
              </span>
            </Tooltip>
            {!oneTimePay.immutable && (
              <Tooltip title={t('bonus.tab.table.action.delete')}>
                <DumbLink onClick={remove(oneTimePay.original)} style={{ cursor: 'pointer' }}>
                  <Icon type="cross" color="grey" />
                </DumbLink>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const getBonuses = (): OneTimePayRow[] => {
    return props.oneTimePays.oneTimePays
      .filter((oneTimePay) => isBonus(oneTimePay.type) && (showHistory ? oneTimePay.settled : !oneTimePay.settled))
      .toArray()
      .map((oneTimePay: OneTimePay): OneTimePayRow => {
        let title: string | undefined = oneTimePay.title
        if (title.length > 22) {
          title = title.substring(0, 20) + '...'
        }
        const notSeparate = oneTimePay.type === 'Free Phone' || oneTimePay.type === 'ATP'
        if (notSeparate) {
          title = undefined
        }
        const otp = {
          key: oneTimePay.id,
          id: oneTimePay.id,
          date: formatDate(oneTimePay.dispositionDate),
          type: formatOneTimePayType(oneTimePay.type),
          amount: formatCurrency(oneTimePay.amount, 2),
          rate: oneTimePay.rate ? formatCurrency(oneTimePay.rate, 2) : undefined,
          units: oneTimePay.units ? formatNumber(oneTimePay.units) : undefined,
          notSeparate,
          title,
          immutable: oneTimePay.immutable,
          approved: oneTimePay.approved,
          original: oneTimePay,
        }

        if (oneTimePay.type === 'Work Hours') {
          otp.amount = t('unit.hour_count', { count: oneTimePay.amount })
        }

        return otp
      })
  }

  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      const oneTimePays = getBonuses()
        .filter((reg) => !reg.approved)
        .map((reg) => reg.original)
      setApproving(oneTimePays.map((reg) => reg.id))
      props.approveOneTimePays(oneTimePays.map((otp) => otp.id))
    }
  }

  const hasUnapprovedBonuses = () => getBonuses().some((otp) => !otp.approved)

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (
    !props.employee.activeContract ||
    props.employee.activeEmployment.id !== props.employee.activeContract.employmentID
  ) {
    return <NoContractCard employee={props.employee} />
  }
  if (!props.salaryCycle) {
    return null
  }

  return (
    <Card className="employees-single-form">
      <TitleMenu>
        {!showHistory && props.canEditObjects && (
          <Button onClick={() => setEditVisibility(true)} style={{ marginRight: 20 }}>
            <Icon type="user" color="grey" />
            {t('bonus.tab.header.add_bonus')}
          </Button>
        )}
        {showHistory ? (
          <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/bonus'}>
            <Button className="gtm-hide-bonus-history" style={{ paddingRight: 14 }}>
              {t('bonus.tab.header.hide_history')}
            </Button>
          </Link>
        ) : (
          <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/bonus/history'}>
            <Button className="gtm-show-bonus-history" style={{ paddingRight: 14 }}>
              {t('bonus.tab.header.show_history')}
            </Button>
          </Link>
        )}

        {hasUnapprovedBonuses() && props.canApproveObjects && (
          <Button onClick={approveAll} style={{ marginLeft: 20 }}>
            {t('bonus.tab.header.approve_all')}
          </Button>
        )}
      </TitleMenu>
      <Title>{showHistory ? t('bonus.tab.title.history') : t('bonus.tab.title.standard')}</Title>
      <p>&nbsp;</p>

      <Table columns={columns} dataSource={getBonuses()} size="small" pagination={false} />
      {props.oneTimePays.saving && <LoadingOverlay />}

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <BonusEdit
          visible={editing !== false}
          editing={mutable}
          canApproveObjects={props.canApproveObjects}
          employee={props.employee}
          company={props.company}
          oneTimePayID={typeof editing === 'boolean' ? undefined : editing}
          oneTimePays={props.oneTimePays}
          salaryCycle={props.salaryCycle}
          supplementBalances={props.supplementBalances}
          supplementTypes={props.supplementTypes}
          leaveBalances={props.leaveBalances}
          leaveTypes={props.leaveTypes}
          costCenters={props.costCenters}
          costCenterAccounting={props.costCenterAccounting}
          addOneTimePay={props.addOneTimePay}
          updateOneTimePay={props.updateOneTimePay}
          getSupplementBalances={props.getSupplementBalances}
          getLeaveBalances={props.getLeaveBalances}
          user={props.user}
        />
      </Modal>
    </Card>
  )
}
