import React, { ReactElement, useEffect } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import { ExternalSalaryFileSettingMutableFields, ExternalSalaryFileType } from '../../model/externalSalaryFileSetting'
import { ExternalSalaryFileSettingReducer } from '../../reducers/externalSalaryFileSettings'
import { paths } from '../../routes'
import StagedImportFileType from '../../types/staged-import-file-type'
import { t, tx } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import LoadingOverlay from '../widgets/LoadingOverlay'
import ExternalSalaryFileSettingsForm, { ExternalSalarySettingResult } from './ExternalSalaryFileSettingsForm'

type Props = {
  company: Company
  externalSalaryFileSettings: ExternalSalaryFileSettingReducer
  externalSalaryFileType?: string

  addAlert: addAlertSignature
  getExternalSalaryFileSettings: (companyID: string) => void
  saveExternalSalaryFileSettings: (companyID: string, settings: ExternalSalaryFileSettingMutableFields[]) => void
}

export default function ExternalSalaryFileSettingsTab(props: Props): ReactElement | null {
  const { company, externalSalaryFileSettings, getExternalSalaryFileSettings } = props

  useEffect(() => {
    if (
      externalSalaryFileSettings.companyID !== company.id ||
      (!externalSalaryFileSettings.loaded && !externalSalaryFileSettings.loading)
    ) {
      getExternalSalaryFileSettings(company.id)
    }
  }, [externalSalaryFileSettings, company, getExternalSalaryFileSettings])

  const previousExternalSalaryFileSettings = usePrevious(externalSalaryFileSettings)

  useEffect(() => {
    if (
      previousExternalSalaryFileSettings &&
      previousExternalSalaryFileSettings.saving &&
      !externalSalaryFileSettings.saving
    ) {
      if (!externalSalaryFileSettings.error) {
        props.addAlert('success', t('external_salary_file_setting.alert.success', { name: company.name }), {
          timeout: 5,
        })
      }
    }
  })

  const handleSubmit = (values: ExternalSalarySettingResult) => {
    // we save all the existing settings for other types, so those settings are not lost during this update
    props.saveExternalSalaryFileSettings(props.company.id, [
      ...props.externalSalaryFileSettings.externalSalaryFileSettings
        .filter((setting) => setting.fileType !== values.externalSalaryFileType)
        .toArray(),
      ...values.settings,
    ])
  }

  if (!externalSalaryFileSettings.loaded) {
    return <LoadingOverlay />
  }

  let stagedImportFileType = StagedImportFileType.DANLOEN
  let externalSalaryFileType: ExternalSalaryFileType = 'Danløn'
  if (props.externalSalaryFileType === 'proloen') {
    stagedImportFileType = StagedImportFileType.PROLOEN
    externalSalaryFileType = 'Proløn'
  }

  return (
    <Card>
      <TitleMenu>
        <Link to={'/' + paths.STAGED_IMPORT + '/?type=' + stagedImportFileType}>
          <Button>{t('external_salary_file_setting.header.file_import')}</Button>
        </Link>
      </TitleMenu>
      <Title>{t('external_salary_file_setting.header.title', { name: externalSalaryFileType })}</Title>
      <p>
        {tx('external_salary_file_setting.header.description', { name: <strong>{externalSalaryFileType}</strong> })}
      </p>
      <ExternalSalaryFileSettingsForm
        externalSalaryFileType={externalSalaryFileType}
        externalSalaryFileSettings={externalSalaryFileSettings.externalSalaryFileSettings}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
