import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import Company, { CompanyDKSpecific } from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import SalaryCycle from '../../model/salaryCycle'
import { OffsetDisposition } from '../../model/types'
import { CompanyReducer } from '../../reducers/companies'
import { paths } from '../../routes'
import CompanySize from '../../types/company-size'
import FamilyLeaveFund from '../../types/family-leave-fund'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import {
  formatBiweeklySalaryCycle,
  formatCompanySize,
  formatDispositionDateRule,
  formatFamilyLeaveFund,
} from '../../utils/format-utils'
import { formatOrdinalNumber } from '../../utils/number-utils'
import { capitalise } from '../../utils/string-utils'
import { t, tx } from '../../utils/translation-utils'
import { getCityFromPostalCode } from '../../utils/validation-utils'
import Select from '../antd/select'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Switch from '../elements/switch'
import LoadingOverlay from '../widgets/LoadingOverlay'

function getDispositionDateRules(): OffsetDisposition[] {
  return ['PeriodEnd', 'NextMonday', 'NextTuesday', 'NextWednesday', 'NextThursday', 'NextFriday']
}

type Props = {
  company: Company
  companies: CompanyReducer
  companyFeatures: List<CompanyFeature>
  salaryCycles: List<SalaryCycle>
}

type Fields = {
  nationalID: string
  name: string
  address: string
  postalCode: string
  city: string
  defaultHourlyMonthlyCycleID?: string
  defaultBiweeklyCycleID?: string
  dkSpecific: CompanyDKSpecific
  offsetDispositionWeekly?: OffsetDisposition
  offsetDispositionBiWeekly?: OffsetDisposition
  offsetDispositionMonthly: string
  autoApproveTimeRegistrationHours: boolean
  autoApproveTimeRegistrationLeave: boolean
  autoApproveCarAllowances: boolean
  autoApproveReimbursementVouchers: boolean
  autoApproveCompensations: boolean
  autoApproveSalaryRegistrations: boolean
  familyLeaveFund: FamilyLeaveFund
  paySlipTransportDefault: 'EBoks' | 'MitDK' | 'EMail' | 'None'
}

export type ResultFields = {
  nationalID: string
  name: string
  address: string
  postalCode: string
  city: string
  defaultHourlyMonthlyCycleID?: string
  defaultBiweeklyCycleID?: string
  dkSpecific: CompanyDKSpecific
  offsetDispositionWeekly?: OffsetDisposition
  offsetDispositionBiWeekly?: OffsetDisposition
  offsetDispositionMonthly?: number
  autoApproveTimeRegistrationHours: boolean
  autoApproveTimeRegistrationLeave: boolean
  autoApproveCarAllowances: boolean
  autoApproveReimbursementVouchers: boolean
  autoApproveCompensations: boolean
  autoApproveSalaryRegistrations: boolean
  familyLeaveFund: FamilyLeaveFund
  paySlipTransportDefaultEBoks: boolean
  paySlipTransportDefaultMitDK: boolean
  paySlipTransportDefaultEMail: boolean
}

function CompanyForm(props: Props & FormComponentProps<Fields, ResultFields>): ReactElement | null {
  const hasEmployeeAppFeature = props.companyFeatures.some((feature) => feature.featureType === 'Employee App')

  const hasReimbursementVouchersFeature = props.companyFeatures.some(
    (feature) => feature.featureType === 'Reimbursement Vouchers'
  )

  const hasEBoks = props.companyFeatures.some((feature) => feature.featureType === 'DK EBoks')

  const hasFreelancerFeeEnabled = props.company.settingsEnabled.some(
    (setting) => setting === 'EnableAppFeeRegistration'
  )

  const { decorateField, decorateAnyField } = props

  const monthlyOffsets = [0, -5, -4, -3, -2, -1, 1]

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('nationalID', {
            placeholder: t('company.edit.form.national_id'),
            validate: (val) => {
              if (!val) {
                return t('company.edit.form.validation.national_id.required')
              }
              if (!val.match(/^[0-9]{8}$/)) {
                return t('company.edit.form.validation.national_id.invalid')
              }
              if (
                props.companies.companies.some(
                  (company) => company.nationalID === val && company.id !== props.company.id
                )
              ) {
                return t('company.edit.form.validation.national_id.in_use')
              }
              return null
            },
          })(<Input disabled />)}
        </Col>
        <Col span={12}>
          {decorateField('name', {
            placeholder: t('company.edit.form.name'),
            validate: (val) => (!val ? t('company.edit.form.validation.name.required') : null),
          })(<Input tabIndex={1} />)}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('address', {
            placeholder: t('company.edit.form.address'),
            validate: (val) => (!val ? t('company.edit.form.validation.address.required') : null),
          })(<Input tabIndex={2} />)}
        </Col>
        <Col span={6}>
          {decorateField('postalCode', {
            placeholder: t('company.edit.form.postal_code'),
            validate: (val) => {
              if (!val) {
                return t('company.edit.form.validation.postal_code.required')
              }
              if (!getCityFromPostalCode(val)) {
                return t('company.edit.form.validation.postal_code.invalid')
              }
              return null
            },
          })(<Input tabIndex={3} />)}
        </Col>
        <Col span={6}>
          {decorateField('city', {
            placeholder: t('company.edit.form.city'),
            validate: (val) => (!val ? t('company.edit.form.validation.city.required') : null),
          })(<Input tabIndex={4} />)}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateAnyField('dkSpecific.skatSize', {
            placeholder: t('company.edit.form.tax_size'),
            validate: (val) => (!val ? t('company.edit.form.validation.tax_size.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false} placeholder={t('company.edit.form.tax_size.select')}>
              <Select.Option value={CompanySize.SMALL}>{formatCompanySize(CompanySize.SMALL)}</Select.Option>
              <Select.Option value={CompanySize.LARGE}>{formatCompanySize(CompanySize.LARGE)}</Select.Option>
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('defaultHourlyMonthlyCycleID', {
            title: t('company.edit.form.default_hourly_monthly_cycle_id'),
            placeholder: t('company.edit.form.default_hourly_monthly_cycle_id.placeholder'),
            validate: (val) =>
              !val ? t('company.edit.form.validation.default_hourly_monthly_cycle_id.required') : null,
          })(
            <Select dropdownMatchSelectWidth={false}>
              {props.salaryCycles
                .filter((salaryCycle) => !salaryCycle.prepaid && salaryCycle.frequency === 'Monthly')
                .map((salaryCycle) => {
                  let desc = t('company.edit.form.default_hourly_monthly_cycle_id.option.no_offset')
                  if (salaryCycle.offset) {
                    desc = t('company.edit.form.default_hourly_monthly_cycle_id.option.with_offset', {
                      from: formatOrdinalNumber(salaryCycle.offset + 1),
                      to: formatOrdinalNumber(salaryCycle.offset),
                    })
                  }
                  return (
                    <Select.Option key={salaryCycle.id} value={salaryCycle.id}>
                      {desc}
                    </Select.Option>
                  )
                })}
            </Select>
          )}
        </Col>
        <Col span={12}>
          {decorateField('defaultBiweeklyCycleID', {
            title: t('company.edit.form.default_biweekly_cycle_id'),
            placeholder: t('company.edit.form.default_biweekly_cycle_id.placeholder'),
            validate: (val) => (!val ? t('company.edit.form.validation.default_biweekly_cycle_id.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false}>
              {props.salaryCycles
                .filter((salaryCycle) => !salaryCycle.prepaid && salaryCycle.frequency === 'BiWeekly')
                .map((salaryCycle) => {
                  return (
                    <Select.Option key={salaryCycle.id} value={salaryCycle.id}>
                      {capitalise(formatBiweeklySalaryCycle(salaryCycle))}
                    </Select.Option>
                  )
                })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <p>{t('company.edit.form.offset_disposition.description')}</p>
        </Col>
      </Row>
      <Row>
        {
          <Col span={12}>
            {decorateField('offsetDispositionMonthly', {
              placeholder: t('company.edit.form.offset_disposition_monthly'),
              validate: (val) => {
                if (!monthlyOffsets.some((i) => i.toString() === val)) {
                  return t('company.edit.form.validation.offset_disposition_monthly')
                }
                return null
              },
            })(
              <Select
                dropdownMatchSelectWidth={false}
                placeholder={t('company.edit.form.offset_disposition_monthly.select')}
              >
                {monthlyOffsets.map((rule) => {
                  if (rule === 0) {
                    return (
                      <Select.Option key={'0'} value={'0'}>
                        {t('company.edit.form.offset_disposition_monthly.rule.0')}
                      </Select.Option>
                    )
                  }
                  return (
                    <Select.Option key={rule.toString()} value={rule.toString()}>
                      {rule < 0
                        ? t('company.edit.form.offset_disposition_monthly.rule.before', { count: Math.abs(rule) })
                        : t('company.edit.form.offset_disposition_monthly.rule.after', { count: Math.abs(rule) })}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Col>
        }
        <Col span={12}>
          {decorateField('offsetDispositionWeekly', {
            placeholder: t('company.edit.form.offset_disposition_weekly'),
            validate: (val) => (!val ? t('company.edit.form.validation.offset_disposition_weekly.required') : null),
          })(
            <Select
              dropdownMatchSelectWidth={false}
              placeholder={t('company.edit.form.offset_disposition_weekly.select')}
            >
              {getDispositionDateRules().map((rule) => (
                <Select.Option key={rule} value={rule}>
                  {formatDispositionDateRule(rule)}
                </Select.Option>
              ))}
            </Select>
          )}
        </Col>
        <Col span={12}>
          {decorateField('offsetDispositionBiWeekly', {
            placeholder: t('company.edit.form.offset_disposition_biweekly'),
            validate: (val) => (!val ? t('company.edit.form.validation.offset_disposition_biweekly.required') : null),
          })(
            <Select
              dropdownMatchSelectWidth={false}
              placeholder={t('company.edit.form.offset_disposition_biweekly.select')}
            >
              {getDispositionDateRules().map((rule) => (
                <Select.Option key={rule} value={rule}>
                  {formatDispositionDateRule(rule)}
                </Select.Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>
      {hasEmployeeAppFeature && (
        <Row>
          <Col span={12}>
            {decorateField('autoApproveTimeRegistrationHours', {
              title: t('company.edit.form.auto_approve_time_registration_hours'),
              valueOnChecked: true,
              noBlur: true,
            })(
              <Switch
                checkedChildren={t('company.edit.form.auto_approve_time_registration_hours.checked')}
                unCheckedChildren={t('company.edit.form.auto_approve_time_registration_hours.unchecked')}
              />
            )}
          </Col>
          <Col span={12}>
            {decorateField('autoApproveTimeRegistrationLeave', {
              title: t('company.edit.form.auto_approve_time_registration_leave'),
              valueOnChecked: true,
              noBlur: true,
            })(
              <Switch
                checkedChildren={t('company.edit.form.auto_approve_time_registration_leave.checked')}
                unCheckedChildren={t('company.edit.form.auto_approve_time_registration_leave.unchecked')}
              />
            )}
          </Col>
          <Col span={12}>
            {decorateField('autoApproveCarAllowances', {
              title: t('company.edit.form.auto_approve_car_allowances'),
              valueOnChecked: true,
              noBlur: true,
            })(
              <Switch
                checkedChildren={t('company.edit.form.auto_approve_car_allowances.checked')}
                unCheckedChildren={t('company.edit.form.auto_approve_car_allowances.unchecked')}
              />
            )}
          </Col>
          <Col span={12}>
            {decorateField('autoApproveSalaryRegistrations', {
              title: t('company.edit.form.auto_approve_salary_registrations'),
              valueOnChecked: true,
              noBlur: true,
            })(
              <Switch
                checkedChildren={t('company.edit.form.auto_approve_salary_registrations.checked')}
                unCheckedChildren={t('company.edit.form.auto_approve_salary_registrations.unchecked')}
              />
            )}
          </Col>
          {hasReimbursementVouchersFeature && (
            <Col span={12}>
              {decorateField('autoApproveReimbursementVouchers', {
                title: t('company.edit.form.auto_approve_reimbursement_vouchers'),
                valueOnChecked: true,
                noBlur: true,
              })(
                <Switch
                  checkedChildren={t('company.edit.form.auto_approve_reimbursement_vouchers.checked')}
                  unCheckedChildren={t('company.edit.form.auto_approve_reimbursement_vouchers.unchecked')}
                />
              )}
            </Col>
          )}
          {hasFreelancerFeeEnabled && (
            <Col span={12}>
              {decorateField('autoApproveCompensations', {
                title: t('company.edit.form.auto_approve_compensations'),
                valueOnChecked: true,
                noBlur: true,
              })(
                <Switch
                  checkedChildren={t('company.edit.form.auto_approve_compensations.checked')}
                  unCheckedChildren={t('company.edit.form.auto_approve_compensations.unchecked')}
                />
              )}
            </Col>
          )}
          <Col span={24}>
            <span className="ant-form-extra">{t('company.edit.form.auto_approve_description')}</span>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12}>
          {decorateField('familyLeaveFund', {
            placeholder: t('company.edit.form.family_leave_fund'),
          })(
            <Select dropdownMatchSelectWidth={false}>
              <Select.Option value={FamilyLeaveFund.BARSEL_DK}>
                {formatFamilyLeaveFund(FamilyLeaveFund.BARSEL_DK)}
              </Select.Option>
              <Select.Option value={FamilyLeaveFund.DA_BARSEL}>
                {formatFamilyLeaveFund(FamilyLeaveFund.DA_BARSEL)}
              </Select.Option>
              <Select.Option value={FamilyLeaveFund.OTHER}>
                {formatFamilyLeaveFund(FamilyLeaveFund.OTHER)}
              </Select.Option>
            </Select>
          )}
        </Col>
        <Col span={12}>
          {decorateField('paySlipTransportDefault', {
            title: t('company.edit.form.pay_slip_transport_default'),
          })(
            <Select dropdownMatchSelectWidth={false}>
              {hasEBoks && (
                <Select.Option value={'MitDK'}>
                  {t('company.edit.form.pay_slip_transport_default.mit_dk')}
                </Select.Option>
              )}
              {hasEBoks && (
                <Select.Option value={'EBoks'}>{t('company.edit.form.pay_slip_transport_default.eboks')}</Select.Option>
              )}
              <Select.Option value={'EMail'}>{t('company.edit.form.pay_slip_transport_default.email')}</Select.Option>
              <Select.Option value={'None'}>{t('company.edit.form.pay_slip_transport_default.none')}</Select.Option>
            </Select>
          )}
          <p>
            {tx('company.edit.form.pay_slip_transport_default.batch_note', {
              link: (
                <Link to={'/' + paths.EMPLOYEES + '/' + paths.BATCH}>
                  {t('company.edit.form.pay_slip_transport_default.batch_note_link')}
                </Link>
              ),
            })}
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary" tabIndex={7}>
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.companies.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ResultFields>({
  mapPropsToFields: (props) => {
    let defaultHourlyMonthlyCycleID = props.company.defaultHourlyMonthlyCycleID
    if (!defaultHourlyMonthlyCycleID) {
      const firstSalaryCycle = props.salaryCycles
        .filter((salaryCycle) => salaryCycle.frequency === 'Monthly' && !salaryCycle.prepaid && !salaryCycle.offset)
        .first()
      if (firstSalaryCycle) {
        defaultHourlyMonthlyCycleID = firstSalaryCycle.id
      }
    }

    return {
      nationalID: props.company.nationalID,
      name: props.company.name,
      address: props.company.address,
      postalCode: props.company.postalCode,
      city: props.company.city,
      defaultHourlyMonthlyCycleID,
      defaultBiweeklyCycleID: props.company.defaultBiweeklyCycleID,
      dkSpecific: props.company.dkSpecific || { skatSize: CompanySize.SMALL, allowAutomaticZeroTaxReport: false },
      offsetDispositionWeekly: props.company.offsetDispositionWeekly,
      offsetDispositionBiWeekly: props.company.offsetDispositionBiWeekly,
      offsetDispositionMonthly: (props.company.offsetDispositionMonthly || 0).toString(),
      autoApproveTimeRegistrationHours: props.company.settingsEnabled.some(
        (setting) => setting === 'AutoApproveTimeRegistrationHours'
      ),
      autoApproveTimeRegistrationLeave: props.company.settingsEnabled.some(
        (setting) => setting === 'AutoApproveTimeRegistrationLeave'
      ),
      autoApproveCarAllowances: props.company.settingsEnabled.some((setting) => setting === 'AutoApproveCarAllowances'),
      autoApproveReimbursementVouchers: props.company.settingsEnabled.some(
        (setting) => setting === 'AutoApproveReimbursementVouchers'
      ),
      autoApproveCompensations: props.company.settingsEnabled.some((setting) => setting === 'AutoApproveCompensations'),
      autoApproveSalaryRegistrations: props.company.settingsEnabled.some(
        (setting) => setting === 'AutoApproveSalaryRegistrations'
      ),
      familyLeaveFund: props.company.familyLeaveFund,
      paySlipTransportDefault: props.company.settingsEnabled.some(
        (setting) => setting === 'PaySlipTransportDefaultEBoks'
      )
        ? 'EBoks'
        : props.company.settingsEnabled.some((setting) => setting === 'PaySlipTransportDefaultMitDK')
        ? 'MitDK'
        : props.company.settingsEnabled.some((setting) => setting === 'PaySlipTransportDefaultEMail')
        ? 'EMail'
        : 'None',
    }
  },
  onSubmit: (values) => {
    const fields: ResultFields = {
      nationalID: values.nationalID,
      name: values.name,
      address: values.address,
      postalCode: values.postalCode,
      city: values.city,
      defaultHourlyMonthlyCycleID: values.defaultHourlyMonthlyCycleID,
      defaultBiweeklyCycleID: values.defaultBiweeklyCycleID,
      dkSpecific: values.dkSpecific,
      offsetDispositionWeekly: values.offsetDispositionWeekly,
      offsetDispositionBiWeekly: values.offsetDispositionBiWeekly,
      offsetDispositionMonthly:
        values.offsetDispositionMonthly !== '0' ? parseInt(values.offsetDispositionMonthly) : undefined,
      autoApproveTimeRegistrationHours: values.autoApproveTimeRegistrationHours,
      autoApproveTimeRegistrationLeave: values.autoApproveTimeRegistrationLeave,
      autoApproveCarAllowances: values.autoApproveCarAllowances,
      autoApproveReimbursementVouchers: values.autoApproveReimbursementVouchers,
      autoApproveCompensations: values.autoApproveCompensations,
      autoApproveSalaryRegistrations: values.autoApproveSalaryRegistrations,
      familyLeaveFund: values.familyLeaveFund,
      paySlipTransportDefaultEBoks: values.paySlipTransportDefault === 'EBoks',
      paySlipTransportDefaultMitDK: values.paySlipTransportDefault === 'MitDK',
      paySlipTransportDefaultEMail: values.paySlipTransportDefault === 'EMail',
    }
    return fields
  },
})(CompanyForm)
