import React from 'react'

import { delAllWarnings, delWarning, fetchWarnings } from '../api/warnings'
import ActionTypes from '../constants/action-types'
import Warning from '../model/warning'
import { WarningAction } from '../reducers/warnings'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'

function loadingWarnings(): WarningAction {
  return {
    type: ActionTypes.WARNINGS_LOADING,
  }
}
function loadedWarnings(companyID: string, warnings: Warning[], partial = false): WarningAction {
  return {
    type: partial ? ActionTypes.WARNINGS_LOADED_PARTIAL : ActionTypes.WARNINGS_LOADED,
    companyID,
    warnings,
  }
}
function failedLoadingWarnings(companyID: string, error: Error): WarningAction {
  return {
    type: ActionTypes.WARNINGS_LOAD_FAILED,
    error,
    companyID,
  }
}

export function addedWarning(companyID: string, warning: Warning): WarningAction {
  return {
    type: ActionTypes.WARNING_ADDED,
    companyID,
    warning,
  }
}

export function updatedWarning(companyID: string, warningID: string, warning: Warning): WarningAction {
  return {
    type: ActionTypes.WARNING_UPDATED,
    companyID,
    warningID,
    warning,
  }
}

function deletingWarning(companyID: string): WarningAction {
  return {
    type: ActionTypes.WARNING_DELETING,
    companyID,
  }
}
export function deletedWarning(companyID: string, warningID?: string): WarningAction {
  return {
    type: ActionTypes.WARNING_DELETED,
    companyID,
    warningID,
  }
}
function failedDeletingWarning(companyID: string, error: Error): WarningAction {
  return {
    type: ActionTypes.WARNING_DELETE_FAILED,
    companyID,
    error,
  }
}

export function getWarnings(offset?: number) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Warning[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    if (!offset) {
      dispatch(loadingWarnings())
      offset = 0
    }
    const limit = 1000
    return fetchWarnings(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedWarnings(companyID, data)),
          (data) => dispatch(loadedWarnings(companyID, data, true)),
          (offset) => dispatch(getWarnings(offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingWarnings(companyID, e))
        }
      })
  }
}

export function deleteWarning(warningID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(deletingWarning(companyID))
    return delWarning(warningID)
      .then(() => {
        dispatch(deletedWarning(companyID, warningID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingWarning(companyID, e))
        }
      })
  }
}

export function deleteAllWarnings() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature) => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(deletingWarning(companyID))
    return delAllWarnings(companyID)
      .then(() => {
        dispatch(deletedWarning(companyID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingWarning(companyID, e))
        }
      })
  }
}
