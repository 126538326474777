import React, { ReactElement } from 'react'

import AssetCategory from '../../../model/assetCategory'
import Company from '../../../model/company'
import { AssetCategoryReducer } from '../../../reducers/assetCategories'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Fields = {
  title?: string
}

export type AssetCategoryResult = {
  title: string
}

type Props = {
  company: Company
  assetCategoryID?: string
  assetCategories: AssetCategoryReducer
}

function AssetCategoryEditForm(props: Props & FormComponentProps<Fields, AssetCategoryResult>): ReactElement | null {
  const { decorateField } = props
  return (
    <div>
      <Row>
        <Col span={24}>
          {decorateField('title', {
            placeholder: t('asset_category.edit.form.title'),
            validate: (val) => (!val ? t('asset_category.edit.form.validation.title_required') : null),
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {props.assetCategoryID
              ? t('asset_category.edit.form.submit.edit')
              : t('asset_category.edit.form.submit.create')}
          </Button>
        </Col>
      </Row>
      {props.assetCategories.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, AssetCategoryResult>({
  mapPropsToFields: (props: Props) => {
    const fields: Fields = {}
    if (props.assetCategoryID) {
      const assetCategory = props.assetCategories.assetCategories.find(
        (assetCategory: AssetCategory) => assetCategory.id === props.assetCategoryID
      )
      if (assetCategory) {
        fields.title = assetCategory.title
      }
    }

    return fields
  },
  onSubmit: (values) => ({
    title: values.title!,
  }),
})(AssetCategoryEditForm)
