import OneTimePayPension, {
  OneTimePayPensionCreationFields,
  OneTimePayPensionMutableFields,
} from '../model/oneTimePayPension'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchOneTimePayPensions(
  companyID: string | undefined,
  employeeID: string | undefined,
  limit: number,
  offset: number
): Promise<RequestResponse<OneTimePayPension[]>> {
  return secureRequest('GET', url('/v2/oneTimePayPensions', { companyID, employeeID, limit, offset }))
}

export function postOneTimePayPension(
  employeeID: string,
  oneTimePayPension: OneTimePayPensionCreationFields
): Promise<RequestResponse<OneTimePayPension>> {
  const oneTimePay = oneTimePayPension.oneTimePay
  return secureRequest('POST', url('/v2/oneTimePayPensions'), {
    body: {
      destinationType: oneTimePayPension.destinationType,
      pensionCompanyID: oneTimePayPension.pensionCompanyID,
      pensionCustomerID: oneTimePayPension.pensionCustomerID,
      sortCode: oneTimePayPension.sortCode,
      account: oneTimePayPension.account,
      scheme: oneTimePayPension.scheme,
      unionAgreementNumber: oneTimePayPension.unionAgreementNumber,
      reportingBaseAmount: oneTimePayPension.reportingBaseAmount,
      reportingPercentage: oneTimePayPension.reportingPercentage,
      oneTimePay: {
        employeeID: employeeID,
        type: oneTimePay.type,
        category: oneTimePay.category,
        title: oneTimePay.title,
        payOutType: oneTimePay.payOutType,
        amount: oneTimePay.amount,
        dispositionDate: oneTimePay.dispositionDate,
        costCenterID: oneTimePay.costCenterID,
        accountingText: oneTimePay.accountingText,
        approved: oneTimePay.approved,
      },
    },
  })
}

export function putOneTimePayPension(
  oneTimePayPension: OneTimePayPensionMutableFields
): Promise<RequestResponse<OneTimePayPension>> {
  const oneTimePay = oneTimePayPension.oneTimePay
  return secureRequest('PUT', url('/v2/oneTimePayPensions/' + oneTimePayPension.id), {
    body: {
      destinationType: oneTimePayPension.destinationType,
      pensionCompanyID: oneTimePayPension.pensionCompanyID,
      pensionCustomerID: oneTimePayPension.pensionCustomerID,
      sortCode: oneTimePayPension.sortCode,
      account: oneTimePayPension.account,
      scheme: oneTimePayPension.scheme,
      unionAgreementNumber: oneTimePayPension.unionAgreementNumber,
      reportingBaseAmount: oneTimePayPension.reportingBaseAmount,
      reportingPercentage: oneTimePayPension.reportingPercentage,
      oneTimePay: {
        type: oneTimePay.type,
        category: oneTimePay.category,
        title: oneTimePay.title,
        payOutType: oneTimePay.payOutType,
        amount: oneTimePay.amount,
        dispositionDate: oneTimePay.dispositionDate,
        costCenterID: oneTimePay.costCenterID,
        accountingText: oneTimePay.accountingText,
        approved: oneTimePay.approved,
      },
    },
  })
}
