import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'

export default function AdvisorService(): ReactElement | null {
  return (
    <Card className={'card-advisor'}>
      <div>
        <Row>
          <Col span={13}>
            <h1>{t('basic_integrations.advisor_service.title')}</h1>
            <p>{t('basic_integrations.advisor_service.intro')}</p>
            <ul>
              <li>{t('basic_integrations.advisor_service.points.0')}</li>
              <li>{t('basic_integrations.advisor_service.points.1')}</li>
              <li>{t('basic_integrations.advisor_service.points.2')}</li>
              <li>{t('basic_integrations.advisor_service.points.3')}</li>
              <li>{t('basic_integrations.advisor_service.points.4')}</li>
              <li>{t('basic_integrations.advisor_service.points.5')}</li>
              <li>{t('basic_integrations.advisor_service.points.6')}</li>
            </ul>
          </Col>
          <Col span={11}>
            <blockquote>
              <p>{t('basic_integrations.advisor_service.quote.line_1')}</p>
              <p>{t('basic_integrations.advisor_service.quote.line_2')}</p>
              <cite>
                <strong>Michael Larsen</strong>
              </cite>
            </blockquote>
          </Col>
        </Row>
        <Row className={'button-row'}>
          <Col span={3}>
            <a href={'mailto:dk@ageras.com'}>
              <Button style={{ marginLeft: '0' }}>{t('basic_integrations.advisor_service.buttons.questions')}</Button>
            </a>
          </Col>
          <Col span={4}>
            <Link to={'https://www.ageras.dk/revisor-tilbud'} target={'_blank'} rel="noopener noreferrer">
              <Button
                type="secondary"
                style={{ marginLeft: '40px', backgroundColor: 'var(--sally-black)', borderColor: 'var(--sally-black)' }}
              >
                {t('basic_integrations.advisor_service.buttons.offer')}
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    </Card>
  )
}
