import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { CarAllowanceMutableFields } from '../../../model/carAllowance'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import { CarAllowanceReducer } from '../../../reducers/carAllowances'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import DetailedCarAllowanceEditForm, { CarAllowanceResult } from './DetailedCarAllowanceEditForm'

type Props = {
  visible: boolean
  newCarAllowance: boolean
  company: Company
  employee: Employee
  costCenters: List<CostCenter>
  departments: List<Department>
  carAllowanceID?: string
  carAllowances: CarAllowanceReducer

  createCarAllowances: (carAllowance: CarAllowanceMutableFields[]) => void
  updateCarAllowance: (carAllowance: CarAllowanceMutableFields) => void
}

export default function DetailedCarAllowanceEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, carAllowances } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, carAllowances.error, error, setError)
  }, [carAllowances, visible, error])

  const handleSubmit = (values: CarAllowanceResult) => {
    if (values.trips.length === 0) {
      return
    }
    if (props.carAllowanceID && !props.newCarAllowance) {
      // updating?  Just use the first trip
      props.updateCarAllowance({
        id: props.carAllowanceID,
        employeeID: props.employee.id,
        date: values.date,
        licensePlate: values.licensePlate,
        locationFrom: values.trips[0].locationFrom,
        locationTo: values.trips[0].locationTo,
        kilometers: values.trips[0].kilometers,
        reason: values.reason,
        avoidFerries: values.avoidFerries,
        approved: true,
        costCenterID: values.costCenterID,
        rate: values.rate,
      })
    } else {
      // otherwise, create _all_ the trips
      props.createCarAllowances(
        values.trips.map((trip) => ({
          employeeID: props.employee.id,
          date: values.date,
          licensePlate: values.licensePlate,
          locationFrom: trip.locationFrom,
          locationTo: trip.locationTo,
          kilometers: trip.kilometers,
          reason: values.reason,
          avoidFerries: values.avoidFerries,
          approved: true,
          costCenterID: values.costCenterID,
          rate: values.rate,
        }))
      )
    }
  }

  return (
    <Card className="car-allowance-modal">
      <Subtitle>
        {props.carAllowanceID && !props.newCarAllowance
          ? t('car_allowances_tab.detailed.edit.title.edit')
          : t('car_allowances_tab.detailed.edit.title.create')}
      </Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <DetailedCarAllowanceEditForm
        newCarAllowance={props.newCarAllowance}
        company={props.company}
        employee={props.employee}
        costCenters={props.costCenters}
        departments={props.departments}
        carAllowanceID={props.carAllowanceID}
        carAllowances={props.carAllowances}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
