import React from 'react'

import {
  deleteCompanySettings,
  fetchCompanies,
  fetchCompany,
  fetchNetsMessages,
  fetchSupplementTypes,
  patchCompanySettings,
  postApiClientConnect,
  postApiClientUserConnect,
  postCompany,
  postCompanyNemKonto,
  putCompany,
} from '../api/companies'
import ActionTypes from '../constants/action-types'
import Company, { CompanyCreationFields, CompanyMutableFields } from '../model/company'
import CompanySetting from '../model/companySetting'
import NetsMessage from '../model/netsMessage'
import SupplementType from '../model/supplementType'
import { CompanyAction } from '../reducers/companies'
import { NetsMessageAction } from '../reducers/netsMessages'
import { SupplementTypeAction } from '../reducers/supplementTypes'
import { UserAction } from '../reducers/user'
import UserTypes from '../types/user-company-type'
import { getActiveCompany } from '../utils/cookie-utils'
import { isRequestError } from '../utils/error-utils'
import { companySelected } from '../utils/hooks-utils'
import { getCompanyID, getStateSignature, getUserID } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'
import { addedUserCompany } from './user-companies'

function loadingCompanies(): CompanyAction {
  return {
    type: ActionTypes.COMPANIES_LOADING,
  }
}
export function loadedCompanies(companies: Company[], doNotSetActiveCompany = false): CompanyAction {
  let activeCompany: string | undefined
  if (!doNotSetActiveCompany) {
    activeCompany = getActiveCompany()
    if (companies.length > 0) {
      if (!activeCompany || !companies.filter((c) => c.id === activeCompany).length) {
        activeCompany = companies.length ? companies[0].id : undefined
        companySelected(activeCompany || null)
      }
    }
  }

  return {
    type: ActionTypes.COMPANIES_LOADED,
    companies,
    companyID: activeCompany || undefined,
  }
}
function failedLoadingCompanies(error: Error): CompanyAction {
  return {
    type: ActionTypes.COMPANIES_LOAD_FAILED,
    error,
  }
}

function settingActiveCompany(companyID: string | null): CompanyAction {
  return {
    type: ActionTypes.COMPANIES_SETTING_ACTIVE,
    companyID: companyID ?? undefined,
  }
}

function addingCompany(): CompanyAction {
  return {
    type: ActionTypes.COMPANY_ADDING,
  }
}
export function addedCompany(company: Company): CompanyAction {
  return {
    type: ActionTypes.COMPANY_ADDED,
    company,
  }
}
function failedAddingCompany(error: Error): CompanyAction {
  return {
    type: ActionTypes.COMPANY_ADD_FAILED,
    error,
  }
}

function updatingCompany(companyID: string): CompanyAction {
  return {
    type: ActionTypes.COMPANY_UPDATING,
    companyID,
  }
}
export function updatedCompany(company: Company): CompanyAction {
  return {
    type: ActionTypes.COMPANY_UPDATED,
    companyID: company.id,
    company,
  }
}
function failedUpdatingCompany(companyID: string, error: Error): CompanyAction {
  return {
    type: ActionTypes.COMPANY_UPDATE_FAILED,
    companyID,
    error,
  }
}

function loadingSupplementTypes(): SupplementTypeAction {
  return {
    type: ActionTypes.SUPPLEMENT_TYPES_LOADING,
  }
}
export function loadedSupplementTypes(companyID: string, supplementTypes: SupplementType[]): SupplementTypeAction {
  return {
    type: ActionTypes.SUPPLEMENT_TYPES_LOADED,
    companyID,
    supplementTypes,
  }
}
function failedLoadingSupplementTypes(companyID: string, error: Error): SupplementTypeAction {
  return {
    type: ActionTypes.SUPPLEMENT_TYPES_LOAD_FAILED,
    error,
    companyID,
  }
}

function loadingNetsMessages(): NetsMessageAction {
  return {
    type: ActionTypes.NETS_MESSAGES_LOADING,
  }
}
function loadedNetsMessages(companyID: string, netsMessages: NetsMessage[], partial = false): NetsMessageAction {
  return {
    type: partial ? ActionTypes.NETS_MESSAGES_LOADED_PARTIAL : ActionTypes.NETS_MESSAGES_LOADED,
    companyID,
    netsMessages,
  }
}
function failedLoadingNetsMessages(companyID: string, error: Error): NetsMessageAction {
  return {
    type: ActionTypes.NETS_MESSAGES_LOAD_FAILED,
    error,
    companyID,
  }
}

function connectingCompany(companyID: string): CompanyAction {
  return {
    type: ActionTypes.COMPANY_CONNECTING,
    companyID,
  }
}
function connectedCompany(companyID: string): CompanyAction {
  return {
    type: ActionTypes.COMPANY_CONNECTED,
    companyID,
  }
}
function failedConnectingCompany(companyID: string, error: Error): CompanyAction {
  return {
    type: ActionTypes.COMPANY_CONNECT_FAILED,
    companyID,
    error,
  }
}

function connectingUser(userID: string): UserAction {
  return {
    type: ActionTypes.USER_CONNECTING,
    id: userID,
  }
}
function connectedUser(userID: string): UserAction {
  return {
    type: ActionTypes.USER_CONNECTED,
    id: userID,
  }
}
function failedConnectingUser(userID: string, error: Error): UserAction {
  return {
    type: ActionTypes.USER_CONNECT_FAILED,
    id: userID,
    error,
  }
}

export function deletedCompany(companyID: string): CompanyAction {
  return {
    type: ActionTypes.COMPANY_DELETED,
    companyID,
  }
}

export function getCompanies() {
  return (dispatch: React.Dispatch<any>): Promise<Company[] | void> => {
    dispatch(loadingCompanies())
    return fetchCompanies()
      .then((res) => {
        dispatch(loadedCompanies(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompanies(e))
        }
      })
  }
}

export function updateActiveCompany(id: string | null) {
  return (dispatch: React.Dispatch<any>): void => {
    companySelected(id)
    dispatch(settingActiveCompany(id))
  }
}

export function getCompany(companyID: string) {
  return (dispatch: React.Dispatch<any>): Promise<Company | void> => {
    dispatch(loadingCompanies())
    return fetchCompany(companyID)
      .then((res) => {
        dispatch(loadedCompanies([res.data]))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompanies(e))
        }
      })
  }
}

export function addCompany(company: CompanyCreationFields) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Company | void> => {
    dispatch(addingCompany())
    return postCompany(company)
      .then((res) => {
        dispatch(addedCompany(res.data))
        if (getState) {
          const user = getState().user
          dispatch(
            addedUserCompany({
              userID: user.id!,
              name: user.name,
              email: user.email!,
              companyID: res.data.id,
              userType: UserTypes.BUSINESS,
              notificationPayRollAutomaticallyStarted: true,
              notificationPayRollDone: true,
              notificationPayRollDeadlineUpcoming: true,
              notificationPayRollApprovalUpcoming: true,
              notificationSwipeAwaitingApproval: false,
              notificationAutomaticZeroTaxReportDone: false,
              notificationPayRollDepartmentApproval1: false,
              notificationPayRollDepartmentApproval2: false,
              notificationTransferInfo: true,
              notificationEmployeeUpdatedBanking: true,
              permissions: [],
              departments: [],
              createdAt: res.data.createdAt,
            })
          )
        }
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingCompany(e))
        }
      })
  }
}

export function updateCompany(company: CompanyMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<Company | void> => {
    dispatch(updatingCompany(company.id))
    return putCompany(company)
      .then((res) => {
        dispatch(updatedCompany(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompany(company.id, e))
        }
      })
  }
}

export function getSupplementTypes() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<SupplementType[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingSupplementTypes())
    return fetchSupplementTypes(companyID)
      .then((res) => {
        dispatch(loadedSupplementTypes(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSupplementTypes(companyID, e))
        }
      })
  }
}

export function getNetsMessages(offset?: number) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<NetsMessage[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    if (!offset) {
      dispatch(loadingNetsMessages())
      offset = 0
    }
    const limit = 1000
    return fetchNetsMessages(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedNetsMessages(companyID, data)),
          (data) => dispatch(loadedNetsMessages(companyID, data, true)),
          (offset) => dispatch(getNetsMessages(offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingNetsMessages(companyID, e))
        }
      })
  }
}

export function toggleNemKontoAllEmployees(company: Company, enable: boolean) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(updatingCompany(company.id))

    return postCompanyNemKonto(company.id, enable)
      .then(() => {
        dispatch(updatedCompany(company))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompany(company.id, e))
        }
      })
  }
}

export function enableCompanySettings(companyID: string, settings: CompanySetting[]) {
  return (dispatch: React.Dispatch<any>): Promise<Company | void> => {
    dispatch(updatingCompany(companyID))

    return patchCompanySettings(companyID, settings)
      .then((res) => {
        dispatch(updatedCompany(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompany(companyID, e))
        }
      })
  }
}

export function disableCompanySettings(companyID: string, settings: CompanySetting[]) {
  return (dispatch: React.Dispatch<any>): Promise<Company | void> => {
    dispatch(updatingCompany(companyID))

    return deleteCompanySettings(companyID, settings)
      .then((res) => {
        dispatch(updatedCompany(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompany(companyID, e))
        }
      })
  }
}

export function apiClientConnect(companyID: string, apiClientID: string, ref: string, needFeature?: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(connectingCompany(companyID))
    return postApiClientConnect(companyID, apiClientID, ref, needFeature)
      .then(() => {
        dispatch(connectedCompany(companyID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedConnectingCompany(companyID, e))
        }
      })
  }
}

export function apiClientUserConnect(apiClientID: string, ref: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const userID = getUserID(getState)
    if (!userID) {
      return PromiseVoid
    }
    dispatch(connectingUser(userID))
    return postApiClientUserConnect(apiClientID, ref)
      .then(() => {
        dispatch(connectedUser(userID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedConnectingUser(userID, e))
        }
      })
  }
}
