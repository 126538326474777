import React, { ReactElement } from 'react'

import DocumentSigningPage from '../../components/document-signing-page/DocumentSigningPage'
import Alert from '../../components/elements/alert'
import { connectToReducer } from '../../utils/reducer-utils'
import { RouteProps, splatString } from '../../utils/route-utils'

import './PhonePage.css'

function DocumentSigningPhonePage(props: RouteProps): ReactElement | null {
  const resolveSplat = () => {
    const parts = splatString(props.params.splat).split('/') || ['']
    const documentID = parts[0]
    const signID = props.location.query.signID
    const key = props.location.query.key
    return { documentID, signID, key }
  }
  const { documentID, signID, key } = resolveSplat()

  if (!documentID || !signID || !key) {
    return <Alert type={'error'} message={'Mangler dokument-ID, underskriver-ID og/eller nøgle'} showIcon={true} />
  }

  return (
    <div className="document-signing-wrapper">
      <DocumentSigningPage signID={signID} signKey={key} documentID={documentID} />
    </div>
  )
}

export default connectToReducer<object, any, RouteProps>(
  (_state): Record<string, never> => ({}),
  {}
)(DocumentSigningPhonePage)
