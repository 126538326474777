import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import ExpenseCategory from '../model/expenseCategory'
import { ReducerAction } from '../utils/reducer-utils'

export interface ExpenseCategoryReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  expenseCategories: List<ExpenseCategory>
  error: Error | null
}

const Params = Record<ExpenseCategoryReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  expenseCategories: List<ExpenseCategory>(),
  error: null,
})

export interface ExpenseCategoryAction extends ReducerAction {
  companyID?: string
  expenseCategories?: ExpenseCategory[]
  expenseCategory?: ExpenseCategory
  expenseCategoryID?: string
}

const comparator = (a: ExpenseCategory, b: ExpenseCategory) => {
  const diff = a.order - b.order
  if (diff !== 0) {
    return diff
  }
  return a.name.localeCompare(b.name, 'da-dk')
}

export default (
  state: Record<ExpenseCategoryReducer> = Params(),
  action: ExpenseCategoryAction = { type: '' }
): Record<ExpenseCategoryReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.EXPENSE_CATEGORY_LOADING:
      return state.set('loading', true).set('loaded', false).set('companyID', state.get('companyID'))
    case ActionTypes.EXPENSE_CATEGORY_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('companyID', state.get('companyID'))
        .set('expenseCategories', List<ExpenseCategory>(action.expenseCategories).sort(comparator))
        .set('error', null)
    case ActionTypes.EXPENSE_CATEGORY_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', state.get('companyID'))
        .set('error', action.error || null)

    case ActionTypes.EXPENSE_CATEGORY_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EXPENSE_CATEGORY_ADDED:
      if (!action.expenseCategory) {
        return state
      }
      idx = state
        .get('expenseCategories')
        .findIndex((item) => !!action.expenseCategory && item.id === action.expenseCategory.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('expenseCategories', state.get('expenseCategories').set(idx, action.expenseCategory).sort(comparator))
      }
      return state
        .set('saving', false)
        .set('expenseCategories', state.get('expenseCategories').push(action.expenseCategory).sort(comparator))
    case ActionTypes.EXPENSE_CATEGORY_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.EXPENSE_CATEGORY_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EXPENSE_CATEGORY_UPDATED:
      if (!action.expenseCategory) {
        return state
      }
      idx = state.get('expenseCategories').findIndex((item) => item.id === action.expenseCategoryID)
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('expenseCategories', state.get('expenseCategories').set(idx, action.expenseCategory).sort(comparator))
    case ActionTypes.EXPENSE_CATEGORY_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.EXPENSE_CATEGORY_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EXPENSE_CATEGORY_DELETED:
      if ('expenseCategoryID' in action) {
        idx = state.get('expenseCategories').findIndex((item) => {
          return item.id === action.expenseCategoryID
        })
      }
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('expenseCategories', state.get('expenseCategories').delete(idx))
    case ActionTypes.EXPENSE_CATEGORY_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
