import React, { ReactElement, ReactNode, useState } from 'react'

import Form from '../components/antd/form'
import Alert from '../components/elements/alert'
import HelpModal from '../components/elements/HelpModal'
import { formatValidationErrors } from './error-utils'
import { assign, getByPath, setByPath } from './object-utils'

// We use a generic field, to emphasis that it must be an object
export type GenericFields = Record<string, unknown>

export type FormTrigger = 'onSubmit' | 'onChange' | 'onFocus' | 'onBlur' | 'onPressEnter' | 'setFormValue'

// for now, we simply assume the fields using FormField are all string
export type FormField = {
  validate?: (v: string, trigger: FormTrigger) => string | null
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => string | null
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => string | null
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => string | null
}

type ValidateFunction<Fields extends GenericFields, Key extends keyof Fields> = (
  value: Fields[Key],
  state: Fields,
  trigger: FormTrigger
) => string | null
type ValidateAnyFunction<Fields extends GenericFields> = (
  value: any,
  state: Fields,
  trigger: FormTrigger
) => string | null

interface FormFunctions<Props, Fields extends GenericFields, Result extends GenericFields> {
  mapPropsToFields?: (props: Props) => Fields
  onChange?: <Key extends keyof Fields>(
    key: string,
    val: Fields[Key] | unknown,
    allValues: Fields,
    options: Record<string, unknown>,
    props: Props
  ) => Partial<Fields>
  onSubmit: (values: Fields, props: Props) => Result
  submitOnChange?: boolean
}

function resolveValue(e: any) {
  if (e instanceof Object && !(e instanceof Date) && !(e instanceof Array)) {
    if (e.target.type === 'checkbox') {
      return e.target.checked
    }
    return e.target.value
  }
  return e
}
function hasErrors(errors: { [index: string]: unknown }): boolean {
  for (const key in errors) {
    if (errors[key]) {
      if (typeof errors[key] === 'object') {
        if (hasErrors(errors[key] as { [index: string]: unknown })) {
          return true
        }
      } else {
        return true
      }
    }
  }
  return false
}

interface BasicDecorateFieldOptions {
  placeholder?: string
  title?: string
  helpText?: React.ReactNode
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  trigger?: string
  skipWrapper?: boolean
  skipLabel?: boolean
  valueOnChecked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onAfterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  noBlur?: boolean
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}

export interface DecorateFieldOptions<Fields extends GenericFields, Key extends keyof Fields>
  extends BasicDecorateFieldOptions {
  validate?: ValidateFunction<Fields, Key>
}
export interface DecorateAnyFieldOptions<Fields extends GenericFields> extends BasicDecorateFieldOptions {
  validate?: ValidateAnyFunction<Fields>
}

export type decorateFieldSignature<Fields extends GenericFields> = <Key extends keyof Fields>(
  id: Key,
  options: DecorateFieldOptions<Fields, Key>
) => (
  child: ReactElement<DecorateFieldOptions<Fields, Key>>
) =>
  | React.ReactElement<DecorateFieldOptions<Fields, Key>, string | React.JSXElementConstructor<any>>
  | React.ReactElement<DecorateFieldOptions<Fields, Key>, string | React.JSXElementConstructor<Fields>>[]
export type decorateAnyFieldSignature<Fields extends GenericFields> = (
  id: string,
  options: DecorateAnyFieldOptions<Fields>
) => (
  child: ReactElement<DecorateAnyFieldOptions<Fields>>
) =>
  | React.ReactElement<DecorateAnyFieldOptions<Fields>, string | React.JSXElementConstructor<any>>
  | React.ReactElement<DecorateAnyFieldOptions<Fields>, string | React.JSXElementConstructor<Fields>>[]
export type getFieldValueSignature<Fields> = <Key extends keyof Fields>(id: Key) => Fields[Key]
export type getAnyFieldValueSignature = (id: string) => unknown
export type setFieldValueSignature<Fields> = <Key extends keyof Fields>(id: Key, value: Fields[Key]) => void
export type setAnyFieldValueSignature = (id: string, value: unknown) => void
export type getFieldErrorSignature<Fields> = <Key extends keyof Fields>(id: Key) => any
export type getAnyFieldErrorSignature = (id: string) => any
export type setFieldErrorSignature<Fields> = <Key extends keyof Fields>(id: Key, error: string) => void
export type setAnyFieldErrorSignature = (id: string, error: string) => void

interface FormComponentOtherProps<Fields extends GenericFields, Result extends GenericFields> {
  onSubmit?: (values: Result) => void
  onBlur?: (values: Fields) => void
  onBack?: (values: Result) => void
}

export interface FormComponentProps<Fields extends GenericFields, Result extends GenericFields>
  extends FormComponentOtherProps<Fields, Result> {
  decorateField: decorateFieldSignature<Fields>
  decorateAnyField: decorateAnyFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  getAnyFieldValue: getAnyFieldValueSignature
  setFieldValue: setFieldValueSignature<Fields>
  setAnyFieldValue: setAnyFieldValueSignature
  getFieldError: getFieldErrorSignature<Fields>
  getAnyFieldError: getAnyFieldErrorSignature
  setFieldError: setFieldErrorSignature<Fields>
  setAnyFieldError: setAnyFieldErrorSignature
  getFormError: () => React.ReactElement | null
  goBack: (e: React.MouseEvent) => void
}

interface DecorateFieldProps {
  key: string
  id: string
  formMode?: true
  required?: boolean
  title?: string
  placeholder?: string
  value: any
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  checked?: unknown
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  ref?: React.RefObject<FormField>
  setFormValue?: (value: string) => void
}

type ValidateOptions = {
  trigger: FormTrigger
  mustValidate?: boolean
  override?: GenericFields
}

export function withValidations<Props, Fields extends GenericFields, Result extends GenericFields>(
  options: FormFunctions<Props, Fields, Result>
): (
  WrappedComponent: (
    props: Props & FormComponentProps<Fields, Result>
  ) => React.ReactElement<Props & FormComponentProps<Fields, Result>> | null
) => (
  props: Props & FormComponentOtherProps<Fields, Result>
) => React.ReactElement<Props & FormComponentProps<Fields, Result>> | null {
  options = {
    ...{
      mapPropsToFields: (_props): Fields => ({} as Fields),
      onChange: (key: string, val: unknown) => {
        const values = {}
        setByPath(values, key, val)
        return values
      },
      submitOnChange: false,
    },
    ...options,
  }
  const { mapPropsToFields, onChange, onSubmit, submitOnChange } = options
  return function (
    WrappedComponent: (
      props: Props & FormComponentProps<Fields, Result>
    ) => ReactElement<Props & FormComponentProps<Fields, Result>> | null
  ): (
    props: Props & FormComponentOtherProps<Fields, Result>
  ) => ReactElement<Props & FormComponentProps<Fields, Result>> | null {
    return function (props: Props & FormComponentOtherProps<Fields, Result>): ReactElement | null {
      type FormComponentState = Fields & {
        error: string | null
        errors: Record<string, string | null>
      }

      const [state, setState] = useState<FormComponentState>(() => {
        let state = {}
        if (mapPropsToFields) {
          state = mapPropsToFields(props)
        }
        return { ...state, error: null, errors: {} } as FormComponentState
      })

      const validators: Map<string, ValidateAnyFunction<Fields>[]> = new Map()

      const validate = (
        state: FormComponentState & Fields,
        mustValidate: boolean,
        trigger: FormTrigger,
        formErrors?: Record<string, string | null>
      ): { [index: string]: string | null } => {
        const errors = {}
        validators.forEach((validatorList, key) => {
          if (formErrors && getByPath(formErrors, key)) {
            setByPath(errors, key, getByPath(formErrors, key))
          } else if (mustValidate || getByPath(state.errors, key) !== undefined) {
            validatorList.forEach((validator) => {
              if (validator) {
                setByPath(errors, key, validator(getByPath(state as Fields, key), state, trigger))
              }
            })
          }
        })
        return errors
      }

      const _prepareStateChange = (newState: FormComponentState & Fields): FormComponentState & Fields => {
        // remove nullable fields
        return newState as NonNullable<FormComponentState & Fields>
      }

      const valueAndValidate = (key: string, val: any, options: ValidateOptions) => {
        setState((state) => {
          const newState = assign(
            { errors: {} },
            state,
            onChange ? onChange(key, val, state, { trigger: options.trigger }, props) : {},
            options.override ? options.override : {},
            true
          )
          if (options.mustValidate) {
            setByPath(newState.errors, key, null)
          }
          const formErrors = {}
          newState.errors = validate(assign({}, state, newState, true), false, options.trigger, formErrors)
          return _prepareStateChange(newState)
        })
      }

      const changeAndValidate = (
        key: string,
        e: React.ChangeEvent<HTMLInputElement> | Date,
        options: ValidateOptions
      ) => {
        const val = resolveValue(e)
        valueAndValidate(key, val, options)
      }

      const handleSubmit = (e: React.ChangeEvent | React.FocusEvent | Date | boolean) => {
        if (!(e instanceof Date) && typeof e !== 'boolean') {
          e.preventDefault()
        }
        setState((state) => {
          //doing this inside setState ensures we are operating on the newest state
          state = { ...state, error: null }
          const errors = validate(state, true, 'onSubmit')
          if (hasErrors(errors)) {
            return { ...state, error: formatValidationErrors(), errors }
          } else if (props.onSubmit) {
            const values = onSubmit
              ? onSubmit({ ...state, error: null, errors: {} }, props)
              : (state as unknown as Result)
            props.onSubmit(values)
          }
          return state
        })
      }

      const decorateAnyField = (id: string, options: DecorateAnyFieldOptions<Fields>) => {
        options = options || {}
        const { placeholder, validate, trigger, skipWrapper, skipLabel, helpText } = options
        let { prefix, suffix } = options
        const title = options.title || placeholder
        if (validate) {
          validators.set(id, [validate])
        }
        return (child: ReactElement<DecorateAnyFieldOptions<Fields>>) => {
          if (prefix && typeof prefix === 'string') {
            prefix = (
              <span key={`${id}-input-prefix-text`} className="ant-input-prefix-text">
                {prefix}
              </span>
            )
          }
          if (suffix && typeof suffix === 'string') {
            suffix = (
              <span key={`${id}-input-suffix-text`} className="ant-input-suffix-text">
                {suffix}
              </span>
            )
          }
          const props: DecorateFieldProps = {
            key: id,
            id,
            title,
            placeholder,
            value: getByPath(state, id),
            onChange:
              options.onChange ||
              ((e: React.ChangeEvent<HTMLInputElement> | Date) => {
                changeAndValidate(id, e, {
                  trigger: 'onChange',
                  mustValidate: trigger === 'onChange',
                })
                if (!(e instanceof Date) && child.props.onChange) {
                  child.props.onChange(e)
                }
                if (!(e instanceof Date) && options.onAfterChange) {
                  options.onAfterChange(e)
                }
                if (submitOnChange) {
                  setTimeout(() => {
                    handleSubmit(e)
                  }, 50)
                }
              }),
            onBlur: options.noBlur
              ? undefined
              : options.onBlur ||
                ((e: React.FocusEvent<HTMLInputElement>) => {
                  changeAndValidate(id, e, {
                    trigger: 'onBlur',
                    mustValidate: true,
                  })
                  if (child.props.onBlur) {
                    child.props.onBlur(e)
                  }
                  if (submitOnChange) {
                    setTimeout(() => {
                      handleSubmit(e)
                    }, 50)
                  }
                }),
            prefix,
            suffix,
          }
          if (options.valueOnChecked) {
            props.checked = props.value
          }
          let children: React.ReactElement[] = [React.cloneElement(child, props)]
          if (!skipLabel && title) {
            children.unshift(
              <label key={id + '-label'} htmlFor={id} title={title}>
                {(getByPath(state.errors, id) as ReactNode) || title}
                {helpText && <HelpModal>{helpText}</HelpModal>}
              </label>
            )
          }
          if (!skipWrapper) {
            children = [
              <Form.Item key={id + '-item'} validateStatus={getByPath(state.errors, id) ? 'error' : 'success'}>
                {children}
              </Form.Item>,
            ]
          }
          if (children.length === 1) {
            return children[0]
          }
          return children
        }
      }

      const decorateField = <Key extends keyof Fields>(id: Key, options: DecorateFieldOptions<Fields, Key>) => {
        return decorateAnyField(id.toString(), options)
      }

      const handleBack = (e: React.MouseEvent) => {
        if (!props.onBack) {
          return
        }
        e.preventDefault()
        const values = onSubmit ? onSubmit({ ...state, error: null, errors: {} }, props) : (state as unknown as Result)
        props.onBack(values)
      }

      return (
        <Form layout="horizontal" onSubmit={handleSubmit} autoComplete="off">
          <WrappedComponent
            {...props}
            decorateField={decorateField}
            decorateAnyField={decorateAnyField}
            getFieldValue={<Key extends keyof Fields>(key: Key) => getByPath(state, key)}
            getAnyFieldValue={(key: string) => getByPath(state, key)}
            setFieldValue={<Key extends keyof Fields>(key: Key, val: Fields[Key]) => {
              setState((state) => {
                return _prepareStateChange({ ...state, [key]: val })
              })
            }}
            setAnyFieldValue={(key: string, val: any) => {
              setState((state) => {
                const newState = { ...state }
                setByPath(newState as unknown as { [index: string]: unknown }, key, val)
                return _prepareStateChange(newState)
              })
            }}
            getFieldError={<Key extends keyof Fields>(key: Key) => getByPath(state.errors, key)}
            getAnyFieldError={(key: string) => getByPath(state.errors, key)}
            setFieldError={<Key extends keyof Fields>(key: Key, error: string) => {
              setState((state) => {
                return _prepareStateChange({ ...state, errors: { ...state.errors, [key]: error } })
              })
            }}
            setAnyFieldError={(key: string, error: string) => {
              setState((state) => {
                const newState = { ...state }
                setByPath(newState.errors, key, error)
                return _prepareStateChange(newState)
              })
            }}
            getFormError={() => (state.error ? <Alert message={state.error} type="error" showIcon /> : null)}
            goBack={handleBack}
          />
        </Form>
      )
    }
  }
}

export function combineSearchOption(option: ReactElement): string {
  const combineChildren = (children: any): string => {
    let text = ''
    if (children.join) {
      text += children.join('')
    }
    if (children.children) {
      text += combineChildren(children.children)
    }
    return text
  }
  let text = ''
  if (option.props.children) {
    text = combineChildren(option.props.children)
  }
  if (option.props.title) {
    text += option.props.title
  }
  return text
}
