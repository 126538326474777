import React, { ReactElement } from 'react'

import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import Input from '../elements/input'

export type Fields = {
  password: string
}

function ResetPasswordForm(props: FormComponentProps<Fields, Fields>): ReactElement | null {
  const { decorateField } = props
  return (
    <div>
      {decorateField('password', {
        placeholder: t('password_reset.form.password'),
        validate: (val) => {
          if (!val) {
            return t('password_reset.form.password.required')
          }
          if (val.length < 8) {
            return t('password_reset.form.password.at_least_8_characters')
          }
          return null
        },
      })(<Input type="password" />)}
      <Form.Item>
        <Button htmlType="submit" type="secondary">
          {t('password_reset.form.submit')}
        </Button>
      </Form.Item>
    </div>
  )
}

export default withValidations<any, Fields, Fields>({
  onSubmit: (values) => values,
})(ResetPasswordForm)
