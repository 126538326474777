import { List } from 'immutable'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'

import CompanyUser from '../../model/companyUser'
import Department from '../../model/department'
import Employee from '../../model/employee'
import { isDepartmentRestricted } from '../../utils/permissions-utils'
import { escapeRegExp } from '../../utils/string-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import Input from './input'

import './EmployeeFilter.css'

export type FilterContainer = {
  searchQuery: string
  departmentID?: string
}

export function filterEmployee(employee: Employee, filter: FilterContainer): boolean {
  if (filter.searchQuery) {
    const pattern = new RegExp(escapeRegExp(filter.searchQuery), 'i')
    if (
      !pattern.test(employee.name ?? '') &&
      !pattern.test(employee.email ?? '') &&
      !pattern.test(employee.nationalID ?? '') &&
      !pattern.test(employee.activeEmployment?.employeeNumber ?? '')
    ) {
      return false
    }
  }
  if (filter.departmentID) {
    if (employee.departmentID !== filter.departmentID) {
      return false
    }
  }
  return true
}

type PresentationProps = {
  filter: FilterContainer
  departments: List<Department>
  prefix?: ReactNode
}

export function EmployeeFilterPresentation(props: PresentationProps): ReactElement | null {
  if (!props.filter.searchQuery && !props.filter.departmentID) {
    return null
  }

  const { searchQuery, departmentID } = props.filter

  const getDepartment = () => {
    return props.departments.find((department) => department.id === departmentID)
  }

  return (
    <>
      {props.prefix}(
      {searchQuery && (
        <>
          {t('employee_filter.searching_for', { query: searchQuery })}
          {departmentID && <>, </>}
        </>
      )}
      {departmentID && <>{t('employee_filter.searching_in_department', { department: getDepartment()?.name ?? '' })}</>}
      )
    </>
  )
}

type Props = {
  companyUser?: CompanyUser
  departments?: List<Department>

  onFilterChange: (filter: FilterContainer) => void
}

export default function EmployeeFilter(props: Props): ReactElement | null {
  const [inputSearchQuery, setInputSearchQuery] = useState('')
  const [container, setContainer] = useState<FilterContainer>({ searchQuery: '' })

  const { onFilterChange } = props

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setContainer((prev) => {
        if (prev.searchQuery === inputSearchQuery) {
          return prev
        }
        return { ...prev, searchQuery: inputSearchQuery }
      })
    }, 100)
    return () => clearTimeout(searchTimeout)
  }, [inputSearchQuery, onFilterChange])

  useEffect(() => {
    onFilterChange(container)
  }, [onFilterChange, container])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearchQuery(e.currentTarget.value.trim())
  }

  const handleSetDepartment = (value?: string) => {
    setContainer((prev) => ({ ...prev, departmentID: value }))
  }

  const getDepartments = () => {
    return props.departments?.filter((department) => department.active) ?? List()
  }

  const departments = getDepartments()
  const restrictedByDepartments = isDepartmentRestricted(props.companyUser)

  return (
    <div className="employee-filter">
      <Input.Search placeholder={t('employee_filter.search')} onChange={handleSearch} />
      {departments.size > (restrictedByDepartments ? 1 : 0) && (
        <Select
          dropdownMatchSelectWidth={false}
          allowClear
          onChange={handleSetDepartment}
          placeholder={t('employee_filter.department')}
          style={{ maxWidth: '200px', padding: 0 }}
        >
          {departments.map((department) => {
            return (
              <Select.Option key={department.id} value={department.id}>
                {department.name}
              </Select.Option>
            )
          })}
        </Select>
      )}
    </div>
  )
}
