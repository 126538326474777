import { subDays } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import { getStagedImportData, getStagedImportResult } from '../../api/data'
import ApplyResult from '../../model/applyResult'
import AsynchronousTask from '../../model/asynchronousTask'
import Company from '../../model/company'
import { DataIntegrationType } from '../../model/dataIntegration'
import Employee from '../../model/employee'
import EmployeeBatchResult from '../../model/employeeBatchResult'
import { BenefitDefinition } from '../../model/remuneration'
import SalaryType from '../../model/salaryType'
import { StagedImportDataCompany, StagedImportDataEmployee } from '../../model/stagedImport'
import { AsynchronousTaskReducer } from '../../reducers/asynchronousTasks'
import { paths } from '../../routes'
import PreferredTaxCardType from '../../types/preferred-tax-card-type'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { getEmployeeSalaryDefinition, SalaryInformation } from '../../utils/data-integration-utils'
import { getDate, isTimeBefore } from '../../utils/date-utils'
import { compareError, convertStoredErrorToError, formatError } from '../../utils/error-utils'
import { formatBenefitDefinition, formatPreferredTaxCardType } from '../../utils/format-utils'
import { formatCurrency, formatNumber } from '../../utils/number-utils'
import { formatEmployeeBatchMessage } from '../../utils/staged-import-utils'
import { t, tx } from '../../utils/translation-utils'
import Select from '../antd/select'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Checkbox from '../elements/checkbox'
import CustomTable from '../elements/custom-table'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import DumbLink from '../widgets/DumbLink'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import AsynchronousTaskSelector from './AsynchronousTaskSelector'
import AsynchronousTaskStatusDisplay from './AsynchronousTaskStatusDisplay'

type Props = {
  displayName: string
  integrationType: DataIntegrationType
  company: Company
  employees: List<Employee>
  salaryTypes: List<SalaryType>
  asynchronousTasks: AsynchronousTaskReducer
  isFieldsMappingComplete: boolean

  addAlert: addAlertSignature
  startEmployeeImport: (companyID: string, preferredTaxCard: PreferredTaxCardType) => Promise<AsynchronousTask | void>
  storeStagedImportData: (asynchronousTaskID: string, employeesToImport?: string[]) => Promise<AsynchronousTask | void>
  getAsynchronousTask: (id: string) => void
  toggleShowFieldsMapping: () => void
}

type State = {
  preferredTaxCard: PreferredTaxCardType
  companies?: StagedImportDataCompany[]
  applyResult?: ApplyResult[]
  employeesToImport: string[]
  step: number
  asynchronousTask?: AsynchronousTask
  importing: boolean
  saving: boolean
}

export default function EmployeesImport(props: Props): ReactElement | null {
  const [state, setState] = useState<State>({
    preferredTaxCard: PreferredTaxCardType.SECONDARY_CARD,
    employeesToImport: [],
    step: 0,
    importing: false,
    saving: false,
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [taskError, setTaskError] = useState<Error | null>(null)

  const loadEmployees = (task: AsynchronousTask) => {
    if (task.status !== 'Success') {
      return
    }
    setLoading(true)
    getStagedImportData(task.id).then((res) => {
      const employees = res.data.companies.reduce((l: StagedImportDataEmployee[], c) => [...l, ...c.employees], [])
      setState((prev) => ({
        ...prev,
        step: 1,
        asynchronousTask: task,
        companies: res.data.companies,
        employeesToImport: employees
          .filter((employee) => employee.errors.length === 0)
          .map((employee) => employee.importID),
        importing: false,
        applyResult: undefined,
      }))
      setLoading(false)
    })
  }

  const loadApplyResult = useCallback(
    (task: AsynchronousTask) => {
      if (task.status !== 'Success') {
        return
      }
      if (loading) {
        return
      }
      if (state.applyResult) {
        return
      }
      setLoading(true)
      getStagedImportResult(task.id).then((res) => {
        if (!res) {
          return
        }
        setState((prev) => ({ ...prev, applyResult: res.data }))
        setLoading(false)
      })
    },
    [loading, state]
  )

  const { asynchronousTasks } = props
  useEffectOnce(() => {
    const task = asynchronousTasks.asynchronousTasks
      .filter((task) => task.type === 'ExternalEmployeeImportGather' && task.status === 'Success')
      .sort((a, b) => (a.startedAt || '').localeCompare(b.startedAt || ''))
      .last()
    if (task) {
      loadEmployees(task)
    }
  })

  const { addAlert } = props

  useEffect(() => {
    const existingTask = state.asynchronousTask
    if (!existingTask) {
      return
    }
    const updatedTask = asynchronousTasks.asynchronousTasks.find((task) => task.id === existingTask.id)
    if (!updatedTask) {
      return
    }
    switch (updatedTask.type) {
      case 'ExternalEmployeeImportGather': {
        let nextImporting = state.importing
        if (nextImporting && (updatedTask.status === 'Failed' || updatedTask.status === 'Success')) {
          nextImporting = false
        }
        const newError = convertStoredErrorToError(updatedTask.error)
        if (!compareError(taskError, newError)) {
          setTaskError(newError)
        }
        if (state.importing !== nextImporting || existingTask.status !== updatedTask.status) {
          setState((prev) => {
            if (prev.importing !== nextImporting) {
              prev = { ...prev, importing: nextImporting }
            }
            if (prev.asynchronousTask && prev.asynchronousTask.status !== updatedTask.status) {
              prev = {
                ...prev,
                asynchronousTask: updatedTask,
              }
            }
            return prev
          })
        }
        if (updatedTask.status === 'Success' && !state.companies && !loading) {
          loadEmployees(updatedTask)
        }
        break
      }
      case 'StagedDataSave': {
        let nextSaving = state.saving
        if (nextSaving && (updatedTask.status === 'Failed' || updatedTask.status === 'Success')) {
          nextSaving = false
        }

        const newError = convertStoredErrorToError(updatedTask.error)
        if (!compareError(taskError, newError)) {
          setTaskError(newError)
        }

        if (state.saving !== nextSaving || existingTask.status !== updatedTask.status) {
          setState((prev) => {
            if (prev.saving !== nextSaving) {
              prev = { ...prev, saving: nextSaving }
            }
            if (prev.asynchronousTask && prev.asynchronousTask.status !== updatedTask.status) {
              prev = {
                ...prev,
                asynchronousTask: updatedTask,
              }
            }
            return prev
          })
        }
        if (updatedTask.status === 'Success') {
          addAlert('success', t('data_integration.employees.alert.success'), { timeout: 5 })
          if ((updatedTask.applySummary?.failures ?? 0) > 0) {
            loadApplyResult(updatedTask)
          } else {
            jsBrowserHistory.push('/' + paths.TIME_REGISTRATION)
          }
        }
        break
      }
      default:
        break
    }
  }, [asynchronousTasks, state, loading, taskError, addAlert, loadApplyResult])

  useEffect(() => {
    regularComponentDidUpdate(asynchronousTasks.error, error, setError)
  }, [asynchronousTasks, error])

  const startImport = (e: React.MouseEvent) => {
    e.preventDefault()

    props.startEmployeeImport(props.company.id, state.preferredTaxCard).then((res) => {
      if (!res) {
        return
      }
      setState((prev) => ({
        ...prev,
        step: 1,
        asynchronousTask: res,
        importing: true,
        companies: undefined,
        employeesToImport: [],
        applyResult: undefined,
      }))
      loadEmployees(res)
    })
  }

  const refreshTask = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!state.asynchronousTask) {
      return
    }

    props.getAsynchronousTask(state.asynchronousTask.id)
  }

  const saveImport = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!state.asynchronousTask) {
      return
    }

    props.storeStagedImportData(state.asynchronousTask.id, state.employeesToImport).then((res) => {
      if (!res) {
        return
      }
      setState((prev) => ({
        ...prev,
        step: 2,
        asynchronousTask: res,
        importing: false,
        saving: true,
        applyResult: undefined,
      }))
    })
  }

  const selectAsynchronousTask = (taskID: string) => {
    const task = props.asynchronousTasks.asynchronousTasks.find((task) => task.id === taskID)
    if (task) {
      setState((prev) => ({
        ...prev,
        step: 1,
        asynchronousTask: task,
        importing: true,
        saving: false,
        companies: undefined,
        applyResult: undefined,
      }))
      loadEmployees(task)
      setTaskError(convertStoredErrorToError(task.error))
    }
  }

  const toggleEmployeeImport = (importID: string) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setState((prev) => {
        if (e.target.checked) {
          return { ...prev, employeesToImport: [...prev.employeesToImport, importID] }
        } else {
          return { ...prev, employeesToImport: [...prev.employeesToImport.filter((id) => id !== importID)] }
        }
      })
    }
  }

  type EmployeeRow = {
    key: string
    importID: string
    canImport: boolean
    willImport: boolean
    nationalID: string
    name: string
    email: string
    phoneNumber: string
    addressLine1: string
    addressLine2: string
    accountNumber: string
    salaryTypes: {
      salaryTypeID: string
      salaryInfo: SalaryInformation
    }[]
    benefits?: BenefitDefinition[]
    errors: string[]
    warnings: string[]
    importResult?: EmployeeBatchResult
  }

  const getRowClassName = (result?: EmployeeBatchResult): string | undefined => {
    if (!result) {
      return undefined
    }

    switch (result.state) {
      case 'Success':
        return 'employee-import-result success'
      case 'Failure':
        return 'employee-import-result failure'
      default:
        return undefined
    }
  }

  const getEmployees = (): EmployeeRow[] => {
    if (!state.companies) {
      return []
    }
    const salaryTypes = {
      ...props.salaryTypes.reduce((o: Record<string, SalaryType>, salaryType) => {
        o[salaryType.id] = salaryType
        return o
      }, {}),
      ...state.companies.reduce((o: Record<string, SalaryType>, company) => {
        return {
          ...o,
          ...company.salaryTypes.reduce((oo: Record<string, SalaryType>, salaryType) => {
            oo[salaryType.id] = salaryType
            return oo
          }, {}),
        }
      }, {}),
    }
    return state.companies
      .reduce((l: StagedImportDataEmployee[], company) => [...l, ...company.employees], [])
      .map((employee): EmployeeRow => {
        const accountNumber = []
        if (employee.bankRegistrationNumber) {
          accountNumber.push(employee.bankRegistrationNumber)
        }
        if (employee.bankAccountNumber) {
          accountNumber.push(employee.bankAccountNumber)
        }
        return {
          key: employee.importID,
          importID: employee.importID,
          willImport: state.employeesToImport.indexOf(employee.importID) !== -1,
          canImport: employee.errors.length === 0,
          nationalID: employee.nationalID || '',
          name: employee.name || '',
          email: employee.email || '',
          phoneNumber: employee.phoneNumber
            ? (employee.phoneNumberCountryCode ? '+' + employee.phoneNumberCountryCode + ' ' : '') +
              employee.phoneNumber
            : '',
          addressLine1: employee.address || '',
          addressLine2: (employee.postalCode || '') + ' ' + (employee.city || ''),
          accountNumber: accountNumber.join(' '),
          salaryTypes: [...(employee.salaryChanges ?? []), ...(employee.remuneration?.salary ?? [])].map((s) => ({
            salaryTypeID: s.salaryTypeID,
            salaryInfo: getEmployeeSalaryDefinition(props.employees, employee, salaryTypes, s.salaryTypeID),
          })),
          benefits: employee.remuneration?.benefits,
          errors: employee.errors.map((err) => err.message),
          warnings: employee.warnings.map((w) => w.message),
          importResult: state.applyResult?.reduce((msg: EmployeeBatchResult | undefined, applyResult) => {
            return applyResult.messages.find((result) => result.importID === employee.importID)
          }, undefined),
        }
      })
      .sort((a, b) => {
        const importResultA = a.importResult
        const importResultB = b.importResult
        if (!importResultA || !importResultB || importResultA.state === importResultB.state) {
          const canImportA = a.errors.length === 0
          const canImportB = b.errors.length === 0
          if (canImportA === canImportB) {
            if (a.warnings.length > 0 === b.warnings.length > 0) {
              return a.name.localeCompare(b.name)
            }
            if (a.warnings.length > 0) {
              return -1
            }
            return 1
          }
          if (canImportA) {
            return 1
          }
          return canImportB ? -1 : 1
        }
        if (importResultA.state !== 'Failure') {
          return 1
        }
        return importResultB.state === 'Failure' ? 1 : -1
      })
  }

  const taxCardSelector = (): ReactNode => {
    return (
      <>
        <span style={{ marginLeft: '15px' }}>{t('data_integration.employees.tax_card_selector')}</span>
        <Select
          dropdownMatchSelectWidth={false}
          style={{ width: '150px', marginLeft: '15px' }}
          defaultValue={state.preferredTaxCard}
          onChange={(v: PreferredTaxCardType) => setState((state) => ({ ...state, preferredTaxCard: v }))}
        >
          <Select.Option value={PreferredTaxCardType.MAIN_CARD}>
            {formatPreferredTaxCardType(PreferredTaxCardType.MAIN_CARD)}
          </Select.Option>
          <Select.Option value={PreferredTaxCardType.SECONDARY_CARD}>
            {formatPreferredTaxCardType(PreferredTaxCardType.SECONDARY_CARD)}
          </Select.Option>
        </Select>
      </>
    )
  }

  const employees = getEmployees()
  const isOldData = isTimeBefore(getDate(state.asynchronousTask?.finishedAt), subDays(getDate(), 1))
  const includeBenefits = props.integrationType !== 'Planday'
  const isUsed = state.asynchronousTask?.gatherInformation?.used ?? false

  return (
    <div className="data-integration-employee-import-container">
      {taskError && (
        <Alert
          message={
            <>
              {formatError(taskError)}
              <Button
                onClick={startImport}
                noArrow
                regular
                className="gtm-time-registrations-employees-import"
                style={{ float: 'right' }}
              >
                {t('data_integration.employees.actions.start_import_again')}
              </Button>
            </>
          }
          type="error"
          showIcon
        />
      )}
      {!taskError && error && <Alert message={formatError(error)} type="error" showIcon />}
      {isUsed && (
        <Alert
          type={'warning'}
          message={
            <>
              {t('data_integration.employees.warning.already_used')}
              <Button
                onClick={startImport}
                noArrow
                regular
                className="gtm-time-registrations-employees-import"
                style={{ float: 'right' }}
              >
                {t('data_integration.employees.actions.start_new_import')}
              </Button>
            </>
          }
          showIcon
        />
      )}
      <TitleMenu style={{ minWidth: '400px' }}>
        <AsynchronousTaskSelector
          asynchronousTasks={props.asynchronousTasks.asynchronousTasks}
          asynchronousTaskType={'ExternalEmployeeImportGather'}
          asynchronousTask={state.asynchronousTask}
          onChange={selectAsynchronousTask}
        />
      </TitleMenu>
      <Title> {t('data_integration.employees.title', { name: props.displayName })}</Title>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      {!props.isFieldsMappingComplete && (
        <p>
          {tx('data_integration.employees.mapping_required', {
            link: (
              <DumbLink onClick={() => props.toggleShowFieldsMapping()}>
                {t('data_integration.employees.mapping_required.link')}
              </DumbLink>
            ),
          })}
        </p>
      )}
      {state.step === 0 && (
        <Row>
          <Col span={24}>
            <Button
              size="large"
              onClick={startImport}
              type="secondary"
              noArrow
              className="gtm-time-registrations-employees-import"
              disabled={!props.isFieldsMappingComplete}
            >
              {t('data_integration.employees.actions.start_import')}
            </Button>
            {taxCardSelector()}
          </Col>
        </Row>
      )}
      {state.step === 1 && state.importing && (
        <AsynchronousTaskStatusDisplay
          title={t('data_integration.employees.status.importing.title')}
          description={t('data_integration.employees.status.importing.description')}
          task={state.asynchronousTask}
          refreshTask={refreshTask}
        />
      )}
      {state.step === 2 && state.saving && (
        <AsynchronousTaskStatusDisplay
          title={t('data_integration.employees.status.saving.title')}
          description={t('data_integration.employees.status.saving.description')}
          task={state.asynchronousTask}
          refreshTask={refreshTask}
        />
      )}
      {(state.step === 1 || (state.step === 2 && !!state.applyResult)) && !state.importing && state.companies && (
        <div className={'employees-import-list'} style={{ textAlign: 'left', fontSize: '14px' }}>
          <Row>
            <Col span={24}>
              {!isUsed && (
                <Button
                  size="large"
                  onClick={saveImport}
                  type="secondary"
                  noArrow
                  className="gtm-time-registrations-employees-import-save"
                >
                  {t('data_integration.employees.actions.save_import')}
                </Button>
              )}
              <Button
                onClick={startImport}
                noArrow
                className="gtm-time-registrations-employees-import"
                style={{ float: 'right' }}
                disabled={!props.isFieldsMappingComplete}
              >
                {t('data_integration.employees.actions.start_import_again')}
              </Button>
              {taxCardSelector()}
            </Col>
          </Row>
          {isOldData && (
            <Row>
              <Col span={24}>
                <Alert
                  type={'warning'}
                  message={t('data_integration.employees.warning.more_than_24_hours')}
                  style={{ marginBottom: '0' }}
                  showIcon
                />
              </Col>
            </Row>
          )}
          {!!state.applyResult && (state.asynchronousTask?.applySummary?.failures ?? 0) > 0 && (
            <Alert
              type={'warning'}
              message={t('data_integration.employees.warning.errors_on_employees', {
                count: state.asynchronousTask?.applySummary?.failures,
              })}
              showIcon
            />
          )}
          <Row>
            <Col span={24}>
              <p>
                {t('data_integration.employees.intro.line_1')}
                <br />
                {tx('data_integration.employees.intro.line_2', {
                  count: state.employeesToImport.length,
                  amount: <strong>{formatNumber(state.employeesToImport.length)}</strong>,
                })}
              </p>
            </Col>
          </Row>
          <CustomTable columns={includeBenefits ? 9 : 8}>
            <CustomTable.Header>
              <CustomTable.TH>&nbsp;</CustomTable.TH>
              <CustomTable.TH>{t('data_integration.employees.header.national_id')}</CustomTable.TH>
              <CustomTable.TH>{t('data_integration.employees.header.name')}</CustomTable.TH>
              <CustomTable.TH>{t('data_integration.employees.header.email')}</CustomTable.TH>
              <CustomTable.TH>{t('data_integration.employees.header.phone_number')}</CustomTable.TH>
              <CustomTable.TH>{t('data_integration.employees.header.address')}</CustomTable.TH>
              <CustomTable.TH>{t('data_integration.employees.header.bank_info')}</CustomTable.TH>
              <CustomTable.TH>{t('data_integration.employees.header.remuneration')}</CustomTable.TH>
              {includeBenefits && <CustomTable.TH>{t('data_integration.employees.header.benefits')}</CustomTable.TH>}
            </CustomTable.Header>
            <CustomTable.Body>
              {employees.map((employee) => {
                let spanRow = 1
                if (employee.importResult && employee.importResult.state !== 'Success') {
                  spanRow++
                }
                if (employee.errors.length > 0 || employee.warnings.length > 0) {
                  spanRow++
                }
                let subSpan = 5
                if (includeBenefits) {
                  subSpan++
                }
                return (
                  <React.Fragment key={employee.key}>
                    <CustomTable.Row className={getRowClassName(employee.importResult)}>
                      <CustomTable.TD spanRow={spanRow}>
                        <Checkbox
                          checked={employee.willImport}
                          disabled={!employee.canImport}
                          onChange={toggleEmployeeImport(employee.importID)}
                        />
                      </CustomTable.TD>
                      <CustomTable.TD spanRow={spanRow}>{employee.nationalID}</CustomTable.TD>
                      <CustomTable.TD spanRow={spanRow}>{employee.name}</CustomTable.TD>
                      <CustomTable.TD>{employee.email}</CustomTable.TD>
                      <CustomTable.TD>{employee.phoneNumber}</CustomTable.TD>
                      <CustomTable.TD>
                        {employee.addressLine1}
                        {employee.addressLine1 && employee.addressLine2 && <br />}
                        {employee.addressLine2}
                      </CustomTable.TD>
                      <CustomTable.TD>{employee.accountNumber}</CustomTable.TD>
                      <CustomTable.TD>
                        {(employee.salaryTypes || []).map((salary, i) => {
                          return (
                            <div key={`salary-${i}`}>
                              {salary.salaryInfo.title}
                              {(salary.salaryInfo.rate ?? 0) !== 0 && <> {formatCurrency(salary.salaryInfo.rate)}</>}
                            </div>
                          )
                        })}
                      </CustomTable.TD>
                      {includeBenefits && (
                        <CustomTable.TD>
                          {employee.benefits &&
                            employee.benefits.map((benefit, i) => {
                              return <div key={`benefit-${i}`}>{formatBenefitDefinition(benefit)}</div>
                            })}
                        </CustomTable.TD>
                      )}
                    </CustomTable.Row>
                    {(employee.errors.length > 0 || employee.warnings.length > 0) && (
                      <CustomTable.Row className={getRowClassName(employee.importResult) + ' spanned-row'}>
                        <CustomTable.TD span={subSpan}>
                          {employee.errors.map((err, i) => {
                            return (
                              <>
                                {i > 0 && <br />}
                                <strong key={`error-${i}`} className="employees-import-error">
                                  {err}
                                </strong>
                              </>
                            )
                          })}
                          {employee.errors.length > 0 && employee.warnings.length > 0 && <br />}
                          {employee.warnings.map((msg, i) => (
                            <>
                              {i > 0 && <br />}
                              <strong key={`warning-${i}`} className="employees-import-warning">
                                {msg}
                              </strong>
                            </>
                          ))}
                        </CustomTable.TD>
                      </CustomTable.Row>
                    )}
                    {employee.importResult && employee.importResult.state !== 'Success' && (
                      <CustomTable.Row className={'employee-import-result failure spanned-row'}>
                        <CustomTable.TD span={subSpan}>
                          {formatEmployeeBatchMessage(
                            employee.importResult.state,
                            employee.importResult.message,
                            employee.importResult.details
                          )}
                        </CustomTable.TD>
                      </CustomTable.Row>
                    )}
                  </React.Fragment>
                )
              })}
            </CustomTable.Body>
          </CustomTable>
          <Row>
            <Col span={24}>&nbsp;</Col>
          </Row>
          <Row>
            <Col span={24}>
              {!isUsed && (
                <Button
                  size="large"
                  onClick={saveImport}
                  type="secondary"
                  noArrow
                  className="gtm-time-registrations-employees-import-save"
                >
                  {t('data_integration.employees.actions.save_import')}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      )}

      {!taskError && !error && state.step === 1 && !state.importing && !state.companies && (
        <LoadingOverlay text={t('data_integration.employees.importing')} />
      )}
      {loading && <LoadingOverlay />}
    </div>
  )
}
