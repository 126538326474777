import CompanyPricingPackage from '../model/companyPricingPackage'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCompanyPricingPackages(companyID: string): Promise<RequestResponse<CompanyPricingPackage[]>> {
  return secureRequest('GET', url('/v2/companyPricingPackages', { companyID }))
}

export function postCompanyPricingPackage(
  companyID: string,
  pricingPackageID: string
): Promise<RequestResponse<CompanyPricingPackage>> {
  return secureRequest('POST', url('/v2/companyPricingPackages'), {
    body: { companyID, pricingPackageID },
  })
}

export function postCompanyPricingPackageCode(
  companyID: string,
  code: string
): Promise<RequestResponse<CompanyPricingPackage>> {
  return secureRequest('POST', url('/v2/companyPricingPackages/code'), {
    body: { companyID, code },
  })
}

export function delCompanyPricingPackage(companyPricingPackageID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/companyPricingPackages/' + companyPricingPackageID))
}
