import React, { CSSProperties, ReactElement } from 'react'

import Spin from '../elements/spin'

import './LoadingOverlay.css'

// NEED_NO_TRANSLATION

type Props = {
  text?: string | React.ReactNode
  inline?: boolean
  style?: CSSProperties
}

export default function LoadingOverlay(props: Props): ReactElement | null {
  return (
    <div className={'loading-overlay' + (props.inline ? ' inline' : '')} style={props.style}>
      <Spin />
      {props.text !== '' && <span className="loading-text">{props.text}</span>}
    </div>
  )
}
