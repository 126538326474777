import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

/**
 * Connect React component to Redux
 * @param {Function} select: Function to extract part of global state, e.g. state => state.homes
 * @param {Object} [actions]: Object of actions to bind to component
 * @returns {Function} React component connected to redux
 */
export function connectToReducerInternal(select, actions) {
  return (DecoratedComponent) => {
    const displayName = DecoratedComponent.displayName || DecoratedComponent.name || 'Component'

    class ReducerConnector extends React.Component {
      render() {
        const { dispatch } = this.props // eslint-disable-line react/prop-types
        const actionProps = actions ? bindActionCreators(actions, dispatch) : {}
        return <DecoratedComponent {...this.props} {...actionProps} />
      }
    }
    ReducerConnector.displayName = `connectToReducer(${displayName})`

    return connect(select)(ReducerConnector)
  }
}
