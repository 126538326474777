import React, { ReactElement, useEffect, useState } from 'react'

import { DepartmentCreationFields, DepartmentMutableFields } from '../../model/department'
import { DepartmentReducer } from '../../reducers/departments'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import DepartmentEditForm, { DepartmentResult } from './DepartmentEditForm'

type Props = {
  visible: boolean
  departmentID?: string
  departments: DepartmentReducer

  addDepartment: (department: DepartmentCreationFields) => void
  updateDepartment: (department: DepartmentMutableFields) => void
}

export default function DepartmentEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, departments } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, departments.error, error, setError)
  }, [visible, departments, error])

  const handleSubmit = (values: DepartmentResult) => {
    if (props.departmentID) {
      props.updateDepartment({
        id: props.departmentID,
        name: values.name,
      })
    } else {
      props.addDepartment({ name: values.name })
    }
  }

  return (
    <Card>
      <Subtitle>{props.departmentID ? t('department.edit.title.edit') : t('department.edit.title.create')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <DepartmentEditForm departmentID={props.departmentID} departments={props.departments} onSubmit={handleSubmit} />
    </Card>
  )
}
