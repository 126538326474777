import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import { UserReducer } from '../../reducers/user'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Title from '../elements/Title'
import PasswordForm, { PasswordResult } from './PasswordForm'

type Props = {
  user: UserReducer

  addAlert: addAlertSignature
  changePassword: (oldPassword: string, newPassword: string) => void
}

export default function PasswordTab(props: Props): ReactElement | null {
  const [formKey, setFormKey] = useState(1)
  const [error, setError] = useState<Error | null>(null)

  const { user } = props

  useEffect(() => {
    regularComponentDidUpdate(user.error, error, setError)
  }, [user, error])

  const { addAlert } = props
  const previousUser = usePrevious(user)

  useEffect(() => {
    // Check for save callback
    if (previousUser && previousUser.changingPassword && !user.changingPassword) {
      // Check for no error occurred
      if (!user.error) {
        addAlert('success', t('form.message.your_password_has_been_updated'), { timeout: 3 })
        // Reset form element to clear fields
        setFormKey((prev) => prev + 1)
      }
    }
  }, [previousUser, user, addAlert])

  const handleSubmit = (values: PasswordResult) => {
    props.changePassword(values.oldPassword, values.newPassword)
  }

  return (
    <Card>
      <Title>{t('account.password')}</Title>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <PasswordForm key={formKey} user={props.user} onSubmit={handleSubmit} />
    </Card>
  )
}
