import React from 'react'

import { fetchLeaveBalances } from '../api/leave-balances'
import ActionTypes from '../constants/action-types'
import LeaveBalance from '../model/leaveBalance'
import { LeaveBalanceAction } from '../reducers/leaveBalances'
import { isRequestError } from '../utils/error-utils'

function loadingLeaveBalances(companyID: string | undefined, employeeID: string | undefined): LeaveBalanceAction {
  return {
    type: ActionTypes.LEAVE_BALANCES_LOADING,
    companyID,
    employeeID,
  }
}
function loadedLeaveBalances(
  companyID: string | undefined,
  employeeID: string | undefined,
  leaveBalances: LeaveBalance[]
): LeaveBalanceAction {
  return {
    type: ActionTypes.LEAVE_BALANCES_LOADED,
    companyID,
    employeeID,
    leaveBalances,
  }
}
function failedLoadingLeaveBalances(
  companyID: string | undefined,
  employeeID: string | undefined,
  error: Error
): LeaveBalanceAction {
  return {
    type: ActionTypes.LEAVE_BALANCES_LOAD_FAILED,
    error,
    companyID,
    employeeID,
  }
}

export function updatedLeaveBalance(employeeID: string, leaveBalance: LeaveBalance): LeaveBalanceAction {
  return {
    type: ActionTypes.LEAVE_BALANCE_UPDATED,
    employeeID,
    leaveTypeID: leaveBalance.leaveTypeID,
    leaveBalance,
  }
}

export function getLeaveBalances(companyID?: string, employeeID?: string) {
  return (dispatch: React.Dispatch<any>): Promise<LeaveBalance[] | void> => {
    dispatch(loadingLeaveBalances(companyID, employeeID))
    return fetchLeaveBalances(companyID, employeeID)
      .then((res) => {
        dispatch(loadedLeaveBalances(companyID, employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingLeaveBalances(companyID, employeeID, e))
        }
      })
  }
}
