import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../../model/company'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import { ContractReducer } from '../../../reducers/contracts'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate } from '../../../utils/date-utils'
import { getValidFrom } from '../../../utils/employment-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import EmploymentPayEditForm, { EmploymentPayFields } from './EmploymentPayEditForm'

type Props = {
  visible: boolean
  employee: Employee
  mutableContract: Contract
  contracts: ContractReducer
  company: Company
  salaryCycles: List<SalaryCycle>

  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
}

export default function EmploymentPayEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, contracts } = props
  useEffect(() => {
    visibleComponentDidUpdate(visible, contracts.error, error, setError)
  }, [visible, contracts, error])

  const handleSubmit = (values: EmploymentPayFields) => {
    const contract = { ...props.mutableContract, salaryCycleID: values.salaryCycleID }

    const { validFrom, canUpdate } = getValidFrom(props.employee, contract)
    if (canUpdate) {
      props.updateContract(contract)
    } else {
      contract.validFrom = formatAPIDate(validFrom)
      props.addContract(contract)
    }
  }

  return (
    <Card>
      <Subtitle>{t('freelancer.pay.edit.title')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <EmploymentPayEditForm
        mutableContract={props.mutableContract}
        validFrom={getValidFrom(props.employee, props.mutableContract).validFrom}
        employee={props.employee}
        contracts={props.contracts}
        company={props.company}
        salaryCycles={props.salaryCycles}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
