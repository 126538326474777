import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { AssetMutableFields } from '../../../model/asset'
import AssetCategory from '../../../model/assetCategory'
import { AssetReducer } from '../../../reducers/assets'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate, getDate } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import AssetEditForm, { AssetResult } from './AssetEditForm'

type Props = {
  returning: boolean
  visible: boolean
  editing: boolean
  full: boolean
  employeeID?: string
  companyID: string
  assetID?: string
  assets: AssetReducer
  assetCategories: List<AssetCategory>

  createAsset: (companyID: string, asset: AssetMutableFields) => void
  updateAsset: (companyID: string, asset: AssetMutableFields) => void
}

export default function AssetEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, companyID, assets } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, assets.error, error, setError)
  }, [visible, assets, error])

  let assetName = ''
  props.assets.assets.toArray().forEach((value) => {
    if (value.id != props.assetID) {
      return
    }

    assetName = value.title
  })

  const handleSubmit = (values: AssetResult) => {
    if (!props.editing || !props.employeeID) {
      return
    }
    const asset: AssetMutableFields = {
      employeeID: props.employeeID,
      categoryID: values.categoryID,
      title: values.title,
      description: values.description,
      note: values.note,
      receivedDate: values.receivedDate,
      returnDate: values.returnDate,
    }
    if (props.assetID) {
      asset.id = props.assetID
      asset.receivedDate = values.receivedDate
      props.updateAsset(companyID, asset)
    } else {
      if (!asset.receivedDate) {
        asset.receivedDate = formatAPIDate(getDate())
      }
      props.createAsset(companyID, asset)
    }
  }

  return (
    <Card>
      {props.returning && <Subtitle>{t('asset.tab.confirm.returning') + ' ' + assetName}</Subtitle>}
      {!props.returning && (
        <Subtitle>{props.assetID ? t('asset.edit.title.edit') : t('asset.edit.title.create')}</Subtitle>
      )}
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <AssetEditForm
        returning={props.returning}
        editing={props.editing}
        full={props.full}
        assetID={props.assetID}
        assets={props.assets.assets}
        assetCategories={props.assetCategories}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
