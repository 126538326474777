import React, { CSSProperties, ReactElement } from 'react'

import classNames from '../../antd/_util/classNames'

import './style/css'

type Props = React.DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  prefixCls?: string
  extra?: string
  children?: React.ReactNode
  bodyStyle?: CSSProperties
  noHovering?: boolean
  loading?: boolean
  bordered?: boolean
}

function Card(props: Props): ReactElement | null {
  const {
    prefixCls = 'ant-card',
    className,
    extra,
    bodyStyle,
    noHovering,
    title,
    loading,
    bordered = true,
    ...others
  } = props
  let children = props.children

  const classString = classNames(prefixCls, className, {
    [`${prefixCls}-loading`]: loading,
    [`${prefixCls}-bordered`]: bordered,
    [`${prefixCls}-no-hovering`]: noHovering,
    [`${prefixCls}-wider-padding`]: false,
  })

  if (loading) {
    children = (
      <div className={`${prefixCls}-loading-content`}>
        <p className={`${prefixCls}-loading-block`} style={{ width: '94%' }} />
        <p>
          <span className={`${prefixCls}-loading-block`} style={{ width: '28%' }} />
          <span className={`${prefixCls}-loading-block`} style={{ width: '62%' }} />
        </p>
        <p>
          <span className={`${prefixCls}-loading-block`} style={{ width: '22%' }} />
          <span className={`${prefixCls}-loading-block`} style={{ width: '66%' }} />
        </p>
        <p>
          <span className={`${prefixCls}-loading-block`} style={{ width: '56%' }} />
          <span className={`${prefixCls}-loading-block`} style={{ width: '39%' }} />
        </p>
        <p>
          <span className={`${prefixCls}-loading-block`} style={{ width: '21%' }} />
          <span className={`${prefixCls}-loading-block`} style={{ width: '15%' }} />
          <span className={`${prefixCls}-loading-block`} style={{ width: '40%' }} />
        </p>
      </div>
    )
  }

  let head
  if (title || extra) {
    head = (
      <div className={`${prefixCls}-head`}>
        {title ? <div className={`${prefixCls}-head-title`}>{title}</div> : null}
        {extra ? <div className={`${prefixCls}-extra`}>{extra}</div> : null}
      </div>
    )
  }

  return (
    <div {...others} className={classString}>
      {head}
      <div className={`${prefixCls}-body`} style={bodyStyle}>
        {children}
      </div>
    </div>
  )
}

export default Card
