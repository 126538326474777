import React, { ReactElement } from 'react'

import { AccountingDimensionCreation, AccountingDimensionMutable } from '../../../model/accountingDimension'
import AccountingIntegration from '../../../model/accountingIntegration'
import { AccountingDimensionReducer } from '../../../reducers/accountingDimensions'
import { t } from '../../../utils/translation-utils'
import Subcard from '../../elements/Subcard'
import Subtitle from '../../elements/Subtitle'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import EmployeeDimensionsForm, { EmployeeDimensionResult } from './EmployeeDimensionsForm'

type Props = {
  accountingIntegration?: AccountingIntegration
  accountingDimensions: AccountingDimensionReducer

  selectAccountingDimensions: (dimensions: (AccountingDimensionMutable | AccountingDimensionCreation)[]) => void
}

export default function EmployeeDimensionsCard(props: Props): ReactElement | null {
  const handleSubmit = (values: EmployeeDimensionResult) => {
    props.selectAccountingDimensions(values.dimensions)
  }

  return (
    <Subcard>
      <Subtitle>{t('dimensions_tab.employee.title')}</Subtitle>
      <p>{t('dimensions_tab.employee.intro')}</p>
      {props.accountingIntegration && (
        <p>
          {props.accountingIntegration.costCenterAccounting === 'Departments'
            ? t('dimensions_tab.employee.with_integration.departments')
            : t('dimensions_tab.employee.with_integration.cost_centers')}
        </p>
      )}
      {!props.accountingDimensions.saving && (
        <EmployeeDimensionsForm
          accountingIntegration={props.accountingIntegration}
          accountingDimensions={props.accountingDimensions}
          onSubmit={handleSubmit}
        />
      )}
      {props.accountingDimensions.saving && <LoadingOverlay />}
    </Subcard>
  )
}
