import React from 'react'

import {
  deleteCompanyFromCompanyGroup,
  deleteCompanyGroupUser,
  fetchCompanyGroups,
  patchCompanyGroupUserSetCompanySettings,
  postCompanyToCompanyGroup,
  putCompanyGroup,
  putCompanyGroupUser,
} from '../api/company-groups'
import ActionTypes from '../constants/action-types'
import CompanyGroup, { CompanyGroupMutableFields, CompanyGroupUser } from '../model/companyGroup'
import CompanySetting from '../model/companySetting'
import { CompanyGroupAction } from '../reducers/companyGroups'
import { isRequestError } from '../utils/error-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingCompanyGroups(): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_LOADING,
  }
}
export function loadedCompanyGroups(companyGroups: CompanyGroup[]): CompanyGroupAction {
  let companyGroupID = undefined
  if (companyGroups.length > 0) {
    companyGroupID = companyGroups[0].id
  }
  return {
    type: ActionTypes.COMPANY_GROUPS_LOADED,
    companyGroups,
    companyGroupID,
  }
}
function failedLoadingCompanyGroups(error: Error): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_LOAD_FAILED,
    error,
  }
}

export function setActiveCompanyGroup(companyGroupID: string): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_SET_ACTIVE,
    companyGroupID,
  }
}

function updatingCompanyGroup(companyGroupID: string): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_UPDATING,
    companyGroupID,
  }
}
export function updatedCompanyGroup(companyGroupID: string, companyGroup: CompanyGroup): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_UPDATED,
    companyGroupID,
    companyGroup,
  }
}
function failedUpdatingCompanyGroup(companyGroupID: string, error: Error): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_UPDATE_FAILED,
    companyGroupID,
    error,
  }
}

function addingCompanyToCompanyGroup(): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_ADDING_COMPANY,
  }
}
export function addedCompanyToCompanyGroup(companyGroupID: string, companyGroup: CompanyGroup): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_ADDED_COMPANY,
    companyGroupID,
    companyGroup,
  }
}
function failedAddingCompanyToCompanyGroup(error: Error): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_ADD_COMPANY_FAILED,
    error,
  }
}

function removingCompanyFromCompanyGroup(): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_REMOVING_COMPANY,
  }
}
export function removedCompanyFromCompanyGroup(companyGroupID: string, companyGroup: CompanyGroup): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_REMOVED_COMPANY,
    companyGroupID,
    companyGroup,
  }
}
function failedRemovingCompanyFromCompanyGroup(error: Error): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_REMOVE_COMPANY_FAILED,
    error,
  }
}

export function addedCompanyGroupUser(
  companyGroupID: string,
  userID: string,
  companyGroupUser: CompanyGroupUser
): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_USER_ADDED,
    companyGroupID,
    userID,
    companyGroupUser,
  }
}
export function updatedCompanyGroupUser(
  companyGroupID: string,
  userID: string,
  companyGroupUser: CompanyGroupUser
): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_USER_UPDATED,
    companyGroupID,
    userID,
    companyGroupUser,
  }
}
export function deletedCompanyGroupUser(companyGroupID: string, userID: string): CompanyGroupAction {
  return {
    type: ActionTypes.COMPANY_GROUPS_USER_DELETED,
    companyGroupID,
    userID,
  }
}

export function getCompanyGroups() {
  return (dispatch: React.Dispatch<any>): Promise<CompanyGroup[] | void> => {
    dispatch(loadingCompanyGroups())
    return fetchCompanyGroups()
      .then((res) => {
        dispatch(loadedCompanyGroups(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompanyGroups(e))
        }
      })
  }
}

export function updateCompanyGroup(companyGroupID: string, companyGroup: CompanyGroupMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<CompanyGroup | void> => {
    dispatch(updatingCompanyGroup(companyGroupID))
    return putCompanyGroup(companyGroupID, companyGroup)
      .then((res) => {
        dispatch(updatedCompanyGroup(companyGroupID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompanyGroup(companyGroupID, e))
        }
      })
  }
}

export function addCompanyToCompanyGroup(companyGroupID: string, companyID: string) {
  return (dispatch: React.Dispatch<any>): Promise<CompanyGroup | void> => {
    dispatch(addingCompanyToCompanyGroup())
    return postCompanyToCompanyGroup(companyGroupID, companyID)
      .then((res) => {
        dispatch(addedCompanyToCompanyGroup(companyGroupID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingCompanyToCompanyGroup(e))
        }
      })
  }
}

export function removeCompanyFromCompanyGroup(companyGroupID: string, companyID: string) {
  return (dispatch: React.Dispatch<any>): Promise<CompanyGroup | void> => {
    dispatch(removingCompanyFromCompanyGroup())
    return deleteCompanyFromCompanyGroup(companyGroupID, companyID)
      .then((res) => {
        dispatch(removedCompanyFromCompanyGroup(companyGroupID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedRemovingCompanyFromCompanyGroup(e))
        }
      })
  }
}

export function setCompanyGroupCompanySettings(
  companyGroupID: string,
  settingsEnable: CompanySetting[],
  settingDisable: CompanySetting[],
  retroactive: boolean
) {
  return (dispatch: React.Dispatch<any>): Promise<CompanyGroup | void> => {
    dispatch(updatingCompanyGroup(companyGroupID))
    return patchCompanyGroupUserSetCompanySettings(companyGroupID, settingsEnable, settingDisable, retroactive)
      .then((res) => {
        dispatch(updatedCompanyGroup(companyGroupID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompanyGroup(companyGroupID, e))
        }
      })
  }
}

export function updateCompanyGroupUser(companyGroupID: string, userID: string, companyGroupUser: CompanyGroupUser) {
  return (dispatch: React.Dispatch<any>): Promise<CompanyGroupUser | void> => {
    dispatch(updatingCompanyGroup(companyGroupID))
    return putCompanyGroupUser(companyGroupID, userID, companyGroupUser)
      .then((res) => {
        dispatch(updatedCompanyGroupUser(companyGroupID, userID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompanyGroup(companyGroupID, e))
        }
      })
  }
}

export function removeUserFromCompanyGroup(companyGroupID: string, userID: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(updatingCompanyGroup(companyGroupID))
    return deleteCompanyGroupUser(companyGroupID, userID)
      .then(() => {
        dispatch(deletedCompanyGroupUser(companyGroupID, userID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompanyGroup(companyGroupID, e))
        }
      })
  }
}

export function selectActiveCompanyGroup(companyGroupID: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(setActiveCompanyGroup(companyGroupID))
    return PromiseVoid
  }
}
