import { addYears, subYears } from 'date-fns'

import PayRoll from '../model/payRoll'
import { PaymentMethod } from '../model/transfer'
import { DateFormat, DateTimeFormat } from '../model/types'
import { formatAPIDate, getDate } from '../utils/date-utils'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchPayRolls(companyID: string, limit: number, offset: number): Promise<RequestResponse<PayRoll[]>> {
  const fromDate = formatAPIDate(subYears(getDate(), 10))
  const toDate = formatAPIDate(addYears(getDate(), 10))
  return secureRequest('GET', url('/v2/payRolls', { companyID, from: fromDate, to: toDate, limit, offset }))
}

export function fetchPayRoll(payRollID: string): Promise<RequestResponse<PayRoll>> {
  return secureRequest('GET', url('/v2/payRolls/' + payRollID))
}

export type NewPayRoll = {
  companyID: string
  type: 'Extra' | 'Negation'
  salaryPeriodID: string
  dispositionDate: DateFormat
  overdueTime?: DateTimeFormat
  employeeIDs?: string[]
  paySlipIDs?: string[]
}

export function postPayRoll(payRoll: NewPayRoll): Promise<RequestResponse<PayRoll>> {
  return secureRequest('POST', url('/v2/payRolls'), {
    body: payRoll,
  })
}

export function postZeroTaxReport(companyID: string, month: DateFormat): Promise<RequestResponse<PayRoll>> {
  return secureRequest('POST', url('/v2/zeroTaxReports'), {
    body: {
      companyID,
      month,
    },
  })
}

export type NotificationChannel = 'Email' | 'SMS'

type PatchPayRollOptions = {
  notifyUserIDs?: string[]
  notifyChannel?: NotificationChannel
  paymentMethod?: PaymentMethod
  notificationMessage?: string
  notifyPreviousApprovers?: boolean
  payrollApprovalCode?: string
}

export function patchPayRoll(
  payRollID: string,
  version: string,
  operation:
    | 'Review'
    | 'Approve'
    | 'Reject'
    | 'RevertApproval'
    | 'Restart'
    | 'SetPaymentMethod'
    | 'CompleteSKAT'
    | 'ResendSKAT',
  options?: PatchPayRollOptions
): Promise<RequestResponse> {
  options = options || {}
  return secureRequest('PATCH', url('/v2/payRolls/' + payRollID), {
    body: {
      operation,
      version,
      notifyUserIDs: options.notifyUserIDs,
      notifyChannel: options.notifyChannel,
      paymentMethod: options.paymentMethod,
      notificationMessage: options.notificationMessage,
      notifyPreviousApprovers: options.notifyPreviousApprovers,
      payrollApprovalCode: options.payrollApprovalCode,
    },
  })
}

export function putPayRoll(payRollID: string, dispositionDate: DateFormat): Promise<RequestResponse<PayRoll>> {
  return secureRequest('PUT', url('/v2/payRolls/' + payRollID), {
    body: { dispositionDate },
  })
}

export function delPayRoll(payRollID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/payRolls/' + payRollID))
}
