import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import Company from '../../../model/company'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import SalaryCycle from '../../../model/salaryCycle'
import SalaryType from '../../../model/salaryType'
import SupplementType from '../../../model/supplementType'
import { Day } from '../../../model/types'
import { ContractReducer } from '../../../reducers/contracts'
import { getEmployeePayType } from '../../../utils/employee-utils'
import { formatEmployeePayType, formatSalaryCycle } from '../../../utils/format-utils'
import { formatNumber } from '../../../utils/number-utils'
import { getSalaryType } from '../../../utils/salary-type-utils'
import { t, tx } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Table from '../../antd/table'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import EmploymentPayEdit from './EmploymentPayEdit'

type Props = {
  mutableContract: Contract
  employee: Employee
  company: Company
  contracts: ContractReducer
  salaryCycles: List<SalaryCycle>
  leaveTypes: List<LeaveType>
  supplementTypes: List<SupplementType>
  salaryTypes: List<SalaryType>
  isMutableContract: boolean
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
}

export default function EmploymentPayCard(props: Props): ReactElement | null {
  const [showEdit, setShowEdit] = useState(false)
  const [modalKey, setModalKey] = useState(1)

  const { contracts } = props
  const previousContracts = usePrevious(contracts)

  const setEditVisibility = (visible: boolean) => {
    if (!props.isMutableContract) {
      return
    }
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setShowEdit(visible)
  }

  useEffect(() => {
    if (previousContracts && previousContracts.saving && !contracts.saving) {
      if (!contracts.error) {
        setEditVisibility(false)
      }
    }
  })

  const getEmployment = () => {
    const payType = getEmployeePayType(props.mutableContract)
    let employment = formatEmployeePayType(payType)
    if (payType === 'Salaried') {
      if (props.mutableContract.weeklyHours <= 30) {
        employment = t('employment_pay.card.employment.salaried.part_time', { title: employment })
      } else {
        employment = t('employment_pay.card.employment.salaried.full_time', { title: employment })
      }
    }
    return employment
  }
  const getCycle = () => {
    const cycle = props.salaryCycles.find((salaryCycle) => props.mutableContract.salaryCycleID === salaryCycle.id)
    if (cycle) {
      return formatSalaryCycle(cycle.frequency, cycle.offset)
    }
    return '-'
  }

  type SalaryRow = {
    key: string
    type: string
    title: string
    quantity?: string
    rate: string
    total: string
    supplements?: number
    frequency: string
  }

  const salaryColumns = [
    { title: t('employment_pay.card.table.header.type'), dataIndex: 'type', key: 'type' },
    { title: t('employment_pay.card.table.header.title'), dataIndex: 'title', key: 'title' },
    {
      title: t('employment_pay.card.table.header.rate'),
      dataIndex: '',
      key: 'x1',
      render: (row: SalaryRow) =>
        row.quantity ? (
          <span>
            {tx('employment_pay.card.table.quantity_format', {
              quantity: row.quantity,
              rate: row.rate,
              total: row.total,
              frequency: <em>{t('employment_pay.card.table.frequency_format', { frequency: row.frequency })}</em>,
            })}
          </span>
        ) : (
          <span>
            {tx('employment_pay.card.table.rate_format', {
              rate: row.rate,
              frequency: <em>{t('employment_pay.card.table.frequency_format', { frequency: row.frequency })}</em>,
            })}
            {(row.supplements || 0) > 0 && (
              <>
                <br />
                <small>{t('employment_pay.card.table.rate_format.with_supplement')}</small>
              </>
            )}
          </span>
        ),
    },
  ]
  const getSalaryRows = (): SalaryRow[] => {
    return (
      props.mutableContract.remuneration?.salary.map((row, i): SalaryRow | null => {
        const salaryType = getSalaryType(props.salaryTypes.toArray(), row.salaryTypeID)
        if (!salaryType) {
          return null
        }
        return {
          key: row.id || i.toString(),
          type: salaryType.title || '',
          title: salaryType.title === row.title ? '' : row.title || '',
          rate: formatNumber(row.rate, 2),
          quantity: (row.quantity || 0) > 1 ? formatNumber(row.quantity, 0) : undefined,
          total: formatNumber(row.rate * (row.quantity || 1), 2),
          supplements: salaryType.supplements ? salaryType.supplements.length : undefined,
          frequency:
            salaryType.class === 'SupplementVaried'
              ? t('employment_pay.card.table.frequency.piece')
              : salaryType.class === 'Hourly' || salaryType.class === 'HourlyAggregate'
              ? t('employment_pay.card.table.frequency.hourly')
              : getCycle().toLowerCase(),
        }
      }) || []
    ).reduce((list: SalaryRow[], row) => {
      if (!row) {
        return list
      }
      list.push(row)
      return list
    }, [])
  }
  const averageWeeklyWorkDays = (workCycle: Day[][]): number => {
    return (
      workCycle.reduce((c, w) => {
        return c + w.length
      }, 0) / workCycle.length
    )
  }

  return (
    <Card>
      <TitleMenu>
        {props.isMutableContract && <Button className="ant-btn-edit" onClick={() => setEditVisibility(true)} />}
      </TitleMenu>
      <Title>{t('employment_pay.card.title')}</Title>

      <Row>
        <Col span={7}>
          {t('employment_pay.card.fields.employment')}:
          <br />
          <strong>{getEmployment()}</strong>
        </Col>
        {getEmployeePayType(props.mutableContract) === 'Salaried' && (
          <Col span={7}>
            {t('employment_pay.card.fields.weekly_hours')}:
            <br />
            <strong>
              {t('employment_pay.card.fields.weekly_hours.hours', { count: props.mutableContract.weeklyHours })} (
              {t('employment_pay.card.fields.weekly_hours.days', {
                count: averageWeeklyWorkDays(props.mutableContract.workCycle ?? []),
              })}
              {(props.mutableContract.workCycle?.length ?? 0) > 1 && (
                <>
                  {' '}
                  /{' '}
                  {t('employment_pay.card.fields.weekly_hours.weeks', {
                    count: props.mutableContract.workCycle?.length ?? 0,
                  })}
                </>
              )}
              )
            </strong>
          </Col>
        )}
        <Col span={7}>
          {t('employment_pay.card.fields.cycle')}:
          <br />
          <strong>{getCycle()}</strong>
        </Col>
      </Row>

      <Table columns={salaryColumns} dataSource={getSalaryRows()} size="small" pagination={false} />

      <Modal
        key={modalKey}
        visible={showEdit}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={980}
        footer={null}
      >
        <EmploymentPayEdit
          visible={showEdit}
          employee={props.employee}
          mutableContract={props.mutableContract}
          contracts={props.contracts}
          company={props.company}
          salaryCycles={props.salaryCycles}
          leaveTypes={props.leaveTypes}
          supplementTypes={props.supplementTypes}
          salaryTypes={props.salaryTypes}
          addContract={props.addContract}
          updateContract={props.updateContract}
        />
      </Modal>
    </Card>
  )
}
