import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import Employee from '../../model/employee'
import { paths } from '../../routes'
import { t } from '../../utils/translation-utils'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'

interface Props {
  employee: Employee
}

export default function NoContractCard(props: Props): ReactElement | null {
  return (
    <Card className="no-contract-card">
      <Subtitle>{t('employee_no_contract.title')}</Subtitle>
      <p>{t('employee_no_contract.text')}</p>

      <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/contracts/add'}>
        {t('employee_no_contract.button')}
      </Link>
    </Card>
  )
}
