import React, { Children } from 'react'

import classNames from '../_util/classNames'
import TimelineItem from './TimelineItem'

class Timeline extends React.Component {
  render() {
    const { prefixCls, children, pending, className, ...restProps } = this.props
    const pendingNode = typeof pending === 'boolean' ? null : pending
    const classString = classNames(
      prefixCls,
      {
        [`${prefixCls}-pending`]: !!pending,
      },
      className
    )
    // Remove falsy items
    const falsylessItems = Children.toArray(children).filter((item) => !!item)
    const items = Children.map(falsylessItems, (ele, idx) =>
      React.cloneElement(ele, {
        last: idx === Children.count(falsylessItems) - 1,
      })
    )
    const pendingItem = pending ? <TimelineItem pending={!!pending}>{pendingNode}</TimelineItem> : null
    return (
      <ul {...restProps} className={classString}>
        {items}
        {pendingItem}
      </ul>
    )
  }
}

Timeline.Item = TimelineItem
Timeline.defaultProps = {
  prefixCls: 'ant-timeline',
}

export default Timeline
