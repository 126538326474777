import React, { ReactElement, useEffect, useState } from 'react'

import { SalaryClass, SalaryTypeMutableFields } from '../../model/salaryType'
import { SalaryTypeReducer } from '../../reducers/salaryTypes'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import SalaryTypeEditHourlyForm, { SalaryTypeHourlyResult } from './SalaryTypeEditHourlyForm'
import SalaryTypeEditSupplementVariedForm, {
  SalaryTypeSupplementVariedResult,
} from './SalaryTypeEditSupplementVariedForm'

interface Props {
  visible: boolean
  salaryClass?: SalaryClass
  salaryTypeID?: string
  salaryTypes: SalaryTypeReducer
  allowSalaryTypeSupplement: boolean
  addSalaryType: (salaryType: SalaryTypeMutableFields) => void
  updateSalaryType: (salaryType: SalaryTypeMutableFields) => void
}

export default function SalaryTypeEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, salaryTypes } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, salaryTypes.error, error, setError)
  }, [visible, salaryTypes, error])

  const handleHourlySubmit = (values: SalaryTypeHourlyResult) => {
    if (props.salaryTypeID) {
      props.updateSalaryType({
        id: props.salaryTypeID,
        title: values.title,
        class: 'Hourly',
        supplements: values.supplements,
        includeInPensionBasis: values.includeInPensionBasis,
        includeInVacationBasis: values.includeInVacationBasis,
        includeInShFritvalgBasis: values.includeInShFritvalgBasis,
      })
    } else {
      props.addSalaryType({
        title: values.title,
        class: 'Hourly',
        supplements: values.supplements,
        includeInPensionBasis: values.includeInPensionBasis,
        includeInVacationBasis: values.includeInVacationBasis,
        includeInShFritvalgBasis: values.includeInShFritvalgBasis,
      })
    }
  }

  const handleSupplementVariedSubmit = (values: SalaryTypeSupplementVariedResult) => {
    if (props.salaryTypeID) {
      props.updateSalaryType({
        id: props.salaryTypeID,
        title: values.title,
        class: 'SupplementVaried',
        includeInPensionBasis: values.includeInPensionBasis,
        includeInVacationBasis: values.includeInVacationBasis,
        includeInShFritvalgBasis: values.includeInShFritvalgBasis,
      })
    } else {
      props.addSalaryType({
        title: values.title,
        class: 'SupplementVaried',
        includeInPensionBasis: values.includeInPensionBasis,
        includeInVacationBasis: values.includeInVacationBasis,
        includeInShFritvalgBasis: values.includeInShFritvalgBasis,
      })
    }
  }

  const editing = !!props.salaryTypeID
  let isHourly = true
  if (!editing) {
    if (props.salaryClass && props.salaryClass === 'SupplementVaried') {
      isHourly = false
    }
  } else {
    const salaryType = props.salaryTypes.salaryTypes.find((type) => type.id === props.salaryTypeID)
    if (salaryType) {
      isHourly = salaryType.class === 'Hourly'
    }
  }
  return (
    <Card>
      <Subtitle>
        {isHourly
          ? editing
            ? t('salary_types.hourly.edit.title.edit')
            : t('salary_types.hourly.edit.title.create')
          : editing
          ? t('salary_types.supplement_varied.edit.title.edit')
          : t('salary_types.supplement_varied.edit.title.create')}
      </Subtitle>
      {isHourly && (
        <p>
          {t('salary_types.hourly.edit.note.line_1')}
          <br />
          {t('salary_types.hourly.edit.note.line_2')}
        </p>
      )}
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      {isHourly && (
        <SalaryTypeEditHourlyForm
          salaryTypeID={props.salaryTypeID}
          salaryTypes={props.salaryTypes}
          allowSalaryTypeSupplement={props.allowSalaryTypeSupplement}
          onSubmit={handleHourlySubmit}
        />
      )}
      {!isHourly && (
        <SalaryTypeEditSupplementVariedForm
          salaryTypeID={props.salaryTypeID}
          salaryTypes={props.salaryTypes}
          onSubmit={handleSupplementVariedSubmit}
        />
      )}
    </Card>
  )
}
