import React, { ReactElement, useEffect } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import Employee from '../../../model/employee'
import { EmployeeInviteReducer } from '../../../reducers/employeeInvites'
import { formatDateTime } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Tooltip from '../../elements/tooltip'
import FeatureLock from '../../widgets/FeatureLock'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import Lock from '../../widgets/Lock'

type Props = {
  employee: Employee
  employeeInvites: EmployeeInviteReducer

  addAlert: addAlertSignature
  sendInvite: (id: string) => void
  deleteEmployeeUser: (id: string) => void
}

export default function EmployeeAppInvite(props: Props): ReactElement | null {
  const { employeeInvites, addAlert } = props
  const wasSending = usePrevious(employeeInvites.sending)

  useEffect(() => {
    if (wasSending && !employeeInvites.sending) {
      if (!employeeInvites.error) {
        addAlert('success', t('employee.sidebar.employee_invite.success'), { timeout: 5 })
      } else {
        addAlert('error', formatError(employeeInvites.error), { closable: true })
      }
    }
  }, [wasSending, employeeInvites, addAlert])

  const handleSendInvite = (e: React.MouseEvent) => {
    e.preventDefault()
    props.sendInvite(props.employee.id)
  }
  const handleDeleteEmployeeUser = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      props.deleteEmployeeUser(props.employee.id)
    }
  }

  const loadingEmployeeApp = employeeInvites.sending || employeeInvites.deleting

  return (
    <div className="employee-app">
      <FeatureLock featureType={'Employee App'} description={t('employee.sidebar.employee_app.feature_lock')}>
        {loadingEmployeeApp ? (
          <div style={{ position: 'relative' }}>
            <LoadingOverlay text={t('employee.sidebar.employee_app.loading')} />
          </div>
        ) : props.employee.hasUser ? (
          <Tooltip title={t('employee.sidebar.employee_app.remove_access')} overlayStyle={{ maxWidth: '170px' }}>
            <div onClick={handleDeleteEmployeeUser} className="employee-app-enabled">
              {t('employee.sidebar.employee_app.title')}
            </div>
          </Tooltip>
        ) : props.employee.invitationSent ? (
          <Lock isLocked={!props.employee.email} description={t('employee.sidebar.employee_app.lock')}>
            <Button onClick={handleSendInvite}>{t('employee.sidebar.employee_app.resend_invite')}</Button>
            <p style={{ fontSize: '9pt' }}>
              {t('employee.sidebar.employee_app.invite_sent', { at: formatDateTime(props.employee.invitationSent) })}
            </p>
          </Lock>
        ) : (
          <Lock isLocked={!props.employee.email} description={t('employee.sidebar.employee_app.lock')}>
            <Button onClick={handleSendInvite} type="secondary">
              {t('employee.sidebar.employee_app.invite')}
            </Button>
          </Lock>
        )}
      </FeatureLock>
    </div>
  )
}
