import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import { TimeRegistrationBulk } from '../../../api/time-registrations'
import Company from '../../../model/company'
import Employee from '../../../model/employee'
import LeaveType, { LeaveSubTypeName } from '../../../model/leaveType'
import TimeRegistration, { TimeRegistrationClass, TimeRegistrationMutableFields } from '../../../model/timeRegistration'
import { LeaveBalanceReducer } from '../../../reducers/leaveBalances'
import { TimeRegistrationReducer } from '../../../reducers/timeRegistrations'
import { paths } from '../../../routes'
import LeaveDurations from '../../../types/leave-duration'
import { formatDate } from '../../../utils/date-utils'
import { formatLeaveDuration, formatLeaveSubType, formatLeaveTypeName } from '../../../utils/format-utils'
import { formatDisplayNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Table from '../../antd/table'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Icon from '../../elements/Icon'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import Tooltip from '../../elements/tooltip'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import LeaveEdit from './LeaveEdit'

type Props = {
  company: Company
  employee: Employee
  timeRegistrations: TimeRegistrationReducer
  leaveBalances: LeaveBalanceReducer
  leaveTypes: List<LeaveType>
  canEditObjects: boolean
  canApproveObjects: boolean

  addAlert: addAlertSignature
  createTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  createTimeRegistrationBulk: (bulk: TimeRegistrationBulk) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  approveTimeRegistrations: (ids: string[]) => void
  unapproveTimeRegistrations: (ids: string[]) => void
  deleteTimeRegistration: (id: string) => void
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    type: TimeRegistrationClass
  ) => void
  getLeaveBalances: (companyID?: string, employeeID?: string) => void
}

export default function LeaveTab(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)

  const { employee, leaveBalances, getLeaveBalances } = props
  useEffect(() => {
    const employeeID = employee.id
    if (leaveBalances.employeeID !== employeeID || (!leaveBalances.loading && !leaveBalances.loaded)) {
      getLeaveBalances(undefined, employeeID)
    }
  })

  const setEditVisibility = (id: string | boolean) => {
    setModalKey((prev) => prev + 1)
    setEditing(id)
  }

  const { timeRegistrations } = props
  const previousTimeRegistrations = usePrevious(timeRegistrations)

  useEffect(() => {
    if (previousTimeRegistrations && previousTimeRegistrations.saving && !timeRegistrations.saving) {
      if (!timeRegistrations.error) {
        setEditVisibility(false)
      }
    }
  })

  const getLeaveTypeById = (id: string) => {
    return props.leaveTypes.find((type) => type.id === id)
  }

  type TimeRegistrationRow = {
    key: string
    id: string
    approved: boolean
    date: string
    description: string
    immutable: boolean
    deletable: boolean
    original: TimeRegistration
    category?: LeaveSubTypeName
  }

  const getTimeRegistrations = (): TimeRegistrationRow[] => {
    return props.timeRegistrations.timeRegistrations
      .filter((timeRegistration) => timeRegistration.class === 'Leave')
      .map((timeRegistration) => {
        const leaveType = getLeaveTypeById(timeRegistration.leaveTypeID!)!
        let description = formatLeaveTypeName(
          leaveType.name,
          false,
          props.employee.activeContract && props.employee.activeContract.remunerationType
        )
        if (timeRegistration.days !== 1) {
          let extra: string | undefined
          switch (timeRegistration.days) {
            case 1:
              extra = formatLeaveDuration(LeaveDurations.FULL_DAY).toLowerCase()
              break
            case 0.75:
              extra = formatLeaveDuration(LeaveDurations.THREE_QUARTERS_DAY).toLowerCase()
              break
            case 0.5:
              extra = formatLeaveDuration(LeaveDurations.HALF_DAY).toLowerCase()
              break
            case 0.25:
              extra = formatLeaveDuration(LeaveDurations.QUARTER_DAY).toLowerCase()
              break
            default:
              extra = t('unit.day_count', { count: timeRegistration.days ?? 0 })
              break
          }
          if (extra) {
            description = t('leave.tab.table.duration.format', { description, extra })
          }
        }
        return {
          key: timeRegistration.id,
          id: timeRegistration.id,
          approved: timeRegistration.approved,
          date: formatDate(timeRegistration.date),
          description,
          immutable: timeRegistration.immutable,
          deletable: leaveType.settledDeletable || !timeRegistration.immutable,
          original: timeRegistration,
          category: leaveType.leaveSubTypes.find((subType) => subType.id === timeRegistration.leaveSubTypeID)?.name,
        }
      })
      .toArray()
      .reduce((list: TimeRegistrationRow[], timeReg) => {
        if (!timeReg) {
          return list
        }
        list.push(timeReg)
        return list
      }, [])
  }

  const approve = (timeRegistrationID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => [...prev, timeRegistrationID])
      props.approveTimeRegistrations([timeRegistrationID])
    }
  }
  const unapprove = (timeRegistrationID: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      setApproving((prev) => prev.filter((id) => id !== timeRegistrationID))
      props.unapproveTimeRegistrations([timeRegistrationID])
    }
  }
  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      const registrations = getTimeRegistrations()
        .filter((reg) => !reg.approved)
        .map((reg) => reg.original)
      setApproving(registrations.map((reg) => reg.id))
      props.approveTimeRegistrations(registrations.map((reg) => reg.id))
    }
  }
  const remove = (timeRegistrationID: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, timeRegistrationID])
        props.deleteTimeRegistration(timeRegistrationID)
      }
    }
  }

  const columns = [
    {
      title: t('leave.tab.table.header.approved'),
      key: 'x1',
      className: 'time-registration-table-approved',
      render: (timeRegistration: TimeRegistrationRow) => {
        if (timeRegistration.approved) {
          return (
            <span>
              {t('leave.tab.table.approved.true')}
              {props.canApproveObjects && !timeRegistration.immutable && (
                <Tooltip title={t('leave.tab.table.approved.remove')}>
                  <span onClick={unapprove(timeRegistration.id)} style={{ cursor: 'pointer' }}>
                    <Icon type="cross" color="grey" />
                  </span>
                </Tooltip>
              )}
            </span>
          )
        }
        if (approving.indexOf(timeRegistration.id) !== -1) {
          return t('leave.tab.table.saving')
        }
        if (!props.canApproveObjects) {
          return t('leave.tab.table.approved.false')
        }
        return (
          <DumbLink onClick={approve(timeRegistration.id)} type="standard">
            {t('leave.tab.table.approved.approve')}
          </DumbLink>
        )
      },
    },
    { title: t('leave.tab.table.header.date'), dataIndex: 'date', key: 'date' },
    {
      title: t('leave.tab.table.header.description'),
      render: (timeRegistration: TimeRegistrationRow) => {
        if (timeRegistration.category) {
          return (
            <span>
              {timeRegistration.description}
              <br />
              <span style={{ fontStyle: 'italic', fontWeight: 400 }}>
                {formatLeaveSubType(timeRegistration.category)}
              </span>
            </span>
          )
        }
        return timeRegistration.description
      },
    },
    {
      title: '',
      key: 'x3',
      className: 'employee-table-actions',
      render: (timeRegistration: TimeRegistrationRow) => {
        if (deleting.indexOf(timeRegistration.id) !== -1) {
          return null
        }
        if (!props.canEditObjects) {
          return null
        }
        return (
          <div>
            {!timeRegistration.immutable && (
              <Tooltip title={t('leave.tab.table.actions.edit')}>
                <span onClick={() => setEditVisibility(timeRegistration.id)} style={{ cursor: 'pointer' }}>
                  <Icon type="edit" color="grey" />
                </span>
              </Tooltip>
            )}
            {timeRegistration.deletable && (
              <Tooltip title={t('leave.tab.table.actions.delete')}>
                <span onClick={remove(timeRegistration.id)} style={{ cursor: 'pointer' }}>
                  <Icon type="cross" color="grey" />
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]

  const hasUnapprovedTimeRegistrations = () => {
    return getTimeRegistrations().filter((reg) => !reg.approved).length > 0
  }

  const bulkDelete = () => {
    if (window.confirm(t('leave.tab.confirm.bulk_delete'))) {
      props.deleteTimeRegistrationBulk(undefined, props.employee.id, 'Leave')
    }
  }

  if (!props.leaveBalances.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (
    !props.employee.activeContract ||
    props.employee.activeEmployment.id !== props.employee.activeContract.employmentID
  ) {
    return <NoContractCard employee={props.employee} />
  }

  const timeRegistrationRows = getTimeRegistrations()
  const remunerationType = props.employee.activeContract && props.employee.activeContract.remunerationType
  const vacationLimit = (props.employee.vacationExcessLimit ?? props.company.vacationExcessLimit) || 0

  return (
    <Card className="employees-single-form">
      <TitleMenu>
        {timeRegistrationRows.length > 0 && props.canEditObjects && (
          <Button onClick={() => bulkDelete()} type="danger" style={{ marginRight: '10px' }}>
            <Icon type="cross" color="red" />
            {t('leave.tab.header.bulk_delete')}
          </Button>
        )}
        {props.canEditObjects && (
          <Button onClick={() => setEditVisibility(true)}>
            <Icon type="stopwatch" color="grey" />
            {t('leave.tab.header.add_new')}
          </Button>
        )}

        {hasUnapprovedTimeRegistrations() && props.canApproveObjects && (
          <Button onClick={approveAll} style={{ marginLeft: 20 }}>
            {t('leave.tab.header.approve_all')}
          </Button>
        )}
      </TitleMenu>
      <Title>
        {t('leave.tab.header.title')}
        <Link to={'/' + paths.LEAVE_REGISTRATION + '/calendar'} className={'subtitle-link'}>
          {t('leave.tab.header.link_to_calendar')}
        </Link>
      </Title>
      <Row>
        {props.leaveBalances.leaveBalances
          .filter(
            (leaveBalance) =>
              leaveBalance.employeeID === props.employee.id &&
              getLeaveTypeById(leaveBalance.leaveTypeID)?.unit === 'Days'
          )
          .map((leaveBalance) => {
            const leaveType = getLeaveTypeById(leaveBalance.leaveTypeID)!
            const used = formatDisplayNumber(leaveBalance.used + leaveBalance.registered)
            const hasFuture = leaveBalance.registeredFuture > 0
            const future = formatDisplayNumber(leaveBalance.registeredFuture)
            const left = formatDisplayNumber(leaveBalance.left)
            let borrowedNumber = 0
            const showBorrowed =
              leaveType.name === 'DenmarkVacationPaid' ||
              leaveType.name === 'DenmarkVacationPaidAdditional' ||
              leaveType.name === 'DenmarkVacationNoPay' ||
              leaveType.name === 'DenmarkVacationAccrual' ||
              leaveType.name === 'DenmarkVacationFund' ||
              leaveType.name === 'DenmarkVacationFundWithPension'

            if (showBorrowed && leaveBalance.left < 0 && vacationLimit > 0) {
              borrowedNumber = Math.abs(leaveBalance.left)
              if (borrowedNumber > vacationLimit) {
                borrowedNumber = vacationLimit
              }
            }
            const hasBorrowed = showBorrowed && borrowedNumber !== 0
            const borrowed = formatDisplayNumber(borrowedNumber)
            return (
              <Col key={leaveBalance.leaveTypeID} span={8}>
                {leaveType
                  ? formatLeaveTypeName(leaveType.name, true, remunerationType)
                  : t('leave.tab.balances.unknown')}
                :
                {leaveType && (
                  <>
                    <br />
                    <strong>
                      {leaveType && !leaveType.assignable
                        ? t(
                            hasFuture
                              ? 'leave.tab.balances.not_assignable.with_future'
                              : 'leave.tab.balances.not_assignable.standard',
                            { used, future }
                          )
                        : t(
                            hasFuture
                              ? hasBorrowed
                                ? 'leave.tab.balances.assignable.with_future_borrowed'
                                : 'leave.tab.balances.assignable.with_future'
                              : hasBorrowed
                              ? 'leave.tab.balances.assignable.with_borrowed'
                              : 'leave.tab.balances.assignable.standard',
                            { used, future, left, borrowed }
                          )}
                    </strong>
                  </>
                )}
              </Col>
            )
          })}
      </Row>
      <p>&nbsp;</p>

      <Table columns={columns} dataSource={timeRegistrationRows} size="small" pagination={false} />
      {props.timeRegistrations.saving && <LoadingOverlay />}

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <LeaveEdit
          visible={editing !== false}
          employee={props.employee}
          canApproveObjects={props.canApproveObjects}
          timeRegistrationID={typeof editing === 'string' ? editing : undefined}
          timeRegistrations={props.timeRegistrations}
          leaveTypes={props.leaveTypes}
          remunerationType={remunerationType}
          createTimeRegistration={props.createTimeRegistration}
          createTimeRegistrationBulk={props.createTimeRegistrationBulk}
          updateTimeRegistration={props.updateTimeRegistration}
        />
      </Modal>
    </Card>
  )
}
