import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import CompanySetting from '../../../model/companySetting'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import { SupplementDefinition } from '../../../model/remuneration'
import SupplementType from '../../../model/supplementType'
import { ContractReducer } from '../../../reducers/contracts'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate } from '../../../utils/date-utils'
import { isChoiceSupplement } from '../../../utils/employee-contract-utils'
import { getValidFrom } from '../../../utils/employment-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import EmploymentChoiceEditForm, { ResultFields } from './EmploymentChoiceEditForm'

type Props = {
  visible: boolean
  employee: Employee
  mutableContract: Contract
  contracts: ContractReducer
  settingsEnabled: CompanySetting[]
  supplementTypes: List<SupplementType>

  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
}

export default function EmploymentChoiceEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, contracts } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, contracts.error, error, setError)
  }, [visible, contracts, error])

  const handleSubmit = (values: ResultFields) => {
    const getSupplementType = (id: string) => props.supplementTypes.find((type) => type.id === id)

    const contract = { ...props.mutableContract }
    if (!contract.remuneration) {
      return // should not happen
    }
    contract.remuneration.supplements = contract.remuneration.supplements.filter(
      (row: SupplementDefinition) => !isChoiceSupplement(row.type?.name)
    )
    contract.remuneration.supplements = values.remuneration.supplements.reduce((supplements, row) => {
      const supplementType = getSupplementType(row.typeID || '')
      if (!supplementType || !isChoiceSupplement(supplementType.name)) {
        return supplements
      }
      supplements.push(row)
      return supplements
    }, contract.remuneration.supplements)
    const { validFrom, canUpdate } = getValidFrom(props.employee, contract)
    if (canUpdate) {
      props.updateContract(contract)
    } else {
      contract.validFrom = formatAPIDate(validFrom)
      props.addContract(contract)
    }
  }

  return (
    <Card>
      <Subtitle>{t('choice.edit.title')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <EmploymentChoiceEditForm
        mutableContract={props.mutableContract}
        validFrom={getValidFrom(props.employee, props.mutableContract).validFrom}
        employee={props.employee}
        contracts={props.contracts}
        settingsEnabled={props.settingsEnabled}
        supplementTypes={props.supplementTypes}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
