import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CompanyPricing from '../model/companyPricing'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyPricingReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  companyPricings: List<CompanyPricing>
  error: Error | null
}

const Params = Record<CompanyPricingReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  companyPricings: List<CompanyPricing>(),
  error: null,
})

export interface CompanyPricingAction extends ReducerAction {
  companyID?: string
  companyPricings?: CompanyPricing[]
}

export default (
  state: Record<CompanyPricingReducer> = Params(),
  action: CompanyPricingAction = { type: '' }
): Record<CompanyPricingReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.COMPANY_PRICINGS_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.COMPANY_PRICINGS_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        companyPricings: List<CompanyPricing>(action.companyPricings),
      })
    case ActionTypes.COMPANY_PRICINGS_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
