import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce } from 'react-use'

import {
  AccountingIntegrationActiveInformation,
  getCompanyAccountingIntegrationActiveInformation,
} from '../../api/accounting-integration'
import Company from '../../model/company'
import { paths } from '../../routes'
import { formatError, isRequestError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import LoadingOverlay from '../widgets/LoadingOverlay'

import './Accounting.css'

type Props = {
  company: Company
}

export default function AccountingActiveInformation(props: Props): ReactElement | null {
  const [loading, setLoading] = useState(true)
  const [information, setInformation] = useState<AccountingIntegrationActiveInformation>()
  const [error, setError] = useState<Error | null>(null)

  useEffectOnce(() => {
    getCompanyAccountingIntegrationActiveInformation(props.company.id)
      .then((res) => {
        setInformation(res.data)
      })
      .catch((e) => {
        if (isRequestError(e)) {
          setError(e)
        }
      })
      .finally(() => setLoading(false))
  })

  return (
    <Card>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <TitleMenu>
        <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING}>
          <Button>{t('accounting_tab.active_information.back')}</Button>
        </Link>
      </TitleMenu>
      <Title>{t('accounting_tab.active_information.title')}</Title>
      {information && (
        <>
          <p>{t('accounting_tab.active_information.intro')}:</p>
          <dl>
            <dt>{t('accounting_tab.active_information.field.external_id')}</dt>
            <dd>{information.externalID}</dd>
            <dt>{t('accounting_tab.active_information.field.company')}</dt>
            <dd>
              {information.company.name} (
              {t('accounting_tab.active_information.field.company.national_id', {
                national_id: information.company.nationalID,
              })}
              )<br />
              {information.company.address}
              <br />
              {information.company.postalCode} {information.company.city}
            </dd>
          </dl>
        </>
      )}

      {loading && <LoadingOverlay />}
    </Card>
  )
}
