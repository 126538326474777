import React, { ReactElement } from 'react'

import Employee from '../../../model/employee'
import { DepartmentReducer } from '../../../reducers/departments'
import { EmployeeReducer } from '../../../reducers/employees'
import { formatDate, getDate, isTimeAfter } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Select from '../../antd/select'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  validFrom: Date
  employee: Employee
  employees: EmployeeReducer
  departments: DepartmentReducer
  isDepartmentManager: boolean
}

export type EmploymentDetailFields = {
  departmentID?: string
}

function EmploymentDetailsEditForm(
  props: Props & FormComponentProps<EmploymentDetailFields, EmploymentDetailFields>
): ReactElement | null {
  const showCombobox = () => {
    if (!props.employee.departmentID) {
      return true
    }
    return (
      props.employee.departmentID !== props.getFieldValue('departmentID') &&
      !props.departments.departments.some((department) => department.id === props.getFieldValue('departmentID'))
    )
  }

  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        {!props.isDepartmentManager && (
          <Col span={12}>
            {decorateField('departmentID', {
              title: t('freelancer.details.edit.department_id'),
              placeholder: t('freelancer.details.edit.department_id.placeholder'),
            })(
              <Select
                mode={showCombobox() ? 'combobox' : ''}
                dropdownMatchSelectWidth={false}
                optionLabelProp="title"
                filterOption={(input: string, option: ReactElement) =>
                  option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {!showCombobox() && (
                  <Select.Option key={''} value={''} title={''}>
                    <i>{t('freelancer.details.edit.department_id.none_or_new')}</i>
                  </Select.Option>
                )}
                {props.departments.departments
                  .filter((department) => department.active)
                  .map((department) => {
                    return (
                      <Select.Option key={department.id} value={department.id} title={department.name}>
                        {department.name}
                      </Select.Option>
                    )
                  })}
              </Select>
            )}
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div className="ant-form-warning">
            {isTimeAfter(props.validFrom, getDate())
              ? t('freelancer.details.edit.valid_from.at_date', { date: formatDate(props.validFrom) })
              : t('freelancer.details.edit.valid_from.now')}
          </div>
        </Col>
      </Row>
      {(props.departments.saving || props.employees.saving) && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, EmploymentDetailFields, EmploymentDetailFields>({
  mapPropsToFields: (props) => ({
    departmentID: props.employee.departmentID,
  }),
  onSubmit: (values) => values,
})(EmploymentDetailsEditForm)
