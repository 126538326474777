import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce, usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import { getStagedImportData, getStagedImportResult } from '../../api/data'
import ApplyResult from '../../model/applyResult'
import AsynchronousTask from '../../model/asynchronousTask'
import Employee from '../../model/employee'
import { OneTimePayType } from '../../model/oneTimePay'
import { StagedImportData } from '../../model/stagedImport'
import { AsynchronousTaskReducer } from '../../reducers/asynchronousTasks'
import { FileChangeEvent } from '../../utils/antd-utils'
import { getAccessToken } from '../../utils/cookie-utils'
import { compareError, convertStoredErrorToError, formatError } from '../../utils/error-utils'
import { formatOneTimePayCategory, formatOneTimePayType } from '../../utils/format-utils'
import { formatCurrency } from '../../utils/number-utils'
import { RequestError, secureUrl, url } from '../../utils/request-utils'
import { formatEmployeeBatchMessage, formatEmployeeBatchStatus } from '../../utils/staged-import-utils'
import { t } from '../../utils/translation-utils'
import UploadDragger from '../antd/upload/Dragger'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/Icon'
import Steps from '../elements/steps'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  visible: boolean
  companyID: string
  asynchronousTasks: AsynchronousTaskReducer
  employees: List<Employee>
  oneTimePayType?: OneTimePayType

  addAlert: addAlertSignature
  startOneTimePaysImport: (fileID: string, oneTimePayType?: OneTimePayType) => Promise<AsynchronousTask | void>
  storeStagedImportData: (asynchronousTaskID: string) => Promise<AsynchronousTask | void>
  closeModal: () => void
}

export default function ImportOneTimePaysModal({
  companyID,
  asynchronousTasks,
  employees,
  oneTimePayType,
  addAlert,
  startOneTimePaysImport,
  storeStagedImportData,
  closeModal,
}: Props): ReactElement | null {
  const [step, setStep] = useState<number>(0)
  const [uploading, setUploading] = useState<boolean>(false)
  const [importing, setImporting] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const [taskID, setTaskID] = useState<string | null>(null)
  const [importData, setImportData] = useState<StagedImportData | null>(null)
  const [importResult, setImportResult] = useState<ApplyResult[] | null>(null)

  const asynchronousTasksList = asynchronousTasks.asynchronousTasks

  useEffectOnce(() => {
    // find a task
    const task = asynchronousTasksList.find(
      (task) => task.type === 'OneTimePaysImportGather' && task.status === 'Success'
    )
    if (task) {
      // check if its save call has finished
      if (
        !asynchronousTasksList.find(
          (newTask) =>
            newTask.type === 'StagedDataSave' &&
            newTask.status === 'Success' &&
            newTask.originalAsynchronousTaskID === task.id
        )
      ) {
        getStagedImportData(task.id)
          .then((res) => {
            setTaskID(task.id)
            setStep(2)
            setImportData(res.data)
          })
          .catch((e: RequestError) => setError(e))
      }
    }
  })

  const asyncError = asynchronousTasks.error

  useEffect(() => {
    if (asyncError && step === 2) {
      setStep(1)
    }
  }, [asyncError, step, setStep])

  const task = asynchronousTasksList.find((task) => task.id === taskID)
  const prevError = usePrevious(error)

  useEffect(() => {
    if (task) {
      if (task.type !== 'OneTimePaysImportGather' && task.type !== 'StagedDataSave') {
        return
      }
      if (importing && (task.status === 'Failed' || task.status === 'Success')) {
        setImporting(false)
      }
      const newError = convertStoredErrorToError(task.error)
      if (!compareError(prevError || null, newError)) {
        setError(newError)
      }
      switch (task.type) {
        case 'OneTimePaysImportGather':
          if (task.status === 'Success' && !importData) {
            getStagedImportData(task.id).then((res) => {
              setImportData(res.data)
              setStep(2)
            })
          }
          break
        case 'StagedDataSave':
          if (task.status === 'Success' && !importResult) {
            getStagedImportResult(task.id).then((res) => {
              setImportResult(res.data)
              setStep(3)
            })
          }
          break
      }
    }
  }, [
    task,
    importing,
    prevError,
    importData,
    importResult,
    setImporting,
    setError,
    setImportData,
    setImportResult,
    setStep,
  ])

  const getDownloadUrl = (): string => {
    let url = '/v2/oneTimePays/Excel'
    url += '?companyID=' + companyID
    if (oneTimePayType) {
      url += '&oneTimePayType=' + oneTimePayType
    }
    url += '&disposition=inline'
    return secureUrl(url)
  }

  const getEmployeeName = (id: string): string => {
    const employee = employees.find((employee) => employee.id === id)
    if (employee) {
      return employee.name || employee.email || '-'
    }
    return t('common.unknown')
  }

  type OneTimePayRow = {
    key: string
    title: string
    type: string
    category: string
    amount: number
    name: string
  }

  const getOneTimePays = (): OneTimePayRow[] => {
    if (!importData) {
      return []
    }
    const employees = importData.companies[0].employees
    const rows: OneTimePayRow[] = []
    employees.forEach((employee) => {
      if (!employee.employeeID) {
        return // do nothing
      }
      const employeeName = getEmployeeName(employee.employeeID)
      employee.oneTimePays.forEach((otp) => {
        rows.push({
          key: `${employee.employeeID}-otp-${otp.amount}`,
          title: `${otp.title}`,
          type: formatOneTimePayType(otp.type),
          category: otp.category ? formatOneTimePayCategory(otp.category) : 'Ingen',
          amount: otp.amount,
          name: employeeName,
        })
      })
    })
    return rows
  }

  type ResultRow = {
    key: string
    nationalID: string
    name: string
    state: string
    stateMessage: string
  }

  const getResult = (): ResultRow[] => {
    if (!importResult || !importData) {
      return []
    }
    if (importResult.length === 0 || importData.companies.length === 0) {
      return []
    }
    return importResult[0].messages.map((message, i): ResultRow => {
      const employee = employees.find((employee) => employee.id === message.employeeID)
      if (employee && employee.nationalID) {
        return {
          key: employee.nationalID,
          nationalID: employee.nationalID,
          name: employee.name || employee.email || '-',
          state: formatEmployeeBatchStatus(message.state),
          stateMessage: formatEmployeeBatchMessage(message.state, message.message, message.details),
        }
      }
      const employeeData = importData.companies[0].employees[i]
      return {
        key: employeeData.nationalID,
        nationalID: employeeData.nationalID,
        name: employeeData.name || employeeData.email || '',
        state: formatEmployeeBatchStatus(message.state),
        stateMessage: formatEmployeeBatchMessage(message.state, message.message, message.details),
      }
    })
  }

  const handleFileUpload = (e: FileChangeEvent) => {
    switch (e.file.status) {
      case 'uploading':
        setUploading(true)
        break
      case 'done':
        setUploading(false)
        setImportData(null)
        setTaskID(null)
        setImporting(true)
        if (e.file.response.data) {
          startOneTimePaysImport(e.file.response.data.id, oneTimePayType).then((res) => {
            if (!res) {
              return
            }
            setTaskID(res.id)
          })
        }
        break
      default:
        break
    }
  }

  const handleFileStore = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!task) {
      return
    }

    setImportResult(null)
    setTaskID(null)
    setImporting(true)
    storeStagedImportData(task.id).then((res) => {
      if (!res) {
        return
      }
      setTaskID(res.id)
      if (oneTimePayType) {
        addAlert('success', t('one_time_pays.import.alert.success.fees'), { timeout: 5 })
      } else {
        addAlert('success', t('one_time_pays.import.alert.success.standard'), { timeout: 5 })
      }
    })
  }

  const getErrors = () => {
    if (!importData) {
      return []
    }
    const errors = importData.errors
    if (!errors) {
      return []
    }
    return errors.map((error) => error.message)
  }

  const errors = getErrors()
  return (
    <Card className="one-time-pays-import">
      <Title>{t('one_time_pays.import.title')}</Title>
      <Steps current={step}>
        <Steps.Step title={t('one_time_pays.import.steps.0')} />
        <Steps.Step title={t('one_time_pays.import.steps.1')} />
        <Steps.Step title={t('one_time_pays.import.steps.2')} />
        <Steps.Step title={t('one_time_pays.import.steps.3')} />
      </Steps>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <p>&nbsp;</p>
      {step === 0 && (
        <div>
          <p>{t('one_time_pays.import.step_0.intro')}</p>
          <Link to={getDownloadUrl()} target="_blank" rel="noopener noreferrer" onClick={() => setStep(1)}>
            <Button type="secondary" className="gtm-download-otp-excel">
              <Icon type="download" />
              {t('one_time_pays.import.step_0.buttons.download')}
            </Button>
          </Link>
          <Button size="large" style={{ marginLeft: 20 }} onClick={() => setStep(1)}>
            {t('one_time_pays.import.step_0.buttons.continue')}
          </Button>
        </div>
      )}
      {step === 1 && (
        <div>
          {importing && <div>{t('one_time_pays.import.step_1.importing')}</div>}
          {!importing && (
            <div>
              <p>{t('one_time_pays.import.step_1.intro')}</p>
              <div style={{ float: 'left' }}>
                <UploadDragger
                  name={'fileData'}
                  action={url('v2/stagedFiles')}
                  headers={{ authorization: getAccessToken() }}
                  accept={'.xlsx'}
                  showUploadList={false}
                  onChange={handleFileUpload}
                >
                  <Button type="secondary" className="gtm-upload-otp-excel">
                    <Icon type="upload" />
                    {t('one_time_pays.import.step_1.buttons.upload')}
                  </Button>
                </UploadDragger>
              </div>
              <Button size="large" style={{ marginLeft: 20 }} onClick={() => setStep(0)}>
                {t('one_time_pays.import.step_1.buttons.back')}
              </Button>
              {(uploading || asynchronousTasks.saving) && <LoadingOverlay />}
            </div>
          )}
        </div>
      )}
      {step === 2 && (
        <div>
          {importing && <div>{t('one_time_pays.import.step_2.importing')}</div>}
          {!importing && (
            <div className="preview-table">
              <div style={{ marginBottom: 20 }}>
                <Button onClick={handleFileStore} type="secondary" className="gtm-submit-otp-excel">
                  {oneTimePayType
                    ? t('one_time_pays.import.step_2.buttons.save.fee')
                    : t('one_time_pays.import.step_2.buttons.save.standard')}
                </Button>
                <Button size="large" style={{ marginLeft: 20 }} onClick={() => setStep(1)}>
                  {t('one_time_pays.import.step_2.buttons.back')}
                </Button>
              </div>
              {errors.length > 0 && (
                <div className="errors">
                  {errors.map((error: string, i: number) => {
                    return <Alert key={i} message={error} type="error" showIcon />
                  })}
                </div>
              )}
              <Row>
                <Col span={oneTimePayType ? 8 : 4}>
                  <strong>{t('one_time_pays.import.step_2.result.header.employee')}</strong>
                </Col>
                {!oneTimePayType && (
                  <Col span={5}>
                    <strong>{t('one_time_pays.import.step_2.result.header.type')}</strong>
                  </Col>
                )}
                {!oneTimePayType && (
                  <Col span={5}>
                    <strong>{t('one_time_pays.import.step_2.result.header.category')}</strong>
                  </Col>
                )}
                <Col span={oneTimePayType ? 6 : 3}>
                  <strong>{t('one_time_pays.import.step_2.result.header.amount')}</strong>
                </Col>
                <Col span={oneTimePayType ? 8 : 5}>
                  <strong>{t('one_time_pays.import.step_2.result.header.title')}</strong>
                </Col>
              </Row>
              {getOneTimePays().map((otp) => {
                return (
                  <Row key={otp.key}>
                    <Col span={oneTimePayType ? 8 : 4}>{otp.name}</Col>
                    {!oneTimePayType && <Col span={5}>{otp.type}</Col>}
                    {!oneTimePayType && <Col span={5}>{otp.category}</Col>}
                    <Col span={oneTimePayType ? 6 : 3}>{formatCurrency(otp.amount, 2)}</Col>
                    <Col span={oneTimePayType ? 8 : 5}>{otp.title}</Col>
                  </Row>
                )
              })}
            </div>
          )}
          {asynchronousTasks.saving && <LoadingOverlay />}
        </div>
      )}
      {step === 3 && (
        <div className="preview-table">
          {(!importResult || importResult.length === 0) && (
            <div>
              {oneTimePayType
                ? t('one_time_pays.import.step_3.success.fee')
                : t('one_time_pays.import.step_3.success.standard')}
            </div>
          )}
          {importResult && importResult.length > 0 && (
            <div>
              <Button style={{ marginBottom: 20 }} onClick={closeModal} type="secondary" className="gtm-done-otps">
                {t('one_time_pays.import.step_3.buttons.done')}
              </Button>
              <Row>
                <Col span={5}>
                  <strong>{t('one_time_pays.import.step_3.result.header.employee')}</strong>
                </Col>
                <Col span={8}>
                  <strong>{t('one_time_pays.import.step_3.result.header.state')}</strong>
                </Col>
                <Col span={8}>
                  <strong>{t('one_time_pays.import.step_3.result.header.state_message')}</strong>
                </Col>
              </Row>
              {getResult().map((result) => {
                return (
                  <Row key={result.key}>
                    <Col span={5}>{result.name}</Col>
                    <Col span={8}>{result.state}</Col>
                    <Col span={8}>{result.stateMessage}</Col>
                  </Row>
                )
              })}
            </div>
          )}
        </div>
      )}
    </Card>
  )
}
