import React, { ReactElement } from 'react'

import { TaxCardReducer } from '../../../reducers/taxCards'
import { getDate } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import DatePicker from '../../elements/date-picker'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  taxCardID?: string
  taxCards: TaxCardReducer
}

export type Fields = {
  validFrom: Date
}

function EditModalForm(props: Props & FormComponentProps<Fields, Fields>): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('validFrom', {
            placeholder: t('tax_card.tab.edit.form.valid_from'),
            validate: (val) => {
              if (!val) {
                return t('tax_card.tab.edit.form.valid_from.required')
              }
              return null
            },
          })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.taxCards.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, Fields>({
  mapPropsToFields: (props: Props): Fields => {
    const fields = { validFrom: getDate() }
    props.taxCards.taxCards.forEach((taxCard) => {
      if (!taxCard) {
        return
      }
      if (taxCard.id === props.taxCardID) {
        fields.validFrom = getDate(taxCard.validFromDate)
      }
    })
    return fields
  },
  onSubmit: (values) => values,
})(EditModalForm)
