import Asset, { AssetMutableFields } from '../model/asset'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function postAsset(asset: AssetMutableFields): Promise<RequestResponse<Asset>> {
  return secureRequest('POST', url('/v2/assets'), {
    body: {
      employeeID: asset.employeeID,
      title: asset.title,
      description: asset.description,
      note: asset.note,
      categoryID: asset.categoryID,
      receivedDate: asset.receivedDate,
      returnDate: asset.returnDate,
      hidden: asset.hidden,
    },
  })
}

export function patchReturnAsset(assetID: string): Promise<RequestResponse<Asset>> {
  return secureRequest('PATCH', url('/v2/assets/' + assetID), {
    body: {
      operation: 'return',
    },
  })
}

export function patchReceiveAsset(assetID: string): Promise<RequestResponse<Asset>> {
  return secureRequest('PATCH', url('/v2/assets/' + assetID), {
    body: {
      operation: 'receive',
    },
  })
}

export function patchHideAsset(assetID: string): Promise<RequestResponse<Asset>> {
  return secureRequest('PATCH', url('/v2/assets/' + assetID), {
    body: {
      operation: 'hide',
    },
  })
}

export function putAsset(asset: AssetMutableFields): Promise<RequestResponse<Asset>> {
  return secureRequest('PUT', url('/v2/assets/' + asset.id), {
    body: {
      id: asset.id,
      employeeID: asset.employeeID,
      title: asset.title,
      description: asset.description,
      note: asset.note,
      categoryID: asset.categoryID,
      receivedDate: asset.receivedDate,
      returnDate: asset.returnDate,
      hidden: asset.hidden,
    },
  })
}

export function deleteAssetRequest(assetID: string): Promise<RequestResponse<Asset>> {
  return secureRequest('DELETE', url('/v2/assets/' + assetID), {
    body: {},
  })
}

export function fetchAssets(companyID: string, limit: number, offset: number): Promise<RequestResponse<Asset[]>> {
  return secureRequest('GET', url('/v2/assets', { companyID, limit, offset }))
}
