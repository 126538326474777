import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import ContractDelta from '../../model/contractDelta'
import ContractTemplate, { ContractTemplateMutableFields } from '../../model/contractTemplate'
import ContractTemplateContract, { ContractTemplateContractMutableFields } from '../../model/contractTemplateContract'
import Department from '../../model/department'
import Employee from '../../model/employee'
import EmploymentPosition from '../../model/employmentPosition'
import PensionCompany from '../../model/pensionCompany'
import SalaryCycle from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import { AlertReducer } from '../../reducers/alerts'
import { ContractTemplateContractReducer } from '../../reducers/contractTemplateContracts'
import { ContractTemplateReducer } from '../../reducers/contractTemplates'
import { EmployeeContractDeltaReducer } from '../../reducers/employeeContractDeltas'
import { paths } from '../../routes'
import ContractTemplateAdd from './ContractTemplateAdd'
import ContractTemplateEdit from './ContractTemplateEdit'
import ContractTemplatesOverview from './ContractTemplatesOverview'

import './ContractTemplates.css'

type Props = {
  subsection?: string
  alerts: AlertReducer
  contractTemplates: ContractTemplateReducer
  contractTemplateContracts: ContractTemplateContractReducer
  company: Company
  employees: List<Employee>
  employeeContractDeltas: EmployeeContractDeltaReducer
  employmentPositions: List<EmploymentPosition>
  departments: List<Department>
  salaryCycles: List<SalaryCycle>
  salaryTypes: List<SalaryType>
  pensionCompanies: List<PensionCompany>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getRemuneration: (contractID: string) => void
  getContractDeltas: (employeeID: string) => Promise<ContractDelta[] | void>
  createContractTemplate: (contractTemplate: ContractTemplateMutableFields) => Promise<ContractTemplate | void>
  updateContractTemplate: (contractTemplate: ContractTemplate) => Promise<ContractTemplate | void>
  deleteContractTemplate: (contractTemplateID: string) => void
  getContractTemplateContracts: (contractTemplateID: string) => void
  createContractTemplateContract: (
    contractTemplateContract: ContractTemplateContractMutableFields
  ) => Promise<ContractTemplateContract | void>
  updateContractTemplateContract: (contractTemplateContract: ContractTemplateContract) => void
  deleteContractTemplateContract: (contractTemplateContractID: string) => void
}

export default function ContractTemplates(props: Props): ReactElement | null {
  // NEED_NO_TRANSLATION
  const subsection = props.subsection
  return (
    <div>
      {subsection === paths.ADD && (
        <ContractTemplateAdd
          alerts={props.alerts}
          company={props.company}
          contractTemplates={props.contractTemplates}
          contractTemplateContracts={props.contractTemplateContracts}
          departments={props.departments}
          employees={props.employees}
          employeeContractDeltas={props.employeeContractDeltas}
          employmentPositions={props.employmentPositions}
          salaryCycles={props.salaryCycles}
          salaryTypes={props.salaryTypes}
          pensionCompanies={props.pensionCompanies}
          addAlert={props.addAlert}
          removeAlert={props.removeAlert}
          getRemuneration={props.getRemuneration}
          getContractDeltas={props.getContractDeltas}
          createContractTemplate={props.createContractTemplate}
          createContractTemplateContract={props.createContractTemplateContract}
        />
      )}
      {!!subsection && subsection !== paths.ADD && (
        <ContractTemplateEdit
          contractTemplateID={subsection}
          alerts={props.alerts}
          contractTemplates={props.contractTemplates}
          contractTemplateContracts={props.contractTemplateContracts}
          departments={props.departments}
          employmentPositions={props.employmentPositions}
          salaryCycles={props.salaryCycles}
          salaryTypes={props.salaryTypes}
          pensionCompanies={props.pensionCompanies}
          addAlert={props.addAlert}
          removeAlert={props.removeAlert}
          getContractTemplateContracts={props.getContractTemplateContracts}
          updateContractTemplate={props.updateContractTemplate}
          deleteContractTemplate={props.deleteContractTemplate}
        />
      )}
      {!subsection && (
        <ContractTemplatesOverview
          alerts={props.alerts}
          contractTemplates={props.contractTemplates}
          employees={props.employees}
          removeAlert={props.removeAlert}
          deleteContractTemplate={props.deleteContractTemplate}
        />
      )}
    </div>
  )
}
