import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import IncomeTaxReport from '../model/incomeTaxReport'
import { ReducerAction } from '../utils/reducer-utils'

export interface IncomeTaxReportReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  payRollID: string | undefined
  loading: boolean
  loaded: boolean
  incomeTaxReports: List<IncomeTaxReport>
  error: Error | null
}

const Params = Record<IncomeTaxReportReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  payRollID: undefined,
  loading: false,
  loaded: false,
  incomeTaxReports: List<IncomeTaxReport>(),
  error: null,
})

export interface IncomeTaxReportAction extends ReducerAction {
  companyID?: string
  payRollID?: string
  incomeTaxReports?: IncomeTaxReport[]
  incomeTaxReport?: IncomeTaxReport
  incomeTaxReportID?: string
}

export default (
  state: Record<IncomeTaxReportReducer> = Params(),
  action: IncomeTaxReportAction = { type: '' }
): Record<IncomeTaxReportReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if ('companyID' in action && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.INCOME_TAX_REPORTS_LOADING:
      return Params({
        loading: true,
        companyID: state.get('companyID'),
        payRollID: action.payRollID || state.get('payRollID'),
      })
    case ActionTypes.INCOME_TAX_REPORTS_LOADED:
    case ActionTypes.INCOME_TAX_REPORTS_LOADED_PARTIAL:
      return Params({
        loaded: true,
        loading: action.type === ActionTypes.INCOME_TAX_REPORTS_LOADED_PARTIAL,
        companyID: state.get('companyID'),
        payRollID: action.payRollID || state.get('payRollID'),
        incomeTaxReports: state.get('incomeTaxReports').concat(List(action.incomeTaxReports)),
      })
    case ActionTypes.INCOME_TAX_REPORTS_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        payRollID: action.payRollID || state.get('payRollID'),
        error: action.error || null,
      })

    case ActionTypes.INCOME_TAX_REPORT_ADDED:
      if (!action.incomeTaxReport) {
        return state
      }
      // only process actions for the active pay roll
      if (state.get('payRollID') && action.payRollID !== state.get('payRollID')) {
        return state
      }
      idx = state
        .get('incomeTaxReports')
        .findIndex((item) => !!action.incomeTaxReport && item.id === action.incomeTaxReport.id)
      if (idx !== -1) {
        return state.set('incomeTaxReports', state.get('incomeTaxReports').set(idx, action.incomeTaxReport))
      }
      return state.set('incomeTaxReports', state.get('incomeTaxReports').push(action.incomeTaxReport))
    case ActionTypes.INCOME_TAX_REPORT_UPDATED:
      if (!action.incomeTaxReport) {
        return state
      }
      idx = state.get('incomeTaxReports').findIndex((item) => item.id === action.incomeTaxReportID)
      if (idx === -1) {
        return state
      }
      return state.set('incomeTaxReports', state.get('incomeTaxReports').set(idx, action.incomeTaxReport))
    case ActionTypes.INCOME_TAX_REPORT_DELETED:
      idx = state.get('incomeTaxReports').findIndex((item) => item.id === action.incomeTaxReportID)
      if (idx === -1) {
        return state
      }
      return state.set('incomeTaxReports', state.get('incomeTaxReports').delete(idx))

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
