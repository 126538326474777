import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { CostCenterAccounting } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Employee from '../../../model/employee'
import { OneTimePayCreationFields, OneTimePayMutableFields, OneTimePayType } from '../../../model/oneTimePay'
import SalaryCycle from '../../../model/salaryCycle'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { figureGrossPayReductionType } from '../../../utils/benefit-utils'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import SalaryReductionEditForm, { SalaryReductionResult } from './SalaryReductionEditForm'

type Props = {
  visible: boolean
  editing: boolean
  canApproveObjects: boolean
  employee?: Employee
  isFreelancer: boolean
  company: Company
  oneTimePayID?: string
  oneTimePays: OneTimePayReducer
  salaryCycle: SalaryCycle
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>

  addOneTimePay: (id: string, oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (id: string, oneTimePay: OneTimePayMutableFields) => void
}

export default function SalaryReductionEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, oneTimePays } = props
  useEffect(() => {
    visibleComponentDidUpdate(visible, oneTimePays.error, error, setError)
  }, [visible, oneTimePays, error])

  const handleSubmit = (values: SalaryReductionResult) => {
    if (!props.editing || !props.employee) {
      return
    }
    let type: OneTimePayType = 'Gross Pay Reduction'
    if (values.useBIncome) {
      if (values.useBIncomeAM) {
        type = 'B Income Reduction'
      } else {
        type = 'B Income Reduction No AM'
      }
    } else {
      type = figureGrossPayReductionType(values.withVacation, values.withPension)
    }
    const oneTimePay: OneTimePayCreationFields = {
      employeeID: props.employee.id,
      type,
      dispositionDate: values.dispositionDate,
      amount: values.amount,
      rate: values.rate,
      units: values.units,
      title: values.title,
      costCenterID: values.costCenterID,
      accountingText: values.accountingText,
      approved: values.approved,
    }
    if (props.oneTimePayID) {
      props.updateOneTimePay(props.employee.id, { ...oneTimePay, id: props.oneTimePayID })
    } else {
      props.addOneTimePay(props.employee.id, oneTimePay)
    }
  }

  if (!props.employee) {
    return null
  }
  return (
    <Card className="reimbursement-modal">
      <Subtitle>
        {props.oneTimePayID
          ? props.editing
            ? t('salary_reduction.edit.title.edit')
            : t('salary_reduction.edit.title.view')
          : t('salary_reduction.edit.title.create')}
      </Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <SalaryReductionEditForm
        editing={props.editing}
        canApproveObjects={props.canApproveObjects}
        employee={props.employee}
        isFreelancer={props.isFreelancer}
        company={props.company}
        oneTimePayID={props.oneTimePayID}
        oneTimePays={props.oneTimePays}
        salaryCycle={props.salaryCycle}
        costCenterAccounting={props.costCenterAccounting}
        costCenters={props.costCenters}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
