import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company, { CompanyMutableFields } from '../../model/company'
import { CompanyReducer } from '../../reducers/companies'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'
import ProductionUnitsFormWrapper, { ProductionUnitResult } from './ProductionUnitsFormWrapper'

type Props = {
  company: Company
  companies: CompanyReducer

  addAlert: addAlertSignature
  updateCompany: (company: CompanyMutableFields) => void
}

export default function ProductionUnitsTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { companies } = props

  useEffect(() => {
    regularComponentDidUpdate(companies.error, error, setError)
  }, [companies, error])

  const { addAlert, company } = props
  const previousCompanies = usePrevious(companies)

  useEffect(() => {
    // Check for save callback
    if (previousCompanies && previousCompanies.saving && !companies.saving) {
      // Check for no error occurred
      if (!companies.error) {
        addAlert('success', t('production_units.alert.success', { name: company.name }), { timeout: 5 })
      }
    }
  })

  const handleSubmit = (values: ProductionUnitResult) => {
    props.updateCompany({ ...props.company, productionUnits: values.productionUnits })
  }

  return (
    <Card>
      <Title>{t('production_units.title')}</Title>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <ProductionUnitsFormWrapper company={props.company} companies={props.companies} onSubmit={handleSubmit} />
      {props.companies.saving && <LoadingOverlay />}
    </Card>
  )
}
