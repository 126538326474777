import { isSameDay } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import AccountingDimension from '../../../model/accountingDimension'
import Company, { ProductionUnit } from '../../../model/company'
import CompanyUser from '../../../model/companyUser'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Department, { DepartmentCreationFields } from '../../../model/department'
import Employee from '../../../model/employee'
import EmployeeDimension, { EmployeeDimensionMutableFields } from '../../../model/employeeDimension'
import Employment from '../../../model/employment'
import EmploymentPosition from '../../../model/employmentPosition'
import SalaryCycle from '../../../model/salaryCycle'
import { ContractReducer } from '../../../reducers/contracts'
import { DepartmentReducer } from '../../../reducers/departments'
import { EmployeeReducer } from '../../../reducers/employees'
import { EmploymentReducer } from '../../../reducers/employments'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate, getDate } from '../../../utils/date-utils'
import { findDepartmentID } from '../../../utils/department-utils'
import { getValidFrom } from '../../../utils/employment-utils'
import { formatError } from '../../../utils/error-utils'
import { getDepartmentsWithPermission, isDepartmentRestricted } from '../../../utils/permissions-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import EmploymentDetailsEditForm, { EmploymentDetailResult } from './EmploymentDetailsEditForm'

type Props = {
  companyUser?: CompanyUser
  visible: boolean
  company: Company
  employee: Employee
  activeEmployment: Employment
  productionUnits: ProductionUnit[]
  employees: EmployeeReducer
  employeeDimensions: List<EmployeeDimension>
  accountingDimensions: List<AccountingDimension>
  hasDimensionValues: boolean
  mutableContract: Contract
  isFutureContract: boolean
  contracts: ContractReducer
  departments: DepartmentReducer
  employments: EmploymentReducer
  salaryCycle: SalaryCycle
  employmentPositions: List<EmploymentPosition>
  isMutableContract: boolean

  updateEmployee: (employee: Employee) => void
  updateEmployment: (employeeID: string, employment: Employment) => void
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
  updateEmployeeDimensions: (employeeID: string, dimensions: EmployeeDimensionMutableFields[]) => void
}

export default function EmploymentDetailsEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, contracts, employments, employees } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, contracts.error || employments.error || employees.error, error, setError)
  }, [visible, contracts, employments, employees, error])

  const handleSubmit = (values: EmploymentDetailResult) => {
    const employee = {
      ...props.employee,
      departmentID: findDepartmentID(props.departments.departments.toArray(), values.departmentID || undefined),
    }
    if (employee.departmentID) {
      const promise = new Promise<string | void>((resolve, reject) => {
        if (props.departments.departments.findIndex((item) => item.id === employee.departmentID) === -1) {
          props
            .addDepartment({ name: employee.departmentID! })
            .then((data) => {
              if (!data) {
                return
              }
              resolve(data.id)
            })
            .catch(reject)
        } else {
          resolve()
        }
      })
      promise.then((departmentID) => {
        if (departmentID) {
          employee.departmentID = departmentID
        }
        props.updateEmployee(employee)
      })
    } else {
      employee.departmentID = undefined
      props.updateEmployee(employee)
    }
    if (!props.isMutableContract) {
      return // only update employee if not mutable contract
    }
    const contract = {
      ...props.mutableContract,
      position: values.position,
      productionUnitID: values.productionUnitID,
      familyLeaveFund: values.familyLeaveFund,
    }
    if (values.employmentPositionID && Array.isArray(values.employmentPositionID)) {
      if (values.employmentPositionID.length >= 2) {
        contract.employmentPositionID = values.employmentPositionID[1]
      } else if (values.employmentPositionID.length === 0) {
        contract.employmentPositionID = undefined
      }
    }
    const validFrom = getDate(values.validFrom)
    if (!props.employee.immutableEndDate || isSameDay(validFrom, getDate(contract.validFrom))) {
      if (!props.employee.immutableEndDate) {
        contract.validFrom = values.validFrom
      }
      props.updateContract(contract)
    } else {
      contract.validFrom = formatAPIDate(validFrom)
      props.addContract(contract)
    }
    props.updateEmployment(props.employee.id, {
      ...props.activeEmployment,
      startDate: values.employmentStartDate,
      incomeType: values.incomeType,
    })
    if (props.hasDimensionValues && values.dimensions && values.dimensions.length > 0) {
      props.updateEmployeeDimensions(
        props.employee.id,
        values.dimensions.reduce((list: EmployeeDimensionMutableFields[], dimension) => {
          const accountingDimension = props.accountingDimensions.find(
            (accountingDimension) => accountingDimension.id === dimension.dimensionID
          )
          if (accountingDimension) {
            if (dimension.dimensionValueID !== '') {
              const dimensionValue = accountingDimension.values.find((value) => value.id === dimension.dimensionValueID)
              if (dimensionValue) {
                list.push({ dimensionID: accountingDimension.id, dimensionValueID: dimension.dimensionValueID })
              } else if (accountingDimension.valuesMutable) {
                // new value
                list.push({ dimensionID: accountingDimension.id, dimensionValueCode: dimension.dimensionValueID })
              }
            } else {
              // unset
              list.push({ dimensionID: accountingDimension.id })
            }
          }
          return list
        }, [])
      )
    }
    if (!props.hasDimensionValues) {
      props.updateEmployeeDimensions(props.employee.id, [{ dimensionID: values.dimensionID }])
    }
  }

  return (
    <Card>
      <Subtitle>{t('employment_details.edit.title')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <EmploymentDetailsEditForm
        mutableContract={props.mutableContract}
        isFutureContract={props.isFutureContract}
        isMutableContract={props.isMutableContract}
        validFrom={formatAPIDate(getValidFrom(props.employee, props.mutableContract).validFrom)}
        company={props.company}
        employee={props.employee}
        activeEmployment={props.activeEmployment}
        productionUnits={props.productionUnits}
        employees={props.employees}
        contracts={props.contracts}
        departmentIDs={
          isDepartmentRestricted(props.companyUser)
            ? getDepartmentsWithPermission(props.companyUser, ['HireFire', 'SeeSalaryRates'])
            : props.departments.departments
                .filter((department) => department.active)
                .map((department) => department.id)
                .toArray()
        }
        departments={props.departments}
        employments={props.employments}
        employmentPositions={props.employmentPositions}
        employeeDimensions={props.employeeDimensions}
        accountingDimensions={props.accountingDimensions}
        salaryCycle={props.salaryCycle}
        isDepartmentManager={isDepartmentRestricted(props.companyUser)}
        hasDimensionValues={props.hasDimensionValues}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
