import RcPagination from 'rc-pagination'
import enGB from 'rc-pagination/lib/locale/en_GB'
import React from 'react'

import classNames from '../_util/classNames'
import injectLocale from '../locale-provider/injectLocale'
import Select from '../select'
import MiniSelect from './MiniSelect'

class Pagination extends React.Component {
  static defaultProps = {
    prefixCls: 'ant-pagination',
    selectPrefixCls: 'ant-select',
  }

  render() {
    const { className, size, ...restProps } = this.props
    const locale = this.getLocale()
    const isSmall = size === 'small'
    return (
      <RcPagination
        {...restProps}
        className={classNames(className, { mini: isSmall })}
        selectComponentClass={isSmall ? MiniSelect : Select}
        locale={locale}
      />
    )
  }
}

const injectPaginationLocale = injectLocale('Pagination', enGB)
export default injectPaginationLocale(Pagination)
