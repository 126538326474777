import { differenceInDays, endOfMonth, max, startOfMonth } from 'date-fns'
import { List } from 'immutable'

import SalaryCycle from '../model/salaryCycle'
import SalaryPeriod from '../model/salaryPeriod'
import { DateFormat } from '../model/types'
import { formatAPIDate, getDate, isSameOrAfter, isSameOrBefore } from './date-utils'

type SalaryPeriodInfo = {
  id: string
  start: Date
  end: Date
  days: number
  dispositionDate: DateFormat
}

export function getCurrentPeriod(
  salaryCycle: SalaryCycle,
  currentDate = getDate(),
  adjustPeriod = true
): SalaryPeriodInfo {
  const currentPeriod = salaryCycle.salaryPeriods.find((salaryPeriod) => {
    if (salaryCycle.frequency === 'Monthly') {
      let startOf = getDate(salaryPeriod.start)
      let endOf = getDate(salaryPeriod.end)
      if (adjustPeriod) {
        startOf = startOfMonth(getDate(salaryPeriod.end))
        endOf = endOfMonth(getDate(salaryPeriod.end))
      }
      return isSameOrAfter(currentDate, startOf) && isSameOrBefore(currentDate, endOf)
    } else {
      return (
        isSameOrAfter(currentDate, getDate(salaryPeriod.start)) &&
        isSameOrBefore(currentDate, getDate(salaryPeriod.end))
      )
    }
  })
  if (!currentPeriod) {
    // this should not happen in practice
    return {
      id: '',
      start: getDate(),
      end: getDate(),
      days: 1,
      dispositionDate: formatAPIDate(getDate()),
    }
  }
  const start = getDate(currentPeriod.start)
  const end = getDate(currentPeriod.end)
  return {
    id: currentPeriod.id,
    start,
    end,
    days: differenceInDays(start, end) + 1,
    dispositionDate: currentPeriod.dispositionDate,
  }
}

export function getCurrentPeriodFromDispositionDate(
  salaryPeriods: SalaryPeriod[],
  currentDate = getDate()
): SalaryPeriodInfo | undefined {
  const currentPeriod = salaryPeriods
    .sort((a, b) => a.start.localeCompare(b.start))
    .reduce((currentPeriod: SalaryPeriod | undefined, salaryPeriod) => {
      if (!currentPeriod) {
        const date = max([getDate(salaryPeriod.dispositionDate), getDate(salaryPeriod.end)])
        if (isSameOrBefore(currentDate, date)) {
          return salaryPeriod
        }
      }
      return currentPeriod
    }, undefined)
  if (!currentPeriod) {
    return undefined
  }
  const start = getDate(currentPeriod.start)
  const end = getDate(currentPeriod.end)
  return {
    id: currentPeriod.id,
    start,
    end,
    days: differenceInDays(start, end) + 1,
    dispositionDate: currentPeriod.dispositionDate,
  }
}

export function getSalaryCyclePeriods(salaryCycles: List<SalaryCycle>, salaryCycleID: string): SalaryPeriod[] {
  return salaryCycles.find((cycle) => cycle.id === salaryCycleID)?.salaryPeriods || []
}
