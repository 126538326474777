import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import { StartBalances } from '../model/startBalances'
import { ReducerAction } from '../utils/reducer-utils'

export interface StartBalanceReducer {
  employmentID: string | null
  loading: boolean
  loaded: boolean
  saving: boolean
  startBalances: StartBalances<number> | null
  error: Error | null
}

const Params = Record<StartBalanceReducer>({
  employmentID: null,
  loading: false,
  loaded: false,
  saving: false,
  startBalances: null,
  error: null,
})

export interface StartBalanceAction extends ReducerAction {
  employmentID?: string
  startBalances?: StartBalances<number> | null
}

export default (
  state: Record<StartBalanceReducer> = Params(),
  action: StartBalanceAction = { type: '' }
): Record<StartBalanceReducer> => {
  switch (action.type) {
    case ActionTypes.START_BALANCES_LOADING:
      return Params({
        employmentID: action.employmentID,
        loading: true,
      })
    case ActionTypes.START_BALANCES_LOADED:
      return Params({
        employmentID: action.employmentID,
        loaded: true,
        startBalances: action.startBalances,
      })
    case ActionTypes.START_BALANCES_LOAD_FAILED:
      return Params({
        employmentID: action.employmentID,
        error: action.error,
      })

    case ActionTypes.START_BALANCES_UPDATING:
      return state
        .set('employmentID', action.employmentID || state.get('employmentID'))
        .set('saving', true)
        .set('error', null)
    case ActionTypes.START_BALANCES_UPDATED:
      return state
        .set('employmentID', action.employmentID || state.get('employmentID'))
        .set('saving', false)
        .set('loaded', true)
        .set('startBalances', action.startBalances || null)
    case ActionTypes.START_BALANCES_UPDATE_FAILED:
      return state
        .set('employmentID', action.employmentID || state.get('employmentID'))
        .set('saving', false)
        .set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
