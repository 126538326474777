import { endOfMonth, startOfMonth } from 'date-fns'
import React, { ReactElement } from 'react'

import Employee from '../../../model/employee'
import { DateFormat } from '../../../model/types'
import { ContractReducer } from '../../../reducers/contracts'
import { formatAPIDate } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import DatePicker from '../../elements/date-picker'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import LoadingOverlay from '../../widgets/LoadingOverlay'

export const isLeavePeriodValid = (startDate: Date, endDate: Date) => {
  if (!startDate || !endDate) {
    return true
  }
  if (startDate.getMonth() !== endDate.getMonth()) {
    return true
  }
  if (startOfMonth(startDate).getDate() === startDate.getDate()) {
    return true
  }
  if (endOfMonth(endDate).getDate() === endDate.getDate()) {
    return true
  }
  return false
}

type Props = {
  employee: Employee
  contracts: ContractReducer
  startDate: Date
  endDate: Date
}

type Fields = {
  startDate: Date
  endDate: Date
}

export type LeaveResult = {
  startDate: DateFormat
  endDate: DateFormat
}

function LeaveEmployeeForm(props: Props & FormComponentProps<Fields, LeaveResult>): ReactElement | null {
  const { decorateField, getFieldValue } = props

  let error = null
  if (!isLeavePeriodValid(getFieldValue('startDate'), getFieldValue('endDate'))) {
    error = t('employee_leave.form.invalid_period')
  }
  return (
    <div>
      {error && <Alert message={error} type="error" showIcon />}
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('startDate', {
            placeholder: t('employee_leave.form.start_date'),
            validate: (val) => {
              if (!val) {
                return t('employee_leave.form.start_date.required')
              }
              return null
            },
          })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('endDate', {
            placeholder: t('employee_leave.form.end_date'),
            validate: (val) => {
              if (!val) {
                return t('employee_leave.form.end_date.required')
              }
              return null
            },
          })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" type="danger" size="large" block>
            {t('employee_leave.form.submit')}
          </Button>
        </Col>
      </Row>
      {props.contracts.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, LeaveResult>({
  mapPropsToFields: (props) => ({
    startDate: props.startDate,
    endDate: props.endDate,
  }),
  onSubmit: (values) => ({
    startDate: formatAPIDate(values.startDate),
    endDate: formatAPIDate(values.endDate),
  }),
})(LeaveEmployeeForm)
