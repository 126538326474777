import React, { ReactElement } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import { NewPayRoll } from '../../api/pay-rolls'
import Company from '../../model/company'
import { DateFormat } from '../../model/types'
import { EmployeeReducer } from '../../reducers/employees'
import { SalaryCycleReducer } from '../../reducers/salaryCycles'
import { paths } from '../../routes'
import { formatLoadingText } from '../../utils/loading-utils'
import { t } from '../../utils/translation-utils'
import Card from '../elements/card'
import Title from '../elements/Title'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import PayRollsAddForm, { PayRollsAddResult } from './PayRollsAddForm'

type Props = {
  company: Company
  salaryCycles: SalaryCycleReducer
  employees: EmployeeReducer

  addAlert: addAlertSignature
  addPayRoll: (payRoll: NewPayRoll) => void
  startZeroTaxReport: (month: DateFormat) => void
}

export default function PayRollsAdd(props: Props): ReactElement | null {
  const handleSubmit = (values: PayRollsAddResult) => {
    const salaryPeriod = props.salaryCycles.salaryCycles
      .find((salaryCycle) => salaryCycle.id === values.salaryCycleID)
      ?.salaryPeriods?.find((salaryPeriod) => salaryPeriod.id === values.salaryPeriodID)

    if (!salaryPeriod) {
      return
    }
    if (values.type === 'Extra') {
      const employeeIDs = values.employeeIDs

      props.addPayRoll({
        companyID: props.company.id,
        type: 'Extra',
        salaryPeriodID: salaryPeriod.id,
        dispositionDate: salaryPeriod.dispositionDate,
        employeeIDs: employeeIDs,
        paySlipIDs: [],
      })
    } else if (values.type === 'ZeroTaxReport') {
      props.startZeroTaxReport(salaryPeriod.start)
    }
    props.addAlert('success', t('pay_rolls.add.alert.success'), { timeout: 5 })
    jsBrowserHistory.push('/' + paths.PAY_ROLLS)
  }

  if (!props.employees.loaded || !props.salaryCycles.loaded) {
    return (
      <LoadingOverlay
        text={formatLoadingText([
          { loading: !props.employees.loaded, text: t('loading.reducer.employees') },
          { loading: !props.salaryCycles.loaded, text: t('loading.reducer.salary_cycles') },
        ])}
      />
    )
  }

  return (
    <Card className="pay-roll-create">
      <Title>{t('pay_rolls.add.title')}</Title>
      <p>{t('pay_rolls.add.intro')}</p>
      <p>&nbsp;</p>
      <PayRollsAddForm
        company={props.company}
        salaryCycles={props.salaryCycles.salaryCycles}
        employees={props.employees.employees}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
