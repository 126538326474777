import React, { ReactElement, useEffect, useState } from 'react'

import DocumentCategory, { DocumentCategoryMutableFields } from '../../model/documentCategory'
import { DocumentCategoryReducer } from '../../reducers/documentCategories'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import DocumentCategoryEditForm, { DocumentCategoryResult } from './DocumentCategoryEditForm'

type Props = {
  visible: boolean
  documentCategoryID?: string
  documentCategories: DocumentCategoryReducer

  addDocumentCategory: (category: DocumentCategoryMutableFields) => void
  updateDocumentCategory: (category: DocumentCategory) => void
}

export default function DocumentCategoryEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, documentCategories } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, documentCategories.error, error, setError)
  }, [documentCategories, visible, error])

  const handleSubmit = (values: DocumentCategoryResult) => {
    if (props.documentCategoryID) {
      const category = documentCategories.documentCategories.find(
        (category) => category.id === props.documentCategoryID
      )
      props.updateDocumentCategory({
        id: props.documentCategoryID,
        name: values.name,
        active: category ? category.active : false,
      })
    } else {
      props.addDocumentCategory({ name: values.name })
    }
  }

  return (
    <Card>
      <Subtitle>
        {props.documentCategoryID ? t('document_category.edit.title.edit') : t('document_category.edit.title.create')}
      </Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <DocumentCategoryEditForm
        documentCategoryID={props.documentCategoryID}
        documentCategories={props.documentCategories}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
