import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import Company from '../../model/company'
import { FeatureType } from '../../model/companyFeature'
import CompanySetting from '../../model/companySetting'
import { paths } from '../../routes'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber } from '../../utils/number-utils'
import { escapeRegExp } from '../../utils/string-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Checkbox from '../elements/checkbox'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import Switch from '../elements/switch'
import JumpLink from '../widgets/JumpLink'
import SupportUserLock from '../widgets/SupportUserLock'

type Props = {
  settingsEnabled: CompanySetting[]
  featureTypes: FeatureType[]
  company?: Company
  isCompanyGroup: boolean
}

const advancedSettings: CompanySetting[] = [
  'MoreBonusOptions',
  'MoreIncomeTypes',
  'SHDageFritvalgIncreaseTypes',
  'AllowNoCPR',
  'AllowFreelancerCVR',
  'AllowDayLaborer',
  'AllowVacationFund',
  'AllowLeaveTypeEmployeeSelectable',
  'DisableAppTimeRegistration',
  'DisableAppLeaveRegistration',
  'RegisterTimeRegistrationStartEnd',
  'AskForOTPDraftState',
  'AllowNetPension',
  'AllowOTPUnitsAndRate',
  'EnableAppHourlyLeaveRegistration',
  'EnableAppFeeRegistration',
  'AllowNonSalariedPaidVacation',
  'UseStandardRatesForUnknownATPHours',
  'AllowNegationPayroll',
  'AllowApproveOnlyUser',
  'EnableFlexAndOvertime',
  'UseAdjustedWorkWeekLeaveRate',
  'AllowSalaryTypeSupplements',
  'AllowSHFritvalgDirectPay',
  'DisableAppUpcomingPaySlip',
  'EnableEmployeeDimensions',
  'EnableEIncomeTexts',
  'TreatHourlyVacationAsSalaried',
  'AllowVacationRate',
  'ContractTemplatesEnabled',
  'DisableAppFlexOvertimeRegistration',
  'IncludeBenefitsInPensionBasis',
  'AllowEmployeeUpdateBanking',
  'EnableProjects',
  'DisableAppProjectsRegistration',
  'DisableTimeRegistrationMethodDetailedOnInvite',
  'DisableCarAllowanceRegistrationMethodDetailedOnInvite',
  'DisableSalaryRegistrationMethodDetailedOnInvite',
  'AllowNoATP',
  'ProportionalLeaveTypeVesting',
  'AllowEmployeeToUploadDocuments',
  'UseEndForTimeRegistrationSupplementOverlap',
  'AllowSalaryReductionBIncome',
  'GreatPrayerDayPeriodically',
  'GreatPrayerDayForAll',
  'EnableReadyEmployeeForPayroll',
  'GreatPrayerDayEnableDefault',
  'IncludeVacationSupplementsInPensionBasis',
  'AllowRatePerCarAllowance',
  'EnableMultipleWorkWeeks',
]

type Fields = Partial<Record<CompanySetting, boolean>> & {
  workHourLunchLimit?: string
  retroactive: boolean
}

export type AdvancedResult = {
  enable: CompanySetting[]
  disable: CompanySetting[]
  workHourLunchLimit?: number
  retroactive: boolean
}

function AdvancedSettingsForm(props: Props & FormComponentProps<Fields, AdvancedResult>): ReactElement | null {
  const [inputSearchQuery, setInputSearchQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    const searchTimeout = setTimeout(() => setSearchQuery(inputSearchQuery))
    return () => clearTimeout(searchTimeout)
  }, [inputSearchQuery])
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearchQuery(e.currentTarget.value.trim())
  }

  const { decorateField } = props

  const settingsEnabled: Record<string, boolean> = {}
  props.settingsEnabled.forEach((setting) => {
    settingsEnabled[setting] = true
  })

  type Option = {
    setting: keyof Fields
    titleID: string
    isNumber?: boolean
    notOnCompanyGroup?: boolean
    requiresFeature?: FeatureType
    requiresSetting?: CompanySetting
    linkOnSelected?: string
  }
  type OptionGroup = {
    titleID: string
    options: Option[]
  }

  const options: OptionGroup[] = [
    {
      titleID: 'pay',
      options: [
        { setting: 'MoreBonusOptions', titleID: 'more_bonus_options' },
        { setting: 'MoreIncomeTypes', titleID: 'more_income_types' },
        { setting: 'AllowNegationPayroll', titleID: 'allow_negation_payroll' },
        { setting: 'AskForOTPDraftState', titleID: 'ask_for_otp_draft_state' },
        { setting: 'AllowOTPUnitsAndRate', titleID: 'allow_tp_units_and_rate' },
        { setting: 'SHDageFritvalgIncreaseTypes', titleID: 'sh_fritvalg_increase_types' },
        { setting: 'UseStandardRatesForUnknownATPHours', titleID: 'use_standard_rates_for_unknown_atp_hours' },
        { setting: 'AllowSalaryTypeSupplements', titleID: 'allow_salary_type_supplement' },
        { setting: 'AllowSHFritvalgDirectPay', titleID: 'allow_sh_fritvalg_direct_pay' },
        { setting: 'EnableEIncomeTexts', titleID: 'enable_e_income_texts' },
        { setting: 'AllowSalaryReductionBIncome', titleID: 'allow_salary_reduction_b_income' },
        { setting: 'GreatPrayerDayPeriodically', titleID: 'great_prayer_day_periodically' },
        { setting: 'GreatPrayerDayForAll', titleID: 'great_prayer_day_for_all' },
        { setting: 'GreatPrayerDayEnableDefault', titleID: 'great_prayer_day_enable_default' },
        { setting: 'EnableMultipleWorkWeeks', titleID: 'enable_multiple_work_weeks' },
      ],
    },
    {
      titleID: 'employees',
      options: [
        { setting: 'AllowNoCPR', titleID: 'allow_no_cpr' },
        { setting: 'AllowFreelancerCVR', titleID: 'allow_freelancer_cvr' },
        { setting: 'ContractTemplatesEnabled', titleID: 'contract_templates_enabled' },
        { setting: 'EnableReadyEmployeeForPayroll', titleID: 'enable_ready_employee_for_payroll' },
      ],
    },
    {
      titleID: 'time_registrations',
      options: [
        { setting: 'RegisterTimeRegistrationStartEnd', titleID: 'register_time_registration_start_end' },
        {
          setting: 'UseEndForTimeRegistrationSupplementOverlap',
          titleID: 'use_end_for_time_registration_supplement_overlap',
        },
      ],
    },
    {
      titleID: 'benefits',
      options: [
        { setting: 'workHourLunchLimit', titleID: 'work_hour_lunch_limit', isNumber: true, notOnCompanyGroup: true },
        { setting: 'IncludeBenefitsInPensionBasis', titleID: 'include_benefits_in_pension_basis' },
      ],
    },
    {
      titleID: 'leave',
      options: [
        { setting: 'AllowVacationFund', titleID: 'allow_vacation_fund' },
        { setting: 'AllowNonSalariedPaidVacation', titleID: 'allow_non_salaried_paid_vacation' },
        { setting: 'AllowDayLaborer', titleID: 'allow_day_laborer' },
        { setting: 'EnableFlexAndOvertime', titleID: 'enable_flex_and_overtime' },
        { setting: 'UseAdjustedWorkWeekLeaveRate', titleID: 'use_adjusted_work_week_leave_rate' },
        { setting: 'TreatHourlyVacationAsSalaried', titleID: 'treat_hourly_vacation_as_salaried' },
        { setting: 'AllowVacationRate', titleID: 'allow_vacation_rate' },
        { setting: 'ProportionalLeaveTypeVesting', titleID: 'enable_proportional_leave_type_vesting' },
      ],
    },
    {
      titleID: 'pension',
      options: [
        { setting: 'AllowNetPension', titleID: 'allow_net_pension' },
        { setting: 'AllowNoATP', titleID: 'allow_no_atp' },
        {
          setting: 'IncludeVacationSupplementsInPensionBasis',
          titleID: 'include_vacation_supplements_in_pension_basis',
        },
      ],
    },
    {
      titleID: 'car_allowance',
      options: [{ setting: 'AllowRatePerCarAllowance', titleID: 'allow_rate_per_car_allowance' }],
    },
    {
      titleID: 'additional_features',
      options: [{ setting: 'EnableProjects', titleID: 'enable_projects', requiresFeature: 'Projects' }],
    },
    {
      titleID: 'employee_app',
      options: [
        {
          setting: 'DisableTimeRegistrationMethodDetailedOnInvite',
          titleID: 'disable_time_registration_method_detailed_on_invite',
        },
        {
          setting: 'DisableCarAllowanceRegistrationMethodDetailedOnInvite',
          titleID: 'disable_car_allowance_registration_method_detailed_on_invite',
        },
        {
          setting: 'DisableSalaryRegistrationMethodDetailedOnInvite',
          titleID: 'disable_salary_registration_method_detailed_on_invite',
        },
        { setting: 'DisableAppTimeRegistration', titleID: 'disable_app_time_registration' },
        { setting: 'DisableAppLeaveRegistration', titleID: 'disable_app_leave_registration' },
        { setting: 'DisableAppFlexOvertimeRegistration', titleID: 'disable_app_flex_overtime_registration' },
        { setting: 'EnableAppHourlyLeaveRegistration', titleID: 'enable_app_hourly_leave_registration' },
        {
          setting: 'AllowEmployeeToUploadDocuments',
          titleID: 'allow_employee_to_upload_documents',
          requiresFeature: 'Document Store',
        },
        {
          setting: 'AllowLeaveTypeEmployeeSelectable',
          titleID: 'allow_leave_type_employee_selectable',
          notOnCompanyGroup: true,
          linkOnSelected: props.company
            ? '/' + paths.COMPANIES + '/' + props.company.id + '/leave-type-settings'
            : undefined,
        },
        { setting: 'EnableAppFeeRegistration', titleID: 'enable_app_fee_registration' },
        { setting: 'DisableAppUpcomingPaySlip', titleID: 'disable_app_upcoming_pay_slip' },
        { setting: 'AllowEmployeeUpdateBanking', titleID: 'allow_app_employee_update_banking' },
        {
          setting: 'DisableAppProjectsRegistration',
          titleID: 'disable_app_project_registration',
          requiresFeature: 'Projects',
          requiresSetting: 'EnableProjects',
        },
      ],
    },
    {
      titleID: 'accounting',
      options: [{ setting: 'EnableEmployeeDimensions', titleID: 'enable_employee_dimensions' }],
    },
    {
      titleID: 'company',
      options: [{ setting: 'AllowApproveOnlyUser', titleID: 'allow_approve_only_user' }],
    },
  ]

  const getOptions = () => {
    return options
      .map((group) => {
        const groupTitle = t(['advanced_setting', 'header', group.titleID])
        return {
          ...group,
          options: group.options.filter((option) => {
            if (searchQuery) {
              const title = t(['advanced_setting', 'setting', option.titleID, 'title'])
              const desc = t(['advanced_setting', 'setting', option.titleID, 'description'])
              const pattern = new RegExp(escapeRegExp(searchQuery), 'i')
              if (!pattern.test(title) && !pattern.test(desc) && !pattern.test(groupTitle)) {
                return false
              }
            }
            if (!!option.notOnCompanyGroup && props.isCompanyGroup) {
              return false
            }
            if (option.requiresFeature && !props.featureTypes.some((feature) => feature === option.requiresFeature)) {
              return false
            }
            if (option.requiresSetting && !settingsEnabled[option.requiresSetting]) {
              return false
            }
            return true
          }),
        }
      })
      .filter((group) => group.options.length > 0)
  }

  return (
    <div className="advanced-settings">
      <Row style={{ marginTop: '20px' }}>
        <Col span={12}>
          <Button htmlType="submit" size="large" type="secondary" style={{ marginBottom: '15px', marginTop: '0' }}>
            {t('form.button.save_changes')}
          </Button>
        </Col>
        <Col span={12}>
          <Input.Search onChange={handleSearch} />
        </Col>
      </Row>
      {getOptions().map((group) => {
        return (
          <Subcard key={group.titleID}>
            <Subtitle>{t(['advanced_setting', 'header', group.titleID])}</Subtitle>
            <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
              {group.options.map((option) => {
                const helpLink = (
                  <SupportUserLock inline>
                    <JumpLink
                      to={'/' + paths.JUMP + '/' + paths.COMPANIES + '/advance-settings#setting-' + option.setting}
                    >
                      Hjælpehægte til dette felt
                    </JumpLink>
                  </SupportUserLock>
                )
                if (option.isNumber) {
                  return (
                    <Col span={12} key={option.titleID} className={'setting-cell'} id={`setting-${option.setting}`}>
                      {decorateField(option.setting, {
                        placeholder: t(['advanced_setting', 'setting', option.titleID, 'title']),
                        validate: (val) => {
                          if (!val || typeof val !== 'string') {
                            return null // nothing is permitted
                          }
                          if (!val.match(/^[0-9,.]+$/)) {
                            return t('form.validation.must_be_a_number')
                          }
                          return null
                        },
                      })(<Input />)}
                      <p>
                        {t(['advanced_setting', 'setting', option.titleID, 'description'])}
                        {helpLink}
                      </p>
                    </Col>
                  )
                }
                if (option.linkOnSelected) {
                  return (
                    <Col span={12} key={option.titleID} className={'setting-cell'} id={`setting-${option.setting}`}>
                      <div style={{ padding: '0px 0px 15px' }}>
                        {decorateField(option.setting, {
                          title: t(['advanced_setting', 'setting', option.titleID, 'title']),
                          valueOnChecked: true,
                          skipWrapper: true,
                        })(<Switch style={{ marginTop: '13px' }} />)}
                        {settingsEnabled[option.setting] && (
                          <Link
                            style={{ position: 'relative', top: '7px', marginLeft: '15px' }}
                            to={option.linkOnSelected}
                          >
                            {t(['advanced_setting', 'setting', option.titleID, 'link'])}
                          </Link>
                        )}
                      </div>
                      <p>
                        {t(['advanced_setting', 'setting', option.titleID, 'description'])}
                        {helpLink}
                      </p>
                    </Col>
                  )
                }
                return (
                  <Col span={12} key={option.titleID} className={'setting-cell'} id={`setting-${option.setting}`}>
                    {decorateField(option.setting, {
                      title: t(['advanced_setting', 'setting', option.titleID, 'title']),
                      valueOnChecked: true,
                      noBlur: true,
                    })(<Switch />)}
                    <p>
                      {t(['advanced_setting', 'setting', option.titleID, 'description'])}
                      {helpLink}
                    </p>
                  </Col>
                )
              })}
            </Row>
          </Subcard>
        )
      })}
      {props.isCompanyGroup && (
        <Row style={{ marginTop: '25px' }}>
          <Col span={12}>
            <p>{t('advanced_setting.company_group.retroactive.description')}</p>
          </Col>
          <Col span={6}>
            {decorateField('retroactive', {
              title: t('advanced_setting.company_group.retroactive.title'),
              valueOnChecked: true,
              noBlur: true,
            })(<Checkbox />)}
          </Col>
        </Row>
      )}
      <Button htmlType="submit" size="large" type="secondary">
        {t('form.button.save_changes')}
      </Button>
    </div>
  )
}

export default withValidations<Props, Fields, AdvancedResult>({
  mapPropsToFields: (props) => {
    let settingsEnabled = props.settingsEnabled
    if (!settingsEnabled) {
      settingsEnabled = []
    }
    const fields: Fields = {
      retroactive: true,
      workHourLunchLimit:
        props.company && props.company.workHourLunchLimit
          ? formatInputNumber(props.company.workHourLunchLimit)
          : undefined,
    }

    advancedSettings.forEach((setting) => {
      fields[setting] = settingsEnabled.some((settingEnabled) => settingEnabled === setting)
    })

    return fields
  },
  onSubmit: (values) => {
    const enable: CompanySetting[] = []
    const disable: CompanySetting[] = []
    advancedSettings.forEach((setting) => {
      if (values[setting]) {
        enable.push(setting)
      } else {
        disable.push(setting)
      }
    })
    return {
      enable,
      disable,
      workHourLunchLimit: values.workHourLunchLimit ? forceParseInputNumber(values.workHourLunchLimit) : undefined,
      retroactive: values.retroactive,
    }
  },
})(AdvancedSettingsForm)
