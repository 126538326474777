import React, { ReactElement } from 'react'

import { AsynchronousScheduleReducer } from '../../reducers/asynchronousSchedules'
import { buildCron, parseCron } from '../../utils/cron-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import { Col, Row } from '../elements/grid'
import Input from '../elements/input'

type Fields = {
  dayFrequency: string
  hourStamp: string
}

export type ScheduleResult = {
  cron: string
}

type Props = {
  title: string
  asynchronousSchedules: AsynchronousScheduleReducer
  asynchronousScheduleID?: string
}

function AsynchronousScheduleModalEdit(props: Props & FormComponentProps<Fields, ScheduleResult>): ReactElement | null {
  const { decorateField } = props
  const title = props.title
  return (
    <>
      <Row>
        <Col span={12}>
          {decorateField('dayFrequency', {
            title: t('data_integration.async_schedule.edit.form.day_frequency', { title }),
            prefix: t('data_integration.async_schedule.edit.form.day_frequency.prefix'),
            suffix: t('data_integration.async_schedule.edit.form.day_frequency.suffix'),
            validate: (v) => {
              if (!v) {
                return t('data_integration.async_schedule.edit.form.day_frequency.required', { title })
              }
              if (forceParseInputNumber(v) <= 0) {
                return t('data_integration.async_schedule.edit.form.day_frequency.positive_integer', { title })
              }
              if (forceParseInputNumber(v) > 60) {
                return t('data_integration.async_schedule.edit.form.day_frequency.most_60_days', { title })
              }
              return null
            },
          })(<Input />)}
        </Col>
        <Col span={12}>
          {decorateField('hourStamp', {
            title: t('data_integration.async_schedule.edit.form.hour_stamp', { title }),
            validate: (v) => {
              if (!v) {
                return t('data_integration.async_schedule.edit.form.hour_stamp.required', { title })
              }
              const n = forceParseInputNumber(v)
              if (n < 0 || n > 23) {
                return t('data_integration.async_schedule.edit.form.hour_stamp.invalid')
              }
              return null
            },
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default withValidations<Props, Fields, ScheduleResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      dayFrequency: '1',
      hourStamp: '18',
    }
    if (props.asynchronousScheduleID) {
      const schedule = props.asynchronousSchedules.asynchronousSchedules.find(
        (schedule) => schedule.id === props.asynchronousScheduleID
      )
      if (schedule) {
        const details = parseCron(schedule.cron)
        if (details.dayFrequency !== -1) {
          fields.dayFrequency = formatInputNumber(details.dayFrequency)
          fields.hourStamp = formatInputNumber(details.hourStamp)
        }
      }
    }
    return fields
  },
  onSubmit: (values) => {
    const cron = buildCron({
      dayFrequency: forceParseInputNumber(values.dayFrequency),
      hourStamp: forceParseInputNumber(values.hourStamp),
    })
    return {
      cron,
    }
  },
})(AsynchronousScheduleModalEdit)
