import { List } from 'immutable'

import CompanyUser from '../model/companyUser'
import { DepartmentPermission } from '../model/departmentUser'
import Employee from '../model/employee'

export function isDepartmentRestricted(companyUser?: CompanyUser): boolean {
  return !!companyUser && (!companyUser.permissions || companyUser.permissions.length === 0)
}

export function hasDepartmentPermission(
  companyUser: CompanyUser | undefined,
  departmentID: string | undefined,
  hasPermission: DepartmentPermission
): boolean {
  // there may be no companyUser, if for instance a support user
  if (!companyUser || companyUser.departments.length === 0) {
    return true // if we do not have departments, we assume you have all permissions
  }
  return companyUser.departments.some(
    (department) =>
      (!departmentID || department.departmentID === departmentID) &&
      department.permissions.some((permission) => permission === hasPermission)
  )
}

export function getDepartmentsWithPermission(
  companyUser: CompanyUser | undefined,
  hasPermissions: DepartmentPermission[]
): string[] {
  if (!companyUser || companyUser.departments.length === 0) {
    return []
  }
  return companyUser.departments
    .filter((department) =>
      department.permissions.some((permission) => hasPermissions.some((check) => check === permission))
    )
    .map((department) => department.departmentID)
}

export function hasEmployeeDepartmentPermission(
  companyUser: CompanyUser | undefined,
  employees: Map<string, Employee>,
  employeeID: string,
  hasPermission: DepartmentPermission
): boolean {
  const employee = employees.get(employeeID)
  if (!employee) {
    return false
  }
  return hasDepartmentPermission(companyUser, employee.departmentID, hasPermission)
}

export function isPayRollReviewer(companyUsers: List<CompanyUser>, companyID: string, userID: string): boolean {
  let isPayRollReviewer = false
  companyUsers.forEach((companyUser) => {
    if (companyUser.companyID === companyID && companyUser.userID === userID) {
      companyUser.permissions.forEach((permission) => {
        if (permission.permission === 'ReviewPayRoll') {
          isPayRollReviewer = true
        }
      })
    }
  })
  return isPayRollReviewer
}
