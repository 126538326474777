import React, { ReactElement, useEffect, useState } from 'react'

import { TaxCardUpdate } from '../../../actions/tax-cards'
import { TaxCardReducer } from '../../../reducers/taxCards'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import EditModalForm, { Fields } from './EditModalForm'

type Props = {
  visible: boolean
  taxCardID?: string
  taxCards: TaxCardReducer
  updateTaxCard: (_: TaxCardUpdate) => void
}

export default function EditModal(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const propsError = props.taxCards.error
  const visible = props.visible

  useEffect(() => {
    visibleComponentDidUpdate(visible, propsError, error, setError)
  }, [visible, propsError, error])

  const handleSubmit = (values: Fields): void => {
    props.updateTaxCard({ id: props.taxCardID || '', validFrom: formatAPIDate(values.validFrom) })
  }

  return (
    <Card>
      <Subtitle>{t('tax_card.tab.edit.title')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <EditModalForm taxCardID={props.taxCardID || undefined} taxCards={props.taxCards} onSubmit={handleSubmit} />
    </Card>
  )
}
