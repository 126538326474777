import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../model/company'
import Document, { DocumentCreationFields, DocumentMutableFields } from '../../model/document'
import DocumentCategory from '../../model/documentCategory'
import { DocumentReducer } from '../../reducers/documents'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import CompanyDocumentEditForm, { CompanyDocumentResult } from './CompanyDocumentEditForm'

type Props = {
  visible: boolean
  company: Company
  documentID?: string
  fileID?: string
  documents: DocumentReducer
  documentCategories: List<DocumentCategory>

  addDocument: (companyID: string, document: DocumentCreationFields) => Promise<Document | void>
  updateDocument: (companyID: string, document: DocumentMutableFields) => Promise<Document | void>
}

export default function CompanyDocumentEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, documents } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, documents.error, error, setError)
  }, [visible, documents, error])

  const handleSubmit = (values: CompanyDocumentResult) => {
    if (props.documentID) {
      const document = props.documents.documents.find((document) => document.id === props.documentID)
      if (document) {
        props.updateDocument(props.company.id, {
          ...document,
          documentCategoryID: values.documentCategoryID,
          name: values.name,
        })
      }
    } else if (props.fileID) {
      const document: DocumentCreationFields = {
        companyID: props.company.id,
        documentCategoryID: values.documentCategoryID,
        name: values.name,
        visibleForEmployee: true,
        fileID: props.fileID,
      }
      props.addDocument(props.company.id, document)
    }
  }

  return (
    <Card className="document-modal">
      <Subtitle>
        {props.documentID
          ? t('documents.company_document_edit.title.edit')
          : t('documents.company_document_edit.title.create')}
      </Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <CompanyDocumentEditForm
        documentID={props.documentID}
        documents={props.documents}
        documentCategories={props.documentCategories}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
