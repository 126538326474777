import { List } from 'immutable'
import React, { ReactElement, useEffect } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { CoarseTimeRegistrationMutableFields } from '../../model/coarseTimeRegistration'
import Company from '../../model/company'
import { ContractCreationFields, ContractMutableFields } from '../../model/contract'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import Project from '../../model/project'
import SalaryCycle from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import { TimeRegistrationClass, TimeRegistrationMutableFields } from '../../model/timeRegistration'
import { AlertReducer } from '../../reducers/alerts'
import { CoarseTimeRegistrationReducer } from '../../reducers/coarseTimeRegistrations'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { ContractReducer } from '../../reducers/contracts'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { UserReducer } from '../../reducers/user'
import { VacationCalendarReducer } from '../../reducers/vacationCalendars'
import { paths } from '../../routes'
import RemunerationType from '../../types/remuneration-type'
import StagedImportFileType from '../../types/staged-import-file-type'
import { isDepartmentRestricted } from '../../utils/permissions-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Icon from '../elements/Icon'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import TimeRegistrationCalendar from './TimeRegistrationCalendar'
import TimeRegistrationTable from './TimeRegistrationTable'

import './TimeRegistration.css'

type Props = {
  alerts: AlertReducer
  showCalendar: boolean
  user: UserReducer
  company: Company
  companyUsers: CompanyUserReducer
  employees: List<Employee>
  contracts: ContractReducer
  coarseTimeRegistrations: CoarseTimeRegistrationReducer
  timeRegistrations: TimeRegistrationReducer
  costCenters: List<CostCenter>
  departments: List<Department>
  salaryCycles: List<SalaryCycle>
  salaryTypes: List<SalaryType>
  projects: List<Project>
  vacationCalendars: VacationCalendarReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getCoarseTimeRegistrations: (companyID: string, salaryPeriodID: string) => void
  updateCoarseTimeRegistrationBulk: (employeeID: string, registrations: CoarseTimeRegistrationMutableFields[]) => void
  approveTimeRegistrations: (ids: string[]) => void
  getContracts: () => void
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    timeRegistrationClass: TimeRegistrationClass
  ) => Promise<boolean | void>
  getVacationCalendarYear: (companyID: string, year: number) => void
  getRemuneration: (id: string) => void
  createTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (id: string) => void
}

export default function TimeRegistrations(props: Props): ReactElement | null {
  const { contracts, employees, getContracts } = props

  useEffect(() => {
    // load remunerations for all employees we display here
    if (
      !contracts.loading &&
      employees.some(
        (employee) =>
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          employee.activeContract?.remunerationType === RemunerationType.HOURLY &&
          !employee.activeContract.remuneration
      )
    ) {
      getContracts()
    }
  })

  return (
    <div className="time-registration">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <TitleMenu>
        {!isDepartmentRestricted(props.companyUsers.companyUser) && (
          <Link to={'/' + paths.STAGED_IMPORT + '?type=' + StagedImportFileType.TIME_REGISTRATIONS}>
            <Button type="primary" className="gtm-import-timereg" style={{ marginRight: '25px' }}>
              <Icon type="watch" />
              {t('time_registrations.header.import_from_file')}
            </Button>
          </Link>
        )}
        <Link to={'/' + paths.TIME_REGISTRATION + (props.showCalendar ? '' : '/calendar')}>
          <Button type="primary" className="gtm-toggle-time-view">
            {props.showCalendar
              ? t('time_registrations.header.switch_to_table')
              : t('time_registrations.header.switch_to_calendar')}
          </Button>
        </Link>
      </TitleMenu>

      {props.showCalendar && (
        <TimeRegistrationCalendar
          company={props.company}
          employees={props.employees}
          costCenters={props.costCenters}
          departments={props.departments}
          timeRegistrations={props.timeRegistrations}
          vacationCalendars={props.vacationCalendars}
          salaryTypes={props.salaryTypes}
          projects={props.projects}
          getVacationCalendarYear={props.getVacationCalendarYear}
          getRemuneration={props.getRemuneration}
          createTimeRegistration={props.createTimeRegistration}
          updateTimeRegistration={props.updateTimeRegistration}
          approveTimeRegistrations={props.approveTimeRegistrations}
          deleteTimeRegistration={props.deleteTimeRegistration}
        />
      )}
      {!props.showCalendar && (
        <TimeRegistrationTable
          user={props.user}
          company={props.company}
          companyUsers={props.companyUsers}
          employees={props.employees}
          departments={props.departments}
          contracts={props.contracts}
          coarseTimeRegistrations={props.coarseTimeRegistrations}
          timeRegistrations={props.timeRegistrations}
          salaryCycles={props.salaryCycles}
          salaryTypes={props.salaryTypes}
          addAlert={props.addAlert}
          getCoarseTimeRegistrations={props.getCoarseTimeRegistrations}
          updateCoarseTimeRegistrationBulk={props.updateCoarseTimeRegistrationBulk}
          approveTimeRegistrations={props.approveTimeRegistrations}
          addContract={props.addContract}
          updateContract={props.updateContract}
          deleteTimeRegistrationBulk={props.deleteTimeRegistrationBulk}
        />
      )}
    </div>
  )
}
