import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import OneTimePayPension from '../model/oneTimePayPension'
import { ReducerAction } from '../utils/reducer-utils'

export interface OneTimePayPensionReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  oneTimePayPensions: List<OneTimePayPension>
  error: Error | null
}

const Params = Record<OneTimePayPensionReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  oneTimePayPensions: List<OneTimePayPension>(),
  error: null,
})

export interface OneTimePayPensionAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  oneTimePayID?: string
  oneTimePayPensions?: OneTimePayPension[]
  oneTimePayPension?: OneTimePayPension
}

const comparator = (a: OneTimePayPension, b: OneTimePayPension) => {
  return b.oneTimePay.dispositionDate.localeCompare(a.oneTimePay.dispositionDate, 'da-dk')
}

export default (
  state: Record<OneTimePayPensionReducer> = Params(),
  action: OneTimePayPensionAction = { type: '' }
): Record<OneTimePayPensionReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.ONE_TIME_PAY_PENSION_LOADING:
      return Params({
        loading: true,
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID || state.get('employeeID'),
      })
    case ActionTypes.ONE_TIME_PAY_PENSION_LOADED:
    case ActionTypes.ONE_TIME_PAY_PENSION_LOADED_PARTIAL:
      return Params({
        loaded: true,
        loading: action.type === ActionTypes.ONE_TIME_PAY_PENSION_LOADED_PARTIAL,
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID || state.get('employeeID'),
        oneTimePayPensions: state.get('oneTimePayPensions').concat(List(action.oneTimePayPensions)).sort(comparator),
      })
    case ActionTypes.ONE_TIME_PAY_PENSION_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID || state.get('employeeID'),
        error: action.error || null,
      })

    case ActionTypes.ONE_TIME_PAY_PENSION_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ONE_TIME_PAY_PENSION_ADDED:
      if (!action.oneTimePayPension) {
        return state
      }
      if (!action.companyID) {
        if (!action.employeeID) {
          return state
        }
        // only process actions for the active employee
        if (action.employeeID !== state.get('employeeID')) {
          return state
        }
      }
      idx = state
        .get('oneTimePayPensions')
        .findIndex(
          (item) => !!action.oneTimePayPension && item.oneTimePay.id === action.oneTimePayPension.oneTimePay.id
        )
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set(
            'oneTimePayPensions',
            state.get('oneTimePayPensions').set(idx, action.oneTimePayPension).sort(comparator)
          )
      }
      return state
        .set('saving', false)
        .set('oneTimePayPensions', state.get('oneTimePayPensions').push(action.oneTimePayPension).sort(comparator))
    case ActionTypes.ONE_TIME_PAY_PENSION_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.ONE_TIME_PAY_PENSION_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ONE_TIME_PAY_PENSION_UPDATED:
      if (!action.oneTimePayPension) {
        return state
      }
      idx = state.get('oneTimePayPensions').findIndex((item) => item.oneTimePay.id === action.oneTimePayID)
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('oneTimePayPensions', state.get('oneTimePayPensions').set(idx, action.oneTimePayPension).sort(comparator))
    case ActionTypes.ONE_TIME_PAY_PENSION_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.ONE_TIME_PAY_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ONE_TIME_PAY_DELETED:
      idx = state.get('oneTimePayPensions').findIndex((item) => item.oneTimePay.id === action.oneTimePayID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('oneTimePayPensions', state.get('oneTimePayPensions').delete(idx))
    case ActionTypes.ONE_TIME_PAY_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
