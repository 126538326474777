import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CoarseTimeRegistration from '../model/coarseTimeRegistration'
import { ReducerAction } from '../utils/reducer-utils'

export interface CoarseTimeRegistrationReducer {
  companyID: string | undefined
  employeeID: string | undefined
  salaryPeriodID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  coarseTimeRegistrations: List<CoarseTimeRegistration>
  error: Error | null
}

const Params = Record<CoarseTimeRegistrationReducer>({
  companyID: undefined,
  employeeID: undefined,
  salaryPeriodID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  coarseTimeRegistrations: List<CoarseTimeRegistration>(),
  error: null,
})

export interface CoarseTimeRegistrationAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  salaryPeriodID?: string
  coarseTimeRegistrations?: CoarseTimeRegistration[]
  coarseTimeRegistration?: CoarseTimeRegistration
  coarseTimeRegistrationID?: string
}

export default (
  state: Record<CoarseTimeRegistrationReducer> = Params(),
  action: CoarseTimeRegistrationAction = { type: '' }
): Record<CoarseTimeRegistrationReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.COARSE_TIME_REGISTRATION_LOADING:
      return Params({
        loading: true,
        companyID: action.companyID || state.get('companyID'),
        salaryPeriodID: action.salaryPeriodID,
        employeeID: action.employeeID,
      })
    case ActionTypes.COARSE_TIME_REGISTRATION_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('coarseTimeRegistrations', List<CoarseTimeRegistration>(action.coarseTimeRegistrations))
    case ActionTypes.COARSE_TIME_REGISTRATION_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('error', action.error || null)

    case ActionTypes.COARSE_TIME_REGISTRATION_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COARSE_TIME_REGISTRATION_CREATED:
      if (!action.coarseTimeRegistration) {
        return state
      }
      if (!action.companyID || !action.salaryPeriodID) {
        // only process actions for the active employee
        if (action.employeeID && action.employeeID !== state.get('employeeID')) {
          return state
        }
      }
      idx = state
        .get('coarseTimeRegistrations')
        .findIndex((item) => !!action.coarseTimeRegistration && item.id === action.coarseTimeRegistration.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('coarseTimeRegistrations', state.get('coarseTimeRegistrations').set(idx, action.coarseTimeRegistration))
      }
      return state
        .set('saving', false)
        .set('coarseTimeRegistrations', state.get('coarseTimeRegistrations').push(action.coarseTimeRegistration))
    case ActionTypes.COARSE_TIME_REGISTRATION_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COARSE_TIME_REGISTRATION_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COARSE_TIME_REGISTRATION_UPDATED:
      if (!action.coarseTimeRegistration) {
        return state
      }
      if (action.employeeID && action.coarseTimeRegistrationID) {
        idx = state
          .get('coarseTimeRegistrations')
          .findIndex((item) => item.employeeID === action.employeeID && item.id === action.coarseTimeRegistrationID)
      }
      if (idx === -1) {
        return state
          .set('saving', false)
          .set('coarseTimeRegistrations', state.get('coarseTimeRegistrations').push(action.coarseTimeRegistration))
      }
      return state
        .set('saving', false)
        .set('coarseTimeRegistrations', state.get('coarseTimeRegistrations').set(idx, action.coarseTimeRegistration))
    case ActionTypes.COARSE_TIME_REGISTRATION_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COARSE_TIME_REGISTRATION_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COARSE_TIME_REGISTRATION_DELETED:
      if (action.coarseTimeRegistrationID) {
        idx = state.get('coarseTimeRegistrations').findIndex((item) => item.id === action.coarseTimeRegistrationID)
      }
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('coarseTimeRegistrations', state.get('coarseTimeRegistrations').delete(idx))
    case ActionTypes.COARSE_TIME_REGISTRATION_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
