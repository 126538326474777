import da from 'date-fns/locale/da'
import enGB from 'date-fns/locale/en-GB'
import { registerLocale } from 'react-datepicker'

import Picker from './DatePicker'
import RangePicker from './RangePicker'

import './style/css'

registerLocale('da-DK', da)
registerLocale('en-GB', enGB)

const DatePicker = Object.assign(Picker, { RangePicker })

export default DatePicker
