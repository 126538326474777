import PropTypes from 'prop-types'
import RcMenu, { Divider, ItemGroup, SubMenu } from 'rc-menu'
import React from 'react'

import classNames from '../_util/classNames'
import animation from '../_util/openAnimation'
import warning from '../_util/warning'
import Item from './MenuItem'

import './style/css'

class Menu extends React.Component {
  constructor(props) {
    super(props)

    warning(
      !('onOpen' in props || 'onClose' in props),
      '`onOpen` and `onClose` are removed, please use `onOpenChange` instead, ' +
        'see: https://u.ant.design/menu-on-open-change.'
    )

    warning(
      !('inlineCollapsed' in props && props.mode !== 'inline'),
      "`inlineCollapsed` should only be used when Menu's `mode` is inline."
    )

    this.state = {
      openKeys: [],
    }
  }
  getChildContext() {
    return {
      inlineCollapsed: this.getInlineCollapsed(),
    }
  }
  handleClick = (e) => {
    this.handleOpenChange([])

    const { onClick } = this.props
    if (onClick) {
      onClick(e)
    }
  }
  handleOpenChange = (openKeys) => {
    this.setOpenKeys(openKeys)

    const { onOpenChange } = this.props
    if (onOpenChange) {
      onOpenChange(openKeys)
    }
  }
  setOpenKeys(openKeys) {
    if (!('openKeys' in this.props)) {
      this.setState({ openKeys })
    }
  }
  getRealMenuMode() {
    const inlineCollapsed = this.getInlineCollapsed()
    const { mode } = this.props
    return inlineCollapsed ? 'vertical' : mode
  }
  getInlineCollapsed() {
    const { inlineCollapsed } = this.props
    if (this.context.siderCollapsed !== undefined) {
      return this.context.siderCollapsed
    }
    return inlineCollapsed
  }
  getMenuOpenAnimation(menuMode) {
    const { openAnimation, openTransitionName } = this.props
    let menuOpenAnimation = openAnimation || openTransitionName
    if (openAnimation === undefined && openTransitionName === undefined) {
      switch (menuMode) {
        case 'horizontal':
          menuOpenAnimation = 'slide-up'
          break
        case 'vertical':
          // When mode switch from inline
          // submenu should hide without animation
          if (this.switchModeFromInline) {
            menuOpenAnimation = ''
            this.switchModeFromInline = false
          } else {
            menuOpenAnimation = 'zoom-big'
          }
          break
        case 'inline':
          menuOpenAnimation = {
            ...animation,
            leave: (node, done) =>
              animation.leave(node, () => {
                // Make sure inline menu leave animation finished before mode is switched
                this.switchModeFromInline = false
                // Fix https://github.com/ant-design/ant-design/issues/8475
                this.leaveAnimationExecutedWhenInlineCollapsed = true
                this.setState({})
                done()
              }),
          }
          break
        default:
      }
    }
    return menuOpenAnimation
  }

  render() {
    const { prefixCls, className, theme } = this.props
    const menuMode = this.getRealMenuMode()
    const menuOpenAnimation = this.getMenuOpenAnimation(menuMode)

    const menuClassName = classNames(className, `${prefixCls}-${theme}`, {
      [`${prefixCls}-inline-collapsed`]: this.getInlineCollapsed(),
    })

    const menuProps = {
      onOpenChange: this.handleOpenChange,
      className: menuClassName,
      mode: menuMode,
    }

    if (menuMode !== 'inline') {
      // closing vertical popup submenu after click it
      menuProps.onClick = this.handleClick
      menuProps.openTransitionName = menuOpenAnimation
    } else {
      menuProps.openAnimation = menuOpenAnimation
    }

    return <RcMenu {...this.props} {...menuProps} />
  }
}

Menu.Divider = Divider
Menu.Item = Item
Menu.SubMenu = SubMenu
Menu.ItemGroup = ItemGroup
Menu.defaultProps = {
  prefixCls: 'ant-menu',
  className: '',
  theme: 'light', // or dark
}
Menu.childContextTypes = {
  inlineCollapsed: PropTypes.bool,
}
Menu.contextTypes = {
  siderCollapsed: PropTypes.bool,
}

export default Menu
