import React, { ReactElement } from 'react'

import Company from '../../../model/company'
import Employee from '../../../model/employee'
import { getEmployeePayType } from '../../../utils/employee-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import { Col, Row } from '../../elements/grid'
import SwitchWrapper from '../../form-elements/SwitchWrapper'

type Props = {
  employee: Employee
  company: Company
}

type Fields = {
  employeeEnableReimbursementVoucher: boolean
  employeeVacationExcessLimit: boolean
  employeeEnableWorkHours: boolean
  employeeAutomaticVacationTransferLimit: boolean
  employeeAutomaticOptionalVacationTransferLimit: boolean
  employeeRegistrationsNeverApprovedFromEmployeeApp: boolean
}

export type EmployeeSettingsResult = {
  disableReimbursementVouchers: boolean
  vacationExcessLimit: number
  disableWorkHours: boolean
  automaticVacationTransferLimit: number
  automaticOptionalVacationTransferLimit: number
  registrationsNeverApprovedFromEmployeeApp: boolean
}

function EmployeeSettingsForm(props: Props & FormComponentProps<Fields, EmployeeSettingsResult>): ReactElement | null {
  const { decorateField } = props
  return (
    <div>
      <Row>
        {props.company.vacationExcessLimit > 0 && (
          <Col span={12}>
            <SwitchWrapper decorateField={decorateField} id="employeeVacationExcessLimit">
              {t('employee_settings.form.employee_vacation_excess_limit')}
            </SwitchWrapper>
          </Col>
        )}
        {props.company.enableReimbursementVouchers && (
          <Col span={12}>
            <SwitchWrapper id="employeeEnableReimbursementVoucher" decorateField={decorateField}>
              {t('employee_settings.form.employee_enable_reimbursement_voucher')}
            </SwitchWrapper>
          </Col>
        )}
        {getEmployeePayType(props.employee.activeContract) !== 'Hourly Paid' &&
          props.company.settingsEnabled.some((setting) => setting === 'EnableWorkHoursFeature') && (
            <Col span={12}>
              <SwitchWrapper id="employeeEnableWorkHours" decorateField={decorateField}>
                {t('employee_settings.form.employee_enable_work_hours')}
              </SwitchWrapper>
            </Col>
          )}
        {(props.company.automaticVacationTransferLimit ?? 0) > 0 && (
          <Col span={12}>
            <SwitchWrapper id="employeeAutomaticVacationTransferLimit" decorateField={decorateField}>
              {t('employee_settings.form.employee_automatic_vacation_transfer_limit')}
            </SwitchWrapper>
          </Col>
        )}
        {(props.company.automaticOptionalVacationTransferLimit ?? 0) > 0 && (
          <Col span={12}>
            <SwitchWrapper id="employeeAutomaticOptionalVacationTransferLimit" decorateField={decorateField}>
              {t('employee_settings.form.employee_automatic_optional_vacation_transfer_limit')}
            </SwitchWrapper>
          </Col>
        )}
        {(props.employee.hasUser || !!props.employee.invitationSent) && (
          <Col span={24}>
            <SwitchWrapper id="employeeRegistrationsNeverApprovedFromEmployeeApp" decorateField={decorateField}>
              {t('employee_settings.form.employee_registrations_never_approved_from_employee_app')}
            </SwitchWrapper>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={12}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, EmployeeSettingsResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      employeeEnableReimbursementVoucher: true,
      employeeVacationExcessLimit: true,
      employeeEnableWorkHours: true,
      employeeAutomaticOptionalVacationTransferLimit: true,
      employeeAutomaticVacationTransferLimit: true,
      employeeRegistrationsNeverApprovedFromEmployeeApp: false,
    }
    if (props.employee.disableReimbursementVouchers) {
      fields.employeeEnableReimbursementVoucher = false
    }
    if (props.employee.vacationExcessLimit === 0) {
      fields.employeeVacationExcessLimit = false
    }
    if (props.employee.disableWorkHours) {
      fields.employeeEnableWorkHours = false
    }
    if (props.employee.automaticVacationTransferLimit === 0) {
      fields.employeeAutomaticVacationTransferLimit = false
    }
    if (props.employee.automaticOptionalVacationTransferLimit === 0) {
      fields.employeeAutomaticOptionalVacationTransferLimit = false
    }
    if (props.employee.registrationsNeverApprovedFromEmployeeApp) {
      fields.employeeRegistrationsNeverApprovedFromEmployeeApp = true
    }
    return fields
  },
  onSubmit: (values) => ({
    disableReimbursementVouchers: !values.employeeEnableReimbursementVoucher,
    vacationExcessLimit: !values.employeeVacationExcessLimit ? 0 : -1, // setting it to negative unsets it
    disableWorkHours: !values.employeeEnableWorkHours,
    automaticVacationTransferLimit: !values.employeeAutomaticVacationTransferLimit ? 0 : -1,
    automaticOptionalVacationTransferLimit: !values.employeeAutomaticOptionalVacationTransferLimit ? 0 : -1,
    registrationsNeverApprovedFromEmployeeApp: values.employeeRegistrationsNeverApprovedFromEmployeeApp,
  }),
})(EmployeeSettingsForm)
