import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getBanks } from '../actions/banks'
import { getCompanyPricingPackages } from '../actions/company-pricing-packages'
import { getCompanyUsers } from '../actions/company-users'
import { getContractBookContracts, getContractBookIntegrations } from '../actions/contract-book'
import { getContractTemplates } from '../actions/contract-templates'
import { addDepartment, getDepartments } from '../actions/departments'
import { updateDocument } from '../actions/documents'
import { addEmployee } from '../actions/employees'
import { getPricingPackages } from '../actions/pricing-packages'
import EmployeesAddComponent from '../components/employees-add/EmployeesAdd'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import Department, { DepartmentCreationFields } from '../model/department'
import { DocumentMutableFields } from '../model/document'
import Employee, { EmployeeCreation } from '../model/employee'
import { AlertReducer } from '../reducers/alerts'
import { BankReducer } from '../reducers/banks'
import { CompanyReducer } from '../reducers/companies'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { CompanyPricingPackageReducer } from '../reducers/companyPricingPackages'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { ContractBookContractReducer } from '../reducers/contractBookContracts'
import { ContractBookIntegrationReducer } from '../reducers/contractBookIntegrations'
import { ContractTemplateReducer } from '../reducers/contractTemplates'
import { DepartmentReducer } from '../reducers/departments'
import { DocumentReducer } from '../reducers/documents'
import { EmployeeReducer } from '../reducers/employees'
import { PricingPackageReducer } from '../reducers/pricingPackages'
import { UserReducer } from '../reducers/user'
import { paths } from '../routes'
import { isPricingPackageGroup } from '../utils/pricing-package-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  companyFeatures: CompanyFeatureReducer
  companyPricingPackages: CompanyPricingPackageReducer
  pricingPackages: PricingPackageReducer
  contractTemplates: ContractTemplateReducer
  documents: DocumentReducer
  employees: EmployeeReducer
  departments: DepartmentReducer
  companyUsers: CompanyUserReducer
  user: UserReducer
  banks: BankReducer
  contractBookIntegrations: ContractBookIntegrationReducer
  contractBookContracts: ContractBookContractReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getContractTemplates: () => void
  getBanks: () => void
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
  getDepartments: () => void
  getCompanyUsers: () => void
  addEmployee: (employee: EmployeeCreation) => Promise<Employee | void>
  updateDocument: (companyID: string, document: DocumentMutableFields) => void
  getContractBookIntegrations: () => void
  getContractBookContracts: () => void
  getCompanyPricingPackages: () => void
  getPricingPackages: (includePricingPackageID: string[]) => void
}

function EmployeesAdd(props: Reducers & Actions & RouteProps): ReactElement | null {
  const {
    companies,
    banks,
    getBanks,
    departments,
    getDepartments,
    contractBookIntegrations,
    getContractBookIntegrations,
    contractBookContracts,
    getContractBookContracts,
    contractTemplates,
    getContractTemplates,
    companyUsers,
    getCompanyUsers,
    companyPricingPackages,
    getCompanyPricingPackages,
    pricingPackages,
    getPricingPackages,
  } = props
  useEffect(() => {
    if (!banks.loaded && !banks.loading) {
      getBanks()
    }
    if (!departments.loaded && !departments.loading) {
      getDepartments()
    }
    if (!contractBookIntegrations.loading && !contractBookIntegrations.loaded) {
      getContractBookIntegrations()
    }
    if (
      contractBookIntegrations.loaded &&
      contractBookIntegrations.contractBookIntegrations &&
      contractBookIntegrations.contractBookIntegrations.size > 0
    ) {
      if (!contractBookContracts.loading && !contractBookContracts.loaded) {
        getContractBookContracts()
      }
    }
    const companyID = companies.company?.id
    if (
      (companyID && contractTemplates.companyID !== companyID) ||
      (!contractTemplates.loaded && !contractTemplates.loading)
    ) {
      getContractTemplates()
    }
    if ((companyID && companyUsers.companyID !== companyID) || (!companyUsers.loaded && !companyUsers.loading)) {
      getCompanyUsers()
    }
    if (!companyPricingPackages.loading && !companyPricingPackages.loaded) {
      getCompanyPricingPackages()
    }
    if (!pricingPackages.loading && !pricingPackages.loaded) {
      getPricingPackages([])
    }
  })

  const company = companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  const loading =
    !props.banks.loaded ||
    !props.departments.loaded ||
    !props.contractBookIntegrations.loaded ||
    (props.contractBookIntegrations.contractBookIntegrations &&
      props.contractBookIntegrations.contractBookIntegrations.size > 0 &&
      !props.contractBookContracts.loaded) ||
    !props.contractTemplates.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  const hasPremiumPackage = isPricingPackageGroup(
    props.pricingPackages.pricingPackages.toArray(),
    props.companyPricingPackages.companyPricingPackages.toArray(),
    company.id,
    ['Premium']
  )

  return (
    <EmployeesAddComponent
      contractBookContractID={props.location.query.contractBookContractID}
      contractTemplateID={props.location.query.contractTemplateID}
      documentID={props.location.query.documentID}
      isInvite={props.location.pathname === '/' + paths.EMPLOYEES + '/' + paths.INVITE}
      alerts={props.alerts}
      user={props.user}
      company={company}
      companyFeatures={props.companyFeatures.companyFeatures}
      contractTemplates={props.contractTemplates}
      documents={props.documents}
      employees={props.employees}
      departments={props.departments}
      companyUser={props.companyUsers.companyUser}
      banks={props.banks.banks}
      contractBookContracts={props.contractBookContracts}
      hasPremiumPackage={hasPremiumPackage}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      addDepartment={props.addDepartment}
      addEmployee={props.addEmployee}
      updateDocument={props.updateDocument}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    companyFeatures: state.companyFeatures,
    contractTemplates: state.contractTemplates,
    documents: state.documents,
    employees: state.employees,
    departments: state.departments,
    companyUsers: state.companyUsers,
    user: state.user,
    banks: state.banks,
    contractBookIntegrations: state.contractBookIntegrations,
    contractBookContracts: state.contractBookContracts,
    companyPricingPackages: state.companyPricingPackages,
    pricingPackages: state.pricingPackages,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getBanks: getBanks,
    getContractTemplates: getContractTemplates,
    addDepartment: addDepartment,
    getDepartments: getDepartments,
    addEmployee: addEmployee,
    updateDocument: updateDocument,
    getCompanyUsers: getCompanyUsers,
    getContractBookIntegrations: getContractBookIntegrations,
    getContractBookContracts: getContractBookContracts,
    getCompanyPricingPackages: getCompanyPricingPackages,
    getPricingPackages: getPricingPackages,
  }
)(EmployeesAdd)
