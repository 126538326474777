import React from 'react'

import { fetchPayRollDeviations } from '../api/pay-roll-deviations'
import ActionTypes from '../constants/action-types'
import PayRollDeviation from '../model/payRollDeviation'
import { PayRollDeviationAction } from '../reducers/payRollDeviations'
import { isRequestError } from '../utils/error-utils'

function loadingPayRollDeviations(payRollID: string): PayRollDeviationAction {
  return {
    type: ActionTypes.PAY_ROLL_DEVIATION_LOADING,
    payRollID,
  }
}
function loadedPayRollDeviations(payRollID: string, payRollDeviations: PayRollDeviation[]): PayRollDeviationAction {
  return {
    type: ActionTypes.PAY_ROLL_DEVIATION_LOADED,
    payRollDeviations,
    payRollID,
  }
}
function failedLoadingPayRollDeviations(payRollID: string, error: Error): PayRollDeviationAction {
  return {
    type: ActionTypes.PAY_ROLL_DEVIATION_LOAD_FAILED,
    error,
    payRollID,
  }
}

export function addedPayRollDeviation(payRollDeviation: PayRollDeviation): PayRollDeviationAction {
  return {
    type: ActionTypes.PAY_ROLL_DEVIATION_ADDED,
    payRollDeviation,
  }
}

export function updatedPayRollDeviation(
  payRollDeviationID: string,
  payRollDeviation: PayRollDeviation
): PayRollDeviationAction {
  return {
    type: ActionTypes.PAY_ROLL_DEVIATION_UPDATED,
    payRollDeviationID,
    payRollDeviation,
  }
}
export function deletedPayRollDeviation(payRollDeviationID: string): PayRollDeviationAction {
  return {
    type: ActionTypes.PAY_ROLL_DEVIATION_DELETED,
    payRollDeviationID,
  }
}

export function getPayRollDeviations(payRollID: string) {
  return (dispatch: React.Dispatch<any>): Promise<PayRollDeviation[] | void> => {
    dispatch(loadingPayRollDeviations(payRollID))
    return fetchPayRollDeviations(payRollID)
      .then((res) => {
        dispatch(loadedPayRollDeviations(payRollID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingPayRollDeviations(payRollID, e))
        }
      })
  }
}
