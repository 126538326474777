import Warning from '../model/warning'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchWarnings(companyID: string, limit: number, offset: number): Promise<RequestResponse<Warning[]>> {
  return secureRequest('GET', url('/v2/warnings', { companyID, limit, offset }))
}

export function delWarning(warningID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/warnings/' + warningID))
}

export function delAllWarnings(companyID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/warnings', { companyID }))
}
