import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import PaymentIntegration from '../model/paymentIntegration'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyPaymentIntegrationReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  companyPaymentIntegrations: List<PaymentIntegration>
  error: Error | null
}

const Params = Record<CompanyPaymentIntegrationReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  companyPaymentIntegrations: List<PaymentIntegration>(),
  error: null,
})

export interface CompanyPaymentIntegrationAction extends ReducerAction {
  companyID?: string
  companyPaymentIntegrations?: PaymentIntegration[]
  companyPaymentIntegration?: PaymentIntegration
}

export default (
  state: Record<CompanyPaymentIntegrationReducer> = Params(),
  action: CompanyPaymentIntegrationAction = { type: '' }
): Record<CompanyPaymentIntegrationReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.COMPANY_PAYMENT_INTEGRATIONS_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.COMPANY_PAYMENT_INTEGRATIONS_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        companyPaymentIntegrations: List<PaymentIntegration>(action.companyPaymentIntegrations),
      })
    case ActionTypes.COMPANY_PAYMENT_INTEGRATIONS_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.COMPANY_PAYMENT_INTEGRATION_ADDED:
    case ActionTypes.COMPANY_PAYMENT_INTEGRATION_UPDATED:
    case ActionTypes.COMPANY_PAYMENT_INTEGRATION_DELETED:
      if (!action.companyPaymentIntegration) {
        return state
      }
      idx = state
        .get('companyPaymentIntegrations')
        .findIndex(
          (integration) => !!action.companyPaymentIntegration && integration.id === action.companyPaymentIntegration.id
        )
      switch (action.type) {
        case ActionTypes.COMPANY_PAYMENT_INTEGRATION_ADDED:
          if (idx !== -1) {
            return state
          }
          return state.set(
            'companyPaymentIntegrations',
            state.get('companyPaymentIntegrations').push(action.companyPaymentIntegration)
          )
        case ActionTypes.COMPANY_PAYMENT_INTEGRATION_UPDATED:
          if (idx === -1) {
            return state
          }
          return state.set(
            'companyPaymentIntegrations',
            state.get('companyPaymentIntegrations').set(idx, action.companyPaymentIntegration)
          )
        case ActionTypes.COMPANY_PAYMENT_INTEGRATION_DELETED:
          if (idx === -1) {
            return state
          }
          return state.set('companyPaymentIntegrations', state.get('companyPaymentIntegrations').delete(idx))
        default:
          return state
      }

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
