import React, { ReactElement } from 'react'

import Employee from '../../../model/employee'
import { EmploymentReducer } from '../../../reducers/employments'
import { formatDate, getDate, isTimeBefore } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { setByPath } from '../../../utils/object-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import DatePicker from '../../elements/date-picker'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  employee: Employee
  employments: EmploymentReducer
  endDate: Date
}

export type Fields = {
  endDate: Date
}

function TerminateEmployeeForm(props: Props & FormComponentProps<Fields, Fields>): ReactElement | null {
  const { decorateField } = props

  const isFreelancer = props.employee.affiliationType === 'Freelancer'
  const fieldName = isFreelancer
    ? t('employee_terminate.form.end_date.freelancer')
    : t('employee_terminate.form.end_date.standard')

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('endDate', {
            placeholder: props.employee.immutableEndDate
              ? t('employee_terminate.form.end_date.deadline_format', {
                  field: fieldName,
                  date: formatDate(props.employee.immutableEndDate),
                })
              : fieldName,
            validate: (val) => {
              if (!val) {
                return t('employee_terminate.form.end_date.required', { field: fieldName })
              }
              if (props.employee.immutableEndDate && isTimeBefore(val, getDate(props.employee.immutableEndDate))) {
                return t('employee_terminate.form.end_date.not_before_immutable', { field: fieldName })
              }
              return null
            },
          })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" type="danger" size="large" block>
            {t(isFreelancer ? 'employee_terminate.form.submit.freelancer' : 'employee_terminate.form.submit.standard', {
              name: props.employee.name,
            })}
          </Button>
        </Col>
      </Row>
      {props.employments.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, Fields>({
  mapPropsToFields: (props) => ({
    endDate: props.endDate,
  }),
  onChange: (key, val, allValues, options, props) => {
    const values = {}
    if (key === 'endDate') {
      if (
        props.employee.immutableEndDate &&
        val instanceof Date &&
        isTimeBefore(val, getDate(props.employee.immutableEndDate))
      ) {
        setByPath(values, key, getDate(props.employee.immutableEndDate))
      } else {
        setByPath(values, key, val)
      }
    } else {
      setByPath(values, key, val)
    }
    return values
  },
  onSubmit: (values) => values,
})(TerminateEmployeeForm)
