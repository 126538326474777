import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import AsynchronousTask from '../../model/asynchronousTask'
import Company from '../../model/company'
import { getDate } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Title from '../elements/Title'
import ReportButton, { DSTReportFields } from '../widgets/ReportButton'

type Props = {
  visible: boolean
  company: Company
  asynchronousTasks: List<AsynchronousTask>

  addAlert: addAlertSignature
}

type Fields = {
  period: string
  year: number
}

function ReportDSTModal(props: Props & FormComponentProps<Fields, Fields>): ReactElement | null {
  const [buttonState, setButtonState] = useState(1)
  const [disabled, setDisabled] = useState(false)

  const getFields = (): DSTReportFields => {
    const period = props.getFieldValue('period')
    const year = props.getFieldValue('year')
    return {
      type: 'DST',
      companyID: props.company.id,
      period,
      year,
    }
  }

  const { decorateField } = props
  const periods = [
    { id: 'Q1', name: t('report_dst.manual.form.period.q1') },
    { id: 'Q2', name: t('report_dst.manual.form.period.q2') },
    { id: 'Q3', name: t('report_dst.manual.form.period.q3') },
    { id: 'Q4', name: t('report_dst.manual.form.period.q4') },
    { id: 'Yearly', name: t('report_dst.manual.form.period.yearly') },
  ]

  const years = [2020, 2021, 2022, 2023, 2024] // FIXME:  A better dynamic solution

  return (
    <Card className="company-report-dst">
      <Title>{t('report_dst.manual.title')}</Title>
      <Row>
        <Col span={12}>
          {decorateField('year', {
            skipWrapper: true,
            placeholder: t('report_dst.manual.form.year'),
          })(
            <Select
              dropdownMatchSelectWidth={false}
              onChange={() => setButtonState(buttonState + 1)}
              disabled={disabled}
            >
              {years.map((year) => {
                return (
                  <Select.Option key={year} value={year.toString()}>
                    {year}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
        <Col span={12}>
          {decorateField('period', {
            skipWrapper: true,
            placeholder: t('report_dst.manual.form.period'),
          })(
            <Select
              dropdownMatchSelectWidth={false}
              onChange={() => setButtonState(buttonState + 1)}
              disabled={disabled}
            >
              {periods.map((period) => {
                return (
                  <Select.Option key={period.id} value={period.id}>
                    {period.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ReportButton
            onStateChange={(ready) => setDisabled(!ready)}
            state={buttonState}
            asynchronousTasks={props.asynchronousTasks}
            type={'DST'}
            addAlert={props.addAlert}
            getFields={getFields}
            buttonType="secondary"
          />
        </Col>
      </Row>
    </Card>
  )
}

export default withValidations<Props, Fields, Fields>({
  mapPropsToFields: () => {
    const month = getDate().getMonth()
    const period =
      month >= 0 && month <= 2
        ? 'Q1'
        : month >= 3 && month <= 5
        ? 'Q2'
        : month >= 6 && month <= 8
        ? 'Q3'
        : month >= 9 && month <= 11
        ? 'Q4'
        : 'Yearly'
    return { period: period, year: 2023 }
  },
  onSubmit: (values) => values,
})(ReportDSTModal)
