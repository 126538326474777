import React, { ReactElement, useEffect, useState } from 'react'

import { ApiKeyCreationFields } from '../../model/apiKey'
import { ApiKeyReducer } from '../../reducers/apiKeys'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import ApiKeyEditForm, { ApiKeyResult } from './ApiKeyEditForm'

type Props = {
  visible: boolean
  apiKeyID?: string
  apiKeys: ApiKeyReducer

  addApiKey: (apiKey: ApiKeyCreationFields) => void
}

export default function ApiKeyEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, apiKeys } = props
  useEffect(() => {
    visibleComponentDidUpdate(visible, apiKeys.error, error, setError)
  }, [visible, apiKeys, error])

  const handleSubmit = (values: ApiKeyResult) => {
    props.addApiKey({ description: values.description, scopes: values.scopes })
  }

  return (
    <Card>
      <Subtitle>{props.apiKeyID ? t('api_key.edit.title.edit') : t('api_key.edit.title.create')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <ApiKeyEditForm apiKeyID={props.apiKeyID} apiKeys={props.apiKeys} onSubmit={handleSubmit} />
    </Card>
  )
}
