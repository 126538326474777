import PropTypes from 'prop-types'
import RcTable from 'rc-table'
import React from 'react'
import { findDOMNode } from 'react-dom'
import shallowEqual from 'shallowequal'

import classNames from '../_util/classNames'
import warning from '../_util/warning'
import BaseIcon from '../../elements/base-icon'
import Spin from '../../elements/spin'
import Pagination from '../pagination'
import Column from './Column'
import ColumnGroup from './ColumnGroup'
import createBodyRow from './createBodyRow'
import createStore from './createStore'
import FilterDropdown from './filterDropdown'
import SelectionBox from './SelectionBox'
import SelectionCheckboxAll from './SelectionCheckboxAll'
import { flatArray, flatFilter, normalizeColumns, treeMap } from './util'

function noop() {}

function stopPropagation(e) {
  e.stopPropagation()
  if (e.nativeEvent.stopImmediatePropagation) {
    e.nativeEvent.stopImmediatePropagation()
  }
}

function getColumnKey(column, index) {
  return column.key || column.dataIndex || index
}

const defaultLocale = {
  filterTitle: 'Filter menu',
  filterConfirm: 'OK',
  filterReset: 'Reset',
  emptyText: (
    <span>
      <BaseIcon type="frown-o" />
      No data
    </span>
  ),
  selectAll: 'Select current page',
  selectInvert: 'Invert current page',
}

const defaultPagination = {
  onChange: noop,
  onShowSizeChange: noop,
}

/**
 * Avoid creating new object, so that parent component's shouldComponentUpdate
 * can works appropriately。
 */
const emptyObject = {}

const createComponents = (components = {}) => {
  const bodyRow = components && components.body && components.body.row
  return {
    ...components,
    body: {
      ...components.body,
      row: createBodyRow(bodyRow),
    },
  }
}

function isTheSameComponents(components1 = {}, components2 = {}) {
  return (
    components1 === components2 ||
    ['table', 'header', 'body'].every((key) => shallowEqual(components1[key], components2[key]))
  )
}

function getFilteredValueColumns(state, columns) {
  return flatFilter(columns || (state || {}).columns || [], (column) => typeof column.filteredValue !== 'undefined')
}

function getFiltersFromColumns(state = {}, columns) {
  const filters = {}
  getFilteredValueColumns(state, columns).forEach((col) => {
    const colKey = getColumnKey(col)
    filters[colKey] = col.filteredValue
  })
  return filters
}

function isFiltersChanged(state, filters) {
  if (Object.keys(filters).length !== Object.keys(state.filters).length) {
    return true
  }
  return Object.keys(filters).some((columnKey) => filters[columnKey] !== state.filters[columnKey])
}

class Table extends React.Component {
  constructor(props) {
    super(props)

    warning(
      !('columnsPageRange' in props || 'columnsPageSize' in props),
      '`columnsPageRange` and `columnsPageSize` are removed, please use ' +
        'fixed columns instead, see: https://u.ant.design/fixed-columns.'
    )

    this.columns = props.columns || normalizeColumns(props.children)

    this.state = {
      ...this.getSortStateFromColumns(),
      // 减少状态
      filters: this.getFiltersFromColumns(),
      pagination: this.getDefaultPagination(props),
      prevProps: props,
    }

    this.CheckboxPropsCache = {}

    this.store = createStore({
      selectedRowKeys: (props.rowSelection || {}).selectedRowKeys || [],
      selectionDirty: false,
    })
  }

  getCheckboxPropsByItem = (item, index) => {
    const { rowSelection = {} } = this.props
    if (!rowSelection.getCheckboxProps) {
      return {}
    }
    const key = this.getRecordKey(item, index)
    // Cache checkboxProps
    if (!this.CheckboxPropsCache[key]) {
      this.CheckboxPropsCache[key] = rowSelection.getCheckboxProps(item)
    }
    return this.CheckboxPropsCache[key]
  }

  getDefaultSelection() {
    const { rowSelection = {} } = this.props
    if (!rowSelection.getCheckboxProps) {
      return []
    }
    return this.getFlatData()
      .filter((item, rowIndex) => this.getCheckboxPropsByItem(item, rowIndex).defaultChecked)
      .map((record, rowIndex) => this.getRecordKey(record, rowIndex))
  }

  getDefaultPagination(props) {
    const pagination = props.pagination || {}
    return this.hasPagination(props)
      ? {
          ...defaultPagination,
          ...pagination,
          current: pagination.defaultCurrent || pagination.current || 1,
          pageSize: pagination.defaultPageSize || pagination.pageSize || 10,
        }
      : {}
  }

  getLocale() {
    let locale = {}
    if (this.context.antLocale && this.context.antLocale.Table) {
      locale = this.context.antLocale.Table
    }
    return {
      ...defaultLocale,
      ...locale,
      ...this.props.locale,
    }
  }

  setSelectedRowKeys(selectedRowKeys, { selectWay, record, checked, changeRowKeys }) {
    const { rowSelection = {} } = this.props
    if (rowSelection && !('selectedRowKeys' in rowSelection)) {
      this.store.setState({ selectedRowKeys })
    }
    const data = this.getFlatData()
    if (!rowSelection.onChange && !rowSelection[selectWay]) {
      return
    }
    const selectedRows = data.filter((row, i) => selectedRowKeys.indexOf(this.getRecordKey(row, i)) >= 0)
    if (rowSelection.onChange) {
      rowSelection.onChange(selectedRowKeys, selectedRows)
    }
    if (selectWay === 'onSelect' && rowSelection.onSelect) {
      rowSelection.onSelect(record, checked, selectedRows)
    } else if (selectWay === 'onSelectAll' && rowSelection.onSelectAll) {
      const changeRows = data.filter((row, i) => changeRowKeys.indexOf(this.getRecordKey(row, i)) >= 0)
      rowSelection.onSelectAll(checked, selectedRows, changeRows)
    } else if (selectWay === 'onSelectInvert' && rowSelection.onSelectInvert) {
      rowSelection.onSelectInvert(selectedRowKeys)
    }
  }

  hasPagination(props) {
    return (props || this.props).pagination !== false
  }

  getSortOrderColumns(columns) {
    return flatFilter(columns || this.columns || [], (column) => 'sortOrder' in column)
  }

  getFilteredValueColumns(columns) {
    return flatFilter(columns || this.columns || [], (column) => typeof column.filteredValue !== 'undefined')
  }

  getFiltersFromColumns(columns) {
    let filters = {}
    this.getFilteredValueColumns(columns).forEach((col) => {
      filters[this.getColumnKey(col)] = col.filteredValue
    })
    return filters
  }

  getSortStateFromColumns(columns) {
    // return fisrt column which sortOrder is not falsy
    const sortedColumn = this.getSortOrderColumns(columns).filter((col) => col.sortOrder)[0]
    if (sortedColumn) {
      return {
        sortColumn: sortedColumn,
        sortOrder: sortedColumn.sortOrder,
      }
    }
    return {
      sortColumn: null,
      sortOrder: null,
    }
  }

  getSorterFn() {
    const { sortOrder, sortColumn } = this.state
    if (!sortOrder || !sortColumn || typeof sortColumn.sorter !== 'function') {
      return
    }
    return (a, b) => {
      const result = sortColumn.sorter(a, b)
      if (result !== 0) {
        return sortOrder === 'descend' ? -result : result
      }
      return 0
    }
  }

  toggleSortOrder(order, column) {
    let { sortColumn, sortOrder } = this.state
    // 只同时允许一列进行排序，否则会导致排序顺序的逻辑问题
    let isSortColumn = this.isSortColumn(column)
    if (!isSortColumn) {
      // 当前列未排序
      sortOrder = order
      sortColumn = column
    } else {
      // 当前列已排序
      if (sortOrder === order) {
        // 切换为未排序状态
        sortOrder = ''
        sortColumn = null
      } else {
        // 切换为排序状态
        sortOrder = order
      }
    }
    const newState = {
      sortOrder,
      sortColumn,
    }

    // Controlled
    if (this.getSortOrderColumns().length === 0) {
      this.setState(newState)
    }

    const onChange = this.props.onChange
    if (onChange) {
      onChange.apply(
        null,
        this.prepareParamsArguments({
          ...this.state,
          ...newState,
        })
      )
    }
  }

  handleFilter = (column, nextFilters) => {
    const props = this.props
    let pagination = { ...this.state.pagination }
    const filters = {
      ...this.state.filters,
      [this.getColumnKey(column)]: nextFilters,
    }
    // Remove filters not in current columns
    const currentColumnKeys = []
    treeMap(this.columns, (c) => {
      if (!c.children) {
        currentColumnKeys.push(this.getColumnKey(c))
      }
    })
    Object.keys(filters).forEach((columnKey) => {
      if (currentColumnKeys.indexOf(columnKey) < 0) {
        delete filters[columnKey]
      }
    })

    if (props.pagination) {
      // Reset current prop
      pagination.current = 1
      pagination.onChange(pagination.current)
    }

    const newState = {
      pagination,
      filters: {},
    }
    const filtersToSetState = { ...filters }
    // Remove filters which is controlled
    this.getFilteredValueColumns().forEach((col) => {
      const columnKey = this.getColumnKey(col)
      if (columnKey) {
        delete filtersToSetState[columnKey]
      }
    })
    if (Object.keys(filtersToSetState).length > 0) {
      newState.filters = filtersToSetState
    }

    // Controlled current prop will not respond user interaction
    if (typeof props.pagination === 'object' && 'current' in props.pagination) {
      newState.pagination = {
        ...pagination,
        current: this.state.pagination.current,
      }
    }

    this.setState(newState, () => {
      this.store.setState({
        selectionDirty: false,
      })
      const onChange = this.props.onChange
      if (onChange) {
        onChange.apply(
          null,
          this.prepareParamsArguments({
            ...this.state,
            selectionDirty: false,
            filters,
            pagination,
          })
        )
      }
    })
  }

  handleSelect = (record, rowIndex, e) => {
    const checked = e.target.checked
    const defaultSelection = this.store.getState().selectionDirty ? [] : this.getDefaultSelection()
    let selectedRowKeys = this.store.getState().selectedRowKeys.concat(defaultSelection)
    let key = this.getRecordKey(record, rowIndex)
    if (checked) {
      selectedRowKeys.push(this.getRecordKey(record, rowIndex))
    } else {
      selectedRowKeys = selectedRowKeys.filter((i) => key !== i)
    }
    this.store.setState({
      selectionDirty: true,
    })
    this.setSelectedRowKeys(selectedRowKeys, {
      selectWay: 'onSelect',
      record,
      checked,
    })
  }

  handleRadioSelect = (record, rowIndex, e) => {
    const checked = e.target.checked
    const defaultSelection = this.store.getState().selectionDirty ? [] : this.getDefaultSelection()
    let selectedRowKeys = this.store.getState().selectedRowKeys.concat(defaultSelection)
    let key = this.getRecordKey(record, rowIndex)
    selectedRowKeys = [key]
    this.store.setState({
      selectionDirty: true,
    })
    this.setSelectedRowKeys(selectedRowKeys, {
      selectWay: 'onSelect',
      record,
      checked,
    })
  }

  handleSelectRow = (selectionKey, index, onSelectFunc) => {
    const data = this.getFlatCurrentPageData()
    const defaultSelection = this.store.getState().selectionDirty ? [] : this.getDefaultSelection()
    const selectedRowKeys = this.store.getState().selectedRowKeys.concat(defaultSelection)
    const changeableRowKeys = data
      .filter((item, i) => !this.getCheckboxPropsByItem(item, i).disabled)
      .map((item, i) => this.getRecordKey(item, i))

    let changeRowKeys = []
    let selectWay = ''
    let checked
    // handle default selection
    switch (selectionKey) {
      case 'all':
        changeableRowKeys.forEach((key) => {
          if (selectedRowKeys.indexOf(key) < 0) {
            selectedRowKeys.push(key)
            changeRowKeys.push(key)
          }
        })
        selectWay = 'onSelectAll'
        checked = true
        break
      case 'removeAll':
        changeableRowKeys.forEach((key) => {
          if (selectedRowKeys.indexOf(key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(key), 1)
            changeRowKeys.push(key)
          }
        })
        selectWay = 'onSelectAll'
        checked = false
        break
      case 'invert':
        changeableRowKeys.forEach((key) => {
          if (selectedRowKeys.indexOf(key) < 0) {
            selectedRowKeys.push(key)
          } else {
            selectedRowKeys.splice(selectedRowKeys.indexOf(key), 1)
          }
          changeRowKeys.push(key)
          selectWay = 'onSelectInvert'
        })
        break
      default:
        break
    }

    this.store.setState({
      selectionDirty: true,
    })
    // when select custom selection, callback selections[n].onSelect
    const { rowSelection } = this.props
    let customSelectionStartIndex = 2
    if (rowSelection && rowSelection.hideDefaultSelections) {
      customSelectionStartIndex = 0
    }
    if (index >= customSelectionStartIndex && typeof onSelectFunc === 'function') {
      return onSelectFunc(changeableRowKeys)
    }
    this.setSelectedRowKeys(selectedRowKeys, {
      selectWay: selectWay,
      checked,
      changeRowKeys,
    })
  }

  handlePageChange = (current, ...otherArguments) => {
    const props = this.props
    let pagination = { ...this.state.pagination }
    if (current) {
      pagination.current = current
    } else {
      pagination.current = pagination.current || 1
    }
    pagination.onChange(pagination.current, ...otherArguments)

    const newState = {
      pagination,
    }
    // Controlled current prop will not respond user interaction
    if (props.pagination && typeof props.pagination === 'object' && 'current' in props.pagination) {
      newState.pagination = {
        ...pagination,
        current: this.state.pagination.current,
      }
    }
    this.setState(newState)

    this.store.setState({
      selectionDirty: false,
    })

    const onChange = this.props.onChange
    if (onChange) {
      onChange.apply(
        null,
        this.prepareParamsArguments({
          ...this.state,
          selectionDirty: false,
          pagination,
        })
      )
    }
  }

  renderSelectionBox = (type) => {
    return (_, record, index) => {
      let rowIndex = this.getRecordKey(record, index) // 从 1 开始
      const props = this.getCheckboxPropsByItem(record, index)
      const handleChange = (e) => {
        type === 'radio' ? this.handleRadioSelect(record, rowIndex, e) : this.handleSelect(record, rowIndex, e)
      }

      return (
        <span onClick={stopPropagation}>
          <SelectionBox
            type={type}
            store={this.store}
            rowIndex={rowIndex}
            disabled={props.disabled}
            onChange={handleChange}
            defaultSelection={this.getDefaultSelection()}
          />
        </span>
      )
    }
  }

  getRecordKey = (record, index) => {
    const rowKey = this.props.rowKey
    const recordKey = typeof rowKey === 'function' ? rowKey(record, index) : record[rowKey]
    warning(
      recordKey !== undefined,
      'Each record in dataSource of table should have a unique `key` prop, or set `rowKey` to an unique primary key,' +
        'see https://u.ant.design/table-row-key'
    )
    return recordKey === undefined ? index : recordKey
  }

  getPopupContainer = () => {
    return findDOMNode(this)
  }

  renderRowSelection() {
    const { prefixCls, rowSelection } = this.props
    const columns = this.columns.concat()
    if (rowSelection) {
      const data = this.getFlatCurrentPageData().filter((item, index) => {
        if (rowSelection.getCheckboxProps) {
          return !this.getCheckboxPropsByItem(item, index).disabled
        }
        return true
      })
      let selectionColumnClass = classNames(`${prefixCls}-selection-column`, {
        [`${prefixCls}-selection-column-custom`]: rowSelection.selections,
      })
      const selectionColumn = {
        key: 'selection-column',
        render: this.renderSelectionBox(rowSelection.type),
        className: selectionColumnClass,
      }
      if (rowSelection.type !== 'radio') {
        const checkboxAllDisabled = data.every((item, index) => this.getCheckboxPropsByItem(item, index).disabled)
        selectionColumn.title = (
          <SelectionCheckboxAll
            store={this.store}
            locale={this.getLocale()}
            data={data}
            getCheckboxPropsByItem={this.getCheckboxPropsByItem}
            getRecordKey={this.getRecordKey}
            disabled={checkboxAllDisabled}
            prefixCls={prefixCls}
            onSelect={this.handleSelectRow}
            selections={rowSelection.selections}
            hideDefaultSelections={rowSelection.hideDefaultSelections}
            getPopupContainer={this.getPopupContainer}
          />
        )
      }
      if (columns.some((column) => column.fixed === 'left' || column.fixed === true)) {
        selectionColumn.fixed = 'left'
      }
      if (columns[0] && columns[0].key === 'selection-column') {
        columns[0] = selectionColumn
      } else {
        columns.unshift(selectionColumn)
      }
    }
    return columns
  }

  getColumnKey(column, index) {
    return column.key || column.dataIndex || index
  }

  getMaxCurrent(total) {
    const { current, pageSize } = this.state.pagination
    if ((current - 1) * pageSize >= total) {
      return Math.floor((total - 1) / pageSize) + 1
    }
    return current
  }

  isSortColumn(column) {
    const { sortColumn } = this.state
    if (!column || !sortColumn) {
      return false
    }
    return this.getColumnKey(sortColumn) === this.getColumnKey(column)
  }

  renderColumnsDropdown(columns) {
    const { prefixCls, dropdownPrefixCls } = this.props
    const { sortOrder } = this.state
    const locale = this.getLocale()
    return treeMap(columns, (originColumn, i) => {
      let column = { ...originColumn }
      let key = this.getColumnKey(column, i)
      let filterDropdown
      let sortButton
      if ((column.filters && column.filters.length > 0) || column.filterDropdown) {
        let colFilters = this.state.filters[key] || []
        filterDropdown = (
          <FilterDropdown
            locale={locale}
            column={column}
            selectedKeys={colFilters}
            confirmFilter={this.handleFilter}
            prefixCls={`${prefixCls}-filter`}
            dropdownPrefixCls={dropdownPrefixCls || 'ant-dropdown'}
            getPopupContainer={this.getPopupContainer}
          />
        )
      }
      let title = column.title
      if (column.sorter) {
        let isSortColumn = this.isSortColumn(column)
        if (isSortColumn) {
          column.className = column.className || ''
          if (sortOrder) {
            column.className += ` ${prefixCls}-column-sort`
          }
        }
        const isAscend = isSortColumn && sortOrder === 'ascend'
        const isDescend = isSortColumn && sortOrder === 'descend'
        title = (
          <span
            className={`${prefixCls}-column-sorter-toggle`}
            onClick={() => this.toggleSortOrder(isAscend ? 'descend' : 'ascend', column)}
          >
            {title}
          </span>
        )
        sortButton = (
          <div className={`${prefixCls}-column-sorter`}>
            <span
              className={`${prefixCls}-column-sorter-up ${isAscend ? 'on' : 'off'}`}
              title="↑"
              onClick={() => this.toggleSortOrder('ascend', column)}
            >
              <BaseIcon type="caret-up" />
            </span>
            <span
              className={`${prefixCls}-column-sorter-down ${isDescend ? 'on' : 'off'}`}
              title="↓"
              onClick={() => this.toggleSortOrder('descend', column)}
            >
              <BaseIcon type="caret-down" />
            </span>
          </div>
        )
      }
      column.title = (
        <span>
          {title}
          {sortButton}
          {filterDropdown}
        </span>
      )
      return column
    })
  }

  handleShowSizeChange = (current, pageSize) => {
    const pagination = this.state.pagination
    pagination.onShowSizeChange(current, pageSize)
    const nextPagination = {
      ...pagination,
      pageSize,
      current,
    }
    this.setState({ pagination: nextPagination })

    const onChange = this.props.onChange
    if (onChange) {
      onChange.apply(
        null,
        this.prepareParamsArguments({
          ...this.state,
          pagination: nextPagination,
        })
      )
    }
  }

  renderPagination() {
    // 强制不需要分页
    if (!this.hasPagination()) {
      return null
    }
    let size = 'default'
    const { pagination } = this.state
    if (pagination.size) {
      size = pagination.size
    } else if (this.props.size === 'middle' || this.props.size === 'small') {
      size = 'small'
    }
    let total = pagination.total || this.getLocalData().length
    return total > 0 ? (
      <Pagination
        key="pagination"
        {...pagination}
        className={classNames(pagination.className, `${this.props.prefixCls}-pagination`)}
        onChange={this.handlePageChange}
        total={total}
        size={size}
        current={this.getMaxCurrent(total)}
        onShowSizeChange={this.handleShowSizeChange}
      />
    ) : null
  }

  // Get pagination, filters, sorter
  prepareParamsArguments(state) {
    const pagination = { ...state.pagination }
    // remove useless handle function in Table.onChange
    delete pagination.onChange
    delete pagination.onShowSizeChange
    const filters = state.filters
    const sorter = {}
    if (state.sortColumn && state.sortOrder) {
      sorter.column = state.sortColumn
      sorter.order = state.sortOrder
      sorter.field = state.sortColumn.dataIndex
      sorter.columnKey = this.getColumnKey(state.sortColumn)
    }
    return [pagination, filters, sorter]
  }

  findColumn(myKey) {
    let column
    treeMap(this.columns, (c) => {
      if (this.getColumnKey(c) === myKey) {
        column = c
      }
    })
    return column
  }

  getCurrentPageData() {
    let data = this.getLocalData()
    let current
    let pageSize
    let state = this.state
    // 如果没有分页的话，默认全部展示
    if (!this.hasPagination()) {
      pageSize = Number.MAX_VALUE
      current = 1
    } else {
      pageSize = state.pagination.pageSize
      current = this.getMaxCurrent(state.pagination.total || data.length)
    }

    // 分页
    // ---
    // 当数据量少于等于每页数量时，直接设置数据
    // 否则进行读取分页数据
    if (data.length > pageSize || pageSize === Number.MAX_VALUE) {
      data = data.filter((_, i) => {
        return i >= (current - 1) * pageSize && i < current * pageSize
      })
    }
    return data
  }

  getFlatData() {
    return flatArray(this.getLocalData())
  }

  getFlatCurrentPageData() {
    return flatArray(this.getCurrentPageData())
  }

  recursiveSort(data, sorterFn) {
    const { childrenColumnName = 'children' } = this.props
    return data.sort(sorterFn).map((item) =>
      item[childrenColumnName]
        ? {
            ...item,
            [childrenColumnName]: this.recursiveSort(item[childrenColumnName], sorterFn),
          }
        : item
    )
  }

  getLocalData() {
    const state = this.state
    const { dataSource } = this.props
    let data = dataSource || []
    // 优化本地排序
    data = data.slice(0)
    const sorterFn = this.getSorterFn()
    if (sorterFn) {
      data = this.recursiveSort(data, sorterFn)
    }
    // 筛选
    if (state.filters) {
      Object.keys(state.filters).forEach((columnKey) => {
        let col = this.findColumn(columnKey)
        if (!col) {
          return
        }
        let values = state.filters[columnKey] || []
        if (values.length === 0) {
          return
        }
        const onFilter = col.onFilter
        data = onFilter
          ? data.filter((record) => {
              return values.some((v) => onFilter(v, record))
            })
          : data
      })
    }
    return data
  }

  render() {
    const { style, className, prefixCls, showHeader, ...restProps } = this.props
    const data = this.getCurrentPageData()
    let columns = this.renderRowSelection()
    const expandIconAsCell = this.props.expandedRowRender && this.props.expandIconAsCell !== false
    const locale = this.getLocale()

    const classString = classNames({
      [`${prefixCls}-${this.props.size}`]: true,
      [`${prefixCls}-bordered`]: this.props.bordered,
      [`${prefixCls}-empty`]: !data.length,
      [`${prefixCls}-without-column-header`]: !showHeader,
    })

    columns = this.renderColumnsDropdown(columns)
    columns = columns.map((column, i) => {
      const newColumn = { ...column }
      newColumn.key = this.getColumnKey(newColumn, i)
      return newColumn
    })

    let expandIconColumnIndex = columns[0] && columns[0].key === 'selection-column' ? 1 : 0
    if ('expandIconColumnIndex' in restProps) {
      expandIconColumnIndex = restProps.expandIconColumnIndex
    }

    const table = (
      <RcTable
        key="table"
        {...restProps}
        prefixCls={prefixCls}
        data={data}
        columns={columns}
        showHeader={showHeader}
        className={classString}
        expandIconColumnIndex={expandIconColumnIndex}
        expandIconAsCell={expandIconAsCell}
        emptyText={() => locale.emptyText}
      />
    )

    // if there is no pagination or no data,
    // the height of spin should decrease by half of pagination
    const paginationPatchClass =
      this.hasPagination() && data && data.length !== 0
        ? `${prefixCls}-with-pagination`
        : `${prefixCls}-without-pagination`

    let loading = this.props.loading
    if (typeof loading === 'boolean') {
      loading = {
        spinning: loading,
      }
    }

    return (
      <div className={classNames(`${prefixCls}-wrapper`, className)} style={style}>
        <Spin {...loading} className={loading ? `${paginationPatchClass} ${prefixCls}-spin-holder` : ''}>
          {table}
          {this.renderPagination()}
        </Spin>
      </div>
    )
  }
}

Table.Column = Column
Table.ColumnGroup = ColumnGroup

Table.propTypes = {
  dataSource: PropTypes.array,
  columns: PropTypes.array,
  prefixCls: PropTypes.string,
  useFixedHeader: PropTypes.bool,
  rowSelection: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  bordered: PropTypes.bool,
  onChange: PropTypes.func,
  locale: PropTypes.object,
  dropdownPrefixCls: PropTypes.string,
}

Table.defaultProps = {
  dataSource: [],
  prefixCls: 'ant-table',
  useFixedHeader: false,
  rowSelection: null,
  className: '',
  size: 'large',
  loading: false,
  bordered: false,
  indentSize: 20,
  locale: {},
  rowKey: 'key',
  showHeader: true,
}

Table.contextTypes = {
  antLocale: PropTypes.object,
}

Table.getDerivedStateFromProps = function (nextProps, prevState) {
  const { prevProps } = prevState
  const columns = nextProps.columns || normalizeColumns(nextProps.children)

  let nextState = {
    ...prevState,
    prevProps: nextProps,
    columns,
  }

  if ('pagination' in nextProps || 'pagination' in prevProps) {
    const newPagination = {
      ...defaultPagination,
      ...prevState.pagination,
      ...nextProps.pagination,
    }
    newPagination.current = newPagination.current || 1
    newPagination.pageSize = newPagination.pageSize || 10

    nextState = {
      ...nextState,
      pagination: nextProps.pagination !== false ? newPagination : emptyObject,
    }
  }

  if (nextProps.rowSelection && 'selectedRowKeys' in nextProps.rowSelection) {
    nextState.selectedRowKeys = nextProps.rowSelection.selectedRowKeys || []
  } else if (prevProps.rowSelection && !nextProps.rowSelection) {
    nextState.selectedRowKeys = []
  }
  if ('dataSource' in nextProps && nextProps.dataSource !== prevProps.dataSource) {
    nextState.selectionDirty = false
    Table.CheckboxPropsCache = {}
  }

  // Update filters
  const filteredValueColumns = getFilteredValueColumns(nextState, nextState.columns)
  if (filteredValueColumns.length > 0) {
    const filtersFromColumns = getFiltersFromColumns(nextState, nextState.columns)
    const newFilters = { ...nextState.filters }
    Object.keys(filtersFromColumns).forEach((key) => {
      newFilters[key] = filtersFromColumns[key]
    })
    if (isFiltersChanged(nextState, newFilters)) {
      nextState = {
        ...nextState,
        filters: newFilters,
      }
    }
  }

  if (!isTheSameComponents(nextProps.components, prevProps.components)) {
    const components = createComponents(nextProps.components)

    nextState = {
      ...nextState,
      components,
    }
  }

  return nextState
}

export default Table
