import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import ContractBookDraft from '../model/contractBookDraft'
import { ReducerAction } from '../utils/reducer-utils'

export interface ContractBookDraftReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  saving: boolean
  drafts: List<ContractBookDraft>
  error: Error | null
}

const Params = Record<ContractBookDraftReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  saving: false,
  drafts: List<ContractBookDraft>(),
  error: null,
})

export interface ContractBookDraftAction extends ReducerAction {
  companyID?: string
  contractBookID?: string
  draft?: ContractBookDraft
}

export default (
  state: Record<ContractBookDraftReducer> = Params(),
  action: ContractBookDraftAction = { type: '' }
): Record<ContractBookDraftReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  let draft = null
  switch (action.type) {
    case ActionTypes.CONTRACT_BOOK_DRAFT_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CONTRACT_BOOK_DRAFT_ADDED:
      if (!action.draft) {
        return state
      }
      draft = { ...action.draft, contractBookID: action.contractBookID }
      idx = state.get('drafts').findIndex((item) => !!action.draft && item.id === action.draft.id)
      if (idx !== -1) {
        return state.set('saving', false).set('drafts', state.get('drafts').set(idx, draft))
      }
      return state.set('saving', false).set('drafts', state.get('drafts').push(draft))
    case ActionTypes.CONTRACT_BOOK_DRAFT_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
