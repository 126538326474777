import React from 'react'

import { delCompanyIntegration, fetchCompanyIntegrations } from '../api/company-integrations'
import ActionTypes from '../constants/action-types'
import CompanyIntegration from '../model/companyIntegration'
import { CompanyIntegrationAction } from '../reducers/companyIntegrations'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingCompanyIntegrations(): CompanyIntegrationAction {
  return {
    type: ActionTypes.COMPANY_INTEGRATION_LOADING,
  }
}
export function loadedCompanyIntegrations(
  companyID: string,
  companyIntegrations: CompanyIntegration[]
): CompanyIntegrationAction {
  return {
    type: ActionTypes.COMPANY_INTEGRATION_LOADED,
    companyIntegrations,
    companyID,
  }
}
function failedLoadingCompanyIntegrations(companyID: string, error: Error): CompanyIntegrationAction {
  return {
    type: ActionTypes.COMPANY_INTEGRATION_LOAD_FAILED,
    error,
    companyID,
  }
}

function deletingCompanyIntegration(companyID: string): CompanyIntegrationAction {
  return {
    type: ActionTypes.COMPANY_INTEGRATION_DELETING,
    companyID,
  }
}
export function deletedCompanyIntegration(companyID: string, companyIntegrationID: string): CompanyIntegrationAction {
  return {
    type: ActionTypes.COMPANY_INTEGRATION_DELETED,
    companyID,
    companyIntegrationID,
  }
}
function failedDeletingCompanyIntegration(companyID: string, error: Error): CompanyIntegrationAction {
  return {
    type: ActionTypes.COMPANY_INTEGRATION_DELETE_FAILED,
    error,
    companyID,
  }
}

export function getCompanyIntegrations() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<CompanyIntegration[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingCompanyIntegrations())
    return fetchCompanyIntegrations(companyID)
      .then((res) => {
        dispatch(loadedCompanyIntegrations(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompanyIntegrations(companyID, e))
        }
      })
  }
}

export function deleteCompanyIntegration(companyIntegrationID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingCompanyIntegration(companyID))
    return delCompanyIntegration(companyID, companyIntegrationID)
      .then(() => {
        dispatch(deletedCompanyIntegration(companyID, companyIntegrationID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingCompanyIntegration(companyID, e))
        }
      })
  }
}
