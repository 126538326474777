import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../../model/company'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import SalaryCycle from '../../../model/salaryCycle'
import SalaryType from '../../../model/salaryType'
import SupplementType from '../../../model/supplementType'
import { ContractReducer } from '../../../reducers/contracts'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate, getDate, isSameOrAfter } from '../../../utils/date-utils'
import { EmployeePayType, getEmployeePayType } from '../../../utils/employee-utils'
import { getValidFrom } from '../../../utils/employment-utils'
import { formatError } from '../../../utils/error-utils'
import { isVacationSupplement } from '../../../utils/remuneration-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import EmploymentPayEditForm, { PayEditResult } from './EmploymentPayEditForm'

type Props = {
  visible: boolean
  employee: Employee
  mutableContract: Contract
  contracts: ContractReducer
  company: Company
  salaryCycles: List<SalaryCycle>
  leaveTypes: List<LeaveType>
  supplementTypes: List<SupplementType>
  salaryTypes: List<SalaryType>
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields, setViewing?: boolean, removeOrphans?: boolean) => void
}

export default function EmploymentPayEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, contracts } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, contracts.error, error, setError)
  }, [visible, contracts, error])

  const getVacationType = () => {
    let vacationType = 'none'

    const contract = props.mutableContract

    if (!contract.remuneration) {
      return vacationType
    }

    if (contract.remuneration.leave.length > 0) {
      contract.remuneration.leave.forEach((row) => {
        const leaveType = props.leaveTypes.find((type) => type.id === row.typeID)
        if (leaveType) {
          switch (leaveType.name) {
            case 'DenmarkVacationPaid':
            case 'DenmarkVacationAccrual':
              vacationType = 'paid'
              break
            case 'DenmarkVacationNoPay':
            case 'DenmarkVacationFund':
            case 'DenmarkVacationFundWithPension':
              vacationType = 'unpaid'
              break
            default:
              break
          }
        }
      })
    }
    return vacationType
  }

  const greatPrayerDayAdjustment = (contract: ContractMutableFields, orgPayType: EmployeePayType) => {
    if (!contract.remuneration) {
      return contract
    }

    if (getEmployeePayType(contract) == orgPayType) {
      return contract
    }

    const fromSalaried = getEmployeePayType(contract) !== 'Salaried'

    // Make sure the great prayer day is in effect
    if (!isSameOrAfter(getDate(), getDate('2024-01-01'))) {
      return contract
    }

    const gpdsupplement = props.supplementTypes.find(
      (supplement) => supplement.name == 'DenmarkGreatPrayerDaySupplement'
    )
    if (!gpdsupplement) {
      return contract
    }

    if (fromSalaried) {
      contract.remuneration.supplements = contract.remuneration.supplements.filter(
        (supplement) => supplement.typeID != gpdsupplement.id
      )
    } else {
      const has = contract.remuneration.supplements.some(
        (supplement) => supplement.type?.name === 'DenmarkGreatPrayerDaySupplement'
      )
      if (!has) {
        contract.remuneration.supplements.push({
          typeID: gpdsupplement.id,
          compensationRate: 0.0045,
        })
      }
    }

    return contract
  }

  const adjustVacationForContract = (contract: ContractMutableFields) => {
    if (!contract.remuneration) {
      return contract
    }
    // If we allow paid for non-salaried, do nothing
    if (props.company.settingsEnabled.some((setting) => setting === 'AllowNonSalariedPaidVacation')) {
      return contract
    }
    // Check for vacation as hourly paid
    if (getEmployeePayType(contract) !== 'Salaried') {
      // Check for paid vacation
      if (getVacationType() === 'paid') {
        // Switch to vacation without pay, first by filtering out those that are paid
        contract.remuneration.leave = contract.remuneration.leave.filter((leave) => {
          let leaveType = leave.type
          if (!leaveType) {
            leaveType = props.leaveTypes.find((type) => type.id === leave.typeID)
          }
          if (!leaveType) {
            return true
          }
          return leaveType.name !== 'DenmarkVacationPaid' && leaveType.name !== 'DenmarkVacationAccrual'
        })
        const leaveType = props.leaveTypes.find((type) => type.name === 'DenmarkVacationFund')
        if (leaveType) {
          // then adding our unpaid one
          contract.remuneration.leave.push({ typeID: leaveType.id, days: 25 })
          // leave those not vacation supplements
          contract.remuneration.supplements = contract.remuneration.supplements.filter(
            (supplement) => !isVacationSupplement(props.supplementTypes.toArray(), supplement)
          )
        }
      }
    }
    return contract
  }

  const handleSubmit = (values: PayEditResult) => {
    if (!props.mutableContract.remuneration) {
      return
    }
    let contract = adjustVacationForContract({
      ...props.mutableContract,
      salaryCycleID: values.salaryCycleID,
      weeklyHours: values.weeklyHours,
      workCycle: values.workCycle,
      workCycleAnchorDate: values.workCycleAnchorDate,
      periodWorkHours: values.periodWorkHours || undefined,
      remunerationType: values.remunerationType,
      remuneration: { ...props.mutableContract.remuneration, ...values.remuneration },
    })
    contract = greatPrayerDayAdjustment(contract, values.orgPayType)

    if (getEmployeePayType(contract) === 'Hourly Paid') {
      contract.periodWorkHours = undefined
    }

    const { validFrom, canUpdate } = getValidFrom(props.employee, contract)
    if (canUpdate) {
      props.updateContract(contract, true, false)
    } else {
      contract.validFrom = formatAPIDate(validFrom)
      props.addContract(contract)
    }
  }

  return (
    <Card>
      <Subtitle>{t('employment_pay.edit.title')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <EmploymentPayEditForm
        mutableContract={props.mutableContract}
        validFrom={getValidFrom(props.employee, props.mutableContract).validFrom}
        employee={props.employee}
        contracts={props.contracts}
        company={props.company}
        salaryCycles={props.salaryCycles}
        salaryTypes={props.salaryTypes}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
