import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import LeaveAdjustment from '../model/leaveAdjustment'
import { ReducerAction } from '../utils/reducer-utils'

export interface LeaveAdjustmentReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  leaveAdjustments: List<LeaveAdjustment>
  error: Error | null
}

const Params = Record<LeaveAdjustmentReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  leaveAdjustments: List<LeaveAdjustment>(),
  error: null,
})

export interface LeaveAdjustmentAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  leaveAdjustmentID?: string
  leaveAdjustments?: LeaveAdjustment[]
  leaveAdjustment?: LeaveAdjustment
}

export default (
  state: Record<LeaveAdjustmentReducer> = Params(),
  action: LeaveAdjustmentAction = { type: '' }
): Record<LeaveAdjustmentReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.LEAVE_ADJUSTMENTS_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.LEAVE_ADJUSTMENTS_LOADED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        loaded: true,
        leaveAdjustments: List<LeaveAdjustment>(action.leaveAdjustments),
      })
    case ActionTypes.LEAVE_ADJUSTMENTS_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID,
        error: action.error,
      })
    case ActionTypes.LEAVE_ADJUSTMENTS_ADDING:
      return state
        .set('employeeID', action.employeeID || state.get('employeeID'))
        .set('saving', true)
        .set('loaded', false) // first be loaded when socket returns them
    case ActionTypes.LEAVE_ADJUSTMENTS_ADDED:
      if (!action.leaveAdjustment) {
        return state
      }
      // only update for this employee
      if (!state.get('employeeID') || action.employeeID !== state.get('employeeID')) {
        return state
      }
      idx = state
        .get('leaveAdjustments')
        .findIndex((item) => !!action.leaveAdjustment && item.id === action.leaveAdjustment.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('leaveAdjustments', state.get('leaveAdjustments').set(idx, action.leaveAdjustment))
      }
      return state
        .set('saving', false)
        .set('leaveAdjustments', state.get('leaveAdjustments').push(action.leaveAdjustment))
    case ActionTypes.LEAVE_ADJUSTMENTS_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)
    case ActionTypes.LEAVE_ADJUSTMENTS_UPDATING:
      return state
        .set('employeeID', action.employeeID || state.get('employeeID'))
        .set('saving', true)
        .set('loaded', false) // first be loaded when socket returns them
    case ActionTypes.LEAVE_ADJUSTMENTS_UPDATED:
      if (!action.leaveAdjustment) {
        return state
      }
      idx = state
        .get('leaveAdjustments')
        .findIndex((item) => item.id === action.leaveAdjustmentID && item.employeeID === action.employeeID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state
        .set('saving', false)
        .set('leaveAdjustments', state.get('leaveAdjustments').set(idx, action.leaveAdjustment))
    case ActionTypes.LEAVE_ADJUSTMENTS_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)
    case ActionTypes.LEAVE_ADJUSTMENTS_DELETING:
      return state.set('saving', true)
    case ActionTypes.LEAVE_ADJUSTMENTS_DELETED:
      idx = state.get('leaveAdjustments').findIndex((item) => item.id === action.leaveAdjustmentID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('leaveAdjustments', state.get('leaveAdjustments').delete(idx))
    case ActionTypes.LEAVE_ADJUSTMENTS_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
