import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Employee from '../../model/employee'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'

type Props = {
  employees: List<Employee>
}

type Fields = {
  employeeID?: string
}

export type AttachDocumentResult = {
  employeeID: string
}

function AttachDocumentForm(props: Props & FormComponentProps<Fields, AttachDocumentResult>): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('employeeID', {
            placeholder: t('documents.attach.form.employee_id'),
            validate: (val) => (!val ? t('documents.attach.form.employee_id.required') : null),
          })(
            <Select
              dropdownMatchSelectWidth={false}
              showSearch={true}
              filterOption={(inputValue: string, option: ReactElement) =>
                option.props.title.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
              }
            >
              {props.employees.map((employee) => {
                return (
                  <Select.Option key={employee.id} value={employee.id} title={employee.name}>
                    {employee.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('documents.attach.form.submit')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, AttachDocumentResult>({
  onSubmit: (values) => {
    return {
      employeeID: values.employeeID!,
    }
  },
})(AttachDocumentForm)
