import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import Department from '../../model/department'
import { DepartmentPermission } from '../../model/departmentUser'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import LoadingOverlay from '../widgets/LoadingOverlay'
import EditCompanyUserDepartmentsForm, { CompanyUserDepartmentResult } from './EditCompanyUserDepartmentsForm'

type Props = {
  visible: boolean
  id?: string
  company: Company
  companyUsers: CompanyUserReducer
  departments: List<Department>

  addAlert: addAlertSignature
  closeModal: () => void
  grantDepartmentUserPermission: (
    userCompanyID: string,
    departmentID: string,
    permissions: DepartmentPermission[]
  ) => void
  revokeDepartmentUserPermission: (userCompanyID: string, departmentID: string) => void
}

export default function EditCompanyUserDepartments(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const getCompanyUser = () =>
    props.companyUsers.companyUsers.find((v) => v.userID === props.id && v.companyID === props.company.id)

  const { companyUsers, addAlert, closeModal } = props
  const previousCompanyUsers = usePrevious(companyUsers)
  useEffect(() => {
    if (previousCompanyUsers && previousCompanyUsers.saving && !companyUsers.saving) {
      if (!companyUsers.error) {
        const companyUser = getCompanyUser()
        if (!companyUser) {
          return
        }
        addAlert('success', t('company_users.edit_departments.alert.success', { name: companyUser.name }), {
          timeout: 5,
        })
        closeModal()
      }
    }
  })

  const { visible } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, companyUsers.error, error, setError)
  }, [visible, companyUsers, error])

  const handleSubmit = (values: CompanyUserDepartmentResult) => {
    const companyUser = getCompanyUser()
    const companyUserID = companyUser?.id
    if (!companyUser || !companyUserID) {
      return
    }
    values.departments.forEach((department) => {
      props.grantDepartmentUserPermission(companyUserID, department.departmentID, department.permissions)
    })
    companyUser.departments.forEach((department) => {
      if (!values.departments.some((v) => v.departmentID === department.departmentID)) {
        props.revokeDepartmentUserPermission(companyUserID, department.departmentID)
      }
    })
  }

  const companyUser = getCompanyUser()
  if (!companyUser) {
    return <LoadingOverlay />
  }

  return (
    <Card className="company-user-permissions">
      <Subtitle>{t('company_users.edit_departments.title', { name: companyUser?.name })}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <EditCompanyUserDepartmentsForm
        companyUsers={props.companyUsers}
        departments={props.departments}
        companyUser={companyUser}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
