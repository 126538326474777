import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { UserReducer } from '../../reducers/user'
import { t } from '../../utils/translation-utils'
import Subtitle from '../elements/Subtitle'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'

import './Preview.css'

type Props = {
  user: UserReducer
  documentTitle: string
  documentUrl: string
  downloadUrl: string
}

export default function Preview(props: Props): ReactElement | null {
  return (
    <div className="preview">
      <LoadingOverlay style={{ zIndex: '1' }} />
      <iframe title={props.documentTitle} src={props.documentUrl} frameBorder={0} />
      <div className="preview-header">
        <Subtitle>{props.documentTitle}</Subtitle>
        <Link to={props.downloadUrl} className="preview-download" target="_blank" rel="noopener noreferrer">
          {t('preview.download_pdf')}
        </Link>
        {(props.user.userType === 'Admin' || props.user.userType === 'Support') && (
          <span
            onClick={() => {
              if (jsBrowserHistory.length > 1) {
                jsBrowserHistory.goBack()
              } else {
                window.close()
              }
            }}
            className="preview-close"
          >
            {t('preview.close')}
          </span>
        )}
      </div>
    </div>
  )
}
