import React from 'react'

import { fetchCompanyPaymentIntegrations } from '../api/company-payment-integrations'
import ActionTypes from '../constants/action-types'
import PaymentIntegration from '../model/paymentIntegration'
import { CompanyPaymentIntegrationAction } from '../reducers/companyPaymentIntegrations'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingCompanyPaymentIntegrations(): CompanyPaymentIntegrationAction {
  return {
    type: ActionTypes.COMPANY_PAYMENT_INTEGRATIONS_LOADING,
  }
}
function loadedCompanyPaymentIntegrations(
  companyID: string,
  companyPaymentIntegrations: PaymentIntegration[]
): CompanyPaymentIntegrationAction {
  return {
    type: ActionTypes.COMPANY_PAYMENT_INTEGRATIONS_LOADED,
    companyID,
    companyPaymentIntegrations,
  }
}
function failedLoadingCompanyPaymentIntegrations(companyID: string, error: Error): CompanyPaymentIntegrationAction {
  return {
    type: ActionTypes.COMPANY_PAYMENT_INTEGRATIONS_LOAD_FAILED,
    error,
    companyID,
  }
}

export function addedCompanyPaymentIntegration(
  companyID: string,
  companyPaymentIntegration: PaymentIntegration
): CompanyPaymentIntegrationAction {
  return {
    type: ActionTypes.COMPANY_PAYMENT_INTEGRATION_ADDED,
    companyID,
    companyPaymentIntegration,
  }
}
export function updatedCompanyPaymentIntegration(
  companyID: string,
  companyPaymentIntegration: PaymentIntegration
): CompanyPaymentIntegrationAction {
  return {
    type: ActionTypes.COMPANY_PAYMENT_INTEGRATION_UPDATED,
    companyID,
    companyPaymentIntegration,
  }
}
export function deletedCompanyPaymentIntegration(
  companyID: string,
  companyPaymentIntegration: PaymentIntegration
): CompanyPaymentIntegrationAction {
  return {
    type: ActionTypes.COMPANY_PAYMENT_INTEGRATION_DELETED,
    companyID,
    companyPaymentIntegration,
  }
}

export function getCompanyPaymentIntegrations() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<PaymentIntegration[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingCompanyPaymentIntegrations())
    return fetchCompanyPaymentIntegrations(companyID)
      .then((res) => {
        dispatch(loadedCompanyPaymentIntegrations(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompanyPaymentIntegrations(companyID, e))
        }
      })
  }
}
