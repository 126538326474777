import DepartmentUser, { DepartmentPermission } from '../model/departmentUser'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchDepartmentUsers(companyID: string, userID: string): Promise<RequestResponse<DepartmentUser[]>> {
  return secureRequest('GET', url('/v2/userDepartments/' + companyID, { userID }))
}

export function postDepartmentUserPermission(
  userCompanyID: string,
  departmentID: string,
  permissions: DepartmentPermission[]
): Promise<RequestResponse<DepartmentUser>> {
  return secureRequest('POST', url('/v2/userDepartmentPermissions', { userCompanyID, departmentID }), {
    body: { permissions },
  })
}

export function delDepartmentUserPermission(userCompanyID: string, departmentID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/userDepartmentPermissions', { userCompanyID, departmentID }))
}
