import React, { ReactElement } from 'react'

import Company from '../../model/company'
import UserTerms from '../widgets/UserTerms'

// NEED_NO_TRANSLATION

type Props = {
  company?: Company
}

export default function TermsContainer(props: Props): ReactElement | null {
  return <UserTerms fullHeight={true} company={props.company} />
}
