import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Shipment from '../model/shipment'
import { ReducerAction } from '../utils/reducer-utils'

export interface ShipmentReducer {
  payRollID: string | undefined
  loading: boolean
  loaded: boolean
  shipments: List<Shipment>
  error: Error | null
}

const Params = Record<ShipmentReducer>({
  payRollID: undefined,
  loading: false,
  loaded: false,
  shipments: List<Shipment>(),
  error: null,
})

export interface ShipmentAction extends ReducerAction {
  payRollID?: string
  shipments?: Shipment[]
}

export default (
  state: Record<ShipmentReducer> = Params(),
  action: ShipmentAction = { type: '' }
): Record<ShipmentReducer> => {
  switch (action.type) {
    case ActionTypes.SHIPMENT_LOADING:
      return Params({
        payRollID: action.payRollID,
        loading: true,
      })
    case ActionTypes.SHIPMENT_LOADED:
      return Params({
        payRollID: action.payRollID,
        loaded: true,
        shipments: List(action.shipments),
      })
    case ActionTypes.SHIPMENT_LOAD_FAILED:
      return Params({
        payRollID: action.payRollID,
        error: action.error || null,
      })

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
