import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Project from '../model/project'
import { ReducerAction } from '../utils/reducer-utils'

export interface ProjectReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  projects: List<Project>
  error: Error | null
}

const Params = Record<ProjectReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  projects: List<Project>(),
  error: null,
})

export interface ProjectAction extends ReducerAction {
  companyID?: string
  projects?: Project[]
  project?: Project
  projectID?: string
}

const comparator = (a: Project, b: Project) => {
  if (a.parentID === b.parentID) {
    return a.order - b.order
  }
  if (a.parentID && b.parentID) {
    return 1
  }
  if (a.parentID) {
    if (a.parentID === b.id) {
      return 1
    } else {
      return -1
    }
  } else {
    if (b.parentID === a.id) {
      return -1
    } else {
      return 1
    }
  }
}

export default (
  state: Record<ProjectReducer> = Params(),
  action: ProjectAction = { type: '' }
): Record<ProjectReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.TIME_REGISTRATION_TYPE_LOADING:
      return Params({
        loading: true,
        companyID: action.companyID,
      })
    case ActionTypes.TIME_REGISTRATION_TYPE_LOADED:
      return Params({
        loaded: true,
        companyID: action.companyID,
        projects: List<Project>(action.projects).sort(comparator),
      })
    case ActionTypes.TIME_REGISTRATION_TYPE_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', action.companyID || state.get('companyID'))
        .set('error', action.error || null)

    case ActionTypes.TIME_REGISTRATION_TYPE_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.TIME_REGISTRATION_TYPE_CREATED:
      if (!action.project) {
        return state
      }
      idx = state.get('projects').findIndex((item) => !!action.project && item.id === action.project.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('projects', state.get('projects').set(idx, action.project).sort(comparator))
      }
      return state.set('saving', false).set('projects', state.get('projects').push(action.project).sort(comparator))
    case ActionTypes.TIME_REGISTRATION_TYPE_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.TIME_REGISTRATION_TYPE_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.TIME_REGISTRATION_TYPE_UPDATED:
      if (!action.project) {
        return state
      }
      idx = state.get('projects').findIndex((item) => item.id === action.project!.id)
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('projects', state.get('projects').set(idx, action.project).sort(comparator))
    case ActionTypes.TIME_REGISTRATION_TYPE_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.TIME_REGISTRATION_TYPE_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.TIME_REGISTRATION_TYPE_DELETED:
      if (!action.projectID) {
        return state
      }
      idx = state.get('projects').findIndex((item) => item.id === action.projectID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('projects', state.get('projects').delete(idx))
    case ActionTypes.TIME_REGISTRATION_TYPE_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
