import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import React, { ReactElement, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import Company from '../../../model/company'
import StripeConfiguration, { StripeConfigurationSetup } from '../../../model/stripeConfiguration'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Title from '../../elements/Title'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import StripeSetupForm from './StripeSetupForm'

let stripePromise: Promise<Stripe | null> | null = null
if (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
}

type Props = {
  visible: boolean
  company: Company
  hasIntegration: boolean

  closeModal: () => void
  addAlert: addAlertSignature
  addStripeConfiguration: () => Promise<StripeConfigurationSetup | void>
  updateStripeConfiguration: (setupIntentID: string) => Promise<StripeConfiguration | void>
}

export default function StripeIntegrationModal(props: Props): ReactElement | null {
  const [clientSecret, setClientSecret] = useState<string>()
  const [error, setError] = useState<Error | null>(null)

  const { addStripeConfiguration } = props

  useEffectOnce(() => {
    addStripeConfiguration()
      .then((data) => {
        if (!data) {
          return
        }
        setClientSecret(data.clientSecret)
      })
      .catch((e) => setError(e.message))
  })

  if (!stripePromise) {
    return null
  }

  return (
    <Elements stripe={stripePromise}>
      <Card className="stripe-integration">
        <Title>{props.hasIntegration ? t('stripe.edit.title.edit') : t('stripe.edit.title.create')}</Title>
        {error && <Alert message={formatError(error)} type="error" showIcon />}
        {clientSecret && (
          <StripeSetupForm
            clientSecret={clientSecret}
            company={props.company}
            closeModal={props.closeModal}
            addAlert={props.addAlert}
            updateStripeConfiguration={props.updateStripeConfiguration}
          />
        )}
        {!clientSecret && <LoadingOverlay />}
      </Card>
    </Elements>
  )
}
