import React, { ReactElement } from 'react'

import { t } from '../../utils/translation-utils'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'

export default function NoEmploymentCard(): ReactElement | null {
  return (
    <Card className="no-employment-card">
      <Subtitle>{t('employee_no_employment.title')}</Subtitle>
      <p>{t('employee_no_employment.text')}</p>

      <a href="mailto:support@salary.dk">{t('employee_no_employment.link')}</a>
    </Card>
  )
}
