import React from 'react'

import {
  delContractTemplate,
  delContractTemplateContract,
  fetchContractTemplateContracts,
  fetchContractTemplates,
  postContractTemplate,
  postContractTemplateContract,
  putContractTemplate,
  putContractTemplateContract,
} from '../api/contract-templates'
import ActionTypes from '../constants/action-types'
import ContractTemplate, { ContractTemplateMutableFields } from '../model/contractTemplate'
import ContractTemplateContract, {
  ContractTemplateContractContainer,
  ContractTemplateContractMutableFields,
} from '../model/contractTemplateContract'
import { ContractTemplateContractAction } from '../reducers/contractTemplateContracts'
import { ContractTemplateAction } from '../reducers/contractTemplates'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingContractTemplates(companyID: string): ContractTemplateAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_LOADING,
    companyID,
  }
}
function loadedContractTemplates(companyID: string, contractTemplates: ContractTemplate[]): ContractTemplateAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_LOADED,
    companyID,
    contractTemplates,
  }
}
function failedLoadingContractTemplates(companyID: string, error: Error): ContractTemplateAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_LOAD_FAILED,
    companyID,
    error,
  }
}

function addingContractTemplate(companyID: string): ContractTemplateAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_ADDING,
    companyID,
  }
}
export function addedContractTemplate(
  companyID: string,
  contractTemplateID: string,
  contractTemplate: ContractTemplate
): ContractTemplateAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_ADDED,
    companyID,
    contractTemplateID,
    contractTemplate,
  }
}
function failedAddingContractTemplate(companyID: string, error: Error): ContractTemplateAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_ADD_FAILED,
    companyID,
    error,
  }
}

function updatingContractTemplate(companyID: string, contractTemplateID: string): ContractTemplateAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_UPDATING,
    companyID,
    contractTemplateID,
  }
}
export function updatedContractTemplate(
  companyID: string,
  contractTemplateID: string,
  contractTemplate: ContractTemplate
): ContractTemplateAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_UPDATED,
    companyID,
    contractTemplateID,
    contractTemplate,
  }
}
function failedUpdatingContractTemplate(companyID: string, error: Error): ContractTemplateAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_UPDATE_FAILED,
    companyID,
    error,
  }
}

function deletingContractTemplate(companyID: string, contractTemplateID: string): ContractTemplateAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_DELETING,
    companyID,
    contractTemplateID,
  }
}
export function deletedContractTemplate(companyID: string, contractTemplateID: string): ContractTemplateAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_DELETED,
    companyID,
    contractTemplateID,
  }
}
function failedDeletingContractTemplate(companyID: string, error: Error): ContractTemplateAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_DELETE_FAILED,
    companyID,
    error,
  }
}

function loadingContractTemplateContracts(contractTemplateID: string): ContractTemplateContractAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_CONTRACT_LOADING,
    contractTemplateID,
  }
}
function loadedContractTemplateContracts(
  contractTemplateID: string,
  contractTemplateContracts: ContractTemplateContractContainer[]
): ContractTemplateContractAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_CONTRACT_LOADED,
    contractTemplateID,
    contractTemplateContracts,
  }
}
function failedLoadingContractTemplateContracts(
  contractTemplateID: string,
  error: Error
): ContractTemplateContractAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_CONTRACT_LOAD_FAILED,
    contractTemplateID,
    error,
  }
}

function addingContractTemplateContract(contractTemplateID: string): ContractTemplateContractAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_CONTRACT_ADDING,
    contractTemplateID,
  }
}
export function addedContractTemplateContract(
  contractTemplateID: string,
  contractTemplateContractID: string,
  contractTemplateContract: ContractTemplateContract
): ContractTemplateContractAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_CONTRACT_ADDED,
    contractTemplateID,
    contractTemplateContractID,
    contractTemplateContract,
  }
}
function failedAddingContractTemplateContract(
  contractTemplateID: string,
  error: Error
): ContractTemplateContractAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_CONTRACT_ADD_FAILED,
    contractTemplateID,
    error,
  }
}

function updatingContractTemplateContract(
  contractTemplateID: string,
  contractTemplateContractID: string
): ContractTemplateContractAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_CONTRACT_UPDATING,
    contractTemplateID,
    contractTemplateContractID,
  }
}
export function updatedContractTemplateContract(
  contractTemplateID: string,
  contractTemplateContractID: string,
  contractTemplateContract: ContractTemplateContract
): ContractTemplateContractAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_CONTRACT_UPDATED,
    contractTemplateID,
    contractTemplateContractID,
    contractTemplateContract,
  }
}
function failedUpdatingContractTemplateContract(
  contractTemplateID: string,
  error: Error
): ContractTemplateContractAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_CONTRACT_UPDATE_FAILED,
    contractTemplateID,
    error,
  }
}

function deletingContractTemplateContract(contractTemplateContractID: string): ContractTemplateContractAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_CONTRACT_DELETING,
    contractTemplateContractID,
  }
}
export function deletedContractTemplateContract(contractTemplateContractID: string): ContractTemplateContractAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_CONTRACT_DELETED,
    contractTemplateContractID,
  }
}
function failedDeletingContractTemplateContract(
  contractTemplateContractID: string,
  error: Error
): ContractTemplateContractAction {
  return {
    type: ActionTypes.CONTRACT_TEMPLATE_CONTRACT_DELETE_FAILED,
    contractTemplateContractID,
    error,
  }
}

export function getContractTemplates() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ContractTemplate[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(loadingContractTemplates(companyID))
    return fetchContractTemplates(companyID)
      .then((res) => {
        dispatch(loadedContractTemplates(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingContractTemplates(companyID, e))
        }
      })
  }
}

export function createContractTemplate(contractTemplate: ContractTemplateMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<ContractTemplate | void> => {
    dispatch(addingContractTemplate(contractTemplate.companyID))
    return postContractTemplate(contractTemplate)
      .then((res) => {
        dispatch(addedContractTemplate(contractTemplate.companyID, res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingContractTemplate(contractTemplate.companyID, e))
        }
      })
  }
}

export function updateContractTemplate(contractTemplate: ContractTemplate) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ContractTemplate | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingContractTemplate(companyID, contractTemplate.id))
    return putContractTemplate(contractTemplate.id, contractTemplate)
      .then((res) => {
        dispatch(updatedContractTemplate(companyID, res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingContractTemplate(companyID, e))
        }
      })
  }
}

export function deleteContractTemplate(contractTemplateID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingContractTemplate(companyID, contractTemplateID))
    return delContractTemplate(contractTemplateID)
      .then(() => {
        dispatch(deletedContractTemplate(companyID, contractTemplateID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingContractTemplate(companyID, e))
        }
      })
  }
}

export function getContractTemplateContracts(contractTemplateID: string) {
  return (dispatch: React.Dispatch<any>): Promise<ContractTemplateContractContainer[] | void> => {
    dispatch(loadingContractTemplateContracts(contractTemplateID))
    return fetchContractTemplateContracts(contractTemplateID)
      .then((res) => {
        dispatch(loadedContractTemplateContracts(contractTemplateID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingContractTemplateContracts(contractTemplateID, e))
        }
      })
  }
}

export function createContractTemplateContract(contractTemplateContract: ContractTemplateContractMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<ContractTemplateContract | void> => {
    dispatch(addingContractTemplateContract(contractTemplateContract.contractTemplateID))
    return postContractTemplateContract(contractTemplateContract)
      .then((res) => {
        dispatch(addedContractTemplateContract(contractTemplateContract.contractTemplateID, res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingContractTemplateContract(contractTemplateContract.contractTemplateID, e))
        }
      })
  }
}

export function updateContractTemplateContract(contractTemplateContract: ContractTemplateContract) {
  return (dispatch: React.Dispatch<any>): Promise<ContractTemplateContract | void> => {
    dispatch(updatingContractTemplateContract(contractTemplateContract.contractTemplateID, contractTemplateContract.id))
    return putContractTemplateContract(contractTemplateContract.id, contractTemplateContract)
      .then((res) => {
        dispatch(updatedContractTemplateContract(contractTemplateContract.contractTemplateID, res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingContractTemplateContract(contractTemplateContract.contractTemplateID, e))
        }
      })
  }
}

export function deleteContractTemplateContract(contractTemplateContractID: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(deletingContractTemplateContract(contractTemplateContractID))
    return delContractTemplateContract(contractTemplateContractID)
      .then(() => {
        dispatch(deletedContractTemplateContract(contractTemplateContractID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingContractTemplateContract(contractTemplateContractID, e))
        }
      })
  }
}
