import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import CompanyUser from '../../model/companyUser'
import Department from '../../model/department'
import Employee from '../../model/employee'
import Swipe from '../../model/swipe'
import { SwipeReducer } from '../../reducers/swipes'
import { paths } from '../../routes'
import { EmployeeRowEmployeeDetails, getEmployeeRowEmployeeDetails } from '../../utils/approve-tab-utils'
import { formatDate } from '../../utils/date-utils'
import { formatError } from '../../utils/error-utils'
import { formatCurrency } from '../../utils/number-utils'
import { hasEmployeeDepartmentPermission } from '../../utils/permissions-utils'
import { t } from '../../utils/translation-utils'
import Table from '../antd/table'
import Button from '../elements/button'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import { ApproveCardEmployeeName } from './ApproveTabElements'

type Props = {
  companyUser?: CompanyUser
  swipes: SwipeReducer

  employees: Map<string, Employee>
  departments: List<Department>
  companyUsers: List<CompanyUser>

  addAlert: addAlertSignature
  approveSwipes: (ids: string[]) => void
}

export default function SwipesCard(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])

  const { swipes, addAlert } = props
  const previousSwipes = usePrevious(swipes)

  useEffect(() => {
    if (previousSwipes && previousSwipes.saving && !swipes.saving) {
      if (swipes.error) {
        addAlert('error', formatError(swipes.error))
      }
      setApproving([]) //empty it
    }
  }, [previousSwipes, swipes, addAlert, setApproving])

  const pendingSwipes: Swipe[] = props.swipes.swipes
    .filter(
      (swipe) =>
        !swipe.approved &&
        !approving.some((approve) => approve === swipe.id) &&
        hasEmployeeDepartmentPermission(props.companyUser, props.employees, swipe.employeeID, 'ApproveObjects')
    )
    .toArray()

  const approve = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      setApproving((prev) => [...prev, id])
      props.approveSwipes([id])
    }
  }
  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    const unapproved = pendingSwipes.map((reg) => reg.id)
    setApproving((prev) => [...prev, ...unapproved])
    props.approveSwipes(unapproved)
  }

  type SwipeRow = EmployeeRowEmployeeDetails & {
    key: string
    id: string
    employeeID: string
    amount: string
    date: string
    original: Swipe
  }

  const columns = [
    {
      title: t('approve_tab.swipes.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      render: (row: SwipeRow) => {
        return <ApproveCardEmployeeName {...row} linkTo={'/' + paths.SWIPE_OVERVIEW} />
      },
    },
    { title: t('approve_tab.swipes.header.date'), dataIndex: 'date', key: 'date' },
    { title: t('approve_tab.swipes.header.amount'), dataIndex: 'amount', key: 'amount' },
    {
      title: '',
      dataIndex: '',
      key: 'xApprove',
      render: (row: SwipeRow) => {
        return (
          <span className="approve-link" onClick={approve(row.id)}>
            {t('approve_tab.swipes.actions.approve')}
          </span>
        )
      },
    },
  ]

  const pendingSwipeRows: SwipeRow[] = pendingSwipes
    .map((swipe) => {
      return {
        key: swipe.id,
        id: swipe.id,
        employeeID: swipe.employeeID,
        ...getEmployeeRowEmployeeDetails(props.employees.get(swipe.employeeID), props.departments, props.companyUsers),
        date: formatDate(swipe.createdAt),
        amount: formatCurrency(swipe.amount, 2),
        original: swipe,
      }
    })
    .sort((a, b) => {
      if (a.employeeName === b.employeeName) {
        if (a.date === b.date) {
          return a.amount.localeCompare(b.amount)
        }
        return a.original.createdAt.localeCompare(b.original.createdAt)
      }
      return a.employeeName.localeCompare(b.employeeName)
    })

  if (pendingSwipes.length === 0) {
    return null
  }

  return (
    <div className="approve-box">
      <TitleMenu>
        <Button onClick={approveAll} type="primary" size="large">
          {t('approve_tab.swipes.actions.approve_all')}
        </Button>
      </TitleMenu>
      <Title>{t('approve_tab.swipes.title')}</Title>
      <Table columns={columns} dataSource={pendingSwipeRows} pagination={false} />
    </div>
  )
}
