import { List, Record } from 'immutable'

import { CompanyAccountingIntegrationType } from '../api/company-accounting-integration-setup'
import ActionTypes from '../constants/action-types'
import AccountingIntegration, { AccountingMapping } from '../model/accountingIntegration'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyAccountingIntegrationReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  accountingIntegration: AccountingIntegration | undefined
  // TODO: Remove these, and have them read it off the object
  integrationType: CompanyAccountingIntegrationType | undefined
  displayName: string | undefined
  organizationID: string | undefined
  daybookID: string | undefined
  bookingRule: string | undefined
  costCenterAccounting: string | undefined
  hasDimensions: boolean | undefined
  accountMapping: List<AccountingMapping>
  error: Error | null
}

const Params = Record<CompanyAccountingIntegrationReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  accountingIntegration: undefined,
  integrationType: undefined,
  displayName: undefined,
  organizationID: undefined,
  daybookID: undefined,
  bookingRule: undefined,
  costCenterAccounting: undefined,
  hasDimensions: undefined,
  accountMapping: List<AccountingMapping>(),
  error: null,
})

export interface CompanyAccountingIntegrationAction extends ReducerAction {
  companyID?: string
  accountingIntegration?: AccountingIntegration
  mapping?: AccountingMapping[]
}

export default (
  state: Record<CompanyAccountingIntegrationReducer> = Params(),
  action: CompanyAccountingIntegrationAction = { type: '' }
): Record<CompanyAccountingIntegrationReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  const integration = state.get('accountingIntegration')
  switch (action.type) {
    case ActionTypes.ACCOUNTING_INTEGRATION_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.ACCOUNTING_INTEGRATION_LOADED:
      if (!action.accountingIntegration) {
        return state
      }
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        accountingIntegration: action.accountingIntegration,
        integrationType: action.accountingIntegration.integrationType,
        displayName: action.accountingIntegration.displayName,
        organizationID: action.accountingIntegration.organizationID,
        daybookID: action.accountingIntegration.daybookID,
        bookingRule: action.accountingIntegration.bookingRule,
        costCenterAccounting: action.accountingIntegration.costCenterAccounting,
        hasDimensions: action.accountingIntegration.hasDimensions,
        accountMapping: List<AccountingMapping>(action.accountingIntegration.accountMapping),
      })
    case ActionTypes.ACCOUNTING_INTEGRATION_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.ACCOUNTING_INTEGRATION_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ACCOUNTING_INTEGRATION_UPDATED:
      if (!action.accountingIntegration) {
        return state
      }
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        accountingIntegration: action.accountingIntegration,
        integrationType: action.accountingIntegration.integrationType,
        displayName: action.accountingIntegration.displayName,
        organizationID: action.accountingIntegration.organizationID,
        daybookID: action.accountingIntegration.daybookID,
        bookingRule: action.accountingIntegration.bookingRule,
        costCenterAccounting: action.accountingIntegration.costCenterAccounting,
        hasDimensions: action.accountingIntegration.hasDimensions,
        accountMapping: List<AccountingMapping>(action.accountingIntegration.accountMapping),
      })
    case ActionTypes.ACCOUNTING_INTEGRATION_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.ACCOUNT_PLANS_UPDATED:
      if (!action.mapping) {
        return state
      }
      if (integration) {
        integration.accountMapping = action.mapping
      }
      return state
        .set('accountMapping', List<AccountingMapping>(action.mapping))
        .set('accountingIntegration', integration)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
