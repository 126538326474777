import React from 'react'

import {
  delDepartmentUserPermission,
  fetchDepartmentUsers,
  postDepartmentUserPermission,
} from '../api/department-users'
import ActionTypes from '../constants/action-types'
import DepartmentUser, { DepartmentPermission } from '../model/departmentUser'
import { DepartmentUserAction } from '../reducers/departmentUsers'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingDepartmentUsers(): DepartmentUserAction {
  return {
    type: ActionTypes.DEPARTMENT_USERS_LOADING,
  }
}
export function loadedDepartmentUsers(
  companyID: string,
  userID: string,
  departmentUsers: DepartmentUser[]
): DepartmentUserAction {
  return {
    type: ActionTypes.DEPARTMENT_USERS_LOADED,
    departmentUsers,
    companyID,
    userID,
  }
}
function failedLoadingDepartmentUsers(companyID: string, userID: string, error: Error): DepartmentUserAction {
  return {
    type: ActionTypes.DEPARTMENT_USERS_LOAD_FAILED,
    error,
    companyID,
    userID,
  }
}

function grantingDepartmentUserPermission(): DepartmentUserAction {
  return {
    type: ActionTypes.DEPARTMENT_USER_PERMISSION_GRANTING,
  }
}
function grantedDepartmentUserPermission(departmentUser: DepartmentUser): DepartmentUserAction {
  return {
    type: ActionTypes.DEPARTMENT_USER_PERMISSION_GRANTED,
    departmentUser,
  }
}
function failedGrantingDepartmentUserPermission(error: Error): DepartmentUserAction {
  return {
    type: ActionTypes.DEPARTMENT_USER_PERMISSION_GRANT_FAILED,
    error,
  }
}

function revokingDepartmentUserPermission(): DepartmentUserAction {
  return {
    type: ActionTypes.DEPARTMENT_USER_PERMISSION_REVOKING,
  }
}
function revokedDepartmentUserPermission(departmentUser: DepartmentUser): DepartmentUserAction {
  return {
    type: ActionTypes.DEPARTMENT_USER_PERMISSION_REVOKED,
    departmentUser,
  }
}
function failedRevokingDepartmentUserPermission(error: Error): DepartmentUserAction {
  return {
    type: ActionTypes.DEPARTMENT_USER_PERMISSION_REVOKE_FAILED,
    error,
  }
}

export function getDepartmentUsers(userID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<DepartmentUser[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingDepartmentUsers())
    return fetchDepartmentUsers(companyID, userID)
      .then((res) => {
        dispatch(loadedDepartmentUsers(companyID, userID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingDepartmentUsers(companyID, userID, e))
        }
      })
  }
}

export function grantDepartmentUserPermissions(
  userCompanyID: string,
  departmentID: string,
  permissions: DepartmentPermission[]
) {
  return (dispatch: React.Dispatch<any>): Promise<DepartmentUser | void> => {
    dispatch(grantingDepartmentUserPermission())
    return postDepartmentUserPermission(userCompanyID, departmentID, permissions)
      .then((res) => {
        dispatch(grantedDepartmentUserPermission(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedGrantingDepartmentUserPermission(e))
        }
      })
  }
}

export function revokeDepartmentUserPermission(userCompanyID: string, departmentID: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(revokingDepartmentUserPermission())
    return delDepartmentUserPermission(userCompanyID, departmentID)
      .then(() => {
        dispatch(revokedDepartmentUserPermission({ userCompanyID, departmentID, id: '', permissions: [] }))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedRevokingDepartmentUserPermission(e))
        }
      })
  }
}
