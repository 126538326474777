import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { addAlertSignature } from '../../../actions/alerts'
import { CoarseTimeRegistrationMutableFields } from '../../../model/coarseTimeRegistration'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import SalaryType from '../../../model/salaryType'
import { CoarseTimeRegistrationReducer } from '../../../reducers/coarseTimeRegistrations'
import { ContractReducer } from '../../../reducers/contracts'
import { t } from '../../../utils/translation-utils'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import CoarseTimeRegistrationTab from './CoarseTimeRegistrationTab'

type Props = {
  visible: boolean
  employee?: Employee
  salaryPeriodID?: string
  contracts: ContractReducer
  coarseTimeRegistrations: CoarseTimeRegistrationReducer
  salaryCycles: List<SalaryCycle>
  salaryCycle?: SalaryCycle
  salaryTypes: List<SalaryType>

  addAlert: addAlertSignature
  addContract: (contract: Contract) => void
  updateContract: (contract: Contract) => void
  updateCoarseTimeRegistrationBulk: (employeeID: string, registrations: CoarseTimeRegistrationMutableFields[]) => void
}

export default function CoarseTimeRegistrationModal(props: Props): ReactElement | null {
  if (!props.employee || !props.salaryCycle) {
    return null
  }
  return (
    <Card className="time-registration-coarse">
      <Subtitle>{t('time_registration_tab.coarse.modal.title', { name: props.employee.name })}</Subtitle>
      <p>&nbsp;</p>
      <CoarseTimeRegistrationTab
        employee={props.employee}
        preventPeriodChange={false}
        salaryPeriodID={props.salaryPeriodID}
        contracts={props.contracts}
        coarseTimeRegistrations={props.coarseTimeRegistrations}
        salaryCycles={props.salaryCycles}
        salaryCycle={props.salaryCycle}
        salaryTypes={props.salaryTypes}
        addAlert={props.addAlert}
        addContract={props.addContract}
        updateContract={props.updateContract}
        updateCoarseTimeRegistrationBulk={props.updateCoarseTimeRegistrationBulk}
      />
      {props.contracts.saving && <LoadingOverlay />}
    </Card>
  )
}
