import React from 'react'

import { delSwipe, fetchSwipes, patchSwipes } from '../api/swipe'
import ActionTypes from '../constants/action-types'
import Swipe from '../model/swipe'
import { SwipeAction } from '../reducers/swipes'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'

function loadingSwipes(companyID: string): SwipeAction {
  return {
    type: ActionTypes.SWIPE_LOADING,
    companyID,
  }
}
export function loadedSwipes(companyID: string, swipes: Swipe[], partial = false): SwipeAction {
  return {
    type: partial ? ActionTypes.SWIPE_LOADED_PARTIAL : ActionTypes.SWIPE_LOADED,
    swipes,
    companyID,
  }
}
function failedLoadingSwipes(companyID: string, error: Error): SwipeAction {
  return {
    type: ActionTypes.SWIPE_LOAD_FAILED,
    error,
    companyID,
  }
}

export function addedSwipe(companyID: string, swipeID: string, swipe: Swipe): SwipeAction {
  return {
    type: ActionTypes.SWIPE_ADDED,
    swipeID,
    swipe,
    companyID,
  }
}
export function updatedSwipe(companyID: string, swipeID: string, swipe: Swipe): SwipeAction {
  return {
    type: ActionTypes.SWIPE_UPDATED,
    swipeID,
    swipe,
    companyID,
  }
}

function approvingSwipes(companyID: string): SwipeAction {
  return {
    type: ActionTypes.SWIPE_APPROVING,
    companyID,
  }
}
export function approvedSwipes(companyID: string, swipes: Swipe[]): SwipeAction {
  return {
    type: ActionTypes.SWIPE_APPROVED,
    companyID,
    swipes,
  }
}
function failedApprovingSwipes(companyID: string, error: Error): SwipeAction {
  return {
    type: ActionTypes.SWIPE_APPROVE_FAILED,
    error,
    companyID,
  }
}

function deletingSwipe(swipeID: string): SwipeAction {
  return {
    type: ActionTypes.SWIPE_DELETING,
    swipeID,
  }
}
export function deletedSwipe(swipeID: string): SwipeAction {
  return {
    type: ActionTypes.SWIPE_DELETED,
    swipeID,
  }
}
function failedDeletingSwipe(swipeID: string, error: Error): SwipeAction {
  return {
    type: ActionTypes.SWIPE_DELETE_FAILED,
    error,
    swipeID,
  }
}

export function getSwipes(offset?: number) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Swipe[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    if (!offset) {
      dispatch(loadingSwipes(companyID))
      offset = 0
    }
    const limit = 1000
    return fetchSwipes(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedSwipes(companyID, data)),
          (data) => dispatch(loadedSwipes(companyID, data, true)),
          (offset) => dispatch(getSwipes(offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSwipes(companyID, e))
        }
      })
  }
}

export function approveSwipes(swipeIDs: string[]) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Swipe[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(approvingSwipes(companyID))
    return patchSwipes(swipeIDs)
      .then((res) => {
        dispatch(approvedSwipes(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedApprovingSwipes(companyID, e))
        }
      })
  }
}

export function deleteSwipe(swipeID: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(deletingSwipe(swipeID))
    return delSwipe(swipeID)
      .then(() => {
        dispatch(deletedSwipe(swipeID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingSwipe(swipeID, e))
        }
      })
  }
}
