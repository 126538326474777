import DocumentCategory, { DocumentCategoryMutableFields } from '../model/documentCategory'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchDocumentCategories(companyID: string): Promise<RequestResponse<DocumentCategory[]>> {
  return secureRequest('GET', url('/v2/documentCategories', { companyID }))
}

export function postDocumentCategory(
  companyID: string,
  documentCategory: DocumentCategoryMutableFields
): Promise<RequestResponse<DocumentCategory>> {
  return secureRequest('POST', url('/v2/documentCategories'), {
    body: {
      companyID,
      name: documentCategory.name,
    },
  })
}

export function putDocumentCategory(documentCategory: DocumentCategory): Promise<RequestResponse<DocumentCategory>> {
  return secureRequest('PUT', url('/v2/documentCategories/' + documentCategory.id), {
    body: {
      name: documentCategory.name,
      active: documentCategory.active,
    },
  })
}
