import React from 'react'

import {
  delSalaryRegistration,
  delSalaryRegistrationBulk,
  fetchSalaryRegistrations,
  patchSalaryRegistrationApprove,
  postSalaryRegistration,
  postSalaryRegistrationBulk,
  putSalaryRegistration,
} from '../api/salary-registrations'
import ActionTypes from '../constants/action-types'
import SalaryRegistration, { SalaryRegistrationMutableFields } from '../model/salaryRegistration'
import { DateFormat, SettledState } from '../model/types'
import { SalaryRegistrationAction } from '../reducers/salaryRegistrations'
import { isRequestError } from '../utils/error-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'

function loadingSalaryRegistrations(
  companyID: string | undefined,
  employeeID: string | undefined,
  payRollID: string | undefined,
  fromDate: DateFormat,
  toDate: DateFormat,
  stateFilter?: SettledState
): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_LOADING,
    companyID,
    employeeID,
    payRollID,
    fromDate,
    toDate,
    stateFilter,
  }
}
function loadedSalaryRegistrations(
  companyID: string | undefined,
  employeeID: string | undefined,
  payRollID: string | undefined,
  fromDate: DateFormat,
  toDate: DateFormat,
  stateFilter: SettledState | undefined,
  salaryRegistrations: SalaryRegistration[],
  partial = false
): SalaryRegistrationAction {
  return {
    type: partial ? ActionTypes.SALARY_REGISTRATION_LOADED_PARTIAL : ActionTypes.SALARY_REGISTRATION_LOADED,
    companyID,
    employeeID,
    payRollID,
    fromDate,
    toDate,
    stateFilter,
    salaryRegistrations,
  }
}
function failedLoadingSalaryRegistrations(
  companyID: string | undefined,
  employeeID: string | undefined,
  payRollID: string | undefined,
  error: Error
): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_LOAD_FAILED,
    companyID,
    employeeID,
    payRollID,
    error,
  }
}

function creatingSalaryRegistration(employeeID: string): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_CREATING,
    employeeID,
  }
}
export function createdSalaryRegistration(
  employeeID: string,
  salaryRegistration: SalaryRegistration
): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_CREATED,
    employeeID,
    salaryRegistration,
  }
}
function failedCreatingSalaryRegistration(employeeID: string, error: Error): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_CREATE_FAILED,
    error,
    employeeID,
  }
}

function approvingSalaryRegistrations(salaryRegistrationIDs: string[]): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_APPROVING,
    salaryRegistrationIDs,
  }
}
export function approvedSalaryRegistrations(
  salaryRegistrationIDs: string[],
  approved: boolean
): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_APPROVED,
    salaryRegistrationIDs,
    approved,
  }
}
function failedApprovingSalaryRegistrations(salaryRegistrationIDs: string[], error: Error): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_APPROVE_FAILED,
    error,
    salaryRegistrationIDs,
  }
}

function updatingSalaryRegistration(employeeID: string, salaryRegistrationID?: string): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_UPDATING,
    employeeID,
    salaryRegistrationID,
  }
}
export function updatedSalaryRegistration(
  employeeID: string,
  salaryRegistration: SalaryRegistration
): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_UPDATED,
    employeeID,
    salaryRegistrationID: salaryRegistration.id,
    salaryRegistration,
  }
}
function failedUpdatingSalaryRegistration(
  employeeID: string,
  salaryRegistrationID: string | undefined,
  error: Error
): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_UPDATE_FAILED,
    error,
    employeeID,
    salaryRegistrationID,
  }
}

function deletingSalaryRegistration(salaryRegistrationID?: string): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_DELETING,
    salaryRegistrationID,
  }
}
export function deletedSalaryRegistration(salaryRegistrationID?: string): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_DELETED,
    salaryRegistrationID,
  }
}
function failedDeletingSalaryRegistration(
  salaryRegistrationID: string | undefined,
  error: Error
): SalaryRegistrationAction {
  return {
    type: ActionTypes.SALARY_REGISTRATION_DELETE_FAILED,
    error,
    salaryRegistrationID,
  }
}

export function getSalaryRegistrations(
  companyID: string | undefined,
  employeeID: string | undefined,
  payRollID: string | undefined,
  fromDate: DateFormat,
  toDate: DateFormat,
  stateFilter?: SettledState,
  offset = 0
) {
  return (dispatch: React.Dispatch<any>): Promise<SalaryRegistration[] | void> => {
    if (!stateFilter) {
      stateFilter = undefined
    }

    if (!offset) {
      dispatch(loadingSalaryRegistrations(companyID, employeeID, payRollID, fromDate, toDate, stateFilter))
      offset = 0
    }
    const limit = 1000
    return fetchSalaryRegistrations(companyID, employeeID, payRollID, fromDate, toDate, stateFilter, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) =>
            dispatch(loadedSalaryRegistrations(companyID, employeeID, payRollID, fromDate, toDate, stateFilter, data)),
          (data) =>
            dispatch(
              loadedSalaryRegistrations(companyID, employeeID, payRollID, fromDate, toDate, stateFilter, data, true)
            ),
          (offset) =>
            dispatch(getSalaryRegistrations(companyID, employeeID, payRollID, fromDate, toDate, stateFilter, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSalaryRegistrations(companyID, employeeID, payRollID, e))
        }
      })
  }
}

export function createSalaryRegistration(registration: SalaryRegistrationMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<SalaryRegistration | void> => {
    dispatch(creatingSalaryRegistration(registration.employeeID))
    return postSalaryRegistration(registration)
      .then((res) => {
        dispatch(createdSalaryRegistration(registration.employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingSalaryRegistration(registration.employeeID, e))
        }
      })
  }
}

export function createSalaryRegistrationBulk(employeeID: string, salaryRegistrations: SalaryRegistration[]) {
  return (dispatch: React.Dispatch<any>): Promise<SalaryRegistration[] | void> => {
    dispatch(creatingSalaryRegistration(employeeID))
    return postSalaryRegistrationBulk(salaryRegistrations)
      .then((res) => {
        res.data.forEach((salaryRegistration) => {
          dispatch(createdSalaryRegistration(employeeID, salaryRegistration))
        })
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingSalaryRegistration(employeeID, e))
        }
      })
  }
}

export function updateSalaryRegistration(registration: SalaryRegistrationMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<SalaryRegistration | void> => {
    if (!registration.id) {
      return PromiseVoid
    }
    dispatch(updatingSalaryRegistration(registration.employeeID, registration.id))
    return putSalaryRegistration(registration.id, registration)
      .then((res) => {
        dispatch(updatedSalaryRegistration(registration.employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingSalaryRegistration(registration.employeeID, registration.id, e))
        }
      })
  }
}

export function approveSalaryRegistrations(ids: string[]) {
  return (dispatch: React.Dispatch<any>): Promise<SalaryRegistration[] | void> => {
    dispatch(approvingSalaryRegistrations(ids))
    return patchSalaryRegistrationApprove('Approve', ids)
      .then((res) => {
        dispatch(approvedSalaryRegistrations(ids, true))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedApprovingSalaryRegistrations(ids, e))
        }
      })
  }
}

export function unapproveSalaryRegistrations(ids: string[]) {
  return (dispatch: React.Dispatch<any>): Promise<SalaryRegistration[] | void> => {
    dispatch(approvingSalaryRegistrations(ids))
    return patchSalaryRegistrationApprove('Unapprove', ids)
      .then((res) => {
        dispatch(approvedSalaryRegistrations(ids, false))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedApprovingSalaryRegistrations(ids, e))
        }
      })
  }
}

export function deleteSalaryRegistrationBulk(companyID?: string, employeeID?: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(deletingSalaryRegistration())
    return delSalaryRegistrationBulk(companyID, employeeID)
      .then(() => {
        dispatch(deletedSalaryRegistration())
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingSalaryRegistration(undefined, e))
        }
      })
  }
}

export function deleteSalaryRegistration(id: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(deletingSalaryRegistration(id))
    return delSalaryRegistration(id)
      .then(() => {
        dispatch(deletedSalaryRegistration(id))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingSalaryRegistration(id, e))
        }
      })
  }
}
