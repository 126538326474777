import React from 'react'

import { fetchInvoices } from '../api/invoices'
import ActionTypes from '../constants/action-types'
import Invoice from '../model/invoice'
import { InvoiceAction } from '../reducers/invoices'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingInvoices(payRollID: string | undefined, companyID: string | undefined): InvoiceAction {
  return {
    type: ActionTypes.INVOICES_LOADING,
    payRollID,
    companyID,
  }
}
function loadedInvoices(
  payRollID: string | undefined,
  companyID: string | undefined,
  invoices: Invoice[],
  partial = false
): InvoiceAction {
  return {
    type: partial ? ActionTypes.INVOICES_LOADED_PARTIAL : ActionTypes.INVOICES_LOADED,
    invoices,
    payRollID,
    companyID,
  }
}
function failedLoadingInvoices(
  payRollID: string | undefined,
  companyID: string | undefined,
  error: Error
): InvoiceAction {
  return {
    type: ActionTypes.INVOICES_LOAD_FAILED,
    error,
    payRollID,
    companyID,
  }
}
export function addedInvoice(
  payRollID: string | undefined,
  companyID: string | undefined,
  invoice: Invoice
): InvoiceAction {
  return {
    type: ActionTypes.INVOICE_ADDED,
    invoice,
    payRollID,
    companyID,
  }
}
export function updatedInvoice(invoiceID: string, invoice: Invoice): InvoiceAction {
  return {
    type: ActionTypes.INVOICE_UPDATED,
    invoiceID,
    invoice,
  }
}
export function deletedInvoice(invoiceID: string): InvoiceAction {
  return {
    type: ActionTypes.INVOICE_DELETED,
    invoiceID,
  }
}

export function getInvoices(payRollID?: string, companyID?: string, offset?: number) {
  return (dispatch: React.Dispatch<any>): Promise<Invoice[] | void> => {
    if (!offset) {
      dispatch(loadingInvoices(payRollID, companyID))
      offset = 0
    }
    const limit = 1000
    return fetchInvoices(payRollID, companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedInvoices(payRollID, companyID, data)),
          (data) => dispatch(loadedInvoices(payRollID, companyID, data, true)),
          (offset) => dispatch(getInvoices(payRollID, companyID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingInvoices(payRollID, companyID, e))
        }
      })
  }
}
