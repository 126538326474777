import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { removeAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import Employee from '../../model/employee'
import SalaryType from '../../model/salaryType'
import { EmploymentType } from '../../model/types'
import { AlertReducer } from '../../reducers/alerts'
import { EmployeeReducer } from '../../reducers/employees'
import { SwipeReducer } from '../../reducers/swipes'
import { paths } from '../../routes'
import { isEmployeeType } from '../../utils/employee-utils'
import { formatEmploymentType } from '../../utils/format-utils'
import { formatNumber } from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'
import Table from '../antd/table'
import Button from '../elements/button'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'

import './SwipeOverview.css'

type Props = {
  alerts: AlertReducer
  company: Company
  employees: EmployeeReducer
  swipes: SwipeReducer
  salaryTypes: List<SalaryType>

  removeAlert: removeAlertSignature
  updateEmployee: (employee: Employee) => void
}

export default function SwipeEmployeeOverview(props: Props): ReactElement | null {
  const enable = (employee: Employee) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      employee.hasSwipe = true
      props.updateEmployee(employee)
    }
  }

  const disable = (employee: Employee) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      employee.hasSwipe = false
      props.updateEmployee(employee)
    }
  }

  const isValid = (employee: Employee) => {
    return props.company.swipeEmployeeTypes.some((employeeType) => isEmployeeType(employee, employeeType))
  }

  const getEmployeeType = (employee: Employee) => {
    return (['Fixed', 'Hourly', 'Freelancer', 'Commissioned'] as const).reduce((type, employeeType) => {
      if (isEmployeeType(employee, employeeType)) {
        return employeeType
      }
      return type
    }, '')
  }

  type ValidEmployeeRow = {
    id: string
    key: string
    name: string
    swipeCount: string
    hasSwipe: boolean
    original: Employee
  }
  type InvalidEmployeeRow = {
    id: string
    key: string
    name: string
    swipeCount: string
    employeeType: string
  }

  const validColumns = [
    { title: t('swipe_employees.valid_table.header.employee'), dataIndex: 'name', key: 'name' },
    { title: t('swipe_employees.valid_table.header.swipe_count'), dataIndex: 'swipeCount', key: 'swipeCount' },
    {
      title: t('swipe_employees.valid_table.header.has_swipe'),
      dataIndex: '',
      key: 'x3',
      render: (employee: ValidEmployeeRow) => {
        if (employee.hasSwipe) {
          return (
            <span>
              {t('swipe_employees.valid_table.has_swipe.enabled')}
              <br />
              <DumbLink onClick={disable(employee.original)}>
                {t('swipe_employees.valid_table.has_swipe.disable')}
              </DumbLink>
            </span>
          )
        }
        return (
          <span>
            {t('swipe_employees.valid_table.has_swipe.disabled')}
            <br />
            <DumbLink onClick={enable(employee.original)}>
              {t('swipe_employees.valid_table.has_swipe.enable')}
            </DumbLink>{' '}
          </span>
        )
      },
    },
  ]

  const invalidColumns = [
    { title: t('swipe_employees.invalid_table.header.employee'), dataIndex: 'name', key: 'name' },
    { title: t('swipe_employees.invalid_table.header.swipe_count'), dataIndex: 'swipeCount', key: 'swipeCount' },
    { title: t('swipe_employees.invalid_table.header.employee_type'), dataIndex: 'employeeType', key: 'employeeType' },
  ]

  const getValidEmployees = (): ValidEmployeeRow[] => {
    return props.employees.employees
      .filter((employee) => isValid(employee))
      .map((employee) => {
        const count = props.swipes.swipes.filter((swipe) => employee.id === swipe.employeeID).size
        return {
          id: employee.id,
          key: employee.id,
          name: employee.name || employee.email || '-',
          swipeCount: formatNumber(count, 0),
          hasSwipe: employee.hasSwipe,
          original: employee,
        }
      })
      .toArray()
  }

  const getInvalidEmployees = (): InvalidEmployeeRow[] => {
    return props.employees.employees
      .filter((employee) => !isValid(employee))
      .map((employee) => {
        const count = props.swipes.swipes.filter((swipe) => employee.id === swipe.employeeID).size
        return {
          id: employee.id,
          key: employee.id,
          name: employee.name || employee.email || '-',
          swipeCount: formatNumber(count, 0),
          employeeType: formatEmploymentType(getEmployeeType(employee) as EmploymentType),
        }
      })
      .toArray()
  }

  return (
    <div className="swipe-employee-overview">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <TitleMenu>
        <Link to={'/' + paths.SWIPE_OVERVIEW}>
          <Button className="gtm-hide-swipe-overview-history">{t('swipe_employees.header.back')}</Button>
        </Link>
      </TitleMenu>
      <div>
        <Title>{t('swipe_employees.valid.title')}</Title>
        <p>{t('swipe_employees.valid.description')}</p>
        <Table
          columns={validColumns}
          dataSource={getValidEmployees()}
          pagination={false}
          className="swipe-employee-valid-overview-table"
        />
      </div>
      <div>
        <Title>{t('swipe_employees.invalid.title')}</Title>
        <p>
          {tx('swipe_employees.invalid.description', {
            link: (
              <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/transfer-immediate-pay'}>
                {t('swipe_employees.invalid.link_text')}
              </Link>
            ),
          })}
        </p>
        <Table
          columns={invalidColumns}
          dataSource={getInvalidEmployees()}
          //className="swipe-employee-invalid-overview-table"
        />
      </div>
    </div>
  )
}
