import { RequestResponse, secureRequest, url } from '../utils/request-utils'

type MFAResponse = {
  challengeID: string
  key?: string
  qrImage?: string
}

export function putMfa(channel: string): Promise<RequestResponse<MFAResponse>> {
  return secureRequest('PUT', url('/v2/mfa'), {
    body: { channel },
  })
}

export function patchMfa(challengeID: string, response: string): Promise<RequestResponse> {
  return secureRequest('PATCH', url('/v2/mfa'), {
    body: { challengeID, response },
  })
}

export function delMfa(): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/mfa'))
}
