import React from 'react'

import { delDepartment, fetchDepartments, postDepartment, putDepartment } from '../api/departments'
import ActionTypes from '../constants/action-types'
import Department, { DepartmentCreationFields, DepartmentMutableFields } from '../model/department'
import { DepartmentAction } from '../reducers/departments'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingDepartments(): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENTS_LOADING,
  }
}
export function loadedDepartments(companyID: string, departments: Department[]): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENTS_LOADED,
    departments,
    companyID,
  }
}
function failedLoadingDepartments(companyID: string, error: Error): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENTS_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingDepartment(): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENT_ADDING,
  }
}
export function addedDepartment(companyID: string, department: Department): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENT_ADDED,
    companyID,
    department,
  }
}
function failedAddingDepartment(companyID: string, error: Error): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENT_ADD_FAILED,
    error,
    companyID,
  }
}

function updatingDepartment(companyID: string, departmentID: string): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENT_UPDATING,
    companyID,
    departmentID,
  }
}
export function updatedDepartment(companyID: string, departmentID: string, department: Department): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENT_UPDATED,
    companyID,
    departmentID,
    department,
  }
}
function failedUpdatingDepartment(companyID: string, departmentID: string, error: Error): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENT_UPDATE_FAILED,
    error,
    companyID,
    departmentID,
  }
}

function deletingDepartment(companyID: string, departmentID: string): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENT_DELETING,
    companyID,
    departmentID,
  }
}
export function deletedDepartment(companyID: string, departmentID: string): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENT_DELETED,
    companyID,
    departmentID,
  }
}
function failedDeletingDepartment(companyID: string, departmentID: string, error: Error): DepartmentAction {
  return {
    type: ActionTypes.DEPARTMENT_DELETE_FAILED,
    error,
    companyID,
    departmentID,
  }
}

export function addDepartment(department: DepartmentCreationFields) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Department | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingDepartment())
    return postDepartment(companyID, department)
      .then((res) => {
        dispatch(addedDepartment(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingDepartment(companyID, e))
        }
      })
  }
}

export function getDepartments() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Department[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingDepartments())
    return fetchDepartments(companyID)
      .then((res) => {
        dispatch(loadedDepartments(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingDepartments(companyID, e))
        }
      })
  }
}

export function updateDepartment(department: DepartmentMutableFields) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Department | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingDepartment(companyID, department.id))
    return putDepartment(companyID, department)
      .then((res) => {
        dispatch(updatedDepartment(companyID, res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingDepartment(companyID, department.id, e))
        }
      })
  }
}

export function deleteDepartment(departmentID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingDepartment(companyID, departmentID))
    return delDepartment(departmentID)
      .then(() => {
        dispatch(deletedDepartment(companyID, departmentID))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingDepartment(companyID, departmentID, e))
        }
      })
  }
}
