import React from 'react'

import { fetchBanks } from '../api/banks'
import ActionTypes from '../constants/action-types'
import Bank from '../model/bank'
import { BankAction } from '../reducers/banks'
import { isRequestError } from '../utils/error-utils'

function loadingBanks(): BankAction {
  return {
    type: ActionTypes.BANKS_LOADING,
  }
}
function loadedBanks(banks: Bank[]): BankAction {
  return {
    type: ActionTypes.BANKS_LOADED,
    banks,
  }
}
function failedLoadingBanks(error: Error): BankAction {
  return {
    type: ActionTypes.BANKS_LOAD_FAILED,
    error,
  }
}

export function getBanks() {
  return (dispatch: React.Dispatch<any>): Promise<Bank[] | void> => {
    dispatch(loadingBanks())
    return fetchBanks()
      .then((res) => {
        dispatch(loadedBanks(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingBanks(e))
        }
      })
  }
}
