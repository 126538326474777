import React, { ReactElement, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { getUserVerificationUrl } from '../../api/user-verification'
import { UserReducer } from '../../reducers/user'
import { paths } from '../../routes'
import { canLookupNationalID } from '../../utils/national-id-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  user: UserReducer
}

export default function MitIDTab(props: Props): ReactElement | null {
  const [signURL, setSignURL] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const canNationalIDLookUp = canLookupNationalID(props.user.user, 'DK CPR')

  useEffectOnce(() => {
    if (canNationalIDLookUp) {
      return // no need
    }
    getUserVerificationUrl()
      .then((res) => {
        setSignURL(res.data.url)
      })
      .catch(() => {
        setErrorMessage(t('account.mitid.error.unable_to_find_url'))
      })
  })

  if (errorMessage) {
    return (
      <Card>
        <Alert type={'error'} message={errorMessage} showIcon />
      </Card>
    )
  }

  if (canNationalIDLookUp) {
    return (
      <Card>
        <Title>{t('account.mitid.identity_already_verified')}</Title>
        <p>{t('account.mitid.verified_intro')}</p>
        <p>{t('account.mitid.logging_note')}</p>
      </Card>
    )
  }

  const handleCriiptoSign = (e: React.MouseEvent) => {
    e.preventDefault()
    document.location = signURL + `&state=` + paths.USER_VERIFICATION
  }

  if (!signURL) {
    return <LoadingOverlay />
  }

  return (
    <Card>
      <Title>{t('account.mitid.title')}</Title>
      <p>{t('account.mitid.intro')}</p>
      <p>{t('account.mitid.logging_note')}</p>
      <Button onClick={handleCriiptoSign} size="large" type="secondary" noArrow className="mitid-button">
        {t('account.mitid.confirm')}
      </Button>
    </Card>
  )
}
