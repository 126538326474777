import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { CostCenterAccounting } from '../../model/accountingIntegration'
import AsynchronousTask from '../../model/asynchronousTask'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import LeaveType from '../../model/leaveType'
import OneTimePay, { OneTimePayCreationFields, OneTimePayMutableFields, OneTimePayType } from '../../model/oneTimePay'
import SalaryCycle from '../../model/salaryCycle'
import SupplementType from '../../model/supplementType'
import { AlertReducer } from '../../reducers/alerts'
import { AsynchronousTaskReducer } from '../../reducers/asynchronousTasks'
import { EmployeeReducer } from '../../reducers/employees'
import { LeaveBalanceReducer } from '../../reducers/leaveBalances'
import { OneTimePayReducer } from '../../reducers/oneTimePays'
import { SupplementBalanceReducer } from '../../reducers/supplementBalances'
import { UserReducer } from '../../reducers/user'
import { paths } from '../../routes'
import { regularBonusTypes } from '../../utils/employee-utils'
import { formatCurrency } from '../../utils/number-utils'
import { hasDepartmentPermission } from '../../utils/permissions-utils'
import { getPage, setPage } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Table from '../antd/table'
import Button from '../elements/button'
import EmployeeFilter, { FilterContainer, filterEmployee } from '../elements/EmployeeFilter'
import Headline from '../elements/Headline'
import Icon from '../elements/Icon'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import UserImage from '../elements/UserImage'
import BonusEdit from '../employees-single/bonus/BonusEdit'
import PayCheckAdvanceEdit from '../employees-single/pay-check-advance/PayCheckAdvanceEdit'
import ReimbursementEdit from '../employees-single/reimbursement/ReimbursementEdit'
import TravelAllowanceEdit from '../employees-single/travel-allowance/TravelAllowanceEdit'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'
import ImportOneTimePaysModal from './ImportOneTimePaysModal'

import './OneTimePays.css'

type Props = {
  alerts: AlertReducer
  asynchronousTasks: AsynchronousTaskReducer
  company: Company
  employees: EmployeeReducer
  oneTimePays: OneTimePayReducer
  salaryCycles: List<SalaryCycle>
  supplementBalances: SupplementBalanceReducer
  supplementTypes: List<SupplementType>
  leaveBalances: LeaveBalanceReducer
  leaveTypes: List<LeaveType>
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>
  departments: List<Department>
  companyUser?: CompanyUser
  user: UserReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getRemuneration: (contractID: string) => void
  addOneTimePay: (employeeID: string, oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, oneTimePay: OneTimePayMutableFields) => void
  approveOneTimePays: (ids: string[]) => void
  startOneTimePaysImport: (fileID: string, oneTimePayType?: OneTimePayType) => Promise<AsynchronousTask | void>
  storeStagedImportData: (asynchronousTaskID: string) => Promise<AsynchronousTask | void>
  getSupplementBalances: () => void
  getLeaveBalances: () => void
}

export default function OneTimePays(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showBonus, setShowBonus] = useState(false)
  const [showAdvance, setShowAdvance] = useState(false)
  const [showReimbursement, setShowReimbursement] = useState(false)
  const [showTravel, setShowTravel] = useState(false)
  const [currentEmployee, setCurrentEmployee] = useState<string | null>(null)
  const [showImport, setShowImport] = useState(false)
  const [filter, setFilter] = useState<FilterContainer>({ searchQuery: '' })

  const setBonusVisibility = (showBonus: string | null) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setShowBonus(!!showBonus)
    setCurrentEmployee(showBonus)
  }
  const setAdvanceVisibility = (showAdvance: string | null) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setShowAdvance(!!showAdvance)
    setCurrentEmployee(showAdvance)
  }
  const setReimbursementVisibility = (showReimbursement: string | null) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setShowReimbursement(!!showReimbursement)
    setCurrentEmployee(showReimbursement)
  }
  const setTravelVisibility = (showTravel: string | null) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setShowTravel(!!showTravel)
    setCurrentEmployee(showTravel)
  }
  const setImportVisibility = (showImport: boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setShowImport(showImport)
  }

  const { oneTimePays } = props
  const previousOneTimePays = usePrevious(oneTimePays)

  useEffect(() => {
    // Check for save callback
    if (previousOneTimePays && previousOneTimePays.saving && !oneTimePays.saving) {
      // Check for no error occurred
      if (!oneTimePays.error) {
        // Close import modal
        setBonusVisibility(null)
        setAdvanceVisibility(null)
        setReimbursementVisibility(null)
        setTravelVisibility(null)
        setImportVisibility(false)
      }
    }
  })

  const approveList = (oneTimePays: OneTimePay[]) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      props.approveOneTimePays(oneTimePays.map((otp) => otp.id))
    }
  }

  type AmountContainer = Partial<Record<OneTimePayType, number>>
  type UnapprovedContainer = Partial<
    Record<
      OneTimePayType,
      {
        oneTimePays: OneTimePay[]
        amount: number
      }
    >
  >

  type EmployeeRow = {
    key: string
    id: string
    profileImageURL?: string
    name: string
    position?: string
    canEditObjects: boolean
    canApproveObjects: boolean
    amount: AmountContainer
    unapproved: UnapprovedContainer
  }

  const columns = [
    {
      title: t('one_time_pays.table.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      width: 330,
      render: (employee: EmployeeRow) => {
        return (
          <Headline>
            <UserImage src={employee.profileImageURL} name={employee.name} />
            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id}>{employee.name}</Link>
            <small>{employee.position ? employee.position : '-'}</small>
          </Headline>
        )
      },
    },
    {
      title: t('one_time_pays.table.header.bonus'),
      dataIndex: '',
      key: 'xBonus',
      render: (employee: EmployeeRow) => {
        const amount = employee.amount['Bonus']
        if (amount) {
          const unapproved = employee.unapproved['Bonus']
          return (
            <div>
              <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/bonus'} className="black">
                {formatCurrency(amount)}
              </Link>
              {unapproved && employee.canApproveObjects && (
                <span>
                  <br />
                  <DumbLink onClick={approveList(unapproved.oneTimePays)} type="standard">
                    {t('one_time_pays.table.approve_amount', { amount: formatCurrency(unapproved.amount) })}
                  </DumbLink>
                </span>
              )}
            </div>
          )
        }
        if (!employee.canEditObjects) {
          return ''
        }
        return (
          <DumbLink
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              setBonusVisibility(employee.id)
            }}
          >
            {t('one_time_pays.table.add')}
          </DumbLink>
        )
      },
    },
    {
      title: t('one_time_pays.table.header.advanced'),
      dataIndex: '',
      key: 'xAdvanced',
      render: (employee: EmployeeRow) => {
        const amount = employee.amount['Pay Check Advanced']
        if (amount) {
          const unapproved = employee.unapproved['Pay Check Advanced']
          return (
            <div>
              <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/pay-check-advance'} className="black">
                {formatCurrency(amount)}
              </Link>
              {unapproved && employee.canApproveObjects && (
                <span>
                  <br />
                  <DumbLink onClick={approveList(unapproved.oneTimePays)} type="standard">
                    {t('one_time_pays.table.approve_amount', { amount: formatCurrency(unapproved.amount) })}
                  </DumbLink>
                </span>
              )}
            </div>
          )
        }
        if (!employee.canEditObjects) {
          return ''
        }
        return (
          <DumbLink
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              setAdvanceVisibility(employee.id)
            }}
          >
            {t('one_time_pays.table.add')}
          </DumbLink>
        )
      },
    },
    {
      title: t('one_time_pays.table.header.reimbursement'),
      dataIndex: '',
      key: 'xReimbursement',
      render: (employee: EmployeeRow) => {
        const amount = employee.amount['Reimbursement']
        if (amount) {
          const unapproved = employee.unapproved['Reimbursement']
          return (
            <div>
              <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/reimbursement'} className="black">
                {formatCurrency(amount)}
              </Link>
              {unapproved && employee.canApproveObjects && (
                <span>
                  <br />
                  <DumbLink onClick={approveList(unapproved.oneTimePays)} type="standard">
                    {t('one_time_pays.table.approve_amount', { amount: formatCurrency(unapproved.amount) })}
                  </DumbLink>
                </span>
              )}
            </div>
          )
        }
        if (!employee.canEditObjects) {
          return ''
        }
        return (
          <DumbLink
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              setReimbursementVisibility(employee.id)
            }}
          >
            {t('one_time_pays.table.add')}
          </DumbLink>
        )
      },
    },
    {
      title: t('one_time_pays.table.header.travel'),
      dataIndex: '',
      key: 'xTravel Allowance',
      render: (employee: EmployeeRow) => {
        const amount = employee.amount['Travel Allowance']
        if (amount) {
          const unapproved = employee.unapproved['Travel Allowance']
          return (
            <div>
              <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/travel-allowance'} className="black">
                {formatCurrency(amount)}
              </Link>
              {unapproved && employee.canApproveObjects && (
                <span>
                  <br />
                  <DumbLink onClick={approveList(unapproved.oneTimePays)} type="standard">
                    {t('one_time_pays.table.approve_amount', { amount: formatCurrency(unapproved.amount) })}
                  </DumbLink>
                </span>
              )}
            </div>
          )
        }
        if (!employee.canEditObjects) {
          return ''
        }
        return (
          <DumbLink
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              setTravelVisibility(employee.id)
            }}
          >
            {t('one_time_pays.table.add')}
          </DumbLink>
        )
      },
    },
  ]

  const getEmployees = (): EmployeeRow[] => {
    const interpretType = (otp: OneTimePay) => {
      let type = otp.type
      if (regularBonusTypes.indexOf(type) !== -1) {
        type = 'Bonus'
      }
      return type
    }
    return props.employees.employees
      .filter(
        (employee) =>
          filterEmployee(employee, filter) &&
          (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed') &&
          employee.affiliationType !== 'Freelancer' &&
          !!employee.activeEmployment &&
          !!employee.activeContract
      )
      .map((employee) => {
        const oneTimePays = props.oneTimePays.oneTimePays.filter(
          (reg) => reg.employeeID === employee.id && !reg.settled
        )
        return {
          key: employee.id,
          id: employee.id,
          profileImageURL: employee.profileImageURL,
          name: employee.name || employee.email || '-',
          position: employee.activeContract?.position,
          canEditObjects: hasDepartmentPermission(props.companyUser, employee.departmentID, 'EditObjects'),
          canApproveObjects: hasDepartmentPermission(props.companyUser, employee.departmentID, 'ApproveObjects'),
          amount: oneTimePays.reduce((amount: AmountContainer, oneTimePay) => {
            const type = interpretType(oneTimePay)
            amount[type] = (amount[type] || 0) + oneTimePay.amount
            return amount
          }, {}),
          unapproved: oneTimePays
            .filter((otp) => !otp.approved)
            .reduce((unapproved: UnapprovedContainer, oneTimePay) => {
              const type = interpretType(oneTimePay)
              unapproved[type] = {
                amount: (unapproved[type]?.amount || 0) + oneTimePay.amount,
                oneTimePays: [...(unapproved[type]?.oneTimePays || []), oneTimePay],
              }
              return unapproved
            }, {}),
        }
      })
      .toArray()
  }

  const employees = getEmployees()
  const employee = currentEmployee
    ? props.employees.employees.find((employee) => employee.id === currentEmployee)
    : undefined
  const salaryCycle = employee?.activeContract
    ? props.salaryCycles.find((cycle) => cycle.id === employee.activeContract?.salaryCycleID)
    : undefined
  return (
    <div className="one-time-pays">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

      <TitleMenu>
        <EmployeeFilter
          departments={props.departments}
          companyUser={props.companyUser}
          onFilterChange={(filter) => setFilter(filter)}
        />
        {hasDepartmentPermission(props.companyUser, undefined, 'EditObjects') && (
          <Button
            type="primary"
            className="gtm-import-otp-excel"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              setImportVisibility(true)
            }}
          >
            <Icon type="watch" />
            {t('one_time_pays.header.import_excel')}
          </Button>
        )}
      </TitleMenu>

      <Title>{t('one_time_pays.title')}</Title>
      <Table
        columns={columns}
        dataSource={employees}
        pagination={
          employees.length > 100
            ? {
                defaultCurrent: getPage(),
                defaultPageSize: 100,
                onChange: setPage,
              }
            : false
        }
      />

      <Modal
        key={'b' + modalKey}
        visible={showBonus}
        onOk={() => setBonusVisibility(null)}
        onCancel={() => setBonusVisibility(null)}
        width={582}
        footer={null}
      >
        <BonusEdit
          visible={showBonus}
          editing={true}
          canApproveObjects={hasDepartmentPermission(props.companyUser, employee?.departmentID, 'ApproveObjects')}
          employee={employee}
          company={props.company}
          oneTimePays={props.oneTimePays}
          salaryCycle={salaryCycle}
          supplementBalances={props.supplementBalances}
          supplementTypes={props.supplementTypes}
          leaveBalances={props.leaveBalances}
          leaveTypes={props.leaveTypes}
          costCenterAccounting={props.costCenterAccounting}
          costCenters={props.costCenters}
          getRemuneration={props.getRemuneration}
          addOneTimePay={props.addOneTimePay}
          updateOneTimePay={props.updateOneTimePay}
          getSupplementBalances={props.getSupplementBalances}
          getLeaveBalances={props.getLeaveBalances}
          user={props.user}
        />
      </Modal>
      <Modal
        key={'a' + modalKey}
        visible={showAdvance}
        onOk={() => setAdvanceVisibility(null)}
        onCancel={() => setAdvanceVisibility(null)}
        width={582}
        footer={null}
      >
        <PayCheckAdvanceEdit
          visible={showAdvance}
          editing={true}
          canApproveObjects={hasDepartmentPermission(props.companyUser, employee?.departmentID, 'ApproveObjects')}
          employee={employee}
          company={props.company}
          oneTimePays={props.oneTimePays}
          salaryCycle={salaryCycle}
          costCenterAccounting={props.costCenterAccounting}
          costCenters={props.costCenters}
          addOneTimePay={props.addOneTimePay}
          updateOneTimePay={props.updateOneTimePay}
        />
      </Modal>
      <Modal
        key={'r' + modalKey}
        visible={showReimbursement}
        onOk={() => setReimbursementVisibility(null)}
        onCancel={() => setReimbursementVisibility(null)}
        width={582}
        footer={null}
      >
        <ReimbursementEdit
          visible={showReimbursement}
          editing={true}
          canApproveObjects={hasDepartmentPermission(props.companyUser, employee?.departmentID, 'ApproveObjects')}
          employee={employee}
          company={props.company}
          oneTimePays={props.oneTimePays}
          salaryCycle={salaryCycle}
          costCenterAccounting={props.costCenterAccounting}
          costCenters={props.costCenters}
          addOneTimePay={props.addOneTimePay}
          updateOneTimePay={props.updateOneTimePay}
        />
      </Modal>
      <Modal
        key={'t' + modalKey}
        visible={showTravel}
        onOk={() => setTravelVisibility(null)}
        onCancel={() => setTravelVisibility(null)}
        width={582}
        footer={null}
      >
        <TravelAllowanceEdit
          visible={showTravel}
          editing={true}
          canApproveObjects={hasDepartmentPermission(props.companyUser, employee?.departmentID, 'ApproveObjects')}
          employee={employee}
          company={props.company}
          oneTimePays={props.oneTimePays}
          salaryCycle={salaryCycle}
          costCenterAccounting={props.costCenterAccounting}
          costCenters={props.costCenters}
          addOneTimePay={props.addOneTimePay}
          updateOneTimePay={props.updateOneTimePay}
        />
      </Modal>

      <Modal
        key={modalKey}
        visible={showImport}
        onOk={() => setImportVisibility(false)}
        onCancel={() => setImportVisibility(false)}
        width={720}
        footer={null}
      >
        <ImportOneTimePaysModal
          visible={showImport}
          asynchronousTasks={props.asynchronousTasks}
          companyID={props.company.id}
          employees={props.employees.employees}
          closeModal={() => setImportVisibility(false)}
          startOneTimePaysImport={props.startOneTimePaysImport}
          storeStagedImportData={props.storeStagedImportData}
          addAlert={props.addAlert}
        />
      </Modal>
    </div>
  )
}
