import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import {
  createContractTemplate,
  createContractTemplateContract,
  deleteContractTemplate,
  deleteContractTemplateContract,
  getContractTemplateContracts,
  getContractTemplates,
  updateContractTemplate,
  updateContractTemplateContract,
} from '../actions/contract-templates'
import { getRemuneration } from '../actions/contracts'
import { getContractDeltas } from '../actions/contracts'
import { getEmploymentPositions } from '../actions/employment-positions'
import { getSalaryCycles } from '../actions/salary-cycles'
import { getSalaryTypes } from '../actions/salary-types'
import ContractTemplatesComponent from '../components/contract-templates/ContractTemplates'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import ContractDelta from '../model/contractDelta'
import ContractTemplate, { ContractTemplateMutableFields } from '../model/contractTemplate'
import ContractTemplateContract, { ContractTemplateContractMutableFields } from '../model/contractTemplateContract'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { ContractTemplateContractReducer } from '../reducers/contractTemplateContracts'
import { ContractTemplateReducer } from '../reducers/contractTemplates'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeContractDeltaReducer } from '../reducers/employeeContractDeltas'
import { EmployeeReducer } from '../reducers/employees'
import { EmploymentPositionReducer } from '../reducers/employmentPositions'
import { PensionCompanyReducer } from '../reducers/pensionCompanies'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { formatLoadingText } from '../utils/loading-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'
import { t } from '../utils/translation-utils'

type Reducers = {
  alerts: AlertReducer
  contractTemplates: ContractTemplateReducer
  contractTemplateContracts: ContractTemplateContractReducer
  companies: CompanyReducer
  departments: DepartmentReducer
  employees: EmployeeReducer
  employeeContractDeltas: EmployeeContractDeltaReducer
  employmentPositions: EmploymentPositionReducer
  salaryCycles: SalaryCycleReducer
  salaryTypes: SalaryTypeReducer
  pensionCompanies: PensionCompanyReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getContractTemplates: () => void
  getEmploymentPositions: () => void
  getSalaryCycles: () => void
  getSalaryTypes: () => void
  getRemuneration: (contractID: string) => void
  getContractDeltas: (employeeID: string) => Promise<ContractDelta[] | void>
  createContractTemplate: (contractTemplate: ContractTemplateMutableFields) => Promise<ContractTemplate | void>
  updateContractTemplate: (contractTemplate: ContractTemplate) => Promise<ContractTemplate | void>
  deleteContractTemplate: (contractTemplateID: string) => void
  getContractTemplateContracts: (contractTemplateID: string) => void
  createContractTemplateContract: (
    contractTemplateContract: ContractTemplateContractMutableFields
  ) => Promise<ContractTemplateContract | void>
  updateContractTemplateContract: (contractTemplateContract: ContractTemplateContract) => void
  deleteContractTemplateContract: (contractTemplateContractID: string) => void
}

function ContractTemplates(props: Reducers & Actions & RouteProps): ReactElement | null {
  const {
    contractTemplates,
    getContractTemplates,
    employmentPositions,
    getEmploymentPositions,
    salaryCycles,
    getSalaryCycles,
    salaryTypes,
    getSalaryTypes,
  } = props
  useEffect(() => {
    if (!contractTemplates.loading && !contractTemplates.loaded) {
      getContractTemplates()
    }
    if (!employmentPositions.loading && !employmentPositions.loaded) {
      getEmploymentPositions()
    }
    if (!salaryCycles.loading && !salaryCycles.loaded) {
      getSalaryCycles()
    }
    if (!salaryTypes.loading && !salaryTypes.loaded) {
      getSalaryTypes()
    }
  })

  const company = props.companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  if (
    !props.contractTemplates.loaded ||
    !props.employees.loaded ||
    !props.employmentPositions.loaded ||
    !props.salaryCycles.loaded ||
    !props.salaryTypes.loaded
  ) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            { loading: !props.contractTemplates.loaded, text: t('loading.reducer.contract_templates') },
            { loading: !props.employees.loaded, text: t('loading.reducer.employees') },
            { loading: !props.employmentPositions.loaded, text: t('loading.reducer.employment_positions') },
            { loading: !props.salaryCycles.loaded, text: t('loading.reducer.salary_cycles') },
            { loading: !props.salaryTypes.loaded, text: t('loading.reducer.salary_types') },
          ])}
        />
      </div>
    )
  }

  return (
    <ContractTemplatesComponent
      subsection={splatString(props.params.splat)}
      alerts={props.alerts}
      company={company}
      contractTemplates={props.contractTemplates}
      contractTemplateContracts={props.contractTemplateContracts}
      departments={props.departments.departments}
      employees={props.employees.employees}
      employeeContractDeltas={props.employeeContractDeltas}
      employmentPositions={props.employmentPositions.employmentPositions}
      salaryCycles={props.salaryCycles.salaryCycles}
      salaryTypes={props.salaryTypes.salaryTypes}
      pensionCompanies={props.pensionCompanies.pensionCompanies}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      getRemuneration={props.getRemuneration}
      getContractDeltas={props.getContractDeltas}
      createContractTemplate={props.createContractTemplate}
      updateContractTemplate={props.updateContractTemplate}
      deleteContractTemplate={props.deleteContractTemplate}
      getContractTemplateContracts={props.getContractTemplateContracts}
      createContractTemplateContract={props.createContractTemplateContract}
      updateContractTemplateContract={props.updateContractTemplateContract}
      deleteContractTemplateContract={props.deleteContractTemplateContract}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    contractTemplates: state.contractTemplates,
    contractTemplateContracts: state.contractTemplateContracts,
    companies: state.companies,
    employees: state.employees,
    employeeContractDeltas: state.employeeContractDeltas,
    employmentPositions: state.employmentPositions,
    departments: state.departments,
    salaryCycles: state.salaryCycles,
    salaryTypes: state.salaryTypes,
    pensionCompanies: state.pensionCompanies,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getContractTemplates: getContractTemplates,
    getEmploymentPositions: getEmploymentPositions,
    getSalaryCycles: getSalaryCycles,
    getSalaryTypes: getSalaryTypes,
    getRemuneration: getRemuneration,
    getContractDeltas: getContractDeltas,
    createContractTemplate: createContractTemplate,
    updateContractTemplate: updateContractTemplate,
    deleteContractTemplate: deleteContractTemplate,
    getContractTemplateContracts: getContractTemplateContracts,
    createContractTemplateContract: createContractTemplateContract,
    updateContractTemplateContract: updateContractTemplateContract,
    deleteContractTemplateContract: deleteContractTemplateContract,
  }
)(ContractTemplates)
