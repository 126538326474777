import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { AccountingAccount } from '../../model/accountingIntegration'
import { ExpenseCategoryReducer } from '../../reducers/expenseCategories'
import { combineSearchOption, FormComponentProps, withValidations } from '../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import LoadingOverlay from '../widgets/LoadingOverlay'

const NO_ACCOUNT = 'NoAccount'

type Fields = {
  name?: string
  accountingAccountID: string
  order?: string
}

export type ExpenseCategoryResult = {
  name: string
  accountingAccountID?: string
  order: number
}

type Props = {
  expenseCategoryID?: string
  expenseCategories: ExpenseCategoryReducer
  accounts: List<AccountingAccount>
}

function ExpenseCategoryEditForm(
  props: Props & FormComponentProps<Fields, ExpenseCategoryResult>
): ReactElement | null {
  const { decorateField, getFieldValue } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('name', {
            placeholder: t('expense_category.edit.form.name'),
            validate: (val) => (!val ? t('expense_category.edit.form.name.required') : null),
          })(<Input style={{ width: '100%' }} />)}
        </Col>
      </Row>
      {props.accounts.size > 0 && (
        <Row>
          <Col span={24}>
            {decorateField('accountingAccountID', {
              placeholder: t('expense_category.edit.form.accounting_account_id'),
              validate: (val) => {
                if (!val || val === NO_ACCOUNT) {
                  return null
                }
                const account = props.accounts.find((account) => account.id === val)
                if (!account) {
                  return t('expense_category.edit.form.accounting_account_id.no_longer_available.generic')
                }
                if (!account.active) {
                  return t('expense_category.edit.form.accounting_account_id.no_longer_available.specific', {
                    account: account.accountNumber,
                  })
                }
                return null
              },
            })(
              <Select
                dropdownMatchSelectWidth={false}
                showSearch={true}
                filterOption={(inputValue: string, option: ReactElement) => {
                  return combineSearchOption(option).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }}
              >
                <Select.Option
                  key={NO_ACCOUNT}
                  value={NO_ACCOUNT}
                  title={t('expense_category.edit.form.accounting_account_id.no_account')}
                >
                  <em>{t('expense_category.edit.form.accounting_account_id.no_account')}</em>
                </Select.Option>
                {props.accounts
                  .filter((account) => account.active || account.id === getFieldValue('accountingAccountID'))
                  .map((account) => {
                    let name = account.accountNumber
                    if (account.name) {
                      name += ' ' + account.name
                    }
                    return (
                      <Select.Option key={account.id} value={account.id} title={name}>
                        {name}
                        {!account.active && (
                          <i>
                            {' '}
                            {t('expense_category.edit.form.accounting_account_id.no_longer_available.select_note')}
                          </i>
                        )}
                      </Select.Option>
                    )
                  })}
              </Select>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          {decorateField('order', {
            placeholder: t('expense_category.edit.form.order'),
            validate: (val) => {
              if (!val) {
                return t('expense_category.edit.form.order.required')
              }
              if (!val.toString().match(/^\d+$/) || forceParseInputNumber(val) < 1) {
                return t('expense_category.edit.form.order.invalid')
              }
              return null
            },
          })(<Input style={{ width: '100%' }} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.expenseCategories.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ExpenseCategoryResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      accountingAccountID: NO_ACCOUNT,
      order: '1',
    }
    const expenseCategory = props.expenseCategories.expenseCategories.find(
      (expenseCategory) => expenseCategory.id === props.expenseCategoryID
    )
    if (expenseCategory) {
      fields.name = expenseCategory.name
      fields.accountingAccountID = expenseCategory.accountingAccountID
        ? expenseCategory.accountingAccountID
        : NO_ACCOUNT
      fields.order = formatInputNumber(expenseCategory.order)
    }
    return fields
  },
  onSubmit: (values) => ({
    name: values.name!,
    order: forceParseInputNumber(values.order),
    accountingAccountID: values.accountingAccountID === NO_ACCOUNT ? undefined : values.accountingAccountID,
  }),
})(ExpenseCategoryEditForm)
