import React, { ReactElement } from 'react'

import {
  getCompanyAccountingIntegration,
  getCompanyAccountPlans,
  syncCompanyAccountingIntegration,
  updateCompanyAccountPlan,
} from '../actions/accounting-integration'
import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getAvailableAccountingIntegrations } from '../actions/available-accounting-integrations'
import { getCostCenters } from '../actions/cost-centers'
import { AccountPlanUpdate } from '../api/accounting-integration'
import AccountingComponent from '../components/accounting/Accounting'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import { AlertReducer } from '../reducers/alerts'
import { AvailableAccountingIntegrationReducer } from '../reducers/availableAccountingIntegrations'
import { CompanyReducer } from '../reducers/companies'
import { CompanyAccountingIntegrationReducer } from '../reducers/companyAccountingIntegration'
import { CompanyAccountPlanReducer } from '../reducers/companyAccountPlans'
import { CostCenterReducer } from '../reducers/costCenters'
import { WarningReducer } from '../reducers/warnings'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import IntegrationsLayout from './layouts/IntegrationsLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  availableAccountingIntegrations: AvailableAccountingIntegrationReducer
  companyAccountingIntegration: CompanyAccountingIntegrationReducer
  companyAccountPlans: CompanyAccountPlanReducer
  costCenters: CostCenterReducer
  warnings: WarningReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getAvailableAccountingIntegrations: () => void
  getCompanyAccountingIntegration: () => void
  getCompanyAccountPlans: () => void
  getCostCenters: () => void
  updateCompanyAccountPlan: (accountPlan: AccountPlanUpdate) => void
  syncCompanyAccountingIntegration: () => void
}

function Accounting(props: Reducers & Actions & RouteProps): ReactElement | null {
  const company = props.companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  return (
    <IntegrationsLayout location={props.location}>
      <AccountingComponent
        alerts={props.alerts}
        company={company}
        availableAccountingIntegrations={props.availableAccountingIntegrations}
        companyAccountingIntegration={props.companyAccountingIntegration}
        companyAccountPlans={props.companyAccountPlans}
        costCenters={props.costCenters}
        warnings={props.warnings.warnings}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        getAvailableAccountingIntegrations={props.getAvailableAccountingIntegrations}
        getCompanyAccountingIntegration={props.getCompanyAccountingIntegration}
        getCompanyAccountPlans={props.getCompanyAccountPlans}
        getCostCenters={props.getCostCenters}
        updateCompanyAccountPlan={props.updateCompanyAccountPlan}
        syncCompanyAccountingIntegration={props.syncCompanyAccountingIntegration}
      />
    </IntegrationsLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    availableAccountingIntegrations: state.availableAccountingIntegrations,
    companyAccountingIntegration: state.companyAccountingIntegration,
    companyAccountPlans: state.companyAccountPlans,
    costCenters: state.costCenters,
    warnings: state.warnings,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getAvailableAccountingIntegrations: getAvailableAccountingIntegrations,
    getCompanyAccountingIntegration: getCompanyAccountingIntegration,
    getCompanyAccountPlans: getCompanyAccountPlans,
    getCostCenters: getCostCenters,
    updateCompanyAccountPlan: updateCompanyAccountPlan,
    syncCompanyAccountingIntegration: syncCompanyAccountingIntegration,
  }
)(Accounting)
