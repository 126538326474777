import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import CompanySetting from '../../../model/companySetting'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import SupplementType from '../../../model/supplementType'
import { ContractReducer } from '../../../reducers/contracts'
import {
  isAmountChoiceSupplement,
  isChoiceSupplement,
  isDirectPayChoiceSupplement,
} from '../../../utils/employee-contract-utils'
import { formatSupplementTypeFullName } from '../../../utils/format-utils'
import { formatNumber } from '../../../utils/number-utils'
import { t, tx } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Table from '../../antd/table'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import EmploymentChoiceEdit from './EmploymentChoiceEdit'

type Props = {
  mutableContract: Contract
  employee: Employee
  contracts: ContractReducer
  settingsEnabled: CompanySetting[]
  supplementTypes: List<SupplementType>
  isMutableContract: boolean

  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
}

export default function EmploymentChoiceCard(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showEdit, setShowEdit] = useState(false)

  const { contracts } = props
  const previousContracts = usePrevious(contracts)

  const setEditVisibility = (show: boolean) => {
    if (!props.isMutableContract) {
      return
    }
    setModalKey((prev) => prev + 1)
    setShowEdit(show)
  }

  useEffect(() => {
    if (previousContracts && previousContracts.saving && !contracts.saving) {
      if (!contracts.error) {
        setEditVisibility(false)
      }
    }
  })

  type SupplementRow = {
    key: string
    type: string
    directPay: string
    rate: string
    suffix: string
  }

  const columns = () => {
    const columns: { title: string; dataIndex: string; key: string; render?: (row: SupplementRow) => ReactElement }[] =
      [{ title: t('choice.card.table.header.type'), dataIndex: 'type', key: 'type' }]
    if (props.settingsEnabled.some((setting) => setting === 'AllowSHFritvalgDirectPay')) {
      columns.push({ title: t('choice.card.table.header.direct_pay'), dataIndex: 'directPay', key: 'directPay' })
    }
    columns.push({
      title: t('choice.card.table.header.rate'),
      dataIndex: '',
      key: 'x2',
      render: (row) => <>{tx('choice.card.table.rate_format', { rate: row.rate, suffix: <em>{row.suffix}</em> })}</>,
    })
    return columns
  }

  const getChoiceRows = (): SupplementRow[] => {
    if (!props.mutableContract.remuneration) {
      return []
    }
    return props.mutableContract.remuneration.supplements
      .filter((row) => isChoiceSupplement(row.type?.name))
      .map((row) => {
        const name = row.type?.name
        const title = row.type ? formatSupplementTypeFullName(row.type.name, { skipDirectPay: true }) : ''
        let suffix = t('choice.card.table.percentage_suffix')
        let multiplier = 100
        if (isAmountChoiceSupplement(name)) {
          suffix = t('choice.card.table.fixed_suffix')
          multiplier = 1
        }
        return {
          key: row.id || '',
          type: title,
          directPay: isDirectPayChoiceSupplement(name)
            ? t('choice.card.table.direct_pay.true')
            : t('choice.card.table.direct_pay.false'),
          rate: formatNumber(row.compensationRate * multiplier, 2),
          suffix,
        }
      })
  }

  return (
    <Card>
      <TitleMenu>
        {props.isMutableContract && <Button className="ant-btn-edit" onClick={() => setEditVisibility(true)} />}
      </TitleMenu>
      <Title>{t('choice.card.title')}</Title>

      <Table columns={columns()} dataSource={getChoiceRows()} size="small" pagination={false} />

      <Modal
        key={modalKey}
        visible={showEdit}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={788}
        footer={null}
      >
        <EmploymentChoiceEdit
          visible={showEdit}
          employee={props.employee}
          mutableContract={props.mutableContract}
          contracts={props.contracts}
          supplementTypes={props.supplementTypes}
          settingsEnabled={props.settingsEnabled}
          addContract={props.addContract}
          updateContract={props.updateContract}
        />
      </Modal>
    </Card>
  )
}
