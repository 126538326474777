import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import Department from '../../model/department'
import Employee from '../../model/employee'
import { t } from '../../utils/translation-utils'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import ProjectEmployeeSelectForm, { ProjectEmployeeResult } from './ProjectEmployeeSelectForm'

type Props = {
  company: Company
  employees: List<Employee>
  employeeIDs: string[]
  departments: List<Department>

  updateEmployeeList: (employeeIDs: string[]) => void
  closeModal: () => void
}

export default function ProjectEmployeeSelect(props: Props): ReactElement | null {
  const handleSubmit = (values: ProjectEmployeeResult) => {
    props.updateEmployeeList(values.employeeIDs)
    props.closeModal()
  }

  return (
    <Card>
      <Subtitle>{t('projects.employee_select.title')}</Subtitle>
      <ProjectEmployeeSelectForm
        company={props.company}
        employees={props.employees}
        employeeIDs={props.employeeIDs}
        departments={props.departments}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
