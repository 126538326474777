import React, { ChangeEvent, ReactElement } from 'react'

import { LoanEstimate } from '../../api/loan'
import Company from '../../model/company'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatCurrency } from '../../utils/number-utils'
import RadioGroup from '../antd/radio/group'
import RadioButton from '../antd/radio/radioButton'
import Button from '../elements/button'
import { Col, Row } from '../elements/grid'
import Icon from '../elements/Icon'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import Switch from '../elements/switch'
import Title from '../elements/Title'

type Props = {
  company: Company
  lendingInfo: LoanEstimate
}

export type Fields = {
  companyName?: string
  cvr?: string
  loanAmount: number
  loanDuration: number
  acceptTerms: boolean
}

function LoanServiceForm(props: Props & FormComponentProps<Fields, Fields>): ReactElement | null {
  const { decorateField, getFieldValue, setFieldValue } = props

  const durations = props.lendingInfo.loanDurations

  const selectedLoanAmount = getFieldValue('loanAmount') as number
  const selectedLoanDuration = getFieldValue('loanDuration') as number

  const serviceFee = Math.ceil((selectedLoanAmount * ((props.lendingInfo.interestRate || 0) / 100)) / 12)
  const installments = Math.ceil(selectedLoanAmount / selectedLoanDuration)
  const monthlyPayment = Math.ceil(serviceFee + installments)

  const setLoanAmount = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFieldValue('loanAmount', parseFloat(e.currentTarget.value))
  }

  return (
    <div>
      <Row>
        <Col span={16}>
          <Title>Lån til din virksomhed</Title>
          <p>
            Mangler du penge i din virksomhed, kan du nemt låne penge via vores partner{' '}
            <a href="https://ageras.group/ageras-finance" target="_blank">
              Ageras Finance
            </a>
            . Pengene udbetales til din konto indenfor 24 timer, og er få klik væk.
          </p>
          <Row className="loan-columns-lists">
            <Col span={12}>
              <ul>
                <li>
                  <Icon type="check" />
                  <span>Intet oprettelsesgebyr</span>
                </li>
                <li>
                  <Icon type="check" />
                  <span>Ingen binding - lånet kan tilbagebetales uden ekstra gebyrer</span>
                </li>
                <li>
                  <Icon type="check" />
                  <span>Ingen pant i virksomheden - men du hæfter personligt</span>
                </li>
              </ul>
            </Col>
            <Col span={12}>
              <ul>
                <li>
                  <Icon type="check" />
                  <span>Ingen skjulte afgifter</span>
                </li>
                <li>
                  <Icon type="check" />
                  <span>Hurtig godkendelse på kun 24 timer</span>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <input
                type="range"
                className="select-amount-slider"
                onChange={setLoanAmount}
                value={selectedLoanAmount}
                min={props.lendingInfo.minimumAmount}
                max={props.lendingInfo.maximumAmount}
                step={props.lendingInfo.amountIncrements}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>Minimum {formatCurrency(props.lendingInfo.minimumAmount)}</Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              Maximum {formatCurrency(props.lendingInfo.maximumAmount)}
            </Col>
          </Row>

          {decorateField('loanDuration', { title: 'Løbetid' })(
            <RadioGroup>
              {durations.map((duration) => {
                return (
                  <RadioButton key={duration} value={duration}>
                    {duration} måneder
                  </RadioButton>
                )
              })}
            </RadioGroup>
          )}
          <Subtitle>Dine oplysninger</Subtitle>
          <Row>
            <Col span={12}>
              {decorateField('companyName', {
                title: 'Virksomhedsnavn',
                validate: (val) => {
                  if (!val) {
                    return 'Virksomhedsnavn skal indtastes'
                  }
                  return null
                },
              })(<Input disabled={true} />)}
            </Col>
            <Col span={12}>
              {decorateField('cvr', {
                title: 'CVR',
                validate: (val) => {
                  if (!val) {
                    return 'CVR-nummer skal indtastes'
                  }
                  return null
                },
              })(<Input disabled={true} />)}
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Subcard>
            <Subtitle>Lånebetingelser</Subtitle>
            <Row>
              <Col span={12} className="row-label">
                Lånebeløb
              </Col>
              <Col span={12} className="row-value">
                {formatCurrency(selectedLoanAmount, 0)}
              </Col>
            </Row>
            <Row>
              <Col span={12} className="row-label">
                Løbetid
              </Col>
              <Col span={12} className="row-value">
                {getFieldValue('loanDuration')} mdr.
              </Col>
            </Row>
            <hr />
            <Row>
              <Col span={12} className="row-label">
                Oprettelsesgebyr
              </Col>
              <Col span={12} className="row-value">
                {formatCurrency(0)}
              </Col>
            </Row>
            <Row>
              <Col span={12} className="row-label">
                Månedsgebyr
              </Col>
              <Col span={12} className="row-value">
                {formatCurrency(serviceFee, 0)}
              </Col>
            </Row>
            <Row>
              <Col span={12} className="row-label">
                Afdrag
              </Col>
              <Col span={12} className="row-value">
                {formatCurrency(installments, 0)}
              </Col>
            </Row>
            <Row>
              <Col span={12} className="row-label">
                Månedelig betaling
              </Col>
              <Col span={12} className="row-value">
                {formatCurrency(monthlyPayment, 0)}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p>
                  Overstående er et eksempel, du aftaler nærmere detaljer direkte med{' '}
                  <a href="https://ageras.group/ageras-finance" target="_blank">
                    Ageras Finance
                  </a>
                  .
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="ant-switch-wrapper">
                  {decorateField('acceptTerms', { skipWrapper: true, skipLabel: true })(<Switch />)}
                  <p style={{ display: 'inline' }}>
                    Jeg accepterer at Salary videregiver mine oplysninger til Ageras Finance, med henblik på at optage
                    et erhvervslån.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button disabled={!getFieldValue('acceptTerms')} htmlType="submit" size="large" type="secondary">
                  Anmod om lån
                </Button>
              </Col>
            </Row>
          </Subcard>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, Fields>({
  mapPropsToFields: (props) => {
    return {
      companyName: props.company.name,
      cvr: props.company.nationalID,
      loanAmount: props.lendingInfo.minimumAmount,
      loanDuration: props.lendingInfo.loanDurations[0],
      acceptTerms: false,
    }
  },
  onSubmit: (values) => {
    return {
      loanAmount: values.loanAmount,
      loanDuration: values.loanDuration,
      acceptTerms: values.acceptTerms,
    }
  },
})(LoanServiceForm)
