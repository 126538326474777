import ReimbursementVoucher from '../model/reimbursementVoucher'
import { DateFormat } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchReimbursementVouchers(
  companyID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<ReimbursementVoucher[]>> {
  return secureRequest('GET', url('/v2/reimbursementVouchers', { companyID, limit, offset }))
}

export interface ReimbursementVoucherCreate {
  companyID: string
  employeeID?: string
  fileID: string
}

export function postReimbursementVoucher(
  reimbursementVoucher: ReimbursementVoucherCreate
): Promise<RequestResponse<ReimbursementVoucher>> {
  return secureRequest('POST', url('/v2/reimbursementVouchers'), {
    body: {
      companyID: reimbursementVoucher.companyID,
      employeeID: reimbursementVoucher.employeeID,
      fileID: reimbursementVoucher.fileID,
    },
  })
}

export interface ReimbursementVoucherUpdate {
  id: string
  expenseCategoryID?: string
  costCenterID?: string
  disburseAmount: number
  disburseable: boolean
  immediatePay: boolean
  note: string
  cardNote?: string
}

export function putReimbursementVoucher(
  reimbursementVoucher: ReimbursementVoucherUpdate
): Promise<RequestResponse<ReimbursementVoucher>> {
  return secureRequest('PUT', url('/v2/reimbursementVouchers/' + reimbursementVoucher.id), {
    body: {
      expenseCategoryID: reimbursementVoucher.expenseCategoryID,
      costCenterID: reimbursementVoucher.costCenterID,
      disburseAmount: reimbursementVoucher.disburseAmount,
      disburseable: reimbursementVoucher.disburseable,
      immediatePay: reimbursementVoucher.immediatePay,
      note: reimbursementVoucher.note,
      cardNote: reimbursementVoucher.cardNote,
    },
  })
}

export interface ReimbursementVoucherFieldsUpdate {
  id: string
  receiptDate?: DateFormat
  amount?: number
  currency?: string
}

export function patchReimbursementVoucher(
  reimbursementVoucher: ReimbursementVoucherFieldsUpdate
): Promise<RequestResponse<ReimbursementVoucher>> {
  return secureRequest('PATCH', url('/v2/reimbursementVouchers/' + reimbursementVoucher.id), {
    body: {
      receiptDate: reimbursementVoucher.receiptDate,
      amount: reimbursementVoucher.amount,
      currency: reimbursementVoucher.currency,
    },
  })
}

export function patchReimbursementVouchersApprove(
  reimbursementVoucherIDs: string[]
): Promise<RequestResponse<ReimbursementVoucher[]>> {
  return secureRequest('PATCH', url('/v2/reimbursementVouchers/approve'), {
    body: reimbursementVoucherIDs,
  })
}

export function patchReimbursementVouchersUnapprove(
  reimbursementVoucherIDs: string[]
): Promise<RequestResponse<ReimbursementVoucher[]>> {
  return secureRequest('PATCH', url('/v2/reimbursementVouchers/unapprove'), {
    body: reimbursementVoucherIDs,
  })
}

export function patchReimbursementVouchersRebook(
  reimbursementVoucherIDs: string[]
): Promise<RequestResponse<ReimbursementVoucher[]>> {
  return secureRequest('PATCH', url('/v2/reimbursementVouchers/rebook'), {
    body: reimbursementVoucherIDs,
  })
}

export function patchReimbursementVouchersDraft(
  reimbursementVoucherIDs: string[]
): Promise<RequestResponse<ReimbursementVoucher[]>> {
  return secureRequest('PATCH', url('/v2/reimbursementVouchers/draft'), {
    body: reimbursementVoucherIDs,
  })
}

export function delReimbursementVoucher(id: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/reimbursementVouchers/' + id))
}
