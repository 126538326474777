import React, { ReactElement } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import { requestCompanyImport } from '../../api/companies'
import Company from '../../model/company'
import { formatError, isRequestError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Card from '../elements/card'
import Title from '../elements/Title'
import RequestImportModalForm, { RequestImportResult } from './RequestImportModalForm'

type Props = {
  company: Company

  addAlert: addAlertSignature
  closeModal: () => void
}

export default function RequestImportModal(props: Props): ReactElement | null {
  const handleSubmit = (values: RequestImportResult) => {
    let payRollSystem = values.payRollSystem
    if (payRollSystem === 'other') {
      payRollSystem = values.payRollSystemText || ''
    }
    let username = undefined,
      password = undefined
    if (payRollSystem === 'Danløn') {
      username = values.username
      password = values.password
    }
    requestCompanyImport(props.company.id, payRollSystem, values.dateRequest, username, password)
      .then(() => {
        props.addAlert('success', t('request_import.alert.success'), { timeout: 30 })
        props.closeModal()
      })
      .catch((e) => {
        if (isRequestError(e)) {
          props.addAlert('error', formatError(e))
          props.closeModal()
        }
      })
  }

  return (
    <Card>
      <Title>{t('request_import.title')}</Title>

      <RequestImportModalForm company={props.company} onSubmit={handleSubmit} />
    </Card>
  )
}
