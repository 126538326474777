import React, { ReactElement, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import OneTimePay from '../../../model/oneTimePay'
import SalaryCycle from '../../../model/salaryCycle'
import { DateFormat } from '../../../model/types'
import { formatAPIDate, formatDate, getDate } from '../../../utils/date-utils'
import { decorateFieldSignature, getFieldValueSignature, setFieldValueSignature } from '../../../utils/form-utils'
import { getDispositionDateList, getOneTimePaySelectedDate } from '../../../utils/one-time-pay-utils'
import { getCurrentPeriodFromDispositionDate } from '../../../utils/salary-period-utils'
import { t } from '../../../utils/translation-utils'
import Form from '../../antd/form'
import Select from '../../antd/select'
import DatePicker from '../../elements/date-picker'
import Switch from '../../elements/switch'
import SupportUserLock from '../../widgets/SupportUserLock'

type OneTimePayDispositionDateFields = {
  dispositionDate?: DateFormat
}

type Props<Fields extends OneTimePayDispositionDateFields> = {
  title?: string
  placeholder?: string
  oneTimePayID: string | undefined
  editing: boolean
  oneTimePays: OneTimePay[]
  salaryCycle: SalaryCycle

  decorateField: decorateFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  setFieldValue: setFieldValueSignature<Fields>
}

export default function OneTimePayDispositionDate<Fields extends OneTimePayDispositionDateFields>(
  props: Props<Fields>
): ReactElement | null {
  const [pickerMode, setPickerMode] = useState(false)
  const [pickerDate, setPickerDate] = useState<Date>()
  const { title = t('otp_form.disposition_date.default_title') } = props
  const { placeholder = title } = props

  // Here we override the dispositionDate if we are creating a new OTP
  // This will override whatever the forms may have set themselves
  // This only happens when the form is rendered
  // This is to ensure consistent behaviour in calculating the dispositionDate
  useEffectOnce(() => {
    if (props.oneTimePayID) {
      setPickerDate(getDate(props.getFieldValue('dispositionDate')))
      return // do nothing, we can trust the value of dispositionDate
    }

    const dispositionDate = getCurrentPeriodFromDispositionDate(props.salaryCycle.salaryPeriods)?.dispositionDate
    props.setFieldValue('dispositionDate', dispositionDate)
    setPickerDate(getDate(dispositionDate))
  })

  const selectDate = props.getFieldValue('dispositionDate')
  const { setFieldValue } = props
  useEffect(() => {
    if (!selectDate || !pickerDate) {
      return
    }
    if (pickerMode) {
      if (selectDate !== formatAPIDate(pickerDate)) {
        setFieldValue('dispositionDate', formatAPIDate(pickerDate))
      }
    } else {
      if (selectDate !== formatAPIDate(pickerDate)) {
        setPickerDate(getDate(selectDate))
      }
    }
  }, [pickerMode, selectDate, pickerDate, setFieldValue])

  return (
    <>
      {!pickerMode &&
        props.decorateField('dispositionDate', {
          title: title,
          placeholder: placeholder,
          validate: (val) => (!val ? t('otp_form.disposition_date.required', { title: placeholder }) : null),
        })(
          <Select dropdownMatchSelectWidth={false} disabled={!props.editing}>
            {getDispositionDateList(
              props.salaryCycle.salaryPeriods,
              [
                getOneTimePaySelectedDate(props.oneTimePays, props.oneTimePayID),
                props.getFieldValue('dispositionDate'),
              ].reduce((list: DateFormat[], date) => {
                if (!date) {
                  return list
                }
                if (list.indexOf(date) !== -1) {
                  return list
                }
                list.push(date)
                return list
              }, [])
            ).map((date) => {
              return (
                <Select.Option key={date} value={date}>
                  {formatDate(date)}
                </Select.Option>
              )
            })}
          </Select>
        )}
      {pickerMode && (
        <Form.Item>
          <label key={'date-picker-label'} title={title}>
            {title}
          </label>
          <DatePicker
            value={pickerDate}
            onChange={(date) => setPickerDate(date || undefined)}
            inputStyle={{ position: 'relative', top: '-10px' }}
          />
        </Form.Item>
      )}
      <SupportUserLock>
        <div className="ant-switch-wrapper">
          <Switch onChange={(val: boolean) => setPickerMode(val)} />
          <span className="ant-switch-text">{t('otp_form.disposition_date.picker')}</span>
        </div>
      </SupportUserLock>
    </>
  )
}
