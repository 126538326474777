import React, { ReactElement, useEffect } from 'react'
import { usePrevious } from 'react-use'

import { getCompany } from '../../actions/companies'
import { getEmployees } from '../../actions/employees'
import { approvePayRoll, getPayRoll, reopenPayRoll } from '../../actions/pay-rolls'
import { getPaySlips } from '../../actions/pay-slips'
import { getUser, loginUserID, logout } from '../../actions/user'
import Alert from '../../components/elements/alert'
import PayrollApproveLogin from '../../components/payroll-approve-page/PayrollApproveLogin'
import PayrollApprovePage from '../../components/payroll-approve-page/PayrollApprovePage'
import LoadingOverlay from '../../components/widgets/LoadingOverlay'
import { AccessToken } from '../../model/user'
import { CompanyReducer } from '../../reducers/companies'
import { EmployeeReducer } from '../../reducers/employees'
import { PayRollReducer } from '../../reducers/payRolls'
import { PaySlipReducer } from '../../reducers/paySlips'
import { UserReducer } from '../../reducers/user'
import { connectToReducer } from '../../utils/reducer-utils'
import { ChannelMFAError, RequestError } from '../../utils/request-utils'
import { RouteProps } from '../../utils/route-utils'

import './PhonePage.css'

type Reducers = {
  payRolls: PayRollReducer
  companies: CompanyReducer
  user: UserReducer
  employees: EmployeeReducer
  paySlips: PaySlipReducer
}

type Actions = {
  logout: () => void
  loginUserID: (
    userID: string,
    mfaChallengeID?: string,
    mfaResponse?: string
  ) => Promise<AccessToken | RequestError | ChannelMFAError>
  getUser: () => void
  getCompany: (companyID: string) => void
  getEmployees: () => void
  getPayRoll: (payRollID: string) => void
  getPaySlips: (payRollID: string) => void
  approvePayRoll: (payRollID: string, version: string, approvalCode?: string) => void
  reopenPayRoll: (payRollID: string, version: string, note?: string, notify?: boolean) => Promise<boolean | void>
}

function hyphenUUID(uuid: string): string {
  return (
    uuid.substring(0, 8) +
    '-' +
    uuid.substring(8, 12) +
    '-' +
    uuid.substring(12, 16) +
    '-' +
    uuid.substring(16, 20) +
    '-' +
    uuid.substring(20, 32)
  )
}

function PayrollApprovePhonePage(props: Reducers & Actions & RouteProps): ReactElement | null {
  const q = props.location.query.q
  let userID: string | undefined = undefined
  let payRollID: string | undefined = undefined
  if (q) {
    userID = hyphenUUID(q.substring(0, 32))
    payRollID = hyphenUUID(q.substring(32, 64))
  }

  const { user, logout, getUser } = props
  const previousUser = usePrevious(user)

  useEffect(() => {
    if (!userID || !payRollID) {
      return
    }
    if (user.loggedIn && !user.loading && !user.id) {
      getUser()
      return
    }
    // ensure we are not logged in with the wrong user
    if (user.loggedIn && user.id !== userID) {
      logout()
    }
  }, [user, userID, payRollID, getUser, logout])

  if (!userID || !payRollID) {
    return (
      <div>
        <Alert message={'Der mangler information i din anmodning, tjek at stien er korrekt'} type="error" showIcon />
      </div>
    )
  }

  if (user.loading) {
    return <LoadingOverlay />
  }

  return (
    <div className="payroll-approve-wrapper">
      {(!user.loggedIn || user.loggingIn) && (
        <PayrollApproveLogin
          user={user}
          justLoggedOut={!!previousUser && previousUser.loggedIn && !user.loggedIn}
          userID={userID}
          loginUserID={props.loginUserID}
        />
      )}
      {user.loggedIn && (
        <PayrollApprovePage
          user={user}
          payRolls={props.payRolls}
          companies={props.companies}
          employees={props.employees}
          paySlips={props.paySlips}
          payRollID={payRollID}
          getPayRoll={props.getPayRoll}
          getPaySlips={props.getPaySlips}
          getEmployees={props.getEmployees}
          logout={props.logout}
          getCompany={props.getCompany}
          approvePayRoll={props.approvePayRoll}
          reopenPayRoll={props.reopenPayRoll}
        />
      )}
    </div>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    companies: state.companies,
    user: state.user,
    payRolls: state.payRolls,
    paySlips: state.paySlips,
    employees: state.employees,
  }),
  {
    loginUserID: loginUserID,
    logout: logout,
    getUser: getUser,
    getCompany: getCompany,
    getPayRoll: getPayRoll,
    approvePayRoll: approvePayRoll,
    reopenPayRoll: reopenPayRoll,
    getEmployees: getEmployees,
    getPaySlips: getPaySlips,
  }
)(PayrollApprovePhonePage)
