import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature } from '../../../actions/alerts'
import { CostCenterAccounting } from '../../../model/accountingIntegration'
import { CoarseTimeRegistrationMutableFields } from '../../../model/coarseTimeRegistration'
import Company from '../../../model/company'
import Contract from '../../../model/contract'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import Project from '../../../model/project'
import SalaryCycle from '../../../model/salaryCycle'
import SalaryType from '../../../model/salaryType'
import { TimeRegistrationClass, TimeRegistrationMutableFields } from '../../../model/timeRegistration'
import { CoarseTimeRegistrationReducer } from '../../../reducers/coarseTimeRegistrations'
import { ContractReducer } from '../../../reducers/contracts'
import { TimeRegistrationReducer } from '../../../reducers/timeRegistrations'
import { paths } from '../../../routes'
import { formatAPIDate } from '../../../utils/date-utils'
import { getValidFrom } from '../../../utils/employment-utils'
import { formatSavingText } from '../../../utils/loading-utils'
import { formatCurrency } from '../../../utils/number-utils'
import { projectFilter } from '../../../utils/projects-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Card from '../../elements/card'
import { RegistrationMethodAlert } from '../../elements/RegistrationMethodAlert'
import { RegistrationMethodAppAlert } from '../../elements/RegistrationMethodAppAlert'
import Switch from '../../elements/switch'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import { TimeRegistrationMode } from '../../types'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import CoarseTimeRegistrationHistoryTab from './CoarseTimeRegistrationHistoryTab'
import CoarseTimeRegistrationTab from './CoarseTimeRegistrationTab'
import DetailedTimeRegistrationHistoryTab from './DetailedTimeRegistrationHistoryTab'
import DetailedTimeRegistrationTab from './DetailedTimeRegistrationTab'

type BasicProps = {
  mode: TimeRegistrationMode
  subsection?: string
  company: Company
  employee: Employee
  contracts: ContractReducer
  timeRegistrations: TimeRegistrationReducer
  salaryCycles: List<SalaryCycle>
  salaryCycle?: SalaryCycle
  projects: List<Project>
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>
  departments: List<Department>
  canEditObjects: boolean
  canHireFire: boolean
  canApproveObjects: boolean

  addAlert: addAlertSignature
  addContract: (contract: Contract) => void
  updateContract: (contract: Contract) => void
  createTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  approveTimeRegistrations: (ids: string[]) => void
  unapproveTimeRegistrations: (ids: string[]) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (id: string) => void
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    type: TimeRegistrationClass
  ) => void
}

type FreelancerProps = BasicProps & {
  forFreelancer: true
  mode: 'Project'
}

type NonFreelancerProps = BasicProps & {
  forFreelancer: false
  hasTimeRegistrationsForPeriod: boolean
  coarseTimeRegistrations: CoarseTimeRegistrationReducer
  salaryTypes: List<SalaryType>
  leaveTypes: List<LeaveType>
  updateCoarseTimeRegistrationBulk: (employeeID: string, registrations: CoarseTimeRegistrationMutableFields[]) => void
}

export default function TimeRegistrationTab(props: FreelancerProps | NonFreelancerProps): ReactElement | null {
  type State = {
    hasHourRegistration: boolean
    hasProject: boolean
    hasDetailedTimeRegistration: boolean
    hasFlexTime: boolean
    hasOvertime: boolean
  }

  const { employee } = props
  const salaryTypes = props.forFreelancer ? List<SalaryType>() : props.salaryTypes

  const getState = useCallback((): State => {
    const contract = employee.activeContract
    const hasHourRegistration =
      (!props.forFreelancer &&
        contract?.remuneration?.salary.some((salary) =>
          salaryTypes.some((salaryType) => salaryType.id === salary.salaryTypeID && salaryType.class === 'Hourly')
        )) ??
      false
    const hasProject =
      (hasHourRegistration || props.mode === 'Project') && props.projects.some((p) => projectFilter(p, employee))
    let { hasFlexTime, hasOvertime } = {
      hasFlexTime: false,
      hasOvertime: false,
    }
    if (contract && contract.remuneration && contract.remuneration.leave) {
      hasFlexTime = contract.remuneration.leave.some((leave) => leave.type?.class === 'Flex')
      hasOvertime = contract.remuneration.leave.some((leave) => leave.type?.class === 'Overtime')
    }

    return {
      hasHourRegistration,
      hasProject,
      hasDetailedTimeRegistration: !!contract && contract.timeRegistrationMethodType !== 'Coarse',
      hasFlexTime: hasFlexTime,
      hasOvertime: hasOvertime,
    }
  }, [employee, props.projects, props.mode, props.forFreelancer, salaryTypes])

  const [state, setState] = useState(getState())

  useEffect(() => {
    const newState = getState()
    if (
      newState.hasHourRegistration !== state.hasHourRegistration ||
      newState.hasDetailedTimeRegistration !== state.hasDetailedTimeRegistration ||
      newState.hasFlexTime !== state.hasFlexTime ||
      newState.hasOvertime !== state.hasOvertime
    ) {
      setState(newState)
    }
  }, [state, getState])

  const hasDetailedTimeRegistration = () => {
    return (
      props.mode === 'Project' ||
      (state.hasHourRegistration && state.hasDetailedTimeRegistration) ||
      props.mode === 'FlexTime' ||
      props.mode === 'Overtime'
    )
  }
  const hasCoarseTimeRegistration = () => {
    return props.mode === 'Hours' && state.hasHourRegistration && !state.hasDetailedTimeRegistration
  }
  const showHistory = props.subsection === 'history'

  const handleChange = (val: boolean, force = false) => {
    if (!props.employee.earliestMutableContract) {
      return
    }
    setState((prev) => ({ ...prev, hasDetailedTimeRegistration: val }))
    const contract: Contract = {
      ...props.employee.earliestMutableContract,
      timeRegistrationMethodType: val ? 'Detailed' : 'Coarse',
    }
    if (force) {
      props.updateContract(contract)
      return
    }
    const { validFrom, canUpdate } = getValidFrom(props.employee, contract)
    if (canUpdate) {
      props.updateContract(contract)
    } else {
      contract.validFrom = formatAPIDate(validFrom)
      props.addContract(contract)
    }
  }

  const deleteDetailed = () => {
    if (props.mode !== 'Hours') {
      return
    }
    props.deleteTimeRegistrationBulk(undefined, props.employee.id, 'Hours')
  }
  const deleteCoarse = () => {
    if (props.mode !== 'Hours') {
      return
    }
    props.updateCoarseTimeRegistrationBulk(
      props.employee.id,
      props.coarseTimeRegistrations.coarseTimeRegistrations
        .filter((registration) => registration.employeeID === props.employee.id && !registration.immutable)
        .reduce((fields: CoarseTimeRegistrationMutableFields[], registration) => {
          fields.push({
            employeeID: registration.employeeID,
            salaryTypeID: registration.salaryTypeID,
            salaryPeriodID: registration.salaryPeriodID,
            hours: 0,
            days: 0,
          })
          return fields
        }, [])
    )
  }

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (
    !props.employee.activeContract ||
    props.employee.activeEmployment.id !== props.employee.activeContract.employmentID
  ) {
    return <NoContractCard employee={props.employee} />
  }
  let timeRegTitle = showHistory
    ? t('time_registration_tab.title.history.hours')
    : t('time_registration_tab.title.standard.hours')
  switch (props.mode) {
    case 'FlexTime':
      timeRegTitle = showHistory
        ? t('time_registration_tab.title.history.flex_time')
        : t('time_registration_tab.title.standard.flex_time')
      break
    case 'Overtime':
      timeRegTitle = showHistory
        ? t('time_registration_tab.title.history.overtime')
        : t('time_registration_tab.title.standard.overtime')
      break
    case 'Project':
      timeRegTitle = showHistory
        ? t('time_registration_tab.title.history.project')
        : t('time_registration_tab.title.standard.project')
      break
  }

  if (!props.salaryCycle) {
    return null
  }

  // show the detail toggle if in hours mode (and no registrations) or in non-hours mode, and it's Coarse
  const showDetailToggle =
    props.canHireFire &&
    !props.forFreelancer &&
    !showHistory &&
    props.employee.earliestMutableContract &&
    ((props.mode === 'Hours' && !props.hasTimeRegistrationsForPeriod) ||
      (props.mode !== 'Hours' && props.employee.earliestMutableContract.timeRegistrationMethodType === 'Coarse'))

  const subsection =
    props.mode === 'FlexTime' || props.mode === 'Overtime'
      ? 'time-box-registration'
      : props.mode === 'Project'
      ? 'project-registration'
      : 'time-registration'

  let pendingDetailedTimeRegistrationSummary = { count: 0, value: 0 }
  let pendingCoarseTimeRegistrationSummary = { count: 0, value: 0 }
  if (props.mode === 'Hours') {
    pendingDetailedTimeRegistrationSummary = props.timeRegistrations.timeRegistrations
      .filter(
        (registration) =>
          registration.employeeID === props.employee.id &&
          registration.class === 'Hours' &&
          registration.state === 'Pending'
      )
      .reduce(
        (summary, registration) => {
          const salary = props.employee.activeContract?.remuneration?.salary.find(
            (line) => line.salaryTypeID === registration.salaryTypeID
          )
          if (!salary) {
            return summary
          }
          return {
            count: summary.count + 1,
            value: summary.value + (registration.hours ?? 0) * salary.rate,
          }
        },
        { count: 0, value: 0 }
      )
    pendingCoarseTimeRegistrationSummary = props.coarseTimeRegistrations.coarseTimeRegistrations
      .filter((registration) => registration.employeeID === props.employee.id && !registration.immutable)
      .reduce(
        (summary, registration) => {
          const salary = props.employee.activeContract?.remuneration?.salary.find(
            (line) => line.salaryTypeID === registration.salaryTypeID
          )
          if (!salary) {
            return summary
          }
          return {
            count: summary.count + 1,
            value: summary.value + registration.hours * salary.rate,
          }
        },
        { count: 0, value: 0 }
      )
  }

  return (
    <div>
      {props.mode === 'Hours' && (
        <RegistrationMethodAppAlert
          company={props.company}
          disablingSettings={['DisableAppTimeRegistration', 'DisableTimeRegistrationMethodDetailedOnInvite']}
          employee={props.employee}
          isDetailed={state.hasDetailedTimeRegistration}
          itemMessageID="time_registration"
          switchToDetailed={() => handleChange(true, true)}
        />
      )}
      <Card className="employees-single-form">
        {props.mode === 'Hours' && (
          <RegistrationMethodAlert
            isDetailed={state.hasDetailedTimeRegistration}
            detailedSummary={pendingDetailedTimeRegistrationSummary}
            coarseSummary={pendingCoarseTimeRegistrationSummary}
            itemMessageID={'time_registration'}
            deleteDetailed={deleteDetailed}
            deleteCoarse={deleteCoarse}
            switchToDetailed={() => handleChange(true)}
            switchToCoarse={() => handleChange(false)}
            formatValue={(value) => formatCurrency(value, 2)}
          />
        )}
        <TitleMenu>
          {showDetailToggle && (
            <div className="ant-switch-wrapper" style={{ display: 'inline-block', marginRight: 20 }}>
              <Switch checked={state.hasDetailedTimeRegistration} onChange={handleChange} />
              <span className="ant-switch-text">
                {props.mode === 'Hours'
                  ? t('time_registration_tab.header.detailed.hours')
                  : t('time_registration_tab.header.detailed.other')}
              </span>
            </div>
          )}
          {showHistory ? (
            <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/' + subsection}>
              <Button className="gtm-hide-time-reg-history" style={{ paddingRight: 14 }}>
                {t('time_registration_tab.header.hide_history')}
              </Button>
            </Link>
          ) : (
            <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/' + subsection + '/history'}>
              <Button className="gtm-show-time-reg-history" style={{ paddingRight: 14 }}>
                {t('time_registration_tab.header.show_history')}
              </Button>
            </Link>
          )}
        </TitleMenu>
        <Title>
          {timeRegTitle}
          {props.mode === 'Hours' && hasDetailedTimeRegistration() && (
            <Link to={'/' + paths.TIME_REGISTRATION + '/calendar'} className={'subtitle-link'}>
              {t('time_registration_tab.header.go_to_calendar.hours')}
            </Link>
          )}
        </Title>
        {hasDetailedTimeRegistration() && !showHistory && (
          <DetailedTimeRegistrationTab
            company={props.company}
            employee={props.employee}
            mode={props.mode}
            canEditObjects={props.canEditObjects}
            canApproveObjects={props.canApproveObjects}
            timeRegistrations={props.timeRegistrations}
            salaryTypes={props.forFreelancer ? List() : props.salaryTypes}
            leaveTypes={props.forFreelancer ? List() : props.leaveTypes}
            projects={props.projects}
            costCenterAccounting={props.costCenterAccounting}
            costCenters={props.costCenters}
            departments={props.departments}
            addAlert={props.addAlert}
            createTimeRegistration={props.createTimeRegistration}
            approveTimeRegistrations={props.approveTimeRegistrations}
            unapproveTimeRegistrations={props.unapproveTimeRegistrations}
            updateTimeRegistration={props.updateTimeRegistration}
            deleteTimeRegistration={props.deleteTimeRegistration}
            deleteTimeRegistrationBulk={props.deleteTimeRegistrationBulk}
          />
        )}
        {hasDetailedTimeRegistration() && showHistory && (
          <DetailedTimeRegistrationHistoryTab
            employee={props.employee}
            mode={props.mode}
            timeRegistrations={props.timeRegistrations}
            salaryTypes={props.forFreelancer ? List() : props.salaryTypes}
            leaveTypes={props.forFreelancer ? List() : props.leaveTypes}
            projects={props.projects}
          />
        )}
        {hasCoarseTimeRegistration() && showHistory && !props.forFreelancer && (
          <CoarseTimeRegistrationHistoryTab
            employee={props.employee}
            contracts={props.contracts}
            coarseTimeRegistrations={props.coarseTimeRegistrations}
            salaryCycles={props.salaryCycles}
            salaryCycle={props.salaryCycle}
            salaryTypes={props.salaryTypes}
            addAlert={props.addAlert}
            addContract={props.addContract}
            updateContract={props.updateContract}
            updateCoarseTimeRegistrationBulk={props.updateCoarseTimeRegistrationBulk}
          />
        )}
        {hasCoarseTimeRegistration() && !showHistory && !props.forFreelancer && (
          <CoarseTimeRegistrationTab
            employee={props.employee}
            preventPeriodChange={false}
            contracts={props.contracts}
            coarseTimeRegistrations={props.coarseTimeRegistrations}
            salaryCycles={props.salaryCycles}
            salaryCycle={props.salaryCycle}
            salaryTypes={props.salaryTypes}
            addAlert={props.addAlert}
            addContract={props.addContract}
            updateContract={props.updateContract}
            updateCoarseTimeRegistrationBulk={props.updateCoarseTimeRegistrationBulk}
          />
        )}
        {props.contracts.saving && (
          <LoadingOverlay text={formatSavingText([{ loading: props.contracts.saving, text: 'kontrakt' }])} />
        )}
      </Card>
    </div>
  )
}
