import React from 'react'

import {
  deleteAssetRequest,
  fetchAssets,
  patchHideAsset,
  patchReceiveAsset,
  patchReturnAsset,
  postAsset,
  putAsset,
} from '../api/assets'
import ActionTypes from '../constants/action-types'
import Asset, { AssetMutableFields } from '../model/asset'
import { AssetAction } from '../reducers/assets'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'

function creatingAsset(companyID: string): AssetAction {
  return {
    type: ActionTypes.ASSET_CREATING,
    companyID,
  }
}
export function createdAsset(companyID: string, asset: Asset): AssetAction {
  return {
    type: ActionTypes.ASSET_CREATED,
    companyID,
    asset: asset,
  }
}
function failedCreatingAsset(companyID: string, error: Error): AssetAction {
  return {
    type: ActionTypes.ASSET_CREATE_FAILED,
    error,
    companyID,
  }
}

function deletingAsset(companyID: string): AssetAction {
  return {
    type: ActionTypes.ASSET_DELETING,
    companyID,
  }
}
export function deletedAsset(assetID: string): AssetAction {
  return {
    type: ActionTypes.ASSET_DELETED,
    assetID: assetID,
  }
}
function failedDeletingAsset(error: Error): AssetAction {
  return {
    type: ActionTypes.ASSET_DELETE_FAILED,
    error,
  }
}

function updatingAsset(companyID: string): AssetAction {
  return {
    type: ActionTypes.ASSET_UPDATING,
    companyID,
  }
}
export function updatedAsset(companyID: string, asset: Asset): AssetAction {
  return {
    type: ActionTypes.ASSET_UPDATED,
    companyID,
    assetID: asset.id,
    asset: asset,
  }
}
function failedUpdatingAsset(companyID: string, error: Error): AssetAction {
  return {
    type: ActionTypes.ASSET_UPDATE_FAILED,
    error,
    companyID,
  }
}

function loadingAssets(companyID: string): AssetAction {
  return {
    type: ActionTypes.ASSET_LOADING,
    companyID,
  }
}
function loadedAssets(companyID: string, assets: Asset[], partial = false): AssetAction {
  return {
    type: partial ? ActionTypes.ASSET_LOADED_PARTIAL : ActionTypes.ASSET_LOADED,
    assets,
    companyID,
  }
}
function failedLoadingAssets(companyID: string, error: Error): AssetAction {
  return {
    type: ActionTypes.ASSET_LOAD_FAILED,
    error,
    companyID,
  }
}

export function createAsset(companyID: string, asset: AssetMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<Asset | void> => {
    dispatch(creatingAsset(companyID))
    return postAsset(asset)
      .then((response) => {
        dispatch(createdAsset(companyID, response.data))
        return response.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingAsset(companyID, e))
        }
      })
  }
}

export function deleteAsset(assetID: string) {
  return (dispatch: React.Dispatch<any>): Promise<Asset | void> => {
    dispatch(deletingAsset(assetID))
    return deleteAssetRequest(assetID)
      .then(() => {
        dispatch(deletedAsset(assetID))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingAsset(e))
        }
      })
  }
}

export function updateAsset(companyID: string, asset: AssetMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<Asset | void> => {
    dispatch(updatingAsset(companyID))
    return putAsset(asset)
      .then((response) => {
        dispatch(updatedAsset(companyID, response.data))
        return response.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingAsset(companyID, e))
        }
      })
  }
}

export function returnAsset(companyID: string, asset: Asset) {
  return (dispatch: React.Dispatch<any>): Promise<Asset | void> => {
    dispatch(updatingAsset(asset.id))
    return patchReturnAsset(asset.id)
      .then((response) => {
        dispatch(updatedAsset(companyID, response.data))
        return response.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingAsset(companyID, e))
        }
      })
  }
}

export function receiveAsset(companyID: string, asset: Asset) {
  return (dispatch: React.Dispatch<any>): Promise<Asset | void> => {
    dispatch(updatingAsset(asset.id))
    return patchReceiveAsset(asset.id)
      .then((response) => {
        dispatch(updatedAsset(companyID, response.data))
        return response.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingAsset(companyID, e))
        }
      })
  }
}

export function hideAsset(companyID: string, asset: Asset) {
  return (dispatch: React.Dispatch<any>): Promise<Asset | void> => {
    dispatch(updatingAsset(asset.id))
    return patchHideAsset(asset.id)
      .then((response) => {
        dispatch(updatedAsset(companyID, response.data))
        return response.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingAsset(companyID, e))
        }
      })
  }
}

export function getAssets(offset?: number) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<Asset[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    if (!offset) {
      dispatch(loadingAssets(companyID))
      offset = 0
    }

    const limit = 1000
    return fetchAssets(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedAssets(companyID, data)),
          (data) => dispatch(loadedAssets(companyID, data, true)),
          (offset) => dispatch(getAssets(offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingAssets(companyID, e))
        }
      })
  }
}
