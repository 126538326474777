import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { CompanyReducer } from '../../reducers/companies'
import { EmployeeReducer } from '../../reducers/employees'
import { PayRollReducer } from '../../reducers/payRolls'
import { PaySlipReducer } from '../../reducers/paySlips'
import { UserReducer } from '../../reducers/user'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatDate, formatDateTime, trimCurrentYear } from '../../utils/date-utils'
import { formatError } from '../../utils/error-utils'
import { formatUserPayRollStatus } from '../../utils/format-utils'
import { convertPayRollStatus } from '../../utils/pay-roll-utils'
import { secureUrl } from '../../utils/request-utils'
import { t, tx } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import { Col, Row } from '../elements/grid'
import Icon from '../elements/Icon'
import Input from '../elements/input'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'
import PayrollApproveReopenModal from './PayrollApproveReopenModal'
import PaySlipTable from './PaySlipTable'

type Props = {
  user: UserReducer
  payRolls: PayRollReducer
  employees: EmployeeReducer
  companies: CompanyReducer
  paySlips: PaySlipReducer
  payRollID: string

  logout: () => void
  getCompany: (companyID: string) => void
  getPayRoll: (payRollID: string) => void
  getPaySlips: (payRollID: string) => void
  getEmployees: () => void
  approvePayRoll: (payRollID: string, version: string, approvalCode?: string) => void
  reopenPayRoll: (payRollID: string, version: string, note?: string, notify?: boolean) => Promise<boolean | void>
}

export default function PayrollApprovePage(props: Props): ReactElement | null {
  const [approvalCode, setApprovalCode] = useState<string>()
  const [showReject, setShowReject] = useState(false)
  const [modalKey, setModalKey] = useState(1)
  const [error, setError] = useState<Error | null>(null)

  const setRejectVisibility = (visible: boolean) => {
    setModalKey((prev) => prev + 1)
    setShowReject(visible)
  }

  const { payRolls, getPayRoll, payRollID, paySlips, getPaySlips } = props
  useEffect(() => {
    if (!payRolls.loading && !payRolls.loaded) {
      getPayRoll(payRollID)
    }
    if (!paySlips.loading && !paySlips.loaded) {
      getPaySlips(payRollID)
    }
  }, [payRolls, getPayRoll, payRollID, paySlips, getPaySlips])

  const { companies, getCompany, employees, getEmployees } = props

  useEffect(() => {
    if (payRolls.loaded) {
      const payRoll = payRolls.payRolls.find((payRoll) => payRoll.id === payRollID)
      if (payRoll) {
        if (!companies.loading && !companies.loaded) {
          getCompany(payRoll.companyID)
        }
      }
    }
  }, [payRollID, payRolls, companies, getCompany])

  useEffect(() => {
    if (!employees.loading && !employees.loaded) {
      if (companies.loaded) {
        getEmployees()
      }
    }
  }, [companies, employees, getEmployees])

  useEffect(() => {
    regularComponentDidUpdate(payRolls.error, error, setError)
  }, [payRolls, error, setError])

  const logout = () => {
    props.logout()
  }
  const approve = () => {
    const payRoll = props.payRolls.payRolls.find((payRoll) => payRoll.id === props.payRollID)
    if (!payRoll) {
      return
    }
    props.approvePayRoll(payRoll.id, payRoll.version, approvalCode)
  }

  if (!payRolls.loaded || payRolls.saving || !companies.loaded) {
    return <LoadingOverlay />
  }
  const payRoll = props.payRolls.payRolls.find((payRoll) => payRoll.id === props.payRollID)
  if (!payRoll) {
    return <Alert message={t('payroll_approve.page.error.unknown_pay_roll')} type="error" showIcon />
  }
  const company = companies.company
  if (!company) {
    return <Alert message={t('payroll_approve.page.error.unknown_company')} type="error" showIcon />
  }

  const status = convertPayRollStatus(payRoll)
  const isAwaitingApproval =
    (status === 'Reviewed' || status === 'Tentative') &&
    !payRoll.approvers.some((approver) => approver.userID === props.user.id)

  return (
    <div>
      <Card>
        {error && <Alert type={'error'} message={formatError(error)} showIcon />}
        <Title>
          {t('payroll_approve.page.card.pay_roll_for')}
          <br />
          {company.name}:
          <br />
          <strong>
            {trimCurrentYear(formatDate(payRoll.salaryPeriod.start))} - {formatDate(payRoll.salaryPeriod.end)}
          </strong>
          <br />
          {payRoll.tasks.length > 0 && payRoll.tasks[0].approvalDeadline && (
            <small>
              (
              {t('payroll_approve.page.card.approve_deadline', {
                time: trimCurrentYear(formatDateTime(payRoll.tasks[0].approvalDeadline)),
              })}
            </small>
          )}
        </Title>
        <Row>
          <Col span={24}>
            <Link to={secureUrl('/v2/paySlipsPDF?payRollID=' + props.payRollID)} target={'_blank'}>
              <Button className="btn-download">
                <Icon type="download" color="grey" />
                {t('payroll_approve.page.card.download_payslips')}
              </Button>
            </Link>
          </Col>
        </Row>
        {isAwaitingApproval && (
          <>
            {company.askForPayrollApprovalCode && (
              <Row>
                <Col span={24}>
                  <Input
                    placeholder={t('payroll_approve.page.card.approve.approval_code')}
                    onChange={(e) => setApprovalCode(e.currentTarget.value)}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col span={12}>
                <Button className="btn-reject" onClick={() => setRejectVisibility(true)}>
                  {t('payroll_approve.page.card.approve.reject')}
                </Button>
              </Col>
              <Col span={12}>
                <Button className="btn-approve" onClick={approve}>
                  {t('payroll_approve.page.card.approve.approve')}
                </Button>
              </Col>
            </Row>
          </>
        )}
        {!isAwaitingApproval && (
          <Row>
            <Col span={24}>
              <p>
                {tx('payroll_approve.page.card.pay_roll_status', {
                  status: <strong>{formatUserPayRollStatus(status)}</strong>,
                })}
              </p>
            </Col>
          </Row>
        )}
        {props.paySlips.paySlips.size > 0 && (
          <PaySlipTable paySlips={props.paySlips.paySlips} employees={props.employees.employees} />
        )}
        <Row>
          <Col span={24}>
            <Button className="btn-logout" onClick={logout}>
              {t('payroll_approve.page.card.log_out')}
            </Button>
          </Col>
        </Row>
      </Card>

      <Modal
        key={modalKey}
        visible={showReject}
        onOk={() => setRejectVisibility(false)}
        onCancel={() => setRejectVisibility(false)}
        className="payroll-approve-wrapper"
        footer={null}
      >
        <PayrollApproveReopenModal
          user={props.user}
          payRoll={payRoll}
          payRolls={props.payRolls}
          reopenPayRoll={props.reopenPayRoll}
          closeModal={() => setRejectVisibility(false)}
        />
      </Modal>
    </div>
  )
}
