import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { AccountingDimensionCreation, AccountingDimensionMutable } from '../../model/accountingDimension'
import AccountingIntegration, { CostCenterAccounting } from '../../model/accountingIntegration'
import Company from '../../model/company'
import CompanySetting from '../../model/companySetting'
import CostCenter from '../../model/costCenter'
import { AccountingDimensionReducer } from '../../reducers/accountingDimensions'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyAccountingIntegrationReducer } from '../../reducers/companyAccountingIntegration'
import { paths } from '../../routes'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import DepartmentsForm, { AccountingDepartmentFields } from './dimensions/DepartmentsForm'
import DimensionsCard from './dimensions/DimensionsCard'
import EmployeeDimensionsCard from './dimensions/EmployeeDimensionsCard'

import './dimensions/Dimensions.css'

type Props = {
  alerts: AlertReducer
  accountingDimensions: AccountingDimensionReducer
  company: Company
  companies: CompanyReducer
  companyAccountingIntegration: CompanyAccountingIntegrationReducer
  costCenters: List<CostCenter>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  selectAccountingDimensions: (dimensions: (AccountingDimensionMutable | AccountingDimensionCreation)[]) => void
  setCompanyAccountingIntegrationAccounting: (
    companyID: string,
    costCenterAccounting: CostCenterAccounting
  ) => Promise<AccountingIntegration | void>
  enableCompanySettings: (companyID: string, settings: CompanySetting[]) => void
  disableCompanySettings: (companyID: string, settings: CompanySetting[]) => void
}

export default function AccountingDimensions(props: Props): ReactElement | null {
  const { companyAccountingIntegration } = props
  const accountingIntegration = companyAccountingIntegration.accountingIntegration

  const handleDepartmentSubmit = (values: AccountingDepartmentFields) => {
    props.setCompanyAccountingIntegrationAccounting(props.company.id, values.costCenterAccounting).then(() => {
      if (values.timeRegistrationDepartment) {
        props.enableCompanySettings(props.company.id, ['DepartmentSelectTimeRegistration'])
      } else {
        props.disableCompanySettings(props.company.id, ['DepartmentSelectTimeRegistration'])
      }
    })
  }

  const employeeDimensionEnabled = props.company.settingsEnabled.some(
    (setting) => setting === 'EnableEmployeeDimensions'
  )

  return (
    <Card>
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      <TitleMenu>
        <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING}>
          <Button>{t('accounting_tab.dimensions.back')}</Button>
        </Link>
      </TitleMenu>
      <Title>
        {accountingIntegration?.hasDimensions
          ? t('accounting_tab.dimensions.title.with_dimensions')
          : employeeDimensionEnabled
          ? t('accounting_tab.dimensions.title.employee_dimensions')
          : t('accounting_tab.dimensions.title.no_dimensions')}
      </Title>
      <Subcard>
        <Subtitle>{t('accounting_tab.dimensions.departments.title')}</Subtitle>
        <p>{t('accounting_tab.dimensions.departments.intro')}</p>
        <DepartmentsForm
          company={props.company}
          companies={props.companies}
          hasDimensions={accountingIntegration?.hasDimensions || false}
          onSubmit={handleDepartmentSubmit}
        />
      </Subcard>
      {accountingIntegration?.hasDimensions && props.company.costCenterAccounting !== 'Off' && (
        <DimensionsCard
          accountingIntegration={accountingIntegration}
          accountingDimensions={props.accountingDimensions}
          company={props.company}
          costCenters={props.costCenters}
          selectAccountingDimensions={props.selectAccountingDimensions}
        />
      )}
      {employeeDimensionEnabled && (
        <EmployeeDimensionsCard
          accountingIntegration={accountingIntegration}
          accountingDimensions={props.accountingDimensions}
          selectAccountingDimensions={props.selectAccountingDimensions}
        />
      )}
    </Card>
  )
}
