import Agreement from '../model/agreement'
import Company from '../model/company'
import CompanyFeature from '../model/companyFeature'
import CompanyPricing from '../model/companyPricing'
import CompanyPricingPackage from '../model/companyPricingPackage'
import CompanyUser from '../model/companyUser'
import CostCenter from '../model/costCenter'
import Department from '../model/department'
import DepartmentUser from '../model/departmentUser'
import Employee from '../model/employee'
import LeaveType from '../model/leaveType'
import PricingPackage from '../model/pricingPackage'
import SalaryType from '../model/salaryType'
import SupplementType from '../model/supplementType'
import { DateTimeFormat } from '../model/types'
import User from '../model/user'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export type BootstrapResponse = {
  currentTime?: DateTimeFormat
  globalMessage?: string
  user: User
  userCompanies: CompanyUser[]
  userDepartments: DepartmentUser[]
  companies: Company[]
  costCenters: CostCenter[]
  departments: Department[]
  employees: Employee[]
  salaryTypes: SalaryType[]
  leaveTypes: LeaveType[]
  supplementTypes: SupplementType[]
  companyFeatures: CompanyFeature[]
  companyPricings: CompanyPricing[]
  pricingPackages: PricingPackage[]
  companyPricingPackages: CompanyPricingPackage[]
  agreements: Agreement[]
}

export function fetchBootstrap(companyID?: string): Promise<RequestResponse<BootstrapResponse>> {
  return secureRequest('GET', url('/v2/bootstrap', { companyID }))
}
