import Animate from 'rc-animate'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'

import classNames from '../../antd/_util/classNames'

import './style/css'

type Props = React.DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  spinning?: boolean
  size?: 'small' | 'default' | 'large'
  prefixCls?: string
  tip?: ReactNode
  wrapperClassName?: string
}

export default function Spin(props: Props): ReactElement | null {
  const [stateSpinning, setStateSpinning] = useState(props.spinning)

  const {
    className,
    size = 'default',
    prefixCls = 'ant-spin',
    tip,
    spinning = true,
    wrapperClassName = '',
    ...restProps
  } = props

  useEffect(() => {
    if (stateSpinning !== spinning) {
      setStateSpinning(spinning)
    }
  }, [spinning, stateSpinning])

  const isNestedPattern = () => {
    return !!(props && props.children)
  }

  const spinClassName = classNames(
    prefixCls,
    {
      [`${prefixCls}-sm`]: size === 'small',
      [`${prefixCls}-lg`]: size === 'large',
      [`${prefixCls}-spinning`]: spinning,
      [`${prefixCls}-show-text`]: !!tip,
    },
    className
  )

  const spinElement = (
    <div {...restProps} className={spinClassName}>
      <span className={`${prefixCls}-dot`}>
        <i />
        <i />
        <i />
        <i />
      </span>
      {tip ? <div className={`${prefixCls}-text`}>{tip}</div> : null}
    </div>
  )
  if (isNestedPattern()) {
    let animateClassName = prefixCls + '-nested-loading'
    if (wrapperClassName) {
      animateClassName += ' ' + wrapperClassName
    }
    const containerClassName = classNames({
      [`${prefixCls}-container`]: true,
      [`${prefixCls}-blur`]: spinning,
    })
    return (
      <Animate {...restProps} component="div" className={animateClassName} style={null} transitionName="fade">
        {spinning && <div key="loading">{spinElement}</div>}
        <div className={containerClassName} key="container">
          {props.children}
        </div>
      </Animate>
    )
  }
  return spinElement
}
