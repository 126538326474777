import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CompanyUser from '../model/companyUser'
import { ReducerAction } from '../utils/reducer-utils'

// UserCompanies are companies the user has access to

export interface UserCompanyReducer {
  loading: boolean
  loaded: boolean
  saving: boolean
  userCompanies: List<CompanyUser>
  error: Error | null
}

const Params = Record<UserCompanyReducer>({
  loading: false,
  loaded: false,
  saving: false,
  userCompanies: List<CompanyUser>(),
  error: null,
})

export interface UserCompanyAction extends ReducerAction {
  userCompanies?: CompanyUser[]
  userCompany?: CompanyUser
  companyID?: string
}

export default (
  state: Record<UserCompanyReducer> = Params(),
  action: UserCompanyAction = { type: '' }
): Record<UserCompanyReducer> => {
  let idx = -1
  switch (action.type) {
    case ActionTypes.USER_COMPANY_LOADING:
      return Params({
        loading: true,
      })
    case ActionTypes.USER_COMPANY_LOADED:
      return Params({
        loaded: true,
        userCompanies: List<CompanyUser>(action.userCompanies),
      })
    case ActionTypes.USER_COMPANY_LOAD_FAILED:
      return Params({
        error: action.error,
      })
    case ActionTypes.USER_COMPANY_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.USER_COMPANY_ADDED:
      if (!action.userCompany) {
        return state
      }
      idx = state
        .get('userCompanies')
        .findIndex((item) => !!action.userCompany && item.companyID === action.userCompany.companyID)
      if (idx !== -1) {
        return state.set('saving', false).set('userCompanies', state.get('userCompanies').set(idx, action.userCompany))
      }
      return state.set('saving', false).set('userCompanies', state.get('userCompanies').push(action.userCompany))
    case ActionTypes.USER_COMPANY_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)
    case ActionTypes.USER_COMPANY_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.USER_COMPANY_UPDATED:
      if (!action.userCompany) {
        return state
      }
      idx = state
        .get('userCompanies')
        .findIndex(
          (item) =>
            !!action.userCompany && item.companyID === action.companyID && item.userID === action.userCompany.userID
        )
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('userCompanies', state.get('userCompanies').set(idx, action.userCompany))
    case ActionTypes.USER_COMPANY_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
