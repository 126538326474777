import React from 'react'

import {
  delExpenseCategory,
  fetchExpenseCategories,
  postExpenseCategory,
  putExpenseCategory,
} from '../api/expense-categories'
import ActionTypes from '../constants/action-types'
import ExpenseCategory, { ExpenseCategoryBaseFields, ExpenseCategoryUpdateFields } from '../model/expenseCategory'
import { ExpenseCategoryAction } from '../reducers/expenseCategories'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingExpenseCategories(): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_LOADING,
  }
}
export function loadedExpenseCategories(
  companyID: string,
  expenseCategories: ExpenseCategory[]
): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_LOADED,
    expenseCategories,
    companyID,
  }
}
function failedLoadingExpenseCategories(companyID: string, error: Error): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingExpenseCategory(): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_ADDING,
  }
}
export function addedExpenseCategory(companyID: string, expenseCategory: ExpenseCategory): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_ADDED,
    companyID,
    expenseCategory,
  }
}
function failedAddingExpenseCategory(companyID: string, error: Error): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_ADD_FAILED,
    error,
    companyID,
  }
}

function updatingExpenseCategory(companyID: string, expenseCategoryID: string): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_UPDATING,
    companyID,
    expenseCategoryID,
  }
}
export function updatedExpenseCategory(
  companyID: string,
  expenseCategoryID: string,
  expenseCategory: ExpenseCategory
): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_UPDATED,
    companyID,
    expenseCategoryID,
    expenseCategory,
  }
}
function failedUpdatingExpenseCategory(
  companyID: string,
  expenseCategoryID: string,
  error: Error
): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_UPDATE_FAILED,
    error,
    companyID,
    expenseCategoryID,
  }
}

function deletingExpenseCategory(companyID: string, expenseCategoryID: string): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_DELETING,
    companyID,
    expenseCategoryID,
  }
}
export function deletedExpenseCategory(companyID: string, expenseCategoryID: string): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_DELETED,
    companyID,
    expenseCategoryID,
  }
}
function failedDeletingExpenseCategory(
  companyID: string,
  expenseCategoryID: string,
  error: Error
): ExpenseCategoryAction {
  return {
    type: ActionTypes.EXPENSE_CATEGORY_DELETE_FAILED,
    error,
    companyID,
    expenseCategoryID,
  }
}

export function addExpenseCategory(expenseCategory: ExpenseCategoryBaseFields) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ExpenseCategory | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingExpenseCategory())
    return postExpenseCategory(companyID, expenseCategory)
      .then((res) => {
        dispatch(addedExpenseCategory(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingExpenseCategory(companyID, e))
        }
      })
  }
}

export function getExpenseCategories() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ExpenseCategory[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingExpenseCategories())
    return fetchExpenseCategories(companyID)
      .then((res) => {
        dispatch(loadedExpenseCategories(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingExpenseCategories(companyID, e))
        }
      })
  }
}

export function updateExpenseCategory(expenseCategory: ExpenseCategoryUpdateFields) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ExpenseCategory | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingExpenseCategory(companyID, expenseCategory.id))
    return putExpenseCategory(expenseCategory)
      .then((res) => {
        dispatch(updatedExpenseCategory(companyID, res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingExpenseCategory(companyID, expenseCategory.id, e))
        }
      })
  }
}

export function deleteExpenseCategory(expenseCategoryID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | ExpenseCategory | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingExpenseCategory(companyID, expenseCategoryID))
    return delExpenseCategory(expenseCategoryID)
      .then((res) => {
        if (res.data) {
          dispatch(updatedExpenseCategory(companyID, expenseCategoryID, res.data))
          return res.data
        } else {
          dispatch(deletedExpenseCategory(companyID, expenseCategoryID))
          return true
        }
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingExpenseCategory(companyID, expenseCategoryID, e))
        }
      })
  }
}
