import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Dashboard from '../model/dashboard'
import { ReducerAction } from '../utils/reducer-utils'

export interface DashboardReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  dashboards: List<Dashboard>
  error: Error | null
}

const Params = Record<DashboardReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  dashboards: List<Dashboard>(),
  error: null,
})

export interface DashboardAction extends ReducerAction {
  companyID?: string
  dashboards?: Dashboard[]
}

const comparator = (a: Dashboard, b: Dashboard) => {
  return a.name.localeCompare(b.name, 'da-dk')
}

export default (
  state: Record<DashboardReducer> = Params(),
  action: DashboardAction = { type: '' }
): Record<DashboardReducer> => {
  switch (action.type) {
    case ActionTypes.DASHBOARD_LOADING:
      return Params({
        companyID: action.companyID,
        loading: true,
      })
    case ActionTypes.DASHBOARD_LOADED:
      return Params({
        companyID: action.companyID,
        loaded: true,
        dashboards: List(action.dashboards).sort(comparator),
      })
    case ActionTypes.DASHBOARD_LOAD_FAILED:
      return Params({
        companyID: action.companyID,
        error: action.error,
      })
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
