import omit from 'omit.js'

import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import { paths } from '../routes'
import MfaChannel from '../types/mfa-channel'
import { getAccessToken, setAccessToken } from './cookie-utils'
import { companySelected } from './hooks-utils'
require('isomorphic-fetch')

export class RequestError extends Error {
  message: string
  type: string
  details: string[] | undefined

  constructor(message: string, type: string, details: string[] | undefined) {
    super(message)

    // restore prototype chain
    const actualProto = new.target.prototype
    Object.setPrototypeOf(this, actualProto)

    this.message = message
    this.type = type
    this.details = details
  }

  toString(): string {
    return this.message
  }
}

export class ChannelMFAError extends RequestError {
  channel: MfaChannel
  mfaChallengeID: string

  constructor(
    message: string,
    type: string,
    details: string[] | undefined,
    channel: MfaChannel,
    mfaChallengeID: string
  ) {
    super(message, type, details)

    // restore prototype chain
    const actualProto = new.target.prototype
    Object.setPrototypeOf(this, actualProto)

    this.channel = channel
    this.mfaChallengeID = mfaChallengeID
  }
}

interface Pagination {
  count: number
}

// Use DataType to describe the type filled by `data`
export interface RequestResponse<DataType = void> {
  data: DataType
  pagination?: Pagination
  error?: RequestError
}

interface WindowAPIUrl {
  sally_api_url?: string
}
declare let window: WindowAPIUrl

export function getHost(): string {
  if (window.sally_api_url) {
    return window.sally_api_url
  }
  return process.env.REACT_APP_API_URL || 'http://localhost:3100'
}

export function isSSOPage(pathname: string): boolean {
  return pathname.startsWith('/' + paths.SSO)
}

export function isOutsidePage(pathname: string): boolean {
  return (
    pathname === '/' + paths.PAYROLL_APPROVE_PHONE_SITE ||
    pathname.startsWith('/' + paths.DOCUMENT_SIGNING_PHONE_SITE) ||
    pathname.startsWith('/' + paths.IN + '/' + paths.CRIIPTO) ||
    pathname.startsWith('/' + paths.PERSON_VERIFICATION)
  )
}

export function isResponsivePage(pathname: string): boolean {
  return (
    isOutsidePage(pathname) ||
    pathname === '/' + paths.REGISTER ||
    pathname === '/' + paths.LOGIN ||
    pathname === '/' + paths.REQUEST_PASSWORD ||
    pathname === '/' + paths.COMPANIES + '/' + paths.ADD
  )
}

export type URLQuery = Record<string, string | number | boolean | undefined>

export function url(path: string, query: URLQuery = {}): string {
  let url = getHost() + (path.substring(0, 1) !== '/' ? '/' : '') + path
  if (query) {
    const params = []
    for (const key in query) {
      const val = query[key]
      if (val !== null && val !== undefined) {
        params.push(key + '=' + encodeURIComponent(val))
      }
    }
    if (params.length) {
      url += (url.indexOf('?') === -1 ? '?' : '&') + params.join('&')
    }
  }
  return url
}

export function secureUrl(path: string, query?: { [index: string]: any }): string {
  return url(path, { ...query, apiKey: getAccessToken() })
}

type RequestOptions = {
  headers?: Record<string, string>
  ignore401?: boolean
  body?: string | Record<string, unknown> | (string | number | boolean | Record<string, unknown>)[]
}

export function request<DataType>(
  method: string,
  url: string,
  options: RequestOptions = {}
): Promise<RequestResponse<DataType>> {
  const isAuthorized = Object.keys(options.headers || {}).some((key) => {
    return key.toLowerCase() === 'authorization'
  })

  let ignore401 = !!options.ignore401
  if (isOutsidePage(document.location.pathname)) {
    ignore401 = true
  }

  let body = options.body
  if (body && typeof body !== 'string') {
    body = JSON.stringify(body)
  }
  const payload = {
    ...{
      method: method || 'GET',
      headers: {
        ...options.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Sally-Context': 'SalaryFrontend',
      },
    },
    body,
    ...omit(options, ['ignore401', 'body', 'headers']),
  }
  return fetch(url, payload)
    .then((res: Response) => {
      if (!ignore401 && isAuthorized && res.status === 401) {
        return res.json().then((json) => {
          switch (json?.error?.message) {
            // @ts-expect-error fallthrough
            case 'Company requires SAML Access': {
              if (json.error.details && json.error.details.length > 0) {
                const companyID: string = json.error.details
                  .find((t: string) => t.match(/^CompanyID: /))
                  ?.replace('CompanyID: ', '')
                if (companyID) {
                  jsBrowserHistory.push('/' + paths.SSO + '/' + companyID)
                  break
                }
              }
              // fallthrough
            }
            default:
              setAccessToken(null)
              document.location =
                '/' +
                paths.LOGIN +
                '?ref=' +
                encodeURIComponent(document.location.pathname + document.location.search + document.location.hash)
              break
          }
          return json
        })
      }
      if (isAuthorized && res.status === 403) {
        if (document.location.pathname === '/') {
          // Assume inaccessible active company on dashboard
          companySelected(null)
        }
      }
      if (res.status === 204 || res.headers.get('Content-Length') === '0') {
        // No Content
        return null
      }
      return res.json()
    })
    .then((res) => {
      const error = res?.error || res
      if (error?.message) {
        let err: RequestError | ChannelMFAError | undefined = undefined
        if (res.mfaChallengeID) {
          err = new ChannelMFAError(error.message, error.type, error.details, res.channel, res.mfaChallengeID)
        } else {
          err = new RequestError(error.message, error.type, error.details)
        }
        Object.keys(res).forEach((key) => {
          if (err) {
            switch (key) {
              case 'message':
                err.message = res[key]
                break
              case 'type':
                err.type = res[key]
                break
              case 'channel':
                if (err instanceof ChannelMFAError) {
                  err.channel = res[key]
                }
                break
              case 'mfaChallengeID':
                if (err instanceof ChannelMFAError) {
                  err.mfaChallengeID = res[key]
                }
                break
            }
          }
        })
        if (err) {
          throw err
        }
      }
      return res as RequestResponse<DataType>
    })
    .catch((error) => {
      if (error instanceof ChannelMFAError) {
        throw error
      } else if (error instanceof RequestError) {
        throw error
      } else {
        throw new RequestError(error.message, error.type, [])
      }
    })
}

export function secureRequest<DataType>(
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  url: string,
  options: RequestOptions = {}
): Promise<RequestResponse<DataType>> {
  if (!options.headers) {
    options.headers = {}
  }
  options.headers['Authorization'] = getAccessToken() || ''
  return request<DataType>(method, url, options)
}

export const PromiseVoid = new Promise<void>((_) => undefined)
