import { addHours, differenceInDays, subHours } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { useEffectOnce, usePrevious } from 'react-use'

import { getCompanyPricingPackages } from '../../actions/company-pricing-packages'
import { getPricingPackages } from '../../actions/pricing-packages'
import { postUserSurveys } from '../../api/user'
import CompanyUser from '../../model/companyUser'
import PayRoll from '../../model/payRoll'
import User from '../../model/user'
import { CompanyPricingPackageReducer } from '../../reducers/companyPricingPackages'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { PayRollReducer } from '../../reducers/payRolls'
import { PricingPackageReducer } from '../../reducers/pricingPackages'
import { UserReducer } from '../../reducers/user'
import { getDate, isTimeBetween } from '../../utils/date-utils'
import { convertPayRollStatus } from '../../utils/pay-roll-utils'
import { isDepartmentRestricted } from '../../utils/permissions-utils'
import { getMissingPackageIDs, isPricingPackageGroup } from '../../utils/pricing-package-utils'
import { connectToReducer } from '../../utils/reducer-utils'
import { t } from '../../utils/translation-utils'
import RadioGroup from '../antd/radio/group'
import RadioButton from '../antd/radio/radioButton'
import Button from '../elements/button'
import Card from '../elements/card'
import Input from '../elements/input'

import './UserSurvey.css'

type Reducers = {
  companyUsers: CompanyUserReducer
  user: UserReducer
  payRolls: PayRollReducer
  pricingPackages: PricingPackageReducer
  companyPricingPackages: CompanyPricingPackageReducer
}

type Actions = {
  getCompanyPricingPackages: () => void
  getPricingPackages: (includePackages: string[]) => void
}

const userAge = 90 // days
const sinceLastResponse = 90 // days
const askForCommentThreshold = 9 // below this number

function shouldDoSurvey(
  user: User,
  companyUser: CompanyUser | undefined,
  payRolls: List<PayRoll>,
  isPaidPackage: boolean
): boolean {
  if (!process.env.REACT_APP_FEATURE_USER_SURVEY) {
    return false
  }
  // must be attached to the active company; i.e. no support users!
  if (!companyUser) {
    return false
  }
  // no survey for department managers
  if (isDepartmentRestricted(companyUser)) {
    return false
  }
  // only if on a paid package
  if (!isPaidPackage) {
    return false
  }
  // only relevant for users who have users for X number of days
  if (Math.abs(differenceInDays(getDate(user.createdAt), getDate())) < userAge) {
    return false
  }
  // not relevant for those without a random bucket number set
  // (though, in practice, it shouldn't happen)
  if (user.randomBucket === undefined || user.randomBucket === null) {
    return false
  }
  // don't prod the user too many times, if they have already responded
  if (
    !!user.lastSurveyResponse &&
    Math.abs(differenceInDays(getDate(user.lastSurveyResponse), getDate())) < sinceLastResponse
  ) {
    return false
  }
  // only if the user has access to an actual successful payroll
  if (
    !payRolls.some(
      (payRoll) =>
        payRoll.payRollType === 'Ordinary' &&
        payRoll.tasks.some((task) => task.type === 'RunPayRoll') &&
        convertPayRollStatus(payRoll) === 'Success'
    )
  ) {
    return false
  }
  // but do not show it, if we are within 72 hours before a payroll approval or 24 hours after it
  if (
    payRolls.some(
      (payRoll) =>
        payRoll.tasks.length > 0 &&
        !!payRoll.tasks[0].approvalDeadline &&
        isTimeBetween(
          getDate(),
          subHours(getDate(payRoll.tasks[0].approvalDeadline), 72),
          addHours(getDate(payRoll.tasks[0].approvalDeadline), 24),
          'hour'
        )
    )
  ) {
    return false
  }
  // still here?  Then the user might be ready for a survey, but only if they fit our "bucket"

  // we want to ask the user twice a year, but we want to spread it out
  // evenly over the year, we use some bucketing for this

  // we get there by taking the current month and take the modulus 6 of it
  const monthModulus = getDate().getMonth() % 6
  // we want January to be 0 and 1, JavaScript months are 0 - 11,
  // so 0 % 6 = 0, 1 % 6 = 1, 6 % 6 = 0, 7 % 6 = 1, etc.

  // we know the randomBucket is a number between 0 and 11 (inclusive)
  // but we don't want random buckets 0 and 6 to be January,
  // we want 0 and 1 to be January, so we divide it by 2, and round down
  // so 0 / 2 = 0, 1 / 2 = 0, 2 / 2 = 1, etc.
  const bucketModulus = Math.floor(user.randomBucket / 2)
  // and then we simply check if they match
  return monthModulus === bucketModulus
}

function UserSurvey(props: Reducers & Actions): ReactElement | null {
  const [step, setStep] = useState(0)
  const [score, setScore] = useState<number>()
  const [comment, setComment] = useState<string>()

  const departmentRestricted = isDepartmentRestricted(props.companyUsers.companyUser)

  const { companyPricingPackages, getCompanyPricingPackages, pricingPackages, getPricingPackages } = props

  useEffectOnce(() => {
    if (departmentRestricted) {
      return
    }
    if (!companyPricingPackages.loading && !companyPricingPackages.loaded) {
      getCompanyPricingPackages()
    }
  })

  useEffect(() => {
    if (departmentRestricted) {
      return
    }
    if (companyPricingPackages.loaded) {
      const missingPackages = getMissingPackageIDs(
        pricingPackages.pricingPackages.toArray(),
        companyPricingPackages.companyPricingPackages.toArray()
      )
      if (!pricingPackages.loading && (!pricingPackages.loaded || missingPackages.length > 0)) {
        getPricingPackages(missingPackages)
      }
    }
  })

  const previousStep = usePrevious(step)

  useEffect(() => {
    if (previousStep !== undefined && previousStep < step && step === 2 && score !== undefined) {
      const sendScore = score
      const sendComment = comment ?? ''
      setTimeout(() => {
        postUserSurveys(sendScore, sendComment)
      }, 2500)
    }
  }, [step, previousStep, score, comment])

  const handleNext = (score?: number) => {
    switch (step) {
      case 0:
        if (score === undefined) {
          return
        }
        if (score < askForCommentThreshold) {
          setStep(1)
        } else {
          setStep(2)
        }
        break
      case 1:
        setStep(2)
        break
    }
  }

  const user = props.user.user
  if (!user) {
    return null
  }
  if (
    !shouldDoSurvey(
      user,
      props.companyUsers.companyUser,
      props.payRolls.payRolls,
      isPricingPackageGroup(
        pricingPackages.pricingPackages.toArray(),
        companyPricingPackages.companyPricingPackages.toArray(),
        props.companyUsers.companyID,
        ['Automatic', 'Premium']
      )
    )
  ) {
    return null
  }

  return (
    <Card className="user-survey">
      {step === 0 && (
        <>
          <p>
            {t('user_survey.step_0.intro.line_1', {
              name: user.name ?? t('user_survey.step_0.intro.line_1.user_default'),
            })}
          </p>
          <p>{t('user_survey.step_0.intro.line_2')}</p>
          <RadioGroup
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const score = parseInt(e.target.value)
              setScore(score)
              handleNext(score)
            }}
            value={score}
          >
            <RadioButton value={0}>0</RadioButton>
            <RadioButton value={1}>1</RadioButton>
            <RadioButton value={2}>2</RadioButton>
            <RadioButton value={3}>3</RadioButton>
            <RadioButton value={4}>4</RadioButton>
            <RadioButton value={5}>5</RadioButton>
            <RadioButton value={6}>6</RadioButton>
            <RadioButton value={7}>7</RadioButton>
            <RadioButton value={8}>8</RadioButton>
            <RadioButton value={9}>9</RadioButton>
            <RadioButton value={10}>10</RadioButton>
          </RadioGroup>
          <div className="user-survey-help-row">
            <span className="user-survey-help-least">{t('user_survey.step_0.help.least')}</span>
            <span className="user-survey-help-most">{t('user_survey.step_0.help.most')}</span>
          </div>
          <div className="user-survey-button-row">
            <Button disabled={score === undefined} onClick={() => handleNext(score)} type="secondary">
              {t('user_survey.step_0.next')}
            </Button>
            <Button onClick={() => postUserSurveys(-1, '')}>{t('user_survey.step_0.reject')}</Button>
          </div>
        </>
      )}
      {step === 1 && (
        <>
          <p>{t('user_survey.step_1.intro')}</p>
          <Input.TextArea value={comment} onChange={(v) => setComment(v.target.value)} rows={5}></Input.TextArea>
          <div className="user-survey-button-row">
            <Button onClick={() => handleNext()} type="secondary">
              {t('user_survey.step_1.next')}
            </Button>
            <Button
              onClick={() => {
                setStep(0)
              }}
            >
              {t('user_survey.step_1.previous')}
            </Button>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <p>{t('user_survey.step_2.intro')}</p>
        </>
      )}
    </Card>
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    companyUsers: state.companyUsers,
    user: state.user,
    payRolls: state.payRolls,
    pricingPackages: state.pricingPackages,
    companyPricingPackages: state.companyPricingPackages,
  }),
  {
    getPricingPackages: getPricingPackages,
    getCompanyPricingPackages: getCompanyPricingPackages,
  }
)(UserSurvey)
