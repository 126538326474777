import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import User from '../../model/user'
import { AlertReducer } from '../../reducers/alerts'
import { RecoveryCodeReducer } from '../../reducers/recoveryCodes'
import { UserReducer } from '../../reducers/user'
import { paths } from '../../routes'
import { t } from '../../utils/translation-utils'
import Alerts from '../widgets/Alerts'
import EmailTab from './EmailTab'
import MfaTab from './MfaTab'
import MitIDTab from './MitIDTab'
import PasswordTab from './PasswordTab'
import ProfileTab from './ProfileTab'
import TerminateTab from './TerminateTab'

import './Account.css'

type Props = {
  section: string
  alerts: AlertReducer
  user: UserReducer
  companies: List<Company>
  recoveryCodes: RecoveryCodeReducer
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateUser: (user: User) => void
  requestEmailChange: (email: string) => void
  changePassword: (oldPassword: string, newPassword: string) => void
}

export default function Account(props: Props): ReactElement | null {
  const getMenuItems = () => {
    const menuItems = []
    menuItems.push({ key: 'profile', title: t('account.profile') })
    menuItems.push({ key: 'email', title: t('account.email') })
    menuItems.push({ key: 'password', title: t('account.password') })
    menuItems.push({ key: 'mfa', title: t('account.mfa') })
    menuItems.push({ key: 'terminate', title: t('account.terminate') })
    menuItems.push({ key: 'mitid', title: t('account.mitid') })
    return menuItems
  }

  const section = props.section
  return (
    <div className="account">
      <div className="account-menu">
        {getMenuItems().map((item) => {
          return (
            <Link
              key={item.key}
              to={'/' + paths.ACCOUNT + '/' + item.key}
              className={section === item.key ? 'active' : ''}
            >
              {item.title}
            </Link>
          )
        })}
        {props.companies.size === 1 && (
          <Link to={'/' + paths.COMPANIES + '/' + paths.ADD} style={{ float: 'right' }}>
            + {t('account.add_new_company')}
          </Link>
        )}
      </div>
      <div className="account-main">
        <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
        {section === 'profile' && (
          <ProfileTab user={props.user} addAlert={props.addAlert} updateUser={props.updateUser} />
        )}
        {section === 'email' && (
          <EmailTab user={props.user} addAlert={props.addAlert} requestEmailChange={props.requestEmailChange} />
        )}
        {section === 'password' && (
          <PasswordTab user={props.user} addAlert={props.addAlert} changePassword={props.changePassword} />
        )}
        {section === 'mfa' && (
          <MfaTab user={props.user} recoveryCodes={props.recoveryCodes} addAlert={props.addAlert} />
        )}
        {section === 'terminate' && <TerminateTab companies={props.companies} />}
        {section === 'mitid' && <MitIDTab user={props.user} />}
      </div>
    </div>
  )
}
