import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import { CoarseSalaryRegistrationMutableFields } from '../../model/coarseSalaryRegistration'
import Contract, { ContractCreationFields } from '../../model/contract'
import Employee from '../../model/employee'
import SalaryCycle from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import { CoarseSalaryRegistrationReducer } from '../../reducers/coarseSalaryRegistrations'
import { ContractReducer } from '../../reducers/contracts'
import { t } from '../../utils/translation-utils'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import CoarseSalaryRegistrationTab from '../employees-single/salary-registrations/CoarseSalaryRegistrationTab'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  visible: boolean
  employee?: Employee
  contracts: ContractReducer
  coarseSalaryRegistrations: CoarseSalaryRegistrationReducer
  salaryCycles: List<SalaryCycle>
  salaryCycle?: SalaryCycle
  salaryTypes: List<SalaryType>

  addAlert: addAlertSignature
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: Contract) => void
  updateCoarseSalaryRegistrationBulk: (
    employeeID: string,
    coarseSalaryRegistrations: CoarseSalaryRegistrationMutableFields[]
  ) => void
}

export default function CoarseSalaryRegistrationModal(props: Props): ReactElement | null {
  if (!props.employee || !props.employee.earliestMutableContract || !props.salaryCycle) {
    return null
  }
  return (
    <Card className="salary-registration-coarse">
      <Subtitle>{t('salary_registrations.modal_edit.title', { name: props.employee.name })}</Subtitle>
      <p>&nbsp;</p>
      <CoarseSalaryRegistrationTab
        employee={props.employee}
        contract={props.employee.earliestMutableContract}
        contracts={props.contracts}
        coarseSalaryRegistrations={props.coarseSalaryRegistrations}
        salaryCycles={props.salaryCycles}
        salaryCycle={props.salaryCycle}
        salaryTypes={props.salaryTypes}
        addAlert={props.addAlert}
        updateCoarseSalaryRegistrationBulk={props.updateCoarseSalaryRegistrationBulk}
        updateContract={props.updateContract}
        addContract={props.addContract}
      />
      {props.contracts.saving && <LoadingOverlay />}
    </Card>
  )
}
