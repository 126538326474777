import AccountingIntegration, {
  AccountPlan,
  AccountPlanUpdateConfiguration,
  BookingRule,
} from '../model/accountingIntegration'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'
import { ExternalDaybook, ExternalOrganization } from './company-accounting-integration-setup'

export function fetchCompanyAccountingIntegration(companyID: string): Promise<RequestResponse<AccountingIntegration>> {
  return secureRequest('GET', url('/v2/accountingIntegrations', { companyID }))
}

export function putCompanyAccountingIntegration(state: string): Promise<RequestResponse<AccountingIntegration>> {
  return secureRequest('PUT', url('/v2/accountingIntegrations', { state }))
}

export function fetchCompanyAccountPlans(companyID: string): Promise<RequestResponse<AccountPlan>> {
  return secureRequest('GET', url('/v2/accountPlans', { companyID }))
}

export type AccountPlanUpdate = {
  accountMapping: AccountPlanUpdateConfiguration[]
  costCenterID?: string
  bookingRule?: BookingRule
}

export function putCompanyAccountPlan(
  companyID: string,
  body: AccountPlanUpdate
): Promise<RequestResponse<AccountPlan>> {
  return secureRequest('PUT', url('/v2/accountPlans', { companyID }), { body })
}

export function postCompanyAccountingIntegrationSync(companyID: string): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/accountingIntegrations/sync', { companyID }))
}

export function postCompanyGroupAccountingIntegrationSync(companyGroupID: string): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/accountingIntegrations/sync', { companyGroupID }))
}

export function fetchCompanyAccountingIntegrationDaybooks(
  companyID: string
): Promise<RequestResponse<ExternalDaybook[]>> {
  return secureRequest('GET', url('/v2/accountingIntegrations/daybooks', { companyID }))
}

export function putCompanyAccountingIntegrationDaybooks(
  companyID: string,
  daybookID?: string
): Promise<RequestResponse<AccountingIntegration>> {
  return secureRequest('PUT', url('/v2/accountingIntegrations/daybooks', { companyID }), {
    body: {
      daybookID,
    },
  })
}

export function fetchCompanyAccountingIntegrationOrganizations(
  companyID: string
): Promise<RequestResponse<ExternalOrganization[]>> {
  return secureRequest('GET', url('/v2/accountingIntegrations/organizations', { companyID }))
}

export function putCompanyAccountingIntegrationOrganizations(
  companyID: string,
  organizationID: string
): Promise<RequestResponse<AccountingIntegration>> {
  return secureRequest('PUT', url('/v2/accountingIntegrations/organizations', { companyID }), {
    body: {
      organizationID,
    },
  })
}

export type AccountingIntegrationActiveInformation = {
  externalID: string
  company: {
    nationalID: string
    name: string
    address: string
    postalCode: string
    city: string
  }
}

export function getCompanyAccountingIntegrationActiveInformation(
  companyID: string
): Promise<RequestResponse<AccountingIntegrationActiveInformation>> {
  return secureRequest('GET', url('/v2/accountingIntegrations/activeInformation', { companyID }))
}
