import Invoice from '../model/invoice'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchInvoices(
  payRollID: string | undefined,
  companyID: string | undefined,
  limit: number,
  offset: number
): Promise<RequestResponse<Invoice[]>> {
  if (payRollID) {
    companyID = undefined // make sure it is not set
  }
  return secureRequest('GET', url('/v2/invoices', { companyID, payRollID, limit, offset }))
}
