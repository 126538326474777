import Company from '../model/company'
import SupportAccess from '../model/supportAccess'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function postSupportAccess(companyID: string): Promise<RequestResponse<SupportAccess>> {
  return secureRequest('POST', url('/v2/supportAccess', { companyID }))
}

export function deleteSupportAccess(companyID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/supportAccess', { companyID }))
}

export function getSupportAccess(companyID: string): Promise<RequestResponse<SupportAccess>> {
  return secureRequest('GET', url('/v2/supportAccess', { companyID }))
}

export function fetchSupportCompanies(): Promise<RequestResponse<Company[]>> {
  return secureRequest('GET', url('/v2/companies', { supportActive: true }))
}
