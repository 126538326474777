import { endOfMonth } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { addAlertSignature } from '../../../actions/alerts'
import Employee from '../../../model/employee'
import Employment from '../../../model/employment'
import SalaryCycle from '../../../model/salaryCycle'
import { EmployeeReducer } from '../../../reducers/employees'
import { EmploymentReducer } from '../../../reducers/employments'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate, formatDate, getDate, isTimeAfter } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { getCurrentPeriod } from '../../../utils/salary-period-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Headline from '../../elements/Headline'
import Subtitle from '../../elements/Subtitle'
import UserImage from '../../elements/UserImage'
import TerminateEmployeeForm, { Fields } from './TerminateEmployeeForm'

type Props = {
  visible: boolean
  employee?: Employee
  employeeID?: string
  employees: EmployeeReducer
  employments: EmploymentReducer
  salaryCycles?: List<SalaryCycle>

  addAlert: addAlertSignature
  terminateEmployment: (employeeID: string, employment: Employment) => Promise<boolean | void>
  getEmployments?: (employeeID: string) => void
  closeModal: () => void
}

export default function TerminateEmployee(props: Props): ReactElement | null {
  let employee = props.employee
  if (!employee) {
    employee = props.employees.employees.find((employee) => employee.id === props.employeeID)
  }

  const [error, setError] = useState<Error | null>(null)

  const { employments, getEmployments } = props

  useEffect(() => {
    if (!employee) {
      return
    }
    if (employments.employeeID !== employee.id || (!employments.loaded && !employments.loading)) {
      if (getEmployments) {
        getEmployments(employee.id)
      }
    }
  }, [employee, employments, getEmployments])

  const { visible } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, employments.error, error, setError)
  }, [visible, employments, error])

  const handleSubmit = (values: Fields) => {
    if (!employee || !employee.activeEmployment) {
      return
    }
    const employment = { ...employee.activeEmployment }
    employment.endDate = formatAPIDate(values.endDate)
    props.terminateEmployment(employee.id, employment).then((success) => {
      if (success && employee) {
        const isFreelancer = employee.affiliationType === 'Freelancer'
        props.addAlert(
          'success',
          t(
            isFreelancer ? 'employee_terminate.alert.success.freelancer' : 'employee_terminate.alert.success.standard',
            { name: employee.name }
          ),
          {
            timeout: 5,
          }
        )
        props.closeModal()
      }
    })
  }

  if (!employee || !employee.activeEmployment) {
    props.closeModal()
    return null
  }

  const isFreelancer = employee.affiliationType === 'Freelancer'

  const findDate = () => {
    if (isFreelancer) {
      return getDate()
    }
    const toSuggest = endOfMonth(getDate())
    if (!employee?.activeContract) {
      return toSuggest
    }
    const salaryCycle = props.salaryCycles?.find((cycle) => cycle.id === employee?.activeContract?.salaryCycleID)
    if (!salaryCycle) {
      return toSuggest
    }
    const currentPeriod = getCurrentPeriod(salaryCycle)
    return currentPeriod.end
  }

  let suggestedTerminationDate = findDate()
  if (employee.immutableEndDate) {
    const immutableEndDate = getDate(employee.immutableEndDate)
    if (isTimeAfter(immutableEndDate, suggestedTerminationDate)) {
      suggestedTerminationDate = immutableEndDate
    }
  }

  return (
    <Card className="employees-terminate">
      <Subtitle>
        {isFreelancer ? t('employee_terminate.title.freelancer') : t('employee_terminate.title.standard')}
      </Subtitle>
      {!isFreelancer && (
        <>
          <p>
            {t('employee_terminate.intro.standard.line_1')}
            <br />
            {t('employee_terminate.intro.standard.line_2')}
          </p>
          <p>{t('employee_terminate.intro.standard.line_3')}</p>
        </>
      )}
      {isFreelancer && <p>{t('employee_terminate.intro.freelancer')}</p>}
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Row>
        <Col span={12} offset={6}>
          <Card className="employees-terminate-profile">
            <UserImage name={employee.name} size="small" />
            <div className="employees-terminate-profile-data">
              <Headline>{employee.name}</Headline>
              <p>
                {isFreelancer ? t('employee_terminate.employed.freelancer') : t('employee_terminate.employed.standard')}
                : <strong>{formatDate(employee.activeEmployment.startDate)}</strong>
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      <TerminateEmployeeForm
        endDate={suggestedTerminationDate}
        employee={employee}
        employments={props.employments}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
