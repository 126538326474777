import React from 'react'

import { delProject, fetchProjects, postProject, putProject } from '../api/projects'
import ActionTypes from '../constants/action-types'
import Project, { ProjectCreationFields, ProjectMutableFields } from '../model/project'
import { ProjectAction } from '../reducers/projects'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingProjects(companyID: string): ProjectAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TYPE_LOADING,
    companyID,
  }
}
function loadedProjects(companyID: string, projects: Project[]): ProjectAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TYPE_LOADED,
    companyID,
    projects,
  }
}
function failedLoadingProjects(companyID: string, error: Error): ProjectAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TYPE_LOAD_FAILED,
    companyID,
    error,
  }
}

function creatingProject(companyID: string): ProjectAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TYPE_CREATING,
    companyID,
  }
}
export function createdProject(companyID: string, project: Project): ProjectAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TYPE_CREATED,
    companyID,
    project,
  }
}
function failedCreatingProject(companyID: string, error: Error): ProjectAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TYPE_CREATE_FAILED,
    companyID,
    error,
  }
}

function updatingProject(companyID: string): ProjectAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TYPE_UPDATING,
    companyID,
  }
}
export function updatedProject(companyID: string, project: Project): ProjectAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TYPE_UPDATED,
    companyID,
    project,
  }
}
function failedUpdatingProject(companyID: string, error: Error): ProjectAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TYPE_UPDATE_FAILED,
    companyID,
    error,
  }
}

function deletingProject(): ProjectAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TYPE_DELETING,
  }
}
export function deletedProject(projectID: string): ProjectAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TYPE_DELETED,
    projectID,
  }
}
function failedDeletingProject(error: Error): ProjectAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TYPE_DELETE_FAILED,
    error,
  }
}

export function getProjects() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature) => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(loadingProjects(companyID))
    return fetchProjects(companyID)
      .then((res) => {
        dispatch(loadedProjects(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingProjects(companyID, e))
        }
      })
  }
}

export function createProject(project: ProjectCreationFields) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(creatingProject(project.companyID))
    return postProject(project)
      .then((res) => {
        dispatch(createdProject(project.companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingProject(project.companyID, e))
        }
      })
  }
}

export function updateProject(project: ProjectMutableFields) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(updatingProject(project.companyID))
    return putProject(project)
      .then((res) => {
        dispatch(updatedProject(project.companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingProject(project.companyID, e))
        }
      })
  }
}

export function deleteProject(projectID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(deletingProject())
    return delProject(projectID)
      .then(() => {
        dispatch(deletedProject(projectID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingProject(e))
        }
      })
  }
}
