import { StartBalances } from '../model/startBalances'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchStartBalances(employmentID: string): Promise<RequestResponse<StartBalances<number>>> {
  return secureRequest('GET', url('/v2/startBalances/' + employmentID))
}

export function putStartBalances(
  employmentID: string,
  startBalances: StartBalances<number>
): Promise<RequestResponse<StartBalances<number>>> {
  return secureRequest('PUT', url('/v2/startBalances/' + employmentID), {
    body: startBalances,
  })
}
