import React from 'react'

import { fetchTransfers, patchTransfer } from '../api/transfers'
import ActionTypes from '../constants/action-types'
import Transfer, { PaymentMethod } from '../model/transfer'
import { DateFormat } from '../model/types'
import { TransferAction } from '../reducers/transfers'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingTransfers(payRollID: string | undefined, companyID: string | undefined): TransferAction {
  return {
    type: ActionTypes.TRANSFERS_LOADING,
    payRollID,
    companyID,
  }
}
function loadedTransfers(
  payRollID: string | undefined,
  companyID: string | undefined,
  fromDate: DateFormat | undefined,
  transfers: Transfer[],
  partial = false
): TransferAction {
  return {
    type: partial ? ActionTypes.TRANSFERS_LOADED_PARTIAL : ActionTypes.TRANSFERS_LOADED,
    transfers,
    payRollID,
    companyID,
    fromDate,
  }
}
function failedLoadingTransfers(
  payRollID: string | undefined,
  companyID: string | undefined,
  error: Error
): TransferAction {
  return {
    type: ActionTypes.TRANSFERS_LOAD_FAILED,
    error,
    payRollID,
    companyID,
  }
}

export function addedTransfer(
  payRollID: string | undefined,
  companyID: string | undefined,
  transfer: Transfer
): TransferAction {
  return {
    type: ActionTypes.TRANSFER_ADDED,
    payRollID,
    companyID,
    transfer,
  }
}

function updatingTransfer(transferID: string): TransferAction {
  return {
    type: ActionTypes.TRANSFER_UPDATING,
    transferID,
  }
}
export function updatedTransfer(transferID: string, transfer: Transfer): TransferAction {
  return {
    type: ActionTypes.TRANSFER_UPDATED,
    transferID,
    transfer,
  }
}
function failedUpdatingTransfer(transferID: string, error: Error): TransferAction {
  return {
    type: ActionTypes.TRANSFER_UPDATE_FAILED,
    error,
    transferID,
  }
}

export function deletedTransfer(transferID: string): TransferAction {
  return {
    type: ActionTypes.TRANSFER_DELETED,
    transferID,
  }
}

export function getTransfers(
  payRollID: string | undefined,
  companyID: string | undefined,
  fromDate?: DateFormat,
  offset?: number
) {
  return (dispatch: React.Dispatch<any>): Promise<Transfer[] | void> => {
    if (!offset) {
      dispatch(loadingTransfers(payRollID, companyID))
      offset = 0
    }
    const limit = 1000
    return fetchTransfers(payRollID, companyID, fromDate, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedTransfers(payRollID, companyID, fromDate, data)),
          (data) => dispatch(loadedTransfers(payRollID, companyID, fromDate, data, true)),
          (offset) => dispatch(getTransfers(payRollID, companyID, fromDate, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingTransfers(payRollID, companyID, e))
        }
      })
  }
}

export function automateTransfer(transferID: string, paymentMethod: PaymentMethod) {
  return (dispatch: React.Dispatch<any>): Promise<Transfer | void> => {
    dispatch(updatingTransfer(transferID))
    return patchTransfer(transferID, { paymentMethod })
      .then((res) => {
        dispatch(updatedTransfer(transferID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingTransfer(transferID, e))
        }
      })
  }
}

export function updateTransferDate(transferID: string, date: DateFormat) {
  return (dispatch: React.Dispatch<any>): Promise<Transfer | void> => {
    dispatch(updatingTransfer(transferID))
    return patchTransfer(transferID, { date })
      .then((res) => {
        dispatch(updatedTransfer(transferID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingTransfer(transferID, e))
        }
      })
  }
}

function updateTransferState(transferID: string, state: 'Pending' | 'Completed') {
  return (dispatch: React.Dispatch<any>): Promise<Transfer | void> => {
    dispatch(updatingTransfer(transferID))
    return patchTransfer(transferID, { state })
      .then((res) => {
        dispatch(updatedTransfer(transferID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingTransfer(transferID, e))
        }
      })
  }
}

export function approveTransfer(transferID: string) {
  return updateTransferState(transferID, 'Completed')
}

export function declineTransfer(transferID: string) {
  return updateTransferState(transferID, 'Pending')
}

export function tryTransferAgain(transferID: string) {
  return (dispatch: React.Dispatch<any>): Promise<Transfer | void> => {
    dispatch(updatingTransfer(transferID))
    return patchTransfer(transferID, { action: 'tryAgain' })
      .then((res) => {
        dispatch(updatedTransfer(transferID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingTransfer(transferID, e))
        }
      })
  }
}
