import React from 'react'

import {
  fetchSupplementAdjustments,
  postSupplementAdjustment,
  putSupplementAdjustment,
  removeSupplementAdjustment,
} from '../api/supplement-adjustments'
import ActionTypes from '../constants/action-types'
import SupplementAdjustment, {
  SupplementAdjustmentCreationFields,
  SupplementAdjustmentMutableFields,
} from '../model/supplementAdjustment'
import { SupplementAdjustmentAction } from '../reducers/supplementAdjustments'
import { isRequestError } from '../utils/error-utils'

function loadingSupplementAdjustments(
  companyID: string | undefined,
  employeeID: string | undefined
): SupplementAdjustmentAction {
  return {
    type: ActionTypes.SUPPLEMENT_ADJUSTMENTS_LOADING,
    companyID,
    employeeID,
  }
}
function loadedSupplementAdjustments(
  companyID: string | undefined,
  employeeID: string | undefined,
  supplementAdjustments: SupplementAdjustment[]
): SupplementAdjustmentAction {
  return {
    type: ActionTypes.SUPPLEMENT_ADJUSTMENTS_LOADED,
    companyID,
    employeeID,
    supplementAdjustments,
  }
}
function failedLoadingSupplementAdjustments(
  companyID: string | undefined,
  employeeID: string | undefined,
  error: Error
): SupplementAdjustmentAction {
  return { type: ActionTypes.SUPPLEMENT_ADJUSTMENTS_LOAD_FAILED, companyID, employeeID, error }
}

function addingSupplementAdjustment(employeeID: string): SupplementAdjustmentAction {
  return {
    type: ActionTypes.SUPPLEMENT_ADJUSTMENTS_ADDING,
    employeeID,
  }
}
export function addedSupplementAdjustment(
  employeeID: string,
  supplementAdjustmentID: string,
  supplementAdjustment: SupplementAdjustment
): SupplementAdjustmentAction {
  return {
    type: ActionTypes.SUPPLEMENT_ADJUSTMENTS_ADDED,
    employeeID,
    supplementAdjustmentID,
    supplementAdjustment,
  }
}
function failedAddedSupplementAdjustment(employeeID: string, error: Error): SupplementAdjustmentAction {
  return { type: ActionTypes.SUPPLEMENT_ADJUSTMENTS_ADD_FAILED, employeeID, error }
}

function updatingSupplementAdjustment(employeeID: string, supplementAdjustmentID: string): SupplementAdjustmentAction {
  return {
    type: ActionTypes.SUPPLEMENT_ADJUSTMENTS_UPDATING,
    employeeID,
    supplementAdjustmentID,
  }
}
export function updatedSupplementAdjustment(
  employeeID: string,
  supplementAdjustmentID: string,
  supplementAdjustment: SupplementAdjustment
): SupplementAdjustmentAction {
  return {
    type: ActionTypes.SUPPLEMENT_ADJUSTMENTS_UPDATED,
    employeeID,
    supplementAdjustmentID,
    supplementAdjustment,
  }
}
function failedUpdatingSupplementAdjustment(employeeID: string, error: Error): SupplementAdjustmentAction {
  return { type: ActionTypes.SUPPLEMENT_ADJUSTMENTS_UPDATE_FAILED, employeeID, error }
}

function deletingSupplementAdjustment(supplementAdjustmentID: string): SupplementAdjustmentAction {
  return {
    type: ActionTypes.SUPPLEMENT_ADJUSTMENTS_DELETING,
    supplementAdjustmentID,
  }
}
export function deletedSupplementAdjustment(supplementAdjustmentID: string): SupplementAdjustmentAction {
  return {
    type: ActionTypes.SUPPLEMENT_ADJUSTMENTS_DELETED,
    supplementAdjustmentID,
  }
}
function failedDeletingSupplementAdjustment(supplementAdjustmentID: string, error: Error): SupplementAdjustmentAction {
  return { type: ActionTypes.SUPPLEMENT_ADJUSTMENTS_DELETE_FAILED, supplementAdjustmentID, error }
}

export function getSupplementAdjustments(companyID?: string, employeeID?: string) {
  return (dispatch: React.Dispatch<any>): Promise<SupplementAdjustment[] | void> => {
    dispatch(loadingSupplementAdjustments(companyID, employeeID))
    return fetchSupplementAdjustments(companyID, employeeID)
      .then((res) => {
        dispatch(loadedSupplementAdjustments(companyID, employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSupplementAdjustments(companyID, employeeID, e))
        }
      })
  }
}

export function addSupplementAdjustment(employeeID: string, supplementAdjustment: SupplementAdjustmentCreationFields) {
  return (dispatch: React.Dispatch<any>): Promise<SupplementAdjustment | void> => {
    dispatch(addingSupplementAdjustment(employeeID))
    return postSupplementAdjustment(supplementAdjustment)
      .then((res) => {
        dispatch(addedSupplementAdjustment(employeeID, res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddedSupplementAdjustment(employeeID, e))
        }
      })
  }
}

export function updateSupplementAdjustment(
  employeeID: string,
  supplementAdjustment: SupplementAdjustmentMutableFields
) {
  return (dispatch: React.Dispatch<any>): Promise<SupplementAdjustment | void> => {
    dispatch(updatingSupplementAdjustment(employeeID, supplementAdjustment.id))
    return putSupplementAdjustment(supplementAdjustment.id, supplementAdjustment)
      .then((res) => {
        dispatch(updatedSupplementAdjustment(employeeID, supplementAdjustment.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingSupplementAdjustment(employeeID, e))
        }
      })
  }
}

export function deleteSupplementAdjustment(supplementAdjustmentID: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(deletingSupplementAdjustment(supplementAdjustmentID))
    return removeSupplementAdjustment(supplementAdjustmentID)
      .then(() => {
        dispatch(deletedSupplementAdjustment(supplementAdjustmentID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingSupplementAdjustment(supplementAdjustmentID, e))
        }
      })
  }
}
