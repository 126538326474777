import React, { ReactElement, ReactNode } from 'react'

import Tooltip from '../elements/tooltip'

import './Lock.css'

// NEED_NO_TRANSLATION

type Props = {
  isLocked: boolean
  description: string
  children: ReactNode
}

export default function Lock(props: Props): ReactElement | null {
  if (props.isLocked) {
    return (
      <Tooltip title={props.description} overlayStyle={{ maxWidth: '170px' }}>
        <span className="lock">{props.children}</span>
      </Tooltip>
    )
  }
  if (!props.children) {
    return null
  }
  return <>{props.children}</>
}
