import { List } from 'immutable'
import React, { ReactElement, useEffect } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import CompanySetting from '../../model/companySetting'
import Employee from '../../model/employee'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyPaymentIntegrationReducer } from '../../reducers/companyPaymentIntegrations'
import { EmployeeReducer } from '../../reducers/employees'
import { paths } from '../../routes'
import { compareEmployees } from '../../utils/employee-utils'
import { formatCurrency } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import CustomTable from '../elements/custom-table'
import Headline from '../elements/Headline'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import UserImage from '../elements/UserImage'
import LoadingOverlay from '../widgets/LoadingOverlay'
import ReimbursementSettingsEmployeeForm, {
  ReimbursementSettingsEmployeeResult,
} from './ReimbursementSettingsEmployeeForm'
import ReimbursementSettingsForm, { ReimbursementSettingsResult } from './ReimbursementSettingsForm'

type Props = {
  company: Company
  companies: CompanyReducer
  employees: EmployeeReducer
  companyFeatures: List<CompanyFeature>
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer

  addAlert: addAlertSignature
  updateCompany: (company: Company) => Promise<Company | void>
  updateEmployee: (employee: Employee) => void
  getCompanyPaymentIntegrations: () => void
  enableCompanySettings: (companyID: string, enable: CompanySetting[]) => void
  disableCompanySettings: (companyID: string, disable: CompanySetting[]) => void
}

export default function ReimbursementSettingsTab(props: Props): ReactElement | null {
  const { companies, addAlert } = props
  const previousCompanies = usePrevious(companies)

  useEffect(() => {
    if (previousCompanies && previousCompanies.saving && !companies.saving) {
      if (!companies.error) {
        addAlert('success', t('reimbursement_settings.alert.success'), { timeout: 5 })
      }
    }
  })

  const { companyPaymentIntegrations, getCompanyPaymentIntegrations } = props

  useEffect(() => {
    if (!companyPaymentIntegrations.loaded && !companyPaymentIntegrations.loading) {
      getCompanyPaymentIntegrations()
    }
  })

  const hasNETSIntegration = () => {
    return props.companyPaymentIntegrations.companyPaymentIntegrations.some(
      (v) => v.paymentIntegrationType === 'NETS' && v.status !== 'Expired'
    )
  }
  const hasImmediatePayFeature = () => {
    return props.companyFeatures.some((feature) => feature.featureType === 'Immediate Pay')
  }

  const handleSubmit = (values: ReimbursementSettingsResult) => {
    props.updateCompany({ ...props.company, ...values }).then((res) => {
      if (!res) {
        return
      }
      if (values.autoApproveReimbursementVouchers) {
        props.enableCompanySettings(props.company.id, ['AutoApproveReimbursementVouchers'])
      } else {
        props.disableCompanySettings(props.company.id, ['AutoApproveReimbursementVouchers'])
      }
    })
  }

  const handleEmployeeSubmit = (values: ReimbursementSettingsEmployeeResult) => {
    const employee = props.employees.employees.find((employee) => employee.id === values.employeeID)
    if (!employee) {
      return
    }
    props.updateEmployee({
      ...employee,
      reimbursementVoucherAutoApprovalLimit: values.reimbursementVoucherAutoApprovalLimit,
    })
  }

  const handleEmployeeDeleteSubmit = (employeeID: string) => {
    const employee = props.employees.employees.find((employee) => employee.id === employeeID)
    if (!employee) {
      return
    }
    props.updateEmployee({
      ...employee,
      reimbursementVoucherAutoApprovalLimit: -1,
    })
  }

  if (!companyPaymentIntegrations.loaded) {
    return <LoadingOverlay />
  }

  return (
    <div>
      <Card>
        <TitleMenu>
          {hasNETSIntegration() && hasImmediatePayFeature() && (
            <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/transfer-immediate-pay'}>
              <Button className="gtm-change-transfer-immediate-pay">
                {t('reimbursement_settings.header.immediate_pay')}
              </Button>
            </Link>
          )}
          <Link to={'/' + paths.REIMBURSEMENT_VOUCHERS}>
            <Button>{t('reimbursement_settings.header.back')}</Button>
          </Link>
        </TitleMenu>
        <Title>{t('reimbursement_settings.title')}</Title>

        <ReimbursementSettingsForm company={props.company} onSubmit={handleSubmit} />
      </Card>
      {props.company.settingsEnabled.some((setting) => setting === 'AutoApproveReimbursementVouchers') && (
        <Card className="auto-approve-reimbursement-employees">
          <Title>{t('reimbursement_settings.employee.title')}</Title>
          <p style={{ marginBottom: '15px' }}>
            {props.company.enableImmediatePay
              ? (props.company.periodicLimitForImmediatePayOut ?? 0) > 0
                ? t('reimbursement_settings.employee.note.line_1.immediate_pay_with_limit', {
                    amount: formatCurrency(props.company.periodicLimitForImmediatePayOut),
                  })
                : t('reimbursement_settings.employee.note.line_1.immediate_pay_no_limit')
              : t('reimbursement_settings.employee.note.line_1.no_immediate_pay')}
            <br />
            {t('reimbursement_settings.employee.note.line_2')}
            <br />
            {t('reimbursement_settings.employee.note.line_3')}
          </p>
          <CustomTable columns={2}>
            <CustomTable.Header>
              <CustomTable.TH className="reimbursement-limit-employee">
                {t('reimbursement_settings.employee.header.employee')}
              </CustomTable.TH>
              <CustomTable.TH className="reimbursement-limit-amount">
                {t('reimbursement_settings.employee.header.amount')}
              </CustomTable.TH>
            </CustomTable.Header>
            <CustomTable.Body>
              {props.employees.employees
                .sort((a, b) => compareEmployees(a, b))
                .map((employee) => {
                  return (
                    <CustomTable.Row key={employee.id}>
                      <CustomTable.TD className="reimbursement-limit-employee">
                        <Headline>
                          <UserImage src={employee.profileImageURL} name={employee.name} />
                          <Link to={'/' + paths.EMPLOYEES + '/' + employee.id}>{employee.name}</Link>
                        </Headline>
                      </CustomTable.TD>
                      <CustomTable.TD className="reimbursement-limit-amount">
                        <ReimbursementSettingsEmployeeForm
                          key={employee.id}
                          employee={employee}
                          employees={props.employees}
                          company={props.company}
                          onSubmit={handleEmployeeSubmit}
                          onDelete={handleEmployeeDeleteSubmit}
                        />
                      </CustomTable.TD>
                    </CustomTable.Row>
                  )
                })}
            </CustomTable.Body>
          </CustomTable>
        </Card>
      )}
    </div>
  )
}
