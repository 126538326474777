import React, { CSSProperties, ReactElement } from 'react'

import './Elements.css'

// NEED_NO_TRANSLATION

export default function Subcard(props: {
  className?: string
  style?: CSSProperties
  children?: React.ReactNode
}): ReactElement {
  return (
    <div className={'subcard' + (props.className ? ' ' + props.className : '')} style={props.style}>
      {props.children}
    </div>
  )
}
