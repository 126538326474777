import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import { AccountingAccount, AccountPlanMapping } from '../model/accountingIntegration'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyAccountPlanReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  accounts: List<AccountingAccount>
  mapping: List<AccountPlanMapping>
  error: Error | null
}

const Params = Record<CompanyAccountPlanReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  accounts: List<AccountingAccount>(),
  mapping: List<AccountPlanMapping>(),
  saving: false,
  error: null,
})

export interface CompanyAccountPlanAction extends ReducerAction {
  companyID?: string
  accounts?: AccountingAccount[]
  mapping?: AccountPlanMapping[]
}

export default (
  state: Record<CompanyAccountPlanReducer> = Params(),
  action: CompanyAccountPlanAction = { type: '' }
): Record<CompanyAccountPlanReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.ACCOUNT_PLANS_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.ACCOUNT_PLANS_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        accounts: List<AccountingAccount>(action.accounts),
        mapping: List<AccountPlanMapping>(action.mapping),
      })
    case ActionTypes.ACCOUNT_PLANS_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.ACCOUNT_PLANS_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ACCOUNT_PLANS_UPDATED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        accounts: List<AccountingAccount>(action.accounts),
        mapping: List<AccountPlanMapping>(action.mapping),
      })
    case ActionTypes.ACCOUNT_PLANS_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
