export type SyncAction<DataType> = () => Promise<DataType | void>

export function createSynchroniser<DataType>(actions?: SyncAction<DataType>[]): SyncAction<DataType>[] {
  return actions ? actions : []
}

export function executeSynchroniser<DataType>(actions: SyncAction<DataType>[]) {
  const f = (id: number) => {
    actions[id]().then(() => {
      if (actions.length > id + 1) {
        f(id + 1)
      }
    })
  }
  if (actions.length > 0) {
    f(0)
  }
}

export type ConditionalSyncAction = () => Promise<boolean>

export function executeConditionalSynchroniser(actions: ConditionalSyncAction[], final?: () => void) {
  const doFinal = () => {
    if (final) {
      final()
    }
  }
  const f = (id: number) => {
    actions[id]().then((res) => {
      if (!res) {
        doFinal()
      }
      if (actions.length > id + 1) {
        f(id + 1)
      } else {
        doFinal()
      }
    })
  }
  if (actions.length > 0) {
    f(0)
  } else {
    doFinal()
  }
}
