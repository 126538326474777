import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getAssetCategories } from '../actions/asset-categories'
import { getAssets } from '../actions/assets'
import AssetComponent from '../components/assets/Assets'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import Asset from '../model/asset'
import AssetCategory from '../model/assetCategory'
import { AlertReducer } from '../reducers/alerts'
import { AssetCategoryReducer } from '../reducers/assetCategories'
import { AssetReducer } from '../reducers/assets'
import { CompanyReducer } from '../reducers/companies'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { EmployeeReducer } from '../reducers/employees'
import { formatLoadingText } from '../utils/loading-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import AdministrationLayout from './layouts/AdministrationLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  employees: EmployeeReducer
  assets: AssetReducer
  assetCategories: AssetCategoryReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getAssets: () => Promise<Asset[] | void>
  getAssetCategories: () => Promise<AssetCategory[] | void>
}

function Assets(props: Reducers & Actions & RouteProps): ReactElement | null {
  const company = props.companies.company
  const { assetCategories, assets, getAssetCategories, getAssets } = props

  useEffect(() => {
    if (!company) {
      return
    }
    if (assetCategories.companyID !== company.id || (!assetCategories.loading && !assetCategories.loaded)) {
      getAssetCategories()
    }
  }, [company, assetCategories, getAssetCategories])

  useEffect(() => {
    if (!company) {
      return
    }
    if (assets.companyID !== company.id || (!assets.loading && !assets.loaded)) {
      getAssets()
    }
  }, [assets, getAssets, company])

  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  if (!assets.loaded || !assetCategories.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            { loading: !assets.loaded, text: 'enheder' },
            { loading: !assetCategories.loaded, text: 'enhedskategorier' },
          ])}
        />
      </div>
    )
  }

  return (
    <AdministrationLayout route={props.route}>
      <AssetComponent
        alerts={props.alerts}
        employees={props.employees.employees}
        assets={props.assets.assets}
        company={company}
        companyUser={props.companyUsers.companyUser}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
      />
    </AdministrationLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    user: state.user,
    companies: state.companies,
    companyUsers: state.companyUsers,
    employees: state.employees,
    assets: state.assets,
    assetCategories: state.assetCategories,
  }),
  {
    getAssets: getAssets,
    getAssetCategories: getAssetCategories,
    addAlert: addAlert,
    removeAlert: removeAlert,
  }
)(Assets)
