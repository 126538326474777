import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import SalaryRegistration from '../model/salaryRegistration'
import { DateFormat, SettledState } from '../model/types'
import { ReducerAction } from '../utils/reducer-utils'

export interface SalaryRegistrationReducer {
  companyID: string | undefined
  employeeID: string | undefined
  payRollID: string | undefined
  fromDate: DateFormat | undefined
  toDate: DateFormat | undefined
  stateFilter: SettledState | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  salaryRegistrations: List<SalaryRegistration>
  error: Error | null
}

const Params = Record<SalaryRegistrationReducer>({
  companyID: undefined,
  employeeID: undefined,
  payRollID: undefined,
  fromDate: undefined,
  toDate: undefined,
  stateFilter: undefined,
  loading: false,
  loaded: false,
  saving: false,
  salaryRegistrations: List<SalaryRegistration>(),
  error: null,
})

export interface SalaryRegistrationAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  payRollID?: string
  fromDate?: DateFormat
  toDate?: DateFormat
  stateFilter?: SettledState
  salaryRegistrations?: SalaryRegistration[]
  salaryRegistration?: SalaryRegistration
  salaryRegistrationID?: string
  salaryRegistrationIDs?: string[]
  approved?: boolean
}

export default (
  state: Record<SalaryRegistrationReducer> = Params(),
  action: SalaryRegistrationAction = { type: '' }
): Record<SalaryRegistrationReducer> => {
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  if (action.type !== ActionTypes.SALARY_REGISTRATION_LOADING) {
    if (action.payRollID && state.get('payRollID')) {
      // only process actions for the active pay roll
      if (action.payRollID !== state.get('payRollID')) {
        return state
      }
    } else if (action.employeeID && state.get('employeeID')) {
      // only process actions for the active employee
      if (action.employeeID !== state.get('employeeID')) {
        return state
      }
    }
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.SALARY_REGISTRATION_LOADING:
      return Params({
        companyID: action.companyID,
        employeeID: action.employeeID,
        payRollID: action.payRollID,
        fromDate: action.fromDate,
        toDate: action.toDate,
        stateFilter: action.stateFilter,
        loading: true,
      })
    case ActionTypes.SALARY_REGISTRATION_LOADED:
    case ActionTypes.SALARY_REGISTRATION_LOADED_PARTIAL:
      return Params({
        loaded: true,
        loading: action.type === ActionTypes.SALARY_REGISTRATION_LOADED_PARTIAL,
        companyID: action.companyID,
        employeeID: action.employeeID,
        payRollID: action.payRollID,
        fromDate: action.fromDate,
        toDate: action.toDate,
        stateFilter: action.stateFilter,
        salaryRegistrations: List(action.salaryRegistrations),
      })
    case ActionTypes.SALARY_REGISTRATION_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', action.companyID || state.get('companyID'))
        .set('employeeID', action.employeeID || state.get('employeeID'))
        .set('payRollID', action.payRollID || state.get('payRollID'))
        .set('error', action.error || null)

    case ActionTypes.SALARY_REGISTRATION_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SALARY_REGISTRATION_CREATED:
      if (!action.salaryRegistration) {
        return state
      }
      if (!action.companyID) {
        // only process for active employee
        if (action.employeeID !== state.get('employeeID')) {
          return state
        }
      }

      idx = state
        .get('salaryRegistrations')
        .findIndex((item) => !!action.salaryRegistration && item.id === action.salaryRegistration.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('salaryRegistrations', state.get('salaryRegistrations').set(idx, action.salaryRegistration))
      }
      return state
        .set('saving', false)
        .set('salaryRegistrations', state.get('salaryRegistrations').push(action.salaryRegistration))
    case ActionTypes.SALARY_REGISTRATION_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.SALARY_REGISTRATION_APPROVING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SALARY_REGISTRATION_APPROVED:
      if (typeof action.approved !== 'boolean') {
        return state
      }
      return state.set('saving', false).set(
        'salaryRegistrations',
        state.get('salaryRegistrations').map((registration) => {
          if (!action.salaryRegistrationIDs) {
            return registration
          }
          if (action.salaryRegistrationIDs.indexOf(registration.id) !== -1) {
            registration.approved = !!action.approved
          }
          return registration
        })
      )
    case ActionTypes.SALARY_REGISTRATION_APPROVE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.SALARY_REGISTRATION_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SALARY_REGISTRATION_UPDATED:
      if (!action.salaryRegistration) {
        return state
      }

      if (!action.companyID) {
        // only process for active employee
        if (action.employeeID !== state.get('employeeID')) {
          return state
        }
      }

      idx = state
        .get('salaryRegistrations')
        .findIndex((item) => !!action.salaryRegistration && item.id === action.salaryRegistration.id)
      if (idx === -1) {
        return state.set('saving', false)
      }

      return state
        .set('saving', false)
        .set('salaryRegistrations', state.get('salaryRegistrations').set(idx, action.salaryRegistration))
    case ActionTypes.SALARY_REGISTRATION_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.SALARY_REGISTRATION_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SALARY_REGISTRATION_DELETED:
      idx = state.get('salaryRegistrations').findIndex((item) => item.id === action.salaryRegistrationID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('salaryRegistrations', state.get('salaryRegistrations').delete(idx))
    case ActionTypes.SALARY_REGISTRATION_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
