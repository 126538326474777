import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import AccountingDimension from '../model/accountingDimension'
import { ReducerAction } from '../utils/reducer-utils'

export interface AccountingDimensionReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  accountingDimensions: List<AccountingDimension>
  error: Error | null
}

const Params = Record<AccountingDimensionReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  accountingDimensions: List<AccountingDimension>(),
  error: null,
})

export interface AccountingDimensionAction extends ReducerAction {
  companyID?: string
  accountingDimensions?: AccountingDimension[]
  accountingDimension?: AccountingDimension
}

export default (
  state: Record<AccountingDimensionReducer> = Params(),
  action: AccountingDimensionAction = { type: '' }
): Record<AccountingDimensionReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.ACCOUNTING_DIMENSIONS_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.ACCOUNTING_DIMENSIONS_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        accountingDimensions: List<AccountingDimension>(action.accountingDimensions),
      })
    case ActionTypes.ACCOUNTING_DIMENSIONS_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.ACCOUNTING_DIMENSIONS_UPDATING:
      return state.set('saving', true)
    case ActionTypes.ACCOUNTING_DIMENSIONS_UPDATED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        accountingDimensions: List<AccountingDimension>(action.accountingDimensions),
      })
    case ActionTypes.ACCOUNTING_DIMENSIONS_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.ACCOUNTING_DIMENSION_ADDED:
      if (!action.accountingDimension) {
        return state
      }
      idx = state
        .get('accountingDimensions')
        .findIndex((dimension) => !!action.accountingDimension && dimension.id === action.accountingDimension.id)
      if (idx !== -1) {
        return state.set('accountingDimensions', state.get('accountingDimensions').set(idx, action.accountingDimension))
      }
      return state.set('accountingDimensions', state.get('accountingDimensions').push(action.accountingDimension))

    case ActionTypes.ACCOUNTING_DIMENSION_UPDATED:
      if (!action.accountingDimension) {
        return state
      }
      idx = state
        .get('accountingDimensions')
        .findIndex((dimension) => !!action.accountingDimension && dimension.id === action.accountingDimension.id)
      if (idx === -1) {
        return state
      }
      return state.set('accountingDimensions', state.get('accountingDimensions').set(idx, action.accountingDimension))

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
