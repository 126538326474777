import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../../model/company'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import { OneTimePayPensionCreationFields, OneTimePayPensionMutableFields } from '../../../model/oneTimePayPension'
import PensionCompany from '../../../model/pensionCompany'
import SalaryCycle from '../../../model/salaryCycle'
import { OneTimePayPensionReducer } from '../../../reducers/oneTimePayPensions'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import ExtraPensionEditForm, { OneTimePayPensionResultFields } from './ExtraPensionEditForm'

type Props = {
  visible: boolean
  editing: boolean
  canApproveObjects: boolean
  company: Company
  employee?: Employee
  oneTimePayID?: string
  oneTimePayPensions: OneTimePayPensionReducer
  pensionCompanies: List<PensionCompany>
  salaryCycle: SalaryCycle
  viewingContract: Contract

  addOneTimePayPension: (employeeID: string, otp: OneTimePayPensionCreationFields) => void
  updateOneTimePayPension: (employeeID: string, otp: OneTimePayPensionMutableFields) => void
}

export default function ExtraPension(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, oneTimePayPensions } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, oneTimePayPensions.error, error, setError)
  }, [visible, oneTimePayPensions, error])

  const handleSubmit = (values: OneTimePayPensionResultFields) => {
    if (!props.editing || !props.employee) {
      return
    }
    const oneTimePayPension: OneTimePayPensionCreationFields = {
      oneTimePay: {
        employeeID: props.employee.id,
        dispositionDate: values.dispositionDate,
        title: values.title,
        amount: values.amount,
        approved: values.approved,
        type: 'Pension',
        payOutType: 'Salary',
      },
      destinationType: values.destinationType,
      pensionCompanyID: values.pensionCompanyID,
      pensionCustomerID: values.pensionCustomerID,
      sortCode: values.sortCode,
      account: values.account,
      scheme: values.scheme,
      unionAgreementNumber: values.unionAgreementNumber,
      reportingBaseAmount: values.reportingBaseAmount,
      reportingPercentage: values.reportingPercentage,
    }
    if (props.oneTimePayID) {
      props.updateOneTimePayPension(props.employee.id, { ...oneTimePayPension, id: props.oneTimePayID })
    } else {
      props.addOneTimePayPension(props.employee.id, oneTimePayPension)
    }
  }

  if (!props.employee) {
    return null
  }
  return (
    <Card className="bonus-modal">
      <Subtitle>
        {props.oneTimePayID
          ? props.editing
            ? t('extra_pension.edit.title.edit')
            : t('extra_pension.edit.title.view')
          : t('extra_pension.edit.title.create')}
      </Subtitle>
      <p>{t('extra_pension.edit.intro')}</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <ExtraPensionEditForm
        editing={props.editing}
        canApproveObjects={props.canApproveObjects}
        company={props.company}
        employee={props.employee}
        oneTimePayID={props.oneTimePayID}
        oneTimePayPensions={props.oneTimePayPensions}
        pensionCompanies={props.pensionCompanies}
        salaryCycle={props.salaryCycle}
        viewingContract={props.viewingContract}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
