import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { addContractBookDraft, getContractBookIntegrations, getContractBookTemplates } from '../actions/contract-book'
import { getDepartments } from '../actions/departments'
import { deleteEmployee } from '../actions/employees'
import { getEmployments, terminateEmployment } from '../actions/employments'
import { ContractBookDraftCreated } from '../api/contract-book'
import EmployeesComponent from '../components/employees/Employees'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import Employment from '../model/employment'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { ContractBookDraftReducer } from '../reducers/contractBookDrafts'
import { ContractBookIntegrationReducer } from '../reducers/contractBookIntegrations'
import { ContractBookTemplateReducer } from '../reducers/contractBookTemplates'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employees'
import { EmploymentReducer } from '../reducers/employments'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { UserReducer } from '../reducers/user'
import { isDepartmentRestricted } from '../utils/permissions-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  companyFeatures: CompanyFeatureReducer
  companyUsers: CompanyUserReducer
  departments: DepartmentReducer
  employees: EmployeeReducer
  employments: EmploymentReducer
  user: UserReducer
  contractBookIntegrations: ContractBookIntegrationReducer
  contractBookTemplates: ContractBookTemplateReducer
  contractBookDrafts: ContractBookDraftReducer
  salaryCycles: SalaryCycleReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getContractBookIntegrations: () => void
  getContractBookTemplates: () => void
  addContractBookDraft: (contractBookID: string, templateID: string) => Promise<ContractBookDraftCreated | void>
  getEmployments: (employeeID: string) => void
  getDepartments: () => void
  deleteEmployee: (employeeID: string, removeOrphans: boolean) => Promise<boolean | void>
  terminateEmployment: (employeeID: string, employment: Employment) => Promise<boolean | void>
}

function Employees(props: Reducers & Actions & RouteProps): ReactElement | null {
  const {
    contractBookIntegrations,
    getContractBookIntegrations,
    contractBookTemplates,
    getContractBookTemplates,
    departments,
    getDepartments,
  } = props
  useEffect(() => {
    if (!contractBookIntegrations.loading && !contractBookIntegrations.loaded) {
      getContractBookIntegrations()
    }
    if (
      contractBookIntegrations.loaded &&
      contractBookIntegrations.contractBookIntegrations &&
      contractBookIntegrations.contractBookIntegrations.size > 0
    ) {
      if (!contractBookTemplates.loading && !contractBookTemplates.loaded && !contractBookTemplates.error) {
        getContractBookTemplates()
      }
    }
    if (!departments.loading && !departments.loaded) {
      getDepartments()
    }
  })

  const company = props.companies.company
  if (!company) {
    return null
  }

  const loading =
    !props.contractBookIntegrations.loaded ||
    (props.contractBookIntegrations.contractBookIntegrations &&
      props.contractBookIntegrations.contractBookIntegrations.size > 0 &&
      !props.contractBookTemplates.loaded &&
      !props.contractBookTemplates.error) ||
    !props.departments.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <EmployeesComponent
      location={props.location}
      alerts={props.alerts}
      company={company}
      companyFeatures={props.companyFeatures.companyFeatures}
      companyUser={props.companyUsers.companyUser}
      departments={props.departments.departments}
      employees={props.employees}
      employments={props.employments}
      salaryCycles={props.salaryCycles.salaryCycles}
      contractBookTemplates={props.contractBookTemplates}
      contractBookDrafts={props.contractBookDrafts}
      isDepartmentRestricted={isDepartmentRestricted(props.companyUsers.companyUser)}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      addContractBookDraft={props.addContractBookDraft}
      getEmployments={props.getEmployments}
      deleteEmployee={props.deleteEmployee}
      terminateEmployment={props.terminateEmployment}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    companyFeatures: state.companyFeatures,
    companyUsers: state.companyUsers,
    departments: state.departments,
    employees: state.employees,
    employments: state.employments,
    user: state.user,
    contractBookIntegrations: state.contractBookIntegrations,
    contractBookTemplates: state.contractBookTemplates,
    contractBookDrafts: state.contractBookDrafts,
    salaryCycles: state.salaryCycles,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getContractBookIntegrations: getContractBookIntegrations,
    getContractBookTemplates: getContractBookTemplates,
    addContractBookDraft: addContractBookDraft,
    getEmployments: getEmployments,
    getDepartments: getDepartments,
    deleteEmployee: deleteEmployee,
    terminateEmployment: terminateEmployment,
  }
)(Employees)
