import React from 'react'

import { fetchCompanyPricings } from '../api/company-pricings'
import ActionTypes from '../constants/action-types'
import CompanyPricing from '../model/companyPricing'
import { CompanyPricingAction } from '../reducers/companyPricings'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingCompanyPricings(): CompanyPricingAction {
  return {
    type: ActionTypes.COMPANY_PRICINGS_LOADING,
  }
}
export function loadedCompanyPricings(companyID: string, companyPricings: CompanyPricing[]): CompanyPricingAction {
  return {
    type: ActionTypes.COMPANY_PRICINGS_LOADED,
    companyPricings,
    companyID,
  }
}
function failedLoadingCompanyPricings(companyID: string, error: Error): CompanyPricingAction {
  return {
    type: ActionTypes.COMPANY_PRICINGS_LOAD_FAILED,
    error,
    companyID,
  }
}

export function getCompanyPricings() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<CompanyPricing[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingCompanyPricings())
    return fetchCompanyPricings(companyID)
      .then((res) => {
        dispatch(loadedCompanyPricings(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompanyPricings(companyID, e))
        }
      })
  }
}
