import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import { Col, Row } from '../elements/grid'
import Title from '../elements/Title'

export default function MinRefusion(): ReactElement | null {
  return (
    <div>
      <Card className="card-minrefusion">
        <Title>{t('basic_integrations.min_refusion.title')}</Title>
        <p>{t('basic_integrations.min_refusion.text.line_1')}</p>
        <p>{t('basic_integrations.min_refusion.text.line_2')}</p>
        <p>{t('basic_integrations.min_refusion.text.line_3')}</p>
        <Row className={'button-row'}>
          <Col span={8}>
            <Link
              to={
                'https://www.nem-hr.dk/minrefusion?utm_source=salary&utm_medium=web_partner&utm_campaign=salaryintegration&utm_content=readmorebutton'
              }
              target={'_blank'}
            >
              <Button size="large">{t('basic_integrations.min_refusion.buttons.read_more')}</Button>
            </Link>
          </Col>
          <Col span={8}>
            <Link
              to={
                'https://app.minrefusion.dk/integration/install/e99f2296d28ca5c992e7d41a04ce2f09d6c37e3e8d384083713c14bfb7236cd2'
              }
              target={'_blank'}
            >
              <Button size="large" type="secondary">
                {t('basic_integrations.min_refusion.buttons.install')}
              </Button>
            </Link>
          </Col>
        </Row>
      </Card>
    </div>
  )
}
