import React, { CSSProperties, ReactElement } from 'react'

import './Elements.css'

// NEED_NO_TRANSLATION

export default function Subtitle(props: { style?: CSSProperties; children?: React.ReactNode }): ReactElement {
  return (
    <h2 className="h2" style={props.style}>
      {props.children}
    </h2>
  )
}
