import { SupplementDefinition } from '../model/remuneration'
import SupplementType from '../model/supplementType'

export function isVacationSupplement(supplementTypes: SupplementType[], supplement: SupplementDefinition) {
  return supplementTypes.some((type) => type.id === supplement.typeID && type.name.match(/DenmarkVacation.+/))
}

export function isGreatPrayerDaySupplement(supplementTypes: SupplementType[], supplement: SupplementDefinition) {
  return supplementTypes.some((type) => type.id === supplement.typeID && type.name.match(/GreatPrayerDay.+/))
}
