import { addMonths, addWeeks } from 'date-fns'

import SalaryCycle from '../model/salaryCycle'
import { DateFormat } from '../model/types'
import { formatAPIDate, getDate } from './date-utils'

export function calculateFutureValidFrom(
  salaryCycles: SalaryCycle[],
  salaryCycleID: string,
  previousValidFrom: DateFormat,
  validFromCount: number
) {
  const salaryCycle = salaryCycles.find((salaryCycle) => salaryCycle.id === salaryCycleID)
  switch (salaryCycle?.frequency) {
    case 'Monthly':
      return formatAPIDate(addMonths(getDate(previousValidFrom), validFromCount))
    case 'BiWeekly':
      return formatAPIDate(addWeeks(getDate(previousValidFrom), validFromCount * 2))
    case 'Weekly':
      return formatAPIDate(addWeeks(getDate(previousValidFrom), validFromCount))
    default:
      return undefined
  }
}
