import React from 'react'

import { fetchAvailableAccountingIntegrations } from '../api/available-accounting-integrations'
import ActionTypes from '../constants/action-types'
import AvailableAccountingIntegration from '../model/availableAccountingIntegration'
import { AvailableAccountingIntegrationAction } from '../reducers/availableAccountingIntegrations'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingAvailableAccountingIntegrations(): AvailableAccountingIntegrationAction {
  return {
    type: ActionTypes.AVAILABLE_ACCOUNTING_INTEGRATIONS_LOADING,
  }
}
function loadedAvailableAccountingIntegrations(
  companyID: string,
  availableAccountingIntegrations: AvailableAccountingIntegration[]
): AvailableAccountingIntegrationAction {
  return {
    type: ActionTypes.AVAILABLE_ACCOUNTING_INTEGRATIONS_LOADED,
    companyID,
    availableAccountingIntegrations,
  }
}
function failedLoadingAvailableAccountingIntegrations(
  companyID: string,
  error: Error
): AvailableAccountingIntegrationAction {
  return {
    type: ActionTypes.AVAILABLE_ACCOUNTING_INTEGRATIONS_LOAD_FAILED,
    companyID,
    error,
  }
}

export function getAvailableAccountingIntegrations() {
  return (
    dispatch: React.Dispatch<any>,
    getState?: getStateSignature
  ): Promise<AvailableAccountingIntegration[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingAvailableAccountingIntegrations())
    return fetchAvailableAccountingIntegrations(companyID)
      .then((res) => {
        dispatch(loadedAvailableAccountingIntegrations(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingAvailableAccountingIntegrations(companyID, e))
        }
      })
  }
}
