import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getAvailableDataIntegrations } from '../actions/available-data-integrations'
import { updateCompanyDataIntegration } from '../actions/data-integration'
import CompanyDataIntegrationComponent from '../components/company-data-integration/CompanyDataIntegration'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { AlertReducer } from '../reducers/alerts'
import { AvailableDataIntegrationReducer } from '../reducers/availableDataIntegrations'
import { CompanyReducer } from '../reducers/companies'
import { CompanyDataIntegrationReducer } from '../reducers/companyDataIntegration'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  availableDataIntegrations: AvailableDataIntegrationReducer
  companyDataIntegration: CompanyDataIntegrationReducer
  salaryTypes: SalaryTypeReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getAvailableDataIntegrations: () => void
  updateCompanyDataIntegration: (state: string) => void
}

function CompanyDataIntegration(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { availableDataIntegrations, getAvailableDataIntegrations } = props
  useEffect(() => {
    if (!availableDataIntegrations.loaded && !availableDataIntegrations.loading) {
      getAvailableDataIntegrations()
    }
  })

  const company = props.companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  if (!props.availableDataIntegrations.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <CompanyDataIntegrationComponent
      query={props.location.query}
      alerts={props.alerts}
      company={company}
      availableDataIntegrations={props.availableDataIntegrations.availableDataIntegrations}
      companyDataIntegration={props.companyDataIntegration}
      salaryTypes={props.salaryTypes.salaryTypes}
      updateCompanyDataIntegration={props.updateCompanyDataIntegration}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    availableDataIntegrations: state.availableDataIntegrations,
    companyDataIntegration: state.companyDataIntegration,
    salaryTypes: state.salaryTypes,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getAvailableDataIntegrations: getAvailableDataIntegrations,
    updateCompanyDataIntegration: updateCompanyDataIntegration,
  }
)(CompanyDataIntegration)
