import React, { ReactElement } from 'react'
import { useEffectOnce } from 'react-use'

import { getCompanyAccountPlans } from '../actions/accounting-integration'
import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getExpenseCategories } from '../actions/expense-categories'
import { getReimbursementVouchers, rebookReimbursementVouchers } from '../actions/reimbursement-vouchers'
import { changeVoucherDate, getVouchers, rebookVoucher } from '../actions/vouchers'
import VouchersComponent from '../components/vouchers/Vouchers'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { DateFormat } from '../model/types'
import Voucher from '../model/voucher'
import { AlertReducer } from '../reducers/alerts'
import { AsynchronousTaskReducer } from '../reducers/asynchronousTasks'
import { CompanyReducer } from '../reducers/companies'
import { CompanyAccountPlanReducer } from '../reducers/companyAccountPlans'
import { ExpenseCategoryReducer } from '../reducers/expenseCategories'
import { ReimbursementVoucherReducer } from '../reducers/reimbursementVouchers'
import { UserReducer } from '../reducers/user'
import { VoucherReducer } from '../reducers/vouchers'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import IntegrationsLayout from './layouts/IntegrationsLayout'

type Reducers = {
  alerts: AlertReducer
  asynchronousTasks: AsynchronousTaskReducer
  companies: CompanyReducer
  user: UserReducer
  vouchers: VoucherReducer
  reimbursementVouchers: ReimbursementVoucherReducer
  expenseCategories: ExpenseCategoryReducer
  companyAccountPlans: CompanyAccountPlanReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getVouchers: () => void
  getReimbursementVouchers: () => void
  getExpenseCategories: () => void
  getCompanyAccountPlans: () => void
  rebookVoucher: (id: string) => void
  rebookReimbursementVouchers: (ids: string[]) => void
  changeVoucherDate: (id: string, date: DateFormat) => Promise<Voucher | void>
}

function AccountingVouchers(props: Reducers & Actions & RouteProps): ReactElement | null {
  const {
    vouchers,
    getVouchers,
    reimbursementVouchers,
    getReimbursementVouchers,
    expenseCategories,
    getExpenseCategories,
    companyAccountPlans,
    getCompanyAccountPlans,
  } = props

  useEffectOnce(() => {
    if (vouchers.payRollID || (!vouchers.loading && !vouchers.loaded)) {
      getVouchers()
    }
    if (!reimbursementVouchers.loading && !reimbursementVouchers.loaded) {
      getReimbursementVouchers()
    }
    if (!expenseCategories.loading && !expenseCategories.loaded) {
      getExpenseCategories()
    }
    if (!companyAccountPlans.loading && !companyAccountPlans.loaded) {
      getCompanyAccountPlans()
    }
  })

  if (!props.companies.company) {
    return null // should not happen
  }

  if (!props.vouchers.loaded || !props.reimbursementVouchers.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <IntegrationsLayout location={props.location}>
      <VouchersComponent
        alerts={props.alerts}
        asynchronousTasks={props.asynchronousTasks.asynchronousTasks}
        company={props.companies.company}
        user={props.user}
        vouchers={props.vouchers.vouchers}
        reimbursementVouchers={props.reimbursementVouchers.reimbursementVouchers}
        accounts={props.companyAccountPlans.accounts}
        expenseCategories={props.expenseCategories.expenseCategories}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        rebookVoucher={props.rebookVoucher}
        rebookReimbursementVouchers={props.rebookReimbursementVouchers}
        changeVoucherDate={props.changeVoucherDate}
      />
    </IntegrationsLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    asynchronousTasks: state.asynchronousTasks,
    companies: state.companies,
    user: state.user,
    vouchers: state.vouchers,
    companyAccountPlans: state.companyAccountPlans,
    expenseCategories: state.expenseCategories,
    reimbursementVouchers: state.reimbursementVouchers,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getVouchers: getVouchers,
    getReimbursementVouchers: getReimbursementVouchers,
    getExpenseCategories: getExpenseCategories,
    getCompanyAccountPlans: getCompanyAccountPlans,
    rebookVoucher: rebookVoucher,
    rebookReimbursementVouchers: rebookReimbursementVouchers,
    changeVoucherDate: changeVoucherDate,
  }
)(AccountingVouchers)
