import React, { ReactElement, useEffect, useState } from 'react'
import { useEffectOnce, usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import CompanyGroup, { CompanyGroupMutableFields } from '../../../model/companyGroup'
import CompanySetting from '../../../model/companySetting'
import { CompanyGroupReducer } from '../../../reducers/companyGroups'
import { PricingPackageReducer } from '../../../reducers/pricingPackages'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import AdvancedSettingsForm, { AdvancedResult } from '../../companies-single/AdvancedSettingsForm'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Title from '../../elements/Title'
import SettingsForm, { SettingResult } from './SettingsForm'

type Props = {
  companyGroup: CompanyGroup
  companyGroups: CompanyGroupReducer
  pricingPackages: PricingPackageReducer

  addAlert: addAlertSignature
  getPricingPackages: (pricingPackageIDs: string[]) => void
  updateCompanyGroup: (companyGroupID: string, companyGroup: CompanyGroupMutableFields) => void
  setCompanyGroupCompanySettings: (
    companyGroupID: string,
    settingsEnable: CompanySetting[],
    settingDisable: CompanySetting[],
    retroactive: boolean
  ) => void
}

export default function SettingsTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { pricingPackages, companyGroup, getPricingPackages } = props

  useEffectOnce(() => {
    if (
      !pricingPackages.loading &&
      (!pricingPackages.loaded ||
        !pricingPackages.pricingPackages.some(
          (pricingPackage) => pricingPackage.id === companyGroup.defaultPricingPackageID
        ))
    ) {
      getPricingPackages([companyGroup.defaultPricingPackageID])
    }
  })

  const { companyGroups, addAlert } = props
  const previousCompanyGroups = usePrevious(companyGroups)
  useEffect(() => {
    if (previousCompanyGroups && previousCompanyGroups.saving && !companyGroups.saving) {
      if (!companyGroups.error) {
        addAlert('success', t('companies.settings.alert.success', { name: companyGroup.name }), { timeout: 5 })
      }
    }
  })
  useEffect(() => {
    regularComponentDidUpdate(companyGroups.error, error, setError)
  }, [companyGroups, error])

  const handleSubmit = (values: SettingResult) => {
    const companyGroup = {
      name: values.name,
      defaultPricingPackageID: values.defaultPricingPackageID,
    }

    props.updateCompanyGroup(props.companyGroup.id, companyGroup)
  }

  const handleAdvanceSettingsSubmit = (values: AdvancedResult) => {
    props.setCompanyGroupCompanySettings(props.companyGroup.id, values.enable, values.disable, values.retroactive)
  }

  const currentPricingPackage = props.pricingPackages.pricingPackages.find(
    (pkg) => pkg.id === companyGroup.defaultPricingPackageID
  )

  return (
    <div>
      <Card>
        <Title>{t('companies.settings.title')}</Title>
        {error && <Alert message={formatError(error)} type="error" showIcon />}

        <SettingsForm
          companyGroup={props.companyGroup}
          pricingPackages={props.pricingPackages.pricingPackages}
          onSubmit={handleSubmit}
        />
      </Card>

      <Card>
        <Title>{t('companies.settings.advanced.title')}</Title>

        <p>{t('companies.settings.advanced.intro')}</p>

        <AdvancedSettingsForm
          settingsEnabled={props.companyGroup.companySettingsEnabled}
          featureTypes={currentPricingPackage?.featureTypes ?? []}
          isCompanyGroup={true}
          onSubmit={handleAdvanceSettingsSubmit}
        />
      </Card>
    </div>
  )
}
