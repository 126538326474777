import SalaryType, { SalaryTypeMutableFields } from '../model/salaryType'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchSalaryTypes(companyID: string): Promise<RequestResponse<SalaryType[]>> {
  return secureRequest('GET', url('/v2/salaryTypes', { companyID }))
}

export function postSalaryType(
  companyID: string,
  salaryType: SalaryTypeMutableFields
): Promise<RequestResponse<SalaryType>> {
  return secureRequest('POST', url('/v2/salaryTypes'), {
    body: {
      companyID: companyID,
      title: salaryType.title,
      supplements: salaryType.supplements,
      class: salaryType.class,
      includeInPensionBasis: salaryType.includeInPensionBasis,
      includeInVacationBasis: salaryType.includeInVacationBasis,
      includeInShFritvalgBasis: salaryType.includeInShFritvalgBasis,
    },
  })
}

export function putSalaryType(salaryType: SalaryTypeMutableFields): Promise<RequestResponse<SalaryType>> {
  return secureRequest('PUT', url('/v2/salaryTypes/' + salaryType.id), {
    body: {
      title: salaryType.title,
      supplements: salaryType.supplements,
      class: salaryType.class,
      includeInPensionBasis: salaryType.includeInPensionBasis,
      includeInVacationBasis: salaryType.includeInVacationBasis,
      includeInShFritvalgBasis: salaryType.includeInShFritvalgBasis,
    },
  })
}

export function delSalaryType(salaryTypeID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/salaryTypes/' + salaryTypeID))
}

export function patchSalaryType(salaryTypeID: string): Promise<RequestResponse<SalaryType>> {
  return secureRequest('PATCH', url('/v2/salaryTypes/' + salaryTypeID), {
    body: {
      action: 'Reactivate',
    },
  })
}
