import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { ReimbursementVoucherFieldsUpdate, ReimbursementVoucherUpdate } from '../../api/reimbursement-vouchers'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import ExpenseCategory from '../../model/expenseCategory'
import LeaveType from '../../model/leaveType'
import Project from '../../model/project'
import ReimbursementVoucher from '../../model/reimbursementVoucher'
import { AlertReducer } from '../../reducers/alerts'
import { CarAllowanceReducer } from '../../reducers/carAllowances'
import { OneTimePayReducer } from '../../reducers/oneTimePays'
import { ReimbursementVoucherReducer } from '../../reducers/reimbursementVouchers'
import { SalaryRegistrationReducer } from '../../reducers/salaryRegistrations'
import { SwipeReducer } from '../../reducers/swipes'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { UserCompanyReducer } from '../../reducers/userCompanies'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import EmployeeFilter, { FilterContainer, filterEmployee } from '../elements/EmployeeFilter'
import { Col, Row } from '../elements/grid'
import Switch from '../elements/switch'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import CarAllowancesCard from './CarAllowancesCard'
import LeaveRegistrationsCard from './LeaveRegistrationsCard'
import OneTimePaysCard from './OneTimePaysCard'
import ReimbursementVouchersCard from './ReimbursementVouchersCard'
import SalaryRegistrationsCard from './SalaryRegistrationsCard'
import SwipesCard from './SwipesCard'
import TimeRegistrationsCard from './TimeRegistrationsCard'

import './ApproveTab.css'

type Props = {
  alerts: AlertReducer
  company: Company
  companyUser?: CompanyUser
  carAllowances: CarAllowanceReducer
  timeRegistrations: TimeRegistrationReducer
  oneTimePays: OneTimePayReducer
  reimbursementVouchers: ReimbursementVoucherReducer
  salaryRegistrations: SalaryRegistrationReducer
  swipes: SwipeReducer
  userCompanies: UserCompanyReducer

  companyUsers: List<CompanyUser>
  costCenters: List<CostCenter>
  employees: List<Employee>
  expenseCategories: List<ExpenseCategory>
  departments: List<Department>
  leaveTypes: List<LeaveType>
  projects: List<Project>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  approveTimeRegistrations: (ids: string[]) => void
  approveCarAllowances: (ids: string[]) => void
  approveOneTimePays: (ids: string[]) => void
  approveSwipes: (ids: string[]) => void
  approveSalaryRegistrations: (ids: string[]) => void
  updateUserCompany: (companyUser: CompanyUser) => Promise<CompanyUser | void>

  updateReimbursementVoucher: (o: ReimbursementVoucherUpdate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (o: ReimbursementVoucherFieldsUpdate) => Promise<ReimbursementVoucher | void>
  approveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  unapproveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  draftReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
}

export default function ApproveTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [updating, setUpdating] = useState(false)
  const [notificationEnabled, setNotificationEnabled] = useState(
    () =>
      !!props.companyUser &&
      (props.companyUser.notificationPayRollDepartmentApproval1 ||
        props.companyUser.notificationPayRollDepartmentApproval2)
  )
  const [filter, setFilter] = useState<FilterContainer>({ searchQuery: '' })
  const { userCompanies } = props

  useEffect(() => {
    regularComponentDidUpdate(userCompanies.error, error, setError)
  }, [userCompanies, error])

  const toggleNotification = (enable: boolean) => {
    if (!props.companyUser) {
      return
    }
    if (updating) {
      return
    }
    setUpdating(true)
    const companyUser = {
      ...props.companyUser,
      notificationPayRollDepartmentApproval1: enable,
      notificationPayRollDepartmentApproval2: enable,
    }
    props.updateUserCompany(companyUser).then(() => {
      setUpdating(false)
      setNotificationEnabled(enable)
    })
  }

  const employeeMap = new Map<string, Employee>()
  props.employees
    .filter((employee) => filterEmployee(employee, filter))
    .forEach((employee) => employeeMap.set(employee.id, employee))

  return (
    <div className="approve-tab">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <TitleMenu
        style={{
          textAlign: 'right',
        }}
      >
        {props.companyUser && (
          <Row style={{ marginRight: 0, marginLeft: 0 }}>
            <span>
              <label>
                <Switch
                  checked={notificationEnabled}
                  onChange={() => toggleNotification(!notificationEnabled)}
                  style={{ marginRight: '10px' }}
                />
                {t('approve_tab.notification_toggle')}
              </label>
            </span>
          </Row>
        )}
      </TitleMenu>
      <Title>{t('approve_tab.title')}</Title>
      <Row>
        <Col span={24}>
          <EmployeeFilter
            departments={props.departments}
            companyUser={props.companyUser}
            onFilterChange={(filter) => setFilter(filter)}
          />
        </Col>
      </Row>
      <TimeRegistrationsCard
        addAlert={props.addAlert}
        companyUser={props.companyUser}
        timeRegistrations={props.timeRegistrations}
        employees={employeeMap}
        departments={props.departments}
        companyUsers={props.companyUsers}
        projects={props.projects}
        approveTimeRegistrations={props.approveTimeRegistrations}
      />
      <LeaveRegistrationsCard
        addAlert={props.addAlert}
        companyUser={props.companyUser}
        timeRegistrations={props.timeRegistrations}
        employees={employeeMap}
        departments={props.departments}
        companyUsers={props.companyUsers}
        leaveTypes={props.leaveTypes}
        approveTimeRegistrations={props.approveTimeRegistrations}
      />
      <CarAllowancesCard
        addAlert={props.addAlert}
        companyUser={props.companyUser}
        carAllowances={props.carAllowances}
        employees={employeeMap}
        departments={props.departments}
        companyUsers={props.companyUsers}
        approveCarAllowances={props.approveCarAllowances}
      />
      <SalaryRegistrationsCard
        addAlert={props.addAlert}
        companyUser={props.companyUser}
        salaryRegistrations={props.salaryRegistrations}
        employees={employeeMap}
        departments={props.departments}
        companyUsers={props.companyUsers}
        approveSalaryRegistrations={props.approveSalaryRegistrations}
      />
      <OneTimePaysCard
        addAlert={props.addAlert}
        companyUser={props.companyUser}
        oneTimePays={props.oneTimePays}
        employees={employeeMap}
        departments={props.departments}
        companyUsers={props.companyUsers}
        approveOneTimePays={props.approveOneTimePays}
      />
      <ReimbursementVouchersCard
        addAlert={props.addAlert}
        company={props.company}
        companyUsers={props.companyUsers}
        companyUser={props.companyUser}
        reimbursementVouchers={props.reimbursementVouchers}
        employees={props.employees}
        employeeMaps={employeeMap}
        expenseCategories={props.expenseCategories}
        departments={props.departments}
        costCenters={props.costCenters}
        updateReimbursementVoucher={props.updateReimbursementVoucher}
        updateReimbursementVoucherFields={props.updateReimbursementVoucherFields}
        approveReimbursementVouchers={props.approveReimbursementVouchers}
        unapproveReimbursementVouchers={props.unapproveReimbursementVouchers}
        draftReimbursementVouchers={props.draftReimbursementVouchers}
      />
      <SwipesCard
        addAlert={props.addAlert}
        companyUser={props.companyUser}
        swipes={props.swipes}
        employees={employeeMap}
        departments={props.departments}
        companyUsers={props.companyUsers}
        approveSwipes={props.approveSwipes}
      />
      <p style={{ marginTop: '50px' }}>{t('approve_tab.empty')}</p>
    </div>
  )
}
