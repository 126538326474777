import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import { SalaryRegistrationMutableFields } from '../../../model/salaryRegistration'
import SalaryType from '../../../model/salaryType'
import { SalaryRegistrationReducer } from '../../../reducers/salaryRegistrations'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import DetailedSalaryRegistrationEditForm, { ResultFields } from './DetailedSalaryRegistrationEditForm'

type Props = {
  visible: boolean
  company: Company
  employee: Employee
  costCenters: List<CostCenter>
  departments: List<Department>
  salaryRegistrationID?: string
  salaryRegistrations: SalaryRegistrationReducer
  salaryTypes: List<SalaryType>

  createSalaryRegistration: (registration: SalaryRegistrationMutableFields) => void
  updateSalaryRegistration: (registration: SalaryRegistrationMutableFields) => void
}

export default function DetailedSalaryRegistrationEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { salaryRegistrations, visible } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, salaryRegistrations.error, error, setError)
  }, [visible, salaryRegistrations, error])

  const handleSubmit = (values: ResultFields) => {
    const salaryRegistration: SalaryRegistrationMutableFields = {
      employeeID: props.employee.id,
      salaryTypeID: values.salaryTypeID,
      date: values.date,
      quantity: values.quantity,
      costCenterID: values.costCenterID,
      note: values.note,
      approved: true,
    }
    if (props.salaryRegistrationID) {
      salaryRegistration.id = props.salaryRegistrationID
      props.updateSalaryRegistration(salaryRegistration)
    } else {
      props.createSalaryRegistration(salaryRegistration)
    }
  }

  return (
    <Card>
      <Subtitle>
        {props.salaryRegistrationID
          ? t('salary_registrations_tab.detailed.edit.title.edit')
          : t('salary_registrations_tab.detailed.edit.title.create')}
      </Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <DetailedSalaryRegistrationEditForm
        company={props.company}
        employee={props.employee}
        costCenters={props.costCenters}
        departments={props.departments}
        salaryRegistrationID={props.salaryRegistrationID}
        salaryRegistrations={props.salaryRegistrations}
        salaryTypes={props.salaryTypes}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
