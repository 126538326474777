import React, { ReactElement } from 'react'

import Company from '../../../model/company'
import { decorateFieldSignature, getFieldValueSignature } from '../../../utils/form-utils'
import { forceParseInputNumber, formatCurrency } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Col from '../../elements/grid/col'
import Input from '../../elements/input'
import Switch from '../../elements/switch'

type OneTimePayAmountFields = {
  useRate: boolean
  amount?: string
  rate?: string
  units?: string
}

type Props<Fields extends OneTimePayAmountFields> = {
  company: Company
  description: string
  editing: boolean
  disableRate: boolean
  disabled: boolean
  required?: boolean

  decorateField: decorateFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
}

export default function OneTimePayAmount<Fields extends OneTimePayAmountFields>(
  props: Props<Fields>
): ReactElement | null {
  const { decorateField, getFieldValue, required = true } = props

  return (
    <>
      {!getFieldValue('useRate') && (
        <Col span={24}>
          {decorateField('amount', {
            title: t('otp_form.amount.title', { description: props.description }),
            placeholder: props.description.charAt(0).toUpperCase() + props.description.slice(1),
            validate: (val) => {
              if (props.disabled) {
                return null
              }
              if (!required && !val) {
                return null
              }
              if (!val) {
                return t('otp_form.amount.required', { description: props.description })
              }
              if (forceParseInputNumber(val) < 0.0) {
                return t('otp_form.amount.not_negative', { description: props.description })
              }
              if (typeof val === 'string' && !val.match(/^([0-9,.]+)/)) {
                return t('otp_form.amount.invalid', { description: props.description })
              }
              return null
            },
            suffix: t('otp_form.amount.suffix'),
          })(<Input disabled={!props.editing || props.disabled} />)}
        </Col>
      )}
      {getFieldValue('useRate') && (
        <div className="ant-subrow">
          <Col span={12}>
            {decorateField('rate', {
              title: t('otp_form.amount.rate'),
              placeholder: t('otp_form.amount.rate'),
              validate: (val) => {
                if (props.disabled) {
                  return null
                }
                if (!val) {
                  return t('otp_form.amount.rate.required')
                }
                if (forceParseInputNumber(val) < 0.0) {
                  return t('otp_form.amount.rate.not_negative')
                }
                if (!val.match(/^([0-9,.]+)/)) {
                  return t('otp_form.amount.rate.invalid')
                }
                return null
              },
              suffix: t('otp_form.amount.rate.suffix'),
            })(<Input disabled={!props.editing || props.disabled} />)}
          </Col>
          <Col span={12}>
            {decorateField('units', {
              title: t('otp_form.amount.units'),
              placeholder: t('otp_form.amount.units'),
              validate: (val) => {
                if (props.disabled) {
                  return null
                }
                if (!val) {
                  return t('otp_form.amount.units.required')
                }
                if (forceParseInputNumber(val) < 0.0) {
                  return t('otp_form.amount.units.not_negative')
                }
                if (!val.match(/^([0-9,.]+)/)) {
                  return t('otp_form.amount.units.invalid')
                }
                return null
              },
            })(<Input disabled={!props.editing || props.disabled} />)}
          </Col>
          <Col span={24}>
            {t('otp_form.amount.calculated_amount', {
              amount: formatCurrency(
                forceParseInputNumber(getFieldValue('rate')) * forceParseInputNumber(getFieldValue('units')),
                2
              ),
            })}
          </Col>
        </div>
      )}
      {props.editing &&
        !props.disableRate &&
        props.company.settingsEnabled.some((setting) => setting === 'AllowOTPUnitsAndRate') && (
          <Col span={24}>
            <div className="ant-switch-wrapper">
              {decorateField('useRate', {
                title: t('otp_form.amount.use_rate'),
                valueOnChecked: true,
                skipLabel: true,
                skipWrapper: true,
                noBlur: true,
              })(<Switch disabled={!props.editing || props.disabled} />)}
              <span className="ant-switch-text">{t('otp_form.amount.use_rate')}</span>
            </div>
          </Col>
        )}
    </>
  )
}
