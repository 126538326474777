import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import Project from '../../../model/project'
import SalaryType from '../../../model/salaryType'
import { TimeRegistrationClass } from '../../../model/timeRegistration'
import { DateFormat, DateTimeFormat } from '../../../model/types'
import { TimeRegistrationReducer } from '../../../reducers/timeRegistrations'
import { formatAPIDate, formatDateTime, getDate, isTimeBefore } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import {
  forceParseInputNumber,
  formatCurrency,
  formatInputAsMinutes,
  formatInputNumber,
  formatMinutesAsTime,
  parseInputNumber,
  parseTimeAsMinutes,
} from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { projectFilter } from '../../../utils/projects-utils'
import { t, translateGroupTitle } from '../../../utils/translation-utils'
import Select from '../../antd/select'
import Button from '../../elements/button'
import DatePicker from '../../elements/date-picker'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import Switch from '../../elements/switch'
import RegistrationCostCenter from '../../form-elements/RegistrationCostCenter'
import SwitchWrapper from '../../form-elements/SwitchWrapper'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type BaseProps = {
  company: Company
  employee: Employee
  timeRegistrationID?: string
  suggestedDate?: Date
  timeRegistrations: TimeRegistrationReducer
  costCenters: List<CostCenter>
  departments: List<Department>
  includeDateRange?: [Date, Date] // from -> to
}

type HoursProps = BaseProps & {
  mode: 'Hours'
  salaryTypes: List<SalaryType>
  projects: List<Project>
}

type ProjectProps = BaseProps & {
  mode: 'Project' | 'Work Hours'
  projects: List<Project>
}

type OtherProps = BaseProps & {
  mode: 'FlexTime' | 'Overtime'
}

type Fields = {
  class: TimeRegistrationClass
  date: Date
  costCenterID?: string
  startTime?: string
  endTime?: string
  breakMinutes?: string
  hours?: string
  minutes?: string
  useMinutes: boolean
  isUsing: boolean
  salaryTypeID?: string
  projectID?: string
  note?: string
  createdAt?: DateTimeFormat
}

export type ResultFields = {
  class: TimeRegistrationClass
  date: DateFormat
  costCenterID?: string
  start?: number
  end?: number
  hours?: number
  minutes?: number
  salaryTypeID?: string
  projectID?: string
  note?: string
}

function DetailedTimeRegistrationEditForm(
  props: (HoursProps | OtherProps | ProjectProps) & FormComponentProps<Fields, ResultFields>
): ReactElement | null {
  const doTimeBasedRegistration = (): boolean => {
    if (props.mode === 'FlexTime' || props.mode === 'Overtime') {
      return false
    }
    if (!!props.getFieldValue('startTime') && !!props.getFieldValue('endTime')) {
      return true // the values are set
    }
    if (props.mode === 'Work Hours') {
      return props.company.settingsEnabled.some((setting) => setting === 'RegisterWorkHoursStartEnd')
    }
    if (props.company.settingsEnabled.some((setting) => setting === 'RegisterTimeRegistrationStartEnd')) {
      return true // company setting requires it
    }
    if (props.mode !== 'Hours') {
      return false
    }
    let hasTimeSupplements = false
    if (props.employee.activeContract && props.employee.activeContract.remuneration) {
      props.employee.activeContract.remuneration.salary.filter((salary) => {
        const salaryType = props.salaryTypes.find((type) => type.id === salary.salaryTypeID)
        if (salaryType && salaryType.supplements && salaryType.supplements.length > 0) {
          hasTimeSupplements = true
          return true
        }
        return false
      })
    }
    return hasTimeSupplements
  }

  const { decorateField, getFieldValue } = props

  const employment = props.employee.activeEmployment
  if (!employment) {
    return null
  }
  const contract = props.employee.activeContract
  const knownTypes: string[] = []
  const types =
    (props.mode === 'Hours' &&
      contract &&
      contract.remuneration &&
      contract.remuneration.salary
        .filter((salary) => {
          if (knownTypes.indexOf(salary.salaryTypeID) !== -1) {
            return false
          }
          const salaryType = props.salaryTypes.find((type) => type.id === salary.salaryTypeID)
          if (!salaryType) {
            return false
          }
          knownTypes.push(salary.salaryTypeID)
          return salaryType.class === 'Hourly'
        })
        .sort((a, b) => {
          const titleA = translateGroupTitle(a)
          const titleB = translateGroupTitle(b)
          if (titleA === titleB) {
            return a.rate - b.rate
          }
          return titleA.localeCompare(titleB)
        })) ||
    []
  const timeBasedRegistration = doTimeBasedRegistration()
  const includeProjects =
    props.mode === 'Project' ||
    ((props.mode === 'Hours' || props.mode === 'Work Hours') &&
      props.projects.some((p) => projectFilter(p, props.employee)))

  const projects = includeProjects
    ? props.projects.filter((p) => projectFilter(p, props.employee, getFieldValue('date'))).toArray()
    : []

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('date', {
            placeholder: t('time_registration_tab.detailed.edit.form.date'),
            validate: (val) => {
              if (!val) {
                return t('time_registration_tab.detailed.edit.form.date.required')
              }
              if (isTimeBefore(val, getDate(employment.startDate), 'day')) {
                return t('time_registration_tab.detailed.edit.form.date.within_employment')
              }
              return null
            },
          })(
            <DatePicker
              allowClear={false}
              tabIndex={2}
              style={{ width: '100%' }}
              includeDateRange={props.includeDateRange}
            />
          )}
        </Col>
      </Row>
      {timeBasedRegistration && (
        <Row>
          <Col span={12}>
            {decorateField('startTime', {
              placeholder: t('time_registration_tab.detailed.edit.form.start'),
              validate: (val) => {
                if (!val) {
                  return t('time_registration_tab.detailed.edit.form.start.required')
                }
                if (!val.match(/^([0-9]{4}|[0-9:]{5})$/)) {
                  return t('time_registration_tab.detailed.edit.form.start.invalid')
                }
                const endTime = getFieldValue('endTime')
                if (endTime && parseTimeAsMinutes(val) >= parseTimeAsMinutes(endTime)) {
                  return t('time_registration_tab.detailed.edit.form.start.before_end')
                }
                return null
              },
            })(<Input />)}
            <span className="ant-form-extra">{t('time_registration_tab.detailed.edit.form.start.format_example')}</span>
          </Col>
          <Col span={12}>
            {decorateField('endTime', {
              placeholder: t('time_registration_tab.detailed.edit.form.end'),
              validate: (val) => {
                if (!val) {
                  return t('time_registration_tab.detailed.edit.form.end.required')
                }
                if (!val.match(/^([0-9]{4}|[0-9:]{5})$/)) {
                  return t('time_registration_tab.detailed.edit.form.end.invalid')
                }
                const startTime = getFieldValue('startTime')
                if (startTime && parseTimeAsMinutes(startTime) >= parseTimeAsMinutes(val)) {
                  return t('time_registration_tab.detailed.edit.form.end.after_start')
                }
                return null
              },
            })(<Input />)}
          </Col>
        </Row>
      )}
      {timeBasedRegistration && (
        <Row>
          <Col span={24}>
            {decorateField('breakMinutes', {
              placeholder: t('time_registration_tab.detailed.edit.form.break_minutes'),
              suffix: t('time_registration_tab.detailed.edit.form.break_minutes.suffix'),
              validate: (val) => {
                if (!val) {
                  return null
                }
                if (!val.match(/^([0-9,.]+)/)) {
                  return t('time_registration_tab.detailed.edit.form.break_minutes.invalid')
                }
                const num = forceParseInputNumber(val)
                if (num % 1 !== 0) {
                  return t('time_registration_tab.detailed.edit.form.break_minutes.must_be_integer')
                }
                const startTime = getFieldValue('startTime')
                const endTime = getFieldValue('endTime')
                if (startTime && endTime && parseTimeAsMinutes(endTime) - parseTimeAsMinutes(startTime) <= num) {
                  return t('time_registration_tab.detailed.edit.form.break_minutes.longer_before_total')
                }
                return null
              },
            })(<Input />)}
          </Col>
        </Row>
      )}
      {!timeBasedRegistration && (
        <Row>
          <Col span={props.mode === 'FlexTime' || props.mode === 'Overtime' ? 12 : 24}>
            {!getFieldValue('useMinutes') && (
              <>
                {decorateField('hours', {
                  placeholder: t('time_registration_tab.detailed.edit.form.hours'),
                  suffix: t('time_registration_tab.detailed.edit.form.hours.suffix'),
                  validate: (val) => (!val ? t('time_registration_tab.detailed.edit.form.hours.required') : null),
                })(<Input />)}
                <span className="ant-form-extra">{t('time_registration_tab.detailed.edit.form.hours.note')}</span>
              </>
            )}
            {getFieldValue('useMinutes') && (
              <>
                {decorateField('minutes', {
                  placeholder: t('time_registration_tab.detailed.edit.form.minutes'),
                  suffix: t('time_registration_tab.detailed.edit.form.minutes.suffix'),
                  validate: (val) => {
                    if (!val) {
                      return t('time_registration_tab.detailed.edit.form.minutes.required')
                    }
                    if (forceParseInputNumber(val) % 1 !== 0.0) {
                      return t('time_registration_tab.detailed.edit.form.minutes.invalid')
                    }
                    return null
                  },
                })(<Input />)}
                <span className="ant-form-extra">{t('time_registration_tab.detailed.edit.form.minutes.note')}</span>
              </>
            )}
          </Col>
          {(props.mode === 'FlexTime' || props.mode === 'Overtime') && (
            <Col span={12}>
              {decorateField('isUsing', {
                title: t('time_registration_tab.detailed.edit.form.is_using'),
                valueOnChecked: true,
                noBlur: true,
              })(
                <Switch
                  checkedChildren={t('time_registration_tab.detailed.edit.form.is_using.checked')}
                  unCheckedChildren={t('time_registration_tab.detailed.edit.form.is_using.unchecked')}
                />
              )}
              <SwitchWrapper
                id={'useMinutes'}
                decorateField={decorateField}
                textStyle={{
                  marginLeft: '-10px',
                  fontWeight: 'normal',
                  fontSize: '13px',
                  color: 'var(--sally-grey-shaded)',
                }}
              >
                {t('time_registration_tab.detailed.edit.form.use_minutes')}
              </SwitchWrapper>
            </Col>
          )}
        </Row>
      )}
      {props.mode === 'Hours' && (
        <Row>
          <Col span={24}>
            {decorateField('salaryTypeID', {
              placeholder: t('time_registration_tab.detailed.edit.form.salary_type_id'),
              validate: (val) => (!val ? t('time_registration_tab.detailed.edit.form.salary_type_id.required') : null),
            })(
              <Select dropdownMatchSelectWidth={false} tabIndex={1}>
                {types.map((row) => {
                  return (
                    <Select.Option key={row.salaryTypeID} value={row.salaryTypeID}>
                      {translateGroupTitle(row)} ({formatCurrency(row.rate, 2)})
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Col>
        </Row>
      )}
      {includeProjects && (
        <Row>
          <Col span={24}>
            {decorateField('projectID', {
              placeholder: t('time_registration_tab.detailed.edit.form.project_id'),
              validate: (val) => {
                if (props.mode === 'Project' && !val) {
                  return t('time_registration_tab.detailed.edit.form.project_id.required')
                }
                return null
              },
            })(
              <Select dropdownMatchSelectWidth={false} tabIndex={1}>
                {projects.map((row) => {
                  return (
                    <Select.Option key={row.id} value={row.id}>
                      {row.name}
                    </Select.Option>
                  )
                })}
                {projects.length === 0 && (
                  <Select.Option key={''} value={''}>
                    <i>{t('time_registration_tab.detailed.edit.form.project_id.none_available')}</i>
                  </Select.Option>
                )}
              </Select>
            )}
          </Col>
        </Row>
      )}
      {props.mode !== 'Work Hours' && (
        <RegistrationCostCenter
          company={props.company}
          costCenters={props.costCenters}
          departments={props.departments}
          decorateField={decorateField}
          getFieldValue={getFieldValue}
        />
      )}
      <Row>
        <Col span={24}>
          {decorateField('note', {
            placeholder: t('time_registration_tab.detailed.edit.form.note'),
          })(<Input />)}
        </Col>
      </Row>
      {props.timeRegistrationID && (
        <Row>
          <Col span={24} className="created-at-note">
            {t('time_registration_tab.detailed.edit.form.created_at', {
              createdAt: formatDateTime(getDate(getFieldValue('createdAt'))),
            })}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.timeRegistrations.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<HoursProps | OtherProps | ProjectProps, Fields, ResultFields>({
  mapPropsToFields: (props: HoursProps | OtherProps | ProjectProps): Fields => {
    // filter to hourly salary types
    const hourlySalaries =
      (props.mode === 'Hours' &&
        props.employee.activeContract &&
        props.employee.activeContract.remuneration &&
        props.employee.activeContract.remuneration.salary.filter((salary) =>
          props.salaryTypes.some((salaryType) => salaryType.id === salary.salaryTypeID && salaryType.class === 'Hourly')
        )) ||
      []
    let firstHourlySalary = null
    if (hourlySalaries.length > 0) {
      firstHourlySalary = hourlySalaries[0]
    }
    let timeClass: TimeRegistrationClass = 'Hours'
    let salaryTypeID = firstHourlySalary ? firstHourlySalary.salaryTypeID : undefined
    if (props.mode === 'FlexTime') {
      timeClass = 'Flex'
      salaryTypeID = undefined
    }
    if (props.mode === 'Overtime') {
      timeClass = 'Overtime'
      salaryTypeID = undefined
    }
    if (props.mode === 'Project') {
      timeClass = 'Project Hours'
      salaryTypeID = undefined
    }
    if (props.mode === 'Work Hours') {
      timeClass = 'Work Hours'
      salaryTypeID = undefined
    }
    const fields: Fields = {
      class: timeClass,
      date: props.suggestedDate || getDate(),
      salaryTypeID,
      costCenterID: undefined,
      isUsing: true,
      useMinutes: false,
    }
    const timeRegistration = props.timeRegistrations.timeRegistrations.find(
      (timeRegistration) => timeRegistration.id === props.timeRegistrationID
    )
    if (timeRegistration) {
      fields.class = timeRegistration.class
      fields.date = getDate(timeRegistration.date)
      fields.costCenterID = timeRegistration.costCenterID
      if (timeRegistration.start !== undefined) {
        fields.startTime = formatMinutesAsTime(timeRegistration.start)
        fields.breakMinutes = '0'
        if (timeRegistration.end !== undefined) {
          fields.endTime = formatMinutesAsTime(timeRegistration.end)
          fields.breakMinutes = formatInputNumber(
            ((timeRegistration.end - timeRegistration.start) / 60 -
              (timeRegistration.hours ? timeRegistration.hours : 0)) *
              60,
            0
          )
        } else {
          fields.endTime = formatMinutesAsTime(
            timeRegistration.start + (timeRegistration.hours ? timeRegistration.hours * 60 : 0)
          )
        }
      } else if (props.mode === 'FlexTime' || props.mode === 'Overtime') {
        fields.minutes = formatInputNumber(timeRegistration.minutes ?? 0, 0)
        const hours = timeRegistration.minutes ? timeRegistration.minutes / 60 : 0
        fields.hours = formatInputNumber(hours, 2)
        fields.isUsing = !!fields.hours && hours > 0
        if (hours && hours < 0) {
          fields.hours = formatInputNumber(-1 * hours, 2)
        }
      } else {
        fields.hours = formatInputNumber(timeRegistration.hours)
      }
      fields.salaryTypeID = timeRegistration.salaryTypeID
      fields.projectID = timeRegistration.projectID
      fields.note = timeRegistration.note
      fields.createdAt = timeRegistration.createdAt
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'startTime':
      case 'endTime':
        if (options.trigger === 'onBlur') {
          setByPath(values, key, formatInputAsMinutes(val as string))
        } else {
          setByPath(values, key, (val as string).replace(/[ ]/g, ''))
        }
        break
      case 'hours':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values, props) => {
    const result: ResultFields = {
      class: values.class,
      date: formatAPIDate(values.date),
      costCenterID: values.costCenterID,
      salaryTypeID: values.salaryTypeID,
      projectID: values.projectID,
      note: values.note,
    }
    if (values.startTime && values.endTime) {
      result.start = parseTimeAsMinutes(values.startTime)
      result.end = parseTimeAsMinutes(values.endTime)
      result.hours = (result.end - result.start - forceParseInputNumber(values.breakMinutes)) / 60
    } else {
      if (props.mode === 'FlexTime' || props.mode === 'Overtime') {
        if (values.useMinutes) {
          result.minutes = forceParseInputNumber(values.minutes)
        } else {
          // round to nearest minute
          result.minutes = Math.round(forceParseInputNumber(values.hours) * 60)
        }
        if (!values.isUsing) {
          result.minutes = -result.minutes
        }
        result.hours = undefined
      } else if (values.hours !== null) {
        result.hours = forceParseInputNumber(values.hours)
      }
    }
    return result
  },
})(DetailedTimeRegistrationEditForm)
