import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Swipe from '../model/swipe'
import { ReducerAction } from '../utils/reducer-utils'

export interface SwipeReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  swipes: List<Swipe>
  error: Error | null
}

const Params = Record<SwipeReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  swipes: List<Swipe>(),
  error: null,
})

export interface SwipeAction extends ReducerAction {
  companyID?: string
  swipeID?: string
  swipes?: Swipe[]
  swipe?: Swipe
}

const comparator = (a: Swipe, b: Swipe) => {
  return b.createdAt.localeCompare(a.createdAt, 'da-dk')
}

export default (state: Record<SwipeReducer> = Params(), action: SwipeAction = { type: '' }): Record<SwipeReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.SWIPE_LOADING:
      return Params({
        loading: true,
        companyID: state.get('companyID'),
      })
    case ActionTypes.SWIPE_LOADED:
    case ActionTypes.SWIPE_LOADED_PARTIAL:
      return Params({
        loaded: true,
        loading: action.type === ActionTypes.SWIPE_LOADED_PARTIAL,
        swipes: state.get('swipes').concat(List(action.swipes)).sort(comparator),
        companyID: state.get('companyID'),
      })
    case ActionTypes.SWIPE_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error || null,
      })
    case ActionTypes.SWIPE_ADDED:
    case ActionTypes.SWIPE_UPDATED:
      if (!action.swipe) {
        return state
      }
      idx = state.get('swipes').findIndex((item) => item.id === action.swipeID)
      if (idx === -1) {
        return state.set('saving', false).set('swipes', state.get('swipes').push(action.swipe))
      }
      return state.set('saving', false).set('swipes', state.get('swipes').set(idx, action.swipe))
    case ActionTypes.SWIPE_APPROVING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SWIPE_APPROVED:
      if (action.swipes) {
        return state.set('saving', false).set(
          'swipes',
          state.get('swipes').map((swipe) => {
            if (!action.swipes) {
              return swipe
            }
            const newSwipe = action.swipes.find((newSwipe) => newSwipe.id === swipe.id)
            if (!newSwipe) {
              return swipe
            }
            return newSwipe
          })
        )
      }
      return state.set('saving', false)
    case ActionTypes.SWIPE_APPROVE_FAILED:
      return state.set('saving', false).set('error', action.error || null)
    case ActionTypes.SWIPE_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.SWIPE_DELETED:
      idx = state.get('swipes').findIndex((item) => item.id === action.swipeID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('swipes', state.get('swipes').delete(idx))
    case ActionTypes.SWIPE_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
