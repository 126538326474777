import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { AccountPlanUpdate } from '../../api/accounting-integration'
import {
  CompanyAccountingIntegrationType,
  postCompanyAccountingIntegrationReAuth,
} from '../../api/company-accounting-integration-setup'
import Company from '../../model/company'
import Warning from '../../model/warning'
import { AlertReducer } from '../../reducers/alerts'
import { AvailableAccountingIntegrationReducer } from '../../reducers/availableAccountingIntegrations'
import { CompanyAccountingIntegrationReducer } from '../../reducers/companyAccountingIntegration'
import { CompanyAccountPlanReducer } from '../../reducers/companyAccountPlans'
import { CostCenterReducer } from '../../reducers/costCenters'
import { paths } from '../../routes'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError, isRequestError } from '../../utils/error-utils'
import { formatAccountingIntegration } from '../../utils/format-utils'
import { companySelected } from '../../utils/hooks-utils'
import { formatLoadingText, formatSavingText } from '../../utils/loading-utils'
import { logError } from '../../utils/log-utils'
import { t, tx } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import LoadingOverlay from '../widgets/LoadingOverlay'
import AccountingForm, { AccountingFormFields } from './AccountingForm'

type Props = {
  alerts: AlertReducer
  company: Company
  availableAccountingIntegrations: AvailableAccountingIntegrationReducer
  companyAccountingIntegration: CompanyAccountingIntegrationReducer
  companyAccountPlans: CompanyAccountPlanReducer
  costCenters: CostCenterReducer
  warnings: List<Warning>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getAvailableAccountingIntegrations: () => void
  getCompanyAccountingIntegration: () => void
  getCompanyAccountPlans: () => void
  getCostCenters: () => void
  updateCompanyAccountPlan: (plan: AccountPlanUpdate) => void
  syncCompanyAccountingIntegration: () => void
}

export default function Accounting(props: Props): ReactElement | null {
  const [costCenterID, setCostCenterID] = useState<string>('')
  const [error, setError] = useState<Error | null>(null)
  const [hasSynced, setHasSynced] = useState(false)

  const {
    availableAccountingIntegrations,
    getAvailableAccountingIntegrations,
    companyAccountingIntegration,
    getCompanyAccountingIntegration,
    companyAccountPlans,
    getCompanyAccountPlans,
    costCenters,
    getCostCenters,
  } = props
  useEffect(() => {
    if (!availableAccountingIntegrations.loaded && !availableAccountingIntegrations.loading) {
      getAvailableAccountingIntegrations()
    }
    if (!companyAccountingIntegration.loading && !companyAccountingIntegration.loaded) {
      getCompanyAccountingIntegration()
    }
    if (!companyAccountPlans.loading && !companyAccountPlans.loaded && !companyAccountPlans.error) {
      getCompanyAccountPlans()
    }
    if (!costCenters.loaded && !costCenters.loading) {
      getCostCenters()
    }
  }, [
    availableAccountingIntegrations,
    getAvailableAccountingIntegrations,
    companyAccountingIntegration,
    getCompanyAccountingIntegration,
    companyAccountPlans,
    getCompanyAccountPlans,
    costCenters,
    getCostCenters,
  ])

  const { addAlert, company } = props
  const previousCompanyAccountingIntegration = usePrevious(companyAccountingIntegration)
  useEffect(() => {
    if (
      previousCompanyAccountingIntegration &&
      previousCompanyAccountingIntegration.saving &&
      !companyAccountingIntegration.saving
    ) {
      if (!companyAccountingIntegration.error) {
        addAlert('success', t('accounting_tab.alert.success', { name: company.name }), { timeout: 5 })
        window.scrollTo(0, 0)
      }
    }
  }, [previousCompanyAccountingIntegration, companyAccountingIntegration, addAlert, company])

  const previousCompanyAccountPlans = usePrevious(companyAccountPlans)
  useEffect(() => {
    if (previousCompanyAccountPlans && previousCompanyAccountPlans.saving && !companyAccountPlans.saving) {
      if (!companyAccountPlans.error) {
        addAlert('success', t('accounting_tab.alert.success', { name: company.name }), { timeout: 5 })
        window.scrollTo(0, 0)
      }
    }
  }, [previousCompanyAccountPlans, companyAccountPlans, addAlert, company])

  useEffect(() => {
    regularComponentDidUpdate(companyAccountingIntegration.error, error, setError)
  }, [companyAccountingIntegration, error])

  const getDisplayName = (type?: CompanyAccountingIntegrationType): string => {
    const integration = props.availableAccountingIntegrations.availableAccountingIntegrations.find(
      (integration) => integration.type === type
    )
    if (integration) {
      return integration.displayName
    }
    return formatAccountingIntegration(type || 'None')
  }

  const handleSync = (e: React.MouseEvent) => {
    e.preventDefault()

    if (hasSynced) {
      return
    }
    setHasSynced(true)
    props.syncCompanyAccountingIntegration()
  }

  const handleCostCenterChange = (costCenterID: string) => {
    setCostCenterID(costCenterID)
  }

  const hasAccounting = (): boolean => {
    return props.companyAccountingIntegration.accountMapping.size > 0
  }
  const hasIntegration = (): boolean => {
    return props.companyAccountingIntegration.integrationType !== 'None'
  }
  const canSync = (): boolean => {
    return hasIntegration()
  }

  const handleSubmit = (values: AccountingFormFields) => {
    let accountMapping = values.accountMapping
    const noIntegration = !hasIntegration()
    accountMapping = accountMapping.map((account) => {
      if (noIntegration) {
        account.accountNumber = account.externalID
      } else if (account.accountNumber === '-') {
        account.accountNumber = undefined // unset, rely on externalID
      }
      return account
    })
    props.updateCompanyAccountPlan({
      bookingRule: values.bookingRule,
      costCenterID: costCenterID ? costCenterID : undefined,
      accountMapping: accountMapping,
    })
  }

  const reAuthIntegration = () => {
    postCompanyAccountingIntegrationReAuth(company.id)
      .then((res) => {
        if (res.data.next === 'NeedAuth') {
          companySelected(company.id)
          document.location = res.data.authURL || ''
        } else {
          // not implemented; should not happen
          logError('Unimplemented path in accounting re auth')
          setError(new Error('Not implemented'))
        }
      })
      .catch((e) => {
        if (isRequestError(e)) {
          setError(e)
        }
      })
  }

  if (!props.availableAccountingIntegrations.loaded || !props.companyAccountingIntegration.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            {
              loading: !props.availableAccountingIntegrations.loaded,
              text: t('loading.reducer.available_accounting_integrations'),
            },
            {
              loading: !props.companyAccountingIntegration.loaded,
              text: t('loading.reducer.company_accounting_integration'),
            },
          ])}
        />
      </div>
    )
  }

  const hasDineroOldTokenWarning = props.warnings.some((warning) => warning.warningType === 'DineroOldToken')
  const brokenIntegrationMessage = props.warnings.find(
    (warning) => warning.warningType === 'BrokenAccountingIntegration'
  )?.parameter
  const accountingIntegration = props.companyAccountingIntegration.accountingIntegration
  const hasDimensions = accountingIntegration?.hasDimensions || false
  const employeeDimensionEnabled = props.company.settingsEnabled.some(
    (setting) => setting === 'EnableEmployeeDimensions'
  )
  return (
    <div>
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      {brokenIntegrationMessage && (
        <Alert
          message={
            <span>
              {accountingIntegration?.canReAuth && (
                <Button size="large" type="secondary" regular style={{ float: 'right' }} onClick={reAuthIntegration}>
                  {t('accounting_tab.broken_integration.button')}
                </Button>
              )}
              {t('accounting_tab.broken_integration.message')}
              <br />
              {brokenIntegrationMessage}
            </span>
          }
          type="error"
          showIcon
        />
      )}
      {props.companyAccountPlans.error && (
        <Alert message={formatError(props.companyAccountPlans.error)} type="error" showIcon />
      )}
      {hasDineroOldTokenWarning && (
        <Alert
          message={
            <span>
              {tx('accounting_tab.old_dinero_token.message', {
                link: (
                  <Link to={'/' + paths.COMPANIES + '/' + company.id + '/' + paths.ACCOUNTING + '/' + paths.ADD}>
                    {t('accounting_tab.old_dinero_token.message.link')}
                  </Link>
                ),
              })}
            </span>
          }
          type="warning"
          showIcon
        />
      )}

      <Card>
        {hasAccounting() && (
          <TitleMenu
            style={{
              minWidth: '500px',
              textAlign: 'right',
            }}
          >
            <Row style={{ marginRight: 0, marginLeft: 0 }}>
              <Col span={24}>
                <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING_DIMENSIONS}>
                  <Button>
                    {hasDimensions && employeeDimensionEnabled
                      ? hasDimensions
                        ? t('accounting_tab.header.dimension.has_dimensions')
                        : t('accounting_tab.header.dimension.employee_dimensions')
                      : t('accounting_tab.header.dimension.no_dimensions')}
                  </Button>
                </Link>
                {props.companyAccountingIntegration.accountingIntegration?.hasDaybooks && (
                  <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING_DAYBOOKS}>
                    <Button>{t('accounting_tab.header.daybook')}</Button>
                  </Link>
                )}
                {/* TODO: Re-enable; disabled since some integrations would require a completely new account mapping
                props.companyAccountingIntegration.accountingIntegration?.hasOrganizations && (
                  <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING_ORGANIZATIONS}>
                    <Button>Skift virksomhed</Button>
                  </Link>
                )*/}
                {canSync() && (
                  <Button onClick={handleSync} className="gtm-refresh-sync-integration" disabled={hasSynced}>
                    {t('accounting_tab.header.sync')}
                  </Button>
                )}
                <Link to={'/' + paths.COMPANIES + '/' + company.id + '/' + paths.ACCOUNTING + '/' + paths.ADD}>
                  <Button className="gtm-change-accounting-integration">
                    {t('accounting_tab.header.add_integration')}
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row style={{ marginRight: 0, marginLeft: 0 }}>
              <Col span={24}>
                <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING + '/' + paths.SETTINGS}>
                  <Button className="gtm-settings">{t('accounting_tab.header.settings')}</Button>
                </Link>
                {accountingIntegration?.hasActiveInformation && (
                  <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING + '/' + paths.ACTIVE_INFORMATION}>
                    <Button className="gtm-active-information">{t('accounting_tab.header.active_information')}</Button>
                  </Link>
                )}
                <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING_VOUCHERS}>
                  <Button className="gtm-see-vouchers">{t('accounting_tab.header.vouchers')}</Button>
                </Link>
              </Col>
            </Row>
          </TitleMenu>
        )}
        <Title>{t('accounting_tab.title')}</Title>

        <Row style={{ width: '40%' }}>
          <Col span={12}>
            {t('accounting_tab.accounting_accounting')}:
            <br />
            <strong>
              {hasIntegration()
                ? t('accounting_tab.accounting_accounting.automatic')
                : t('accounting_tab.accounting_accounting.manual')}
            </strong>
          </Col>
          {hasIntegration() && (
            <Col span={12}>
              {t('accounting_tab.accounting_integration.intro')}:
              <br />
              <strong>
                {getDisplayName(props.companyAccountingIntegration.accountingIntegration?.integrationType)}
              </strong>
            </Col>
          )}
        </Row>

        {!hasAccounting() && (
          <Row>
            <Col span={24}>
              <Link to={'/' + paths.COMPANIES + '/' + company.id + '/' + paths.ACCOUNTING + '/' + paths.ADD}>
                <Button size="large" type="secondary" className="gtm-setup-accounting-integration">
                  {t('accounting_tab.add_accounting_integration')}
                </Button>
              </Link>
            </Col>
          </Row>
        )}

        {hasAccounting() && accountingIntegration && (
          <div className="companies-single-form">
            <AccountingForm
              key={costCenterID} // Force re-creation on cost center change
              costCenterAccounting={props.company.costCenterAccounting}
              integrationType={accountingIntegration.integrationType}
              costCenterID={costCenterID}
              displayName={accountingIntegration.displayName}
              bookingRule={accountingIntegration.bookingRule}
              availableAccountingIntegrations={props.availableAccountingIntegrations.availableAccountingIntegrations}
              accountMapping={accountingIntegration.accountMapping}
              companyAccountPlans={props.companyAccountPlans}
              costCenters={props.costCenters.costCenters}
              getCompanyAccountPlans={props.getCompanyAccountPlans}
              onCostCenterChange={handleCostCenterChange}
              onSubmit={handleSubmit}
            />
            {(props.companyAccountingIntegration.saving || props.companyAccountPlans.saving) && (
              <LoadingOverlay
                text={formatSavingText([
                  {
                    loading: props.companyAccountingIntegration.saving,
                    text: t('loading.reducer.company_accounting_integration'),
                  },
                  { loading: props.companyAccountPlans.saving, text: t('loading.reducer.company_account_plans') },
                ])}
              />
            )}
          </div>
        )}
      </Card>
    </div>
  )
}
