import React from 'react'

import { CompanyLogoCreate, delCompanyLogo, fetchCompanyLogo, putCompanyLogo } from '../api/company-logos'
import ActionTypes from '../constants/action-types'
import { CompanyLogoAction } from '../reducers/companyLogo'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingCompanyLogo(): CompanyLogoAction {
  return {
    type: ActionTypes.COMPANY_LOGO_LOADING,
  }
}
export function loadedCompanyLogo(companyID: string, companyLogo: string | null): CompanyLogoAction {
  return {
    type: ActionTypes.COMPANY_LOGO_LOADED,
    companyLogo,
    companyID,
  }
}
function failedLoadingCompanyLogo(companyID: string, error: Error): CompanyLogoAction {
  return {
    type: ActionTypes.COMPANY_LOGO_LOAD_FAILED,
    error,
    companyID,
  }
}

function updatingCompanyLogo(): CompanyLogoAction {
  return {
    type: ActionTypes.COMPANY_LOGO_UPDATING,
  }
}
export function updatedCompanyLogo(companyID: string, companyLogo: string): CompanyLogoAction {
  return {
    type: ActionTypes.COMPANY_LOGO_UPDATED,
    companyID,
    companyLogo,
  }
}
function failedUpdatingCompanyLogo(companyID: string, error: Error): CompanyLogoAction {
  return {
    type: ActionTypes.COMPANY_LOGO_UPDATE_FAILED,
    error,
    companyID,
  }
}

function deletingCompanyLogo(companyID: string): CompanyLogoAction {
  return {
    type: ActionTypes.COMPANY_LOGO_DELETING,
    companyID,
  }
}
export function deletedCompanyLogo(companyID: string): CompanyLogoAction {
  return {
    type: ActionTypes.COMPANY_LOGO_DELETED,
    companyID,
  }
}
function failedDeletingCompanyLogo(companyID: string, error: Error): CompanyLogoAction {
  return {
    type: ActionTypes.COMPANY_LOGO_DELETE_FAILED,
    error,
    companyID,
  }
}

export function getCompanyLogo() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<string | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingCompanyLogo())
    return fetchCompanyLogo(companyID)
      .then((res) => {
        dispatch(loadedCompanyLogo(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          if (e.type === 'NotFound') {
            dispatch(loadedCompanyLogo(companyID, null))
          } else {
            dispatch(failedLoadingCompanyLogo(companyID, e))
          }
        }
      })
  }
}

export function updateCompanyLogo(image: CompanyLogoCreate) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<string | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(updatingCompanyLogo())
    return putCompanyLogo(companyID, image)
      .then((res) => {
        dispatch(updatedCompanyLogo(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCompanyLogo(companyID, e))
        }
      })
  }
}

export function deleteCompanyLogo() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingCompanyLogo(companyID))
    return delCompanyLogo(companyID)
      .then(() => {
        dispatch(deletedCompanyLogo(companyID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingCompanyLogo(companyID, e))
        }
      })
  }
}
