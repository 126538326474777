import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Department from '../model/department'
import { ReducerAction } from '../utils/reducer-utils'

export interface DepartmentReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  departments: List<Department>
  error: Error | null
}

const Params = Record<DepartmentReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  departments: List<Department>(),
  error: null,
})

export interface DepartmentAction extends ReducerAction {
  companyID?: string
  departments?: Department[]
  department?: Department
  departmentID?: string
}

const comparator = (a: Department, b: Department) => {
  return a.name.localeCompare(b.name, 'da-dk')
}

export default (
  state: Record<DepartmentReducer> = Params(),
  action: DepartmentAction = { type: '' }
): Record<DepartmentReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if ('companyID' in action && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.DEPARTMENTS_LOADING:
      return state.set('loading', true).set('loaded', false).set('companyID', state.get('companyID'))
    case ActionTypes.DEPARTMENTS_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('companyID', state.get('companyID'))
        .set('departments', List<Department>(action.departments).sort(comparator))
        .set('error', null)
    case ActionTypes.DEPARTMENTS_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', state.get('companyID'))
        .set('error', action.error || null)

    case ActionTypes.DEPARTMENT_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DEPARTMENT_ADDED:
      if (!action.department) {
        return state
      }
      idx = state.get('departments').findIndex((item) => !!action.department && item.id === action.department.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('departments', state.get('departments').set(idx, action.department).sort(comparator))
      }
      return state
        .set('saving', false)
        .set('departments', state.get('departments').push(action.department).sort(comparator))
    case ActionTypes.DEPARTMENT_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.DEPARTMENT_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DEPARTMENT_UPDATED:
      if (!action.department) {
        return state
      }
      idx = state.get('departments').findIndex((item) => item.id === action.departmentID)
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('departments', state.get('departments').set(idx, action.department).sort(comparator))
    case ActionTypes.DEPARTMENT_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.DEPARTMENT_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DEPARTMENT_DELETED:
      if ('departmentID' in action) {
        idx = state.get('departments').findIndex((item) => {
          return item.id === action.departmentID
        })
      }
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('departments', state.get('departments').delete(idx))
    case ActionTypes.DEPARTMENT_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
