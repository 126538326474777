import React from 'react'

import { fetchRecoveryCodes } from '../api/recovery-codes'
import ActionTypes from '../constants/action-types'
import { RecoveryCode } from '../model/types'
import { RecoveryCodeAction } from '../reducers/recoveryCodes'
import { isRequestError } from '../utils/error-utils'

function loadingRecoveryCodes(): RecoveryCodeAction {
  return {
    type: ActionTypes.RECOVERY_CODES_LOADING,
  }
}
function loadedRecoveryCodes(recoveryCodes: RecoveryCode[]): RecoveryCodeAction {
  return {
    type: ActionTypes.RECOVERY_CODES_LOADED,
    recoveryCodes,
  }
}
function failedLoadingRecoveryCodes(error: Error): RecoveryCodeAction {
  return {
    type: ActionTypes.RECOVERY_CODES_LOAD_FAILED,
    error,
  }
}

export function getRecoveryCodes() {
  return (dispatch: React.Dispatch<any>): Promise<RecoveryCode[] | void> => {
    dispatch(loadingRecoveryCodes())
    return fetchRecoveryCodes()
      .then((res) => {
        dispatch(loadedRecoveryCodes(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingRecoveryCodes(e))
        }
      })
  }
}
