import React, { ReactElement } from 'react'

import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'

type Fields = {
  signerValidationCode?: string
}

export type CompleteFields = {
  signerValidationCode: string
}

function DocumentSigningCompleteForm(props: FormComponentProps<Fields, CompleteFields>): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      <Row>
        <Col span={24}>
          {decorateField('signerValidationCode', {
            placeholder: t('form.field.signing_document.validation_code'),
            validate: (val) => {
              if (!val) {
                return t('form.validation.signing_document.validation_code_is_required')
              }
              return null
            },
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary" noArrow>
            {t('signing_document.sign_document')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<unknown, Fields, CompleteFields>({
  onSubmit: (values) => ({
    signerValidationCode: values.signerValidationCode!,
  }),
})(DocumentSigningCompleteForm)
