import { List } from 'immutable'
import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import Asset from '../../model/asset'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import Employee from '../../model/employee'
import { AlertReducer } from '../../reducers/alerts'
import { paths } from '../../routes'
import { getDate, isSameOrAfter, isTimeBefore } from '../../utils/date-utils'
import { isDepartmentRestricted } from '../../utils/permissions-utils'
import { t } from '../../utils/translation-utils'
import Table from '../antd/table'
import Button from '../elements/button'
import Headline from '../elements/Headline'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import UserImage from '../elements/UserImage'

type Props = {
  alerts: AlertReducer
  company: Company
  companyUser?: CompanyUser
  employees: List<Employee>
  assets: List<Asset>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
}

export default function Assets(props: Props): ReactElement | null {
  type EmployeeRow = {
    key: string
    id: string
    employee: string
    profileImageURL?: string
    received: number
    returned: number
    terminated: boolean
  }

  const columns = [
    {
      title: t('assets.table.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      width: 500,
      render: (employee: EmployeeRow) => {
        return (
          <Headline>
            <UserImage src={employee.profileImageURL} name={employee.employee} />
            <Link to={'/' + paths.EMPLOYEES + '/' + employee.id + '/assets'}> {employee.employee}</Link>
          </Headline>
        )
      },
    },
    { title: t('assets.table.header.received'), dataIndex: 'received', key: 'received' },
    { title: t('assets.table.header.returned'), dataIndex: 'returned', key: 'returned' },
  ]

  const getAssets = (): EmployeeRow[] => {
    const receivedCount = new Map<string, number>()
    const returnedCount = new Map<string, number>()
    const date = getDate()

    props.assets.forEach((asset) => {
      let returnDate = undefined
      if (asset.returnDate) {
        returnDate = getDate(asset.returnDate)
      }

      if (!returnDate || isTimeBefore(date, returnDate)) {
        receivedCount.set(asset.employeeID, (receivedCount.get(asset.employeeID) ?? 0) + 1)
      }
      if (!!returnDate && isSameOrAfter(date, returnDate)) {
        returnedCount.set(asset.employeeID, (returnedCount.get(asset.employeeID) ?? 0) + 1)
      }
    })

    return props.employees
      .toArray()
      .filter((employee) => {
        const received = receivedCount.get(employee.id) ?? 0
        const returned = returnedCount.get(employee.id) ?? 0
        return received > 0 || returned > 0
      })
      .map(
        (employee: Employee): EmployeeRow => ({
          key: employee.id,
          id: employee.id,
          employee: employee.name || employee.email || '-',
          profileImageURL: employee.profileImageURL,
          received: receivedCount.get(employee.id) ?? 0,
          returned: returnedCount.get(employee.id) ?? 0,
          terminated: employee.employmentStatus === 'Terminated',
        })
      )
  }

  const assets = getAssets()
  const standardEmployees = assets.filter((employee) => !employee.terminated)
  const terminatedEmployees = assets.filter((employee) => employee.terminated)

  return (
    <div className="assets">
      <TitleMenu>
        {!isDepartmentRestricted(props.companyUser) && (
          <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/asset-categories'}>
            <Button className="gtm-show-asset-categories">{t('assets.header.asset_categories')}</Button>
          </Link>
        )}
      </TitleMenu>
      <Title>{t('assets.standard.title')}</Title>

      <Table columns={columns} dataSource={standardEmployees} pagination={false} />

      {terminatedEmployees.length > 0 && (
        <>
          <Title>{t('assets.terminated.title')}</Title>

          <Table columns={columns} dataSource={terminatedEmployees} pagination={false} />
        </>
      )}
    </div>
  )
}
