import SalaryCycle, { BasicSalaryCycle } from '../model/salaryCycle'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchSalaryCycles(companyID: string): Promise<RequestResponse<SalaryCycle[]>> {
  return secureRequest('GET', url('/v2/salaryCycles', { companyID, includeSalaryPeriods: true }))
}

export function fetchSalaryCyclesWithoutPeriods(): Promise<RequestResponse<BasicSalaryCycle[]>> {
  return secureRequest('GET', url('/v2/salaryCycles'))
}
