import { List } from 'immutable'

import CompanyUser from '../model/companyUser'
import Department from '../model/department'
import Employee from '../model/employee'

export type EmployeeRowEmployeeDetails = {
  employeeName: string
  isFreelancer: boolean
  department?: string
  departmentApprover?: string
}

export function getEmployeeRowEmployeeDetails(
  employee: Employee | undefined,
  departments: List<Department>,
  companyUsers: List<CompanyUser>
): EmployeeRowEmployeeDetails {
  let departmentName: string | undefined = undefined
  let departmentApprover: string | undefined = undefined
  if (employee && employee.departmentID && departments.size > 0) {
    const department = departments.find((d) => d.id === employee.departmentID)
    if (department) {
      departmentName = department.name
      const approvers = companyUsers.filter((user) =>
        user.departments.some(
          (d) => d.departmentID === department.id && d.permissions.some((permission) => permission === 'ApproveObjects')
        )
      )
      if (approvers.size === 1) {
        departmentApprover = approvers.get(0)?.name || approvers.get(0)?.email || undefined
      }
    }
  }
  let employeeName = ''
  if (employee) {
    employeeName = employee.name || employee.email || ''
  }
  return {
    employeeName,
    isFreelancer: !!employee && employee.affiliationType === 'Freelancer',
    department: departmentName,
    departmentApprover,
  }
}
