import React, { ReactElement } from 'react'

import { BenefitType } from '../../model/remuneration'
import {
  decorateAnyFieldSignature,
  getAnyFieldErrorSignature,
  getFieldValueSignature,
  setAnyFieldValueSignature,
  setFieldValueSignature,
} from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import Icon from '../elements/Icon'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'

type BenefitFreeTextFields = {
  texts: {
    title: string
    type: BenefitType
  }[]
}

type Props<Fields extends BenefitFreeTextFields> = {
  decorateAnyField: decorateAnyFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  setFieldValue: setFieldValueSignature<Fields>
  setAnyFieldValue: setAnyFieldValueSignature
  getAnyFieldError: getAnyFieldErrorSignature
}

export default function BenefitFreeTextForm<Fields extends BenefitFreeTextFields>(
  props: Props<Fields>
): ReactElement | null {
  const add = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    const { getFieldValue, setAnyFieldValue } = props
    const texts = getFieldValue('texts')
    setAnyFieldValue(`texts.${texts.length}`, {
      type: 'Free Text',
    })
  }
  const remove = (i: number) => {
    const { getFieldValue, setFieldValue } = props
    const texts = getFieldValue('texts')
    texts.splice(i, 1)
    setFieldValue('texts', texts)
  }

  const { decorateAnyField, getFieldValue, getAnyFieldError } = props

  return (
    <div>
      <Row style={{ marginTop: '15px', marginBottom: '20px' }}>
        <Col span={24}>
          <Headline>{t('benefit.form.free_text.intro.title')}</Headline>
        </Col>
      </Row>
      <Subcard>
        <Row style={{ marginBottom: '-10px' }}>
          <Col span={24}>
            <label>{t('benefit.form.free_text.header.title')}</label>
          </Col>
        </Row>
        {getFieldValue('texts').map((benefit, i) => {
          return (
            <Form.Item
              key={i}
              validateStatus={
                getAnyFieldError(`texts.${i}.title`) || getAnyFieldError(`texts.${i}.title`) ? 'error' : 'success'
              }
            >
              <Row>
                <Col span={23}>
                  {decorateAnyField(`texts.${i}.title`, {
                    placeholder: t('benefit.form.free_text.title'),
                    validate: (val: string | undefined) => (!val ? t('benefit.form.free_text.title.required') : null),
                    skipWrapper: true,
                    skipLabel: true,
                  })(<Input />)}
                </Col>
                <Col span={1} className="contracts-remove-row">
                  <span onClick={() => remove(i)}>
                    <Icon type="cross" color="grey" />
                  </span>
                </Col>
              </Row>
            </Form.Item>
          )
        })}
        <span className="contracts-add-row" onClick={add}>
          <Icon type="add" color="orange" />{' '}
          {getFieldValue('texts').length > 0
            ? t('benefit.form.free_text.add_row.not_empty')
            : t('benefit.form.free_text.add_row.empty')}
        </span>
      </Subcard>
    </div>
  )
}
