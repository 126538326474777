import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature } from '../../../actions/alerts'
import { resendPaySlip } from '../../../api/pay-slips'
import PDFPreviewTypes from '../../../constants/pdf-preview-types'
import Company from '../../../model/company'
import Employee from '../../../model/employee'
import PaySlip from '../../../model/paySlip'
import PricingPackage from '../../../model/pricingPackage'
import { CompanyPricingPackageReducer } from '../../../reducers/companyPricingPackages'
import { EmployeeReducer } from '../../../reducers/employees'
import { paths } from '../../../routes'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatDate, formatShortDate } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { formatCurrency } from '../../../utils/number-utils'
import { isPricingPackageGroup } from '../../../utils/pricing-package-utils'
import { secureUrl } from '../../../utils/request-utils'
import { t } from '../../../utils/translation-utils'
import Table from '../../antd/table'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Icon from '../../elements/Icon'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import Tooltip from '../../elements/tooltip'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import PaySlipsForm, { PaySlipFields } from './PaySlipsForm'

type Props = {
  company: Company
  employee: Employee
  employees: EmployeeReducer
  paySlips: List<PaySlip>
  pricingPackages: List<PricingPackage>
  companyPricingPackages: CompanyPricingPackageReducer

  addAlert: addAlertSignature
  updateEmployee: (employee: Employee) => void
  getCompanyPricingPackages: () => void
}

export default function PaySlipsTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [resentPaySlips, setResentPaySlips] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const { companyPricingPackages, getCompanyPricingPackages } = props

  useEffect(() => {
    if (!companyPricingPackages.loaded && !companyPricingPackages.loading) {
      getCompanyPricingPackages()
    }
  })

  const { employees } = props

  useEffect(() => {
    regularComponentDidUpdate(employees.error, error, setError)
  }, [employees, error])

  const handleSubmit = (values: PaySlipFields) => {
    const employee = { ...props.employee, ...values }
    props.updateEmployee(employee)
  }

  const resend = (paySlipID: string) => {
    if (resentPaySlips.some((id) => id === paySlipID)) {
      return
    }
    setResentPaySlips((prev) => {
      prev.push(paySlipID)
      return prev
    })
    setLoading(true)
    resendPaySlip(paySlipID)
      .then(() => props.addAlert('success', t('pay_slips_tab.resend.success'), { timeout: 5 }))
      .catch(() => {
        setError(new Error(t('pay_slips_tab.resend.error')))
      })
      .finally(() => setLoading(false))
  }

  type PaySlipRow = {
    key: string
    id: string
    payRollID: string
    dispositionDate: string
    salaryPeriod: string
    grossPay: string
    settled: boolean
    shouldResend: boolean
    canResend: boolean
  }

  const columns = [
    {
      title: t('pay_slips_tab.table.header.period'),
      dataIndex: '',
      key: 'salaryPeriod',
      render: (paySlip: PaySlipRow) => {
        return (
          <Link to={'/' + paths.PAY_ROLLS + '/' + paySlip.payRollID}>
            <Tooltip placement="top" title={t('pay_slips_tab.table.actions.payroll')}>
              {paySlip.salaryPeriod}
            </Tooltip>
          </Link>
        )
      },
    },
    { title: t('pay_slips_tab.table.header.gross_pay'), dataIndex: 'grossPay', key: 'grossPay' },
    {
      title: t('pay_slips_tab.table.header.disposition_date'),
      dataIndex: 'dispositionDate',
      key: 'dispositionDate',
    },
    {
      title: t('pay_slips_tab.table.header.settled'),
      dataIndex: '',
      key: 'x4',
      render: (paySlip: PaySlipRow) => <span>{paySlip.settled ? t('pay_slips_tab.table.settled.true') : ''}</span>,
    },
    {
      title: '',
      dataIndex: '',
      key: 'x5',
      width: 1,
      render: (paySlip: PaySlipRow) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <Link
              to={'/' + paths.PDF_PREVIEW + '/' + PDFPreviewTypes.PAY_SLIP + '/' + paySlip.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tooltip placement="top" title={t('pay_slips_tab.table.actions.download')}>
                <Icon type="download" color="bluish" />
              </Tooltip>
            </Link>
            {paySlip.shouldResend && paySlip.canResend && (
              <DumbLink onClick={() => resend(paySlip.id)}>
                <Tooltip placement="top" title={t('pay_slips_tab.table.actions.resend')}>
                  <Icon type="refresh" color="bluish" />
                </Tooltip>
              </DumbLink>
            )}
          </div>
        )
      },
    },
  ]

  const getPaySlips = (): PaySlipRow[] => {
    return props.paySlips
      .map((paySlip) => {
        const combinedPaySlip = {
          key: paySlip.id,
          id: paySlip.id,
          payRollID: paySlip.payRollID,
          dispositionDate: formatDate(paySlip.dispositionDate),
          salaryPeriod: formatShortDate(paySlip.salaryPeriod.start) + ' - ' + formatShortDate(paySlip.salaryPeriod.end),
          grossPay: '-',
          settled: paySlip.settled,
          shouldResend: paySlip.settled && !!props.employee.email,
          canResend: paySlip.settled && !!props.employee.email && !resentPaySlips.some((id) => id === paySlip.id),
        }
        if (paySlip.calculations) {
          const grossPay = paySlip.calculations.find((calc) => calc.type === 'Salary')?.result
          if (grossPay) {
            combinedPaySlip.grossPay = formatCurrency(grossPay, 0)
          }
        }
        return combinedPaySlip
      })
      .toArray()
  }

  if (!companyPricingPackages.loaded) {
    return <LoadingOverlay />
  }

  const paySlips = getPaySlips()
  const hasPaySlips = props.paySlips.some((paySlip) => paySlip.settled)
  const hasPremiumPackage = isPricingPackageGroup(
    props.pricingPackages.toArray(),
    props.companyPricingPackages.companyPricingPackages.toArray(),
    props.company.id,
    ['Premium']
  )
  return (
    <Card>
      {loading && <LoadingOverlay />}
      {hasPaySlips && (
        <TitleMenu>
          {hasPaySlips && (
            <Link
              to={secureUrl('/v2/paySlipsPDF?employeeID=' + props.employee.id)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>
                <Icon type="download" color="grey" />
                {t('pay_slips_tab.header.download_all')}
              </Button>
            </Link>
          )}
        </TitleMenu>
      )}
      <Title>{t('pay_slips_tab.title')}</Title>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      {!!props.employee.activeEmployment && (
        <PaySlipsForm
          employee={props.employee}
          employees={props.employees}
          hasPremiumPackage={hasPremiumPackage}
          onSubmit={handleSubmit}
        />
      )}

      <Table columns={columns} dataSource={paySlips} size="small" pagination={false} />
    </Card>
  )
}
