import SupplementAdjustment, {
  SupplementAdjustmentCreationFields,
  SupplementAdjustmentMutableFields,
} from '../model/supplementAdjustment'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchSupplementAdjustments(
  companyID?: string,
  employeeID?: string
): Promise<RequestResponse<SupplementAdjustment[]>> {
  return secureRequest('GET', url('/v2/balanceAdjustments/supplement', { companyID, employeeID }))
}

export function postSupplementAdjustment(
  supplementAdjustment: SupplementAdjustmentCreationFields
): Promise<RequestResponse<SupplementAdjustment>> {
  return secureRequest('POST', url('/v2/balanceAdjustments/supplement'), {
    body: {
      employeeID: supplementAdjustment.employeeID,
      supplementTypeName: supplementAdjustment.supplementTypeName,
      operation: supplementAdjustment.operation,
      dispositionDate: supplementAdjustment.dispositionDate,
      earned: supplementAdjustment.earned,
    },
  })
}

export function putSupplementAdjustment(
  supplementAdjustmentID: string,
  supplementAdjustment: SupplementAdjustmentMutableFields
): Promise<RequestResponse<SupplementAdjustment>> {
  return secureRequest('PUT', url('/v2/balanceAdjustments/supplement/' + supplementAdjustmentID), {
    body: {
      employeeID: supplementAdjustment.employeeID,
      supplementTypeName: supplementAdjustment.supplementTypeName,
      operation: supplementAdjustment.operation,
      dispositionDate: supplementAdjustment.dispositionDate,
      earned: supplementAdjustment.earned,
    },
  })
}

export function removeSupplementAdjustment(supplementAdjustmentID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/balanceAdjustments/supplement/' + supplementAdjustmentID))
}
