import React, { ReactElement } from 'react'

import {
  decorateAnyFieldSignature,
  getAnyFieldErrorSignature,
  getFieldValueSignature,
  setAnyFieldValueSignature,
} from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Checkbox from '../elements/checkbox'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/Icon'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'

export type ProductionUnitField = {
  productionUnitNumber: string
  name: string
  active: boolean
}

type ProductionUnitFields = {
  productionUnits: ProductionUnitField[]
}

type Props<Fields extends ProductionUnitFields> = {
  decorateAnyField: decorateAnyFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  setAnyFieldValue: setAnyFieldValueSignature
  getAnyFieldError: getAnyFieldErrorSignature
}

export default function ProductionUnitsForm<Fields extends ProductionUnitFields>(
  props: Props<Fields>
): ReactElement | null {
  const add = () => {
    const { getFieldValue, setAnyFieldValue } = props
    const productionUnits = getFieldValue('productionUnits')
    setAnyFieldValue(`productionUnits.${productionUnits.length}`, { active: true })
  }

  const { decorateAnyField, getFieldValue, getAnyFieldError } = props

  return (
    <Subcard>
      <Row style={{ marginBottom: '-10px' }}>
        <Col span={8}>
          <label>{t('production_units.form.number')}</label>
        </Col>
        <Col span={14}>
          <label>{t('production_units.form.name')}</label>
        </Col>
        <Col span={2}>
          <label>{t('production_units.form.active')}</label>
        </Col>
      </Row>
      {getFieldValue('productionUnits').map((productionUnit, i) => {
        return (
          <Form.Item
            key={i}
            validateStatus={
              getAnyFieldError(`productionUnits.${i}.productionUnitNumber`) ||
              getAnyFieldError(`productionUnits.${i}.name`)
                ? 'error'
                : 'success'
            }
          >
            <Row>
              <Col span={8}>
                {decorateAnyField(`productionUnits.${i}.productionUnitNumber`, {
                  placeholder: t('production_units.form.number'),
                  validate: (val) => {
                    if (!val) {
                      return t('production_units.form.number.required')
                    }
                    val = val.toString()
                    if (!val) {
                      return t('production_units.form.number.required')
                    }
                    if (!val.match(/^[0-9]{10}$/)) {
                      return t('production_units.form.number.invalid')
                    }
                    return null
                  },
                  skipWrapper: true,
                  skipLabel: true,
                })(<Input />)}
              </Col>
              <Col span={14}>
                {decorateAnyField(`productionUnits.${i}.name`, {
                  placeholder: t('production_units.form.name'),
                  validate: (val) => (!val ? t('production_units.form.name.required') : null),
                  skipWrapper: true,
                  skipLabel: true,
                })(<Input />)}
              </Col>
              <Col span={2}>
                {decorateAnyField(`productionUnits.${i}.active`, {
                  placeholder: t('production_units.form.active'),
                  valueOnChecked: true,
                  noBlur: true,
                  skipWrapper: true,
                  skipLabel: true,
                })(<Checkbox style={{ marginTop: 10 }} />)}
              </Col>
            </Row>
          </Form.Item>
        )
      })}
      <span className="contracts-add-row" onClick={add}>
        <Icon type="add" color="orange" /> {t('production_units.form.add_row')}
      </span>
    </Subcard>
  )
}
