import { addYears, subYears } from 'date-fns'
import React, { ReactElement, useEffect } from 'react'
import { Link } from 'react-router'

import { getCarAllowances } from '../../actions/car-allowances'
import { getOneTimePays } from '../../actions/one-time-pays'
import { getReimbursementVouchers } from '../../actions/reimbursement-vouchers'
import { getSalaryRegistrations } from '../../actions/salary-registrations'
import { getSwipes } from '../../actions/swipes'
import { getTimeRegistrations } from '../../actions/time-registrations'
import Company from '../../model/company'
import { DateFormat, SettledState } from '../../model/types'
import { CarAllowanceReducer } from '../../reducers/carAllowances'
import { CompanyFeatureReducer } from '../../reducers/companyFeatures'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { EmployeeReducer } from '../../reducers/employees'
import { OneTimePayReducer } from '../../reducers/oneTimePays'
import { ReimbursementVoucherReducer } from '../../reducers/reimbursementVouchers'
import { SalaryRegistrationReducer } from '../../reducers/salaryRegistrations'
import { SalaryTypeReducer } from '../../reducers/salaryTypes'
import { SwipeReducer } from '../../reducers/swipes'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { paths } from '../../routes'
import { formatAPIDate, getDate } from '../../utils/date-utils'
import { hasDepartmentPermission } from '../../utils/permissions-utils'
import { connectToReducer } from '../../utils/reducer-utils'
import { RoutePropsRoute } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'

import './SubmenuLayout.css'

type Reducers = {
  children?: ReactElement
  companyFeatures: CompanyFeatureReducer
  company?: Company
  companyUsers: CompanyUserReducer
  employees: EmployeeReducer
  oneTimePays: OneTimePayReducer
  salaryTypes: SalaryTypeReducer
  carAllowances: CarAllowanceReducer
  timeRegistrations: TimeRegistrationReducer
  swipes: SwipeReducer
  reimbursementVouchers: ReimbursementVoucherReducer
  salaryRegistrations: SalaryRegistrationReducer
}

type Actions = {
  getOneTimePays: (companyID?: string, employeeID?: string) => void
  getCarAllowances: (
    companyID: string | undefined,
    employeeID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => void
  getTimeRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat
  ) => void
  getSalaryRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat,
    stateFilter?: SettledState
  ) => void
  getSwipes: () => void
  getReimbursementVouchers: () => void
}

type Props = {
  route: RoutePropsRoute
  children: React.ReactNode
}

function RegistrationLayout(props: Reducers & Actions & Props): ReactElement | null {
  const {
    company,
    oneTimePays,
    getOneTimePays,
    carAllowances,
    getCarAllowances,
    timeRegistrations,
    getTimeRegistrations,
    reimbursementVouchers,
    getReimbursementVouchers,
    salaryRegistrations,
    getSalaryRegistrations,
    swipes,
    getSwipes,
  } = props

  useEffect(() => {
    if (!company) {
      return
    }
    if (
      !carAllowances.companyID ||
      carAllowances.companyID !== company.id ||
      (!carAllowances.loaded && !carAllowances.loading)
    ) {
      getCarAllowances(
        company.id,
        undefined,
        formatAPIDate(subYears(getDate(), 2)),
        formatAPIDate(addYears(getDate(), 10))
      )
    }
    if (
      !timeRegistrations.companyID ||
      timeRegistrations.companyID !== company.id ||
      (!timeRegistrations.loaded && !timeRegistrations.loading)
    ) {
      getTimeRegistrations(
        company.id,
        undefined,
        undefined,
        formatAPIDate(subYears(getDate(), 2)),
        formatAPIDate(addYears(getDate(), 10))
      )
    }
    if (
      !oneTimePays.companyID ||
      oneTimePays.companyID !== company.id ||
      (!oneTimePays.loaded && !oneTimePays.loading)
    ) {
      getOneTimePays(company.id)
    }
    if (
      !reimbursementVouchers.companyID ||
      reimbursementVouchers.companyID !== company.id ||
      (!reimbursementVouchers.loaded && !reimbursementVouchers.loading)
    ) {
      getReimbursementVouchers()
    }
    if (!swipes.companyID || swipes.companyID !== company.id || (!swipes.loaded && !swipes.loading)) {
      getSwipes()
    }
    if (
      !salaryRegistrations.companyID ||
      salaryRegistrations.companyID !== company.id ||
      (!salaryRegistrations.loaded && !salaryRegistrations.loading)
    ) {
      getSalaryRegistrations(
        company.id,
        undefined,
        undefined,
        formatAPIDate(subYears(getDate(), 2)),
        formatAPIDate(addYears(getDate(), 10)),
        'Pending'
      )
    }
  }, [
    company,
    oneTimePays,
    getOneTimePays,
    carAllowances,
    getCarAllowances,
    reimbursementVouchers,
    getReimbursementVouchers,
    salaryRegistrations,
    getSalaryRegistrations,
    swipes,
    getSwipes,
    timeRegistrations,
    getTimeRegistrations,
  ])

  const hasReimbursementVouchersFeature = props.companyFeatures.companyFeatures.some(
    (feature) => feature.featureType === 'Reimbursement Vouchers'
  )

  const hasSwipe =
    (company && company.enableSwipe) || oneTimePays.oneTimePays.some((oneTimePay) => oneTimePay.type === 'Swipe')

  const hasSupplementVaried = props.employees.employees.some(
    (employee) =>
      !!employee.earliestMutableContract &&
      !!employee.earliestMutableContract.remuneration &&
      employee.earliestMutableContract.remuneration.salary.some((salary) =>
        props.salaryTypes.salaryTypes.some(
          (salaryType) => salaryType.id === salary.salaryTypeID && salaryType.class === 'SupplementVaried'
        )
      )
  )

  const path = props.route.path.split('/')[0]
  const canSeeSalaryRates = hasDepartmentPermission(props.companyUsers.companyUser, undefined, 'SeeSalaryRates')

  const getPendingApprovalCount = (): number => {
    return (
      props.carAllowances.carAllowances.filter((allowance) => !allowance.approved).size +
      props.timeRegistrations.timeRegistrations.filter((timeReg) => !timeReg.approved).size +
      props.swipes.swipes.filter((swipe) => !swipe.approved).size +
      props.oneTimePays.oneTimePays.filter(
        (otp) => otp.type !== 'Swipe' && otp.type !== 'Reimbursement Voucher' && !otp.approved
      ).size +
      props.reimbursementVouchers.reimbursementVouchers.filter((voucher) => voucher.approvalState === 'Ready').size +
      props.salaryRegistrations.salaryRegistrations.filter((reg) => !reg.approved).size
    )
  }
  const pendingApprovalCount = getPendingApprovalCount()

  return (
    <div className="submenu">
      <div className="submenu-topbar">
        <Link
          key={paths.APPROVE_TAB}
          to={'/' + paths.APPROVE_TAB}
          className={path === paths.APPROVE_TAB ? 'active' : ''}
        >
          {t('registrations.layout.awaiting_approval')}
          {pendingApprovalCount !== undefined && <span className="approval-count">{pendingApprovalCount}</span>}
        </Link>
        <Link
          key={paths.TIME_REGISTRATION}
          to={'/' + paths.TIME_REGISTRATION}
          className={path === paths.TIME_REGISTRATION ? 'active' : ''}
        >
          {t('registrations.layout.time_registrations')}
        </Link>
        {hasSupplementVaried && (
          <Link
            key={paths.SALARY_REGISTRATION}
            to={'/' + paths.SALARY_REGISTRATION}
            className={path === paths.SALARY_REGISTRATION ? 'active' : ''}
          >
            {t('registrations.layout.salary_registrations')}
          </Link>
        )}
        <Link
          key={paths.LEAVE_REGISTRATION}
          to={'/' + paths.LEAVE_REGISTRATION}
          className={path === paths.LEAVE_REGISTRATION ? 'active' : ''}
        >
          {t('registrations.layout.leave_registrations')}
        </Link>
        <Link
          key={paths.CAR_ALLOWANCE}
          to={'/' + paths.CAR_ALLOWANCE}
          className={path === paths.CAR_ALLOWANCE ? 'active' : ''}
        >
          {t('registrations.layout.car_allowances')}
        </Link>
        <Link key={paths.WORK_HOURS} to={'/' + paths.WORK_HOURS} className={path === paths.WORK_HOURS ? 'active' : ''}>
          {t('registrations.layout.work_hours')}
        </Link>
        {canSeeSalaryRates && (
          <Link
            key={paths.ONE_TIME_PAYS}
            to={'/' + paths.ONE_TIME_PAYS}
            className={path === paths.ONE_TIME_PAYS ? 'active' : ''}
          >
            {t('registrations.layout.one_time_pays')}
          </Link>
        )}
        {hasReimbursementVouchersFeature && (
          <Link
            key={paths.REIMBURSEMENT_VOUCHERS}
            to={'/' + paths.REIMBURSEMENT_VOUCHERS}
            className={path === paths.REIMBURSEMENT_VOUCHERS ? 'active' : ''}
          >
            {t('registrations.layout.reimbursement_vouchers')}
          </Link>
        )}
        <Link
          key={paths.FREELANCERS_OVERVIEW}
          to={'/' + paths.FREELANCERS_OVERVIEW}
          className={path === paths.FREELANCERS_OVERVIEW ? 'active' : ''}
        >
          {t('registrations.layout.freelancers')}
        </Link>
        {hasSwipe && (
          <Link
            key={paths.SWIPE_OVERVIEW}
            to={'/' + paths.SWIPE_OVERVIEW}
            className={path === paths.SWIPE_OVERVIEW ? 'active' : ''}
          >
            {t('registrations.layout.swipes')}
          </Link>
        )}
      </div>
      <div className="submenu-main">{props.children}</div>
    </div>
  )
}

export default connectToReducer<Reducers, Actions, Props>(
  (state) => ({
    companyFeatures: state.companyFeatures,
    company: state.companies.company,
    companyUsers: state.companyUsers,
    employees: state.employees,
    oneTimePays: state.oneTimePays,
    salaryTypes: state.salaryTypes,
    timeRegistrations: state.timeRegistrations,
    swipes: state.swipes,
    reimbursementVouchers: state.reimbursementVouchers,
    carAllowances: state.carAllowances,
    salaryRegistrations: state.salaryRegistrations,
  }),
  {
    getOneTimePays: getOneTimePays,
    getCarAllowances: getCarAllowances,
    getTimeRegistrations: getTimeRegistrations,
    getSalaryRegistrations: getSalaryRegistrations,
    getSwipes: getSwipes,
    getReimbursementVouchers: getReimbursementVouchers,
  }
)(RegistrationLayout)
