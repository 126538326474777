import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import ContractBookTemplate from '../model/contractBookTemplate'
import { ReducerAction } from '../utils/reducer-utils'

export interface ContractBookTemplateReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  templates: List<ContractBookTemplate>
  error: Error | null
}

const Params = Record<ContractBookTemplateReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  templates: List<ContractBookTemplate>(),
  error: null,
})

const comparator = (a: ContractBookTemplate, b: ContractBookTemplate) => {
  return a.title.localeCompare(b.title, 'da-dk')
}

export interface ContractBookTemplateAction extends ReducerAction {
  companyID?: string
  templates?: ContractBookTemplate[]
}

export default (
  state: Record<ContractBookTemplateReducer> = Params(),
  action: ContractBookTemplateAction = { type: '' }
): Record<ContractBookTemplateReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.CONTRACT_BOOK_TEMPLATE_LOADING:
      return state.set('loading', true).set('loaded', false).set('companyID', state.get('companyID'))
    case ActionTypes.CONTRACT_BOOK_TEMPLATE_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('companyID', state.get('companyID'))
        .set('templates', List<ContractBookTemplate>(action.templates).sort(comparator))
        .set('error', null)
    case ActionTypes.CONTRACT_BOOK_TEMPLATE_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', state.get('companyID'))
        .set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
