import React, { ReactElement } from 'react'

import Company from '../../model/company'
import { CompanyReducer } from '../../reducers/companies'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Switch from '../elements/switch'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  company: Company
  companies: CompanyReducer
}

export type PayslipQuestionResult = {
  paySlipQuestionsVisible: boolean
  paySlipQuestionsLink?: string
}

function PayslipQuestionsForm(
  props: Props & FormComponentProps<PayslipQuestionResult, PayslipQuestionResult>
): ReactElement | null {
  const { decorateField, getFieldValue } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('paySlipQuestionsVisible', {
            title: t('pay_slip_fields.link.form.pay_slip_questions_visible'),
            valueOnChecked: true,
            noBlur: true,
          })(
            <Switch
              checkedChildren={t('pay_slip_fields.link.form.pay_slip_questions_visible.checked')}
              unCheckedChildren={t('pay_slip_fields.link.form.pay_slip_questions_visible.unchecked')}
            />
          )}
        </Col>
      </Row>
      {getFieldValue('paySlipQuestionsVisible') && (
        <Row>
          <Col span={24}>
            {decorateField('paySlipQuestionsLink', {
              placeholder: t('pay_slip_fields.link.form.pay_slip_questions_link'),
              validate: (val) => {
                if (!val) {
                  return t('pay_slip_fields.link.form.pay_slip_questions_link.required')
                }
                return null
              },
            })(<Input style={{ width: '100%' }} />)}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('pay_slip_fields.link.form.submit')}
          </Button>
        </Col>
      </Row>
      {props.companies.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, PayslipQuestionResult, PayslipQuestionResult>({
  mapPropsToFields: (props) => ({
    paySlipQuestionsVisible: props.company.paySlipQuestionsVisible,
    paySlipQuestionsLink: props.company.paySlipQuestionsLink,
  }),
  onSubmit: (values) => values,
})(PayslipQuestionsForm)
