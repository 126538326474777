import React, { ReactElement, useEffect } from 'react'
import { usePrevious } from 'react-use'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getRecoveryCodes } from '../actions/recovery-codes'
import { changePassword, requestEmailChange, updateUser } from '../actions/user'
import AccountComponent from '../components/account/Account'
import User from '../model/user'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { RecoveryCodeReducer } from '../reducers/recoveryCodes'
import { UserReducer } from '../reducers/user'
import MfaChannel from '../types/mfa-channel'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  user: UserReducer
  recoveryCodes: RecoveryCodeReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getRecoveryCodes: () => void
  updateUser: (user: User) => void
  requestEmailChange: (email: string) => void
  changePassword: (oldPassword: string, newPassword: string) => void
}

function Account(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { user, recoveryCodes, getRecoveryCodes } = props
  const previousUser = usePrevious(user)
  useEffect(() => {
    if (
      (!previousUser || previousUser.mfaChannel === MfaChannel.NONE) &&
      user.mfaChannel !== MfaChannel.NONE &&
      !recoveryCodes.loading &&
      !recoveryCodes.loaded
    ) {
      getRecoveryCodes()
    }
  })
  return (
    <AccountComponent
      section={splatString(props.params.splat) || 'profile'}
      alerts={props.alerts}
      user={props.user}
      companies={props.companies.companies}
      recoveryCodes={props.recoveryCodes}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      updateUser={props.updateUser}
      requestEmailChange={props.requestEmailChange}
      changePassword={props.changePassword}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    user: state.user,
    companies: state.companies,
    recoveryCodes: state.recoveryCodes,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    updateUser: updateUser,
    requestEmailChange: requestEmailChange,
    changePassword: changePassword,
    getRecoveryCodes: getRecoveryCodes,
  }
)(Account)
