import React from 'react'

import {
  delPayRoll,
  fetchPayRoll,
  fetchPayRolls,
  NewPayRoll,
  NotificationChannel,
  patchPayRoll,
  postPayRoll,
  postZeroTaxReport,
  putPayRoll,
} from '../api/pay-rolls'
import ActionTypes from '../constants/action-types'
import PayRoll from '../model/payRoll'
import { PaymentMethod } from '../model/transfer'
import { DateFormat } from '../model/types'
import { PayRollAction } from '../reducers/payRolls'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'

function loadingPayRolls(): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_LOADING,
  }
}
function loadedPayRolls(companyID: string, payRolls: PayRoll[], partial = false): PayRollAction {
  return {
    type: partial ? ActionTypes.PAY_ROLL_LOADED_PARTIAL : ActionTypes.PAY_ROLL_LOADED,
    payRolls,
    companyID,
  }
}
function failedLoadingPayRolls(companyID: string, error: Error): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_LOAD_FAILED,
    error,
    companyID,
  }
}

function loadingPayRoll(): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_SINGLE_LOADING,
  }
}
function loadedPayRoll(payRollID: string, payRoll: PayRoll): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_SINGLE_LOADED,
    payRoll,
    payRollID,
  }
}
function failedLoadingPayRoll(payRollID: string, error: Error): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_SINGLE_LOAD_FAILED,
    error,
    payRollID,
  }
}

function addingPayRoll(): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_ADDING,
  }
}
export function addedPayRoll(companyID: string, payRoll: PayRoll): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_ADDED,
    companyID,
    payRoll,
  }
}
function failedAddingPayRoll(error: Error): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_ADD_FAILED,
    error,
  }
}

function updatingPayRoll(payRollID: string): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_UPDATING,
    payRollID,
  }
}
export function updatedPayRoll(companyID: string, payRollID: string, payRoll: PayRoll): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_UPDATED,
    companyID,
    payRollID,
    payRoll,
  }
}
function failedUpdatingPayRoll(payRollID: string, error: Error): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_UPDATE_FAILED,
    error,
    payRollID,
  }
}

function approvingPayRoll(payRollID: string): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_APPROVING,
    payRollID,
  }
}
function approvedPayRoll(payRollID: string): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_APPROVED,
    payRollID,
  }
}
function failedApprovingPayRoll(payRollID: string, error: Error): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_APPROVE_FAILED,
    error,
    payRollID,
  }
}

function rejectingPayRoll(payRollID: string): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_REJECTING,
    payRollID,
  }
}
function rejectedPayRoll(payRollID: string): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_REJECTED,
    payRollID,
  }
}
function failedRejectingPayRoll(payRollID: string, error: Error): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_REJECT_FAILED,
    error,
    payRollID,
  }
}

function restartingPayRoll(payRollID: string): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_RESTARTING,
    payRollID,
  }
}
function restartedPayRoll(payRollID: string): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_RESTARTED,
    payRollID,
  }
}
function failedRestartingPayRoll(payRollID: string, error: Error): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_RESTART_FAILED,
    error,
    payRollID,
  }
}

function deletingPayRoll(payRollID: string): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_DELETING,
    payRollID,
  }
}
export function deletedPayRoll(payRollID: string): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_DELETED,
    payRollID,
  }
}
function failedDeletingPayRoll(payRollID: string, error: Error): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_DELETE_FAILED,
    error,
    payRollID,
  }
}

function changingPayRollPaymentMethod(payRollID: string): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_PAYMENT_METHOD_CHANGING,
    payRollID,
  }
}
function changedPayRollPaymentMethod(payRollID: string, paymentMethod: PaymentMethod): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_PAYMENT_METHOD_CHANGED,
    payRollID,
    paymentMethod,
  }
}
function failedChangedPayRollPaymentMethod(payRollID: string, error: Error): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_PAYMENT_METHOD_CHANGE_FAILED,
    error,
    payRollID,
  }
}

function payRollResendingSKAT(payRollID: string): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_RESENDING_SKAT,
    payRollID,
  }
}
export function payRollResentSKAT(payRollID: string): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_RESENT_SKAT,
    payRollID,
  }
}
function failedPayRollResendingSKAT(payRollID: string, error: Error): PayRollAction {
  return {
    type: ActionTypes.PAY_ROLL_RESEND_SKAT_FAILED,
    error,
    payRollID,
  }
}

export function getPayRolls(offset?: number) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<PayRoll[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    if (!offset) {
      dispatch(loadingPayRolls())
      offset = 0
    }
    const limit = 1000
    return fetchPayRolls(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedPayRolls(companyID, data)),
          (data) => dispatch(loadedPayRolls(companyID, data, true)),
          (offset) => dispatch(getPayRolls(offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingPayRolls(companyID, e))
        }
      })
  }
}

export function getPayRoll(payRollID: string) {
  return (dispatch: React.Dispatch<any>): Promise<PayRoll | void> => {
    dispatch(loadingPayRoll())
    return fetchPayRoll(payRollID)
      .then((res) => {
        dispatch(loadedPayRoll(payRollID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingPayRoll(payRollID, e))
        }
      })
  }
}

export function addPayRoll(payRoll: NewPayRoll) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<PayRoll | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(addingPayRoll())
    return postPayRoll(payRoll)
      .then((res) => {
        dispatch(addedPayRoll(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingPayRoll(e))
        }
      })
  }
}

export function startZeroTaxReport(month: DateFormat) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature) => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(addingPayRoll())
    return postZeroTaxReport(companyID, month)
      .then((res) => {
        dispatch(addedPayRoll(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingPayRoll(e))
        }
      })
  }
}

export function approvePayRoll(
  payRollID: string,
  version: string,
  approvalCode: string | undefined,
  userIDs?: string[],
  notificationChannel?: NotificationChannel
) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(approvingPayRoll(payRollID))
    return patchPayRoll(payRollID, version, 'Approve', {
      payrollApprovalCode: approvalCode,
      notifyUserIDs: userIDs,
      notifyChannel: notificationChannel,
    })
      .then(() => {
        dispatch(approvedPayRoll(payRollID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedApprovingPayRoll(payRollID, e))
        }
      })
  }
}

export function reviewPayRoll(
  payRollID: string,
  version: string,
  userIDs: string[],
  notificationChannel: NotificationChannel
) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(approvingPayRoll(payRollID))
    return patchPayRoll(payRollID, version, 'Review', { notifyUserIDs: userIDs, notifyChannel: notificationChannel })
      .then(() => {
        dispatch(approvedPayRoll(payRollID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedApprovingPayRoll(payRollID, e))
        }
      })
  }
}

export function reopenPayRoll(payRollID: string, version: string, note?: string, notify?: boolean) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(approvingPayRoll(payRollID))
    return patchPayRoll(payRollID, version, 'RevertApproval', {
      notificationMessage: note,
      notifyPreviousApprovers: notify,
    })
      .then(() => {
        dispatch(approvedPayRoll(payRollID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedApprovingPayRoll(payRollID, e))
        }
      })
  }
}

export function rejectPayRoll(payRollID: string, version: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(rejectingPayRoll(payRollID))
    return patchPayRoll(payRollID, version, 'Reject')
      .then(() => {
        dispatch(rejectedPayRoll(payRollID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedRejectingPayRoll(payRollID, e))
        }
      })
  }
}

export function restartPayRoll(payRollID: string, version: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(restartingPayRoll(payRollID))
    return patchPayRoll(payRollID, version, 'Restart')
      .then(() => {
        dispatch(restartedPayRoll(payRollID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedRestartingPayRoll(payRollID, e))
        }
      })
  }
}

export function deletePayRoll(payRollID: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(deletingPayRoll(payRollID))
    return delPayRoll(payRollID)
      .then(() => {
        dispatch(deletedPayRoll(payRollID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingPayRoll(payRollID, e))
        }
      })
  }
}

export function payRollSetPaymentMethod(payRollID: string, version: string, paymentMethod: PaymentMethod) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(changingPayRollPaymentMethod(payRollID))
    return patchPayRoll(payRollID, version, 'SetPaymentMethod', { paymentMethod: paymentMethod })
      .then(() => {
        dispatch(changedPayRollPaymentMethod(payRollID, paymentMethod))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedChangedPayRollPaymentMethod(payRollID, e))
        }
      })
  }
}

export function resendSKAT(payRollID: string, version: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(payRollResendingSKAT(payRollID))
    return patchPayRoll(payRollID, version, 'ResendSKAT')
      .then(() => {
        dispatch(payRollResentSKAT(payRollID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedPayRollResendingSKAT(payRollID, e))
        }
      })
  }
}

export function payRollSetDispositionDate(payRollID: string, dispositionDate: DateFormat) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<PayRoll | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingPayRoll(payRollID))
    return putPayRoll(payRollID, dispositionDate)
      .then((res) => {
        dispatch(updatedPayRoll(companyID, payRollID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingPayRoll(payRollID, e))
        }
      })
  }
}
