import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import AsynchronousSchedule from '../model/asynchronousSchedule'
import { ReducerAction } from '../utils/reducer-utils'

export interface AsynchronousScheduleReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  asynchronousSchedules: List<AsynchronousSchedule>
  error: Error | null
}

const Params = Record<AsynchronousScheduleReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  asynchronousSchedules: List<AsynchronousSchedule>(),
  error: null,
})

export interface AsynchronousScheduleAction extends ReducerAction {
  companyID?: string
  asynchronousSchedules?: AsynchronousSchedule[]
  asynchronousSchedule?: AsynchronousSchedule
  asynchronousScheduleID?: string
}

export default (
  state: Record<AsynchronousScheduleReducer> = Params(),
  action: AsynchronousScheduleAction = { type: '' }
): Record<AsynchronousScheduleReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.ASYNCHRONOUS_SCHEDULE_LOADING:
      return Params({
        loading: true,
        companyID: state.get('companyID'),
      })
    case ActionTypes.ASYNCHRONOUS_SCHEDULE_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('asynchronousSchedules', List<AsynchronousSchedule>(action.asynchronousSchedules))
    case ActionTypes.ASYNCHRONOUS_SCHEDULE_LOAD_FAILED:
      return state.set('loading', false).set('error', action.error || null)

    case ActionTypes.ASYNCHRONOUS_SCHEDULE_ADDING:
      return state.set('saving', true)
    case ActionTypes.ASYNCHRONOUS_SCHEDULE_ADDED:
      if (!action.asynchronousSchedule) {
        return state
      }
      idx = state
        .get('asynchronousSchedules')
        .findIndex((item) => !!action.asynchronousSchedule && item.id === action.asynchronousSchedule.id)

      if (idx === -1) {
        return state
          .set('saving', false)
          .set('loading', false)
          .set('asynchronousSchedules', state.get('asynchronousSchedules').push(action.asynchronousSchedule))
      }
      return state
        .set('saving', false)
        .set('loading', false)
        .set('asynchronousSchedules', state.get('asynchronousSchedules').set(idx, action.asynchronousSchedule))
    case ActionTypes.ASYNCHRONOUS_SCHEDULE_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.ASYNCHRONOUS_SCHEDULE_UPDATING:
      return state.set('saving', true)
    case ActionTypes.ASYNCHRONOUS_SCHEDULE_UPDATED:
      if (!action.asynchronousSchedule) {
        return state
      }
      idx = state
        .get('asynchronousSchedules')
        .findIndex((item) => !!action.asynchronousSchedule && item.id === action.asynchronousSchedule.id)

      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('loading', false)
        .set('asynchronousSchedules', state.get('asynchronousSchedules').set(idx, action.asynchronousSchedule))
    case ActionTypes.ASYNCHRONOUS_SCHEDULE_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.ASYNCHRONOUS_SCHEDULE_DELETING:
      return state.set('saving', true)
    case ActionTypes.ASYNCHRONOUS_SCHEDULE_DELETED:
      if (!action.asynchronousScheduleID) {
        return state
      }
      idx = state.get('asynchronousSchedules').findIndex((item) => item.id === action.asynchronousScheduleID)

      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('loading', false)
        .set('asynchronousSchedules', state.get('asynchronousSchedules').remove(idx))
    case ActionTypes.ASYNCHRONOUS_SCHEDULE_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
