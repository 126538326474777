import React, { ReactElement } from 'react'

import { AllReducers } from '../reducers'
import { connectToReducerInternal } from './reducer-utils-old'

export type ReducerAction = {
  type: string
  error?: Error
}

export type getStateSignature = () => AllReducers

// connectToReducer wraps the internal connectToReducer, still in JavaScript, but makes it typed
export function connectToReducer<
  Reducers extends Partial<AllReducers>,
  Actions extends Record<string, (...a: any) => any>,
  AdditionalProps extends Record<string, unknown> = Record<string, unknown>
>(
  mapStateToProps: (state: AllReducers) => Reducers,
  actions: {
    [Key in keyof Actions]: (
      ...params: Parameters<Actions[Key]>
    ) => (dispatch: React.Dispatch<any>, getState?: getStateSignature) => ReturnType<Actions[Key]>
  }
): (
  el: (props: Reducers & Actions & AdditionalProps) => ReactElement | null
) => (props: AdditionalProps) => ReactElement | null {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return connectToReducerInternal(mapStateToProps, actions)
}

// Utility function, returns either the active company ID or undefined
export function getCompanyID(getState?: getStateSignature): string | undefined {
  if (!getState) {
    return undefined
  }
  return getState()?.companies.company?.id
}

// Utility function, returns either the active user ID or undefined
export function getUserID(getState?: getStateSignature): string | undefined {
  if (!getState) {
    return undefined
  }
  return getState()?.user.id
}

type BasicReducer = {
  loaded: boolean
  loading: boolean
}

export function loadReducer(reducer: BasicReducer, load: () => void) {
  if (!reducer.loading && !reducer.loaded) {
    load()
  }
}
