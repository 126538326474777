import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import DepartmentUser from '../model/departmentUser'
import { ReducerAction } from '../utils/reducer-utils'

export interface DepartmentUserReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  departmentUsers: List<DepartmentUser>
  error: Error | null
}

const Params = Record<DepartmentUserReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  departmentUsers: List<DepartmentUser>(),
  error: null,
})

export interface DepartmentUserAction extends ReducerAction {
  companyID?: string
  userID?: string
  departmentUsers?: DepartmentUser[]
  departmentUser?: DepartmentUser
}

export default (
  state: Record<DepartmentUserReducer> = Params(),
  action: DepartmentUserAction = { type: '' }
): Record<DepartmentUserReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.DEPARTMENT_USERS_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.DEPARTMENT_USERS_LOADED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loaded: true,
        departmentUsers: List<DepartmentUser>(action.departmentUsers),
      })
    case ActionTypes.DEPARTMENT_USERS_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.DEPARTMENT_USER_PERMISSION_GRANTING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DEPARTMENT_USER_PERMISSION_GRANTED:
      if (!action.departmentUser) {
        return state
      }
      idx = state
        .get('departmentUsers')
        .findIndex(
          (item) =>
            !!action.departmentUser &&
            item.userCompanyID === action.departmentUser.userCompanyID &&
            item.departmentID === action.departmentUser.departmentID
        )
      if (idx === -1) {
        return state
          .set('saving', false)
          .set('departmentUsers', state.get('departmentUsers').set(idx, action.departmentUser))
      }
      return state.set('saving', false).set('departmentUsers', state.get('departmentUsers').push(action.departmentUser))
    case ActionTypes.DEPARTMENT_USER_PERMISSION_GRANT_FAILED:
      return state.set('saving', false).set('error', action.error || null)
    case ActionTypes.DEPARTMENT_USER_PERMISSION_REVOKING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DEPARTMENT_USER_PERMISSION_REVOKED:
      if (!action.departmentUser) {
        return state
      }
      idx = state
        .get('departmentUsers')
        .findIndex(
          (item) =>
            !!action.departmentUser &&
            item.userCompanyID === action.departmentUser.userCompanyID &&
            item.departmentID === action.departmentUser.departmentID
        )
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('departmentUsers', state.get('departmentUsers').delete(idx))
    case ActionTypes.DEPARTMENT_USER_PERMISSION_REVOKE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
