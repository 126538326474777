import React, { ReactElement, useEffect, useState } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { addCompany, updateActiveCompany } from '../actions/companies'
import { addCompanyToCompanyGroup } from '../actions/company-groups'
import { fetchSalaryCyclesWithoutPeriods } from '../api/salary-cycles'
import CompaniesAddComponent from '../components/companies-add/CompaniesAdd'
import { CompanyCreationFields } from '../model/company'
import { BasicSalaryCycle } from '../model/salaryCycle'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { UserReducer } from '../reducers/user'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  user: UserReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  addCompany: (company: CompanyCreationFields) => void
  addCompanyToCompanyGroup: (companyGroupID: string, companyID: string) => void
  updateActiveCompany: (id: string) => void
}

function CompaniesAdd(props: Reducers & Actions & RouteProps): ReactElement | null {
  const [loadingCycles, setLoadingCycles] = useState(false)
  const [salaryCycles, setSalaryCycles] = useState<BasicSalaryCycle[]>([])

  useEffect(() => {
    if (loadingCycles || salaryCycles.length > 0) {
      return
    }
    setLoadingCycles(true)
    fetchSalaryCyclesWithoutPeriods().then((res) => {
      if (!res) {
        return
      }
      setSalaryCycles(res.data)
      setLoadingCycles(false)
    })
  }, [loadingCycles, salaryCycles])

  const companyGroupID = props.location.query.companyGroupID

  return (
    <CompaniesAddComponent
      alerts={props.alerts}
      companies={props.companies}
      companyGroupID={companyGroupID}
      user={props.user}
      salaryCycles={salaryCycles}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      addCompany={props.addCompany}
      addCompanyToCompanyGroup={props.addCompanyToCompanyGroup}
      updateActiveCompany={props.updateActiveCompany}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    salaryCycles: state.salaryCycles,
    user: state.user,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    addCompany: addCompany,
    addCompanyToCompanyGroup: addCompanyToCompanyGroup,
    updateActiveCompany: updateActiveCompany,
  }
)(CompaniesAdd)
