import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import ExternalSalaryFileSetting from '../model/externalSalaryFileSetting'
import { ReducerAction } from '../utils/reducer-utils'

export interface ExternalSalaryFileSettingReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  externalSalaryFileSettings: List<ExternalSalaryFileSetting>
  error: Error | null
}

const Params = Record<ExternalSalaryFileSettingReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  externalSalaryFileSettings: List<ExternalSalaryFileSetting>(),
  error: null,
})

export interface ExternalSalaryFileSettingAction extends ReducerAction {
  companyID?: string
  externalSalaryFileSettings?: ExternalSalaryFileSetting[]
}

const comparator = (a: ExternalSalaryFileSetting, b: ExternalSalaryFileSetting) => {
  if (a.fileType === b.fileType) {
    return 0 // trust backend's sorting
  }
  return a.fileType.localeCompare(b.fileType)
}

export default (
  state: Record<ExternalSalaryFileSettingReducer> = Params(),
  action: ExternalSalaryFileSettingAction = { type: '' }
): Record<ExternalSalaryFileSettingReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.EXTERNAL_SALARY_FILE_SETTINGS_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.EXTERNAL_SALARY_FILE_SETTINGS_LOADED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loaded: true,
        externalSalaryFileSettings: List<ExternalSalaryFileSetting>(action.externalSalaryFileSettings).sort(comparator),
      })
    case ActionTypes.EXTERNAL_SALARY_FILE_SETTINGS_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.EXTERNAL_SALARY_FILE_SETTINGS_SAVING:
      return state.set('saving', true)
    case ActionTypes.EXTERNAL_SALARY_FILE_SETTINGS_SAVED:
      return state
        .set('saving', false)
        .set(
          'externalSalaryFileSettings',
          List<ExternalSalaryFileSetting>(action.externalSalaryFileSettings).sort(comparator)
        )
    case ActionTypes.EXTERNAL_SALARY_FILE_SETTINGS_SAVE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
