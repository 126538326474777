import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature } from '../actions/alerts'
import { getEmployees } from '../actions/employees'
import { addPayRoll, startZeroTaxReport } from '../actions/pay-rolls'
import { getSalaryCycles } from '../actions/salary-cycles'
import { NewPayRoll } from '../api/pay-rolls'
import PayRollsAddComponent from '../components/pay-rolls-add/PayRollsAdd'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { DateFormat } from '../model/types'
import { CompanyReducer } from '../reducers/companies'
import { EmployeeReducer } from '../reducers/employees'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  companies: CompanyReducer
  salaryCycles: SalaryCycleReducer
  employees: EmployeeReducer
}

type Actions = {
  addAlert: addAlertSignature
  getEmployees: () => void
  getSalaryCycles: () => void
  addPayRoll: (payRoll: NewPayRoll) => void
  startZeroTaxReport: (month: DateFormat) => void
}

function PayRollsAdd(props: Reducers & Actions): ReactElement | null {
  const { salaryCycles, getSalaryCycles, employees, getEmployees } = props

  useEffect(() => {
    if (!salaryCycles.loading && !salaryCycles.loaded) {
      getSalaryCycles()
    }
    if (!employees.loading && !employees.loaded) {
      getEmployees()
    }
  })

  const loading = !props.salaryCycles.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  if (!props.companies.company) {
    return null
  }

  return (
    <PayRollsAddComponent
      company={props.companies.company}
      salaryCycles={props.salaryCycles}
      employees={props.employees}
      addAlert={props.addAlert}
      addPayRoll={props.addPayRoll}
      startZeroTaxReport={props.startZeroTaxReport}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    companies: state.companies,
    employees: state.employees,
    salaryCycles: state.salaryCycles,
  }),
  {
    addAlert: addAlert,
    getEmployees: getEmployees,
    getSalaryCycles: getSalaryCycles,
    addPayRoll: addPayRoll,
    startZeroTaxReport: startZeroTaxReport,
  }
)(PayRollsAdd)
