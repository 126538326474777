import { List, Record as ImmutableRecord } from 'immutable'

import ActionTypes from '../constants/action-types'
import PayRoll, { PayRollStatus } from '../model/payRoll'
import { PaymentMethod } from '../model/transfer'
import { ReducerAction } from '../utils/reducer-utils'

export interface PayRollReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  payRolls: List<PayRoll>
  error: Error | null
}

const Params = ImmutableRecord<PayRollReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  payRolls: List<PayRoll>(),
  error: null,
})

export interface PayRollAction extends ReducerAction {
  companyID?: string
  payRolls?: PayRoll[]
  payRoll?: PayRoll
  payRollID?: string
  paymentMethod?: PaymentMethod
}

const payRollOrder: Record<PayRollStatus, number> = {
  ['Scheduled']: 10,
  ['Upcoming']: 10,
  ['InProgress']: 20,
  ['Success']: 30,
  ['Failed']: 30,
  ['Cancelled']: 30,
}
const comparator = (a: PayRoll, b: PayRoll) => {
  const order = payRollOrder[a.status] - payRollOrder[b.status]
  if (order !== 0) {
    return order
  }
  return b.salaryPeriod.end.localeCompare(a.salaryPeriod.end, 'da-dk')
}

export default (
  state: ImmutableRecord<PayRollReducer> = Params(),
  action: PayRollAction = { type: '' }
): ImmutableRecord<PayRollReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.PAY_ROLL_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.PAY_ROLL_LOADED:
    case ActionTypes.PAY_ROLL_LOADED_PARTIAL:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        loading: action.type === ActionTypes.PAY_ROLL_LOADED_PARTIAL,
        payRolls: state.get('payRolls').concat(List(action.payRolls)).sort(comparator),
      })
    case ActionTypes.PAY_ROLL_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.PAY_ROLL_SINGLE_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.PAY_ROLL_SINGLE_LOADED:
      if (!action.payRoll) {
        return state
      }
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        payRolls: List<PayRoll>([action.payRoll]).sort(comparator),
      })
    case ActionTypes.PAY_ROLL_SINGLE_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.PAY_ROLL_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.PAY_ROLL_ADDED:
      if (!action.payRoll) {
        return state
      }
      idx = state.get('payRolls').findIndex((item) => !!action.payRoll && item.id === action.payRoll.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('payRolls', state.get('payRolls').set(idx, action.payRoll).sort(comparator))
      }
      return state.set('saving', false).set('payRolls', state.get('payRolls').push(action.payRoll).sort(comparator))
    case ActionTypes.PAY_ROLL_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.PAY_ROLL_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.PAY_ROLL_UPDATED:
      if (!action.payRoll) {
        return state
      }
      idx = state.get('payRolls').findIndex((item) => item.id === action.payRollID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('payRolls', state.get('payRolls').set(idx, action.payRoll).sort(comparator))
    case ActionTypes.PAY_ROLL_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)
    case ActionTypes.PAY_ROLL_APPROVING:
    case ActionTypes.PAY_ROLL_REJECTING:
    case ActionTypes.PAY_ROLL_RESTARTING:
    case ActionTypes.PAY_ROLL_DELETING:
    case ActionTypes.PAY_ROLL_PAYMENT_METHOD_CHANGING:
    case ActionTypes.PAY_ROLL_RESENDING_SKAT:
      return state.set('saving', true).set('error', null)
    case ActionTypes.PAY_ROLL_APPROVED:
      return state.set('saving', false) //.set('payRolls', state.get('payRolls').set(idx, Object.assign(state.get('payRolls').get(idx), { isApproved: true })));
    case ActionTypes.PAY_ROLL_REJECTED:
      return state.set('saving', false) //.set('payRolls', state.get('payRolls').set(idx, Object.assign(state.get('payRolls').get(idx), { status: 'Cancelled' })));
    case ActionTypes.PAY_ROLL_RESTARTED:
      return state.set('saving', false) //.set('payRolls', state.get('payRolls').set(idx, Object.assign(state.get('payRolls').get(idx), { status: 'Scheduled' })));
    case ActionTypes.PAY_ROLL_DELETED:
      idx = state.get('payRolls').findIndex((item) => item.id === action.payRollID)
      if (idx !== -1) {
        return state.set('saving', false).set('payRolls', state.get('payRolls').delete(idx))
      }
      return state.set('saving', false)
    case ActionTypes.PAY_ROLL_PAYMENT_METHOD_CHANGED:
      idx = state.get('payRolls').findIndex((item) => item.id === action.payRollID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state
        .set('saving', false)
        .set(
          'payRolls',
          state
            .get('payRolls')
            .set(idx, Object.assign(state.get('payRolls').get(idx), { paymentMethod: action.paymentMethod }))
        )
    case ActionTypes.PAY_ROLL_RESENT_SKAT:
      return state.set('saving', false)
    case ActionTypes.PAY_ROLL_APPROVE_FAILED:
    case ActionTypes.PAY_ROLL_REJECT_FAILED:
    case ActionTypes.PAY_ROLL_RESTART_FAILED:
    case ActionTypes.PAY_ROLL_DELETE_FAILED:
    case ActionTypes.PAY_ROLL_PAYMENT_METHOD_CHANGE_FAILED:
    case ActionTypes.PAY_ROLL_RESEND_SKAT_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
