import * as Sentry from '@sentry/browser'
import React from 'react'

import {
  deleteLogin,
  fetchUser,
  postAcceptUserInvite,
  postAuthToken,
  postEmailChangeConfirm,
  postEmailChangeRequest,
  postEmailVerify,
  postLogin,
  postLoginUserID,
  postPasswordChange,
  postPasswordRequest,
  postPasswordReset,
  postUser,
  putUser,
} from '../api/user'
import ActionTypes from '../constants/action-types'
import { CompanyGroupUser } from '../model/companyGroup'
import CompanyUser from '../model/companyUser'
import User, { AccessToken } from '../model/user'
import { UserAction } from '../reducers/user'
import { createDeviceToken, getDeviceToken, setAccessToken } from '../utils/cookie-utils'
import { isRequestError } from '../utils/error-utils'
import { ChannelMFAError, RequestError } from '../utils/request-utils'

function registeringUser(email: string): UserAction {
  return {
    type: ActionTypes.USER_REGISTERING,
    email,
  }
}
function registeredUser(user: User): UserAction {
  return {
    type: ActionTypes.USER_REGISTERED,
    user,
  }
}
function failedRegisteringUser(error: Error): UserAction {
  return {
    type: ActionTypes.USER_REGISTERING_FAILED,
    error,
  }
}

function loggingOutUser(): UserAction {
  return {
    type: ActionTypes.USER_LOGGING_OUT,
  }
}
function loggedOutUser(): UserAction {
  return {
    type: ActionTypes.USER_LOGGED_OUT,
  }
}
function logoutFailedUser(error: Error): UserAction {
  return {
    type: ActionTypes.USER_LOGOUT_FAILED,
    error,
  }
}

function loggingInUser(email?: string, id?: string): UserAction {
  return {
    type: ActionTypes.USER_LOGGING_IN,
    email,
    id,
  }
}
function loggedInUser(user: User): UserAction {
  return {
    type: ActionTypes.USER_LOGGED_IN,
    user,
  }
}
function loggingInUserFailed(error: Error): UserAction {
  return {
    type: ActionTypes.USER_LOGIN_FAILED,
    error,
  }
}

function loadingUser(): UserAction {
  return {
    type: ActionTypes.USER_LOADING,
  }
}
export function loadedUser(user: User): UserAction {
  return {
    type: ActionTypes.USER_LOADED,
    user,
  }
}
function failedLoadingUser(error: Error): UserAction {
  return {
    type: ActionTypes.USER_LOAD_FAILED,
    error,
  }
}

function requestingPassword(email: string): UserAction {
  return {
    type: ActionTypes.USER_REQUESTING_PASSWORD,
    email,
  }
}
function requestedPassword(): UserAction {
  return {
    type: ActionTypes.USER_REQUESTED_PASSWORD,
  }
}
function failedRequestingPassword(error: Error): UserAction {
  return {
    type: ActionTypes.USER_REQUEST_PASSWORD_FAILED,
    error,
  }
}

function resettingPassword(): UserAction {
  return {
    type: ActionTypes.USER_RESETTING_PASSWORD,
  }
}
function didResetPassword(): UserAction {
  return {
    type: ActionTypes.USER_RESET_PASSWORD,
  }
}
function failedResettingPassword(error: Error): UserAction {
  return {
    type: ActionTypes.USER_RESET_PASSWORD_FAILED,
    error,
  }
}

function updatingUser(): UserAction {
  return {
    type: ActionTypes.USER_UPDATING,
  }
}
export function updatedUser(user: User): UserAction {
  return {
    type: ActionTypes.USER_UPDATED,
    user,
  }
}
function failedUpdatingUser(error: Error): UserAction {
  return {
    type: ActionTypes.USER_UPDATE_FAILED,
    error,
  }
}

function requestingEmailChange(): UserAction {
  return {
    type: ActionTypes.USER_REQUESTING_EMAIL_CHANGE,
  }
}
function requestedEmailChange(): UserAction {
  return {
    type: ActionTypes.USER_REQUESTED_EMAIL_CHANGE,
  }
}
function failedRequestingEmailChange(error: Error): UserAction {
  return {
    type: ActionTypes.USER_REQUEST_EMAIL_CHANGE_FAILED,
    error,
  }
}

function confirmingEmailChange(): UserAction {
  return {
    type: ActionTypes.USER_CONFIRMING_EMAIL_CHANGE,
  }
}
function confirmedEmailChange(user: User): UserAction {
  return {
    type: ActionTypes.USER_CONFIRMED_EMAIL_CHANGE,
    user,
  }
}
function failedConfirmingEmailChange(error: Error): UserAction {
  return {
    type: ActionTypes.USER_CONFIRM_EMAIL_CHANGE_FAILED,
    error,
  }
}

function verifyingEmail(): UserAction {
  return {
    type: ActionTypes.USER_VERIFYING_EMAIL,
  }
}
function verifiedEmail(): UserAction {
  return {
    type: ActionTypes.USER_VERIFIED_EMAIL,
  }
}
function failedVerifyingEmail(error: Error): UserAction {
  return {
    type: ActionTypes.USER_VERIFY_EMAIL_FAILED,
    error,
  }
}

function changingPassword(): UserAction {
  return {
    type: ActionTypes.USER_CHANGING_PASSWORD,
  }
}
function changedPassword(): UserAction {
  return {
    type: ActionTypes.USER_CHANGED_PASSWORD,
  }
}
function failedChangingPassword(error: Error): UserAction {
  return {
    type: ActionTypes.USER_CHANGE_PASSWORD_FAILED,
    error,
  }
}

function acceptingUserInvite(): UserAction {
  return {
    type: ActionTypes.USER_ACCEPTING_INVITE,
  }
}
function acceptedUserInvite(): UserAction {
  return {
    type: ActionTypes.USER_ACCEPTED_INVITE,
  }
}
function failedAcceptingUserInvite(error: Error): UserAction {
  return {
    type: ActionTypes.USER_ACCEPT_INVITE_FAILED,
    error,
  }
}

export function register(
  email: string,
  password: string,
  name: string,
  phoneNumber: string,
  phoneNumberCountryCode: string
) {
  return (dispatch: React.Dispatch<any>): Promise<AccessToken | void> => {
    dispatch(registeringUser(email))
    return postUser(email, password, name, phoneNumber, phoneNumberCountryCode)
      .then((res) => {
        setAccessToken(res.data.accessToken)
        Sentry.setUser({ id: res.data.user.id })
        dispatch(registeredUser(res.data.user))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedRegisteringUser(e))
        }
      })
  }
}

export function logout() {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(loggingOutUser())
    return deleteLogin()
      .then(() => {
        setAccessToken(null)
        Sentry.setUser(null)
        dispatch(loggedOutUser())
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(logoutFailedUser(e))
          return e
        }
      })
  }
}

export function login(
  email: string,
  password: string,
  challengeID?: string,
  response?: string,
  recoveryCode?: string,
  trustDevice = false
) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(loggingInUser(email))
    let deviceToken = getDeviceToken()
    if (trustDevice && !deviceToken) {
      deviceToken = createDeviceToken()
    }
    return postLogin(email, password, deviceToken, challengeID, response, recoveryCode, trustDevice)
      .then((res) => {
        setAccessToken(res.data.accessToken)
        Sentry.setUser({ id: res.data.user.id })
        dispatch(loggedInUser(res.data.user))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(loggingInUserFailed(e))
          return e
        }
      })
  }
}

export function loginUserID(userID: string, mfaChallengeID?: string, mfaResponse?: string) {
  return (dispatch: React.Dispatch<any>): Promise<AccessToken | RequestError> => {
    dispatch(loggingInUser(undefined, userID))
    return postLoginUserID(userID, mfaChallengeID, mfaResponse)
      .then((res) => {
        setAccessToken(res.data.accessToken)
        Sentry.setUser({ id: res.data.user.id })
        dispatch(loggedInUser(res.data.user))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(loggingInUserFailed(e))
          return e
        }
      })
  }
}

export function webTokenAuth(
  webToken: string,
  mfaChallengeID?: string,
  mfaResponse?: string,
  recoveryCode?: string,
  trustDevice = false
) {
  return (dispatch: React.Dispatch<any>): Promise<User | ChannelMFAError> => {
    dispatch(loggingInUser())
    return postAuthToken(webToken, mfaChallengeID, mfaResponse, recoveryCode, trustDevice)
      .then((res) => {
        setAccessToken(res.data.accessToken)
        Sentry.setUser({ id: res.data.user.id })
        dispatch(loggedInUser(res.data.user))
        return res.data.user
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(loggingInUserFailed(e))
        }
        return e
      })
  }
}

export function requestPassword(email: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(requestingPassword(email))
    return postPasswordRequest(email)
      .then(() => {
        dispatch(requestedPassword())
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedRequestingPassword(e))
        }
      })
  }
}

export function resetPassword(token: string, password: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(resettingPassword())
    return postPasswordReset(token, password)
      .then(() => {
        dispatch(didResetPassword())
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedResettingPassword(e))
        }
      })
  }
}

export function updateUser(user: User) {
  return (dispatch: React.Dispatch<any>): Promise<User | void> => {
    dispatch(updatingUser())
    return putUser(user)
      .then((res) => {
        dispatch(updatedUser(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingUser(e))
        }
      })
  }
}

export function requestEmailChange(email: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(requestingEmailChange())
    return postEmailChangeRequest(email)
      .then(() => {
        dispatch(requestedEmailChange())
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedRequestingEmailChange(e))
        }
      })
  }
}

export function confirmEmailChange(token: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(confirmingEmailChange())
    return postEmailChangeConfirm(token)
      .then((res) => {
        dispatch(confirmedEmailChange(res.data))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedConfirmingEmailChange(e))
        }
      })
  }
}

export function verifyEmail(token: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(verifyingEmail())
    return postEmailVerify(token)
      .then(() => {
        dispatch(verifiedEmail())
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedVerifyingEmail(e))
        }
      })
  }
}

export function changePassword(oldPassword: string, newPassword: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(changingPassword())
    return postPasswordChange(oldPassword, newPassword)
      .then(() => {
        dispatch(changedPassword())
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedChangingPassword(e))
        }
      })
  }
}

export function acceptUserInvite(token: string) {
  return (dispatch: React.Dispatch<any>): Promise<(CompanyUser & CompanyGroupUser) | void> => {
    dispatch(acceptingUserInvite())
    return postAcceptUserInvite(token)
      .then((res) => {
        dispatch(acceptedUserInvite())
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAcceptingUserInvite(e))
        }
      })
  }
}

export function getUser() {
  return (dispatch: React.Dispatch<any>): Promise<User | void> => {
    dispatch(loadingUser())
    return fetchUser()
      .then((res) => {
        dispatch(loadedUser(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingUser(e))
        }
      })
  }
}
