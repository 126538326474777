import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import AsynchronousTask from '../model/asynchronousTask'
import { getDate, isTimeAfter } from '../utils/date-utils'
import { ReducerAction } from '../utils/reducer-utils'

export interface AsynchronousTaskReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  asynchronousTasks: List<AsynchronousTask>
  error: Error | null
}

const Params = Record<AsynchronousTaskReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  asynchronousTasks: List<AsynchronousTask>(),
  error: null,
})

export interface AsynchronousTaskAction extends ReducerAction {
  companyID?: string
  asynchronousTasks?: AsynchronousTask[]
  asynchronousTask?: AsynchronousTask
}

export default (
  state: Record<AsynchronousTaskReducer> = Params(),
  action: AsynchronousTaskAction = { type: '' }
): Record<AsynchronousTaskReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.ASYNCHRONOUS_TASK_LOADING:
      return Params({
        loading: true,
        companyID: state.get('companyID'),
      })
    case ActionTypes.ASYNCHRONOUS_TASK_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set(
          'asynchronousTasks',
          List<AsynchronousTask>(
            action.asynchronousTasks?.filter(
              // async tasks before 5 August 2024 might be borked
              // so let's just hide them
              // at some point the backend will remove them for us
              (task) => !task.startedAt || isTimeAfter(task.startedAt, getDate('2024-08-05'))
            )
          )
        )
    case ActionTypes.ASYNCHRONOUS_TASK_LOAD_FAILED:
      return state.set('loading', false).set('error', action.error || null)

    case ActionTypes.ASYNCHRONOUS_TASK_START:
      return state.set('saving', true)
    case ActionTypes.ASYNCHRONOUS_TASK_SAVED:
      if (!action.asynchronousTask) {
        return state
      }
      idx = state
        .get('asynchronousTasks')
        .findIndex((item) => !!action.asynchronousTask && item.id === action.asynchronousTask.id)

      if (idx === -1) {
        return state
          .set('saving', false)
          .set('loading', false)
          .set('asynchronousTasks', state.get('asynchronousTasks').push(action.asynchronousTask))
      }
      return state
        .set('saving', false)
        .set('loading', false)
        .set('asynchronousTasks', state.get('asynchronousTasks').set(idx, action.asynchronousTask))
    case ActionTypes.ASYNCHRONOUS_TASK_START_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
