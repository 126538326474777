import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { addAlertSignature } from '../../../actions/alerts'
import CoarseSalaryRegistration from '../../../model/coarseSalaryRegistration'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import { SalaryDefinition } from '../../../model/remuneration'
import SalaryPeriod from '../../../model/salaryPeriod'
import SalaryType from '../../../model/salaryType'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, formatNumber, parseInputNumber } from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'

type Props = {
  employee: Employee
  contract: Contract
  period: SalaryPeriod
  coarseSalaryRegistrations: CoarseSalaryRegistration[]
  salaryTypes: List<SalaryType>

  addAlert: addAlertSignature
}

type RegistrationFields = {
  quantity: string
  rate: string
  editable: boolean
}

type Fields = {
  registrations: Record<string, RegistrationFields>
}

type ResultRegistrationFields = {
  quantity: number
  rate: number
}

export type ResultFields = {
  registrations: Record<string, ResultRegistrationFields>
}

function CoarseSalaryRegistrationForm(props: Props & FormComponentProps<Fields, ResultFields>): ReactElement | null {
  const { decorateAnyField } = props

  const knownTypes: string[] = []
  const types = props.contract.remuneration
    ? props.contract.remuneration.salary.filter((salary: SalaryDefinition) => {
        const salaryType = props.salaryTypes.find((type) => type.id === salary.salaryTypeID)
        if (!salaryType) {
          return false
        }
        if (knownTypes.indexOf(salary.salaryTypeID) !== -1) {
          return false
        }
        knownTypes.push(salary.salaryTypeID)
        return salaryType.class === 'SupplementVaried'
      })
    : []
  return (
    <div>
      {props.getFormError()}
      {types.map((salary) => {
        let description = t('salary_registrations_tab.coarse.form.description')
        let rateDescription = t('salary_registrations_tab.coarse.form.description_rate')
        const title = salary.title
        if (title) {
          description = t('salary_registrations_tab.coarse.form.description.title', { title })
          rateDescription = t('salary_registrations_tab.coarse.form.description_rate.title', { title })
        }
        return (
          <Row key={salary.salaryTypeID}>
            <Col span={12}>
              {decorateAnyField(`registrations.${salary.salaryTypeID}.quantity`, {
                title: description,
                placeholder: t('salary_registrations_tab.coarse.form.quantity'),
                suffix: t('salary_registrations_tab.coarse.form.quantity.suffix'),
                validate: (val: string) => {
                  if (!val) {
                    return t('salary_registrations_tab.coarse.form.quantity.required', { description })
                  }
                  if (forceParseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                    return t('salary_registrations_tab.coarse.form.quantity.invalid', { description })
                  }
                  return null
                },
              })(<Input />)}
            </Col>
            <Col span={12}>
              {decorateAnyField(`registrations.${salary.salaryTypeID}.rate`, {
                title: rateDescription,
                placeholder: t('salary_registrations_tab.coarse.form.rate'),
                suffix: t('salary_registrations_tab.coarse.form.rate.suffix'),
                validate: (val: string) => {
                  if (!val) {
                    return t('salary_registrations_tab.coarse.form.rate.required', { description: rateDescription })
                  }
                  if (forceParseInputNumber(val) < 0 || !val.toString().match(/^[0-9,.]+$/)) {
                    return t('salary_registrations_tab.coarse.form.rate.invalid', { description: rateDescription })
                  }
                  return null
                },
              })(<Input />)}
            </Col>
          </Row>
        )
      })}
      {props.coarseSalaryRegistrations
        .filter((registration) => knownTypes.indexOf(registration.salaryTypeID) === -1)
        .map((registration) => {
          const salaryType = props.salaryTypes.find((type) => registration.salaryTypeID === type.id)
          if (!salaryType) {
            return null
          }
          return (
            <Row>
              <Col span={24}>
                {salaryType.title}: {formatNumber(registration.quantity, 2)}
              </Col>
            </Row>
          )
        })}
      <Row>
        <Col span={10}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, ResultFields>({
  mapPropsToFields: (props) => {
    const registrations: Record<string, RegistrationFields> = {}
    if (props.employee.activeContract && props.employee.activeContract.remuneration) {
      props.employee.activeContract.remuneration.salary
        .filter((salary) => {
          const salaryType = props.salaryTypes.find((type) => type.id === salary.salaryTypeID)
          return salaryType && salaryType.class === 'SupplementVaried'
        })
        .forEach((salary) => {
          const registration = props.coarseSalaryRegistrations.find(
            (registration) => registration.salaryTypeID === salary.salaryTypeID
          )
          registrations[salary.salaryTypeID] = {
            rate: formatInputNumber(salary.rate, 2),
            quantity: registration ? formatInputNumber(registration.quantity, 2) : '0',
            editable: true,
          }
        })
    }
    return {
      registrations,
    }
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    if (key.match(/^registrations\.(.+)$/)) {
      setByPath(values, key, formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })))
    } else {
      setByPath(values, key, val)
    }
    return values
  },
  onSubmit: (values) => {
    const registrations: Record<string, ResultRegistrationFields> = {}
    Object.keys(values.registrations).forEach((salaryTypeID) => {
      registrations[salaryTypeID] = {
        rate: forceParseInputNumber(values.registrations[salaryTypeID].rate),
        quantity: forceParseInputNumber(values.registrations[salaryTypeID].quantity),
      }
    })
    return {
      registrations,
    }
  },
})(CoarseSalaryRegistrationForm)
