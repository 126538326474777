import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import User from '../../model/user'
import { UserReducer } from '../../reducers/user'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Title from '../elements/Title'
import ProfileForm, { ProfileResult } from './ProfileForm'

type Props = {
  user: UserReducer

  addAlert: addAlertSignature
  updateUser: (user: User) => void
}

export default function ProfileTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { user } = props

  useEffect(() => {
    regularComponentDidUpdate(user.error, error, setError)
  }, [user, error])

  const { addAlert } = props
  const previousUser = usePrevious(user)

  useEffect(() => {
    // Check for save callback
    if (previousUser && previousUser.requestingEmailChange && !user.requestingEmailChange) {
      // Check for no error occurred
      if (!user.error) {
        addAlert('success', t('form.message.email_change_success_text'), { timeout: 3 })
      }
    }
  })

  const handleSubmit = (values: ProfileResult) => {
    if (!props.user.user) {
      return
    }
    props.updateUser({ ...props.user.user, ...values })
  }

  return (
    <Card>
      <Title>{t('account.profile')}</Title>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <ProfileForm user={props.user} onSubmit={handleSubmit} />
    </Card>
  )
}
