import Company from '../model/company'
import Contract from '../model/contract'
import { AffiliationType } from '../model/employee'
import SalaryCycle from '../model/salaryCycle'

export function getSalaryCycle(salaryCycles: SalaryCycle[], id: string): SalaryCycle | undefined {
  return salaryCycles.find((cycle) => cycle.id === id)
}

export function getAvailableCycles(
  company: Company,
  salaryCycles: SalaryCycle[],
  asHourlyPaid: boolean,
  employee?: {
    affiliationType: AffiliationType
    activeContract?: Contract
  }
): SalaryCycle[] {
  const cycleMap: Record<string, boolean> = {}
  salaryCycles.forEach((salaryCycle) => {
    if (asHourlyPaid) {
      if (salaryCycle.frequency === 'Monthly') {
        if (!salaryCycle.offset) {
          cycleMap[salaryCycle.id] = true
        }
      } else if (salaryCycle.frequency === 'BiWeekly') {
        if (!company.defaultBiweeklyCycleID) {
          cycleMap[salaryCycle.id] = true
        }
      } else {
        cycleMap[salaryCycle.id] = true
      }
    } else {
      if (!salaryCycle.prepaid && !salaryCycle.offset) {
        if (salaryCycle.frequency === 'BiWeekly') {
          if (!company.defaultBiweeklyCycleID) {
            cycleMap[salaryCycle.id] = true
          }
        } else {
          cycleMap[salaryCycle.id] = true
        }
      }
    }
  })
  if (company.defaultHourlyMonthlyCycleID) {
    cycleMap[company.defaultHourlyMonthlyCycleID] = true
  }
  if (company.defaultBiweeklyCycleID) {
    cycleMap[company.defaultBiweeklyCycleID] = true
  }
  if (employee) {
    const contract = employee.activeContract
    if (contract) {
      cycleMap[contract.salaryCycleID] = true
    }
  }
  const cycles = Object.keys(cycleMap)
  const availableCycles = salaryCycles.filter(
    (salaryCycle) => !salaryCycle.prepaid && cycles.indexOf(salaryCycle.id) !== -1
  )
  return availableCycles.sort((a, b) => {
    return (b.offset || 0) - (a.offset || 0)
  })
}
