import * as Sentry from '@sentry/browser'
import React from 'react'

import { BootstrapResponse, fetchBootstrap } from '../api/bootstrap'
import ActionTypes from '../constants/action-types'
import User from '../model/user'
import { GlobalMessageAction } from '../reducers/globalMessage'
import { getActiveCompany, setClock } from '../utils/cookie-utils'
import { isRequestError } from '../utils/error-utils'
import { ReducerAction } from '../utils/reducer-utils'
import { loadedAgreements } from './agreements'
import { loadedCompanies, loadedSupplementTypes } from './companies'
import { loadedCompanyFeatures } from './company-features'
import { loadedCompanyPricingPackages } from './company-pricing-packages'
import { loadedCompanyPricings } from './company-pricings'
import { loadedCostCenters } from './cost-centers'
import { loadedDepartments } from './departments'
import { loadedEmployees } from './employees'
import { loadedLeaveTypes } from './leave-types'
import { loadedPricingPackages } from './pricing-packages'
import { loadedSalaryTypes } from './salary-types'
import { getSupportCompaniesThenLoadCompanies } from './support-access'
import { loadedUser } from './user'
import { loadedUserCompanies } from './user-companies'

function loadingBootstrap(): ReducerAction {
  return {
    type: ActionTypes.BOOTSTRAP_LOADING,
  }
}
function loadedBootstrap(): ReducerAction {
  return {
    type: ActionTypes.BOOTSTRAP_LOADED,
  }
}
function failedLoadingBootstrap(error: Error): ReducerAction {
  return {
    type: ActionTypes.BOOTSTRAP_LOAD_FAILED,
    error,
  }
}

function loadedGlobalMessage(message: string): GlobalMessageAction {
  return {
    type: ActionTypes.GLOBAL_MESSAGE_LOADED,
    message,
  }
}

function loadBootstrapElements(companyID: string | undefined, dispatch: React.Dispatch<any>, data: BootstrapResponse) {
  dispatch(loadedUserCompanies(data.userCompanies))
  if (data.globalMessage) {
    dispatch(loadedGlobalMessage(data.globalMessage))
  }
  if (companyID && data.agreements !== null) {
    dispatch(loadedAgreements(companyID, data.agreements))
  }
  if (companyID && data.companyFeatures !== null) {
    dispatch(loadedCompanyFeatures(companyID, data.companyFeatures))
  }
  if (companyID && data.companyPricingPackages !== null) {
    dispatch(loadedCompanyPricingPackages(companyID, data.companyPricingPackages))
  }
  if (companyID && data.companyPricings !== null) {
    dispatch(loadedCompanyPricings(companyID, data.companyPricings))
  }
  if (companyID && data.costCenters !== null) {
    dispatch(loadedCostCenters(companyID, data.costCenters))
  }
  if (companyID && data.departments !== null) {
    dispatch(loadedDepartments(companyID, data.departments))
  }
  if (companyID && data.employees !== null) {
    dispatch(loadedEmployees(companyID, data.employees))
  }
  if (companyID && data.leaveTypes !== null) {
    dispatch(loadedLeaveTypes(companyID, data.leaveTypes))
  }
  if (companyID && data.pricingPackages !== null) {
    dispatch(loadedPricingPackages(data.pricingPackages))
  }
  if (companyID && data.salaryTypes !== null) {
    dispatch(loadedSalaryTypes(companyID, data.salaryTypes))
  }
  if (companyID && data.supplementTypes !== null) {
    dispatch(loadedSupplementTypes(companyID, data.supplementTypes))
  }
  dispatch(loadedBootstrap())
}

export type BootstrapResult = {
  user: User
}

export function getBootstrap(justLoggedIn: boolean, includeActiveCompany = true, forceSettingActiveCompany = false) {
  return (dispatch: React.Dispatch<any>): Promise<BootstrapResult | void> => {
    dispatch(loadingBootstrap())
    let companyID: string | undefined
    if (includeActiveCompany) {
      companyID = getActiveCompany()
    }
    return fetchBootstrap(companyID)
      .then((res) => {
        setClock(res.data.currentTime || null)
        Sentry.setUser({ id: res.data.user.id })
        dispatch(loadedUser(res.data.user))
        // make sure we load support companies before direct companies
        if (res.data.user.userType === 'Admin' || res.data.user.userType === 'Support') {
          getSupportCompaniesThenLoadCompanies(
            res.data.companies,
            !forceSettingActiveCompany &&
              justLoggedIn &&
              res.data.companies.length > 1 &&
              res.data.user.showCompanyListOnLogIn
          )(dispatch).then(() => {
            // make sure the elements are loaded afterwards
            loadBootstrapElements(companyID, dispatch, res.data)
          })
        } else {
          dispatch(
            loadedCompanies(
              res.data.companies,
              !forceSettingActiveCompany &&
                justLoggedIn &&
                res.data.companies.length > 1 &&
                res.data.user.showCompanyListOnLogIn
            )
          )
          loadBootstrapElements(companyID, dispatch, res.data)
        }
        return {
          user: res.data.user,
        }
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingBootstrap(e))
        }
      })
  }
}
