import React, { CSSProperties, ReactElement } from 'react'

import Popover from './popover'

import './Elements.css'

// NEED_NO_TRANSLATION

export default function ContextMenu(props: { style?: CSSProperties; children?: React.ReactNode }): ReactElement {
  return (
    <Popover
      placement="bottomRight"
      content={props.children}
      trigger="click"
      overlayClassName="context-menu-popover"
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <div>
        <div style={props.style} />
      </div>
    </Popover>
  )
}
