import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Voucher from '../model/voucher'
import { ReducerAction } from '../utils/reducer-utils'

export interface VoucherReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  payRollID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  vouchers: List<Voucher>
  error: Error | null
}

const Params = Record<VoucherReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  payRollID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  vouchers: List<Voucher>(),
  error: null,
})

export interface VoucherAction extends ReducerAction {
  companyID?: string
  payRollID?: string
  vouchers?: Voucher[]
  voucher?: Voucher
  voucherID?: string
}

const comparator = (a: Voucher, b: Voucher) => {
  return b.date.localeCompare(a.date, 'da-dk')
}

export default (
  state: Record<VoucherReducer> = Params(),
  action: VoucherAction = { type: '' }
): Record<VoucherReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.VOUCHERS_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        payRollID: action.payRollID,
        loading: true,
      })
    case ActionTypes.VOUCHERS_LOADED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        payRollID: action.payRollID,
        loaded: true,
        vouchers: List<Voucher>(action.vouchers).sort(comparator),
      })
    case ActionTypes.VOUCHERS_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        payRollID: action.payRollID,
        error: action.error,
      })

    case ActionTypes.VOUCHER_ADDED:
      if (!action.voucher) {
        return state
      }
      // only process actions for the active pay roll
      if (state.get('payRollID') && action.payRollID !== state.get('payRollID')) {
        return state
      }
      idx = state.get('vouchers').findIndex((item) => !!action.voucher && item.id === action.voucher.id)
      if (idx !== -1) {
        return state.set('vouchers', state.get('vouchers').set(idx, action.voucher))
      }
      return state.set('vouchers', state.get('vouchers').push(action.voucher))

    case ActionTypes.VOUCHER_UPDATING:
      idx = state.get('vouchers').findIndex((item) => item.id === action.voucherID)
      if (idx === -1) {
        return state
      }
      return state.set('saving', true)
    case ActionTypes.VOUCHER_UPDATED:
      if (!action.voucher) {
        return state
      }
      idx = state.get('vouchers').findIndex((item) => item.id === action.voucherID)
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('vouchers', state.get('vouchers').set(idx, action.voucher).sort(comparator))
    case ActionTypes.VOUCHER_UPDATE_FAILED:
      idx = state.get('vouchers').findIndex((item) => item.id === action.voucherID)
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.VOUCHER_DELETED:
      idx = state.get('vouchers').findIndex((item) => item.id === action.voucherID)
      if (idx === -1) {
        return state
      }
      return state.set('vouchers', state.get('vouchers').delete(idx))

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
