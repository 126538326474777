import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Asset from '../model/asset'
import { ReducerAction } from '../utils/reducer-utils'

export interface AssetReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  assets: List<Asset>
  error: Error | null
}

const Params = Record<AssetReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  assets: List<Asset>(),
  error: null,
})

const comparator = (a: Asset, b: Asset) => {
  return a.title.localeCompare(b.title, 'da-dk')
}

export interface AssetAction extends ReducerAction {
  companyID?: string
  assets?: Asset[]
  asset?: Asset
  assetID?: string
}

export default (state: Record<AssetReducer> = Params(), action: AssetAction = { type: '' }): Record<AssetReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    // Loading actions
    case ActionTypes.ASSET_LOADING:
      return Params({
        loading: true,
        companyID: action.companyID || state.get('companyID'),
      })
    case ActionTypes.ASSET_LOADED:
    case ActionTypes.ASSET_LOADED_PARTIAL:
      return Params({
        loading: action.type === ActionTypes.ASSET_LOADED_PARTIAL,
        loaded: true,
        companyID: action.companyID || state.get('companyID'),
        assets: state.get('assets').concat(List(action.assets)).sort(comparator),
      })
    case ActionTypes.ASSET_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error || null,
      })

    // Creating actions
    case ActionTypes.ASSET_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ASSET_CREATED:
      if (!action.asset) {
        return state
      }
      idx = state.get('assets').findIndex((item) => !!action.asset && item.id === action.asset.id)
      if (idx !== -1) {
        return state.set('saving', false).set('assets', state.get('assets').set(idx, action.asset).sort(comparator))
      }
      return state.set('saving', false).set('assets', state.get('assets').push(action.asset).sort(comparator))
    case ActionTypes.ASSET_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    // Updating actions
    case ActionTypes.ASSET_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ASSET_UPDATED:
      if (!action.asset) {
        return state
      }
      idx = state.get('assets').findIndex((item) => item.id === action.assetID)
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('assets', state.get('assets').set(idx, action.asset).sort(comparator))
    case ActionTypes.ASSET_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    // Deleting actions
    case ActionTypes.ASSET_DELETING:
      return state.set('saving', true).set('error', null)

    case ActionTypes.ASSET_DELETED:
      if (action.assetID) {
        idx = state.get('assets').findIndex((item) => {
          return item.id === action.assetID
        })
      }
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('assets', state.get('assets').delete(idx))
    case ActionTypes.ASSET_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
