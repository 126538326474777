import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Contract from '../model/contract'
import DepartmentUser from '../model/departmentUser'
import Employee from '../model/employee'
import Remuneration from '../model/remuneration'
import { compareEmployees } from '../utils/employee-utils'
import { ReducerAction } from '../utils/reducer-utils'

interface HashMap {
  [key: string]: boolean
}

export interface EmployeeReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  departmentIDs: List<string> // list of department IDs to keep track if the user only has access to these
  loading: boolean
  loaded: boolean
  saving: boolean
  employees: List<Employee>
  hashMap: HashMap
  error: Error | null
}

const Params = Record<EmployeeReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  departmentIDs: List<string>(), // list of department IDs to keep track if the user only has access to these
  loading: false,
  loaded: false,
  saving: false,
  employees: List<Employee>(),
  hashMap: {},
  error: null,
})

export interface EmployeeAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  employmentID?: string
  contractID?: string
  employees?: Employee[]
  employee?: Employee
  contracts?: Contract[]
  contract?: Contract
  departmentUsers?: DepartmentUser[]
  remuneration?: Remuneration
}

const comparator = (a: Employee, b: Employee) => {
  return compareEmployees(a, b)
}

export default (
  state: Record<EmployeeReducer> = Params(),
  action: EmployeeAction = { type: '' }
): Record<EmployeeReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  if (action.type === ActionTypes.DEPARTMENT_USERS_LOADED) {
    if (!action.departmentUsers) {
      return state
    }
    return Params({
      companyID: state.get('companyID'),
      departmentIDs: List<string>(action.departmentUsers.map((departmentUser) => departmentUser.departmentID)),
      employees: state.get('employees'),
      hashMap: state.get('hashMap'),
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  const employees = state.get('employees').toArray()
  let employee = null
  const hashMap = state.get('hashMap')
  switch (action.type) {
    case ActionTypes.EMPLOYEES_LOADING:
      return Params({
        companyID: state.get('companyID'),
        departmentIDs: state.get('departmentIDs'),
        hashMap: {},
        loading: true,
      })
    case ActionTypes.EMPLOYEES_LOADED:
    case ActionTypes.EMPLOYEES_LOADED_PARTIAL:
      if (!action.employees) {
        return state
      }
      action.employees.forEach((employee) => {
        if (hashMap[employee.id]) {
          return
        }
        employees.push(employee)
        hashMap[employee.id] = true
      })
      return Params({
        companyID: state.get('companyID'),
        departmentIDs: state.get('departmentIDs'),
        loaded: true,
        loading: action.type === ActionTypes.EMPLOYEES_LOADED_PARTIAL,
        employees: List(employees).sort(comparator),
        hashMap,
      })
    case ActionTypes.EMPLOYEES_LOAD_FAILED:
      return state
        .set('loaded', false)
        .set('loading', false)
        .set('error', action.error || null)

    case ActionTypes.REMUNERATION_LOADING:
      return state.set('loading', true)
    case ActionTypes.REMUNERATION_LOADED:
      if (!action.remuneration) {
        return state
      }
      idx = employees.findIndex((item) => item.id === action.employeeID)
      if (idx === -1) {
        return state
      }
      employee = employees[idx]
      if (!employee) {
        return state.set('loading', false)
      }
      if (employee.earliestMutableContract && employee.earliestMutableContract.id === action.contractID) {
        employee.earliestMutableContract.remuneration = action.remuneration
      }
      if (employee.activeContract && employee.activeContract.id === action.contractID) {
        employee.activeContract.remuneration = action.remuneration
      }
      return state.set('loading', false).set('employees', state.get('employees').set(idx, employee).sort(comparator))
    case ActionTypes.REMUNERATION_LOAD_FAILED:
      return state.set('loading', false).set('error', action.error || null)

    case ActionTypes.EMPLOYEE_CONTRACT_LOADED:
    case ActionTypes.EMPLOYEE_CONTRACT_LOADED_PARTIAL:
      if (!action.contracts) {
        return state
      }
      action.contracts.forEach((contract) => {
        let idx = employees.findIndex(
          (item) => item.earliestMutableContract && item.earliestMutableContract.id === contract.id
        )
        if (idx === -1) {
          // try on active contract
          idx = employees.findIndex((item) => item.activeContract && item.activeContract.id === contract.id)
        }
        if (idx === -1) {
          return
        }
        const employee = employees[idx]
        if (!employee) {
          return
        }
        if (employee.earliestMutableContract && employee.earliestMutableContract.id === contract.id) {
          employee.earliestMutableContract.remuneration = contract.remuneration
        }
        if (employee.activeContract && employee.activeContract.id === contract.id) {
          employee.activeContract.remuneration = contract.remuneration
        }
        employees[idx] = employee
      })
      return state.set('employees', List(employees).sort(comparator))

    case ActionTypes.EMPLOYEE_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EMPLOYEE_ADDED:
      if (!action.employee) {
        return state
      }
      if (hashMap[action.employee.id]) {
        return state // already added
      }
      idx = state.get('employees').findIndex((item) => !!action.employee && item.id === action.employee.id)
      if (idx !== -1) {
        return state // already added
      }
      hashMap[action.employee.id] = true
      return state
        .set('saving', false)
        .set('employees', state.get('employees').push(action.employee).sort(comparator))
        .set('hashMap', hashMap)
    case ActionTypes.EMPLOYEE_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.EMPLOYEE_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EMPLOYEE_UPDATED:
      if (!action.employee) {
        return state
      }
      if (action.employeeID) {
        idx = state.get('employees').findIndex((item) => item.id === action.employeeID)
      }
      if (action.employmentID) {
        idx = state
          .get('employees')
          .findIndex((item) => !!item.activeEmployment && item.activeEmployment.id === action.employmentID)
      }
      if (idx === -1) {
        // the employee might have been updated on a different department to this one, so let's check
        if (action.employee.departmentID && state.get('departmentIDs').indexOf(action.employee.departmentID) !== -1) {
          return state
            .set('saving', false)
            .set('employees', state.get('employees').push(action.employee).sort(comparator))
        }
        return state // otherwise not
      }
      return state
        .set('saving', false)
        .set('employees', state.get('employees').set(idx, action.employee).sort(comparator))
    case ActionTypes.EMPLOYEE_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.EMPLOYEE_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EMPLOYEE_DELETED:
      if (action.employeeID) {
        if (!hashMap[action.employeeID]) {
          return state // already deleted
        }
        idx = employees.findIndex((item) => item.id === action.employeeID)
      }
      if (idx === -1 && action.employmentID) {
        idx = employees.findIndex((item) => item.activeEmployment && item.activeEmployment.id === action.employmentID)
      }
      if (idx === -1) {
        return state
      }
      employee = employees[idx]
      if (employee && employee.id) {
        hashMap[employee && employee.id] = false
      }
      return state.set('saving', false).set('hashMap', hashMap).set('employees', state.get('employees').delete(idx))
    case ActionTypes.EMPLOYEE_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
