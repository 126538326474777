import OutgoingLead, { OutgoingLeadType } from '../model/outgoingLead'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchOutgoingLeads(companyID: string): Promise<RequestResponse<OutgoingLead[]>> {
  return secureRequest('GET', url('/v2/outgoingLeads', { companyID }))
}

export function postOutgoingLead(
  companyID: string,
  leadType: OutgoingLeadType
): Promise<RequestResponse<OutgoingLead>> {
  return secureRequest('POST', url('/v2/outgoingLeads'), {
    body: {
      companyID,
      leadType,
    },
  })
}
