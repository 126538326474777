import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import Company from '../../../model/company'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import { ContractReducer } from '../../../reducers/contracts'
import { formatSalaryCycle } from '../../../utils/format-utils'
import { t } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import EmploymentPayEdit from './EmploymentPayEdit'

type Props = {
  mutableContract: Contract
  employee: Employee
  company: Company
  contracts: ContractReducer
  salaryCycles: List<SalaryCycle>
  isMutableContract: boolean

  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
}

export default function EmploymentPayCard(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showEdit, setShowEdit] = useState(false)

  const setEditVisibility = (visible: boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setShowEdit(visible)
  }

  const { contracts } = props
  const previousContracts = usePrevious(contracts)

  useEffect(() => {
    if (previousContracts && previousContracts.saving && !contracts.saving) {
      if (!contracts.error) {
        setEditVisibility(false)
      }
    }
  })

  const getCycle = () => {
    const salaryCycle = props.salaryCycles.find((salaryCycle) => salaryCycle.id === props.mutableContract.salaryCycleID)
    if (!salaryCycle) {
      return '-'
    }
    return formatSalaryCycle(salaryCycle.frequency, salaryCycle.offset)
  }

  return (
    <Card>
      <TitleMenu>
        {props.isMutableContract && <Button className="ant-btn-edit" onClick={() => setEditVisibility(true)} />}
      </TitleMenu>
      <Title>{t('freelancer.pay.card.title')}</Title>

      <Row>
        <Col span={7}>
          {t('freelancer.pay.card.cycle')}:
          <br />
          <strong>{getCycle()}</strong>
        </Col>
      </Row>

      <Modal
        key={modalKey}
        visible={showEdit}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={376}
        footer={null}
      >
        <EmploymentPayEdit
          visible={showEdit}
          employee={props.employee}
          mutableContract={props.mutableContract}
          contracts={props.contracts}
          company={props.company}
          salaryCycles={props.salaryCycles}
          addContract={props.addContract}
          updateContract={props.updateContract}
        />
      </Modal>
    </Card>
  )
}
