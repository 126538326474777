import React, { ReactElement } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { deleteEmployee } from '../actions/employees'
import { getEmployments, terminateEmployment } from '../actions/employments'
import FreelancersComponent from '../components/freelancers/Freelancers'
import Employment from '../model/employment'
import { AlertReducer } from '../reducers/alerts'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { EmployeeReducer } from '../reducers/employees'
import { EmploymentReducer } from '../reducers/employments'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  alerts: AlertReducer
  employees: EmployeeReducer
  employments: EmploymentReducer
  companyUsers: CompanyUserReducer
  companyFeatures: CompanyFeatureReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  deleteEmployee: (employeeID: string) => Promise<boolean | void>
  terminateEmployment: (employeeID: string, employment: Employment) => Promise<boolean | void>
  getEmployments: (employeeID: string) => void
}

function Freelancers(props: Reducers & Actions): ReactElement | null {
  return (
    <FreelancersComponent
      alerts={props.alerts}
      employees={props.employees}
      employments={props.employments}
      companyUser={props.companyUsers.companyUser}
      companyFeatures={props.companyFeatures.companyFeatures}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      deleteEmployee={props.deleteEmployee}
      terminateEmployment={props.terminateEmployment}
      getEmployments={props.getEmployments}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    alerts: state.alerts,
    employees: state.employees,
    employments: state.employments,
    companyUsers: state.companyUsers,
    companyFeatures: state.companyFeatures,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    deleteEmployee: deleteEmployee,
    terminateEmployment: terminateEmployment,
    getEmployments: getEmployments,
  }
)(Freelancers)
