import { List, Record as ImmutableRecord } from 'immutable'

import ActionTypes from '../constants/action-types'
import SalaryCycle, { SalaryFrequency } from '../model/salaryCycle'
import { ReducerAction } from '../utils/reducer-utils'

export interface SalaryCycleReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  salaryCycles: List<SalaryCycle>
  error: Error | null
}

const Params = ImmutableRecord<SalaryCycleReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  salaryCycles: List<SalaryCycle>(),
  error: null,
})

export interface SalaryCycleAction extends ReducerAction {
  companyID?: string
  salaryCycles?: SalaryCycle[]
}

const order: Record<SalaryFrequency, number> = {
  Monthly: 0,
  BiWeekly: 1,
  Weekly: 2,
}
const comparator = (a: SalaryCycle, b: SalaryCycle) => {
  const diff = order[a.frequency] - order[b.frequency]
  if (diff !== 0) {
    return diff
  }
  return (a.offset || 0) - (b.offset || 0)
}

export default (
  state: ImmutableRecord<SalaryCycleReducer> = Params(),
  action: SalaryCycleAction = { type: '' }
): ImmutableRecord<SalaryCycleReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.SALARY_CYCLE_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        loading: true,
      })
    case ActionTypes.SALARY_CYCLE_LOADED:
      return Params({
        companyID: action.companyID,
        loaded: true,
        salaryCycles: List<SalaryCycle>(action.salaryCycles).sort(comparator),
      })
    case ActionTypes.SALARY_CYCLE_LOAD_FAILED:
      return Params({
        companyID: action.companyID,
        error: action.error,
      })
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
