import closest from 'dom-closest'
import Menu, { Item as MenuItem, SubMenu } from 'rc-menu'
import React from 'react'
import ReactDOM from 'react-dom'
import shallowequal from 'shallowequal'

import classNames from '../_util/classNames'
import BaseIcon from '../../elements/base-icon'
import Checkbox from '../../elements/checkbox'
import Dropdown from '../dropdown'
import Radio from '../radio'
import FilterDropdownMenuWrapper from './FilterDropdownMenuWrapper'

class FilterMenu extends React.Component {
  constructor(props) {
    super(props)

    const visible = 'filterDropdownVisible' in props.column ? props.column.filterDropdownVisible : false

    this.state = {
      selectedKeys: props.selectedKeys,
      keyPathOfSelectedItem: {}, // 记录所有有选中子菜单的祖先菜单
      visible,
    }
  }

  componentDidMount() {
    const { column } = this.props
    this.setNeverShown(column)
  }

  setNeverShown = (column) => {
    const rootNode = ReactDOM.findDOMNode(this)
    const filterBelongToScrollBody = !!closest(rootNode, `.ant-table-scroll`)
    if (filterBelongToScrollBody) {
      // When fixed column have filters, there will be two dropdown menus
      // Filter dropdown menu inside scroll body should never be shown
      // To fix https://github.com/ant-design/ant-design/issues/5010 and
      // https://github.com/ant-design/ant-design/issues/7909
      this.neverShown = !!column.fixed
    }
  }

  setSelectedKeys = ({ selectedKeys }) => {
    this.setState({ selectedKeys })
  }

  setVisible(visible) {
    const { column } = this.props
    if (!('filterDropdownVisible' in column)) {
      this.setState({ visible })
    }
    if (column.onFilterDropdownVisibleChange) {
      column.onFilterDropdownVisibleChange(visible)
    }
  }

  handleClearFilters = () => {
    this.setState(
      {
        selectedKeys: [],
      },
      this.handleConfirm
    )
  }

  handleConfirm = () => {
    this.setVisible(false)
    this.confirmFilter()
  }

  onVisibleChange = (visible) => {
    this.setVisible(visible)
    if (!visible) {
      this.confirmFilter()
    }
  }

  confirmFilter() {
    if (this.state.selectedKeys !== this.props.selectedKeys) {
      this.props.confirmFilter(this.props.column, this.state.selectedKeys)
    }
  }

  renderMenuItem(item) {
    const { column } = this.props
    const multiple = 'filterMultiple' in column ? column.filterMultiple : true
    const input = multiple ? (
      <Checkbox checked={this.state.selectedKeys.indexOf(item.value.toString()) >= 0} />
    ) : (
      <Radio checked={this.state.selectedKeys.indexOf(item.value.toString()) >= 0} />
    )

    return (
      <MenuItem key={item.value}>
        {input}
        <span>{item.text}</span>
      </MenuItem>
    )
  }

  hasSubMenu() {
    const {
      column: { filters = [] },
    } = this.props
    return filters.some((item) => !!(item.children && item.children.length > 0))
  }

  renderMenus(items) {
    return items.map((item) => {
      if (item.children && item.children.length > 0) {
        const { keyPathOfSelectedItem } = this.state
        const containSelected = Object.keys(keyPathOfSelectedItem).some(
          (key) => keyPathOfSelectedItem[key].indexOf(item.value) >= 0
        )
        const subMenuCls = containSelected ? `${this.props.dropdownPrefixCls}-submenu-contain-selected` : ''
        return (
          <SubMenu title={item.text} className={subMenuCls} key={item.value.toString()}>
            {this.renderMenus(item.children)}
          </SubMenu>
        )
      }
      return this.renderMenuItem(item)
    })
  }

  handleMenuItemClick = (info) => {
    if (info.keyPath.length <= 1) {
      return
    }
    const keyPathOfSelectedItem = this.state.keyPathOfSelectedItem
    if (this.state.selectedKeys.indexOf(info.key) >= 0) {
      // deselect SubMenu child
      delete keyPathOfSelectedItem[info.key]
    } else {
      // select SubMenu child
      keyPathOfSelectedItem[info.key] = info.keyPath
    }
    this.setState({ keyPathOfSelectedItem })
  }

  renderFilterIcon = () => {
    const { column, locale, prefixCls } = this.props
    const filterIcon = column.filterIcon
    const dropdownSelectedClass = this.props.selectedKeys.length > 0 ? `${prefixCls}-selected` : ''

    return filterIcon ? (
      React.cloneElement(filterIcon, {
        title: locale.filterTitle,
        className: classNames(filterIcon.className, {
          [`${prefixCls}-icon`]: true,
        }),
      })
    ) : (
      <BaseIcon title={locale.filterTitle} type="filter" className={dropdownSelectedClass} />
    )
  }
  render() {
    const { column, locale, prefixCls, dropdownPrefixCls, getPopupContainer } = this.props
    // default multiple selection in filter dropdown
    const multiple = 'filterMultiple' in column ? column.filterMultiple : true
    const dropdownMenuClass = classNames({
      [`${dropdownPrefixCls}-menu-without-submenu`]: !this.hasSubMenu(),
    })
    const menus = column.filterDropdown ? (
      <FilterDropdownMenuWrapper>{column.filterDropdown}</FilterDropdownMenuWrapper>
    ) : (
      <FilterDropdownMenuWrapper className={`${prefixCls}-dropdown`}>
        <Menu
          multiple={multiple}
          onClick={this.handleMenuItemClick}
          prefixCls={`${dropdownPrefixCls}-menu`}
          className={dropdownMenuClass}
          onSelect={this.setSelectedKeys}
          onDeselect={this.setSelectedKeys}
          selectedKeys={this.state.selectedKeys}
        >
          {this.renderMenus(column.filters)}
        </Menu>
        <div className={`${prefixCls}-dropdown-btns`}>
          <span
            className={`${prefixCls}-dropdown-link confirm`}
            style={{ cursor: 'pointer' }}
            onClick={this.handleConfirm}
          >
            {locale.filterConfirm}
          </span>
          <span
            className={`${prefixCls}-dropdown-link clear`}
            style={{ cursor: 'pointer' }}
            onClick={this.handleClearFilters}
          >
            {locale.filterReset}
          </span>
        </div>
      </FilterDropdownMenuWrapper>
    )

    return (
      <Dropdown
        trigger={['click']}
        overlay={menus}
        visible={this.neverShown ? false : this.state.visible}
        onVisibleChange={this.onVisibleChange}
        getPopupContainer={getPopupContainer}
      >
        {this.renderFilterIcon()}
      </Dropdown>
    )
  }
}

FilterMenu.defaultProps = {
  handleFilter() {},
  column: {},
}

FilterMenu.getDerivedStateFromProps = function (nextProps, prevState) {
  const { column } = nextProps
  const { prevProps } = prevState

  const newState = {
    prevProps: nextProps,
  }

  /**
   * if the state is visible the component should ignore updates on selectedKeys prop to avoid
   * that the user selection is lost
   * this happens frequently when a table is connected on some sort of realtime data
   * Fixes https://github.com/ant-design/ant-design/issues/10289 and
   * https://github.com/ant-design/ant-design/issues/10209
   */
  if ('selectedKeys' in nextProps && !shallowequal(prevProps.selectedKeys, nextProps.selectedKeys)) {
    newState.selectedKeys = nextProps.selectedKeys
  }
  if ('filterDropdownVisible' in column) {
    newState.visible = column.filterDropdownVisible
  }
  return newState
}

export default FilterMenu
