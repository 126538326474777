import LeaveType from '../model/leaveType'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchLeaveTypes(companyID: string): Promise<RequestResponse<LeaveType[]>> {
  return secureRequest('GET', url('/v2/leaveTypes', { companyID }))
}

export function patchLeaveType(leaveTypeID: string, employeeSelectable: boolean): Promise<RequestResponse<LeaveType>> {
  return secureRequest('PATCH', url('/v2/leaveTypes/' + leaveTypeID), {
    body: {
      action: 'setEmployeeSelectable',
      employeeSelectable,
    },
  })
}

export function patchLeaveSubTypes(
  leaveTypeID: string,
  leaveSubTypeIDs: string[]
): Promise<RequestResponse<LeaveType>> {
  return secureRequest('PATCH', url('/v2/leaveTypes/' + leaveTypeID + '/subTypes'), {
    body: {
      leaveSubTypeIDs,
    },
  })
}
