import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature } from '../../../actions/alerts'
import { CoarseSalaryRegistrationMutableFields } from '../../../model/coarseSalaryRegistration'
import Company from '../../../model/company'
import { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import { SalaryRegistrationMutableFields } from '../../../model/salaryRegistration'
import SalaryType from '../../../model/salaryType'
import { CoarseSalaryRegistrationReducer } from '../../../reducers/coarseSalaryRegistrations'
import { ContractReducer } from '../../../reducers/contracts'
import { SalaryRegistrationReducer } from '../../../reducers/salaryRegistrations'
import { paths } from '../../../routes'
import { formatAPIDate } from '../../../utils/date-utils'
import { getValidFrom } from '../../../utils/employment-utils'
import { formatSavingText } from '../../../utils/loading-utils'
import { formatCurrency } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Card from '../../elements/card'
import { RegistrationMethodAlert } from '../../elements/RegistrationMethodAlert'
import { RegistrationMethodAppAlert } from '../../elements/RegistrationMethodAppAlert'
import Switch from '../../elements/switch'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import CoarseSalaryRegistrationTab from './CoarseSalaryRegistrationTab'
import DetailedSalaryRegistrationHistoryTab from './DetailedSalaryRegistrationHistoryTab'
import DetailedSalaryRegistrationTab from './DetailedSalaryRegistrationTab'

type Props = {
  company: Company
  employee: Employee
  costCenters: List<CostCenter>
  departments: List<Department>
  contracts: ContractReducer
  coarseSalaryRegistrations: CoarseSalaryRegistrationReducer
  salaryRegistrations: SalaryRegistrationReducer
  salaryCycles: List<SalaryCycle>
  salaryCycle?: SalaryCycle
  salaryTypes: List<SalaryType>

  subsection?: string
  hasSalaryRegistrationsForPeriod: boolean
  canEditObjects: boolean
  canApproveObjects: boolean
  canHireFire: boolean

  addAlert: addAlertSignature
  updateCoarseSalaryRegistrationBulk: (
    employeeID: string,
    salaryRegistrations: CoarseSalaryRegistrationMutableFields[]
  ) => void
  updateContract: (contract: ContractMutableFields) => void
  addContract: (contract: ContractCreationFields) => void

  createSalaryRegistration: (registration: SalaryRegistrationMutableFields) => void
  approveSalaryRegistrations: (ids: string[]) => void
  unapproveSalaryRegistrations: (ids: string[]) => void
  updateSalaryRegistration: (registration: SalaryRegistrationMutableFields) => void
  deleteSalaryRegistration: (id: string) => void
  deleteSalaryRegistrationBulk: (companyID?: string, employeeID?: string) => void
}

export default function SalaryRegistrationTab(props: Props): ReactElement | null {
  const [hasDetailedSalaryRegistration, setHasDetailedSalaryRegistration] = useState(
    props.employee.activeContract && props.employee.activeContract.salaryRegistrationMethodType === 'Detailed'
  )
  const showHistory = hasDetailedSalaryRegistration && props.subsection === 'history'

  const handleChange = (value: boolean, force = false) => {
    if (!props.employee.earliestMutableContract || !props.employee.earliestMutableContract.remuneration) {
      return
    }
    setHasDetailedSalaryRegistration(value)
    const contract: ContractMutableFields = {
      ...props.employee.earliestMutableContract,
      salaryRegistrationMethodType: value ? 'Detailed' : 'Coarse',
    }
    if (force) {
      props.updateContract(contract)
      return
    }
    const { validFrom, canUpdate } = getValidFrom(props.employee, contract)
    if (canUpdate) {
      props.updateContract(contract)
    } else {
      contract.validFrom = formatAPIDate(validFrom)
      props.addContract(contract)
    }
  }

  const deleteDetailed = () => {
    props.deleteSalaryRegistrationBulk(undefined, props.employee.id)
  }
  const deleteCoarse = () => {
    props.updateCoarseSalaryRegistrationBulk(
      props.employee.id,
      props.coarseSalaryRegistrations.coarseSalaryRegistrations
        .filter((registration) => registration.employeeID === props.employee.id && !registration.immutable)
        .reduce((fields: CoarseSalaryRegistrationMutableFields[], registration) => {
          fields.push({
            id: registration.id,
            employeeID: registration.employeeID,
            salaryTypeID: registration.salaryTypeID,
            salaryPeriodID: registration.salaryPeriodID,
            quantity: 0,
          })
          return fields
        }, [])
    )
  }

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (
    !props.employee.activeContract ||
    props.employee.activeEmployment.id !== props.employee.activeContract.employmentID
  ) {
    return <NoContractCard employee={props.employee} />
  }

  if (!props.salaryCycle) {
    return null
  }

  let title = t('salary_registrations_tab.title.standard')
  if (showHistory) {
    title = t('salary_registrations_tab.title.history')
  }

  const pendingDetailedSalaryRegistrationSummary = props.salaryRegistrations.salaryRegistrations
    .filter((registration) => registration.employeeID === props.employee.id && registration.state === 'Pending')
    .reduce(
      (summary, registration) => {
        const salary = props.employee.activeContract?.remuneration?.salary.find(
          (line) => line.salaryTypeID === registration.salaryTypeID
        )
        if (!salary) {
          return summary
        }
        return {
          count: summary.count + 1,
          value: summary.value + registration.quantity * salary.rate,
        }
      },
      { count: 0, value: 0 }
    )
  const pendingCoarseSalaryRegistrationSummary = props.coarseSalaryRegistrations.coarseSalaryRegistrations
    .filter((registration) => registration.employeeID === props.employee.id && !registration.immutable)
    .reduce(
      (summary, registration) => {
        const salary = props.employee.activeContract?.remuneration?.salary.find(
          (line) => line.salaryTypeID === registration.salaryTypeID
        )
        if (!salary) {
          return summary
        }
        return {
          count: summary.count + 1,
          value: summary.value + registration.quantity * salary.rate,
        }
      },
      { count: 0, value: 0 }
    )

  return (
    <>
      <RegistrationMethodAppAlert
        company={props.company}
        disablingSettings={['DisableSalaryRegistrationMethodDetailedOnInvite']}
        employee={props.employee}
        isDetailed={hasDetailedSalaryRegistration ?? false}
        itemMessageID="salary_registration"
        switchToDetailed={() => handleChange(true, true)}
      />
      <Card className="employees-single-form">
        <RegistrationMethodAlert
          isDetailed={hasDetailedSalaryRegistration ?? false}
          detailedSummary={pendingDetailedSalaryRegistrationSummary}
          coarseSummary={pendingCoarseSalaryRegistrationSummary}
          itemMessageID={'salary_registration'}
          deleteDetailed={deleteDetailed}
          deleteCoarse={deleteCoarse}
          switchToDetailed={() => handleChange(true)}
          switchToCoarse={() => handleChange(false)}
          formatValue={(value) => formatCurrency(value, 2)}
        />
        <TitleMenu>
          {props.canHireFire &&
            !showHistory &&
            !props.hasSalaryRegistrationsForPeriod &&
            props.employee.earliestMutableContract && (
              <div className="ant-switch-wrapper" style={{ display: 'inline-block', marginRight: 20 }}>
                <Switch checked={hasDetailedSalaryRegistration} onChange={handleChange} />
                <span className="ant-switch-text">{t('salary_registrations_tab.header.detailed')}</span>
              </div>
            )}
          {hasDetailedSalaryRegistration &&
            (showHistory ? (
              <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/salary-registration'}>
                <Button className="gtm-hide-salary-registration-history" style={{ paddingRight: 14 }}>
                  {t('salary_registrations_tab.header.hide_history')}
                </Button>
              </Link>
            ) : (
              <Link to={'/' + paths.EMPLOYEES + '/' + props.employee.id + '/salary-registration/history'}>
                <Button className="gtm-show-salary-registration-history" style={{ paddingRight: 14 }}>
                  {t('salary_registrations_tab.header.show_history')}
                </Button>
              </Link>
            ))}
        </TitleMenu>
        <Title>{title}</Title>
        {!hasDetailedSalaryRegistration && (
          <CoarseSalaryRegistrationTab
            employee={props.employee}
            contract={props.employee.activeContract}
            contracts={props.contracts}
            coarseSalaryRegistrations={props.coarseSalaryRegistrations}
            salaryCycles={props.salaryCycles}
            salaryCycle={props.salaryCycle}
            salaryTypes={props.salaryTypes}
            addAlert={props.addAlert}
            updateCoarseSalaryRegistrationBulk={props.updateCoarseSalaryRegistrationBulk}
            updateContract={props.updateContract}
            addContract={props.addContract}
          />
        )}
        {hasDetailedSalaryRegistration &&
          (showHistory ? (
            <DetailedSalaryRegistrationHistoryTab
              employee={props.employee}
              salaryRegistrations={props.salaryRegistrations.salaryRegistrations}
              salaryTypes={props.salaryTypes}
            />
          ) : (
            <DetailedSalaryRegistrationTab
              company={props.company}
              employee={props.employee}
              costCenters={props.costCenters}
              departments={props.departments}
              canEditObjects={props.canEditObjects}
              canApproveObjects={props.canApproveObjects}
              salaryRegistrations={props.salaryRegistrations}
              salaryTypes={props.salaryTypes}
              addAlert={props.addAlert}
              createSalaryRegistration={props.createSalaryRegistration}
              approveSalaryRegistrations={props.approveSalaryRegistrations}
              unapproveSalaryRegistrations={props.unapproveSalaryRegistrations}
              updateSalaryRegistration={props.updateSalaryRegistration}
              deleteSalaryRegistration={props.deleteSalaryRegistration}
              deleteSalaryRegistrationBulk={props.deleteSalaryRegistrationBulk}
            />
          ))}
        {props.contracts.saving && (
          <LoadingOverlay text={formatSavingText([{ loading: props.contracts.saving, text: 'kontrakt' }])} />
        )}
      </Card>
    </>
  )
}
