import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { AccountingAccount, CostCenterAccounting } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Employee from '../../../model/employee'
import { OneTimePayCreationFields, OneTimePayMutableFields } from '../../../model/oneTimePay'
import SalaryCycle from '../../../model/salaryCycle'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import FeeEditForm, { FeeResult } from './FeeEditForm'

type Props = {
  visible: boolean
  editing: boolean
  canApproveObjects: boolean
  employee?: Employee
  company: Company
  oneTimePayID?: string
  oneTimePays: OneTimePayReducer
  salaryCycle: SalaryCycle
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>
  accounts: List<AccountingAccount>

  addOneTimePay: (employeeID: string, oneTimePay: OneTimePayCreationFields) => void
  updateOneTimePay: (employeeID: string, oneTimePay: OneTimePayMutableFields) => void
}

export default function FeeEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, oneTimePays } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, oneTimePays.error, error, setError)
  }, [visible, oneTimePays, error])

  const handleSubmit = (values: FeeResult) => {
    if (!props.editing || !props.employee) {
      return
    }
    const oneTimePay: OneTimePayCreationFields = {
      employeeID: props.employee.id,
      dispositionDate: values.dispositionDate,
      type: values.type,
      category: values.category,
      payOutType: values.withPayment ? 'Salary' : 'Manual',
      amount: values.amount,
      title: values.title,
      approved: values.approved,
      costCenterID: values.costCenterID,
      accountingText: values.accountingText,
      debitCompanyAccountingAccountID: values.debitCompanyAccountingAccountID,
    }
    if (props.oneTimePayID) {
      props.updateOneTimePay(props.employee.id, { ...oneTimePay, id: props.oneTimePayID })
    } else {
      props.addOneTimePay(props.employee.id, oneTimePay)
    }
  }

  if (!props.employee || !props.company) {
    return null
  }
  return (
    <Card className="fee-modal">
      <Subtitle>
        {props.editing
          ? props.oneTimePayID
            ? t('freelancer.fee.edit.title.edit')
            : t('freelancer.fee.edit.title.create')
          : t('freelancer.fee.edit.title.view')}
      </Subtitle>
      {props.editing && <p>{t('freelancer.fee.edit.intro.editing')}</p>}
      {!props.editing && <p>{t('freelancer.fee.edit.intro.not_editing')}</p>}
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <FeeEditForm
        editing={props.editing}
        canApproveObjects={props.canApproveObjects}
        employee={props.employee}
        company={props.company}
        oneTimePayID={props.oneTimePayID}
        oneTimePays={props.oneTimePays}
        salaryCycle={props.salaryCycle}
        costCenterAccounting={props.costCenterAccounting}
        costCenters={props.costCenters}
        accounts={props.accounts}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
