import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Company from '../model/company'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyReducer {
  loading: boolean
  loaded: boolean
  saving: boolean
  companies: List<Company>
  company?: Company // the currently active company
  error: Error | null
}

const Params = Record<CompanyReducer>({
  loading: false,
  loaded: false,
  saving: false,
  companies: List<Company>(),
  company: undefined, // the currently active company
  error: null,
})

const comparator = (a: Company, b: Company) => {
  return a.name.localeCompare(b.name, 'da-dk')
}

export interface CompanyAction extends ReducerAction {
  companies?: Company[]
  company?: Company
  companyID?: string
}

export default (
  state: Record<CompanyReducer> = Params(),
  action: CompanyAction = { type: '' }
): Record<CompanyReducer> => {
  const newCompanies: Company[] = []
  let toAdd: List<Company> = List<Company>()
  let idx = -1
  let company = state.get('company')
  switch (action.type) {
    case ActionTypes.COMPANIES_LOADING:
      return Params({
        loading: true,
      })
    case ActionTypes.COMPANIES_LOADED:
      if (action.companies) {
        for (const i in action.companies) {
          const newCompany = action.companies[i]
          if (!state.get('companies').some((company) => company.id === newCompany.id)) {
            newCompanies.push(newCompany)
          }
        }
      }
      toAdd = state.get('companies').concat(newCompanies)
      return Params({
        loaded: true,
        companies: toAdd.sort(comparator),
        company: toAdd.find((item) => item.id === action.companyID),
      })
    case ActionTypes.COMPANIES_LOAD_FAILED:
      return Params({
        error: action.error,
      })

    case ActionTypes.COMPANY_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_ADDED:
      if (!action.company) {
        return state
      }
      idx = state.get('companies').findIndex((item) => !!action.company && item.id === action.company.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('companies', state.get('companies').set(idx, action.company).sort(comparator))
      }
      return state.set('saving', false).set('companies', state.get('companies').push(action.company).sort(comparator))
    case ActionTypes.COMPANY_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COMPANY_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_UPDATED:
      if (!action.company) {
        return state
      }
      idx = state.get('companies').findIndex((item) => item.id === action.companyID)
      if (idx === -1) {
        return state
      }
      if (!company || company.id === action.companyID) {
        return state
          .set('saving', false)
          .set('companies', state.get('companies').set(idx, action.company).sort(comparator))
          .set('company', action.company)
      }
      return state
        .set('saving', false)
        .set('companies', state.get('companies').set(idx, action.company).sort(comparator))
    case ActionTypes.COMPANY_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COMPANIES_SETTING_ACTIVE:
      company = state.get('companies').find((item) => item.id === action.companyID) || undefined
      if (company) {
        return state.set('company', company)
      }
      return state

    case ActionTypes.SUPPORT_COMPANIES_LOADED:
      if (action.companies) {
        for (const i in action.companies) {
          const newCompany = action.companies[i]
          if (!state.get('companies').some((company) => company.id === newCompany.id)) {
            newCompanies.push(newCompany)
          }
        }
      }
      toAdd = state.get('companies').concat(newCompanies)
      return state
        .set('companies', toAdd.sort(comparator))
        .set('company', toAdd.find((item) => item.id === action.companyID) || undefined)

    case ActionTypes.COMPANY_CONNECTING:
      return state.set('saving', true)
    case ActionTypes.COMPANY_CONNECTED:
      return state.set('saving', false)
    case ActionTypes.COMPANY_CONNECT_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COMPANY_DELETED:
      idx = state.get('companies').findIndex((company) => company.id === action.companyID)
      if (idx === -1) {
        return state
      }
      return state.set('companies', state.get('companies').delete(idx))

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
