import React from 'react'

import {
  delCompanyPricingPackage,
  fetchCompanyPricingPackages,
  postCompanyPricingPackage,
  postCompanyPricingPackageCode,
} from '../api/company-pricing-packages'
import ActionTypes from '../constants/action-types'
import CompanyPricingPackage from '../model/companyPricingPackage'
import { CompanyPricingPackageAction } from '../reducers/companyPricingPackages'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingCompanyPricingPackages(): CompanyPricingPackageAction {
  return {
    type: ActionTypes.COMPANY_PRICING_PACKAGES_LOADING,
  }
}
export function loadedCompanyPricingPackages(
  companyID: string,
  companyPricingPackages: CompanyPricingPackage[]
): CompanyPricingPackageAction {
  return {
    type: ActionTypes.COMPANY_PRICING_PACKAGES_LOADED,
    companyID,
    companyPricingPackages,
  }
}
function failedLoadingCompanyPricingPackages(error: Error): CompanyPricingPackageAction {
  return {
    type: ActionTypes.COMPANY_PRICING_PACKAGES_LOAD_FAILED,
    error,
  }
}

function addingCompanyPricingPackage(): CompanyPricingPackageAction {
  return {
    type: ActionTypes.COMPANY_PRICING_PACKAGE_ADDING,
  }
}
export function addedCompanyPricingPackage(
  companyID: string,
  companyPricingPackage: CompanyPricingPackage
): CompanyPricingPackageAction {
  return {
    type: ActionTypes.COMPANY_PRICING_PACKAGE_ADDED,
    companyID,
    companyPricingPackage,
  }
}
function failedAddingCompanyPricingPackage(companyID: string, error: Error): CompanyPricingPackageAction {
  return {
    type: ActionTypes.COMPANY_PRICING_PACKAGE_ADD_FAILED,
    error,
    companyID,
  }
}

export function updatedCompanyPricingPackage(
  companyID: string,
  companyPricingPackage: CompanyPricingPackage
): CompanyPricingPackageAction {
  return {
    type: ActionTypes.COMPANY_PRICING_PACKAGE_UPDATED,
    companyID,
    companyPricingPackage,
  }
}

function deletingCompanyPricingPackage(companyID: string): CompanyPricingPackageAction {
  return {
    type: ActionTypes.COMPANY_PRICING_PACKAGE_DELETING,
    companyID,
  }
}
export function deletedCompanyPricingPackage(
  companyID: string,
  companyPricingPackageID: string
): CompanyPricingPackageAction {
  return {
    type: ActionTypes.COMPANY_PRICING_PACKAGE_DELETED,
    companyID,
    companyPricingPackageID,
  }
}
function failedDeletingCompanyPricingPackage(companyID: string, error: Error): CompanyPricingPackageAction {
  return {
    type: ActionTypes.COMPANY_PRICING_PACKAGE_DELETE_FAILED,
    error,
    companyID,
  }
}

export function getCompanyPricingPackages() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<CompanyPricingPackage[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingCompanyPricingPackages())
    return fetchCompanyPricingPackages(companyID)
      .then((res) => {
        dispatch(loadedCompanyPricingPackages(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompanyPricingPackages(e))
        }
      })
  }
}

export function addCompanyPricingPackage(pricingPackageID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<CompanyPricingPackage | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingCompanyPricingPackage())
    return postCompanyPricingPackage(companyID, pricingPackageID)
      .then((res) => {
        dispatch(addedCompanyPricingPackage(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingCompanyPricingPackage(companyID, e))
        }
      })
  }
}

export function activateCompanyPricingPackageCode(code: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<CompanyPricingPackage | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingCompanyPricingPackage())
    return postCompanyPricingPackageCode(companyID, code)
      .then((res) => {
        dispatch(addedCompanyPricingPackage(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingCompanyPricingPackage(companyID, e))
        }
      })
  }
}

export function deleteCompanyPricingPackage(companyPricingPackageID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingCompanyPricingPackage(companyID))
    return delCompanyPricingPackage(companyPricingPackageID)
      .then(() => {
        dispatch(deletedCompanyPricingPackage(companyID, companyPricingPackageID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingCompanyPricingPackage(companyID, e))
        }
      })
  }
}
