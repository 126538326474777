import CompanyIntegration from '../model/companyIntegration'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCompanyIntegrations(companyID: string): Promise<RequestResponse<CompanyIntegration[]>> {
  return secureRequest('GET', url('/v2/companies/' + companyID + '/integrations'))
}

export function delCompanyIntegration(companyID: string, companyIntegrationID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/companies/' + companyID + '/integrations/' + companyIntegrationID))
}
