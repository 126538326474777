import React from 'react'

import {
  delTimeRegistrationTemplates,
  fetchTimeRegistrationTemplates,
  postTimeRegistrationTemplates,
} from '../api/time-registration-templates'
import ActionTypes from '../constants/action-types'
import TimeRegistrationTemplate, { TimeRegistrationTemplateCreationFields } from '../model/timeRegistrationTemplate'
import { TimeRegistrationTemplateAction } from '../reducers/timeRegistrationTemplates'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingTimeRegistrationTemplates(companyID: string): TimeRegistrationTemplateAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TEMPLATE_LOADING,
    companyID,
  }
}
function loadedTimeRegistrationTemplates(
  companyID: string,
  templates: TimeRegistrationTemplate[]
): TimeRegistrationTemplateAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TEMPLATE_LOADED,
    companyID,
    templates,
  }
}
function failedLoadingTimeRegistrationTemplates(companyID: string, error: Error): TimeRegistrationTemplateAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TEMPLATE_LOAD_FAILED,
    companyID,
    error,
  }
}

function savingTimeRegistrationTemplates(companyID: string): TimeRegistrationTemplateAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TEMPLATE_SAVING,
    companyID,
  }
}
function savedTimeRegistrationTemplates(
  companyID: string,
  employeeID: string | null,
  template: TimeRegistrationTemplate | null
): TimeRegistrationTemplateAction {
  if (template === null) {
    return {
      type: ActionTypes.TIME_REGISTRATION_TEMPLATE_SAVED,
      companyID,
      employeeID,
      templateDeleted: true,
    }
  }
  return {
    type: ActionTypes.TIME_REGISTRATION_TEMPLATE_SAVED,
    companyID,
    employeeID,
    template,
  }
}
function failedSavingTimeRegistrationTemplates(companyID: string, error: Error): TimeRegistrationTemplateAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_TEMPLATE_SAVE_FAILED,
    companyID,
    error,
  }
}

export function getTimeRegistrationTemplates() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<TimeRegistrationTemplate[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingTimeRegistrationTemplates(companyID))
    return fetchTimeRegistrationTemplates(companyID)
      .then((res) => {
        dispatch(loadedTimeRegistrationTemplates(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          if (e.type === 'NotFound') {
            dispatch(loadedTimeRegistrationTemplates(companyID, []))
          } else {
            dispatch(failedLoadingTimeRegistrationTemplates(companyID, e))
          }
        }
      })
  }
}

export function saveTimeRegistrationTemplates(template: TimeRegistrationTemplateCreationFields) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<TimeRegistrationTemplate | void> => {
    let companyID = template.companyID
    if (!companyID) {
      companyID = getCompanyID(getState)
      if (!companyID) {
        return PromiseVoid
      }
    }

    dispatch(savingTimeRegistrationTemplates(companyID))
    return postTimeRegistrationTemplates(template)
      .then((res) => {
        dispatch(savedTimeRegistrationTemplates(companyID!, template.employeeID ?? null, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedSavingTimeRegistrationTemplates(companyID!, e))
        }
      })
  }
}

export function deleteTimeRegistrationTemplate(companyID: string | undefined, employeeID: string | undefined) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    if (!companyID) {
      companyID = getCompanyID(getState)
      if (!companyID) {
        return PromiseVoid
      }
    }

    dispatch(savingTimeRegistrationTemplates(companyID))
    return delTimeRegistrationTemplates(companyID, employeeID)
      .then(() => {
        dispatch(savedTimeRegistrationTemplates(companyID!, employeeID ?? null, null))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedSavingTimeRegistrationTemplates(companyID!, e))
        }
      })
  }
}
