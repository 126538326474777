import React, { ReactElement, useState } from 'react'

import CompanyGroup, { CompanyGroupUser } from '../../../model/companyGroup'
import { UserPermission } from '../../../model/companyUser'
import { UserReducer } from '../../../reducers/user'
import { UserInviteCompanyGroupReducer } from '../../../reducers/userInviteCompanyGroups'
import CompanyGroupUserType from '../../../types/company-group-user-type'
import { emailDomain, validateEmail } from '../../../utils/email-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { formatCompanyGroupUserType, formatUserPermission } from '../../../utils/format-utils'
import { t, tx } from '../../../utils/translation-utils'
import Select from '../../antd/select'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import Subcard from '../../elements/Subcard'
import Subtitle from '../../elements/Subtitle'
import Switch from '../../elements/switch'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  companyGroup: CompanyGroup
  userInviteCompanyGroups: UserInviteCompanyGroupReducer
  user: UserReducer
  companyGroupUser?: CompanyGroupUser
}

type Fields = {
  groupUserType?: CompanyGroupUserType
  email?: string
  permissionAdmin: boolean
  permissionApprovePayRoll: boolean
  permissionReviewPayRoll: boolean
}

export type CompanyGroupUserAddResult = {
  groupUserType: CompanyGroupUserType
  email: string
  permissionAdmin: boolean
  permissionApprovePayRoll: boolean
  permissionReviewPayRoll: boolean
}

function CompanyGroupUserAddForm(
  props: Props & FormComponentProps<Fields, CompanyGroupUserAddResult>
): ReactElement | null {
  const [notSameDomain, setNotSameDomain] = useState(false)

  const checkEmail = () => {
    const email = props.getFieldValue('email')
    let notSameDomain = false
    if (email && email !== '' && email.match(/.+@.+/)) {
      notSameDomain = emailDomain(email) !== emailDomain(props.user.email || '')
    }
    setNotSameDomain(notSameDomain)
  }

  const showAdmin = () => {
    return !!props.companyGroupUser && props.companyGroupUser.groupUserType === CompanyGroupUserType.ADMIN
  }
  const showPermission = (permission: UserPermission) => {
    return (
      !!props.companyGroupUser &&
      (props.companyGroupUser.permissions.includes(permission) ||
        props.companyGroupUser.groupUserType === CompanyGroupUserType.ADMIN)
    )
  }

  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('groupUserType', {
            title: t('companies.group.users.add.form.group_user_type'),
            placeholder: t('companies.group.users.add.form.group_user_type.placeholder'),
            validate: (val) => (!val ? t('companies.group.users.add.form.group_user_type.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false}>
              {showAdmin() && (
                <Select.Option value={CompanyGroupUserType.ADMIN}>
                  {formatCompanyGroupUserType(CompanyGroupUserType.ADMIN)}
                </Select.Option>
              )}
              <Select.Option value={CompanyGroupUserType.REGULAR}>
                {formatCompanyGroupUserType(CompanyGroupUserType.REGULAR)}
              </Select.Option>
            </Select>
          )}
        </Col>
        <Col span={12}>
          {decorateField('email', {
            placeholder: t('companies.group.users.add.form.email'),
            validate: (val) => {
              if (!val) {
                return t('companies.group.users.add.form.email.required')
              }
              if (!validateEmail(val)) {
                return t('companies.group.users.add.form.email.invalid')
              }
              return null
            },
          })(<Input onBlur={checkEmail} placeholder="E-mail" />)}
        </Col>
      </Row>
      {notSameDomain && (
        <Row>
          <Col span={24}>
            <Alert
              message={tx('companies.group.users.add.form.email.not_same_domain', {
                group: <em>{t('companies.group.users.add.form.email.not_same_domain.group')}</em>,
                company: <em>{t('companies.group.users.add.form.email.not_same_domain.company')}</em>,
              })}
              type="warning"
              showIcon
            />
          </Col>
        </Row>
      )}
      <Subcard>
        <Subtitle>{t('companies.group.users.add.form.permissions.title')}</Subtitle>
        <p>{t('companies.group.users.add.form.permissions.intro')}</p>
        {showPermission('Admin') && (
          <Row>
            <Col span={24}>
              <div className="ant-switch-wrapper">
                {decorateField('permissionAdmin', {
                  skipWrapper: true,
                  skipLabel: true,
                  valueOnChecked: true,
                  noBlur: true,
                })(<Switch />)}
                <span className="ant-switch-text">{formatUserPermission('Admin')}</span>
              </div>
            </Col>
          </Row>
        )}
        {showPermission('ApprovePayRoll') && (
          <Row>
            <Col span={24}>
              <div className="ant-switch-wrapper">
                {decorateField('permissionApprovePayRoll', {
                  skipWrapper: true,
                  skipLabel: true,
                  valueOnChecked: true,
                  noBlur: true,
                })(<Switch />)}
                <span className="ant-switch-text">{formatUserPermission('ApprovePayRoll')}</span>
              </div>
            </Col>
          </Row>
        )}
        {showPermission('ReviewPayRoll') && (
          <Row>
            <Col span={24}>
              <div className="ant-switch-wrapper">
                {decorateField('permissionReviewPayRoll', {
                  skipWrapper: true,
                  skipLabel: true,
                  valueOnChecked: true,
                  noBlur: true,
                })(<Switch />)}
                <span className="ant-switch-text">{formatUserPermission('ReviewPayRoll')}</span>
              </div>
            </Col>
          </Row>
        )}
      </Subcard>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('companies.group.users.add.submit')}
          </Button>
        </Col>
      </Row>
      {props.userInviteCompanyGroups.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, CompanyGroupUserAddResult>({
  mapPropsToFields: () => ({
    permissionAdmin: false,
    permissionApprovePayRoll: false,
    permissionReviewPayRoll: false,
  }),
  onSubmit: (values) => ({
    ...values,
    email: values.email!,
    groupUserType: values.groupUserType!,
  }),
})(CompanyGroupUserAddForm)
