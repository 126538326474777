import { List } from 'immutable'
import React, { ReactElement } from 'react'

import DocumentCategory from '../../../model/documentCategory'
import Employee from '../../../model/employee'
import { DocumentReducer } from '../../../reducers/documents'
import { compareEmployees } from '../../../utils/employee-utils'
import { combineSearchOption, FormComponentProps, withValidations } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Radio, { Group as RadioGroup } from '../../antd/radio'
import Select from '../../antd/select'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import DocumentSignerTable from './DocumentSignerTable'

type Props = {
  documentID?: string
  documents: DocumentReducer
  documentCategories: List<DocumentCategory>
  employees?: List<Employee>
}

type Fields = {
  documentCategoryID?: string
  name?: string
  visibleForEmployee: boolean
  employeeID?: string
}

export type DocumentResult = {
  documentCategoryID: string
  name: string
  visibleForEmployee: boolean
  employeeID: string
}

function DocumentEditForm(props: Props & FormComponentProps<Fields, DocumentResult>): ReactElement | null {
  const { decorateField, getFieldValue } = props

  const document = props.documents.documents.find((document) => document.id === props.documentID)

  return (
    <div>
      {props.getFormError()}
      {props.employees && (
        <Row>
          <Col span={24}>
            {decorateField('employeeID', {
              placeholder: t('document_tab.edit.form.employee.placeholder'),
              validate: (val) => {
                if (!val) {
                  return t('document_tab.edit.form.employee.required')
                }
                return null
              },
            })(
              <Select
                tabIndex={1}
                showSearch={true}
                filterOption={(inputValue: string, option: ReactElement) =>
                  combineSearchOption(option).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }
              >
                {props.employees
                  .sort((a, b) => {
                    const { orderA, orderB } = {
                      orderA: a.employmentStatus === 'Terminated' ? 1 : 0,
                      orderB: b.employmentStatus === 'Terminated' ? 1 : 0,
                    }
                    if (orderA === orderB) {
                      return compareEmployees(a, b)
                    }
                    return orderA - orderB
                  })
                  .map((employee) => {
                    const name = employee.name || employee.email
                    return (
                      <Select.Option key={employee.id} value={employee.id} title={name}>
                        {employee.employmentStatus === 'Terminated'
                          ? t('document_tab.edit.form.employee.terminated', { name })
                          : name}
                      </Select.Option>
                    )
                  })}
              </Select>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          {decorateField('documentCategoryID', {
            placeholder: t('document_tab.edit.form.document_category_id'),
            validate: (val) => (!val ? t('document_tab.edit.form.document_category_id.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false}>
              {props.documentCategories
                .filter(
                  (documentCategory) =>
                    documentCategory.active || documentCategory.id === getFieldValue('documentCategoryID')
                )
                .map((documentCategory) => {
                  return (
                    <Select.Option key={documentCategory.id} value={documentCategory.id}>
                      {documentCategory.name}
                    </Select.Option>
                  )
                })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('name', {
            placeholder: t('document_tab.edit.form.name'),
            validate: (val) => (!val ? t('document_tab.edit.form.name.required') : null),
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('visibleForEmployee', {
            title: t('document_tab.edit.form.visible_for_employee'),
          })(
            <RadioGroup>
              <Radio value={true}>{t('document_tab.edit.form.visible_for_employee.true')}</Radio>
              <Radio value={false}>{t('document_tab.edit.form.visible_for_employee.false')}</Radio>
            </RadioGroup>
          )}
        </Col>
      </Row>
      {document && <DocumentSignerTable document={document} />}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {props.documentID ? t('form.button.save_changes') : t('document_tab.edit.form.submit.create')}
          </Button>
        </Col>
      </Row>
      {props.documents.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, DocumentResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      visibleForEmployee: true,
    }
    const document = props.documents.documents.find((document) => document.id === props.documentID)
    if (document) {
      fields.documentCategoryID = document.documentCategoryID
      fields.name = document.name
      fields.visibleForEmployee = document.visibleForEmployee
      fields.employeeID = document.employeeID
    }
    return fields
  },
  onSubmit: (values) => {
    return {
      documentCategoryID: values.documentCategoryID!,
      name: values.name!,
      visibleForEmployee: values.visibleForEmployee,
      employeeID: values.employeeID!,
    }
  },
})(DocumentEditForm)
