import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CoarseCarAllowance from '../model/coarseCarAllowance'
import { ReducerAction } from '../utils/reducer-utils'

export interface CoarseCarAllowanceReducer {
  companyID: string | undefined
  employeeID: string | undefined
  salaryPeriodIDs: List<string>
  loading: boolean
  loaded: boolean
  saving: boolean
  coarseCarAllowances: List<CoarseCarAllowance>
  error: Error | null
}

const Params = Record<CoarseCarAllowanceReducer>({
  companyID: undefined,
  employeeID: undefined,
  salaryPeriodIDs: List<string>(),
  loading: false,
  loaded: false,
  saving: false,
  coarseCarAllowances: List<CoarseCarAllowance>(),
  error: null,
})

export interface CoarseCarAllowanceAction extends ReducerAction {
  companyID?: string
  salaryPeriodIDs?: string[]
  employeeID?: string
  coarseCarAllowances?: CoarseCarAllowance[]
  coarseCarAllowance?: CoarseCarAllowance
  coarseCarAllowanceID?: string
}

export default (
  state: Record<CoarseCarAllowanceReducer> = Params(),
  action: CoarseCarAllowanceAction = { type: '' }
): Record<CoarseCarAllowanceReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.COARSE_CAR_ALLOWANCE_LOADING:
      return Params({
        loading: true,
        companyID: action.companyID || state.get('companyID'),
        salaryPeriodIDs: List<string>(action.salaryPeriodIDs),
        employeeID: action.employeeID,
      })
    case ActionTypes.COARSE_CAR_ALLOWANCE_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('coarseCarAllowances', List<CoarseCarAllowance>(action.coarseCarAllowances))
        .set('error', null)
    case ActionTypes.COARSE_CAR_ALLOWANCE_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('error', action.error || null)

    case ActionTypes.COARSE_CAR_ALLOWANCE_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COARSE_CAR_ALLOWANCE_CREATED:
      if (!action.coarseCarAllowance) {
        return state
      }
      if (!action.companyID || !action.salaryPeriodIDs) {
        // only process actions for the active employee
        if (state.get('employeeID') && action.employeeID !== state.get('employeeID')) {
          return state
        }
      }
      idx = state
        .get('coarseCarAllowances')
        .findIndex((item) => !!action.coarseCarAllowance && item.id === action.coarseCarAllowance.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('coarseCarAllowances', state.get('coarseCarAllowances').set(idx, action.coarseCarAllowance))
      }
      return state
        .set('saving', false)
        .set('coarseCarAllowances', state.get('coarseCarAllowances').push(action.coarseCarAllowance))
    case ActionTypes.COARSE_CAR_ALLOWANCE_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COARSE_CAR_ALLOWANCE_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COARSE_CAR_ALLOWANCE_UPDATED:
      if (!action.coarseCarAllowance) {
        return state
      }
      if (action.coarseCarAllowanceID) {
        idx = state.get('coarseCarAllowances').findIndex((item) => item.id === action.coarseCarAllowanceID)
      }
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('coarseCarAllowances', state.get('coarseCarAllowances').set(idx, action.coarseCarAllowance))
    case ActionTypes.COARSE_CAR_ALLOWANCE_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.COARSE_CAR_ALLOWANCE_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COARSE_CAR_ALLOWANCE_DELETED:
      if (action.coarseCarAllowanceID) {
        idx = state.get('coarseCarAllowances').findIndex((item) => item.id === action.coarseCarAllowanceID)
      }
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('coarseCarAllowances', state.get('coarseCarAllowances').delete(idx))
    case ActionTypes.COARSE_CAR_ALLOWANCE_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
