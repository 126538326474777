import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import Company from '../../model/company'
import Employee from '../../model/employee'
import PayRoll from '../../model/payRoll'
import PayRollDeviation from '../../model/payRollDeviation'
import { TaxCardType } from '../../model/taxCard'
import { paths } from '../../routes'
import { formatDeviationType, formatTaxCardType } from '../../utils/format-utils'
import { logWarning } from '../../utils/log-utils'
import { formatDiffCurrency, formatDiffNumber, formatDisplayNumber } from '../../utils/number-utils'
import { t, tx } from '../../utils/translation-utils'
import Table from '../antd/table'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/Icon'
import Subtitle from '../elements/Subtitle'

function calcPercentage(before: number, after: number) {
  return (((after - before) / before) * 10000.0) / 100.0
}

type PayRollDeviationRow = {
  original: PayRollDeviation
}

const columns = [
  { title: t('pay_roll.single.deviations.header.type'), dataIndex: 'type', key: 'type' },
  { title: t('pay_roll.single.deviations.header.name'), dataIndex: 'name', key: 'name' },
  {
    title: t('pay_roll.single.deviations.header.deviation'),
    dataIndex: '',
    key: 'deviation',
    render: (deviation: PayRollDeviationRow) => {
      switch (deviation.original.type) {
        case 'Hours':
          return (
            <span>
              {t('pay_roll.single.deviations.hours_format', {
                percentage: formatDiffNumber(
                  calcPercentage(deviation.original.valueBefore || 0, deviation.original.valueAfter || 0),
                  2
                ),
                hours: formatDiffNumber(
                  (deviation.original.valueAfter || 0) - (deviation.original.valueBefore || 0),
                  2
                ),
              })}
              )
            </span>
          )
        case 'Tax Card':
          return (
            <span>
              {t('pay_roll.single.deviations.tax_card_format', {
                old: formatTaxCardType(deviation.original.stringValueBefore as TaxCardType),
                new: formatTaxCardType(deviation.original.stringValueAfter as TaxCardType),
              })}
            </span>
          )
        case 'Gross Pay':
        case 'Pay Check':
        case 'Gross Pay Reduction':
          return (
            <span>
              {t('pay_roll.single.deviations.currency_format', {
                percentage: formatDiffNumber(
                  calcPercentage(deviation.original.valueBefore || 0, deviation.original.valueAfter || 0),
                  2
                ),
                currency: formatDiffCurrency(
                  (deviation.original.valueAfter || 0) - (deviation.original.valueBefore || 0),
                  2
                ),
              })}
            </span>
          )
        default:
          logWarning('Unknown deviation type: ' + deviation.original.type)
          return null
      }
    },
  },
  {
    title: '',
    dataIndex: '',
    key: 'action',
    width: 1,
    render: (deviation: PayRollDeviationRow) => {
      switch (deviation.original.type) {
        case 'Hours':
          return (
            <Link to={'/' + paths.EMPLOYEES + '/' + deviation.original.employeeID + '/time-registration'}>
              <Icon type="edit" color="grey" />
            </Link>
          )
        case 'Tax Card':
          return (
            <Link to={'/' + paths.EMPLOYEES + '/' + deviation.original.employeeID + '/tax-cards'}>
              <Icon type="edit" color="grey" />
            </Link>
          )
        case 'Gross Pay':
        case 'Pay Check':
          return (
            <Link to={'/' + paths.EMPLOYEES + '/' + deviation.original.employeeID + '/employment'}>
              <Icon type="edit" color="grey" />
            </Link>
          )
        default:
          logWarning('Unknown deviation type: ' + deviation.original.type)
          return null
      }
    },
  },
]

type Props = {
  company: Company
  payRoll: PayRoll
  payRollDeviations: List<PayRollDeviation>
  employeesMap: Record<string, Employee>
}

export default function DeviationsCard(props: Props): ReactElement | null {
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => setExpanded(!expanded)

  const getDeviationNotifications = (): PayRollDeviationRow[] => {
    return props.payRollDeviations
      .toArray()
      .filter((deviation) => deviation.payRollID === props.payRoll.id && !!props.employeesMap[deviation.employeeID])
      .sort((a, b) => {
        if (a.type !== b.type) {
          return a.type.localeCompare(b.type)
        }
        if (a.type === 'Tax Card') {
          return (a.stringValueAfter || '').localeCompare(b.stringValueAfter || '')
        }
        return (
          calcPercentage(b.valueBefore || 0, b.valueAfter || 0) - calcPercentage(a.valueBefore || 0, a.valueAfter || 0)
        )
      })
      .map((deviation) => {
        const employee = props.employeesMap[deviation.employeeID]
        if (!employee) {
          return { name: '', type: '', original: deviation } // won't happen
        }
        return {
          name: employee.name,
          type: formatDeviationType(deviation.type),
          original: deviation,
        }
      })
  }

  const deviations = getDeviationNotifications()
  if (deviations.length === 0) {
    return null
  }

  return (
    <Card>
      <Row>
        <Col span={6}>
          <Subtitle>{t('pay_roll.single.deviations.title')}</Subtitle>
        </Col>
        <Col span={12}>
          {tx('pay_roll.single.deviations.total', { total: <strong>{formatDisplayNumber(deviations.length)}</strong> })}
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <span onClick={toggleExpanded} className={'pay-roll-toggle' + (expanded ? ' pay-roll-toggle-visible' : '')}>
            {expanded ? t('pay_roll.single.deviations.toggle.hide') : t('pay_roll.single.deviations.toggle.show')}
          </span>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={deviations}
        size="small"
        pagination={false}
        className={'pay-roll-table' + (expanded ? ' pay-roll-table-visible' : '')}
        footer={() => (
          <div>
            <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/deviations'}>
              <Button>
                <Icon type="edit" color="grey" />
                {t('pay_roll.single.deviations.settings')}
              </Button>
            </Link>
          </div>
        )}
      />
    </Card>
  )
}
