import React from 'react'

import { fetchIncomeTaxReports } from '../api/income-tax-reports'
import ActionTypes from '../constants/action-types'
import IncomeTaxReport from '../model/incomeTaxReport'
import { IncomeTaxReportAction } from '../reducers/incomeTaxReports'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingIncomeTaxReports(payRollID: string): IncomeTaxReportAction {
  return {
    type: ActionTypes.INCOME_TAX_REPORTS_LOADING,
    payRollID,
  }
}
function loadedIncomeTaxReports(
  payRollID: string,
  incomeTaxReports: IncomeTaxReport[],
  partial = false
): IncomeTaxReportAction {
  return {
    type: partial ? ActionTypes.INCOME_TAX_REPORTS_LOADED_PARTIAL : ActionTypes.INCOME_TAX_REPORTS_LOADED,
    incomeTaxReports,
    payRollID,
  }
}
function failedLoadingIncomeTaxReports(payRollID: string, error: Error): IncomeTaxReportAction {
  return {
    type: ActionTypes.INCOME_TAX_REPORTS_LOAD_FAILED,
    error,
    payRollID,
  }
}
export function addedIncomeTaxReport(payRollID: string, incomeTaxReport: IncomeTaxReport): IncomeTaxReportAction {
  return {
    type: ActionTypes.INCOME_TAX_REPORT_ADDED,
    payRollID,
    incomeTaxReport,
  }
}
export function updatedIncomeTaxReport(
  incomeTaxReportID: string,
  incomeTaxReport: IncomeTaxReport
): IncomeTaxReportAction {
  return {
    type: ActionTypes.INCOME_TAX_REPORT_UPDATED,
    incomeTaxReportID,
    incomeTaxReport,
  }
}
export function deletedIncomeTaxReport(incomeTaxReportID: string): IncomeTaxReportAction {
  return {
    type: ActionTypes.INCOME_TAX_REPORT_DELETED,
    incomeTaxReportID,
  }
}

export function getIncomeTaxReports(payRollID: string, offset?: number) {
  return (dispatch: React.Dispatch<any>): Promise<IncomeTaxReport[] | void> => {
    if (!offset) {
      dispatch(loadingIncomeTaxReports(payRollID))
      offset = 0
    }
    const limit = 1000
    return fetchIncomeTaxReports(payRollID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedIncomeTaxReports(payRollID, data)),
          (data) => dispatch(loadedIncomeTaxReports(payRollID, data, true)),
          (offset) => dispatch(getIncomeTaxReports(payRollID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingIncomeTaxReports(payRollID, e))
        }
      })
  }
}
