import React, { ReactElement } from 'react'

import Employee from '../../model/employee'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import { validateNationalID } from '../../utils/validation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import { Col, Row } from '../elements/grid'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'

type Props = {
  employees: Employee[]
}

type Fields = {
  employees: {
    id: string
    name: string
    oldNationalID: string
    newNationalID?: string
  }[]
}

export type FixNationalIDResult = {
  employees: {
    id: string
    newNationalID: string
  }[]
}

function FixNationalIDForm(props: Props & FormComponentProps<Fields, FixNationalIDResult>): ReactElement | null {
  const { getFieldValue, decorateAnyField, getAnyFieldError } = props
  return (
    <div>
      <Subcard>
        <Row>
          <Col span={8}>{t('pay_roll.single.fix_national_id.header.name')}</Col>
          <Col span={8}>{t('pay_roll.single.fix_national_id.header.old_national_id')}</Col>
          <Col span={8}>{t('pay_roll.single.fix_national_id.header.new_national_id')}</Col>
        </Row>
        {getFieldValue('employees').map((employee, i) => {
          const error = getAnyFieldError(`employees.${i}.newNationalID`)
          return (
            <Row key={i}>
              <Col span={8}>{employee.name}</Col>
              <Col span={8}>{employee.oldNationalID}</Col>
              <Col span={8}>
                <Form.Item validateStatus={error ? 'error' : 'success'}>
                  {decorateAnyField(`employees.${i}.newNationalID`, {
                    skipWrapper: true,
                    skipLabel: true,
                    validate: (val) => {
                      if (!val) {
                        return null
                      }
                      return validateNationalID(val, 'DK CPR', [])
                    },
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
          )
        })}
      </Subcard>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary" block>
            {t('pay_roll.single.fix_national_id.submit', { count: getFieldValue('employees').length })}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, FixNationalIDResult>({
  mapPropsToFields: (props) => ({
    employees: props.employees.map((employee) => ({
      id: employee.id,
      name: employee.name || employee.email || '-',
      oldNationalID: employee.nationalID!,
    })),
  }),
  onSubmit: (values) => ({
    employees: values.employees
      .filter((employee) => !!employee.newNationalID)
      .map((employee) => ({
        id: employee.id,
        newNationalID: employee.newNationalID!,
      })),
  }),
})(FixNationalIDForm)
