import { TaxCardUpdate } from '../actions/tax-cards'
import TaxCard from '../model/taxCard'
import TaxCardRequest from '../model/taxCardRequest'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchTaxCards(
  companyID: string | undefined,
  employeeID: string | undefined,
  limit: number,
  offset: number
): Promise<RequestResponse<TaxCard[]>> {
  return secureRequest('GET', url('/v2/taxCards', { companyID, employeeID, limit, offset }))
}

export function fetchTaxCardRequests(
  employeeID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<TaxCardRequest[]>> {
  return secureRequest('GET', url('/v2/taxCardRequests', { employeeID, limit, offset }))
}

export function patchTaxCard(taxCard: TaxCardUpdate): Promise<RequestResponse<TaxCard>> {
  return secureRequest('PATCH', url('/v2/taxCards/' + taxCard.id), { body: { validFrom: taxCard.validFrom } })
}

export function postTaxCardRequest(employeeID: string, requestType: string): Promise<RequestResponse<TaxCardRequest>> {
  return secureRequest('POST', url('/v2/taxCardRequests'), {
    body: { employeeID, requestType },
  })
}
