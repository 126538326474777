import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import SalaryCycle from '../../../model/salaryCycle'
import { StartBalances } from '../../../model/startBalances'
import { StartBalanceReducer } from '../../../reducers/startBalances'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Title from '../../elements/Title'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import { ResultFields } from './StartBalanceEditForm'
import StartBalanceForm from './StartBalanceForm'

type Props = {
  employee: Employee
  startBalances: StartBalanceReducer
  leaveTypes: List<LeaveType>
  salaryCycle?: SalaryCycle
  canEditObjects: boolean

  addAlert: addAlertSignature
  getStartBalances: (id: string) => void
  updateStartBalances: (id: string, o: StartBalances<number>) => void
}

const resolveStartBalances = (props: Props) => {
  return {
    ...{
      employeePaidPension: 0,
      employerPaidPension: 0,
      payCheck: 0,
      salary: 0,
      workHours: 0,
      workDays: 0,
      dkSpecific: {
        aIncome: 0,
        aTax: 0,
        amIncome: 0,
        amTax: 0,
        employeeATP: 0,
        employerATP: 0,
        shDage: 0,
        fritvalg: 0,
        carAllowance: {
          kilometers: 0,
        },
        vacationBasisThisYear: 0,
        vacationBasisLastYear: 0,
        vacationBasisNextYear: 0,
        vacationBasisTransitionYear2019: 0,
        vacationBasisTransitionYear2020: 0,
        vacationAccrual: {
          daysEarned: 0,
          daysTaken: 0,
          daysCarryOver: 0,
          vacationBasis: 0,
          vacationBasisCarryOver: 0,
          supplementEarned: 0,
        },
        vacationFund: {
          daysEarned: 0,
          daysTaken: 0,
          vacationNet: 0,
          vacationBasis: 0,
        },
        vacationOptional: {
          daysAvailableThisYear: 0,
          daysTakenThisYear: 0,
        },
        extraVacationAccrual: {
          daysEarned: 0,
          daysTaken: 0,
        },
        vacationPersonalDays: {
          daysAvailableThisYear: 0,
          daysTakenThisYear: 0,
        },
        personalTimeAccrual: {
          daysEarned: 0,
          daysTaken: 0,
        },
      },
    },
    ...props.startBalances.startBalances,
  }
}

export default function StartBalanceTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { employee, getStartBalances } = props
  const employmentID = employee && employee.activeEmployment && employee.activeEmployment.id
  const startBalanceEmploymentID = props.startBalances.employmentID
  const startBalanceLoading = props.startBalances.loading
  const startBalanceLoaded = props.startBalances.loaded

  useEffect(() => {
    if (employmentID && (employmentID !== startBalanceEmploymentID || (!startBalanceLoading && !startBalanceLoaded))) {
      getStartBalances(employmentID)
    }
  }, [employmentID, startBalanceEmploymentID, startBalanceLoading, startBalanceLoaded, getStartBalances])

  const saving = props.startBalances.saving
  const wasSaving = usePrevious(saving)
  const startBalanceError = props.startBalances.error
  const { addAlert } = props

  useEffect(() => {
    if (wasSaving && !saving) {
      if (!startBalanceError) {
        addAlert('success', t('start_balances.alert.success', { name: employee.name }), { timeout: 5 })
        window.scrollTo(0, 0)
      }
    }
  }, [wasSaving, saving, startBalanceError, addAlert, employee])

  useEffect(() => {
    regularComponentDidUpdate(startBalanceError, error, setError)
  }, [startBalanceError, error])

  const handleSubmit = (values: ResultFields) => {
    if (!employmentID) {
      return
    }
    const startBalances = { ...resolveStartBalances(props), ...values.startBalances }
    props.updateStartBalances(employmentID, startBalances)
  }

  if (!props.startBalances.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (
    !props.employee.activeContract ||
    props.employee.activeEmployment.id !== props.employee.activeContract.employmentID
  ) {
    return <NoContractCard employee={props.employee} />
  }
  if (!props.startBalances.startBalances || Object.keys(props.startBalances.startBalances).length === 0) {
    return (
      <Card>
        <LoadingOverlay />
      </Card>
    )
  }

  if (!props.employee.activeContract.remuneration || !props.salaryCycle) {
    return null
  }
  return (
    <Card>
      <Title>{t('start_balances.title')}</Title>
      <p style={{ marginTop: '-20px' }}>{t('start_balances.intro')}</p>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <div className="employees-single-form">
        <StartBalanceForm
          remuneration={props.employee.activeContract.remuneration}
          contractValidFrom={props.employee.activeContract.validFrom}
          startBalances={resolveStartBalances(props)}
          leaveTypes={props.leaveTypes}
          salaryCycle={props.salaryCycle}
          disabled={!props.canEditObjects}
          onSubmit={handleSubmit}
        />
        {props.startBalances.saving && <LoadingOverlay />}
      </div>
    </Card>
  )
}
