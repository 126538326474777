import React from 'react'

import { fetchPaySlip, fetchPaySlips } from '../api/pay-slips'
import ActionTypes from '../constants/action-types'
import PaySlip from '../model/paySlip'
import { PaySlipAction } from '../reducers/paySlips'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingPaySlips(payRollID: string | undefined, employeeID: string | undefined): PaySlipAction {
  return {
    type: ActionTypes.PAY_SLIP_LOADING,
    payRollID,
    employeeID,
  }
}
function loadedPaySlips(
  payRollID: string | undefined,
  employeeID: string | undefined,
  paySlips: PaySlip[],
  partial = false
): PaySlipAction {
  return {
    type: partial ? ActionTypes.PAY_SLIP_LOADED_PARTIAL : ActionTypes.PAY_SLIP_LOADED,
    paySlips,
    payRollID,
    employeeID,
  }
}
function failedLoadingPaySlips(
  payRollID: string | undefined,
  employeeID: string | undefined,
  error: Error
): PaySlipAction {
  return {
    type: ActionTypes.PAY_SLIP_LOAD_FAILED,
    error,
    payRollID,
    employeeID,
  }
}
export function addedPaySlip(payRollID: string, employeeID: string, paySlip: PaySlip): PaySlipAction {
  return {
    type: ActionTypes.PAY_SLIP_ADDED,
    payRollID,
    employeeID,
    paySlip,
  }
}
export function updatedPaySlip(paySlipID: string, paySlip: PaySlip): PaySlipAction {
  return {
    type: ActionTypes.PAY_SLIP_UPDATED,
    paySlipID,
    paySlip,
  }
}
export function deletedPaySlip(paySlipID: string): PaySlipAction {
  return {
    type: ActionTypes.PAY_SLIP_DELETED,
    paySlipID,
  }
}

export function getPaySlips(payRollID?: string, employeeID?: string, offset?: number) {
  return (dispatch: React.Dispatch<any>): Promise<PaySlip[] | void> => {
    if (!offset) {
      dispatch(loadingPaySlips(payRollID, employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchPaySlips(payRollID, employeeID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedPaySlips(payRollID, employeeID, data)),
          (data) => dispatch(loadedPaySlips(payRollID, employeeID, data, true)),
          (offset) => dispatch(getPaySlips(payRollID, employeeID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingPaySlips(payRollID, employeeID, e))
        }
      })
  }
}

export function getPaySlip(paySlipID: string) {
  return (dispatch: React.Dispatch<any>): Promise<PaySlip | void> => {
    dispatch(loadingPaySlips(undefined, undefined))
    return fetchPaySlip(paySlipID)
      .then((res) => {
        dispatch(loadedPaySlips(undefined, undefined, [res.data]))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingPaySlips(undefined, undefined, e))
        }
      })
  }
}
