import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import User from '../model/user'
import { ReducerAction } from '../utils/reducer-utils'

// rather than having all user fields straight onto this reducer, it should be an object
// but we cannot do that until all our code is rewritten in TypeScript, since so much relies on it.
export interface UserReducer extends Partial<User> {
  user: User | undefined
  loggedIn: boolean
  loggingIn: boolean
  requestedPassword: boolean
  requestingPassword: boolean
  resettingPassword: boolean
  loading: boolean
  saving: boolean
  requestingEmailChange: boolean
  confirmingEmailChange: boolean
  verifyingEmail: boolean
  verifiedEmail: boolean
  changingPassword: boolean
  acceptingUserInvite: boolean
  acceptedUserInvite: boolean
  error: Error | null
}

export const UserParams = Record<UserReducer>({
  user: undefined,
  id: undefined,
  email: undefined,
  name: undefined,
  phoneNumber: undefined,
  phoneNumberCountryCode: undefined,
  userType: undefined,
  language: undefined,
  profileImageURL: undefined,
  mfaChannel: undefined,
  signupCompanyRole: undefined,
  loggedIn: false,
  loggingIn: false,
  requestedPassword: false,
  requestingPassword: false,
  resettingPassword: false,
  loading: false,
  saving: false,
  requestingEmailChange: false,
  confirmingEmailChange: false,
  verifyingEmail: false,
  verifiedEmail: false,
  changingPassword: false,
  acceptingUserInvite: false,
  acceptedUserInvite: false,
  error: null,
})

export interface UserAction extends ReducerAction {
  user?: User
  id?: string
  email?: string
}

export default (state: Record<UserReducer> = UserParams(), action: UserAction = { type: '' }): Record<UserReducer> => {
  switch (action.type) {
    case ActionTypes.USER_REGISTERING:
    case ActionTypes.USER_LOGGING_IN:
      return UserParams({
        email: action.email,
        id: action.id,
        loggingIn: true,
      })
    case ActionTypes.USER_REGISTERED:
    case ActionTypes.USER_LOGGED_IN:
      return UserParams({
        user: action.user,
        id: action.user?.id,
        email: action.user?.email,
        name: action.user?.name,
        phoneNumber: action.user?.phoneNumber,
        phoneNumberCountryCode: action.user?.phoneNumberCountryCode,
        userType: action.user?.userType,
        language: action.user?.language,
        profileImageURL: action.user?.profileImageURL,
        mfaChannel: action.user?.mfaChannel,
        signupCompanyRole: action.user?.signupCompanyRole,
        loggedIn: true,
      })
    case ActionTypes.USER_REGISTERING_FAILED:
    case ActionTypes.USER_LOGIN_FAILED:
      return state.set('loggingIn', false).set('error', action.error || null)

    case ActionTypes.USER_LOADING:
      return state.set('loading', true)
    case ActionTypes.USER_LOADED:
      return UserParams({
        user: action.user,
        id: action.user?.id,
        email: action.user?.email,
        name: action.user?.name,
        phoneNumber: action.user?.phoneNumber,
        phoneNumberCountryCode: action.user?.phoneNumberCountryCode,
        userType: action.user?.userType,
        language: action.user?.language,
        profileImageURL: action.user?.profileImageURL,
        mfaChannel: action.user?.mfaChannel,
        signupCompanyRole: action.user?.signupCompanyRole,
        loggedIn: true,
      })
    case ActionTypes.USER_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loggingIn', false)
        .set('loggedIn', false)
        .set('error', action.error || null)

    case ActionTypes.USER_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.USER_UPDATED:
      return UserParams({
        user: action.user,
        id: action.user?.id,
        email: action.user?.email,
        name: action.user?.name,
        phoneNumber: action.user?.phoneNumber,
        phoneNumberCountryCode: action.user?.phoneNumberCountryCode,
        userType: action.user?.userType,
        language: action.user?.language,
        profileImageURL: action.user?.profileImageURL,
        mfaChannel: action.user?.mfaChannel,
        signupCompanyRole: action.user?.signupCompanyRole,
        loggedIn: true,
        saving: false,
      })
    case ActionTypes.USER_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_REQUESTING_EMAIL_CHANGE:
      return state.set('requestingEmailChange', true).set('error', null)
    case ActionTypes.USER_REQUESTED_EMAIL_CHANGE:
      return state.set('requestingEmailChange', false)
    case ActionTypes.USER_REQUEST_EMAIL_CHANGE_FAILED:
      return state.set('requestingEmailChange', false).set('error', action.error || null)

    case ActionTypes.USER_CONFIRMING_EMAIL_CHANGE:
      return state.set('confirmingEmailChange', true).set('error', null)
    case ActionTypes.USER_CONFIRMED_EMAIL_CHANGE:
      return UserParams({
        user: action.user,
        id: action.user?.id,
        email: action.user?.email,
        name: action.user?.name,
        phoneNumber: action.user?.phoneNumber,
        phoneNumberCountryCode: action.user?.phoneNumberCountryCode,
        userType: action.user?.userType,
        language: action.user?.language,
        profileImageURL: action.user?.profileImageURL,
        mfaChannel: action.user?.mfaChannel,
        signupCompanyRole: action.user?.signupCompanyRole,
        loggedIn: true,
      })
    case ActionTypes.USER_CONFIRM_EMAIL_CHANGE_FAILED:
      return state.set('confirmingEmailChange', false).set('error', action.error || null)

    case ActionTypes.USER_VERIFYING_EMAIL:
      return state.set('verifyingEmail', true).set('verifiedEmail', false).set('error', null)
    case ActionTypes.USER_VERIFIED_EMAIL:
      return state.set('verifyingEmail', false).set('verifiedEmail', true)
    case ActionTypes.USER_VERIFY_EMAIL_FAILED:
      return state
        .set('verifyingEmail', false)
        .set('verifiedEmail', true)
        .set('error', action.error || null)

    case ActionTypes.USER_CHANGING_PASSWORD:
      return state.set('changingPassword', true).set('error', null)
    case ActionTypes.USER_CHANGED_PASSWORD:
      return state.set('changingPassword', false)
    case ActionTypes.USER_CHANGE_PASSWORD_FAILED:
      return state.set('changingPassword', false).set('error', action.error || null)

    case ActionTypes.USER_REQUESTING_PASSWORD:
      return UserParams({
        email: action.email,
        requestingPassword: true,
      })
    case ActionTypes.USER_REQUESTED_PASSWORD:
      return UserParams({
        email: action.email,
        requestedPassword: true,
      })
    case ActionTypes.USER_REQUEST_PASSWORD_FAILED:
      return state.set('requestingPassword', false).set('error', action.error || null)

    case ActionTypes.USER_RESETTING_PASSWORD:
      return state.set('resettingPassword', true).set('error', null)
    case ActionTypes.USER_RESET_PASSWORD:
      return state.set('resettingPassword', false)
    case ActionTypes.USER_RESET_PASSWORD_FAILED:
      return state.set('resettingPassword', false).set('error', action.error || null)

    case ActionTypes.USER_ACCEPTING_INVITE:
      return state.set('acceptingUserInvite', true).set('acceptedUserInvite', false).set('error', null)
    case ActionTypes.USER_ACCEPTED_INVITE:
      return state.set('acceptingUserInvite', false).set('acceptedUserInvite', true)
    case ActionTypes.USER_ACCEPT_INVITE_FAILED:
      return state
        .set('acceptingUserInvite', false)
        .set('acceptedUserInvite', true)
        .set('error', action.error || null)

    case ActionTypes.USER_CONNECTING:
      return state.set('saving', true)
    case ActionTypes.USER_CONNECTED:
      return state.set('saving', false)
    case ActionTypes.USER_CONNECT_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGING_OUT:
      // do nothing when logging out, first actually do it when confirmed
      return state
    case ActionTypes.USER_LOGGED_OUT:
      return UserParams()
    case ActionTypes.USER_LOGOUT_FAILED:
      return state.set('error', action.error || null)
    default:
      return state
  }
}
