import posthog from 'posthog-js'
import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import { useEffectOnce, usePrevious } from 'react-use'

import { acceptAgreement, getAgreements } from './actions/agreements'
import { BootstrapResult, getBootstrap } from './actions/bootstrap'
import { getSupplementTypes, updateActiveCompany } from './actions/companies'
import { getCompanyFeatures } from './actions/company-features'
import { getCompanyGroups } from './actions/company-groups'
import { addCompanyPricingPackage, getCompanyPricingPackages } from './actions/company-pricing-packages'
import { getCompanyPricings } from './actions/company-pricings'
import { getCompanyUsers } from './actions/company-users'
import { getDashboards } from './actions/dashboards'
import { getEmployees } from './actions/employees'
import { getLeaveTypes } from './actions/leave-types'
import { removeModal } from './actions/modals'
import { getPricingPackages } from './actions/pricing-packages'
import { getSalaryTypes } from './actions/salary-types'
import { logout } from './actions/user'
import { getWarnings } from './actions/warnings'
import Modal from './components/antd/modal'
import Header from './components/header/Header'
import Landing from './components/landing/Landing'
import Modals from './components/modals/Modals'
import GDPRTerms from './components/widgets/GDPRTerms'
import jsBrowserHistory from './components/widgets/jsBrowserHistory'
import ResponsiveNoteModal from './components/widgets/ResponsiveNoteModal'
import UserSurvey from './components/widgets/UserSurvey'
import { needCompany } from './controllers/jumper/Jumper'
import { AgreementType } from './model/agreement'
import { AgreementReducer } from './reducers/agreements'
import { BootstrapReducer } from './reducers/bootstrap'
import { CompanyReducer } from './reducers/companies'
import { CompanyFeatureReducer } from './reducers/companyFeatures'
import { CompanyGroupReducer } from './reducers/companyGroups'
import { CompanyPricingPackageReducer } from './reducers/companyPricingPackages'
import { CompanyPricingReducer } from './reducers/companyPricings'
import { CompanyUserReducer } from './reducers/companyUsers'
import { DashboardReducer } from './reducers/dashboards'
import { EmployeeReducer } from './reducers/employees'
import { GlobalMessageReducer } from './reducers/globalMessage'
import { LeaveTypeReducer } from './reducers/leaveTypes'
import { ModalReducer } from './reducers/modals'
import { PricingPackageReducer } from './reducers/pricingPackages'
import { SalaryTypeReducer } from './reducers/salaryTypes'
import { SupplementTypeReducer } from './reducers/supplementTypes'
import { UserReducer } from './reducers/user'
import { WarningReducer } from './reducers/warnings'
import { paths } from './routes'
import Language from './types/language'
import { setAllowEnglishOverride } from './utils/feature-utils'
import { getCurrentLanguage, setCurrentLanguage } from './utils/language-utils'
import { connectToReducer } from './utils/reducer-utils'
import { isOutsidePage, isResponsivePage, isSSOPage } from './utils/request-utils'
import { RouteProps } from './utils/route-utils'
import { userPosthogInit } from './utils/tracking-utils'

import './App.css'

declare global {
  interface Window {
    Intercom: any
  }
}

type Reducers = {
  modals: ModalReducer
  bootstrap: BootstrapReducer
  user: UserReducer
  companies: CompanyReducer
  companyGroups: CompanyGroupReducer
  dashboards: DashboardReducer
  agreements: AgreementReducer
  employees: EmployeeReducer
  companyUsers: CompanyUserReducer
  companyFeatures: CompanyFeatureReducer
  companyPricings: CompanyPricingReducer
  companyPricingPackages: CompanyPricingPackageReducer
  leaveTypes: LeaveTypeReducer
  pricingPackages: PricingPackageReducer
  salaryTypes: SalaryTypeReducer
  supplementTypes: SupplementTypeReducer
  globalMessage: GlobalMessageReducer
  warnings: WarningReducer
}

type Actions = {
  getBootstrap: (
    justLoggedIn: boolean,
    includeActiveCompany?: boolean,
    forceSettingActiveCompany?: boolean
  ) => Promise<BootstrapResult | void>
  updateActiveCompany: (id: string | null) => void
  getAgreements: () => void
  acceptAgreement: (type: 'GDPR_1.0') => void
  getEmployees: () => void
  getCompanyUsers: () => void
  getCompanyFeatures: () => void
  getCompanyPricings: () => void
  getCompanyPricingPackages: () => void
  addCompanyPricingPackage: (pricingPackageID: string) => void
  getLeaveTypes: () => void
  getPricingPackages: (includePricingPackageID: string[]) => void
  getSupplementTypes: () => void
  getSalaryTypes: () => void
  getWarnings: () => void
  getCompanyGroups: () => void
  getDashboards: (companyID: string) => void
  removeModal: (id: number) => void
  logout: () => Promise<boolean | void>
}

type AdditionalProps = RouteProps & {
  children: ReactNode
}

function App(props: Reducers & Actions & AdditionalProps): ReactElement | null {
  const [minHeight, setMinHeight] = useState(window.innerHeight - 54)
  const [showResponsiveNoteModal, setShowResponsiveNoteModal] = useState(false)
  const [knownCurrentLanguage, setKnownCurrentLanguage] = useState<Language>(getCurrentLanguage())
  const [hashDocument, setHashDocument] = useState<string>()

  const updateMinHeight = () => {
    setMinHeight(window.innerHeight - 54)
  }

  const ssoPrePage = isSSOPage(props.location.pathname)

  const isJumpPage = props.location.pathname.indexOf('/' + paths.JUMP) === 0
  const isAboutToBeJumpPage =
    props.location.pathname.indexOf('/' + paths.LOGIN) === 0 &&
    props.location.query?.ref?.indexOf('/' + paths.JUMP) === 0

  const { user, bootstrap, getBootstrap, location } = props
  useEffectOnce(() => {
    window.addEventListener('resize', updateMinHeight)

    if (!isOutsidePage(location.pathname)) {
      if (user.loggedIn && !bootstrap.loaded) {
        getBootstrap(false, !ssoPrePage).then((res) => {
          if (res && res.user) {
            userPosthogInit(res.user)
          }
        })
      }
    }

    return () => window.removeEventListener('resize', updateMinHeight)
  })

  const {
    companies,
    agreements,
    getAgreements,
    employees,
    getEmployees,
    companyUsers,
    getCompanyUsers,
    companyFeatures,
    getCompanyFeatures,
    companyPricings,
    getCompanyPricings,
    leaveTypes,
    getLeaveTypes,
    supplementTypes,
    getSupplementTypes,
    salaryTypes,
    getSalaryTypes,
    companyGroups,
    getCompanyGroups,
    dashboards,
    getDashboards,
    warnings,
    getWarnings,
    updateActiveCompany,
  } = props

  const previousUser = usePrevious(user)

  useEffectOnce(() => {
    if (process.env.REACT_APP_POSTHOG_ID) {
      posthog.init(process.env.REACT_APP_POSTHOG_ID, {
        api_host: 'https://t.salary.dk',
        person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
      })
    }
  })

  useEffect(() => {
    if (bootstrap?.error?.message === 'Company requires SAML Access') {
      getBootstrap(true, false).then((res) => {
        if (res && res.user) {
          userPosthogInit(res.user)
        }
      })
    }
  })

  useEffect(() => {
    if (!isJumpPage && needCompany(companies, location.pathname, true)) {
      return
    }
    if (isOutsidePage(location.pathname)) {
      return // no handling for these phone pages
    }
    if (isSSOPage(location.pathname)) {
      return
    }
    const newCompanyID = location.query.companyID
    if (newCompanyID && (!companies.company || newCompanyID !== companies.company.id)) {
      updateActiveCompany(newCompanyID)
    } else if (previousUser && !previousUser.loggedIn && user.loggedIn) {
      // just logged in
      if (user.user?.showCompanyListOnLogIn) {
        updateActiveCompany(null)
      }
    }
    if (previousUser && !previousUser.loggedIn && user.loggedIn) {
      jsBrowserHistory.push(location.query.ref || '/')
    }
    const userJustLoggedIn = !!previousUser && !previousUser.loggedIn && user.loggedIn
    const bootstrapInitiated = bootstrap.loading || bootstrap.loaded
    if (userJustLoggedIn && !bootstrapInitiated) {
      getBootstrap(true, !ssoPrePage, isJumpPage || isAboutToBeJumpPage || !!newCompanyID).then((res) => {
        if (res && res.user) {
          userPosthogInit(res.user)
        }
      })
    }
    if (bootstrap.loaded) {
      const companyID = companies.company?.id
      if ((agreements.companyID && agreements.companyID !== companyID) || (!agreements.loading && !agreements.loaded)) {
        getAgreements()
      }
      if (!employees.loading && !employees.loaded) {
        getEmployees()
      }
      if (!companyUsers.loading && !companyUsers.loaded) {
        getCompanyUsers()
      }
      if (!companyFeatures.loading && !companyFeatures.loaded) {
        getCompanyFeatures()
      }
      if (!companyPricings.loading && !companyPricings.loaded) {
        getCompanyPricings()
      }
      if (!leaveTypes.loading && !leaveTypes.loaded) {
        getLeaveTypes()
      }
      if (!supplementTypes.loading && !supplementTypes.loaded) {
        getSupplementTypes()
      }
      if (!salaryTypes.loading && !salaryTypes.loaded) {
        getSalaryTypes()
      }
      if (!companyGroups.loading && !companyGroups.loaded) {
        getCompanyGroups()
      }
      if (companyID && !dashboards.loading && !dashboards.loaded) {
        getDashboards(companyID)
      }
    }
    if (!warnings.loading && !warnings.loaded) {
      getWarnings()
    }
  })

  const getMaxWidth = () => {
    const parts = props.location.pathname.split('/')
    // Remove max width on dashboard
    if (parts.length === 2 && parts[0] === '' && parts[1] === '') {
      return 'none'
    }
    // Remove max width on employee page
    if (
      (parts.length === 3 || parts.length === 4 || parts.length === 5) &&
      parts[1] === paths.EMPLOYEES &&
      parts[2] !== paths.ADD
    ) {
      return 'none'
    }
    // Remove max width on freelancer page
    if (
      (parts.length === 3 || parts.length === 4 || parts.length === 5) &&
      parts[1] === paths.FREELANCERS &&
      parts[2] !== paths.ADD
    ) {
      return 'none'
    }
    // Remove max width on account page
    if ((parts.length === 2 || parts.length === 3) && parts[1] === paths.ACCOUNT) {
      return 'none'
    }
    // Remove max width on company page
    if (
      (parts.length === 3 || parts.length === 4 || parts.length === 5) &&
      parts[1] === paths.COMPANIES &&
      parts[2] !== paths.ADD
    ) {
      return 'none'
    }
    // Remove max width on company dashboards
    if (parts.length === 2 && parts[1] === paths.DASHBOARD) {
      return 'none'
    }
    // Remove max width on pay roll page
    if (parts.length === 3 && parts[1] === paths.PAY_ROLLS) {
      return 'none'
    }
    // Remove max width on time registration page
    if (parts.length >= 2 && parts[1] === paths.TIME_REGISTRATION) {
      return 'none'
    }
    // Remove max width on salary registration page
    if (parts.length === 2 && parts[1] === paths.SALARY_REGISTRATION) {
      return 'none'
    }
    // Remove max width on leave registration page
    if (parts.length >= 2 && parts[1] === paths.LEAVE_REGISTRATION) {
      return 'none'
    }
    // Remove max width on car allowance page
    if (parts.length === 2 && parts[1] === paths.CAR_ALLOWANCE) {
      return 'none'
    }
    // Remove max width on one time pays page
    if (parts.length === 2 && parts[1] === paths.ONE_TIME_PAYS) {
      return 'none'
    }
    // Remove max width on work hours page
    if (parts.length === 2 && parts[1] === paths.WORK_HOURS) {
      return 'none'
    }
    // Remove max width on freelancers overview page
    if (parts.length === 2 && parts[1] === paths.FREELANCERS_OVERVIEW) {
      return 'none'
    }
    // Remove max width on approve tab page
    if (parts.length === 2 && parts[1] === paths.APPROVE_TAB) {
      return 'none'
    }
    // Remove max width on Swipe overview page
    if ((parts.length === 2 || parts.length === 3) && parts[1] === paths.SWIPE_OVERVIEW) {
      return 'none'
    }
    // Remove max width on integrations page
    if ((parts.length === 3 || parts.length === 4 || parts.length === 5) && parts[1] === paths.INTEGRATIONS) {
      return 'none'
    }
    // Remove max width on reimbursement voucher page
    if ((parts.length === 2 || parts.length === 3) && parts[1] === paths.REIMBURSEMENT_VOUCHERS) {
      return 'none'
    }
    // Remove max width on assets page
    if ((parts.length === 2 || parts.length === 3) && parts[1] === paths.ASSETS) {
      return 'none'
    }
    // Remove max width on a documents page
    if (parts.length >= 2 && parts[1] === paths.DOCUMENTS) {
      return 'none'
    }
    // Remove max width on a projects page
    if (parts.length >= 2 && parts[1] === paths.PROJECTS) {
      return 'none'
    }
    // Remove max width on a company groups page
    if (parts.length >= 2 && parts[1] === paths.COMPANY_GROUPS) {
      return 'none'
    }
    // Remove max width on companies page if the user is admin of the current company group
    if (parts.length === 2 && parts[1] === paths.COMPANIES) {
      const companyGroup = props.companyGroups.companyGroup
      if (companyGroup) {
        return 'none'
      }
    }
    return '1240px'
  }

  useEffect(() => {
    // update whenever the user's language is not what we currently think is the language
    if (user.language && user.language !== knownCurrentLanguage) {
      setCurrentLanguage(user.language)
      setKnownCurrentLanguage(user.language)
    }
  }, [user, knownCurrentLanguage])

  // the following handles scrolling to an element when the hash for it is set
  // of course, if the element never shows up, this will keep running forever
  // but it will only run if an actual hash is given, which is seldom the case
  const scrollToHash = useCallback((hash: string) => {
    const e = document.getElementById(hash.replace(/^#/, ''))
    if (!e) {
      setTimeout(() => {
        scrollToHash(hash)
      }, 500)
      return
    }
    const list = document.getElementsByClassName('target-element')
    for (let i = 0; i < list.length; i++) {
      list[i].className = list[i].className.replace(/ ?target-element$/, '')
    }
    e.className = e.className + ' target-element'
    e.scrollIntoView({ block: 'center' })
    setHashDocument(hash)
  }, [])

  useEffect(() => {
    if (!location.hash || location.hash === hashDocument) {
      return
    }
    scrollToHash(location.hash)
  }, [location, hashDocument, scrollToHash])

  const previousLanguage = usePrevious(knownCurrentLanguage)
  const languageJustChanged = previousLanguage && previousLanguage !== knownCurrentLanguage

  const hasAcceptedGDPR =
    !props.companies.company ||
    props.agreements.agreements.filter((agreement) => agreement.type === 'GDPR_1.0').size > 0

  if (
    !isResponsivePage(props.location.pathname) &&
    !showResponsiveNoteModal &&
    hasAcceptedGDPR &&
    screen.width < 1280
  ) {
    document.getElementById('viewport')?.setAttribute('content', 'width=1280')
  } else {
    document.getElementById('viewport')?.setAttribute('content', 'width=device-width, initial-scale=1')
  }

  const acceptAgreement = (t: AgreementType) => {
    props.acceptAgreement(t)

    if (screen.width < 1040) {
      setShowResponsiveNoteModal(true)
    }
  }

  if (isOutsidePage(props.location.pathname)) {
    if (props.location.pathname === '/' + paths.PAYROLL_APPROVE_PHONE_SITE && window.Intercom) {
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        email: props.user.email,
        user_id: props.user.id,
      })
    }
    // TODO: Remove this once English is generally permitted
    setAllowEnglishOverride(true) // ensure English is _always_ permitted on these pages
    return (
      <div lang={knownCurrentLanguage} className="phone-page-wrapper">
        {props.children}
      </div>
    )
  }

  if (!props.user.loggedIn) {
    return <>{props.children}</>
  }

  if (
    !props.companies.loaded ||
    (!ssoPrePage &&
      props.companies.company &&
      (!props.agreements.loaded || !props.companyUsers.loaded || !props.employees.loaded)) ||
    (ssoPrePage && !props.companies.loaded)
  ) {
    return <Landing />
  }

  if (window.Intercom) {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      email: props.user.email,
      user_id: props.user.id,
    })
  }

  const normalMode = (hasAcceptedGDPR && !showResponsiveNoteModal) || ssoPrePage

  return (
    <div lang={knownCurrentLanguage}>
      {props.globalMessage.message && <div className="global-message">{props.globalMessage.message}</div>}
      <Header
        location={props.location}
        user={props.user}
        dashboards={props.dashboards}
        companies={props.companies}
        companyUsers={props.companyUsers}
        companyFeatures={props.companyFeatures}
        companyGroups={props.companyGroups.companyGroups}
        employees={props.employees}
        warnings={props.warnings}
        updateActiveCompany={props.updateActiveCompany}
        logout={props.logout}
        currentLanguage={knownCurrentLanguage}
        setCurrentLanguage={setKnownCurrentLanguage}
      />
      <div
        className="main-wrapper"
        style={{
          maxWidth: getMaxWidth(),
          minHeight: `${minHeight}px`,
        }}
      >
        {normalMode && !languageJustChanged && props.children}
        {normalMode &&
          languageJustChanged &&
          React.Children.map(props.children, (child) =>
            // React does not re-render props.children, even when this component's state changes.
            // We can however force this by using this trick.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            React.cloneElement(child, { currentLanguage: knownCurrentLanguage })
          )}
        {!hasAcceptedGDPR && !ssoPrePage && (
          <Modal visible closable={false} width={579} footer={null}>
            <GDPRTerms agreements={props.agreements.agreements} acceptAgreement={acceptAgreement} />
          </Modal>
        )}
        {normalMode && (
          <Modals
            companies={props.companies}
            companyPricingPackages={props.companyPricingPackages}
            modals={props.modals}
            pricingPackages={props.pricingPackages}
            removeModal={props.removeModal}
            getCompanyPricingPackages={props.getCompanyPricingPackages}
            addCompanyPricingPackage={props.addCompanyPricingPackage}
            getPricingPackages={props.getPricingPackages}
          />
        )}

        <Modal
          visible={showResponsiveNoteModal}
          onOk={() => setShowResponsiveNoteModal(false)}
          onCancel={() => setShowResponsiveNoteModal(false)}
          footer={null}
          className="responsive-note"
        >
          <ResponsiveNoteModal closeModal={() => setShowResponsiveNoteModal(false)} />
        </Modal>

        <UserSurvey />
      </div>
    </div>
  )
}

export default connectToReducer<Reducers, Actions, AdditionalProps>(
  (state) => ({
    modals: state.modals,
    bootstrap: state.bootstrap,
    user: state.user,
    dashboards: state.dashboards,
    companies: state.companies,
    companyGroups: state.companyGroups,
    agreements: state.agreements,
    employees: state.employees,
    companyUsers: state.companyUsers,
    companyFeatures: state.companyFeatures,
    companyPricings: state.companyPricings,
    companyPricingPackages: state.companyPricingPackages,
    leaveTypes: state.leaveTypes,
    pricingPackages: state.pricingPackages,
    supplementTypes: state.supplementTypes,
    salaryTypes: state.salaryTypes,
    globalMessage: state.globalMessage,
    warnings: state.warnings,
  }),
  {
    removeModal: removeModal,
    getBootstrap: getBootstrap,
    getDashboards: getDashboards,
    updateActiveCompany: updateActiveCompany,
    getAgreements: getAgreements,
    acceptAgreement: acceptAgreement,
    getEmployees: getEmployees,
    getCompanyGroups: getCompanyGroups,
    getCompanyUsers: getCompanyUsers,
    getCompanyFeatures: getCompanyFeatures,
    getCompanyPricings: getCompanyPricings,
    getCompanyPricingPackages: getCompanyPricingPackages,
    addCompanyPricingPackage: addCompanyPricingPackage,
    getLeaveTypes: getLeaveTypes,
    getPricingPackages: getPricingPackages,
    getSupplementTypes: getSupplementTypes,
    getSalaryTypes: getSalaryTypes,
    getWarnings: getWarnings,
    logout: logout,
  }
)(App)
