import { addMonths, getMonth, getYear } from 'date-fns'
import React from 'react'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'

import { formatDate } from '../../../utils/date-utils'
import { getTranslationAntdStrings } from '../../../utils/language-utils'
import { t } from '../../../utils/translation-utils'

const startYear = 1900
const years = [...Array(getYear(new Date()) + 3 - startYear).keys()].map((i) => i + startYear)
const months = getTranslationAntdStrings()['TimePicker']['format']['months'] as string[]

export default function createCustomHeader(
  props: ReactDatePickerCustomHeaderProps & {
    showTwoMonths: boolean
  }
): React.ReactNode {
  // NEED_NO_TRANSLATION
  const date = props.date
  const currentMonth = getMonth(date)
  const currentYear = getYear(date)

  return (
    <div className="react-datepicker__header-wrapper">
      <span
        onClick={props.decreaseMonth}
        style={props.showTwoMonths && props.customHeaderCount === 1 ? { visibility: 'hidden' } : {}}
        className="react-datepicker__navigation react-datepicker__navigation--previous"
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">{'<'}</span>
      </span>

      {props.customHeaderCount === 0 && (
        <>
          <select
            value={months[currentMonth]}
            onChange={({ target: { value } }) => {
              props.changeMonth(months.indexOf(value))
            }}
            className="datepicker-select"
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={currentYear.toString()}
            onChange={({ target: { value } }) => {
              props.changeYear(parseInt(value))
            }}
            className="datepicker-select"
          >
            {years.map((option) => (
              <option key={option} value={option.toString()}>
                {option}
              </option>
            ))}
          </select>
        </>
      )}
      {props.customHeaderCount === 1 && (
        <span className="react-datepicker__current-month">
          {formatDate(addMonths(date, 1), t('date.month_of_year'))}
        </span>
      )}

      <span
        onClick={props.increaseMonth}
        style={props.showTwoMonths && props.customHeaderCount === 0 ? { visibility: 'hidden' } : {}}
        className="react-datepicker__navigation react-datepicker__navigation--next"
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">{'>'}</span>
      </span>
    </div>
  )
}
