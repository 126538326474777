import CompanyPricingPackage from '../model/companyPricingPackage'
import PricingPackage, { PricingPackageGroup } from '../model/pricingPackage'

function pricingPackageMatch(v: CompanyPricingPackage, pricingPackageID: string, companyID?: string): boolean {
  return v.companyID === companyID && v.pricingPackageID === pricingPackageID
}

export function isPricingPackageGroup(
  pricingPackages: PricingPackage[],
  companyPricingPackages: CompanyPricingPackage[],
  companyID: string | undefined,
  packageGroups: PricingPackageGroup[]
): boolean {
  return pricingPackages
    .filter((pricingPackage) => packageGroups.some((group) => group === pricingPackage.group))
    .some((pricingPackage) => companyPricingPackages.some((v) => pricingPackageMatch(v, pricingPackage.id, companyID)))
}

export function getCompanyPricingPackage(
  pricingPackages: PricingPackage[],
  companyPricingPackages: CompanyPricingPackage[],
  companyID: string
) {
  return pricingPackages.find((pricingPackage) =>
    companyPricingPackages.some((v) => pricingPackageMatch(v, pricingPackage.id, companyID))
  )
}

export function getMissingPackageIDs(
  pricingPackages: PricingPackage[],
  companyPricingPackages: CompanyPricingPackage[]
): string[] {
  const pricingPackageIDs = pricingPackages.map((v) => v.id)
  const companyPricingPackageIDs = companyPricingPackages.map((v) => v.pricingPackageID)
  return companyPricingPackageIDs.filter((v) => !pricingPackageIDs.includes(v))
}
