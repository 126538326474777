import { addDays } from 'date-fns'
import React, { ReactElement } from 'react'

import { DateFormat } from '../../model/types'
import { formatAPIDate, formatDate, getDate, isTimeBefore } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import DatePicker from '../elements/date-picker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'

type Props = {
  dispositionDate: DateFormat
}

type Fields = {
  dispositionDate: Date
}

export type DispositionDateResult = {
  dispositionDate: DateFormat
}

function EditDispositionDate(props: Props & FormComponentProps<Fields, DispositionDateResult>): ReactElement | null {
  const { decorateField } = props
  return (
    <Card className="pay-roll-edit-disposition-date">
      <Subtitle>{t('pay_roll.single.edit_disposition_date.title')}</Subtitle>
      <p>{t('pay_roll.single.edit_disposition_date.intro')}</p>
      <p>&nbsp;</p>
      <Row>
        <Col span={24}>
          {decorateField('dispositionDate', {
            placeholder: t('pay_roll.single.edit_disposition_date.disposition_date'),
            validate: (val) => {
              if (!val) {
                return t('pay_roll.single.edit_disposition_date.disposition_date.required')
              }
              const start = addDays(getDate(), 2) // add one day to allow the overdueTime to be within the period
              if (isTimeBefore(val, start)) {
                return t('pay_roll.single.edit_disposition_date.disposition_date.not_before_date', {
                  date: formatDate(start),
                })
              }
              return null
            },
          })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
        </Col>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary" block>
            {t('pay_roll.single.edit_disposition_date.submit')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default withValidations<Props, Fields, DispositionDateResult>({
  mapPropsToFields: (props) => {
    return { dispositionDate: getDate(props.dispositionDate) }
  },
  onSubmit: (values) => {
    return {
      dispositionDate: formatAPIDate(values.dispositionDate),
    }
  },
})(EditDispositionDate)
