import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import EmploymentPosition from '../model/employmentPosition'
import { ReducerAction } from '../utils/reducer-utils'

export interface EmploymentPositionReducer {
  loading: boolean
  loaded: boolean
  employmentPositions: List<EmploymentPosition>
  error: Error | null
}

const Params = Record<EmploymentPositionReducer>({
  loading: false,
  loaded: false,
  employmentPositions: List<EmploymentPosition>(),
  error: null,
})

export interface EmploymentPositionAction extends ReducerAction {
  employmentPositions?: EmploymentPosition[]
}

const comparator = (a: EmploymentPosition, b: EmploymentPosition) => {
  const order = a.group.localeCompare(b.group, 'da-dk')
  if (order !== 0) {
    return order
  }
  return a.title.localeCompare(b.title, 'da-dk')
}

export default (
  state: Record<EmploymentPositionReducer> = Params(),
  action: EmploymentPositionAction = { type: '' }
): Record<EmploymentPositionReducer> => {
  switch (action.type) {
    case ActionTypes.EMPLOYMENT_POSITIONS_LOADING:
      return Params({
        loading: true,
      })
    case ActionTypes.EMPLOYMENT_POSITIONS_LOADED:
      return Params({
        loaded: true,
        employmentPositions: List(action.employmentPositions).sort(comparator),
      })
    case ActionTypes.EMPLOYMENT_POSITIONS_LOAD_FAILED:
      return Params({
        error: action.error,
      })
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
