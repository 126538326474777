export function validateEmail(email: string): boolean {
  // RFC 1035: domains cannot start with 0-9 and hyphen; domains cannot end with hyphen
  // but apparently no respects the not starting with numbers thing, only the hyphen
  return !!email.match(
    /^[a-z0-9_.+-]+@([\da-z0-9æøåẞöäüé]{1,2}|[\da-z0-9æøåẞöäüé][\da-z0-9.æøåẞöäüé-]+[\da-z0-9.æøåẞöäüé])\.[a-z0-9.-]{2,}$/i
  )
}

export function emailDomain(email: string): string {
  return email.split('@')[1]
}
