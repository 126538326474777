import { List } from 'immutable'
import React, { ReactElement } from 'react'

import ContractDelta from '../../model/contractDelta'
import { formatDate } from '../../utils/date-utils'
import { isContractDeltaActive } from '../../utils/employee-contract-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import Button from '../elements/button'
import { Col, Row } from '../elements/grid'

type Fields = {
  contractID?: string
}

export type ContractSelectResult = {
  contractID: string
}

type Props = {
  contractDeltas: List<ContractDelta>

  handlePrevious: () => void
}

function ContractTemplateContractSelectForm(
  props: Props & FormComponentProps<Fields, ContractSelectResult>
): ReactElement | null {
  const { decorateField } = props
  return (
    <div>
      <Row>
        <Col span={24}>
          {decorateField('contractID', {
            title: t('contract_template.add.select.form.contract_id'),
            placeholder: t('contract_template.add.select.form.contract_id.placeholder'),
            validate: (val) => (!val ? t('contract_template.add.select.form.contract_id.required') : null),
          })(
            <Select>
              {props.contractDeltas.map((delta) => {
                const isActive = isContractDeltaActive(delta)
                return (
                  <Select.Option key={delta.contractID} value={delta.contractID}>
                    {t(
                      isActive
                        ? 'contract_template.add.select.form.contract_id.option.active'
                        : 'contract_template.add.select.form.contract_id.option.other',
                      { from: formatDate(delta.validFrom), to: delta.validTo ? formatDate(delta.validTo) : '' }
                    )}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('contract_template.add.select.form.submit')}
          </Button>
          <Button onClick={props.handlePrevious}>{t('contract_template.form.back')}</Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, ContractSelectResult>({
  mapPropsToFields: (props) => ({
    contractID: props.contractDeltas.find((delta) => isContractDeltaActive(delta))?.contractID,
  }),
  onSubmit: (values) => ({
    contractID: values.contractID!,
  }),
})(ContractTemplateContractSelectForm)
