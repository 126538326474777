import React, { ReactElement, ReactNode, useState } from 'react'
import { Link } from 'react-router'

type Props = {
  to: string
  children?: ReactNode
}

export default function JumpLink(props: Props): ReactElement | null {
  const [body, setBody] = useState(props.children ?? 'Hjælpehægte')
  const onClick = () => {
    if (!navigator.clipboard) {
      setBody('Skaf dig en moderne netlæser!')
      return
    }
    navigator.clipboard
      .writeText(window.location.origin + props.to)
      .then(() => {
        setBody('Hjælpehægte kopieret')
      })
      .catch(() => {
        setBody('Den gik ikke, så må du gøre det manuelt...')
      })
  }

  return (
    <Link
      to={props.to}
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
    >
      {body}
    </Link>
  )
}
