import { addDays } from 'date-fns'

import { DateFormat } from '../model/types'
import { getDate, isSameOrAfter, isTimeBefore } from './date-utils'

type ValidFromWrapper = {
  validFrom: Date
  canUpdate: boolean
}

type HasImmutableEndDate = {
  immutableEndDate?: DateFormat
}
type HasValidFromTo = {
  validFrom: DateFormat
  validTo?: DateFormat
}

export function getValidFrom(employee: HasImmutableEndDate, contract: HasValidFromTo): ValidFromWrapper {
  const immutableEndDate = employee.immutableEndDate
  let validFrom = getDate(contract.validFrom)
  // if the validTo is before now, we assume are editing an old contract, and thus therefore don't want this check
  if (contract.validTo && isTimeBefore(contract.validTo, getDate())) {
    return { validFrom, canUpdate: true }
  }
  let canUpdate = true
  if (immutableEndDate && isSameOrAfter(getDate(immutableEndDate), validFrom)) {
    validFrom = addDays(getDate(immutableEndDate), 1)
    canUpdate = false
  }
  return { validFrom, canUpdate }
}
