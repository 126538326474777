import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company, { CompanyMutableFields } from '../../model/company'
import { CompanyReducer } from '../../reducers/companies'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'
import InvoiceEmailModalForm, { InvoiceEmailFields } from './InvoiceEmailModalForm'

type Props = {
  visible: boolean
  company: Company
  companies: CompanyReducer

  addAlert: addAlertSignature
  closeModal: () => void
  updateCompany: (company: CompanyMutableFields) => void
}

export default function InvoiceEmailModal(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, companies, addAlert, company, closeModal } = props
  const previousCompanies = usePrevious(companies)

  useEffect(() => {
    if (previousCompanies && previousCompanies.saving && !companies.saving) {
      // Check for no error occurred
      if (!companies.error) {
        addAlert('success', t('invoice_email.alert.success', { name: company.name }), { timeout: 5 })
        closeModal()
      }
    }

    visibleComponentDidUpdate(visible, companies.error, error, setError)
  }, [previousCompanies, companies, company, addAlert, closeModal, visible, error])

  const handleSubmit = (values: InvoiceEmailFields) => {
    props.updateCompany({ ...props.company, ...values })
  }

  return (
    <Card className="invoice-email">
      <Title>{t('invoice_email.edit.title')}</Title>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <InvoiceEmailModalForm company={props.company} onSubmit={handleSubmit} />
      {props.companies.saving && <LoadingOverlay />}
    </Card>
  )
}
