import React, { ReactElement } from 'react'

import { decorateFieldSignature } from '../../../utils/form-utils'
import { formatCurrency } from '../../../utils/number-utils'
import { t, tx } from '../../../utils/translation-utils'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import SwitchWrapper from '../../form-elements/SwitchWrapper'
import FeatureLock from '../../widgets/FeatureLock'
import Lock from '../../widgets/Lock'

type PaySlipsTransportFields = {
  paySlipTransportMitDK: boolean
  paySlipTransportEBoks: boolean
  paySlipTransportEMail: boolean
  paySlipTransportSMS: boolean
}

type BaseProps<Fields extends PaySlipsTransportFields> = {
  hasNationalID: boolean
  hasPremiumPackage: boolean
  hasEmail: boolean
  decorateField: decorateFieldSignature<Fields>
}

type EmployeeProps<Fields extends PaySlipsTransportFields> = BaseProps<Fields> & {
  isFreelancer: false
  hasDanishPhoneNumber: boolean
  hasPhoneNumber: boolean
}

type FreelancerProps<Fields extends PaySlipsTransportFields> = BaseProps<Fields> & {
  isFreelancer: true
}

export default function PaySlipsTransport<Fields extends PaySlipsTransportFields>(
  props: EmployeeProps<Fields> | FreelancerProps<Fields>
): ReactElement | null {
  const { decorateField } = props
  return (
    <Row>
      {props.hasNationalID && (
        <>
          <Col span={6}>
            <FeatureLock featureType={'DK EBoks'} description={t('pay_slips_form.pay_slip_transport_mit_dk.lock')}>
              <SwitchWrapper id={'paySlipTransportMitDK'} decorateField={decorateField}>
                {t('pay_slips_form.pay_slip_transport_mit_dk')}
              </SwitchWrapper>
            </FeatureLock>
          </Col>
          <Col span={6}>
            <FeatureLock featureType={'DK EBoks'} description={t('pay_slips_form.pay_slip_transport_e_boks.lock')}>
              <SwitchWrapper
                id={'paySlipTransportEBoks'}
                decorateField={decorateField}
                textStyle={
                  !props.hasPremiumPackage ? { lineHeight: '28px', paddingTop: '4px', display: 'inline-block' } : {}
                }
              >
                {t('pay_slips_form.pay_slip_transport_e_boks')}
                {!props.hasPremiumPackage && (
                  <small style={{ lineHeight: '0' }}>
                    {tx('pay_slips_form.pay_slip_transport_e_boks.price_note', {
                      price: <strong>{formatCurrency(3)}</strong>,
                    })}
                  </small>
                )}
              </SwitchWrapper>
            </FeatureLock>
          </Col>
        </>
      )}
      <Col span={6}>
        <Lock isLocked={!props.hasEmail} description={t('pay_slips_form.pay_slip_transport_email.lock')}>
          <SwitchWrapper id={'paySlipTransportEMail'} decorateField={decorateField}>
            {t('pay_slips_form.pay_slip_transport_email')}
          </SwitchWrapper>
        </Lock>
      </Col>
      {!props.isFreelancer && props.hasDanishPhoneNumber && (
        <Col span={6}>
          <FeatureLock featureType={'SMS'} description={t('pay_slips_form.pay_slip_transport_sms.feature_lock')}>
            <Lock isLocked={!props.hasPhoneNumber} description={t('pay_slips_form.pay_slip_transport_sms.lock')}>
              <SwitchWrapper id={'paySlipTransportSMS'} decorateField={decorateField}>
                {t('pay_slips_form.pay_slip_transport_sms')}
              </SwitchWrapper>
            </Lock>
          </FeatureLock>
        </Col>
      )}
    </Row>
  )
}
