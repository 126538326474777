import React from 'react'

import {
  ContractBookDraftCreated,
  delContractBookIntegration,
  fetchContractBookContracts,
  fetchContractBookIntegrations,
  fetchContractBookTemplates,
  patchContractBookContract,
  postContractBookDraft,
  postContractBookIntegration,
  putContractBookIntegration,
} from '../api/contract-book'
import ActionTypes from '../constants/action-types'
import ContractBookContract from '../model/contractBookContract'
import ContractBookDraft from '../model/contractBookDraft'
import ContractBookIntegration, {
  ContractBookIntegrationCreationFields,
  ContractBookIntegrationMutableFields,
} from '../model/contractBookIntegration'
import ContractBookTemplate from '../model/contractBookTemplate'
import { ContractBookContractAction } from '../reducers/contractBookContracts'
import { ContractBookDraftAction } from '../reducers/contractBookDrafts'
import { ContractBookIntegrationAction } from '../reducers/contractBookIntegrations'
import { ContractBookTemplateAction } from '../reducers/contractBookTemplates'
import { formatError, isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'
import { addAlert } from './alerts'

function loadingContractBookIntegrations(): ContractBookIntegrationAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_INTEGRATION_LOADING,
  }
}
function loadedContractBookIntegrations(
  companyID: string,
  integrations: ContractBookIntegration[]
): ContractBookIntegrationAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_INTEGRATION_LOADED,
    companyID,
    integrations,
  }
}
function failedLoadingContractBookIntegrations(companyID: string, error: Error): ContractBookIntegrationAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_INTEGRATION_LOAD_FAILED,
    error,
    companyID,
  }
}

function updatingContractBookIntegration(companyID: string, contractBookID?: string): ContractBookIntegrationAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_INTEGRATION_UPDATING,
    companyID,
    contractBookID,
  }
}
export function updatedContractBookIntegration(
  companyID: string,
  contractBookID: string,
  integration: ContractBookIntegration
): ContractBookIntegrationAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_INTEGRATION_UPDATED,
    companyID,
    contractBookID,
    integration,
  }
}
function failedUpdatingContractBookIntegration(
  companyID: string,
  contractBookID: string | undefined,
  error: Error
): ContractBookIntegrationAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_INTEGRATION_UPDATE_FAILED,
    error,
    companyID,
    contractBookID,
  }
}

function deletingContractBookIntegration(companyID: string, contractBookID: string): ContractBookIntegrationAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_INTEGRATION_DELETING,
    companyID,
    contractBookID,
  }
}
export function deletedContractBookIntegration(
  companyID: string,
  contractBookID: string
): ContractBookIntegrationAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_INTEGRATION_DELETED,
    companyID,
    contractBookID,
  }
}
function failedDeletingContractBookIntegration(
  companyID: string,
  contractBookID: string,
  error: Error
): ContractBookIntegrationAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_INTEGRATION_DELETE_FAILED,
    error,
    companyID,
    contractBookID,
  }
}

function loadingContractBookTemplates(): ContractBookTemplateAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_TEMPLATE_LOADING,
  }
}
function loadedContractBookTemplates(companyID: string, templates: ContractBookTemplate[]): ContractBookTemplateAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_TEMPLATE_LOADED,
    companyID,
    templates,
  }
}
function failedLoadingContractBookTemplates(companyID: string, error: Error): ContractBookTemplateAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_TEMPLATE_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingContractBookDraft(): ContractBookDraftAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_DRAFT_ADDING,
  }
}
export function addedContractBookDraft(
  companyID: string,
  contractBookID: string,
  draft: ContractBookDraft
): ContractBookDraftAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_DRAFT_ADDED,
    companyID,
    contractBookID,
    draft,
  }
}
function failedAddingContractBookDraft(
  companyID: string,
  contractBookID: string,
  error: Error
): ContractBookDraftAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_DRAFT_ADD_FAILED,
    error,
    companyID,
    contractBookID,
  }
}

function loadingContractBookContracts(): ContractBookContractAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_CONTRACT_LOADING,
  }
}
function loadedContractBookContracts(companyID: string, contracts: ContractBookContract[]): ContractBookContractAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_CONTRACT_LOADED,
    companyID,
    contracts,
  }
}
function failedLoadingContractBookContracts(companyID: string, error: Error): ContractBookContractAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_CONTRACT_LOAD_FAILED,
    error,
    companyID,
  }
}

function updatingContractBookContract(companyID: string): ContractBookContractAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_CONTRACT_UPDATING,
    companyID,
  }
}
function updatedContractBookContract(
  companyID: string,
  contractID: string,
  contract: ContractBookContract
): ContractBookContractAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_CONTRACT_UPDATED,
    companyID,
    contractID,
    contract,
  }
}
function failedUpdatingContractBookContract(
  companyID: string,
  contractID: string,
  error: Error
): ContractBookContractAction {
  return {
    type: ActionTypes.CONTRACT_BOOK_CONTRACT_UPDATE_FAILED,
    error,
    companyID,
  }
}

export function getContractBookIntegrations() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ContractBookIntegration[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingContractBookIntegrations())
    return fetchContractBookIntegrations(companyID)
      .then((res) => {
        dispatch(loadedContractBookIntegrations(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingContractBookIntegrations(companyID, e))
        }
      })
  }
}

export function getContractBookTemplates() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ContractBookTemplate[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingContractBookTemplates())
    return fetchContractBookTemplates(companyID)
      .then((res) => {
        dispatch(loadedContractBookTemplates(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          if (e.message === 'ContractBook Invalid API Key') {
            dispatch(addAlert('warning', formatError(e)))
            dispatch(loadedContractBookTemplates(companyID, []))
          } else {
            dispatch(failedLoadingContractBookTemplates(companyID, e))
          }
        }
      })
  }
}

export function createContractBookIntegration(integration: ContractBookIntegrationCreationFields) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ContractBookIntegration | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingContractBookIntegration(companyID))
    return postContractBookIntegration(companyID, integration)
      .then((res) => {
        dispatch(updatedContractBookIntegration(companyID, res.data.id, res.data))
        dispatch(getContractBookTemplates())
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingContractBookIntegration(companyID, undefined, e))
        }
      })
  }
}
export function updateContractBookIntegration(
  contractBookID: string,
  integration: ContractBookIntegrationMutableFields
) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ContractBookIntegration | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(updatingContractBookIntegration(companyID, contractBookID))
    return putContractBookIntegration(companyID, contractBookID, integration)
      .then((res) => {
        dispatch(updatedContractBookIntegration(companyID, contractBookID, res.data))
        dispatch(getContractBookTemplates())
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingContractBookIntegration(companyID, contractBookID, e))
        }
      })
  }
}

export function deleteContractBookIntegration(contractBookID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<boolean | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(deletingContractBookIntegration(companyID, contractBookID))
    return delContractBookIntegration(companyID, contractBookID)
      .then(() => {
        dispatch(deletedContractBookIntegration(companyID, contractBookID))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingContractBookIntegration(companyID, contractBookID, e))
        }
      })
  }
}

export function addContractBookDraft(contractBookID: string, templateID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ContractBookDraftCreated | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingContractBookDraft())
    return postContractBookDraft(companyID, contractBookID, templateID)
      .then((res) => {
        dispatch(addedContractBookDraft(companyID, contractBookID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingContractBookDraft(companyID, contractBookID, e))
        }
      })
  }
}

export function getContractBookContracts() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ContractBookContract[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingContractBookContracts())
    return fetchContractBookContracts(companyID)
      .then((res) => {
        dispatch(loadedContractBookContracts(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          if (e.message === 'ContractBook Invalid API Key') {
            dispatch(addAlert('warning', formatError(e)))
            dispatch(loadedContractBookContracts(companyID, []))
          } else {
            dispatch(failedLoadingContractBookContracts(companyID, e))
          }
        }
      })
  }
}

export function setContractBookContractActive(contractID: string, active: boolean) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature) => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(updatingContractBookContract(companyID))
    return patchContractBookContract(companyID, contractID, active)
      .then((res) => {
        updatedContractBookContract(companyID, contractID, res.data)
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingContractBookContract(companyID, contractID, e))
        }
      })
  }
}
