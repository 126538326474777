import React from 'react'

import {
  delReimbursementVoucher,
  fetchReimbursementVouchers,
  patchReimbursementVoucher,
  patchReimbursementVouchersApprove,
  patchReimbursementVouchersDraft,
  patchReimbursementVouchersRebook,
  patchReimbursementVouchersUnapprove,
  postReimbursementVoucher,
  putReimbursementVoucher,
  ReimbursementVoucherCreate,
  ReimbursementVoucherFieldsUpdate,
  ReimbursementVoucherUpdate,
} from '../api/reimbursement-vouchers'
import ActionTypes from '../constants/action-types'
import ReimbursementVoucher from '../model/reimbursementVoucher'
import { ReimbursementVoucherAction } from '../reducers/reimbursementVouchers'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'

function loadingReimbursementVouchers(companyID: string): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_LOADING,
    companyID,
  }
}
function loadedReimbursementVouchers(
  companyID: string,
  reimbursementVouchers: ReimbursementVoucher[],
  partial = false
): ReimbursementVoucherAction {
  return {
    type: partial ? ActionTypes.REIMBURSEMENT_VOUCHER_LOADED_PARTIAL : ActionTypes.REIMBURSEMENT_VOUCHER_LOADED,
    reimbursementVouchers,
    companyID,
  }
}
function failedLoadingReimbursementVouchers(companyID: string, error: Error): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingReimbursementVoucher(): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_ADDING,
  }
}
export function addedReimbursementVoucher(reimbursementVoucher: ReimbursementVoucher): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_ADDED,
    companyID: reimbursementVoucher.companyID,
    reimbursementVoucher,
  }
}
function failedAddingReimbursementVoucher(error: Error): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_ADD_FAILED,
    error,
  }
}

function updatingReimbursementVoucher(): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_UPDATING,
  }
}
export function updatedReimbursementVoucher(reimbursementVoucher: ReimbursementVoucher): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_UPDATED,
    companyID: reimbursementVoucher.companyID,
    reimbursementVoucherID: reimbursementVoucher.id,
    reimbursementVoucher,
  }
}
function failedUpdatingReimbursementVoucher(error: Error): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_UPDATE_FAILED,
    error,
  }
}

function deletingReimbursementVoucher(): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_DELETING,
  }
}
export function deletedReimbursementVoucher(reimbursementVoucherID: string): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_DELETED,
    reimbursementVoucherID,
  }
}
function failedDeletingReimbursementVoucher(error: Error): ReimbursementVoucherAction {
  return {
    type: ActionTypes.REIMBURSEMENT_VOUCHER_DELETE_FAILED,
    error,
  }
}

export function getReimbursementVouchers(offset?: number) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<ReimbursementVoucher[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    if (!offset) {
      dispatch(loadingReimbursementVouchers(companyID))
      offset = 0
    }
    const limit = 1000
    return fetchReimbursementVouchers(companyID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedReimbursementVouchers(companyID, data)),
          (data) => dispatch(loadedReimbursementVouchers(companyID, data, true)),
          (offset) => dispatch(getReimbursementVouchers(offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingReimbursementVouchers(companyID, e))
        }
      })
  }
}

export function addReimbursementVoucher(reimbursementVoucher: ReimbursementVoucherCreate) {
  return (dispatch: React.Dispatch<any>): Promise<ReimbursementVoucher | void> => {
    dispatch(addingReimbursementVoucher())
    return postReimbursementVoucher(reimbursementVoucher)
      .then((res) => {
        dispatch(addedReimbursementVoucher(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingReimbursementVoucher(e))
        }
      })
  }
}

export function updateReimbursementVoucher(reimbursementVoucher: ReimbursementVoucherUpdate) {
  return (dispatch: React.Dispatch<any>): Promise<ReimbursementVoucher | void> => {
    dispatch(updatingReimbursementVoucher())
    return putReimbursementVoucher(reimbursementVoucher)
      .then((res) => {
        dispatch(updatedReimbursementVoucher(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingReimbursementVoucher(e))
        }
      })
  }
}

export function updateReimbursementVoucherFields(reimbursementVoucher: ReimbursementVoucherFieldsUpdate) {
  return (dispatch: React.Dispatch<any>): Promise<ReimbursementVoucher | void> => {
    dispatch(updatingReimbursementVoucher())
    return patchReimbursementVoucher(reimbursementVoucher)
      .then((res) => {
        dispatch(updatedReimbursementVoucher(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingReimbursementVoucher(e))
        }
      })
  }
}

export function approveReimbursementVouchers(reimbursementVoucherIDs: string[]) {
  return (dispatch: React.Dispatch<any>): Promise<ReimbursementVoucher[] | void> => {
    dispatch(updatingReimbursementVoucher())
    return patchReimbursementVouchersApprove(reimbursementVoucherIDs)
      .then((res) => {
        res.data.forEach((voucher) => {
          dispatch(updatedReimbursementVoucher(voucher))
        })
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingReimbursementVoucher(e))
        }
      })
  }
}

export function unapproveReimbursementVouchers(reimbursementVoucherIDs: string[]) {
  return (dispatch: React.Dispatch<any>): Promise<ReimbursementVoucher[] | void> => {
    dispatch(updatingReimbursementVoucher())
    return patchReimbursementVouchersUnapprove(reimbursementVoucherIDs)
      .then((res) => {
        res.data.forEach((voucher) => {
          dispatch(updatedReimbursementVoucher(voucher))
        })
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingReimbursementVoucher(e))
        }
      })
  }
}

export function rebookReimbursementVouchers(reimbursementVoucherIDs: string[]) {
  return (dispatch: React.Dispatch<any>): Promise<ReimbursementVoucher[] | void> => {
    dispatch(updatingReimbursementVoucher())
    return patchReimbursementVouchersRebook(reimbursementVoucherIDs)
      .then((res) => {
        res.data.forEach((voucher) => {
          dispatch(updatedReimbursementVoucher(voucher))
        })
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingReimbursementVoucher(e))
        }
      })
  }
}

export function draftReimbursementVouchers(reimbursementVoucherIDs: string[]) {
  return (dispatch: React.Dispatch<any>): Promise<ReimbursementVoucher[] | void> => {
    dispatch(updatingReimbursementVoucher())
    return patchReimbursementVouchersDraft(reimbursementVoucherIDs)
      .then((res) => {
        res.data.forEach((voucher) => {
          dispatch(updatedReimbursementVoucher(voucher))
        })
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingReimbursementVoucher(e))
        }
      })
  }
}

export function deleteReimbursementVoucher(id: string) {
  return (dispatch: React.Dispatch<any>): void => {
    dispatch(deletingReimbursementVoucher())
    delReimbursementVoucher(id)
      .then(() => {
        dispatch(deletedReimbursementVoucher(id))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingReimbursementVoucher(e))
        }
      })
  }
}
