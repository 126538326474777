import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import { EmployeeRowEmployeeDetails } from '../../utils/approve-tab-utils'
import { t } from '../../utils/translation-utils'

type CardEmployeeProps = EmployeeRowEmployeeDetails & {
  linkTo?: string
  onClick?: (e: React.MouseEvent) => void
}

export function ApproveCardEmployeeName(props: CardEmployeeProps): ReactElement | null {
  return (
    <div onClick={props.onClick}>
      {props.linkTo ? <Link to={props.linkTo}>{props.employeeName}</Link> : props.employeeName}
      {props.isFreelancer && <div>({t('approve_tab.employee_name.freelancer')})</div>}
      {props.department && (
        <div>
          {props.department}
          {props.departmentApprover && (
            <>
              <br />
              <small>
                {t('approve_tab.employee_name.leader')}: {props.departmentApprover}
              </small>
            </>
          )}
        </div>
      )}
    </div>
  )
}
