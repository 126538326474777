import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { CostCenterAccounting } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import { DateFormat } from '../../../model/types'
import { OneTimePayReducer } from '../../../reducers/oneTimePays'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import OneTimePayAccountingText from '../../form-elements/one-time-pay/OneTimePayAccountingText'
import OneTimePayAmount from '../../form-elements/one-time-pay/OneTimePayAmount'
import OneTimePayApproved from '../../form-elements/one-time-pay/OneTimePayApproved'
import OneTimePayCostCenter from '../../form-elements/one-time-pay/OneTimePayCostCenter'
import OneTimePayDispositionDate from '../../form-elements/one-time-pay/OneTimePayDispositionDate'
import OneTimePayTitle from '../../form-elements/one-time-pay/OneTimePayTitle'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  editing: boolean
  canApproveObjects: boolean
  employee: Employee
  company: Company
  oneTimePayID?: string
  oneTimePays: OneTimePayReducer
  salaryCycle: SalaryCycle
  costCenterAccounting: CostCenterAccounting
  costCenters: List<CostCenter>
}

type Fields = {
  dispositionDate?: DateFormat
  amount?: string
  rate?: string
  units?: string
  useRate: boolean
  approved: boolean
  costCenterID?: string
  title?: string
  accountingText?: string
}

export type TravelAllowanceResult = {
  dispositionDate: DateFormat
  amount: number
  rate?: number
  units?: number
  approved: boolean
  costCenterID?: string
  title: string
  accountingText?: string
}

function TravelAllowanceEditForm(
  props: Props & FormComponentProps<Fields, TravelAllowanceResult>
): ReactElement | null {
  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          <OneTimePayDispositionDate
            {...props}
            oneTimePayID={props.oneTimePayID}
            oneTimePays={props.oneTimePays.oneTimePays.toArray()}
            title={t('travel_allowance.edit.form.disposition_date')}
            placeholder={t('travel_allowance.edit.form.disposition_date.placeholder')}
          />
        </Col>
        <Col span={12}>
          <OneTimePayAmount
            {...props}
            description={t('travel_allowance.edit.form.amount')}
            disableRate={false}
            disabled={false}
          />
        </Col>
      </Row>
      <OneTimePayApproved {...props} />
      <OneTimePayCostCenter {...props} />
      <Row>
        <Col span={24}>
          <OneTimePayTitle {...props} />
        </Col>
      </Row>
      <OneTimePayAccountingText {...props} />
      {props.editing && (
        <Row>
          <Col span={24}>
            <Button htmlType="submit" size="large" type="secondary">
              {t('form.button.save_changes')}
            </Button>
          </Col>
        </Row>
      )}
      {props.oneTimePays.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, TravelAllowanceResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      useRate: false,
      approved:
        !props.company.settingsEnabled.some((setting) => setting === 'AskForOTPDraftState') && props.canApproveObjects,
    }
    if (props.oneTimePayID) {
      const oneTimePay = props.oneTimePays.oneTimePays.find((oneTimePay) => props.oneTimePayID === oneTimePay.id)
      if (oneTimePay) {
        fields.dispositionDate = oneTimePay.dispositionDate
        fields.amount = formatInputNumber(oneTimePay.amount)
        fields.rate = oneTimePay.rate ? formatInputNumber(oneTimePay.rate) : undefined
        fields.units = oneTimePay.units ? formatInputNumber(oneTimePay.units) : undefined
        if (!!fields.rate && !!fields.units) {
          fields.useRate = true
        }
        fields.title = oneTimePay.title
        fields.costCenterID = oneTimePay.costCenterID
        fields.accountingText = oneTimePay.accountingText
        fields.approved = oneTimePay.approved
      }
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'amount':
      case 'rate':
      case 'units':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => ({
    ...values,
    dispositionDate: values.dispositionDate!,
    title: values.title!,
    amount: values.useRate ? 0 : forceParseInputNumber(values.amount),
    rate: values.useRate ? forceParseInputNumber(values.rate) : undefined,
    units: values.useRate ? forceParseInputNumber(values.units) : undefined,
    accountingText: values.accountingText || undefined,
  }),
})(TravelAllowanceEditForm)
