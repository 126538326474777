import React, { ReactElement } from 'react'

import PersonVerificationPage from '../../components/person-verification-page/PersonVerificationPage'
import { connectToReducer } from '../../utils/reducer-utils'
import { RouteProps } from '../../utils/route-utils'

import './PhonePage.css'

function PersonVerificationPhonePage(props: RouteProps): ReactElement | null {
  return (
    <div className="document-signing-wrapper">
      <PersonVerificationPage signed={props.location.query?.signed === 'true'} />
    </div>
  )
}

export default connectToReducer<object, any, RouteProps>((_) => ({}), {})(PersonVerificationPhonePage)
