import AssetCategory, { AssetCategoryMutableFields } from '../model/assetCategory'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function postAssetCategory(assetCategory: AssetCategoryMutableFields): Promise<RequestResponse<AssetCategory>> {
  return secureRequest('POST', url('/v2/assetCategories'), {
    body: {
      companyID: assetCategory.companyID,
      title: assetCategory.title,
    },
  })
}

export function putAssetCategory(assetCategory: AssetCategoryMutableFields): Promise<RequestResponse<AssetCategory>> {
  return secureRequest('PUT', url('/v2/assetCategories/' + assetCategory.id), {
    body: {
      id: assetCategory.id,
      companyID: assetCategory.companyID,
      title: assetCategory.title,
    },
  })
}

export function deleteAssetCategoryRequest(assetCategoryID: string): Promise<RequestResponse<AssetCategory>> {
  return secureRequest('DELETE', url('/v2/assetCategories/' + assetCategoryID), {
    body: {},
  })
}

export function fetchAssetCategories(
  companyID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<AssetCategory[]>> {
  return secureRequest('GET', url('/v2/assetCategories', { companyID, limit, offset }))
}
