import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getPayRolls } from '../actions/pay-rolls'
import { deleteWarning } from '../actions/warnings'
import PayRollsComponent from '../components/pay-rolls/PayRolls'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { PayRollReducer } from '../reducers/payRolls'
import { WarningReducer } from '../reducers/warnings'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  payRolls: PayRollReducer
  warnings: WarningReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getPayRolls: () => void
  deleteWarning: (id: string) => void
}

function PayRolls(props: Reducers & Actions): ReactElement | null {
  const { payRolls, getPayRolls } = props
  useEffect(() => {
    if (!payRolls.loading && !payRolls.loaded) {
      getPayRolls()
    }
  })

  const loading = !props.payRolls.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  const company = props.companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  return (
    <PayRollsComponent
      alerts={props.alerts}
      company={company}
      payRolls={props.payRolls}
      warnings={props.warnings.warnings}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      deleteWarning={props.deleteWarning}
    />
  )
}

export default connectToReducer<Reducers, Actions>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    payRolls: state.payRolls,
    warnings: state.warnings,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getPayRolls: getPayRolls,
    deleteWarning: deleteWarning,
  }
)(PayRolls)
