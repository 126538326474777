import React from 'react'

import { delSalaryType, fetchSalaryTypes, patchSalaryType, postSalaryType, putSalaryType } from '../api/salary-types'
import ActionTypes from '../constants/action-types'
import SalaryType, { SalaryTypeMutableFields } from '../model/salaryType'
import { SalaryTypeAction } from '../reducers/salaryTypes'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingSalaryTypes(): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPES_LOADING,
  }
}
export function loadedSalaryTypes(companyID: string, salaryTypes: SalaryType[]): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPES_LOADED,
    companyID,
    salaryTypes,
  }
}
function failedLoadingSalaryTypes(companyID: string, error: Error): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPES_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingSalaryType(): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPE_ADDING,
  }
}
export function addedSalaryType(companyID: string, salaryType: SalaryType): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPE_ADDED,
    companyID,
    salaryType,
  }
}
function failedAddingSalaryType(companyID: string, error: Error): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPE_ADD_FAILED,
    error,
    companyID,
  }
}

function updatingSalaryType(companyID: string, salaryTypeID: string): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPE_UPDATING,
    companyID,
    salaryTypeID,
  }
}
export function updatedSalaryType(companyID: string, salaryTypeID: string, salaryType: SalaryType): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPE_UPDATED,
    companyID,
    salaryTypeID,
    salaryType,
  }
}
function failedUpdatingSalaryType(companyID: string, salaryTypeID: string, error: Error): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPE_UPDATE_FAILED,
    error,
    companyID,
    salaryTypeID,
  }
}

function activeTogglingSalaryType(companyID: string, salaryTypeID: string): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPE_ACTIVE_TOGGLING,
    companyID,
    salaryTypeID,
  }
}
export function activeToggledSalaryType(companyID: string, salaryTypeID: string, active: boolean): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPE_ACTIVE_TOGGLED,
    companyID,
    salaryTypeID,
    active,
  }
}
function failedActiveToggleSalaryType(companyID: string, salaryTypeID: string, error: Error): SalaryTypeAction {
  return {
    type: ActionTypes.SALARY_TYPE_ACTIVE_TOGGLE_FAILED,
    error,
    companyID,
    salaryTypeID,
  }
}

export function addSalaryType(salaryType: SalaryTypeMutableFields) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<SalaryType | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingSalaryType())
    return postSalaryType(companyID, salaryType)
      .then((res) => {
        dispatch(addedSalaryType(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingSalaryType(companyID, e))
        }
      })
  }
}

export function getSalaryTypes() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<SalaryType[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingSalaryTypes())
    return fetchSalaryTypes(companyID)
      .then((res) => {
        dispatch(loadedSalaryTypes(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingSalaryTypes(companyID, e))
        }
      })
  }
}

export function updateSalaryType(salaryType: SalaryTypeMutableFields) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<SalaryType | void> => {
    const companyID = getCompanyID(getState)
    const salaryTypeID = salaryType.id
    if (!companyID || !salaryTypeID) {
      return PromiseVoid
    }
    dispatch(updatingSalaryType(companyID, salaryTypeID))
    return putSalaryType(salaryType)
      .then((res) => {
        dispatch(updatedSalaryType(companyID, res.data.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingSalaryType(companyID, salaryTypeID, e))
        }
      })
  }
}

export function deleteSalaryType(salaryTypeID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(activeTogglingSalaryType(companyID, salaryTypeID))
    return delSalaryType(salaryTypeID)
      .then(() => {
        dispatch(activeToggledSalaryType(companyID, salaryTypeID, false))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedActiveToggleSalaryType(companyID, salaryTypeID, e))
        }
      })
  }
}

export function reactivateSalaryType(salaryTypeID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<SalaryType | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(activeTogglingSalaryType(companyID, salaryTypeID))
    return patchSalaryType(salaryTypeID)
      .then((res) => {
        dispatch(activeToggledSalaryType(companyID, salaryTypeID, true))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedActiveToggleSalaryType(companyID, salaryTypeID, e))
        }
      })
  }
}
