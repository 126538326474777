import ContractDelta, { BasicContractDelta } from '../model/contractDelta'
import LeaveType from '../model/leaveType'
import PensionCompany from '../model/pensionCompany'
import { BenefitDefinition, BenefitType, PensionDefinition, SalaryDefinition } from '../model/remuneration'
import SalaryCycle from '../model/salaryCycle'
import SalaryType from '../model/salaryType'
import SupplementType from '../model/supplementType'
import { DateFormat, Day } from '../model/types'
import FamilyLeaveFund from '../types/family-leave-fund'
import { formatDate } from './date-utils'
import { formatWorkCycle } from './day-utils'
import {
  formatBenefitType,
  formatBiweeklySalaryCycle,
  formatFamilyLeaveFund,
  formatLeaveTypeName,
  formatPensionDefinition,
  formatSalaryCycle,
  formatSupplementTypeFullName,
} from './format-utils'
import { formatCurrency, formatNumber } from './number-utils'
import { t, translateGroupTitle } from './translation-utils'

type ChangeType = 'initial' | 'change' | 'remove'
type Change = {
  type: ChangeType
  field: string
  value?: string
  increase: boolean
  decrease: boolean
  message: string
  salaryTypeID?: string
}

function addChange(
  initial: boolean,
  field: string,
  value?: number | string | FamilyLeaveFund | Day[][],
  salaryCycles?: SalaryCycle[]
): Change {
  const change: Change = {
    type: initial ? 'initial' : 'change',
    field: field,
    value: typeof value === 'string' ? value : undefined,
    increase: false,
    decrease: false,
    message: field,
  }
  switch (field) {
    case 'vacationTypeChange':
      change.message = t('contract_delta.field.vacation_type_change')
      break
    case 'position':
      change.message = t('contract_delta.field.position')
      break
    case 'type':
      change.message = t('contract_delta.field.type')
      if (value === 'Ordinary') {
        change.value = t('contract_delta.field.type.ordinary')
      }
      break
    case 'weeklyHours':
      change.message = t('contract_delta.field.weekly_hours')
      change.value = t('unit.hour_count', { count: Math.abs(value as number) })
      break
    case 'workCycle':
      change.message = t('contract_delta.field.work_cycle')
      change.value = formatWorkCycle(value as Day[][])
      break
    case 'workCycleAnchorDate':
      change.message = t('contract_delta.field.work_cycle_anchor_date')
      change.value = formatDate(value as DateFormat)
      break
    case 'periodWorkHours':
      change.message = t('contract_delta.field.period_work_hours')
      change.value = t('unit.hour_count', { count: Math.abs(value as number) })
      break
    case 'salaryCycleID': {
      change.message = t('contract_delta.field.salary_cycle_id')
      const salaryCycle = salaryCycles && salaryCycles.find((salaryCycle) => salaryCycle.id === value)
      if (salaryCycle) {
        change.value =
          formatSalaryCycle(salaryCycle.frequency, salaryCycle.offset) +
          (salaryCycle.frequency === 'BiWeekly' ? ` (${formatBiweeklySalaryCycle(salaryCycle, false)})` : '')
      }
      break
    }
    case 'timeRegistrationMethodType':
      change.message = t('contract_delta.field.time_registration_method_type')
      switch (value) {
        case 'Coarse':
          change.value = t('contract_delta.field.time_registration_method_type.coarse')
          break
        case 'Detailed':
          change.value = t('contract_delta.field.time_registration_method_type.detailed')
          break
        default:
          change.value = t('common.unknown')
          break
      }
      break
    case 'carAllowanceRegistrationMethodType':
      change.message = t('contract_delta.field.car_allowance_registration_method')
      switch (value) {
        case 'Coarse':
          change.value = t('contract_delta.field.car_allowance_registration_method.coarse')
          break
        case 'Detailed':
          change.value = t('contract_delta.field.car_allowance_registration_method.detailed')
          break
        default:
          change.value = t('common.unknown')
          break
      }
      break
    case 'workSchedule':
      // TODO ???
      break
    case 'extraTaxPercentage':
      change.message = t('contract_delta.field.extra_tax_percentage')
      change.value = formatNumber(Math.abs(value as number)) + '%'
      break
    case 'carAllowanceRate':
      change.message = t('contract_delta.field.car_allowance_rate')
      change.value = t('contract_delta.field.car_allowance_rate.format', {
        amount: formatCurrency(Math.abs(value as number), 2),
      })
      break
    case 'familyLeaveFund':
      change.message = t('contract_delta.field.family_leave_fund')
      change.value = formatFamilyLeaveFund(value as FamilyLeaveFund)
      break
    default:
      break // do nothing
  }
  return change
}

function addSalaryChange(change: ChangeType, salary: SalaryDefinition, salaryTypes: SalaryType[]): Change {
  const salaryType = salaryTypes.find((type) => type.id === salary.salaryTypeID)
  const value = salary.rate ? salary.rate : 0
  return {
    type: change,
    field: 'salary',
    increase: value > 0,
    decrease: value < 0,
    value: formatCurrency(Math.abs(value), 2),
    message: salaryType ? translateGroupTitle(salaryType) : translateGroupTitle(salary),
    salaryTypeID: salary.salaryTypeID,
  }
}

function addSupplementChange(change: ChangeType, type: SupplementType, value = 0): Change {
  return {
    type: change,
    field: 'supplement',
    increase: value > 0,
    decrease: value < 0,
    value: value < 1 ? formatNumber(Math.abs(value * 100), 2) + '%' : formatCurrency(value, 2),
    message: formatSupplementTypeFullName(type.name),
  }
}

function addBenefitChange(change: ChangeType, type: BenefitType, benefit?: BenefitDefinition): Change {
  let increase = true
  let decrease = false
  let value: string | undefined = undefined
  if (change !== 'remove' && benefit) {
    const amount = benefit.amount
    if (amount) {
      increase = amount > 0
      decrease = amount < 0
      value = formatCurrency(Math.abs(amount))
    }
  }
  return {
    type: change,
    field: type,
    increase: increase,
    decrease: decrease,
    value: value,
    message: formatBenefitType(type),
  }
}

function addLeaveChange(change: ChangeType, type: LeaveType, days = 0): Change {
  const title = formatLeaveTypeName(type.name)
  return {
    type: change,
    field: title,
    increase: days > 0,
    decrease: days < 0,
    value: t('unit.day_count', { count: Math.abs(days) }),
    message: title,
  }
}

function addPensionChange(change: ChangeType, title: string, pension?: PensionDefinition): Change {
  let value = 0
  let displayValue: string | undefined = undefined
  if (change !== 'remove' && pension) {
    const percentage = pension.percentage
    const fixedAmount = pension.fixedAmount
    if (percentage) {
      value = percentage
      displayValue = formatNumber(Math.abs(percentage), 2) + '%'
    }
    if (fixedAmount) {
      value = fixedAmount
      displayValue = formatCurrency(Math.abs(fixedAmount), 2)
    }
  }
  return {
    type: change,
    field: title,
    increase: value > 0,
    decrease: value < 0,
    value: displayValue,
    message: title,
  }
}

export function displayContractDelta(
  delta: ContractDelta | BasicContractDelta,
  salaryCycles: SalaryCycle[],
  salaryTypes: SalaryType[],
  pensionCompanies: PensionCompany[]
) {
  const changes = []
  const initial = 'initial' in delta ? delta.initial : false
  if (delta.dkSpecific?.vacationTypeChange) {
    changes.push(addChange(initial, 'vacationTypeChange'))
  }
  if (delta.position) {
    changes.push(addChange(initial, 'position', delta.position))
  }
  if (delta.type) {
    changes.push(addChange(initial, 'type', delta.type))
  }
  if (delta.weeklyHours) {
    changes.push(addChange(initial, 'weeklyHours', delta.weeklyHours))
  }
  if (delta.workCycle) {
    changes.push(addChange(initial, 'workCycle', delta.workCycle))
  }
  if (delta.workCycleAnchorDate) {
    changes.push(addChange(initial, 'workCycleAnchorDate', delta.workCycleAnchorDate))
  }
  if (delta.periodWorkHours) {
    changes.push(addChange(initial, 'periodWorkHours', delta.periodWorkHours))
  }
  if (delta.salaryCycleID) {
    changes.push(addChange(initial, 'salaryCycleID', delta.salaryCycleID, salaryCycles))
  }
  if (delta.timeRegistrationMethodType) {
    changes.push(addChange(initial, 'timeRegistrationMethodType', delta.timeRegistrationMethodType))
  }
  if (delta.carAllowanceRegistrationMethodType) {
    changes.push(addChange(initial, 'carAllowanceRegistrationMethodType', delta.carAllowanceRegistrationMethodType))
  }
  if (delta.workSchedule) {
    changes.push(addChange(initial, 'workSchedule', delta.workSchedule))
  }
  if (delta.extraTaxPercentage) {
    changes.push(addChange(initial, 'extraTaxPercentage', delta.extraTaxPercentage))
  }
  if (delta.carAllowanceRate) {
    changes.push(addChange(initial, 'carAllowanceRate', delta.carAllowanceRate))
  }
  if (delta.familyLeaveFund) {
    changes.push(addChange(initial, 'familyLeaveFund', delta.familyLeaveFund))
  }

  const change = initial ? 'initial' : 'change'
  delta.addRemuneration.salary.forEach((salary) => changes.push(addSalaryChange(change, salary, salaryTypes)))
  delta.addRemuneration.supplements.forEach(
    (supplement) =>
      supplement.type && changes.push(addSupplementChange(change, supplement.type, supplement.compensationRate))
  )
  delta.addRemuneration.benefits.forEach((benefit) => changes.push(addBenefitChange(change, benefit.type, benefit)))
  delta.addRemuneration.leave.forEach(
    (leave) => leave.type && changes.push(addLeaveChange(change, leave.type, leave.days))
  )
  delta.addRemuneration.pension.forEach((pension) =>
    changes.push(addPensionChange(change, formatPensionDefinition(pension, pensionCompanies), pension))
  )

  delta.delRemuneration.salary.forEach((salary) => changes.push(addSalaryChange('remove', salary, salaryTypes)))
  delta.delRemuneration.supplements.forEach(
    (supplement) => supplement.type && changes.push(addSupplementChange('remove', supplement.type))
  )
  delta.delRemuneration.benefits.forEach((benefit) => changes.push(addBenefitChange('remove', benefit.type)))
  delta.delRemuneration.leave.forEach((leave) => leave.type && changes.push(addLeaveChange('remove', leave.type)))
  delta.delRemuneration.pension.forEach((pension) =>
    changes.push(addPensionChange('remove', formatPensionDefinition(pension, pensionCompanies)))
  )

  return changes
}
