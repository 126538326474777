import React, { ReactElement, useEffect } from 'react'

import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { CompanyReducer } from '../reducers/companies'
import { paths } from '../routes'
import { connectToReducer } from '../utils/reducer-utils'
import { url, URLQuery } from '../utils/request-utils'
import { RouteProps, splatString } from '../utils/route-utils'

type Reducers = {
  companies: CompanyReducer
}

function InAccounting(props: Reducers & RouteProps): ReactElement | null {
  const { companies, params, location } = props
  useEffect(() => {
    const company = companies.company
    if (!company) {
      jsBrowserHistory.push('/')
      return
    }
    // Redirect to accounting setup
    const p: URLQuery = { state: splatString(params.splat), ...location.query }
    if (location.hash) {
      const items = location.hash.substring(1).split('&')
      items.forEach((item) => {
        const [key, val] = item.split('=')
        p[key] = decodeURIComponent(val)
      })
    }
    jsBrowserHistory.push(url('/' + paths.COMPANIES + '/' + company.id + '/' + paths.ACCOUNTING + '/' + paths.ADD, p))
  })

  return (
    <div style={{ position: 'relative', minHeight: '300px', marginTop: '96px' }}>
      <LoadingOverlay />
    </div>
  )
}

export default connectToReducer<Reducers, any, RouteProps>(
  (state) => ({
    companies: state.companies,
  }),
  {}
)(InAccounting)
