import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import AssetCategory from '../model/assetCategory'
import { ReducerAction } from '../utils/reducer-utils'

export interface AssetCategoryReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  assetCategories: List<AssetCategory>
  error: Error | null
}

const Params = Record<AssetCategoryReducer>({
  companyID: undefined,
  employeeID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  assetCategories: List<AssetCategory>(),
  error: null,
})

const comparator = (a: AssetCategory, b: AssetCategory) => {
  return a.title.localeCompare(b.title, 'da-dk')
}

export interface AssetCategoryAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  assetCategories?: AssetCategory[]
  assetCategory?: AssetCategory
  assetCategoryID?: string
}

export default (
  state: Record<AssetCategoryReducer> = Params(),
  action: AssetCategoryAction = { type: '' }
): Record<AssetCategoryReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    // Loading actions
    case ActionTypes.ASSET_CATEGORY_LOADING:
      return Params({
        loading: true,
        companyID: action.companyID || state.get('companyID'),
      })
    case ActionTypes.ASSET_CATEGORY_LOADED:
    case ActionTypes.ASSET_CATEGORY_LOADED_PARTIAL:
      return Params({
        loading: action.type === ActionTypes.ASSET_CATEGORY_LOADED_PARTIAL,
        loaded: true,
        companyID: action.companyID || state.get('companyID'),
        assetCategories: state
          .get('assetCategories')
          .concat(List<AssetCategory>(action.assetCategories))
          .sort(comparator),
      })
    case ActionTypes.ASSET_CATEGORY_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        error: action.error || null,
      })

    case ActionTypes.ASSET_CATEGORY_CREATING:
      return state.set('saving', true).set('error', null)

    // Creating actions
    case ActionTypes.ASSET_CATEGORY_CREATED:
      if (!action.assetCategory) {
        return state
      }
      idx = state
        .get('assetCategories')
        .findIndex((item) => !!action.assetCategory && item.id === action.assetCategory.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('assetCategories', state.get('assetCategories').set(idx, action.assetCategory).sort(comparator))
      }
      return state
        .set('saving', false)
        .set('assetCategories', state.get('assetCategories').push(action.assetCategory).sort(comparator))
    case ActionTypes.ASSET_CATEGORY_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    // Updating actions
    case ActionTypes.ASSET_CATEGORY_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ASSET_CATEGORY_UPDATED:
      if (!action.assetCategory) {
        return state
      }
      idx = state.get('assetCategories').findIndex((item) => item.id === action.assetCategory?.id)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state
        .set('saving', false)
        .set('assetCategories', state.get('assetCategories').set(idx, action.assetCategory).sort(comparator))
    case ActionTypes.ASSET_CATEGORY_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    // Deleting actions
    case ActionTypes.ASSET_CATEGORY_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ASSET_CATEGORY_DELETED:
      if (action.assetCategoryID) {
        idx = state.get('assetCategories').findIndex((item) => {
          return item.id === action.assetCategoryID
        })
      }
      if (idx === -1) {
        return state
      }
      return state.set('saving', false).set('assetCategories', state.get('assetCategories').delete(idx))
    case ActionTypes.ASSET_CATEGORY_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
