import React, { ReactElement, useState } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import { delMfa } from '../../api/mfa'
import { RecoveryCodeReducer } from '../../reducers/recoveryCodes'
import { UserReducer } from '../../reducers/user'
import MfaChannel from '../../types/mfa-channel'
import { formatError } from '../../utils/error-utils'
import { formatMfaChannel } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import LoadingOverlay from '../widgets/LoadingOverlay'
import MfaEditModal from './MfaEditModal'

type Props = {
  user: UserReducer
  recoveryCodes: RecoveryCodeReducer

  addAlert: addAlertSignature
}

export default function MfaTab(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const [saving, setSaving] = useState(false)

  const setEditVisibility = (id: string | boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditing(id)
  }

  const disableMfa = (e: React.MouseEvent) => {
    e.preventDefault()

    if (window.confirm(t('common.are_you_sure'))) {
      setError(null)
      setSaving(true)
      delMfa()
        .then(() => {
          props.addAlert('success', t('account.mfa.message.mfa_disabled'), { timeout: 5 })
          setSaving(false)
        })
        .catch((e) => {
          setError(e)
          setSaving(false)
        })
    }
  }

  const hasMfa = () => {
    return props.user.mfaChannel !== MfaChannel.NONE
  }

  return (
    <div>
      <Card>
        <TitleMenu>
          {!hasMfa() && (
            <Button type="primary" onClick={() => setEditVisibility(true)}>
              {t('account.mfa.setup')}
            </Button>
          )}
          {hasMfa() && (
            <Button type="danger" onClick={disableMfa}>
              {t('account.mfa.disable')}
            </Button>
          )}
          {saving && <LoadingOverlay />}
        </TitleMenu>
        <Title>{t('account.mfa')}</Title>

        {error && <Alert message={formatError(error)} type="error" showIcon />}

        {!hasMfa() && <p>{t('account.mfa.message.mfa_not_enabled')}</p>}
        {hasMfa() && (
          <div>
            <Row>
              <Col span={8}>
                {t('account.mfa.enabled_via')}:
                <br />
                <strong>{formatMfaChannel(props.user.mfaChannel || MfaChannel.NONE)}</strong>
              </Col>
            </Row>
            <Row>
              <Col>&nbsp;</Col>
            </Row>
            <Subtitle>{t('account.mfa.recovery_codes')}</Subtitle>
            <p>{t('account.mfa.message.recovery_codes_explanation')}</p>
            <div className="account-recovery-codes">
              {props.recoveryCodes.recoveryCodes.map((recoveryCode) => {
                return (
                  <div key={recoveryCode}>
                    {recoveryCode}
                    <span>,&nbsp;</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </Card>

      <Modal
        key={modalKey}
        visible={editing}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={376}
        footer={null}
      >
        <MfaEditModal
          visible={editing !== false}
          closeModal={() => setEditVisibility(false)}
          addAlert={props.addAlert}
        />
      </Modal>
    </div>
  )
}
