import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Employment from '../model/employment'
import { ReducerAction } from '../utils/reducer-utils'

export interface EmploymentReducer {
  employeeID: string | null
  loading: boolean
  loaded: boolean
  saving: boolean
  employments: List<Employment>
  error: Error | null
}

const Params = Record<EmploymentReducer>({
  employeeID: null,
  loading: false,
  loaded: false,
  saving: false,
  employments: List<Employment>(),
  error: null,
})

export interface EmploymentAction extends ReducerAction {
  employeeID?: string
  employments?: Employment[]
  employment?: Employment
  employmentID?: string
}

const comparator = (a: Employment, b: Employment) => {
  if (!a.endDate) {
    return -1
  }
  if (!b.endDate) {
    return 1
  }
  return a.endDate.localeCompare(b.endDate)
}

export default (
  state: Record<EmploymentReducer> = Params(),
  action: EmploymentAction = { type: '' }
): Record<EmploymentReducer> => {
  let idx = -1
  switch (action.type) {
    case ActionTypes.EMPLOYMENT_LOADING:
      return Params({
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.EMPLOYMENT_LOADED:
      return Params({
        employeeID: action.employeeID,
        loaded: true,
        employments: List<Employment>(action.employments).sort(comparator),
      })
    case ActionTypes.EMPLOYMENT_LOAD_FAILED:
      return Params({
        employeeID: action.employeeID,
        error: action.error,
      })

    case ActionTypes.EMPLOYMENT_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EMPLOYMENT_ADDED:
      if (!action.employment) {
        return state
      }
      idx = state.get('employments').findIndex((item) => !!action.employment && item.id === action.employment.id)
      if (idx !== -1) {
        return state.set('saving', false).set('employments', state.get('employments').set(idx, action.employment))
      }
      return state.set('employments', state.get('employments').push(action.employment))
    case ActionTypes.EMPLOYMENT_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.EMPLOYMENT_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.EMPLOYMENT_UPDATED:
      if (!action.employment) {
        return state
      }
      idx = state.get('employments').findIndex((item) => item.id === action.employmentID)
      if (idx === -1) {
        return state
          .set('saving', false)
          .set('employments', state.get('employments').push(action.employment).sort(comparator))
      }
      return state
        .set('saving', false)
        .set('employments', state.get('employments').set(idx, action.employment).sort(comparator))
    case ActionTypes.EMPLOYMENT_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
