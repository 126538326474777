import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import CompanySetting from '../../../model/companySetting'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import PensionCompany from '../../../model/pensionCompany'
import { ContractReducer } from '../../../reducers/contracts'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate } from '../../../utils/date-utils'
import { getValidFrom } from '../../../utils/employment-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import EmploymentPensionEditForm, { PensionResult } from './EmploymentPensionEditForm'

type Props = {
  visible: boolean
  settingsEnabled: CompanySetting[]
  employee: Employee
  mutableContract: Contract
  contracts: ContractReducer
  pensionCompanies: List<PensionCompany>
  leaveTypes: List<LeaveType>

  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
}

export default function EmploymentPensionEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, contracts } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, contracts.error, error, setError)
  }, [visible, contracts, error])

  const handleSubmit = (values: PensionResult) => {
    if (!props.mutableContract.remuneration) {
      return
    }
    const contract = {
      ...props.mutableContract,
      remuneration: {
        ...props.mutableContract.remuneration,
        pension: values.remuneration.pension,
        leave: values.remuneration.leave,
      },
    }
    const { validFrom, canUpdate } = getValidFrom(props.employee, contract)
    if (canUpdate) {
      props.updateContract(contract)
    } else {
      contract.validFrom = formatAPIDate(validFrom)
      props.addContract(contract)
    }
  }

  return (
    <Card>
      <Subtitle>{t('pension.card.edit.title')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <EmploymentPensionEditForm
        mutableContract={props.mutableContract}
        validFrom={getValidFrom(props.employee, props.mutableContract).validFrom}
        settingsEnabled={props.settingsEnabled}
        employee={props.employee}
        contracts={props.contracts}
        pensionCompanies={props.pensionCompanies}
        leaveTypes={props.leaveTypes}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
