import ApiKey, { ApiKeyCreationFields } from '../model/apiKey'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchApiKeys(companyID: string): Promise<RequestResponse<ApiKey[]>> {
  return secureRequest('GET', url('/v2/apiKeys', { companyID }))
}

export function postApiKey(companyID: string, apiKey: ApiKeyCreationFields): Promise<RequestResponse<ApiKey>> {
  return secureRequest('POST', url('/v2/apiKeys'), {
    body: {
      companyID: companyID,
      description: apiKey.description,
      scopes: apiKey.scopes,
    },
  })
}

export function delApiKey(apiKeyID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/apiKeys/' + apiKeyID))
}
