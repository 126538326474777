import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import PayRollDeviation from '../model/payRollDeviation'
import { ReducerAction } from '../utils/reducer-utils'

export interface PayRollDeviationReducer {
  companyID: string | undefined
  payRollID: string | undefined
  loaded: boolean
  loading: boolean
  payRollDeviations: List<PayRollDeviation>
  error: Error | null
}

const Params = Record<PayRollDeviationReducer>({
  companyID: undefined,
  payRollID: undefined,
  loaded: false,
  loading: false,
  payRollDeviations: List<PayRollDeviation>(),
  error: null,
})

export interface PayRollDeviationAction extends ReducerAction {
  companyID?: string
  payRollID?: string
  payRollDeviations?: PayRollDeviation[]
  payRollDeviation?: PayRollDeviation
  payRollDeviationID?: string
}

export default (
  state: Record<PayRollDeviationReducer> = Params(),
  action: PayRollDeviationAction = { type: '' }
): Record<PayRollDeviationReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active pay roll
  if (state.get('payRollID') && action.payRollID && action.payRollID !== state.get('payRollID')) {
    return state
  }
  if (
    action.payRollDeviation &&
    state.get('payRollID') &&
    action.payRollDeviation.payRollID !== state.get('payRollID')
  ) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.PAY_ROLL_DEVIATION_LOADING:
      return Params({
        payRollID: action.payRollID,
        loading: true,
      })
    case ActionTypes.PAY_ROLL_DEVIATION_LOADED:
      return Params({
        payRollID: action.payRollID,
        loaded: true,
        payRollDeviations: List<PayRollDeviation>(action.payRollDeviations),
      })
    case ActionTypes.PAY_ROLL_DEVIATION_LOAD_FAILED:
      return Params({
        payRollID: action.payRollID,
        error: action.error,
      })

    case ActionTypes.PAY_ROLL_DEVIATION_ADDED:
    case ActionTypes.PAY_ROLL_DEVIATION_UPDATED:
      if (!action.payRollDeviation) {
        return state
      }
      idx = state
        .get('payRollDeviations')
        .findIndex((item) => !!action.payRollDeviation && item.id === action.payRollDeviation.id)
      if (idx !== -1) {
        return state.set('payRollDeviations', state.get('payRollDeviations').set(idx, action.payRollDeviation))
      }
      return state.set('payRollDeviations', state.get('payRollDeviations').push(action.payRollDeviation))

    case ActionTypes.PAY_ROLL_DEVIATION_DELETED:
      idx = state.get('payRollDeviations').findIndex((item) => item.id === action.payRollDeviationID)
      if (idx === -1) {
        return state
      }
      return state.set('payRollDeviations', state.get('payRollDeviations').delete(idx))

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
