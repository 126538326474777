import React, { CSSProperties, ReactElement } from 'react'

import './Elements.css'

// NEED_NO_TRANSLATION

export default function Blocktitle(props: { style?: CSSProperties; children?: React.ReactNode }): ReactElement {
  return (
    <h5 className="h5" style={props.style}>
      {props.children}
    </h5>
  )
}
