import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import Company from '../../../model/company'
import CompanyFeature from '../../../model/companyFeature'
import Employee from '../../../model/employee'
import { EmployeeEmergencyContactMutableFields } from '../../../model/employeeEmergencyContact'
import Employment from '../../../model/employment'
import { BankReducer } from '../../../reducers/banks'
import { EmployeeEmergencyContactReducer } from '../../../reducers/employeeEmergencyContacts'
import { EmployeeReducer } from '../../../reducers/employees'
import { EmploymentReducer } from '../../../reducers/employments'
import { UserReducer } from '../../../reducers/user'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Title from '../../elements/Title'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import ProfileForm, { ProfileResult } from './ProfileForm'

type Props = {
  user: UserReducer
  employee: Employee
  company: Company
  companyFeatures: List<CompanyFeature>
  employees: EmployeeReducer
  employeeEmergencyContacts: EmployeeEmergencyContactReducer
  employments: EmploymentReducer
  banks: BankReducer
  canEditObjects: boolean

  addAlert: addAlertSignature
  updateEmployee: (employee: Employee) => Promise<Employee | void>
  updateEmployeeEmergencyContact: (employeeID: string, contact: EmployeeEmergencyContactMutableFields) => void
  deleteEmployeeEmergencyContact: (employeeID: string) => void
  updateEmployment: (employeeID: string, employment: Employment) => void
  getBanks: () => void
}

export default function ProfileTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { banks, getBanks } = props
  useEffect(() => {
    if (!banks.loaded && !banks.loading) {
      getBanks()
    }
  })

  const { employees, addAlert } = props
  const previousEmployees = usePrevious(employees)

  useEffect(() => {
    // Check for save callback
    if (previousEmployees && previousEmployees.saving && !employees.saving) {
      // Check for no error occurred
      if (!employees.error) {
        addAlert('success', t('employee_single.profile.alert.success'), {
          timeout: 5,
        })
      }
    }
  })

  const { employments } = props

  useEffect(() => {
    regularComponentDidUpdate(employees.error || employments.error, error, setError)
  }, [employees, employments, error])

  const handleSubmit = (values: ProfileResult) => {
    const employee: Employee = { ...props.employee, ...values, onboardingState: 'Final' }
    props
      .updateEmployee(employee)
      .then(() => {
        const employment = props.employee.activeEmployment
        if (employment) {
          if (employment.employeeNumber !== values.employeeNumber) {
            return props.updateEmployment(employee.id, { ...employment, employeeNumber: values.employeeNumber })
          }
        }
      })
      .then(() => {
        const emergencyContact: EmployeeEmergencyContactMutableFields = {
          name: values.emergencyContactName,
          email: values.emergencyContactEmail,
          phoneNumberCountryCode: values.emergencyContactPhoneNumberCountryCode,
          phoneNumber: values.emergencyContactPhoneNumber,
          relation: values.emergencyContactRelation,
        }
        if (
          emergencyContact.name ||
          emergencyContact.email ||
          emergencyContact.phoneNumber ||
          emergencyContact.relation
        ) {
          props.updateEmployeeEmergencyContact(employee.id, emergencyContact)
        } else {
          props.deleteEmployeeEmergencyContact(employee.id)
        }
      })
  }

  if (!props.banks.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <Card>
      <Title>{t('employee_single.profile.title')}</Title>

      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <ProfileForm
        user={props.user}
        employee={props.employee}
        company={props.company}
        companyFeatures={props.companyFeatures}
        employees={props.employees}
        employeeEmergencyContacts={props.employeeEmergencyContacts}
        employments={props.employments}
        banks={props.banks.banks}
        disabled={!props.canEditObjects}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
