import { List, Record as ImmutableRecord } from 'immutable'

import ActionTypes from '../constants/action-types'
import ReimbursementVoucher, { ReimbursementVoucherBookingState } from '../model/reimbursementVoucher'
import { ReducerAction } from '../utils/reducer-utils'

export interface ReimbursementVoucherReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  reimbursementVouchers: List<ReimbursementVoucher>
  error: Error | null
}

const Params = ImmutableRecord<ReimbursementVoucherReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  reimbursementVouchers: List<ReimbursementVoucher>(),
  error: null,
})

export interface ReimbursementVoucherAction extends ReducerAction {
  companyID?: string
  reimbursementVouchers?: ReimbursementVoucher[]
  reimbursementVoucher?: ReimbursementVoucher
  reimbursementVoucherID?: string
}

const order: Record<ReimbursementVoucherBookingState, number> = {
  Pending: 0,
  Ready: 1,
  Failed: 2,
  Booked: 3,
  Daybooked: 4,
  Manual: 5,
}
const comparator = (a: ReimbursementVoucher, b: ReimbursementVoucher) => {
  const sort = b.createdAt.localeCompare(a.createdAt, 'da-dk')
  if (sort !== 0) {
    return sort
  }
  return order[a.bookingState] - order[b.bookingState]
}

export default (
  state: ImmutableRecord<ReimbursementVoucherReducer> = Params(),
  action: ReimbursementVoucherAction = { type: '' }
): ImmutableRecord<ReimbursementVoucherReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.REIMBURSEMENT_VOUCHER_LOADING:
      return state
        .set('loading', true)
        .set('loaded', false)
        .set('companyID', action.companyID || state.get('companyID'))
    case ActionTypes.REIMBURSEMENT_VOUCHER_LOADED:
    case ActionTypes.REIMBURSEMENT_VOUCHER_LOADED_PARTIAL:
      return state
        .set('loading', action.type === ActionTypes.REIMBURSEMENT_VOUCHER_LOADED_PARTIAL)
        .set('loaded', true)
        .set('companyID', action.companyID || state.get('companyID'))
        .set(
          'reimbursementVouchers',
          state.get('reimbursementVouchers').concat(List(action.reimbursementVouchers)).sort(comparator)
        )
        .set('error', null)
    case ActionTypes.REIMBURSEMENT_VOUCHER_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', action.companyID || state.get('companyID'))
        .set('error', action.error || null)

    case ActionTypes.REIMBURSEMENT_VOUCHER_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.REIMBURSEMENT_VOUCHER_ADDED:
      if (!action.reimbursementVoucher) {
        return state
      }
      idx = state
        .get('reimbursementVouchers')
        .findIndex((item) => !!action.reimbursementVoucher && item.id === action.reimbursementVoucher.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set(
            'reimbursementVouchers',
            state.get('reimbursementVouchers').set(idx, action.reimbursementVoucher).sort(comparator)
          )
      }
      return state
        .set('saving', false)
        .set(
          'reimbursementVouchers',
          state.get('reimbursementVouchers').push(action.reimbursementVoucher).sort(comparator)
        )
    case ActionTypes.REIMBURSEMENT_VOUCHER_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.REIMBURSEMENT_VOUCHER_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.REIMBURSEMENT_VOUCHER_UPDATED:
      if (!action.reimbursementVoucher) {
        return state
      }
      idx = state.get('reimbursementVouchers').findIndex((item) => item.id === action.reimbursementVoucherID)
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set(
          'reimbursementVouchers',
          state.get('reimbursementVouchers').set(idx, action.reimbursementVoucher).sort(comparator)
        )
    case ActionTypes.REIMBURSEMENT_VOUCHER_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.REIMBURSEMENT_VOUCHER_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.REIMBURSEMENT_VOUCHER_DELETED:
      idx = state.get('reimbursementVouchers').findIndex((item) => item.id === action.reimbursementVoucherID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('reimbursementVouchers', state.get('reimbursementVouchers').delete(idx))
    case ActionTypes.REIMBURSEMENT_VOUCHER_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
