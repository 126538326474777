import React from 'react'

import { fetchOneTimePayPensions, postOneTimePayPension, putOneTimePayPension } from '../api/one-time-pay-pensions'
import ActionTypes from '../constants/action-types'
import OneTimePayPension, {
  OneTimePayPensionCreationFields,
  OneTimePayPensionMutableFields,
} from '../model/oneTimePayPension'
import { OneTimePayPensionAction } from '../reducers/oneTimePayPensions'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingOneTimePayPensions(companyID?: string, employeeID?: string): OneTimePayPensionAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_PENSION_LOADING,
    companyID,
    employeeID,
  }
}
function loadedOneTimePayPensions(
  companyID: string | undefined,
  employeeID: string | undefined,
  oneTimePayPensions: OneTimePayPension[],
  partial = false
): OneTimePayPensionAction {
  return {
    type: partial ? ActionTypes.ONE_TIME_PAY_PENSION_LOADED_PARTIAL : ActionTypes.ONE_TIME_PAY_PENSION_LOADED,
    oneTimePayPensions,
    companyID,
    employeeID,
  }
}
function failedLoadingOneTimePayPensions(
  companyID: string | undefined,
  employeeID: string | undefined,
  error: Error
): OneTimePayPensionAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_PENSION_LOAD_FAILED,
    error,
    companyID,
    employeeID,
  }
}

function addingOneTimePayPension(employeeID: string): OneTimePayPensionAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_PENSION_ADDING,
    employeeID,
  }
}
export function addedOneTimePayPension(
  employeeID: string,
  oneTimePayPension: OneTimePayPension
): OneTimePayPensionAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_PENSION_ADDED,
    employeeID,
    oneTimePayPension,
  }
}
function failedAddingOneTimePayPension(employeeID: string, error: Error): OneTimePayPensionAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_PENSION_ADD_FAILED,
    error,
    employeeID,
  }
}

function updatingOneTimePayPension(employeeID: string): OneTimePayPensionAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_PENSION_UPDATING,
    employeeID,
  }
}
export function updatedOneTimePayPension(
  employeeID: string,
  oneTimePayPension: OneTimePayPension
): OneTimePayPensionAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_PENSION_UPDATED,
    employeeID,
    oneTimePayID: oneTimePayPension.oneTimePay.id,
    oneTimePayPension,
  }
}
function failedUpdatingOneTimePayPension(employeeID: string, error: Error): OneTimePayPensionAction {
  return {
    type: ActionTypes.ONE_TIME_PAY_PENSION_UPDATE_FAILED,
    error,
    employeeID,
  }
}

export function getOneTimePayPensions(companyID?: string, employeeID?: string, offset?: number) {
  return (dispatch: React.Dispatch<any>): Promise<OneTimePayPension[] | void> => {
    if (!offset) {
      dispatch(loadingOneTimePayPensions(companyID, employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchOneTimePayPensions(companyID, employeeID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedOneTimePayPensions(companyID, employeeID, data)),
          (data) => dispatch(loadedOneTimePayPensions(companyID, employeeID, data, true)),
          (offset) => dispatch(getOneTimePayPensions(companyID, employeeID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingOneTimePayPensions(companyID, employeeID, e))
        }
      })
  }
}

export function addOneTimePayPension(employeeID: string, oneTimePayPension: OneTimePayPensionCreationFields) {
  return (dispatch: React.Dispatch<any>): Promise<OneTimePayPension | void> => {
    dispatch(addingOneTimePayPension(employeeID))
    return postOneTimePayPension(employeeID, oneTimePayPension)
      .then((res) => {
        dispatch(addedOneTimePayPension(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingOneTimePayPension(employeeID, e))
        }
      })
  }
}

export function updateOneTimePayPension(employeeID: string, oneTimePayPension: OneTimePayPensionMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<OneTimePayPension | void> => {
    dispatch(updatingOneTimePayPension(oneTimePayPension.id))
    return putOneTimePayPension(oneTimePayPension)
      .then((res) => {
        dispatch(updatedOneTimePayPension(oneTimePayPension.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingOneTimePayPension(oneTimePayPension.id, e))
        }
      })
  }
}
