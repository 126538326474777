import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import PaySlip from '../model/paySlip'
import { ReducerAction } from '../utils/reducer-utils'

export interface PaySlipReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  payRollID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  paySlips: List<PaySlip>
  error: Error | null
}

const Params = Record<PaySlipReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  payRollID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  paySlips: List<PaySlip>(),
  error: null,
})

export interface PaySlipAction extends ReducerAction {
  companyID?: string
  payRollID?: string
  employeeID?: string
  paySlips?: PaySlip[]
  paySlip?: PaySlip
  paySlipID?: string
}

const comparator = (a: PaySlip, b: PaySlip) => {
  return b.dispositionDate.localeCompare(a.dispositionDate)
}

export default (
  state: Record<PaySlipReducer> = Params(),
  action: PaySlipAction = { type: '' }
): Record<PaySlipReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.PAY_SLIP_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        payRollID: action.payRollID,
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.PAY_SLIP_LOADED:
    case ActionTypes.PAY_SLIP_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        payRollID: action.payRollID,
        employeeID: action.employeeID,
        loading: action.type === ActionTypes.PAY_SLIP_LOADED_PARTIAL,
        loaded: true,
        paySlips: state.get('paySlips').concat(List(action.paySlips)).sort(comparator),
      })
    case ActionTypes.PAY_SLIP_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        payRollID: action.payRollID,
        employeeID: action.employeeID,
        error: action.error,
      })

    case ActionTypes.PAY_SLIP_ADDED:
      if (!action.paySlip) {
        return state
      }
      if (action.payRollID && state.get('payRollID')) {
        // only process actions for the active pay roll
        if (action.payRollID !== state.get('payRollID')) {
          return state
        }
      } else {
        // only process actions for the active employee
        if (action.employeeID !== state.get('employeeID')) {
          return state
        }
      }
      idx = state.get('paySlips').findIndex((item) => !!action.paySlip && item.id === action.paySlip.id)
      if (idx !== -1) {
        return state.set('paySlips', state.get('paySlips').set(idx, action.paySlip).sort(comparator))
      }
      return state.set('paySlips', state.get('paySlips').push(action.paySlip).sort(comparator))
    case ActionTypes.PAY_SLIP_UPDATED:
      if (!action.paySlip) {
        return state
      }
      idx = state.get('paySlips').findIndex((item) => item.id === action.paySlipID)
      if (idx === -1) {
        return state
      }
      return state.set('paySlips', state.get('paySlips').set(idx, action.paySlip).sort(comparator))
    case ActionTypes.PAY_SLIP_DELETED:
      idx = state.get('paySlips').findIndex((item) => item.id === action.paySlipID)
      if (idx === -1) {
        return state
      }
      return state.set('paySlips', state.get('paySlips').delete(idx))

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
