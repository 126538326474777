import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Document from '../model/document'
import { ReducerAction } from '../utils/reducer-utils'

export interface DocumentReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  documents: List<Document>
  error: Error | null
}

const Params = Record<DocumentReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  documents: List<Document>(),
  error: null,
})

export interface DocumentAction extends ReducerAction {
  companyID?: string
  documents?: Document[]
  document?: Document
  documentID?: string
}

const comparator = (a: Document, b: Document) => {
  return a.name.localeCompare(b.name, 'da-dk')
}

export default (
  state: Record<DocumentReducer> = Params(),
  action: DocumentAction = { type: '' }
): Record<DocumentReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }
  let idx = -1
  switch (action.type) {
    case ActionTypes.DOCUMENT_LOADING:
      return state
        .set('loading', true)
        .set('loaded', false)
        .set('companyID', action.companyID || state.get('companyID'))
    case ActionTypes.DOCUMENT_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('companyID', action.companyID || state.get('companyID'))
        .set('documents', List<Document>(action.documents).sort(comparator))
        .set('error', null)
    case ActionTypes.DOCUMENT_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', action.companyID || state.get('companyID'))
        .set('error', action.error || null)

    case ActionTypes.DOCUMENT_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DOCUMENT_ADDED:
      if (!action.document) {
        return state
      }
      idx = state.get('documents').findIndex((item) => !!action.document && item.id === action.document.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('documents', state.get('documents').set(idx, action.document).sort(comparator))
      }
      return state.set('saving', false).set('documents', state.get('documents').push(action.document).sort(comparator))
    case ActionTypes.DOCUMENT_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.DOCUMENT_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DOCUMENT_UPDATED:
      if (!action.document) {
        return state
      }
      idx = state.get('documents').findIndex((item) => item.id === action.documentID)
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('documents', state.get('documents').set(idx, action.document).sort(comparator))
    case ActionTypes.DOCUMENT_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.DOCUMENT_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DOCUMENT_DELETED:
      idx = state.get('documents').findIndex((item) => item.id === action.documentID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('documents', state.get('documents').delete(idx))
    case ActionTypes.DOCUMENT_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
