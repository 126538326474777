import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import DataIntegration from '../model/dataIntegration'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyDataIntegrationReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  dataIntegration: DataIntegration | undefined
  error: Error | null
}

const Params = Record<CompanyDataIntegrationReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  dataIntegration: undefined,
  error: null,
})

export interface DataIntegrationAction extends ReducerAction {
  companyID?: string
  integration?: DataIntegration
}

export default (
  state: Record<CompanyDataIntegrationReducer> = Params(),
  action: DataIntegrationAction = { type: '' }
): Record<CompanyDataIntegrationReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.DATA_INTEGRATION_LOADING:
      return Params({
        companyID: state.get('companyID'),
        loading: true,
      })
    case ActionTypes.DATA_INTEGRATION_LOADED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        dataIntegration: action.integration, // yes, if it is undefined, we still save it; that indicates we have none
      })
    case ActionTypes.DATA_INTEGRATION_LOAD_FAILED:
      return Params({
        companyID: state.get('companyID'),
        error: action.error,
      })

    case ActionTypes.DATA_INTEGRATION_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DATA_INTEGRATION_UPDATED:
      if (!action.integration) {
        return state
      }
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        dataIntegration: action.integration,
      })
    case ActionTypes.DATA_INTEGRATION_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.DATA_INTEGRATION_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DATA_INTEGRATION_DELETED:
      return Params({
        companyID: state.get('companyID'),
        loaded: true,
        dataIntegration: undefined,
      })
    case ActionTypes.DATA_INTEGRATION_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
