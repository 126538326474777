import { List } from 'immutable'
import React, { ReactElement, useEffect } from 'react'
import { Link } from 'react-router'

import ApplyResult from '../../model/applyResult'
import CostCenter from '../../model/costCenter'
import Employee from '../../model/employee'
import { EmployeeBatchStatus } from '../../model/employeeBatchResult'
import LeaveType from '../../model/leaveType'
import OneTimePay, { OneTimePayType } from '../../model/oneTimePay'
import { BenefitDefinition } from '../../model/remuneration'
import SalaryCycle from '../../model/salaryCycle'
import SalaryType from '../../model/salaryType'
import StagedImportResult from '../../model/stagedImportResult'
import { ContractReducer } from '../../reducers/contracts'
import { paths } from '../../routes'
import { formatShortDate } from '../../utils/date-utils'
import { formatLeaveTypeName, formatOneTimePayType } from '../../utils/format-utils'
import { formatCurrency, formatNumber } from '../../utils/number-utils'
import {
  formatEmployeeBatchMessage,
  formatExcelColumn,
  formatStagedImportMessage,
} from '../../utils/staged-import-utils'
import { translateGroupTitle } from '../../utils/translation-utils'
import Table from '../antd/table'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import UserImage from '../elements/UserImage'
import LoadingOverlay from '../widgets/LoadingOverlay'

function stateToSort(state: EmployeeBatchStatus) {
  switch (state) {
    case 'Failure':
      return 0
    case 'Success':
      return 1
    case 'NoChange':
      return 2
    default:
      return 3
  }
}

type Props = {
  stagedImport?: StagedImportResult
  applyResult?: ApplyResult[]
  employees: List<Employee>
  contracts: ContractReducer
  costCenters: List<CostCenter>
  leaveTypes: List<LeaveType>
  salaryTypes: List<SalaryType>
  salaryCycles: List<SalaryCycle>
  getContracts: () => void
}

export default function StagedImportResultDisplay(props: Props): ReactElement | null {
  const { stagedImport, contracts, employees, getContracts } = props

  useEffect(() => {
    if (stagedImport && stagedImport.companies.length > 0) {
      if (
        !contracts.loading &&
        stagedImport.companies[0].employees.some((modEmp) => {
          const employee = employees.find((employee) => employee.id === modEmp.employeeID)
          return employee && employee.earliestMutableContract && !employee.earliestMutableContract.remuneration
        })
      ) {
        getContracts()
      }
    }
  })

  const getSalaryPeriod = (salaryPeriodID: string) => {
    return props.salaryCycles
      .find((cycle) => cycle.salaryPeriods.some((period) => period.id === salaryPeriodID))
      ?.salaryPeriods.find((period) => period.id === salaryPeriodID)
  }

  type OTPRow = {
    key: string
    oneTimePayType: string
    count: string
    totalAmount: string
  }

  const otpColumns = [
    { title: 'Type', dataIndex: 'oneTimePayType', key: 'oneTimePayType' },
    { title: 'Antal', dataIndex: 'count', key: 'count' },
    { title: 'Total beløb', dataIndex: 'totalAmount', key: 'totalAmount' },
  ]

  const getOTPSummed = (): OTPRow[] => {
    if (!props.stagedImport) {
      return []
    }
    const otpTypes: OneTimePayType[] = []
    type OTPRecord = Partial<Record<OneTimePayType, OneTimePay[]>>
    const otps: OTPRecord = props.stagedImport.companies[0].employees.reduce((otps, employee) => {
      return employee.oneTimePays.reduce((otps: OTPRecord, otp) => {
        otps[otp.type] = [...(otps[otp.type] || []), otp]
        if (!otpTypes.some((t) => t === otp.type)) {
          otpTypes.push(otp.type)
        }
        return otps
      }, otps)
    }, {})
    return otpTypes
      .map((otpType) => {
        const otpList = otps[otpType] || []
        const { count, totalAmount } = otpList.reduce(
          (o, otp) => ({
            count: o.count + 1,
            totalAmount: o.totalAmount + otp.amount,
          }),
          { count: 0, totalAmount: 0 }
        )
        return {
          key: otpType,
          oneTimePayType: formatOneTimePayType(otpType),
          count: formatNumber(count),
          totalAmount: formatCurrency(totalAmount, 2),
        }
      })
      .sort((a, b) => a.oneTimePayType.localeCompare(b.oneTimePayType, 'da-dk'))
  }

  if (
    !stagedImport ||
    (stagedImport.companies.length > 0 &&
      stagedImport.companies[0].employees.some((modEmp) => {
        const employee = props.employees.find((employee) => employee.id === modEmp.employeeID)
        return employee && employee.earliestMutableContract && !employee.earliestMutableContract.remuneration
      }))
  ) {
    return <LoadingOverlay />
  }

  const company = stagedImport.companies[0]
  const applyResult = props.applyResult && props.applyResult[0]
  const hasOneTimePays = company && company.employees.some((employee) => employee.oneTimePays.length > 0)
  return (
    <div className="staged-import-results">
      {stagedImport.errors.length > 0 && (
        <Subcard className="staged-import-errors">
          <Subtitle>Fejl</Subtitle>
          <Row className="staged-import-errors-header">
            <Col span={4}>
              <strong>Række</strong>
            </Col>
            <Col span={4}>
              <strong>Kolonne</strong>
            </Col>
            <Col span={16}>
              <strong>Fejlbesked</strong>
            </Col>
          </Row>
          {stagedImport.errors.map((error) => {
            return (
              <Row key={'import-error-' + error.row} className="staged-import-errors-error">
                <Col span={4}>{error.row}</Col>
                <Col span={4}>{formatExcelColumn(error.field)}</Col>
                <Col span={16}>{formatStagedImportMessage(error.type, error.message)}</Col>
              </Row>
            )
          })}
        </Subcard>
      )}
      {company && company.employees.length > 0 && (
        <Subcard>
          <Subtitle>Medarbejdere</Subtitle>
          {company.employees
            .sort((a, b) => {
              const resultA = applyResult && applyResult.messages.find((message) => message.employeeID === a.employeeID)
              const resultB = applyResult && applyResult.messages.find((message) => message.employeeID === b.employeeID)
              if (!resultA || !resultB || resultA.state === resultB.state) {
                const employeeA = props.employees.find((employee) => employee.id === a.employeeID)
                const employeeB = props.employees.find((employee) => employee.id === b.employeeID)
                return (employeeA?.name || '').localeCompare(employeeB?.name || '')
              }
              const sortA = stateToSort(resultA.state)
              const sortB = stateToSort(resultB.state)
              return sortA - sortB
            })
            .map((employeeData) => {
              const employee = props.employees.find((employee) => employee.id === employeeData.employeeID)
              if (!employee) {
                return null
              }
              const contract = employee.activeContract
              if (!contract) {
                return null
              }
              const hasErrors = employeeData.errors.length > 0
              let hasContractChanges = employeeData.salaryChanges.length > 0 // || employeeData.contract
              const remunerationChanges: { benefits: BenefitDefinition[] } = { benefits: [] }
              if (employeeData.remuneration) {
                employeeData.remuneration.benefits.forEach((benefit) => {
                  const oldBenefit = contract.remuneration?.benefits.find(
                    (oldBenefit) => oldBenefit.type === benefit.type
                  )
                  if (!oldBenefit) {
                    remunerationChanges.benefits.push(benefit)
                    return
                  }
                  if (oldBenefit.amount !== benefit.amount) {
                    remunerationChanges.benefits.push(benefit)
                  }
                })
              }
              hasContractChanges = hasContractChanges || remunerationChanges.benefits.length > 0
              const hasRegistrations =
                employeeData.coarseTimeRegistrations.length > 0 ||
                employeeData.coarseSalaryRegistrations.length > 0 ||
                employeeData.coarseCarAllowances.length > 0 ||
                employeeData.timeRegistrations.length > 0
              const hasOneTimePays = employeeData.oneTimePays.length > 0
              let state: EmployeeBatchStatus = 'None'
              const result: { message: string; details: string[] } = { message: '', details: [] }
              const employeeResult =
                applyResult && applyResult.messages.find((message) => message.employeeID === employee.id)
              if (employeeResult) {
                state = employeeResult.state
                result.message = employeeResult.message
                result.details = employeeResult.details
              }
              const getSalaryTitleRate = (salaryTypeID: string) => {
                const salary = contract.remuneration?.salary.find((salary) => salary.salaryTypeID === salaryTypeID)
                let salaryRate = null
                let salaryTitle = ''
                if (salary) {
                  salaryRate = salary.rate
                  salaryTitle = translateGroupTitle(salary)
                } else {
                  const salaryType = props.salaryTypes.find((type) => type.id === salaryTypeID)
                  if (salaryType && salaryType.title) {
                    salaryTitle = salaryType.title
                  }
                }
                employeeData.salaryChanges.forEach((change) => {
                  if (change.id === salaryTypeID) {
                    salaryRate = change.rate
                  }
                })
                return { salaryTitle, salaryRate }
              }
              return (
                <div
                  className={
                    'staged-import-employee employees' +
                    (state === 'Success' ? ' success' : '') +
                    (state === 'NoChange' ? ' no-change' : '') +
                    (state === 'Failure' ? ' failure' : '')
                  }
                  key={'employee-' + employee.id}
                >
                  <Headline className="staged-import-employee-headline">
                    <UserImage src={employee.profileImageURL} name={employee.name || employee.email} />
                    <div className="staged-import-employee-headline-details">
                      {state === 'Failure' ? (
                        <Link
                          to={
                            '/' +
                            paths.EMPLOYEES +
                            '/' +
                            employee.id +
                            (employee.affiliationType === 'Freelancer' ? '/profile' : '')
                          }
                        >
                          {employee.name || employee.email}
                        </Link>
                      ) : (
                        <span>{employee.name || employee.email}</span>
                      )}
                      {state !== 'None' ? (
                        <div className={'message'}>
                          {formatEmployeeBatchMessage(state, result.message, result.details)}
                        </div>
                      ) : (
                        <small>{contract.position ? contract.position : '-'}</small>
                      )}
                    </div>
                  </Headline>
                  <div className="staged-import-employee-details">
                    {hasErrors && (
                      <div className="staged-import-employee-errors">
                        <span>Der var følgende fejl på medarbejderen ved indlæsning af filen:</span>
                        <Row className="staged-import-employee-errors-header">
                          <Col span={2}>
                            <strong>Række</strong>
                          </Col>
                          <Col span={2}>
                            <strong>Kolonne</strong>
                          </Col>
                          <Col span={16}>
                            <strong>Fejlbesked</strong>
                          </Col>
                        </Row>
                        {employeeData.errors.map((error) => {
                          return (
                            <Row key={'employee-error-' + error.row} className="staged-import-employee-errors-error">
                              <Col span={2}>{error.row}</Col>
                              <Col span={2}>{formatExcelColumn(error.field)}</Col>
                              <Col span={16}>{formatStagedImportMessage(error.type, error.message)}</Col>
                            </Row>
                          )
                        })}
                      </div>
                    )}
                    {hasContractChanges && (
                      <div className="staged-import-employee-contract-changes staged-import-employee-list">
                        <span>Medarbejderen vil få følgende ændringer til deres ansættelse:</span>
                        {employeeData.salaryChanges.map((change) => {
                          let title = translateGroupTitle(change)
                          const salaryType = props.salaryTypes.find(
                            (salaryType) => salaryType.id === change.salaryTypeID
                          )
                          if (salaryType && salaryType.title !== title) {
                            title = salaryType.title + ' (' + title + ')'
                          }
                          return (
                            <div
                              key={'employee-salaryChange-' + (salaryType?.name || change.salaryTypeID)}
                              className="staged-import-employee-contract-changes-change"
                            >
                              {title}: Sats sat til <strong>{formatCurrency(change.rate, 2)}</strong>
                              {change.quantity && (
                                <span>
                                  , og antal sat til <strong>{change.quantity}</strong>
                                </span>
                              )}
                            </div>
                          )
                        })}
                        {remunerationChanges.benefits.map((benefit) => {
                          const title = benefit.title
                          return (
                            <div
                              key={'employee-benefitChange-' + benefit.type}
                              className="staged-import-employee-contract-changes-change"
                            >
                              {title}: Sats sat til <strong>{formatCurrency(benefit.amount, 2)}</strong>
                            </div>
                          )
                        })}
                      </div>
                    )}
                    {hasRegistrations && (
                      <div className="staged-import-employee-registrations staged-import-employee-list">
                        <span>Medarbejderen vil få følgende registreringer:</span>
                        {employeeData.coarseTimeRegistrations.map((reg, i) => {
                          const { salaryTitle, salaryRate } = getSalaryTitleRate(reg.salaryTypeID)
                          const salaryPeriod = getSalaryPeriod(reg.salaryPeriodID)
                          return (
                            <div
                              key={'employee-timereg-' + employee.id + '-' + i}
                              className="staged-import-employee-registrations-time"
                            >
                              {salaryTitle}: <strong>{formatNumber(reg.hours, 2)}</strong> timer
                              {reg.days > 0 && (
                                <span>
                                  , <strong>{formatNumber(reg.days, 0)}</strong> dage
                                </span>
                              )}
                              {salaryRate !== null && <span> til {formatCurrency(salaryRate, 2)} i timen</span>}
                              {salaryPeriod && (
                                <span>
                                  {' '}
                                  for perioden {formatShortDate(salaryPeriod.start)} -{' '}
                                  {formatShortDate(salaryPeriod.end)}
                                </span>
                              )}
                            </div>
                          )
                        })}
                        {employeeData.coarseSalaryRegistrations.map((reg, i) => {
                          const { salaryTitle, salaryRate } = getSalaryTitleRate(reg.salaryTypeID)
                          const salaryPeriod = getSalaryPeriod(reg.salaryPeriodID)
                          return (
                            <div
                              key={'employee-salaryreg-' + employee.id + '-' + i}
                              className="staged-import-employee-registrations-salary"
                            >
                              {salaryTitle}: <strong>{reg.quantity}</strong> stk
                              {salaryRate !== null && <span> à {formatCurrency(salaryRate, 2)}</span>}
                              {salaryPeriod && (
                                <span>
                                  {' '}
                                  for perioden {formatShortDate(salaryPeriod.start)} -{' '}
                                  {formatShortDate(salaryPeriod.end)}
                                </span>
                              )}
                            </div>
                          )
                        })}
                        {employeeData.coarseCarAllowances.map((reg, i) => {
                          const salaryPeriod = getSalaryPeriod(reg.salaryPeriodID)
                          return (
                            <div
                              key={'employee-carallow-' + employee.id + '-' + i}
                              className="staged-import-employee-registrations-car-allowance"
                            >
                              <strong>{formatNumber(reg.kilometers, 2)}</strong> km
                              {salaryPeriod && (
                                <span>
                                  {' '}
                                  for perioden {formatShortDate(salaryPeriod.start)} -{' '}
                                  {formatShortDate(salaryPeriod.end)}
                                </span>
                              )}
                            </div>
                          )
                        })}
                        {employeeData.timeRegistrations.map((reg, i) => {
                          switch (reg.class) {
                            case 'Leave': {
                              const leaveType = props.leaveTypes.find((leaveType) => leaveType.id === reg.leaveTypeID)
                              if (!leaveType) {
                                return null
                              }
                              return (
                                <div
                                  key={'employee-leavereg-' + employee.id + '-' + i}
                                  className="staged-import-employee-registrations-leave"
                                >
                                  <strong>{formatNumber(reg.days, 2)}</strong>{' '}
                                  {formatLeaveTypeName(leaveType.name, reg.days !== 1)}, {formatShortDate(reg.date)}
                                </div>
                              )
                            }
                            case 'Hours': {
                              if (!reg.salaryTypeID) {
                                return null
                              }
                              const { salaryTitle, salaryRate } = getSalaryTitleRate(reg.salaryTypeID)
                              return (
                                <div
                                  key={'employee-timereg-' + employee.id + '-' + i}
                                  className="staged-import-employee-registrations-time"
                                >
                                  {salaryTitle}: <strong>{formatNumber(reg.hours, 2)}</strong> timer den{' '}
                                  <strong>{formatShortDate(reg.date)}</strong>{' '}
                                  {salaryRate !== null && <span> til {formatCurrency(salaryRate, 2)} i timen</span>}
                                </div>
                              )
                            }
                          }
                        })}
                      </div>
                    )}
                    {hasOneTimePays && (
                      <div className="staged-import-employee-otps staged-import-employee-list">
                        <span>Medarbejderen vil få følgende engangsløntillæg:</span>
                        {employeeData.oneTimePays.map((otp, i) => {
                          let costCenter = null
                          if (otp.costCenterID) {
                            costCenter = props.costCenters.find((costCenter) => costCenter.id === otp.costCenterID)
                          }
                          const needBreak = !!costCenter || !!otp.accountingText
                          return (
                            <div
                              key={'employee-timereg-' + employee.id + '-' + i}
                              className="staged-import-employee-otps-otp"
                            >
                              {formatOneTimePayType(otp.type)}
                              {otp.title ? ' ("' + otp.title + '")' : ''}:{' '}
                              <strong>{formatCurrency(otp.amount, 2)}</strong>
                              {needBreak && <br />}
                              {costCenter ? 'Omkostningssted: ' + costCenter.name : ''}
                              {otp.accountingText ? 'Bogføringslinjetekst: ' + otp.accountingText : ''}
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
        </Subcard>
      )}
      {hasOneTimePays && (
        <Subcard>
          <Subtitle>Betalingssummering</Subtitle>
          <Table
            columns={otpColumns}
            dataSource={getOTPSummed()}
            pagination={false}
            className="staged-import-otp-summed-table"
          />
        </Subcard>
      )}
    </div>
  )
}
