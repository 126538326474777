import PropTypes from 'prop-types'
import { Item } from 'rc-menu'
import React from 'react'

import Tooltip from '../../elements/tooltip'

class MenuItem extends React.Component {
  render() {
    const { inlineCollapsed } = this.context
    const props = this.props
    return (
      <Tooltip
        title={inlineCollapsed && props.level === 1 ? props.children : ''}
        placement="right"
        overlayClassName={`${props.rootPrefixCls}-inline-collapsed-tooltip`}
      >
        <Item {...props} />
      </Tooltip>
    )
  }
}

MenuItem.contextTypes = {
  inlineCollapsed: PropTypes.bool,
}
MenuItem.isMenuItem = 1

export default MenuItem
