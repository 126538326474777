import React, { CSSProperties, ReactElement } from 'react'

// NEED_NO_TRANSLATION
type GeneralProps = {
  className?: string
  style?: CSSProperties
  children: React.ReactNode
}

type TableProps = GeneralProps & {
  columns: number
}

function addClassName(className: string, extraClassName?: string): string {
  if (!extraClassName) {
    return className
  }
  return className + ' ' + extraClassName
}

export function Table(props: TableProps): ReactElement {
  return (
    <div className={addClassName('ant-table', props.className)} style={props.style}>
      <div className="ant-table-content">
        <div className="ant-table-body">
          <table className="">
            <colgroup>
              {[...Array(props.columns).keys()].map((i) => (
                <col key={i} />
              ))}
            </colgroup>
            {props.children}
          </table>
        </div>
      </div>
    </div>
  )
}

export function Header(props: GeneralProps): ReactElement {
  return (
    <thead className={addClassName('ant-table-thead', props.className)} style={props.style}>
      <tr>{props.children}</tr>
    </thead>
  )
}

type CellProps = GeneralProps & {
  span?: number
  spanRow?: number
}

export function TH(props: CellProps): ReactElement {
  return (
    <th className={addClassName('', props.className)} style={props.style} colSpan={props.span} rowSpan={props.spanRow}>
      <span>{props.children}</span>
    </th>
  )
}

export function Body(props: GeneralProps): ReactElement {
  return (
    <tbody className={addClassName('ant-table-tbody', props.className)} style={props.style}>
      {props.children}
    </tbody>
  )
}

export function Row(props: GeneralProps): ReactElement {
  return (
    <tr className={addClassName('ant-table-row ant-table-row-level-0', props.className)} style={props.style}>
      {props.children}
    </tr>
  )
}

export function TD(props: CellProps): ReactElement {
  return (
    <td className={addClassName('', props.className)} style={props.style} colSpan={props.span} rowSpan={props.spanRow}>
      {props.children}
    </td>
  )
}
