import React, { ReactElement, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import { EmployeeImportFields, getDataIntegrationFields, setDataIntegrationFields } from '../../api/data-integration'
import Company from '../../model/company'
import { formatError, isRequestError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Title from '../elements/Title'
import LoadingOverlay from '../widgets/LoadingOverlay'
import CustomFieldMappingForm, { CustomFieldsResult } from './CustomFieldMappingForm'

type Props = {
  displayName: string
  company: Company

  addAlert: addAlertSignature
  getCompanyDataIntegration: () => void
}

export default function CustomFieldMapping(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [state, setState] = useState<EmployeeImportFields>({
    rules: [],
    mapping: [],
    externalFields: [],
  })

  const { company } = props

  useEffectOnce(() => {
    getDataIntegrationFields(company.id)
      .then((res) => {
        setState(res.data)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        if (isRequestError(e)) {
          setError(e)
        }
      })
  })

  const handleSubmit = (values: CustomFieldsResult) => {
    setSaving(true)
    setDataIntegrationFields(props.company.id, values.fields)
      .then((res) => {
        setState((prev) => ({ ...prev, mapping: res.data }))
        setSaving(false)
        props.addAlert('success', t('data_integration.custom_field_mapping.alert.success'), { timeout: 5 })
        // reload the data integration
        props.getCompanyDataIntegration()
      })
      .catch((e) => {
        setSaving(false)
        if (isRequestError(e)) {
          setError(e)
        }
      })
  }

  if (loading || saving) {
    return <LoadingOverlay />
  }

  if (error) {
    return <Alert message={formatError(error)} type="error" showIcon />
  }

  return (
    <div>
      <Title>{t('data_integration.custom_field_mapping.title', { title: props.displayName })}</Title>
      <p>{t('data_integration.custom_field_mapping.intro', { title: props.displayName })}</p>
      <CustomFieldMappingForm displayName={props.displayName} state={state} onSubmit={handleSubmit} />
    </div>
  )
}
