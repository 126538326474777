import React, { ReactElement, useEffect } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyPaymentIntegrationReducer } from '../../reducers/companyPaymentIntegrations'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import LoadingOverlay from '../widgets/LoadingOverlay'
import TransferNemKontoForm, { TransferNemKontoFields } from './TransferNemKontoForm'

type Props = {
  companies: CompanyReducer
  company: Company
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer

  addAlert: addAlertSignature
  getCompanyPaymentIntegrations: () => void
  updateCompany: (company: Company) => void
  toggleNemKontoAllEmployees: (company: Company, enable: boolean) => void
}

export default function TransferNemKontoTab(props: Props): ReactElement | null {
  const { companyPaymentIntegrations, getCompanyPaymentIntegrations } = props
  useEffect(() => {
    if (!companyPaymentIntegrations.loading && !companyPaymentIntegrations.loaded) {
      getCompanyPaymentIntegrations()
    }
  })

  const getIntegration = () => {
    return props.companyPaymentIntegrations.companyPaymentIntegrations.find(
      (v) => v.paymentIntegrationType === 'NETS' && v.status !== 'Expired'
    )
  }

  const handleSubmit = (values: TransferNemKontoFields) => {
    const company = { ...props.company, enableNemKontoTransfers: values.enableNemKontoTransfers }
    props.updateCompany(company)
  }

  const handleEnableAllEmployees = () => {
    if (window.confirm(t('common.are_you_sure'))) {
      props.toggleNemKontoAllEmployees(props.company, true)
    }
  }

  if (!props.companyPaymentIntegrations.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }
  const integration = getIntegration()!
  const hasNemKonto = props.company.enableNemKontoTransfers

  return (
    <Card>
      {hasNemKonto && (
        <TitleMenu>
          <Button onClick={handleEnableAllEmployees} className="gtm-refresh-employee-nemkonto-switch">
            {t('transfer_easy_account.header.enable_all_employees')}
          </Button>
        </TitleMenu>
      )}
      <Title>{t('transfer_easy_account.title')}</Title>

      <Subtitle>{t('transfer_easy_account.subtitle')}</Subtitle>
      <p>{t('transfer_easy_account.intro.line_1')}</p>
      <p>{t('transfer_easy_account.intro.line_2')}</p>
      <p>
        {t('transfer_easy_account.intro.line_3')}
        <br />
        <a
          href="https://www.mastercardpaymentservices.com/denmark/kundeservice/Betalingsformidling#os-tilmeld"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.mastercardpaymentservices.com/denmark/kundeservice/Betalingsformidling#os-tilmeld
        </a>
      </p>
      <TransferNemKontoForm
        companies={props.companies}
        company={props.company}
        integration={integration}
        addAlert={props.addAlert}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
