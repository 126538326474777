import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import PaymentConfiguration, { TransferSetting } from '../../model/paymentConfiguration'
import PaymentIntegration from '../../model/paymentIntegration'
import { CompanyPaymentIntegrationReducer } from '../../reducers/companyPaymentIntegrations'
import { PaymentConfigurationReducer } from '../../reducers/paymentConfigurations'
import { UserReducer } from '../../reducers/user'
import { paths } from '../../routes'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import LoadingOverlay from '../widgets/LoadingOverlay'
import TransferSettingsForm, { Fields } from './TransferSettingsForm'

interface Props {
  company: Company
  paymentConfigurations: PaymentConfigurationReducer
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer
  companyFeatures: List<CompanyFeature>
  user: UserReducer

  addAlert: addAlertSignature
  getPaymentConfigurations: () => void
  getCompanyPaymentIntegrations: () => void
  updatePaymentConfiguration: (settings: TransferSetting[]) => void
}

export default function TransferSettingsTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const { paymentConfigurations, getPaymentConfigurations, companyPaymentIntegrations, getCompanyPaymentIntegrations } =
    props
  useEffect(() => {
    if (!paymentConfigurations.loading && !paymentConfigurations.loaded) {
      getPaymentConfigurations()
    }
    if (!companyPaymentIntegrations.loading && !companyPaymentIntegrations.loaded) {
      getCompanyPaymentIntegrations()
    }
  }, [paymentConfigurations, getPaymentConfigurations, companyPaymentIntegrations, getCompanyPaymentIntegrations])

  useEffect(() => {
    regularComponentDidUpdate(paymentConfigurations.error, error, setError)
  }, [paymentConfigurations, error])

  const getIntegration = (): PaymentIntegration | undefined => {
    return props.companyPaymentIntegrations.companyPaymentIntegrations
      .filter((v) => v.paymentIntegrationType === 'NETS' && v.status !== 'Expired')
      .sort((a, b) => a.startDate.localeCompare(b.startDate))
      .last()
  }
  const getPaymentConfiguration = (): PaymentConfiguration | undefined => {
    // prefer NETS to Stripe integration, but try both
    const integrations = props.companyPaymentIntegrations.companyPaymentIntegrations.filter(
      (v) => (v.paymentIntegrationType === 'NETS' || v.paymentIntegrationType === 'Stripe') && v.status !== 'Expired'
    )
    const integration = integrations
      .sort((a, b) => {
        if (a.paymentIntegrationType === b.paymentIntegrationType) {
          return a.startDate.localeCompare(b.startDate)
        }
        // this should ensure NETS gets sorted last, which in this case is what we want
        return a.paymentIntegrationType === 'NETS' ? 1 : -1
      })
      .last()
    if (!integration) {
      return undefined
    }
    return props.paymentConfigurations.paymentConfigurations.find(
      (v) => v.integrationType === integration.paymentIntegrationType
    )
  }

  const handleSubmit = (values: Fields) => {
    props.updatePaymentConfiguration(values.transferSettings)
  }

  if (!props.paymentConfigurations.loaded && !props.companyPaymentIntegrations.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }
  const integration = getIntegration()
  const paymentConfiguration = getPaymentConfiguration()

  return (
    <Card>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <TitleMenu>
        <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/transfers'}>
          <Button>{t('transfer_settings.header.back')}</Button>
        </Link>
        {paymentConfiguration && (
          <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/transfer-bank-account'}>
            <Button className={'gtm-change-transfer-integration' + (!integration ? ' ant-btn-secondary' : '')}>
              {integration
                ? t('transfer_settings.transfer_bank_account.change')
                : t('transfer_settings.transfer_bank_account.create')}
            </Button>
          </Link>
        )}
      </TitleMenu>
      <Title>{t('transfer_settings.title')}</Title>

      <Row>
        <Col span={8}>
          {t('transfer_settings.transfers_handled.title')}
          <br />
          <strong>
            {integration
              ? t('transfer_settings.transfers_handled.automatic')
              : t('transfer_settings.transfers_handled.manual')}
          </strong>
        </Col>
        {integration && (
          <Col span={8}>
            {t('transfer_settings.transfers_via.title')}
            <br />
            <strong>{t('transfer_settings.transfers_via.payment_service')}</strong>
          </Col>
        )}
        {integration && (
          <Col span={8}>
            {t('transfer_settings.transfer_from.title')}
            <br />
            <strong>
              {integration.registrationNumber} {integration.accountNumber}
            </strong>
          </Col>
        )}
      </Row>

      {paymentConfiguration && (
        <TransferSettingsForm
          company={props.company}
          transferSettings={paymentConfiguration.transferSettings}
          companyPaymentIntegrations={props.companyPaymentIntegrations.companyPaymentIntegrations}
          paymentConfigurations={props.paymentConfigurations}
          companyFeatures={props.companyFeatures}
          addAlert={props.addAlert}
          onSubmit={handleSubmit}
        />
      )}

      {!paymentConfiguration && (
        <div>
          <Row>&nbsp;</Row>
          <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/transfer-bank-account'}>
            <Button size="large" type="secondary">
              Opret Overførselsservice-aftale
            </Button>
          </Link>
        </div>
      )}
    </Card>
  )
}
