import React, { ReactElement } from 'react'

import { addAlert, addAlertSignature } from '../actions/alerts'
import { resetPassword } from '../actions/user'
import ResetPasswordComponent from '../components/password-reset/ResetPassword'
import { UserReducer } from '../reducers/user'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'

type Reducers = {
  user: UserReducer
}

type Actions = {
  addAlert: addAlertSignature
  resetPassword: (token: string, password: string) => Promise<boolean | void>
}

function ResetPassword(props: Reducers & Actions & RouteProps): ReactElement | null {
  return (
    <ResetPasswordComponent
      token={splatString(props.params.splat)}
      user={props.user}
      addAlert={props.addAlert}
      resetPassword={props.resetPassword}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    user: state.user,
  }),
  {
    addAlert: addAlert,
    resetPassword: resetPassword,
  }
)(ResetPassword)
