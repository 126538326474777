import React, { CSSProperties, ReactElement } from 'react'

import addGreyIcon from '../../images/icon-add-grey-20x20.png'
import addOrangeIcon from '../../images/icon-add-orange-20x20.png'
import archiveGreyIcon from '../../images/icon-archive-grey-20x20.png'
import archiveLightGreyIcon from '../../images/icon-archive-lightgrey-20x20.png'
import arrowDownLightGreyIcon from '../../images/icon-arrow-down-lightgrey-24x24.png'
import arrowDownWhiteIcon from '../../images/icon-arrow-down-white-24x24.png'
import arrowLeftWhiteIcon from '../../images/icon-arrow-left-white-20x20.png'
import arrowLongRightBlackIcon from '../../images/icon-arrow-long-right-black-20x20.png'
import arrowLongRightBlueIcon from '../../images/icon-arrow-long-right-blue-20x20.png'
import arrowLongRightBluishIcon from '../../images/icon-arrow-long-right-bluish-20x20.png'
import arrowRightWhiteIcon from '../../images/icon-arrow-right-white-20x20.png'
import arrowUpLightGreyIcon from '../../images/icon-arrow-up-lightgrey-12x12.png'
import bagGreyIcon from '../../images/icon-bag-grey-20x20.png'
import bellIcon from '../../images/icon-bell-20x20.png'
import calculatorLightGreyIcon from '../../images/icon-calculator-lightgrey-20x20.png'
import checkIcon from '../../images/icon-check-circle-green-20x20.png'
import companyIcon from '../../images/icon-company-20x20.png'
import companyAddIcon from '../../images/icon-company-add-20x20.png'
import companyGreyIcon from '../../images/icon-company-grey-20x20.png'
import crossGreyIcon from '../../images/icon-cross-grey-20x20.png'
import crossOrangeIcon from '../../images/icon-cross-orange-20x20.png'
import dashboardIcon from '../../images/icon-dashboard-20x20.png'
import downloadBluishIcon from '../../images/icon-download-bluish-20x20.png'
import downloadGreyIcon from '../../images/icon-download-grey-20x20.png'
import downloadLightGreyIcon from '../../images/icon-download-lightgrey-20x20.png'
import downloadWhiteIcon from '../../images/icon-download-white-20x20.png'
import editGreyIcon from '../../images/icon-edit-grey-20x20.png'
import editLightgreyIcon from '../../images/icon-edit-lightgrey-20x20.png'
import fileGreyIcon from '../../images/icon-file-grey-20x20.png'
import fileLightgreyIcon from '../../images/icon-file-lightgrey-20x20.png'
import fileWhiteIcon from '../../images/icon-file-white-20x20.png'
import gearIcon from '../../images/icon-gear-20x20.png'
import infoGreyIcon from '../../images/icon-info-grey-20x20.png'
import keyIcon from '../../images/icon-key-20x20.png'
import payIcon from '../../images/icon-pay-20x20.png'
import phoneLightGreyIcon from '../../images/icon-phone-lightgrey-30x30.png'
import refreshBlueIcon from '../../images/icon-refresh-blue-20x20.png'
import refreshBluishIcon from '../../images/icon-refresh-bluish-20x20.png'
import refreshGreyIcon from '../../images/icon-refresh-grey-20x20.png'
import searchGreyIcon from '../../images/icon-search-grey-20x20.png'
import searchWhiteIcon from '../../images/icon-search-white-20x20.png'
import signedLightGreyIcon from '../../images/icon-signed-lightgrey-20x20.png'
import speechbubblesWhiteIcon from '../../images/icon-speechbubbles-white-20x20.png'
import stopwatchGreyIcon from '../../images/icon-stopwatch-grey-20x20.png'
import teamIcon from '../../images/icon-team-20x20.png'
import teamGreyIcon from '../../images/icon-team-grey-20x20.png'
import uploadIcon from '../../images/icon-upload-white-20x20.png'
import userIcon from '../../images/icon-user-20x20.png'
import userAddIcon from '../../images/icon-user-add-20x20.png'
import userAddBlackIcon from '../../images/icon-user-add-black-20x20.png'
import userDeleteGreyIcon from '../../images/icon-user-delete-grey-20x20.png'
import userGreyIcon from '../../images/icon-user-grey-20x20.png'
import watchIcon from '../../images/icon-watch-20x20.png'
import wrenchLightgreyIcon from '../../images/icon-wrench-lightgrey-20x20.png'

import './Elements.css'

// NEED_NO_TRANSLATION

type Props = {
  type:
    | 'archive'
    | 'arrow-right'
    | 'arrow-left'
    | 'arrow-long-right'
    | 'arrow-down'
    | 'arrow-up'
    | 'add'
    | 'bag'
    | 'bell'
    | 'calculator'
    | 'check'
    | 'company'
    | 'cross'
    | 'company-add'
    | 'dashboard'
    | 'download'
    | 'edit'
    | 'file'
    | 'gear'
    | 'info'
    | 'key'
    | 'pay'
    | 'refresh'
    | 'search'
    | 'speechbubbles'
    | 'stopwatch'
    | 'signed'
    | 'team'
    | 'upload'
    | 'user'
    | 'user-add'
    | 'user-delete'
    | 'watch'
    | 'wrench'
    | 'phone'
  color?: 'blue' | 'grey' | 'lightgrey' | 'bluish' | 'black' | 'orange' | 'white' | 'red'
  style?: CSSProperties
  className?: string
}

export default function Icon(props: Props): ReactElement {
  let type = ''
  switch (props.type) {
    case 'archive':
      switch (props.color) {
        case 'lightgrey':
          type = archiveLightGreyIcon
          break
        default:
          type = archiveGreyIcon
          break
      }
      break
    case 'arrow-right':
      type = arrowRightWhiteIcon
      break
    case 'arrow-left':
      type = arrowLeftWhiteIcon
      break
    case 'arrow-long-right':
      switch (props.color) {
        case 'blue':
          type = arrowLongRightBlueIcon
          break
        case 'bluish':
          type = arrowLongRightBluishIcon
          break
        default:
          type = arrowLongRightBlackIcon
          break
      }
      break
    case 'arrow-down':
      switch (props.color) {
        case 'white':
          type = arrowDownWhiteIcon
          break
        default:
          type = arrowDownLightGreyIcon
          break
      }
      break
    case 'arrow-up':
      switch (props.color) {
        default:
          type = arrowUpLightGreyIcon
          break
      }
      break
    case 'add':
      switch (props.color) {
        case 'grey':
          type = addGreyIcon
          break
        default:
          type = addOrangeIcon
          break
      }
      break
    case 'bag':
      type = bagGreyIcon
      break
    case 'bell':
      type = bellIcon
      break
    case 'calculator':
      type = calculatorLightGreyIcon
      break
    case 'check':
      type = checkIcon
      break
    case 'company':
      switch (props.color) {
        case 'grey':
          type = companyGreyIcon
          break
        default:
          type = companyIcon
          break
      }
      break
    case 'cross':
      switch (props.color) {
        case 'grey':
          type = crossGreyIcon
          break
        default:
          type = crossOrangeIcon
          break
      }
      break
    case 'company-add':
      type = companyAddIcon
      break
    case 'dashboard':
      type = dashboardIcon
      break
    case 'download':
      switch (props.color) {
        case 'lightgrey':
          type = downloadLightGreyIcon
          break
        case 'grey':
          type = downloadGreyIcon
          break
        case 'bluish':
          type = downloadBluishIcon
          break
        default:
          type = downloadWhiteIcon
          break
      }
      break
    case 'edit':
      switch (props.color) {
        case 'lightgrey':
          type = editLightgreyIcon
          break
        default:
          type = editGreyIcon
          break
      }
      break
    case 'file':
      switch (props.color) {
        case 'lightgrey':
          type = fileLightgreyIcon
          break
        case 'white':
          type = fileWhiteIcon
          break
        default:
          type = fileGreyIcon
          break
      }
      break
    case 'gear':
      type = gearIcon
      break
    case 'info':
      type = infoGreyIcon
      break
    case 'key':
      type = keyIcon
      break
    case 'pay':
      type = payIcon
      break
    case 'refresh':
      switch (props.color) {
        case 'grey':
          type = refreshGreyIcon
          break
        case 'bluish':
          type = refreshBluishIcon
          break
        default:
          type = refreshBlueIcon
          break
      }
      break
    case 'search':
      switch (props.color) {
        case 'grey':
          type = searchGreyIcon
          break
        default:
          type = searchWhiteIcon
          break
      }
      break
    case 'speechbubbles':
      type = speechbubblesWhiteIcon
      break
    case 'stopwatch':
      type = stopwatchGreyIcon
      break
    case 'signed':
      type = signedLightGreyIcon
      break
    case 'team':
      switch (props.color) {
        case 'grey':
          type = teamGreyIcon
          break
        default:
          type = teamIcon
          break
      }
      break
    case 'upload':
      type = uploadIcon
      break
    case 'user':
      switch (props.color) {
        case 'grey':
          type = userGreyIcon
          break
        default:
          type = userIcon
          break
      }
      break
    case 'user-add':
      switch (props.color) {
        case 'black':
          type = userAddBlackIcon
          break
        default:
          type = userAddIcon
          break
      }
      break
    case 'user-delete':
      type = userDeleteGreyIcon
      break
    case 'watch':
      type = watchIcon
      break
    case 'wrench':
      type = wrenchLightgreyIcon
      break
    case 'phone':
      type = phoneLightGreyIcon
      break
    default:
      break
  }
  return (
    <i
      className={'icon' + (props.className ? ` ${props.className}` : '')}
      style={{ ...props.style, backgroundImage: 'url(' + type + ')' }}
    />
  )
}
