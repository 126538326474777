import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import TaxCardRequest from '../model/taxCardRequest'
import { ReducerAction } from '../utils/reducer-utils'

export interface TaxCardRequestReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  taxCardRequests: List<TaxCardRequest>
  error: Error | null
}

const Params = Record<TaxCardRequestReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  taxCardRequests: List<TaxCardRequest>(),
  error: null,
})

export interface TaxCardRequestAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  taxCardRequests?: TaxCardRequest[]
  taxCardRequest?: TaxCardRequest
}

export default (
  state: Record<TaxCardRequestReducer> = Params(),
  action: TaxCardRequestAction = { type: '' }
): Record<TaxCardRequestReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.TAX_CARD_REQUEST_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID || state.get('employeeID'),
        loading: true,
      })
    case ActionTypes.TAX_CARD_REQUEST_LOADED:
    case ActionTypes.TAX_CARD_REQUEST_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID || state.get('employeeID'),
        loaded: true,
        loading: action.type == ActionTypes.TAX_CARD_REQUEST_LOADED_PARTIAL,
        taxCardRequests: state.get('taxCardRequests').concat(List(action.taxCardRequests)),
      })
    case ActionTypes.TAX_CARD_REQUEST_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID || state.get('employeeID'),
        error: action.error || null,
      })

    case ActionTypes.TAX_CARD_REQUEST_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.TAX_CARD_REQUEST_ADDED:
      if (!action.taxCardRequest) {
        return state
      }
      idx = state
        .get('taxCardRequests')
        .findIndex((item) => (item && action.taxCardRequest ? item.id === action.taxCardRequest.id : false))
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('taxCardRequests', state.get('taxCardRequests').set(idx, action.taxCardRequest))
      }
      return state.set('saving', false).set('taxCardRequests', state.get('taxCardRequests').push(action.taxCardRequest))
    case ActionTypes.TAX_CARD_REQUEST_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
