import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { updateActiveCompany } from '../actions/companies'
import {
  addCompanyGroupUserInvite,
  deleteCompanyGroupUserInvite,
  getCompanyGroupUserInvites,
} from '../actions/company-group-users'
import {
  addCompanyToCompanyGroup,
  getCompanyGroups,
  removeCompanyFromCompanyGroup,
  removeUserFromCompanyGroup,
  selectActiveCompanyGroup,
  setCompanyGroupCompanySettings,
  updateCompanyGroup,
  updateCompanyGroupUser,
} from '../actions/company-groups'
import { getPricingPackages } from '../actions/pricing-packages'
import CompaniesComponent from '../components/companies/Companies'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { CompanyGroupMutableFields, CompanyGroupUser } from '../model/companyGroup'
import CompanySetting from '../model/companySetting'
import { UserPermission } from '../model/companyUser'
import UserInvite from '../model/userInvite'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { CompanyGroupReducer } from '../reducers/companyGroups'
import { PricingPackageReducer } from '../reducers/pricingPackages'
import { UserReducer } from '../reducers/user'
import { UserInviteCompanyGroupReducer } from '../reducers/userInviteCompanyGroups'
import CompanyGroupUserType from '../types/company-group-user-type'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  companyGroups: CompanyGroupReducer
  user: UserReducer
  pricingPackages: PricingPackageReducer
  userInviteCompanyGroups: UserInviteCompanyGroupReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getCompanyGroups: () => void
  addCompanyToCompanyGroup: (companyGroupID: string, companyID: string) => void
  removeCompanyFromCompanyGroup: (companyGroupID: string, companyID: string) => void
  removeUserFromCompanyGroup: (companyGroupID: string, userID: string) => void
  updateCompanyGroupUser: (
    companyGroupID: string,
    userID: string,
    companyGroupUser: CompanyGroupUser
  ) => Promise<CompanyGroupUser | void>
  updateCompanyGroup: (companyGroupID: string, companyGroup: CompanyGroupMutableFields) => void
  setCompanyGroupCompanySettings: (
    companyGroupID: string,
    settingsEnable: CompanySetting[],
    settingDisable: CompanySetting[],
    retroactive: boolean
  ) => void
  updateActiveCompany: (id: string) => void
  addCompanyGroupUserInvite: (
    companyGroupID: string,
    email: string,
    groupUserType: CompanyGroupUserType,
    permissions: UserPermission[]
  ) => Promise<UserInvite | void>
  selectActiveCompanyGroup: (companyGroupID: string) => void
  getCompanyGroupUserInvites: (companyGroupID: string) => void
  getPricingPackages: (includePricingPackageID: string[]) => void
  deleteCompanyGroupUserInvite: (companyGroupID: string, email: string) => void
}

function Companies(props: Reducers & Actions & RouteProps): ReactElement | null {
  const resolveSplat = () => {
    const params = props.params
    if (!params || !params.splat) {
      return { companyGroupID: undefined, section: 'companies' }
    }
    const parts = splatString(params.splat).split('/')
    const companyGroupID = parts[0]
    let section = ''
    if (parts.length > 1) {
      section = parts[1]
    }
    section = section || 'companies'
    return { companyGroupID, section }
  }

  const { companyGroups, getCompanyGroups } = props

  useEffect(() => {
    if (!companyGroups.loading && !companyGroups.loaded) {
      getCompanyGroups()
    }
  })

  const { selectActiveCompanyGroup } = props

  useEffect(() => {
    const { companyGroupID } = resolveSplat()
    if (companyGroupID && companyGroups.companyGroup && companyGroups.companyGroup.id !== companyGroupID) {
      selectActiveCompanyGroup(companyGroupID)
    }
  })

  const { section } = resolveSplat()

  if (!props.companyGroups.loaded || props.companyGroups.saving) {
    return <LoadingOverlay />
  }

  let companyGroupUser = undefined
  if (props.companyGroups.companyGroup) {
    companyGroupUser = props.companyGroups.companyGroup.users.find((user) => user.userID === props.user.id)
  }

  return (
    <CompaniesComponent
      section={section}
      alerts={props.alerts}
      companies={props.companies}
      companyGroups={props.companyGroups}
      companyGroupUser={companyGroupUser}
      pricingPackages={props.pricingPackages}
      userInviteCompanyGroups={props.userInviteCompanyGroups}
      user={props.user}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      updateActiveCompany={props.updateActiveCompany}
      addCompanyToCompanyGroup={props.addCompanyToCompanyGroup}
      removeCompanyFromCompanyGroup={props.removeCompanyFromCompanyGroup}
      removeUserFromCompanyGroup={props.removeUserFromCompanyGroup}
      updateCompanyGroupUser={props.updateCompanyGroupUser}
      updateCompanyGroup={props.updateCompanyGroup}
      setCompanyGroupCompanySettings={props.setCompanyGroupCompanySettings}
      getPricingPackages={props.getPricingPackages}
      getCompanyGroupUserInvites={props.getCompanyGroupUserInvites}
      addCompanyGroupUserInvite={props.addCompanyGroupUserInvite}
      deleteCompanyGroupUserInvite={props.deleteCompanyGroupUserInvite}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    companyGroups: state.companyGroups,
    pricingPackages: state.pricingPackages,
    user: state.user,
    userInviteCompanyGroups: state.userInviteCompanyGroups,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getCompanyGroups: getCompanyGroups,
    addCompanyToCompanyGroup: addCompanyToCompanyGroup,
    removeCompanyFromCompanyGroup: removeCompanyFromCompanyGroup,
    removeUserFromCompanyGroup: removeUserFromCompanyGroup,
    updateCompanyGroupUser: updateCompanyGroupUser,
    updateCompanyGroup: updateCompanyGroup,
    setCompanyGroupCompanySettings: setCompanyGroupCompanySettings,
    getPricingPackages: getPricingPackages,
    updateActiveCompany: updateActiveCompany,
    selectActiveCompanyGroup: selectActiveCompanyGroup,
    addCompanyGroupUserInvite: addCompanyGroupUserInvite,
    getCompanyGroupUserInvites: getCompanyGroupUserInvites,
    deleteCompanyGroupUserInvite: deleteCompanyGroupUserInvite,
  }
)(Companies)
