import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import { UserCompanyReducer } from '../../reducers/userCompanies'
import { paths } from '../../routes'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import UserNotificationTabForm, { ResultFields } from './UserNotificationTabForm'

type Props = {
  company: Company
  userCompanies: UserCompanyReducer

  addAlert: addAlertSignature
  updateUserCompany: (userCompany: CompanyUser) => void
}

export default function UserNotificationTab(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { company, userCompanies, addAlert } = props
  const previousUserCompanies = usePrevious(userCompanies)

  useEffect(() => {
    if (previousUserCompanies && previousUserCompanies.saving && !userCompanies.saving) {
      if (!userCompanies.error) {
        addAlert('success', t('user_notifications.alert.success', { name: company.name }), { timeout: 5 })
      }
    }

    regularComponentDidUpdate(userCompanies.error, error, setError)
  }, [previousUserCompanies, company, userCompanies, addAlert, error])

  const handleSubmit = (values: ResultFields) => {
    const userCompany = props.userCompanies.userCompanies.find((userCompany) => userCompany.companyID === company.id)
    if (userCompany) {
      props.updateUserCompany({
        ...userCompany,
        notificationPayRollAutomaticallyStarted: values.notificationPayRollAutomaticallyStarted,
        notificationPayRollDone: values.notificationPayRollDone,
        notificationPayRollDeadlineUpcoming: values.notificationPayRollDeadlineUpcoming,
        notificationPayRollApprovalUpcoming: values.notificationPayRollApprovalUpcoming,
        notificationSwipeAwaitingApproval: values.notificationSwipeAwaitingApproval,
        notificationTransferInfo: values.notificationTransferInfo,
        notificationEmployeeUpdatedBanking: values.notificationEmployeeUpdatedBanking,
        notificationAutomaticZeroTaxReportDone: values.notificationAutomaticZeroTaxReportDone,
      })
    }
  }

  const userCompany = props.userCompanies.userCompanies.find(
    (userCompany) => userCompany.companyID === props.company.id
  )

  if (!userCompany) {
    jsBrowserHistory.push('/' + paths.COMPANIES + '/' + props.company.id + '/pay')
    return null
  }

  return (
    <Card>
      <TitleMenu>
        <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/pay'}>
          <Button>{t('user_notifications.header.back')}</Button>
        </Link>
      </TitleMenu>
      <Title>{t('user_notifications.title')}</Title>

      {error && <Alert message={formatError(error)} type="error" showIcon />}

      <UserNotificationTabForm
        company={company}
        userCompany={userCompany}
        userCompanies={userCompanies}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
