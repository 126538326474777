import React, { ReactElement } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { deleteCompanyIntegration, getCompanyIntegrations } from '../actions/company-integrations'
import { createOutgoingLead, getOutgoingLeads } from '../actions/outgoing-leads'
import AdvisorServiceComponent from '../components/basic-integrations/AdvisorService'
import CodanComponent from '../components/basic-integrations/Codan'
import GrandhoodComponent from '../components/basic-integrations/Grandhood'
import MinRefusionComponent from '../components/basic-integrations/MinRefusion'
import PersonaleZonenComponent from '../components/basic-integrations/PersonaleZonen'
import RmindrComponent from '../components/basic-integrations/Rmindr'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import { OutgoingLeadType } from '../model/outgoingLead'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { CompanyIntegrationReducer } from '../reducers/companyIntegrations'
import { OutgoingLeadReducer } from '../reducers/outgoingLeads'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import IntegrationsLayout from './layouts/IntegrationsLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
  companyIntegrations: CompanyIntegrationReducer
  outgoingLeads: OutgoingLeadReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getCompanyIntegrations: () => void
  deleteCompanyIntegration: (companyIntegrationID: string) => void
  getOutgoingLeads: () => void
  createOutgoingLead: (leadType: OutgoingLeadType) => void
}

function BasicIntegration(props: Reducers & Actions & RouteProps): ReactElement | null {
  const company = props.companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  const section = props.location.pathname.split('/')[2] || 'codan'
  return (
    <IntegrationsLayout location={props.location} className={'basic-integrations'}>
      {section === 'codan' && (
        <CodanComponent
          alerts={props.alerts}
          company={company}
          companyIntegrations={props.companyIntegrations}
          outgoingLeads={props.outgoingLeads}
          addAlert={props.addAlert}
          removeAlert={props.removeAlert}
          getCompanyIntegrations={props.getCompanyIntegrations}
          deleteCompanyIntegration={props.deleteCompanyIntegration}
          getOutgoingLeads={props.getOutgoingLeads}
          createOutgoingLead={props.createOutgoingLead}
        />
      )}
      {section === 'pension' && <GrandhoodComponent company={company} />}
      {section === 'personalezonen' && <PersonaleZonenComponent />}
      {section === 'advisor' && <AdvisorServiceComponent />}
      {section === 'rmindr' && <RmindrComponent />}
      {section === 'minrefusion' && <MinRefusionComponent />}
    </IntegrationsLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    companyIntegrations: state.companyIntegrations,
    outgoingLeads: state.outgoingLeads,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getCompanyIntegrations: getCompanyIntegrations,
    deleteCompanyIntegration: deleteCompanyIntegration,
    getOutgoingLeads: getOutgoingLeads,
    createOutgoingLead: createOutgoingLead,
  }
)(BasicIntegration)
