import Agreement from '../model/agreement'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchAgreements(companyID: string): Promise<RequestResponse<Agreement[]>> {
  return secureRequest('GET', url('/v2/agreements', { companyID }))
}

export function postAgreement(companyID: string, type: 'GDPR_1.0'): Promise<RequestResponse<Agreement>> {
  return secureRequest('POST', url('/v2/agreements'), {
    body: { companyID, type },
  })
}
