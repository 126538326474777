import React, { ReactElement } from 'react'

import TermsContainerComponent from '../components/terms/TermsContainer'
import { CompanyReducer } from '../reducers/companies'
import { connectToReducer } from '../utils/reducer-utils'

type Reducers = {
  companies: CompanyReducer
}

function TermsContainer(props: Reducers): ReactElement | null {
  return <TermsContainerComponent company={props.companies.company} />
}

export default connectToReducer<Reducers, any>(
  (state) => ({
    companies: state.companies,
  }),
  {}
)(TermsContainer)
