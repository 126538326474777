import React, { ReactElement, useEffect, useState } from 'react'

import { addAlertSignature } from '../../../actions/alerts'
import Employee from '../../../model/employee'
import { EmployeeReducer } from '../../../reducers/employees'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatDate, getDate } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Headline from '../../elements/Headline'
import Subtitle from '../../elements/Subtitle'
import UserImage from '../../elements/UserImage'
import DeleteEmployeeForm from './DeleteEmployeeForm'

type Props = {
  visible: boolean
  employee?: Employee
  employeeID?: string
  employees: EmployeeReducer

  addAlert: addAlertSignature
  deleteEmployee: (employeeID: string, removeOrphans: boolean) => Promise<boolean | void>
  closeModal: () => void
}

export default function DeleteEmployee(props: Props): ReactElement | null {
  let employee = props.employee
  if (!employee) {
    employee = props.employees.employees.find((employee) => employee.id === props.employeeID)
  }

  const [error, setError] = useState<Error | null>(null)

  const { visible, employees } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, employees.error, error, setError)
  }, [visible, employees, error])

  const handleSubmit = () => {
    if (!employee) {
      props.closeModal()
      return
    }
    props.deleteEmployee(employee.id, false).then((success) => {
      if (success && employee) {
        props.addAlert('success', t('employee_delete.alert.success', { name: employee.name }), { timeout: 5 })
        props.closeModal()
      }
    })
  }

  if (!employee) {
    props.closeModal()
    return null
  }

  const isFreelancer = employee.affiliationType === 'Freelancer'
  return (
    <Card className="employees-terminate">
      <Subtitle>{isFreelancer ? t('employee_delete.title.freelancer') : t('employee_delete.title.employee')}</Subtitle>
      <p>
        {isFreelancer ? t('employee_delete.intro.freelancer.line_1') : t('employee_delete.intro.employee.line_1')}
        <br />
        {isFreelancer ? t('employee_delete.intro.freelancer.line_2') : t('employee_delete.intro.employee.line_2')}
      </p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Row>
        <Col span={12} offset={6}>
          <Card className="employees-terminate-profile">
            <UserImage name={employee.name} size="small" />
            <div className="employees-terminate-profile-data">
              <Headline>{employee.name}</Headline>
              {(employee.activeEmployment || employee.earliestMutableContract) && (
                <p>
                  {formatDate(
                    employee.activeEmployment
                      ? employee.activeEmployment.startDate
                      : employee.earliestMutableContract
                      ? employee.earliestMutableContract.validFrom
                      : getDate()
                  )}
                </p>
              )}
            </div>
          </Card>
        </Col>
      </Row>
      <DeleteEmployeeForm employee={employee} employees={props.employees} onSubmit={handleSubmit} />
    </Card>
  )
}
