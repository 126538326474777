import { List } from 'immutable'
import React, { ReactElement } from 'react'

import CompanySetting from '../../../model/companySetting'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import { SupplementDefinition } from '../../../model/remuneration'
import SupplementType from '../../../model/supplementType'
import { ContractReducer } from '../../../reducers/contracts'
import { formatDate, getDate, isTimeAfter } from '../../../utils/date-utils'
import {
  isAmountChoiceSupplement,
  isChoiceSupplement,
  isDirectPayChoiceSupplement,
  isPercentageChoiceSupplement,
} from '../../../utils/employee-contract-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { t } from '../../../utils/translation-utils'
import ChoiceForm, { ChoiceFormFields, SupplementRow } from '../../contracts-add/ChoiceForm'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  mutableContract: Contract
  validFrom: Date
  employee: Employee
  contracts: ContractReducer
  settingsEnabled: CompanySetting[]
  supplementTypes: List<SupplementType>
}

export type ResultFields = {
  remuneration: {
    supplements: SupplementDefinition[]
  }
}

function EmploymentChoiceEditForm(
  props: Props & FormComponentProps<ChoiceFormFields, ResultFields>
): ReactElement | null {
  return (
    <div>
      {props.getFormError()}
      <ChoiceForm<ChoiceFormFields>
        decorateField={props.decorateField}
        decorateAnyField={props.decorateAnyField}
        getFieldValue={props.getFieldValue}
        getAnyFieldValue={props.getAnyFieldValue}
        setAnyFieldValue={props.setAnyFieldValue}
        getAnyFieldError={props.getAnyFieldError}
        employee={props.employee}
        settingsEnabled={props.settingsEnabled}
        supplementTypes={props.supplementTypes}
      />
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div className="ant-form-warning">
            {isTimeAfter(props.validFrom, getDate())
              ? t('choice.edit.form.valid_from.at_date', { date: formatDate(props.validFrom) })
              : t('choice.edit.form.valid_from.now')}
          </div>
        </Col>
      </Row>
      {props.contracts.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, ChoiceFormFields, ResultFields>({
  mapPropsToFields: (props) => {
    const getSupplementType = (row: SupplementDefinition) => {
      return props.supplementTypes.find((type) => type.id === row.typeID) || { id: '', name: undefined }
    }

    const fields: ChoiceFormFields = {
      hasChoice:
        !!props.mutableContract.remuneration &&
        props.mutableContract.remuneration.supplements.filter((row) => {
          const type = getSupplementType(row)
          return type && isChoiceSupplement(type.name)
        }).length > 0,
      remuneration: {
        supplements: props.mutableContract.remuneration
          ? props.mutableContract.remuneration.supplements
              .filter((row) => isChoiceSupplement(getSupplementType(row).name))
              .map((row: SupplementDefinition): SupplementRow => {
                const type = getSupplementType(row)
                const isFixedAmount = isAmountChoiceSupplement(type.name)

                return {
                  typeID: type.id,
                  compensationRate: formatInputNumber(row.compensationRate * (isFixedAmount ? 1 : 100)),
                  suffix: isFixedAmount ? 'fixedAmount' : 'percentage',
                  directPay: isDirectPayChoiceSupplement(type.name),
                }
              })
          : [],
      },
    }
    if (fields.remuneration.supplements.length === 0) {
      const supplementType = props.supplementTypes.find(
        (row) => isPercentageChoiceSupplement(row.name) && !isDirectPayChoiceSupplement(row.name)
      )
      if (supplementType) {
        fields.remuneration.supplements.push({
          typeID: supplementType.id,
          suffix: isAmountChoiceSupplement(supplementType.name) ? 'fixedAmount' : 'percentage',
          directPay: isDirectPayChoiceSupplement(supplementType.name),
        })
      }
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    if (key.match(/^remuneration\.supplements\.\d+\.compensationRate$/)) {
      setByPath(values, key, formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })))
    } else {
      setByPath(values, key, val)
    }
    return values
  },
  onSubmit: (values) => {
    const supplements = values.hasChoice ? values.remuneration.supplements : []
    return {
      remuneration: {
        supplements: supplements.map((row: SupplementRow): SupplementDefinition => {
          return {
            typeID: row.typeID,
            compensationRate: forceParseInputNumber(row.compensationRate) / (row.suffix === 'fixedAmount' ? 1 : 100),
          }
        }),
      },
    }
  },
})(EmploymentChoiceEditForm)
