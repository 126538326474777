import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import { RecoveryCode } from '../model/types'
import { ReducerAction } from '../utils/reducer-utils'

export interface RecoveryCodeReducer {
  loading: boolean
  loaded: boolean
  recoveryCodes: List<RecoveryCode>
  error: Error | null
}

const Params = Record<RecoveryCodeReducer>({
  loading: false,
  loaded: false,
  recoveryCodes: List<RecoveryCode>(),
  error: null,
})

export interface RecoveryCodeAction extends ReducerAction {
  recoveryCodes?: RecoveryCode[]
}

export default (
  state: Record<RecoveryCodeReducer> = Params(),
  action: RecoveryCodeAction = { type: '' }
): Record<RecoveryCodeReducer> => {
  switch (action.type) {
    case ActionTypes.RECOVERY_CODES_LOADING:
      return Params({
        loading: true,
      })
    case ActionTypes.RECOVERY_CODES_LOADED:
      return Params({
        loaded: true,
        recoveryCodes: List<RecoveryCode>(action.recoveryCodes),
      })
    case ActionTypes.RECOVERY_CODES_LOAD_FAILED:
      return Params({
        error: action.error,
      })
    case ActionTypes.USER_LOGGED_IN:
    case ActionTypes.USER_LOADED:
    case ActionTypes.USER_UPDATED:
    case ActionTypes.USER_CONFIRMED_EMAIL_CHANGE:
    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
