enum UserCompanyType {
  BUSINESS = 'Business',
  ACCOUNTANT = 'Accountant',
  INTERNAL_SALARY_ADMINISTRATOR = 'InternalSalaryAdministrator',
  EXTERNAL_SALARY_ADMINISTRATOR = 'ExternalSalaryAdministrator',
  BOOK_KEEPER = 'BookKeeper',
  DEPARTMENT_MANAGER = 'DepartmentManager',
  APPROVE_ONLY = 'ApproveOnly',
  READ_ONLY = 'ReadOnly',
}
export default UserCompanyType
