import React, { ReactElement } from 'react'

import Company from '../../model/company'
import CompanySetting from '../../model/companySetting'
import Employee from '../../model/employee'
import { t } from '../../utils/translation-utils'
import Alert from './alert'
import Button from './button'

type Props = {
  company: Company
  disablingSettings: CompanySetting[]
  employee: Employee
  isDetailed: boolean
  itemMessageID: 'time_registration' | 'car_allowance' | 'salary_registration'

  switchToDetailed: () => void
}

export function RegistrationMethodAppAlert(props: Props): ReactElement | null {
  if (!props.employee.hasUser && !props.employee.invitationSent) {
    return null
  }
  if (props.isDetailed) {
    return null
  }
  if (props.disablingSettings.some((setting) => props.company.settingsEnabled.some((enabled) => enabled === setting))) {
    return null
  }

  return (
    <Alert
      type={'warning'}
      message={
        <div className="registration-method-app-alert-message">
          <div>{t(`registration_method_app_alert.message.${props.itemMessageID}`)}</div>
          <Button type="secondary" onClick={props.switchToDetailed}>
            {t(`registration_method_app_alert.button.${props.itemMessageID}`)}
          </Button>
        </div>
      }
      showIcon
    />
  )
}
