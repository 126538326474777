import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature } from '../actions/alerts'
import { getInvoices } from '../actions/invoices'
import { getPaySlip } from '../actions/pay-slips'
import { getVouchers } from '../actions/vouchers'
import PreviewComponent from '../components/pdf-preview/Preview'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import PDFPreviewTypes from '../constants/pdf-preview-types'
import { CompanyReducer } from '../reducers/companies'
import { EmployeeReducer } from '../reducers/employees'
import { InvoiceReducer } from '../reducers/invoices'
import { PaySlipReducer } from '../reducers/paySlips'
import { UserReducer } from '../reducers/user'
import { VoucherReducer } from '../reducers/vouchers'
import { formatDate } from '../utils/date-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { secureUrl } from '../utils/request-utils'
import { RouteProps, splatArray } from '../utils/route-utils'

type Reducers = {
  user: UserReducer
  companies: CompanyReducer
  employees: EmployeeReducer
  paySlips: PaySlipReducer
  invoices: InvoiceReducer
  vouchers: VoucherReducer
}

type Actions = {
  addAlert: addAlertSignature
  getPaySlip: (paySlipID: string) => void
  getVouchers: () => void
  getInvoices: (payRollID?: string, companyID?: string) => void
}

function PDFPreview(props: Reducers & Actions & RouteProps): ReactElement | null {
  const { params, paySlips, getPaySlip, invoices, getInvoices, vouchers, getVouchers, companies } = props
  useEffect(() => {
    const company = companies.company
    if (!company) {
      addAlert('warning', 'Det ønskede dokument kunne desværre ikke findes', { timeout: 5 })
      jsBrowserHistory.push('/')
      return
    }
    const [documentType, documentId] = splatArray(params.splat)
    switch (documentType) {
      case PDFPreviewTypes.PAY_SLIP:
        if (!paySlips.loading && !paySlips.loaded) {
          getPaySlip(documentId)
        }
        break
      case PDFPreviewTypes.NEXT_PAY_SLIP:
        break
      case PDFPreviewTypes.INVOICE:
        if (invoices.companyID !== company.id || invoices.payRollID || (!invoices.loading && !invoices.loaded)) {
          getInvoices(undefined, company.id)
        }
        break
      case PDFPreviewTypes.ACCOUNTING_VOUCHER:
        if (vouchers.payRollID || (!vouchers.loading && !vouchers.loaded)) {
          getVouchers()
        }
        break
      default:
        addAlert('warning', 'Det ønskede dokument kunne desværre ikke findes', { timeout: 5 })
        jsBrowserHistory.push('/')
        break
    }
  })

  const isLoading = () => {
    const [documentType] = splatArray(props.params.splat)
    switch (documentType) {
      case PDFPreviewTypes.PAY_SLIP:
        return !props.paySlips.loaded
      case PDFPreviewTypes.NEXT_PAY_SLIP:
        return false
      case PDFPreviewTypes.INVOICE:
        return !props.invoices.loaded
      case PDFPreviewTypes.ACCOUNTING_VOUCHER:
        return !props.vouchers.loaded
      default:
        return true
    }
  }

  const getDocumentTitle = () => {
    const [documentType, documentId] = splatArray(props.params.splat)
    switch (documentType) {
      case PDFPreviewTypes.PAY_SLIP: {
        const paySlip = props.paySlips.paySlips.find((paySlip) => paySlip.id === documentId)
        if (!paySlip) {
          props.addAlert('warning', 'Det ønskede dokument kunne desværre ikke findes', { timeout: 5 })
          jsBrowserHistory.push('/')
          return ''
        }
        let employeeName = ''
        paySlip.fields.forEach((field) => {
          if (field.name === 'EmployeeName') {
            employeeName = field.serializedValue
          }
        })
        return 'Lønseddel for ' + employeeName
      }
      case PDFPreviewTypes.NEXT_PAY_SLIP: {
        const employee = props.employees.employees.find((employee) => employee.id === documentId)
        if (!employee) {
          props.addAlert('warning', 'Det ønskede dokument kunne desværre ikke findes', { timeout: 5 })
          jsBrowserHistory.push('/')
          return ''
        }
        return 'Næste lønseddel for ' + employee.name
      }
      case PDFPreviewTypes.INVOICE: {
        const invoice = props.invoices.invoices.find((invoice) => invoice.id === documentId)
        if (!invoice) {
          props.addAlert('warning', 'Det ønskede dokument kunne desværre ikke findes', { timeout: 5 })
          jsBrowserHistory.push('/')
          return ''
        }
        return 'Faktura (' + formatDate(invoice.invoiceDate)
      }
      case PDFPreviewTypes.ACCOUNTING_VOUCHER: {
        const voucher = props.vouchers.vouchers.find((voucher) => voucher.id === documentId)
        if (!voucher) {
          props.addAlert('warning', 'Det ønskede dokument kunne desværre ikke findes', { timeout: 5 })
          jsBrowserHistory.push('/')
          return ''
        }
        return 'Bogføringsbilag (' + formatDate(voucher.date) + ')'
      }
      default:
        return ''
    }
  }
  const getDocumentUrl = () => {
    const [documentType, documentId] = splatArray(props.params.splat)
    switch (documentType) {
      case PDFPreviewTypes.PAY_SLIP:
        return secureUrl('/v2/paySlipsPDF/' + documentId, {
          disposition: 'inline',
        })
      case PDFPreviewTypes.NEXT_PAY_SLIP:
        return secureUrl('/v2/simulatedPaySlipsPDF?employeeID=' + documentId, {
          disposition: 'inline',
        })
      case PDFPreviewTypes.INVOICE:
        return secureUrl('/v2/invoicesPDF/' + documentId, {
          disposition: 'inline',
        })
      case PDFPreviewTypes.ACCOUNTING_VOUCHER:
        return secureUrl('/v2/accountingVoucherPDF/' + documentId, {
          disposition: 'inline',
        })
      default:
        return ''
    }
  }
  const getDownloadUrl = () => {
    const [documentType, documentId] = splatArray(props.params.splat)
    switch (documentType) {
      case PDFPreviewTypes.PAY_SLIP:
        return secureUrl('/v2/paySlipsPDF/' + documentId)
      case PDFPreviewTypes.NEXT_PAY_SLIP:
        return secureUrl('/v2/simulatedPaySlipsPDF?employeeID=' + documentId)
      case PDFPreviewTypes.INVOICE:
        return secureUrl('/v2/invoicesPDF/' + documentId)
      case PDFPreviewTypes.ACCOUNTING_VOUCHER:
        return secureUrl('/v2/accountingVoucherPDF/' + documentId)
      default:
        return ''
    }
  }

  if (isLoading()) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }
  return (
    <PreviewComponent
      user={props.user}
      documentTitle={getDocumentTitle()}
      documentUrl={getDocumentUrl()}
      downloadUrl={getDownloadUrl()}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    user: state.user,
    companies: state.companies,
    employees: state.employees,
    paySlips: state.paySlips,
    invoices: state.invoices,
    vouchers: state.vouchers,
  }),
  {
    addAlert: addAlert,
    getPaySlip: getPaySlip,
    getVouchers: getVouchers,
    getInvoices: getInvoices,
  }
)(PDFPreview)
