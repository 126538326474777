import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import DocumentCategory from '../model/documentCategory'
import { ReducerAction } from '../utils/reducer-utils'

export interface DocumentCategoryReducer {
  companyID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  documentCategories: List<DocumentCategory>
  error: Error | null
}

const Params = Record<DocumentCategoryReducer>({
  companyID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  documentCategories: List<DocumentCategory>(),
  error: null,
})

export interface DocumentCategoryAction extends ReducerAction {
  companyID?: string
  documentCategories?: DocumentCategory[]
  documentCategory?: DocumentCategory
  documentCategoryID?: string
}

const comparator = (a: DocumentCategory, b: DocumentCategory) => {
  return a.name.localeCompare(b.name, 'da-dk')
}

export default (
  state: Record<DocumentCategoryReducer> = Params(),
  action: DocumentCategoryAction = { type: '' }
): Record<DocumentCategoryReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.DOCUMENT_CATEGORY_LOADING:
      return state
        .set('loading', true)
        .set('loaded', false)
        .set('companyID', action.companyID || state.get('companyID'))
    case ActionTypes.DOCUMENT_CATEGORY_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('companyID', action.companyID || state.get('companyID'))
        .set('documentCategories', List<DocumentCategory>(action.documentCategories).sort(comparator))
        .set('error', null)
    case ActionTypes.DOCUMENT_CATEGORY_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', action.companyID || state.get('companyID'))
        .set('error', action.error || null)

    case ActionTypes.DOCUMENT_CATEGORY_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DOCUMENT_CATEGORY_ADDED:
      if (!action.documentCategory) {
        return state
      }
      idx = state
        .get('documentCategories')
        .findIndex((item) => !!action.documentCategory && item.id === action.documentCategory.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('documentCategories', state.get('documentCategories').set(idx, action.documentCategory).sort(comparator))
      }
      return state
        .set('saving', false)
        .set('documentCategories', state.get('documentCategories').push(action.documentCategory).sort(comparator))
    case ActionTypes.DOCUMENT_CATEGORY_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.DOCUMENT_CATEGORY_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.DOCUMENT_CATEGORY_UPDATED:
      if (!action.documentCategory) {
        return state
      }
      idx = state.get('documentCategories').findIndex((item) => item.id === action.documentCategoryID)
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('documentCategories', state.get('documentCategories').set(idx, action.documentCategory).sort(comparator))
    case ActionTypes.DOCUMENT_CATEGORY_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
