import { addDays, isSameDay } from 'date-fns'
import React, { ReactElement, useEffect, useState } from 'react'

import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import SalaryCycle from '../../../model/salaryCycle'
import { DateFormat } from '../../../model/types'
import { ContractReducer } from '../../../reducers/contracts'
import { EmployeeContractDeltaReducer } from '../../../reducers/employeeContractDeltas'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { getDate, isTimeAfter } from '../../../utils/date-utils'
import { formatError } from '../../../utils/error-utils'
import { getCurrentPeriod } from '../../../utils/salary-period-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Subtitle from '../../elements/Subtitle'
import LeaveEmployeeForm, { isLeavePeriodValid, LeaveResult } from './LeaveEmployeeForm'

type Props = {
  visible: boolean
  employee: Employee
  contracts: ContractReducer
  viewingContract: Contract
  employeeContractDeltas: EmployeeContractDeltaReducer
  salaryCycle: SalaryCycle

  createLeave: (employeeID: string, startDate: DateFormat, endDate: DateFormat) => Promise<Contract[] | void>
  removeLeave: (contractID: string) => void
  setEmployeeContract: (employeeID: string, contractID: string) => void
}

export default function LeaveEmployee(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, contracts } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, contracts.error, error, setError)
  }, [visible, contracts, error])

  const handleSubmit = (values: LeaveResult) => {
    if (!isLeavePeriodValid(getDate(values.startDate), getDate(values.endDate))) {
      return
    }
    const currentContract = props.viewingContract
    props.createLeave(props.employee.id, values.startDate, values.endDate).then(() => {
      // make sure that we update to a contract after the current one, if the current got deleted
      let updateContractID = true
      let toContractID: string | undefined = undefined
      props.employeeContractDeltas.contracts.forEach((delta) => {
        if (delta.contractID === currentContract.id) {
          updateContractID = false
        }
        if (
          updateContractID &&
          !toContractID &&
          isTimeAfter(getDate(delta.contract.validFrom), getDate(currentContract.validFrom))
        ) {
          toContractID = delta.contractID
        }
      })
      if (updateContractID && toContractID) {
        props.setEmployeeContract(props.employee.id, toContractID)
      }
    })
  }
  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault()

    let thisContract = props.viewingContract
    props.employeeContractDeltas.contracts.forEach((delta) => {
      if (delta.contract.employmentID === props.employee.activeEmployment?.id) {
        if (
          !thisContract &&
          delta.contract.validTo &&
          props.viewingContract.validTo &&
          delta.contract.validTo < props.viewingContract.validTo
        ) {
          thisContract = delta.contract
        }
      }
    })
    if (thisContract) {
      props.removeLeave(thisContract.id)
    }
  }

  const employee = props.employee
  let onLeave = employee.employmentStatus === 'OnLeave'
  if (!onLeave) {
    // only make this check if the current contract starts after now
    if (isTimeAfter(getDate(props.viewingContract.validFrom), getDate())) {
      let wasCurrentPrevious = false
      props.employeeContractDeltas.contracts.reverse().forEach((delta) => {
        if (delta.contractID === props.viewingContract.id) {
          wasCurrentPrevious = true
        } else if (wasCurrentPrevious) {
          if (!isSameDay(addDays(getDate(delta.contract.validTo), 1), getDate(props.viewingContract.validFrom))) {
            onLeave = true
          }
          wasCurrentPrevious = false
        }
      })
    }
  }

  const currentPeriod = getCurrentPeriod(props.salaryCycle)
  return (
    <Card className="employees-leave">
      <Subtitle>{onLeave ? t('employee_leave.title.delete') : t('employee_leave.title.create')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      {onLeave ? (
        <Row>
          <Col span={24}>
            <Button type="danger" size="large" block onClick={handleCancel}>
              {t('employee_leave.title.delete.button')}
            </Button>
          </Col>
        </Row>
      ) : (
        <LeaveEmployeeForm
          startDate={currentPeriod.start}
          endDate={currentPeriod.end}
          employee={props.employee}
          contracts={props.contracts}
          onSubmit={handleSubmit}
        />
      )}
    </Card>
  )
}
