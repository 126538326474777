import React, { CSSProperties, ReactElement, ReactNode, useRef } from 'react'

import Tooltip from '../tooltip'

import './style/css'

type Props = {
  prefixCls?: string
  placement?: 'top' | 'bottomRight'
  transitionName?: 'zoom-big'
  trigger?: 'hover' | 'click'
  mouseEnterDelay?: number
  mouseLeaveDelay?: number
  overlayStyle?: CSSProperties
  overlayClassName?: string
  onClick?: (e: React.MouseEvent) => void
  title?: ReactNode
  content?: ReactNode
  children?: ReactNode
}

export default function Popover(props: Props): ReactElement | null {
  const {
    prefixCls = 'ant-popover',
    placement = 'top',
    transitionName = 'zoom-big',
    trigger = 'hover',
    mouseEnterDelay = 0.1,
    mouseLeaveDelay = 0.1,
    overlayStyle = {},
  } = props

  const ref = useRef<typeof Tooltip>()

  const getOverlay = () => {
    const { title, content } = props
    return (
      <div>
        {title && <div className={`${prefixCls}-title`}>{title}</div>}
        <div className={`${prefixCls}-inner-content`}>{content}</div>
      </div>
    )
  }

  delete props.title
  return (
    <Tooltip
      {...props}
      prefixCls={prefixCls}
      placement={placement}
      transitionName={transitionName}
      trigger={trigger}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      overlayStyle={overlayStyle}
      ref={ref}
      title={getOverlay()}
    />
  )
}
