import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import {
  getCoarseSalaryRegistrations,
  updateCoarseSalaryRegistrationBulk,
} from '../actions/coarse-salary-registrations'
import { addContract, getContracts, updateContract } from '../actions/contracts'
import { getSalaryCycles } from '../actions/salary-cycles'
import {
  approveSalaryRegistrations,
  deleteSalaryRegistrationBulk,
  getSalaryRegistrations,
} from '../actions/salary-registrations'
import SalaryRegistrationComponent from '../components/salary-registration/SalaryRegistration'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { CoarseSalaryRegistrationMutableFields } from '../model/coarseSalaryRegistration'
import Contract, { ContractCreationFields } from '../model/contract'
import { DateFormat, SettledState } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CoarseSalaryRegistrationReducer } from '../reducers/coarseSalaryRegistrations'
import { CompanyReducer } from '../reducers/companies'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { ContractReducer } from '../reducers/contracts'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employees'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { SalaryRegistrationReducer } from '../reducers/salaryRegistrations'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import RegistrationLayout from './layouts/RegistrationLayout'

type Reducers = {
  alerts: AlertReducer
  coarseSalaryRegistrations: CoarseSalaryRegistrationReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  contracts: ContractReducer
  employees: EmployeeReducer
  departments: DepartmentReducer
  salaryCycles: SalaryCycleReducer
  salaryRegistrations: SalaryRegistrationReducer
  salaryTypes: SalaryTypeReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: Contract) => void
  getSalaryCycles: () => void
  getCoarseSalaryRegistrations: (companyID: string, salaryPeriodID: string) => void
  getSalaryRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    fromDate: DateFormat,
    toDate: DateFormat,
    stateFilter?: SettledState
  ) => void
  getContracts: () => void
  approveSalaryRegistrations: (ids: string[]) => void
  updateCoarseSalaryRegistrationBulk: (
    employeeID: string,
    coarseSalaryRegistrations: CoarseSalaryRegistrationMutableFields[]
  ) => void
  deleteSalaryRegistrationBulk: (companyID?: string, employeeID?: string) => Promise<void>
}

function SalaryRegistration(props: Reducers & Actions & RouteProps): ReactElement | null {
  const company = props.companies.company
  const { salaryCycles, getSalaryCycles } = props
  useEffect(() => {
    if (!company) {
      return
    }
    if (!salaryCycles.loaded && !salaryCycles.loading) {
      getSalaryCycles()
    }
  }, [company, salaryCycles, getSalaryCycles])

  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  if (!salaryCycles.loaded) {
    return <LoadingOverlay />
  }

  return (
    <RegistrationLayout route={props.route}>
      <SalaryRegistrationComponent
        alerts={props.alerts}
        coarseSalaryRegistrations={props.coarseSalaryRegistrations}
        companies={props.companies}
        companyUsers={props.companyUsers}
        contracts={props.contracts}
        employees={props.employees}
        departments={props.departments.departments}
        salaryCycles={props.salaryCycles.salaryCycles}
        salaryRegistrations={props.salaryRegistrations}
        salaryTypes={props.salaryTypes.salaryTypes}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        addContract={props.addContract}
        updateContract={props.updateContract}
        getCoarseSalaryRegistrations={props.getCoarseSalaryRegistrations}
        getSalaryRegistrations={props.getSalaryRegistrations}
        getContracts={props.getContracts}
        approveSalaryRegistrations={props.approveSalaryRegistrations}
        updateCoarseSalaryRegistrationBulk={props.updateCoarseSalaryRegistrationBulk}
        deleteSalaryRegistrationBulk={props.deleteSalaryRegistrationBulk}
      />
    </RegistrationLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    coarseSalaryRegistrations: state.coarseSalaryRegistrations,
    companies: state.companies,
    companyUsers: state.companyUsers,
    contracts: state.contracts,
    employees: state.employees,
    departments: state.departments,
    salaryCycles: state.salaryCycles,
    salaryRegistrations: state.salaryRegistrations,
    salaryTypes: state.salaryTypes,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    addContract: addContract,
    updateContract: updateContract,
    getSalaryRegistrations: getSalaryRegistrations,
    getSalaryCycles: getSalaryCycles,
    getCoarseSalaryRegistrations: getCoarseSalaryRegistrations,
    getContracts: getContracts,
    approveSalaryRegistrations: approveSalaryRegistrations,
    updateCoarseSalaryRegistrationBulk: updateCoarseSalaryRegistrationBulk,
    deleteSalaryRegistrationBulk: deleteSalaryRegistrationBulk,
  }
)(SalaryRegistration)
