import { List } from 'immutable'
import React, { ReactElement, useCallback, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import { fetchDefaultWorkSchedules } from '../../../api/employees'
import { WorkSchedule } from '../../../model/contract'
import Employee from '../../../model/employee'
import SalaryPeriod from '../../../model/salaryPeriod'
import SalaryType from '../../../model/salaryType'
import { formatError } from '../../../utils/error-utils'
import { isRequestError } from '../../../utils/error-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { forceParseInputNumber, formatCurrency, formatInputNumber, parseInputNumber } from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { t, translateGroupTitle } from '../../../utils/translation-utils'
import Form from '../../antd/form'
import Alert from '../../elements/alert'
import Button from '../../elements/button'
import Checkbox from '../../elements/checkbox'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import Switch from '../../elements/switch'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  employee: Employee
  period: SalaryPeriod
  salaryTypes: List<SalaryType>
  workSchedule: WorkSchedule
  workHours: Record<string, number>
  workDays?: number
  addAlert: addAlertSignature
}

type Fields = {
  workHours: Record<string, string>
  workDays: string
  useStandardDays: boolean
  reuseRegistration: boolean
}

export type CoarseTimeRegistrationResult = {
  workHours: Record<string, number>
  workDays: number
  workSchedule: WorkSchedule
}

function CoarseTimeRegistrationForm(
  props: Props & FormComponentProps<Fields, CoarseTimeRegistrationResult>
): ReactElement | null {
  const [defaultWorkScheduleDays, setDefaultWorkScheduleDays] = useState<number>()
  const [loading, setLoading] = useState(false)

  const { employee, period, setFieldValue, addAlert } = props

  const getDefaultWorkSchedule = useCallback(() => {
    fetchDefaultWorkSchedules(employee.id, period.id)
      .then((res) => {
        const defaultWorkScheduleDays = res.data.days
        setLoading(false)
        setDefaultWorkScheduleDays(defaultWorkScheduleDays)
        setFieldValue('workDays', formatInputNumber(defaultWorkScheduleDays))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          addAlert('error', formatError(e))
        }
      })
  }, [employee, period, setFieldValue, addAlert])

  const { workSchedule } = props

  useEffectOnce(() => {
    if (workSchedule === 'Calendar') {
      setLoading(true)
      getDefaultWorkSchedule()
    }
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (defaultWorkScheduleDays === undefined) {
        setLoading(true)
        getDefaultWorkSchedule()
      }
    } else {
      setDefaultWorkScheduleDays(undefined)
    }
  }

  const { decorateField, decorateAnyField, getFieldValue, getAnyFieldValue } = props

  const contract = props.employee.activeContract
  if (!contract || !contract.remuneration) {
    return null
  }
  const types = contract.remuneration.salary.filter((salary) => {
    return props.salaryTypes.find((type) => type.id === salary.salaryTypeID)?.class === 'Hourly'
  })
  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          {types.map((type) => {
            let description = t('time_registration_tab.coarse.form.work_hours')
            const title = translateGroupTitle(type)
              .replace(/^Timeløn/, '')
              .trim()
            if (title) {
              description = t('time_registration_tab.coarse.form.work_hours.with_title', { title })
            }
            description = t('time_registration_tab.coarse.form.work_hours.format', {
              title: description,
              rate: formatCurrency(type.rate, 2),
            })
            return decorateAnyField(`workHours.${type.salaryTypeID}`, {
              title: description,
              placeholder: t('time_registration_tab.coarse.form.work_hours'),
              suffix: t('time_registration_tab.coarse.form.work_hours.suffix'),
              validate: (val) => {
                if (!val && val !== 0) {
                  return t('time_registration_tab.coarse.form.work_hours.required', { description })
                }
                const n = forceParseInputNumber(val)
                if (n < 0 || n > props.period.days * 24 || !val.toString().match(/^[0-9,.]+$/)) {
                  return t('time_registration_tab.coarse.form.work_hours.invalid', { description })
                }
                return null
              },
            })(<Input />)
          })}
          <span className="ant-form-extra">{t('time_registration_tab.coarse.form.work_hours.info')}</span>
        </Col>
        <Col span={12}>
          {decorateField('workDays', {
            placeholder: t('time_registration_tab.coarse.form.work_days'),
            suffix: t('time_registration_tab.coarse.form.work_days.suffix'),
            validate: (val) => {
              if (!val) {
                return t('time_registration_tab.coarse.form.work_days.required')
              }
              const num = forceParseInputNumber(val)
              if (num < 0 || num > props.period.days || !val.toString().match(/^[0-9,.]+$/)) {
                return t('time_registration_tab.coarse.form.work_days.invalid')
              }
              if (num % 1 !== 0) {
                return t('time_registration_tab.coarse.form.work_days.must_be_integer')
              }
              return null
            },
          })(<Input disabled={getFieldValue('useStandardDays')} />)}
          {decorateField('useStandardDays', {
            skipWrapper: true,
            skipLabel: true,
            valueOnChecked: true,
            noBlur: true,
          })(<Checkbox onChange={handleChange}>{t('time_registration_tab.coarse.form.use_standard_days')}</Checkbox>)}
        </Col>
      </Row>
      {types.some(
        (type) =>
          parseInputNumber(getAnyFieldValue(`workHours.${type.salaryTypeID}`) as string, { permitNoTrim: true }) >= 200
      ) && (
        <Row>
          <Col span={24}>
            <Alert
              message={t('time_registration_tab.coarse.form.hours_warning')}
              type="warning"
              style={{ marginBottom: 0 }}
              showIcon
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={10}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
        <Col span={14}>
          <Form.Item style={{ marginTop: -5 }}>
            <label htmlFor="reuseRegistration">&nbsp;</label>
            <div className="ant-switch-wrapper">
              {decorateField('reuseRegistration', {
                skipWrapper: true,
                skipLabel: true,
                valueOnChecked: true,
                noBlur: true,
              })(<Switch />)}
              <span className="ant-switch-text">
                {getFieldValue('useStandardDays')
                  ? t('time_registration_tab.coarse.form.reuse_registration.use_standard_days')
                  : t('time_registration_tab.coarse.form.reuse_registration')}
              </span>
            </div>
          </Form.Item>
        </Col>
      </Row>
      {loading && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, CoarseTimeRegistrationResult>({
  mapPropsToFields: (props) => {
    const workHours: Record<string, string> = {}
    props.employee.activeContract?.remuneration?.salary
      .filter((salary) => {
        return props.salaryTypes.find((type) => type.id === salary.salaryTypeID)?.class === 'Hourly'
      })
      .forEach((salary) => {
        workHours[salary.salaryTypeID] = props.workHours[salary.salaryTypeID]
          ? formatInputNumber(props.workHours[salary.salaryTypeID])
          : '0'
      })
    return {
      workHours,
      workDays: formatInputNumber(props.workDays),
      useStandardDays: props.workSchedule === 'Calendar',
      reuseRegistration: props.workSchedule !== 'None',
    }
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    if (key.match(/^workHours\.(.+)$/)) {
      setByPath(values, key, formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })))
    }
    switch (key) {
      case 'workDays':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    const workHours: Record<string, number> = {}
    Object.keys(values.workHours).forEach((salaryTypeID) => {
      workHours[salaryTypeID] = forceParseInputNumber(values.workHours[salaryTypeID])
    })
    let workSchedule: WorkSchedule = 'None'
    if (values.reuseRegistration) {
      if (values.useStandardDays) {
        workSchedule = 'Calendar'
      } else {
        workSchedule = 'Fixed'
      }
    }
    return {
      workHours,
      workDays: forceParseInputNumber(values.workDays),
      workSchedule,
    }
  },
})(CoarseTimeRegistrationForm)
