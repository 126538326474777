import { addMonths, subYears } from 'date-fns'
import React, { ReactElement, useEffect } from 'react'

import { createProject, deleteProject, getProjects, updateProject } from '../actions/projects'
import { getTimeRegistrations } from '../actions/time-registrations'
import ProjectsComponents from '../components/projects/Projects'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import { ProjectCreationFields, ProjectMutableFields } from '../model/project'
import { DateFormat } from '../model/types'
import { CompanyReducer } from '../reducers/companies'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employees'
import { ProjectReducer } from '../reducers/projects'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { formatAPIDate, getDate } from '../utils/date-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import AdministrationLayout from './layouts/AdministrationLayout'

type Reducer = {
  companies: CompanyReducer
  employees: EmployeeReducer
  departments: DepartmentReducer
  timeRegistrations: TimeRegistrationReducer
  projects: ProjectReducer
}

type Actions = {
  getTimeRegistrations: (
    companyID?: string,
    employeeID?: string,
    payRollID?: string,
    fromDate?: DateFormat,
    toDate?: DateFormat
  ) => void
  getProjects: () => void
  createProject: (project: ProjectCreationFields) => void
  updateProject: (project: ProjectMutableFields) => void
  deleteProject: (id: string) => void
}

function Projects(props: Reducer & Actions & RouteProps): ReactElement | null {
  const { companies, projects, timeRegistrations, getTimeRegistrations, getProjects } = props
  useEffect(() => {
    if (!projects.loading && !projects.loaded) {
      getProjects()
    }
    const companyID = companies.company?.id
    if (
      companyID &&
      (!timeRegistrations.companyID ||
        timeRegistrations.companyID !== companyID ||
        (!timeRegistrations.loading && !timeRegistrations.loaded))
    ) {
      getTimeRegistrations(
        companyID,
        undefined,
        undefined,
        formatAPIDate(subYears(getDate(), 2)),
        formatAPIDate(addMonths(getDate(), 6))
      )
    }
  })

  const company = companies.company

  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  return (
    <AdministrationLayout route={props.route}>
      <ProjectsComponents
        timeRegistrations={timeRegistrations}
        projects={projects}
        company={company}
        employees={props.employees.employees}
        departments={props.departments.departments}
        createProject={props.createProject}
        updateProject={props.updateProject}
        deleteProject={props.deleteProject}
      />
    </AdministrationLayout>
  )
}

export default connectToReducer<Reducer, Actions, RouteProps>(
  (state) => ({
    companies: state.companies,
    employees: state.employees,
    departments: state.departments,
    timeRegistrations: state.timeRegistrations,
    projects: state.projects,
  }),
  {
    getTimeRegistrations: getTimeRegistrations,
    getProjects: getProjects,
    createProject: createProject,
    updateProject: updateProject,
    deleteProject: deleteProject,
  }
)(Projects)
