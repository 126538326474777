import jsBrowserHistory from '../components/widgets/jsBrowserHistory'

export type RoutePropsParams = {
  splat?: string | string[]
}

export type RoutePropsLocation = {
  pathname: string
  search: string
  query: Record<string, string> // from React
  hash?: string
}

export type RoutePropsRoute = {
  path: string
}

export type RouteProps = {
  route: RoutePropsRoute // from React
  location: RoutePropsLocation // from React
  params: RoutePropsParams // from React
}

export function splatArray(splat?: string | string[]): string[] {
  if (!splat) {
    return []
  }
  if (typeof splat === 'string') {
    return [splat]
  }
  return splat
}
export function splatString(splat?: string | string[]): string {
  if (!splat) {
    return ''
  }
  if (typeof splat === 'string') {
    return splat
  }
  return splat.join('/')
}

export function getParam(key: string): string {
  const search = document.location.search
  if (search && search.substring(0, 1) === '?') {
    const regex = new RegExp('(?:\\?|&)' + key + '=(.*?)(?:&|$)', 'i')
    const matches = search.match(regex)
    if (matches) {
      return decodeURIComponent(matches[1])
    }
  }
  return ''
}

export function getPage(): number {
  const page = parseInt(getParam('p'), 10)
  if (page && !isNaN(page)) {
    return page
  }
  return 1
}

export function setPage(page: number): void {
  const param = page > 1 ? 'p=' + page : ''
  const search = (document.location.search || '').replace(/^\?/, '')
  const result = []
  let hadPage = false
  search.split('&').forEach((part) => {
    if (part.substring(0, 2) === 'p=') {
      part = param
      hadPage = true
    }
    if (part) {
      result.push(part)
    }
  })
  if (!hadPage && param) {
    result.push(param)
  }
  jsBrowserHistory.push({
    pathname: document.location.pathname,
    search: result.length > 0 ? '?' + result.join('&') : '',
  })
}
