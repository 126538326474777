import React, { ReactElement, useEffect, useState } from 'react'

import { DateFormat } from '../../model/types'
import { TransferReducer } from '../../reducers/transfers'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import TransferDateModalForm, { TransferDateFields } from './TransferDateModalForm'

type Props = {
  visible: boolean
  transferID: string
  transfers: TransferReducer

  updateTransferDate: (transferID: string, date: DateFormat) => void
}

export default function TransferDateModal(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, transfers } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, transfers.error, error, setError)
  }, [visible, transfers, error])

  const handleSubmit = (values: TransferDateFields) => {
    props.updateTransferDate(props.transferID, values.date)
  }

  return (
    <Card className="transfer-date">
      <Subtitle>{t('transfers.date.title')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <TransferDateModalForm transferID={props.transferID} transfers={props.transfers} onSubmit={handleSubmit} />
    </Card>
  )
}
