import React from 'react'

import { fetchDocumentCategories, postDocumentCategory, putDocumentCategory } from '../api/document-categories'
import ActionTypes from '../constants/action-types'
import DocumentCategory, { DocumentCategoryMutableFields } from '../model/documentCategory'
import { DocumentCategoryAction } from '../reducers/documentCategories'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingDocumentCategories(companyID: string): DocumentCategoryAction {
  return {
    type: ActionTypes.DOCUMENT_CATEGORY_LOADING,
    companyID,
  }
}
function loadedDocumentCategories(companyID: string, documentCategories: DocumentCategory[]): DocumentCategoryAction {
  return {
    type: ActionTypes.DOCUMENT_CATEGORY_LOADED,
    documentCategories,
    companyID,
  }
}
function failedLoadingDocumentCategories(companyID: string, error: Error): DocumentCategoryAction {
  return {
    type: ActionTypes.DOCUMENT_CATEGORY_LOAD_FAILED,
    error,
    companyID,
  }
}

function addingDocumentCategory(companyID: string): DocumentCategoryAction {
  return {
    type: ActionTypes.DOCUMENT_CATEGORY_ADDING,
    companyID,
  }
}
export function addedDocumentCategory(companyID: string, documentCategory: DocumentCategory): DocumentCategoryAction {
  return {
    type: ActionTypes.DOCUMENT_CATEGORY_ADDED,
    companyID,
    documentCategory,
  }
}
function failedAddingDocumentCategory(companyID: string, error: Error): DocumentCategoryAction {
  return {
    type: ActionTypes.DOCUMENT_CATEGORY_ADD_FAILED,
    error,
    companyID,
  }
}

function updatingDocumentCategory(companyID: string): DocumentCategoryAction {
  return {
    type: ActionTypes.DOCUMENT_CATEGORY_UPDATING,
    companyID,
  }
}
export function updatedDocumentCategory(companyID: string, documentCategory: DocumentCategory): DocumentCategoryAction {
  return {
    type: ActionTypes.DOCUMENT_CATEGORY_UPDATED,
    companyID,
    documentCategoryID: documentCategory.id,
    documentCategory,
  }
}
function failedUpdatingDocumentCategory(companyID: string, error: Error): DocumentCategoryAction {
  return {
    type: ActionTypes.DOCUMENT_CATEGORY_UPDATE_FAILED,
    error,
    companyID,
  }
}

export function getDocumentCategories() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<DocumentCategory[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingDocumentCategories(companyID))
    return fetchDocumentCategories(companyID)
      .then((res) => {
        dispatch(loadedDocumentCategories(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingDocumentCategories(companyID, e))
        }
      })
  }
}

export function addDocumentCategory(documentCategory: DocumentCategoryMutableFields) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<DocumentCategory | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(addingDocumentCategory(companyID))
    return postDocumentCategory(companyID, documentCategory)
      .then((res) => {
        dispatch(addedDocumentCategory(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingDocumentCategory(companyID, e))
        }
      })
  }
}

export function updateDocumentCategory(documentCategory: DocumentCategory) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<DocumentCategory | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(updatingDocumentCategory(companyID))
    return putDocumentCategory(documentCategory)
      .then((res) => {
        dispatch(updatedDocumentCategory(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingDocumentCategory(companyID, e))
        }
      })
  }
}
