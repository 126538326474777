import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CompanyFeature from '../../model/companyFeature'
import CompanyPricing from '../../model/companyPricing'
import CompanyUser from '../../model/companyUser'
import Document, { DocumentCreationFields, DocumentMutableFields } from '../../model/document'
import DocumentCategory from '../../model/documentCategory'
import Employee from '../../model/employee'
import { DateTimeFormat } from '../../model/types'
import { AlertReducer } from '../../reducers/alerts'
import { DocumentReducer } from '../../reducers/documents'
import { paths } from '../../routes'
import Language from '../../types/language'
import { FileChangeEvent, HandleFileEventError } from '../../utils/antd-utils'
import { getAccessToken } from '../../utils/cookie-utils'
import { formatDate } from '../../utils/date-utils'
import { formatError } from '../../utils/error-utils'
import { hasDocumentSigning } from '../../utils/feature-utils'
import { formatDocumentSignatureType, formatLanguage } from '../../utils/format-utils'
import { isDepartmentRestricted } from '../../utils/permissions-utils'
import { RequestError, secureUrl, url } from '../../utils/request-utils'
import { escapeRegExp } from '../../utils/string-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Select from '../antd/select'
import Table from '../antd/table'
import UploadDragger from '../antd/upload/Dragger'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Row from '../elements/grid/row'
import Icon from '../elements/Icon'
import Input from '../elements/input'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import DocumentEdit from '../employees-single/documents/DocumentEdit'
import DumbLink from '../widgets/DumbLink'
import LoadingOverlay from '../widgets/LoadingOverlay'
import AttachDocument from './AttachDocument'
import CompanyDocuments from './CompanyDocuments'
import SigningDocumentEdit from './SigningDocumentEdit'

import './Documents.css'

type Props = {
  subsection?: string
  alerts: AlertReducer
  company: Company
  companyFeatures: List<CompanyFeature>
  companyUser?: CompanyUser
  documents: DocumentReducer
  documentCategories: List<DocumentCategory>
  employees: List<Employee>
  companyPricings: List<CompanyPricing>

  addAlert: addAlertSignature
  addDocument: (companyID: string, document: DocumentCreationFields) => Promise<Document | void>
  updateDocument: (companyID: string, document: DocumentMutableFields) => Promise<Document | void>
  addSignerToDocument: (document: Document, email: string, name: string, language: Language) => Promise<Document | void>
  deleteDocument: (documentID: string) => void
}

export default function Documents(props: Props): ReactElement | null {
  const [inputSearchQuery, setInputSearchQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [inputDocumentCategoryID, setInputDocumentCategoryID] = useState<string>()
  const [documentCategoryID, setDocumentCategoryID] = useState<string>()

  useEffect(() => {
    const searchTimeout = setTimeout(() => setSearchQuery(inputSearchQuery), 100)
    return () => clearTimeout(searchTimeout)
  }, [inputSearchQuery])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearchQuery(e.currentTarget.value.trim())
  }

  useEffect(() => {
    const searchTimeout = setTimeout(() => setDocumentCategoryID(inputDocumentCategoryID), 100)
    return () => clearTimeout(searchTimeout)
  }, [inputDocumentCategoryID])

  const handleSetDocumentCategory = (value?: string) => {
    setInputDocumentCategoryID(value)
  }

  type DocumentState = {
    modalKey: number
    editing: string | boolean
    fileID?: string
  }
  type CreationDocumentState = {
    modalKey: number
    editing: string | boolean
    fileID?: string
  }
  type AttachDocumentState = {
    modalKey: number
    editing: string | null
  }
  const [deleting, setDeleting] = useState<string[]>([])
  const [documentState, setDocumentState] = useState<DocumentState>({ modalKey: 1, editing: false })
  const [signingDocumentState, setSigningDocumentState] = useState<CreationDocumentState>({
    modalKey: 1,
    editing: false,
  })
  const [attachDocumentState, setAttachDocumentState] = useState<AttachDocumentState>({ modalKey: 1, editing: null })
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState<RequestError>()
  const [displayError, setDisplayError] = useState<string>()

  const setEditDocumentVisibility = (id: string | boolean, fileID?: string) => {
    setDocumentState((prev) => ({
      modalKey: prev.modalKey + 1,
      editing: id,
      fileID: fileID,
    }))
  }
  const setEditSigningDocumentVisibility = (id: string | boolean, fileID?: string) => {
    setSigningDocumentState((prev) => ({
      modalKey: prev.modalKey + 1,
      editing: id,
      fileID: fileID,
    }))
  }
  const setAttachDocumentVisibility = (id: string | null) => {
    setAttachDocumentState((prev) => ({
      modalKey: prev.modalKey + 1,
      editing: id,
    }))
  }

  const { documents } = props
  const previousDocuments = usePrevious(documents)
  useEffect(() => {
    if (previousDocuments && previousDocuments.saving && !documents.saving) {
      if (!documents.error) {
        setEditDocumentVisibility(false)
        setEditSigningDocumentVisibility(false)
        setAttachDocumentVisibility(null)
      }
    }
  })

  const archive = (id: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      const document = props.documents.documents.find((document) => document.id === id)
      if (document) {
        if (!document.archived) {
          if (window.confirm(t('documents.confirm.archive'))) {
            props.updateDocument(props.company.id, { ...document, archived: true })
          }
        } else {
          props.updateDocument(props.company.id, { ...document, archived: false })
        }
      }
    }
  }

  const remove = (id: string) => {
    return (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      if (window.confirm(t('documents.confirm.remove'))) {
        setDeleting((prev) => [...prev, id])
        props.deleteDocument(id)
      }
    }
  }

  const handleFileUpload = (e: FileChangeEvent, isSigning: boolean) => {
    switch (e.file.status) {
      case 'uploading':
        setError(undefined)
        setDisplayError(undefined)
        setUploading(true)
        break
      case 'done':
        setUploading(false)
        if (isSigning) {
          if (e.file.response.data?.mimeType !== 'application/pdf') {
            setDisplayError(t('documents.error.must_be_pdf'))
          } else {
            setEditSigningDocumentVisibility(true, e.file.response.data?.id)
          }
        } else {
          setEditDocumentVisibility(true, e.file.response.data?.id)
        }
        break
      case 'error': {
        setUploading(false)
        setError(HandleFileEventError(e.file.response))
        break
      }
      default:
        break
    }
  }

  const handleSigningFileUpload = (e: FileChangeEvent) => {
    handleFileUpload(e, true)
  }

  const handleEmployeeFileUpload = (e: FileChangeEvent) => {
    handleFileUpload(e, false)
  }

  const showHistory = props.subsection === 'archive'

  type SigningDocumentSigner = {
    language: string
    name: string
    signed: boolean
    phoneNumber?: string
    signedAt?: string
  }

  type SigningDocumentRow = {
    key: string
    id: string
    signers: SigningDocumentSigner[]
    category: string
    filename: string
    name: string
  }
  const signingDocumentColumns = [
    {
      title: t('documents.table.header.name'),
      dataIndex: '',
      key: 'xName',
      className: 'document-table-name',
      render: (document: SigningDocumentRow) => {
        return (
          <div>
            {document.name}
            <div className="document-table-filename">{document.filename}</div>
          </div>
        )
      },
    },
    { title: t('documents.table.header.category'), dataIndex: 'category', key: 'category' },
    {
      title: t('documents.table.header.signer'),
      dataIndex: '',
      key: 'xSigner',
      className: 'document-table-signer',
      render: (document: SigningDocumentRow) =>
        document.signers.map((signer, i) => {
          return (
            <span key={`signer-${i}`}>
              {i > 0 && <br />}
              {signer.name}
              {signer.phoneNumber ? ' (' + signer.phoneNumber + ')' : ''}
            </span>
          )
        }),
    },
    {
      title: t('documents.table.header.signed'),
      dataIndex: '',
      key: 'xSigned',
      className: 'document-table-signed',
      render: (document: SigningDocumentRow) => {
        return (
          <span>
            {document.signers.map((signer, i) => {
              return (
                <span key={`signed-${i}`}>
                  {i > 0 && <br />}
                  {signer.signed ? t('documents.table.signed.true') : t('documents.table.signed.false')}
                  {signer.signedAt ? ', ' + signer.signedAt : ''}
                </span>
              )
            })}
          </span>
        )
      },
    },
    {
      title: '',
      key: 'x2',
      className: 'employee-table-actions',
      render: (document: SigningDocumentRow) => {
        if (deleting.indexOf(document.id) !== -1) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('documents.table.actions.download')}>
              <a
                href={secureUrl('v2/documentDownload/' + document.id + '?disposition=attachment')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="download" color="lightgrey" />
              </a>
            </Tooltip>
            <Tooltip title={t('documents.table.actions.edit')}>
              <span onClick={() => setEditSigningDocumentVisibility(document.id)} style={{ cursor: 'pointer' }}>
                <Icon type="edit" color="lightgrey" />
              </span>
            </Tooltip>
            <Tooltip title={t('documents.table.actions.delete')}>
              <span onClick={remove(document.id)} style={{ cursor: 'pointer' }}>
                <Icon type="cross" color="grey" />
              </span>
            </Tooltip>
            {!document.signers.some((signer) => !signer.signed) && (
              <>
                <br />
                <DumbLink onClick={() => setAttachDocumentVisibility(document.id)}>
                  {t('documents.table.actions.attach')}
                </DumbLink>
                <br />
                <Link to={'/' + paths.EMPLOYEES + '/' + paths.ADD + '?documentID=' + document.id}>
                  {t('documents.table.actions.attach_create')}
                </Link>
              </>
            )}
          </div>
        )
      },
    },
  ]

  type EmployeeDocumentRow = {
    key: string
    id: string
    employee: string
    employeeID: string
    category: string
    filename: string
    name: string
    visibleForEmployee: string
    archived: boolean
    createdAt: string
    signed: boolean
    signedAt?: string
    signedWith: string
  }
  const employeeDocumentColumns = [
    {
      title: t('documents.employee_table.header.employee'),
      dataIndex: '',
      key: 'xEmployee',
      render: (document: EmployeeDocumentRow) => {
        return (
          <Link to={'/' + paths.EMPLOYEES + '/' + document.employeeID + '/' + paths.DOCUMENTS}>
            {document.employee}
          </Link>
        )
      },
    },
    {
      title: t('documents.employee_table.header.filename'),
      key: 'x1',
      render: (document: EmployeeDocumentRow) => {
        return (
          <div>
            <Tooltip title={document.filename}>
              <span className="table-overflow-ellipsis">{document.filename}</span>
            </Tooltip>
            {document.signed && (
              <Tooltip
                title={t('documents.employee_table.signed', {
                  signed_at: document.signedAt,
                  signed_with: document.signedWith,
                })}
              >
                <span className="document-signed-note">
                  <Icon type="signed" style={{ position: 'relative', top: '5px' }} />
                </span>
              </Tooltip>
            )}
            <small className="small-note">
              {t('documents.employee_table.created_at', { date: document.createdAt })}
            </small>
          </div>
        )
      },
    },
    { title: t('documents.employee_table.header.name'), dataIndex: 'name', key: 'name' },
    { title: t('documents.employee_table.header.category'), dataIndex: 'category', key: 'category' },
    {
      title: t('documents.employee_table.header.visible_for_employee'),
      dataIndex: 'visibleForEmployee',
      key: 'visibleForEmployee',
    },
    {
      title: '',
      key: 'x2',
      className: 'employee-table-actions',
      render: (document: EmployeeDocumentRow) => {
        if (deleting.indexOf(document.id) !== -1) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('documents.employee_table.actions.download')}>
              <a
                href={secureUrl('v2/documentDownload/' + document.id + '?disposition=attachment')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="download" color="lightgrey" />
              </a>
            </Tooltip>
            <Tooltip title={t('documents.employee_table.actions.edit')}>
              <span onClick={() => setEditDocumentVisibility(document.id)} style={{ cursor: 'pointer' }}>
                <Icon type="edit" color="lightgrey" />
              </span>
            </Tooltip>
            <Tooltip
              title={
                document.archived
                  ? t('documents.employee_table.actions.un_archive')
                  : t('documents.employee_table.actions.archive')
              }
            >
              <span onClick={archive(document.id)} style={{ cursor: 'pointer' }}>
                <Icon type={document.archived ? 'refresh' : 'archive'} color="lightgrey" />
              </span>
            </Tooltip>
            <Tooltip title={t('documents.employee_table.actions.delete')}>
              <span onClick={remove(document.id)} style={{ cursor: 'pointer' }}>
                <Icon type="cross" color="grey" />
              </span>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const getSigningDocuments = (): SigningDocumentRow[] => {
    return props.documents.documents
      .filter((document) => !document.employeeID && document.signatureType !== 'None')
      .map((document) => {
        let category = t('common.unknown')
        const documentCategory = props.documentCategories.find(
          (documentCategory) => documentCategory.id === document.documentCategoryID
        )
        if (documentCategory) {
          category = documentCategory.name
        }
        const row: SigningDocumentRow = {
          id: document.id,
          key: document.id,
          category,
          filename: document.filename,
          name: document.name,
          signers: document.signers.map((signer) => ({
            ...signer,
            signed: signer.state === 'Signed',
            signedAt: signer.signedAt ? formatDate(signer.signedAt) : undefined,
            phoneNumber:
              signer.phoneNumber && signer.phoneNumberCountryCode
                ? '+' + signer.phoneNumberCountryCode + ' ' + signer.phoneNumber
                : undefined,
            language: formatLanguage(signer.language),
          })),
        }
        return row
      })
      .toArray()
  }

  const getEmployeeDocuments = (): EmployeeDocumentRow[] => {
    return props.documents.documents
      .filter((document) => {
        if (!document.employeeID) {
          return false
        }
        if (document.archived !== showHistory) {
          return false
        }
        if (showHistory) {
          return true // ignore search
        }
        if (searchQuery) {
          const pattern = new RegExp(escapeRegExp(searchQuery), 'i')
          if (!pattern.test(document.name) && !pattern.test(document.filename)) {
            return false
          }
        }
        if (documentCategoryID) {
          if (document.documentCategoryID !== documentCategoryID) {
            return false
          }
        }
        return true
      })
      .map((document) => {
        let category = t('common.unknown')
        const documentCategory = props.documentCategories.find(
          (documentCategory) => documentCategory.id === document.documentCategoryID
        )
        if (documentCategory) {
          category = documentCategory.name
        }
        const signedAt = document.signers.reduce((date: DateTimeFormat | undefined, signer) => {
          if (signer.state !== 'Signed') {
            return date
          }
          if (!date) {
            return signer.signedAt
          }
          if (!signer.signedAt) {
            return date
          }
          if (date.localeCompare(signer.signedAt) < 0) {
            return signer.signedAt
          }
          return date
        }, undefined)
        const row: EmployeeDocumentRow = {
          id: document.id,
          key: document.id,
          employeeID: '',
          employee: '',
          category,
          filename: document.filename,
          name: document.name,
          visibleForEmployee: document.visibleForEmployee
            ? t('documents.employee_table.visible_for_employee.true')
            : t('documents.employee_table.visible_for_employee.false'),
          archived: document.archived,
          createdAt: formatDate(document.createdAt),
          signed: !!signedAt,
          signedAt: signedAt ? formatDate(signedAt) : undefined,
          signedWith: formatDocumentSignatureType(document.signatureType),
        }
        if (document.employeeID) {
          const employee = props.employees.find((employee) => employee.id === document.employeeID)
          if (employee) {
            row.employeeID = employee.id
            row.employee = employee.name || employee.email || '-'
          }
        }
        return row
      })
      .toArray()
  }

  if (props.subsection === 'templates') {
    return (
      <CompanyDocuments
        alerts={props.alerts}
        company={props.company}
        documents={props.documents}
        documentCategories={props.documentCategories}
        addAlert={props.addAlert}
        addDocument={props.addDocument}
        updateDocument={props.updateDocument}
        deleteDocument={props.deleteDocument}
      />
    )
  }

  const onlyDepartments = isDepartmentRestricted(props.companyUser)

  const employee = props.employees.find(
    (employee) =>
      employee.id === props.documents.documents.find((document) => document.id === documentState.editing)?.employeeID
  )

  const hasDocumentSigningFeature =
    !onlyDepartments &&
    hasDocumentSigning() &&
    props.companyFeatures.some((feature) => feature.featureType === 'Document Signing')

  return (
    <div className="documents">
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      {displayError && (
        <Alert
          message={displayError}
          type="error"
          showIcon
          closable
          onClose={() => {
            setDisplayError(undefined)
          }}
        />
      )}

      {hasDocumentSigningFeature && (
        <div>
          <TitleMenu>
            <Link to={'/' + paths.DOCUMENTS + '/templates'}>
              <Button>{t('documents.header.templates')}</Button>
            </Link>
            {!showHistory && (
              <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/document-categories'}>
                <Button>{t('documents.header.categories')}</Button>
              </Link>
            )}
            {!showHistory && (
              <div style={{ float: 'right', marginLeft: '20px' }}>
                <UploadDragger
                  name={'fileData'}
                  action={url('v2/stagedFiles')}
                  headers={{ authorization: getAccessToken() }}
                  accept={'.pdf'}
                  showUploadList={false}
                  onChange={handleSigningFileUpload}
                >
                  <Button type="primary" className="ant-btn-primary gtm-upload-document">
                    <Icon type="file" color="white" />
                    {t('documents.header.new_signing_document')}
                  </Button>
                </UploadDragger>
              </div>
            )}
          </TitleMenu>

          {!showHistory && <Title>{t('documents.signing_title')}</Title>}

          {!showHistory && (
            <Table columns={signingDocumentColumns} dataSource={getSigningDocuments()} pagination={false} />
          )}
        </div>
      )}

      <TitleMenu style={{ minWidth: '800px', textAlign: 'right' }}>
        <Row style={{ marginRight: 0, marginLeft: 0 }}>
          <span>
            <Link to={'/' + paths.DOCUMENTS + (showHistory ? '' : '/archive')}>
              <Button>{showHistory ? t('documents.header.hide_history') : t('documents.header.show_history')}</Button>
            </Link>
          </span>
          {!showHistory && (
            <>
              <Input.Search
                placeholder={t('documents.header.search')}
                value={inputSearchQuery}
                onChange={handleSearch}
              />
              <Select
                dropdownMatchSelectWidth={false}
                allowClear
                onChange={handleSetDocumentCategory}
                placeholder={t('documents.header.category_select.placeholder')}
                style={{ maxWidth: '200px', padding: 0 }}
                value={inputDocumentCategoryID}
              >
                {props.documentCategories.map((category) => {
                  return (
                    <Select.Option key={category.id} value={category.id}>
                      {category.name}
                    </Select.Option>
                  )
                })}
              </Select>
              <div style={{ float: 'right', marginLeft: '20px' }}>
                <UploadDragger
                  name={'fileData'}
                  action={url('v2/stagedFiles')}
                  headers={{ authorization: getAccessToken() }}
                  showUploadList={false}
                  onChange={handleEmployeeFileUpload}
                >
                  <Button type="primary" className="ant-btn-primary gtm-upload-document">
                    <Icon type="file" color="white" />
                    {t('documents.header.new_document')}
                  </Button>
                </UploadDragger>
              </div>
            </>
          )}
        </Row>
      </TitleMenu>

      <Title>{showHistory ? t('documents.title.history') : t('documents.title.standard')}</Title>

      <Table columns={employeeDocumentColumns} dataSource={getEmployeeDocuments()} pagination={false} />

      <Modal
        key={`document-${documentState.modalKey}`}
        visible={documentState.editing !== false}
        onOk={() => setEditDocumentVisibility(false)}
        onCancel={() => setEditDocumentVisibility(false)}
        width={582}
        footer={null}
      >
        <DocumentEdit
          visible={documentState.editing !== false}
          company={props.company}
          employee={employee}
          employees={typeof documentState.editing === 'string' ? undefined : props.employees}
          documentID={typeof documentState.editing === 'string' ? documentState.editing : undefined}
          fileID={documentState.fileID}
          documents={props.documents}
          documentCategories={props.documentCategories}
          addDocument={props.addDocument}
          updateDocument={props.updateDocument}
        />
      </Modal>

      <Modal
        key={`document-signing-${signingDocumentState.modalKey}`}
        visible={signingDocumentState.editing !== false}
        onOk={() => setEditSigningDocumentVisibility(false)}
        onCancel={() => setEditSigningDocumentVisibility(false)}
        width={582}
        footer={null}
      >
        <SigningDocumentEdit
          visible={signingDocumentState.editing !== false}
          company={props.company}
          documentID={typeof signingDocumentState.editing === 'string' ? signingDocumentState.editing : undefined}
          fileID={signingDocumentState.fileID}
          documents={props.documents}
          documentCategories={props.documentCategories}
          companyPricings={props.companyPricings}
          addDocument={props.addDocument}
          updateDocument={props.updateDocument}
          addSignerToDocument={props.addSignerToDocument}
        />
      </Modal>

      <Modal
        key={`attach-document-${attachDocumentState.modalKey}`}
        visible={attachDocumentState.editing !== null}
        onOk={() => setAttachDocumentVisibility(null)}
        onCancel={() => setAttachDocumentVisibility(null)}
        width={582}
        footer={null}
      >
        {typeof attachDocumentState.editing === 'string' && (
          <AttachDocument
            visible={true}
            company={props.company}
            employees={props.employees}
            documentID={attachDocumentState.editing}
            documents={props.documents}
            updateDocument={props.updateDocument}
          />
        )}
      </Modal>

      {uploading && <LoadingOverlay />}
    </div>
  )
}
