import React from 'react'

import {
  delCoarseCarAllowance,
  fetchCoarseCarAllowances,
  postCoarseCarAllowance,
  putCoarseCarAllowance,
} from '../api/coarse-car-allowances'
import ActionTypes from '../constants/action-types'
import CoarseCarAllowance, {
  CoarseCarAllowanceCreationFields,
  CoarseCarAllowanceMutableFields,
} from '../model/coarseCarAllowance'
import { CoarseCarAllowanceAction } from '../reducers/coarseCarAllowances'
import { isRequestError } from '../utils/error-utils'

function loadingCoarseCarAllowances(
  companyID: string | undefined,
  salaryPeriodIDs: string[] | undefined,
  employeeID: string | undefined
): CoarseCarAllowanceAction {
  return {
    type: ActionTypes.COARSE_CAR_ALLOWANCE_LOADING,
    companyID,
    salaryPeriodIDs,
    employeeID,
  }
}
function loadedCoarseCarAllowances(
  companyID: string | undefined,
  salaryPeriodIDs: string[] | undefined,
  employeeID: string | undefined,
  coarseCarAllowances: CoarseCarAllowance[]
): CoarseCarAllowanceAction {
  return {
    type: ActionTypes.COARSE_CAR_ALLOWANCE_LOADED,
    companyID,
    salaryPeriodIDs,
    employeeID,
    coarseCarAllowances,
  }
}
function failedLoadingCoarseCarAllowances(
  companyID: string | undefined,
  salaryPeriodIDs: string[] | undefined,
  employeeID: string | undefined,
  error: Error
): CoarseCarAllowanceAction {
  return {
    type: ActionTypes.COARSE_CAR_ALLOWANCE_LOAD_FAILED,
    companyID,
    salaryPeriodIDs,
    employeeID,
    error,
  }
}

function creatingCoarseCarAllowance(employeeID: string): CoarseCarAllowanceAction {
  return {
    type: ActionTypes.COARSE_CAR_ALLOWANCE_CREATING,
    employeeID,
  }
}
export function createdCoarseCarAllowance(
  employeeID: string,
  coarseCarAllowance: CoarseCarAllowance
): CoarseCarAllowanceAction {
  return {
    type: ActionTypes.COARSE_CAR_ALLOWANCE_CREATED,
    employeeID,
    coarseCarAllowance,
  }
}
function failedCreatingCoarseCarAllowance(employeeID: string, error: Error): CoarseCarAllowanceAction {
  return {
    type: ActionTypes.COARSE_CAR_ALLOWANCE_CREATE_FAILED,
    error,
    employeeID,
  }
}

function updatingCoarseCarAllowance(employeeID: string, coarseCarAllowanceID: string): CoarseCarAllowanceAction {
  return {
    type: ActionTypes.COARSE_CAR_ALLOWANCE_UPDATING,
    employeeID,
    coarseCarAllowanceID,
  }
}
export function updatedCoarseCarAllowance(
  employeeID: string,
  coarseCarAllowanceID: string,
  coarseCarAllowance: CoarseCarAllowance
): CoarseCarAllowanceAction {
  return {
    type: ActionTypes.COARSE_CAR_ALLOWANCE_UPDATED,
    employeeID,
    coarseCarAllowanceID,
    coarseCarAllowance,
  }
}
function failedUpdatingCoarseCarAllowance(
  employeeID: string,
  coarseCarAllowanceID: string,
  error: Error
): CoarseCarAllowanceAction {
  return {
    type: ActionTypes.COARSE_CAR_ALLOWANCE_UPDATE_FAILED,
    error,
    employeeID,
    coarseCarAllowanceID,
  }
}

function deletingCoarseCarAllowance(coarseCarAllowanceID: string): CoarseCarAllowanceAction {
  return {
    type: ActionTypes.COARSE_CAR_ALLOWANCE_DELETING,
    coarseCarAllowanceID,
  }
}
export function deletedCoarseCarAllowance(coarseCarAllowanceID: string): CoarseCarAllowanceAction {
  return {
    type: ActionTypes.COARSE_CAR_ALLOWANCE_DELETED,
    coarseCarAllowanceID,
  }
}
function failedDeletingCoarseCarAllowance(coarseCarAllowanceID: string, error: Error): CoarseCarAllowanceAction {
  return {
    type: ActionTypes.COARSE_CAR_ALLOWANCE_DELETE_FAILED,
    error,
    coarseCarAllowanceID,
  }
}

export function getCoarseCarAllowances(companyID?: string, salaryPeriodIDs?: string[], employeeID?: string) {
  return (dispatch: React.Dispatch<any>): Promise<CoarseCarAllowance[] | void> => {
    dispatch(loadingCoarseCarAllowances(companyID, salaryPeriodIDs, employeeID))
    return fetchCoarseCarAllowances(companyID, salaryPeriodIDs, employeeID)
      .then((res) => {
        dispatch(loadedCoarseCarAllowances(companyID, salaryPeriodIDs, employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCoarseCarAllowances(companyID, salaryPeriodIDs, employeeID, e))
        }
      })
  }
}

export function createCoarseCarAllowance(coarseCarAllowance: CoarseCarAllowanceCreationFields) {
  return (dispatch: React.Dispatch<any>): Promise<CoarseCarAllowance | void> => {
    dispatch(creatingCoarseCarAllowance(coarseCarAllowance.employeeID))
    return postCoarseCarAllowance(coarseCarAllowance.employeeID, coarseCarAllowance)
      .then((res) => {
        dispatch(createdCoarseCarAllowance(coarseCarAllowance.employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingCoarseCarAllowance(coarseCarAllowance.employeeID, e))
        }
      })
  }
}

export function updateCoarseCarAllowance(coarseCarAllowance: CoarseCarAllowanceMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<CoarseCarAllowance | void> => {
    dispatch(updatingCoarseCarAllowance(coarseCarAllowance.employeeID, coarseCarAllowance.id))
    return putCoarseCarAllowance(coarseCarAllowance.id, coarseCarAllowance)
      .then((res) => {
        dispatch(updatedCoarseCarAllowance(coarseCarAllowance.employeeID, coarseCarAllowance.id, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCoarseCarAllowance(coarseCarAllowance.employeeID, coarseCarAllowance.id, e))
        }
      })
  }
}

export function deleteCoarseCarAllowance(coarseCarAllowance: CoarseCarAllowance) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(deletingCoarseCarAllowance(coarseCarAllowance.id))
    return delCoarseCarAllowance(coarseCarAllowance.id)
      .then(() => {
        dispatch(deletedCoarseCarAllowance(coarseCarAllowance.id))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingCoarseCarAllowance(coarseCarAllowance.id, e))
        }
      })
  }
}
