import React from 'react'

import { fetchVacationCalendar, postVacationCalendar } from '../api/vacation-calendars'
import ActionTypes from '../constants/action-types'
import VacationCalendar from '../model/vacationCalendar'
import { VacationCalendarAction } from '../reducers/vacationCalendars'
import { isRequestError } from '../utils/error-utils'

function loadingVacationCalendar(companyID: string): VacationCalendarAction {
  return {
    type: ActionTypes.VACATION_CALENDAR_LOADING,
    companyID,
  }
}
function loadedVacationCalendar(companyID: string, vacationCalendar: VacationCalendar): VacationCalendarAction {
  return {
    type: ActionTypes.VACATION_CALENDAR_LOADED,
    companyID,
    vacationCalendar,
  }
}
function failedLoadingVacationCalendar(companyID: string, error: Error): VacationCalendarAction {
  return {
    type: ActionTypes.VACATION_CALENDAR_LOAD_FAILED,
    companyID,
    error,
  }
}

function loadingVacationCalendarYear(companyID: string, year: number): VacationCalendarAction {
  return {
    type: ActionTypes.VACATION_CALENDAR_YEAR_LOADING,
    companyID,
    year,
  }
}
function loadedVacationCalendarYear(
  companyID: string,
  year: number,
  vacationCalendar: VacationCalendar
): VacationCalendarAction {
  return {
    type: ActionTypes.VACATION_CALENDAR_YEAR_LOADED,
    companyID,
    year,
    vacationCalendar,
  }
}
function failedLoadingVacationCalendarYear(companyID: string, year: number, error: Error): VacationCalendarAction {
  return {
    type: ActionTypes.VACATION_CALENDAR_YEAR_LOAD_FAILED,
    companyID,
    year,
    error,
  }
}

function updatingVacationCalendar(companyID: string): VacationCalendarAction {
  return {
    type: ActionTypes.VACATION_CALENDAR_UPDATING,
    companyID,
  }
}
function updatedVacationCalendar(companyID: string, vacationCalendar: VacationCalendar): VacationCalendarAction {
  return {
    type: ActionTypes.VACATION_CALENDAR_UPDATED,
    companyID,
    vacationCalendar,
  }
}
function failedUpdatingVacationCalendar(companyID: string, error: Error): VacationCalendarAction {
  return {
    type: ActionTypes.VACATION_CALENDAR_UPDATE_FAILED,
    companyID,
    error,
  }
}

export function getVacationCalendar(companyID: string) {
  return (dispatch: React.Dispatch<any>): Promise<VacationCalendar | void> => {
    dispatch(loadingVacationCalendar(companyID))
    return fetchVacationCalendar(companyID)
      .then((res) => {
        dispatch(loadedVacationCalendar(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingVacationCalendar(companyID, e))
        }
      })
  }
}

export function getVacationCalendarYear(companyID: string, year: number) {
  return (dispatch: React.Dispatch<any>): Promise<VacationCalendar | void> => {
    dispatch(loadingVacationCalendarYear(companyID, year))
    return fetchVacationCalendar(companyID, year)
      .then((res) => {
        dispatch(loadedVacationCalendarYear(companyID, year, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingVacationCalendarYear(companyID, year, e))
        }
      })
  }
}

export function updateVacationCalendar(companyID: string, vacationCalendar: VacationCalendar) {
  return (dispatch: React.Dispatch<any>): Promise<VacationCalendar | void> => {
    dispatch(updatingVacationCalendar(companyID))
    return postVacationCalendar(companyID, vacationCalendar)
      .then((res) => {
        dispatch(updatedVacationCalendar(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingVacationCalendar(companyID, e))
        }
      })
  }
}
