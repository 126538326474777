import React from 'react'

import { fetchTaxCardRequests, fetchTaxCards, patchTaxCard, postTaxCardRequest } from '../api/tax-cards'
import ActionTypes from '../constants/action-types'
import TaxCard from '../model/taxCard'
import TaxCardRequest from '../model/taxCardRequest'
import { TaxCardRequestAction } from '../reducers/taxCardRequests'
import { TaxCardAction } from '../reducers/taxCards'
import { isRequestError } from '../utils/error-utils'
import { handlePagination } from './pagination'

function loadingTaxCards(companyID: string | undefined, employeeID: string | undefined): TaxCardAction {
  return {
    type: ActionTypes.TAX_CARD_LOADING,
    companyID,
    employeeID,
  }
}
function loadedTaxCards(
  companyID: string | undefined,
  employeeID: string | undefined,
  taxCards: TaxCard[],
  partial = false
): TaxCardAction {
  return {
    type: partial ? ActionTypes.TAX_CARD_LOADED_PARTIAL : ActionTypes.TAX_CARD_LOADED,
    taxCards,
    companyID,
    employeeID,
  }
}
function failedLoadingTaxCards(
  companyID: string | undefined,
  employeeID: string | undefined,
  error: Error
): TaxCardAction {
  return {
    type: ActionTypes.TAX_CARD_LOAD_FAILED,
    error,
    companyID,
    employeeID,
  }
}

function updatingTaxCard(): TaxCardAction {
  return {
    type: ActionTypes.TAX_CARD_UPDATING,
  }
}
export function updatedTaxCard(taxCard: TaxCard | undefined): TaxCardAction {
  return {
    type: ActionTypes.TAX_CARD_UPDATED,
    taxCard,
  }
}
function failedUpdatingTaxCard(error: Error): TaxCardAction {
  return {
    type: ActionTypes.TAX_CARD_UPDATE_FAILED,
    error,
  }
}

export function addedTaxCard(taxCard: TaxCard | undefined): TaxCardAction {
  return {
    type: ActionTypes.TAX_CARD_ADDED,
    taxCard,
  }
}

function loadingTaxCardRequests(employeeID: string): TaxCardRequestAction {
  return {
    type: ActionTypes.TAX_CARD_REQUEST_LOADING,
    employeeID,
  }
}
function loadedTaxCardRequests(
  employeeID: string,
  taxCardRequests: TaxCardRequest[],
  partial = false
): TaxCardRequestAction {
  return {
    type: partial ? ActionTypes.TAX_CARD_REQUEST_LOADED_PARTIAL : ActionTypes.TAX_CARD_REQUEST_LOADED,
    taxCardRequests,
    employeeID,
  }
}
function failedLoadingTaxCardRequests(employeeID: string, error: Error): TaxCardRequestAction {
  return {
    type: ActionTypes.TAX_CARD_REQUEST_LOAD_FAILED,
    error,
    employeeID,
  }
}

function addingTaxCardRequest(): TaxCardRequestAction {
  return {
    type: ActionTypes.TAX_CARD_REQUEST_ADDING,
  }
}
export function addedTaxCardRequest(employeeID: string, taxCardRequest?: TaxCardRequest): TaxCardRequestAction {
  return {
    type: ActionTypes.TAX_CARD_REQUEST_ADDED,
    employeeID,
    taxCardRequest,
  }
}
function failedAddingTaxCardRequest(employeeID: string, error: Error): TaxCardRequestAction {
  return {
    type: ActionTypes.TAX_CARD_REQUEST_ADD_FAILED,
    error,
    employeeID,
  }
}

export function getTaxCards(companyID?: string, employeeID?: string, offset?: number) {
  return (dispatch: React.Dispatch<any>): Promise<TaxCard[]> => {
    if (!offset) {
      dispatch(loadingTaxCards(companyID, employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchTaxCards(companyID, employeeID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedTaxCards(companyID, employeeID, data)),
          (data) => dispatch(loadedTaxCards(companyID, employeeID, data, true)),
          (offset) => dispatch(getTaxCards(companyID, employeeID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingTaxCards(companyID, employeeID, e))
          return e
        }
        return null
      })
  }
}

export type TaxCardUpdate = {
  id: string
  validFrom: string
}

export function updateTaxCard(taxCard: TaxCardUpdate) {
  return (dispatch: React.Dispatch<any>): Promise<TaxCard> => {
    dispatch(updatingTaxCard())
    return patchTaxCard(taxCard)
      .then((res) => {
        dispatch(updatedTaxCard(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingTaxCard(e))
          return e
        }
        return null
      })
  }
}

export function getTaxCardRequests(employeeID: string, offset?: number) {
  return (dispatch: React.Dispatch<any>): Promise<TaxCardRequest[]> => {
    if (!offset) {
      dispatch(loadingTaxCardRequests(employeeID))
      offset = 0
    }
    const limit = 1000
    return fetchTaxCardRequests(employeeID, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedTaxCardRequests(employeeID, data)),
          (data) => dispatch(loadedTaxCardRequests(employeeID, data, true)),
          (offset) => dispatch(getTaxCardRequests(employeeID, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingTaxCardRequests(employeeID, e))
          return e
        }
        return null
      })
  }
}

export function addTaxCardRequest(employeeID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(addingTaxCardRequest())
    return postTaxCardRequest(employeeID, 'ReRequest')
      .then((res) => {
        dispatch(addedTaxCardRequest(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedAddingTaxCardRequest(employeeID, e))
          return e
        }
        return null
      })
  }
}
