import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { addAlertSignature } from '../../actions/alerts'
import CoarseCarAllowance, {
  CoarseCarAllowanceCreationFields,
  CoarseCarAllowanceMutableFields,
} from '../../model/coarseCarAllowance'
import Employee from '../../model/employee'
import SalaryCycle from '../../model/salaryCycle'
import { CoarseCarAllowanceReducer } from '../../reducers/coarseCarAllowances'
import { getSalaryCycle } from '../../utils/salary-cycle-utils'
import { t } from '../../utils/translation-utils'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import CoarseCarAllowanceTab from '../employees-single/car-allowance/CoarseCarAllowanceTab'

type Props = {
  visible: boolean
  employee?: Employee
  coarseCarAllowances: CoarseCarAllowanceReducer
  salaryCycles: List<SalaryCycle>

  addAlert: addAlertSignature
  createCoarseCarAllowance: (carAllowance: CoarseCarAllowanceCreationFields) => void
  updateCoarseCarAllowance: (carAllowance: CoarseCarAllowanceMutableFields) => void
  deleteCoarseCarAllowance: (coarseCarAllowance: CoarseCarAllowance) => void
}

export default function CoarseCarAllowanceModal(props: Props): ReactElement | null {
  if (!props.employee || !props.employee.activeContract) {
    return null
  }
  const salaryCycle = getSalaryCycle(props.salaryCycles.toArray(), props.employee.activeContract.salaryCycleID)
  if (!salaryCycle) {
    return null
  }
  return (
    <Card className="time-registration-coarse">
      <Subtitle>{t('car_allowances.coarse', { name: props.employee.name })}</Subtitle>
      <p>&nbsp;</p>
      <CoarseCarAllowanceTab
        preventPeriodChange={true}
        employee={props.employee}
        coarseCarAllowances={props.coarseCarAllowances}
        salaryCycles={props.salaryCycles}
        salaryCycle={salaryCycle}
        addAlert={props.addAlert}
        createCoarseCarAllowance={props.createCoarseCarAllowance}
        updateCoarseCarAllowance={props.updateCoarseCarAllowance}
        deleteCoarseCarAllowance={props.deleteCoarseCarAllowance}
      />
    </Card>
  )
}
