import React, { ReactElement } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import LoanServiceComponent from '../components/loan-service/LoanService'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import IntegrationsLayout from './layouts/IntegrationsLayout'

type Reducers = {
  alerts: AlertReducer
  companies: CompanyReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
}

function LoanService(props: Reducers & Actions & RouteProps): ReactElement | null {
  if (!props.companies.company) {
    jsBrowserHistory.push('/')
    return null
  }
  return (
    <IntegrationsLayout location={props.location} className={'loan-service'}>
      <LoanServiceComponent
        company={props.companies.company}
        alerts={props.alerts}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
      />
    </IntegrationsLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
  }
)(LoanService)
