import { OptGroup, Option } from 'rc-select'
import React from 'react'

import classNames from '../_util/classNames'
import Input from '../../elements/input'
import Select from '../select'
import InputElement from './InputElement'

import './style/css'

function isSelectOptionOrSelectOptGroup(child) {
  return child && child.type && (child.type.isSelectOption || child.type.isSelectOptGroup)
}

class AutoComplete extends React.Component {
  getInputElement = () => {
    const { children } = this.props
    const element =
      children && React.isValidElement(children) && children.type !== Option ? (
        React.Children.only(this.props.children)
      ) : (
        <Input selectAllOnFocus={false} id={this.props.inputID} placeholder="" />
      )
    const elementProps = { ...element.props }
    // https://github.com/ant-design/ant-design/pull/7742
    delete elementProps.children
    return <InputElement {...elementProps}>{element}</InputElement>
  }

  render() {
    let { size, className = '', notFoundContent, prefixCls, optionLabelProp, dataSource, children } = this.props

    const cls = classNames({
      [`${prefixCls}-lg`]: size === 'large',
      [`${prefixCls}-sm`]: size === 'small',
      [className]: !!className,
      [`${prefixCls}-show-search`]: true,
      [`${prefixCls}-auto-complete`]: true,
    })

    let options
    const childArray = React.Children.toArray(children)
    if (childArray.length && isSelectOptionOrSelectOptGroup(childArray[0])) {
      options = children
    } else {
      options = dataSource
        ? dataSource.map((item) => {
            if (React.isValidElement(item)) {
              return item
            }
            switch (typeof item) {
              case 'string':
                return <Option key={item}>{item}</Option>
              case 'object':
                return <Option key={item.value}>{item.text}</Option>
              default:
                throw new Error('AutoComplete[dataSource] only supports type `string[] | Object[]`.')
            }
          })
        : []
    }

    return (
      <Select
        {...this.props}
        className={cls}
        mode="combobox"
        optionLabelProp={optionLabelProp}
        getInputElement={this.getInputElement}
        notFoundContent={notFoundContent}
      >
        {options}
      </Select>
    )
  }
}

AutoComplete.Option = Option
AutoComplete.OptGroup = OptGroup

AutoComplete.defaultProps = {
  prefixCls: 'ant-select',
  transitionName: 'slide-up',
  optionLabelProp: 'children',
  choiceTransitionName: 'zoom',
  showSearch: false,
  filterOption: false,
}

export default AutoComplete
