import Transfer, { PaymentMethod, TransferState } from '../model/transfer'
import { DateFormat } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchTransfers(
  payRollID: string | undefined,
  companyID: string | undefined,
  fromDate: DateFormat | undefined,
  limit: number,
  offset: number
): Promise<RequestResponse<Transfer[]>> {
  const includeDetails = !!payRollID
  return secureRequest(
    'GET',
    url('/v2/transfers', { limit, offset, payRollID, companyID, includeDetails, from: fromDate })
  )
}

export type TransferPatch = {
  action?: 'tryAgain'
  paymentMethod?: PaymentMethod
  date?: DateFormat
  state?: TransferState
}

export function patchTransfer(transferID: string, body: TransferPatch): Promise<RequestResponse<Transfer>> {
  return secureRequest('PATCH', url('/v2/transfers/' + transferID), { body })
}
