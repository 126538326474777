import { List } from 'immutable'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import { DepartmentCreationFields, DepartmentMutableFields } from '../../model/department'
import Employee from '../../model/employee'
import { AccountingDimensionReducer } from '../../reducers/accountingDimensions'
import { DepartmentReducer } from '../../reducers/departments'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Table from '../antd/table'
import Button from '../elements/button'
import Card from '../elements/card'
import Icon from '../elements/Icon'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import LoadingOverlay from '../widgets/LoadingOverlay'
import DepartmentEdit from './DepartmentEdit'

type Props = {
  departments: DepartmentReducer
  employees: List<Employee>
  accountingDimensions: AccountingDimensionReducer

  addAlert: addAlertSignature
  getDepartments: () => void
  addDepartment: (department: DepartmentCreationFields) => void
  updateDepartment: (department: DepartmentMutableFields) => void
  deleteDepartment: (id: string) => void
  getAccountingDimensions: () => void
}

export default function DepartmentsTab(props: Props): ReactElement | null {
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)

  const { departments, getDepartments } = props

  useEffect(() => {
    if (!departments.loading && !departments.loaded) {
      getDepartments()
    }
  })

  const { accountingDimensions, getAccountingDimensions } = props

  useEffect(() => {
    if (!accountingDimensions.loading && !accountingDimensions.loaded) {
      getAccountingDimensions()
    }
  })

  const setEditVisibility = (id: string | boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditing(id)
  }

  const previousDepartments = usePrevious(departments)

  useEffect(() => {
    // Check for save callback
    if (previousDepartments && previousDepartments.saving && !departments.saving) {
      // Check for no error occurred
      if (!departments.error) {
        // Close edit modal
        setEditVisibility(false)
      }
    }
  })

  const remove = (id: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, id])
        props.deleteDepartment(id)
      }
    }
  }

  type DepartmentRow = {
    key: string
    id: string
    name: string
    dimension?: string
    employeeCount: string
  }

  const showDimensionColumn = accountingDimensions.accountingDimensions.size > 0

  const getColumns = () => {
    const columns: {
      title: string
      dataIndex: string
      key: string
      className?: string
      render?: (department: DepartmentRow) => ReactNode
    }[] = [
      { title: t('departments.table.header.name'), dataIndex: 'name', key: 'name' },
      { title: t('departments.table.header.employee_count'), dataIndex: 'employeeCount', key: 'employeeCount' },
    ]
    if (showDimensionColumn) {
      columns.push({ title: t('departments.table.header.dimension'), dataIndex: 'dimension', key: 'dimension' })
    }
    columns.push({
      title: '',
      dataIndex: '',
      key: 'x1',
      className: 'company-table-actions',
      render: (department: DepartmentRow) => {
        if (deleting.indexOf(department.id) !== -1) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('departments.table.actions.edit')}>
              <span onClick={() => setEditVisibility(department.id)} style={{ cursor: 'pointer' }}>
                <Icon type="edit" color="grey" />
              </span>
            </Tooltip>
            <Tooltip title={t('departments.table.actions.delete')}>
              <span onClick={remove(department.id)} style={{ cursor: 'pointer' }}>
                <Icon type="cross" color="grey" />
              </span>
            </Tooltip>
          </div>
        )
      },
    })
    return columns
  }

  const getDepartmentRows = (): DepartmentRow[] => {
    return props.departments.departments
      .filter((department) => department.active)
      .map((department) => {
        const employeeCount = props.employees.filter((employee) => employee.departmentID === department.id).size
        let dimension
        if (showDimensionColumn && department.costCenterID) {
          const aDimension = accountingDimensions.accountingDimensions.find(
            (dimension) =>
              dimension.costCenterIDs?.some((id) => id === department.costCenterID) ||
              dimension.values.some((value) => value.costCenterIDs?.some((id) => id === department.costCenterID))
          )
          if (aDimension) {
            if (aDimension.costCenterIDs !== undefined) {
              dimension = `${aDimension.dimensionCode} ${aDimension.dimensionName}`
            } else {
              const value = aDimension.values.find((value) =>
                value.costCenterIDs?.some((id) => id === department.costCenterID)
              )
              if (value) {
                dimension = `${value.dimensionValueCode} ${value.dimensionValueName}`
              }
            }
          }
        }
        return {
          key: department.id,
          id: department.id,
          name: department.name,
          dimension,
          employeeCount: t('unit.employee_count', { count: employeeCount }),
        }
      })
      .toArray()
  }

  if (!props.departments.loaded || !props.accountingDimensions.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <Card>
      <TitleMenu>
        <Button type="primary" onClick={() => setEditVisibility(true)}>
          <Icon type="company-add" />
          {t('departments.header.add_department')}
        </Button>
      </TitleMenu>
      <Title>{t('departments.header.title')}</Title>

      <Table columns={getColumns()} dataSource={getDepartmentRows()} size="small" pagination={false} />

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <DepartmentEdit
          visible={editing !== false}
          departmentID={typeof editing === 'string' ? editing : undefined}
          departments={props.departments}
          addDepartment={props.addDepartment}
          updateDepartment={props.updateDepartment}
        />
      </Modal>
    </Card>
  )
}
