import { DateTimeFormat } from './types'

export type ApiKeyScope = 'WriteAll' | 'ReadAll' | 'ReadBaseData' | 'ReadEmployments' | 'ReadLeave'
export const ApiKeyScopes: ApiKeyScope[] = ['ReadAll', 'WriteAll', 'ReadBaseData', 'ReadEmployments', 'ReadLeave']

export type ApiKeyCreationFields = {
  description: string
  scopes: ApiKeyScope[]
}

type ApiKey = ApiKeyCreationFields & {
  id: string
  apiKey: string
  createdAt: DateTimeFormat
}
export default ApiKey
