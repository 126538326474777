import { Record as ImmutableRecord } from 'immutable'

import ActionTypes from '../constants/action-types'
import VacationCalendar from '../model/vacationCalendar'
import { ReducerAction } from '../utils/reducer-utils'

export interface VacationCalendarReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  vacationCalendar: VacationCalendar | undefined
  years: Record<number, VacationCalendar>
  error: Error | null
}

const Params = ImmutableRecord<VacationCalendarReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  vacationCalendar: undefined,
  years: {},
  error: null,
})

export interface VacationCalendarAction extends ReducerAction {
  companyID?: string
  vacationCalendar?: VacationCalendar
  year?: number
}

export default (
  state: ImmutableRecord<VacationCalendarReducer> = Params(),
  action: VacationCalendarAction = { type: '' }
): ImmutableRecord<VacationCalendarReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.VACATION_CALENDAR_LOADING:
      return state.set('loading', true).set('loaded', false).set('companyID', state.get('companyID'))
    case ActionTypes.VACATION_CALENDAR_LOADED:
      if (!action.vacationCalendar) {
        return state
      }
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('companyID', state.get('companyID'))
        .set('vacationCalendar', action.vacationCalendar)
        .set('error', null)
    case ActionTypes.VACATION_CALENDAR_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', state.get('companyID'))
        .set('error', action.error || null)

    case ActionTypes.VACATION_CALENDAR_YEAR_LOADING:
      return state.set('loading', true).set('loaded', false).set('companyID', state.get('companyID'))
    case ActionTypes.VACATION_CALENDAR_YEAR_LOADED: {
      if (!action.vacationCalendar || !action.year) {
        return state
      }
      const years = state.get('years')
      years[action.year] = action.vacationCalendar

      return state
        .set('loading', false)
        .set('loaded', true)
        .set('companyID', state.get('companyID'))
        .set('years', years)
        .set('error', null)
    }
    case ActionTypes.VACATION_CALENDAR_YEAR_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', state.get('companyID'))
        .set('error', action.error || null)

    case ActionTypes.VACATION_CALENDAR_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.VACATION_CALENDAR_UPDATED:
      if (!action.vacationCalendar) {
        return state
      }
      return state.set('saving', false).set('vacationCalendar', action.vacationCalendar)
    case ActionTypes.VACATION_CALENDAR_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
