import React, { ReactElement } from 'react'

import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import Input from '../elements/input'

type Fields = {
  email?: string
}

export type RequestPasswordResult = {
  email: string
}

function RequestPasswordForm(props: FormComponentProps<Fields, RequestPasswordResult>): ReactElement | null {
  const { decorateField } = props
  return (
    <div>
      {decorateField('email', {
        placeholder: t('password_request.form.email'),
        validate: (val) => {
          if (!val) {
            return t('password_request.form.email.required')
          }
          return null
        },
      })(<Input />)}
      <Form.Item>
        <Button htmlType="submit" type="secondary">
          {t('password_request.form.submit')}
        </Button>
      </Form.Item>
    </div>
  )
}

export default withValidations<any, Fields, RequestPasswordResult>({
  onSubmit: (values) => ({
    email: values.email!,
  }),
})(RequestPasswordForm)
