import CompanyGroup, { CompanyGroupMutableFields, CompanyGroupUser } from '../model/companyGroup'
import CompanySetting from '../model/companySetting'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCompanyGroups(): Promise<RequestResponse<CompanyGroup[]>> {
  return secureRequest('GET', url('/v2/companyGroups'))
}

export function putCompanyGroup(
  companyGroupID: string,
  companyGroup: CompanyGroupMutableFields
): Promise<RequestResponse<CompanyGroup>> {
  return secureRequest('PUT', url('/v2/companyGroups/' + companyGroupID), {
    body: {
      name: companyGroup.name,
      defaultPricingPackageID: companyGroup.defaultPricingPackageID,
    },
  })
}

export function postCompanyToCompanyGroup(
  companyGroupID: string,
  companyID: string
): Promise<RequestResponse<CompanyGroup>> {
  return secureRequest('POST', url('/v2/companyGroups/' + companyGroupID + '/companies'), {
    body: {
      companyID,
    },
  })
}

export function deleteCompanyFromCompanyGroup(
  companyGroupID: string,
  companyID: string
): Promise<RequestResponse<CompanyGroup>> {
  return secureRequest('DELETE', url('/v2/companyGroups/' + companyGroupID + '/companies/' + companyID))
}

export function patchCompanyGroupUserSetCompanySettings(
  companyGroupID: string,
  settingsEnable: CompanySetting[],
  settingsDisable: CompanySetting[],
  retroactive: boolean
): Promise<RequestResponse<CompanyGroup>> {
  return secureRequest('PATCH', url('/v2/companyGroups/' + companyGroupID), {
    body: {
      action: 'SetCompanySettings',
      retroactive,
      companySettingsEnable: settingsEnable,
      companySettingsDisable: settingsDisable,
    },
  })
}

export function putCompanyGroupUser(
  companyGroupID: string,
  userID: string,
  companyGroupUser: CompanyGroupUser
): Promise<RequestResponse<CompanyGroupUser>> {
  return secureRequest('PUT', url('/v2/companyGroups/' + companyGroupID + '/users/' + userID), {
    body: {
      userID,
      permissions: companyGroupUser.permissions,
      groupUserType: companyGroupUser.groupUserType,
    },
  })
}

export function deleteCompanyGroupUser(companyGroupID: string, userID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/companyGroups/' + companyGroupID + '/users/' + userID))
}
