import { List } from 'immutable'
import React, { ReactElement } from 'react'

import LeaveType from '../../../model/leaveType'
import Remuneration from '../../../model/remuneration'
import SalaryCycle from '../../../model/salaryCycle'
import { StartBalances } from '../../../model/startBalances'
import { DateFormat } from '../../../model/types'
import { getDate } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { getCurrentPeriod } from '../../../utils/salary-period-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import StartBalanceEditForm, { ResultFields, StartBalanceFields } from './StartBalanceEditForm'

type Props = {
  remuneration: Remuneration
  contractValidFrom: DateFormat
  startBalances: Omit<StartBalances<number>, 'dispositionDate'> & {
    dispositionDate?: DateFormat
  }
  leaveTypes: List<LeaveType>
  salaryCycle: SalaryCycle
  disabled: boolean
}

function StartBalanceFormComponent(
  props: Props & FormComponentProps<StartBalanceFields, ResultFields>
): ReactElement | null {
  return (
    <div>
      {props.getFormError()}
      <StartBalanceEditForm<StartBalanceFields>
        decorateAnyField={props.decorateAnyField}
        getAnyFieldValue={props.getAnyFieldValue}
        contractValidFrom={props.contractValidFrom}
        remuneration={props.remuneration}
        leaveTypes={props.leaveTypes}
        salaryPeriods={props.salaryCycle.salaryPeriods}
        disabled={props.disabled}
      />
      {!props.disabled && (
        <Row>
          <Col span={24}>
            <Button htmlType="submit" size="large" type="secondary">
              {t('form.button.save_changes')}
            </Button>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default withValidations<Props, StartBalanceFields, ResultFields>({
  mapPropsToFields: (props) => {
    let dispositionDate = props.startBalances.dispositionDate
    if (!dispositionDate) {
      const currentPeriod = getCurrentPeriod(props.salaryCycle, getDate(props.contractValidFrom), false)
      if (currentPeriod) {
        dispositionDate = currentPeriod.dispositionDate
      } else {
        dispositionDate = props.contractValidFrom
      }
    }
    const fields: StartBalanceFields = {
      startBalances: {
        dispositionDate: dispositionDate,
        employeePaidPension: formatInputNumber(props.startBalances.employeePaidPension),
        employerPaidPension: formatInputNumber(props.startBalances.employerPaidPension),
        payCheck: formatInputNumber(props.startBalances.payCheck),
        salary: formatInputNumber(props.startBalances.salary),
        workHours: formatInputNumber(props.startBalances.workHours),
        workDays: formatInputNumber(props.startBalances.workDays),
        dkSpecific: {
          aIncome: formatInputNumber(props.startBalances.dkSpecific.aIncome),
          aTax: formatInputNumber(props.startBalances.dkSpecific.aTax),
          amIncome: formatInputNumber(props.startBalances.dkSpecific.amIncome),
          amTax: formatInputNumber(props.startBalances.dkSpecific.amTax),
          employeeATP: formatInputNumber(props.startBalances.dkSpecific.employeeATP),
          employerATP: formatInputNumber(props.startBalances.dkSpecific.employerATP),
          shDage: formatInputNumber(props.startBalances.dkSpecific.shDage),
          fritvalg: formatInputNumber(props.startBalances.dkSpecific.fritvalg),
          carAllowance: {
            kilometers: formatInputNumber(props.startBalances.dkSpecific.carAllowance.kilometers),
          },
          vacationBasisThisYear: formatInputNumber(props.startBalances.dkSpecific.vacationBasisThisYear),
          vacationBasisLastYear: formatInputNumber(props.startBalances.dkSpecific.vacationBasisLastYear),
          vacationBasisNextYear: formatInputNumber(props.startBalances.dkSpecific.vacationBasisNextYear),
          vacationBasisTransitionYear2019: formatInputNumber(
            props.startBalances.dkSpecific.vacationBasisTransitionYear2019
          ),
          vacationBasisTransitionYear2020: formatInputNumber(
            props.startBalances.dkSpecific.vacationBasisTransitionYear2020
          ),
          vacationAccrual: {
            daysEarned: formatInputNumber(props.startBalances.dkSpecific.vacationAccrual?.daysEarned),
            daysTaken: formatInputNumber(props.startBalances.dkSpecific.vacationAccrual?.daysTaken),
            daysCarryOver: formatInputNumber(props.startBalances.dkSpecific.vacationAccrual?.daysCarryOver),
            vacationBasis: formatInputNumber(props.startBalances.dkSpecific.vacationAccrual?.vacationBasis),
            vacationBasisCarryOver: formatInputNumber(
              props.startBalances.dkSpecific.vacationAccrual?.vacationBasisCarryOver
            ),
            supplementEarned: formatInputNumber(props.startBalances.dkSpecific.vacationAccrual?.supplementEarned),
          },
          vacationFund: {
            daysEarned: formatInputNumber(props.startBalances.dkSpecific.vacationFund?.daysEarned),
            daysTaken: formatInputNumber(props.startBalances.dkSpecific.vacationFund?.daysTaken),
            vacationNet: formatInputNumber(props.startBalances.dkSpecific.vacationFund?.vacationNet),
            vacationBasis: formatInputNumber(props.startBalances.dkSpecific.vacationFund?.vacationBasis),
          },
          vacationOptional: {
            daysAvailableThisYear: formatInputNumber(
              props.startBalances.dkSpecific.vacationOptional?.daysAvailableThisYear
            ),
            daysTakenThisYear: formatInputNumber(props.startBalances.dkSpecific.vacationOptional?.daysTakenThisYear),
          },
          extraVacationAccrual: {
            daysEarned: formatInputNumber(props.startBalances.dkSpecific.extraVacationAccrual?.daysEarned),
            daysTaken: formatInputNumber(props.startBalances.dkSpecific.extraVacationAccrual?.daysTaken),
          },
          vacationPersonalDays: {
            daysAvailableThisYear: formatInputNumber(
              props.startBalances.dkSpecific.vacationPersonalDays?.daysAvailableThisYear
            ),
            daysTakenThisYear: formatInputNumber(
              props.startBalances.dkSpecific.vacationPersonalDays?.daysTakenThisYear
            ),
          },
          personalTimeAccrual: {
            daysEarned: formatInputNumber(props.startBalances.dkSpecific.personalTimeAccrual?.daysEarned),
            daysTaken: formatInputNumber(props.startBalances.dkSpecific.personalTimeAccrual?.daysTaken),
          },
          overtime: {
            balance: formatInputNumber(props.startBalances.dkSpecific.overtime?.balance),
          },
          greatPrayerDay: {
            earned: formatInputNumber(props.startBalances.dkSpecific.greatPrayerDay?.earned),
          },
        },
      },
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    if (key !== 'startBalances.dispositionDate') {
      setByPath(values, key, formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })))
    } else {
      setByPath(values, key, val)
    }
    return values
  },
  onSubmit: (values: StartBalanceFields): ResultFields => {
    return {
      startBalances: {
        dispositionDate: values.startBalances.dispositionDate!,
        employeePaidPension: forceParseInputNumber(values.startBalances.employeePaidPension),
        employerPaidPension: forceParseInputNumber(values.startBalances.employerPaidPension),
        payCheck: forceParseInputNumber(values.startBalances.payCheck),
        salary: forceParseInputNumber(values.startBalances.salary),
        workHours: forceParseInputNumber(values.startBalances.workHours),
        workDays: forceParseInputNumber(values.startBalances.workDays),
        dkSpecific: {
          aIncome: forceParseInputNumber(values.startBalances.dkSpecific.aIncome),
          aTax: forceParseInputNumber(values.startBalances.dkSpecific.aTax),
          amIncome: forceParseInputNumber(values.startBalances.dkSpecific.amIncome),
          amTax: forceParseInputNumber(values.startBalances.dkSpecific.amTax),
          employeeATP: forceParseInputNumber(values.startBalances.dkSpecific.employeeATP),
          employerATP: forceParseInputNumber(values.startBalances.dkSpecific.employerATP),
          shDage: forceParseInputNumber(values.startBalances.dkSpecific.shDage),
          fritvalg: forceParseInputNumber(values.startBalances.dkSpecific.fritvalg),
          carAllowance: {
            kilometers: forceParseInputNumber(values.startBalances.dkSpecific.carAllowance.kilometers),
          },
          vacationBasisThisYear: forceParseInputNumber(values.startBalances.dkSpecific.vacationBasisThisYear),
          vacationBasisLastYear: forceParseInputNumber(values.startBalances.dkSpecific.vacationBasisLastYear),
          vacationBasisNextYear: forceParseInputNumber(values.startBalances.dkSpecific.vacationBasisNextYear),
          vacationBasisTransitionYear2019: forceParseInputNumber(
            values.startBalances.dkSpecific.vacationBasisTransitionYear2019
          ),
          vacationBasisTransitionYear2020: forceParseInputNumber(
            values.startBalances.dkSpecific.vacationBasisTransitionYear2020
          ),
          vacationAccrual: values.startBalances.dkSpecific.vacationAccrual && {
            daysEarned: forceParseInputNumber(values.startBalances.dkSpecific.vacationAccrual.daysEarned),
            daysTaken: forceParseInputNumber(values.startBalances.dkSpecific.vacationAccrual.daysTaken),
            daysCarryOver: forceParseInputNumber(values.startBalances.dkSpecific.vacationAccrual.daysCarryOver),
            vacationBasis: forceParseInputNumber(values.startBalances.dkSpecific.vacationAccrual.vacationBasis),
            vacationBasisCarryOver: forceParseInputNumber(
              values.startBalances.dkSpecific.vacationAccrual.vacationBasisCarryOver
            ),
            supplementEarned: forceParseInputNumber(values.startBalances.dkSpecific.vacationAccrual.supplementEarned),
          },
          vacationFund: values.startBalances.dkSpecific.vacationFund && {
            daysEarned: forceParseInputNumber(values.startBalances.dkSpecific.vacationFund.daysEarned),
            daysTaken: forceParseInputNumber(values.startBalances.dkSpecific.vacationFund.daysTaken),
            vacationNet: forceParseInputNumber(values.startBalances.dkSpecific.vacationFund.vacationNet),
            vacationBasis: forceParseInputNumber(values.startBalances.dkSpecific.vacationFund.vacationBasis),
          },
          vacationOptional: values.startBalances.dkSpecific.vacationOptional && {
            daysAvailableThisYear: forceParseInputNumber(
              values.startBalances.dkSpecific.vacationOptional.daysAvailableThisYear
            ),
            daysTakenThisYear: forceParseInputNumber(
              values.startBalances.dkSpecific.vacationOptional.daysTakenThisYear
            ),
          },
          extraVacationAccrual: values.startBalances.dkSpecific.extraVacationAccrual && {
            daysEarned: forceParseInputNumber(values.startBalances.dkSpecific.extraVacationAccrual.daysEarned),
            daysTaken: forceParseInputNumber(values.startBalances.dkSpecific.extraVacationAccrual.daysTaken),
          },
          vacationPersonalDays: values.startBalances.dkSpecific.vacationPersonalDays && {
            daysAvailableThisYear: forceParseInputNumber(
              values.startBalances.dkSpecific.vacationPersonalDays.daysAvailableThisYear
            ),
            daysTakenThisYear: forceParseInputNumber(
              values.startBalances.dkSpecific.vacationPersonalDays.daysTakenThisYear
            ),
          },
          personalTimeAccrual: values.startBalances.dkSpecific.personalTimeAccrual && {
            daysEarned: forceParseInputNumber(values.startBalances.dkSpecific.personalTimeAccrual.daysEarned),
            daysTaken: forceParseInputNumber(values.startBalances.dkSpecific.personalTimeAccrual.daysTaken),
          },
          overtime: values.startBalances.dkSpecific.overtime && {
            balance: forceParseInputNumber(values.startBalances.dkSpecific.overtime.balance),
          },
          greatPrayerDay: values.startBalances.dkSpecific.greatPrayerDay && {
            earned: forceParseInputNumber(values.startBalances.dkSpecific.greatPrayerDay.earned),
          },
        },
      },
    }
  },
})(StartBalanceFormComponent)
