import ExternalSalaryFileSetting, { ExternalSalaryFileSettingMutableFields } from '../model/externalSalaryFileSetting'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchExternalSalaryFileSettings(
  companyID: string
): Promise<RequestResponse<ExternalSalaryFileSetting[]>> {
  return secureRequest('GET', url('/v2/externalSalaryFileSettings', { companyID }))
}

export function putExternalSalaryFileSettings(
  companyID: string,
  externalSalaryFileSettings: ExternalSalaryFileSettingMutableFields[]
): Promise<RequestResponse<ExternalSalaryFileSetting[]>> {
  return secureRequest('PUT', url('/v2/externalSalaryFileSettings', { companyID }), {
    body: {
      settings: externalSalaryFileSettings,
    },
  })
}
