import React, { ReactElement, useEffect } from 'react'
import { useEffectOnce } from 'react-use'

import { getCompanyPricingPackages } from '../../actions/company-pricing-packages'
import { getPricingPackages } from '../../actions/pricing-packages'
import { PricingPackageGroup } from '../../model/pricingPackage'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyFeatureReducer } from '../../reducers/companyFeatures'
import { CompanyPricingPackageReducer } from '../../reducers/companyPricingPackages'
import { PricingPackageReducer } from '../../reducers/pricingPackages'
import { getMissingPackageIDs, isPricingPackageGroup } from '../../utils/pricing-package-utils'
import { connectToReducer } from '../../utils/reducer-utils'

// NEED_NO_TRANSLATION

type Reducers = {
  companies: CompanyReducer
  companyFeatures: CompanyFeatureReducer
  pricingPackages: PricingPackageReducer
  companyPricingPackages: CompanyPricingPackageReducer
}

type Actions = {
  getPricingPackages: (packageIDs: string[]) => void
  getCompanyPricingPackages: () => void
}

type Props = {
  packageGroups?: PricingPackageGroup[]
  children?: React.ReactNode
}

function PackageLock(props: Reducers & Actions & Props): ReactElement | null {
  const { packageGroups = ['Automatic', 'Premium'] } = props

  const { companyPricingPackages, getCompanyPricingPackages, pricingPackages, getPricingPackages } = props

  useEffectOnce(() => {
    if (!companyPricingPackages.loading && !companyPricingPackages.loaded) {
      getCompanyPricingPackages()
    }
  })

  useEffect(() => {
    if (companyPricingPackages.loaded) {
      const missingPackages = getMissingPackageIDs(
        pricingPackages.pricingPackages.toArray(),
        companyPricingPackages.companyPricingPackages.toArray()
      )
      if (!pricingPackages.loading && (!pricingPackages.loaded || missingPackages.length > 0)) {
        getPricingPackages(missingPackages)
      }
    }
  })

  const hasPackageGroup = () => {
    return isPricingPackageGroup(
      pricingPackages.pricingPackages.filter((p) => p.type === 'Base').toArray(),
      companyPricingPackages.companyPricingPackages.toArray(),
      props.companies.company?.id,
      packageGroups
    )
  }

  if (!hasPackageGroup() || !props.children) {
    return null
  }
  return <>{props.children}</>
}

export default connectToReducer<Reducers, Actions, Props>(
  (state) => ({
    companies: state.companies,
    companyFeatures: state.companyFeatures,
    pricingPackages: state.pricingPackages,
    companyPricingPackages: state.companyPricingPackages,
  }),
  {
    getPricingPackages: getPricingPackages,
    getCompanyPricingPackages: getCompanyPricingPackages,
  }
)(PackageLock)
