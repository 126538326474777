import React from 'react'

import { changeCompanyPricingPackage, fetchPricingPackages } from '../api/pricing-packages'
import ActionTypes from '../constants/action-types'
import PricingPackage from '../model/pricingPackage'
import { CompanyPricingPackageAction } from '../reducers/companyPricingPackages'
import { PricingPackageAction } from '../reducers/pricingPackages'
import { isRequestError } from '../utils/error-utils'

function loadingPricingPackages(): PricingPackageAction {
  return {
    type: ActionTypes.PRICING_PACKAGES_LOADING,
  }
}

export function loadedPricingPackages(pricingPackages: PricingPackage[]): PricingPackageAction {
  return {
    type: ActionTypes.PRICING_PACKAGES_LOADED,
    pricingPackages,
  }
}

function failedLoadingPricingPackages(error: Error): PricingPackageAction {
  return {
    type: ActionTypes.PRICING_PACKAGES_LOAD_FAILED,
    error,
  }
}

function updatingCompanyPricingPackage(companyID: string, pricingPackageID: string): CompanyPricingPackageAction {
  return {
    type: ActionTypes.COMPANY_PRICING_PACKAGE_UPDATING,
    companyID,
    pricingPackageID, // TODO These don't do anything, figure out to make them work
  }
}

function updatedCompanyPricingPackage(companyID: string, pricingPackageID: string): CompanyPricingPackageAction {
  return {
    type: ActionTypes.COMPANY_PRICING_PACKAGE_UPDATED,
    companyID,
    pricingPackageID,
  }
}

function failedUpdatingCompanyPricingPackage(
  companyID: string,
  pricingPackageID: string,
  error: Error
): CompanyPricingPackageAction {
  return {
    type: ActionTypes.COMPANY_PRICING_PACKAGE_UPDATE_FAILED,
    error,
    companyID,
    pricingPackageID,
  }
}

export function getPricingPackages(includePricingPackageID: string[]) {
  return (dispatch: React.Dispatch<any>): Promise<PricingPackage[] | void> => {
    dispatch(loadingPricingPackages())
    return fetchPricingPackages(includePricingPackageID)
      .then((res) => {
        dispatch(loadedPricingPackages(res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingPricingPackages(e))
        }
      })
  }
}

export function updateCompanyPricingPackage(companyID: string, pricingPackageID: string) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(updatingCompanyPricingPackage(companyID, pricingPackageID))
    return changeCompanyPricingPackage(companyID, pricingPackageID)
      .then(() => {
        dispatch(updatedCompanyPricingPackage(companyID, pricingPackageID))
        return true
      })
      .catch((e) => {
        dispatch(failedUpdatingCompanyPricingPackage(companyID, pricingPackageID, e))
      })
  }
}
