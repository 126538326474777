import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getBanks } from '../actions/banks'
import { getCompanyPricingPackages } from '../actions/company-pricing-packages'
import { addContract } from '../actions/contracts'
import { addDepartment, getDepartments } from '../actions/departments'
import { addEmployee } from '../actions/employees'
import { getPricingPackages } from '../actions/pricing-packages'
import { getSalaryCycles } from '../actions/salary-cycles'
import FreelancersAddComponent from '../components/freelancers-add/FreelancersAdd'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { ContractCreationFields } from '../model/contract'
import Department, { DepartmentCreationFields } from '../model/department'
import Employee, { EmployeeCreation } from '../model/employee'
import { AlertReducer } from '../reducers/alerts'
import { BankReducer } from '../reducers/banks'
import { CompanyReducer } from '../reducers/companies'
import { CompanyFeatureReducer } from '../reducers/companyFeatures'
import { CompanyPricingPackageReducer } from '../reducers/companyPricingPackages'
import { ContractReducer } from '../reducers/contracts'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employees'
import { PricingPackageReducer } from '../reducers/pricingPackages'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { UserReducer } from '../reducers/user'
import { paths } from '../routes'
import { isPricingPackageGroup } from '../utils/pricing-package-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  banks: BankReducer
  user: UserReducer
  companies: CompanyReducer
  companyFeatures: CompanyFeatureReducer
  contracts: ContractReducer
  departments: DepartmentReducer
  employees: EmployeeReducer
  salaryCycles: SalaryCycleReducer
  pricingPackages: PricingPackageReducer
  companyPricingPackages: CompanyPricingPackageReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getBanks: () => void
  getDepartments: () => void
  getSalaryCycles: () => void
  addDepartment: (department: DepartmentCreationFields) => Promise<Department | void>
  addEmployee: (employee: EmployeeCreation) => Promise<Employee | void>
  addContract: (contract: ContractCreationFields) => void
  getPricingPackages: (includePricingPackageID: string[]) => void
  getCompanyPricingPackages: () => void
}

function FreelancersAdd(props: Reducers & Actions & RouteProps): ReactElement | null {
  const {
    banks,
    getBanks,
    departments,
    getDepartments,
    salaryCycles,
    getSalaryCycles,
    pricingPackages,
    companyPricingPackages,
    getPricingPackages,
    getCompanyPricingPackages,
  } = props
  useEffect(() => {
    if (!banks.loaded && !banks.loading) {
      getBanks()
    }
    if (!departments.loaded && !departments.loading) {
      getDepartments()
    }
    if (!salaryCycles.loaded && !salaryCycles.loading) {
      getSalaryCycles()
    }
    if (!pricingPackages.loaded && !pricingPackages.loading) {
      getPricingPackages([])
    }
    if (!companyPricingPackages.loaded && !companyPricingPackages.loading) {
      getCompanyPricingPackages()
    }
  }, [
    banks,
    getBanks,
    departments,
    getDepartments,
    salaryCycles,
    getSalaryCycles,
    pricingPackages,
    getPricingPackages,
    companyPricingPackages,
    getCompanyPricingPackages,
  ])

  const loading = !banks.loaded || !departments.loaded || !salaryCycles.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }
  const company = props.companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  const hasPremiumPackage = isPricingPackageGroup(
    props.pricingPackages.pricingPackages.toArray(),
    props.companyPricingPackages.companyPricingPackages.toArray(),
    company.id,
    ['Premium']
  )

  return (
    <FreelancersAddComponent
      isInvite={props.location.pathname === '/' + paths.FREELANCERS + '/' + paths.INVITE}
      alerts={props.alerts}
      user={props.user}
      company={company}
      employees={props.employees}
      departments={props.departments}
      companyFeatures={props.companyFeatures.companyFeatures}
      banks={props.banks}
      contracts={props.contracts}
      salaryCycles={props.salaryCycles.salaryCycles}
      hasPremiumPackage={hasPremiumPackage}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      addDepartment={props.addDepartment}
      addEmployee={props.addEmployee}
      addContract={props.addContract}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    employees: state.employees,
    departments: state.departments,
    companyFeatures: state.companyFeatures,
    banks: state.banks,
    contracts: state.contracts,
    salaryCycles: state.salaryCycles,
    pricingPackages: state.pricingPackages,
    companyPricingPackages: state.companyPricingPackages,
    user: state.user,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getBanks: getBanks,
    addDepartment: addDepartment,
    getDepartments: getDepartments,
    getSalaryCycles: getSalaryCycles,
    addEmployee: addEmployee,
    addContract: addContract,
    getPricingPackages: getPricingPackages,
    getCompanyPricingPackages: getCompanyPricingPackages,
  }
)(FreelancersAdd)
