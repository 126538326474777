import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Employee from '../../../model/employee'
import Remuneration from '../../../model/remuneration'
import SalaryCycle from '../../../model/salaryCycle'
import {
  SupplementAdjustmentCreationFields,
  SupplementAdjustmentMutableFields,
} from '../../../model/supplementAdjustment'
import SupplementBalance from '../../../model/supplementBalance'
import SupplementType from '../../../model/supplementType'
import { SupplementAdjustmentReducer } from '../../../reducers/supplementAdjustments'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatError } from '../../../utils/error-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import SupplementAdjustmentEditForm, { SupplementAdjustmentResult } from './SupplementAdjustmentEditForm'

type Props = {
  visible: boolean
  employee: Employee
  supplementAdjustmentID?: string
  supplementAdjustments: SupplementAdjustmentReducer
  supplementBalances: List<SupplementBalance>
  supplementTypes: List<SupplementType>
  remuneration: Remuneration
  salaryCycle: SalaryCycle

  addSupplementAdjustment: (employeeID: string, supplementAdjustment: SupplementAdjustmentCreationFields) => void
  updateSupplementAdjustment: (employeeID: string, supplementAdjustment: SupplementAdjustmentMutableFields) => void
}

export default function SupplementAdjustmentEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, supplementAdjustments } = props
  useEffect(() => {
    visibleComponentDidUpdate(visible, supplementAdjustments.error, error, setError)
  }, [visible, supplementAdjustments, error])

  const handleSubmit = (values: SupplementAdjustmentResult) => {
    const supplementAdjustment: SupplementAdjustmentCreationFields = {
      employeeID: props.employee.id,
      supplementTypeName: values.supplementTypeName,
      operation: values.operation,
      dispositionDate: values.dispositionDate,
      earned: values.earned,
    }
    if (props.supplementAdjustmentID) {
      props.updateSupplementAdjustment(props.employee.id, {
        ...supplementAdjustment,
        id: props.supplementAdjustmentID,
      })
    } else {
      props.addSupplementAdjustment(props.employee.id, supplementAdjustment)
    }
  }

  return (
    <Card className="balance-adjustment-modal">
      <Subtitle>
        {props.supplementAdjustmentID
          ? t('balance_adjustment_tab.supplements.edit.title.edit')
          : t('balance_adjustment_tab.supplements.edit.title.create')}
      </Subtitle>
      <p>{t('balance_adjustment_tab.supplements.edit.intro')}</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <SupplementAdjustmentEditForm
        employee={props.employee}
        supplementAdjustmentID={props.supplementAdjustmentID}
        supplementAdjustments={props.supplementAdjustments}
        supplementBalances={props.supplementBalances}
        supplementTypes={props.supplementTypes}
        remuneration={props.remuneration}
        salaryCycle={props.salaryCycle}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
