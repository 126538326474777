import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import Transfer from '../model/transfer'
import { DateFormat } from '../model/types'
import { ReducerAction } from '../utils/reducer-utils'

interface HashMap {
  [key: string]: boolean
}

export interface TransferReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  payRollID: string | undefined
  fromDate: DateFormat | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  transfers: List<Transfer>
  hashMap: HashMap
  error: Error | null
}

const Params = Record<TransferReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  payRollID: undefined,
  fromDate: undefined,
  loading: false,
  loaded: false,
  saving: false,
  transfers: List<Transfer>(),
  hashMap: {},
  error: null,
})

export interface TransferAction extends ReducerAction {
  companyID?: string
  payRollID?: string
  fromDate?: DateFormat
  transfers?: Transfer[]
  transfer?: Transfer
  transferID?: string
}

const comparator = (a: Transfer, b: Transfer) => {
  const sort1 = a.paymentDate.localeCompare(b.paymentDate, 'da-dk')
  if (sort1 !== 0) {
    return sort1
  }
  return a.type.localeCompare(b.type, 'da-dk')
}

export default (
  state: Record<TransferReducer> = Params(),
  action: TransferAction = { type: '' }
): Record<TransferReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  if (
    ![
      ActionTypes.TRANSFERS_LOADING,
      ActionTypes.TRANSFERS_LOADED,
      ActionTypes.TRANSFERS_LOADED_PARTIAL,
      ActionTypes.TRANSFERS_LOAD_FAILED,
    ].some((type) => type === action.type)
  ) {
    // only process actions for the active company or pay roll, when not loading
    if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
      return state
    }
    if (action.payRollID && state.get('payRollID') && action.payRollID !== state.get('payRollID')) {
      return state
    }
  }

  let idx = -1
  const transfers = state.get('transfers').toArray()
  const hashMap = state.get('hashMap')
  switch (action.type) {
    case ActionTypes.TRANSFERS_LOADING:
      return Params({
        companyID: action.companyID,
        payRollID: action.payRollID,
        hashMap: {},
        loading: true,
      })
    case ActionTypes.TRANSFERS_LOADED:
    case ActionTypes.TRANSFERS_LOADED_PARTIAL:
      if (!action.transfers) {
        return state
      }
      action.transfers.forEach((newTransfer) => {
        if (!hashMap[newTransfer.id]) {
          transfers.push(newTransfer)
          hashMap[newTransfer.id] = true
        }
      })
      return Params({
        companyID: action.companyID,
        payRollID: action.payRollID,
        fromDate: action.fromDate,
        loaded: true,
        loading: action.type === ActionTypes.TRANSFERS_LOADED_PARTIAL,
        transfers: List(transfers).sort(comparator),
        hashMap,
      })
    case ActionTypes.TRANSFERS_LOAD_FAILED:
      return Params({
        companyID: action.companyID,
        payRollID: action.payRollID,
        error: action.error,
        hashMap: {},
      })

    case ActionTypes.TRANSFER_ADDED:
      if (!action.transfer) {
        return state
      }
      if (hashMap[action.transfer.id]) {
        // only bother looking, if we know it is there
        idx = state.get('transfers').findIndex((item) => !!action.transfer && item.id === action.transfer.id)
      }
      if (idx !== -1) {
        return state.set('transfers', state.get('transfers').set(idx, action.transfer))
      }
      hashMap[action.transfer.id] = true
      return state
        .set('transfers', state.get('transfers').push(action.transfer).sort(comparator))
        .set('hashMap', hashMap)

    case ActionTypes.TRANSFER_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.TRANSFER_UPDATED:
      if (!action.transferID || !action.transfer) {
        return state
      }
      if (!hashMap[action.transferID]) {
        return state
      }
      idx = state.get('transfers').findIndex((item) => item.id === action.transferID)
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('transfers', state.get('transfers').set(idx, action.transfer).sort(comparator))
    case ActionTypes.TRANSFER_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.TRANSFER_DELETED:
      if (!action.transferID) {
        return state
      }
      if (!hashMap[action.transferID]) {
        return state
      }
      idx = state.get('transfers').findIndex((item) => item.id === action.transferID)
      if (idx === -1) {
        return state
      }
      hashMap[action.transferID] = false
      return state.set('transfers', state.get('transfers').delete(idx)).set('hashMap', hashMap)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
