import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../actions/alerts'
import Company from '../../model/company'
import CompanyDeviationConfiguration, {
  CompanyDeviationConfigurationMutableFields,
  CompanyDeviationType,
} from '../../model/companyDeviationConfiguration'
import { CompanyDeviationConfigurationReducer } from '../../reducers/companyDeviationConfigurations'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import LoadingOverlay from '../widgets/LoadingOverlay'
import DeviationsEditForm, { DeviationResult } from './DeviationsEditForm'

type Props = {
  company: Company
  companyDeviationConfigurations: CompanyDeviationConfigurationReducer

  addAlert: addAlertSignature
  addCompanyDeviationConfiguration: (configuration: CompanyDeviationConfigurationMutableFields) => void
  updateCompanyDeviationConfiguration: (configuration: CompanyDeviationConfiguration) => void
  deleteCompanyDeviationConfiguration: (id: string) => void
}

export default function DeviationsEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { companyDeviationConfigurations } = props

  useEffect(() => {
    regularComponentDidUpdate(companyDeviationConfigurations.error, error, setError)
  }, [companyDeviationConfigurations, error])

  const { addAlert, company } = props
  const previousCompanyDeviationConfigurations = usePrevious(companyDeviationConfigurations)

  useEffect(() => {
    // Check for save callback
    if (
      previousCompanyDeviationConfigurations &&
      previousCompanyDeviationConfigurations.saving &&
      !companyDeviationConfigurations.saving
    ) {
      // Check for no error occurred
      if (!companyDeviationConfigurations.error) {
        addAlert('success', t('deviation.alert.success', { name: company.name }), { timeout: 5 })
      }
    }
  })

  const handleSubmit = (values: DeviationResult) => {
    const enabledTypes: CompanyDeviationType[] = []
    const currentConfiguration = props.companyDeviationConfigurations.companyDeviationConfigurations.toArray()

    values.companyDeviationConfigurations.forEach((companyDeviationConfiguration) => {
      if (companyDeviationConfiguration.id) {
        props.updateCompanyDeviationConfiguration({
          ...companyDeviationConfiguration,
          id: companyDeviationConfiguration.id,
        })
      } else {
        props.addCompanyDeviationConfiguration(companyDeviationConfiguration)
      }
      enabledTypes.push(companyDeviationConfiguration.type)
    })

    // delete those that existed, but are not in current update
    currentConfiguration.forEach((current) => {
      if (!enabledTypes.some((type) => type === current.type)) {
        props.deleteCompanyDeviationConfiguration(current.id)
      }
    })
  }

  if (!props.companyDeviationConfigurations.loaded) {
    return <LoadingOverlay />
  }

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}

      <DeviationsEditForm
        companyDeviationConfigurations={props.companyDeviationConfigurations}
        onSubmit={handleSubmit}
      />
    </div>
  )
}
