import React from 'react'

import { fetchDashboards } from '../api/dashboards'
import ActionTypes from '../constants/action-types'
import Dashboard from '../model/dashboard'
import { DashboardAction } from '../reducers/dashboards'
import { isRequestError } from '../utils/error-utils'

function loadingDashboards(companyID?: string): DashboardAction {
  return {
    type: ActionTypes.DASHBOARD_LOADING,
    companyID,
  }
}
function loadedDashboards(companyID: string | undefined, dashboards: Dashboard[]): DashboardAction {
  return {
    type: ActionTypes.DASHBOARD_LOADED,
    companyID,
    dashboards,
  }
}
function failedLoadingDashboards(companyID: string | undefined, error: Error): DashboardAction {
  return {
    type: ActionTypes.DASHBOARD_LOAD_FAILED,
    companyID,
    error,
  }
}

export function getDashboards(companyID?: string) {
  return (dispatch: React.Dispatch<any>): Promise<Dashboard[] | void> => {
    dispatch(loadingDashboards(companyID))
    return fetchDashboards(companyID)
      .then((res) => {
        dispatch(loadedDashboards(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingDashboards(companyID, e))
        }
      })
  }
}
