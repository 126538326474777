import { Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import { ReducerAction } from '../utils/reducer-utils'

export interface CompanyBankAccountReducer {
  companyID: string | undefined
  saving: boolean
  error: Error | null
}

const Params = Record<CompanyBankAccountReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  saving: false,
  error: null,
})

export interface CompanyBankAccountAction extends ReducerAction {
  companyID?: string
}

export default (
  state: Record<CompanyBankAccountReducer> = Params(),
  action: CompanyBankAccountAction = { type: '' }
): Record<CompanyBankAccountReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  switch (action.type) {
    case ActionTypes.COMPANY_BANK_ACCOUNT_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.COMPANY_BANK_ACCOUNT_ADDED:
      return state.set('saving', false)
    case ActionTypes.COMPANY_BANK_ACCOUNT_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
