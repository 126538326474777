import Swipe from '../model/swipe'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchSwipes(companyID: string, limit: number, offset: number): Promise<RequestResponse<Swipe[]>> {
  return secureRequest('GET', url('/v2/swipes', { companyID, limit, offset }))
}

export function patchSwipes(swipeIDs: string[]): Promise<RequestResponse<Swipe[]>> {
  return secureRequest('PATCH', url('/v2/swipes'), { body: swipeIDs })
}

export function delSwipe(swipeID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/swipes/' + swipeID))
}
