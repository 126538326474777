import EmployeeDimension, { EmployeeDimensionMutableFields } from '../model/employeeDimension'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchEmployeeDimensions(companyID: string): Promise<RequestResponse<EmployeeDimension[]>> {
  return secureRequest('GET', url('/v2/employeeDimensions', { companyID }))
}

export function putEmployeeDimensions(
  employeeID: string,
  dimensions: EmployeeDimensionMutableFields[]
): Promise<RequestResponse<EmployeeDimension[]>> {
  return secureRequest('PUT', url('/v2/employees/' + employeeID + '/dimensions'), {
    body: {
      dimensions,
    },
  })
}
