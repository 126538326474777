import React, { ReactElement, useEffect, useState } from 'react'
import { useEffectOnce, usePrevious } from 'react-use'

import { logout, webTokenAuth } from '../actions/user'
import Alert from '../components/elements/alert'
import Button from '../components/elements/button'
import LoginForm, { Fields } from '../components/login/LoginForm'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import User from '../model/user'
import { UserReducer } from '../reducers/user'
import MfaChannel from '../types/mfa-channel'
import { formatError, isMFAChannelError } from '../utils/error-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { ChannelMFAError } from '../utils/request-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  user: UserReducer
}

type Actions = {
  webTokenAuth: (
    webToken: string,
    mfaChallengeID?: string,
    response?: string,
    recoveryCode?: string,
    trustDevice?: boolean
  ) => Promise<User | ChannelMFAError>
  logout: () => void
}

interface MFADetails {
  channel: MfaChannel
  mfaChallengeID: string
}

function WebTokenAuth(props: Reducers & Actions & RouteProps): ReactElement | null {
  const [mfa, setMFA] = useState<MFADetails | undefined>(undefined)
  const [error, setError] = useState<Error | null>(null)

  const { location, user, webTokenAuth, logout } = props

  useEffectOnce(() => {
    if (location.query.webToken) {
      if (user.loggedIn) {
        logout()
      } else {
        webTokenAuth(location.query.webToken)
          .then((res) => {
            if (res && isMFAChannelError(res)) {
              const mfa = res as ChannelMFAError
              setMFA({
                channel: mfa.channel,
                mfaChallengeID: mfa.mfaChallengeID,
              })
            }
          })
          .catch((e) => {
            if (isMFAChannelError(e)) {
              setMFA({
                channel: e.channel,
                mfaChallengeID: e.mfaChallengeID,
              })
            }
          })
      }
    }
  })

  const prevUser = usePrevious(user)

  useEffect(() => {
    if (prevUser && !prevUser.loggedIn) {
      if (user.error) {
        setError(user.error)
        return
      }
      if (user.loggedIn) {
        jsBrowserHistory.push('/')
      }
    }
  }, [prevUser, user])

  const handleSubmit = (values: Fields) => {
    if (!mfa) {
      return
    }
    webTokenAuth(location.query.webToken, mfa.mfaChallengeID, values.response, values.recoveryCode, values.trustDevice)
      .then((res) => {
        if (res && isMFAChannelError(res)) {
          const mfa = res as ChannelMFAError
          setMFA({
            channel: mfa.channel,
            mfaChallengeID: mfa.mfaChallengeID,
          })
        }
      })
      .catch((e) => {
        if (isMFAChannelError(e)) {
          setMFA({
            channel: e.channel,
            mfaChallengeID: e.mfaChallengeID,
          })
        }
      })
  }

  if (mfa) {
    return (
      <div style={{ position: 'relative', minHeight: '300px', marginTop: '96px' }}>
        <LoginForm channel={mfa.channel} onSubmit={handleSubmit} />
      </div>
    )
  }

  if (error) {
    return (
      <div style={{ position: 'relative', minHeight: '300px', marginTop: '96px' }}>
        <Alert message={formatError(error)} type="error" showIcon />
        <Button size="large" type="secondary" onClick={() => jsBrowserHistory.goBack()}>
          Vend tilbage
        </Button>
      </div>
    )
  }

  return (
    <div style={{ position: 'relative', minHeight: '300px', marginTop: '96px' }}>
      <LoadingOverlay />
    </div>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    user: state.user,
  }),
  {
    webTokenAuth: webTokenAuth,
    logout: logout,
  }
)(WebTokenAuth)
