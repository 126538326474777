import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import ContractTemplate from '../model/contractTemplate'
import { ReducerAction } from '../utils/reducer-utils'

export interface ContractTemplateReducer {
  companyID: string | undefined // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: boolean
  loaded: boolean
  saving: boolean
  contractTemplates: List<ContractTemplate>
  error: Error | null
}

const Params = Record<ContractTemplateReducer>({
  companyID: undefined, // keeping track of the active company by listening on COMPANY_SETTING_ACTIVE
  loading: false,
  loaded: false,
  saving: false,
  contractTemplates: List<ContractTemplate>(),
  error: null,
})

export interface ContractTemplateAction extends ReducerAction {
  companyID?: string
  contractTemplateID?: string
  contractTemplates?: ContractTemplate[]
  contractTemplate?: ContractTemplate
}

const comparator = (a: ContractTemplate, b: ContractTemplate) => {
  return a.title.localeCompare(b.title, 'da-dk')
}

export default (
  state: Record<ContractTemplateReducer> = Params(),
  action: ContractTemplateAction = { type: '' }
): Record<ContractTemplateReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  const idx = state.get('contractTemplates').findIndex((item) => item.id === action.contractTemplateID)

  switch (action.type) {
    case ActionTypes.CONTRACT_TEMPLATE_LOADING:
      return state.set('loading', true).set('loaded', false).set('companyID', state.get('companyID'))
    case ActionTypes.CONTRACT_TEMPLATE_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('companyID', state.get('companyID'))
        .set('contractTemplates', List<ContractTemplate>(action.contractTemplates).sort(comparator))
        .set('error', null)
    case ActionTypes.CONTRACT_TEMPLATE_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('companyID', state.get('companyID'))
        .set('error', action.error || null)

    case ActionTypes.CONTRACT_TEMPLATE_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CONTRACT_TEMPLATE_ADDED:
      if (!action.contractTemplate) {
        return state
      }
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('contractTemplates', state.get('contractTemplates').set(idx, action.contractTemplate).sort(comparator))
      }
      return state
        .set('saving', false)
        .set('contractTemplates', state.get('contractTemplates').push(action.contractTemplate).sort(comparator))
    case ActionTypes.CONTRACT_TEMPLATE_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.CONTRACT_TEMPLATE_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CONTRACT_TEMPLATE_UPDATED:
      if (!action.contractTemplate) {
        return state
      }
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state
        .set('saving', false)
        .set('contractTemplates', state.get('contractTemplates').set(idx, action.contractTemplate).sort(comparator))
    case ActionTypes.CONTRACT_TEMPLATE_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.CONTRACT_TEMPLATE_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CONTRACT_TEMPLATE_DELETED:
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('contractTemplates', state.get('contractTemplates').delete(idx))
    case ActionTypes.CONTRACT_TEMPLATE_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
