import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'

import { removeAlertSignature } from '../../actions/alerts'
import Employee from '../../model/employee'
import { AlertReducer } from '../../reducers/alerts'
import { ContractTemplateReducer } from '../../reducers/contractTemplates'
import { paths } from '../../routes'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Table from '../antd/table'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import ContextMenu from '../elements/ContextMenu'
import Icon from '../elements/Icon'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'

type Props = {
  alerts: AlertReducer
  contractTemplates: ContractTemplateReducer
  employees: List<Employee>
  removeAlert: removeAlertSignature
  deleteContractTemplate: (contractTemplateID: string) => void
}

export default function ContractTemplatesOverview(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)
  const [deleting, setDeleting] = useState<string[]>([])

  const { contractTemplates } = props

  useEffect(() => {
    regularComponentDidUpdate(contractTemplates.error, error, setError)
  }, [contractTemplates, error])

  const deleteContractTemplate = (contractTemplateID: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, contractTemplateID])
        props.deleteContractTemplate(contractTemplateID)
      }
    }
  }

  type ContractTemplateRow = {
    id: string
    key: string
    title: string
    description: string
  }

  const columns = [
    { title: t('contract_template.table.header.title'), dataIndex: 'title', key: 'title' },
    { title: t('contract_template.table.header.description'), dataIndex: 'description', key: 'description' },
    {
      title: '',
      dataIndex: '',
      key: 'x2',
      className: 'ant-table-col-context',
      width: 1,
      render: (contractTemplate: ContractTemplateRow) => (
        <ContextMenu>
          <Link to={'/' + paths.EMPLOYEE_TEMPLATES + '/' + contractTemplate.id}>
            <Icon type="search" color="grey" /> {t('contract_template.table.actions.details')}
          </Link>
          <DumbLink onClick={deleteContractTemplate(contractTemplate.id)}>
            <Icon type="cross" color="grey" /> {t('contract_template.table.actions.delete')}
          </DumbLink>
        </ContextMenu>
      ),
    },
  ]

  const getContractTemplates = (): ContractTemplateRow[] => {
    return props.contractTemplates.contractTemplates
      .filter((template) => !deleting.some((id) => id === template.id))
      .map((template) => {
        return {
          id: template.id,
          key: template.id,
          title: template.title,
          description: template.description,
          usageCount: props.employees.count(
            (employee) => !!employee.activeContract && employee.activeContract.contractTemplateID === template.id
          ),
          original: template,
        }
      })
      .toArray()
  }

  return (
    <Card>
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      {error && <Alert message={formatError(error)} type="error" showIcon />}

      <TitleMenu>
        <Link to={'/' + paths.EMPLOYEE_TEMPLATES + '/' + paths.ADD}>
          <Button size="large" type="secondary">
            {t('contract_template.header.new_template')}
          </Button>
        </Link>
      </TitleMenu>
      <Title>{t('contract_template.title')}</Title>

      <p>{t('contract_template.intro')}</p>

      <Table columns={columns} dataSource={getContractTemplates()} size="small" pagination={false} />

      <Link to={'/' + paths.EMPLOYEES}>
        <Button>{t('contract_template.back')}</Button>
      </Link>
    </Card>
  )
}
