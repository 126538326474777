import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Employee from '../../model/employee'
import PayRoll from '../../model/payRoll'
import Transfer from '../../model/transfer'
import { formatDate } from '../../utils/date-utils'
import { formatCurrency } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Table from '../antd/table'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Title from '../elements/Title'

type Props = {
  visible: boolean
  payRoll: PayRoll
  employeesMap: Record<string, Employee>
  transfers: List<Transfer>
}

export default function TransfersModal(props: Props): ReactElement | null {
  type TransferRow = {
    key: string
    paymentDate: string
    recipient: string
    amount: string
    accountDetails: string
  }

  const columns = [
    { title: t('pay_roll.single.transfers_modal.header.payment_date'), dataIndex: 'paymentDate', key: 'paymentDate' },
    {
      title: t('pay_roll.single.transfers_modal.header.recipient'),
      dataIndex: '',
      key: 'xRecipient',
      width: '25%',
      render: (transfer: TransferRow) => <span className="pay-roll-limited-width">{transfer.recipient}</span>,
    },
    { title: t('pay_roll.single.transfers_modal.header.amount'), dataIndex: 'amount', key: 'amount' },
    {
      title: t('pay_roll.single.transfers_modal.header.account_details'),
      dataIndex: 'accountDetails',
      key: 'accountDetails',
    },
  ]

  const getTransfers = (): TransferRow[] => {
    return props.transfers
      .filter((transfer) => ['FreelanceFee', 'Salary'].indexOf(transfer.type) !== -1)
      .map((transfer) => {
        const combinedTransfer = {
          key: transfer.id,
          paymentDate: formatDate(transfer.paymentDate),
          recipient: transfer.recipient,
          amount: formatCurrency(transfer.amount, 2),
          accountDetails: '',
        }
        if (transfer.employeeID) {
          const employee = props.employeesMap[transfer.employeeID]
          if (employee) {
            combinedTransfer.recipient = employee.name || employee.email || '-'
            switch (employee.transferDestinationType) {
              case 'DK NemKonto':
                combinedTransfer.accountDetails = t('pay_roll.single.transfers_modal.easy_account')
                break
              case 'DK Account':
                combinedTransfer.accountDetails = employee.bankRegistrationNumber + ' ' + employee.bankAccountNumber
                break
              default:
                if (employee.bankRegistrationNumber && employee.bankAccountNumber) {
                  combinedTransfer.accountDetails = employee.bankRegistrationNumber + ' ' + employee.bankAccountNumber
                } else {
                  combinedTransfer.accountDetails = t('common.unknown')
                }
            }
          }
        }
        return combinedTransfer
      })
      .toArray()
  }

  return (
    <Card className="pay-roll-transfers">
      <Title>{t('pay_roll.single.transfers_modal.title')}</Title>
      <Row>
        <Col span={8} />
      </Row>

      <Table
        columns={columns}
        dataSource={getTransfers()}
        size="small"
        pagination={false}
        className="pay-roll-table pay-roll-table-small"
      />
    </Card>
  )
}
