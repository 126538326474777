import Animate from 'rc-animate'
import React, { CSSProperties, ReactElement, ReactNode, useState } from 'react'

import classNames from '../../antd/_util/classNames'
import BaseIcon from '../base-icon'

import './index.css'

type Props = {
  closable?: boolean
  description?: string
  type: 'error' | 'warning' | 'info' | 'success'
  prefixCls?: string
  message: ReactNode | string
  closeText?: string
  showIcon?: boolean
  banner?: string
  className?: string
  style?: CSSProperties
  onClose?: (e?: React.MouseEvent<HTMLSpanElement>) => void
}

export default function Alert(props: Props): ReactElement | null {
  // NEED_NO_TRANSLATION
  type State = {
    closing: boolean
    closed: boolean
  }
  const [state, setState] = useState<State>({ closing: true, closed: false })

  const ref = React.useRef<HTMLDivElement>(null)

  const handleClose = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    if (ref.current) {
      ref.current.style.height = `${ref.current.offsetHeight}px`
      ref.current.style.height = `${ref.current.offsetHeight}px`
    }

    setState((prev) => ({ ...prev, closing: false }))
    if (props.onClose) {
      props.onClose(e)
    }
  }
  const animationEnd = () => {
    setState({
      closed: true,
      closing: true,
    })
  }

  const { description, prefixCls = 'ant-alert', message, closeText, banner, className = '', style } = props

  let { closable, type, showIcon } = props

  showIcon = banner && showIcon === undefined ? true : showIcon
  type = banner && type === undefined ? 'warning' : type || 'info'

  let iconType = ''
  switch (type) {
    case 'success':
      iconType = 'check-circle'
      break
    case 'info':
      iconType = 'info-circle'
      break
    case 'error':
      iconType = 'cross-circle'
      break
    case 'warning':
      iconType = 'exclamation-circle'
      break
    default:
      iconType = 'default'
  }

  // use outline icon in alert with description
  if (description) {
    iconType += '-o'
  }

  const alertCls = classNames(
    prefixCls,
    {
      [`${prefixCls}-${type}`]: true,
      [`${prefixCls}-close`]: !state.closing,
      [`${prefixCls}-with-description`]: !!description,
      [`${prefixCls}-no-icon`]: !showIcon,
      [`${prefixCls}-banner`]: !!banner,
    },
    className
  )

  // closeable when closeText is assigned
  if (closeText) {
    closable = true
  }

  const closeIcon = closable ? (
    <span onClick={handleClose} className={`${prefixCls}-close-icon`}>
      {closeText || <BaseIcon type="cross" />}
    </span>
  ) : null

  return state.closed ? null : (
    <Animate component="" showProp="data-show" transitionName={`${prefixCls}-slide-up`} onEnd={animationEnd}>
      <div data-show={state.closing} className={alertCls} style={style} ref={ref}>
        {showIcon ? <BaseIcon className={`${prefixCls}-icon`} type={iconType} /> : null}
        <span className={`${prefixCls}-message`}>{message}</span>
        <span className={`${prefixCls}-description`}>{description}</span>
        {closeIcon}
      </div>
    </Animate>
  )
}
