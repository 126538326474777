import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { AccountingAccount } from '../../../model/accountingIntegration'
import Company from '../../../model/company'
import { combineSearchOption, decorateFieldSignature, getFieldValueSignature } from '../../../utils/form-utils'
import { t } from '../../../utils/translation-utils'
import Select from '../../antd/select'
import { Col, Row } from '../../elements/grid'

type OneTimePayDebitAccountFields = {
  debitCompanyAccountingAccountID?: string
}

type Props<Fields extends OneTimePayDebitAccountFields> = {
  editing: boolean
  company: Company
  accounts: List<AccountingAccount>

  getFieldValue: getFieldValueSignature<Fields>
  decorateField: decorateFieldSignature<Fields>
}

export default function OneTimePayDebitAccount<Fields extends OneTimePayDebitAccountFields>(
  props: Props<Fields>
): ReactElement | null {
  if (!props.company.settingsEnabled.some((setting) => setting === 'AllowDebitCompanyAccountingAccount')) {
    return null
  }
  if (props.accounts.size === 0) {
    return null
  }

  return (
    <Row>
      <Col span={24}>
        {props.decorateField('debitCompanyAccountingAccountID', {
          placeholder: t('otp_form.debit_company_accounting_account_id'),
        })(
          <Select
            disabled={!props.editing}
            dropdownMatchSelectWidth={false}
            showSearch={true}
            filterOption={(inputValue: string, option: ReactElement) => {
              return combineSearchOption(option).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }}
            allowClear
          >
            {props.accounts
              .filter(
                (account) => account.active || account.id === props.getFieldValue('debitCompanyAccountingAccountID')
              )
              .map((account) => {
                let name = account.accountNumber
                if (account.name) {
                  name += ' ' + account.name
                }
                return (
                  <Select.Option key={account.id} value={account.id} title={name}>
                    {name}
                    {!account.active && (
                      <i> {t('otp_form.debit_company_accounting_account_id.no_longer_available.option_note')}</i>
                    )}
                  </Select.Option>
                )
              })}
          </Select>
        )}
      </Col>
    </Row>
  )
}
