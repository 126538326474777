import React from 'react'

import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'

type Props = {
  deletePayRoll: (e: React.MouseEvent) => void
}

export default function DeletePayRoll(props: Props) {
  return (
    <Card className="pay-roll-approve">
      <Subtitle>{t('pay_roll.single.delete.title')}</Subtitle>
      <p>{t('pay_roll.single.delete.intro')}</p>
      <p>&nbsp;</p>
      <Row>
        <Col span={24}>
          <Button type="danger" size="large" block onClick={props.deletePayRoll}>
            {t('pay_roll.single.delete.submit')}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}
