import React, { ReactElement } from 'react'

import { SalaryTypeReducer } from '../../reducers/salaryTypes'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Switch from '../elements/switch'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  salaryTypeID?: string
  salaryTypes: SalaryTypeReducer
}

type Fields = {
  title?: string
  includeInPensionBasis: boolean
  includeInVacationBasis: boolean
  includeInShFritvalgBasis: boolean
}

export type SalaryTypeSupplementVariedResult = {
  title: string
  includeInPensionBasis: boolean
  includeInVacationBasis: boolean
  includeInShFritvalgBasis: boolean
}

function SalaryTypeEditSupplementVariedForm(
  props: Props & FormComponentProps<Fields, SalaryTypeSupplementVariedResult>
): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('title', {
            placeholder: t('salary_types.supplement_varied.edit.form.title'),
            validate: (val) => (!val ? t('salary_types.supplement_varied.edit.form.title.required') : null),
          })(<Input style={{ width: '100%' }} />)}
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div className="ant-switch-wrapper">
            {decorateField('includeInPensionBasis', {
              skipWrapper: true,
              skipLabel: true,
              valueOnChecked: true,
              noBlur: true,
            })(<Switch />)}
            <span className="ant-switch-text">
              {t('salary_types.supplement_varied.edit.form.include_in_pension_basis')}
            </span>
          </div>
        </Col>
        <Col span={8}>
          <div className="ant-switch-wrapper">
            {decorateField('includeInVacationBasis', {
              skipWrapper: true,
              skipLabel: true,
              valueOnChecked: true,
              noBlur: true,
            })(<Switch />)}
            <span className="ant-switch-text">
              {t('salary_types.supplement_varied.edit.form.include_in_vacation_basis')}
            </span>
          </div>
        </Col>
        <Col span={8}>
          <div className="ant-switch-wrapper">
            {decorateField('includeInShFritvalgBasis', {
              skipWrapper: true,
              skipLabel: true,
              valueOnChecked: true,
              noBlur: true,
            })(<Switch />)}
            <span className="ant-switch-text">
              {t('salary_types.supplement_varied.edit.form.include_in_sh_fritvalg_basis')}
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.salaryTypes.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, SalaryTypeSupplementVariedResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      title: undefined,
      includeInPensionBasis: true,
      includeInVacationBasis: true,
      includeInShFritvalgBasis: true,
    }
    if (props.salaryTypeID) {
      props.salaryTypes.salaryTypes.forEach((salaryType) => {
        if (salaryType.id === props.salaryTypeID) {
          fields.title = salaryType.title || undefined
          fields.includeInPensionBasis = salaryType.includeInPensionBasis
          fields.includeInVacationBasis = salaryType.includeInVacationBasis
          fields.includeInShFritvalgBasis = salaryType.includeInShFritvalgBasis
        }
      })
    }
    return fields
  },
  onSubmit: (values) => {
    return {
      title: values.title!,
      includeInPensionBasis: values.includeInPensionBasis,
      includeInVacationBasis: values.includeInVacationBasis,
      includeInShFritvalgBasis: values.includeInShFritvalgBasis,
    }
  },
})(SalaryTypeEditSupplementVariedForm)
