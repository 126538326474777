import React, { ReactElement, useState } from 'react'

import { UserReducer } from '../../reducers/user'
import { connectToReducer } from '../../utils/reducer-utils'
import { isUserSupport } from '../../utils/user-utils'
import Icon from '../elements/Icon'

import './SupportUserLock.css'

// NEED_NO_TRANSLATION

type Reducers = {
  user: UserReducer
}

type Props = {
  children: React.ReactNode
  inline?: boolean
}

function SupportUserLock(props: Props & Reducers): ReactElement | null {
  const [show, setShow] = useState(true)
  if (!isUserSupport(props.user)) {
    return null
  }

  if (!show) {
    return null
  }

  const removeIcon = (
    <span
      onClick={() => setShow(false)}
      className="support-user-field-hide"
      title="Skjul funktion, til f.eks. at tage et skærmbillede"
    >
      <Icon type="cross" />
    </span>
  )

  if (props.inline) {
    return (
      <span className={'support-user-field'} title={'Dette felt er kun tilgængeligt for supportbrugere'}>
        {props.children} {removeIcon}
      </span>
    )
  }

  return (
    <div className={'support-user-field'} title={'Dette felt er kun tilgængeligt for supportbrugere'}>
      {removeIcon}
      {props.children}
    </div>
  )
}

export default connectToReducer<Reducers, any, Props>(
  (state) => ({
    user: state.user,
  }),
  {}
)(SupportUserLock)
