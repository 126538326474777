import { startOfWeek } from 'date-fns'
import React from 'react'

import {
  delLeaveRegistrationBulk,
  delTimeRegistration,
  delTimeRegistrationHoursBulk,
  fetchTimeRegistrations,
  patchTimeRegistrations,
  postTimeRegistration,
  postTimeRegistrationBulk,
  putTimeRegistration,
  TimeRegistrationBulk,
} from '../api/time-registrations'
import ActionTypes from '../constants/action-types'
import TimeRegistration, { TimeRegistrationClass, TimeRegistrationMutableFields } from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { TimeRegistrationAction } from '../reducers/timeRegistrations'
import { getDate } from '../utils/date-utils'
import { isRequestError } from '../utils/error-utils'
import { PromiseVoid } from '../utils/request-utils'
import { handlePagination } from './pagination'

function loadingTimeRegistrations(
  companyID: string | undefined,
  employeeID: string | undefined,
  payRollID: string | undefined,
  fromDate?: DateFormat,
  toDate?: DateFormat
): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_LOADING,
    companyID,
    employeeID,
    payRollID,
    fromDate,
    toDate,
  }
}
function loadedTimeRegistrations(
  companyID: string | undefined,
  employeeID: string | undefined,
  payRollID: string | undefined,
  fromDate: DateFormat | undefined,
  toDate: DateFormat | undefined,
  registrations: TimeRegistration[],
  partial = false
): TimeRegistrationAction {
  return {
    type: partial ? ActionTypes.TIME_REGISTRATION_LOADED_PARTIAL : ActionTypes.TIME_REGISTRATION_LOADED,
    companyID,
    employeeID,
    payRollID,
    fromDate,
    toDate,
    registrations: registrations,
  }
}
function failedLoadingTimeRegistrations(
  companyID: string | undefined,
  employeeID: string | undefined,
  payRollID: string | undefined,
  error: Error
): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_LOAD_FAILED,
    companyID,
    employeeID,
    payRollID,
    error,
  }
}

function creatingTimeRegistration(employeeID: string): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_CREATING,
    employeeID,
  }
}
export function createdTimeRegistration(employeeID: string, registration: TimeRegistration): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_CREATED,
    employeeID,
    registration: registration,
  }
}
function failedCreatingTimeRegistration(employeeID: string, error: Error): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_CREATE_FAILED,
    error,
    employeeID,
  }
}

function approvingTimeRegistrations(timeRegistrationIDs: string[]): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_APPROVING,
    registrationIDs: timeRegistrationIDs,
  }
}
export function approvedTimeRegistrations(timeRegistrationIDs: string[], approved: boolean): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_APPROVED,
    registrationIDs: timeRegistrationIDs,
    approved,
  }
}
function failedApprovingTimeRegistrations(timeRegistrationIDs: string[], error: Error): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_APPROVE_FAILED,
    error,
    registrationIDs: timeRegistrationIDs,
  }
}

function updatingTimeRegistration(employeeID: string, timeRegistrationID: string): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_UPDATING,
    employeeID,
    registrationID: timeRegistrationID,
  }
}
export function updatedTimeRegistration(
  employeeID: string,
  timeRegistrationID: string,
  registration: TimeRegistration
): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_UPDATED,
    employeeID,
    registrationID: timeRegistrationID,
    registration: registration,
  }
}
function failedUpdatingTimeRegistration(
  employeeID: string,
  timeRegistrationID: string,
  error: Error
): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_UPDATE_FAILED,
    error,
    employeeID,
    registrationID: timeRegistrationID,
  }
}

function deletingTimeRegistration(timeRegistrationID: string): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_DELETING,
    registrationID: timeRegistrationID,
  }
}
export function deletedTimeRegistration(timeRegistrationID: string): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_DELETED,
    registrationID: timeRegistrationID,
  }
}
function failedDeletingTimeRegistration(timeRegistrationID: string, error: Error): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_DELETE_FAILED,
    error,
    registrationID: timeRegistrationID,
  }
}

function bulkDeletingTimeRegistration(companyID?: string, employeeID?: string): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_DELETING,
    companyID,
    employeeID,
  }
}
function bulkDeletedTimeRegistration(
  companyID: string | undefined,
  employeeID: string | undefined,
  registrationType: TimeRegistrationClass,
  deleteFromDate?: Date
): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_BULK_DELETED,
    companyID,
    employeeID,
    registrationType,
    deleteFromDate,
  }
}
function failedBulkDeletingTimeRegistration(
  companyID: string | undefined,
  employeeID: string | undefined,
  error: Error
): TimeRegistrationAction {
  return {
    type: ActionTypes.TIME_REGISTRATION_DELETE_FAILED,
    error,
    companyID,
    employeeID,
  }
}

export function getTimeRegistrations(
  companyID?: string,
  employeeID?: string,
  payRollID?: string,
  fromDate?: DateFormat,
  toDate?: DateFormat,
  offset?: number
) {
  return (dispatch: React.Dispatch<any>): Promise<void | TimeRegistration[]> => {
    if (!offset) {
      dispatch(loadingTimeRegistrations(companyID, employeeID, payRollID, fromDate, toDate))
      offset = 0
    }
    const limit = 1000
    return fetchTimeRegistrations(companyID, employeeID, payRollID, fromDate, toDate, limit, offset)
      .then((res) => {
        return handlePagination(
          res,
          limit,
          offset,
          (data) => dispatch(loadedTimeRegistrations(companyID, employeeID, payRollID, fromDate, toDate, data)),
          (data) => dispatch(loadedTimeRegistrations(companyID, employeeID, payRollID, fromDate, toDate, data, true)),
          (offset) => dispatch(getTimeRegistrations(companyID, employeeID, payRollID, fromDate, toDate, offset))
        )
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingTimeRegistrations(companyID, employeeID, payRollID, e))
        }
      })
  }
}

export function createTimeRegistration(registration: TimeRegistrationMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<TimeRegistration | void> => {
    dispatch(creatingTimeRegistration(registration.employeeID))
    return postTimeRegistration(registration)
      .then((res) => {
        dispatch(createdTimeRegistration(registration.employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingTimeRegistration(registration.employeeID, e))
        }
      })
  }
}

export function createTimeRegistrationBulk(registration: TimeRegistrationBulk) {
  return (dispatch: React.Dispatch<any>): Promise<TimeRegistration[] | void> => {
    dispatch(creatingTimeRegistration(registration.employeeID))
    return postTimeRegistrationBulk(registration)
      .then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((timeRegistration) => {
            dispatch(createdTimeRegistration(registration.employeeID, timeRegistration))
          })
        } else {
          dispatch(
            failedCreatingTimeRegistration(
              registration.employeeID,
              new Error('Der er allerede registreret for denne periode.')
            )
          )
        }
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingTimeRegistration(registration.employeeID, e))
        }
      })
  }
}

export function approveTimeRegistrations(registrationIDs: string[]) {
  return (dispatch: React.Dispatch<any>): Promise<TimeRegistration[] | void> => {
    dispatch(approvingTimeRegistrations(registrationIDs))
    return patchTimeRegistrations('Approve', registrationIDs)
      .then((res) => {
        dispatch(approvedTimeRegistrations(registrationIDs, true))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedApprovingTimeRegistrations(registrationIDs, e))
        }
      })
  }
}

export function unapproveTimeRegistrations(registrationIDs: string[]) {
  return (dispatch: React.Dispatch<any>): Promise<TimeRegistration[] | void> => {
    dispatch(approvingTimeRegistrations(registrationIDs))
    return patchTimeRegistrations('Unapprove', registrationIDs)
      .then((res) => {
        dispatch(approvedTimeRegistrations(registrationIDs, false))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedApprovingTimeRegistrations(registrationIDs, e))
        }
      })
  }
}

export function updateTimeRegistration(registration: TimeRegistrationMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<TimeRegistration | void> => {
    const registrationID = registration.id
    if (!registrationID) {
      return PromiseVoid
    }
    dispatch(updatingTimeRegistration(registration.employeeID, registrationID))
    return putTimeRegistration(registration)
      .then((res) => {
        dispatch(updatedTimeRegistration(registration.employeeID, registrationID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingTimeRegistration(registration.employeeID, registrationID, e))
        }
      })
  }
}

export function deleteTimeRegistration(registrationID: string) {
  return (dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(deletingTimeRegistration(registrationID))
    return delTimeRegistration(registrationID)
      .then(() => {
        dispatch(deletedTimeRegistration(registrationID))
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingTimeRegistration(registrationID, e))
        }
      })
  }
}

function deleteLeaveRegistrationBulk(employeeID: string) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(bulkDeletingTimeRegistration(undefined, employeeID))
    return delLeaveRegistrationBulk(employeeID)
      .then(() => {
        dispatch(bulkDeletedTimeRegistration(undefined, employeeID, 'Leave'))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedBulkDeletingTimeRegistration(undefined, employeeID, e))
        }
      })
  }
}

function deleteHoursRegistrationBulk(
  companyID?: string,
  employeeID?: string,
  timeClass?: TimeRegistrationClass,
  deleteAll?: boolean
) {
  return (dispatch: React.Dispatch<any>) => {
    dispatch(bulkDeletingTimeRegistration(companyID, employeeID))
    return delTimeRegistrationHoursBulk(companyID, employeeID, timeClass, deleteAll)
      .then(() => {
        const from = timeClass !== 'Work Hours' || deleteAll ? undefined : startOfWeek(getDate(), { weekStartsOn: 1 })
        dispatch(bulkDeletedTimeRegistration(companyID, employeeID, timeClass ?? 'Hours', from))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedBulkDeletingTimeRegistration(companyID, employeeID, e))
        }
      })
  }
}

export function deleteTimeRegistrationBulk(
  companyID: string | undefined,
  employeeID: string | undefined,
  timeRegistrationClass: TimeRegistrationClass,
  deleteAll?: boolean
): (dispatch: React.Dispatch<any>) => Promise<boolean | void> {
  if (timeRegistrationClass === 'Leave') {
    if (employeeID) {
      return deleteLeaveRegistrationBulk(employeeID)
    }
    return (_dispatch: React.Dispatch<any>) => PromiseVoid
  }
  return deleteHoursRegistrationBulk(companyID, employeeID, timeRegistrationClass, deleteAll)
}
