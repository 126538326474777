import React, { ReactElement } from 'react'

import { ExternalOrganization } from '../../api/company-accounting-integration-setup'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'

type Props = {
  editing: boolean
  organizations: ExternalOrganization[]

  organizationID?: string
}

type Fields = {
  organizationID?: string
}

export type OrganizationFields = {
  readonly step: 'Organization'
  organizationID: string
}

function OrganizationForm(props: Props & FormComponentProps<Fields, OrganizationFields>): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {!props.editing && <Subtitle>{t('accounting_integration.organization.title')}</Subtitle>}
      {props.getFormError()}
      <Subcard className="connection-search">
        {decorateField('organizationID', {
          placeholder: t('accounting_integration.organization.organization_id'),
          validate: (val) => (!val ? t('accounting_integration.organization.organization_id.required') : ''),
        })(
          <Select dropdownMatchSelectWidth={false}>
            {props.organizations
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((organization) => {
                return (
                  <Select.Option key={organization.organizationID} value={organization.organizationID}>
                    {organization.name}
                  </Select.Option>
                )
              })}
          </Select>
        )}
      </Subcard>
      <Row>
        <Col span={24}>
          <Button
            htmlType="submit"
            size="large"
            type="secondary"
            className="gtm-accounting-integration-continue-to-daybook"
          >
            {props.editing
              ? t('accounting_integration.organization.save_change')
              : t('accounting_integration.organization.next_step')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, OrganizationFields>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      organizationID: props.organizationID,
    }
    if (!fields.organizationID) {
      if (props.organizations.length === 1) {
        fields.organizationID = props.organizations[0].organizationID
      }
    }
    return fields
  },
  onSubmit: (values) => {
    return {
      step: 'Organization',
      organizationID: values.organizationID!,
    }
  },
})(OrganizationForm)
