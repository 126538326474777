import React, { ReactElement } from 'react'

import { BenefitDefinitionCreationFields, BenefitType } from '../../model/remuneration'
import { figureGrossPayReductionType } from '../../utils/benefit-utils'
import { formatAPIDate, getDate } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'
import BenefitFixedDisbursementForm from './BenefitFixedDisbursementForm'
import BenefitFreeTextForm from './BenefitFreeTextForm'
import BenefitPayReductionForm from './BenefitPayReductionForm'
import BenefitsForm from './BenefitsForm'
import BenefitTaxReportingForm from './BenefitTaxReportingForm'
import BenefitTaxReportingNoThresholdReportingForm from './BenefitTaxReportingNoThresholdReporting'
import { RemunerationFields } from './types'

type Props = {
  remuneration: RemunerationFields
}

type Fields = {
  hasCar: boolean
  hasEasyCar: boolean
  carTaxValue: string
  carValuation: string
  carAcquisitionDate: Date
  carFirstLicensePlateDate: Date
  carYearlyEnvironmentalTax: string
  carNettoAmount: string
  carMajorityShareholder: boolean
  hasInternet: boolean
  hasTelephone: boolean
  hasHealth: boolean
  healthTaxValue: string
  hasLunch: boolean
  hasLunchDaily: boolean
  lunchNettoAmount: string
  hasEmployeeAssociation: boolean
  employeeAssociationAmount: string
  hasBoardLodging: boolean
  boardLodgingTaxValue: string
  hasPermanentResidence: boolean
  permanentResidenceReportingAmount: string
  hasFreeTransport: boolean
  hasTaxReportings: boolean
  taxReportings: {
    title: string
    type: BenefitType
    reportingAmount: string
  }[]
  hasPayReductions: boolean
  payReductions: {
    title: string
    isGross: 'true' | 'false'
    withVacation: boolean
    withPension: boolean
    reduction: string
  }[]
  hasEmployeeStock: boolean
  hasFixedDisbursement: boolean
  increases: {
    title: string
    type: BenefitType
    increase: string
  }[]
  hasFreeText: boolean
  texts: {
    title: string
    type: BenefitType
  }[]
  employeeStockText?: string
  hasTaxReportingsNoThreshold: boolean
  taxReportingsNoThreshold: {
    title: string
    type: BenefitType
    reportingAmount: string
  }[]
  hasForenedeGruppeliv: boolean
  forenedeGruppelivCode: string
  forenedeGruppelivTaxValue: string
}

export type BenefitStepResult = {
  remuneration: {
    benefits: BenefitDefinitionCreationFields[]
  }
  step: 'BenefitStep'
}

function BenefitsStep(props: Props & FormComponentProps<Fields, BenefitStepResult>): ReactElement | null {
  const { getFieldValue } = props
  return (
    <div>
      <Subtitle>{t('contracts_add.benefits_step.title')}</Subtitle>
      <p>&nbsp;</p>
      {props.getFormError()}
      <BenefitsForm<Fields>
        decorateField={props.decorateField}
        getFieldValue={getFieldValue}
        setFieldValue={props.setFieldValue}
        setAnyFieldValue={props.setAnyFieldValue}
      />
      {getFieldValue('hasTaxReportings') && (
        <BenefitTaxReportingForm<Fields>
          decorateAnyField={props.decorateAnyField}
          getFieldValue={getFieldValue}
          setFieldValue={props.setFieldValue}
          setAnyFieldValue={props.setAnyFieldValue}
          getAnyFieldError={props.getAnyFieldError}
        />
      )}
      {getFieldValue('hasTaxReportingsNoThreshold') && (
        <BenefitTaxReportingNoThresholdReportingForm<Fields>
          decorateAnyField={props.decorateAnyField}
          getFieldValue={getFieldValue}
          setFieldValue={props.setFieldValue}
          setAnyFieldValue={props.setAnyFieldValue}
          getAnyFieldError={props.getAnyFieldError}
        />
      )}
      {getFieldValue('hasPayReductions') && (
        <BenefitPayReductionForm<Fields>
          decorateAnyField={props.decorateAnyField}
          getFieldValue={getFieldValue}
          getAnyFieldValue={props.getAnyFieldValue}
          setFieldValue={props.setAnyFieldValue}
          setAnyFieldValue={props.setAnyFieldValue}
          getAnyFieldError={props.getAnyFieldError}
        />
      )}
      {getFieldValue('hasFixedDisbursement') && (
        <BenefitFixedDisbursementForm<Fields>
          decorateAnyField={props.decorateAnyField}
          getFieldValue={getFieldValue}
          setFieldValue={props.setFieldValue}
          setAnyFieldValue={props.setAnyFieldValue}
          getAnyFieldError={props.getAnyFieldError}
        />
      )}
      {getFieldValue('hasFreeText') && (
        <BenefitFreeTextForm<Fields>
          decorateAnyField={props.decorateAnyField}
          getFieldValue={getFieldValue}
          setFieldValue={props.setFieldValue}
          setAnyFieldValue={props.setAnyFieldValue}
          getAnyFieldError={props.getAnyFieldError}
        />
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('contracts_add.benefits_step.submit')}
          </Button>
          <Button size="large" onClick={props.goBack}>
            {t('contracts_add.benefits_step.back')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, BenefitStepResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      hasCar: false,
      hasEasyCar: true,
      carTaxValue: '0',
      carValuation: '0',
      carAcquisitionDate: getDate(),
      carFirstLicensePlateDate: getDate(),
      carYearlyEnvironmentalTax: '0',
      carNettoAmount: '0',
      carMajorityShareholder: false,
      hasInternet: false,
      hasTelephone: false,
      hasHealth: false,
      healthTaxValue: '0',
      hasLunch: false,
      hasLunchDaily: false,
      lunchNettoAmount: '0',
      employeeAssociationAmount: '0',
      hasEmployeeAssociation: false,
      hasBoardLodging: false,
      boardLodgingTaxValue: '0',
      hasPermanentResidence: false,
      permanentResidenceReportingAmount: '0',
      hasFreeTransport: false,
      hasTaxReportings: false,
      taxReportings: [],
      hasPayReductions: false,
      payReductions: [],
      hasEmployeeStock: false,
      hasFixedDisbursement: false,
      increases: [],
      hasFreeText: false,
      texts: [],
      hasTaxReportingsNoThreshold: false,
      taxReportingsNoThreshold: [],
      hasForenedeGruppeliv: false,
      forenedeGruppelivTaxValue: '',
      forenedeGruppelivCode: '',
    }
    props.remuneration.benefits.forEach((row) => {
      switch (row.type) {
        case 'Car Simple':
        case 'Car Managed':
          fields.hasCar = true
          if (row.type === 'Car Simple') {
            fields.hasEasyCar = false
            fields.carTaxValue = formatInputNumber(row.amount)
          } else {
            fields.carNettoAmount = formatInputNumber(row.amount)
          }
          fields.carValuation = formatInputNumber(row.carValuation)
          fields.carAcquisitionDate = getDate(row.carAcquisitionDate)
          fields.carFirstLicensePlateDate = getDate(row.carFirstLicensePlateDate)
          fields.carYearlyEnvironmentalTax = formatInputNumber(row.yearlyEnvironmentalTax)
          fields.carMajorityShareholder = row.carMajorityShareholder ?? false
          break
        case 'Internet':
          fields.hasInternet = true
          break
        case 'Telephone':
          fields.hasTelephone = true
          break
        case 'Health':
          fields.hasHealth = true
          fields.healthTaxValue = formatInputNumber(row.amount)
          break
        case 'Lunch':
          fields.hasLunch = true
          fields.lunchNettoAmount = formatInputNumber(row.amount)
          break
        case 'Lunch Daily':
          fields.hasLunch = true
          fields.hasLunchDaily = true
          fields.lunchNettoAmount = formatInputNumber(row.amount)
          break
        case 'Board and Lodging':
          fields.hasBoardLodging = true
          fields.boardLodgingTaxValue = formatInputNumber(row.amount)
          break
        case 'Permanent Residence':
          fields.hasPermanentResidence = true
          fields.permanentResidenceReportingAmount = formatInputNumber(row.amount)
          break
        case 'Free Transport':
          fields.hasFreeTransport = true
          break
        case 'Benefit In Kind':
          fields.hasEmployeeStock = true
          fields.employeeStockText = row.title
          break
        case 'Forenede Gruppeliv':
          fields.hasForenedeGruppeliv = true
          fields.forenedeGruppelivCode = row.customerNumber ?? ''
          fields.forenedeGruppelivTaxValue = formatInputNumber(row.amount)
          break
        case 'Tax Reporting No Threshold':
          fields.hasTaxReportingsNoThreshold = true
          fields.taxReportingsNoThreshold.push({
            title: row.title ?? '',
            type: row.type,
            reportingAmount: formatInputNumber(row.amount),
          })
          break
        case 'Tax Reporting':
          fields.hasTaxReportings = true
          fields.taxReportings.push({
            title: row.title ?? '',
            type: row.type,
            reportingAmount: formatInputNumber(row.amount),
          })
          break
        case 'Gross Pay Reduction':
        case 'Gross Pay Reduction No Vacation':
        case 'Gross Pay Reduction No Pension':
        case 'Gross Pay Reduction No Vacation and Pension':
        case 'Net Pay Reduction':
          fields.hasPayReductions = true
          fields.payReductions.push({
            title: row.title ?? '',
            isGross: row.type !== 'Net Pay Reduction' ? 'true' : 'false',
            withVacation: row.type === 'Gross Pay Reduction' || row.type === 'Gross Pay Reduction No Pension',
            withPension: row.type === 'Gross Pay Reduction' || row.type === 'Gross Pay Reduction No Vacation',
            reduction: formatInputNumber(row.amount),
          })
          break
        case 'Fixed Disbursement':
          fields.hasFixedDisbursement = true
          fields.increases.push({
            title: row.title ?? '',
            type: row.type,
            increase: formatInputNumber(row.amount),
          })
          break
        case 'Employee Association':
          fields.hasEmployeeAssociation = true
          fields.employeeAssociationAmount = formatInputNumber(row.amount)
          break
        case 'Free Text':
          fields.hasFreeText = true
          break
        default:
          break
      }
    })
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'carTaxValue':
      case 'carValuation':
      case 'carYearlyEnvironmentalTax':
      case 'carNettoAmount':
      case 'healthTaxValue':
      case 'lunchNettoAmount':
      case 'employeeAssociationAmount':
      case 'boardLodgingTaxValue':
      case 'permanentResidenceReportingAmount':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        )
        break
      default:
        if (
          key.match(/.+salaryReduction$/) ||
          key.match(/.+payCheckReduction$/) ||
          key.match(/.+reportingAmount$/) ||
          key.match(/.+payCheckIncrease$/) ||
          key.match(/.+increase$/)
        ) {
          setByPath(
            values,
            key,
            formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
          )
        } else {
          setByPath(values, key, val)
        }
        break
    }
    return values
  },
  onSubmit: (values) => {
    const benefits: BenefitDefinitionCreationFields[] = []
    if (values.hasCar) {
      const benefit: BenefitDefinitionCreationFields = { type: 'Car Managed' }
      if (values.hasEasyCar) {
        benefit.type = 'Car Managed'
        benefit.carValuation = forceParseInputNumber(values.carValuation)
        benefit.carAcquisitionDate = formatAPIDate(values.carAcquisitionDate)
        benefit.carFirstLicensePlateDate = formatAPIDate(values.carFirstLicensePlateDate)
        benefit.yearlyEnvironmentalTax = forceParseInputNumber(values.carYearlyEnvironmentalTax)
        benefit.amount = forceParseInputNumber(values.carNettoAmount)
      } else {
        benefit.type = 'Car Simple'
        benefit.amount = forceParseInputNumber(values.carTaxValue)
      }
      benefit.carMajorityShareholder = values.carMajorityShareholder
      benefits.push(benefit)
    }
    if (values.hasTelephone) {
      benefits.push({
        type: 'Telephone',
      })
    }
    if (values.hasInternet) {
      benefits.push({
        type: 'Internet',
      })
    }
    if (values.hasHealth) {
      benefits.push({
        type: 'Health',
        amount: forceParseInputNumber(values.healthTaxValue),
      })
    }
    if (values.hasLunch) {
      benefits.push({
        type: values.hasLunchDaily ? 'Lunch Daily' : 'Lunch',
        amount: forceParseInputNumber(values.lunchNettoAmount),
      })
    }
    if (values.hasEmployeeAssociation) {
      benefits.push({
        type: 'Employee Association',
        amount: forceParseInputNumber(values.employeeAssociationAmount),
      })
    }
    if (values.hasBoardLodging) {
      benefits.push({
        type: 'Board and Lodging',
        amount: forceParseInputNumber(values.boardLodgingTaxValue),
      })
    }
    if (values.hasPermanentResidence) {
      benefits.push({
        type: 'Permanent Residence',
        amount: forceParseInputNumber(values.permanentResidenceReportingAmount),
      })
    }
    if (values.hasFreeTransport) {
      benefits.push({ type: 'Free Transport' })
    }
    if (values.hasEmployeeStock) {
      benefits.push({
        title: values.employeeStockText!,
        type: 'Benefit In Kind',
      })
    }
    if (values.hasTaxReportings) {
      values.taxReportings.forEach((row) => {
        benefits.push({
          title: row.title,
          type: row.type,
          amount: forceParseInputNumber(row.reportingAmount),
        })
      })
    }
    if (values.hasTaxReportingsNoThreshold) {
      values.taxReportingsNoThreshold.forEach((row) => {
        benefits.push({
          title: row.title,
          type: row.type,
          amount: forceParseInputNumber(row.reportingAmount),
        })
      })
    }
    if (values.hasPayReductions) {
      values.payReductions.forEach((row) => {
        let type: BenefitType = 'Net Pay Reduction'
        if (row.isGross === 'true') {
          type = figureGrossPayReductionType(row.withVacation, row.withPension)
        }
        benefits.push({
          title: row.title,
          type,
          amount: forceParseInputNumber(row.reduction),
        })
      })
    }
    if (values.hasFixedDisbursement) {
      values.increases.forEach((row) => {
        benefits.push({ title: row.title, type: row.type, amount: forceParseInputNumber(row.increase) })
      })
    }
    if (values.hasFreeText) {
      values.texts.forEach((row) => {
        benefits.push({ title: row.title, type: row.type })
      })
    }
    if (values.hasForenedeGruppeliv) {
      benefits.push({
        type: 'Forenede Gruppeliv',
        amount: forceParseInputNumber(values.forenedeGruppelivTaxValue),
        customerNumber: values.forenedeGruppelivCode,
      })
    }
    return {
      remuneration: {
        benefits,
      },
      step: 'BenefitStep',
    }
  },
})(BenefitsStep)
