import ApplyResult from '../model/applyResult'
import AsynchronousTask from '../model/asynchronousTask'
import { OneTimePayType } from '../model/oneTimePay'
import { StagedImportData } from '../model/stagedImport'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function initiateStagedImport(
  companyID: string,
  type: string,
  fileID: string,
  oneTimePayType?: OneTimePayType,
  periodID?: string
): Promise<RequestResponse<AsynchronousTask>> {
  return secureRequest('POST', url('/v2/stagedImports/async'), {
    body: { companyID, type, fileID, oneTimePayType, periodID },
  })
}

export function getStagedImportData(asynchronousTaskID: string): Promise<RequestResponse<StagedImportData>> {
  return secureRequest('GET', url('/v2/stagedImports/async/' + asynchronousTaskID))
}

export function initiateSavingStagedImportData(
  asynchronousTaskID: string,
  employeeImportIDs: string[]
): Promise<RequestResponse<AsynchronousTask>> {
  return secureRequest('POST', url('/v2/stagedImports/async/' + asynchronousTaskID), {
    body: {
      employeeImportIDs,
    },
  })
}

export function getStagedImportResult(asynchronousTaskID: string): Promise<RequestResponse<ApplyResult[]>> {
  return secureRequest('GET', url('/v2/stagedImports/async/' + asynchronousTaskID + '/result'))
}
