import React from 'react'

import {
  delCarAllowance,
  fetchCarAllowances,
  patchCarAllowances,
  postCarAllowances,
  putCarAllowance,
} from '../api/car-allowances'
import ActionTypes from '../constants/action-types'
import CarAllowance, { CarAllowanceMutableFields } from '../model/carAllowance'
import { DateFormat } from '../model/types'
import { CarAllowanceAction } from '../reducers/carAllowances'
import { isRequestError } from '../utils/error-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingCarAllowances(companyID?: string, employeeID?: string): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCES_LOADING,
    companyID,
    employeeID,
  }
}
function loadedCarAllowances(
  companyID: string | undefined,
  employeeID: string | undefined,
  carAllowances: CarAllowance[]
): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCES_LOADED,
    companyID,
    employeeID,
    carAllowances,
  }
}
function failedLoadingCarAllowances(
  companyID: string | undefined,
  employeeID: string | undefined,
  error: Error
): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCES_LOAD_FAILED,
    companyID,
    employeeID,
    error,
  }
}

export function createdCarAllowance(employeeID: string, carAllowance: CarAllowance): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_CREATED,
    employeeID,
    carAllowance,
  }
}

function creatingCarAllowances(employeeID: string): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCES_CREATING,
    employeeID,
  }
}
function createdCarAllowances(employeeID: string, carAllowances: CarAllowance[]): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCES_CREATED,
    employeeID,
    carAllowances,
  }
}
function failedCreatingCarAllowances(employeeID: string, error: Error): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCES_CREATE_FAILED,
    error,
    employeeID,
  }
}

function approvingCarAllowances(carAllowanceIDs: string[]): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_APPROVING,
    carAllowanceIDs,
  }
}
export function approvedCarAllowances(carAllowanceIDs: string[], approved: boolean): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_APPROVED,
    carAllowanceIDs,
    approved,
  }
}
function failedApprovingCarAllowances(carAllowanceIDs: string[], error: Error): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_APPROVE_FAILED,
    error,
    carAllowanceIDs,
  }
}

function updatingCarAllowance(employeeID: string, carAllowanceID: string): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_UPDATING,
    employeeID,
    carAllowanceID,
  }
}
export function updatedCarAllowance(
  employeeID: string,
  carAllowanceID: string,
  carAllowance: CarAllowance
): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_UPDATED,
    employeeID,
    carAllowanceID,
    carAllowance,
  }
}
function failedUpdatingCarAllowance(employeeID: string, carAllowanceID: string, error: Error): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_UPDATE_FAILED,
    error,
    employeeID,
    carAllowanceID,
  }
}

function deletingCarAllowance(carAllowanceID: string): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_DELETING,
    carAllowanceID,
  }
}
export function deletedCarAllowance(carAllowanceID: string): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_DELETED,
    carAllowanceID,
  }
}
function failedDeletingCarAllowance(carAllowanceID: string, error: Error): CarAllowanceAction {
  return {
    type: ActionTypes.CAR_ALLOWANCE_DELETE_FAILED,
    error,
    carAllowanceID,
  }
}

export function getCarAllowances(
  companyID: string | undefined,
  employeeID: string | undefined,
  fromDate: DateFormat,
  toDate: DateFormat
) {
  return (dispatch: React.Dispatch<any>): Promise<CarAllowance[] | void> => {
    dispatch(loadingCarAllowances(companyID, employeeID))
    return fetchCarAllowances(companyID, employeeID, fromDate, toDate)
      .then((res) => {
        dispatch(loadedCarAllowances(companyID, employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCarAllowances(companyID, employeeID, e))
        }
      })
  }
}

export function createCarAllowances(carAllowances: CarAllowanceMutableFields[]) {
  return (dispatch: React.Dispatch<any>): Promise<CarAllowance[] | void> => {
    const employeeID = carAllowances[0].employeeID
    dispatch(creatingCarAllowances(employeeID))
    return postCarAllowances(employeeID, carAllowances)
      .then((res) => {
        dispatch(createdCarAllowances(employeeID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingCarAllowances(employeeID, e))
        }
      })
  }
}

export function approveCarAllowances(carAllowanceIDs: string[]) {
  return (dispatch: React.Dispatch<any>): Promise<CarAllowance[] | void> => {
    dispatch(approvingCarAllowances(carAllowanceIDs))
    return patchCarAllowances('Approve', carAllowanceIDs)
      .then((res) => {
        dispatch(approvedCarAllowances(carAllowanceIDs, true))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedApprovingCarAllowances(carAllowanceIDs, e))
        }
      })
  }
}

export function unapproveCarAllowances(carAllowanceIDs: string[]) {
  return (dispatch: React.Dispatch<any>): Promise<CarAllowance[] | void> => {
    dispatch(approvingCarAllowances(carAllowanceIDs))
    return patchCarAllowances('Unapprove', carAllowanceIDs)
      .then((res) => {
        dispatch(approvedCarAllowances(carAllowanceIDs, false))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedApprovingCarAllowances(carAllowanceIDs, e))
        }
      })
  }
}

export function updateCarAllowance(carAllowance: CarAllowanceMutableFields) {
  return (dispatch: React.Dispatch<any>): Promise<CarAllowance | void> => {
    const carAllowanceID = carAllowance.id
    if (!carAllowanceID) {
      return PromiseVoid
    }
    dispatch(updatingCarAllowance(carAllowance.employeeID, carAllowanceID))
    return putCarAllowance(carAllowance)
      .then((res) => {
        dispatch(updatedCarAllowance(carAllowance.employeeID, carAllowanceID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingCarAllowance(carAllowance.employeeID, carAllowanceID, e))
        }
      })
  }
}

export function deleteCarAllowance(carAllowance: CarAllowance) {
  return (dispatch: React.Dispatch<any>): Promise<boolean | void> => {
    dispatch(deletingCarAllowance(carAllowance.id))
    return delCarAllowance(carAllowance.id)
      .then(() => {
        dispatch(deletedCarAllowance(carAllowance.id))
        return true
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedDeletingCarAllowance(carAllowance.id, e))
        }
      })
  }
}
