import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { updateActiveCompany } from '../actions/companies'
import {
  addUserInvite,
  deleteCompanyUser,
  deleteUserInvite,
  getCompanyUsers,
  getUserInvites,
  grantCompanyUserPermission,
  revokeCompanyUserPermission,
} from '../actions/company-users'
import { grantDepartmentUserPermissions, revokeDepartmentUserPermission } from '../actions/department-users'
import { getDepartments } from '../actions/departments'
import CompanyUsersComponent from '../components/company-users/CompanyUsers'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { UserPermission } from '../model/companyUser'
import { DepartmentPermission, DepartmentUserMutableFields } from '../model/departmentUser'
import { AlertReducer } from '../reducers/alerts'
import { CompanyReducer } from '../reducers/companies'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { DepartmentReducer } from '../reducers/departments'
import { UserReducer } from '../reducers/user'
import { UserInviteReducer } from '../reducers/userInvites'
import UserCompanyType from '../types/user-company-type'
import { getActiveCompany } from '../utils/cookie-utils'
import { formatLoadingText } from '../utils/loading-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps, splatString } from '../utils/route-utils'

type Reducers = {
  alerts: AlertReducer
  user: UserReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  departments: DepartmentReducer
  userInvites: UserInviteReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateActiveCompany: (companyID: string) => void
  getCompanyUsers: () => void
  deleteCompanyUser: (userID: string) => void
  getDepartments: () => void
  getUserInvites: () => void
  addUserInvite: (
    email: string,
    userType: UserCompanyType,
    permissions?: UserPermission[],
    departments?: DepartmentUserMutableFields[],
    name?: string,
    phoneNumberCountryCode?: string,
    phoneNumber?: string
  ) => void
  deleteUserInvite: (email: string) => void
  grantCompanyUserPermission: (userCompanyID: string, permission: UserPermission) => void
  revokeCompanyUserPermission: (userCompanyID: string, permissionID: string) => void
  grantDepartmentUserPermission: (
    userCompanyID: string,
    departmentID: string,
    permissions: DepartmentPermission[]
  ) => void
  revokeDepartmentUserPermission: (userCompanyID: string, departmentID: string) => void
}

function CompanyUsers(props: Reducers & Actions & RouteProps): ReactElement | null {
  const resolveSplat = () => {
    const parts = splatString(props.params.splat).split('/')
    const companyID = parts[0]
    return { companyID }
  }

  const { updateActiveCompany } = props
  useEffect(() => {
    const { companyID } = resolveSplat()
    const activeCompanyID = getActiveCompany()
    if (!activeCompanyID || activeCompanyID !== companyID) {
      updateActiveCompany(companyID)
    }
  })

  const { companies, companyUsers, getCompanyUsers, departments, getDepartments, userInvites, getUserInvites } = props
  useEffect(() => {
    const companyID = companies.company?.id
    if (!companyID) {
      return
    }
    if ((!companyUsers.loading && !companyUsers.loaded) || companyUsers.companyID !== companyID) {
      getCompanyUsers()
    }
    if ((!departments.loading && !departments.loaded) || departments.companyID !== companyID) {
      getDepartments()
    }
    if ((!userInvites.loading && !userInvites.loaded) || userInvites.companyID !== companyID) {
      getUserInvites()
    }
  })

  const company = companies.company
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  const loading =
    !props.companies.loaded || !props.companyUsers.loaded || !props.departments.loaded || !props.userInvites.loaded
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            { loading: !props.companies.loaded, text: 'virksomhed' },
            { loading: !props.companyUsers.loaded, text: 'brugere' },
            { loading: !props.departments.loaded, text: 'afdelinger' },
            { loading: !props.userInvites.loaded, text: 'invitationer' },
          ])}
        />
      </div>
    )
  }

  return (
    <CompanyUsersComponent
      alerts={props.alerts}
      user={props.user}
      company={company}
      companyUsers={props.companyUsers}
      departments={props.departments.departments}
      userInvites={props.userInvites}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      deleteCompanyUser={props.deleteCompanyUser}
      addUserInvite={props.addUserInvite}
      deleteUserInvite={props.deleteUserInvite}
      getCompanyUsers={props.getCompanyUsers}
      grantCompanyUserPermission={props.grantCompanyUserPermission}
      revokeCompanyUserPermission={props.revokeCompanyUserPermission}
      grantDepartmentUserPermission={props.grantDepartmentUserPermission}
      revokeDepartmentUserPermission={props.revokeDepartmentUserPermission}
    />
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    user: state.user,
    companies: state.companies,
    companyUsers: state.companyUsers,
    departments: state.departments,
    userInvites: state.userInvites,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    updateActiveCompany: updateActiveCompany,
    getCompanyUsers: getCompanyUsers,
    deleteCompanyUser: deleteCompanyUser,
    getDepartments: getDepartments,
    getUserInvites: getUserInvites,
    addUserInvite: addUserInvite,
    deleteUserInvite: deleteUserInvite,
    grantCompanyUserPermission: grantCompanyUserPermission,
    revokeCompanyUserPermission: revokeCompanyUserPermission,
    grantDepartmentUserPermission: grantDepartmentUserPermissions,
    revokeDepartmentUserPermission: revokeDepartmentUserPermission,
  }
)(CompanyUsers)
