import React from 'react'

import { fetchCompanyFeatures } from '../api/company-features'
import ActionTypes from '../constants/action-types'
import CompanyFeature from '../model/companyFeature'
import { CompanyFeatureAction } from '../reducers/companyFeatures'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingCompanyFeatures(): CompanyFeatureAction {
  return {
    type: ActionTypes.COMPANY_FEATURES_LOADING,
  }
}
export function loadedCompanyFeatures(companyID: string, companyFeatures: CompanyFeature[]): CompanyFeatureAction {
  return {
    type: ActionTypes.COMPANY_FEATURES_LOADED,
    companyFeatures,
    companyID,
  }
}
function failedLoadingCompanyFeatures(companyID: string, error: Error): CompanyFeatureAction {
  return {
    type: ActionTypes.COMPANY_FEATURES_LOAD_FAILED,
    error,
    companyID,
  }
}

export function addedCompanyFeature(companyID: string, res: CompanyFeature): CompanyFeatureAction {
  return {
    type: ActionTypes.COMPANY_FEATURE_ADDED,
    companyID,
    companyFeature: res,
  }
}
export function deletedCompanyFeature(companyID: string, companyFeatureID: string): CompanyFeatureAction {
  return {
    type: ActionTypes.COMPANY_FEATURE_DELETED,
    companyID,
    companyFeatureID,
  }
}

export function getCompanyFeatures(companyID?: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<CompanyFeature[] | void> => {
    if (!companyID) {
      companyID = getCompanyID(getState)
    }
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingCompanyFeatures())
    return fetchCompanyFeatures(companyID)
      .then((res) => {
        dispatch(loadedCompanyFeatures(companyID!, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingCompanyFeatures(companyID!, e))
        }
      })
  }
}
