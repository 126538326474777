import React from 'react'

import Select from '../select'

class MiniSelect extends React.Component {
  render() {
    return <Select size="small" {...this.props} />
  }
}

MiniSelect.Option = Select.Option

export default MiniSelect
