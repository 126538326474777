import React from 'react'

import { fetchAccountingDimensions, putAccountingDimensions } from '../api/accounting-dimensions'
import ActionTypes from '../constants/action-types'
import AccountingDimension, {
  AccountingDimensionCreation,
  AccountingDimensionMutable,
} from '../model/accountingDimension'
import { AccountingDimensionAction } from '../reducers/accountingDimensions'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingAccountingDimensions(companyID: string): AccountingDimensionAction {
  return {
    type: ActionTypes.ACCOUNTING_DIMENSIONS_LOADING,
    companyID,
  }
}
function loadedAccountingDimensions(
  companyID: string,
  accountingDimensions: AccountingDimension[]
): AccountingDimensionAction {
  return {
    type: ActionTypes.ACCOUNTING_DIMENSIONS_LOADED,
    accountingDimensions,
    companyID,
  }
}
function failedLoadingAccountingDimensions(companyID: string, error: Error): AccountingDimensionAction {
  return {
    type: ActionTypes.ACCOUNTING_DIMENSIONS_LOAD_FAILED,
    error,
    companyID,
  }
}

function updatingAccountingDimensions(companyID: string): AccountingDimensionAction {
  return {
    type: ActionTypes.ACCOUNTING_DIMENSIONS_UPDATING,
    companyID,
  }
}
function updatedAccountingDimensions(
  companyID: string,
  accountingDimensions: AccountingDimension[]
): AccountingDimensionAction {
  return {
    type: ActionTypes.ACCOUNTING_DIMENSIONS_UPDATED,
    companyID,
    accountingDimensions,
  }
}
function failedUpdatingAccountingDimensions(companyID: string, error: Error): AccountingDimensionAction {
  return {
    type: ActionTypes.ACCOUNTING_DIMENSIONS_UPDATE_FAILED,
    companyID,
    error,
  }
}

export function addedAccountingDimension(
  companyID: string,
  accountingDimension: AccountingDimension
): AccountingDimensionAction {
  return {
    type: ActionTypes.ACCOUNTING_DIMENSION_ADDED,
    companyID,
    accountingDimension,
  }
}

export function updatedAccountingDimension(
  companyID: string,
  accountingDimension: AccountingDimension
): AccountingDimensionAction {
  return {
    type: ActionTypes.ACCOUNTING_DIMENSION_UPDATED,
    companyID,
    accountingDimension,
  }
}

export function getAccountingDimensions() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AccountingDimension[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(loadingAccountingDimensions(companyID))
    return fetchAccountingDimensions(companyID)
      .then((res) => {
        dispatch(loadedAccountingDimensions(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          if (e.type === 'NotFound' || e.type === 'InvalidRequest') {
            dispatch(loadedAccountingDimensions(companyID, []))
          } else {
            dispatch(failedLoadingAccountingDimensions(companyID, e))
          }
        }
      })
  }
}

export function selectAccountingDimensions(
  accountingDimensions: (AccountingDimensionMutable | AccountingDimensionCreation)[]
) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AccountingDimension[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(updatingAccountingDimensions(companyID))
    return putAccountingDimensions(companyID, accountingDimensions)
      .then((res) => {
        dispatch(updatedAccountingDimensions(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingAccountingDimensions(companyID, e))
        }
      })
  }
}
