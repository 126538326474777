import React, { ReactElement, useEffect } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { CompanyLogoCreate } from '../../api/company-logos'
import { ApiKeyCreationFields } from '../../model/apiKey'
import { AssetCategoryMutableFields } from '../../model/assetCategory'
import AsynchronousTask from '../../model/asynchronousTask'
import BankAccount from '../../model/bankAccount'
import Company, { CompanyMutableFields } from '../../model/company'
import CompanyDeviationConfiguration, {
  CompanyDeviationConfigurationMutableFields,
} from '../../model/companyDeviationConfiguration'
import CompanyPricingPackage from '../../model/companyPricingPackage'
import CompanySetting from '../../model/companySetting'
import CompanyUser from '../../model/companyUser'
import { DepartmentCreationFields, DepartmentMutableFields } from '../../model/department'
import DocumentCategory, { DocumentCategoryMutableFields } from '../../model/documentCategory'
import Employee from '../../model/employee'
import { ExpenseCategoryBaseFields, ExpenseCategoryUpdateFields } from '../../model/expenseCategory'
import { ExternalSalaryFileSettingMutableFields } from '../../model/externalSalaryFileSetting'
import LeaveType from '../../model/leaveType'
import { TransferSetting } from '../../model/paymentConfiguration'
import { SalaryTypeMutableFields } from '../../model/salaryType'
import StripeConfiguration, { StripeConfigurationSetup } from '../../model/stripeConfiguration'
import { PaymentMethod } from '../../model/transfer'
import { DateFormat } from '../../model/types'
import VacationCalendar from '../../model/vacationCalendar'
import { AccountingDimensionReducer } from '../../reducers/accountingDimensions'
import { AgreementReducer } from '../../reducers/agreements'
import { AlertReducer } from '../../reducers/alerts'
import { ApiKeyReducer } from '../../reducers/apiKeys'
import { AssetCategoryReducer } from '../../reducers/assetCategories'
import { AsynchronousTaskReducer } from '../../reducers/asynchronousTasks'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyAccountPlanReducer } from '../../reducers/companyAccountPlans'
import { CompanyBankAccountReducer } from '../../reducers/companyBankAccounts'
import { CompanyDeviationConfigurationReducer } from '../../reducers/companyDeviationConfigurations'
import { CompanyFeatureReducer } from '../../reducers/companyFeatures'
import { CompanyLogoReducer } from '../../reducers/companyLogo'
import { CompanyPaymentIntegrationReducer } from '../../reducers/companyPaymentIntegrations'
import { CompanyPricingPackageReducer } from '../../reducers/companyPricingPackages'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { DepartmentReducer } from '../../reducers/departments'
import { DocumentCategoryReducer } from '../../reducers/documentCategories'
import { EmployeeReducer } from '../../reducers/employees'
import { ExpenseCategoryReducer } from '../../reducers/expenseCategories'
import { ExternalSalaryFileSettingReducer } from '../../reducers/externalSalaryFileSettings'
import { InvoiceReducer } from '../../reducers/invoices'
import { LeaveTypeReducer } from '../../reducers/leaveTypes'
import { NetsMessageReducer } from '../../reducers/netsMessages'
import { PaymentConfigurationReducer } from '../../reducers/paymentConfigurations'
import { PayRollReducer } from '../../reducers/payRolls'
import { PricingPackageReducer } from '../../reducers/pricingPackages'
import { SalaryCycleReducer } from '../../reducers/salaryCycles'
import { SalaryTypeReducer } from '../../reducers/salaryTypes'
import { StripeConfigurationReducer } from '../../reducers/stripeConfiguration'
import { SupportAccessReducer } from '../../reducers/supportAccess'
import { TaxCardReducer } from '../../reducers/taxCards'
import { TransferReducer } from '../../reducers/transfers'
import { UserReducer } from '../../reducers/user'
import { UserCompanyReducer } from '../../reducers/userCompanies'
import { VacationCalendarReducer } from '../../reducers/vacationCalendars'
import { WarningReducer } from '../../reducers/warnings'
import { paths } from '../../routes'
import { isPricingPackageGroup } from '../../utils/pricing-package-utils'
import { t } from '../../utils/translation-utils'
import { CompaniesSingleSection } from '../types'
import Alerts from '../widgets/Alerts'
import AdvancedSettingsTab from './AdvancedSettingsTab'
import ApiKeysTab from './ApiKeysTab'
import AssetCategoriesTab from './asset-categories/AssetCategoriesTab'
import CompanyBankAccount from './CompanyBankAccount'
import CompanyTab from './CompanyTab'
import DepartmentsTab from './DepartmentsTab'
import DeviationsTab from './DeviationsTab'
import DocumentCategoriesTab from './DocumentCategoriesTab'
import ExpenseCategoriesTab from './ExpenseCategoriesTab'
import ExternalSalaryFileSettingTab from './ExternalSalaryFileSettingsTab'
import InvoicesTab from './InvoicesTab'
import LeaveTypeSettingsTab from './LeaveTypeSettingsTab'
import NetsTab from './NetsTab'
import PayrollTab from './payroll/PayrollTab'
import PricingPackagePanel from './pricing-package/PricingPackagePanel'
import ProductionUnitsTab from './ProductionUnitsTab'
import ReimbursementSettingsTab from './ReimbursementSettingsTab'
import ReportTab from './ReportTab'
import SalaryTypesTab from './SalaryTypesTab'
import SupportTab from './SupportTab'
import TaxCardsTab from './TaxCardsTab'
import TransferImmediatePayTab from './TransferImmediatePayTab'
import TransferNemKontoTab from './TransferNemKontoTab'
import TransferSettingsTab from './TransferSettingsTab'
import TransfersTab from './TransfersTab'
import UserNotificationTab from './UserNotificationTab'
import VacationCalendarTab from './VacationCalendarTab'
import VacationSettingsTab from './VacationSettingsTab'

import './CompaniesSingle.css'

type Props = {
  section: CompaniesSingleSection
  subsection?: string
  alerts: AlertReducer
  assetCategories: AssetCategoryReducer
  user: UserReducer
  company: Company
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  companyAccountPlans: CompanyAccountPlanReducer
  userCompanies: UserCompanyReducer
  agreements: AgreementReducer
  asynchronousTasks: AsynchronousTaskReducer
  invoices: InvoiceReducer
  transfers: TransferReducer
  paymentConfigurations: PaymentConfigurationReducer
  companyPaymentIntegrations: CompanyPaymentIntegrationReducer
  companyBankAccounts: CompanyBankAccountReducer
  companyDeviationConfigurations: CompanyDeviationConfigurationReducer
  stripeConfiguration: StripeConfigurationReducer
  netsMessages: NetsMessageReducer
  departments: DepartmentReducer
  documentCategories: DocumentCategoryReducer
  employees: EmployeeReducer
  expenseCategories: ExpenseCategoryReducer
  leaveTypes: LeaveTypeReducer
  payRolls: PayRollReducer
  salaryCycles: SalaryCycleReducer
  salaryTypes: SalaryTypeReducer
  supportAccess: SupportAccessReducer
  pricingPackages: PricingPackageReducer
  companyPricingPackages: CompanyPricingPackageReducer
  companyFeatures: CompanyFeatureReducer
  taxCards: TaxCardReducer
  companyLogo: CompanyLogoReducer
  warnings: WarningReducer
  apiKeys: ApiKeyReducer
  vacationCalendars: VacationCalendarReducer
  externalSalaryFileSettings: ExternalSalaryFileSettingReducer
  accountingDimensions: AccountingDimensionReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateCompany: (company: CompanyMutableFields) => Promise<Company | void>
  updateUserCompany: (userCompany: CompanyUser) => void
  getInvoices: () => void
  getTransfers: (
    payRollID: string | undefined,
    companyID: string | undefined,
    fromDate?: DateFormat,
    offset?: number
  ) => void
  automateTransfer: (transferID: string, paymentMethod: PaymentMethod) => void
  tryTransferAgain: (transferID: string) => void
  updateTransferDate: (transferID: string, date: DateFormat) => void
  getPaymentConfigurations: () => void
  getCompanyPaymentIntegrations: () => void
  updatePaymentConfiguration: (transferSettings: TransferSetting[]) => void
  getStripeConfiguration: () => void
  addStripeConfiguration: () => Promise<StripeConfigurationSetup | void>
  updateStripeConfiguration: (setupIntentID: string) => Promise<StripeConfiguration | void>
  getNetsMessages: () => void
  getDepartments: () => void
  addDepartment: (department: DepartmentCreationFields) => void
  updateDepartment: (department: DepartmentMutableFields) => void
  deleteDepartment: (departmentID: string) => void
  getDocumentCategories: () => void
  addDocumentCategory: (category: DocumentCategoryMutableFields) => void
  updateDocumentCategory: (documentCategory: DocumentCategory) => void
  getExpenseCategories: () => void
  addExpenseCategory: (expenseCategory: ExpenseCategoryBaseFields) => void
  updateExpenseCategory: (expenseCategory: ExpenseCategoryUpdateFields) => void
  deleteExpenseCategory: (expenseCategoryID: string) => void
  getPayRolls: () => void
  getSalaryCycles: () => void
  addSalaryType: (salaryType: SalaryTypeMutableFields) => void
  updateSalaryType: (salaryType: SalaryTypeMutableFields) => void
  deleteSalaryType: (salaryTypeID: string) => void
  reactivateSalaryType: (salaryTypeID: string) => void
  loadSupportAccess: () => void
  grantSupportAccess: () => void
  revokeSupportAccess: () => void
  getPricingPackages: (packageIDs: string[]) => void
  addCompanyPricingPackage: (pricingPackageID: string) => Promise<CompanyPricingPackage | void>
  activateCompanyPricingPackageCode: (code: string) => void
  getCompanyPricingPackages: () => void
  deleteCompanyPricingPackage: (companyPricingPackageID: string) => Promise<boolean | void>
  getTaxCards: () => void
  getCompanyLogo: () => void
  updateCompanyLogo: (image: CompanyLogoCreate) => void
  deleteCompanyLogo: () => void
  getApiKeys: () => void
  addApiKey: (apiKey: ApiKeyCreationFields) => void
  deleteApiKey: (apiKeyID: string) => void
  startExcelEmployeesData: (id: string) => Promise<AsynchronousTask | void>
  storeStagedImportData: (id: string) => Promise<AsynchronousTask | void>
  getAsynchronousTasks: (companyID: string) => void
  toggleNemKontoAllEmployees: (company: Company, enable: boolean) => void
  getCompanyDeviationConfigurations: () => void
  addCompanyDeviationConfiguration: (companyDeviationConfiguration: CompanyDeviationConfigurationMutableFields) => void
  updateCompanyDeviationConfiguration: (companyDeviationConfiguration: CompanyDeviationConfiguration) => void
  deleteCompanyDeviationConfiguration: (companyDeviationConfigurationID: string) => void
  enableCompanySettings: (companyID: string, enable: CompanySetting[]) => Promise<Company | void>
  disableCompanySettings: (companyID: string, disable: CompanySetting[]) => Promise<Company | void>
  getCompanyAccountPlans: () => void
  updateLeaveType: (leaveTypeID: string, employeeSelectable: boolean) => void
  updateLeaveSubTypes: (leaveTypeID: string, selectableLeaveSubTypeIDs: string[]) => Promise<LeaveType | void>
  updateEmployee: (employee: Employee) => void
  getVacationCalendar: (companyID: string) => void
  updateVacationCalendar: (companyID: string, vacationCalendar: VacationCalendar) => void
  getExternalSalaryFileSettings: (companyID: string) => void
  saveExternalSalaryFileSettings: (companyID: string, settings: ExternalSalaryFileSettingMutableFields[]) => void
  addCompanyBankAccount: (bankAccount: BankAccount) => void
  createAssetCategory: (assetCategory: AssetCategoryMutableFields) => void
  updateAssetCategory: (assetCategory: AssetCategoryMutableFields) => void
  deleteAssetCategory: (assetCategoryID: string) => void
  getAssetCategories: () => void
  approveTransfer: (transferID: string) => void
  declineTransfer: (transferID: string) => void
  getAccountingDimensions: () => void
}

export default function CompaniesSingle(props: Props): ReactElement | null {
  const getMissingPackages = () => {
    const pricingPackages = props.pricingPackages.pricingPackages.map((v) => v.id)
    const companyPricingPackages = props.companyPricingPackages.companyPricingPackages
      .map((v) => v.pricingPackageID)
      .toArray()
    return companyPricingPackages.filter((v) => !pricingPackages.includes(v))
  }

  const { companyPricingPackages, getCompanyPricingPackages } = props

  useEffect(() => {
    if (!companyPricingPackages.loading && !companyPricingPackages.loaded) {
      getCompanyPricingPackages()
    }
  })

  const { pricingPackages, getPricingPackages } = props

  useEffect(() => {
    if (companyPricingPackages.loaded) {
      const missingPackages = getMissingPackages()
      if (!pricingPackages.loading && (!pricingPackages.loaded || missingPackages.length > 0)) {
        getPricingPackages(missingPackages)
      }
    }
  })

  const isAdmin = (): boolean => {
    return (
      props.companyUsers.companyUsers
        .find(
          (companyUser) =>
            props.company && companyUser.companyID === props.company.id && companyUser.userID === props.user.id
        )
        ?.permissions?.some((v) => v.permission === 'Admin') || false
    )
  }

  const hasPaidPackage = () => {
    return isPricingPackageGroup(
      props.pricingPackages.pricingPackages.toArray(),
      props.companyPricingPackages.companyPricingPackages.toArray(),
      props.company.id,
      ['Automatic', 'Premium']
    )
  }

  const getMenuItems = () => {
    const menuItems: {
      key: CompaniesSingleSection | 'club'
      keys?: CompaniesSingleSection[]
      title: string
      url?: string
    }[] = []
    menuItems.push({
      key: 'company',
      keys: ['production-units', 'advance-settings', 'leave-type-settings'],
      title: t('company.menu.company'),
    })
    menuItems.push({
      key: 'pay',
      keys: ['salary-types', 'nets-messages'],
      title: t('company.menu.pay'),
    })
    menuItems.push({ key: 'invoices', title: t('company.menu.invoices') })
    menuItems.push({
      key: 'transfers',
      keys: ['transfer-settings', 'transfer-nemkonto', 'transfer-immediate-pay'],
      title: t('company.menu.transfers'),
    })
    menuItems.push({ key: 'departments', title: t('company.menu.departments') })
    menuItems.push({ key: 'pricing', title: t('company.menu.pricing') })
    if (isAdmin()) {
      menuItems.push({ key: 'api-keys', title: t('company.menu.api_keys') })
    }
    menuItems.push({ key: 'support', title: t('company.menu.support') })
    menuItems.push({ key: 'reports', title: t('company.menu.reports') })
    if (hasPaidPackage()) {
      menuItems.push({ key: 'club', url: 'https://salary.dk/fordelsklub', title: t('company.menu.club') })
    }
    return menuItems
  }

  const section = props.section
  const company = props.company
  return (
    <div className="companies-single">
      <div className="companies-single-menu">
        {getMenuItems().map((item) => {
          return (
            <Link
              key={item.key}
              to={item.url ? item.url : '/' + paths.COMPANIES + '/' + company.id + '/' + item.key}
              className={section === item.key || (item.keys && item.keys.indexOf(section) !== -1) ? 'active' : ''}
              target={item.url ? '_blank' : ''}
            >
              {item.title}
            </Link>
          )
        })}
      </div>
      <div className="companies-single-main">
        <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />

        {section === 'company' && (
          <CompanyTab
            company={props.company}
            companies={props.companies}
            employees={props.employees}
            agreements={props.agreements}
            companyFeatures={props.companyFeatures.companyFeatures}
            salaryCycles={props.salaryCycles}
            companyLogo={props.companyLogo}
            addAlert={props.addAlert}
            updateCompany={props.updateCompany}
            getSalaryCycles={props.getSalaryCycles}
            getCompanyLogo={props.getCompanyLogo}
            updateCompanyLogo={props.updateCompanyLogo}
            deleteCompanyLogo={props.deleteCompanyLogo}
            enableCompanySettings={props.enableCompanySettings}
            disableCompanySettings={props.disableCompanySettings}
          />
        )}
        {section === 'advance-settings' && (
          <AdvancedSettingsTab
            company={props.company}
            companies={props.companies}
            companyFeatures={props.companyFeatures.companyFeatures}
            alerts={props.alerts}
            addAlert={props.addAlert}
            enableCompanySettings={props.enableCompanySettings}
            disableCompanySettings={props.disableCompanySettings}
            updateCompany={props.updateCompany}
          />
        )}
        {section === 'vacation-settings' && (
          <VacationSettingsTab
            company={props.company}
            companies={props.companies}
            companyFeatures={props.companyFeatures.companyFeatures}
            leaveTypes={props.leaveTypes.leaveTypes}
            alerts={props.alerts}
            addAlert={props.addAlert}
            updateCompany={props.updateCompany}
          />
        )}
        {section === 'vacation-calendar' && (
          <VacationCalendarTab
            company={props.company}
            vacationCalendars={props.vacationCalendars}
            addAlert={props.addAlert}
            getVacationCalendar={props.getVacationCalendar}
            updateVacationCalendar={props.updateVacationCalendar}
          />
        )}
        {section === 'production-units' && (
          <ProductionUnitsTab
            company={props.company}
            companies={props.companies}
            addAlert={props.addAlert}
            updateCompany={props.updateCompany}
          />
        )}
        {section === 'pay' && (
          <PayrollTab
            company={props.company}
            companies={props.companies}
            companyFeatures={props.companyFeatures.companyFeatures}
            companyUser={props.companyUsers.companyUser}
            companyUsers={props.companyUsers.companyUsers}
            user={props.user}
            userCompanies={props.userCompanies.userCompanies}
            companyPaymentIntegrations={props.companyPaymentIntegrations}
            addAlert={props.addAlert}
            updateCompany={props.updateCompany}
            updateUserCompany={props.updateUserCompany}
            getCompanyPaymentIntegrations={props.getCompanyPaymentIntegrations}
          />
        )}
        {section === 'user-notifications' && (
          <UserNotificationTab
            company={props.company}
            userCompanies={props.userCompanies}
            addAlert={props.addAlert}
            updateUserCompany={props.updateUserCompany}
          />
        )}
        {section === 'salary-types' && (
          <SalaryTypesTab
            company={props.company}
            salaryTypes={props.salaryTypes}
            addAlert={props.addAlert}
            addSalaryType={props.addSalaryType}
            updateSalaryType={props.updateSalaryType}
            deleteSalaryType={props.deleteSalaryType}
            reactivateSalaryType={props.reactivateSalaryType}
          />
        )}
        {section === 'nets-messages' && (
          <NetsTab company={props.company} netsMessages={props.netsMessages} getNetsMessages={props.getNetsMessages} />
        )}
        {section === 'deviations' && (
          <DeviationsTab
            company={props.company}
            companyDeviationConfigurations={props.companyDeviationConfigurations}
            addAlert={props.addAlert}
            getCompanyDeviationConfigurations={props.getCompanyDeviationConfigurations}
            addCompanyDeviationConfiguration={props.addCompanyDeviationConfiguration}
            updateCompanyDeviationConfiguration={props.updateCompanyDeviationConfiguration}
            deleteCompanyDeviationConfiguration={props.deleteCompanyDeviationConfiguration}
          />
        )}
        {section === 'invoices' && (
          <InvoicesTab
            companies={props.companies}
            company={props.company}
            invoices={props.invoices}
            companyPaymentIntegrations={props.companyPaymentIntegrations}
            stripeConfiguration={props.stripeConfiguration}
            addAlert={props.addAlert}
            getInvoices={props.getInvoices}
            getCompanyPaymentIntegrations={props.getCompanyPaymentIntegrations}
            getStripeConfiguration={props.getStripeConfiguration}
            addStripeConfiguration={props.addStripeConfiguration}
            updateStripeConfiguration={props.updateStripeConfiguration}
            updateCompany={props.updateCompany}
          />
        )}
        {section === 'transfers' && (
          <TransfersTab
            asynchronousTasks={props.asynchronousTasks}
            companies={props.companies}
            company={props.company}
            companyFeatures={props.companyFeatures.companyFeatures}
            companyPaymentIntegrations={props.companyPaymentIntegrations}
            employees={props.employees.employees}
            transfers={props.transfers}
            addAlert={props.addAlert}
            getCompanyPaymentIntegrations={props.getCompanyPaymentIntegrations}
            getTransfers={props.getTransfers}
            automateTransfer={props.automateTransfer}
            tryTransferAgain={props.tryTransferAgain}
            updateTransferDate={props.updateTransferDate}
            updateCompany={props.updateCompany}
            getAsynchronousTasks={props.getAsynchronousTasks}
            approveTransfer={props.approveTransfer}
            declineTransfer={props.declineTransfer}
            enableCompanySettings={props.enableCompanySettings}
            disableCompanySettings={props.disableCompanySettings}
          />
        )}
        {section === 'transfer-settings' && (
          <TransferSettingsTab
            company={props.company}
            paymentConfigurations={props.paymentConfigurations}
            user={props.user}
            companyPaymentIntegrations={props.companyPaymentIntegrations}
            companyFeatures={props.companyFeatures.companyFeatures}
            addAlert={props.addAlert}
            getPaymentConfigurations={props.getPaymentConfigurations}
            getCompanyPaymentIntegrations={props.getCompanyPaymentIntegrations}
            updatePaymentConfiguration={props.updatePaymentConfiguration}
          />
        )}
        {section === 'transfer-bank-account' && (
          <CompanyBankAccount
            company={props.company}
            companyBankAccounts={props.companyBankAccounts}
            addAlert={props.addAlert}
            addCompanyBankAccount={props.addCompanyBankAccount}
          />
        )}
        {section === 'transfer-nemkonto' && (
          <TransferNemKontoTab
            companies={props.companies}
            company={props.company}
            companyPaymentIntegrations={props.companyPaymentIntegrations}
            addAlert={props.addAlert}
            getCompanyPaymentIntegrations={props.getCompanyPaymentIntegrations}
            updateCompany={props.updateCompany}
            toggleNemKontoAllEmployees={props.toggleNemKontoAllEmployees}
          />
        )}
        {section === 'transfer-immediate-pay' && (
          <TransferImmediatePayTab
            companies={props.companies}
            company={props.company}
            employees={props.employees.employees}
            companyFeatures={props.companyFeatures.companyFeatures}
            companyPaymentIntegrations={props.companyPaymentIntegrations}
            addAlert={props.addAlert}
            updateCompany={props.updateCompany}
            getCompanyPaymentIntegrations={props.getCompanyPaymentIntegrations}
            enableCompanySettings={props.enableCompanySettings}
            disableCompanySettings={props.disableCompanySettings}
          />
        )}
        {section === 'tax-cards' && (
          <TaxCardsTab
            company={props.company}
            employees={props.employees.employees}
            taxCards={props.taxCards}
            getTaxCards={props.getTaxCards}
          />
        )}
        {section === 'departments' && (
          <DepartmentsTab
            departments={props.departments}
            employees={props.employees.employees}
            accountingDimensions={props.accountingDimensions}
            addAlert={props.addAlert}
            getDepartments={props.getDepartments}
            addDepartment={props.addDepartment}
            updateDepartment={props.updateDepartment}
            deleteDepartment={props.deleteDepartment}
            getAccountingDimensions={props.getAccountingDimensions}
          />
        )}
        {section === 'document-categories' && (
          <DocumentCategoriesTab
            documentCategories={props.documentCategories}
            addAlert={props.addAlert}
            getDocumentCategories={props.getDocumentCategories}
            addDocumentCategory={props.addDocumentCategory}
            updateDocumentCategory={props.updateDocumentCategory}
          />
        )}
        {section === 'expense-categories' && (
          <ExpenseCategoriesTab
            expenseCategories={props.expenseCategories}
            employees={props.employees}
            companyAccountPlans={props.companyAccountPlans}
            addAlert={props.addAlert}
            getExpenseCategories={props.getExpenseCategories}
            addExpenseCategory={props.addExpenseCategory}
            updateExpenseCategory={props.updateExpenseCategory}
            deleteExpenseCategory={props.deleteExpenseCategory}
            updateEmployee={props.updateEmployee}
            getCompanyAccountPlans={props.getCompanyAccountPlans}
          />
        )}
        {section === 'reimbursement-settings' && (
          <ReimbursementSettingsTab
            company={props.company}
            companies={props.companies}
            employees={props.employees}
            companyFeatures={props.companyFeatures.companyFeatures}
            companyPaymentIntegrations={props.companyPaymentIntegrations}
            addAlert={props.addAlert}
            updateCompany={props.updateCompany}
            updateEmployee={props.updateEmployee}
            getCompanyPaymentIntegrations={props.getCompanyPaymentIntegrations}
            enableCompanySettings={props.enableCompanySettings}
            disableCompanySettings={props.disableCompanySettings}
          />
        )}
        {section === 'asset-categories' && (
          <AssetCategoriesTab
            alerts={props.alerts}
            addAlert={props.addAlert}
            removeAlert={props.removeAlert}
            createAssetCategory={props.createAssetCategory}
            updateAssetCategory={props.updateAssetCategory}
            deleteAssetCategory={props.deleteAssetCategory}
            getAssetCategories={props.getAssetCategories}
            company={props.company}
            assetCategories={props.assetCategories}
          />
        )}
        {section === 'leave-type-settings' && (
          <LeaveTypeSettingsTab
            company={props.company}
            leaveTypes={props.leaveTypes}
            addAlert={props.addAlert}
            updateLeaveType={props.updateLeaveType}
            updateLeaveSubTypes={props.updateLeaveSubTypes}
          />
        )}
        {section === 'pricing' && (
          <PricingPackagePanel
            company={props.company}
            companyFeatures={props.companyFeatures.companyFeatures}
            pricingPackages={props.pricingPackages}
            companyPricingPackages={props.companyPricingPackages}
            getPricingPackages={props.getPricingPackages}
            addCompanyPricingPackage={props.addCompanyPricingPackage}
            activateCompanyPricingPackageCode={props.activateCompanyPricingPackageCode}
            getCompanyPricingPackages={props.getCompanyPricingPackages}
            deleteCompanyPricingPackage={props.deleteCompanyPricingPackage}
            addAlert={props.addAlert}
          />
        )}
        {isAdmin() && section === 'api-keys' && (
          <ApiKeysTab
            apiKeys={props.apiKeys}
            addAlert={props.addAlert}
            getApiKeys={props.getApiKeys}
            addApiKey={props.addApiKey}
            deleteApiKey={props.deleteApiKey}
          />
        )}
        {section === 'support' && (
          <SupportTab
            company={props.company}
            agreements={props.agreements}
            asynchronousTasks={props.asynchronousTasks}
            salaryTypes={props.salaryTypes.salaryTypes}
            employees={props.employees.employees}
            supportAccess={props.supportAccess}
            addAlert={props.addAlert}
            grantSupportAccess={props.grantSupportAccess}
            revokeSupportAccess={props.revokeSupportAccess}
            loadSupportAccess={props.loadSupportAccess}
            startExcelEmployeesData={props.startExcelEmployeesData}
            storeStagedImportData={props.storeStagedImportData}
            getAsynchronousTasks={props.getAsynchronousTasks}
          />
        )}
        {section === 'reports' && (
          <ReportTab
            asynchronousTasks={props.asynchronousTasks}
            company={props.company}
            payRolls={props.payRolls}
            addAlert={props.addAlert}
            getPayRolls={props.getPayRolls}
            getAsynchronousTasks={props.getAsynchronousTasks}
            updateCompany={props.updateCompany}
          />
        )}
        {section === 'external-salary-file-settings' && (
          <ExternalSalaryFileSettingTab
            externalSalaryFileType={props.subsection}
            company={props.company}
            externalSalaryFileSettings={props.externalSalaryFileSettings}
            addAlert={props.addAlert}
            getExternalSalaryFileSettings={props.getExternalSalaryFileSettings}
            saveExternalSalaryFileSettings={props.saveExternalSalaryFileSettings}
          />
        )}
      </div>
    </div>
  )
}
