import React, { ReactElement } from 'react'

import Button from '../components/elements/button'
import Card from '../components/elements/card'
import Headline from '../components/elements/Headline'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import { CompanyReducer } from '../reducers/companies'
import { getAccessToken } from '../utils/cookie-utils'
import { companySelected } from '../utils/hooks-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { getHost } from '../utils/request-utils'
import { RouteProps } from '../utils/route-utils'
import { t } from '../utils/translation-utils'

type Reducers = {
  companies: CompanyReducer
}

function SSO(props: RouteProps & Reducers): ReactElement | null {
  const companyID = props.location.pathname.split('/')[2]
  if (!companyID) {
    jsBrowserHistory.push('/')
    return null
  }

  const company = props.companies.companies.find((company) => company.id === companyID)
  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  return (
    <Card>
      <Headline>{t('sso.page.title', { name: company.name })} </Headline>
      <Button
        type="primary"
        onClick={() => {
          document.location = getHost() + '/samlStart?companyID=' + companyID + '&accessToken=' + getAccessToken()
        }}
      >
        {t('sso.page.yes')}
      </Button>
      <Button
        type="danger"
        onClick={() => {
          companySelected(null)
          jsBrowserHistory.push('/')
        }}
      >
        {t('sso.page.no')}
      </Button>
    </Card>
  )
}

export default connectToReducer<Reducers, any, RouteProps>(
  (state) => ({
    companies: state.companies,
  }),
  {}
)(SSO)
