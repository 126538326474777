import React from 'react'

import { fetchOutgoingLeads, postOutgoingLead } from '../api/outgoing-leads'
import ActionTypes from '../constants/action-types'
import OutgoingLead, { OutgoingLeadType } from '../model/outgoingLead'
import { OutgoingLeadAction } from '../reducers/outgoingLeads'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingOutgoingLeads(): OutgoingLeadAction {
  return {
    type: ActionTypes.OUTGOING_LEAD_LOADING,
  }
}
export function loadedOutgoingLeads(companyID: string, outgoingLeads: OutgoingLead[]): OutgoingLeadAction {
  return {
    type: ActionTypes.OUTGOING_LEAD_LOADED,
    outgoingLeads,
    companyID,
  }
}
function failedLoadingOutgoingLeads(companyID: string, error: Error): OutgoingLeadAction {
  return {
    type: ActionTypes.OUTGOING_LEAD_LOAD_FAILED,
    error,
    companyID,
  }
}

function creatingOutgoingLead(): OutgoingLeadAction {
  return {
    type: ActionTypes.OUTGOING_LEAD_CREATING,
  }
}
export function createdOutgoingLead(companyID: string, outgoingLead: OutgoingLead): OutgoingLeadAction {
  return {
    type: ActionTypes.OUTGOING_LEAD_CREATED,
    companyID,
    outgoingLead,
  }
}
function failedCreatingOutgoingLead(companyID: string, error: Error): OutgoingLeadAction {
  return {
    type: ActionTypes.OUTGOING_LEAD_CREATE_FAILED,
    error,
    companyID,
  }
}

export function getOutgoingLeads() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<OutgoingLead[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingOutgoingLeads())
    return fetchOutgoingLeads(companyID)
      .then((res) => {
        dispatch(loadedOutgoingLeads(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingOutgoingLeads(companyID, e))
        }
      })
  }
}

export function createOutgoingLead(leadType: OutgoingLeadType) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<OutgoingLead | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(creatingOutgoingLead())
    return postOutgoingLead(companyID, leadType)
      .then((res) => {
        dispatch(createdOutgoingLead(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedCreatingOutgoingLead(companyID, e))
        }
      })
  }
}
