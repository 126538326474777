import React from 'react'
import { findDOMNode } from 'react-dom'

class InputElement extends React.Component {
  focus = () => {
    this.ele.focus ? this.ele.focus() : findDOMNode(this.ele).focus()
  }
  blur = () => {
    this.ele.blur ? this.ele.blur() : findDOMNode(this.ele).blur()
  }
  saveRef = (ele) => {
    this.ele = ele
    const childRef = this.props.children.ref
    if (typeof childRef === 'function') {
      childRef(ele)
    }
  }
  render() {
    return React.cloneElement(
      this.props.children,
      {
        ...this.props,
        forwardRef: this.saveRef,
      },
      null
    )
  }
}

export default InputElement
