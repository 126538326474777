import React, { ReactElement } from 'react'

import { AffiliationType, NationalIDType } from '../../model/employee'
import Language from '../../types/language'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatLanguage } from '../../utils/format-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import Subtitle from '../elements/Subtitle'
import PaySlipsTransport from '../employees-single/pay-slips/PaySlipsTransport'

type Props = {
  affiliationType: AffiliationType
  language: Language
  nationalIDType: NationalIDType
  paySlipTransportMitDK: boolean
  paySlipTransportEBoks: boolean
  paySlipTransportEMail: boolean
  paySlipTransportSMS: boolean
  email?: string
  phoneNumberCountryCode?: string
  phoneNumber?: string
  hasPremiumPackage: boolean
}

export type CommunicationFields = {
  readonly step: 'Communication'
  language: Language
  nationalIDType: NationalIDType
  paySlipTransportMitDK: boolean
  paySlipTransportEBoks: boolean
  paySlipTransportEMail: boolean
  paySlipTransportSMS: boolean
}

function CommunicationForm(
  props: Props & FormComponentProps<CommunicationFields, CommunicationFields>
): ReactElement | null {
  const { decorateField } = props
  const isFreelancer = props.affiliationType === 'Freelancer'

  return (
    <div>
      <Subtitle>{t('employee.add.communication.title')}</Subtitle>
      <p>&nbsp;</p>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('language', {
            placeholder: t('employee.add.communication.language'),
            validate: (val) => (!val ? t('employee.add.communication.language.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false} placeholder={t('employee.add.communication.language.select')}>
              <Select.Option value={Language.DANISH}>{formatLanguage(Language.DANISH)}</Select.Option>
              <Select.Option value={Language.ENGLISH}>{formatLanguage(Language.ENGLISH)}</Select.Option>
            </Select>
          )}
        </Col>
      </Row>
      <Headline style={{ marginTop: '10px' }}>{t('employee.add.communication.pay_slip_transport')}</Headline>
      <PaySlipsTransport
        decorateField={decorateField}
        hasPremiumPackage={props.hasPremiumPackage}
        hasNationalID={props.nationalIDType !== 'DK No CPR'}
        hasDanishPhoneNumber={props.phoneNumberCountryCode === '45'}
        hasEmail={!!props.email}
        hasPhoneNumber={!!props.phoneNumber}
        isFreelancer={isFreelancer}
      />
      <Row style={!props.hasPremiumPackage ? { marginTop: '0' } : {}}>
        <Col span={24}>
          <Button
            htmlType="submit"
            size="large"
            type="secondary"
            className="gtm-add-employee-continue-to-financial-information"
          >
            {t('employee.add.communication.submit')}
          </Button>
          <Button size="large" onClick={props.goBack}>
            {t('employee.add.communication.back')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, CommunicationFields, CommunicationFields>({
  mapPropsToFields: (props) => ({
    step: 'Communication',
    language: props.language,
    nationalIDType: props.nationalIDType,
    paySlipTransportMitDK: props.paySlipTransportMitDK,
    paySlipTransportEBoks: props.paySlipTransportEBoks,
    paySlipTransportEMail: props.paySlipTransportEMail,
    paySlipTransportSMS: props.paySlipTransportSMS,
  }),
  onChange: (key, val) => {
    const values: Partial<CommunicationFields> = {}
    switch (key) {
      case 'paySlipTransportMitDK':
        values.paySlipTransportEBoks = false
        break
      case 'paySlipTransportEBoks':
        values.paySlipTransportMitDK = false
        break
    }
    setByPath(values, key, val)
    return values
  },
  onSubmit: (values) => values,
})(CommunicationForm)
