import React, { ReactElement } from 'react'

import { postCompanyGroupAccountingIntegrationSync } from '../../../api/accounting-integration'
import CompanyGroup from '../../../model/companyGroup'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'

interface Props {
  companyGroup: CompanyGroup
}

export default function AccountingIntegrationTab({ companyGroup }: Props): ReactElement | null {
  const syncGroup = (e: React.MouseEvent): void => {
    e.preventDefault()

    postCompanyGroupAccountingIntegrationSync(companyGroup.id)
  }

  return (
    <Card>
      <TitleMenu>
        <Button onClick={syncGroup} className="gtm-refresh-sync-group-integration">
          {t('companies.group.accounting_integration.synchronise')}
        </Button>
      </TitleMenu>
      <Title>{t('companies.group.accounting_integration.title')}</Title>
      <p>{t('companies.group.accounting_integration.note')}</p>
    </Card>
  )
}
