import React from 'react'

import { fetchLeaveTypes, patchLeaveSubTypes, patchLeaveType } from '../api/leave-types'
import ActionTypes from '../constants/action-types'
import LeaveType from '../model/leaveType'
import { LeaveTypeAction } from '../reducers/leaveTypes'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingLeaveTypes(): LeaveTypeAction {
  return {
    type: ActionTypes.LEAVE_TYPES_LOADING,
  }
}
export function loadedLeaveTypes(companyID: string, leaveTypes: LeaveType[]): LeaveTypeAction {
  return {
    type: ActionTypes.LEAVE_TYPES_LOADED,
    companyID,
    leaveTypes,
  }
}
function failedLoadingLeaveTypes(companyID: string, error: Error): LeaveTypeAction {
  return {
    type: ActionTypes.LEAVE_TYPES_LOAD_FAILED,
    error,
    companyID,
  }
}

function updatingLeaveType(): LeaveTypeAction {
  return {
    type: ActionTypes.LEAVE_TYPES_UPDATING,
  }
}
export function updatedLeaveType(companyID: string, leaveType: LeaveType): LeaveTypeAction {
  return {
    type: ActionTypes.LEAVE_TYPES_UPDATED,
    companyID,
    leaveType,
  }
}
function failedUpdatingLeaveType(companyID: string, error: Error): LeaveTypeAction {
  return {
    type: ActionTypes.LEAVE_TYPES_UPDATE_FAILED,
    error,
    companyID,
  }
}

export function getLeaveTypes() {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<LeaveType[] | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(loadingLeaveTypes())
    return fetchLeaveTypes(companyID)
      .then((res) => {
        dispatch(loadedLeaveTypes(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingLeaveTypes(companyID, e))
        }
      })
  }
}

export function updateLeaveType(leaveTypeID: string, employeeSelectable: boolean) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<LeaveType | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(updatingLeaveType())
    return patchLeaveType(leaveTypeID, employeeSelectable)
      .then((res) => {
        dispatch(updatedLeaveType(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingLeaveType(companyID, e))
        }
      })
  }
}

export function updateLeaveSubTypes(leaveTypeID: string, selectableLeaveSubTypeIDs: string[]) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature) => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(updatingLeaveType())
    return patchLeaveSubTypes(leaveTypeID, selectableLeaveSubTypeIDs)
      .then((res) => {
        dispatch(updatedLeaveType(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedUpdatingLeaveType(companyID, e))
        }
      })
  }
}
