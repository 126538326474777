import AsynchronousSchedule, { AsynchronousScheduleCreationFields } from '../model/asynchronousSchedule'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchAsynchronousSchedules(companyID: string): Promise<RequestResponse<AsynchronousSchedule[]>> {
  return secureRequest('GET', url('/v2/asynchronousSchedules', { companyID }))
}

export function postAsynchronousSchedule(
  schedule: AsynchronousScheduleCreationFields
): Promise<RequestResponse<AsynchronousSchedule>> {
  return secureRequest('POST', url('/v2/asynchronousSchedules'), {
    body: {
      companyID: schedule.companyID,
      cron: schedule.cron,
      type: schedule.type,
    },
  })
}

export function patchAsynchronousSchedule(
  scheduleID: string,
  cron: string
): Promise<RequestResponse<AsynchronousSchedule>> {
  return secureRequest('PATCH', url('/v2/asynchronousSchedules/' + scheduleID), {
    body: {
      cron,
    },
  })
}

export function delAsynchronousSchedule(scheduleID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/asynchronousSchedules/' + scheduleID))
}
