import CompanyDeviationConfiguration, {
  CompanyDeviationConfigurationMutableFields,
} from '../model/companyDeviationConfiguration'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCompanyDeviationConfigurations(
  companyID: string
): Promise<RequestResponse<CompanyDeviationConfiguration[]>> {
  return secureRequest('GET', url('/v2/companyDeviationConfigurations', { companyID }))
}

export function postCompanyDeviationConfiguration(
  companyID: string,
  companyDeviationConfiguration: CompanyDeviationConfigurationMutableFields
): Promise<RequestResponse<CompanyDeviationConfiguration>> {
  return secureRequest('POST', url('/v2/companyDeviationConfigurations'), {
    body: {
      companyID: companyID,
      type: companyDeviationConfiguration.type,
      threshold: companyDeviationConfiguration.threshold,
      periods: companyDeviationConfiguration.periods,
    },
  })
}

export function putCompanyDeviationConfiguration(
  companyID: string,
  companyDeviationConfiguration: CompanyDeviationConfiguration
): Promise<RequestResponse<CompanyDeviationConfiguration>> {
  return secureRequest('PUT', url('/v2/companyDeviationConfigurations/' + companyDeviationConfiguration.id), {
    body: {
      companyID: companyID,
      type: companyDeviationConfiguration.type,
      threshold: companyDeviationConfiguration.threshold,
      periods: companyDeviationConfiguration.periods,
    },
  })
}

export function delCompanyDeviationConfiguration(companyDeviationConfigurationID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/companyDeviationConfigurations/' + companyDeviationConfigurationID))
}
