import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import Company from '../../../model/company'
import Contract, { ContractCreationFields, ContractMutableFields } from '../../../model/contract'
import Employee from '../../../model/employee'
import { LeaveAdjustmentCreationFields } from '../../../model/leaveAdjustment'
import LeaveBalance from '../../../model/leaveBalance'
import LeaveType, { LeaveTypeName } from '../../../model/leaveType'
import { LeaveDefinition, LeaveDefinitionCreationFields, RemunerationCreationFields } from '../../../model/remuneration'
import SalaryCycle from '../../../model/salaryCycle'
import SupplementType from '../../../model/supplementType'
import { ContractReducer } from '../../../reducers/contracts'
import { EmployeeReducer } from '../../../reducers/employees'
import PensionScheme from '../../../types/pension-scheme'
import { visibleComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate } from '../../../utils/date-utils'
import { getValidFrom } from '../../../utils/employment-utils'
import { formatError } from '../../../utils/error-utils'
import { isGreatPrayerDaySupplement, isVacationSupplement } from '../../../utils/remuneration-utils'
import { t } from '../../../utils/translation-utils'
import Alert from '../../elements/alert'
import Card from '../../elements/card'
import Subtitle from '../../elements/Subtitle'
import EmploymentVacationEditForm, { VacationResult } from './EmploymentVacationEditForm'

type Props = {
  visible: boolean
  company: Company
  employee: Employee
  mutableContract: Contract
  contracts: ContractReducer
  employees: EmployeeReducer
  salaryCycle: SalaryCycle
  leaveTypes: List<LeaveType>
  supplementTypes: List<SupplementType>
  leaveBalances: List<LeaveBalance>

  updateEmployee: (employee: Employee) => Promise<Employee | void>
  addContract: (contract: ContractCreationFields) => Promise<Contract | void>
  updateContract: (contract: ContractMutableFields) => Promise<Contract | void>
  addLeaveAdjustment: (employeeID: string, leaveAdjustment: LeaveAdjustmentCreationFields) => void
}

export default function EmploymentVacationEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, contracts, employees } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, contracts.error || employees.error, error, setError)
  }, [visible, contracts, employees, error])

  const getLeaveResets = (
    originalLeave: LeaveDefinition[],
    newLeave: LeaveDefinitionCreationFields[]
  ): LeaveTypeName[] => {
    let originalExtraVacationAccrual = null
    let originalPersonalDaysAccrual = null
    if (originalLeave.length > 0) {
      originalLeave.forEach((row) => {
        const leaveType = props.leaveTypes.find((type) => type.id === row.typeID)
        if (!leaveType) {
          return
        }
        switch (leaveType.name) {
          case 'DenmarkOptionalVacation':
            originalExtraVacationAccrual = false
            break
          case 'DenmarkPersonalDay':
            originalPersonalDaysAccrual = false
            break
          case 'DenmarkExtraVacationAccrual':
            originalExtraVacationAccrual = true
            break
          case 'DenmarkPersonalTimeAccrual':
            originalPersonalDaysAccrual = true
            break
          default:
            break
        }
      })
    }
    if (originalExtraVacationAccrual === null && originalPersonalDaysAccrual === null) {
      return []
    }
    let newExtraVacationAccrual = null
    let newPersonalDaysAccrual = null
    if (newLeave.length > 0) {
      newLeave.forEach((row) => {
        const leaveType = props.leaveTypes.find((type) => type.id === row.typeID)
        if (!leaveType) {
          return
        }
        switch (leaveType.name) {
          case 'DenmarkOptionalVacation':
            newExtraVacationAccrual = false
            break
          case 'DenmarkPersonalDay':
            newPersonalDaysAccrual = false
            break
          case 'DenmarkExtraVacationAccrual':
            newExtraVacationAccrual = true
            break
          case 'DenmarkPersonalTimeAccrual':
            newPersonalDaysAccrual = true
            break
          default:
            break
        }
      })
    }
    if (newExtraVacationAccrual === null && newPersonalDaysAccrual === null) {
      return []
    }
    if (
      originalExtraVacationAccrual === newExtraVacationAccrual &&
      originalPersonalDaysAccrual === newPersonalDaysAccrual
    ) {
      // no change
      return []
    }

    const list: LeaveTypeName[] = []
    if (originalExtraVacationAccrual !== newExtraVacationAccrual) {
      if (originalExtraVacationAccrual) {
        list.push('DenmarkExtraVacationAccrual')
      } else {
        list.push('DenmarkOptionalVacation')
      }
    }
    if (originalPersonalDaysAccrual !== newPersonalDaysAccrual) {
      if (originalPersonalDaysAccrual) {
        list.push('DenmarkPersonalTimeAccrual')
      } else {
        list.push('DenmarkPersonalDay')
      }
    }
    return list
  }

  const handleSubmit = (values: VacationResult) => {
    if (!props.mutableContract.remuneration) {
      return
    }
    const employee = { ...props.employee, affiliationType: values.affiliationType }
    props.updateEmployee(employee).then(() => {
      if (!props.mutableContract.remuneration) {
        return
      }
      const contract = {
        ...props.mutableContract,
        vacationFundCVR: values.vacationFundCVR,
        manualVacationFund: values.manualVacationFund,
        remuneration: {
          ...(props.mutableContract.remuneration as RemunerationCreationFields),
          leave: values.remuneration.leave,
          supplements: props.mutableContract.remuneration.supplements
            .filter((supplement) => !isVacationSupplement(props.supplementTypes.toArray(), supplement))
            .filter((supplement) => !isGreatPrayerDaySupplement(props.supplementTypes.toArray(), supplement))
            .concat(values.remuneration.supplements),
        },
      }
      const { validFrom, canUpdate } = getValidFrom(employee, contract)

      const leaveBalanceResets = getLeaveResets(props.mutableContract.remuneration.leave, values.remuneration.leave)

      // remove or add ATP if necessary
      if (values.affiliationType === 'Director') {
        const pension = contract.remuneration.pension
        if (values.useATP) {
          if (!pension.some((pension) => pension.scheme === 'ATP')) {
            contract.remuneration.pension.push({ scheme: PensionScheme.ATP, title: 'ATP' })
          }
        } else {
          contract.remuneration.pension = pension.filter((pension) => pension.scheme !== 'ATP')
        }
      }

      contract.dayLaborer = values.dayLaborer

      if (canUpdate) {
        props.updateContract(contract).then(() => {
          leaveBalanceResets.forEach((name) => {
            props.addLeaveAdjustment(props.employee.id, {
              employeeID: props.employee.id,
              leaveTypeName: name,
              operation: 'Override',
              dispositionDate: formatAPIDate(validFrom),
              earned: 0,
              used: 0,
            })
          })
        })
      } else {
        contract.validFrom = formatAPIDate(validFrom)
        props.addContract(contract).then(() => {
          leaveBalanceResets.forEach((name) => {
            props.addLeaveAdjustment(props.employee.id, {
              employeeID: props.employee.id,
              leaveTypeName: name,
              operation: 'Override',
              dispositionDate: formatAPIDate(validFrom),
              earned: 0,
              used: 0,
            })
          })
        })
      }
    })
  }

  return (
    <Card>
      <Subtitle>{t('employment_vacation.card.edit.title')}</Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <EmploymentVacationEditForm
        mutableContract={props.mutableContract}
        validFrom={getValidFrom(props.employee, props.mutableContract).validFrom}
        company={props.company}
        employee={props.employee}
        contracts={props.contracts}
        employees={props.employees}
        salaryCycle={props.salaryCycle}
        leaveTypes={props.leaveTypes}
        supplementTypes={props.supplementTypes}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
