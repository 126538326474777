import React, { ReactElement } from 'react'

import { decorateFieldSignature } from '../../utils/form-utils'
import { parseInputNumber } from '../../utils/number-utils'
import { t } from '../../utils/translation-utils'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'

type ContractPeriodWorkHourFields = {
  periodWorkHours?: string
}

type Props<Fields extends ContractPeriodWorkHourFields> = {
  decorateField: decorateFieldSignature<Fields>
}

export default function ContractPeriodWorkHours<Fields extends ContractPeriodWorkHourFields>(
  props: Props<Fields>
): ReactElement | null {
  const { decorateField } = props

  return (
    <Row>
      <Col span={8}>
        {decorateField('periodWorkHours', {
          title: t('employment_pay.edit.contract_period_work_hours.period_work_hours'),
          placeholder: t('employment_pay.edit.contract_period_work_hours.period_work_hours.placeholder'),
          suffix: t('employment_pay.edit.contract_period_work_hours.period_work_hours.suffix'),
          validate: (val) => {
            if (!val) {
              return null
            }
            if (!val.toString().match(/^[0-9,.]+$/) || parseInputNumber(val) < 0) {
              return t('employment_pay.edit.contract_period_work_hours.period_work_hours.invalid')
            }
            return null
          },
        })(<Input />)}
      </Col>
    </Row>
  )
}
