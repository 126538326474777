import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'

import { AccountingAccount } from '../../model/accountingIntegration'
import { ExpenseCategoryBaseFields, ExpenseCategoryUpdateFields } from '../../model/expenseCategory'
import { ExpenseCategoryReducer } from '../../reducers/expenseCategories'
import { visibleComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import ExpenseCategoryEditForm, { ExpenseCategoryResult } from './ExpenseCategoryEditForm'

type Props = {
  visible: boolean
  expenseCategoryID?: string
  expenseCategories: ExpenseCategoryReducer
  accounts: List<AccountingAccount>

  addExpenseCategory: (expenseCategory: ExpenseCategoryBaseFields) => void
  updateExpenseCategory: (expenseCategory: ExpenseCategoryUpdateFields) => void
}

export default function ExpenseCategoryEdit(props: Props): ReactElement | null {
  const [error, setError] = useState<Error | null>(null)

  const { visible, expenseCategories } = props

  useEffect(() => {
    visibleComponentDidUpdate(visible, expenseCategories.error, error, setError)
  }, [visible, expenseCategories, error])

  const handleSubmit = (values: ExpenseCategoryResult) => {
    if (props.expenseCategoryID) {
      props.updateExpenseCategory({
        id: props.expenseCategoryID,
        name: values.name,
        accountingAccountID: values.accountingAccountID,
        order: values.order,
      })
    } else {
      props.addExpenseCategory({
        name: values.name,
        order: values.order,
        accountingAccountID: values.accountingAccountID,
      })
    }
  }

  return (
    <Card>
      <Subtitle>
        {props.expenseCategoryID ? t('expense_category.edit.title.edit') : t('expense_category.edit.title.create')}
      </Subtitle>
      <p>&nbsp;</p>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <ExpenseCategoryEditForm
        expenseCategoryID={props.expenseCategoryID}
        expenseCategories={props.expenseCategories}
        accounts={props.accounts}
        onSubmit={handleSubmit}
      />
    </Card>
  )
}
