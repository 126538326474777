import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import OneTimePay from '../model/oneTimePay'
import { ReducerAction } from '../utils/reducer-utils'

export interface OneTimePayReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  oneTimePays: List<OneTimePay>
  error: Error | null
}

const Params = Record<OneTimePayReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  oneTimePays: List<OneTimePay>(),
  error: null,
})

export interface OneTimePayAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  oneTimePays?: OneTimePay[]
  oneTimePay?: OneTimePay
  oneTimePayID?: string
  oneTimePayIDs?: string[]
  approved?: boolean
}

const comparator = (a: OneTimePay, b: OneTimePay) => {
  return b.dispositionDate.localeCompare(a.dispositionDate, 'da-dk')
}

export default (
  state: Record<OneTimePayReducer> = Params(),
  action: OneTimePayAction = { type: '' }
): Record<OneTimePayReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }
  // only process actions for the active company
  if (action.companyID && state.get('companyID') && action.companyID !== state.get('companyID')) {
    return state
  }

  let idx = -1
  switch (action.type) {
    case ActionTypes.ONE_TIME_PAY_LOADING:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID || state.get('employeeID'),
        loading: true,
      })
    case ActionTypes.ONE_TIME_PAY_LOADED:
    case ActionTypes.ONE_TIME_PAY_LOADED_PARTIAL:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID || state.get('employeeID'),
        loaded: true,
        loading: action.type === ActionTypes.ONE_TIME_PAY_LOADED_PARTIAL,
        oneTimePays: state.get('oneTimePays').concat(List(action.oneTimePays)).sort(comparator),
      })
    case ActionTypes.ONE_TIME_PAY_LOAD_FAILED:
      return Params({
        companyID: action.companyID || state.get('companyID'),
        employeeID: action.employeeID || state.get('employeeID'),
        error: action.error || null,
      })

    case ActionTypes.ONE_TIME_PAY_ADDING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ONE_TIME_PAY_ADDED:
      if (!action.oneTimePay) {
        return state
      }
      if (!action.companyID) {
        // only process actions for the active employee
        if (action.employeeID && state.get('employeeID') && action.employeeID !== state.get('employeeID')) {
          return state
        }
      }
      idx = state.get('oneTimePays').findIndex((item) => !!action.oneTimePay && item.id === action.oneTimePay.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('oneTimePays', state.get('oneTimePays').set(idx, action.oneTimePay).sort(comparator))
      }
      return state
        .set('saving', false)
        .set('oneTimePays', state.get('oneTimePays').push(action.oneTimePay).sort(comparator))
    case ActionTypes.ONE_TIME_PAY_ADD_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.ONE_TIME_PAY_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ONE_TIME_PAY_UPDATED:
      if (!action.oneTimePay) {
        return state
      }
      idx = state.get('oneTimePays').findIndex((item) => item.id === action.oneTimePayID)
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('oneTimePays', state.get('oneTimePays').set(idx, action.oneTimePay).sort(comparator))
    case ActionTypes.ONE_TIME_PAY_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.ONE_TIME_PAY_APPROVING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ONE_TIME_PAY_APPROVED:
      if (typeof action.approved !== 'boolean') {
        return state
      }
      return state.set('saving', false).set(
        'oneTimePays',
        state
          .get('oneTimePays')
          .map((oneTimePay) => {
            if (!action.oneTimePayIDs) {
              return oneTimePay
            }
            if (action.oneTimePayIDs.indexOf(oneTimePay.id) !== -1) {
              oneTimePay.approved = !!action.approved
            }
            return oneTimePay
          })
          .sort(comparator)
      )
    case ActionTypes.ONE_TIME_PAY_APPROVE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.ONE_TIME_PAY_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.ONE_TIME_PAY_DELETED:
      idx = state.get('oneTimePays').findIndex((item) => item.id === action.oneTimePayID)
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('oneTimePays', state.get('oneTimePays').delete(idx))
    case ActionTypes.ONE_TIME_PAY_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
