import Project, { ProjectCreationFields, ProjectMutableFields } from '../model/project'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchProjects(companyID?: string): Promise<RequestResponse<Project[]>> {
  return secureRequest('GET', url('/v2/projects', { companyID }))
}

export function postProject(project: ProjectCreationFields): Promise<RequestResponse<Project>> {
  return secureRequest('POST', url('/v2/projects'), {
    body: {
      companyID: project.companyID,
      parentID: project.parentID,
      name: project.name,
      description: project.description,
      externalID: project.externalID,
      hourlyRate: project.hourlyRate,
      order: project.order,
      validFrom: project.validFrom,
      validTo: project.validTo,
      employeeIDs: project.employeeIDs,
    },
  })
}

export function putProject(project: ProjectMutableFields): Promise<RequestResponse<Project>> {
  return secureRequest('PUT', url('/v2/projects/' + project.id), {
    body: {
      companyID: project.companyID,
      parentID: project.parentID,
      name: project.name,
      description: project.description,
      externalID: project.externalID,
      hourlyRate: project.hourlyRate,
      order: project.order,
      validFrom: project.validFrom,
      validTo: project.validTo,
      employeeIDs: project.employeeIDs,
    },
  })
}

export function delProject(projectID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/projects/' + projectID))
}
